/* eslint-disable */
import * as React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Table, Menu, Dropdown, Input, Icon, Button, Popconfirm, Tooltip, Modal, Checkbox } from 'antd';
import Paging from "./Paging";
import MenuItem from "antd/lib/menu/MenuItem";
import TableIDs from '../data/TableIDs.js';
import SelectorByID from './SelectorByID';

//import './ExtendedTable.css';
/**
    @param user 
    @param tableFormat {             
            title: string, width: css-width, sorter: bool, editable: bool, isCheckBox: bool,
            actions: [{ actionType: string-action type, actionFunction: func] ...}
    @param apiCalls { apiType: string-action name, apiCall: func ...}
    @param apiCallData // push data p->c
    @param showsearch boolean
    @param isStaticData boolean
*/

const styleTable: any = require('./ExtendedTable.css');

class ExtendedTable extends React.Component {
    // prop.columns
    // prop.api
    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                current: 1,
                defaultCurrent: 1,
                defaultPageSize: 5,
                total: 0,
                hideOnSinglePage: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${total} סה"כ רשומות`,
            },
            dataSource: [],
            sorting: {
                col: 'col_1',    // col_1 = id // col_2 = 
                order: 0,        // 0 - desend // 1 - asend
            },
            count: 0,

            isEditRow: -1,
            isCreateRow: 0,
            edit_line_tmp: [],
        };

        //this.edit_line_tmp = [];

        this.api_get = this.getApi('get');
        this.api_edit = this.getApi('edit');
        this.api_delete = this.getApi('delete');
        this.api_create = this.getApi('create');

        this.columns = [];
        this.generateColumns(this.columns);

        if (this.props.isStaticData) { // modal
            let node = { key: 0, };
            for (var j = 0; j < this.props.tableFormat.length; j++) {
                node['col_' + (j + 1)] = '';
            }
            this.state.dataSource.push(node);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        console.log("\nRECEIVE PROPS\n" + JSON.stringify(newProps));

        this.filterData = newProps.apiCallData;

        this.api_get({
            current: this.state.pagination.current,
            pageSize: this.state.pagination.defaultPageSize,
            sortField: this.state.sorting.col,
            sortOrder: (this.state.sorting.order === 'descend') ? 0 : 1,
            // filter
            filters: this.filterData,
        },
            this.getSuccessCallback,
            (e) => (e),
        );
        this.setState({ value: newProps.value });

        if (newProps.ui.onClickCreateBtn) {
            if (newProps.ui.onClickCreateBtn === this.props.id/*TableIDs.salesAlerts*/) {
                this.showCreateRow();
            }
        }

        console.log("ui.onPressEnter: ", newProps.ui.onPressEnter);

        if (newProps.ui.onPressEnter) {
            if (newProps.ui.onPressEnter.id === this.props.id) {
                this.api_edit( //api_edit
                    //Object.values(record).slice(1), // no key
                    this.edit_line_tmp.slice(1),
                    this.editSuccessCallback,
                    (e) => { console.log(e) }
                );
                let s = { ...this.state }
                s.isEditRow = -1;
                s.isCreateRow = 0;
                this.setState(s);
            }
            else // 0 /*create*/
            {

            }
        }

    }

    getApi = (type) => {
        //alert(type + "\n" + this.props.apiCalls);
        let api = this.props.apiCalls.find(item => item.apiType === type);
        return (api) ? api.apiCall : () => { console.log('skip ' + type); };
    }

    generateColumns = (_columns: any[]) => {
        let index = 1;
        for (let colFormat of this.props.tableFormat) {
            let col = {};

            col.dataIndex = 'col_' + index;
            col.title = colFormat.title;
            col.width = colFormat.width;
            if (colFormat.sorter) {
                //defaultSortOrder: 'descend',
                col.sorter = true; //(a, b) => 0;   // TODO chk api to disable
            }
            if (colFormat.editable) {
                col.render = (text, record) => {
                    return (<EditableCell
                        value={text}
                        onChange={this.onCellChange(record.key, col.dataIndex)}
                        onUpdate={this.update_edit_line_tmp}
                        tableID={this.props.id}
                        position={{ key: record.key, col: col.dataIndex }}
                        isEditRow={this.state.isEditRow === record.key ||
                            (this.state.isCreateRow == 1 && record.key == 0)}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                    />)
                }
            }
            if (colFormat.isCheckBox) {
                col.render = (text, record) => {
                    let isDisabled = !(colFormat.editable
                        && ((this.state.isEditRow == record.key)
                            || (this.state.isCreateRow == 1 && record.key == 0)));

                    if (this.props.isStaticData) { // if: modal
                        console.log("is static data true: ", this.props.isStaticData);
                        return (
                            //<Checkbox
                            //    onChange={(e) => { this.update_edit_line_tmp(record.key, col.dataIndex, (e.target.checked) ? 1 : 0); }}
                            //    defaultChecked={false}
                            //    disabled={isDisabled} />
                            <ExtendedCheckbox2
                                position={{ row: record.key, col: col.dataIndex }}
                                onUpdate={this.update_edit_line_tmp}
                                defaultChecked={false}
                                disabled={isDisabled || this.props.disabled}
                            />
                        )
                    }
                    else {  // else: main

                        console.log("yyy", this.state.isEditRow == record.key, " line ",
                            col.dataIndex,
                            this.state.edit_line_tmp,
                            this.state.edit_line_tmp[parseInt((col.dataIndex.split("_"))[1])],
                            this.state.edit_line_tmp[parseInt((col.dataIndex.split("_"))[1])] == 1);

                        return (
                            <ExtendedCheckbox2
                                position={{ row: record.key, col: col.dataIndex }}
                                onUpdate={this.update_edit_line_tmp}
                                //defaultChecked={record[col.dataIndex] === '1' || record[col.dataIndex] == 1}
                                checked={
                                    (this.state.isEditRow == record.key) ?
                                        ((this.state.edit_line_tmp[parseInt((col.dataIndex.split("_"))[1])] == 1 ||
                                            this.state.edit_line_tmp[parseInt((col.dataIndex.split("_"))[1])] === "1") ? true : false) :
                                        ((this.state.dataSource.find(i => i.key == record.key)[col.dataIndex] == 1) ? true : false)
                                }
                                defaultChecked={false}
                                disabled={isDisabled || this.props.disabled}
                            />
                        )
                    }
                }
            }
            if (colFormat.isDropSelect) {
                col.render = (text, record) => {
                    // TODO: add in table drop selector 
                    console.log("selecor by id: ", colFormat.isDropSelect);

                    let isDisabled = !(this.state.isEditRow === record.key ||
                        (this.state.isCreateRow == 1 && record.key == this.keyIndexer));

                    return (
                        <SelectorByID
                            id={colFormat.isDropSelect}
                            user={this.props.user}
                            onSelect={(e) => { this.handleOnSelect(record.key, col.dataIndex, e) }}
                            value={this.state.dataSource.find(i => i.key == record.key)[col.dataIndex]}
                            //value={this.state.formState.saleAlert}
                            title={false}
                            ui={this.props.ui}
                            uiActions={this.props.uiActions}
                            disabled={isDisabled}
                            filter={(item) => !this.state.dataSource
                                .map((item) => { return item[col.dataIndex] })
                                .includes(item.code)
                            }
                        />
                    )
                }
            }
            if (colFormat.actions) {
                col.render = (text, record) => {
                    let view = [];
                    this.addActionBtns(view, colFormat, record);
                    if (this.state.isEditRow == record.key && this.state.isCreateRow == 0) {
                        console.log("edit line " + record.key);
                        return (
                            <div>
                                <span
                                    onClick={() => {
                                        //this.onEdit(record);
                                        console.log('is disabled from props: ', this.props.disabled);
                                        const dataSource = [...this.state.dataSource];
                                        const target = dataSource.find(item => item.key === record.key);
                                        if (target) {
                                            // todo update dataSource loop over all fields
                                            //target[dataIndex] = value;
                                            this.setState({ dataSource });
                                        }

                                        let actionList = colFormat.actions.filter((item) => item.actionType === 'edit');
                                        if (actionList.length == 1) {
                                            actionList[0].actionFunction( //api_edit
                                                //Object.values(record).slice(1), // no key
                                                this.state.edit_line_tmp.slice(1),
                                                this.editSuccessCallback,
                                                (e) => { console.log(e) }
                                            );
                                        }
                                        else { console.error("edit api function missing"); } //TODO
                                        let s = { ...this.state }
                                        s.isEditRow = -1;
                                        s.isCreateRow = 0;
                                        this.setState(s);
                                    }}>
                                    <Icon style={{ fontSize: 16, padding: 4 }} type="check" />
                                </span>
                                {this.closeEditLine()}
                            </div>
                        )
                    }
                    else if (this.state.isCreateRow == 1 && record.key == 0) {
                        console.log("create line " + record.key);
                        return (
                            <div>
                                <span
                                    onClick={() => {
                                        console.log('is disabled from props: ', this.props.disabled);
                                        const dataSource = [...this.state.dataSource];
                                        const target = dataSource.find(item => item.key === record.key);
                                        if (target) {
                                            console.log("on multi cell change handler: " + JSON.stringify(target));
                                            // todo update dataSource loop over all fields
                                            //target[dataIndex] = value;
                                            this.setState({ dataSource });
                                        }
                                        this.api_create(
                                            //Object.values(record).slice(2), // no key, no ID
                                            this.state.edit_line_tmp.slice(2),
                                            this.createSuccessCallback,
                                            (e) => { console.log(e) }
                                        );
                                        let s = { ...this.state }
                                        s.isEditRow = -1;
                                        s.isCreateRow = 0;
                                        this.setState(s);
                                    }}>
                                    <Icon style={{ fontSize: 16, padding: 4 }} type="check" />
                                </span>
                                {this.closeEditLine()}
                            </div>
                        )
                    }
                    else {
                        return (<div>{view}</div>);
                    }
                }
            }
            index++;
            _columns.push(col);
        }
    }

    closeEditLine = () => {
        return (
            <span onClick={() => {
                let s = Object.assign({}, this.state);
                s.isEditRow = -1;
                s.isCreateRow = 0;
                this.setState(s);
                this.api_get({
                    //filter: this.state.searchTerm,
                    current: this.state.pagination.current,
                    pageSize: this.state.pagination.defaultPageSize,
                    sortField: this.state.sorting.col,
                    sortOrder: (this.state.sorting.order === 'descend') ? 0 : 1,
                    // filter
                    filters: Object.assign({}, this.filterData, { searchTerm: this.state.searchTerm })
                },
                    this.getSuccessCallback,
                    (e) => (e),
                );
            }}>
                <Icon style={{ fontSize: 16, padding: 4 }} type="close" />
            </span>
        )
    }

    addActionBtns = (view, colFormat, record) => {
        let icon;
        for (let action of colFormat.actions) {
            if (action.actionType === 'delete') {
                icon = (this.props.disabled) ? (
                    <Icon style={{ fontSize: 16, padding: 4 }} type="delete" />
                ) : (this.state.dataSource.length > 0) ?
                        (
                            <Popconfirm title="בטוח ברצונך למחוק?" onConfirm={
                                () => {
                                    let key = record.key;
                                    const dataSource = [...this.state.dataSource];
                                    action.actionFunction(
                                        dataSource.find(item => item.key === key).col_1,
                                        this.deleteSuccessCallback,
                                        this.deleteErrorCallback,
                                    );
                                }
                            }>
                                <Icon style={{ fontSize: 16, padding: 4 }} type="delete" />
                            </Popconfirm>
                            /*
                            <span onClick={ 
                                () => { 
                                    let key = record.key;
                                    const dataSource = [...this.state.dataSource];
                                    action.actionFunction(
                                        dataSource.find(item => item.key === key).col_1,
                                        this.deleteSuccessCallback,
                                        this.deleteErrorCallback,
                                    );
                                } }>
                                <Icon style={{ fontSize: 16, padding: 4 }} type="delete" />
                            </span>
                            */
                        ) : null;
            }
            if (action.actionType === 'edit') {
                icon = (this.props.disabled) ?
                    (
                        <Icon style={{ fontSize: 16, padding: 4 }} type="edit" />
                    ) : (this.state.dataSource.length > 0) ?
                        (
                            <span onClick={() => {
                                let s = Object.assign({}, this.state);
                                s.isEditRow = record.key;
                                //console.log(JSON.stringify(record));
                                s.line = Object.values(record);
                                //this.edit_line_tmp = Object.values(record);
                                //console.log("tmp: " + JSON.stringify(this.edit_line_tmp));
                                this.setState(s);
                            }}>
                                <Icon style={{ fontSize: 16, padding: 4 }} type="edit" />
                            </span>
                        ) : null;
            }
            if (action.actionType === 'edit_pop') {
                icon = (this.props.disabled) ?
                    (
                        <Icon style={{ fontSize: 16, padding: 4 }} type="edit" />
                    ) : (this.state.dataSource.length > 0) ?
                        (
                            <span onClick={() => {
                                let actionList = colFormat.actions.filter((item) => item.actionType === 'edit_pop');
                                if (actionList.length == 1) {
                                    actionList[0].actionFunction({ id: record['col_1'], code: record['col_2'] });
                                }
                            }}>
                                <Icon style={{ fontSize: 16, padding: 4 }} type="edit" />
                            </span>
                        ) : null;
            }
            view.push(icon);
        }
    }

    handleOnSelect = (row, col, data) => {
        console.log("on select (id)", row, col, data);
        let line = { ...this.state.edit_line_tmp };
        line[col] = data;
        this.setState(...this.state, { edit_line_tmp: line });
        //this.edit_line_tmp[col] = data;
    }

    onEdit = (record) => { // edit row
        console.log("edit " + JSON.stringify(record));
    }

    onCellChange = (key, dataIndex) => { // edit cell
        return (value) => {
            const dataSource = [...this.state.dataSource];
            const target = dataSource.find(item => item.key === key);
            if (target) {
                console.log("on cell change handler: " + JSON.stringify(target));
                // testing
                target[dataIndex] = value;
                this.setState({ dataSource });
                //
                //if (this.api_edit) {

                this.api_edit(
                    Object.values(target).slice(1),
                    /*value,target['col_1'],*/
                    this.editSuccessCallback,
                    (e) => console.log(e));
                //}
            }
        };
    }

    editSuccessCallback = (ob) => {
        console.log("edit success:" + ob.data);
        this.api_get({
            //filter: this.state.searchTerm,
            current: this.state.pagination.current,
            pageSize: this.state.pagination.defaultPageSize,
            sortField: this.state.sorting.col,
            sortOrder: (this.state.sorting.order === 'descend') ? 0 : 1,
            // filter
            filters: Object.assign({}, this.filterData, { searchTerm: this.state.searchTerm })
        },
            this.getSuccessCallback,
            (e) => (e),
        );
    }

    onDelete = (key) => {
        console.log(key);
        const dataSource = [...this.state.dataSource];
        //let api_deleteTag = this.props.apiCalls.find(item => item.apiType === 'delete').apiCall;
        this.api_delete(
            dataSource.find(item => item.key === key).col_1,
            this.deleteSuccessCallback,
            (e) => { console.log(e); }
            /*this.deleteErrorCallback*/
        );
    }

    deleteSuccessCallback = (ob) => {
        //this.handleTableChange(this.state.pagination, {}, this.state.sorter);
        console.log("DELETE SUCCESS:" + ob.data);
        this.api_get({
            //filter: this.state.searchTerm,
            current: this.state.pagination.current,
            pageSize: this.state.pagination.defaultPageSize,
            sortField: this.state.sorting.col,
            sortOrder: (this.state.sorting.order === 'descend') ? 0 : 1,
            // filter
            filters: Object.assign({}, this.filterData, { searchTerm: this.state.searchTerm })
        },
            this.getSuccessCallback,
            (e) => (e),
        );
    }
    deleteErrorCallback = (error) => {
        console.log(error ? error : "Unspecified error delete");
    }

    handleAdd = () => {
        this.api_create();
        /*
        const { count, dataSource } = this.state;
        const newData = {
            key: count,
            tagID: `new ${count}`,
            tagName: `blah blah ${count}`,
        };
        this.setState({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
        */
    }

    handleNew = () => {
        /*
        console.log(this.state.dataSource);
        let numEntries = this.state.dataSource.keys.length;
    
        let source = { ...this.state.dataSource, };
        console.log("SOURCE:\n" + JSON.stringify(source));
        //this.isEditRow = -1; // key = -1
        this.api_create(
            ["a", 1, "a", "a"],
            this.createSuccessCallback,
            (e) => { console.log(e) },
        );
        */
    }

    createSuccessCallback = (ob) => {
        console.log("create success:" + ob.data);
        this.api_get({
            //filter: this.state.searchTerm,
            current: this.state.pagination.current,
            pageSize: this.state.pagination.defaultPageSize,
            sortField: this.state.sorting.col,
            sortOrder: (this.state.sorting.order === 'descend') ? 0 : 1,
            // filter
            filters: Object.assign({}, this.filterData, { searchTerm: this.state.searchTerm })
        },
            this.getSuccessCallback,
            (e) => (e),
        );
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        const sort = { ...this.state.sorting };
        //console.log('sorter on change: ' + JSON.stringify(sort));
        sort.order = sorter.order;
        sort.col = sorter.field;
        this.setState({
            pagination: pager,
            sorting: sort,
        });
        this.updateTable({
            //results: pagination.pageSize,
            curr_page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            //...filters,
        });
    }

    updateTable = (changes) => {
        console.log("update table");
        //console.log('changes: ' + JSON.stringify(changes));

        this.api_get({
            //filter: this.state.searchTerm,
            current: changes.curr_page,
            pageSize: this.state.pagination.defaultPageSize,
            sortField: changes.sortField,
            sortOrder: (changes.sortOrder === 'descend' ? 0 : 1),
            // filter
            filters: Object.assign({}, this.filterData, { searchTerm: this.state.searchTerm })
        },
            this.getSuccessCallback,
            (e) => (e),
        );
    }

    getSuccessCallback = (ob) => {
        console.log("GET SUCCESS:" + ob.data);
        let data = ob.data;
        let rowsData = data.split("\r");
        let pageing = { ...this.state.pagination };
        //pageing.current = this._page_num;
        if (rowsData.length < 1) {
            pageing.total = 1;
        } else {
            pageing.total = rowsData[rowsData.length - 1];
            console.log("TOTAL(paging): ", pageing.total);
        }
        let dataSource = [];
        let numRows = rowsData.length - 1;
        let numCols;

        /*
        if (numRows > 1 && this.state.isCreateRow == 1) {
            let emptyNode = { key: 0, };
            for (var j = 0; j < numCols; j++) {
                emptyNode['col_' + (j + 1)] = '';
            }
            dataSource.push(emptyNode);
        }*/

        for (var i = 0; i < numRows; i++) {
            numCols = rowsData[i].length;
            let cols = rowsData[i].split("\f");
            let node = { key: (i + 1), };

            //console.log(JSON.stringify(node));
            ////// TODO
            if (i == 0 && this.state.isCreateRow == 1) {
                let createNode = { key: 0, };
                for (var j = 0; j < cols.length; j++) {
                    createNode['col_' + (j + 1)] = (cols[j] == 1 || cols[j] == 0) ? 0 : '';
                }
                numRows--;
                dataSource.push(createNode);
            }
            //////
            for (var j = 0; j < cols.length; j++) {
                node['col_' + (j + 1)] = cols[j];
            }
            dataSource.push(node);
            /*
           node = {
                key: i,
                col_1: cols[0],
                col_2: cols[1], ...
            }*/
        }

        if (!dataSource && this.state.isCreateRow == 1) {
            let emptyNode = { key: 0, };
            for (var j = 0; j < numCols; j++) {
                emptyNode['col_' + (j + 1)] = '';
            }
            dataSource.push(emptyNode);
        }

        console.log("GET SUCC DATA: " + JSON.stringify(dataSource));

        this.setState({
            pagination: pageing,
            dataSource: dataSource,
            // current
            // pagesize
            // showQuickJumper
            // showTotal
            //showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} pages`,
            //current: _page_num,
            //defaultCurrent: 1,
            //defaultPageSize: 5,
            //total: 0,
            count: dataSource.length,
        });

    }
    getErrorCallback = (error) => {
        console.log(error ? error : "Unspecified error get");
    }

    componentDidMount = () => {
        //console.log("mount component");
        if (!this.props.isStaticData) {
            this.updateTable({
                //results: pagination.pageSize,
                curr_page: this.state.pagination.defaultCurrent,
                sortField: 'col_1',
                sortOrder: 'descend', // default asc
                defaultSortOrder: 'descend',
                //...filters,
            });
        }
    }

    update_edit_line_tmp = (key, col, data) => {
        console.log("update tmp...");
        let index = parseInt((col.split("_"))[1]);
        let line = { ...this.state.edit_line_tmp };
        line[index] = data;
        this.setState(...this.state, { edit_line_tmp: line });
        //this.state.edit_line_tmp[index] = data;
        console.log("key: ", key, "data: ", data, " col: ", col, " index: ", index, "tmp: ", this.edit_line_tmp);
    }

    showCreateRow = () => {
        let s = { ...this.state };
        s.isCreateRow = 1;
        s.isEditRow = 0;
        this.setState(s);
        this.updateTable({
            //results: pagination.pageSize,
            curr_page: this.state.pagination.current,
            sortField: this.state.sorting.col,
            sortOrder: this.state.sorting.order,
            //...filters,
        });
    }

    render() {
        //const { dataSource } = this.state;
        console.log("### Rendering ###");
        const columns = this.columns;
        const Search = Input.Search;

        let divMenuStyle = {
            //backgroundColor: '#002766',
            direction: 'ltr',
        };
        //let styleTable = {
        //direction: 'rtl',
        //    padding: '2px 2px 2px 2px',
        //};
        let SearchHiddenStyle = (this.props.showsearch) ?
            { display: 'inline' } : { display: 'none' };
        let createHiddenStyle = (this.props.showCreate) ?
            { display: 'inline' } : { display: 'none' };

        //console.log("FILTER_DATA_RENDER: " + JSON.stringify(this.filterData));
        return (
            <div /*className={styleTable.mainTable}*/ >
                <span>{(this.props.title) ? this.props.title : ''}</span>
                <span style={SearchHiddenStyle}><Search
                    placeholder="קלט סינון"
                    onSearch={value => {
                        let s = Object.assign({}, this.state);
                        s.searchTerm = value;
                        s.sorting.order = 1; /// TODO ????
                        s.sorting.col = 1; /// TODO ????

                        this.setState(s);
                        this.api_get({
                            //filter: this.state.searchTerm,
                            current: this.state.pagination.defaultCurrent,
                            pageSize: this.state.pagination.defaultPageSize,
                            sortField: 'col_1',
                            sortOrder: this.state.sorting.order,
                            filters: Object.assign({}, this.filterData, { searchTerm: s.searchTerm }),
                        },
                            this.getSuccessCallback,
                            (e) => (console.log(e)),
                        );
                    }}
                    disabled={this.props.disabled}
                    style={{ width: 200 }}
                    enterButton
                /></span>
                <span>
                    <Button
                        onClick={this.showCreateRow}
                        disabled={this.props.disabled}
                        style={createHiddenStyle}>צור חדש</Button>
                </span>

                <Table bordered
                    //onChange={this.handleChange}
                    //ref={(e) => { this.e = e }}
                    /*className={styleTable.mainTable}*/
                    columns={columns}
                    dataSource={this.state.dataSource}
                    //pagination={{ pageSize: 5 }}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    scroll={{ y: '100%' }} />
            </div>
        );
    }
}


class EditableCell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            editable: false,
        }

        this.key = this.props.position.key;
        this.col = this.props.position.col;
    }
    handleChange = (e) => {
        console.log("handle change...");
        const value = e.target.value;
        this.props.onUpdate(this.key, this.col, e.target.value);
        this.setState({ value });
    }
    check = () => {
        this.setState({ editable: false });
        if (this.props.onChange) {
            this.props.onChange(this.state.value);
        }
    }
    checkRow = () => {
        //this.setState({ editable: false });
        //if (this.props.onChange) {
        //    this.props.onChange(this.state.value);
        //}
        //console.log("vvv", this.props.uiActions);
        this.props.uiActions.onPressEnter(this.props.tableID, this.key);
    }
    edit = () => {
        this.setState({ editable: true });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ value: newProps.value });
        this.filterData = newProps.apiCallData;
    }

    render() {
        const { value, editable } = this.state;
        let fontStyle = { fontSize: 16, /*padding: 4*/ };
        let editFontStyle = { fontSize: 16, /*fontWeight: 'bold',*/ minHeight: 24 }
        return (
            <div className="editable-cell">
                {
                    (this.props.isEditRow) ?
                        <Input
                            style={fontStyle}
                            value={value}
                            onChange={this.handleChange}
                            onPressEnter={this.checkRow}
                        //ref={(e) => { this.rowFocus = e; }}
                        /> : (
                            (false/*editable*/) ?
                                <div className="editable-cell-input-wrapper">
                                    <Input
                                        style={fontStyle}
                                        value={value}
                                        onChange={this.handleChange}
                                        onPressEnter={this.check}
                                    />
                                    <Icon
                                        style={{ fontSize: 16, padding: 4 }}
                                        type="check"
                                        className="editable-cell-icon-check"
                                        onClick={this.check}
                                    />
                                </div>
                                :
                                <div className="editable-cell-text-wrapper"
                                    style={editFontStyle}
                                    onClick={this.edit}>
                                    {value || ' '}
                                </div>
                        )
                }
            </div>
        );
    }
}

/* at  ## {value || ' '} ##
        <Icon
             style={{ fontSize: 16, padding: 4 }}
             type="edit"
             className="editable-cell-icon"
             onClick={this.edit} >  
*/

class ExtendedCheckbox2 extends React.Component {
    constructor(props) {
        super(props);
        //console.log("extended checkbox: ", this.props.position.row, this.props.position.col);
    }

    handle = (e) => {
        console.log("handle:)", e, e.target.checked);
        //console.log("row:", this.props.position.row, " col:", this.props.position.col, " val:", (e.target.checked) ? 1 : 0);
        this.props.onUpdate(
            this.props.position.row, this.props.position.col, (e.target.checked) ? 1 : 0);
    }

    render() {
        //console.log("render chkbox:\n", this.props, "\ndefault: ", this.props.defaultChecked);
        return (
            <Checkbox
                onChange={this.handle}
                //defaultChecked={false}
                checked={this.props.checked}
                defaultChecked={this.props.defaultChecked} //record[col.dataIndex] === '1'/*(text === '1')*/}
                disabled={this.props.disabled} />
        )
    }
}

export default ExtendedTable;


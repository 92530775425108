/* @flow */
/*jshint esversion: 6 */
/*jslint es6*/
const ActionTypes = {
    USER_SET_USER_FOR_RENEW: "USER_SET_USER_FOR_RENEW",
    USER_GET_USER_FOR_RENEW: "USER_GET_USER_FOR_RENEW",
    USER_GET_USER_FOR_RENEW_SUCCESS: "USER_GET_USER_FOR_RENEW_SUCCESS",
    USER_GET_USER_FOR_RENEW_ERROR: "USER_GET_USER_FOR_RENEW_ERROR",

    USER_JUST_LOGIN: "USER_JUST_LOGIN",
    USER_LOGIN: "USER_LOGIN",
    USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    USER_LOGIN_ERROR: "USER_LOGIN_ERROR",
    USER_LOGOUT: "USER_LOGOUT",

    USER_REFRESH: "USER_REFRESH",

    TOGGLE_MENU: "TOGGLE_MENU",
    SAVE_FORM_VALUES: "SAVE_FORM_VALUES",
    USER_GET_UI_DATA: "USER_GET_UI_DATA",
    USER_GET_UI_DATA_SUCCESS: "USER_GET_UI_DATA_SUCCESS",
    USER_GET_UI_DATA_ERROR: "USER_GET_UI_DATA_ERROR",
    USER_FAVORITES_LOADED: "USER_FAVORITES_LOADED",
    USER_MENU_LOADED: "USER_MENU_LOADED",
    USER_PROFILE_LOADED: "USER_PROFILE_LOADED",
    COMPANY_NAME_LOADED: "COMPANY_NAME_LOADED",

    // MODALS
    RENEW_MODAL: "RENEW_MODAL",
    CONFIRMATION_MODAL: "CONFIRMATION_MODAL",
    RENEW_CONFIRMATION_MODAL: "RENEW_CONFIRMATION_MODAL",

    SHOW_MODAL: "SHOW_MODAL",
    HIDE_MODAL: "HIDE_MODAL",
    HIDE_MODAL_BY_NAME: "HIDE_MODAL_BY_NAME",
    SET_MODAL_UI_DATA: "SET_MODAL_UI_DATA",
    // TOOLBAR
    ON_CREATE_BTN: "ON_CREATE_BTN",
    // GENERAL
    PRESS_ENTER: "PRESS_ENTER",
    // DATA TRANSFER
    GET_COMPS_DATA: "GET_COMPS_DATA",
    GET_COMP_DATA: "GET_COMP_DATA",
    SEND_COMP_DATA: "SEND_COMP_DATA",
    // API CALLS

    // DIALOG ACTIONS
    UI_SHOW_ITEM_PICKER: "UI_SHOW_ITEM_PICKER",
    UI_HIDE_ITEM_PICKER: "UI_HIDE_ITEM_PICKER",
    UI_SHOW_CUSTOMER_PICKER: "UI_SHOW_CUSTOMER_PICKER",
    UI_HIDE_CUSTOMER_PICKER: "UI_HIDE_CUSTOMER_PICKER",
    
    SET_JSON_UI: "SET_JSON_UI",

    // DATA ACTIONS
    SET_DATA: "SET_DATA",
    API_FAILED_FORBIDDEN: "API_FAILED_FORBIDDEN",
    GET_USING_POINTS_REQUESTED: "GET_USING_POINTS_REQUESTED",
    GET_USING_POINTS_SUCCEEDED: "GET_USING_POINTS_SUCCEEDED",
    GET_USING_POINTS_FAILED: "GET_USING_POINTS_FAILED",
    GET_ITEM_TAGS_REQUESTED: "GET_ITEM_TAGS_REQUESTED",
    GET_ITEM_TAGS_SUCCEEDED: "GET_ITEM_TAGS_SUCCEEDED",
    GET_ITEM_TAGS_FAILED: "GET_ITEM_TAGS_FAILED",
    SAVE_USING_POINTS_REQUESTED: "SAVE_USING_POINTS_REQUESTED",
    SAVE_USING_POINTS_SUCCEEDED: "SAVE_USING_POINTS_SUCCEEDED",
    SAVE_USING_POINTS_FAILED: "SAVE_USING_POINTS_FAILED",
    GET_ITEM_CODES_REQUESTED: "GET_ITEM_CODES_REQUESTED",
    GET_ITEM_CODES_SUCCEEDED: "GET_ITEM_CODES_SUCCEEDED",
    GET_ITEM_CODES_FAILED: "GET_ITEM_CODES_FAILED",
    GET_CUSTOMER_TAGS_REQUESTED: "GET_CUSTOMER_TAGS_REQUESTED",
    GET_CUSTOMER_TAGS_SUCCEEDED: "GET_CUSTOMER_TAGS_SUCCEEDED",
    GET_CUSTOMER_TAGS_FAILED: "GET_CUSTOMER_TAGS_FAILED",
    GET_EARNING_POINTS_REQUESTED: "GET_EARNING_POINTS_REQUESTED",
    GET_EARNING_POINTS_SUCCEEDED: "GET_EARNING_POINTS_SUCCEEDED",
    GET_EARNING_POINTS_FAILED: "GET_EARNING_POINTS_FAILED",

    EARNING_POINTS_START_EDITING: "EARNING_POINTS_START_EDITING",
    EARNING_POINTS_CANCEL_EDITING: "EARNING_POINTS_CANCEL_EDITING",
    EARNING_POINTS_SAVE_EDIT: "EARNING_POINTS_SAVE_EDIT",
    EARNING_POINTS_SET_EDIT: "EARNING_POINTS_SET_EDIT",
    EARNING_POINTS_DELETE_ROW: "EARNING_POINTS_DELETE_ROW",
    EARNING_POINTS_DELETE_ROW_SUCCEEDED: "EARNING_POINTS_DELETE_ROW_SUCCEEDED",
    EARNING_POINTS_DELETE_ROW_FAILED: "EARNING_POINTS_DELETE_ROW_FAILED",
    EARNING_POINTS_SAVE_EDIT_SUCCEEDED: "EARNING_POINTS_SAVE_EDIT_SUCCEEDED",
    EARNING_POINTS_SAVE_EDIT_FAILED: "EARNING_POINTS_SAVE_EDIT_FAILED",
    EARNING_POINTS_START_NEW: "EARNING_POINTS_START_NEW",
    EARNING_POINTS_CANCEL_NEW: "EARNING_POINTS_CANCEL_NEW",
    EARNING_POINTS_SET_FILTER: "EARNING_POINTS_SET_FILTER",
    EARNING_POINTS_SHOW_FILTERS: "EARNING_POINTS_SHOW_FILTERS",
    EARNING_POINTS_HIDE_FILTERS: "EARNING_POINTS_HIDE_FILTERS",
    EARNING_POINTS_CLEAR_FILTERS: "EARNING_POINTS_CLEAR_FILTERS",

    SELECTOR_UPDATE_SALESALERTS: "SELECTOR_UPDATE_SALESALERTS",
    SELECTOR_UPDATE_SUPPLIERS: "SELECTOR_UPDATE_SUPPLIERS",
    SELECTOR_UPDATE_PRICES: "SELECTOR_UPDATE_PRICES",
    SELECTOR_UPDATE_TAXTYPE: "SELECTOR_UPDATE_TAXTYPE",
    SELECTOR_PICK_SALESALERTS: "SELECTOR_PICK_SALESALERTS",
    SELECTOR_PICK_SUPPLIERS: "SELECTOR_PICK_SUPPLIERS",
    SELECTOR_PICK_PRICES: "SELECTOR_PICK_PRICES",
    SELECTOR_PICK_TAX_TYPE: "SELECTOR_PICK_TAX_TYPE",
    SELECTOR_UPDATE_TAX_TYPE: "SELECTOR_UPDATE_TAX_TYPE",

    SELECTOR_PICK_CUSTOMER_FIELDS: "SELECTOR_PICK_CUSTOMER_FIELDS",
    SELECTOR_REFRESH_CUSTOMER_FIELDS: "SELECTOR_REFRESH_CUSTOMER_FIELDS",

    BARGAIN_TYPES_GET_REQUESTED: "BARGAIN_TYPES_GET_REQUESTED",
    BARGAIN_TYPES_GET_SUCCEEDED: "BARGAIN_TYPES_GET_SUCCEEDED",
    BARGAIN_TYPES_GET_FAILED: "BARGAIN_TYPES_GET_FAILED",

    GENERIC_SELECTOR_PICK: "GENERIC_SELECTOR_PICK",
    GENERIC_SELECTOR_PICK_UPDATE: "GENERIC_SELECTOR_PICK_UPDATE",
    GENERIC_SELECTOR_REFRESH_DATASET: "GENERIC_SELECTOR_REFRESH_DATASET",
    GENERIC_SELECTOR_GET_SUCCEEDED: "GENERIC_SELECTOR_GET_SUCCEEDED",
    GENERIC_SELECTOR_GET_FAILED: "GENERIC_SELECTOR_GET_FAILED",
    GENERIC_MULTI_SELECTOR_REFRESH_DATASET: "GENERIC_MULTI_SELECTOR_REFRESH_DATASET",
    GENERIC_MULTI_SELECTOR_GET_SUCCEEDED: "GENERIC_MULTI_SELECTOR_GET_SUCCEEDED",
    GENERIC_MULTI_SELECTOR_GET_FAILED: "GENERIC_MULTI_SELECTOR_GET_FAILED",

    GENERIC_DATA_GET_REQUESTED: "GENERIC_DATA_GET_REQUESTED",
    GENERIC_DATA_GET_SUCCEEDED: "GENERIC_DATA_GET_SUCCEEDED",
    GENERIC_DATA_GET_EDIT_REQUESTED: "GENERIC_DATA_GET_EDIT_REQUESTED",
    GENERIC_DATA_GET_EDIT_SUCCEEDED: "GENERIC_DATA_GET_EDIT_SUCCEEDED",
    GENERIC_DATA_GET_FAILED: "GENERIC_DATA_GET_FAILED",
    GENERIC_DATA_START_EDITING: "GENERIC_DATA_START_EDITING",
    GENERIC_DATA_START_EDITING_PATH: "GENERIC_DATA_START_EDITING_PATH",
    GENERIC_DATA_CANCEL_EDITING: "GENERIC_DATA_CANCEL_EDITING",
    GENERIC_DATA_SAVE_EDIT: "GENERIC_DATA_SAVE_EDIT",
    GENERIC_DATA_JUST_SAVE_EDIT: "GENERIC_DATA_JUST_SAVE_EDIT",
    GENERIC_DATA_JUST_SAVE_EDIT_NEXT: "GENERIC_DATA_JUST_SAVE_EDIT_NEXT",

    GENERIC_DATA_SET_EDIT: "GENERIC_DATA_SET_EDIT",
    GENERIC_DATA_DELETE_ROW: "GENERIC_DATA_DELETE_ROW",
    GENERIC_DATA_DELETE_ROW_WITH_PARAMS: "GENERIC_DATA_DELETE_ROW_WITH_PARAMS",
    GENERIC_DATA_DELETE_ROW_SUCCEEDED: "GENERIC_DATA_DELETE_ROW_SUCCEEDED",
    GENERIC_DATA_DELETE_ROW_FAILED: "GENERIC_DATA_DELETE_ROW_FAILED",
    GENERIC_DATA_SAVE_EDIT_SUCCEEDED: "GENERIC_DATA_SAVE_EDIT_SUCCEEDED",
    GENERIC_DATA_JUST_SAVE_EDIT_SUCCEEDED: "GENERIC_DATA_JUST_SAVE_EDIT_SUCCEEDED",
    GENERIC_DATA_SAVE_EDIT_FAILED: "GENERIC_DATA_SAVE_EDIT_FAILED",
    GENERIC_DATA_START_NEW: "GENERIC_DATA_START_NEW",
    GENERIC_DATA_CANCEL_NEW: "GENERIC_DATA_CANCEL_NEW",
    GENERIC_DATA_SET_FILTER: "GENERIC_DATA_SET_FILTER",
    GENERIC_DATA_SHOW_FILTERS: "GENERIC_DATA_SHOW_FILTERS",
    GENERIC_DATA_HIDE_FILTERS: "GENERIC_DATA_HIDE_FILTERS",
    GENERIC_DATA_CLEAR_FILTERS: "GENERIC_DATA_CLEAR_FILTERS",
    GENERIC_DATA_START_INLINE_CREATE: "GENERIC_DATA_START_INLINE_CREATE",
    GENERIC_DATA_CANCEL_INLINE_CREATE: "GENERIC_DATA_CANCEL_INLINE_CREATE",
    GENERIC_DATA_GET_WITH_PARAMS: "GENERIC_DATA_GET_WITH_PARAMS",
    GENERIC_DATA_GET_EDIT_WITH_PARAMS: "GENERIC_DATA_GET_EDIT_WITH_PARAMS",
    GENERIC_DATA_SAVE_EDIT_MULTI: "GENERIC_DATA_SAVE_EDIT_MULTI",
    GENERIC_DATA_JUST_SAVE_EDIT_MULTI: "GENERIC_DATA_JUST_SAVE_EDIT_MULTI",
    GENERIC_DATA_SAVE_EDIT_WITH_PARAMS: "GENERIC_DATA_SAVE_EDIT_WITH_PARAMS",
    GENERIC_DATA_JUST_SAVE_EDIT_WITH_PARAMS: "GENERIC_DATA_JUST_SAVE_EDIT_WITH_PARAMS",

    DATA_TABLE_ADD_ROW: "DATA_TABLE_ADD_ROW",
    DATA_TABLE_SET_ROW: "DATA_TABLE_SET_ROW",
    DATA_TABLE_DELETE_ROW: "DATA_TABLE_DELETE_ROW",

    GENERIC_JSON_GET: "GENERIC_JSON_GET",
    GENERIC_JSON_GET_AND_EDIT: "GENERIC_JSON_GET_AND_EDIT",
    GENERIC_JSON_GET_SUCCEEDED: "GENERIC_JSON_GET_SUCCEEDED",
    GENERIC_JSON_GET_AND_EDIT_SUCCEEDED: "GENERIC_JSON_GET_AND_EDIT_SUCCEEDED",
    GENERIC_JSON_GET_FAILED: "GENERIC_JSON_GET_FAILED",
    GENERIC_JSON_SET: "GENERIC_JSON_SET",
    GENERIC_JSON_SET_AND_RELOAD: "GENERIC_JSON_SET_AND_RELOAD",
    GENERIC_JSON_SET_SUCCEEDED: "GENERIC_JSON_SET_SUCCEEDED",
    GENERIC_JSON_SET_AND_RELOAD_SUCCEEDED: "GENERIC_JSON_SET_AND_RELOAD_SUCCEEDED",
    GENERIC_JSON_SET_FAILED: "GENERIC_JSON_SET_FAILED",

    SET_DATA_STATE: "SET_DATA_STATE",
    GENERIC_NETWORK_REQUEST: "GENERIC_NETWORK_REQUEST",
    GENERIC_NETWORK_REQUEST_SUCCEEDED: "GENERIC_NETWORK_REQUEST_SUCCEEDED",
    GENERIC_NETWORK_REQUEST_FAILED: "GENERIC_NETWORK_REQUEST_FAILED",
    NETWORK_FETCH_DATA: "NETWORK_FETCH_DATA",
    NETWORK_FETCH_DATA_SUCCESS: "NETWORK_FETCH_DATA_SUCCESS",
    NETWORK_FETCH_DATA_FAIL: "NETWORK_FETCH_DATA_FAIL",
    NETWORK_FETCH: "NETWORK_FETCH",
    NETWORK_FETCH_SUCCESS: "NETWORK_FETCH_SUCCESS",
    NETWORK_FETCH_FAIL: "NETWORK_FETCH_FAIL",
    NETWORK_POST_DATA: "NETWORK_POST_DATA",
    NETWORK_POST_DATA_SUCCESS: "NETWORK_POST_DATA_SUCCESS",
    NETWORK_POST_DATA_FAIL: "NETWORK_POST_DATA_FAIL",
    NETWORK_POST: "NETWORK_POST",
    NETWORK_POST_SUCCESS: "NETWORK_POST_SUCCESS",
    NETWORK_POST_FAIL: "NETWORK_POST_FAIL",
    NETWORK_FETCH_JSON: "NETWORK_FETCH_JSON",
    NETWORK_FETCH_JSON_SUCCESS: "NETWORK_FETCH_JSON_SUCCESS",
    NETWORK_FETCH_JSON_FAIL: "NETWORK_FETCH_JSON_FAIL",
    NETWORK_POST_JSON: "NETWORK_POST_JSON",
    NETWORK_POST_JSON_AS_DATA: "NETWORK_POST_JSON_AS_DATA",
    NETWORK_POST_JSON_SUCCESS: "NETWORK_POST_JSON_SUCCESS",
    NETWORK_POST_JSON_FAIL: "NETWORK_POST_JSON_FAIL",
    NETWORK_POST_AND_THEN_FETCH_DATA: "NETWORK_POST_AND_THEN_FETCH_DATA",
    NETWORK_POST_AND_THEN_FETCH_DATA_SUCCESS: "NETWORK_POST_AND_THEN_FETCH_DATA_SUCCESS",
    NETWORK_POST_AND_THEN_FETCH_DATA_FAIL: "NETWORK_POST_AND_THEN_FETCH_DATA_FAIL",
    NETWORK_POST_AND_THEN_FETCH_JSON: "NETWORK_POST_AND_THEN_FETCH_JSON",
    NETWORK_POST_AND_THEN_FETCH_JSON_SUCCESS: "NETWORK_POST_AND_THEN_FETCH_JSON_SUCCESS",
    NETWORK_POST_AND_THEN_FETCH_JSON_FAIL: "NETWORK_POST_AND_THEN_FETCH_JSON_FAIL",
    NETWORK_POST_JSON_AND_THEN_FETCH_DATA: "NETWORK_POST_JSON_AND_THEN_FETCH_DATA",
    NETWORK_POST_JSON_AND_THEN_FETCH_DATA_SUCCESS: "NETWORK_POST_JSON_AND_THEN_FETCH_DATA_SUCCESS",
    NETWORK_POST_JSON_AND_THEN_FETCH_DATA_FAIL: "NETWORK_POST_JSON_AND_THEN_FETCH_DATA_FAIL",
    NETWORK_POST_DATA_AND_THEN_FETCH_JSON: "NETWORK_POST_DATA_AND_THEN_FETCH_JSON",
    NETWORK_POST_DATA_AND_THEN_FETCH_JSON_SUCCESS: "NETWORK_POST_DATA_AND_THEN_FETCH_JSON_SUCCESS",
    NETWORK_POST_DATA_AND_THEN_FETCH_JSON_FAIL: "NETWORK_POST_DATA_AND_THEN_FETCH_JSON_FAIL",

    IMAGE_UPLOAD_REQUESTED: "IMAGE_UPLOAD_REQUESTED",
    IMAGE_UPLOAD_SUCCESS: "IMAGE_UPLOAD_SUCCESS",
    IMAGE_UPLOAD_FAILED: "IMAGE_UPLOAD_FAILED",

    GENERATE_REPORT: "GENERATE_REPORT",
    GENERATE_REPORT_SUCCESS: "GENERARTE_REPORT_SUCCESS",
    GENERATE_REPORT_FAIL: "GENERATE_REPORT_FAIL",

    SET_DEFAULT: "SET_DEFAULT",
    SET_ACTIVE: "SET_ACTIVE",
    SET_IS_EDIT_FATHER_ITEM: 'SET_IS_EDIT_FATHER_ITEM',
    RAISE_WORKINPROGRESS_FLAG: 'RAISE_WORKINPROGRESS_FLAG',
    LOWER_WORKINPROGRESS_FLAG: 'LOWER_WORKINPROGRESS_FLAG',
    SET_JSON: "SET_JSON",
    OVERRIDE_JSON: "OVERRIDE_JSON",
    OVERRIDE_JSON_UI: "OVERRIDE_JSON_UI",

    CLEAR_DATA_STORE: "CLEAR_DATA_STORE",
};

export default ActionTypes;

import * as React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Table, Menu, Dropdown, Input, Icon, Button, Popconfirm, Tooltip } from 'antd';
import Paging from "./Paging";
import MenuItem from "antd/lib/menu/MenuItem";



class EditableCell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            editable: false,
        };
    }

    handleChange (e) {
        const value = e.target.value;
        this.setState({ value });
    };
    check () {
        this.setState({ editable: false });
        if (this.props.onChange) {
            this.props.onChange(this.state.value);
        }
    };
    edit ()  {
        this.setState({ editable: true });
    };
    render() {
        const { value, editable } = this.state;
        return (
            <div className="editable-cell">
                {
                    editable ?
                        <div className="editable-cell-input-wrapper">
                            <Input
                                value={value}
                                onChange={this.handleChange}
                                onPressEnter={this.check}
                            />
                            <Icon
                                type="check"
                                className="editable-cell-icon-check"
                                onClick={this.check}
                            />
                        </div>
                        :
                        <div className="editable-cell-text-wrapper">
                            {value || ' '}
                            <Icon
                                type="edit"
                                className="editable-cell-icon"
                                onClick={this.edit}
                            />
                        </div>
                }
            </div>
        );
    }
}

class TableWithPaging extends React.Component {
    // prop.columns
    // prop.api
    constructor(props) {
        super(props);
        this.columns = [ /// cols header of table
            {
                title: 'קוד לקוח',
                dataIndex: 'tagID',
                width: '30%',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.tagID - b.tagID, // TODO: change to api           
            }, {
                title: 'תגית',
                dataIndex: 'tagName',
                width: '40%',
                render: (text, record) => (
                    <EditableCell
                        value={text}
                        onChange={this.onCellChange(record.key, 'tagName')}
                    />
                ),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.tagName - b.tagName, // TODO: change to api
            }, {
                title: 'פעולות',
                dataIndex: 'operation',
                width: '30%',
                render: (text, record) => {
                    return (
                        this.state.dataSource.length > 1 ?
                            (
                                <Popconfirm title="Sure to delete?" onConfirm={() => this.onDelete(record.key)}>
                                    <Icon type="delete" />
                                </Popconfirm>
                            ) : null
                    );
                },
            }];
        
        

        this.state = { /// data of table... 
            dataSource: [{
                key: '0',
                tagID: '11',
                tagName: 'aaa',
            }, {
                key: '1',
                tagID: '22',
                tagName: 'bbb',
            }, {
                key: '2',
                tagID: '33',
                tagName: 'ccc',
            }, {
                key: '3',
                tagID: '44',
                tagName: 'ddd',
            }, {
                key: '4',
                tagID: '55',
                tagName: 'eee',
            }],
            count: 5,
        };

        /*
                this.state = { /// data of table... 
                    dataSource: [],
                    count: 0,
                };*/
        
    };
    onCellChange = (key, dataIndex) => {
        return (value) => {
            const dataSource = [...this.state.dataSource];
            const target = dataSource.find(item => item.key === key);
            if (target) {
                //target[dataIndex] = value; 
                //this.setState({ dataSource });
                this.api_editTag(value, target['tagID']);
                this.api_getTags();
            }
        };
    };
    onDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        //this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
        var dataSend = "mTagID\r" + dataSource.filter(item => item.key === key).tagID;
        var user = new User(this.props.token, "");
        var job = new Job(user, "delete_tag", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/JMAdmin", this.deleteSuccessCallback, this.deleteErrorCallback);
    };
    deleteSuccessCallback = function (ob) {
        alert("delete success:" + ob.data);
        console.log(ob.data);
        this.api_getTags();
        // TODO. call api to get page
    };
    deleteErrorCallback = function (error) {
        alert(error ? error : "Unspecified error delete");
    };

    //////////////////////////
    api_getTags = function () {
        //const dataSource = [...this.state.dataSource];
        //this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
        var dataSend = "_filter\f_page_num\f_rows_num\f_sort_by\f_isAsc\r" +
            this.pagination + "\f" +
            this.current + "\f" +
            this.pageSize + "\f" +
            this.sortField + "\f" +
            this.sortOrder;

        var user = new User(this.props.token, "");
        var job = new Job(user, "get_tags_by_page", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/JMAdmin", this.getTagsSuccessCallback, this.getTagsErrorCallback);
    };

    getTagsSuccessCallback = function (ob) {
        alert("get success:" + ob.data)
            // update table data
            (function (data) {
                let rowsData = data.split("\r");
                let pageing = { ...this.state.pagination };

                if (rowsData.length < 1) {
                    pageing.total = 1;
                } else {
                    pageing.total = rowsData[rowsData.length - 1];
                }
                this.setState({
                    pagination: pageing,
                });
                this.dataSource = [];

                for (let i = 1; i < rowsData.lenght - 1; i++) {
                    let cols = rowsData[i].split("\f");
                    this.dataSource.push({
                        key: i - 1,
                        tagID: cols[0],
                        tagName: cols[1],
                    })
                }
            }(ob.data));

    };
    getTagsErrorCallback = function (error) {
        alert(error ? error : "Unspecified error get");
    };

    handleAdd = () => {
        this.api_createTag();
        /*
        const { count, dataSource } = this.state;
        const newData = {
            key: count,
            tagID: `new ${count}`,
            tagName: `blah blah ${count}`,
        };
        this.setState({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
        */
    };

    /////////////////////////////////////////
    api_createTag = function () {
        var dataSend = "tag_name\r" + ""; // empty name edit on website
        console.log("dataSend");

        var user = new User(this.props.token, "");
        var job = new Job(user, "create_new_tag", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/JMAdmin", this.createTagsSuccessCallback, this.createTagsErrorCallback);
    };
    createTagsSuccessCallback = function (ob) {
        alert("creates success:" + ob.data)

        this.api_getTags();
    };
    createTagsErrorCallback = function (error) {
        alert(error ? error : "Unspecified error create");
    };

    ////////////////////////////////////////
    api_editTag = function (_newTagName, _TagID) {
        var dataSend = "mNewTagName\fmTagID\r" + _newTagName + "\f" + _TagID;
        console.log("dataSend");

        var user = new User(this.props.token, "");
        var job = new Job(user, "edit_tag", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/JMAdmin", this.editTagsSuccessCallback, this.editTagsErrorCallback);
    };
    editTagsSuccessCallback = function (ob) {
        alert("edit success:" + ob.data)
        // TODO. call api to get page
    };
    editTagsErrorCallback = function (error) {
        alert(error ? error : "Unspecified error edit");
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    render() {
        const { dataSource } = this.state;
        const columns = this.columns;
        const Search = Input.Search;

        let divMenuStyle = {
            //backgroundColor: '#002766',
            direction: 'ltr',
        }

        return (
            <div>
                <span><Search
                    placeholder="input search text"
                    onSearch={value => this.api_getTags()} // TODO get tag with search param using api 
                    style={{ width: 200 }}
                    enterButton
                /></span>
                <Table bordered onChange={this.handleChange} dataSource={dataSource}
                    columns={columns} pagination={{ pageSize: 4 }} scroll={{ y: 240 }} />
            </div>
        );
    }
}

export default TableWithPaging;
/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Select, Switch, InputNumber, Popconfirm, Input, message } from "antd";
import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import ModalLabelFormatsData from "../../components/ModalLabelFormatsData";
import ModalEditLabelField from "../../components/ModalEditLabelField";
import LabelPrinter from "../../components/LabelPrinter";
import { fixCurrentPage } from "../../utils/utils";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class LabelFormats extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "LABEL_FORMATS_LIST";
        this.modal_id = "LABEL_FORMATS_DATA";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false, lFormatsData: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }

        this.api = {
            get: "get_label_formats_list",
            get_modal: "get_labelFields_table",
            get_ezpl: "get_EZPL_code_to_printer",
            create: "create_label_formats",
            edit: "edit_label_formats",
            delete: "delete_label_formats",

        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id); }
            },
            {
                buttonType: 'print',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.labelPrinter, {}) }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10206);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 6;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "lId": col--;
            case "lDescription": col--;
            case "lHeight": col--;
            case "lWidth": col--;
            case "lDirection": col--;
            case "lGap": break; // 6
            default:
                col = 0;
        }


        let request = {
            _id: 0,
            _fieldId: false,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };


        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }


    dataObject = (id) => {
        let headers = this.props.data[id].headers;
        return this.props.data[id].data.map((x, indexData) => {
            let y = x.split("\f");
            let ob = { key: y[0] }
            headers.map((z, index) => ob = { ...ob, [z]: y[index], index: indexData });
            return ob;
        });
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                lId: y[0],
                lDescription: y[1],
                lHeight: y[2],
                lWidth: y[3],
                lDirection: y[4],
                lGap: y[5],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            _id: 0,
            _fieldId: false,
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let list = this.props.data[TableIDs.genericSelectorPrices].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
        });

        let columns = [
            {
                title: this.getText(11633),
                dataIndex: "lId",
                key: "lId",
                sorter: false,
                //sortOrder: paging.sortColumnRaw === 'mId' && paging.sortTypeRaw,
                width: '7%',
                render: (text, record) => text,
            }, {
                title: this.getText(11634),
                dataIndex: "lDescription",
                key: "lDescription",
                width: '20%',
                sorter: false,
                //sortOrder: paging.sortColumnRaw === 'mPriceListId' && paging.sortTypeRaw,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    // console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { lDescription: value.target.value });
                                }} />);
                    } else {
                        return text;
                    }
                },
            }, {
                title: this.getText(11635),
                dataIndex: "lHeight",
                key: "lHeight",
                width: '15%',
                sorter: false,
                //sortOrder: paging.sortColumnRaw === 'mItemCode' && paging.sortTypeRaw,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <InputNumber min={0} step={1}
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value: number) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { lHeight: value });
                                }} />);
                    } else {
                        return text;
                    }
                },
            }, {
                title: this.getText(11636),
                dataIndex: "lWidth",
                key: "lWidth",
                width: '15%',
                sorter: false,
                //sortOrder: paging.sortColumnRaw === 'mShortName' && paging.sortTypeRaw,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <InputNumber min={0} step={1}
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value: number) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { lWidth: value });
                                }} />);
                    } else {
                        return text;
                    }
                },
            }, {
                title: this.getText(11637),
                dataIndex: "lDirection",
                key: "lDirection",
                width: '15%',
                sorter: false,
                //sortOrder: paging.sortColumnRaw === 'mPriceVal' && paging.sortTypeRaw,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <Switch checkedChildren={this.getText(11643)} unCheckedChildren={this.getText(11644)}
                                checked={this.props.data[this.id].editedRecord.lDirection == 1 ? true : false}
                                onChange={(value) => {
                                    // console.log('xxx',value?1:0);
                                    this.props.dataActions.genericDataSetEdit(this.id, { lDirection: value ? 1 : 0 });
                                }}
                                style={{ margin: '10px' }}
                            />
                        );
                    } else {
                        return (text == 1) ? this.getText(11643) : this.getText(11644);
                    }
                },
            }, {
                title: this.getText(11638),
                dataIndex: "lGap",
                key: "lGap",
                width: '15%',
                sorter: false,
                //sortOrder: paging.sortColumnRaw === 'mPriceVal' && paging.sortTypeRaw,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <InputNumber min={0} step={1}
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value: number) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { lGap: value });
                                }} />);
                    } else {
                        return text;
                    }
                },
            }, {
                title: this.getText(11639),
                width: '12%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                }} />
                                <Popconfirm title={ this.getText(11640)}
                                    onConfirm={() => {
                                        console.log("record", record);
                                        fixCurrentPage.bind(this)();
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.id,
                                            record['lId'],
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.get);
                                    }
                                    }
                                    okText={ this.getText(11641)} cancelText={ this.getText(11642)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm>
                                {/* <Tooltip title={this.getText(11645)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => {
                                        this.setState({ lFormatsData: record['lId'] },
                                            this.props.uiActions.showModal(TableIDs.modalLabelFormatsData, { labelId: record['lId'] })
                                        )
                                    }} />
                                </Tooltip> */}

                            </span >);
                    } else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    let record = this.props.data[this.id].editedRecord;
                                    let fieldsValid = [
                                        record.lDescription,
                                        record.lHeight,
                                        record.lWidth,
                                        record.lGap,
                                    ];
                                    let labelValid = fieldsValid.reduce((a, b) => { return a && b });
                                    if (labelValid) {
                                        if (isCreate)
                                            this.props.dataActions.genericDataSaveEdit(
                                                this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get);
                                        else
                                            this.props.dataActions.genericDataSaveEdit(
                                                this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                    } else {
                                        message.error(this.getText(11646));
                                    }
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)
                    }
                }
            }
        ];

        return (
            <div style={divStyle}>
                <PageToolbar
                    title={this.getText(11632)}
                    actionButtons={this.actionButtons}
                    showsearch={
                        (val) => {
                            this.props.dataActions.genericDataSetFilter(
                                this.id, this.props.user.companyCode, this.props.user.token,
                                {
                                    ...request, _words: val
                                        .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                        page: 1,
                                        _page_num: 1,
                                }, this.api.get)
                        }
                    }
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ResponsiveTable ui={this.props.ui} tableOnly={true} dataSource={dataSource}
                    columns={columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ emptyText: this.getText(11647) }}
                />

                <ModalLabelFormatsData
                    id={TableIDs.modalLabelFormatsData}
                    labelId={this.state.lFormatsData}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalLabelFormatsData)}
                 />


                <ModalEditLabelField
                    id={TableIDs.modalEditLabelField}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalEditLabelField)}
                 />

                <LabelPrinter
                    id={TableIDs.labelPrinter}
                    labels={[
                        { _ITEM_CODE: 1, _CUSTOMER_ID: 1, _CART_CUSTOMER_NAME: 'test1' },
                        { _ITEM_CODE: 2, _CUSTOMER_ID: 2, _CART_CUSTOMER_NAME: 'test2' },
                        { _ITEM_CODE: 3, _CUSTOMER_ID: 3, _CART_CUSTOMER_NAME: 'test3' },
                    ]}
                    qtyInput={true}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.labelPrinter)}
                    title={this.getText(11648)}
                />


            </div>
        );
    }
}

export default LabelFormats;


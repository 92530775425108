/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
import LangContext from "../../contextProvider/LangContext";
import NewOrdersTable from "./NewOrdersTable";
import PageToolbar from "../../components/PageToolbar";
import { Tooltip, Icon, Button, Form, DatePicker, Row, Col, Card, Switch, Radio, Popover, message, Checkbox } from "antd";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import moment from "moment";
import TableIDs from "../../data/TableIDs";
import { Pie } from "ant-design-pro/lib/Charts";
import TimelineChart2 from "../../components/TimelineChart";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";
import ResponsiveTable from "../../components/ResponsiveTable";
import DateTimePicker from "../../components/DateTimePicker";
import { disabledDatePicker } from "../../utils/utils"
import ReportFavoriteSave from "../../components/ReportFavoriteSave";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

class NewOrders extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            tablePage: false,
            modalFilter: false,
            modalBranchesMethod: false,
            blockBranches: '-1',
            searchBranch: '',
            viewBranch: null,
            viewBranchEditedRecord: null,
            favoriteReportDeliveryTracking: false,
            favoriteReportAreasDeliveryCost: false,
            favoriteReportOrders: false,
        }


        this.id = "EXTERNAL_ORDERS_DASHBOARD";
        this.bdm_id = "BRANCH_DELIVERY_METHOD";

        this.selectors = [
            { id: TableIDs.genericSelectorExternalOrderSuppliers, api: 'external_orders_supplier_logo_list' },
            { id: TableIDs.genericSelectorBranches, api: 'get_branch_list' },
            { id: TableIDs.genericSelectorOrderStatuses, api: 'external_orders_statuses_list' },
            { id: "EMPLOYEE_DELIVERY_LIST", api: 'employee_delivery_list' },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.bdm_id] === undefined) props.data[this.bdm_id] = { ...props.data.genericData };

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

        this.api = {
            get: "get_external_orders_dashboard",
            get_bdm: "get_branch_delivery_method_all",
            save_bdm: "save_branch_delivery_method",

            get_filter: "get_filter_favorite_data",
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI(this.api.get_filter, "_url\r" + window.location.pathname, (ob) => {
            if (ob.data) {
                let params = {}
                ob.data.split('\r').forEach(x => {
                    let y = x.split('\f');
                    params = { ...params, [y[0]]: y[1] === 'null' || y[1] === 'undefined' ? null : y[1] }
                })

                // console.log('get_report_params', params);
                this.setFilter(params);
            } else {
                this.initFilters();
            }
        })
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(14478);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            const { reportName } = this.props.ui.favoriteParams
            if (reportName.indexOf("DeliveryTracking") > -1) this.setState({ tablePage: true, favoriteReportDeliveryTracking: true })
            if (reportName.indexOf("AreasDeliveryCost") > -1) this.setState({ tablePage: true, favoriteReportAreasDeliveryCost: true })
            if (reportName.indexOf("OrdersMultiReport") > -1 || reportName.indexOf("OrderReportCentral") > -1) this.setState({ tablePage: true, favoriteReportOrders: true })
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


    initFilters = () => {
        let curdate = moment(Date.now()).format("YYYY-MM-DD");

        let request = {
            _FromDate: null,
            _ToDate: null,
            _FromDateReq: curdate,
            _ToDateReq: curdate,
            _SupplierList: '',
            _BranchList: '',
            _StatusList: '1,3,4,5,7,8,9',
            _EmployeeList: '',
        };

        this.setFilter(request);

    }

    checkFilter = () => {
        this.setFilter({
            ...this.props.data[this.id].filters,
            ...this.state.modalFilter,
        });
        this.closeFilter();
    }

    closeFilter = () => {
        this.setState({ modalFilter: false })
    }

    clearFilter = () => {
        this.setState({
            modalFilter: {
                _FromDate: null,
                _ToDate: null,
                _FromDateReq: null,
                _ToDateReq: null,
                _SupplierList: null,
                _BranchList: null,
                _StatusList: null,
                _EmployeeList: null,
            }
        })
    }

    setFilter = (request) => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    setFilterBdm = (request) => {
        this.props.dataActions.genericDataSetFilter(
            this.bdm_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_bdm);
    }

    viewBranchIsEdit = (key) => this.state.viewBranchEditedRecord && this.state.viewBranchEditedRecord.key === key;

    defaultFuture = (date) => {
        const { mAcceptFutureDateOrder, mAcceptFutureHourOrder } = this.state.viewBranchEditedRecord;
        return (mAcceptFutureDateOrder == '1' || (mAcceptFutureHourOrder == '1' && (!date || date.split(' ')[0] === moment().format('YYYY-MM-DD'))))
    }

    viewBranchDateBlock = (text, record) => {
        let obj = this.state.viewBranchEditedRecord ? this.state.viewBranchEditedRecord : {}
        const { isFuture, dateBlock } = obj;

        if (!this.viewBranchIsEdit(record.key)) {
            return text
        } else {
            return (<DateTimePicker format="DD/MM/YYYY HH:mm:ss" showTime value={dateBlock} onChange={(e) => {
                this.setState({ viewBranchEditedRecord: { ...obj, dateBlock: e, isFuture: !this.defaultFuture(e) ? '0' : isFuture } })
            }} />);
        }
    }

    viewBranchIsFuture = (text, record) => {
        let isEdit = this.viewBranchIsEdit(record.key);
        let obj = this.state.viewBranchEditedRecord ? this.state.viewBranchEditedRecord : {}
        const { isFuture, dateBlock } = obj;

        return (<Checkbox
            checked={(!isEdit && text == '1') || (isEdit && isFuture == '1')}
            disabled={!isEdit}
            onChange={(e) => {
                if (this.defaultFuture(dateBlock)) this.setState({ viewBranchEditedRecord: { isFuture: e.target.checked ? '1' : '0' } })
                else message.error(this.getText(15575))
            }}
        />);
    }

    viewBranchEdit = (text, record) => {
        if (!this.viewBranchIsEdit(record.key)) {
            return (<TableLineIcons
                genericIcons={[{
                    type: !record.dateBlock ? "unlock" : "lock",
                    onClick: () => { this.setState({ viewBranchEditedRecord: { ...record, dateBlock: '' } }) }
                }]} />)
        } else {
            return (<TableLineIcons
                onCheck={() => { this.saveEditBranchDeliveryMethod(this.state.viewBranchEditedRecord) }}
                onClose={() => { this.setState({ viewBranchEditedRecord: null }) }}
            />)
        }
    }

    saveEditBranchDeliveryMethod = (record) => {
        let mSuspendUntil = record.dateBlock;
        let mAllowFutureOrder = record.isFuture;
        let mId = record.key;
        let mBranchId = this.state.viewBranch.mBranchId;

        this.props.dataActions.genericDataSaveEditWithParams(
            this.bdm_id,
            this.props.user.companyCode,
            this.props.user.token,
            this.api.save_bdm,
            this.api.get_bdm,
            { mSuspendUntil, mAllowFutureOrder, mId });

        setTimeout(() => {
            let viewBranch = this.props.data[this.bdm_id].data.map((x, index) => {
                let y = x.split('\f');
                let obj = { key: y[0], index }
                this.props.data[this.bdm_id].headers.forEach((z, i) => { obj = { ...obj, [z]: y[i] } });
                return obj;
            }).find(f => f.mBranchId === mBranchId);
            this.loadBranchData(viewBranch)
            console.log(mBranchId, viewBranch, 'viewBranch')
        }, 1000);

    }

    loadBranchData = (record) => {
        this.setState({
            viewBranch: {
                ...record,
                mData: record.mData ?
                    record.mData.split(',').map((x, index) => {
                        let y = x.split('|');
                        return { key: y[0], name: y[1], dateBlock: y[2], isFuture: y[3], index }
                    })
                    : []
            },
            viewBranchEditedRecord: null,
        })
    }

    render() {
        let dStyle = {
            padding: 4,
            margin: 'auto',
            marginTop: 10,
            width: '100%',
        }

        let btnStyle = { marginTop: 5, marginLeft: 5 }

        let actionButtons = [{
            buttonType: 'filter',
            buttonFunction: () => { this.setState({ modalFilter: this.props.data[this.id].filters }) }
        }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        let genericActionButtons = [
            <Tooltip title={this.getText(14514)}>
                <Button style={btnStyle} onClick={() => { this.setState({ tablePage: true }) }}>
                    <Icon type={'table'} style={{ fontSize: 16 }} />
                </Button>
            </Tooltip>,
            <Tooltip title={this.getText(15704)}>
                <Button style={btnStyle} onClick={() => { this.setState({ modalBranchesMethod: true }) }}>
                    <Icon type={'cluster'} style={{ fontSize: 16 }} />
                </Button>
            </Tooltip>,
        ]

        let titleBar = (<PageToolbar
            title={this.getText(14479)}
            actionButtons={actionButtons}
            genericActionButtons={genericActionButtons}
        />);

        let source = this.props.data[this.id];

        let sumCount = 0;
        let sumTotal = 0;
        let statusOver = 0;
        let statusOpen = 0;
        let statusInProcess = 0;

        let dataPerStatus = [];
        let dataPerSupp = [];
        let dataPerHour = [];
        let suppPerHour = {};

        let sumFormat = (n) => (Math.round(parseFloat(n) * 100) / 100);
        let hourFormat = (n) => {
            let d = new Date();
            d.setHours(n, 0, 0, 0);
            return d.getTime();
        }

        source.data.forEach((x) => {
            let y = x.split('\f');
            let obj = {}
            source.headers.forEach((z, i) => {
                obj = { ...obj, [z]: y[i] }
            });
            sumCount += parseInt(obj.lCount);
            if (obj.lStatus != '2') sumTotal += sumFormat(obj.lSum)
            switch (obj.lStatus) {
                case '1': statusOpen += parseInt(obj.lCount); break;
                case '2': case '6': statusOver += parseInt(obj.lCount); break;
                default: statusInProcess += parseInt(obj.lCount); break;
            }

            let sd = dataPerStatus.findIndex(f => f.x === obj.lStatusName)
            let spd = dataPerSupp.findIndex(f => f.x === obj.lSuppName)
            let hd = dataPerHour.findIndex(f => f.x === hourFormat(obj.lHour))
            let yhd = 'y' + ((spd > -1 ? spd : dataPerSupp.length) + 1);
            let sa = this.state.perCount ? parseInt(obj.lCount) : sumFormat(obj.lSum);

            if (sd > -1) {
                dataPerStatus[sd] = { ...dataPerStatus[sd], y: sumFormat(dataPerStatus[sd].y + sa) }
            } else {
                dataPerStatus.push({ x: obj.lStatusName, y: sumFormat(sa) })
            }

            if (spd > -1) {
                dataPerSupp[spd] = { ...dataPerSupp[spd], y: sumFormat(dataPerSupp[spd].y + sa) }
            } else {
                dataPerSupp.push({ x: obj.lSuppName, y: sumFormat(sa) });
            }

            if (hd > -1) {
                dataPerHour[hd] = { ...dataPerHour[hd], [yhd]: sumFormat(dataPerHour[hd][yhd] + sa) }
            } else {
                dataPerHour.push({ x: hourFormat(obj.lHour), [yhd]: sumFormat(sa) })
            }
        });

        dataPerSupp.forEach((x, i) => {
            let k = 'y' + (i + 1);
            suppPerHour = { ...suppPerHour, [k]: x.x }
            dataPerHour = dataPerHour.map((y) => { return { ...y, [k]: y[k] ? y[k] : 0 } })
        })

        let desktop = window.innerWidth > 600

        let cardStyle = {
            textAlign: desktop ? 'center' : 'right',
            border: '1px solid blue',
            borderRadius: 10
        }

        let widthTimeLine = desktop ? 350 : window.innerWidth - 100;
        let widthHoursGraph = window.innerWidth - (desktop ? this.props.isModalAutoIphone ? 900 : 827 : 100);
        let colStyle = { padding: 20, width: widthTimeLine, display: 'inline-block', verticalAlign: 'text-top' }

        let boxStyle = (text, num) => {

            return (<Col span={desktop ? 6 : 24} style={{ padding: desktop ? 20 : 5 }}>
                <Card style={cardStyle}>
                    {desktop ?
                        (<span>
                            <div style={{ fontSize: 90 }}>{num ? num : 0}</div>
                            <div style={{ fontSize: 50 }}>{this.getText(text)}</div>
                        </span>)
                        : (<span>
                            <span style={{ fontSize: 40 }}>{num ? num : 0} </span>
                            <span style={{ fontSize: 30 }}>{this.getText(text)}</span>
                        </span>)}
                </Card>
            </Col>)
        }

        let pieStyle = (text, data) => {
            console.log('pie data', data)
            return (<div style={colStyle}>
                <Card style={{ textAlign: 'center', borderRadius: 10 }} bodyStyle={{ padding: 24, direction: "ltr" }}  >
                    <Pie hasLegend subTitle={this.getText(text)} data={data}
                        valueFormat={value => <div>{value}</div>}
                        height={270}
                        lineWidth={4}
                        style={{ padding: '8px 0' }} />
                </Card>
            </div>)
        }

        let hourGraphStyle = (<div style={{ ...colStyle, textAlign: 'center', width: widthHoursGraph }}>
            <Card style={cardStyle}>
                <span style={{ fontSize: 50, paddingLeft: 25 }}>{this.getText(14525)}</span>
                <br />
                <span style={{ fontSize: desktop ? 90 : '10vw' }}>{sumTotal ? sumFormat(sumTotal).toFixed(2) : 0.00} ₪</span>
            </Card>
            <Switch
                style={{ margin: 20 }}
                checkedChildren={this.getText(14522)}
                unCheckedChildren={this.getText(14523)}
                onChange={(v) => { this.setState({ perCount: v }) }}
                checked={this.state.perCount}
            />
            <div>{this.getText(14524)}</div>
            <Card style={{ borderRadius: 10 }}>
                <TimelineChart2
                    height={widthHoursGraph * (desktop ? 0.3 : 0.6)}
                    data={dataPerHour}
                    yCount={dataPerSupp.length}
                    titleMap={suppPerHour}
                />
            </Card>
        </div>)


        let mf = this.state.modalFilter;
        let onChange = (f, v) => { this.setState({ modalFilter: { ...mf, [f]: v } }) }
        let formItem = (label, content) => (<Form.Item label={this.getText(label)}>{content}</Form.Item>);
        let datePicker = (field) => {
            let minDate = field == '_ToDate' ? mf._FromDate : field == '_ToDateReq' ? mf._FromDateReq : null
            let maxDate = field == '_FromDate' ? mf._ToDate : field == '_FromDateReq' ? mf._ToDateReq : null
            return (<DatePicker
                format="DD/MM/YYYY"
                value={mf[field] ? moment(mf[field], "YYYY-MM-DD") : null}
                onChange={(v) => { onChange(field, v ? v.format("YYYY-MM-DD") : null) }}
                disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
            />)
        }

        let genericSelector = (field, index) => {
            let s = this.selectors[index];
            return (<GenericSelector
                id={s.id} api={s.api} multi
                value={mf[field] ? mf[field].split(',') : []}
                onChange={(v) => { onChange(field, v ? v.join(',') : '') }}
                data={this.props.data} dataActions={this.props.dataActions} user={this.props.user}
            />)
        }

        let bdmActionButtons = [
            <Popover
                content={(<Radio.Group
                    value={this.props.data[this.bdm_id].filters._blockBranches}
                    onChange={(e) => { this.setFilterBdm({ _blockBranches: e.target.value }) }}>
                    <Radio value={'0'}>{this.getText(15700)}</Radio>
                    <Radio value={'1'}>{this.getText(15701)}</Radio>
                    <Radio value={'2'}>{this.getText(15702)}</Radio>
                </Radio.Group>)}
                title={this.getText(15699)}
                placement="bottomLeft"
                trigger="click">
                <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                    <Icon type={'filter'} style={{ fontSize: "16px", }} />
                </Button>
            </Popover>
        ];

        let dashboardOrders = [
            <Row>
                {boxStyle(14516, sumCount)}
                {boxStyle(14517, statusOver)}
                {boxStyle(14518, statusOpen)}
                {boxStyle(14519, statusInProcess)}
            </Row>,
            <div style={{ width: window.innerWidth > 800 ? 800 : '95%', margin: 'auto' }}>

            </div>,
            <div>
                {desktop ? "" : hourGraphStyle}
                {pieStyle(14520, dataPerStatus)}
                {desktop ? hourGraphStyle : ""}
                {pieStyle(14521, dataPerSupp)}
            </div>
        ];



        console.log('new orders dashboard', dataPerHour, dataPerSupp.length, suppPerHour)

        return this.state.tablePage ?
            (<NewOrdersTable
                baseUrl={this.props.baseUrl}
                user={this.props.user}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                data={this.props.data}
                dataActions={this.props.dataActions}
                ActionQueue={this.props.ActionQueue}
                backPage={() => { this.setState({ tablePage: false }) }}
                filters={this.props.data[this.id].filters}
                onFilter={this.setFilter}
                isModalAutoIphone={this.props.isModalAutoIphone}
                onCancel={this.props.onCancel}
                favoriteReportDeliveryTracking={this.state.favoriteReportDeliveryTracking}
                favoriteReportAreasDeliveryCost={this.state.favoriteReportAreasDeliveryCost}
                favoriteReportOrders={this.state.favoriteReportOrders}
            />)
            : (<div style={dStyle}>
                {this.props.isModalAutoIphone ? "" : titleBar}
                {this.props.isModalAutoIphone ?
                    (<GenericModal
                        visible
                        onCancel={this.props.onCancel}
                        title={this.getText(14479)}
                        actionButtons={actionButtons}
                        genericActionButtons={genericActionButtons}
                    >{dashboardOrders}</GenericModal>)
                    : dashboardOrders}

                <GenericModal visible={mf} onCancel={this.closeFilter} title={this.getText(14505)} width={1000}
                    footer={[
                        <Button key="back" onClick={this.closeFilter}>{this.getText(14506)}</Button>,
                        <Button key="clear" onClick={this.clearFilter}>{this.getText(14507)}</Button>,
                        <Button key="create" type="primary" onClick={this.checkFilter}>{this.getText(14508)}</Button>,
                        <ReportFavoriteSave
                            {...this.props}
                            btnText={this.getText(19267)}
                            isNotReport
                            params={mf}
                            datesToFavorites={[
                                { label: this.getText(14509), field: '_FromDate' },
                                { label: this.getText(14510), field: '_ToDate' },
                                { label: this.getText(15378), field: '_FromDateReq' },
                                { label: this.getText(15379), field: '_ToDateReq' },
                            ]}
                        />
                    ]}>
                    <Row>
                        <Col span={window.innerWidth > 1000 ? 6 : 24} style={{ padding: 5 }}>
                            {formItem(14509, datePicker('_FromDate'))}
                            {formItem(14510, datePicker('_ToDate'))}
                        </Col>
                        <Col span={window.innerWidth > 1000 ? 6 : 24} style={{ padding: 5 }}>
                            {formItem(15378, datePicker('_FromDateReq'))}
                            {formItem(15379, datePicker('_ToDateReq'))}
                        </Col>
                        <Col span={window.innerWidth > 1000 ? 12 : 24} style={{ padding: 5 }}>
                            {formItem(14511, genericSelector('_SupplierList', 0))}
                            {formItem(14512, genericSelector('_BranchList', 1))}
                            {formItem(14513, genericSelector('_StatusList', 2))}
                            {formItem(19365, genericSelector('_EmployeeList', 3))}
                        </Col>
                    </Row>
                </GenericModal>
                <GenericModal
                    visible={this.state.modalBranchesMethod}
                    onCancel={() => { this.setState({ modalBranchesMethod: false }) }}
                    title={this.getText(15705)}
                    genericActionButtons={bdmActionButtons}
                    showsearch={(value) => {
                        this.setFilterBdm({
                            _words: value.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                            page: 1,
                            _page_num: 1,
                        })
                    }}
                    width={500}>
                    {this.state.modalBranchesMethod ? (
                        <GenericTable
                            tableOnly
                            id={this.bdm_id}
                            getApi={this.api.get_bdm}
                            filters={{ _blockBranches: '2' }}
                            columns={[
                                { title: this.getText(15698), key: 'mBranchId', dataIndex: 'mBranchId', width: '30%', render: t => t },
                                { title: this.getText(15572), key: 'mBranchName', dataIndex: 'mBranchName', width: '70%', render: t => t },
                            ]}
                            rowClassName={(r) => r.mIssetBlock == '0' ? 'row_greenyellow' : 'row_pink'}
                            onRowClick={this.loadBranchData}
                            {...this.props} />
                    ) : ''}
                </GenericModal>
                <GenericModal
                    visible={this.state.viewBranch !== null}
                    onCancel={() => { this.setState({ viewBranch: null }) }}
                    title={this.getText(15706) + (this.state.viewBranch ? ' ' + this.state.viewBranch.mBranchName : '')}
                    width={600}>
                    {this.state.viewBranch ? (
                        <ResponsiveTable
                            tableOnly={true}
                            dataSource={this.state.viewBranch.mData}
                            columns={[
                                { title: this.getText(15707), key: 'name', dataIndex: 'name', width: '40%', render: t => t },
                                { title: this.getText(15708), key: 'dateBlock', dataIndex: 'dateBlock', width: '30%', render: this.viewBranchDateBlock },
                                { title: this.getText(15709), key: 'isFuture', dataIndex: 'isFuture', width: '15%', render: this.viewBranchIsFuture },
                                { title: '', width: '5%', render: this.viewBranchEdit },
                            ]}
                            rowClassName={(r) => !r.dateBlock ? 'row_greenyellow' : 'row_pink'}
                            ui={this.props.ui}
                            pagination={{ pageSize: 10 }}
                        />
                    ) : ''}
                </GenericModal>
            </div>)
    }
}

export default NewOrders;
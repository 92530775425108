/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Select, InputNumber, Popconfirm, Input, Tooltip } from "antd";
import ResponsiveTable from "../../components/ResponsiveTable";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
// import ModalCreatePriceList from "../../components/ModalCreatePriceList";
// import ModalCreatePrice from "../../components/ModalCreatePrice";
// import ModalCreateEditEmployee from "../../components/ModalCreateEditEmployee";
// import ModalCreateEditEmployeeGroup from "../../components/ModalCreateEditEmployeeGroup";
import ModalCreateEditStorages from "../../components/ModalCreateEditStorages";
import { fixCurrentPage } from "../../utils/utils.js"
import LangContext from "../../contextProvider/LangContext";
import RecyclingBin from "../../components/RecyclingBin";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 36;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class StorageTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "STORAGE_MAIN";
        this.recycling_id = "STORAGE_DELETION_RECYCLING";
        this.locations_id = "STORAGE_LOCATIONS";

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            height: window.innerHeight,

            modalLocations: null,
        };


        this.doOnResize = null;

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.recycling_id] === undefined) props.data[this.recycling_id] = { ...props.data.genericData };
        if (props.data[this.locations_id] === undefined) props.data[this.locations_id] = { ...props.data.genericData };


        if (props.data[TableIDs.genericSelectorBranches] === undefined) {
            props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_storage_list_table",

            create: "create_storage",
            edit: "edit_storage",
            delete: "delete_storage",

            selector: "get_branch_list",
            //modalPriceListGet: "Prices_List_v2",

            get_recycling: "get_storage_deletion_recycling",
            restoration: "restoration_storage",

            get_locations: "get_storage_locations",
            edit_location: "create_edit_storage_location",
            delete_location: "delete_storage_location",
            order_locations: "order_storage_locations",

        }

        this.actionButtons = [
            {
                buttonType: 'add',
                //buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalCreateEditEmployee) }
                //buttonFunction: this.handleCreateNew
                buttonFunction: () => this.createEditModal(0)
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //  document.title = "Cash On Tab - עובדים";
        //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10218);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        console.log("kkkk initfilter - num rows: ", numOfRowsByHeight, " h: ", this.state.height)

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorBranches, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        // console.log("storage - handle table change: ",
        //     JSON.stringify(pagination), "\n",
        //     JSON.stringify(filters), "\n",
        //     JSON.stringify(sorter));

        let col = 5;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mID": col--;
            case "mName": col--;
            case "mBranchID": col--;
            case "mTransitNumber": col--;
            case "mWasterNumber": break; // 5
            default:
                col = 0;
        }
        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //col
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    createEditModal = (id) => {
        this.props.uiActions.showModal(TableIDs.modalCreateEditStorages, { sID: id });
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key) ? this.props.data[this.id].editedRecord[col] : text;
        return checkedVal == '1'
    }

    editedLocations = (text, record) => {
        const { editing } = this.props.data[this.locations_id];
        const gt = this.getText;

        if (record.key == editing) {
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.locations_id, this.props.user.companyCode, this.props.user.token,
                        this.api.edit_location, this.api.get_locations,
                        { mStorage: this.state.modalLocations.key })
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.locations_id);
                    else this.props.dataActions.genericDataCancelEditing(this.locations_id);
                }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.locations_id, record.key, record.index) }}
                onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.locations_id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete_location, this.api.get_locations) }}
                deleteQuetion={{ title: gt(18176), okText: gt(18177), cancelText: gt(18178) }}
            />);
        }

    }

    nameLocation = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.locations_id];
        if (record.key == editing) {
            return (<Input
                value={editedRecord.name}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.locations_id, { name: e.target.value }) }}
            />)
        } else {
            return text
        }

    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mID: y[0],
                mName: y[1],
                mBranchID: y[2],
                mTransitNumber: y[3],
                mWasterNumber: y[4],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        const render = t => t;
        const sorter = true;
        const gt = this.getText;

        const renderBranch = text => {
            let list = this.props.data[TableIDs.genericSelectorBranches].dataset.map((obj, index) => {
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            });

            return (<Select showsearch filterOption={true} optionFilterProp="valuefilter" placeholder={gt(11455)} disabled
                value={text} defaultValue="" style={{ width: '100%', paddingLeft: '4px' }}  >{list}</Select>)
        }

        const renderEdit = (text, record) => {
            return (
                <span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                        // this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                        this.createEditModal(parseInt(record.key));
                    }} />
                    <Popconfirm title={this.getText(11452)}
                        onConfirm={() => {
                            console.log("record", record);
                            fixCurrentPage.bind(this)();
                            this.props.dataActions.genericDataDeleteRow(
                                this.id,
                                record['mID'],
                                this.props.user.companyCode,
                                this.props.user.token,
                                this.api.delete,
                                this.api.get);
                        }
                        }
                        okText={this.getText(11453)} cancelText={this.getText(11454)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                    </Popconfirm>
                    <Tooltip title={this.getText(19151)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => {
                            this.setState({ modalLocations: record }, () => {
                                this.props.dataActions.genericDataSetFilter(
                                    this.locations_id, this.props.user.companyCode, this.props.user.token, { mStorage: record.key }, this.api.get_locations);
                            })
                        }} />
                    </Tooltip>
                </span >);
        }

        let columns = [
            { title: gt(11448), dataIndex: "mID", key: "mID", width: '10%', sorter, render },
            { title: gt(11449), dataIndex: "mName", key: "mName", width: '15%', sorter, render },
            { title: gt(11450), dataIndex: "mBranchID", key: "mBranchID", width: '25%', sorter, render: renderBranch },
            { title: gt(16075), dataIndex: "mTransitNumber", key: "mTransitNumber", width: '15%', sorter, render },
            { title: gt(19181), dataIndex: "mWasterNumber", key: "mWasterNumber", width: '15%', sorter, render },
            { title: gt(11451), width: '10%', render: renderEdit }
        ];

        //console.log("kkkk render: ", this.state.height, " pg: ", this.props.data[this.id].pagination)
        return (
            <div style={divStyle}>
                <PageToolbar
                    title={this.getText(11447)}
                    actionButtons={this.actionButtons} F
                    genericActionButtons={[
                        <RecyclingBin
                            id={this.recycling_id}
                            getApi={this.api.get_recycling}
                            restorationApi={this.api.restoration}
                            title={this.getText(18924)}
                            columnTitles={[this.getText(18925), this.getText(18926), this.getText(18927), this.getText(18932), this.getText(18928)]}
                            popconfirmText={{ title: this.getText(18929), okText: this.getText(18930), cancelText: this.getText(18931) }}
                            onClose={this.refreshData}
                            {...this.props}
                        />
                    ]}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />
                <ResponsiveTable
                    tableOnly={true}
                    percentageToMobile={[20, 40, 40]}
                    ui={this.props.ui}
                    idTable={this.props.data[this.id]}
                    dataSource={dataSource}
                    columns={columns}
                    onChange={this.handleTableChange}
                />
                {/* <Table
                    dataSource={dataSource}
                    columns={this.columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                />
                
                <ModalCreateEditEmployee
                    id={TableIDs.modalCreateEditEmployee}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditEmployee)}
                    title={"יצירת/עריכת מחסן"}
                />

                <ModalCreateEditEmployeeGroup
                    id={TableIDs.modalCreateEditEmployeeGroup}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditEmployeeGroup)}
                    title={"יצירת/עריכת קבוצת עובדים"}
                /> */}

                <ModalCreateEditStorages
                    id={TableIDs.modalCreateEditStorages}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditStorages)}
                //  title={"יצירת/עריכת מחסן"}
                />
                <GenericModal
                    visible={this.state.modalLocations != null}
                    onCancel={() => { this.setState({ modalLocations: null }) }}
                    width={600}
                    title={this.getText(19152) + " " + (this.state.modalLocations ? this.state.modalLocations.mName : "")}
                    actionButtons={[
                        {
                            buttonType: 'add',
                            buttonFunction: () => {
                                if (this.props.data[this.locations_id].editing === -1) {
                                    this.props.dataActions.genericDataStartInlineCreate(this.locations_id);
                                }
                            }
                        }, {
                            buttonType: 'refresh',
                            buttonFunction: () => {
                                this.props.dataActions.genericDataGetRequested(
                                    this.locations_id, this.props.user.companyCode, this.props.user.token, this.api.get_locations);
                            }
                        }]}>
                    <ResponsiveTable
                        {...this.props}
                        tableOnly
                        idTable={this.props.data[this.locations_id]}
                        columns={[
                            { title: this.getText(19161), width: "80%", key: "name", dataIndex: "name", render: this.nameLocation },
                            { title: this.getText(19162), width: "20%", render: this.editedLocations },
                        ]}
                        id={this.locations_id}
                        onDragAndDrop={(keyFrom, keyTo) => {
                            this.props.dataActions.genericDataSaveEditWithParams(
                                this.locations_id, this.props.user.companyCode, this.props.user.token,
                                this.api.order_locations, this.api.get_locations,
                                { _storage: this.state.modalLocations.key, _id: keyFrom, _toId: keyTo })
                        }}
                    />
                </GenericModal>
            </div>
        );
    }
}

export default StorageTable;

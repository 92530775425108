/* @flow */
/*jshint esversion: 6 */

import React from "react";
import { Input, Icon, Modal, message, Button } from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";

class ModalPriceListItemView extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            visible: false,
        }

        this.api = {
            get: "get_prices_by_item",
            get_list: "get_prices",
        }

        this.id = this.props.id;
        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorPriceListd] === undefined) props.data[TableIDs.genericSelectorPriceList] = { ...props.data.genericSelector };

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPriceList,
            this.props.user.companyCode,
            this.props.user.token,
            this.api.get_list);

    }

    static contextType = LangContext;
    getText = (id) => this.context.get(id) || '[' + id + ']';

    componentDidUpdate(p) {
        let prevItem = p.ui.data ? p.ui.data['itemCode'] : null;
        let mItemCode = this.props.ui.data ? this.props.ui.data['itemCode'] : null;
        if (prevItem !== mItemCode) {
            this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, { mItemCode }, this.api.get);
        }
    }

    handleCancel = () => {
        this.setState({ visible: false }, () => {
            this.props.uiActions.hideModal(TableIDs.modalPriceListItemView);
        })
    }

    getPriceListName = (id) => {
        let list = this.props.data[TableIDs.genericSelectorPriceList].dataset;
        let res = '';
        list.map((x) => { if (x.code === id) res = x.name });
        return res;
    }

    render() {


        let mTitle = this.getText(12691) + " - " + (this.props.ui.data ? this.props.ui.data['itemName'] : "")

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={mTitle}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let mobile = window.innerWidth <= 600 ? true : false;

        let columns = [
            {
                title: this.getText(12692),
                dataIndex: "mPriceListId",
                key: "mPriceListId",
                width: '20%',
                render: (text, record) => text
            }, {
                title: this.getText(12693),
                dataIndex: "mPriceListId",
                key: "mPriceListId",
                width: '40%',
                render: (text, record) => this.getPriceListName(text)
            }, {
                title: this.getText(12694),
                dataIndex: "mPriceVal",
                key: "mPriceVal",
                width: '40%',
                render: (text, record) => text
            },
        ]

        return (
            <Modal
                destroyOnClose={true} width={mobile ? '95%' : "40%"} visible={false || this.props.toggle}
                title={titleBar} onCancel={this.handleCancel}
                ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataAction={this.props.dataAction}
                footer={false}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    tableOnly={true}
                    pagination={false}
                    scroll={{ y: 300 }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
            </Modal >)
    }
}

export default ModalPriceListItemView;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../App.css";
import { Icon, Popconfirm, Button, Tooltip, Modal, message, Checkbox, InputNumber, Row } from "antd";
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";
import { Col } from "antd/es/grid";
import GenericSelector from "./GenericSelector";

class ModalEnglishDocPrice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemCode: null,
            description: null,
            costCurrencyId: null,
            costCurrencySymbol: null,
            costCurrencyRate: null,
            costCurrencyValue: null,
            unitPriceNoTax: null,
        }

        if (props.data.CURRENCY_SELECTOR === undefined) props.data.CURRENCY_SELECTOR = { ...props.data.genericSelector }
        if (props.data.LAST_CURRENCY_RATES === undefined) props.data.LAST_CURRENCY_RATES = { ...props.data.genericData }

        this.costCurrencyValueFocus = null;

    }


    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) {
            const { itemCode, description, costCurrencyId, costCurrencySymbol, costCurrencyRate, costCurrencyValue, unitPriceNoTax } = this.props.editedRecord
            this.setState({ itemCode, description, costCurrencyId, costCurrencySymbol, costCurrencyRate, costCurrencyValue, unitPriceNoTax }, () => {
                clearTimeout(this.costCurrencyValueFocus);
                this.costCurrencyValueFocus = setTimeout(() => { document.getElementById("costCurrencyValue").select() }, 1000)
            })
        }
    }

    handleOk = () => {
        const { unitPriceNoTax } = this.state;
        const { quantity, discountPercentage } = this.props.editedRecord;
        this.props.onChange({
            ...this.props.editedRecord,
            ...this.state,
            unitPriceWithTax: unitPriceNoTax,
            totalSumWithTax: (parseFloat(unitPriceNoTax) * quantity * (1 - discountPercentage / 100)).toFixed(4),
            totalSumNoTax: (parseFloat(unitPriceNoTax) * quantity * (1 - discountPercentage / 100)).toFixed(4),
        })
        this.props.onCancel()
    }

    getMultiplication = (num1, num2) => {
        return (Math.round((num1 ? num1 : 1) * (num2 ? num2 : 1) * 100) / 100).toFixed(2)
    }

    onChangeCurrency = (e) => {
        let dataFind = this.props.data.LAST_CURRENCY_RATES.data?.map(x => x.split("\f")).find(f => f[0] == e)
        let costCurrencySymbol = dataFind ? dataFind[4] : null;
        let costCurrencyRate = dataFind ? dataFind[2] : null;
        this.setState({
            costCurrencyId: e,
            costCurrencySymbol,
            costCurrencyRate,
            unitPriceNoTax: this.getMultiplication(this.state.costCurrencyValue, costCurrencyRate)
        })
    }

    onChangeRate = (e) => {
        this.setState({
            costCurrencyRate: e,
            unitPriceNoTax: this.getMultiplication(this.state.costCurrencyValue, e)
        })
    }

    onChangeCurrValue = (e) => {
        this.setState({
            costCurrencyValue: e,
            unitPriceNoTax: this.getMultiplication(e, this.state.costCurrencyRate)
        })
    }

    onChangeNisValue = (e) => {
        this.setState({
            unitPriceNoTax: e,
            costCurrencyValue: this.getMultiplication(e, 1 / (this.state.costCurrencyRate ?? 1))
        })
    }

    render() {
        const { visible, onCancel } = this.props;
        const { itemCode, description, costCurrencyId, costCurrencyRate, costCurrencyValue, unitPriceNoTax } = this.state

        const labelStyle = { padding: 5, textAlign: "left" }
        const fieldStyle = { padding: 5, textAlign: "right" }

        const currencySelector = (<GenericSelector {...this.props} id={"CURRENCY_SELECTOR"} value={costCurrencyId} onChange={this.onChangeCurrency} />)
        const rateInput = (<InputNumber value={costCurrencyRate} onChange={this.onChangeRate} precision={2} min={0} />)
        const currValueInput = (<InputNumber id={"costCurrencyValue"} value={costCurrencyValue} onChange={this.onChangeCurrValue} precision={2} />)
        const nisValueInput = (<InputNumber value={unitPriceNoTax} onChange={this.onChangeNisValue} precision={2} />)

        return (<GenericModal {...{ visible, onCancel }}
            title={this.getText(20181)}
            width={500}
            footer={[
                <Button key="back" onClick={onCancel}>{this.getText(20188)}</Button>,
                <Button key="create" type="primary" onClick={this.handleOk}>{this.getText(20189)}</Button>,
            ]}>
            <Row><Col span={8} style={labelStyle}>{this.getText(20182)}:</Col><Col span={16} style={fieldStyle}><b>{itemCode}</b></Col></Row>
            <Row><Col span={8} style={labelStyle}>{this.getText(20183)}:</Col><Col span={16} style={fieldStyle}><b>{description}</b></Col></Row>
            <Row><Col span={8} style={labelStyle}>{this.getText(20184)}:</Col><Col span={16} style={fieldStyle}>{currencySelector}</Col></Row>
            <Row><Col span={8} style={labelStyle}>{this.getText(20185)}:</Col><Col span={16} style={fieldStyle}>{rateInput}</Col></Row>
            <Row><Col span={8} style={labelStyle}>{this.getText(20186)}:</Col><Col span={16} style={fieldStyle}>{currValueInput}</Col></Row>
            <Row><Col span={8} style={labelStyle}>{this.getText(20187)}:</Col><Col span={16} style={fieldStyle}>{nisValueInput}</Col></Row>

        </GenericModal>)
    }
}

export default ModalEnglishDocPrice;

import React from "react";
import ResponsiveTable from "./ResponsiveTable";
import { escapeRegExp } from "../utils/utils";
import GenericModal from "./GenericModal";

type Props = {
    id: String,
    getApi: String,
    title: String,
    filters: Object,
    columns: Array,
    actionButtons: Array,
    genericActionButtons: Array,
    percentageToMobile: Array,
    searchBox: Boolean,
    scroll: Object,
    pagination: Object,
    tableOnly: Boolean,
    visible: Boolean,
    rowHeight: Number,
    topHeight: Number,
    addButton: Function,
    keyIndex: Number,
    setFilterActive: Function,
    valueSearch: String,
    onChangeSearch: Function,

    data: Object,
    dataActions: Object,
    ui: Object,
    uiActions: Object,
    user: Object,
}

class GenericTable extends React.Component<Props, State> {

    state = { height: window.innerHeight }

    doOnResize = null;

    componentDidMount() {
        this.initFilter();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilter);
    };

    initFilter = () => {
        let rowHeight = this.props.rowHeight ? this.props.rowHeight : 30;
        let topHeight = this.props.topHeight ? this.props.topHeight : 340;
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight) ? (this.state.height - topHeight) / rowHeight : 1);

        let request = {
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            //filters
            ...this.props.filters

        };
        this.props.dataActions.genericDataSetFilter(
            this.props.id, this.props.user.companyCode, this.props.user.token, request, this.props.getApi);


        if (this.props.onChangeSearch) this.props.onChangeSearch({ target: { value: "" } })
        if (this.props.setFilterActive) this.props.setFilterActive(true)
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.props.id, this.props.user.companyCode, this.props.user.token, this.props.getApi);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let sorterCols = this.props.columns.filter(f => f.sorter);
        let col = sorter.field === 'key' ? 0 : sorterCols.findIndex(f => f.key === sorter.field) + 1;

        let _isAscDefault = this.props.filters && this.props.filters._isAsc !== undefined ? this.props.filters._isAsc : 1;
        let _isAsc = sorter.order === undefined ? _isAscDefault : sorter.order === "ascend" ? 1 : 0
        // this.props.filters && this.props.filters._isAsc ? this.props.filters._isAsc : 1;
        // if(sorter.order !== undefined){
        //     _isAsc =
        // }
        console.log("sorter.order", sorter.order, _isAscDefault, _isAsc, this.props.filters)


        let request = {
            ...this.props.data[this.props.id].filters,
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: _isAsc,
            sortColumn: col,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: _isAsc,
            _sort_by: 'col_' + col,
        };

        this.props.dataActions.genericDataSetFilter(
            this.props.id, this.props.user.companyCode, this.props.user.token, request, this.props.getApi)
    }

    showsearch = (val) => {
        this.props.dataActions.genericDataSetFilter(
            this.props.id,
            this.props.user.companyCode,
            this.props.user.token,
            {
                ...this.props.data[this.props.id].filters,
                page: 1,
                _page_num: 1,
                _words: escapeRegExp(val),
                // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
            },
            this.props.getApi);

        if (val && this.props.setFilterActive) this.props.setFilterActive(true)
    }

    render() {

        let actionButtons = [{ buttonType: 'refresh', buttonFunction: this.refreshData }]

        if (this.props.addButton) actionButtons = [{ buttonType: 'add', buttonFunction: this.props.addButton }, ...actionButtons];

        if (this.props.actionButtons) actionButtons = [...this.props.actionButtons, ...actionButtons];

        let props = {
            idTable: this.props.data[this.props.id],
            columns: this.props.columns,
            percentageToMobile: this.props.percentageToMobile,
            title: this.props.title,
            tableOnly: this.props.tableOnly,
            actionButtons: actionButtons,
            genericActionButtons: this.props.genericActionButtons,
            ui: this.props.ui,
            uiActions: this.props.uiActions,
            onRowClick: this.props.onRowClick,
            rowClassName: this.props.rowClassName,
            onExpand: this.props.onExpand,
            moreDetail: this.props.moreDetail,
            keyIndex: this.props.keyIndex,
            rowsFilter: this.props.rowsFilter,
           }

        if (this.props.searchBox) {
            props = {
                ...props,
                showsearch: this.showsearch,
                valueSearch: this.props.valueSearch,
                onChangeSearch: this.props.onChangeSearch,
            }
        }

        if (this.props.pagination === false) {
            props = {
                ...props,
                pagination: false
            }
        } else if (this.props.scroll && !this.props.scroll.y && !this.props.pagination) {
            props = {
                ...props,
                scroll: this.props.scroll,
                onChange: this.handleTableChange,
            }
        } else if (this.props.scroll) {
            props = {
                ...props,
                scroll: this.props.scroll,
                pagination: false
            }
        } else if (this.props.pagination) {
            props = {
                ...props,
                pagination: this.props.pagination,
            }
        } else {
            props = {
                ...props,
                onChange: this.handleTableChange,
            }
        }

        return (<ResponsiveTable {...props} />)
    }

}

export default GenericTable;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Form, Select, Checkbox, DatePicker } from 'antd';
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { MonthPicker } = DatePicker;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class PaymentMonthlyReport extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            PaymentMonthly: {
                PaymentType: '',
                StorageList: '',
                //ItemTagList: '',
                EmployeeList: '',
                PosList: '',
                LowerMonthSelection: null,
                LowerYearSelection: null,
                UpperMonthSelection: null,
                UpperYearSelection: null,
                reportName: "reports/paymentsMonthly.pdf",
            }
        };

        this.state.type = "pdf";
        this.state.RequestedReport = "Tags"; // Tags // Suppliers

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        //if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
        //    props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        //}
        //if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
        //    props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        //}
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
            props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        }
        //if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
        //    props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        //}
        if (props.data[TableIDs.genericSelectorPaymentTypes] === undefined) { //////
            props.data[TableIDs.genericSelectorPaymentTypes] = { ...props.data.genericSelector };
        }
        //if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
        //    props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        //}

        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
            selector_payment_types: "get_payment_types_list",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        //this.props.dataActions.genericMultiSelectorRefreshDataset(
        //    TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes);//
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group); //
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPaymentTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_payment_types); //

        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.PosList === '') params = { ...params, PosList: fullPosList }
        if (params.StorageList === '') params = { ...params, StorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10190);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            posList && posList.dataset[0]
            &&
            storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("PaymentMonthly", this.props.ui.favoriteParams)
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
        //this.props.ActionQueue.addToQueue({
        //    action: this.props.dataActions.genericMultiSelectorRefreshDataset,
        //    args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        //});
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPaymentTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_payment_types]
        });
        //this.props.ActionQueue.addToQueue({
        //    action: this.props.dataActions.genericSelectorRefreshDataset,
        //    args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        //});
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.PaymentMonthly.LowerYearSelection;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.PaymentMonthly.UpperYearSelection;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    onChange = (field, value, field2, value2) => {
        console.log("f ", field, "v ", value);
        this.setReportParameters("PaymentMonthly", { [field]: value, [field2]: value2 })
        //this.setState({
        //    [field]: value,
        //});
    }

    onStartChange = (value) => {
        let arr = (value ? value.format("YYYY-MM") : '').split('-');;
        console.log("start date ", arr);
        this.onChange('LowerYearSelection', arr[0], 'LowerMonthSelection', arr[1]);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        let arr = (value ? value.format("YYYY-MM") : '').split('-');
        console.log("end date ", arr);
        this.onChange('UpperMonthSelection', arr[1], 'UpperYearSelection', arr[0]);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/
    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("PaymentMonthly", { [field]: data });
                    else
                        this.setReportParameters("PaymentMonthly", { [field]: '' });
                }} />);
    }

    render() {
        const { PaymentMonthly, endOpen } = this.state;
        const {
            LowerMonthSelection,
            LowerYearSelection,
            UpperMonthSelection,
            UpperYearSelection,
        } = PaymentMonthly;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { PaymentMonthly } = this.state;
        const isMinPercShownStyle = { display: (PaymentMonthly.RequestedReport === 5 || PaymentMonthly.RequestedReport === 6) ? 'block' : 'none' }

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listEmployee = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listItemGroup = Jax.get(this.props.data, TableIDs.genericSelectorItemGroup + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listPayments = Jax.get(this.props.data, TableIDs.genericSelectorPaymentTypes + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let mobile = window.innerWidth > 600 ? false : true;



        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(12166)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '60%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '2', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >

                        <FormItem label={this.getText(12167)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                            <MonthPicker
                                //disabledDate={this.disabledStartDate}
                                // showTime
                                format="MM/YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={LowerMonthSelection && LowerYearSelection ? moment(LowerMonthSelection + LowerYearSelection, "MMYYYY") : null}
                                placeholder={this.getText(12178)}
                                onChange={this.onStartChange}
                            //onOpenChange={this.handleStartOpenChange}
                            />

                        </FormItem>
                        <FormItem label={this.getText(12168)} /*style={isEndDateShownStyle}*/>
                            <MonthPicker
                                //disabledDate={this.disabledEndDate}
                                // showTime
                                format="MM/YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={UpperMonthSelection && UpperYearSelection ? moment(UpperMonthSelection + UpperYearSelection, "MMYYYY") : null}
                                placeholder={this.getText(12179)}
                                onChange={this.onEndChange}
                            //open={endOpen}
                            //onOpenChange={this.handleEndOpenChange}
                            />
                        </FormItem>

                        <FormItem label={this.getText(12169)}>
                            <GenericSelectorPosStorages  {...this.props} multi
                                onChange={(value, typeList) => this.setReportParameters("PaymentMonthly", { PosList: value.join(','), StorageList: typeList.join(',') })}
                                value={(this.state.PaymentMonthly.PosList === '') ? [] : this.state.PaymentMonthly.PosList.split(',')}
                            />
                            {/* {this.createCheckBox('PosList', Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"PosList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("PaymentMonthly", { PosList: value.join(',') })}
                                value={(this.state.PaymentMonthly.PosList === '') ? [] : this.state.PaymentMonthly.PosList.split(',')}
                            >
                                {listPos}
                            </Select> */}
                        </FormItem>

                        {/* <FormItem label={this.getText(12170)}>
                            {this.createCheckBox('StorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"StorageList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("PaymentMonthly", { StorageList: value.join(',') })}
                                value={(this.state.PaymentMonthly.StorageList === '') ? [] : this.state.PaymentMonthly.StorageList.split(',')}
                            >
                                {listStorage}
                            </Select>
                        </FormItem> */}

                        <FormItem label={this.getText(12171)}>
                            {this.createCheckBox('PaymentType', Jax.get(this.props.data, TableIDs.genericSelectorPaymentTypes + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"PaymentType"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("PaymentMonthly", { PaymentType: value.join(',') })}
                                value={(this.state.PaymentMonthly.PaymentType === '') ? [] : this.state.PaymentMonthly.PaymentType.split(',')}
                            >
                                {listPayments}
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(12172)} style={{ width: "100%" }}>
                            {this.createCheckBox('EmployeeList', Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"EmployeeList"} style={{ width: "90%" }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("PaymentMonthly", { EmployeeList: value.join(',') })}
                                value={(this.state.PaymentMonthly.EmployeeList === '') ? [] : this.state.PaymentMonthly.EmployeeList.split(',')}
                            >
                                {listEmployee}
                            </Select>
                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.PaymentMonthly}
                            storagesField={'StorageList'}
                            posField={'PosList'}
                            datesToFavorites={[
                                { fields: ['LowerMonthSelection', 'LowerYearSelection'], label: this.getText(12167), format: 2 },
                                { fields: ['UpperMonthSelection', 'UpperYearSelection'], label: this.getText(12168), format: 2 },
                            ]}
                            favoriteSave
                            xlsReportName={"reports/paymentsMonthlyExcel.xls"}
                            />

                        {/* <FormItem key={"reportName"} label={this.getText(12173)}>
                            <Select style={{ width: 250 }}
                                //size={"large"}
                                className={"reports/PaymentMonthly.pdf"}
                                //combobox={null}
                                defaultValue={"pdf"}
                                onChange={(value) => {
                                    //const type = this.state.PaymentMonthly.mRequestedReport;
                                    //console.log(type);
                                    //const str = (type === 0) ? '' : type.toString();
                                    //console.log(value,str);
                                    //this.setReportParameters("PaymentMonthly", { reportName: "reports/PaymentMonthly" + str + ".pdf"})}
                                    //this.setState({ ...this.state, type: value });
                                    this.setReportParameters("PaymentMonthly", {
                                        reportName: "reports/paymentsMonthly." + value,
                                        type: value
                                    });
                                }}
                            >
                                <Option key={"pdf"}>{this.getText(12174)}</Option>
                                <Option key={"xls"}>{this.getText(12175)}</Option>
                                <Option key={"docx"}>{this.getText(12176)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem key={"submit"}>
                            <Button type={"primary"} onClick={() => {
                                //const type = this.state.RequestedReport;
                                this.generateReport.apply(this, ["PaymentMonthly"])
                            }}>{this.getText(12177)}</Button>
                        </FormItem> */}
                    </Form>
                </Card>
            </div>
        );
    }
}
export default PaymentMonthlyReport;

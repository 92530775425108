import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppContainer from './AppContainer';
import register from './registerServiceWorker';
import unregister from './registerServiceWorker';
// import {unregister, register} from './registerServiceWorker';

unregister();
//register()

ReactDOM.render(<AppContainer />, document.getElementById('root'));
//registerServiceWorker();

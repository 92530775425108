/* @flow */
/*jshint esversion: 6 */
import { Input, InputNumber, message } from "antd";
import { indexOf } from "lodash";
import React, { Component } from "react";
import "../../App.css";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";


class StoreNext extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "STORE_NEXT_EDI";

        this.api = {
            get: "get_pos_admin_edi_suppliers",
            set: "set_pos_admin_edi_suppliers",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(17242);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    renderCol = (text, record, field) => {
        const { editing, editedRecord } = this.props.data[this.id];
        if (record.key == editing) {
            let value = editedRecord[field]
            switch (field) {
                case '_code': case '_subCode':
                    return (<InputNumber value={value} onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }} />)
                case '_desc':
                    return (<Input value={value} onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.target.value }) }} />)
            }
        } else {
            return text;
        }
    }

    renderEditCol = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];
        if (record.key == editing) {
            return (<TableLineIcons
                onCheck={() => {
                    let isExists = this.props.data[this.id].data.map((x, index) => {
                        let y = x.split("\f");
                        return { index, _code: y[1], _subCode: y[2] }
                    }).filter(f => f.index !== record.index && f._code == editedRecord._code && f._subCode == editedRecord._subCode)

                    if (isExists.length) {
                        message.error(this.getText(17248))
                    } else {
                        this.props.dataActions.genericDataSaveEdit(
                            this.id, this.props.user.companyCode, this.props.user.token, this.api.set, this.api.get)
                    }
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => {
                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index)
                }}
            />);
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        return (<div style={divStyle}>
            <ResponsiveTable
                title={gt(17243)}
                idTable={this.props.data[this.id]}
                pagination={{ pageSize: 10 }}
                columns={[
                    { title: gt(17244), key: '_code', dataIndex: '_code', width: '20%', render: (t, r) => this.renderCol(t, r, '_code') },
                    { title: gt(17245), key: '_subCode', dataIndex: '_subCode', width: '20%', render: (t, r) => this.renderCol(t, r, '_subCode') },
                    { title: gt(17246), key: '_desc', dataIndex: '_desc', width: '50%', render: (t, r) => this.renderCol(t, r, '_desc') },
                    { title: gt(17247), width: '10%', render: this.renderEditCol },
                ]}
                actionButtons={[
                    { buttonType: 'refresh', buttonFunction: this.refreshData },
                    { buttonType: 'add', buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) } },
                ]}
            />
        </div>)
    }

}

export default StoreNext;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import GenericTable from "../../components/GenericTable";
import LangContext from "../../contextProvider/LangContext";
import TableLineIcons from "../../components/TableLineIcons";
import {Input, message} from "antd";
import GenericModal from "../../components/GenericModal";
import JsonTreeView from "../../components/JsonTreeView";


class HashavshevetJob extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalViewJob: null,
        };

        this.id = "HASHAVSHEVET_JOB_QUEUE";

        this.api = {
            get: "get_hashavshevet_job_queue",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16711);
    }

    copyJob = (id) => {
        let copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        navigator.clipboard.writeText(copyText.value);
        message.success(this.getText(16723))
    }

    editBtn = (text, record) => {
        let id = 'hashavshevet_job_row_json_' + record.jId;
        return (<div>
            <TableLineIcons
                genericIcons={[
                    { type: 'eye', onClick: () => { this.setState({ modalViewJob: record.jRow }) }, tooltip: this.getText(16721) },
                    { type: 'copy', onClick: () => { this.copyJob(id) }, tooltip: this.getText(16722) },
                ]}
            />
            <Input id={id} value={record.jRow} style={{ display: 'none' }} />
        </div>)
    }

    render() {
        let gt = this.getText;
        let sorter = true;
        let render = t => t;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        let columns = [
            { title: gt(16713), key: "jDatetime", dataIndex: "jDatetime", width: "20%", sorter, render },
            { title: gt(16714), key: "jType", dataIndex: "jType", width: "10%", sorter, render },
            { title: gt(16715), key: "jTypeDescription", dataIndex: "jTypeDescription", width: "25%", sorter, render },
            { title: gt(16716), key: "jStatus", dataIndex: "jStatus", width: "10%", sorter, render },
            { title: gt(16717), key: "jStatusDescription", dataIndex: "jStatusDescription", width: "25%", sorter, render },
            { title: gt(16718), width: "10%", render: this.editBtn },
        ];

        let jobView = this.state.modalViewJob;

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16712)}
                columns={columns}
                searchBox
                filters={{ _isAsc: 0 }}
            />
            <GenericModal
                visible={jobView}
                onCancel={() => { this.setState({ modalViewJob: null }) }}
                title={gt(16724)}
                width={600}>
                <JsonTreeView jsonData={jobView} />
            </GenericModal>
        </div>)
    }

}

export default HashavshevetJob;
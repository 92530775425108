/* @flow */
/*jshint esversion: 6 */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import TableIDs from "../data/TableIDs"
import BestClubMenu from '../menus/BestClubMenu';
import MonthlyJoinRate from "./BestClub/MonthlyJoinRate";
import MonthlyUseBenefits from "./BestClub/MonthlyUseBenefits";
import MonthlyUsePoints from "./BestClub/MonthlyUsePoints";
import UseBargains from "./BestClub/UseBargains";
import PurchasesAndVisits from "./BestClub/PurchasesAndVisits";
import ClubNotActive from "./BestClub/ClubNotActive";
import VisitsByDays from "./BestClub/VisitsByDays";
import TopItems from "./BestClub/TopItems";
import CustomersByAge from "./BestClub/CustomersByAge";
import Removals from "./BestClub/Removals";
import ClubCustBranches from "./BestClub/ClubCustBranches";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};

type State = {};

class BestClub extends Component<Props, State> {
    constructor(props/*: Props*/) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={231} path={this.props.baseUrl + "/best-club/monthly-join-rate"} exact render={() => <MonthlyJoinRate {...routeProps} />} />),
            (<Route key={232} path={this.props.baseUrl + "/best-club/monthly-use-benefits"} exact render={() => <MonthlyUseBenefits {...routeProps} />} />),
            (<Route key={233} path={this.props.baseUrl + "/best-club/monthly-use-points"} exact render={() => <MonthlyUsePoints {...routeProps} />} />),
            (<Route key={234} path={this.props.baseUrl + "/best-club/use-bargains"} exact render={() => <UseBargains {...routeProps} />} />),
            (<Route key={235} path={this.props.baseUrl + "/best-club/purchases-and-visits"} exact render={() => <PurchasesAndVisits {...routeProps} />} />),
            (<Route key={236} path={this.props.baseUrl + "/best-club/club-not-active"} exact render={() => <ClubNotActive {...routeProps} />} />),
            (<Route key={237} path={this.props.baseUrl + "/best-club/visits-by-days"} exact render={() => <VisitsByDays {...routeProps} />} />),
            (<Route key={238} path={this.props.baseUrl + "/best-club/top-items"} exact render={() => <TopItems {...routeProps} />} />),
            (<Route key={239} path={this.props.baseUrl + "/best-club/customers-by-age"} exact render={() => <CustomersByAge {...routeProps} />} />),
            (<Route key={240} path={this.props.baseUrl + "/best-club/removals"} exact render={() => <Removals {...routeProps} />} />),
            (<Route key={241} path={this.props.baseUrl + "/best-club/cust-branches"} exact render={() => <ClubCustBranches {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/best-club"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <BestClubMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}
export default BestClub;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
import { Button, Checkbox, Form, Row, Col, Input, Card, Icon, Tooltip, InputNumber, Radio, Popconfirm } from "antd";
import moment from "moment";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

class CarList extends Component {
    constructor(props: Props) {
        super(props);

        this.yasterDay = moment().add(-1, "days").format('YYYY-MM-DD');

        this.state = {
            dataSourceAll: [],
            dataSourceEmployees: [],
            modalFilter: false,
            modalSettings: false,
            modalCarExit: false,
            modalEmployee: null,
            modalEmployeeData: [],
            mobilelFilter: true,
            mobileEmployees: false,
            filters: {
                _branches: '',
                _date_from: moment().format('YYYY-MM-DD') + ' 00:00:00',
                _date_to: moment().format('YYYY-MM-DD') + ' 23:59:59',
                _words: '',
                _employeeGroups: '',
                _minotesToMark: null,
            },
            _with_exit: 0,
            sorterTable: {
                field: null,
                order: null,
            },
            sorterEmployees: {
                field: null,
                order: null,
            },
            lastRenderTime: Date.now(),

            carExitCheckedList: [],
            carExitDate: this.yasterDay,
            carExitIsPayed: 1,
        };

        this.renderTime = null;

        this.mobile = window.innerWidth <= 600;

        this.actionButtons = this.mobile ? [{
            buttonType: 'filter',
            buttonFunction: () => { this.setState({ mobilelFilter: true }) }
        }] : [];

        // this.id = "CAR_LIST_TABLE";
        // this.employees_id = "EMPLOYEES_HOURS";

        this.api = {
            get: "get_car_list",
            get_employees: "get_employee_hours",

            get_defaults: "get_users_car_list_dashboard",
            set_defaults: "set_users_car_list_dashboard",

            set_car_exit: "set_car_list_exit_multi",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list", multi: true },
            { id: TableIDs.genericSelectorGroupEmployee, api: "get_employee_group_list", multi: true },
        ]

        // if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        // if (props.data[this.employees_id] === undefined) props.data[this.employees_id] = { ...props.data.genericData };
        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { dataset: [] } })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI(this.api.get_defaults, "", ob => {
            if (ob.data) {
                let params = ob.data.split("\f");
                let _branches = params[0];
                let _employeeGroups = params[1];
                let _minotesToMark = parseInt(params[2]) ? parseInt(params[2]) : null;
                this.setState({ filters: { ...this.state.filters, _branches, _employeeGroups, _minotesToMark } }, this.initFilter)
            } else {
                this.initFilter();
            }
        })
        this.loopRenderTime();
    }

    componentDidUpdate(p) {
        document.title = "Cash On Tab - " + this.getText(16044);

        if (p.data[TableIDs.genericSelectorBranches].dataset !== this.props.data[TableIDs.genericSelectorBranches].dataset) {
            this.initFilter()
        }

    }

    loopRenderTime = () => {
        this.setState({ lastRenderTime: Date.now() }, () => {
            clearTimeout(this.renderTime);
            this.renderTime = setTimeout(this.loopRenderTime, 30000)
        })
    }

    cancelFilter = () => {
        this.setState({ modalFilter: false })
    }

    clearFilter = () => {
        this.setState({
            filters: {
                ...this.state.filters,
                _branches: '',
                _date_from: null,
                _date_to: null,
                _words: '',
                _employeeGroups: '',
            },
            _with_exit: 0,
        })
    }

    setFilter = () => {
        this.initFilter()
        this.cancelFilter()
    }

    saveSettings = () => {
        const { _branches, _employeeGroups, _minotesToMark } = this.state.filters;
        let dataSend = "_branches\f_employeeGroups\f_minotesToMark\r" + _branches + "\f" + _employeeGroups + "\f" + _minotesToMark;
        this.sendAPI(this.api.set_defaults, dataSend, () => {
            this.setState({ modalSettings: false }, this.initFilter)
        })
    }


    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    initFilter = () => {
        let filters = { ...this.state.filters }
        let _words = filters._words ? filters._words.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'") : '';
        let _branches = filters._branches ? filters._branches : this.props.data[this.selectors[0].id].dataset.map(x => x.code).join(',');
        let _employeeGroups = filters._employeeGroups;
        let _date_from = filters._date_from ? filters._date_from : '';
        let _date_to = filters._date_to ? filters._date_to : '';

        let dataSend = "_words\f_branches\f_date_from\f_date_to\f_employeeGroups\r" +
            _words + "\f" + _branches + "\f" + _date_from + "\f" + _date_to + "\f" + _employeeGroups;


        this.sendAPI(this.api.get, dataSend, this.getDataSource)
        this.sendAPI(this.api.get_employees, dataSend, this.getDataSourceEmployees)
        // this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        // this.props.dataActions.genericDataSetFilter(this.employees_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_employees);
    }

    onChangeFilter = (field, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [field]: value,
            }
        })
    }

    cardDashboard = (sum, text, value, subscribers, noExit) => {
        let _with_exit = this.state._with_exit;
        let color = '#ffffff';
        if (value == 'avg') color = 'lightblue';
        if (value == 'employees') color = 'lightgreen';
        if (value == _with_exit) color = '#ffff00';

        return (<Card
            style={{
                cursor: 'pointer',
                margin: 5,
                padding: 2,
                paddingTop: 5,
                textAlign: 'center',
                fontSize: 35,
                // border: 'black solid 2px',
                borderRadius: 10,
                background: color,
                height: 240,
            }}
            onClick={() => {
                if (value != 'avg') {
                    let isE = value == 'employees';
                    this.setState({ mobilelFilter: false, mobileEmployees: isE, _with_exit: isE ? _with_exit : value })
                }
            }}>
            <div style={{ fontSize: 50 }}><b>{sum}</b></div>
            {value == 'employees' || value == 'avg' ? "" : <div style={{ fontSize: 16 }}><b>({subscribers} {this.getText(17116)})</b></div>}
            <div>{this.getText(text)}</div>
            {noExit !== undefined ? <div style={{ fontSize: 16 }}><b>({noExit} {this.getText(16064)})</b></div> : ""}
        </Card>)
    }

    createIconButton = (type, tooltip, onClick) => {
        return (<Tooltip title={tooltip}>
            <Button onClick={onClick} size="large">
                <Icon type={type} style={{ fontSize: 20 }} />
            </Button>
        </Tooltip>)
    }

    getDataSource = (ob) => {
        let dataSourceAll = [];
        if (ob.data) {
            let data = ob.data.split("\r");
            let headers = data.splice(0, 1)[0].split("\f");

            dataSourceAll = data.map((x, index) => {
                let y = x.split("\f");
                let obj = { index, key: index }
                headers.forEach((h, i) => { obj = { ...obj, [h]: y[i] } })
                return obj;
            })
        }
        this.setState({ dataSourceAll })
    }

    getDataSourceEmployees = (ob) => {
        let dataSourceEmployees = [];

        if (ob.data) {
            let eID = null;
            let eName = null;
            let eHours = 0;
            let eIsActive = false;
            let eEnterTime = null;

            let pushEmployee = () => {
                if (eID) {
                    let eUnixtime = parseInt(Date.now() / 1000);
                    if (eIsActive && eEnterTime) eHours += (eUnixtime - eEnterTime);
                    dataSourceEmployees.push({ eID, eName, eHours, eIsActive });
                }
            }

            ob.data.split("\r").forEach(x => {
                let y = x.split("\f");
                if (eID !== y[0]) {
                    pushEmployee()
                    eID = y[0];
                    eName = y[3];
                    eHours = 0;
                    eIsActive = false;
                    eEnterTime = null;
                }

                let eUnixtime = parseInt(y[1]);
                let eIsEnter = y[2] == '1';

                if (eIsEnter) {
                    eIsActive = true;
                    eEnterTime = eUnixtime;
                } else if (eIsActive && eEnterTime) {
                    eHours += (eUnixtime - eEnterTime);
                    eIsActive = false;
                    eEnterTime = null;
                }
            });

            pushEmployee();
        }

        this.setState({ dataSourceEmployees })
    }

    createFilterField = (label, field, type, index) => {
        let value = this.state.filters[field];
        let onChange = value => { this.setState({ filters: { ...this.state.filters, [field]: value } }) }
        let minDate = field == '_date_to' ? this.state.filters._date_from : null
        let maxDate = field == '_date_from' ? this.state.filters._date_to : null

        return (<Form.Item label={this.getText(label)}>
            {type === 'datetime' ? (<DateTimePicker format={'YYYY-MM-DD HH:mm'} showTime {...{ value, onChange, minDate, maxDate }} />)
                : type === 'input' ? (<Input value={value} onChange={e => { onChange(e.target.value) }} />)
                    : type === 'number' ? (<InputNumber {...{ value, onChange }} />)
                        : type === 'select' ? (
                            <GenericSelector
                                {...this.props}
                                {...this.selectors[index]}
                                value={value ? value.split(',') : []}
                                onChange={(e) => { onChange(e ? e.join(',') : '') }}
                            />) : ""}
        </Form.Item>)
    }

    hourFormat = (sum) => {
        if (!sum) return '0:00';
        let m = sum % 60;
        if (m < 10) m = '0' + m;
        let h = parseInt(sum / 60);
        return h + ':' + m;
    }

    modalEmployeeMetaData = () => {
        const { modalEmployee } = this.state;
        let gt = this.getText;
        if (modalEmployee) {
            return (<Row>
                <Col span={this.mobile ? 24 : 6}><h2>{gt(17138)}: {modalEmployee.eID}</h2></Col>
                <Col span={this.mobile ? 24 : 6}><h2>{gt(17139)}: {modalEmployee.eName}</h2></Col>
                <Col span={this.mobile ? 24 : 6}><h2>{gt(17140)}: {this.hourFormat(modalEmployee.eHours)}</h2></Col>
                <Col span={this.mobile ? 24 : 6}><h2>{gt(17141)}: {modalEmployee.eAvg}</h2></Col>
            </Row>)
        }
    }

    printButton = (data, isEmployees) => {
        let gt = this.getText;

        return (<Tooltip title={gt(17142)}>
            <Button style={{ marginTop: 5, marginLeft: 5 }} onClick={() => {
                let fullData = [];
                let fileName = '';
                if (isEmployees) {
                    fullData = [
                        [gt(17138), gt(17139), gt(17112), gt(17140), gt(17141)],
                        ...data.map(x => [x.eID, x.eName, x.eCount, this.hourFormat(x.eHours), x.eAvg])
                    ];
                    fileName = 'employeeList.csv';
                } else {
                    fullData = [
                        [gt(16046), gt(16047), gt(16048), gt(16049), gt(16050), gt(16051), gt(16052), gt(16053), gt(17143)],
                        ...data.map(x => {
                            return [
                                x.tBranchName,
                                x.tCarNumber,
                                x.tPhoneNumber,
                                x.tDriverName,
                                x.tHasSubscription ? 'V' : '',
                                x.tEnterDateTime,
                                x.tExitDateTime,
                                x.tPayed ? 'V' : '',
                                x.tBuzzer
                            ]
                        })
                    ];
                    fileName = 'employeeCarList.csv';
                }

                let printData = fullData.map(x => x.map(t => typeof t === 'string' ? '"' + (t ? t.split('"').join('""') : '') + '"' : t ? t : '').join(',')).join('\r\n')
                let blob = new Blob(["\ufeff", printData], { type: "text/csv;charset=utf8" });
                let a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.download = fileName;
                a.click();

            }}>
                <Icon type="file-excel" style={{ fontSize: 16 }} />
            </Button>
        </Tooltip>)
    }

    saveCarExit = () => {
        let dataSend = "upperEnterDate\fminutesToAdd\fbranchList\fisPayed\r" +
            this.state.carExitDate + "\f" +
            this.state.filters._minotesToMark + "\f" +
            this.state.carExitCheckedList + "\f" +
            this.state.carExitIsPayed;

        this.sendAPI(this.api.set_car_exit, dataSend, () => {
            this.initFilter()
            this.setState({ modalCarExit: false })
        })
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: '1%',
        };

        let gt = this.getText;
        let render = t => t;
        let renderCB = t => t == 1 ? (<Icon type="check" />) : "";
        let renderHour = t => this.hourFormat(t);
        let sorter = true;

        let filters = this.state.filters;

        let sumCarFinish = 0;
        let countCarFinish = 0;

        let curtime = this.state.lastRenderTime;

        let dataSource = this.state.dataSourceAll.map(x => {

            let entertime = new Date(x.tEnterDateTime).getTime();
            let exittime = x.tExitDateTime ? new Date(x.tExitDateTime).getTime() : null;

            if (exittime) {
                sumCarFinish += (exittime - entertime);
                countCarFinish++;
            }

            if (filters._minotesToMark) {
                let tIsMark = false;
                if (!x.tExitDateTime && !x.tDeletedMessage) {
                    let b = (curtime - entertime) / 60000;
                    tIsMark = b >= filters._minotesToMark;
                }
                return { ...x, tIsMark }
            }
            return x;
        });

        let avgCarFinish = this.hourFormat(Math.round(sumCarFinish / countCarFinish / 60000));
        console.log("eAvg test", sumCarFinish, countCarFinish, avgCarFinish)




        let dataSourceAll = dataSource.filter(f => !f.tDeletedMessage);
        let dataSourceNoExit = dataSourceAll.filter(f => !f.tExitDateTime);
        let dataSourceExit = dataSourceAll.filter(f => f.tExitDateTime);
        let dataSourceDeleted = dataSource.filter(f => f.tDeletedMessage);
        let dataSourceHandWash = dataSource.filter(f => f.tIsHandWash == '1');

        let dataSourceCarExit = [];

        dataSourceNoExit.filter(f => {
            let enterDate = f.tEnterDateTime ? f.tEnterDateTime.split(" ")[0] : ""
            return enterDate <= this.state.carExitDate;
        }).forEach(x => {
            if (this.state.carExitIsPayed == -1 || this.state.carExitIsPayed == x.tPayed) {
                let branchIndex = dataSourceCarExit.findIndex(f => f.id == x.tBranchID);
                if (branchIndex > -1) {
                    let oldRowData = dataSourceCarExit[branchIndex];
                    dataSourceCarExit[branchIndex] = { ...oldRowData, sum: oldRowData.sum + 1, sumNotPayed: oldRowData.sumNotPayed + (x.tPayed == 0 ? 1 : 0) }
                } else {
                    dataSourceCarExit.push({ id: x.tBranchID, name: x.tBranchName, sum: 1, sumNotPayed: x.tPayed == 0 ? 1 : 0 })
                }
            }
        })

        dataSourceCarExit.sort((a, b) => a.id - b.id)

        let columns = [
            { title: gt(16046), key: 'tBranchName', dataIndex: 'tBranchName', sorter, width: '14%', render },
            { title: gt(16047), key: 'tCarNumber', dataIndex: 'tCarNumber', sorter, width: '11%', render },
            { title: gt(16048), key: 'tPhoneNumber', dataIndex: 'tPhoneNumber', sorter, width: '11%', render },
            { title: gt(16049), key: 'tDriverName', dataIndex: 'tDriverName', sorter, width: '14%', render },
            { title: gt(16050), key: 'tHasSubscription', dataIndex: 'tHasSubscription', sorter, width: '7%', render: renderCB },
            { title: gt(16051), key: 'tEnterDateTime', dataIndex: 'tEnterDateTime', sorter, width: '14%', render },
            { title: gt(16052), key: 'tExitDateTime', dataIndex: 'tExitDateTime', sorter, width: '14%', render },
            { title: gt(16053), key: 'tPayed', dataIndex: 'tPayed', sorter, width: '7%', render: renderCB },
            { title: gt(17143), key: 'tBuzzer', dataIndex: 'tBuzzer', sorter, width: '8%', render },
            { title: gt(17126), key: 'tDeletedMessage', dataIndex: 'tDeletedMessage', sorter, width: '21%', render },
        ];

        let tableDataSource = [];
        let tableColumns = [];
        let subTitle = "";

        switch (this.state._with_exit) {
            case 2:
                tableDataSource = dataSourceHandWash;
                tableColumns = columns.filter(f => f.key !== 'tDeletedMessage');
                subTitle = " - " + gt(18553);
                break;
            case 1:
                tableDataSource = dataSourceExit;
                tableColumns = columns.filter(f => f.key !== 'tDeletedMessage');
                subTitle = " - " + gt(16065);
                break;
            case 0:
                tableDataSource = dataSourceNoExit;
                tableColumns = columns.filter(f => f.key !== 'tDeletedMessage');
                subTitle = " - " + gt(16064);
                break;
            case -1:
                tableDataSource = dataSourceAll;
                tableColumns = columns.filter(f => f.key !== 'tDeletedMessage');
                break;
            case -2:
                tableDataSource = dataSourceDeleted;
                tableColumns = columns.filter(f => f.key !== 'tExitDateTime' && f.key !== 'tPayed');
                subTitle = " - " + gt(17125);
                break;
        }

        let employeesDataSource = this.state.dataSourceEmployees.map(x => {
            return { ...x, eCount: 0, eAvg: 0, eHours: x.eHours ? Math.round(x.eHours / 60) : 0 }
        })

        dataSourceAll.forEach(x => {
            let eFind = employeesDataSource.findIndex(f => f.eID === x.tWorker);
            if (eFind > -1) {
                let data = employeesDataSource[eFind];
                let eCount = data.eCount + 1;
                let eAvg = data.eHours ? Math.round((data.eHours / eCount / 60) * 100) / 100 : 0;
                employeesDataSource[eFind] = { ...data, eCount, eAvg }
            }
        });

        let activeEmployeesCount = employeesDataSource.filter(f => f.eIsActive).length;

        if (this.state.sorterTable.field) {
            let f = this.state.sorterTable.field;
            let o = this.state.sorterTable.order;

            tableDataSource = tableDataSource.sort((a, b) => {
                if (o === undefined || o === "ascend") {
                    return a[f].localeCompare(b[f])
                } else {
                    return b[f].localeCompare(a[f])
                }
            })
        }

        if (this.state.sorterEmployees.field) {
            let f = this.state.sorterEmployees.field;
            let o = this.state.sorterEmployees.order;
            let isText = f === 'eName';

            employeesDataSource = employeesDataSource.sort((a, b) => {
                if (o === undefined || o === "ascend") {
                    return isText ? a[f].localeCompare(b[f]) : a[f] - b[f]
                } else {
                    return isText ? b[f].localeCompare(a[f]) : b[f] - a[f]
                }
            })
        }



        let filtersModal = (<GenericModal
            visible={this.state.modalFilter}
            onCancel={this.cancelFilter}
            width={1000}
            title={gt(17100)}
            footer={[
                <Button key="filter" type="primary" onClick={this.setFilter}>{gt(17105)}</Button>,
                <Button key="clear" onClick={this.clearFilter}>{gt(17106)}</Button>,
                <Button key="cancel" onClick={this.cancelFilter}>{gt(17107)}</Button>,
            ]}>
            <Row>
                <Col span={this.mobile ? 24 : 8}>
                    {this.createFilterField(16056, "_date_from", "datetime")}
                    {this.createFilterField(16057, "_date_to", "datetime")}
                </Col>
                <Col span={this.mobile ? 24 : 16}>
                    {this.createFilterField(16055, "_branches", "select", 0)}
                    {this.createFilterField(17121, "_employeeGroups", "select", 1)}
                    {this.createFilterField(16062, "_words", "input")}
                </Col>
            </Row>


        </GenericModal>);

        let settingsModal = (<GenericModal
            visible={this.state.modalSettings}
            onCancel={() => { this.setState({ modalSettings: false }) }}
            width={500}
            title={gt(17130)}
            footer={[
                <Button key="save" type="primary" onClick={this.saveSettings}>{gt(17131)}</Button>,
                <Button key="cancel" onClick={() => { this.setState({ modalSettings: false }) }}>{gt(17132)}</Button>,
            ]}>
            {this.createFilterField(16055, "_branches", "select", 0)}
            {this.createFilterField(17121, "_employeeGroups", "select", 1)}
            {this.createFilterField(17129, "_minotesToMark", "number")}
        </GenericModal>);

        const carExitCB = (id) => {
            const checkedList = this.state.carExitCheckedList;
            const checked = id === "All" ? checkedList.length && checkedList.length === dataSourceCarExit.length : checkedList.find(f => f == id) ? true : false;
            const indeterminate = id === "All" && checkedList.length && checkedList.length < dataSourceCarExit.length;
            const onChange = (e) => {
                if (e.target.checked) {
                    this.setState({ carExitCheckedList: id === "All" ? dataSourceCarExit.map(x => x.id) : [...checkedList, id] })
                } else {
                    this.setState({ carExitCheckedList: id === "All" ? [] : checkedList.filter(f => f !== id) })
                }
            }
            return (<Checkbox {...{ checked, indeterminate, onChange }} />)
        }

        let carExitModal = (<GenericModal
            visible={this.state.modalCarExit}
            onCancel={() => { this.setState({ modalCarExit: false }) }}
            width={500}
            title={gt(18403)}
            footer={[
                <Popconfirm
                    title={this.getText(18411)}
                    okText={this.getText(18412)}
                    cancelText={this.getText(18413)}
                    onConfirm={this.saveCarExit}>
                    <Button key="save" type="primary">{gt(18407)}</Button>
                </Popconfirm>,
                <Button key="cancel" onClick={() => { this.setState({ modalCarExit: false }) }}>{gt(17132)}</Button>,
            ]}>
            <DateTimePicker
                format={"DD/MM/YYYY"}
                maxDate={this.yasterDay}
                value={this.state.carExitDate}
                onChange={e => { this.setState({ carExitDate: e }) }}
            />
            <ResponsiveTable
                tableOnly
                dataSource={dataSourceCarExit}
                columns={[
                    { title: carExitCB("All"), key: "id", dataIndex: "id", width: "10%", render: carExitCB },
                    { title: this.getText(18404), key: "name", dataIndex: "name", width: "40%", render: t => t },
                    { title: this.getText(18405), key: "sum", dataIndex: "sum", width: "25%", render: t => t },
                    { title: this.getText(18406), key: "sumNotPayed", dataIndex: "sumNotPayed", width: "25%", render: t => t },
                ]}
                pagination={{ pageSize: 10 }}
            />
            <Radio.Group value={this.state.carExitIsPayed} onChange={e => { this.setState({ carExitIsPayed: e.target.value }) }}>
                <Radio value={-1}>{this.getText(18408)}</Radio>
                <Radio value={1}>{this.getText(18409)}</Radio>
                <Radio value={0}>{this.getText(18410)}</Radio>
            </Radio.Group>
        </GenericModal>);

        let filterButton = this.createIconButton("filter", gt(17102), () => { this.setState({ modalFilter: true }) })
        let refreshButton = this.createIconButton("reload", gt(17103), this.initFilter)
        let settingsButton = this.createIconButton("setting", gt(17130), () => { this.setState({ modalSettings: true }) })
        let carExitButton = this.createIconButton("car", gt(18403), () => { this.setState({ modalCarExit: true }) })
        let cardsButton = this.createIconButton("appstore", gt(17104), () => { this.setState({ mobilelFilter: true, mobileEmployees: false }) })

        let cardsSection = [
            this.cardDashboard(dataSourceAll.length, 16063, -1, dataSourceAll.filter(f => f.tHasSubscription == '1').length),
            this.cardDashboard(dataSourceNoExit.length, 16064, 0, dataSourceNoExit.filter(f => f.tHasSubscription == '1').length),
            this.cardDashboard(dataSourceExit.length, 16065, 1, dataSourceExit.filter(f => f.tHasSubscription == '1').length),
            this.cardDashboard(dataSourceDeleted.length, 17125, -2, dataSourceDeleted.filter(f => f.tHasSubscription == '1').length),
            this.cardDashboard(dataSourceHandWash.length, 18553, 2, dataSourceHandWash.filter(f => f.tHasSubscription == '1').length,
                dataSourceHandWash.filter(f => !f.tExitDateTime).length),
            this.cardDashboard(avgCarFinish, 17133, 'avg'),
            this.cardDashboard(activeEmployeesCount, 17101, 'employees'),
        ]

        let tableSection = (<div style={{ padding: 5 }}>
            <ResponsiveTable
                title={gt(16045) + subTitle}
                actionButtons={[]}
                dataSource={tableDataSource}
                columns={tableColumns}
                percentageToMobile={[0, 50, 0, 50]}
                pagination={{ pageSize: 10 }}
                onChange={(p, f, s) => { this.setState({ sorterTable: s }) }}
                rowClassName={(record) => record.tIsMark ? "row_pink" : ""}
            />
        </div>)

        let employeesSection = (<div style={{ padding: 5 }}>
            <ResponsiveTable
                title={gt(17110)}
                actionButtons={[]}
                genericActionButtons={[this.printButton(employeesDataSource, true)]}
                dataSource={employeesDataSource}
                columns={[
                    { title: gt(17111), key: 'eName', dataIndex: 'eName', sorter: true, width: '25%', render },
                    { title: gt(17112), key: 'eCount', dataIndex: 'eCount', sorter: true, width: '25%', render },
                    { title: gt(17113), key: 'eHours', dataIndex: 'eHours', sorter: true, width: '25%', render: renderHour },
                    { title: gt(17114), key: 'eAvg', dataIndex: 'eAvg', sorter: true, width: '25%', render },
                ]}
                percentageToMobile={[50, 50]}
                pagination={{ pageSize: 10 }}
                onChange={(p, f, s) => { this.setState({ sorterEmployees: s }) }}
                rowClassName={(r) => r.eIsActive ? 'row_greenyellow' : 'row_pink'}
                onRowClick={r => {
                    this.setState({
                        modalEmployee: r,
                        modalEmployeeData: dataSourceAll.filter(f => f.tWorker === r.eID)
                    })
                }} />
        </div>)

        let modalEmployeeView = (<GenericModal
            visible={this.state.modalEmployee !== null}
            onCancel={() => { this.setState({ modalEmployee: null, modalEmployeeData: [] }) }}
            title={gt(17137)}
            width={1000}
            genericActionButtons={[this.printButton(this.state.modalEmployeeData, false)]}
        >
            {this.modalEmployeeMetaData()}
            <ResponsiveTable
                tableOnly
                dataSource={this.state.modalEmployeeData}
                columns={columns.filter(f => f.key !== 'tDeletedMessage').map(x => { return { ...x, sorter: false } })}
                percentageToMobile={[0, 50, 0, 50]}
                pagination={{ pageSize: 10 }}
                rowClassName={(record) => record.tIsMark ? "row_pink" : ""}
            />
        </GenericModal>);

        let modals = [
            filtersModal,
            settingsModal,
            modalEmployeeView,
            carExitModal,
        ];

        if (this.mobile) {
            if (this.state.mobilelFilter) {
                return (<div style={divStyle}>
                    <div style={{ textAlign: 'center' }}>{filterButton} {refreshButton} {settingsButton} {carExitButton}</div>
                    {cardsSection}
                    {modals}
                </div>)
            } else if (this.state.mobileEmployees) {
                return (<div style={divStyle}>
                    <div style={{ textAlign: 'center' }}>{filterButton} {refreshButton} {settingsButton} {carExitButton} {cardsButton}</div>
                    {employeesSection}
                    {modals}
                </div>)
            } else {
                return (<div style={divStyle}>
                    <div style={{ textAlign: 'center' }}>{filterButton} {refreshButton} {settingsButton} {carExitButton} {cardsButton}</div>
                    {tableSection}
                    {modals}
                </div>)
            }
        } else {
            return (<div style={divStyle}>
                <div style={{ width: 100, display: 'inline-block', verticalAlign: 'text-top' }}>
                    <div style={{ margin: 5 }}>{filterButton}</div>
                    <div style={{ margin: 5 }}>{refreshButton}</div>
                    <div style={{ margin: 5 }}>{settingsButton}</div>
                    <div style={{ margin: 5 }}>{carExitButton}</div>
                </div>
                <div style={{ width: window.innerWidth - 250, display: 'inline-block', verticalAlign: 'text-top' }}>
                    {cardsSection.map(x => (<div style={{ display: "inline-block", verticalAlign: "text-top", width: "14.2%" }}>{x}</div>))}
                </div>
                <Row>
                    <Col span={15}>{tableSection}</Col>
                    <Col span={9}>{employeesSection}</Col>
                </Row>
                {modals}
            </div>)
        }
    }
}

export default CarList;
/* @flow */
/*jshint esversion: 6 */
import { Form } from "antd";
import { Row, Col, DatePicker } from "antd";
import React, { Component } from "react";
import "../../App.css";
import ReportTypeButton from "../../components/ReportTypeButton";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import { disabledDatePicker } from "../../utils/utils";

const { MonthPicker } = DatePicker;

class Removals extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _fromYear: null,
            _toYear: null,
            _fromMonth: null,
            _toMonth: null,
            reportName: "reports/Removals.pdf"
        };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(18089);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 700,
            margin: 'auto'
        };

        const { _fromYear, _toYear, _fromMonth, _toMonth } = this.state;

        return (<div style={divStyle}>
            <PageToolbar title={gt(18090)} actionButtons={[]} />
            <Row>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18091)}>
                        <MonthPicker
                            format="MM/YYYY"
                            value={_fromYear && _fromMonth ? moment(_fromYear + _fromMonth, "YYYYMM") : null}
                            onChange={e => {
                                this.setState({
                                    _fromMonth: e ? moment(e).format("MM") : null,
                                    _fromYear: e ? moment(e).format("YYYY") : null
                                })
                            }}
                            disabledDate={(d) => disabledDatePicker(d, null, _toYear && _toMonth ? _toYear + "-" + _toMonth + "-01" : null)}
                        />
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18092)}>
                        <MonthPicker
                            format="MM/YYYY"
                            value={_toYear && _toMonth ? moment(_toYear + _toMonth, "YYYYMM") : null}
                            onChange={e => {
                                this.setState({
                                    _toMonth: e ? moment(e).format("MM") : null,
                                    _toYear: e ? moment(e).format("YYYY") : null
                                })
                            }}
                            disabledDate={(d) => disabledDatePicker(d, _fromYear && _fromMonth ? _fromYear + "-" + _fromMonth + "-01" : null, null)}
                        />
                    </Form.Item>
                </Col>
            </Row>
           
            <ReportTypeButton
                {...this.props}
                params={this.state}
                datesToFavorites={[
                    { fields: ["_fromMonth", "_fromYear"], label: gt(18091), format: 2 },
                    { fields: ["_toMonth", "_toYear"], label: gt(18092), format: 2 },
                ]}
                favoriteSave
            />
        </div>)
    }

}

export default Removals;
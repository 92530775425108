
/*jshint esversion: 6 */

import React, { Component } from "react";

import "../App.css";
import { Icon, Input, Checkbox, Popconfirm, Modal, message, Form } from "antd";
import TableIDs from "../data/TableIDs";
import ResponsiveTable from "./ResponsiveTable";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import LangContext from "../contextProvider/LangContext";
import PageToolbar from "./PageToolbar";
import DateTimePicker from "./DateTimePicker";

const topHeight = 340; // px
const rowHeight = 30;  // px

class ModalSalesAlert extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            height: window.innerHeight,
        };

        this.api = {
            get: "get_sale_alert_list_paging",
            create: "create_sales_alert",
            edit: "edit_sales_alert",
            delete: "delete_sales_alert",
        }

        this.id = 'ITEM_SALE_ALERT_TABLE';

        this.selector = { id: TableIDs.genericSelectorSalesAlerts, api: 'get_sale_alert_list' };

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.doOnResize = null;

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => {
                    if (this.props.data[this.id].editing === -1)
                        this.props.dataActions.genericDataStartInlineCreate(this.id)
                },
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ]
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _word: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_0',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericSelectorRefreshDataset(this.selector.id, this.props.user.companyCode, this.props.user.token, this.selector.api);
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => e);
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(this.props.id);
    }

    handleTableChange = (pagination, filters, sorter) => {
        let col = 5;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mItemSaleAlertID": col--;
            case "mItemSaleAlertText": col--;
            case "mItemSaleAlertAsk": col--;
            case "mItemSaleAlertProceedText": col--;
            case "mItemSaleAlertCancelText": break; // 5
            default:
                col = 0;
        }

        //console.log("yuda filters ", filters)

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    createInput = (text, record, key, required) => {
        const { editedRecord } = this.props.data[this.id]
        if (this.props.data[this.id].editing === record.key) {
            return (<Form.Item required={required} validateStatus={required && !editedRecord[key] ? "error" : "success"}>
                <Input
                    style={{ width: '98%', textAlign: 'center' }}
                    value={editedRecord[key]}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { [key]: value.target.value });
                    }} />
            </Form.Item>);
        } else {
            return text;
        }
    }

    createTimePicker = (text, record, key) => {
        const { editedRecord, editing } = this.props.data[this.id]
        if (editing === record.key) {
            return (<DateTimePicker
                format="HH:mm:ss"
                value={editedRecord[key]}
                onChange={(value) => {
                    this.props.dataActions.genericDataSetEdit(this.id, { [key]: value });
                }} />);
        } else {
            return text;
        }
    }

    render() {

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    actionButtons={this.actionButtons}
                    title={this.props.title}
                    isModal={true}
                    showsearch={false}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />
            </div>)


        let columns = [{
            title: this.getText(12575),
            dataIndex: "mItemSaleAlertID",
            key: "mItemSaleAlertID",
            sorter: false,
            width: '8%',
            render: (text, record) => text,
        }, {
            title: this.getText(12576),
            dataIndex: 'mItemSaleAlertText',
            key: 'mItemSaleAlertText',
            sorter: true,
            width: '22%',
            render: (text, record) => this.createInput(text, record, 'mItemSaleAlertText', true),
        }, {
            title: this.getText(12577),
            dataIndex: 'mItemSaleAlertAsk',
            key: 'mItemSaleAlertAsk',
            sorter: true,
            width: '10%',
            render: (text, record) => {
                let source = this.props.data[this.id];
                let isEdit = source.editing === record.key ? true : false;
                let key = 'mItemSaleAlertAsk';
                let checked = isEdit ? source.editedRecord[key] : text;
                return (<Checkbox disabled={!isEdit} checked={checked == 1 ? true : false}
                    onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [key]: e.target.checked ? 1 : 0 }) }}
                />)
            }
        }, {
            title: this.getText(12578),
            dataIndex: 'mItemSaleAlertProceedText',
            key: 'mItemSaleAlertProceedText',
            sorter: true,
            width: '18%',
            render: (text, record) => this.createInput(text, record, 'mItemSaleAlertProceedText', this.props.data[this.id].editedRecord.mItemSaleAlertAsk == '1'),
        }, {
            title: this.getText(12579),
            dataIndex: 'mItemSaleAlertCancelText',
            key: 'mItemSaleAlertCancelText',
            sorter: true,
            width: '18%',
            render: (text, record) => this.createInput(text, record, 'mItemSaleAlertCancelText', this.props.data[this.id].editedRecord.mItemSaleAlertAsk == '1'),
        }, {
            title: this.getText(19522),
            dataIndex: 'mItemSaleAlertTimeFrom',
            key: 'mItemSaleAlertTimeFrom',
            width: '8%',
            render: (text, record) => this.createTimePicker(text, record, 'mItemSaleAlertTimeFrom'),
        }, {
            title: this.getText(19523),
            dataIndex: 'mItemSaleAlertTimeTo',
            key: 'mItemSaleAlertTimeTo',
            width: '8%',
            render: (text, record) => this.createTimePicker(text, record, 'mItemSaleAlertTimeTo'),
        }, {
            title: this.getText(12580),
            width: '8%',
            render: (text, record) => {
                if (this.props.data[this.id].editing !== record.key) {
                    return (
                        <span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                            }} />
                            <Popconfirm title={this.getText(12581)}
                                onConfirm={() => {
                                    this.props.dataActions.genericDataDeleteRow(
                                        this.id,
                                        record['mItemSaleAlertID'],
                                        this.props.user.companyCode,
                                        this.props.user.token,
                                        this.api.delete,
                                        this.api.get);
                                }
                                }
                                okText={this.getText(12582)} cancelText={this.getText(12583)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                            </Popconfirm>
                        </span >);
                } else {
                    const { editedRecord, editing } = this.props.data[this.id]

                    return (<span>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                            onClick={() => {
                                if (!editedRecord.mItemSaleAlertText || (
                                    editedRecord.mItemSaleAlertAsk == '1' && (
                                        !editedRecord.mItemSaleAlertProceedText || !editedRecord.mItemSaleAlertCancelText
                                    )
                                )) {
                                    message.error(this.getText(17956))
                                } else {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id,
                                        this.props.user.companyCode,
                                        this.props.user.token,
                                        editing == 0 ? this.api.create : this.api.edit,
                                        this.api.get);
                                    this.props.dataActions.genericSelectorRefreshDataset(this.selector.id, this.props.user.companyCode, this.props.user.token, this.selector.api);
                                }
                            }} />
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                            onClick={() => {
                                if (editing == 0)
                                    this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                else
                                    this.props.dataActions.genericDataCancelEditing(this.id);
                            }} />
                    </span>)
                }
            }
        }];

        let modalStyle = {
            direction: 'rtl',
        };

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'90%'}
                visible={false || this.props.toggle}
                title={titleBar} // pass title in props for generics
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false} >


                <ResponsiveTable
                    tableOnly={true}
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[20, 40, false, false, 40]}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />

            </Modal >
        )

    }
}
export default ModalSalesAlert;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import JournalEntriesFormat from "./JournalEntriesFormat";


class MoveinFormat extends Component {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(12808);
    }

    render() {

        return (<JournalEntriesFormat
            {...this.props}
            typeFormat="movein"
            title={this.getText(12885)}
        />)
    }

}

export default MoveinFormat;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import { Input, InputNumber, message, Button, Select } from "antd";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class HeshinSettings extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalEdit: null,
        };

        this.id = "HESHIN_SETS";
        this.prm_id = "HESHIN_SETS_PRM";

        this.api = {
            get: "get_heshin_sets",
            create_edit: "create_edit_heshin_sets",
            delete: "delete_heshin_sets",

            get_prm: "get_heshin_sets_prm",
            create_prm: "create_edit_heshin_sets_prm",
        }

        this.selectors = [
            { id: "EXPORT_TABLE_LIST", api: "export_table_list" },
            { id: "EXPORT_COL_LIST", api: "export_col_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.prm_id] === undefined) props.data[this.prm_id] = { ...props.data.genericData };
        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.actionButtons = [
            { buttonType: 'add', buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) } },
            { buttonType: 'refresh', buttonFunction: this.refreshData }
        ]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData();
        this.props.dataActions.genericSelectorRefreshDataset(
            this.selectors[0].id, this.props.user.companyCode, this.props.user.token, this.selectors[0].api);
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15910);
    }

    refreshData = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, {}, this.api.get);
    }

    getName = (text, record) => {
        if (this.props.data[this.id].editing !== record.key) {
            return text
        } else {
            return (<Input
                value={this.props.data[this.id].editedRecord.hName}
                onChange={(e) => {
                    this.props.dataActions.genericDataSetEdit(this.id, { hName: e.target.value })
                }} />)
        }
    }

    getTableList = (text, record) => {
        if (this.props.data[this.id].editing !== record.key) {
            return text
        } else {
            return (<GenericSelector
                {...this.props}
                id={this.selectors[0].id}
                value={this.props.data[this.id].editedRecord.hTableName}
                onChange={(e) => {
                    this.props.dataActions.genericDataSetEdit(this.id, { hTableName: e })
                }} />)
        }
    }

    tableIcons = (text, record) => {
        let gt = this.getText;
        if (this.props.data[this.id].editing !== record.key) {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}
                deleteQuetion={{ title: gt(15914), okText: gt(15915), cancelText: gt(15916) }}
                genericIcons={[
                    {
                        type: 'setting',
                        tooltip: gt(15917),
                        onClick: () => { this.setState({ modalEdit: record }, this.getSetPrm) }
                    }
                ]}
            />)
        } else {
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.id,
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.api.create_edit,
                        this.api.get);
                }}
                onClose={() => {
                    if (this.props.data[this.id].editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id, true);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }}
            />)
        }
    }

    getSetPrm = () => {
        const { hID, hTableName } = this.state.modalEdit;
        this.props.dataActions.genericDataSetFilter(
            this.prm_id, this.props.user.companyCode, this.props.user.token, { hID }, this.api.get_prm);
        // this.sendAPI(this.selectors[1].api, "tName\r" + hTableName, (ob) => {
        //     if (ob.data) {
        //         let dataset = ob.data.split('\r').map(x => {
        //             let v = x.split('\f')[1];
        //             return { code: v, name: v }
        //         });
        //         this.props.dataActions.setJson(this.selectors[1].id, { dataset })
        //     }
        // })
        this.props.dataActions.genericSelectorRefreshDataset(
            this.selectors[1].id, this.props.user.companyCode, this.props.user.token, this.selectors[1].api, { tName: hTableName });

    }

    prmTableIcons = (text, record) => {
        if (this.props.data[this.prm_id].editing !== record.key) {
            return (<TableLineIcons onEdit={() => { this.props.dataActions.genericDataStartEditing(this.prm_id, record.key, record.index) }} />)
        } else {
            return (<TableLineIcons
                onCheck={() => {
                    let prmData = this.props.data[this.prm_id].data.map(x => {
                        let y = x.split('\f');
                        return {
                            hID: this.state.modalEdit.hID,
                            pPrmId: y[1],
                            pOrder: y[2],
                            pLength: y[4],
                            pColumnName: y[5],
                            pDefaultValue: y[6],
                        }
                    });
                    console.log('prmTable', prmData)
                    // this.props.dataActions.genericDataSaveEdit(
                    //     this.prm_id, this.props.user.companyCode, this.props.user.token, this.api.create_edit_prm, this.api.get_prm)
                }}
                onClose={() => { this.props.dataActions.genericDataCancelEditing(this.prm_id) }}
            />)
        }
    }

    getInput = (text, record, field) => {

        let value = text;
        let onChange = (e) => {
            let fIndex = this.props.data[this.prm_id].headers.findIndex(f => f === field);
            let changeOrder = field === 'pOrder' ? parseInt(e) - parseInt(record.pOrder) : 0;
            let data = []
            if (changeOrder) {
                this.props.data[this.prm_id].data.forEach((x, i) => {
                    let y = x.split('\f');
                    let o = parseInt(y[2]);
                    if (i === record.index) {
                        y[2] = e;
                        data[parseInt(e) - 1] = y.join('\f');
                    } else if (changeOrder > 0 && i > record.index && i - record.index <= changeOrder) {
                        y[2] = o - 1;
                        data[o - 2] = y.join('\f');
                    } else if (changeOrder < 0 && i < record.index && i - record.index >= changeOrder) {
                        y[2] = o + 1;
                        data[o] = y.join('\f');
                    } else {
                        data[i] = x
                    }
                })
            } else {
                data = this.props.data[this.prm_id].data.map((x, i) => {
                    if (i !== record.index) {
                        return x
                    } else {
                        return x.split('\f').map((y, yi) => {
                            if (yi !== fIndex) return y
                            else return e
                        }).join('\f')
                    }
                });
            }

            this.props.dataActions.setJson(this.prm_id, { data })
        }
        let pLength = parseInt(record.pLength);
        let pDecimal = parseInt(record.pDecimal);

        switch (field) {
            case 'pOrder':
                let activeCount = this.props.data[this.prm_id].data.filter(f => parseInt(f.split('\f')[4])).length;
                return (<InputNumber value={pLength ? value : null} onChange={onChange} precision={0} min={1} max={activeCount} />);
            case 'pLength':
                return (<InputNumber value={value} onChange={onChange} precision={0} min={0} max={parseInt(record.pMaxLength)} />);
            case 'pColumnName':
                return pLength ?
                    (<Select
                        value={value}
                        onChange={onChange}
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={true}
                        optionFilterProp="valuefilter">
                        {this.props.data[this.selectors[1].id].dataset.map(x => (<Select.Option value={x.name} valuefilter={x.name}>{x.name}</Select.Option>))}
                    </Select>)
                    : ""
            case 'pDefaultValue':
                if (pLength) {
                    if (pDecimal) {
                        let maxNum = '';
                        let maxDec = '';
                        for (let x = 0; x < pLength; x++) { maxNum += '9' }
                        for (let x = 0; x < pDecimal; x++) { maxDec += '9' }

                        return (<InputNumber
                            value={value} onChange={onChange}
                            precision={pDecimal} min={0} max={parseFloat(maxNum + '.' + maxDec)}
                            style={{ width: '100%' }} />)
                    } else {
                        return (<Input
                            value={value}
                            onChange={(e) => {
                                let textValue = e.target.value;
                                if (textValue.length > pLength) message.error(this.getText(15930) + pLength + ' ' + this.getText(15931))
                                else onChange(textValue)
                            }} />)
                    }
                }
        }
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    handleOk = () => {
        let notValid = this.props.data[this.prm_id].data.filter(f => {
            let y = f.split('\f');
            return parseInt(y[4]) && !y[5] && !y[6];
        });

        console.log('notValid', notValid)

        if (notValid.length) {
            message.error(this.getText(15935))
        } else {

            let dataSend = this.props.data[this.prm_id].headers.join('\f')
                + '\fhID\r'
                + this.props.data[this.prm_id].data.join('\f' + this.state.modalEdit.hID + '\r')
                + '\f' + this.state.modalEdit.hID;

            this.sendAPI(this.api.create_prm, dataSend, this.handleCancel)
        }
    }

    handleCancel = () => {
        this.setState({ modalEdit: null }, () => {
            this.props.dataActions.setJson(this.prm_id, { data: [], count: 0 })
        })
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            maxWidth: 1000,
        };

        let numOfRowsByHeight = parseInt((window.innerHeight - 340 > 30) ? (window.innerHeight - 340) / 30 : 1);

        return (<div style={divStyle}>
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                title={gt(15911)}
                actionButtons={this.actionButtons}
                columns={[
                    { title: gt(15912), key: 'hName', dataIndex: 'hName', width: '40%', render: this.getName },
                    { title: gt(15922), key: 'hTableName', dataIndex: 'hTableName', width: '40%', render: this.getTableList },
                    { title: gt(15913), width: '20%', render: this.tableIcons },
                ]}
                pagination={{ pageSize: numOfRowsByHeight }}
            />
            <GenericModal
                visible={this.state.modalEdit !== null}
                onCancel={this.handleCancel}
                title={this.state.modalEdit ? gt(15932) + ' - ' + this.state.modalEdit.hName : ''}
                actionButtons={[{ buttonType: 'refresh', buttonFunction: this.getSetPrm }]}
                footer={[
                    <Button style={{ margin: 5 }} onClick={this.handleCancel}>{gt(15934)}</Button>,
                    <Button type="primary" style={{ margin: 5 }} onClick={this.handleOk}>{gt(15933)}</Button>,
                ]}
            >
                <ResponsiveTable
                    idTable={this.props.data[this.prm_id]}
                    tableOnly
                    columns={[
                        { title: gt(15927), key: 'pOrder', dataIndex: 'pOrder', width: '5%', render: (t, r) => this.getInput(t, r, 'pOrder') },
                        { title: gt(15918), key: 'pDescription', dataIndex: 'pDescription', width: '25%', render: t => t },
                        { title: gt(15928), key: 'pMaxLength', dataIndex: 'pMaxLength', width: '10%', render: t => t },
                        { title: gt(15929), key: 'pDecimal', dataIndex: 'pDecimal', width: '5%', render: t => t },
                        { title: gt(15919), key: 'pLength', dataIndex: 'pLength', width: '10%', render: (t, r) => this.getInput(t, r, 'pLength') },
                        { title: gt(15923), key: 'pColumnName', dataIndex: 'pColumnName', width: '20%', render: (t, r) => this.getInput(t, r, 'pColumnName') },
                        { title: gt(15924), key: 'pDefaultValue', dataIndex: 'pDefaultValue', width: '25%', render: (t, r) => this.getInput(t, r, 'pDefaultValue') },
                        // { title: gt(15926), width: '5%', render: this.prmTableIcons },
                    ]}
                    pagination={{ pageSize: numOfRowsByHeight > 4 ? numOfRowsByHeight - 4 : numOfRowsByHeight }}
                />
            </GenericModal>
        </div>)
    }

}

export default HeshinSettings;
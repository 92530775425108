/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

// type Props {
//     width: Number,
//     height: Number,
//     rotate: Number,
//     image: String,
//     top: Number,
//     left: Number,
//     fullWidth: Number,
//     onClick: Function,
//     title: String,
// }


class DividedImage extends Component {
    componentDidUpdate(p) {
        if (p.top !== this.props.top) console.log(this.props.top, 'scrollVirtualShop')
    }
    render() {
        const { width, height, rotate, image, top, left, fullWidth, onClick, title } = this.props;

        let style = {
            width,
            height,
            overflow: 'hidden',
            display: 'inline-block',
            transform: 'rotate(' + rotate + 'deg)',
        }

        if (top && left) style = { ...style, position: 'fixed', top, left }
        if (onClick) style = { ...style, cursor: 'pointer' }

        return (<div style={style} onClick={onClick}>
            <img src={image} alt={title} width={fullWidth} />
        </div>)
    }

}
export default DividedImage;
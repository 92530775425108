/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { Button, Tooltip } from "antd";
import LangContext from "../contextProvider/LangContext";


class MatrixGridTile extends React.Component<Props, State> {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    onDoubleClick = () => {
        this.props.removeSonItem(this.props.row.code, this.props.col.code, this.props.col.status)
    }

    onClick = () => {
        this.props.addSonItem(this.props.row.code, this.props.col.code, this.props.col.status)
    }

    onFocus = (e) => {
        this.props.onFocus(this.props.row.code, this.props.col.code, this.props.col.isSet, this.props.col.isSelected, e.target)
    }

    onKeyDown = (e) => {
        this.props.onTypeBarcode2(e, this.props.row.code, this.props.col.code, this.props.col.status)
    }

    // shouldComponentUpdate(nextProps) {
    //     console.log('shouldComponentUpdate MatrixGridTile', this.props.col.status, nextProps.col.status)
    //     return this.props.col.status !== nextProps.col.status;
    // }

    render() {
        const { col, row, maxTileWidth, rowLength, colLength } = this.props;

        const bStyle = {
            backgroundColor: (col.status === 3) ? '#808080' : (col.status === 2) ? '#4CAF50' : (col.status === 1) ? '#f44336' : null,
            display: 'inline-block',
            borderStyle: 'solid', borderWidth: 0.5,
            minHeight: maxTileWidth, minWidth: maxTileWidth,
            maxHeight: maxTileWidth, maxWidth: maxTileWidth,
            height: (rowLength).toString() + '%',
            width: (colLength).toString() + '%',

        }

        return (<div key={row.code + '#' + col.code + "#div"} style={{ display: 'inline' }}>
            <Tooltip title={<div>{this.getText(10663)}: {row.name}<br />{this.getText(10664)}: {col.name}</div>}>
                <Button
                    id={"matrix-grid-tile-row-"+ row.code + '-col-' + col.code}
                    key={row.code + '#' + col.code}
                    value={row.code + '#' + col.code}
                    style={bStyle}
                    onDoubleClick={this.onDoubleClick}
                    onClick={this.onClick}
                    onFocus={this.onFocus}
                    onBlur={this.props.onBlur}
                    onKeyDown={this.onKeyDown}
                />
            </Tooltip >
        </div>)
    }
}

export default MatrixGridTile;

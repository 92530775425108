/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import GenericSelector from "../../components/GenericSelector";
import GenericModal from "../../components/GenericModal";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
import { Input, Checkbox, message } from "antd";


class ButtonActions extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalParams: null,
        };

        this.id = "BUTTON_ACTIONS_TABLE";
        this.params_id = "BUTTON_ACTION_PARAMETERS";

        this.api = {
            get: "get_button_actions",
            create_edit: "create_edit_button_actions",

            get_params: "get_button_action_parameters",
            create_edit_params: "create_edit_button_action_parameters",
            delete_params: "delete_button_action_parameters",
        }

        this.selectors = [
            { id: "ELECTIVE_MODULES_LIST", api: "elective_modules_list" },
            { id: "VALIDATION_METHODS_LIST", api: "validation_methods_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.forEach(x => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })

    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15508);
    }

    getDescription = (text, record) => {
        let data = this.props.data[this.id];
        if (record.key !== data.editing) {
            return text;
        } else {
            return (<Input
                value={data.editedRecord.nDescription}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { nDescription: e.target.value }) }}
            />)
        }
    }

    getConditionalModule = (text, record) => {
        let data = this.props.data[this.id];

        return (<GenericSelector
            {...this.props}
            id={this.selectors[0].id}
            disabled={record.key !== data.editing}
            value={record.key === data.editing ? data.editedRecord.nConditionalModule : text}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { nConditionalModule: e }) }}
            addFirstRow={{ code: '0', name: this.getText(15513) }}
        />)
    }

    getEditIcons = (text, record) => {
        let data = this.props.data[this.id];
        if (record.key !== data.editing) {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                // onDelete={() => {
                //     fixCurrentPage.bind(this)();
                //     this.props.dataActions.genericDataDeleteRow(this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                // }}
                // deleteQuetion={{ title: this.getText(11821), okText: this.getText(11822), cancelText: this.getText(11823) }}
                genericIcons={[{
                    type: "table",
                    tooltip: this.getText(15515),
                    onClick: () => { this.setState({ modalParams: record }, this.apiParameters) }
                }]}
            />)
        } else {
            let isCreate = (data.editing == 0) ? true : false;
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, { lang: this.getText(100) })
                }}
                onClose={() => {
                    if (isCreate) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }}
            />)
        }
    }

    apiParameters = () => {
        this.props.dataActions.genericDataSetFilter(
            this.params_id,
            this.props.user.companyCode,
            this.props.user.token,
            { _id: this.state.modalParams ? this.state.modalParams.nID : null },
            this.api.get_params)
    }

    getParameterName = (text, record) => {
        let data = this.props.data[this.params_id];
        if (record.key !== data.editing) {
            return text;
        } else {
            return (<Input
                value={data.editedRecord.pName}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.params_id, { pName: e.target.value }) }}
            />)
        }
    }

    getParameterValidationMethod = (text, record) => {
        let data = this.props.data[this.params_id];

        return (<GenericSelector
            {...this.props}
            id={this.selectors[1].id}
            disabled={record.key !== data.editing}
            value={record.key === data.editing ? data.editedRecord.pValidationMethod : text}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.params_id, { pValidationMethod: e }) }}
        />)
    }

    getParameterIsRequired = (text, record) => {
        let data = this.props.data[this.params_id];

        return (<Checkbox
            disabled={record.key !== data.editing}
            checked={record.key === data.editing ? data.editedRecord.pIsRequired == '1' : text == '1'}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.params_id, { pIsRequired: e.target.checked ? '1' : '0' }) }}
        />)
    }

    getParameterEditIcons = (text, record) => {
        let data = this.props.data[this.params_id];
        if (record.key !== data.editing) {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.params_id, record.key, record.index) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRowWithParams(
                        this.params_id, 
                        {_id: this.state.modalParams.nID, _number: record.key}, 
                        this.props.user.companyCode, this.props.user.token, this.api.delete_params, this.api.get_params);
                }}
                deleteQuetion={{ title: this.getText(15521), okText: this.getText(15522), cancelText: this.getText(15523) }}
            />)
        } else {
            let isCreate = (data.editing == 0) ? true : false;
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.params_id, this.props.user.companyCode, this.props.user.token,
                        this.api.create_edit_params, this.api.get_params, { _id: this.state.modalParams.nID })
                }}
                onClose={() => {
                    if (isCreate) this.props.dataActions.genericDataCancelInlineCreate(this.params_id);
                    else this.props.dataActions.genericDataCancelEditing(this.params_id);
                }}
            />)
        }
    }

    render() {
        var divStyle = {
            align: 'center',
            dir: 'rtl', // dir: 'ltr'
            // padding: '2% 2% 2% 2%',
            paddingTop: 2,
            maxWidth: 800,
            margin: 'auto',
        }

        let columns = [
            { title: this.getText(15510), key: 'nDescription', dataIndex: 'nDescription', width: '40%', render: this.getDescription },
            { title: this.getText(15511), key: 'nConditionalModule', dataIndex: 'nConditionalModule', width: '40%', render: this.getConditionalModule },
            { title: this.getText(15512), width: '20%', render: this.getEditIcons },
        ];

        let actionButtons = 
            [{
                buttonType: 'add',
                buttonFunction: () => { if (this.props.data[this.id].editing === -1) this.props.dataActions.genericDataStartInlineCreate(this.id) }
            }];

        let paramColumns = [
            { title: this.getText(15517), key: 'pName', dataIndex: 'pName', width: '30%', render: this.getParameterName },
            { title: this.getText(15518), key: 'pValidationMethod', dataIndex: 'pValidationMethod', width: '30%', render: this.getParameterValidationMethod },
            { title: this.getText(15519), key: 'pIsRequired', dataIndex: 'pIsRequired', width: '20%', render: this.getParameterIsRequired },
            { title: this.getText(15520), width: '20%', render: this.getParameterEditIcons },
        ];

        let paramActionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => {
                    if (this.props.data[this.params_id].editing === -1) {
                        if (this.props.data[this.params_id].data.length < 4) {
                            this.props.dataActions.genericDataStartInlineCreate(this.params_id)
                        } else {
                            message.error(this.getText(15524))
                        }
                    }
                }
            }, {
                buttonType: 'refresh',
                buttonFunction: () => {
                    this.props.dataActions.genericDataGetRequested(
                        this.params_id, this.props.user.companyCode, this.props.user.token, this.api.get_params);
                }
            }
        ]

        return (<div style={divStyle}>
            <GenericTable
                id={this.id}
                getApi={this.api.get}
                title={this.getText(15509)}
                filters={{ lang: this.getText(100) }}
                columns={columns}
                actionButtons={actionButtons}
                searchBox
                {...this.props}
            />
            <GenericModal
                visible={this.state.modalParams!==null}
                onCancel={() => { this.setState({ modalParams: null }) }}
                width={700}
                title={this.getText(15516) + " - " + (this.state.modalParams ? this.state.modalParams.nDescription : '')}
                actionButtons={paramActionButtons}
            >
                <ResponsiveTable
                    idTable={this.props.data[this.params_id]}
                    columns={paramColumns}
                    tableOnly
                    pagination={false}
                    // scroll={{ y: window.innerHeight / 2 }}
                    {...this.props}
                />
            </GenericModal>
        </div>)
    }

}

export default ButtonActions;
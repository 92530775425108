
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, Select, Checkbox } from "antd";
//import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesAnalysis extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            period1DateFrom: null,
            period1DateTo: null,
            period2DateFrom: null,
            period2DateTo: null,
            storageList: '',
            typeReport: '1',
            perLines: '0',
            mOnlyDefault: '0',
            differenceType: '4',
            _withSendingDocs: '1',
            reportName: "reports/SalesAnalysis.pdf",
        };

        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(14559);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    createDatePicker = (label, field) => {
        return (<FormItem label={this.getText(label)}>
            <DateTimePicker
                format="DD/MM/YYYY"
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                minDate={field == 'period1DateTo' ? this.state.period1DateFrom : field == 'period2DateTo' ? this.state.period2DateFrom : null}
                maxDate={field == 'period1DateFrom' ? this.state.period1DateTo : field == 'period2DateFrom' ? this.state.period2DateTo : null}
            />
        </FormItem>)
    }


    render() {

        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;

        let params = this.state;
        // let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        // if (params.storageList === '') params = { ...params, storageList: fullStoragesList }


        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={this.getText(14560)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: '95%' }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 8}>
                        <h2>{this.getText(14561)}</h2>
                        <Row>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14563, 'period1DateFrom')}</Col>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14564, 'period1DateTo')}</Col>
                        </Row>
                        <h2>{this.getText(14562)}</h2>
                        <Row>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14563, 'period2DateFrom')}</Col>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14564, 'period2DateTo')}</Col>
                        </Row>
                        <FormItem>
                            <CheckboxIncludeSendingDocs
                                value={this.state._withSendingDocs}
                                onChange={e => { this.setState({ _withSendingDocs: e }) }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={mobile ? 24 : 8}>
                        <FormItem label={this.getText(14565)}>
                            <GenericSelector
                                multi
                                id={TableIDs.genericSelectorStorages}
                                api={"get_storage_list"}
                                value={this.state.storageList ? this.state.storageList.split(',') : []}
                                onChange={(e) => { this.setState({ storageList: e ? e.join(',') : '' }) }}
                                data={this.props.data}
                                dataActions={this.props.dataActions}
                                user={this.props.user}
                            />
                        </FormItem>
                        <FormItem label={this.getText(14566)}>
                            <Select
                                style={{ width: 300 }}
                                value={this.state.typeReport}
                                onChange={(e) => { this.setState({ typeReport: e }) }}>
                                <Option key={1} value={'1'}>{this.getText(14567)}</Option>
                                <Option key={2} value={'2'}>{this.getText(14568)}</Option>
                                <Option key={3} value={'3'}>{this.getText(14569)}</Option>
                                <Option key={4} value={'4'}>{this.getText(18356)}</Option>
                                <Option key={5} value={'5'}>{this.getText(14570)}</Option>
                            </Select>
                        </FormItem>
                        {this.state.typeReport == 1 ? (
                            <FormItem>
                                <Checkbox
                                    checked={this.state.mOnlyDefault == 1}
                                    onChange={(e) => {
                                        this.setState({ mOnlyDefault: e.target.checked ? '1' : '0' });
                                    }}>{this.getText(15370)}</Checkbox>
                            </FormItem>)
                            : ''}
                        <FormItem label={this.getText(15595)}>
                            <Select
                                style={{ width: 250 }}
                                value={this.state.perLines}
                                onChange={(e) => { this.setState({ perLines: e }) }}>
                                <Option key={'0'} value={'0'}>{this.getText(15597)}</Option>
                                <Option key={'1'} value={'1'}>{this.getText(15596)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(15600)}>
                            <Select
                                style={{ width: 250 }}
                                value={this.state.differenceType}
                                onChange={(e) => { this.setState({ differenceType: e }) }}>
                                <Option key={1} value={'1'}>{this.getText(15601)}</Option>
                                <Option key={2} value={'2'}>{this.getText(15602)}</Option>
                                <Option key={3} value={'3'}>{this.getText(15603)}</Option>
                                <Option key={4} value={'4'}>{this.getText(15604)}</Option>
                            </Select>
                        </FormItem>

                    </Col>
                    <Col span={mobile ? 24 : 8}>
                        <ReportTypeButton
                            {...this.props}
                            params={params}
                            storagesField={'storageList'}
                            datesToFavorites={[
                                { field: 'period1DateFrom', label: this.getText(14561) + ' - ' + this.getText(14563) },
                                { field: 'period1DateTo', label: this.getText(14561) + ' - ' + this.getText(14564) },
                                { field: 'period2DateFrom', label: this.getText(14562) + ' - ' + this.getText(14563) },
                                { field: 'period2DateTo', label: this.getText(14562) + ' - ' + this.getText(14564) },
                            ]}
                            favoriteSave
                        />
                    </Col>
                </Row>

            </Card>
        </div >
        );
    }
}
export default SalesAnalysis;
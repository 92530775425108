
import React from "react";
import { notification, Form, Input, Button, Modal, Select, message, Upload, Switch, Radio, Checkbox } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import moment from "moment";
import LangContext from "../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;


//type State = {}
class ModalDownloadPLUfile extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            loadingImg: false,

            isItemCode: "0", // isItemCode: true,
            priceListId: null,
            format: null,
            isOnlyWithPrice: false,
        }

        this.id = 'MODAL_DOWNLOAD_PLU_FILE';

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state.count = 0;
        //this.state.itemPick = null;
        //this.fieldsMeta = { unknown: { validateStatus: null, value: null } };

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }


        this.api = {
            get: "get_weighable_items",
            selector: "get_prices",
        }

        this.actionButtons = [
            /*{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/];

        this.dataSource = [];

        this.openNotificationWithIcon = (type, msg, descr) => {
            notification[type]({
                message: msg,
                description: descr,
            });
        };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - לקוחות להוראת קבע";
        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorPriceList, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleOkLeave = () => {
        //this.clear();
        //this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalPLUfile);
    }
    handleClear = () => {
        //this.clear();
    }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorPriceList, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    onChange = (e) => {
        this.setState({ priceListId: e });
    }

    onFormatChange = (e) => {
        this.setState({ format: e });
    }

    render() {
        const { loading, loadingImg, isItemCode, priceListId, isOnlyWithPrice, format } = this.state;
        //const sqlFormat = "YYYY-MM-DD";
        //const timeFormat = "HH:mm:ss";
        //const dateFormat = "DD/MM/YYYY";

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    // title={this.getText(10818)}
                    title={"ממשק ייצוא קובץ PLU"}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        const list = this.props.data[TableIDs.genericSelectorPriceList].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        });


        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'40%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                {/* <div style={{ textAlign: 'center', fontSize: '24px' }}>{" " + this.getText(10819) + " " + this.state.count + " " + this.getText(10820)}</div> */}
                <div>
                    <div style={{ marginRight: 8, marginBottom: 16 }}>
                        <span>{"מזהה:"}</span>

                        {/* <Switch
                            style={{ marginRight: 8}}
                            checkedChildren="קוד" unCheckedChildren="ברקוד"
                            onChange={(e) => { this.setState({ isItemCode: e }, () => { }) }}
                            value={isItemCode}
                        /> */}
                        <Radio.Group
                            style={{ textAlign: 'right', direction: "rtl" }}
                            value={this.state.isItemCode}
                            onChange={e => { this.setState({ isItemCode: e.target.value }) }}>
                            <Radio value="0">{"ברקוד"}</Radio>
                            <Radio value="1">{"קוד"}</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{ marginRight: 8, marginBottom: 16 }}>
                        <span>{"מחירון:"}</span>
                        <Select /*disabled={readOnly}*/ value={priceListId} style={{ width: 250, marginRight: 4 }} onChange={this.onChange}>{list}</Select>
                    </div>
                    <div style={{ marginRight: 8, marginBottom: 16 }}>
                        <span style={{ marginLeft: 8 }}>{"האם לייצא פריטים עם מחירון בלבד:"}</span>
                        <Checkbox
                            checked={isOnlyWithPrice}
                            onChange={(e) => { this.setState({ isOnlyWithPrice: e.target.checked }) }}></Checkbox>
                    </div>
                    <div style={{ marginRight: 8, marginBottom: 16 }}>
                        <span >{"פורמט:"}</span>
                        <Select /*disabled={readOnly}*/ value={format} style={{ width: 250, marginRight: 4 }} onChange={this.onFormatChange}>
                            <Option key={1} value={1}>Windows (CRLF)</Option>
                            <Option key={2} value={2}>Unix (CR)</Option>
                            <Option key={3} value={3}>Mac (LF)</Option>
                        </Select>
                    </div>
                </div>
                <div className="action_footer" style={{ textAlign: 'center', marginTop: 16 }}>
                    <Button
                        //style={{ display: 'inline-block' }}
                        onClick={() => {
                            if (priceListId) {
                                this.getFile();
                            }
                            else {
                                message.info("נא לבחור מחירון", 3, null);
                            }
                        }}
                        type="primary"
                        //htmlType="submit" 
                        //form="create_edit_branch_form"
                        loading={this.props.ui.workInProgress}
                        className={"form-button-save"} icon={"download"}>{"הורדה"/*this.getText(10821)*/}</Button>
                </div>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toggle === false && this.props.toggle === true) {
            //this.getCount();
        }

    }

    getCount = () => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, /*"upload_image"*/this.api.getCount,
            //OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC);
            OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        //job.setSection("file", fileUrl);
        //job.setInput();
        //job.setInput(fileUrl);
        job.send("/cgi-bin/CashOnTab", this.SuccessCallback, this.ErrorCallback);
    }

    SuccessCallback = (ob: Object) => {
        console.log("success:", ob.data, 2);
        this.setState({ count: ob.data });
    }

    ErrorCallback = (error: any) => {
        message.error(this.getText(10822), 2);
    }

    getFile = () => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.api.get, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        //job.setInput("_PriceListId\r" + this.state.priceListId);
        let val = (this.state.isOnlyWithPrice) ? "1" : "0";
        let data = "_PriceListId\f_isOnlyWithPrice\r" + this.state.priceListId + "\f" + val;
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", this.downloadSuccessCallback, this.downloadErrorCallback);
    }

    // Convert an Uint8Array into a string.
    decodeuint8arr = (uint8array, format) => {
        return new TextDecoder(format).decode(uint8array);
    }

    // Convert a string into a Uint8Array.
    encodeuint8arr = (myString, format) => {
        return new TextEncoder(format).encode(myString);
    }

    downloadSuccessCallback = (ob: Object) => {
        message.success(this.getText(10823));
        console.log("success:", ob.data);
        /*
        ItemId
        ItemCode
        ItemBarCode
        ItemName
        ItemShortName
        PricesPriceVal
        */
        //var text = ob.data;
        let nl = '\r';
        switch (this.state.format) {
            case 1:
                nl = '\r\n';
                break;
            case 2:
                nl = '\n';
                break;
            case 3:
                nl = '\r';
                break;
            default:
                nl = '\r';
                break;
            // code block
        }

        var rowArr = ((ob.data) ? ob.data : "")
            .split('\r')
            .map(i => {
                let cols = i.split('\f');
                //let _id = "0".repeat(30).concat((this.state.isItemCode === true) ? cols[1] : cols[2]).substr(-13);
                let _id = "0".repeat(30).concat((this.state.isItemCode === "1" || this.state.isItemCode === 1) ? cols[1] : cols[2]).substr(-13);
                let _desc = (cols[3] ?? "").concat(" ".repeat(30)).substr(0, 20);
                let _price = "0".repeat(30).concat(cols[5]).substr(-6);
                return "".concat(_id, " ", _desc, " ", "1", " ", _price, " ", "0".repeat(6), " ", "0".repeat(2));
            })
        //var text = rowArr.join('\r'); 
        // add flag for end of line type (1= CRLF = win | 2 = LF = unix | 3 = CR = mac)
        var text = rowArr.join(nl);

        /*
        var bytesArr = [];
        for(var i = 0; i < str.length; i++) {
            var char = str.charCodeAt(i);
            bytes.push(char >>> 8);
            bytes.push(char & 0xFF);
        }
        */

        text = this.bidiText(text);


        var uint8 = new Uint8Array(text.length);
        for (var i = 0; i < uint8.length; i++) {
            let b = text.charCodeAt(i);
            if (b >= 1488 && b <= 1514) {
                // heb convert
                b -= 1360;
            }
            uint8[i] = b;
            // TODO: check more special chars to convert
        }

        // ;charset=Windows-1252
        // Windows-1255 heb
        var blob = new Blob([uint8]/*[text]*/, { type: 'text/plain;charset=cp862' }); // ;charset=windows-1255
        //var blob = new Blob([text ], { type: 'text/plain;charset=windows-1252' });
        // var blob = new Blob(/*[uint8]*/[text], { type: 'text/plain' });
        var anchor = document.createElement('a');

        anchor.download = "plu.txt";
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        //anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
        //anchor.dataset.downloadurl = ['text/plain;utf-8', anchor.download, anchor.href].join(':');
        anchor.dataset.downloadurl = ['text/plain;charset=cp862', anchor.download, anchor.href].join(':');
        // anchor.dataset.downloadurl = ['text/plain;charset=Windows-1252', anchor.download, anchor.href].join(':');
        anchor.click();
    }

    downloadErrorCallback = (error: any) => {
        message.error(this.getText(10822) + " " + JSON.Stringify(error));
    }

    bidiText = (text) => {
        let parts = []; // first part, ltr
        let lastChar = 0 // 0 - none // 1 - R // 2 - L

        for (let i = 0; i < text.length; i++) {
            //let b = text.charCodeAt(i);
            let ch = text[i];

            if (ch.match(/[א-ת]/)) {                // rtl
                if (lastChar !== 1) {
                    if (lastChar === 0) {
                        // pseudo ltr part
                        parts.push(i);
                    }
                    parts.push(i);
                }
                lastChar = 1;
            } else if (ch.match(/[a-zA-Z0-9`]/)) {   // ltr
                if (lastChar !== 2) {
                    parts.push(i);
                }
                lastChar = 2;
            } else {                                // non
                //lastChar = 0;
                lastChar = lastChar;
            }
        }

        let bidiText = '';
        for (let j = 0; j < parts.length; j++) {
            let a = parts[j];
            let b = ((j + 1) === parts.length)
                ? (text.length)
                : (parts[j + 1]);

            if (j % 2 === 1) {
                if (b === text.length) {
                    bidiText = bidiText.concat(
                        text.slice(a, b)
                            .split("")
                            .reverse()
                            .join("")
                    )
                } else {
                    bidiText = bidiText.concat(
                        text.slice(a, b - 1)
                            .split("")
                            .reverse()
                            .join(""), ' '
                    )
                }

            } else {
                bidiText = bidiText.concat(text.slice(a, b));
            }

            //bidiText = bidiText.concat(text.slice(a + 1, b));
        }
        //String.fromCharCode(65)
        return bidiText;
    }
}


export default ModalDownloadPLUfile;

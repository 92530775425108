
import React from "react";
import {
    DatePicker, notification, Form, Input, Button, Modal,
    Select, message, Switch, Checkbox, InputNumber
} from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import Jax from "../Jax/jax.es6.module";
import moment from "moment";
import CustomerPicker from "./CustomerPicker";
import LangContext from "../contextProvider/LangContext";
import DateTimePicker from "./DateTimePicker";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

//type State = {}
class ModalCustomerDebtDetailedReport extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            loadingImg: false,
        }

        this.id = 'MODAL_CUSTOMER_DEBT_DETAILED_REPORT';

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        //this.state._count = 0;
        //this.state._id = 0;

        this.state.CustomerDebtDetailed = {
            mID: null,
            mLowerDate: null,
            mUpperDate: null,
            isWithPurchase: 1,
            isWithStandardInvoice: 1,
            isWithMatchDocs: 1,
            isWithOpenBalance: 1,
            reportName: "reports/CustomerDebtDetailed.pdf",
        };

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorStandingOrderStatus] === undefined) {
            props.data[TableIDs.genericSelectorStandingOrderStatus] = { ...props.data.genericSelector };
        }

        this.api = {
            selector: "get_standing_order_status_list",
        }

        this.actionButtons = [];
        this.dataSource = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //document.title = "Cash On Tab - לקוחות חובות";
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStandingOrderStatus, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("CustomerDebtDetailed", {
                            [field]: data,
                            StatusTextList: encodeURIComponent(Jax.get(this.props.data, TableIDs.genericSelectorStandingOrderStatus + ".dataset", [])
                                .filter((obj, index) => data.split(',').includes(obj['code']))
                                .map((obj, index) => obj['name'])
                                .join(','))
                        });
                    else
                        this.setReportParameters("CustomerDebtDetailed", { [field]: '', StatusTextList: '' });
                }} />);
    }

    handleOkLeave = () => { this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch); }
    handleOk = () => { }
    handleCancel = () => { this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch); }
    handleClear = () => { }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStandingOrderStatus, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    disabledStartDate = (FromDate) => {
        const ToDate = this.state.CustomerDebtDetailed.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.CustomerDebtDetailed.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("CustomerDebtDetailed", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => { this.onChange('mLowerDate', value ? value.format("YYYY-MM-DD") : null); }
    onEndChange = (value) => { this.onChange('mUpperDate', value ? value.format("YYYY-MM-DD") : null); }

    onChangeAction = (field, value) => {
        this.setReportParameters("CustomerDebtDetailed", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChangeAction = (value) => { this.onChangeAction('mLowerDate', value ? value.format("YYYY-MM-DD") : null); }
    onEndChangeAction = (value) => { this.onChangeAction('mUpperDate', value ? value.format("YYYY-MM-DD") : null); }

    render() {
        const { loading, loadingImg } = this.state;
        //const sqlFormat = "YYYY-MM-DD";
        //const timeFormat = "HH:mm:ss";
        //const dateFormat = "DD/MM/YYYY";

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        const list = this.props.data[TableIDs.genericSelectorStandingOrderStatus].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        });

        const inputStyle = { marginLeft: '4px', width: '40%' };

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'60%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                <Form id="report_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '3' : '1' }}
                    onSubmit={this.handleSaveChanges}
                >

                    <FormItem label={this.getText(10761)} /*label={"מתאריך חיוב:"}*/>
                        {/* <DatePicker
                            disabledDate={this.disabledStartDate}
                            showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            //format="D/M/YY"
                            //value={FromDate ? moment(FromDate, "YYYY-MM-DD") : null}
                            placeholder={this.getText(10765)}
                            onChange={this.onStartChangeAction}
                        //onOpenChange={this.handleStartOpenChange}
                        /> */}
                        <DateTimePicker
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            maxDate={this.state.CustomerDebtDetailed.mUpperDate}
                            value={this.state.CustomerDebtDetailed.mLowerDate}
                            placeholder={this.getText(10765)}
                            onChange={(value) => { this.onChangeAction('mLowerDate', value) }}
                        />

                    </FormItem>
                    <FormItem label={this.getText(10762)} /*label={"עד תאריך חיוב:"} style={isEndDateShownStyle}*/>
                        {/* <DatePicker
                            disabledDate={this.disabledEndDate}
                            showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            //format="D/M/YY"
                            //value={ToDate ? moment(ToDate, "YYYY-MM-DD") : null}
                            placeholder={this.getText(10766)}
                            onChange={this.onEndChangeAction}
                        //open={endOpen}
                        //onOpenChange={this.handleEndOpenChange}
                        /> */}
                        <DateTimePicker
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            minDate={this.state.CustomerDebtDetailed.mLowerDate}
                            value={this.state.CustomerDebtDetailed.mUpperDate}
                            placeholder={this.getText(10766)}
                            onChange={(value) => { this.onChangeAction('mUpperDate', value) }}
                        />
                    </FormItem>

                    <FormItem label={this.getText(10763)}>
                        <Switch checkedChildren={this.getText(10767)} unCheckedChildren={this.getText(10768)}
                            onChange={(value) => this.setReportParameters("CustomerDebtDetailed", { isWithPurchase: (value === true) ? 1 : 0 })}
                            checked={(this.state.CustomerDebtDetailed.isWithPurchase === 1) ? true : false}
                        />
                    </FormItem>

                    <FormItem label={this.getText(15836)}>
                        <Switch checkedChildren={this.getText(10767)} unCheckedChildren={this.getText(10768)}
                            onChange={(value) => this.setReportParameters("CustomerDebtDetailed", { isWithStandardInvoice: (value === true) ? 1 : 0 })}
                            checked={(this.state.CustomerDebtDetailed.isWithStandardInvoice === 1) ? true : false}
                        />
                    </FormItem>

                    <FormItem label={this.getText(18149)}>
                        <Switch checkedChildren={this.getText(10767)} unCheckedChildren={this.getText(10768)}
                            onChange={(value) => this.setReportParameters("CustomerDebtDetailed", { isWithMatchDocs: (value === true) ? 1 : 0 })}
                            checked={(this.state.CustomerDebtDetailed.isWithMatchDocs === 1) ? true : false}
                        />
                    </FormItem>

                    <FormItem label={this.getText(18303)}>
                        <Switch checkedChildren={this.getText(10767)} unCheckedChildren={this.getText(10768)}
                            onChange={(value) => this.setReportParameters("CustomerDebtDetailed", { isWithOpenBalance: (value === true) ? 1 : 0 })}
                            checked={(this.state.CustomerDebtDetailed.isWithOpenBalance === 1) ? true : false}
                        />
                    </FormItem>

                    <FormItem key={"reportName"} label={this.getText(10764)}>
                        <Select style={{ width: 250 }}
                            //size={"large"}
                            className={"reports/CustomerDebtDetailed.pdf"}
                            //combobox={null}
                            defaultValue={"pdf"}
                            onChange={(value) => {
                                this.setReportParameters("CustomerDebtDetailed", {
                                    reportName: "reports/CustomerDebtDetailed." + value,
                                    type: value
                                });
                            }}
                        >
                            <Option key={"pdf"}>{this.getText(10769)}</Option>
                            <Option key={"xls"}>{this.getText(10770)}</Option>
                            <Option key={"docx"}>{this.getText(10771)}</Option>
                        </Select>
                    </FormItem>

                    {/*
                    <FormItem key={"submit"}>
                        <Button type={"primary"} onClick={() => {
                            //const type = this.state.RequestedReport;
                            this.generateReport.apply(this, ["CustomerDebtDetailed"])
                        }}>הפק דו"ח</Button>
                    </FormItem>
                    */}
                </Form>
                <div className="action_footer">
                    <Button type="primary" htmlType="submit" form="report_form" onClick={() => {
                        //const type = this.state.RequestedReport;
                        this.generateReport.apply(this, ["CustomerDebtDetailed"])
                    }}>{this.getText(10772)}</Button>
                </div>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toggle === false && this.props.toggle === true) {
            this.setReportParameters("CustomerDebtDetailed", { mID: this.props.ui.data })
        }
    }
}

export default ModalCustomerDebtDetailedReport;

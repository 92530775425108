// @flow
/*jshint esversion: 6 */
/*jslint es6*/
import { ReduceStore } from "flux/utils";
import ActionTypes from "./ActionTypes";
//import Actions from "./Actions";
import Dispatcher from "./Dispatcher";
import { JobResponse, ReportBlob, Job, User, ProcessType, OutputType } from "../JmReact";
import Jax from "../Jax/jax.es6.module";
//import ActionQueue from "./ActionQueue";
import LangContext from "../contextProvider/LangContext";

export type duplet = {
    code: string,
    name: string,
}

type selector = {
    data: Array<duplet>,
    picks: Array<duplet>,
    pick: string
}

type FormsState = {
    ongoingRequests: number,
    genericData: Object,
    requests: Object,
}

class FormsStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getInitialState() {
        return {
            ongoingRequests: 0,
            requests: {},
            // GENERIC SELECTOR
            genericSelector: {
                dataset: [],
                dynamicDataset: [],
            },
            // GENERIC DATA
            genericData: {
                data: [],
                count: 0,
                headers: [],
                pagination: {
                    current: 1,
                    defaultCurrent: 1,
                    defaultPageSize: 5,
                    total: 0,
                    hideOnSinglePage: false,
                    showQuickJumper: false,
                    showTotal: (total: number, range: Array<number>) => `  מציג ${range[0]} - ${range[1]} מתוך ${total}`, 
                   // showTotal: (total: number, range: Array<number>) => '  ' + this.getText(10372) + ' ' + range[0] + ' - ' + range[1] + ' ' + this.getText(10373) + ' ' + total,
                },
                editing: -1,
                editedRecord: {},
                filters: {
                    page: 1,
                    recordsPerPage: 5,
                    sortColumn: 0,
                    sortType: 1
                }
            }
        };
    }

    reduce(state: FormsState, action: Object) {
        let user: User = new User(action.token, action.companyCode);
        let request: Job;

        let newEarningPoints = state.earningPoints;
        let newFilter;
        let newPagination;
        let count;
        let headers;
        let data;
        let newState;
        let recordData;
        let record;
        let newRecord;
        let emptyRecord;
        let emptyLine;
        let obj;
        let fieldCount;
        let newGenericData;
        let genericDataChildren = [];
        let requestDetails;
        /*let dataArray: Array<string | Object>;*/

        let who = action.who;
        if (who !== undefined) {
            if (typeof action.script === "object" && action.script !== null) {
                who = action.script.who;
            }
            if (who.indexOf(".") === -1) {
                newGenericData = state[who] || Object.assign({}, state.genericData);
            } else {
                let path = who.split(".");
                newGenericData = state[path[0]] || Object.assign({}, state.genericData);
                for (let i = 0; i < path.length; i++) {
                    genericDataChildren.push(path[i]);
                }
            }
        } else {
            newGenericData = {
                pagination: {},
                filters: {},
                data: [],
                headers: [],
                editedRecord: {},
                editing: -1
            }
        }

        window.console.log(action.type);

        switch (action.type) {
            //case ActionTypes.API_CALL:return state {...state}
            case ActionTypes.SET_JSON:
                return Jax.mutate(state, action.who, action.obj);

            case ActionTypes.OVERRIDE_JSON:
                //return Object.assign({}, state, { [action.who]: action.obj });
                return Jax.set(state, action.who, action.obj);

            default:
                return state;
        }
    }


}

export default new FormsStore();


import React from "react";
import { Card, DatePicker, Form, InputNumber, Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import SelectorSalesAlerts from "./SelectorSalesAlerts";
import SelectorTaxType from "./SelectorTaxType";
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import moment from "moment";


const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

const codeInvalid = () => {
    message.error('קוד הפריט כבר קיים במסד הנתונים');
};
const codeValid = () => {
    message.success('קוד פריט פנוי');
};
const codeExists = () => {
    message.warning('קוד פריט כבר קיים במסד הנתונים אך בסטטוס לא פעיל');
};

/*
ItemCode
ItemName
ItemShortName
ItemRuler1ID
ItemRuler2ID
*/

//type State = {}
class ModalCreateEditFatherItem extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.id = 'CREATE_FATHER_ITEM';
        this.id_validator = 'IS_ITEM_CODE_EXIST';

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.id_validator] === undefined) { props.data[this.id_validator] = { ...props.data.genericData }; }

        //if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
        //    props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        //}

        this.api = {
            get: "get_father_item__by_id",
            edit: "edit_create_father_item",
            check: "check_father_item_code",
        }

        this.actionButtons = [/*{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }*/];

        this.dataSource = [];
    }

    componentDidMount() {
        document.title = "Cash On Tab - פריט אב";
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditFatherItem);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditFatherItem);
    }
    handleClear = () => {
        //this.clear();
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
        if (this.isNameValid() === 'success' && this.isItemCodeNotUsed()) {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataJustSaveEdit,
                args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
            });
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataGetRequested,
                args: ["MATRIX_ITEMS_TABLE", this.props.user.companyCode, this.props.user.token, "get_matrix_items_table"]
            });
            this.props.uiActions.hideModal(TableIDs.modalCreateEditFatherItem);
        }
        else {
            message.warning('נתונים חסרים בשדות חובה');
        }
    }

    isNameValid = () => {
        const name = this.props.data[this.id].editedRecord['mRuler1ID'];
        return (name === '' || name === null) ? 'error' : 'success';
    }

    isItemCodeValid = () => {
        let name = (this.props.data[this.id].editedRecord['mCode']);
        return (name === '' || name === null)
            //? 'error' : 'success';
            ? false : true;
    }

    isItemCodeNotUsed = () => {
        let validator = this.props.data[this.id_validator].editedRecord['mCode'];
        let formItemCode = this.props.data[this.id].editedRecord['mCode'];
        return (validator === formItemCode)
            //? 'error' : 'success'; 
            ? false : true;
    }

    render() {
        const { loading } = this.state;
        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={'יצירת/עריכת פריט אב'}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'80%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                data={this.props.data} dataAction={this.props.dataAction}>

                <Form id="create_edit_father_item_form" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: '2' }}
                    onSubmit={this.handleSaveChanges}>

                    <FormItem
                        key={'mCode'} label={'קוד פריט'}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}

                        validateStatus={(this.isItemCodeValid() && this.isItemCodeNotUsed()) ? 'success' : 'error'}
                        //help={"בלה בלה"}
                        extra={(!this.isItemCodeValid())
                            ? <label style={{ color: '#f5222d' }}>נדרש לשייך קוד פריט</label>
                            : (!this.isItemCodeNotUsed())
                                ? <label style={{ color: '#f5222d' }}>קוד פריט נמצא כבר בשימוש</label>
                                : <label></label>
                        }
                        hasFeedback
                        required={true}
                    >
                        <Input
                            disabled={(this.props.ui.data > 0) ? true : false}
                            value={this.props.data[this.id].editedRecord['mCode']}
                            onChange={(value) => {
                                // for validator
                                this.props.dataActions.genericDataGetEditWithParams(
                                    this.id_validator,
                                    this.props.user.companyCode, this.props.user.token,
                                    { _code: value.target.value }, this.api.check
                                );
                                // for form
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mCode']: value.target.value });
                            }
                            } />
                    </FormItem>

                    <FormItem
                        key={'mName'} label={'שם פריט'}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mName']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mName']: value.target.value });
                            }
                            } />
                    </FormItem>

                    <FormItem
                        key={'mShortName'} label={'שם פריט מקוצר'}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mShortName']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mShortName']: value.target.value });
                            }
                            } />
                    </FormItem>

                    <FormItem
                        key={'mRuler1ID'} label={'סרגל ראשי'}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}

                        validateStatus={this.isNameValid()}
                        // hasFeedback={false}
                        //help={"בלה בלה"}
                        extra={<label style={(this.isNameValid() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>שדה חובה</label>}
                        required={true}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mRuler1ID']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mRuler1ID']: value.target.value });
                            }
                            } />
                    </FormItem>

                    <FormItem
                        key={'mRuler2ID'} label={'סרגל משני'}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mRuler2ID']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mRuler2ID']: value.target.value });
                            }
                            } />
                    </FormItem>

                    {/*
                    <FormItem
                        key={'mIsActive'} label={'פעיל'}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                    //validateStatus={}
                    >
                        <Checkbox
                            //onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsIncludeVat", record); }}
                            onChange={(e) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mIsActive']: (e.target.checked) ? 1 : 0 });
                            }}
                            checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                            defaultChecked={false}
                            //disabled={!(this.props.data[this.id].editing === record.key)} />
                            disabled={false} />
                    </FormItem>
                    */}
                </Form>
                <div className="action_footer">
                    <Button
                        type="primary" htmlType="submit" form="create_edit_father_item_form"
                        loading={this.props.ui.workInProgress}
                        className={"form-button-save"} icon={"check"}>שמור</Button>
                </div>

            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                { _id: this.props.ui.data },
                this.api.get
            );
            // set defaults on CREATE
            //if (this.props.ui.data == 0 || this.props.ui.data === undefined) {
            //    setTimeout(
            //        () => this.props.dataActions.genericDataSetEdit(this.id, { ['mIsActive']: 1 }),
            //        500);
            //}

        }
    }
}

export default ModalCreateEditFatherItem;

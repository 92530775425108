/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Form, Input, InputNumber, Select } from "antd";
import "../../App.css";
import GenericTable from "../../components/GenericTable";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";
import ReportTypeButton from "../../components/ReportTypeButton";
import DateTimePicker from "../../components/DateTimePicker";


class Meters extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalReport: false,
            reportParams: {
                _fromDate: null,
                _toDate: null,
                _branchList: '',
                reportName: "reports/Meters.pdf",
            }
        };

        this.id = "METER_DECLAREATIONS";

        this.api = {
            get: "get_meter_declareations",
            create: "create_meter_declareations",
            edit: "edit_meter_declareations",
            delete: "delete_meter_declareations",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorBranches, this.props.user.companyCode, this.props.user.token, "get_branch_list");
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(17666);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState({
                modalReport: true,
                reportParams: this.props.ui.favoriteParams
            })
        }
    }

    renderDesc = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        if (editing == record.key) {
            return (<Input
                value={editedRecord.mDescription}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mDescription: e.target.value }) }}
            />)
        } else {
            return text;
        }
    }

    renderCountDirection = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        return (<Select
            style={{ width: "100%" }}
            value={editing == record.key ? editedRecord.mCountDirection : text}
            disabled={editing != record.key}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mCountDirection: e }) }} >
            <Select.Option value={'0'}>{this.getText(17674)}</Select.Option>
            <Select.Option value={'1'}>{this.getText(17675)}</Select.Option>
        </Select>)
    }

    renderBranch = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        return (<GenericSelector
            {...this.props}
            id={TableIDs.genericSelectorBranches}
            value={editing == record.key ? editedRecord.mBranch : text}
            disabled={editing != record.key}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mBranch: e }) }}
        />)
    }

    renderQQId = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        if (editing == record.key) {
            return (<InputNumber
                value={editedRecord.mQQId}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mQQId: e }) }}
            />)
        } else {
            return text;
        }
    }

    renderEditCol = (text, record) => {
        const { editing } = this.props.data[this.id];

        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.id, this.props.user.companyCode, this.props.user.token,
                        editing == 0 ? this.api.create : this.api.edit,
                        this.api.get);
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }} />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                deleteQuetion={{ title: this.getText(17676), okText: this.getText(17677), cancelText: this.getText(17678) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }} />)
        }
    }


    render() {
        let gt = this.getText;
        const sorter = true;
        const render = t => t;


        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(17667)}
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
                searchBox
                actionButtons={[{ buttonType: 'print', buttonFunction: () => { this.setState({ modalReport: true }) } }]}
                columns={[
                    { title: gt(17668), key: "mID", dataIndex: "mID", width: "10%", sorter, render },
                    { title: gt(17669), key: "mDescription", dataIndex: "mDescription", width: "30%", sorter, render: this.renderDesc },
                    { title: gt(17670), key: "mCountDirection", dataIndex: "mCountDirection", width: "15%", sorter, render: this.renderCountDirection },
                    { title: gt(17671), key: "mBranch", dataIndex: "mBranch", width: "15%", sorter, render: this.renderBranch },
                    { title: gt(17672), key: "mQQId", dataIndex: "mQQId", width: "10%", sorter, render: this.renderQQId },
                    { title: gt(17673), width: "10%", render: this.renderEditCol },
                ]}
            />
            <GenericModal
                visible={this.state.modalReport}
                onCancel={() => { this.setState({ modalReport: false }) }}
                title={gt(17957)}
                width={600}>
                <Form.Item label={gt(17958)}>
                    <DateTimePicker
                        format="DD/MM/YYYY"
                        value={this.state.reportParams._fromDate}
                        onChange={e => this.setState({ reportParams: { ...this.state.reportParams, _fromDate: e } })}
                        maxDate={this.state.reportParams._toDate}
                    />
                </Form.Item>
                <Form.Item label={gt(17959)}>
                    <DateTimePicker
                        format="DD/MM/YYYY"
                        value={this.state.reportParams._toDate}
                        onChange={e => this.setState({ reportParams: { ...this.state.reportParams, _toDate: e } })}
                        minDate={this.state.reportParams._fromDate}
                    />
                </Form.Item>
                <Form.Item label={gt(17960)}>
                    <GenericSelector
                        {...this.props}
                        id={TableIDs.genericSelectorBranches}
                        multi
                        value={this.state.reportParams._branchList ? this.state.reportParams._branchList.split(",") : []}
                        onChange={e => this.setState({ reportParams: { ...this.state.reportParams, _branchList: e ? e.join(",") : "" } })}
                    />
                </Form.Item>
                <ReportTypeButton
                    {...this.props}
                    params={this.state.reportParams}
                    branchesField={"_branchList"}
                    datesToFavorites={[
                        { field: "_fromDate", label: gt(17958) },
                        { field: "_toDate", label: gt(17959) },
                    ]}
                    favoriteSave
                />
            </GenericModal>
        </div>)
    }

}

export default Meters;
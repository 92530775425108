/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Tooltip, Icon } from "antd";
import moment from "moment";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import "../Dashboard.css";

class TopLinesSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            LastTimeRefreshe: null,
        }

        this.id = "TOP_LINES_DATA";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (
            p.dashboardDate !== this.props.dashboardDate ||
            p.dashboardPos !== this.props.dashboardPos
        ) {
            this.getDataTopLines()
        }
    }

    getDataTopLines = () => {
        let request = {
            dashboardDate: this.props.dashboardDate ? moment(this.props.dashboardDate).format("YYYY-MM-DD") : null,
            dashboardPos: this.props.dashboardPos,
        };

        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, "get_dashboard_top_lines");
        this.setState({ LastTimeRefreshe: moment().format('HH:mm:ss'/*'LTS'*/) });
    }

    getDocNum = (text, record) => {
        let params = {
            DocumentNumber: text,
            DocumentTypeID: record.pDocType,
            DocumentPOSID: record.pId,
            CompanyCode: this.props.user.companyCode,
            reportName: "reports/DocumentCopy.pdf",
        }
        let gReport = () => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, params]
            });
        }
        return (<span onClick={gReport}>{text}</span>)
    }

    render() {
        let gt = this.getText;
        let t = (text) => text;
        let t2 = (text) => parseFloat(text) ? parseFloat(text).toFixed(2) : '0.00';

        let columns = [
            { title: gt(10075), dataIndex: 'pName', key: 'pName', render: (t, r) => r.pId + ' - ' + t },
            { title: gt(10076), dataIndex: 'pTime', key: 'pTime', render: t },
            { title: gt(10077), dataIndex: 'pDocNum', key: 'pDocNum', render: this.getDocNum },
            { title: gt(10078), dataIndex: 'pSummary', key: 'pSummary', render: t2 },
            { title: gt(10079), dataIndex: 'pEmployee', key: 'pEmployee', render: t },
        ]

        return (<div style={{ marginBottom: "24px" }}>
            <ResponsiveTable
                columns={columns}
                cardStyle={true}
                percentageToMobile={[40, 30, false, 30]}
                title={<div>
                    <span>{gt(10141)}</span>
                    <span style={{ float: "left" }}>
                        <span style={{ marginLeft: 4 }}>{this.state.LastTimeRefreshe}</span>
                        <Button className={"btn"}
                            disabled={this.props.ui.workInProgress}
                            onClick={this.getDataTopLines}>
                            <Icon type={"reload"} style={{ fontSize: 16, padding: 4 }} />
                        </Button>
                    </span>
                </div>}
                actionButtons={[]}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                idTable={this.props.data[this.id]}
                pagination={false}
                // pagination={this.props.data[this.id].pagination}
            />
        </div>)
    }
}
export default TopLinesSection;

/* @flow */
/*jshint esversion: 6 */
import React from 'react';
import "../App.css";
import { Row, Col, Button, Icon, Tooltip, Card, Form, Checkbox, message, List, Input, Switch } from "antd";
// import { Link } from "umi";
import { NavLink } from "react-router-dom";

class SearchPages extends React.Component {
    constructor(props: Props) {
        super(props);

        this.state = {
            search: "",
            viewMobile: false,
        }

        this.id = "UI_COMPONENT_ROUTE";
        this.api = "get_ui_component_route";

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericSelector };
        }

        this.updateScrolling = null;
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.id, this.props.user.companyCode, this.props.user.token, this.api);

        window.addEventListener("scroll", () => {
            let search_pages_div = document.getElementById("search_pages_div")
            if (search_pages_div) search_pages_div.style.top = window.scrollY * -1 + "px"
            // clearTimeout(this.updateScrolling);
            // this.updateScrolling = setTimeout(() => {
            //     this.setState({ isScrolling: window.scrollY > 50 })
            // }, 100);
        })
    }

    render() {
        const mobile = window.innerWidth <= 600;
        const width = 300;

        const { search } = this.state;
        const { dataset } = this.props.data[this.id];
        const datasetFilter = dataset.filter(f => f.name && f.name.indexOf(search) > -1).slice(0, 10);

        const divStyle = {
            position: "fixed",
            top: 0,
            left: (window.innerWidth - width) / 2,
            width,
            float: 'left',
            zIndex: !search ? 50 : 10000,
            fontSize: 16,
            // textAlign: 'center',

        }

        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'

        return (!mobile || this.state.viewMobile) ?
            (<div style={divStyle} id={"search_pages_div"}>

                <Input.Search
                    onBlur={() => { setTimeout(() => { this.setState({ search: "", viewMobile: false }) }, 500) }}
                    value={search}
                    onChange={e => { this.setState({ search: e.target.value }) }}
                />
                {search ?
                    datasetFilter.map(x => {
                        console.log("SearchPages test", origin + x.code)
                        return (<div style={{ height: 25, paddingRight: 10, marginBottom: 25 }}>
                            <NavLink
                                to={(isDev ? '/cash-on-tab/build' : '') + x.code}
                                style={{ color: "#ffffff" }}
                            // onClick={() => { this.setState({ search: "", viewMobile: false }) }}
                            >{x.name}</NavLink></div>)
                    })
                    : ""}
            </div>)
            : (<Icon type="search" style={{ marginRight: 30 }} onClick={() => { this.setState({ viewMobile: true }) }} />)
    }
}

export default SearchPages;
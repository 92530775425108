/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Modal, Table, Button, Input, Checkbox } from "antd";
import "../App.css";
import TableIDs from "../data/TableIDs.js";
import PageToolbar from "./PageToolbar";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
import GenericSelector from "./GenericSelector";

const Search = Input.Search;

const topHeight = 380; // px
const rowHeight = 30;  // px

type Props = {
    data: Array<string>,
    visible: boolean,
    onChange: Function,
    defaultValue: string,
    zIndex: number,
    supplierId: number,
    supplierName: String,
    filterPerSupplier: Boolean,
    onLoadDataSource: Function,
    dataSource2: Array,
    columns2: Array,

};

type State = { pagination: Object, query: string };

class SelectorItemsModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            query: "",
            pagination: {
                size: "small",
                hideOnSinglePage: true,
                defaultCurrent: 1,
            },
            valueSearch: "",

            multiCheckedList: []
        };

        this.id = TableIDs.SelectorItemsModal;
        this.last_searched_id = "LAST_ITEM_SEARCHED";
        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.last_searched_id] === undefined) { props.data[this.last_searched_id] = { value: "" }; }
        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.api = {
            get: "get_all_items_table",
            create: "",
            edit: "",
            //delete: "delete_item",
            selector: "",
        }

        this.searchTimeout = null;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        console.log("zzzz  selectorItem modal mounted");
        this.initFilters();
        //this.refreshData();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }
    componentDidUpdate(prevProps) {
        if (
            prevProps.supplierId !== this.props.supplierId
            || prevProps.itemType !== this.props.itemType
            || prevProps.filterPerSupplier !== this.props.filterPerSupplier
            || prevProps.ui.data?.onChangeMulti !== this.props.ui.data?.onChangeMulti
        ) {
            this.initFilters();
        }
        // if (this.props.ui.data !== prevProps.ui.data && this.props.ui.data.mCode) {
        //     this.handleChange(this.props.ui.data.mCode)
        // }
        let uiData = this.props.ui.data;
        let pUiData = prevProps.ui.data;

        if ((this.props.value !== prevProps.value)
            || (uiData && pUiData && uiData.idSelector !== pUiData.idSelector && uiData.idSelector === this.props.idSelector)) {
            this.handleChange(this.props.value)

        }

        if (this.props.onLoadDataSource) {
            if (prevProps.data[this.id].data !== this.props.data[this.id].data || this.props.toggle !== prevProps.toggle) {
                let dataSourceKeys = this.getDataSource().map(x => x.key);
                this.props.onLoadDataSource(dataSourceKeys)
            }
        }

        if (this.props.toggle && !prevProps.toggle) {
            let valueSearch = this.props.value
            if (this.props.lastItemSearched && !valueSearch) valueSearch = this.props.data[this.last_searched_id].value
            setTimeout(() => {
                this.setState({ valueSearch }, this.initFilters)
                setTimeout(() => {
                    let searchInput = document.getElementById("search_in_item_select_modal");
                    if (searchInput) {
                        searchInput.focus();
                        searchInput.select();
                    }
                }, 1000);
            }, 500);
        }
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };
    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }
    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: this.props.ui.data?.onChangeMulti ? "1" : this.props.itemType,
            // ui
            page: 1,
            recordsPerPage: /*5,*/ numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            //dataObject: this.id,
            // api
            _words: this.state.valueSearch,
            _page_num: 1,
            _rows_num: /*5,*/ numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
            _supplierId: this.props.supplierId ? this.props.supplierId : null,
            _filterPerSupplier: this.props.filterPerSupplier ? '1' : '0',
        };
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    handleChange = (v) => {
        this.setState({ valueSearch: v }, () => {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(
                (val) => {
                    this.props.dataActions.genericDataSetFilter(
                        this.id, this.props.user.companyCode, this.props.user.token,
                        {
                            _words: ((val == null) ? "" : val).trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                            page: 1,
                            _page_num: 1,
                        }, this.api.get)
                }, 300, v
            );
        });

    }

    handleCancel = () => {
        let issetInList = false;
        this.getDataSource().forEach(x => { if (x.mCode === this.props.value) issetInList = true })
        this.props.uiActions.hideModal(TableIDs.SelectorItemsModal)/*this.props.onChange({ visible: false })*/
        this.handleClear()
    }

    handleClear = () => {
        this.setState({ valueSearch: "", multiCheckedList: [] }, () => {
            this.props.onChange({ mCode: null, mName: null })
            this.initFilters()
        })
    }

    handleAddMulti = () => {
        if (this.props.ui.data?.onChangeMulti) {
            this.props.ui.data.onChangeMulti(this.state.multiCheckedList)
            this.handleCancel()
        }
    }

    handleSearch(query: string, force: boolean) {
        if (query.length > 2 || force) {
            this.setState({ ...this.state, query: query, pagination: Object.assign({}, this.state.pagination, { current: 1 }) })
        } else if (query.length === 0) {
            this.clearSearch();
        }
    }

    clearSearch() {
        this.setState({ ...this.state, query: "", pagination: Object.assign({}, this.state.pagination, { current: 1 }) })
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 6;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mCode": col--;
            case "mName": col--;
            case "mBarCode": col--;
            case "mBarCode2": col--;
            case "mIsMatrixFather": col--;
            case "mSuppCode": break; // 6
            default:
                col = 0;
        }
        //console.log("pagination: ", pagination, "sorter: ", sorter, "\ncol: ", col);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //sortColumn: 'col_1',
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: this.state.valueSearch ? this.state.valueSearch.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'") : "",
            _supplierId: this.props.supplierId ? this.props.supplierId : null,
            _filterPerSupplier: this.props.filterPerSupplier ? '1' : '0',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    getDataSource = () => {
        return this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mName: y[1],
                mCode: y[2],
                mBarCode: y[3],
                mBarcode2: y[4],
                mIsMatrixFather: y[5],
                mSuppCode: y[6],
                index: index
            })
        });
    }

    render() {
        console.log('valuesearch', this.props.value)
        let dataSource = this.getDataSource();
        if (this.props.dataSource2 && this.props.dataSource2.length) {
            dataSource = dataSource.map(x => {
                let data2 = this.props.dataSource2.find(f => f.key === x.key);
                return data2 ? { ...x, ...data2 } : x
            })
        }

        let gt = this.getText;
        let sorter = true;
        let colCount = 4;
        const render = t => t
        if (this.props.supplierId) colCount = 5;
        if (this.props.columns2) colCount += this.props.columns2.length;

        let width = Math.round(90 / colCount) + '%';

        let columns = [
            { title: gt(10395), dataIndex: "mCode", key: "mCode", sorter, width, render },
            { title: gt(10396), dataIndex: "mName", key: "mName", sorter, width, render },
            { title: gt(10397), dataIndex: "mBarCode", key: "mBarCode", sorter, width, render },
            { title: gt(10398), dataIndex: "mBarcode2", key: "mBarcode2", sorter, width, render }
        ];

        if (this.props.supplierId) columns = [...columns, { title: gt(16815), dataIndex: "mSuppCode", key: "mSuppCode", sorter, width, render }]
        if (this.props.columns2) columns = [...columns, ...this.props.columns2.map(x => { return { ...x, width } })]

        columns = [
            ...columns,
            // {
            //     title: gt(10399),
            //     dataIndex: "mIsMatrixFather",
            //     key: "mIsMatrixFather",
            //     sorter,
            //     width: '10%',
            //     render: (text, record) => { return <Checkbox checked={(text === '1') ? true : false} onChange={false} /*disabled*/ /> }
            // },
            {
                title: "",
                width: '10%',
                render: (text, record) => {
                    //console.log("ddddddddddddddd  ", record)
                    return (
                        this.props.ui.data?.onChangeMulti
                            ? (<Checkbox
                                checked={this.state.multiCheckedList.find(f => f.mCode === record.mCode)}
                                onChange={e => {
                                    if (e.target.checked) this.setState({ multiCheckedList: [...this.state.multiCheckedList, record] })
                                    else this.setState({ multiCheckedList: this.state.multiCheckedList.filter(f => f.mCode !== record.mCode) })
                                }} />)
                            : (record.mIsMatrixFather === '1' && this.props.isExpandMatrixFatherItem)
                                ? <Button
                                    onClick={() => { /* open modal - select multi */ this.props.onChange(record /*selection: record.mCode, itemName: record.name }*/) }}>{this.getText(10400)}</Button>
                                : <Button type={record.mCode === this.props.value ? "primary" : ""}
                                    onClick={() => { /* select one - add to row */ this.props.onChange(record /*{ selection: record.mCode, itemName: record.name }*/);
                                        this.props.uiActions.hideModal(TableIDs.SelectorItemsModal)
                                    }}>{this.getText(10401)}</Button>

                    )
                }
            }
        ];

        let mobile = window.innerWidth > 600 ? false : true;

        let percentageToMobile = columns.map((x, i) => {
            if (i == 0) return 20
            if (i == 1) return 60
            if (!columns[i + 1]) return 20
            return 0
        })

        return (
            <Modal zIndex={this.props.zIndex}
                //title={"בחר פריט"}
                title={
                    <PageToolbar
                        title={this.getText(10394)}
                        actionButtons={[]}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                    />
                }
                width={mobile ? '100%' : '80%'}
                visible={/*visible*/ false || this.props.toggle}
                onCancel={() => { this.props.uiActions.hideModal(TableIDs.SelectorItemsModal) }}
                closable={false}
                destroyOnClose={true}
                footer={
                    [
                        <Button key={"back"} onClick={this.handleCancel}>{this.getText(10402)}</Button>,
                        <Button key={"clear"} onClick={this.handleClear}>{this.getText(10403)}</Button>,
                        this.props.ui.data?.onChangeMulti ?
                            <Button key={"addMulti"} type="primary" onClick={this.handleAddMulti}>{this.getText(20275)}</Button>
                            : "",
                    ]} afterClose={this.clearSearch.bind(this)} >
                <div>
                    {this.props.supplierId && this.props.filterPerSupplier ? <h3>{this.props.supplierId + ' - ' + this.props.supplierName}</h3> : ""}
                    <Search
                        id={"search_in_item_select_modal"}
                        placeholder={this.getText(10404)}
                        //onChange={(value) => { this.handleSearch.apply(this, [value.target.value, false]); }}
                        value={this.state.valueSearch}
                        onChange={(value) => {
                            this.handleChange(value.target.value)
                            if (this.props.lastItemSearched) this.props.dataActions.setJson(this.last_searched_id, { value: value.target.value })
                            // console.log("item_search", this.props.lastItemSearched, this.props.value, this.props.data[this.last_searched_id])
                        }}
                        //onSearch={(value) => { this.handleSearch.apply(this, [value, true]); }} 
                        onSearch={(value) => { this.handleSearch.apply(this, [value, true]); }}
                        size={"large"}
                        enterButton
                    />
                    <ResponsiveTable ui={this.props.ui} tableOnly={true} columns={columns} dataSource={dataSource}
                        //pagination={this.state.pagination}
                        //onChange={(pagination) => { this.setState(Object.assign({}, this.state, { pagination })) }} 
                        loading={this.props.ui.workInProgress}
                        //scroll={{ x: 1650 }}
                        bordered
                        pagination={this.props.data[this.id].pagination}
                        onChange={this.handleTableChange}
                        locale={{ emptyText: this.getText(10405) }}
                        percentageToMobile={percentageToMobile}
                    />
                </div>
            </Modal>
        );
    }

}

export default SelectorItemsModal;
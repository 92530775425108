/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Switch, Card, Col, Row, Button, Form, Select, Checkbox, InputNumber, Input, Icon, message, Avatar, Badge } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact'
import DateTimePicker from "../../components/DateTimePicker";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import CustPicker from "../../components/CustPicker";
import DataMultiSelectModal from "../../components/DataMultiSelectModal";
import LangContext from "../../contextProvider/LangContext";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";

//'@ant-design/icons';


const topHeight = 340; // px
const rowHeight = 50;  // px

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;


type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object,

    lowerDate: string,
    upperDate: string,
    filterSelection: number,
    locations: Array<string>,
    customerList: Array<number>,
    preferredMethod: number,
    predefinedMessage: number,
    couponId: -1,
    isMultiSelectOpen: boolean,
    isSmsForbidden: boolean,
    capabilities: {
        total: number,
        push: number,
        sms?: number,
        email: number
    },
    areResultsValid: boolean,
    couponSentCount: number,
    messageSentCount: number,
    parameter1: string,
    parameter2: string,
    textMessageBalance: number
};

type State = {};

// this.mainId = "MESSAGE_LIST_WEB_PUSHR";

class MessageListWebPushr extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            custId: null,
            lowerDate: "",
            upperDate: "",
            filterSelection: 0,
            locations: [],
            customerList: [],
            preferredMethod: 0,
            predefinedMessage: -1,
            couponId: -1,
            isMultiSelectOpen: false,
            isSmsForbidden: true,
            capabilities: {
                total: -1,
                push: -1,
                sms: -1,
                email: -1
            },
            areResultsValid: true,
            couponSentCount: -1,
            messageSentCount: -1,
            parameter1: "",
            parameter2: "",
            textMessageBalance: -1,

            customerListFromTags: [],
            isAllCustomers: true,
            msgText: "",
            campaign_id: "",
            icon_url: props.user.profilePicture,
            target_url: null,
            title: null,
            // status
            push_status: {
                "campaign_status": "",
                "camapign_id": "",
                "count": {
                    "total_attempts": "0",
                    "successfully_sent": "0",
                    "failed_to_send": "0",
                    "delivered": "0",
                    "clicked": "0",
                    "closed": "0"
                }
            },
            processingRequestFlag: 0,
        };
        // this.selectors = [
        //     { id: TableIDs.genericSelectorPos, api: "list_pos" },
        //     { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
        //     { id: TableIDs.genericSelectorEmployees, api: "get_employee_list" },

        // ];
        this.id_tags = 'CustomerTa';

        if (props.data[this.id_tags] === undefined) { props.data[this.id_tags] = { ...props.data.genericData }; }

        this.selectors = [
            { id: TableIDs.genericSelectorPreDefinedMsg, api: "get_pre_defined_msg_list" },
        ];
        this.selectors.forEach((i) => {
            if (props.data[i.id] === undefined) { props.data[i.id] = { ...props.data.genericSelector } }
        });

        if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        };

        this.api = {
            send_msgs_to_all: "webpusher_push_all_subscribers",
            send_msgs: "webpusher_push_by_attributes",
            get_status: "webpusher_push_status",
            get_predef_msg: "get_predefined_msg_text",
            get_customers_by_tags: "get_customers_per_tags",
            get_webisite_link: 'get_webisite_link'
        }

        this.statusTimer = null;
    }

    static contextType = LangContext;
    getText = (id) => { return this.context.get(id) || '[' + id + ']' }

    componentDidMount() {
        ///this.query();
        //this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorPreDefinedMsg, this.props.user.companyCode, this.props.user.token, this.api.get_msg);
        this.props.dataActions.genericMultiSelectorRefreshDataset(TableIDs.genericMultiSelectorCustomerTags, this.props.user.companyCode, this.props.user.token, "get_customer_tags_list");
        this.selectors.forEach((i) => {
            this.props.dataActions.genericSelectorRefreshDataset(i.id, this.props.user.companyCode, this.props.user.token, i.api);
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [this.props.user.companyCode, this.props.user.token, {
                script: "get_all_customers",
                params: { mCreatingBranchList: "" }
            }, "Customers", {
                loading: "",
                failed: "Error loading",//"חלה שגיאה בטעינת הנתונים",
                success: ""
            }, false, null, function (actionName, store, data) {
                let arr = data.split("\r");
                let json = [];
                for (let i = 0, j = arr.length; i < j; i++) {
                    let row = arr[i].split("\f");
                    json.push({
                        id: parseInt(row[0], 10),
                        firstName: row[1],
                        lastName: row[2],
                        contactPerson: row[3],
                        address: row[4],
                        zipCode: parseInt(row[5], 10),
                        phone: row[6].length > 0 ? row[6] : row[7]
                    });
                }
                return Jax.set(store, "Customers", json);
            }]
        });

        this.callApi(
            (this.getText(100)) ? this.getText(100) : 1,
            this.api.get_webisite_link,
            (ob) => {
                if (ob.data && ob.data !== "") {
                    this.setState({ target_url: ob.data })
                }
            },
            (e) => { console.log(e); }
        );
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15741);

    }

    callApi = (inputData, action, succCallback, errCallback) => {
        //let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + selection.target.value + "\f1\f1";
        //let job = new Job(user, this.api.check, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        let dataSend = inputData;//"_itemCode\f_isWithMatrix\f_isWithFather\r" + selection.target.value + "\f1\f1";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, action, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", succCallback, errCallback);
    }

    createSelector = (label, field, index) => {
        return (<FormItem label={this.getText(label)}>
            <GenericSelector
                multi
                id={this.selectors[index].id}
                api={this.selectors[index].api}
                value={this.state[field] ? this.state[field].split(',') : []}
                onChange={(e) => { this.setState({ [field]: e ? e.join(',') : '' }) }}
                data={this.props.data}
                dataActions={this.props.dataActions}
                user={this.props.user}
            />
        </FormItem>)
    }

    fetchStatusTimer = () => {

        let inputData = "campaign_id\r" + this.state.campaign_id;
        this.callApi(inputData, this.api.get_status,
            (ob) => {
                if (ob.data && ob.data !== "") {
                    let obj = {};
                    try {
                        obj = JSON.parse(ob.data);
                    } catch (e) {
                        obj = {};
                    }

                    console.log("webpush status response:", obj);

                    if (obj["status"] === "failure" || this.state?.push_status?.campaign_status === "Sent") {
                        //console.log("deboz: api fatal error (status)= ", obj["status"])
                        // fetch failed. stop timer to stop calls
                        this.stopStatusTimer();
                    } else {
                        this.setState({
                            "push_status":
                            {
                                "campaign_status": obj?.campaign_status,
                                "camapign_id": obj?.camapign_id,
                                "count": {
                                    "total_attempts": obj?.count?.total_attempts,
                                    "successfully_sent": obj?.count?.successfully_sent,
                                    "failed_to_send": obj?.count?.failed_to_send,
                                    "delivered": obj?.count?.delivered,
                                    "clicked": obj?.count?.clicked,
                                    "closed": obj?.count?.closed
                                }
                            }
                        })
                        if (obj["campaign_status"] === "Error" || obj["campaign_status"] === "Sent") {
                            this.stopStatusTimer();
                        }
                    }
                }
            },
            (e) => {
                console.log(e);
            }

            // status  "status":"failure"  "campaign_status":"Error"
            //not exists        //{"status":"failure","type":"invalid_value","description":"Invalid campaign ID"}
            //failed            //{"camapign_id":"wIyDoa4CfZ","campaign_status":"Error","description":"Error: 1242. No subscriber found"}
            //ok                //{"campaign_status":"Sent","camapign_id":"fkZWCN17sA","count":{"total_attempts":"2","successfully_sent":"2","failed_to_send":"0","delivered":"0","clicked":"0","closed":"0"}}

        )
    }

    stopStatusTimer = () => {
        clearInterval(this.statusTimer);
    }

    succCallback = (ob: Object) => {
        console.log("success:", ob.data);
        if (ob.data && ob.data !== "") {
            let obj = JSON.parse(ob.data);
            if (obj && obj.status === "success") {
                message.success(/*"בקשה נשלחה לשרת" */ this.getText(15745));
                this.setState({ campaign_id: obj["ID"], processingRequestFlag: 0 }, () => {
                    this.statusTimer = setInterval(this.fetchStatusTimer.bind(this), 2500)
                })
            } else {
                this.setState({ processingRequestFlag: 0 }, () => {
                    message.error(obj.description /*this.getText(11331)*/)
                });
            }
        } else {
            this.setState({ processingRequestFlag: 0 }, () => {
                message.error(/*"משוב שגוי מהשרת"*/this.getText(15743));
            });
        }
    }
    errCallback = (error: any) => {
        this.setState({ processingRequestFlag: 0 }, () => {
            message.error(/*"הבקשה לשליחת הודעות נכשלה"*/ this.getText(15744));
        });
    }

    sendMessageByAttribute() {
        ///let target_url = 'https://www.google.co.il/';
        let attributeList: Array = [];
        let attributeJson = {};

        this.setState({ processingRequestFlag: 1 });

        if (this.state.isAllCustomers) {
            attributeJson = { [this.props.user.companyCode + "_company"]: this.props.user.companyCode }
        } else {
            attributeJson = {
                [this.props.user.companyCode + "_customerId"]: [...this.state.customerList, ...this.state.customerListFromTags] //, [this.props.user.companyCode + "_form"]: [0,1,2,3,4,5,8]
            }
        }

        let inputData = "title\fattribute\fmessage\ftarget_url\fname\ficon\fimage\fexpire_push\fauto_hide\fsend_at\faction_buttons\r"
            + this.state.title + "\f"
            + JSON.stringify(attributeJson) + "\f" //`{${attributeList.join(",")}}\f` // `${JSON.stringify(attributeList)}\f`
            + (this.state.msgText).replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t") + "\f"
            + this.state.target_url + "\f"
            + `user made campaign (${this.props.user.companyCode})\f`  // name
            + this.state.icon_url + "\f"  // icon
            + "\f"  // image
            + "\f"  // expire_push
            + "1\f" // auto_hide
            + "\f"  // send_at
            + ""    // action_buttons

        //console.log("webpushr ",inputData)
        this.callApi(inputData, this.api.send_msgs, this.succCallback, this.errCallback)
    }

    render() {
        const formLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        const summaryLayout = {
            labelCol: { span: 12 },
            wrapperCol: { span: 12 },
        };

        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;

        // let params = { ...this.state, itemName: '' };
        // let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        // let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        // if (params.posList === '') params = { ...params, posList: fullPosList }
        // if (params.storageList === '') params = { ...params, storageList: fullStoragesList }
        //let listMessages = Jax.get(this.props.data, "availableMessages", []).map((obj) => {

        // let listMessages = Jax.get(this.props.data, TableIDs.genericSelectorPreDefinedMsg, []).map((obj) => {
        //     return (<Option key={obj.id} value={obj.id}>{obj.value}</Option>)
        // });
        let listMessages = Jax.get(this.props.data, TableIDs.genericSelectorPreDefinedMsg + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        // let listCoupons = Jax.get(this.props.data, "availableCoupons", []).map((obj) => {
        //     return (<Option key={obj.id} value={obj.id}>{obj.value}</Option>)
        // });

        let filteredCustomers = Jax.get(this.props.data, "Customers", []).filter((obj) => {
            return this.state.customerList.indexOf(obj.id) > -1
        });
        let listCustomers = filteredCustomers.map((obj) => {
            window.console.log(obj);
            return (<Option key={obj.id} value={obj.id}>{obj.firstName + " " + obj.lastName}</Option>)
        });

        let resSpan = (num) => window.innerWidth > 600 ? num : 24;

        let tagList = this.props.data[TableIDs.genericMultiSelectorCustomerTags].dataset.map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let isValidUrl = (this.state.target_url) ? true : false;
        let isValidMsg = (this.state.msgText && this.state.title) ? true : false;

        //console.log("deboz: render this.state.push_status= ", this.state.push_status);
        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={this.getText(15746)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: '95%' }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                <Row>
                    <Col span={mobile ? 24 : 8} style={{ padding: 8 }}>
                        <FormItem {...formLayout} label={this.getText(15747)}>
                            <Switch
                                checkedChildren={"כל הלקוחות"}
                                unCheckedChildren={"רשימת לקוחות"}
                                checked={this.state.isAllCustomers}
                                onChange={(value) => { this.setState(Jax.set(this.state, "isAllCustomers", !(this.state.isAllCustomers))); }}
                            />
                        </FormItem>


                        <FormItem style={{ display: this.state.isAllCustomers ? "none" : "block" }} {...formLayout} label={
                            <span>
                                {this.getText(15748)}
                            </span>
                        }>

                            <div >
                                {/* {this.getText(12304)}: */}
                                <Button htmlType={"button"} onClick={() => {
                                    this.setState(
                                        Jax.set(this.state, "isMultiSelectOpen", true)
                                    );
                                }}><Icon type="plus" />{this.getText(15749)}</Button>
                                <Select key={"customers"} style={{ width: "98%", marginLeft: 8 }}
                                    mode="multiple"
                                    maxTagCount={6}
                                    onChange={(value) => {
                                        this.setState(Jax.set(this.state, "customerList", value))
                                    }}
                                    value={this.state.customerList}
                                    notFoundContent={this.getText(12305)}
                                    showArrow={false}

                                >
                                    {listCustomers}
                                </Select>
                            </div>
                        </FormItem>

                        <FormItem style={{ display: this.state.isAllCustomers ? "none" : "block" }} {...formLayout} label={this.getText(15750)}>
                            {/* <Select
                                style={{ width: '98%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                //placeholder="בחירת תגיות"
                                onChange={(e) => {
                                    //this.props.dataActions.genericDataSetEdit(this.id_tags, { [field]: e });
                                    //this.props.dataActions.dataTableAddRow(this.id_tags + ".data", e, true);
                                    this.props.dataActions.setData("tagsEditCust", e);
                                    if (Array.isArray(e)) {
                                        this.callApi(
                                            e.map(i => (i.split('\f'))[0]).join(','),
                                            this.api.get_customers_by_tags,
                                            (ob) => {
                                                if (ob.data && ob.data !== "") {
                                                    let arr = ob.data.split('\r');
                                                    this.setState({ "customerListFromTags": arr })
                                                } else {
                                                    this.setState({ "customerListFromTags": [] })
                                                }
                                            },
                                            (e) => { console.log(e); }
                                        )
                                    }

                                }}
                                // style={{ ...toggle }}//, width: '100%' }}
                                //value={this.props.data[this.id_tags].editedRecord[field]}
                                value={this.props.data[this.id_tags].data}//['CustTagTagId']}
                            //disabled={readOnly}
                            >
                                {tagList}
                            </Select> */}
                            <GenericSelectorCustTags
                                {...this.props}
                                multi
                                onChange={(e) => {
                                    this.props.dataActions.setData("tagsEditCust", e);
                                    if (Array.isArray(e)) {
                                        this.callApi(
                                            e.map(i => (i.split('\f'))[0]).join(','),
                                            this.api.get_customers_by_tags,
                                            (ob) => {
                                                if (ob.data && ob.data !== "") {
                                                    let arr = ob.data.split('\r');
                                                    this.setState({ "customerListFromTags": arr })
                                                } else {
                                                    this.setState({ "customerListFromTags": [] })
                                                }
                                            },
                                            (e) => { console.log(e); }
                                        )
                                    }

                                }}
                                value={this.props.data[this.id_tags].data}//['CustTagTagId']}
                            />
                        </FormItem>

                        <FormItem {...formLayout} label={this.getText(15751)}>
                            <Avatar
                                size="large"
                                src={this.state.icon_url}
                            />
                            <Input placeholder={this.getText(15752)}
                                onChange={(e) => { this.setState(Jax.set(this.state, "icon_url", e.target.value)) }}
                                value={this.state.icon_url}
                            />
                        </FormItem>

                        <FormItem {...formLayout} label={this.getText(15753)}
                            validateStatus={(isValidUrl) ? "success" : "error"}
                            required={true}
                            extra={<label style={!isValidUrl ? { color: '#f5222d' } : { display: 'none' }}>{this.getText(15754)}</label>}
                        >
                            <Input placeholder={"כתובת דף נחיתה"}
                                onChange={(e) => { this.setState(Jax.set(this.state, "target_url", e.target.value)) }}
                                value={this.state.target_url}
                            />
                        </FormItem>
                    </Col>

                    <Col span={mobile ? 24 : 10} style={{ padding: 8 }}>

                        <FormItem /*{...formLayout}*/ label={/*"תצוגה מקדימה"*/ this.getText(15755)}
                            validateStatus={(isValidMsg) ? "success" : "error"}
                            required={true}
                            extra={<label style={!isValidMsg ? { color: '#f5222d' } : { display: 'none' }}>{this.getText(15756)}</label>}
                        >
                            <Input placeholder={"כותרת"}
                                onChange={(e) => { this.setState(Jax.set(this.state, "title", e.target.value)) }}
                                value={this.state.title}
                            />
                            <TextArea
                                //ref={this.textFieldRef}
                                id="msg_text_area"
                                value={this.state.msgText}
                                onChange={(value) => {
                                    const txt = value.target.value;
                                    this.setState({ msgText: txt })
                                    //this.props.dataActions.genericDataSetEdit(this.id, { ['mText']: txt });
                                }}
                                autoSize={{ minRows: 6, maxRows: 10 }}
                            />
                        </FormItem>

                        <Card title={this.getText(15757)}>
                            <FormItem>

                                <Select key={"messages"} notFoundContent={this.getText(12316)}
                                    onChange={(value) => {
                                        //this.setState(Jax.set(this.state, "predefinedMessage", parseInt(value, 10)))
                                        this.callApi(parseInt(value, 10), this.api.get_predef_msg,
                                            (ob) => {
                                                if (ob.data && ob.data !== "") {
                                                    this.setState({
                                                        "msgText": ob.data, "predefinedMessage": parseInt(value, 10), "title":
                                                            (Jax.get(this.props.data, TableIDs.genericSelectorPreDefinedMsg + ".dataset", []).find((obj, index) => { return parseInt(value, 10) == (obj['code']) }))?.name
                                                    })
                                                }
                                            },
                                            (e) => { console.log(e); }
                                        )
                                    }}
                                    style={{ width: "90%", display: "block", margin: "0 auto" }}>
                                    {listMessages}
                                </Select>
                            </FormItem>
                            <Button htmlType={"button"} onClick={() => {
                                this.setState({
                                    campaign_id: "",
                                    // status
                                    push_status: {
                                        "campaign_status": "",
                                        "camapign_id": "",
                                        "count": {
                                            "total_attempts": "0",
                                            "successfully_sent": "0",
                                            "failed_to_send": "0",
                                            "delivered": "0",
                                            "clicked": "0",
                                            "closed": "0"
                                        }
                                    },
                                    processingRequestFlag: 0,
                                }, () => { this.sendMessageByAttribute() })
                            }} loading={this.props.data.ongoingRequests > 0}
                                style={{ float: "left", marginLeft: "5%", marginTop: "8px" }}>
                                {this.getText(15758)}

                                <Icon type={(this.state.processingRequestFlag === 1) ? "loading" : "message"}
                                    spin={(this.state.processingRequestFlag === 1) ? true : false}
                                    style={{ marginLeft: "auto", marginRight: "8px" }} />
                            </Button>
                            {/* {this.state.messageSentCount > -1 ? <div className={"recipient-count"}>{this.getText(12325) + " " + this.state.messageSentCount + " " + this.getText(12326)}  </div> : null} */}
                        </Card>
                    </Col>

                    <Col span={mobile ? 24 : 6} style={{ padding: 8 }}>

                        <Card title={"סיכום שילוח הודעות" /*this.getText(12330)*/}>
                            <FormItem {...summaryLayout} label={"סטטוס שילוח"}>
                                <Input readOnly={true} value={this.state.push_status?.camapign_id} />
                                <Input readOnly={true} value={this.state.push_status?.campaign_status} />
                            </FormItem>
                            <FormItem {...summaryLayout} label={this.getText(15759)/*'נקראו'*/}><Input readOnly={true} value={this.state.push_status?.count?.clicked} /></FormItem>
                            <FormItem {...summaryLayout} label={this.getText(15760)/*'נסגרו'*/}><Input readOnly={true} value={this.state.push_status?.count?.closed} /></FormItem>
                            <FormItem {...summaryLayout} label={this.getText(15761)/*'נשלחו'*/} ><Input readOnly={true} suffix={<Badge size={"default"} status="processing" />} value={this.state.push_status?.count?.delivered} /></FormItem>
                            <FormItem {...summaryLayout} label={this.getText(15762)/*'נכשלו בשליחה'*/}><Input readOnly={true} suffix={<Badge size={"default"} status="error" />} value={this.state.push_status?.count?.failed_to_send} /></FormItem>
                            <FormItem {...summaryLayout} label={this.getText(15763)/*'נשלחו בהצלחה'*/}><Input readOnly={true} suffix={<Badge size={"default"} status="success" />} value={this.state.push_status?.count?.successfully_sent} /></FormItem>
                            <FormItem {...summaryLayout} label={this.getText(15764)/*'נשלחו בסה"כ'*/}><Input readOnly={true} value={this.state.push_status?.count?.total_attempts} /></FormItem>
                        </Card>

                    </Col>
                </Row>

                <DataMultiSelectModal addToSelection={(id) => {
                    let key = parseInt(id, 10);
                    let state = Jax.splice(this.state, "customerList", this.state.customerList.indexOf(key), 1);
                    this.setState(
                        Jax.push(state, "customerList", key)
                    )
                }} removeFromSelection={(id) => {
                    let key = parseInt(id, 10);
                    this.setState(
                        Jax.splice(this.state, "customerList", this.state.customerList.indexOf(key), 1)
                    )
                }}
                    clearSelection={() => {
                        this.setState(
                            Jax.set(this.state, "customerList", [])
                        );
                    }}
                    closeDialog={() => {
                        this.setState(
                            Jax.set(this.state, "isMultiSelectOpen", false)
                        );
                    }}
                    data={Jax.map(this.props.data, "Customers", function (v) {
                        return v.id + "\f" + v.firstName + " " + v.lastName;
                    })}
                    keyColumnLabel={this.getText(12332)}
                    nameColumnLabel={this.getText(12333)}
                    title={this.getText(12334)}
                    searchPlaceholder={this.getText(12335)}
                    tableNotFound={this.getText(12336)}
                    zIndex={50}
                    visible={this.state.isMultiSelectOpen} selected={this.state.customerList}
                    closeButton={this.getText(12337)}
                />
            </Card>
        </div >
        );
    }
}

export default MessageListWebPushr;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Select, InputNumber, Checkbox, Popconfirm, Button, Input, Tooltip, message, Form, Radio, Row, Col } from "antd";

import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelector from "../../components/GenericSelector";


const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 30;  // px


class ModalConnectedItems extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            height: window.innerHeight,
        }

        this.doOnResize = null;

        this.id = "ITEMS_TABLE";
        this.connected_id = "CONNECTED_ITEMS";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.connected_id] === undefined) props.data[this.connected_id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };

        this.api = {
            get: "get_items_list",
            delete: "delete_item",


            get_connected: "get_connected_items",
            edit_connected: "edit_connected_items",
            delete_connected: "delete_connected_items",
            save_isConnected: "update_item_connected_items",

            selector_tags: "get_tags_with_types",
            import_connect: "import_connect_items_from_tag",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    openConnectedItems = (mCode, isConnectedItems) => {
        console.log('openConnectedItems1')
        this.props.onChangeState({ modalConnectedItems: mCode, isConnectedItems }, () => {
            console.log('openConnectedItems2')
            this.props.dataActions.genericDataSetFilter(this.connected_id, this.props.user.companyCode, this.props.user.token, { mCode }, this.api.get_connected)
        })
    }

    saveConnectedItems = () => {
        let dataSend = "mCode\fmConnectedItems\r" + this.props.modalConnectedItems + "\f";
        if (this.props.isConnectedItems) dataSend += this.props.isConnectedItems;

        this.sendAPI(this.api.save_isConnected, dataSend, () => {
            this.props.onChangeState({
                modalConnectedItems: false,
                isConnectedItems: null,
                tagToConnectItems: null
            }, this.refreshData)
        })
    }



    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    checkboxTable = (title, key) => {
        return {
            title: title,
            dataIndex: key,
            key: key,
            sorter: false,
            width: '5%',
            render: (text, record) => (<Checkbox checked={text == 1} disabled={true} />)
        }
    }


    renderConnectSonItem = (text, record) => {
        if (this.props.data[this.connected_id].editing !== record.key) {
            return text;
        } else {
            return (<SelectorItemClassic
                {...this.props}
                valueId={this.props.data[this.connected_id].editedRecord.mSonItem}
                onChange={(code, name) => {
                    this.props.dataActions.genericDataSetEdit(this.connected_id, { mSonItem: code, mSonDesc: name })
                }} />);
        }
    }

    renderConnectSonDesc = (text, record) => {
        if (this.props.data[this.connected_id].editing !== record.key) {
            return text;
        } else {
            return (<Input
                value={this.props.data[this.connected_id].editedRecord.mSonDesc}
                onChange={(e) => {
                    if (e.target.value.length > 50) message.error(this.getText(17389))
                    else this.props.dataActions.genericDataSetEdit(this.connected_id, { mSonDesc: e.target.value })
                }} />);
        }
    }

    renderConnectSonQty = (text, record) => {
        if (this.props.data[this.connected_id].editing !== record.key) {
            return text;
        } else {
            return (<InputNumber
                value={this.props.data[this.connected_id].editedRecord.mSonQty}
                onChange={(e) => {
                    this.props.dataActions.genericDataSetEdit(this.connected_id, { mSonQty: e })
                }} />);
        }
    }

    renderConnectSonPrice = (text, record) => {
        if (this.props.data[this.connected_id].editing !== record.key) {
            return text;
        } else {
            return (<InputNumber
                value={this.props.data[this.connected_id].editedRecord.mSonPrice}
                disabled={this.props.isConnectedItems == 2}
                onChange={(e) => {
                    this.props.dataActions.genericDataSetEdit(this.connected_id, { mSonPrice: e })
                }} />);
        }
    }

    renderConnectSonIsEdit = (text, record) => {
        if (this.props.data[this.connected_id].editing !== record.key) {
            return (<Checkbox checked={text == '1'} disabled />);
        } else {
            return (<Checkbox
                checked={this.props.data[this.connected_id].editedRecord.mSonIsEdit == '1'}
                onChange={(e) => {
                    this.props.dataActions.genericDataSetEdit(this.connected_id, { mSonIsEdit: e.target.checked ? '1' : '0' })
                }} />);
        }
    }

    renderConnectEditIcons = (text, record) => {
        if (this.props.data[this.connected_id].editing !== record.key) {
            return (
                <span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                        this.props.dataActions.genericDataStartEditing(this.connected_id, record.key, record.index);
                    }} />
                    <Popconfirm title={this.getText(10237)}
                        onConfirm={() => {
                            let fCode = this.props.modalConnectedItems;
                            let sCode = record.mSonItem

                            this.sendAPI(this.api.delete_connected, 'fCode\fsCode\r' + fCode + '\f' + sCode, (ob) => {
                                this.openConnectedItems(fCode, this.props.isConnectedItems)
                            });
                        }}
                        okText={this.getText(10238)} cancelText={this.getText(10239)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                    </Popconfirm>

                </span >);
        } else {
            let isCreate = (this.props.data[this.connected_id].editing == 0) ? true : false;
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                    onClick={() => {
                        this.props.dataActions.genericDataSaveEditWithParams(
                            this.connected_id,
                            this.props.user.companyCode,
                            this.props.user.token,
                            this.api.edit_connected,
                            this.api.get_connected,
                            { fCode: this.props.modalConnectedItems });
                    }} />
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                    onClick={() => {
                        if (isCreate)
                            this.props.dataActions.genericDataCancelInlineCreate(this.connected_id);
                        else
                            this.props.dataActions.genericDataCancelEditing(this.connected_id);
                    }} />
            </span>)
        }

    }

    render() {


        return (<GenericModal
            visible={this.props.modalConnectedItems}
            onCancel={this.saveConnectedItems}
            title={this.getText(16134) + " " + (this.props.modalConnectedItems ? this.props.modalConnectedItems : "")}>
            <Form.Item>
                <Radio.Group value={this.props.isConnectedItems} onChange={(e) => { this.props.onChangeState({ isConnectedItems: e.target.value }) }}>
                    <Radio value={'0'}>{this.getText(16135)}</Radio><br />
                    <Radio value={'1'}>{this.getText(16136)}</Radio><br />
                    <Radio value={'2'}>{this.getText(16137)}</Radio><br />
                    <Radio value={'3'}>{this.getText(16149)}</Radio>
                </Radio.Group>
            </Form.Item>
            <Row>
                <Col span={window.innerWidth > 600 ? 6 : 24}>
                    <Button
                        type="primary"
                        style={{ marginBottom: 5 }}
                        onClick={() => { this.props.dataActions.genericDataStartInlineCreate(this.connected_id) }}
                    >{this.getText(16144)}</Button>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <GenericSelector
                        {...this.props}
                        id={TableIDs.genericMultiSelectorItemTags}
                        api={this.api.selector_tags}
                        optionsGroup
                        value={this.props.tagToConnectItems}
                        onChange={(e) => { this.props.onChangeState({ tagToConnectItems: e }) }}
                    />
                </Col>
                <Col span={window.innerWidth > 600 ? 6 : 24}>
                    <Button
                        type="primary"
                        style={{ marginBottom: 5 }}
                        onClick={() => {
                            if (!this.props.tagToConnectItems) {
                                message.error(this.getText(16145))
                            } else {
                                const { modalConnectedItems, tagToConnectItems, isConnectedItems } = this.props;
                                this.sendAPI(this.api.import_connect, 'fCode\ftag\r' + modalConnectedItems + '\f' + tagToConnectItems, () => {
                                    this.openConnectedItems(modalConnectedItems, isConnectedItems)
                                })
                            }
                        }}
                    >{this.getText(16146)}</Button>
                </Col>

            </Row>

            <ResponsiveTable
                idTable={this.props.data[this.connected_id]}
                tableOnly
                columns={[
                    { title: this.getText(16138), width: "15%", dataIndex: "mSonItem", key: "mSonItem", render: this.renderConnectSonItem },
                    { title: this.getText(16139), width: "35%", dataIndex: "mSonDesc", key: "mSonDesc", render: this.renderConnectSonDesc },
                    { title: this.getText(16140), width: "15%", dataIndex: "mSonQty", key: "mSonQty", render: this.renderConnectSonQty },
                    { title: this.getText(16141), width: "15%", dataIndex: "mSonPrice", key: "mSonPrice", render: this.renderConnectSonPrice },
                    { title: this.getText(16142), width: "10%", dataIndex: "mSonIsEdit", key: "mSonIsEdit", render: this.renderConnectSonIsEdit },
                    { title: this.getText(16143), width: "10%", render: this.renderConnectEditIcons },
                ]}
                percentageToMobile={[40, 60]}
                pagination={{ pageSize: parseInt((this.state.height - (topHeight + 40) > rowHeight) ? (this.state.height - (topHeight + 40)) / rowHeight : 1) }}
            />

        </GenericModal>)
    }
}
export default ModalConnectedItems;

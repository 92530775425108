/* @flow */
/*jshint esversion: 6 */
import React from "react";
import ReactDOM from "react-dom";
import { Menu, Icon, Badge } from "antd";
import { withRouter } from "react-router-dom";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext"

const SubMenu = Menu.SubMenu;

type History = {
    push: Function,
    location: {
        pathname: string
    }
};

type Props = {
    history: History,
    baseUrl: string
};

type State = {
    menuTag: any,
    menuItemsTop: Array<Object>,
    menuItemsDrop: Array<Object>
};

class UserSettingMenu extends React.Component<Props, State> {
    myHistory: History;
    baseUrl: string;

    constructor(props: Props) {
        super(props);
        this.myHistory = this.props.history;
        this.baseUrl = this.props.baseUrl;

        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }

        this.state = {
            menuTag: (<div></div>),
            menuItemsTop: [
                { KeyComp: "132", key: "edit-buttons", text: "עורך כפתורים", keyLang: 10213, icon: "setting" },
                { KeyComp: "81", key: "permissions", text: "הרשאות", keyLang: 10214, icon: "setting" },

                // { KeyComp: "2", key: "customer-fields", text: "שדות לקוחות", keyLang: 10215, icon: "setting" },
                //הועבר להגדרות מערכת

                { KeyComp: "83", key: "earning-points", text: "צבירת נקודות", keyLang: 10216, icon: "setting" },
                { KeyComp: "84", key: "using-points", text: "שימוש בנקודות", keyLang: 10217, icon: "setting" },
                //{ KeyComp: "91", key: "customer-club", text: "מועדון לקוחות", icon: "setting" },
                { KeyComp: "85", key: "storage-table", text: "ניהול מחסנים", keyLang: 10218, icon: "setting" },
                { KeyComp: "86", key: "branch-table", text: "ניהול סניפים", keyLang: 10219, icon: "setting" },
                { KeyComp: "87", key: "fix-lab-settings", text: "הגדרות תיקונים", keyLang: 10220, icon: "setting" },
                { KeyComp: "88", key: "winner-settings", text: "תחרויות", keyLang: 10221, icon: "setting" },
                { KeyComp: "89", key: "rear-screen-images", text: "תמונות למסך אחורי", keyLang: 10222, icon: "setting" },
                { KeyComp: "131", key: "item-defaults", text: "ברירות מחדל לפריטים", keyLang: 10223, icon: "setting" },
                { KeyComp: "133", key: "working-houers-defaults", text: "הגדרות לשעון נוכחות", keyLang: 13052, icon: "setting" },
                { KeyComp: "142", key: "import-excel", text: "קליטה מאקסל", keyLang: 13122, icon: "setting" },
                { KeyComp: "134", key: "gift-cards", text: "כרטיסי מתנה", keyLang: 13881, icon: "setting" },
                { KeyComp: "135", key: "kupon-declarations", text: "הגדרות קופונים", keyLang: 14386, icon: "setting" },
                { KeyComp: "136", key: "advertisements", text: "פרסומות", keyLang: 14424, icon: "setting" },
                { KeyComp: "137", key: "update-parameters", text: "עדכון פרמטרים", keyLang: 14675, icon: "setting" },
                { KeyComp: "138", key: "export-excel", text: "יצוא טבלאות לאקסל", keyLang: 15719, icon: "file-excel" },
                { KeyComp: "139", key: "document-remarks", text: "הערות למסמכים", keyLang: 16012, icon: "file-protect" },
                { KeyComp: "140", key: "coupon-declarations", text: "הגדרות קופונים חדש", keyLang: 17324, icon: "gift" },
                { KeyComp: "221", key: "best-club-report-settings", text: "הגדרות לדוחות מועדון לקוחות", keyLang: 17942, icon: "file-protect" },
                { KeyComp: "222", key: "currency-rates", text: "שערי מט\"ח", keyLang: 18170, icon: "dollar" },
                { KeyComp: "223", key: "events", text: "אירועים תקופתיים", keyLang: 18580, icon: "calendar" },
                { KeyComp: "224", key: "cancel-reasons", text: "סיבות ביטול", keyLang: 19089, icon: "file-exclamation" },
                { KeyComp: "225", key: "cities-areas", text: "איזורי ערים", keyLang: 19367, icon: "home" },
                { KeyComp: "-1", key: "printer-labels", text: "מדבקות למדפסות", icon: "setting" },
            ].filter((menuItem, index) => this.isActive(menuItem.KeyComp)),
            menuItemsDrop: []
        };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    onMenuSelect(args) {
        this.myHistory.push(
            this.baseUrl + "/user-settings/" + (args.key !== "home" ? args.key : ""),
            {}
        );
    }

    updatePriorityMenu = () => {
        let maxHeight = 50; //px
        let _height = -1;
        let firstIter = true;

        let listTop = this.state.menuItemsTop;
        let listDrop = this.state.menuItemsDrop;
        // re-populate menu
        for (let i = 0; i < listDrop.length; i++) {
            listTop.push(listDrop.pop());
        }

        //ReactDOM.findDOMNode(this).clientHeight;
        while (_height > maxHeight || _height < 0) {
            //this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            if (!firstIter) {
                this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            }
            else {
                firstIter = false;
            }
            this.setState((prevState, props) => {
                return {
                    menuTag: <Menu
                        mode="horizontal"
                        defaultSelectedKeys={[
                            this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[2] || "home"
                        ]}
                        onSelect={this.onMenuSelect.bind(this)}
                    >
                        {this.renderMenuItemsTop()}
                        <SubMenu title={<Badge count={listDrop.length}>
                            <span><Icon type="ellipsis" /></span></Badge>}>
                            {this.renderMenuItemsDrop()}
                        </SubMenu>
                    </Menu>
                }
            });
            console.log("findDomNode settings: ", (this) ? "true" : "false", this);
            let e: Element | null | Text = ReactDOM.findDOMNode(this);
            _height = (e instanceof HTMLElement) ? e.clientHeight : 0;
            //alert(_height);
        }
    }

    renderMenuItemsTop() {
        let res = [];
        let list = this.state.menuItemsTop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
            return res;
        }
    }

    renderMenuItemsDrop() {
        let res = [];
        let list = this.state.menuItemsDrop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
        }
        return res;
    }

    doUpdateOfPriorityMenu = () => {
        setTimeout(this.updatePriorityMenu);
    };

    // Add event listener
    componentDidMount() {
        setTimeout(this.updatePriorityMenu);
        //window.addEventListener("resize", this.updatePriorityMenu);
        window.addEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    // Remove event listener
    componentWillUnmount() {
        //window.removeEventListener("resize", this.updatePriorityMenu);
        window.removeEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    render() {
        return this.state.menuTag;
    }
}

export default withRouter(UserSettingMenu);

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Icon, Form, Select } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
//import { DatePicker } from 'antd';
//import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import ModalEditEventConsts from "../../components/ModalEditEventConsts.js";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";

///const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 50;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class BusinessLogic extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.api = {
            get: "get_business_logic_list",
            // edit: "edit_standing_orders_settings",
            // selector_pre_defined_msg: "get_pre_defined_msg_list",
        }

        this.id = 'BUSINESS_LOGIC_MAIN';
        this.modalId = 'MODAL_EDIT_EVENT_CONSTS';

        // if (props.data[TableIDs.genericSelectorPreDefinedMsg] === undefined) {
        //     props.data[TableIDs.genericSelectorPreDefinedMsg] = { ...props.data.genericSelector };
        // }

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData }
        }

        //     this.props.dataActions.genericSelectorRefreshDataset(
        //         TableIDs.genericSelectorPreDefinedMsg, this.props.user.companyCode, this.props.user.token, this.api.selector_pre_defined_msg);

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalEditEventConsts, 0) }
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];
    }

	static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //document.title = "Cash On Tab - הגדרות";
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

	componentDidUpdate(){
        document.title = "Cash On Tab - " + this.getText(10205);
    } 
	
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    setStateVal(root: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, root, params);
        this.setState(newState);
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: -1, // filter if not -1
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
            //
            _toDate: null,
            _fromDate: null,
            _msgType: '',
            _preDefineType: '',
        };
        //this.props.dataActions.genericDataSetFilter(
        //    this.mainId, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    loadData() {
        console.log(this.props);

        // this.props.dataActions.genericDataGetEditWithParams(
        //     this.id,
        //     this.props.user.companyCode,
        //     this.props.user.token,
        //     { _id: this.props.ui.data },
        //     this.api.get
        // );
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();

        // this.props.dataActions.genericDataJustSaveEdit(
        //     this.id, this.props.user.companyCode,
        //     this.props.user.token, this.api.edit);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 8;
        // fall through
        switch (sorter.field) {
            case "key": col--; // fallsthrough // 0
            case "mID": col--; // fallsthrough 
            case "mCustomerCode": // fallsthrough  col--;
            case "mFirstName": // fallsthrough  col--;
            case "mLastName": // fallsthrough  col--;
            case "mMessageText": // fallsthrough  col--;
            case "mDateTimeSent": // fallsthrough  col--;
            // mMessageType
            case "mTypeDescription": // fallsthrough  col--;
            case "mDestination": // fallsthrough  col--;
            case "mWasSentSuccessfully": break; // 8
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    render() {
        //const { FromDate, ToDate, endOpen } = this.state;
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            // display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        const tableStyle = {
            width: window.innerWidth > 600 ? '60%':'100%',
            margin: 'auto',
        }

        let listPreDef = Jax.get(this.props.data, TableIDs.genericSelectorPreDefinedMsg + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: index,
                eDescription: y[0],
                eActive: y[1],
                eId: y[2],
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            {
                title: this.getText(11613),
                dataIndex: "eDescription",
                key: "eDescription",
                sorter: false,
                width: '60%',
                render: (text, record) => text

            }, {
                title: this.getText(11614),
                dataIndex: "eActive",
                key: "eActive",
                width: '20%',
                sorter: false,
                render: (text, record) => {
                    return (
                        text==1?this.getText(11616):this.getText(11617)
                        // <Switch key={"mIsMsgActive"} checkedChildren="פעיל" unCheckedChildren="לא פעיל"
                        //     onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { mIsMsgActive: (value) ? 1 : 0 }); }}
                        //     checked={(record.eId) ? (text === 1 || text === '1') ? true : false : false}
                        // // style={{ marginLeft: 4 }}
                        // />
                    )
                },
            }, {
                title: this.getText(11615),
                dataIndex: "eId",
                key: "eId",
                width: '20%',
                sorter: false,
                render: (text, record) => {
                    return <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                        this.props.uiActions.showModal(TableIDs.modalEditEventConsts, record);
                    }} />

                },
            },
        ];

      

        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(11612)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={tableStyle}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form id="standing_orders_settings_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: '1' }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <ResponsiveTable ui={this.props.ui} tableOnly={true} dataSource={dataSource}
                            columns={columns}
                            loading={this.props.ui.workInProgress}
                            //scroll={{ x: 1650 }}
                            bordered
                            pagination={this.props.data[this.id].pagination}
                            onChange={this.handleTableChange}
                            locale={{ emptyText: this.getText(11618) }}
                        />
                    </Form>
                </Card>
                <ModalEditEventConsts
                    id={TableIDs.modalEditEventConsts}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalEditEventConsts)}
                    title={this.getText(11619)}
                />
            </div>
        );
    }
}
export default BusinessLogic;
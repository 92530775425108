/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Modal, Table, Button, Input } from "antd";
import "../App.css";

const Search = Input.Search;

type Props = {
    data: Array<string>,
    visible: boolean,
    onChange: Function,
    defaultValue: string,
    zIndex: number
};

type State = { pagination: Object, query: string };

class ModalSelectItem extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            query: "", pagination: {
                size: "small",
                hideOnSinglePage: true,
                defaultCurrent: 1,
            }
        };

        this.columns = [{
            title: "קוד פריט",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "שם פריט",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "",
            render: (text, record) => {
                return <Button type={record.key === this.props.defaultValue ? "primary" : ""}
                    onClick={() => { this.props.onChange({ selection: record.key, itemName: record.name, visible: false }) }}>בחר</Button>
            }
        }
        ];

    }

    handleSearch(query: string, force: boolean) {
        if (query.length > 2 || force) {
            this.setState({ ...this.state, query: query, pagination: Object.assign({}, this.state.pagination, { current: 1 }) })
        } else if (query.length === 0) {
            this.clearSearch();
        }

    }

    clearSearch() {
        this.setState({ ...this.state, query: "", pagination: Object.assign({}, this.state.pagination, { current: 1 }) })
    }

    render() {
        const data = this.props.data
            .filter((value) => this.state.query.length === 0 || value.indexOf(this.state.query) > -1)
            .map((node: string) => {
                let data = node.split("\f");
                return { key: data[0], name: data[1] };
            });
        return (
            <Modal zIndex={this.props.zIndex} title={"בחר פריט"} visible={this.props.visible} closable={false} destroyOnClose={true} footer={[
                <Button key={"back"} onClick={() => { this.props.onChange({ visible: false }) }}>ביטול</Button>,
                <Button key={"clear"} onClick={() => { this.props.onChange({ selection: null, selectionDisplay: "", visible: false }) }}>נקה בחירה</Button>
            ]} afterClose={this.clearSearch.bind(this)}>
                <div>
                    <Search placeholder={"חפש פריט"} onChange={(value) => { this.handleSearch.apply(this, [value.target.value, false]); }} onSearch={(value) => { this.handleSearch.apply(this, [value, true]); }} size={"large"} enterButton />
                    <Table columns={this.columns} dataSource={data} pagination={this.state.pagination} onChange={(pagination) => { this.setState(Object.assign({}, this.state, { pagination })) }} />
                </div>
            </Modal>
        );
    }

}

export default ModalSelectItem;
/* @flow */
/*jshint esversion: 6 */
import React from 'react';
import "../App.css";
import { Button, Icon, Modal } from "antd";
import { ConnectedRouter, push } from 'react-router-redux';


class AddToHomeScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      installButton: false
    }
    this.installPrompt = null;
  }


  componentDidMount() {
    console.log("Listening for Install prompt");
    window.addEventListener('beforeinstallprompt', e => {
      // For older browsers
      e.preventDefault();
      console.log("Install Prompt fired");
      this.installPrompt = e;
      // See if the app is already installed, in that case, do nothing
      if ((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true) {
        return false;
      }
      // Set the state variable to make button visible
      this.setState({
        installButton: true
      })
    })

    // Detects if device is on iOS 
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.setState({ installButton: true });
    }
  }

 
  installApp = () => {
    this.setState({ installButton: false },

      async () => {
        if (!this.installPrompt) return false;

        await this.installPrompt.prompt();
        // let outcome = await this.installPrompt.userChoice;
        // if (outcome.outcome == 'accepted') {
        //   console.log("App Installed")
        // }
        // else {
        //   console.log("App not installed");
        // }
        // Remove the event reference
        this.installPrompt = null;
      })
  }

  onCancel = () => {
    this.setState({ installButton: false })
  }

  render() {
    return (<Modal
      visible={this.state.installButton}
      footer={[
        <Button onClick={this.installApp} type={"primary"} style={{ margin: 5 }}>{this.props.getText(14457)}</Button>,
        <Button onClick={this.onCancel} style={{ margin: 5 }}>{this.props.getText(14458)}</Button>,
      ]}
      onCancel={this.onCancel}>
      <div style={{ textAlign: "center", color: '#1E90FF' }}>
        <Icon type={"home"} style={{ fontSize: 50, marginBottom: 25 }} />
        <h2 style={{ color: '#1E90FF' }}>{this.props.getText(14456)}</h2>
      </div>
    </Modal>)

    // return this.state.installButton ? (<Button
    //   onClick={this.installApp}>
    //   <Icon type="home" />
    // </Button>) : (<div />)
  }
}

export default AddToHomeScreen;

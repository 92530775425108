
import React from "react";
import { Input, InputNumber, Form, Button, Modal, Select, message, Switch, notification } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import { fixCurrentPage } from "../utils/utils";
import CustomerPicker from "../components/CustomerPicker";

import "../style/generalUI.css";
//import { request } from "https";
import FormItem from "antd/lib/form/FormItem";
//import RadioGroup from "antd/lib/radio/group";
import LangContext from "../contextProvider/LangContext";



//const { Option, OptGroup } = Select;
//const { TextArea } = Input;

const topHeight = 200; // px
const rowHeight = 40;  // px

//type State = {}
class ModalCreateEditPointsCustomer extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = "MODAL_CREATE_EDIT_POINTS";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }


        this.api = {
            get: "get_customer_points_data",
            // create: "create_tag_type",
            // edit: "edit_tag_type",
            // delete: "delete_tag_type",
            selector: "get_tag_relation_list", // TODO: tag type categories
            // selector_tags: "get_tag_types_with_relation_list",
        }

        this.actionButtons = [{
            //     buttonType: 'add',
            //     buttonFunction: this.handleCreateNew
            // }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }/*, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/]

        this.modalData = []
        this.columns = [];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - ";
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorTagRelation, this.props.user.companyCode,
            this.props.user.token, this.api.selector);
        //this.refreshData();

        // window.addEventListener("resize", () => {
        //     clearTimeout(this.doOnResize);
        //     this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        // });
    }

    // componentWillUnmount() {
    //     window.removeEventListener("resize", this.handleWindowSizeChange);
    // }

    // handleWindowSizeChange = () => {
    //     this.setState({ height: window.innerHeight }, this.initFilters);
    // };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        // console.log('xxx-yuda ',numOfRowsByHeight);

        let request = {
            _id: 0,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            request,
            this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };

        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {

            let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
                ? (this.state.height - topHeight) / rowHeight
                : 1);


            let request = {
                _id: 0,
                page: 1,
                recordsPerPage: numOfRowsByHeight, //5,
                sortType: 1,
                sortColumn: 1,
                // api
                _page_num: 1,
                _rows_num: numOfRowsByHeight, //5,
                _isAsc: 1,
                _sort_by: 'col_1',
                _words: '',
            };


        }
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 1
            case "dId": col--;
            case "dDate": col--;
            case "dTime": col--;
            case "dType": col--;
            case "dDocNum": col--;
            case "dSumPoints": break; // 5
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update

            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleOk = () => {
        let dataInput = this.props.data[this.id].editedRecord;
        if (dataInput.cUpdate && dataInput.cUpdate !== '0.00') {
            let request = {
                mCustomerId: dataInput.cId,
                mActionType: dataInput.cPoM,
                mInputType: 1,
                mInput: dataInput.cUpdate,
            }
            // console.log('xxx-yuda save',request);
            let dataSend = Object.keys(request).join('\f') + '\r' + Object.values(request).join('\f');
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, 'change_customer_points', OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);

        } else {
            message.error(this.getText(10946));
        }
    }
    successCallback = (ob: Object) => {
        this.props.dataActions.genericDataGetRequested(
            'CUSTOMER_POINTS_LIST', this.props.user.companyCode, this.props.user.token, 'get_dealsActuals_list');
        let messageGet = JSON.parse(ob.data);
        //message.success(messageGet.msg);
        notification.config({ duration: 2 });
        notification.success({
            message: this.getText(10947),
            description: messageGet.msg,
        });

        this.props.dataActions.genericDataCancelEditing(this.id);
        this.props.uiActions.hideModal(TableIDs.modalCreateEditPointsCustomer);


    }
    errorCallback = (error: any) => {
        message.error(this.getText(10948), 2);
    }
    handleCancel = () => {
        this.props.dataActions.genericDataCancelEditing(this.id);
        this.props.uiActions.hideModal(TableIDs.modalCreateEditPointsCustomer);
    }
    handleClear = () => {
        //this.clear();
        if (this.props.data[this.id].editing > -1) this.props.dataActions.genericDataCancelEditing(this.id);
    }

    render() {
        const { loading } = this.state;
        let request = {

            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let modalTitleStyle = { padding: '4px', paddingLeft: '40px' }




        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.getText(10949)}
                    actionButtons={this.actionButtons}

                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        //console.log(this.props.data[this.id].data, ' xxx-yuda');

        const dataSource = ((this.props.data[this.id].data[0])
            && (this.props.data.customer)
            && (this.props.data.customer.editedRecord.customers)
            && (this.props.data.customer.editedRecord.customers.customerId) !== '0') ?
            this.props.data[this.id].data[0].split("\f") : [0, 0, 0, 0];

        let customerPickerLabel = ' - ' + this.getText(10950);
        let customerPickerDefault = '';
        let baseSource = this.props.data["customer"];
        if (baseSource) {
            let baseSource2 = baseSource.editedRecord["customers"];
            if (baseSource2 && baseSource2.customerId && baseSource2.customerId !== '0') {
                customerPickerLabel = baseSource2.customerId + ' - ' + baseSource2.customerName;
                customerPickerDefault = baseSource2.customerId;
            }
        }

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'50%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}
                footer={
                    [
                        <Button key="back"
                            onClick={this.handleCancel}>{this.getText(10951)}</Button>,
                        <Button key="clear"
                            loading={loading} onClick={this.handleClear}
                        >{this.getText(10952)}</Button>,
                        <Button key="create"
                            // disabled={!this.state.isValidItemCode}
                            type="primary"
                            loading={loading} onClick={this.handleOk}
                        >{this.getText(10953)}</Button>,
                        //<Button key="createLeave" disabled={!this.state.isValidItemCode}
                        // type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                    ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >

                <Form id="create_edit_customer_points_form" className="data-entry-form" layout='vertical' /*layout='horizontal' layout='inline'*/
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '3' : '1' }}
                    onSubmit={this.handleSaveChanges}>

                    <CustomerPicker
                        //disabled={this.shouldCustomerPickerBeDisabled()}
                        defaultCustomerId={customerPickerDefault}
                        onChange={(value) => {
                            this.props.dataActions.genericDataSetEdit("customer.editedRecord.customers", value);
                            this.props.dataActions.genericDataSetFilter(
                                this.id,
                                this.props.user.companyCode,
                                this.props.user.token,
                                { _id: value.customerId },
                                this.api.get);
                        }}
                        label={customerPickerLabel}
                        data={(this.props.data.customerList) ? this.props.data.customerList.data : []}
                        showDialog={this.props.uiActions.uiShowCustomerPickerDialog}
                        hideDialog={this.props.uiActions.uiHideCustomerPickerDialog}
                    />

                    <h3> {this.getText(10954)}: {dataSource[1]}</h3>

                    <FormItem label={this.getText(10955)}>
                        <InputNumber
                            // value={Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.discount")}
                            min={0} step={1} precision={2}
                            value={(this.props.data[this.id].editedRecord.cUpdate) ? this.props.data[this.id].editedRecord.cUpdate : 0}
                            onChange={(value) => {
                                if (dataSource[0] == 0) {
                                    message.error(this.getText(10957));
                                } else {
                                    if (this.props.data[this.id].editing == -1) this.props.dataActions.genericDataStartEditing(this.id, dataSource[0], 0);
                                    this.props.dataActions.genericDataSetEdit(this.id, { ['cUpdate']: value ? value : '0.00' });
                                }
                            }} />
                    </FormItem>
                    <FormItem label={this.getText(10956)}>

                        <Switch checkedChildren={this.getText(10958)} unCheckedChildren={this.getText(10959)}
                            checked={(this.props.data[this.id].editedRecord.cPoM) ?
                                (this.props.data[this.id].editedRecord.cPoM == 1) ?
                                    false : true : true
                            }
                            onChange={(value) => {
                                if (dataSource[0] == 0) {
                                    message.error(this.getText(10957));
                                } else {
                                    if (this.props.data[this.id].editing == -1) this.props.dataActions.genericDataStartEditing(this.id, dataSource[0], 0);
                                    this.props.dataActions.genericDataSetEdit(this.id, { ['cPoM']: value ? 0 : 1 });
                                }
                            }}
                            style={{ margin: '10px' }}
                        />
                    </FormItem>
                </Form>

            </Modal >
        )
    }

    save_edit_customer_points = () => {
        let request = this.props.data[this.id].editedRecord;
        let dataSend = Object.keys(request).join('\f') + '\r' + Object.values(request).join('\f');
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'change_customer_points', OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
    };


}

export default ModalCreateEditPointsCustomer;

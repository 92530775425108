import React from 'react';
import numeral from 'numeral';
const nis = val => `₪ ${numeral(val).format('0,0')}`;/**
 * 减少使用 dangerouslySetInnerHTML
 */
export default class Nis extends React.PureComponent {
    componentDidMount() {
        this.rendertoHtml();
    }

    componentDidUpdate() {
        this.rendertoHtml();
    }

    rendertoHtml = () => {
        const { children } = this.props;
        if (this.main) {
            this.main.innerHTML = nis(children);
        }
    };

    render() {
        return (
            <span
                ref={ref => {
                    this.main = ref;
                }}
            />
        );
    }
}

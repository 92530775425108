/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import PageToolbar from "../../components/PageToolbar";
import "../../App.css";
import { Button, DatePicker, Icon, Row, Input, Popconfirm, Radio, message, Form } from "antd";
import moment from "moment";
import Jax from "../../Jax/jax.es6.module";
import { Descriptions } from "antd";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import { disabledDatePicker } from "../../utils/utils";

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};
type State = {
    lowerDate: string,
    upperDate: string,
    groupType: number,
    areResultsValid: boolean,
    report?: {
        businessId: number,
        businessName: string,
        startDate: string,
        endDate: string,
        c100Records: number,
        d110Records: number,
        d120Records: number,
        iniFile: string,
        dataFile: string
    },
    table?: Array<{
        code: string,
        name: string,
        count: number
    }>
};

class OpenFormat extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            lowerDate: "",
            upperDate: "",
            areResultsValid: false,
            table: [],
            groupType: 1,
            franchiseEdited: null,
            franchiseSaved: false,
            franchiseClickSave: false,
        };

        this.api = {
            user_franchise: "get_franchisees_from_user",
            create_edit_franchise: "create_edit_franchisees_table",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    componentDidMount() {

        this.sendAPI(this.api.user_franchise, "", (ob) => {
            if (ob.data) {
                let a = ob.data.split('\f');
                this.setState({
                    franchiseEdited: {
                        fID: a[0],
                        fName: a[1],
                        fOsek: a[2],
                        fNikuy: a[3],
                        fStreet: a[4],
                        fHouse: a[5],
                        fCity: a[6],
                        fZip: a[7],
                        wManagers: a[8],
                        wEmployees: a[9],
                        fEmail: a[10],
                        fLogo: a[11],
                        fEDI: a[12],
                        fManagersTelegram: a[13],
                        fEmployeesTelegram: a[14],
                        fFooterLogo: a[15],
                        fAccountingNumber: a[16],
                        fPhone: a[17],
                    }
                });
            } else {
                message.error(this.getText(14007))
            }
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10212);
    }

    parameterBuilder(request: Object) {
        request.setColumn("startDate", this.state.lowerDate);
        request.setColumn("endDate", this.state.upperDate);
        request.setColumn("groupType", this.state.groupType);
        request.setColumn("fID", this.state.franchiseEdited.fID);
    }

    getOpenFormatFileFromPath(path: string) {
        let arr = path.split("/");
        arr.shift();
        arr.shift();
        arr.shift();
        return arr.join("/");
    }

    collectDate() {
        let newState = Jax.set(this.state, "areResultsValid", false);
        this.setState(newState, function () {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericNetworkRequest,
                args: [
                    "open_format_collect",
                    this.props.user.companyCode,
                    this.props.user.token,
                    this.parameterBuilder.bind(this),
                    function (state, error, data) {
                        let oState = state;
                        if (error != null) {
                            console.error(error.errorCode, error.errorMessage);
                        } else {
                            window.console.log(data);
                            if (data.length > 0) {
                                let report = JSON.parse(data);
                                let startDate = report.startDate.split("-");
                                let endDate = report.endDate.split("-");
                                report.startDate = startDate[2] + startDate[1] + startDate[0];
                                report.endDate = endDate[2] + endDate[1] + endDate[0];

                                let table = [
                                    {
                                        code: "B110",
                                        name: this.getText(14204),
                                        count: report.b110Records
                                    },
                                    {
                                        code: "C100",
                                        name: this.getText(12393),
                                        count: report.c100Records
                                    },
                                    {
                                        code: "D110",
                                        name: this.getText(12394),
                                        count: report.d110Records
                                    },
                                    {
                                        code: "D120",
                                        name: this.getText(12395),
                                        count: report.d120Records
                                    }
                                ];

                                let localState = Jax.set(this.state, "areResultsValid", true);
                                localState = Jax.set(localState, "report", report);
                                localState = Jax.set(localState, "table", table);
                                this.setState(localState);

                                this.generateReport({
                                    lowerDate: this.state.lowerDate,
                                    upperDate: this.state.upperDate,
                                    fID: this.state.franchiseEdited.fID,
                                    c100Records: this.state.report.c100Records,
                                    d110Records: this.state.report.d110Records,
                                    d120Records: this.state.report.d120Records,
                                    b110Records: this.state.report.b110Records,
                                    iniFile: this.state.report.iniFile.split('/').slice(3, -1).join('/'),
                                    reportName: "reports/OpenFormatReport.pdf"
                                });
                            }
                        }
                        return oState;
                    }.bind(this),
                    {
                        loading: this.getText(12396),
                        failed: this.getText(12397),
                        success: this.getText(12398)
                    }
                ]
            });
        });

    }

    saveFranchise = () => {
        this.setState({ franchiseClickSave: true }, () => {
            let s = this.state.franchiseEdited;
            if (s) {
                const getVal = f => s[f] ? s[f] : ""
                let dataSend = 'fID\ffName\ffOsek\ffNikuy\ffStreet\ffHouse\ffCity\ffZip\ffManagersWhatsapp\ffEmployeesWhatsapp\ffEmail\ffLogo\ffEDI\ffManagersTelegram\ffEmployeesTelegram\ffFooterLogo\ffAccountingNumber\ffPhone\r' +
                    getVal('fID') + '\f' +
                    getVal('fName') + '\f' +
                    getVal('fOsek') + '\f' +
                    getVal('fNikuy') + '\f' +
                    getVal('fStreet') + '\f' +
                    getVal('fHouse') + '\f' +
                    getVal('fCity') + '\f' +
                    getVal('fZip') + '\f' +
                    getVal('wManagers') + '\f' +
                    getVal('wEmployees') + '\f' +
                    getVal('fEmail') + '\f' +
                    getVal('fLogo') + '\f' +
                    getVal('fEDI') + '\f' +
                    getVal('fManagersTelegram') + '\f' +
                    getVal('fEmployeesTelegram') + '\f' +
                    getVal('fFooterLogo') + '\f' +
                    getVal('fAccountingNumber') + '\f' +
                    getVal('fPhone');

                this.sendAPI(this.api.create_edit_franchise, dataSend, () => {
                    this.setState({ franchiseClickSave: false, franchiseSaved: true })
                });
            }
        });
    }

    generateReport(params) {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [this.props.user.companyCode, this.props.user.token, { ...params, REPORT_LOCALE: this.getText(101) }]
        });
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dateFormat = "DD/MM/YYYY";
        const sqlFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm:ss";

        const recordTableColumns = [
            {
                title: this.getText(12399),
                dataIndex: "code",
                key: "code"
            },
            {
                title: this.getText(12400),
                dataIndex: "name",
                key: "name"
            },
            {
                title: this.getText(12401),
                dataIndex: "count",
                key: "count"
            },
        ];

        let receptionScreen = (<span>
            {[
                { f: 'fOsek', t: 14008 },
                { f: 'fNikuy', t: 14009 },
                { f: 'fName', t: 14010 },
                { f: 'fStreet', t: 14011 },
                { f: 'fHouse', t: 14012 },
                { f: 'fCity', t: 14013 },
                { f: 'fZip', t: 14014 },
            ].map((x) => {
                let s = this.state.franchiseEdited ? this.state.franchiseEdited : {}
                return (<Form.Item label={this.getText(x.t)} style={{ width: 400, margin: 10, display: 'inline-block' }}>
                    <Input value={s[x.f]} onChange={(e) => {
                        if ((x.f === 'fNikuy' || x.f === 'fOsek') && e.target.value.length > 9) {
                            message.error(this.getText(18253));
                            return;
                        }
                        this.setState({ franchiseEdited: { ...s, [x.f]: e.target.value } })
                    }} />
                </Form.Item>)
            })}
            <Button
                style={{ display: 'block', margin: 'auto', marginTop: 10 }}
                loading={this.state.franchiseClickSave}
                type={"primary"}
                onClick={this.saveFranchise}
            >{this.getText(14015)}</Button>
        </span>)

        let fieldStyle = { display: 'block', marginTop: 10, marginRight: 50 }

        let productScreen = (<div>
            <Form.Item label={this.getText(14016)}>
                {[{ f: 'lowerDate', t: 12406 }, { f: 'upperDate', t: 12407 }].map((x) => {
                    let minDate = x.f == 'upperDate' ? this.state.lowerDate : null
                    let maxDate = x.f == 'lowerDate' ? this.state.upperDate : null
                    return (<DatePicker
                        defaultValue={this.state[x.f].length > 0 ? moment(this.state[x.f], dateFormat) : null}
                        style={{ width: 250, ...fieldStyle }}
                        disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
                        placeholder={this.getText(x.t)} format={dateFormat} onChange={(date) => {
                            this.setState(Jax.set(this.state, x.f, date != null ? date.format("YYYY-MM-DD") : ""));
                        }} />)
                })}
            </Form.Item>
            <Form.Item label={this.getText(14017)}>
                <Radio.Group value={this.state.groupType} onChange={(e) => {
                    this.setState(Jax.set(this.state, "groupType", e.target.value))
                }}>
                    <Radio style={fieldStyle} value={1}>{this.getText(14005)}</Radio>
                    <Radio style={fieldStyle} value={2}>{this.getText(14006)}</Radio>
                </Radio.Group>
            </Form.Item>
            <Popconfirm placement="right" title={this.getText(12408)}
                onConfirm={this.collectDate.bind(this)} okText={this.getText(12409)} cancelText={this.getText(12410)}>
                <Button
                    loading={this.props.data.ongoingRequests > 0}
                    type={"primary"}
                    style={fieldStyle}
                    htmlType={"button"}>
                    {this.getText(12411)}
                    <Icon type="left-circle" style={{ marginLeft: "auto", marginRight: "8px" }} />
                </Button>
            </Popconfirm>
        </div>)

        let areResultsValid = (<Row >
            <Descriptions title={this.getText(12412)} bordered column={{ xxl: 4, xl: 4, lg: 2, md: 2, sm: 2, xs: 1 }}>
                <Descriptions.Item label={this.getText(12413)}>{this.state.report ? this.state.report.businessId : ""}</Descriptions.Item>
                <Descriptions.Item label={this.getText(12414)}>{this.state.report ? this.state.report.businessName : ""}</Descriptions.Item>
                <Descriptions.Item label={this.getText(12415)}>{this.state.report ? this.state.report.startDate : ""}</Descriptions.Item>
                <Descriptions.Item label={this.getText(12416)}>{this.state.report ? this.state.report.endDate : ""}</Descriptions.Item>
                <Descriptions.Item label={this.getText(12417)} span={4}>
                    <ResponsiveTable ui={this.props.ui} tableOnly={true} columns={recordTableColumns} dataSource={this.state.table} pagination={false} />
                </Descriptions.Item>
                <Descriptions.Item label={this.getText(12418)} span={4}>
                    <a href={this.state.report ? this.state.report.iniFile : ""} target={"_blank"}>
                        {this.state.report ? this.getOpenFormatFileFromPath(this.state.report.iniFile) : ""}
                    </a>
                    <br />
                    <span style={{ fontSize: "85%" }}>{this.getText(12419)}</span>
                </Descriptions.Item>
                <Descriptions.Item label={this.getText(12420)} span={4}>
                    <a href={this.state.report ? this.state.report.dataFile : ""} target={"_blank"}>
                        {this.state.report ? this.getOpenFormatFileFromPath(this.state.report.dataFile) : ""}
                    </a>
                    <br />
                    <span style={{ fontSize: "85%" }}>{this.getText(12419)}</span>
                </Descriptions.Item>
            </Descriptions>
        </Row>)

        return (<div style={divStyle}>
            <PageToolbar title={this.getText(12402)} actionButtons={[]} isOfModal={false} setSearch={null} />
            {
                !this.state.franchiseEdited ? (<h1>{this.getText(14007)}</h1>)
                    : !this.state.franchiseSaved ? receptionScreen
                        : this.state.areResultsValid ? areResultsValid
                            : productScreen}
        </div>
        );
    }
}

export default OpenFormat;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../App.css";
import { Tree } from "antd";

class JsonTreeView extends Component {
    constructor(props: Props) {
        super(props);
        this.state = { };
        this.treeNumeratorKeys = 0;
    }

    treeFormat = (obj, vInArray) => {
        let keys = Object.keys(obj);
        return keys.map(x => {
            let children = this.treeValue(obj[x], vInArray);
            this.treeNumeratorKeys++;
            return { key: this.treeNumeratorKeys, title: x, children }
        })
    }

    treeLastData = (value, vIsStr, vInArray) => {
        let title = vIsStr ? '"' + value + '"' : value
        this.treeNumeratorKeys++;
        let res = { key: this.treeNumeratorKeys, title }
        return vInArray ? res : [res]
    }

    treeValue = (value, vInArray) => {
        switch (typeof value) {
            case "bigint": case "number":
                return this.treeLastData(value, false, vInArray);
            case "boolean":
                return this.treeLastData(value ? 'true' : 'false', false, vInArray);
            case "string":
                return this.treeLastData(value, true, vInArray)
            case "object":
                if (value) {
                    if (Array.isArray(value)) {//array
                        let res = []
                        value.forEach(x => {
                            let y = this.treeValue(x, true)
                            if (Array.isArray(y)) res = [...res, ...y]
                            else res = [...res, y]
                        });
                        return res;
                    } else {// obj
                        return this.treeFormat(value, vInArray)
                    }
                } else {//null
                    return this.treeLastData('null', false, vInArray)
                }
        }
    }

    render() {

        let jobView = this.props.jsonData;
        let jobData = jobView ? JSON.parse(jobView) : {}
        let jobTreeData = this.treeFormat(jobData);

        return jobView ? (<Tree
            style={{ direction: 'ltr', textAlign: 'left' }}
            showLine={true}
            showIcon={true}
            treeData={jobTreeData}
            defaultExpandAll
        />) : ""
    }

}

export default JsonTreeView;
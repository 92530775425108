
import React from "react";
import {
    Form, Icon, Popconfirm, Tooltip, Button, Modal, DatePicker, InputNumber, Select
} from 'antd';
import PageToolbar from "../../components/PageToolbar";
import ModalCreateEditWinner from "../../components/ModalCreateEditWinner";
import TableIDs from '../../data/TableIDs.js';

import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import GenericSelector from "../../components/GenericSelector";
import { disabledDatePicker } from "../../utils/utils";

const topHeight = 340; // px
const rowHeight = 30;  // px
const Option = { Select }

class WinnerSettings extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            modalReport: false,
            WinnerResults: {
                mDateStart: null,
                mDateEnd: null,
                mWinnerList: '',
                mTopRes: 3,
                reportName: "reports/WinnerResults.pdf"
            }
        }

        this.id = "WINNER_DECLARATION_DATA";

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.api = {
            get: "get_table_winner_declaration",
            delete: "delete_row_winner_declaration",
        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
        if (props.data[TableIDs.genericSelectorWinners] === undefined) {
            props.data[TableIDs.genericSelectorWinners] = { ...props.data.genericSelector }
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - הגדרות תחרויות";
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10221);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 0,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "wId": col--;
            case "wDescription": col--;
            case "wDateFrom": col--;
            case "wDateTo": break; // 12
            default:
                col = 0;
        }

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    dataObject = (id) => {
        let data = this.props.data[id];
        if (data && data.data[0]) {
            return data.data.map((x, indexData) => {
                let y = x.split("\f");
                let ob = { key: y[0] }
                data.headers.map((z, index) => ob = { ...ob, [z]: y[index], index: indexData });
                return ob;
            });
        }
    }

    modalWinner = (wId, wGroup, wSubject) => {
        this.setState({ wId, wGroup, wSubject },
            this.props.uiActions.showModal(TableIDs.modalCreateEditWinner, { wId, wGroup, wSubject }));
    }

    titleBar = (title, actionButtons, genericActionButtons) => {

        return (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={title}
                    actionButtons={actionButtons}
                    genericActionButtons={genericActionButtons}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);
    }

    modalReport = () => {
        let mobile = window.innerWidth <= 600;

        let fItem = (title, content) => (<Form.Item label={this.getText(title)}>{content}</Form.Item>)

        let getField = (title, field, type) => {
            let value = this.state.WinnerResults[field];
            let onChange = (v) => { this.setState({ WinnerResults: { ...this.state.WinnerResults, [field]: v } }) }

            switch (type) {
                case 'date':
                    let minDate = field == 'mDateEnd' ? this.state.WinnerResults.mDateStart : null
                    let maxDate = field == 'mDateStart' ? this.state.WinnerResults.mDateEnd : null
                     return fItem(title, <DatePicker
                        format="DD-MM-YYYY"//"YYYY-MM-DD"
                        disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
                value={value ? moment(value, "YYYY-MM-DD") : null}
                        onChange={(v) => { onChange(v ? v.format("YYYY-MM-DD") : null) }}
                    />);

                case 'number':
                    return fItem(title, <InputNumber value={value} onChange={onChange} />);

                case 'list':
                    return fItem(title, <GenericSelector
                        id={TableIDs.genericSelectorWinners} api={"winners_list"} multi={true}
                        value={value ? value.split(',') : []} onChange={(v) => { onChange(v ? v.join(',') : '') }}
                        user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
                    />);

                case 'report':
                    return fItem(title, <Select
                        style={{ width: 250 }} value={value} onChange={onChange}
                    >
                        <Option key={"reports/WinnerResults.pdf"}>{this.getText(10769)}</Option>
                        <Option key={"reports/WinnerResultsExcel.xls"}>{this.getText(10770)}</Option>
                        <Option key={"reports/WinnerResults.docx"}>{this.getText(10771)}</Option>
                    </Select>)
            }
        }

        return (<Modal
            width={window.innerWidth > 1000 ? 1000 : "95%"}
            visible={this.state.modalReport}
            onCancel={() => {
                this.setState({
                    modalReport: false,
                    WinnerResults: {
                        mDateStart: null,
                        mDateEnd: null,
                        mWinnerList: '',
                        mTopRes: 3,
                        reportName: "reports/WinnerResults.pdf"
                    }
                })
            }}
            title={this.titleBar(this.getText(14309), [], [])}
            footer={false}
        >
            <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '2', paddingTop: mobile ? 50 : 0 }}
                onSubmit={this.handleSaveChanges}
            >
                {getField(14310, 'mDateStart', 'date')}
                {getField(14311, 'mDateEnd', 'date')}
                {getField(14312, 'mWinnerList', 'list')}
                {getField(14313, 'mTopRes', 'number')}
                {getField(14314, 'reportName', 'report')}

                <div className="action_footer">
                    <Button type="primary" htmlType="submit" form="report_form" onClick={() => {
                        let params = { ...this.state.WinnerResults, REPORT_LOCALE: this.getText(101) }
                        this.props.ActionQueue.addToQueue({
                            action: this.props.dataActions.generateReport,
                            args: [this.props.user.companyCode, this.props.user.token, params]
                        });
                    }}>{this.getText(10772)}</Button>
                </div>
            </Form>

        </Modal>)
    }

    render() {

        let columns = [
            {
                title: this.getText(11519),
                dataIndex: "wId",
                key: "wId",
                sorter: true,
                width: '10%',
                render: (text, record) => text
            }, {
                title: this.getText(11520),
                dataIndex: "wDescription",
                key: "wDescription",
                sorter: true,
                width: '20%',
                render: (text, record) => text
            }, {
                title: this.getText(11521),
                dataIndex: "wDateFrom",
                key: "wDateFrom",
                sorter: true,
                width: '20%',
                render: (text, record) => <div dir="ltr">{text}</div>
            }, {
                title: this.getText(11522),
                dataIndex: "wDateTo",
                key: "wDateTo",
                sorter: true,
                width: '20%',
                render: (text, record) => <div dir="ltr">{text}</div>
            }, {
                title: this.getText(11523),
                dataIndex: "wId",
                key: "wId",
                width: '10%',
                render: (text, record) => {
                    return (<span>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                            this.modalWinner(text, record.wGroup, record.wSubject);
                        }} />
                        <Popconfirm title={this.getText(11524)}
                            onConfirm={() => {
                                this.props.dataActions.genericDataDeleteRow(
                                    this.id, text, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                            }}
                            okText={this.getText(11525)} cancelText={this.getText(11526)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                        </Popconfirm>
                    </span >)
                }
            },
        ];

        let actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.modalWinner(0,0,0) }
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

        let reportButton = (<Tooltip title={this.getText(14308)}>
            <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                onClick={() => {
                    this.setState({ modalReport: true })
                }}><Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" /></Button>
        </Tooltip >)


        return (
            <div >
                {this.titleBar(this.getText(11518), actionButtons, reportButton)}
                <ResponsiveTable
                    tableOnly={true}
                    percentageToMobile={[false, 40, 30, 30]}
                    ui={this.props.ui}
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    onChange={this.handleTableChange}
                />
                {/* <Table
                    loading={this.props.ui.workInProgress}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    dataSource={this.dataObject(this.id)}
                    columns={this.columns}
                    locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                /> */}
                <ModalCreateEditWinner
                    id={TableIDs.modalCreateEditWinner}
                    user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditWinner)}
                // title={"עריכת תחרות"}
                />
                {this.modalReport()}
            </div>
        )
    }

}

export default WinnerSettings;

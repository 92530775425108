
import React from "react";
import { notification, Form, Input, Button, Modal, Select, message, Upload, Checkbox, InputNumber } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import Jax from "../Jax/jax.es6.module";
import moment from "moment";
import LangContext from "../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

//type State = {}
class ModalStandingOrdersChargesReport extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            loadingImg: false,
        }

        this.id = 'MODAL_STANDING_ORDERS_CHARGES_REPORT';

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        //this.state._count = 0;
        //this.state._id = 0;

        this.state.StandingOrdersCharges = {
            StandingOrderTotalPeriod: '',
            StandingOrderTotalID: 0,
            ApplyRangeApplyCharge: 0,
            ApplyRangeLowerCharge: 0,
            ApplyRangeUpperCharge: 0,
            ApplyRangeApplyCustomerCode: 0,
            ApplyRangeLowerCustomerCode: 0,
            ApplyRangeUpperCustomerCode: 0,
            StatusList: '',
            StatusTextList: '',
            reportName: "reports/standingOrdersCharges.pdf",
        };

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorStandingOrderStatus] === undefined) {
            props.data[TableIDs.genericSelectorStandingOrderStatus] = { ...props.data.genericSelector };
        }

        this.api = {
            //get: "get_branch_by_id",
            //edit: "edit_create_branch",
            //selector: "list_pos",
            //delete: "delete_branch",
            //runCharges: "run_standing_order_charges",
            selector: "get_standing_order_status_list",
            //getCount: "get_customer_with_creditcards_count",
        }

        this.actionButtons = [
            /*{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        },*/];

        this.dataSource = [];

        /*
        this.openNotificationWithIcon = (type, msg, descr) => {
            notification[type]({
                message: msg,
                description: descr,
            });
        };
        */
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - לקוחות להוראת קבע";
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStandingOrderStatus, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("StandingOrdersCharges", {
                            [field]: data,
                            StatusTextList: encodeURIComponent(Jax.get(this.props.data, TableIDs.genericSelectorStandingOrderStatus + ".dataset", [])
                                .filter((obj, index) => data.split(',').includes(obj['code']))
                                .map((obj, index) => obj['name'])
                                .join(','))
                        });
                    else
                        this.setReportParameters("StandingOrdersCharges", { [field]: '', StatusTextList: '' });
                }} />);
    }

    handleOkLeave = () => { this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch); }
    handleOk = () => { }
    handleCancel = () => { this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch); }
    handleClear = () => { }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStandingOrderStatus, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    /*
    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataJustSaveEdit,
            args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
        })
        // update main table after change
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataGetRequested,
            args: ["BRANCHES_MAIN", this.props.user.companyCode, this.props.user.token, "get_branches_list_table"]
        });
        this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
    }
    */

    render() {
        const { loading, loadingImg } = this.state;
        //const sqlFormat = "YYYY-MM-DD";
        //const timeFormat = "HH:mm:ss";
        //const dateFormat = "DD/MM/YYYY";

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10908)}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        const list = this.props.data[TableIDs.genericSelectorStandingOrderStatus].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        });

        const inputStyle = { marginLeft: '4px', width: '40%' };

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'60%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '2' : '1' }}
                    onSubmit={this.handleSaveChanges}
                >
                    <FormItem label={this.getText(10909)}>
                        <Checkbox onChange={this.handleCheckBoxRange}
                            //ApplyRange_Apply
                            //checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                            onChange={(value) => this.setReportParameters("StandingOrdersCharges", { ApplyRangeApplyCustomerCode: (value.target.checked) ? 1 : 0 })}
                            style={{ marginLeft: '4px' }}

                        />
                        <InputNumber
                            //ApplyRange_Lower
                            //value={}
                            min={0} precision={0}
                            onChange={(value) => this.setReportParameters("StandingOrdersCharges", { ApplyRangeLowerCustomerCode: value })}
                            disabled={!this.state.StandingOrdersCharges.ApplyRangeApplyCustomerCode}
                            style={inputStyle}

                        />
                        <InputNumber
                            //ApplyRange_Upper
                            //value={}
                            min={0} precision={0}
                            onChange={(value) => this.setReportParameters("StandingOrdersCharges", { ApplyRangeUpperCustomerCode: value })}
                            disabled={!this.state.StandingOrdersCharges.ApplyRangeApplyCustomerCode}
                            style={inputStyle}

                        />
                    </FormItem>
                    <FormItem label={this.getText(10910)}>
                        <Checkbox onChange={this.handleCheckBoxRange}
                            //ApplyRange_Apply
                            //checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                            onChange={(value) => this.setReportParameters("StandingOrdersCharges", { ApplyRangeApplyCharge: (value.target.checked) ? 1 : 0 })}
                            style={{ marginLeft: '4px' }}

                        />
                        <InputNumber
                            //ApplyRange_Lower
                            //value={}
                            precision={2}
                            onChange={(value) => this.setReportParameters("StandingOrdersCharges", { ApplyRangeLowerCharge: value })}
                            disabled={!this.state.StandingOrdersCharges.ApplyRangeApplyCharge}
                            style={inputStyle}

                        />
                        <InputNumber
                            //ApplyRange_Upper
                            //value={}
                            precision={2}
                            onChange={(value) => this.setReportParameters("StandingOrdersCharges", { ApplyRangeUpperCharge: value })}
                            disabled={!this.state.StandingOrdersCharges.ApplyRangeApplyCharge}
                            style={inputStyle}

                        />
                    </FormItem>

                    <FormItem label={this.getText(10911)}>
                        {this.createCheckBox('StatusList', Jax.get(this.props.data, TableIDs.genericSelectorStandingOrderStatus + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                        <Select key={"StatusList"} style={{ width: 250 }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4}
                            //placeholder="Please select"
                            //className={""}
                            //defaultValue={1}
                            onChange={(value) => this.setReportParameters("StandingOrdersCharges",
                                {
                                    StatusList: value.join(','),
                                    StatusTextList: encodeURIComponent(Jax.get(this.props.data, TableIDs.genericSelectorStandingOrderStatus + ".dataset", [])
                                        .filter((obj, index) => value.includes(obj['code']))
                                        .map((obj, index) => obj['name'])
                                        .join(','))
                                })}
                            value={(this.state.StandingOrdersCharges.StatusList === '') ? [] : this.state.StandingOrdersCharges.StatusList.split(',')}
                        //notFoundContent={"לא נמצאו עובדים"}       
                        >
                            {list}
                        </Select>
                    </FormItem>

                    <FormItem key={"reportName"} label={this.getText(10912)}>
                        <Select style={{ width: 250 }}
                            //size={"large"}
                            className={"reports/StandingOrdersCharges.pdf"}
                            //combobox={null}
                            defaultValue={"pdf"}
                            onChange={(value) => {
                                this.setReportParameters("StandingOrdersCharges", {
                                    reportName: "reports/standingOrdersCharges" + (value === "xls" ? "Excel" : "") + "." + value,
                                    type: value
                                });
                            }}
                        >
                            <Option key={"pdf"}>{this.getText(10913)}</Option>
                            <Option key={"xls"}>{this.getText(10914)}</Option>
                            <Option key={"docx"}>{this.getText(10915)}</Option>
                        </Select>
                    </FormItem>
                    <FormItem key={"submit"}>
                        <Button type={"primary"} onClick={() => {
                            //const type = this.state.RequestedReport;
                            this.generateReport.apply(this, ["StandingOrdersCharges"])
                        }}>{this.getText(10916)}</Button>
                    </FormItem>
                </Form>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toggle === false && this.props.toggle === true) {
            const period: String = this.props.ui.data.mPeriod;
            this.setReportParameters("StandingOrdersCharges", {
                StandingOrderTotalID: this.props.ui.data.mId,
                StandingOrderTotalPeriod: (period.length === 0) ? '' : period.slice(0, 2) + '/' + period.slice(-2)
            })
        }
    }
}

export default ModalStandingOrdersChargesReport;

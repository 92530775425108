/* @flow */
/*jshint esversion: 6 */
import { Button, Icon } from "antd";
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import PosMessages from "../mailing/PosMessages";


class PosMessagesForAutoIphone extends Component {
    state = {
        modalOpen: false
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    render() {
        return (<div style={{ textAlign: "center", marginBottom: 24, display: 'inline-block', verticalAlign: 'text-top' }}>
            <Button onClick={() => { this.setState({ modalOpen: true }) }}>
                <Icon type={"mail"} style={{ marginLeft: 5 }} />
                {this.getText(17910)}
            </Button>
            <PosMessages
                {...this.props} isFromCoto
                visibleFromCoto={this.state.modalOpen}
                onCancelFromCoto={() => { this.setState({ modalOpen: false }) }} />
        </div>)
    }
}

export default PosMessagesForAutoIphone;
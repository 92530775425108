
import React from "react";
import { Form, Card, InputNumber, Button, message } from 'antd';
//import TableIDs from '../../data/TableIDs.js';
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import SelectorItems from "../../components/SelectorItems";
import SelectorItemClassic from "../../components/SelectorItemClassic";

class GiftCards extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
        }

        this.id = "GIFT_CARDS_TABLE";

        this.api = {
            get: "get_gift_cards_table",
            edit: "edit_gift_cards_table",
        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilter();
    }

    initFilter = () => {
        this.props.dataActions.genericDataGetEditRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(13881);
    }

    getInputNumber = (text, field, precision, max) => {
        return (<Form.Item label={this.getText(text)}>
            <InputNumber
                step={1} precision={precision} max={max}
                value={this.props.data[this.id].editedRecord[field]}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }}
            />
        </Form.Item>)
    }

    getItemPicker = (text, field, nameField) => {
        let onChange = (code, name) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: code, [nameField]: name }) }
        let editedRecord = this.props.data[this.id].editedRecord;

        return (<Form.Item label={this.getText(text)}>

            <SelectorItemClassic
                {...this.props}
                key={'itemSelector_' + field}
                valueId={editedRecord[field]}
                valueName={editedRecord[nameField]}
                onChange={(code, name) => { onChange(code, name) }}
                idSelector={'itemSelector_' + field}
            // ctrlBKeyFlag={null} isExpandMatrixFatherItem={false} //itemType={0}
            // value={editedRecord[field]}
            // user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
            // onChangeSearch={(s) => {onChange(s.mCode, s.mName)}}
            // onChange={(s) => { onChange(s.target.value) }}
            // onBlur={(s) => { this.checkIfItemExist(field, s.target.value.ItemCode) }}
            // onKeyDown={() => { } /*this.keyboardListener.bind(this)*/}
            />
            {/* <h3>{editedRecord[nameField]}</h3> */}
        </Form.Item>)
    }

    handleOk = () => {
        const { gItemCode, gValidityPeriod, gDiscount, gDiscountItem, gMinVal } = this.props.data[this.id].editedRecord;
        if (!gItemCode || !gValidityPeriod || !gDiscountItem || gMinVal === null || gDiscount === null || gMinVal === "" || gDiscount === "") {
            message.error(this.getText(18595))
            return
        }
        this.props.dataActions.genericDataJustSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.edit);
        setTimeout(this.initFilter, 1000);
    }

    render() {
        return (<Card
            style={{
                padding: 4,
                margin: 'auto',
                marginTop: 5,
                textAlign: 'center',
                width: window.innerWidth > 600 ? '60%' : '95%'
            }}
            title={(<PageToolbar
                title={this.getText(13882)}
                actionButtons={[{
                    buttonType: 'refresh',
                    buttonFunction: this.initFilter
                }]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataActions={this.props.dataActions} />)}
            actions={[<Button type='primary' style={{ fontSize: 16 }} onClick={this.handleOk}>{this.getText(13889)}</Button>]}
        >
            <Form id="gift-cards-settings" className="data-entry-form" layout='horizontal'
                style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '2' : '1' }}
                onSubmit={this.handleOk} >

                {this.getItemPicker(13883, 'gItemCode', 'gItemName')}
                {this.getInputNumber(13884, 'gValidityPeriod', 0)}
                {this.getInputNumber(13885, 'gDiscount', 2)}
                {this.getItemPicker(13886, 'gDiscountItem', 'gDiscountItemName')}
                {this.getInputNumber(13887, 'gMinVal', 2)}
                {this.getInputNumber(19250, 'gRandomLen', 0, 18)}
            </Form>
        </Card>)

    }

}

export default GiftCards;

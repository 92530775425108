/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import LangContext from "../contextProvider/LangContext";
import { message, Button } from "antd";
//import { MailOutlined } from '@ant-design/icons';

class FormUnsubscribe extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.id = "FORM_UNSUBSCRIBE";
        this.api = {
            unsubscribe: "unsubscribe_from_mailing",
        }

        this.state = {
            isUserAnswered: 0,
        }

        this.forms = this.props.forms;
        if (props.forms[this.id] === undefined) props.forms[this.id] = {}
    }

    static contextType = LangContext;

    getText = (id) => {
        let data = this.props.forms.langTexts;
        return data && data["t" + id] ? data["t" + id] : '[' + id + ']'
    }

    /*
    componentDidMount() {   }
    componentDidUpdate(p) {   }
    */
    parse_params_to_json = (params) => {
        console.log(params)
        return params
            .split('&')
            .reduce((prev, curr) => {
                const [key , val] = curr.split('=');
                if (key) {
                    return { ...prev, [key]:val }
                } else {
                    return prev
                }
            }, {})
    }

    render() {
        const { isUserAnswered } = this.state;
        //const params = this.forms.formsFunctions.getParams();
        const formsFunctions = this.forms.formsFunctions;
        const paramsDb = this.forms.formData.params;
        const time = 8;
        const succ_msg = () => { message.success(this.getText(90056) , time) }
        const fail_msg = () => { message.success(this.getText(90057), time) }
        const unknown_cust_msg = () => { message.success(this.getText(90058), time) }
        const baseStyle = { width: "94%", paddingLeft:8, paddingRight:8 }
        const shownStyle = { ...baseStyle, display: 'block' }
        const hiddenStyle = { ...baseStyle, display: 'none' }

        return (
            <div style={(isUserAnswered == 1) ? hiddenStyle : shownStyle}>
                <h3 style={{ paddingLeft: 8, paddingRight: 8 }}>{this.getText(90059)}</h3>
                <Button type="primary" onClick={
                    (e) => {
                        console.log("paramsDb: ", paramsDb);
                        let json = this.parse_params_to_json(paramsDb)
                        console.log("params: ", json);

                        if (parseInt(json.custId) > 0) {
                            formsFunctions.sendAPI(this.api.unsubscribe, 'custId\r' + json.custId,
                                (ob) => { this.setState({ isUserAnswered: 1 }, succ_msg()) },
                                (e) => {
                                    console.error(e);
                                    this.setState({ isUserAnswered: 1 }, fail_msg())
                                })
                        } else {
                            unknown_cust_msg();
                        }
                    }
                }>{this.getText(90055)}</Button>
            </div>
        )
    }
}
export default FormUnsubscribe;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import { Card, Button, Form, Select, Checkbox, Radio, message } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import { disabledDatePicker } from "../../utils/utils";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

class HilanReport extends Component {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            HoursReport: {
                BranchList: '',
                FromDate: new moment().startOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
                ToDate: new moment().endOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
                withILID: 0,
                wageSystem: null,
            },
            isExtended: 0,
            FromDate: new moment().startOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
            ToDate: new moment().endOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
        };

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };

        this.api = {
            selector_branch: "get_branch_list",
            get_data1: "get_hilan_hours_report",
            get_data2: "get_tamal_hours_report",
            get_data3: "get_michpal_hours_report",
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15720);
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (!params.wageSystem) {
            message.error(this.getText(16248));
            return;
        }
        if (!params.BranchList) {
            params = { ...params, BranchList: Jax.get(this.props.data, TableIDs.genericSelectorBranches + ".dataset", []).map((obj, index) => obj['code']).join(',') }
        }
        let BranchList = params.BranchList ? params.BranchList : Jax.get(this.props.data, TableIDs.genericSelectorBranches + ".dataset", []).map((obj, index) => obj['code']).join(',');

        let dataSend = "BranchList\fFromDate\fToDate\fwithILID\r" + BranchList + '\f' + params.FromDate + '\f' + params.ToDate + '\f' + params.withILID;

        let script = '';
        let fileName = '';

        switch (params.wageSystem) {
            case 1:
                script = this.api.get_data1;
                fileName = 'hilan.txt';
                break;
            case 2:
                script = this.api.get_data2;
                fileName = 'ADCLOCK.DAT';
                break;
            case 3:
                script = this.api.get_data3;
                fileName = 'Michpal.csv';
                break;
        }

        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", (ob) => {
            let data = ob.data ? ob.data.split('\r').join('\r\n') : [];
            let blob = new Blob(["\ufeff", data], { type: "text/plain;charset=utf8" });
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.click();

        }, (e) => { console.error(e) });
    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorBranches, this.props.user.companyCode, this.props.user.token, this.api.selector_branch]
        });
    }

    disabledStartDate = (FromDate) => {
        const ToDate = this.state.ToDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.FromDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    onChange = (field, value) => {
        this.setReportParameters("HoursReport", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('FromDate', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('ToDate', value ? value.format("YYYY-MM-DD") : null);
    }

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }

    createCheckBox = (field, sName) => {
        let data = Jax.get(this.props.data, TableIDs[sName] + ".dataset", []).map((obj, index) => obj['code']).join(',');
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("HoursReport", { [field]: data });
                    else
                        this.setReportParameters("HoursReport", { [field]: '' });
                }} />);
    }

    getSwitch = (field, tText, cText, ucText) => {
        return (<FormItem label={this.getText(tText)}>
            <RadioGroup
                value={this.state.HoursReport[field]}
                onChange={(value) => {
                    this.setReportParameters("HoursReport", { [field]: value.target.value });
                }}>
                <Radio value={1}>{this.getText(cText ? cText : 13084)}</Radio>
                <Radio value={0}>{this.getText(ucText ? ucText : 13085)}</Radio>
            </RadioGroup>
        </FormItem>)
    }

    getSelect = (key, sName, ph) => {
        let lists = Jax.get(this.props.data, TableIDs[sName] + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });

        return (<FormItem label={this.getText(ph)}>
            {this.createCheckBox(key, sName)}
            < Select key={key} style={{ width: "95%" }} showSearch filterOption={true}
                optionFilterProp="valuefilter" mode="multiple" maxTagCount={5} placeholder={this.getText(ph)}
                onChange={(value) => this.setReportParameters("HoursReport", { [key]: value.join(',') })}
                value={(this.state.HoursReport[key] === '' || this.state.HoursReport[key] === undefined) ? [] : this.state.HoursReport[key].split(',')}
            >
                {lists}
            </Select>
        </FormItem>)
    }

    render() {
        const { FromDate, ToDate, endOpen } = this.state;
        const mobile = window.innerWidth <= 600 ? true : false;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        const resToMobile = (n) => (!mobile) ? {
            width: (n - 2) + '%',
            margin: '1%',
            display: 'inline-block',
            verticalAlign: 'text-top'
        } : {
            display: 'block',
            padding: 8
        }

        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(15721)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: !mobile ? 800 : '95%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}
                    actions={[
                        <Button
                            type={"primary"}
                            disabled={!this.state.HoursReport.wageSystem}
                            onClick={() => { this.generateReport.apply(this, ["HoursReport"]) }}
                        >{this.getText(15722)}</Button>
                    ]}>

                    <Form className="data-entry-form" layout='horizontal' onSubmit={this.handleSaveChanges}
                        style={{ columnWidth: 'auto', columnCount: '1' }} >

                        <div style={resToMobile(40)}>
                            <FormItem label={this.getText(16245)}>
                                < Select key={'wageSystem'} style={{ width: "95%" }} showSearch filterOption={true} optionFilterProp="valuefilter"
                                    onChange={(value) => this.setReportParameters("HoursReport", { wageSystem: value })}
                                    value={this.state.HoursReport.wageSystem}
                                >
                                    <Option key={1} value={1} valuefilter={this.getText(16246)}>{this.getText(16246)}</Option>
                                    <Option key={2} value={2} valuefilter={this.getText(16247)}>{this.getText(16247)}</Option>
                                    <Option key={3} value={3} valuefilter={this.getText(16249)}>{this.getText(16249)}</Option>
                                </Select >
                            </FormItem>
                        </div>
                        <div style={resToMobile(60)}>
                            {this.getSelect('BranchList', 'genericSelectorBranches', 15724)}
                        </div>
                        <div style={resToMobile(35)}>

                            <FormItem label={this.getText(15725)}>
                                <DatePicker
                                    disabledDate={d => disabledDatePicker(d, null, ToDate)}
                                    // disabledDate={this.disabledStartDate}
                                    // showTime
                                    format="DD-MM-YYYY" //"YYYY-MM-DD"
                                    value={FromDate ? moment(FromDate, "YYYY-MM-DD") : null}
                                    placeholder="Start"
                                    onChange={this.onStartChange}
                                    onOpenChange={this.handleStartOpenChange}
                                />
                            </FormItem>
                        </div>
                        <div style={resToMobile(35)}>

                            <FormItem label={this.getText(15726)}>
                                <DatePicker
                                    disabledDate={d => disabledDatePicker(d, FromDate, null)}
                                    // disabledDate={this.disabledEndDate}
                                    // showTime
                                    format="DD-MM-YYYY" //"YYYY-MM-DD"
                                    value={ToDate ? moment(ToDate, "YYYY-MM-DD") : null}
                                    placeholder="End"
                                    onChange={this.onEndChange}
                                    open={endOpen}
                                    onOpenChange={this.handleEndOpenChange}
                                />
                            </FormItem>
                        </div>
                        <div style={resToMobile(30)}>
                            {this.getSwitch('withILID', 15727, 15729, 15728)}
                        </div>
                    </Form>
                </Card>
            </div>
        );
    }

}

export default HilanReport;
/* @flow */
/*jshint esversion: 6 */
import React from 'react';
import { Redirect } from "react-router-dom"

class AdminCompLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectTo: ''
        }
    }

    formParams = () => {
        let res = {}
        if (this.props.formData.params) {
            this.props.formData.params.split("&").forEach(x => {
                let y = x.split('=');
                res = { ...res, [y[0]]: y[1] }
            })
        }
        return res;
    }

    componentDidMount() {
        this.props.sendAPI("get_user_admin_login", "", (ob) => {
            let res = ob.data ? ob.data.split("\f") : []
            let pageUrl = this.formParams().pageUrl;
            this.props.login(this.props.compCode, res[0], res[1], "");
            this.setState({ redirectTo: this.props.baseUrl + (pageUrl ? pageUrl : "") })
        }, (e) => { console.error(e) })
    }

    render() {
        return this.state.redirectTo ? (<Redirect to={this.state.redirectTo} />) : (<div />)
    }
}

export default AdminCompLink;
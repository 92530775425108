// @flow
/*jshint esversion: 6 */
/*jslint es6*/
import { ReduceStore } from "flux/utils";
import ActionTypes from "./ActionTypes";
import Actions from "./Actions";
import Dispatcher from "./Dispatcher";
import { JobResponse, ReportBlob, Job, User, ProcessType, OutputType } from "../JmReact";
import Jax from "../Jax/jax.es6.module";
import ActionQueue from "./ActionQueue";
import LangContext from "../contextProvider/LangContext";

export type duplet = {
    code: string,
    name: string,
}

type selector = {
    data: Array<duplet>,
    picks: Array<duplet>,
    pick: string
}

type DataState = {
    ongoingRequests: number,
    usingPoints: Object,
    earningPoints: Object,
    genericData: Object,
    itemTags: Array<any>,
    customerTags: Array<any>,
    itemCodes: Array<any>,
    bargainTypes: Array<Object>,

    requests: Object,

    selectors: {
        salesAlert: selector,
        prices: selector,
        suppliers: selector,
        taxTypes: selector,
        customerFields: selector,
    }
}

class DataStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getInitialState() {
        let rowHeight = 30;
        let topHeight = 340;
        let numOfRowsByHeight = parseInt((window.innerHeight - topHeight > rowHeight) ? (window.innerHeight - topHeight) / rowHeight : 1);

        return {
            ongoingRequests: 0,
            usingPoints: {},
            earningPoints: {
                data: [], count: 0, pagination: {
                    current: 1,
                    defaultCurrent: 1,
                    defaultPageSize: numOfRowsByHeight, // 5,
                    total: 0,
                    hideOnSinglePage: false,
                    showQuickJumper: false,
                    showTotal: (total: number, range: Array<number>) => `  מציג ${range[0]} - ${range[1]} מתוך ${total}`,
                    // showTotal: (total: number, range: Array<number>) => '  ' + this.getText(10372) + ' ' + range[0] + ' - ' + range[1] + ' ' + this.getText(10373) + ' ' + total,
                    sortColumn: 0,
                    sortType: 1

                },
                editing: -1, editedRecord: {}, filters: {
                    dateFrom: "1970-01-01",
                    dateTo: "1970-01-01",
                    hourFrom: "00:00:00",
                    hourTo: "00:00:00",
                    isForAccumulation: -1,
                    customerTag: -1,
                    itemCode: "",
                    itemTag: -1,
                    multiplication: -1,
                    accumulation: -1,
                    partialAccumulation: -1,
                    maxDiscount: -1,
                    page: 1,
                    recordsPerPage: numOfRowsByHeight, //  5,
                    sortColumn: 0,
                    sortType: 1
                }
            },
            itemTags: [],
            customerTags: [],
            itemCodes: [],
            bargainTypes: [],
            requests: {},
            selectors: {
                salesAlertData: [],
                pricesData: [],
                suppliersData: [],
                taxTypesData: [],
                salesAlertPicks: [],
                pricesPicks: [],
                suppliersPicks: [],
                taxTypesPicks: [],
                customerFieldsData: [],

                // customerFieldsPicks: [
                //     {
                //         code: 0,
                //         name: "תווית",
                //     }, {
                //         code: 1,
                //         name: "קלט טקסט",
                //     }, {
                //         code: 2,
                //         name: "קלט מחיר",
                //     }, {
                //         code: 3,
                //         name: "קלט דצימלי",
                //     }, {
                //         code: 4,
                //         name: "קלט נומרי",
                //     }, {
                //         code: 5,
                //         name: "בורר תאריכים",
                //     }, {
                //         code: 6,
                //         name: "תיבת סימון",
                //     }, {
                //         code: 7,
                //         name: "בחירה",
                //     }, {
                //         code: 8,
                //         name: "בחירה מרובה",
                //     }, {
                //         code: 9,
                //         name: "תמונה",
                //     }],
            },
            // GENERIC SELECTOR
            genericSelector: {
                dataset: [],
                dynamicDataset: [],
            },

            GENERIC_SELECTOR_PRICES: { dataset: [] },

            // GENERIC DATA
            genericData: {
                data: [],
                count: 0,
                headers: [],
                pagination: {
                    current: 1,
                    defaultCurrent: 1,
                    defaultPageSize: 5,
                    total: 0,
                    hideOnSinglePage: false,
                    showQuickJumper: false,
                    showTotal: (total: number, range: Array<number>) => `  מציג ${range[0]} - ${range[1]} מתוך ${total}`,
                    // showTotal: (total: number, range: Array<number>) => '  ' + this.getText(10372) + ' ' + range[0] + ' - ' + range[1] + ' ' + this.getText(10373) + ' ' + total,
                },
                editing: -1,
                editedRecord: {},
                filters: {
                    page: 1,
                    recordsPerPage: 5,
                    sortColumn: 0,
                    sortType: 1
                }
            }
        };
    }

    reduce(state: DataState, action: Object) {
        let user: User = new User(action.token, action.companyCode);
        let request: Job;

        let tags = [];
        let newEarningPoints = state.earningPoints;
        let newFilter;
        let newPagination;
        let count;
        let headers;
        let data;
        let newState;
        let recordData;
        let record;
        let newRecord;
        let emptyRecord;
        let emptyLine;
        let obj;
        let fieldCount;
        let newGenericData;
        let genericDataChildren = [];
        let requestDetails;
        /*let dataArray: Array<string | Object>;*/

        let who = action.who;
        if (who !== undefined) {
            if (typeof action.script === "object" && action.script !== null) {
                who = action.script.who;
            }
            if (who.indexOf(".") === -1) {
                newGenericData = state[who] || Object.assign({}, state.genericData);
            } else {
                let path = who.split(".");
                newGenericData = state[path[0]] || Object.assign({}, state.genericData);
                for (let i = 0; i < path.length; i++) {
                    genericDataChildren.push(path[i]);
                }
            }
        } else {
            newGenericData = {
                pagination: {},
                filters: {},
                data: [],
                headers: [],
                editedRecord: {},
                editing: -1
            }
        }

        //window.console.log(action.type);

        switch (action.type) {
            case ActionTypes.GET_USING_POINTS_REQUESTED:
                request = new Job(user, "get_using_points", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.getUsingPointsSucceeded(data);
                    } else {
                        Actions.getUsingPointsFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.getUsingPointsFailed(errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            case ActionTypes.EARNING_POINTS_SET_FILTER:
            case ActionTypes.EARNING_POINTS_CLEAR_FILTERS:
                newPagination = Object.assign({}, state.earningPoints.pagination);
                if (action.filter.page !== undefined) {
                    newPagination.current = action.filter.page;
                }
                if (action.filter.recordsPerPage !== undefined) {
                    newPagination.defaultPageSize = action.filter.recordsPerPage;
                    newPagination.pageSize = action.filter.recordsPerPage;
                }
                if (action.filter.sortType !== undefined) {
                    newPagination.sortType = action.filter.sortType;
                }
                if (action.filter.sortColumn !== undefined) {
                    newPagination.sortColumn = action.filter.sortColumn;
                }
                newFilter = Object.assign({}, state.earningPoints.filters, action.filter);
                newEarningPoints = Object.assign({}, state.earningPoints, {
                    filters: newFilter,
                    pagination: newPagination
                });
                state.ongoingRequests++; // Because of fall through
            // falls through
            case ActionTypes.EARNING_POINTS_DELETE_ROW_SUCCEEDED:
            case ActionTypes.EARNING_POINTS_SAVE_EDIT_SUCCEEDED:
                state.ongoingRequests--;
            // falls through
            case ActionTypes.GET_EARNING_POINTS_REQUESTED:
                request = new Job(user, "get_earning_points", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in newEarningPoints.filters) {
                    if (newEarningPoints.filters.hasOwnProperty(i)) {
                        request.setColumn(i, newEarningPoints.filters[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.getEarningPointsSucceeded(data);
                    } else {
                        Actions.getEarningPointsFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.getEarningPointsFailed(errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return Object.assign({}, state, {
                    earningPoints: newEarningPoints,
                    ongoingRequests: state.ongoingRequests + 1
                });
            case ActionTypes.GET_ITEM_TAGS_REQUESTED:
                request = new Job(user, "get_tags_with_types", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.getItemTagsSucceeded(data);
                    } else {
                        Actions.getItemTagsFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.getItemTagsFailed(errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            case ActionTypes.GET_CUSTOMER_TAGS_REQUESTED:
                request = new Job(user, "get_customer_tags", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.getCustomerTagsSucceeded(data);
                    } else {
                        Actions.getCustomerTagsFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.getCustomerTagsFailed(errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            case ActionTypes.GET_ITEM_CODES_REQUESTED:
                request = new Job(user, "list_item_codes", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.getItemCodesSucceeded(data);
                    } else {
                        Actions.getItemCodesFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.getItemCodesFailed(errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };



            case ActionTypes.BARGAIN_TYPES_GET_REQUESTED:
                request = new Job(user, "data_get", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.setColumn("dataObject", "bargainTypes");
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.bargainTypesGetSucceeded(data);
                    } else {
                        Actions.bargainTypesGetFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.bargainTypesGetFailed(errorCode, errorMessage);
                    window.console.error("Loading bargain types failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };



            case ActionTypes.GET_USING_POINTS_SUCCEEDED:
                let json = {};
                if (action.data.length > 0) {
                    let names = action.data[0].split("\f");
                    let values = action.data.length > 1 ? action.data[1].split("\f") : new Array(names.length);

                    for (let i = 0, j = names.length; i < j; i++) {
                        json[names[i]] = values[i] ? values[i] : null;
                    }
                }
                return Object.assign({}, state, { usingPoints: json, ongoingRequests: state.ongoingRequests - 1 });
            case ActionTypes.GET_EARNING_POINTS_SUCCEEDED:
                count = parseInt(action.data.pop(), 10);
                newPagination = Object.assign({}, state.earningPoints.pagination, { total: count });
                newEarningPoints = Object.assign({}, state.earningPoints, {
                    data: action.data,
                    count,
                    editing: -1,
                    editedRecord: {},
                    pagination: newPagination
                });
                newState = Object.assign({}, state, {
                    earningPoints: newEarningPoints,
                    ongoingRequests: state.ongoingRequests - 1
                });
                return newState;
            case ActionTypes.GET_ITEM_CODES_SUCCEEDED:
                return Object.assign({}, state, { itemCodes: action.data, ongoingRequests: state.ongoingRequests - 1 });

            case ActionTypes.BARGAIN_TYPES_GET_SUCCEEDED:
                count = parseInt(action.data.pop(), 10);
                headers = action.data.shift();
                return Object.assign({}, state, {
                    bargainTypes: action.data.map((x) => {
                        let y = x.split("\f");
                        return { bargainTypeId: y[0], bargainTypeDescription: y[1] }
                    })
                }, { ongoingRequests: state.ongoingRequests - 1 });
            case ActionTypes.GET_ITEM_TAGS_SUCCEEDED:
                if (action.data.length > 0) {
                    let lastTagType = "";
                    for (let record of action.data) {
                        let row = record.split("\f");

                        if (row[0] !== lastTagType) {
                            tags.push({
                                type: {
                                    id: row[0],
                                    text: row[3],
                                },
                                tags: [],
                            });
                        }

                        tags[tags.length - 1].tags.push({
                            id: row[1],
                            text: row[2],
                        });
                        lastTagType = row[0];
                    }
                }
                return Object.assign({}, state, { itemTags: tags, ongoingRequests: state.ongoingRequests - 1 });
            case ActionTypes.GET_CUSTOMER_TAGS_SUCCEEDED:

                if (action.data.length > 0) {
                    let lastTagType = "";
                    for (let record of action.data) {
                        let row = record.split("\f");

                        if (row[0] !== lastTagType) {
                            tags.push({
                                type: {
                                    id: row[0],
                                    text: row[3],
                                },
                                tags: [],
                            });
                        }

                        tags[tags.length - 1].tags.push({
                            id: row[1],
                            text: row[2],
                        });
                        lastTagType = row[0];
                    }
                }
                return Object.assign({}, state, { customerTags: tags, ongoingRequests: state.ongoingRequests - 1 });

            case ActionTypes.GET_USING_POINTS_FAILED:
                if (action.errorCode === 403) {
                    Actions.apiFailedForbidden();
                }
                return Object.assign({}, state, { usingPoints: {}, ongoingRequests: state.ongoingRequests - 1 });
            case ActionTypes.GET_EARNING_POINTS_FAILED:
                if (action.errorCode === 403) {
                    Actions.apiFailedForbidden();
                }
                return { ...state, ongoingRequests: state.ongoingRequests - 1 };
            case ActionTypes.GET_ITEM_TAGS_FAILED:
            case ActionTypes.GET_ITEM_CODES_FAILED:
            case ActionTypes.GET_CUSTOMER_TAGS_FAILED:
            case ActionTypes.BARGAIN_TYPES_GET_FAILED:
                if (action.errorCode === 403) {
                    Actions.apiFailedForbidden();
                }
                return { ...state, ongoingRequests: state.ongoingRequests - 1 };

            case ActionTypes.SAVE_USING_POINTS_REQUESTED:
                request = new Job(user, "save_using_points", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.data) {
                    if (action.data.hasOwnProperty(i)) {
                        request.setColumn(i, action.data[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.saveUsingPointsSucceeded();
                    } else {
                        Actions.saveUsingPointsFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.saveUsingPointsFailed(errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };

            case ActionTypes.EARNING_POINTS_DELETE_ROW_FAILED:
            case ActionTypes.EARNING_POINTS_SAVE_EDIT_FAILED:
            case ActionTypes.SAVE_USING_POINTS_FAILED:
                if (action.errorCode === 403) {
                    Actions.apiFailedForbidden();
                }
                return { ...state, ongoingRequests: state.ongoingRequests - 1 };

            case ActionTypes.EARNING_POINTS_START_EDITING:
                recordData = state.earningPoints.data[action.index].split("\f");
                record = {
                    key: recordData[0],
                    dateFrom: recordData[1],
                    dateTo: recordData[2],
                    hourFrom: recordData[3],
                    hourTo: recordData[4],
                    isForAccumulation: recordData[5],
                    customerTag: recordData[6],
                    itemCode: recordData[7],
                    tagId: recordData[8],
                    multiplication: recordData[9],
                    accumulation: recordData[10],
                    partialAccumulation: recordData[11],
                    maxDiscount: recordData[12],
                    customerTagName: recordData[13],
                    itemTagName: recordData[14],
                    itemName: recordData[15]
                };
                newEarningPoints = Object.assign({}, state.earningPoints, { editing: action.id, editedRecord: record });
                return Object.assign({}, state, { earningPoints: newEarningPoints });
            case ActionTypes.EARNING_POINTS_START_NEW:
                emptyRecord = {
                    key: 0,
                    dateFrom: "",
                    dateTo: "",
                    hourFrom: "00:00:00",
                    hourTo: "23:59:59",
                    isForAccumulation: "0",
                    customerTag: "",
                    itemCode: "",
                    tagId: "",
                    multiplication: "",
                    accumulation: "",
                    partialAccumulation: "0",
                    maxDiscount: "",
                    customerTagName: "",
                    itemTagName: "",
                    itemName: ""
                };
                newEarningPoints = Object.assign({}, state.earningPoints, {
                    editing: action.id,
                    editedRecord: emptyRecord
                });
                return Object.assign({}, state, { earningPoints: newEarningPoints });
            case ActionTypes.EARNING_POINTS_CANCEL_EDITING:
                newEarningPoints = Object.assign({}, state.earningPoints, { editing: -1, editedRecord: {} });
                return Object.assign({}, state, { earningPoints: newEarningPoints });


            case ActionTypes.EARNING_POINTS_SET_EDIT:
                newRecord = Object.assign({}, state.earningPoints.editedRecord, action.data);
                newEarningPoints = Object.assign({}, state.earningPoints, { editedRecord: newRecord });
                return Object.assign({}, state, { earningPoints: newEarningPoints });
            case ActionTypes.EARNING_POINTS_DELETE_ROW:
                request = new Job(user, "delete_earning_points", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.setInput(String(action.id));
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.earningPointsDeleteRowSucceeded(action.companyCode, action.token);
                    } else {
                        Actions.earningPointsDeleteRowFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.earningPointsDeleteRowFailed(errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            case ActionTypes.EARNING_POINTS_SAVE_EDIT:
                request = new Job(user, "set_earning_points", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.data) {
                    if (action.data.hasOwnProperty(i)) {
                        request.setColumn(i, action.data[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.earningPointsSaveEditSucceeded(action.companyCode, action.token);
                    } else {
                        Actions.earningPointsSaveEditFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.earningPointsSaveEditFailed(errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };

            case ActionTypes.SET_DATA:
                switch (action.field) {
                    case "usingPoints":
                        return Object.assign({}, state, { usingPoints: action.newData });
                    case "itemTags":
                        return Object.assign({}, state, { itemTags: action.newData });
                    case "documentFinal":
                        return Jax.set(state, "document.finalRecord", action.newData);
                    case "document":
                        let debug = Jax.set(state, "document", action.newData);
                        return debug;
                    // return Jax.set(state, "document", action.newData);
                    case "tags":
                        return Jax.set(state, "ITEM_TAGS_CREATE_EDIT.data", action.newData);
                    case "tagsEditCust":
                        return Jax.set(state, "CustomerTa.data", action.newData);
                    default:
                        return state;
                }


            case ActionTypes.SELECTOR_UPDATE_SALESALERTS:
                this.api_get("get_sales_alert_list");
                return {
                    ...state, selectors: {
                        ...state.selectors,
                        salesAlert: {
                            ...state.selectors.salesAlert,
                            data: { ...this.optionList }
                        }
                    }
                };
            case ActionTypes.SELECTOR_UPDATE_PRICES:
                this.api_get("get_prices");
                return {
                    ...state, selectors: {
                        ...state.selectors,
                        prices: {
                            ...state.selectors.prices,
                            data: { ...this.optionList }
                        }
                    }
                };
            case ActionTypes.SELECTOR_UPDATE_SUPPLIERS:
                this.api_get("get_suppliers");
                return {
                    ...state, selectors: {
                        ...state.selectors,
                        suppliers: {
                            ...state.selectors.suppliers,
                            data: { ...this.optionList }
                        }
                    }
                };
            case ActionTypes.SELECTOR_UPDATE_TAX_TYPE:
                this.api_get("get_tax_types");
                return {
                    ...state, selectors: {
                        ...state.selectors,
                        taxTypes: {
                            ...state.selectors.taxTypes,
                            data: { ...this.optionList }
                        }
                    }
                };

            case ActionTypes.SELECTOR_PICK_SALESALERTS:
                state.selectors.salesAlert.picks.push(action.pick);
                state.selectors.salesAlert.pick = action.pick;
                return { ...state };
            case ActionTypes.SELECTOR_PICK_PRICES:
                state.selectors.prices.picks.push(action.pick);
                state.selectors.prices.pick = action.pick;
                return { ...state };
            case ActionTypes.SELECTOR_PICK_SUPPLIERS:
                state.selectors.suppliers.picks.push(action.pick);
                state.selectors.suppliers.pick = action.pick;
                return { ...state };
            case ActionTypes.SELECTOR_PICK_TAX_TYPE:
                state.selectors.suppliers.picks.push(action.pick);
                state.selectors.taxTypes.pick = action.pick;
                return { ...state };

            case ActionTypes.SELECTOR_PICK_CUSTOMER_FIELDS:
                state.selectors.customerFields.pick = action.pick;
                return { ...state };


            // GENERIC SELECTOR
            case ActionTypes.GENERIC_SELECTOR_PICK:
                return state;
            case ActionTypes.GENERIC_SELECTOR_PICK_UPDATE:
                return state;
            case ActionTypes.GENERIC_SELECTOR_REFRESH_DATASET:
                request = new Job(user, action.getScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);

                // TODO: HHH - test selectors
                if (action.data) {
                    request.setInput(Object.keys(action.data).join('\f')
                        + '\r' + Object.values(action.data).join('\f'));
                }

                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.genericSelectorGetSucceeded(action.id, data);
                    } else {
                        Actions.genericSelectorGetFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericSelectorGetFailed(errorCode, errorMessage);
                    window.console.error("Loading selector failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };

            case ActionTypes.GENERIC_SELECTOR_GET_SUCCEEDED:
                let optionList = [];
                for (let rowRaw of action.data) {
                    let row = rowRaw.split("\f");
                    optionList.push({
                        code: row[0],
                        name: row[1],
                    });
                }
                let container = { [action.id]: { dataset: optionList } };
                return { ...state, ...container, ongoingRequests: state.ongoingRequests - 1 };
            case ActionTypes.GENERIC_SELECTOR_GET_FAILED:
                return { ...state, ongoingRequests: state.ongoingRequests - 1 };

            case ActionTypes.GENERIC_MULTI_SELECTOR_REFRESH_DATASET:
                request = new Job(user, action.getScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.genericMultiSelectorGetSucceeded(action.id, data);
                    } else {
                        Actions.genericMultiSelectorGetFailed(response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericMultiSelectorGetFailed(errorCode, errorMessage);
                    window.console.error("Loading selector failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };

            case ActionTypes.GENERIC_MULTI_SELECTOR_GET_SUCCEEDED:
                let lastTagType = "";
                let optionList2 = [];
                for (let rowRaw of action.data) {
                    let row = rowRaw.split("\f");
                    if (row[0] !== lastTagType) {
                        optionList2.push({
                            type: {
                                id: row[0],
                                text: row[3],
                            },
                            tags: [],
                        });
                    }
                    optionList2[optionList2.length - 1].tags.push({
                        id: row[1],
                        text: row[2],
                    });
                    lastTagType = row[0];
                }
                let container2 = { [action.id]: { dataset: optionList2 } };
                return { ...state, ...container2, ongoingRequests: state.ongoingRequests - 1 };
            case ActionTypes.GENERIC_MULTI_SELECTOR_GET_FAILED:
                return { ...state, ongoingRequests: state.ongoingRequests - 1 };

            // GENERIC DATA
            case ActionTypes.GENERIC_DATA_SET_FILTER:
            case ActionTypes.GENERIC_DATA_CLEAR_FILTERS:
                newPagination = Object.assign({}, state.earningPoints.pagination);
                if (action.filter.page !== undefined) {
                    newPagination.current = action.filter.page;
                }
                if (action.filter.recordsPerPage !== undefined) {
                    newPagination.defaultPageSize = action.filter.recordsPerPage;
                    newPagination.pageSize = action.filter.recordsPerPage;
                }
                if (action.filter.sortType !== undefined) {
                    newPagination.sortType = action.filter.sortType;
                }
                if (action.filter.sortColumn !== undefined) {
                    newPagination.sortColumn = action.filter.sortColumn;
                }
                newFilter = Object.assign({}, newGenericData.filters, action.filter);
                newGenericData = Object.assign({}, newGenericData,
                    { filters: newFilter, pagination: newPagination });
                state.ongoingRequests++; // Because of fall through
                console.log("datastore \nPaging: ", newPagination, "\nfilter", newFilter); // TODO: remove this
            // falls through
            case ActionTypes.GENERIC_DATA_DELETE_ROW_SUCCEEDED:
            case ActionTypes.GENERIC_DATA_SAVE_EDIT_SUCCEEDED:
            case ActionTypes.GENERIC_JSON_SET_AND_RELOAD_SUCCEEDED:
                state.ongoingRequests--;
            // falls through
            case ActionTypes.GENERIC_DATA_GET_REQUESTED:
                //console.log("hhhhhhhhhhhhhh action type: ", action.type);
                if (typeof action.getScript === "string") {
                    requestDetails = {
                        script: action.getScript,
                        who: action.who,
                        params: {}
                    };
                } else {
                    requestDetails = {
                        script: action.getScript.script,
                        who: action.getScript.who || action.who,
                        params: action.getScript.params || {}
                    };
                }
                if (requestDetails.script.length > 0) {
                    request = new Job(user, requestDetails.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                    if (newGenericData.filters.dataObject === undefined) {
                        request.setColumn("dataObject", action.who);
                    }
                    for (let param in requestDetails.params) {
                        if (requestDetails.params.hasOwnProperty(param)) {
                            request.setColumn(param, requestDetails.params[param]);
                        }
                    }
                    for (let i in newGenericData.filters) {
                        if (newGenericData.filters.hasOwnProperty(i)) {
                            request.setColumn(i, newGenericData.filters[i]);
                        }
                    }
                    request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                        if (response.errorCode === 0 && response.success) {
                            let data: Array<string> = response.data.split("\r");
                            Actions.genericDataGetSucceeded(requestDetails.who, data);
                        } else {
                            Actions.genericDataGetFailed(requestDetails.who, response.errorCode, response.errorMessage);
                        }
                    }, (errorMessage: string, errorCode: number) => {
                        Actions.genericDataGetFailed(requestDetails.who, errorCode, errorMessage);
                        window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                    });

                    obj = {};
                    obj[requestDetails.who] = newGenericData;
                    return Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests + 1 });
                } else {
                    obj = {};
                    obj[requestDetails.who] = newGenericData;
                    return Object.assign({}, state, obj);
                }

            case ActionTypes.GENERIC_DATA_GET_EDIT_REQUESTED:
                if (typeof action.getScript === "string") {
                    requestDetails = {
                        script: action.getScript,
                        who: action.who,
                        params: {}
                    };
                } else {
                    requestDetails = {
                        script: action.getScript.script,
                        who: action.getScript.who || action.who,
                        params: action.getScript.params || {}
                    };
                }
                request = new Job(user, requestDetails.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let param in requestDetails.params) {
                    if (requestDetails.params.hasOwnProperty(param)) {
                        request.setColumn(param, requestDetails.params[param]);
                    }
                }
                for (let i in newGenericData.filters) {
                    if (newGenericData.filters.hasOwnProperty(i)) {
                        request.setColumn(i, newGenericData.filters[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.genericDataGetEditSucceeded(requestDetails.who, data);
                    } else {
                        Actions.genericDataGetFailed(requestDetails.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataGetFailed(requestDetails.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                obj = {};
                obj[requestDetails.who] = newGenericData;
                return Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests + 1 });

            case ActionTypes.GENERIC_DATA_GET_EDIT_WITH_PARAMS:
                if (typeof action.getScript === "string") {
                    requestDetails = {
                        script: action.getScript,
                        who: action.who,
                        params: {}
                    };
                } else {
                    requestDetails = {
                        script: action.getScript.script,
                        who: action.getScript.who || action.who,
                        params: action.getScript.params || {}
                    };
                }
                request = new Job(user, requestDetails.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let param in requestDetails.params) {
                    if (requestDetails.params.hasOwnProperty(param)) {
                        request.setColumn(param, requestDetails.params[param]);
                    }
                }
                for (let i in action.params) {
                    if (action.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.genericDataGetEditSucceeded(requestDetails.who, data);
                    } else {
                        Actions.genericDataGetFailed(requestDetails.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataGetFailed(requestDetails.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                obj = {};
                obj[requestDetails.who] = newGenericData;
                return Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests + 1 });

            case ActionTypes.GENERIC_JSON_GET:
                if (typeof action.getScript === "string") {
                    requestDetails = {
                        script: action.getScript,
                        who: action.who,
                        params: {}
                    };
                } else {
                    requestDetails = {
                        script: action.getScript.script,
                        who: action.getScript.who || action.who,
                        params: action.getScript.params || {}
                    };
                }
                request = new Job(user, requestDetails.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let param in requestDetails.params) {
                    if (requestDetails.params.hasOwnProperty(param)) {
                        request.setColumn(param, requestDetails.params[param]);
                    }
                }
                for (let i in action.params) {
                    if (action.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Object = JSON.parse(response.data);
                        Actions.genericJsonGetSucceeded(requestDetails.who, data);
                    } else {
                        Actions.genericJsonGetFailed(requestDetails.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericJsonGetFailed(requestDetails.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                obj = {};
                obj[requestDetails.who] = newGenericData;
                return Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests + 1 });

            case ActionTypes.GENERIC_JSON_GET_AND_EDIT:
                if (typeof action.getScript === "string") {
                    requestDetails = {
                        script: action.getScript,
                        who: action.who,
                        params: {}
                    };
                } else {
                    requestDetails = {
                        script: action.getScript.script,
                        who: action.getScript.who || action.who,
                        params: action.getScript.params || {}
                    };
                }
                request = new Job(user, requestDetails.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let param in requestDetails.params) {
                    if (requestDetails.params.hasOwnProperty(param)) {
                        request.setColumn(param, requestDetails.params[param]);
                    }
                }
                for (let i in action.params) {
                    if (action.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Object = JSON.parse(response.data);
                        Actions.genericJsonGetAndEditSucceeded(requestDetails.who, data);
                    } else {
                        Actions.genericJsonGetFailed(requestDetails.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericJsonGetFailed(requestDetails.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                obj = {};
                obj[requestDetails.who] = newGenericData;
                return Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests + 1 });

            case ActionTypes.GENERIC_DATA_GET_SUCCEEDED:
                //console.log("hhhhhhhhhhhhhh action data: ", action.data);
                count = parseInt(action.data.pop(), 10);
                //console.log("hhhhhhhhhhhhhh count: ", count);
                headers = action.data.shift();
                //console.log("hhhhhhhhhhhhhh headers: ", headers);
                newPagination = Object.assign({}, newGenericData.pagination, { total: count });
                newGenericData = Object.assign({}, newGenericData, {
                    data: action.data,
                    count,
                    headers: headers ? headers.split("\r").join("").split("\f") : [],
                    editing: -1,
                    editedRecord: {},
                    pagination: newPagination
                });
                obj = {};
                obj[action.who] = newGenericData;
                newState = Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests - 1 });
                return newState;

            case ActionTypes.GENERIC_JSON_GET_SUCCEEDED:
                count = 1;
                newPagination = Object.assign({}, newGenericData.pagination, { total: 1 });
                newGenericData = Object.assign({}, newGenericData, {
                    data: action.data,
                    count,
                    editing: -1,
                    editedRecord: {},
                    pagination: newPagination
                });
                obj = {};
                obj[action.who] = newGenericData;
                newState = Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests - 1 });
                return newState;

            case ActionTypes.GENERIC_DATA_GET_EDIT_SUCCEEDED:
                headers = action.data.shift().split("\f");
                count = parseInt(action.data.pop(), 10);
                record = {};
                if (action.data.length > 0) {
                    recordData = action.data[0].split("\f");
                    for (let i = 0, j = headers.length; i < j; i++) {
                        if (i === 0) {
                            record.key = recordData[0];
                        }
                        record[headers[i]] = recordData[i];
                    }
                } else {
                    for (let i = 0, j = headers.length; i < j; i++) {
                        if (i === 0) {
                            record.key = 0;
                        }
                        record[headers[i]] = "";
                    }
                }
                newGenericData = Object.assign({}, newGenericData, {
                    data: action.data,
                    count,
                    headers,
                    editing: 0,
                    editedRecord: record
                });
                obj = {};
                obj[action.who] = newGenericData;
                newState = Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests - 1 });
                return newState;
            case ActionTypes.GENERIC_JSON_GET_AND_EDIT_SUCCEEDED:
                newGenericData = Object.assign({}, newGenericData, {
                    data: action.data,
                    count: 1,
                    // editing: 0,
                    editedRecord: action.data
                });
                obj = {};
                obj[action.who] = newGenericData;
                newState = Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests - 1 });
                return newState;
            case ActionTypes.GENERIC_DATA_GET_FAILED:
            case ActionTypes.GENERIC_DATA_DELETE_ROW_FAILED:
            case ActionTypes.GENERIC_DATA_SAVE_EDIT_FAILED:
            case ActionTypes.GENERIC_JSON_GET_FAILED:
            case ActionTypes.GENERIC_JSON_SET_FAILED:
                if (action.errorCode === 403) {
                    Actions.apiFailedForbidden();
                }
                return { ...state, ongoingRequests: state.ongoingRequests - 1 };

            case ActionTypes.GENERIC_DATA_START_EDITING:
                if (genericDataChildren.length === 0) {
                    recordData = newGenericData.data[action.index].split("\f");
                    record = {};
                    if (action.firstFieldIsKey) {
                        record.key = recordData[0];

                        for (let i = 0, j = newGenericData.headers.length; i < j; i++) {
                            record[newGenericData.headers[i]] = recordData[i + 1];
                        }
                    } else {
                        for (let i = 0, j = newGenericData.headers.length; i < j; i++) {
                            if (i === 0) {
                                record.key = recordData[0];
                            }
                            record[newGenericData.headers[i]] = recordData[i];
                        }
                    }
                    //newGenericData = Object.assign({}, newGenericData, action.defaults || {});
                    newGenericData = Object.assign({}, newGenericData, { editing: action.id, editedRecord: record });
                    obj = {};
                    obj[action.who] = newGenericData;
                    return Object.assign({}, state, obj);
                } else {
                    window.console.log(action);
                    window.console.log(Jax.get(state, action.who + ".data", []), action.index);
                    record = Jax.clone(state, action.who + ".data[" + action.index + "]");
                    window.console.log(record);
                    return Jax.mutate(state, action.who, { editing: action.id, editedRecord: record });
                }

            case ActionTypes.GENERIC_DATA_START_EDITING_PATH:

                record = Jax.clone(state, action.path + ".data[" + action.index + "]");
                return Jax.mutate(state, action.who, { editing: action.id, editedRecord: record });

            case ActionTypes.GENERIC_DATA_START_NEW:
                emptyRecord = {};
                if (genericDataChildren.length === 0) {
                    for (let i = 0, j = newGenericData.headers.length; i < j; i++) {
                        if (i === 0) {
                            emptyRecord.key = 0;
                        }
                        emptyRecord[newGenericData.headers[i]] = "";
                    }
                    emptyRecord = Object.assign({}, emptyRecord, action.defaults || {});
                    newGenericData = Object.assign({}, newGenericData, { editing: 0, editedRecord: emptyRecord });
                    obj = {};
                    obj[action.who] = newGenericData;
                    return Object.assign({}, state, obj);
                } else {
                    for (let key of Jax.get(state, action.who + ".headers", [])) {
                        emptyRecord[key] = "";
                    }
                    emptyRecord = Object.assign({}, emptyRecord, action.defaults || {});
                    return Jax.mutate(state, action.who, { editing: 0, editedRecord: emptyRecord });
                }
            case ActionTypes.GENERIC_DATA_CANCEL_EDITING:
            case ActionTypes.GENERIC_DATA_CANCEL_NEW:
                return Jax.mutate(state, action.who, { editing: -1, editedRecord: {} });

            case ActionTypes.GENERIC_DATA_SET_EDIT:
                if (genericDataChildren.length === 0) {
                    newRecord = Object.assign({}, newGenericData.editedRecord, action.data);
                    newGenericData = Object.assign({}, newGenericData, { editedRecord: newRecord });

                    obj = {};

                    obj[action.who] = newGenericData;
                    return Object.assign({}, state, obj);
                } else /*if (genericDataChildren.length === 2)*/ {
                    return Jax.mutate(state, action.who, action.data);
                }
            case ActionTypes.GENERIC_DATA_DELETE_ROW:
                request = new Job(user, action.deleteScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.setInput(String(action.id));
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {

                    if (response.errorCode === 0 && response.success) {
                        Actions.genericDataDeleteRowSucceeded(action.who, action.companyCode, action.token, action.getScript);
                    } else {
                        //console.log("xxxxxx", response.errorCode ,response.errorMessage);
                        Actions.genericDataDeleteRowFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataDeleteRowFailed(action.who, errorCode, errorMessage);
                    window.console.error("Generic delete failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            case ActionTypes.GENERIC_DATA_DELETE_ROW_WITH_PARAMS:
                request = new Job(user, action.deleteScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.params) {
                    if (action.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.genericDataDeleteRowSucceeded(action.who, action.companyCode, action.token, action.getScript);
                    } else {
                        Actions.genericDataDeleteRowFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataDeleteRowFailed(action.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };

            case ActionTypes.GENERIC_DATA_SAVE_EDIT_WITH_PARAMS:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in newGenericData.editedRecord) {
                    if (newGenericData.editedRecord.hasOwnProperty(i)) {
                        request.setColumn(i, newGenericData.editedRecord[i]);
                    }
                }
                for (let i in action.params) {
                    if (action.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.genericDataSaveEditSucceeded(action.who, action.companyCode, action.token, action.getScript);
                    } else {
                        Actions.genericDataSaveEditFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataSaveEditFailed(action.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };

            case ActionTypes.GENERIC_DATA_SAVE_EDIT_WITH_PARAMS_NEXT:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in newGenericData.editedRecord) {
                    if (newGenericData.editedRecord.hasOwnProperty(i)) {
                        request.setColumn(i, newGenericData.editedRecord[i]);
                    }
                }
                for (let i in action.params) {
                    if (action.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        action.next['action'](...action.next['args']);
                        Actions.genericDataSaveEditSucceeded(action.who, action.companyCode, action.token, action.getScript);
                    } else {
                        Actions.genericDataSaveEditFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataSaveEditFailed(action.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };


            case ActionTypes.GENERIC_DATA_SAVE_EDIT:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in newGenericData.editedRecord) {
                    if (newGenericData.editedRecord.hasOwnProperty(i)) {
                        request.setColumn(i, newGenericData.editedRecord[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.genericDataSaveEditSucceeded(action.who, action.companyCode, action.token, action.getScript);
                    } else {
                        Actions.genericDataSaveEditFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataSaveEditFailed(action.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };

            ///////
            case ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in newGenericData.editedRecord) {
                    if (newGenericData.editedRecord.hasOwnProperty(i)) {
                        request.setColumn(i, newGenericData.editedRecord[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        //action.next['action'](...action.next['args']);
                        Actions.genericDataJustSaveEditSucceeded(action.who, action.companyCode, action.token);
                    } else {
                        Actions.genericDataSaveEditFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataSaveEditFailed(action.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            ////////////
            case ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_WITH_PARAMS:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in newGenericData.editedRecord) {
                    if (newGenericData.editedRecord.hasOwnProperty(i)) {
                        request.setColumn(i, newGenericData.editedRecord[i]);
                    }
                }
                for (let i in action.params) {
                    if (action.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        //action.next['action'](...action.next['args']);
                        Actions.genericDataJustSaveEditSucceeded(action.who, action.companyCode, action.token);
                    } else {
                        Actions.genericDataSaveEditFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataSaveEditFailed(action.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            ////////////

            case ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_NEXT:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in newGenericData.editedRecord) {
                    if (newGenericData.editedRecord.hasOwnProperty(i)) {
                        request.setColumn(i, newGenericData.editedRecord[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        action.next['action'](...action.next['args']);
                        Actions.genericDataJustSaveEditSucceeded(action.who, action.companyCode, action.token);
                    } else {
                        Actions.genericDataSaveEditFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataSaveEditFailed(action.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };

            case ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_SUCCEEDED:
                return { ...state, ongoingRequests: state.ongoingRequests - 1 };

            case ActionTypes.GENERIC_JSON_SET:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.setSection("json", JSON.stringify(Jax.get(state, action.path, {})));
                request.setSection("dataObject", action.who);
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.genericJsonSetSucceeded(action.who);
                    } else {
                        Actions.genericJsonSetFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericJsonSetFailed(action.who, errorCode, errorMessage);
                    window.console.error("Setting json failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            case ActionTypes.GENERIC_JSON_SET_AND_RELOAD:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.setSection("json", JSON.stringify(Jax.get(state, action.path, {})));
                request.setSection("dataObject", action.who);
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.genericJsonSetAndReloadSucceeded(action.who, action.companyCode, action.token, action.getScript);
                    } else {
                        Actions.genericJsonSetFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericJsonSetFailed(action.who, errorCode, errorMessage);
                    window.console.error("Setting json failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            case ActionTypes.GENERIC_DATA_SAVE_EDIT_MULTI:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.setSection("dataObject", action.who);
                for (let section of action.sections) {
                    if (section.sendDataTable) {
                        if (state[section.name].data.length > 0) {
                            if (state[section.name].headers.length !== (state[section.name].data[0].split("\f").length)) {
                                state[section.name].headers.unshift("key");
                            }
                        }
                        request.setSection(section.name, state[section.name].headers.join("\f") + "\r" + state[section.name].data.join("\r"));
                    } else {
                        let headers = "";
                        let content = "";
                        for (let i in state[section.name].editedRecord) {
                            if (state[section.name].editedRecord.hasOwnProperty(i)) {
                                if (headers.length > 0) {
                                    headers += "\f";
                                    content += "\f";
                                }
                                headers += i;
                                content += state[section.name].editedRecord[i];
                            }
                        }
                        request.setSection(section.name, headers + "\r" + content);
                    }
                }

                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.genericDataSaveEditSucceeded(action.who, action.companyCode, action.token, action.getScript);
                    } else {
                        Actions.genericDataSaveEditFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataSaveEditFailed(action.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            case ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_MULTI:
                request = new Job(user, action.saveScript, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let section of action.sections) {
                    if (section.sendDataTable) {
                        console.log("table:", section);
                        if (state[section.name].data.length > 0) {
                            if (state[section.name].headers.length !== (state[section.name].data[0].split("\f").length)) {
                                state[section.name].headers.unshift("key");
                            }
                        }
                        request.setSection(section.name, state[section.name].headers.join("\f") + "\r" + state[section.name].data.join("\r"));
                    } else {
                        console.log("row:", section);
                        let headers = "";
                        let content = "";
                        for (let i in state[section.name].editedRecord) {
                            if (state[section.name].editedRecord.hasOwnProperty(i)) {
                                if (headers.length > 0) {
                                    headers += "\f";
                                    content += "\f";
                                }
                                headers += i;
                                content += state[section.name].editedRecord[i];
                            }
                        }
                        request.setSection(section.name, headers + "\r" + content);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        // TODO: check if statement
                        if (action.next) {
                            action.next['action'](...action.next['args']);
                        }
                        Actions.genericDataJustSaveEditSucceeded(action.who, action.companyCode, action.token);
                    } else {
                        Actions.genericDataSaveEditFailed(action.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataSaveEditFailed(action.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                return { ...state, ongoingRequests: state.ongoingRequests + 1 };
            case ActionTypes.GENERIC_DATA_START_INLINE_CREATE:
                emptyRecord = {};
                if (genericDataChildren.length === 0) {
                    emptyLine = "0";
                    for (let i = 0, j = newGenericData.headers.length; i < j; i++) {
                        if (i === 0) {
                            emptyRecord.key = 0;
                        }
                        emptyRecord[newGenericData.headers[i]] = "";
                        emptyLine += "\f";
                    }
                    emptyRecord.number = newGenericData.data.length + 1;
                    emptyRecord.key = 0;
                    emptyRecord = Object.assign({}, emptyRecord, action.defaults || {});
                    newGenericData.data.unshift(emptyLine);
                    console.log("1", newGenericData, action);
                    newGenericData = Object.assign({}, newGenericData, { editing: "0", editedRecord: emptyRecord });
                    console.log("2", newGenericData, action);
                    obj = {};
                    obj[action.who] = newGenericData;
                    return Object.assign({}, state, obj);
                } else /*if (genericDataChildren.length === 3)*/ {
                    for (let header of /*state[genericDataChildren[0]].editedRecord[genericDataChildren[1]].headers*/Jax.get(state, action.who + ".headers", [])) {
                        emptyRecord[header] = "";
                    }
                    emptyRecord.number = Jax.get(state, action.who + ".data", []).length + 1;
                    emptyRecord.key = 0;
                    emptyRecord = Object.assign({}, emptyRecord, action.defaults || {});

                    obj = Jax.unshift(state, action.who + ".data", emptyRecord);
                    obj = Jax.mutate(obj, action.who, { editing: 0, editedRecord: emptyRecord });
                    return obj;
                }

            case ActionTypes.GENERIC_DATA_CANCEL_INLINE_CREATE:
                obj = Jax.shift(state, action.who + ".data");
                return Jax.mutate(obj, action.who, { editing: -1, editedRecord: {} });

            case ActionTypes.GENERIC_DATA_GET_WITH_PARAMS:
                ///console.log("hhhhhhhhhhhhhh2 action type: ", action.type);
                if (typeof action.getScript === "string") {
                    requestDetails = {
                        script: action.getScript,
                        who: action.who,
                        params: {}
                    };
                } else {
                    requestDetails = {
                        script: action.getScript.script,
                        who: action.getScript.who || action.who,
                        params: action.getScript.params || {}
                    };
                }
                request = new Job(user, requestDetails.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let param in requestDetails.params) {
                    if (requestDetails.params.hasOwnProperty(param)) {
                        request.setColumn(param, requestDetails.params[param]);
                    }
                }
                for (let i in action.params) {
                    if (action.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        let data: Array<string> = response.data.split("\r");
                        Actions.genericDataGetSucceeded(requestDetails.who, data);
                    } else {
                        Actions.genericDataGetFailed(requestDetails.who, response.errorCode, response.errorMessage);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericDataGetFailed(requestDetails.who, errorCode, errorMessage);
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                });
                obj = {};
                obj[requestDetails.who] = newGenericData;
                return Object.assign({}, state, obj, { ongoingRequests: state.ongoingRequests + 1 });

            // DATA TABLE
            case ActionTypes.DATA_TABLE_ADD_ROW:
                if (typeof action.row === "string") {
                    // insert
                    record = action.row;
                    obj = Jax.push(state, action.who + ".data", record);
                } else if (Array.isArray(action.row)) {

                    obj = Jax.set(state, action.who/* + ".data"*/, action.row);
                } else {
                    // insert, either object or flat
                    if (action.flattenData) {
                        record = "";
                        fieldCount = 0;
                        for (let header of state[action.who].headers) {
                            if (fieldCount > 0) {
                                record += "\f";
                            }
                            record += action.row[header] ? action.row[header] : "";
                            fieldCount++;
                        }
                    } else {
                        record = action.row;
                    }
                    obj = Jax.push(state, action.who + ".data", record);
                }

                if (action.wasInline) {
                    obj = Jax.shift(obj, action.who + ".data");
                }

                obj = Jax.mutate(obj, action.who, { editing: -1, count: Jax.get(obj, action.who + ".data", []).length });
                return obj;
            case ActionTypes.DATA_TABLE_SET_ROW:
                // if (genericDataChildren.length === 0) {
                //     dataArray = state[action.who].data;
                // } else if (genericDataChildren.length === 2) {
                //     dataArray = state[genericDataChildren[0]].editedRecord[genericDataChildren[1]];
                // } else if (genericDataChildren.length === 3) {
                //     dataArray = state[genericDataChildren[0]].editedRecord[genericDataChildren[1]][genericDataChildren[2]];
                // } else {
                //     dataArray = [];
                // }
                console.log("DATA_TABLE_SET_ROW: ", action);

                if (typeof action.row === "string") {
                    record = action.row;
                } else if (action.flattenData) {
                    //record = action.row.key + "\f";
                    record = "";
                    fieldCount = 0;
                    for (let header of state[action.who].headers) {
                        if (fieldCount > 0) {
                            record += "\f";
                        }
                        record += action.row[header] ? action.row[header] : "";
                        fieldCount++;
                    }
                } else {
                    record = action.row;
                }

                obj = Jax.splice(state, action.who + ".data", action.index, 1, record);
                obj = Jax.set(state, action.who + ".editing", -1);
                return obj;
            case ActionTypes.DATA_TABLE_DELETE_ROW:

                obj = Jax.splice(state, action.who + ".data", action.index, 1);
                obj = Jax.set(state, action.who + ".count", Jax.get(obj, action.who + ".data", []).length);

                return obj;
            case ActionTypes.SET_DATA_STATE:
                return action.newState;
            case ActionTypes.GENERIC_NETWORK_REQUEST:
                request = new Job(user, action.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                action.payloadBuilder(request);
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        Actions.genericNetworkRequestSucceeded(action.stateBuilder, response.data, action.messages);
                    } else {
                        Actions.genericNetworkRequestFailed(action.stateBuilder, response.errorCode, response.errorMessage, action.messages);
                    }
                }, (errorMessage: string, errorCode: number) => {
                    Actions.genericNetworkRequestFailed(action.stateBuilder, errorCode, errorMessage, action.messages);
                });
                return Object.assign({}, state, { ongoingRequests: state.ongoingRequests + 1 });
            case ActionTypes.GENERIC_NETWORK_REQUEST_SUCCEEDED:
                obj = Jax.dec(state, "ongoingRequests");
                return action.stateBuilder(obj, null, action.data);
            case ActionTypes.GENERIC_NETWORK_REQUEST_FAILED:
                if (action.errorCode === 403) {
                    Actions.apiFailedForbidden();
                    return { ...state, ongoingRequests: state.ongoingRequests - 1 };
                } else {
                    obj = Jax.dec(state, "ongoingRequests");
                    return action.stateBuilder(obj, {
                        errorCode: action.errorCode,
                        errorMessage: action.errorMessage
                    }, null);
                }

            //////////////////////////////////////////////////////////////////////////////////////////////////////////////

            case ActionTypes.NETWORK_FETCH_DATA:
                request = new Job(user, action.request.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.request.params) {
                    if (action.request.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.request.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkFetchDataSuccess,
                            args: [action.path, response.data, action.autoEditMode, action.messages, action.dataStoreWriter, action.successAction]
                        });
                        // if (action.successAction) {
                        //     window.console.log(action.successAction);
                        //     ActionQueue.addToQueue(action.successAction);
                        // }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkFetchDataFail,
                            args: [response.errorCode, response.errorMessage, action.messages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkFetchDataFail,
                        args: [errorCode, errorMessage, action.messages]
                    });
                    window.console.error("Network Fetch Data Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");

            case ActionTypes.NETWORK_FETCH_JSON:
                request = new Job(user, action.request.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.request.params) {
                    if (action.request.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.request.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkFetchJsonSuccess,
                            args: [action.path, response.data, action.autoEditMode, action.messages, action.dataStoreWriter, action.successAction]
                        });
                        // if (action.successAction) {
                        //     ActionQueue.addToQueue(action.successAction);
                        // }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkFetchJsonFail,
                            args: [response.errorCode, response.errorMessage, action.messages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkFetchJsonFail,
                        args: [errorCode, errorMessage, action.messages]
                    });
                    window.console.error("Network Fetch JSON Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");

            case ActionTypes.NETWORK_FETCH:
                request = new Job(user, action.request.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.request.params) {
                    if (action.request.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.request.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkFetchSuccess,
                            args: [action.path, response.data, action.autoEditMode, action.messages, action.dataStoreWriter, action.successAction]
                        });
                        // if (action.successAction) {
                        //     ActionQueue.addToQueue(action.successAction);
                        // }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkFetchFail,
                            args: [response.errorCode, response.errorMessage, action.messages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkFetchFail,
                        args: [errorCode, errorMessage, action.messages]
                    });
                    window.console.error("Network Fetch JSON Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");

            case ActionTypes.NETWORK_POST_DATA:
                request = new Job(user, action.request.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                headers = Jax.get(state, action.headersPath, "").split("\f");
                record = Jax.get(state, action.path, "").split("\f");
                for (let i in action.request.params) {
                    if (action.request.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.request.params[i]);
                    }
                }
                for (let i = 0, j = headers.length; i < j; i++) {
                    request.setColumn(headers[i], record[i] || "");
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostDataSuccess,
                            args: [response.data, action.responsePath, action.messages, action.dataStoreWriter, action.successAction]
                        });
                        // if (action.successAction) {
                        //     ActionQueue.addToQueue(action.successAction);
                        // }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostDataFail,
                            args: [response.errorCode, response.errorMessage, action.messages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkPostDataFail,
                        args: [errorCode, errorMessage, action.messages]
                    });
                    window.console.error("Network Post JSON Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");
            case ActionTypes.NETWORK_POST_JSON:
                request = new Job(user, action.request.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.request.params) {
                    if (action.request.params.hasOwnProperty(i)) {
                        request.setSection(i, action.request.params[i]);
                    }
                }
                request.setSection("json", JSON.stringify(Jax.get(state, action.path, {})));
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostJsonSuccess,
                            args: [response.data, action.path, action.messages, action.dataStoreWriter, action.successAction]
                        });
                        // if (action.successAction) {
                        //     ActionQueue.addToQueue(action.successAction);
                        // }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostJsonFail,
                            args: [response.errorCode, response.errorMessage, action.messages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkPostJsonFail,
                        args: [errorCode, errorMessage, action.messages]
                    });
                    window.console.error("Network Post JSON Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");
            case ActionTypes.NETWORK_POST_JSON_AS_DATA:
                request = new Job(user, action.request.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.request.params) {
                    if (action.request.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.request.params[i]);
                    }
                }
                record = Jax.get(state, action.path, {});
                for (let i in record) {
                    if (record.hasOwnProperty(i)) {
                        request.setColumn(i, record[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostJsonSuccess,
                            args: [response.data, action.path, action.messages, action.dataStoreWriter, action.successAction]
                        });
                        // if (action.successAction) {
                        //     ActionQueue.addToQueue(action.successAction);
                        // }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostJsonFail,
                            args: [response.errorCode, response.errorMessage, action.messages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkPostJsonFail,
                        args: [errorCode, errorMessage, action.messages]
                    });
                    window.console.error("Network Post JSON Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");
            case ActionTypes.NETWORK_POST:
                request = new Job(user, action.request.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.request.params) {
                    if (action.request.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.request.params[i]);
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostSuccess,
                            args: [response.data, action.path, action.messages, action.dataStoreWriter, action.successAction]
                        });
                        // if (action.successAction) {
                        //     ActionQueue.addToQueue(action.successAction);
                        // }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostFail,
                            args: [response.errorCode, response.errorMessage, action.messages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkPostFail,
                        args: [errorCode, errorMessage, action.messages]
                    });
                    window.console.error("Network Post Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_DATA:
                request = new Job(user, action.requestPost.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                headers = Jax.get(state, action.headersPath, "").split("\f");
                record = Jax.get(state, action.path, "").split("\f");
                for (let i in action.requestPost.params) {
                    if (action.requestPost.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.requestPost.params[i]);
                    }
                }
                for (let i = 0, j = headers.length; i < j; i++) {
                    request.setColumn(headers[i], record[i] || "");
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostAndThenFetchDataSuccess,
                            args: [action.companyCode, action.token, response.data, action.pathResponse, action.requestFetch, action.pathFetch, action.postMessages, action.fetchMessages, action.autoEditMode, action.dataStoreWriter]
                        });
                        if (action.successAction) {
                            ActionQueue.addToQueue(action.successAction);
                        }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostAndThenFetchDataFail,
                            args: [response.errorCode, response.errorMessage, action.postMessages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkPostAndThenFetchDataFail,
                        args: [errorCode, errorMessage, action.postMessages]
                    });
                    window.console.error("Network Post Data Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_JSON:
                request = new Job(user, action.requestPost.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.requestPost.params) {
                    if (action.requestPost.params.hasOwnProperty(i)) {
                        request.setSection(i, action.requestPost.params[i]);
                    }
                }
                request.setSection("json", JSON.stringify(Jax.get(state, action.pathPost, {})));
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostAndThenFetchJsonSuccess,
                            args: [action.companyCode, action.token, response.data, action.pathResponse, action.requestFetch, action.pathFetch, action.postMessages, action.fetchMessages, action.autoEditMode, action.dataStoreWriter]
                        });
                        if (action.successAction) {
                            ActionQueue.addToQueue(action.successAction);
                        }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostAndThenFetchJsonFail,
                            args: [response.errorCode, response.errorMessage, action.postMessages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkPostAndThenFetchJsonFail,
                        args: [errorCode, errorMessage, action.postMessages]
                    });
                    window.console.error("Network Post JSON Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");
            case ActionTypes.NETWORK_POST_JSON_AND_THEN_FETCH_DATA:
                request = new Job(user, action.requestPost.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.requestPost.params) {
                    if (action.requestPost.params.hasOwnProperty(i)) {
                        request.setSection(i, action.requestPost.params[i]);
                    }
                }
                request.setSection("json", JSON.stringify(Jax.get(state, action.pathPost, {})));
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostJsonAndThenFetchDataSuccess,
                            args: [action.companyCode, action.token, response.data, action.pathResponse, action.requestFetch, action.pathFetch, action.postMessages, action.fetchMessages, action.autoEditMode, action.dataStoreWriter]
                        });
                        if (action.successAction) {
                            ActionQueue.addToQueue(action.successAction);
                        }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostJsonAndThenFetchDataFail,
                            args: [response.errorCode, response.errorMessage, action.postMessages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkPostJsonAndThenFetchDataFail,
                        args: [errorCode, errorMessage, action.postMessages]
                    });
                    window.console.error("Network Post JSON Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");
            case ActionTypes.NETWORK_POST_DATA_AND_THEN_FETCH_JSON:
                request = new Job(user, action.requestPost.script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                headers = Jax.get(state, action.headersPath, "").split("\f");
                record = Jax.get(state, action.path, "").split("\f");
                for (let i in action.requestPost.params) {
                    if (action.requestPost.params.hasOwnProperty(i)) {
                        request.setColumn(i, action.requestPost.params[i]);
                    }
                }
                for (let i = 0, j = headers.length; i < j; i++) {
                    request.setColumn(headers[i], record[i] || "");
                }
                request.send("/cgi-bin/CashOnTab", (response: JobResponse) => {
                    if (response.errorCode === 0 && response.success) {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostDataAndThenFetchJsonSuccess,
                            args: [action.companyCode, action.token, response.data, action.pathResponse, action.requestFetch, action.pathFetch, action.postMessages, action.fetchMessages, action.autoEditMode, action.dataStoreWriter]
                        });
                        if (action.successAction) {
                            ActionQueue.addToQueue(action.successAction);
                        }
                    } else {
                        ActionQueue.addToQueue({
                            action: Actions.networkPostDataAndThenFetchJsonFail,
                            args: [response.errorCode, response.errorMessage, action.postMessages]
                        });
                    }
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({
                        action: Actions.networkPostDataAndThenFetchJsonFail,
                        args: [errorCode, errorMessage, action.postMessages]
                    });
                    window.console.error("Network Post Data Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");


            case ActionTypes.NETWORK_FETCH_DATA_SUCCESS:
                if (action.data.length > 0) {
                    data = action.data.split("\r");
                    headers = data.shift().split("\f");
                    count = parseInt(data.pop(), 10);
                    obj = Jax.mutate(state, action.path, {
                        data: data,
                        headers: headers,
                        count: count
                    });
                    obj = Jax.set(obj, action.path + ".pagination.total", count);
                    if (action.autoEditMode) {
                        record = {};
                        let d = data[0].split("\f");
                        for (let i = 0, j = headers.length; i < j; i++) {
                            record[headers[i]] = d[i];
                        }
                        obj = Jax.mutate(obj, action.path, { editing: 1, editedRecord: record });
                    }
                } else {
                    obj = state;
                }
                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, obj, action.data);
                }
                if (action.successAction) {
                    ActionQueue.addToQueue(action.successAction);
                }
                return Jax.dec(obj, "ongoingRequests");
            case ActionTypes.NETWORK_FETCH_JSON_SUCCESS:
                if (action.data.length > 0) {
                    obj = Jax.set(state, action.path, JSON.parse(action.data));
                    if (action.autoEditMode) {

                    }
                } else {
                    obj = state;
                }
                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, obj, action.data);
                }
                if (action.successAction) {
                    ActionQueue.addToQueue(action.successAction);
                }
                return Jax.dec(obj, "ongoingRequests");
            case ActionTypes.NETWORK_FETCH_SUCCESS:
                if (action.data.length > 0) {
                    obj = Jax.set(state, action.path, action.data);
                    if (action.autoEditMode) {

                    }
                } else {
                    obj = state;
                }
                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, obj, action.data);
                }
                if (action.successAction) {
                    ActionQueue.addToQueue(action.successAction);
                }
                return Jax.dec(obj, "ongoingRequests");
            case ActionTypes.NETWORK_POST_DATA_SUCCESS:
                if (action.data.length > 0) {
                    data = action.data.split("\r");
                    headers = data.shift();
                    obj = {};
                    headers = headers.split("\f");
                    data = data[0].split("\f");
                    for (let i = 0, j = headers.length; i < j; i++) {
                        obj[headers[i]] = data[i] || "";
                    }
                    obj = Jax.mutate(state, action.responsePath, obj);
                } else {
                    obj = state;
                }
                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, obj, action.data);
                }
                if (action.successAction) {
                    ActionQueue.addToQueue(action.successAction);
                }
                return Jax.dec(obj, "ongoingRequests");
            case ActionTypes.NETWORK_POST_JSON_SUCCESS:
                if (action.data.length > 0) {
                    window.console.log(action.data);
                    obj = Jax.mutate(state, action.responsePath, JSON.parse(action.data));
                } else {
                    obj = state;
                }
                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, obj, action.data);
                }
                if (action.successAction) {
                    ActionQueue.addToQueue(action.successAction);
                }
                return Jax.dec(obj, "ongoingRequests");
            case ActionTypes.NETWORK_POST_SUCCESS:
                obj = state;
                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, state, action.data);
                }
                if (action.successAction) {
                    ActionQueue.addToQueue(action.successAction);
                }
                return Jax.dec(obj, "ongoingRequests");
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_DATA_SUCCESS:
                ActionQueue.addToQueue({
                    action: Actions.networkFetchData,
                    args: [action.companyCode, action.token, action.requestFetch, action.pathFetch, action.fetchMessages, action.autoEditMode, action.successAction, action.dataStoreWriter]
                });
                if (action.data.length > 0) {
                    data = action.data.split("\r");
                    headers = data.shift();
                    obj = {};
                    headers = headers.split("\f");
                    data = data.split("\f");
                    for (let i = 0, j = headers.length; i < j; i++) {
                        obj[headers[i]] = data[i] || "";
                    }
                    obj = Jax.mutate(state, action.responsePath, obj);
                } else {
                    obj = state;
                }
                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, obj, action.data);
                }
                return Jax.dec(obj, "ongoingRequests");
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_JSON_SUCCESS:
                ActionQueue.addToQueue({
                    action: Actions.networkFetchJson,
                    args: [action.companyCode, action.token, action.requestFetch, action.pathFetch, action.fetchMessages, action.autoEditMode, action.successAction, action.dataStoreWriter]
                });
                if (action.data.length > 0) {
                    obj = Jax.mutate(state, action.path, JSON.parse(action.data));
                } else {
                    obj = state;
                }
                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, obj, action.data);
                }
                return Jax.dec(obj, "ongoingRequests");
            case ActionTypes.NETWORK_POST_JSON_AND_THEN_FETCH_DATA_SUCCESS:
                // console.log("zz1: ", action);
                ActionQueue.addToQueue({
                    action: Actions.networkFetchData,
                    args: [action.companyCode, action.token, action.requestFetch, action.pathFetch, action.fetchMessages, action.autoEditMode, action.successAction, action.dataStoreWriter]
                });
                // console.log("zz2: ", action.data.length);

                if (action.data.length > 0) {
                    obj = Jax.mutate(state, action.pathResponse, JSON.parse(action.data));
                } else {
                    obj = state;
                }

                // console.log("zz3: ");


                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, obj, action.data);
                }
                // console.log("zz4: ");

                return Jax.dec(obj, "ongoingRequests");
            case ActionTypes.NETWORK_POST_DATA_AND_THEN_FETCH_JSON_SUCCESS:
                ActionQueue.addToQueue({
                    action: Actions.networkFetchJson,
                    args: [action.companyCode, action.token, action.requestFetch, action.pathFetch, action.fetchMessages, action.autoEditMode, action.successAction, action.dataStoreWriter]
                });
                if (action.data.length > 0) {
                    data = action.data.split("\r");
                    headers = data.shift();
                    obj = {};
                    headers = headers.split("\f");
                    data = data.split("\f");
                    for (let i = 0, j = headers.length; i < j; i++) {
                        obj[headers[i]] = data[i] || "";
                    }
                    obj = Jax.mutate(state, action.responsePath, obj);
                } else {
                    obj = state;
                }
                if (typeof action.dataStoreWriter === "function") {
                    obj = action.dataStoreWriter(action.type, obj, action.data);
                }
                return Jax.dec(obj, "ongoingRequests");

            case ActionTypes.NETWORK_FETCH_DATA_FAIL:
            case ActionTypes.NETWORK_POST_DATA_FAIL:
            case ActionTypes.NETWORK_FETCH_JSON_FAIL:
            case ActionTypes.NETWORK_POST_JSON_FAIL:
            case ActionTypes.NETWORK_FETCH_FAIL:
            case ActionTypes.NETWORK_POST_FAIL:
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_DATA_FAIL:
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_JSON_FAIL:
            case ActionTypes.NETWORK_POST_JSON_AND_THEN_FETCH_DATA_FAIL:
            case ActionTypes.NETWORK_POST_DATA_AND_THEN_FETCH_JSON_FAIL:
            case ActionTypes.GENERATE_REPORT_FAIL:
                if (action.errorCode === 403) {
                    ActionQueue.addToQueue({ action: Actions.apiFailedForbidden, args: [] });
                }
                obj = Jax.dec(state, "ongoingRequests");
                return obj;
            case ActionTypes.GENERATE_REPORT:
                //console.log("test-not-api", action.reportData);
                request = new Job(user, "generate_report", OutputType.OUTPUT_TYPE_REPORT_A4, ProcessType.PROCESS_TYPE_SYNC);
                for (let i in action.reportData) {
                    if (action.reportData.hasOwnProperty(i)) {
                        //console.log("test-not-api-2", action.reportData[i]);
                        request.setColumn(i, action.reportData[i]);
                        //request.setColumn(i, encodeURIComponent(action.reportData[i]));
                    }
                }
                request.send("/cgi-bin/CashOnTab", (response: ReportBlob) => {
                    let names = action.reportData.reportName.split("/");
                    let name = names[names.length - 1];

                    ActionQueue.addToQueue({ action: Actions.generateReportSuccess, args: [response.blob, name] });
                }, (errorMessage: string, errorCode: number) => {
                    ActionQueue.addToQueue({ action: Actions.generateReportFail, args: [errorCode, errorMessage] });
                    window.console.error("Generating Report Error: " + errorCode + ": " + errorMessage);
                });

                return Jax.inc(state, "ongoingRequests");
            case ActionTypes.GENERATE_REPORT_SUCCESS:
                const saveData = (function () {
                    const a = document.createElement("a");
                    if (document.body) {
                        document.body.appendChild(a);
                    }
                    a.style.display = "none";
                    return function (data, fileName) {
                        let url = window.URL.createObjectURL(data);
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    };
                }());
                saveData(action.data, action.reportName);
                obj = Jax.dec(state, "ongoingRequests");
                return obj;
            case ActionTypes.IMAGE_UPLOAD_REQUESTED:
                request = new Job(user, "upload_image", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                request.setSection("data", action.data);
                request.setSection("type", action.imageType);
                request.setSection("box", action.box);
                request.send("/cgi-bin/CashOnTab",
                    (response: ReportBlob) => {
                        ActionQueue.addToQueue({
                            action: Actions.imageUploadSucceeded,
                            args: [response.data, action.setImageCallback]
                        });
                    },
                    (errorMessage: string, errorCode: number) => {
                        ActionQueue.addToQueue({ action: Actions.imageUploadFail, args: [errorCode, errorMessage] });
                        window.console.error("Uploading image: " + errorCode + ": " + errorMessage);
                    });

                return Jax.inc(state, "ongoingRequests");

            case ActionTypes.IMAGE_UPLOAD_FAILED:
                if (action.errorCode === 403) {
                    ActionQueue.addToQueue({ action: Actions.apiFailedForbidden, args: [] });
                }
                obj = Jax.dec(state, "ongoingRequests");
                return obj;
            case ActionTypes.IMAGE_UPLOAD_SUCCESS:
                let images: { url: String, thumbnail: String } = JSON.parse(action.data);
                action.setImageCallback(images.url, images.thumbnail);
                obj = Jax.dec(state, "ongoingRequests");
                return obj;

            case ActionTypes.SET_DEFAULT:
                let tbl = state[action.who].data
                    .map((row) => { return row.split('\f') })
                    .map((row, index) => {
                        //let rowKeyValue = state['ITEM_SUPPLIERS_CREATE_EDIT'].editedRecord['mIsDefault'];
                        let rowKeyValue = state['ITEM_SUPPLIERS_CREATE_EDIT'].editedRecord['mIsDefault'];
                        if (rowKeyValue === '1' || rowKeyValue === 1) {
                            row[action.colIndex] = (index == action.rowKey && row[action.colIndex] === '1') ? 1 : 0;
                        }
                        //else {
                        //   // do nothing
                        //    row[action.colIndex] = (row[action.colIndex] === '1') ? 1 : 0;
                        //}
                        return row;
                    });
                tbl = tbl.map((row) => row.join('\f'))
                console.log("tbl: ", tbl);
                return Jax.mutate(state, action.who, { data: tbl });

            case ActionTypes.SET_ACTIVE:
                let tbl2 = state[action.who].data
                    .map((row) => { return row.split('\f') })
                    .map((row, index) => {
                        let rowKeyValue = state[action.who].editedRecord[action.colKey];
                        if (rowKeyValue === '1' || rowKeyValue === 1) {
                            row[action.colIndex] = (index == action.rowKey && row[action.colIndex] === '1') ? 1 : 0;
                        }
                        //else {
                        //   // do nothing
                        //    row[action.colIndex] = (row[action.colIndex] === '1') ? 1 : 0;
                        //}
                        return row;
                    });
                tbl2 = tbl2.map((row) => row.join('\f'))
                console.log("tbl2: ", tbl2);
                return Jax.mutate(state, action.who, { data: tbl2 });

            case ActionTypes.SET_JSON:
                return Jax.mutate(state, action.who, action.obj);

            case ActionTypes.OVERRIDE_JSON:
                //return Object.assign({}, state, { [action.who]: action.obj });
                return Jax.set(state, action.who, action.obj);

            case ActionTypes.CLEAR_DATA_STORE:
                return this.getInitialState()

            default:
                return state;
        }
    }

    api_get = (api: any) => {
        let dataSend = "";
        const user = new User(this.props.user.token, this.props.user.companyCode);
        const job = new Job(user, api, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, (e) => {
            alert(e);
        });
    };

    successCallback = (ob: any) => {
        let rowsRaw = ob.data;
        let rows = rowsRaw.split("\r");
        this.optionList = [];
        for (let rowRaw of rows) {
            let row = rowRaw.split("\f");
            this.optionList.push({
                code: row[0],
                name: row[1],
            });
        }
    }
}

export default new DataStore();

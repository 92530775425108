/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import GenericModal from "../../components/GenericModal";
import GenericTable from "../../components/GenericTable";
import LangContext from "../../contextProvider/LangContext";
import { Button, Form, Input, InputNumber, Select, Row, Col, Checkbox, Radio, message } from "antd";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import TableLineIcons from "../../components/TableLineIcons";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import SelectorItemsMulti from "../../components/SelectorItemsMulti";
import ReportTypeButton from "../../components/ReportTypeButton";
const { Option } = Select;


class Bonuses extends Component {
    constructor(props: Props) {
        super(props);

        this.clearFilters = {
            _fromDate: null,
            _toDate: null,
            _fromSum: null,
            _toSum: null,
            _docNum: null,
            _employeeList: "",
            _groupEmployeeList: "",
            _itemList: "",
            _tagList: "",
            _supplierList: "",
            _words: "",
        }

        this.state = {
            modalFilter: false,
            modalReport: false,
            filters: this.clearFilters,
            report: {
                ...this.clearFilters,
                bonusToReport: '0',
                isItemGroup: '0',
                _calculateCostMethod: '2',
                reportName: "reports/Bonuses.pdf",
            }
        };

        this.id = "BONUSES_TABLE";

        this.api = {
            get: "get_bonuses_table",
            set: "create_edit_bonuses",
            delete: "delete_bonuses",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorEmployees, api: "get_employee_list" },
            { id: TableIDs.genericSelectorGroupEmployee, api: "get_employee_group_list" },
            { id: "BONUSES_LIST", api: "bonuses_list" },
        ];

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(17824);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            this.setState({ report: this.props.ui.favoriteParams, modalReport: true })
        }
    }

    handleCancelEdit = () => {
        const { editing } = this.props.data[this.id];
        if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id)
        else this.props.dataActions.genericDataCancelEditing(this.id)
    }

    handleSaveEdit = () => {
        const {
            bName, bFromDate, bToDate, bFromSum, bToSum, bPercentValue, bSumValue,
            beList, begList, biList, bitList, bisList,
            bIsLimitToEmployees, bIsLimitToEmployeeGroups, bIsLimitToItems, bIsLimitToItemTags, bIsLimitToItemSuppliers,
        } = this.props.data[this.id].editedRecord;
        if (!bName || !bFromDate || !bToDate || !bFromSum || !bToSum) {
            message.error(this.getText(18595))
        } else if (!bPercentValue && !bSumValue) {
            message.error(this.getText(18596))
        } else if (bIsLimitToEmployees == 1 && !beList) {
            message.error(this.getText(18621))
        } else if (bIsLimitToEmployeeGroups == 1 && !begList) {
            message.error(this.getText(18622))
        } else if (bIsLimitToItems == 1 && !biList) {
            message.error(this.getText(18623))
        } else if (bIsLimitToItemTags == 1 && !bitList) {
            message.error(this.getText(18624))
        } else if (bIsLimitToItemSuppliers == 1 && !bisList) {
            message.error(this.getText(18625))
        } else {
            this.props.dataActions.genericDataSaveEdit(
                this.id, this.props.user.companyCode, this.props.user.token, this.api.set, this.api.get);
        }
    }

    editColRender = (text, record) => {
        return (<TableLineIcons
            onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
            onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get) }}
            deleteQuetion={{ title: this.getText(17809), okText: this.getText(17810), cancelText: this.getText(17811) }}
            genericIcons={[
                { type: 'printer', onClick: () => { this.setState({ modalReport: true, report: { ...this.state.report, bonusToReport: record.key } }) }, tooltip: this.getText(17860) }
            ]}
        />)
    }

    getInputField = (field, label, required) => {
        const { editedRecord } = this.props.data[this.id];
        const value = editedRecord[field];
        let otherChange = {}
        let minNum = 0;

        if (field === 'bPercentValue') otherChange = { bSumValue: null }
        if (field === 'bSumValue') otherChange = { bPercentValue: null }
        if (field === 'bToSum' && editedRecord.bFromSum) minNum = editedRecord.bFromSum;

        const onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e, ...otherChange }) }

        const formItem = content => {
            return (<Form.Item
                label={this.getText(label)}
                required={required}
                validateStatus={required && !value ? "error" : "success"}>
                {content}
            </Form.Item>)
        }

        switch (field) {
            case 'bName':
                return formItem(<Input value={value} onChange={e => { onChange(e.target.value) }} />)
            case 'bFromSum':
            case 'bToSum':
            case 'bPercentValue':
            case 'bSumValue':
                return formItem(<InputNumber min={minNum} precision={2} {...{ value, onChange }} />)
            case 'bFromDate':
            case 'bToDate':
                return formItem(<DateTimePicker format="DD/MM/YYYY" {...{ value, onChange }}
                    minDate={field === 'bToDate' ? editedRecord.bFromDate : null}
                    maxDate={field === 'bFromDate' ? editedRecord.bToDate : null}
                />)
            case 'bType':
                return formItem(<Radio.Group value={value ? value : "0"} onChange={e => { onChange(e.target.value) }} >
                    <Radio value="0">{this.getText(18571)}</Radio>
                    <Radio value="1">{this.getText(18572)}</Radio>
                </Radio.Group>)
        }
    }

    getCheckboxField = (field, label, selectField) => {
        const { editedRecord } = this.props.data[this.id];
        const checked = editedRecord[field] == '1'

        return (<Form.Item>
            <Row>
                <Col span={window.innerWidth > 600 ? 8 : 24}>
                    <Checkbox
                        checked={checked}
                        onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.target.checked ? '1' : '0' }) }}
                    >{this.getText(label)}</Checkbox>
                </Col>
                <Col span={window.innerWidth > 600 ? 16 : 24}>
                    {checked ? this.getSelectorField(selectField) : ""}
                </Col>
            </Row>
        </Form.Item>)
    }

    getSelectorField = (field) => {
        const { editedRecord } = this.props.data[this.id];
        const value = editedRecord[field] ? editedRecord[field].split(",") : [];
        const onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e ? e.join(",") : "" }) }

        switch (field) {
            case 'beList': return (<GenericSelector {...this.props} {...this.selectors[0]} multi {...{ value, onChange }} />)
            case 'begList': return (<GenericSelector {...this.props} {...this.selectors[1]} multi {...{ value, onChange }} />)
            case 'biList': return (<SelectorItemsMulti {...this.props} value={value} onChange={(e) => { onChange(e.map(x => x.mCode)) }} />)
            case 'bitList': return (<GenericSelectorItemTags {...this.props} multi notReport {...{ value, onChange }} />)
            case 'bisList': return (<GenericSelectorSuppliers {...this.props} multi {...{ value, onChange }} />)
        }
    }

    getFilterField = (field) => {
        const value = this.state.filters[field];
        const onChange = (e) => { this.setState({ filters: { ...this.state.filters, [field]: e } }) }

        let minNum = 0;
        let minDate = null;
        let maxDate = null;

        if (field === '_toSum' && this.state.filters._fromSum) minNum = this.state.filters._fromSum;
        if (field === '_fromDate') maxDate = this.state.filters._toDate;
        if (field === '_toDate') minDate = this.state.filters._fromDate;


        switch (field) {
            case '_fromDate': case '_toDate': return (<DateTimePicker format="DD/MM/YYYY" {...{ value, onChange, minDate, maxDate }} />)
            case '_fromSum': case '_toSum': return (<InputNumber min={minNum} precision={2} {...{ value, onChange }} />)
        }

        const valueMulti = value ? value.split(",") : []
        const onChangeMulti = (e) => { onChange(e ? e.join(",") : "") }

        switch (field) {
            case '_employeeList': return (<GenericSelector {...this.props} {...this.selectors[0]} multi value={valueMulti} onChange={onChangeMulti} />)
            case '_groupEmployeeList': return (<GenericSelector {...this.props} {...this.selectors[1]} multi value={valueMulti} onChange={onChangeMulti} />)
            case '_itemList': return (<SelectorItemsMulti {...this.props} value={valueMulti} onChange={(e) => { onChangeMulti(e.map(x => x.mCode)) }} />)
            case '_tagList': return (<GenericSelectorItemTags {...this.props} multi notReport value={valueMulti} onChange={onChangeMulti} />)
            case '_supplierList': return (<GenericSelectorSuppliers {...this.props} multi value={valueMulti} onChange={onChangeMulti} />)
        }

    }

    getReportField = (field) => {
        const value = this.state.report[field];
        const onChange = (e) => { this.setState({ report: { ...this.state.report, [field]: e } }) }

        let minNum = 0;
        let minDate = null;
        let maxDate = null;

        if (field === '_toSum' && this.state.report._fromSum) minNum = this.state.report._fromSum;
        if (field === '_fromDate') maxDate = this.state.report._toDate;
        if (field === '_toDate') minDate = this.state.report._fromDate;


        switch (field) {
            case '_fromDate': case '_toDate': return (<DateTimePicker format="DD/MM/YYYY" {...{ value, onChange, minDate, maxDate }} />)
            case '_fromSum': case '_toSum': return (<InputNumber min={minNum} precision={2} {...{ value, onChange }} />)
            case '_docNum': return (<InputNumber style={{ width: "100%" }} precision={0} {...{ value, onChange }} />)
            case 'bonusToReport': return (<GenericSelector {...this.props} {...this.selectors[2]} {...{ value, onChange }} addFirstRow={{ code: '0', name: this.getText(17859) }} />)
            case 'isItemGroup': return (<Checkbox checked={value == '1'} onChange={e => { onChange(e.target.checked ? '1' : '0') }}>{this.getText(17861)}</Checkbox>)
            case 'reportName':
                return (<Radio.Group value={value} onChange={e => { onChange(e.target.value) }}>
                    <Radio value={"reports/Bonuses.pdf"}>{this.getText(17857)}</Radio>
                    <Radio value={"reports/BonusesDetailed.pdf"}>{this.getText(17858)}</Radio>
                </Radio.Group>)
            case '_calculateCostMethod':
                const isUserCalcFifo = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset &&
                    this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '517');

                return (<Select key={"_calculateCostMethod"} style={{ width: 250 }} {...{ value, onChange }}>
                    <Option key={1} value={'1'}>{this.getText(18843)}</Option>
                    <Option key={2} value={'2'}>{this.getText(18844)}</Option>
                    {isUserCalcFifo ? <Option key={3} value={'3'}>{this.getText(18845)}</Option> : ""}
                    {isUserCalcFifo ? <Option key={4} value={'4'}>{this.getText(18846)}</Option> : ""}
                </Select>)

        }

        const valueMulti = value ? value.split(",") : []
        const onChangeMulti = (e) => { onChange(e ? e.join(",") : "") }

        switch (field) {
            case '_employeeList': return (<GenericSelector {...this.props} {...this.selectors[0]} multi value={valueMulti} onChange={onChangeMulti} />)
            case '_groupEmployeeList': return (<GenericSelector {...this.props} {...this.selectors[1]} multi value={valueMulti} onChange={onChangeMulti} />)
            case '_itemList': return (<SelectorItemsMulti {...this.props} value={valueMulti} onChange={(e) => { onChangeMulti(e.map(x => x.mCode)) }} />)
            case '_tagList': return (<GenericSelectorItemTags {...this.props} multi notReport value={valueMulti} onChange={onChangeMulti} />)
            case '_supplierList': return (<GenericSelectorSuppliers {...this.props} multi value={valueMulti} onChange={onChangeMulti} />)
        }

    }


    handleFilter = () => {
        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, this.state.filters, this.api.get)
        })
    }

    handleClear = () => {
        this.setState({ filters: this.clearFilters }, this.handleFilter)
    }

    render() {
        const gt = this.getText;
        const sorter = true;
        const render = t => t;
        const { editing } = this.props.data[this.id];

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const { _fromDate, _toDate, _fromSum, _toSum, _employeeList, _groupEmployeeList, _itemList, _tagList, _supplierList, _words } = this.state.filters
        const clearDisabled = !_fromDate && !_toDate && !_fromSum && !_toSum && !_employeeList && !_groupEmployeeList && !_itemList && !_tagList && !_supplierList && !_words


        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                filters={this.state.filters}
                actionButtons={[
                    { buttonType: 'add', buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) } },
                    { buttonType: 'filter', buttonFunction: () => { this.setState({ modalFilter: true }) } },
                    { buttonType: 'clear', buttonFunction: this.handleClear, buttonDisabled: clearDisabled },
                    { buttonType: 'print', buttonFunction: () => { this.setState({ modalReport: true, report: { ...this.state.report, bonusToReport: '0' } }) } },
                ]}
                title={gt(17825)}
                searchBox
                valueSearch={this.state.filters._words}
                onChangeSearch={e => { this.setState({ filters: { ...this.state.filters, _words: e.target.value } }) }}
                columns={[
                    { title: gt(17826), key: "bID", dataIndex: "bID", width: "6%", sorter, render },
                    { title: gt(17827), key: "bName", dataIndex: "bName", width: "22%", sorter, render },
                    { title: gt(17828), key: "bFromDate", dataIndex: "bFromDate", width: "13%", sorter, render },
                    { title: gt(17829), key: "bToDate", dataIndex: "bToDate", width: "13%", sorter, render },
                    { title: gt(17830), key: "bFromSum", dataIndex: "bFromSum", width: "10%", sorter, render },
                    { title: gt(17831), key: "bToSum", dataIndex: "bToSum", width: "10%", sorter, render },
                    { title: gt(17832), key: "bPercentValue", dataIndex: "bPercentValue", width: "10%", sorter, render },
                    { title: gt(17833), key: "bSumValue", dataIndex: "bSumValue", width: "10%", sorter, render },
                    { title: gt(17834), width: "6%", render: this.editColRender },
                ]}
                percentageToMobile={[30, 70]}
            />
            <GenericModal
                visible={editing > -1}
                onCancel={this.handleCancelEdit}
                title={gt(17835)}
                width={1000}
                footer={[
                    <Button type='primary' onClick={this.handleSaveEdit}>{gt(17836)}</Button>,
                    <Button onClick={this.handleCancelEdit}>{gt(17837)}</Button>,
                ]}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 10 : 24} style={{ paddingLeft: 30 }}>{this.getInputField('bName', 17827, true)}</Col>
                    <Col span={window.innerWidth > 600 ? 7 : 24} >{this.getInputField('bFromDate', 17828, true)}</Col>
                    <Col span={window.innerWidth > 600 ? 7 : 24} >{this.getInputField('bToDate', 17829, true)}</Col>
                </Row>
                <Row>
                    <Col span={window.innerWidth > 600 ? 4 : 24} >{this.getInputField('bFromSum', 17830, true)}</Col>
                    <Col span={window.innerWidth > 600 ? 4 : 24} >{this.getInputField('bToSum', 17831, true)}</Col>
                    <Col span={window.innerWidth > 600 ? 5 : 24} >{this.getInputField('bPercentValue', 17832)} </Col>
                    <Col span={window.innerWidth > 600 ? 5 : 24} >{this.getInputField('bSumValue', 17833)}</Col>
                    <Col span={window.innerWidth > 600 ? 6 : 24} >{this.getInputField('bType', 18570)}</Col>
                </Row>
                <Form.Item>
                    <Checkbox
                        checked={this.props.data[this.id].editedRecord.bIsPerDoc == '1'}
                        onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { bIsPerDoc: e.target.checked ? '1' : '0' }) }}
                    >{gt(18265)}</Checkbox>
                </Form.Item>
                {this.getCheckboxField('bIsLimitToEmployees', 17838, 'beList')}
                {this.getCheckboxField('bIsLimitToEmployeeGroups', 17839, 'begList')}
                {this.getCheckboxField('bIsLimitToItems', 17840, 'biList')}
                {this.getCheckboxField('bIsLimitToItemTags', 17841, 'bitList')}
                {this.getCheckboxField('bIsLimitToItemSuppliers', 17842, 'bisList')}

            </GenericModal>
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                title={gt(17844)}
                width={1200}
                footer={[
                    <Button type='primary' onClick={this.handleFilter}>{gt(17813)}</Button>,
                    <Button onClick={() => { this.setState({ filters: this.clearFilters }) }}>{gt(17819)}</Button>,
                    <Button onClick={() => { this.setState({ modalFilter: false }) }}>{gt(17814)}</Button>,
                ]}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ paddingLeft: 30 }}>
                        <Form.Item label={gt(17845)}>{this.getFilterField('_fromDate')}</Form.Item>
                        <Form.Item label={gt(17846)}>{this.getFilterField('_toDate')}</Form.Item>
                        <Form.Item label={gt(17847)}>{this.getFilterField('_fromSum')}</Form.Item>
                        <Form.Item label={gt(17848)}>{this.getFilterField('_toSum')}</Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ paddingLeft: 30 }}>
                        <Form.Item label={gt(17849)}>{this.getFilterField('_employeeList')}</Form.Item>
                        <Form.Item label={gt(17850)}>{this.getFilterField('_groupEmployeeList')}</Form.Item>
                        <Form.Item label={gt(17851)}>{this.getFilterField('_itemList')}</Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ paddingLeft: 30 }}>
                        <Form.Item label={gt(17852)}>{this.getFilterField('_tagList')}</Form.Item>
                        <Form.Item label={gt(17853)}>{this.getFilterField('_supplierList')}</Form.Item>
                    </Col>
                </Row>
            </GenericModal>
            <GenericModal
                visible={this.state.modalReport}
                onCancel={() => { this.setState({ modalReport: false }) }}
                title={gt(17854)}
                width={1200}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ paddingLeft: 30 }}>
                        <Form.Item label={gt(17845)}>{this.getReportField('_fromDate')}</Form.Item>
                        <Form.Item label={gt(17846)}>{this.getReportField('_toDate')}</Form.Item>
                        <Form.Item label={gt(17847)}>{this.getReportField('_fromSum')}</Form.Item>
                        <Form.Item label={gt(17848)}>{this.getReportField('_toSum')}</Form.Item>
                        <Form.Item label={gt(18959)}>{this.getReportField('_docNum')}</Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ paddingLeft: 30 }}>
                        <Form.Item label={gt(17849)}>{this.getReportField('_employeeList')}</Form.Item>
                        <Form.Item label={gt(17850)}>{this.getReportField('_groupEmployeeList')}</Form.Item>
                        <Form.Item label={gt(17851)}>{this.getReportField('_itemList')}</Form.Item>
                        <Form.Item label={gt(17852)}>{this.getReportField('_tagList')}</Form.Item>
                        <Form.Item label={gt(17853)}>{this.getReportField('_supplierList')}</Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ paddingLeft: 30 }}>
                        <Form.Item label={gt(17855)}>{this.getReportField('bonusToReport')}</Form.Item>
                        <Form.Item label={gt(17856)}>{this.getReportField('reportName')}</Form.Item>
                        {this.state.report.reportName.indexOf("BonusesDetailed") > -1 ?
                            <Form.Item>{this.getReportField('isItemGroup')}</Form.Item>
                            : ""}
                        <Form.Item label={gt(18842)}>{this.getReportField('_calculateCostMethod')}</Form.Item>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.report}
                            encodeURIFields={['_words', '_itemList']}
                            datesToFavorites={[
                                { field: '_fromDate', label: gt(17845) },
                                { field: '_toDate', label: gt(17846) },
                            ]}
                            xlsReportName={this.state.report.reportName.split(".")[0] + "Excel.xls"}
                            favoriteSave
                        />
                    </Col>
                </Row>
            </GenericModal>
        </div >)
    }

}

export default Bonuses;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Tooltip, Icon } from "antd";
import moment from "moment";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import "../Dashboard.css";

class TopEmployeesSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            LastTimeRefreshe: null,
        }

        this.id = "TOP_EMPLOYEE_DATA";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (
            p.dashboardDate !== this.props.dashboardDate ||
            p.dashboardPos !== this.props.dashboardPos ||
            p.dDateCheck !== this.props.dDateCheck
        ) {
            this.getDataTopEmployees()
        }
    }

    getDataTopEmployees = () => {

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: 10,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: 10,
            _isAsc: 1,
            _sort_by: 'col_0',
            dashboardDate: this.props.dashboardDate ? moment(this.props.dashboardDate).format("YYYY-MM-DD") : null,
            dashboardPos: this.props.dashboardPos,
            dDateCheck: this.props.dDateCheck ? moment(this.props.dDateCheck).format("YYYY-MM-DD") : null,
        };

        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, "get_dashboard_top_employees");

        this.setState({ LastTimeRefreshe: moment().format('HH:mm:ss'/*'LTS'*/) });
    }

    getSummary = (text, record) => {
        let status = parseFloat(text) > 0 && (!record.eYesterday || parseFloat(record.eYesterday) <= parseFloat(text)) ? true : false
        let bgColor = status ? 'GreenYellow' : 'lightpink';
        return (<div style={{ backgroundColor: bgColor, width: '100%', height: '24px' }}>{parseFloat(text) ? parseFloat(text).toFixed(2) : ''}</div>)
    }

    tableChange = (pagination, filters, sorter) => {
        let col = 10;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "eId": col--;
            case "eName": col--;
            case "eHours": col--;
            case "eItems": col--;
            case "eLines": col--;
            case "eItemsAverage": col--;
            case "eSummaryAverage": col--;
            case "eSummary": col--;
            case "eFertility": col--;
            case "eYesterday": break; // 10
            default:
                col = 0;
        }

        let request = {
            ...this.props.data[this.id].filters,
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: 10,//pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: 10,// pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
        };

        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, "get_dashboard_top_employees");

        this.setState({ LastTimeRefreshe: moment().format('HH:mm:ss'/*'LTS'*/) });
    }


    render() {
        let dc = this.props.dDateCheck;
        let gt = this.getText;
        let t = (text) => text;
        let t1 = (text) => parseFloat(text) ? parseFloat(text).toFixed(2) : this.getText(10105);
        let t2 = (text) => parseFloat(text) ? parseFloat(text).toFixed(2) : '0.00';
        let yesterdayTitle = gt(10073) + " " + (dc ? gt(10040) + '-' + moment(dc).format("DD/MM/YY") : gt(10074))


        let columns = [
            { title: gt(10065), dataIndex: 'eName', key: 'eName', width: '20%', sorter: true, render: t },
            { title: gt(10066), dataIndex: 'eHours', key: 'eHours', width: '10%', sorter: true, render: t1 },
            { title: gt(10067), dataIndex: 'eItems', key: 'eItems', width: '10%', sorter: true, render: t2 },
            { title: gt(10068), dataIndex: 'eLines', key: 'eLines', width: '10%', sorter: true, render: t },
            { title: gt(10069), dataIndex: 'eItemsAverage', key: 'eItemsAverage', width: '10%', sorter: true, render: t2 },
            { title: gt(10070), dataIndex: 'eSummaryAverage', key: 'eSummaryAverage', width: '10%', sorter: true, render: t2 },
            { title: gt(10071), dataIndex: 'eFertility', key: 'eFertility', width: '10%', sorter: true, render: t1 },
            { title: gt(10072), dataIndex: 'eSummary', key: 'eSummary', width: '10%', sorter: true, render: this.getSummary },
            { title: yesterdayTitle, dataIndex: 'eYesterday', key: 'eYesterday', width: '10%', sorter: true, render: t2 },
        ]

        return (<div style={{ marginBottom: "24px" }}>
            <ResponsiveTable
                columns={columns}
                cardStyle={true}
                percentageToMobile={[40, 30, false, false, false, false, false, 30]}
                title={<div>
                    <span>{gt(10140)}</span>
                    <span style={{ float: "left" }}>
                        <span style={{ marginLeft: 4 }}>{this.state.LastTimeRefreshe}</span>
                        <Button className={"btn"}
                            disabled={this.props.ui.workInProgress}
                            onClick={this.getDataTopEmployees}>
                            <Icon type={"reload"} style={{ fontSize: 16, padding: 4 }} />
                        </Button>
                    </span>
                </div>}
                actionButtons={[]}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                idTable={this.props.data[this.id]}
                // pagination={false}
                pagination={this.props.data[this.id] ? this.props.data[this.id].pagination : false}
                onChange={this.tableChange}
            />
        </div>)
    }
}
export default TopEmployeesSection;

import React, { Component } from "react";
import { Icon, message, Tooltip } from 'antd';
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext"
import ModalServiceCall from "../components/ModalServiceCall";
import html2canvas from 'html2canvas';

class ServiceCallButton extends Component<Props, State> {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        return (<span>
            <Tooltip title={this.getText(10016)}>
                <Icon
                    type={'phone'}
                    style={{ marginLeft: '40px', color: '#fff' }}
                    onClick={() => {
                        html2canvas(document.body).then((canvas) => {
                            document.getElementById('screenshot').appendChild(canvas);
                            if (canvas) {
                                canvas.style.width = '100%';
                                canvas.style.height = '100%';
                            }
                        });
                        this.props.uiActions.showModal(TableIDs.modalServiceCall, {})
                    }}
                />
            </Tooltip>
            <ModalServiceCall
                id={TableIDs.modalServiceCall}
                user={this.props.user}
                uiActions={this.props.uiActions} ui={this.props.ui}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalServiceCall)}
            />
        </span>)
    }

}

export default ServiceCallButton;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import { Form, Checkbox, Card, Button, Row, Col, message, Select, InputNumber } from "antd";
import PageToolbar from "../../components/PageToolbar";
import PictureView from "../../components/PictureView";
import ImageSearch from "../../components/ImageSearch";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ColorPicker from "../../components/ColorPicker";

const { Option } = Select;

class CustomerPortal extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            report1: '0',
            report2: '0',
            report3: '0',
            report4: '0',
            documentCopy: '0',
            fixLabDocuments: '0',
            topBanner: '',
            bottomBanner: '',
            isPassword: '0',
            formColor: null,

            googleImage: null,
            googleImageField: null,

            formTypeID: '6',
        };

        this.api = {
            get: "get_customer_portal_declarations",
            edit: "edit_customer_portal_declarations",
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.getDataApi();
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15972);
    }

    sendAPI = (script, data, success) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", success, (e) => { message.error(e) });
    }

    getDataApi = () => {
        this.sendAPI(this.api.get, '_id\r' + this.state.formTypeID, (ob) => {
            let data = {
                report1: '0',
                report2: '0',
                report3: '0',
                report4: '0',
                documentCopy: '0',
                fixLabDocuments: '0',
                topBanner: '',
                bottomBanner: '',
                isPassword: '0',
                formColor: null,
            }

            if (ob.data) {
                let y = ob.data.split('\f');

                data = {
                    ...data,
                    report1: y[0],
                    report2: y[1],
                    report3: y[2],
                    report4: y[3],
                    documentCopy: y[4],
                    topBanner: y[5],
                    bottomBanner: y[6],
                    isPassword: y[7],
                    formColor: y[8],
                    fixLabDocuments: y[9],
                }
            }

            this.setState(data);

        }, (e) => { message.error(e) });
    }

    createCheckbox = (field, text) => {
        return (<p>
            <Checkbox
                checked={this.state[field] == '1'}
                onChange={(e) => {
                    this.setState({ [field]: e.target.checked ? '1' : '0' })
                }}>{this.getText(text)}</Checkbox>
        </p>)
    }

    createImage = (field, text) => {
        let onChange = (url) => { this.setState({ [field]: url }) }

        return (<Form.Item label={this.getText(text)} style={{ float: 'left' }}>
            <PictureView
                {...this.props}

                imageType={'V'} aspect={4 / 1} url={this.state[field]}
                onRemove={() => { onChange('') }} onChange={onChange}
                onUploadedSuccess={() => { }} onUploadedFailed={() => { }}
                googleImage={this.state.googleImageField === field ? this.state.googleImage : null}
            />
            <div>
                <ImageSearch
                    {...this.props}
                    onChange={(base64) => this.setState({ googleImage: base64, googleImageField: field })}
                    defaultValue={''}
                />
            </div>
        </Form.Item>)
    }

    handleOk = () => {
        let headers = Object.keys(this.state);
        let dataSend = headers.join('\f') + '\r' + headers.map(x => this.state[x] ? this.state[x] : '').join('\f');
        this.sendAPI(this.api.edit, dataSend, () => {
            message.success(this.getText(16007));
            this.getDataApi()
        });
    }

    render() {
        let divStyle = {
            padding: 4,
            margin: 'auto',
            marginTop: 10,
            width: 1000,
        }

        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        if (isDev) origin += '/cash-on-tab/build';

        
        return (<div style={divStyle}>
            <PageToolbar
                {...this.props}
                title={this.getText(15973)}
                actionButtons={[{
                    buttonType: 'refresh',
                    buttonFunction: this.getDataApi
                }]}
            />
            <Card actions={[<Button type="primary" onClick={this.handleOk}>{this.getText(15982)}</Button>]}>

                <Row>
                    <Col span={window.innerWidth > 600 ? this.state.formTypeID == '12' ? 8 : 6 : 24}>
                        <Form.Item>
                            <Select value={this.state.formTypeID} onChange={(formTypeID) => { this.setState({ formTypeID }, this.getDataApi) }} style={{ width: '100%' }}>
                                <Option value={'6'}>{this.getText(16349)}</Option>
                                <Option value={'1'}>{this.getText(16350)}</Option>
                                <Option value={'12'}>{this.getText(19287)}</Option>
                            </Select>
                        </Form.Item>

                        {this.state.formTypeID == '6' ? [
                            this.createCheckbox('report1', 15974),
                            this.createCheckbox('report2', 15975),
                            this.createCheckbox('report3', 15976),
                            this.createCheckbox('report4', 15977),
                            this.createCheckbox('documentCopy', 15978),
                            this.createCheckbox('fixLabDocuments', 17929),
                            // this.createCheckbox('isPassword', 15979) // דני ביקש לשנות 2024-10-29
                        ] : this.state.formTypeID == '12' ?
                            (<Button type={"primary"} onClick={() => {
                                this.sendAPI("get_link_to_qr_code_link", "", (ob) => {
                                    this.props.ActionQueue.addToQueue({
                                        action: this.props.dataActions.generateReport,
                                        args: [
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            {
                                                _baseUrl: origin,
                                                _compCode: this.props.user.companyCode,
                                                _resToken: ob.data,
                                                reportName: "reports/QrCodeToLink.pdf"
                                            }
                                        ]
                                    });
                                })
                            }}>{this.getText(18143)}</Button>)
                            : ""}
                    </Col>
                    <Col span={window.innerWidth > 600 ? this.state.formTypeID == '12' ? 8 : 6 : 24} style={{ padding: '0px 10% 0px 10%' }}>
                        {this.state.formTypeID == '12' ?
                            [<Form.Item label={this.getText(19288)}>
                                <Select style={{ width: 250 }} value={this.state.report1} onChange={e => { this.setState({ report1: e }) }}>
                                    <Option value={'0'}>{this.getText(19289)}</Option>
                                    <Option value={'1'}>{this.getText(19290)}</Option>
                                </Select>
                            </Form.Item>,
                            <Form.Item label={this.getText(19292)}>
                                <InputNumber value={this.state.report2} onChange={e => { this.setState({ report2: e }) }} />
                            </Form.Item>]
                            : <Form.Item label={this.getText(16351)}>
                                <ColorPicker value={this.state.formColor} onChange={(formColor) => { this.setState({ formColor }) }} />
                            </Form.Item>}
                    </Col>
                    <Col span={window.innerWidth > 600 ? this.state.formTypeID == '12' ? 8 : 6 : 24} style={{ padding: '0px 10% 0px 10%' }}>
                        {this.createImage('topBanner', this.state.formTypeID == '12' ? 19291 : 15980)}
                    </Col>
                    <Col span={this.state.formTypeID == '12' ? 0 : window.innerWidth > 600 ? 6 : 24} style={{ padding: '0px 10% 0px 10%' }}>
                        {this.createImage('bottomBanner', 15981)}
                    </Col>
                </Row>

            </Card>
        </div>)
    }

}

export default CustomerPortal;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import TableLineIcons from "../../components/TableLineIcons";
import GenericTable from "../../components/GenericTable";
import TextArea from "antd/lib/input/TextArea";
import { Input } from "antd";


class CancelReasons extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "CANCEL_REASONS_TABLE";

        this.api = {
            get: "get_cancel_reasons",
            create: "create_cancel_reasons",
            edit: "edit_cancel_reasons",
            delete: "delete_cancel_reasons",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }



    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(19089);
    }

    getDesc = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        if (editing === record.key) {
            return (<Input
                value={editedRecord.name}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { name: e.target.value }) }}
            />)
        } else {
            return text
        }
    }

    getEditCol = (text, record) => {
        const { editing } = this.props.data[this.id];
        if (editing == record.key) {
            let saveApi = editing == 0 ? this.api.create : this.api.edit;
            let cancelAction = editing == 0 ? "genericDataCancelInlineCreate" : "genericDataCancelEditing";
            return (<TableLineIcons
                onCheck={() => { this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, saveApi, this.api.get) }}
                onClose={() => { this.props.dataActions[cancelAction](this.id) }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get) }}
                deleteQuetion={{ title: this.getText(19094), okText: this.getText(19095), cancelText: this.getText(19096) }}
            />)
        }
    }


    render() {
        const gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 1000,
            margin: "auto"
        };



        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id} getApi={this.api.get} title={gt(19090)}
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
                columns={[
                    { title: gt(19091), key: "code", dataIndex: "code", width: "10%", render: t => t > 0 ? t : "" },
                    { title: gt(19092), key: "name", dataIndex: "name", width: "80%", render: this.getDesc },
                    { title: gt(19093), width: "10%", render: this.getEditCol },
                ]}
            />
        </div>)
    }

}

export default CancelReasons;

import React from "react";
import "../App.css";
import { Form, Row, Col, Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Checkbox, Select, Radio } from 'antd';
import { DatePicker } from 'antd';
import moment from "moment";
import Jax from "../Jax/jax.es6.module";
import TableIDs from '../data/TableIDs.js';
import PageToolbar from "./PageToolbar";
import LangContext from "../contextProvider/LangContext";
import GenericSelectorSuppliers from "./GenericSelectorSuppliers";
import { disabledDatePicker } from "../utils/utils";
import GenericSelectorPosStorages from "./GenericSelectorPosStorages";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type TagObj = {
    type: {
        id: string,
        text: string,
    },
    tags: {
        id: string,
        text: string,
    }[],
}

class ModalPurchaseAdvanceSearch extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.id = "ADVANCE_PURCHES_DOCS_SEARCH";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }


        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }


        this.api = {
            selector_pos: "list_pos",
            selector_suppliers: "get_suppliers",

            get: "get_document_purchase_list_table",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.state = {
            loading: false,
            visible: false,
            request: {
                _words: '',
                _FromDate: undefined,
                _ToDate: undefined,
                _PosList: '',
                _SupplierList: '',
                _typeID: '',
                _isHandled: '0',
            }
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_suppliers);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    // componentWillReceiveProps(newProps) {
    //     //alert("on props: " + JSON.stringify(newProps));        
    //     let _state = this.state;
    //     _state.visible = newProps.toggle;
    //     this.setState(_state);
    // }

    refreshData = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_suppliers]
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    handleOk = () => {
        console.log("search...");
        let fullPosList = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj) => obj['code']).join(',');
        let request = this.state.request._PosList === '' ? { ...this.state.request, _PosList: fullPosList } : this.state.request

        this.props.dataActions.genericDataSetFilter(
            'PURCHES_DOCS_MAIN', this.props.user.companyCode, this.props.user.token, request, this.api.get);

        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }
    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }

    disabledStartDate = (mFormDate) => {
        const _ToDate = this.state._ToDate;
        if (!mFormDate || !_ToDate) {
            return false;
        }
        return mFormDate.valueOf() > _ToDate.valueOf();
    }

    disabledEndDate = (_ToDate) => {
        const mFormDate = this.state.mFormDate;
        if (!_ToDate || !mFormDate) {
            return false;
        }
        return _ToDate.valueOf() <= mFormDate.valueOf();
    }

    onChange = (field, value) => {
        //this.setReportParameters("HoursReport", { [field]: value })
        let r = this.state.request;
        this.setState({
            request: {
                ...r, [field]: value,
            }
        });
    }

    onStartChange = (value) => {
        this.onChange('_FromDate', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('_ToDate', value ? value.format("YYYY-MM-DD") : null);
    }

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }

    createCheckBox = (field, data) => {
        let r = this.state.request;
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setState({ request: { ...r, [field]: data } });
                //this.setReportParameters("SalesItems", { [field]: data });
                else
                    this.setState({ request: { ...r, [field]: '' } });
                //this.setReportParameters("SalesItems", { [field]: '' });
            }} />);
    }

    render() {
        const { visible, loading, endOpen } = this.state;
        const { _FromDate, _ToDate } = this.state.request;

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listSupplier = Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(11863)}
                    actionButtons={[]} //{this.actionButtons}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        let rMobile = window.innerWidth <= 600 ? true : false;

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={rMobile ? '90%' : '60%'}
                visible={this.props.toggle}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={/**/null}
            >
                <Form id="search_working_hours_form" layout="horizontal" //className="ant-advanced-search-form"
                    style={{ columnWidth: 'auto', columnCount: rMobile ? '1' : '2' }}
                    onSubmit={this.handleSearch}>

                    <FormItem label={this.getText(11864)} style={{ margin: '4px', breakInside: 'avoid-column' }}/*{...formItemLayout}*/>
                        <Input placeholder={this.getText(11868)} style={{ width: '80%' }}
                            value={//this.props.data['ITEMS_TABLE'].filters['_words']
                                this.state.request['_words']
                            }
                            onChange={this.onChangeInput}
                            onBlur={this.handleFilterBlur}
                        />
                    </FormItem>
                    <FormItem label={this.getText(11865)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        {/* {this.createCheckBox('_PosList', Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                        <Select key={"_PosList"} style={{ width: '80%' }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4} */}
                        <GenericSelectorPosStorages {...this.props} multi

                            onChange={(value) => {
                                let r = this.state.request;
                                this.setState({ request: { ...r, _PosList: value.join(',') } });
                            }}
                            value={(this.state.request._PosList === '') ? [] : this.state.request._PosList.split(',')}
                        />
                        {/* {listPos}
                        </Select> */}
                    </FormItem>

                    <FormItem label={this.getText(12612)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        {/* {this.createCheckBox('_SupplierList', Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                        <Select key={"_SupplierList"} style={{ width: '80%' }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4}
                            onChange={(value) => {
                                let r = this.state.request;
                                this.setState({ request: { ...r, _SupplierList: value.join(',') } });
                            }}
                            value={(this.state.request._SupplierList === '') ? [] : this.state.request._SupplierList.split(',')}
                        >
                            {listSupplier}
                        </Select> */}
                        <GenericSelectorSuppliers
                            {...this.props} multi
                            onChange={(value) => {
                                let r = this.state.request;
                                this.setState({ request: { ...r, _SupplierList: value.join(',') } });
                            }}
                            value={(this.state.request._SupplierList === '') ? [] : this.state.request._SupplierList.split(',')}
                        />
                    </FormItem>


                    <FormItem label={this.getText(11866)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        <DatePicker
                            // disabledDate={this.disabledStartDate}
                            // showTime
                            disabledDate={d => disabledDatePicker(d, null, _ToDate)}
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            value={_FromDate ? moment(_FromDate, "YYYY-MM-DD") : null}
                            placeholder="Start"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                        />
                    </FormItem>
                    <FormItem label={this.getText(11867)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        <DatePicker
                            // disabledDate={this.disabledEndDate}
                            // showTime
                            disabledDate={d => disabledDatePicker(d, _FromDate, null)}
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            value={_ToDate ? moment(_ToDate, "YYYY-MM-DD") : null}
                            placeholder="End"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                        />
                    </FormItem>

                    <FormItem label={this.getText(16232)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        <Radio.Group value={this.state.request._typeID} onChange={(value) => {
                            let r = this.state.request;
                            this.setState({ request: { ...r, _typeID: value.target.value } });
                        }}>
                            <Radio value={''}>{this.getText(16233)}</Radio>
                            <Radio value={'4'}>{this.getText(16234)}</Radio>
                            <Radio value={'18'}>{this.getText(16235)}</Radio>
                        </Radio.Group>

                        <Radio.Group value={this.state.request._isHandled} onChange={(value) => {
                            let r = this.state.request;
                            this.setState({ request: { ...r, _isHandled: value.target.value } });
                        }}>
                            <Radio value={'-1'}>{this.getText(16233)}</Radio>
                            <Radio value={'0'}>{this.getText(19412)}</Radio>
                            <Radio value={'1'}>{this.getText(19413)}</Radio>
                        </Radio.Group>

                    </FormItem>

                </Form >
                <div className="action_footer">
                    <Button key="back" onClick={this.handleCancel}>{this.getText(11870)}</Button>
                    <Button key="clear" onClick={this.handleReset}>{this.getText(11871)}</Button>
                    <Button form="search_working_hours_form" key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(11872)}</Button>
                </div>
            </Modal >
        )
    }

    // bind input field
    onChangeInput = (e) => {
        //this.props.dataActions.genericDataSetFilter(
        //    'ITEMS_TABLE', this.props.user.companyCode, this.props.user.token, { _words: e.target.value }, this.api.get)
        let r = { ...this.state.request };
        r._words = e.target.value;
        this.setState({ ...this.state, request: r });
    }

    handleFilterBlur = (e) => {
        //this.props.dataActions.genericDataSetFilter(
        //    'ITEMS_TABLE', this.props.user.companyCode, this.props.user.token, { _words: e.target.value }, this.api.get)
        let r = { ...this.state.request };
        r._words = e.target.value;
        this.setState({ ...this.state, request: r });
    }

    // tag select
    handleTagSelectChange = (e) => {
        //this.props.dataActions.genericDataSetFilter(
        //   'ITEMS_TABLE', this.props.user.companyCode, this.props.user.token, { _tagList: e.join(',') }, this.api.get)
        let r = { ...this.state.request };
        r._tagList = e.join(',');
        this.setState({ ...this.state, request: r });

    }
    /// search
    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log('Received values of form: ', values);
        });
    }

    handleReset = () => {
        //this.props.form.resetFields();
        let _request = {
            _words: '',
            _FromDate: null,
            _ToDate: null,
            _PosList: '',
            _SupplierList: '',
            _typeID: '',
            _isHandled: '-1',
        };
        this.setState({ request: _request },
            () => {
                this.props.dataActions.genericDataSetFilter(
                    'PURCHES_DOCS_MAIN', this.props.user.companyCode, this.props.user.token, _request, this.api.get);
            }
        );
    }
}

export default ModalPurchaseAdvanceSearch;


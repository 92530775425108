/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";
import { Form, Input, Button } from "antd";
import TableIDs from '../../data/TableIDs';


class DocumentRemarks extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            filters: {
                _posList: '',
                _storageList: '',
            },
            modalFilter: false,

            filterActive: false,
            valueSearch: '',

        };

        this.id = "DOCUMENT_REMARKS_TABLE";

        this.api = {
            get: "get_document_remarks_table",
            create_edit: "create_edit_document_remarks_table",
            delete: "delete_document_remarks_table",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorPos, api: "list_pos" },
            { id: TableIDs.genericSelectorDocumentTypes, api: "get_document_types_list" },
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.forEach(x => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16012);
    }

    renderSelector = (text, record, field) => {
        let isEdit = this.props.data[this.id].editing == record.key;
        let editedRecord = this.props.data[this.id].editedRecord;

        return (<GenericSelector
            {...this.props}
            id={this.selectors[field == 'kPos' ? 0 : 1].id}
            disabled={!isEdit}
            value={isEdit ? editedRecord[field] : text}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }}
            addFirstRow={field == 'kPos' ? { code: '0', name: this.getText(16018) } : null}
        />)
    }

    renderText = (text, record) => {
        let isEdit = this.props.data[this.id].editing == record.key;
        let editedRecord = this.props.data[this.id].editedRecord;
        return isEdit ? (
            <Input.TextArea
                value={editedRecord.kText}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { kText: e.target.value }) }}
                rows={3} />) : text;
    }

    editRow = (text, record) => {
        if (this.props.data[this.id].editing !== record.key) {
            return (<TableLineIcons
                onEdit={() => {
                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}
                deleteQuetion={{ title: this.getText(11821), okText: this.getText(11822), cancelText: this.getText(11823) }}
            />)
        } else {
            let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get);
                }}
                onClose={() => {
                    if (isCreate) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }}
            />)
        }
    }

    filterSelector = (label, index, stateName) => {

        return (<Form.Item label={this.getText(label)}>
            <GenericSelector
                {...this.props}
                {...this.selectors[index]}
                value={this.state.filters[stateName] ? this.state.filters[stateName].split(',') : []}
                onChange={e => { this.setState({ filters: { ...this.state.filters, [stateName]: e ? e.join(',') : '' } }) }}
                multi
            />
        </Form.Item>)
    }

    searchFilter = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode,
            this.props.user.token,
            { ...this.state.filters, page: 1, _page_num: 1 },
            this.api.get);

        this.setState({ modalFilter: false, filterActive: this.state.filters._posList || this.state.filters._storageList || this.state.valueSearch })

    }

    resetFilter = () => {
        this.setState({
            filters: { _posList: "", _storageList: "" },
            valueSearch: "",
            filterActive: false,
        }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode,
                this.props.user.token,
                { ...this.state.filters, page: 1, _page_num: 1, _posList: "", _storageList: "", _words: "" },
                this.api.get);
        })
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            maxWidth: 1200,
            margin: 'auto'
        };

        let columns = [
            { title: gt(16014), key: 'kPos', dataIndex: 'kPos', sorter: true, width: '25%', render: (t, r) => this.renderSelector(t, r, 'kPos') },
            { title: gt(16015), key: 'kType', dataIndex: 'kType', sorter: true, width: '25%', render: (t, r) => this.renderSelector(t, r, 'kType') },
            { title: gt(16016), key: 'kText', dataIndex: 'kText', sorter: true, width: '40%', render: this.renderText },
            { title: gt(16017), width: '10%', render: this.editRow },
        ];

        let actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) }
            }, {
                buttonType: 'filter',
                buttonFunction: () => { this.setState({ modalFilter: true }) }
            }, {
                buttonDisabled: !this.state.filterActive,
                buttonType: 'clear',
                buttonFunction: this.resetFilter,
            }
        ]

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16013)}
                columns={columns}
                actionButtons={actionButtons}
                searchBox
                valueSearch={this.state.valueSearch}
                onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                setFilterActive={e => { this.setState({ filterActive: e }) }}
                filters={this.state.filters}
            />
            <GenericModal
                title={gt(16504)}
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                width={400}
                footer={[
                    <Button type='primary' onClick={this.searchFilter}>{gt(16507)}</Button>,
                    <Button onClick={this.resetFilter}>{gt(16508)}</Button>,
                    <Button onClick={() => { this.setState({ modalFilter: false }) }}>{gt(16509)}</Button>,
                ]}>
                {this.filterSelector(16505, 0, '_posList')}
                {this.filterSelector(16506, 2, '_storageList')}
            </GenericModal>
        </div>)
    }

}

export default DocumentRemarks;
/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import { Radio } from "antd";


class GenericSwitchRadio extends Component {


    render() {
        return (<Radio.Group value={this.props.checked ? "1" : "0"} onChange={e => { this.props.onChange(e.target.value == '1') }}>
            <Radio value="1">{this.props.checkedChildren}</Radio>
            <Radio value="0">{this.props.unCheckedChildren}</Radio>
        </Radio.Group>)
    }


}

export default GenericSwitchRadio;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import {
    Card, //Col, Row, Button, 
    Form, Select, Checkbox, InputNumber
} from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import GenericSelector from "../../components/GenericSelector";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const FormItem = Form.Item;
const Option = Select.Option;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesComparison extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            SalesComparison: {
                mRequestedReport: '0',
                mLowerSelectionDate: null,
                mLowerSelectionTime: "00:00:00",
                mUpperSelectionDate: null,
                mUpperSelectionTime: "23:59:59",
                mPosList: '',
                mApplyRangeApply: '0',
                mApplyRangeLower: 0,
                mApplyRangeUpper: 0,
                mApplySumApply: '0',
                mApplySumLower: 0,
                mApplySumUpper: 0,
                mDaysOfWeek: '1,2,3,4,5,6,7',
                mDayCompare: '1',
                mWeekCompare: '1',
                mMonthCompare: '1',
                mDocTypeList: '',
                reportName: 'reports/salesComparison.pdf'
            }
        };
        this.state.type = "pdf";

        this.selectors = [
            { id: TableIDs.genericSelectorPos, api: 'list_pos', multi: true },
            { id: 'DOC_TYPE_FOR_SALES_REPORT_LIST', api: 'get_doc_type_for_sales_report_list', multi: true },
        ];

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        //this.setReportParameters("SalesComparison", { mRequestedReport: 0 });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        // let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.mPosList === '') params = { ...params, mPosList: fullPosList }
        // if (params.mStorageList === '') params = { ...params, mStorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10197);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        // let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            posList && posList.dataset[0]
            // && 
            // storagesList && storagesList.dataset[0] 
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            let type = this.props.ui.favoriteParams.reportName.split('.')[1];
            this.setState({ type }, () => {

                this.setReportParameters("SalesComparison", this.props.ui.favoriteParams)
            })
        }
    }


    disabledStartDate = (FromDate) => {
        const ToDate = this.state.SalesComparison.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.SalesComparison.mUpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("SalesComparison", { [field]: data });
                    else
                        this.setReportParameters("SalesComparison", { [field]: '' });
                }} />);
    }

    onChange = (field, value) => {
        this.setReportParameters("SalesComparison", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/

    render() {
        const { SalesComparison, endOpen } = this.state;
        const { mLowerSelectionDate, mLowerSelectionTime, mUpperSelectionDate, mUpperSelectionTime } = SalesComparison;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { SalesComparison } = this.state;

        let isEndDateShown = (SalesComparison.mRequestedReport == 3 || SalesComparison.mRequestedReport == 4)

        const isDayShownStyle = { display: (SalesComparison.mRequestedReport == 3) ? 'block' : 'none' }
        const isRangeShownStyle = { display: (SalesComparison.mRequestedReport == 4) ? 'block' : 'none' }
        const isSumShownStyle = { display: (SalesComparison.mRequestedReport != 3) ? 'block' : 'none' }
        const isDayCompareShownStyle = { display: (SalesComparison.mRequestedReport == 0) ? 'block' : 'none' }
        const isWeekCompareShownStyle = { display: (SalesComparison.mRequestedReport == 1) ? 'block' : 'none' }
        const isMonthCompareShownStyle = { display: (SalesComparison.mRequestedReport == 2) ? 'block' : 'none' }
        const isEndDateShownStyle = { display: isEndDateShown ? 'block' : 'none' }

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        //this.setReportParameters("SalesComparison", {
        //    mPosList: this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',')
        //});

        let mobile = window.innerWidth > 600 ? false : true;

        let datesToFavorites = [{ field: 'mLowerSelectionDate', label: this.getText(11977) }];
        if (isEndDateShown) datesToFavorites.push({ field: 'mUpperSelectionDate', label: this.getText(11980) });

        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(11973)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '80%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(11974)}>
                            <Select key={"mRequestedReport"} style={{ width: 220 }}
                                //size={"large"}
                                //className={""}
                                //combobox={null}
                                value={SalesComparison.mRequestedReport}
                                onChange={
                                    (value) => {
                                        this.setReportParameters("SalesComparison",
                                            { mRequestedReport: value, reportName: this.reportNameBuilder(value) });
                                    }
                                }
                            >
                                <Option key={1} value={'0'}>{this.getText(11989)}</Option>
                                <Option key={2} value={'1'}>{this.getText(11990)}</Option>
                                <Option key={3} value={'2'}>{this.getText(11991)}</Option>
                                <Option key={4} value={'3'}>{this.getText(11992)}</Option>
                                <Option key={5} value={'4'}>{this.getText(11993)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(11975)}>
                            <GenericSelectorPosStorages
                                {...this.props}
                                // {...this.selectors[0]}
                                multi
                                value={SalesComparison.mPosList ? SalesComparison.mPosList.split(',') : []}
                                onChange={(value) => { this.setReportParameters("SalesComparison", { mPosList: value ? value.join(',') : '' }) }} />

                        </FormItem>

                        <FormItem label={this.getText(15904)}>
                            <GenericSelector
                                {...this.props}
                                {...this.selectors[1]}
                                value={SalesComparison.mDocTypeList ? SalesComparison.mDocTypeList.split(',') : []}
                                onChange={(value) => { this.setReportParameters("SalesComparison", { mDocTypeList: value ? value.join(',') : '' }) }} />
                        </FormItem>

                        <FormItem label={this.getText(11976)} style={isDayShownStyle}>
                            <Checkbox
                                style={{ display: 'inline' }}
                                checked={SalesComparison.mDaysOfWeek.length > 0}
                                onChange={(e) => { this.setReportParameters("SalesComparison", { mDaysOfWeek: e.target.checked ? '1,2,3,4,5,6,7' : '' }) }}
                            />
                            <Select key={"DaysOfWeek"} style={{ width: 220 }}
                                mode="multiple"
                                //placeholder="Please select"
                                //size={"large"}
                                //className={""}
                                //combobox={null}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mDaysOfWeek: value ? value.join(',') : '' })}
                                value={SalesComparison.mDaysOfWeek ? SalesComparison.mDaysOfWeek.split(',') : []}
                            >
                                <Option key={1} value={'1'}>{this.getText(11994)}</Option>
                                <Option key={2} value={'2'}>{this.getText(11995)}</Option>
                                <Option key={3} value={'3'}>{this.getText(11996)}</Option>
                                <Option key={4} value={'4'}>{this.getText(11997)}</Option>
                                <Option key={5} value={'5'}>{this.getText(11998)}</Option>
                                <Option key={6} value={'6'}>{this.getText(11999)}</Option>
                                <Option key={7} value={'7'}>{this.getText(12000)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(11977)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                            <DatePicker
                                disabledDate={d => disabledDatePicker(d, null, SalesComparison.mUpperSelectionDate)}
                                //  disabledDate={this.disabledStartDate}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={SalesComparison.mLowerSelectionDate ? moment(SalesComparison.mLowerSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12001)}
                                onChange={this.onStartChange}
                            //onOpenChange={this.handleStartOpenChange}
                            />
                        </FormItem>
                        <FormItem label={this.getText(11980)} style={isEndDateShownStyle}>
                            <DatePicker
                                disabledDate={d => disabledDatePicker(d, SalesComparison.mLowerSelectionDate, null)}
                                // disabledDate={this.disabledEndDate}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={SalesComparison.mUpperSelectionDate ? moment(SalesComparison.mUpperSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12002)}
                                onChange={this.onEndChange}
                            //open={endOpen}
                            //onOpenChange={this.handleEndOpenChange}
                            />
                        </FormItem>
                        <FormItem label={this.getText(11978)} style={isRangeShownStyle}>
                            <Checkbox
                                //onChange={this.handleCheckBoxRange}
                                //ApplyRange_Apply
                                checked={(SalesComparison.mApplyRangeApply == 1) ? true : false}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mApplyRangeApply: value.target.checked ? 1 : 0 })}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Lower
                                value={SalesComparison.mApplyRangeLower}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mApplyRangeLower: value })}
                                disabled={this.state.SalesComparison.mApplyRangeApply != 1}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Upper
                                value={SalesComparison.mApplyRangeUpper}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mApplyRangeUpper: value })}
                                disabled={this.state.SalesComparison.mApplyRangeApply != 1}
                                style={{ marginLeft: '4px' }}

                            />
                        </FormItem>
                        <FormItem label={this.getText(11979)} style={isSumShownStyle}>
                            <Checkbox onChange={this.handleCheckBoxSum}
                                //ApplySum_Apply
                                //checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                                checked={(SalesComparison.mApplySumApply == 1) ? true : false}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mApplySumApply: value.target.checked ? 1 : 0 })}
                            />
                            <InputNumber
                                //ApplySum_Lower
                                //value={}
                                value={SalesComparison.mApplySumLower}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mApplySumLower: value })}
                                disabled={this.state.SalesComparison.mApplySumApply != 1}
                            />
                            <InputNumber
                                //ApplySum_Upper
                                //value={}
                                value={SalesComparison.mApplySumUpper}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mApplySumUpper: value })}
                                disabled={this.state.SalesComparison.mApplySumApply != 1}
                            />

                        </FormItem>


                        <FormItem key={"DayCompare"} label={this.getText(11981)} style={isDayCompareShownStyle}>
                            <InputNumber
                                min={1} max={200} precision={0}
                                //style={}
                                //value={}
                                value={SalesComparison.mDayCompare}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mDayCompare: (!value) ? 1 : value })}
                            />
                        </FormItem>
                        <FormItem key={"WeekCompare"} label={this.getText(11982)} style={isWeekCompareShownStyle}>
                            <InputNumber
                                min={1} max={200} precision={0}
                                //style={}
                                //value={}
                                value={SalesComparison.mWeekCompare}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mWeekCompare: (!value) ? 1 : value })}
                            />
                        </FormItem>
                        <FormItem key={"MonthCompare"} label={this.getText(11983)} style={isMonthCompareShownStyle}>
                            <InputNumber
                                min={1} max={200} precision={0}
                                //style={}
                                //value={}
                                value={SalesComparison.mMonthCompare}
                                onChange={(value) => this.setReportParameters("SalesComparison", { mMonthCompare: (!value) ? 1 : value })
                                }
                            />
                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.SalesComparison}
                            posField={'mPosList'}
                            datesToFavorites={datesToFavorites}
                            favoriteSave
                        />

                        {/* <FormItem key={"reportName"} label={this.getText(11984)} >
                            <Select style={{ width: 220 }}
                                //size={"large"}
                                className={"reports/SalesComparison.pdf"}
                                defaultValue={"pdf"}
                                //combobox={null}
                                //defaultValue={"reports/SalesComparison.pdf"}
                                onChange={(value) => {
                                    //const type = this.state.SalesComparison.mRequestedReport;
                                    //console.log(type);
                                    //const str = (type === 0) ? '' : type.toString();
                                    //console.log(value,str);
                                    //this.setReportParameters("SalesComparison", { reportName: "reports/salesComparison" + str + ".pdf"})}
                                    this.setState({ ...this.state, type: value },
                                        () => {
                                            this.setReportParameters("SalesComparison",
                                                { reportName: this.reportNameBuilder() });
                                        }
                                    );
                                }}
                            >
                                <Option key={"pdf"}>{this.getText(11985)} </Option>
                                <Option key={"xls"}>{this.getText(11986)} </Option>
                                <Option key={"docx"}>{this.getText(11987)} </Option>
                            </Select>
                        </FormItem>
                        <Button type={"primary"} onClick={() => {
                            this.generateReport.apply(this, ["SalesComparison"])
                        }}>{this.getText(11988)} </Button> */}
                    </Form>
                </Card>
            </div>
        );
    }

    reportNameBuilder = (_type) => {
        const type = (_type || _type === 0) ? _type : this.state.SalesComparison.mRequestedReport;   //console.log("report", type);
        const str = (type == 0) ? '' : type;            //console.log("report str", str);
        console.table({
            type: type,
            str: str
        })

        return "reports/salesComparison" + str + "." + this.state.type;
    }
}
export default SalesComparison;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import LangContext from "../contextProvider/LangContext";
import { message, Button } from "antd";

class FormReportDownload extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.id = "FORM_REPORT_DOWNLOAD";
        this.api = {
            get_url: "get_report_hash_link",
            get_task_url: "get_report_task_link",
        }
        this.formType = this.props.formType;
        this.forms = this.props.forms;
        if (props.forms[this.id] === undefined) props.forms[this.id] = {}
    }

    static contextType = LangContext;

    getText = (id) => {
        let data = this.props.forms.langTexts;
        return data && data["t" + id] ? data["t" + id] : '[' + id + ']'
    }

    componentDidMount() {
        this.set_timer();
    }

    downloadFile = (data, fileName) => {
        const a = document.createElement("a");
        if (document.body) { document.body.appendChild(a); }
        a.style.display = "none";
        //return function (data, fileName) {
        let url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        //};
    };

    set_timer = () => {
        const TIME = 3000;
        const formsFunctions = this.forms.formsFunctions;
        const paramsDb = this.forms.formData.params;
        const params = this.forms.formsFunctions.getParams();
        const formType = this.formType;

        console.log("paramsDb", paramsDb);
        console.log("params", params);

        setTimeout(() => {
            const succ_msg = () => { message.success(this.getText(18810), 6) }
            const fail_msg = () => { message.error(this.getText(18809), 6) }
            const fetch_report = (url) => {
                fetch(url, {
                    method: "POST",
                    cache: "no-cache",
                    mode: "no-cors",
                })
                    .then(i => { console.log(i); return i.blob() })
                    .then(i => this.downloadFile(i, paramsDb + '.pdf'))
                    .then(i => { succ_msg() });
            }

            let url = (formType == "13") ? this.api.get_url : this.api.get_task_url

            formsFunctions.sendAPI(url /*this.api.get_url*/, 'hash\r' + paramsDb,
                (ob) => {
                    fetch_report(ob.data);
                },
                (e) => {
                    console.error(e);
                    fail_msg();
                })
        }, TIME)

    }

    render() {
        return (
            <div style={{ padding: 16, fontSize: 24 }}>{this.getText(18811)}</div>
        )
    }
}
export default FormReportDownload;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
import { Checkbox, Tooltip, Icon } from "antd";
import ModalDocumentLines from "./ModalDocumentLines";
import moment from "moment";

class ContentDocsCentral extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalLines: null,
        }

        this.id = "CREATE_CENTRAL_DOCUMENT";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        const { customerCode, storageId } = this.props.data.document2;
        let cID = (this.props.documentType == 45051) ? storageId : customerCode
        if (cID) this.initFilter(cID);

    }

    componentDidUpdate(prev) {
        const { remarks } = this.props.data.document2;
        const prevRemarks = prev.data.document2.remarks;

        console.log("customerRecord-444", prevRemarks, remarks)
        if (remarks && prevRemarks != remarks && remarks.indexOf('<jsonData>') > -1) {
            console.log("customerRecord-555", prevRemarks, remarks)
            const { data } = this.props.data[this.id];

            let split1 = remarks.split('<jsonData>');
            let split2 = split1[1].split('</jsonData>');
            if (split2[0]) {
                let dataChecked = JSON.parse(split2[0]);
                let prevDataChecked = data.filter(x => {
                    return dataChecked.find(f => f.DocNum == x.DocNum && f.DocTypeId == x.DocTypeId && f.PosId == x.PosId) && x.isChecked == '1';
                })
                if (dataChecked.length != prevDataChecked.length)
                    this.props.dataActions.setJson(this.id, {
                        data: data.map(x => {
                            let isChecked = dataChecked.find(f => f.DocNum == x.DocNum && f.DocTypeId == x.DocTypeId && f.PosId == x.PosId) ? true : false;
                            return { ...x, isChecked }
                        })
                    })
            }
        }

        if (prev.PrintFormat !== this.props.PrintFormat) {
            this.updateRemark(this.props.PrintFormat)
        }
    }

    initFilter = (cID) => {
        let PrintFormat = this.props.PrintFormat // this.props.data.document2.CustomerSumDocPrintFormat != '0';

        let parseData = this.props.parseCentralDocJson();

        if (parseData && parseData[0] && parseData[0].PrintFormat) {
            PrintFormat = parseData[0].PrintFormat;
        }

        this.props.setRootState({ PrintFormat })


        let typeId = this.props.documentType == 45051 ?'4': this.props.documentType == 40418 ? '16' : '5'

        this.props.sendAPI('get_docs_to_central', 'cID\ftypeId\r' + cID + '\f' + typeId, (ob) => {
            if (ob.data) {
                let res = ob.data.split('\r');
                let headers = res[0].split('\f');
                let count = res.slice(-1);
                let data = res.slice(1, -1).map((x, i) => {
                    let y = x.split('\f');
                    let res = { key: y[0], index: i, isChecked: parseData.findIndex(f => f.DocNum === y[0]) > -1 }
                    headers.forEach((z, zi) => { res = { ...res, [z]: y[zi] } });
                    return res;
                });
                this.props.dataActions.setJson(this.id, { data, headers, count });
            }
        });
    }

    updateRemark = (PrintFormat) => {
        setTimeout(() => {
            let remarkValue = this.props.data.document2.remarks;
            if (remarkValue && remarkValue.indexOf('<jsonData>') > -1) {
                let split1 = remarkValue.split('<jsonData>');
                let split2 = split1[1].split('</jsonData>');
                remarkValue = split1[0] + split2[1];
            }

            let data = this.props.data[this.id].data;

            let dataToJson = data.filter(f => f.isChecked).map(x => {
                return {
                    DocNum: x.DocNum,
                    DocTypeId: x.DocTypeId,
                    PosId: x.PosId,
                    DocSum: x.DocSum,
                    PrintFormat
                }
            });

            let res = '';
            if (dataToJson.length) res += '<jsonData>' + JSON.stringify(dataToJson) + '</jsonData>';
            if (remarkValue) res += remarkValue;

            this.props.dataActions.setJson('document2', { remarks: res });

        }, 1000);
    }

    getCb = (text, record) => {
        let data = [...this.props.data[this.id].data];
        let checked = record ? record.isChecked : data.length > 0;
        let onChange = (e) => {
            data = data.map(x => { return !record || x.DocNum === record.DocNum ? { ...x, isChecked: e.target.checked } : x });

            this.props.dataActions.setJson(this.id, { data });
            this.updateRemark(this.props.PrintFormat);
        }

        if (!record) data.forEach(x => { if (!x.isChecked) checked = false })
        return (<Checkbox checked={checked} onChange={onChange} />);
    }

    // changePrintFormat = (checked) => {
    //     this.props.setRootState({ PrintFormat: checked }, () => { this.updateRemark(checked ? 1 : 0) })
    // }

    linesViewIcon = (text, record) => {
        return (<Tooltip title={this.getText(17083)}>
            <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="eye" theme="outlined" onClick={() => { this.setState({ modalLines: record }) }} />
        </Tooltip>)
    }

    getFormatDatetime = (datetime) => {
        return <div style={{ direction: "ltr", textAlign: "right" }}>{moment(datetime).format("DD-MM-YYYY HH:mm")}</div>
    }

    getFormatSum = (sum) => {
        return <div style={{ direction: "ltr", textAlign: "right" }}>{(Math.round(parseFloat(sum ? sum : 0) * 100) / 100).toFixed(2)}</div>
    }

    render() {
        const { customerCode, storageId } = this.props.data.document2;
        let cID = this.props.documentType == 45051 ? storageId : customerCode

        let columns = [
            { title: this.getCb(), width: '5%', render: this.getCb },
            { title: this.getText(15432), key: 'DocNum', dataIndex: 'DocNum', width: '14%', render: t => t },
            { title: this.getText(15433), key: 'DocTypeName', dataIndex: 'DocTypeName', width: '14%', render: t => t },
            { title: this.getText(15434), key: 'PosName', dataIndex: 'PosName', width: '20%', render: t => t },
            { title: this.getText(15435), key: 'DocTimestamp', dataIndex: 'DocTimestamp', width: '14%', render: this.getFormatDatetime },
            { title: this.getText(15437), key: 'DocSum', dataIndex: 'DocSum', width: '14%', render: this.getFormatSum },
            { title: this.getText(15436), key: 'DocTax', dataIndex: 'DocTax', width: '14%', render: this.getFormatSum },
            { title: this.getText(18636), width: '5%', render: this.linesViewIcon },
        ]

        console.log('sorter test ', this.state.modalLines)

        return (<div dir="rtl">
            {!parseInt(cID) ? (<h4>{this.getText(this.props.documentType == 45051 ? 17117 : this.props.documentType == 40418? 19839: 15431)}</h4>)
                : (<span>
                    {/* <h4 style={{ display: isType4 ? "none" : "block" }}>
                        {this.getText(15438)}:
                        <Radio.Group
                            style={{ marginRight: 10 }}
                            onChange={e => { this.changePrintFormat(e.target.value == '1') }}
                            value={this.state.PrintFormat ? "1" : "0"}
                        >
                            <Radio value={"1"}>{this.getText(15439)}</Radio>
                            <Radio value={"0"}>{this.getText(15440)}</Radio>
                        </Radio.Group>
                    </h4> */}
                    <ResponsiveTable
                        tableOnly
                        dataSource={this.props.data[this.id].data}
                        columns={columns}
                        percentageToMobile={[30, 30, 40]}
                    />
                    <ModalDocumentLines
                        user={this.props.user}
                        visible={this.state.modalLines !== null ? true : false}
                        onCancel={() => { this.setState({ modalLines: null }) }}
                        DocumentNumber={this.state.modalLines ? this.state.modalLines.DocNum : 0}
                        DocumentPOSID={this.state.modalLines ? this.state.modalLines.PosId : 0}
                        DocumentTypeId={this.state.modalLines ? this.state.modalLines.DocTypeId : 0}
                        docTitles={this.state.modalLines ?
                            columns.slice(1, -1).map(x => {
                                return { label: x.title, value: x.render(this.state.modalLines[x.dataIndex], this.state.modalLines) }
                            })
                            : []}
                    />
                </span>)}
        </div>)

    }
}

export default ContentDocsCentral;
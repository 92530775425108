/* eslint-disable */
import * as React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Table, Menu, Dropdown, Input, Icon, Button, Popconfirm, Tooltip, Modal, Checkbox } from 'antd';
import Paging from "./Paging";
import MenuItem from "antd/lib/menu/MenuItem";
import TableIDs from '../data/TableIDs.js';
import SelectorByID from './SelectorByID';
import { Record } from "immutable";


const styleTable: any = require('./ExtendedTable.css');

class ExtendedStaticTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [], // todo run api
            //dataSource: (this.props.dataSource) ? this.props.dataSource : [] , // todo run api

            isEditRow: -1,
            isCreateRow: 0,

            sorting: {
                col: 'col_1',    // col_1 = id // col_2 = 
                order: 0,        // 0 - desend // 1 - asend
            },
            pagination: {
                //showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} pages`,
                current: 1,
                defaultCurrent: 1,
                defaultPageSize: 5,
                total: 0,
                hideOnSinglePage: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${total} סה"כ רשומות`,
            },
        }
        this.keyIndexer = 1;
        this.edit_line_tmp = [];
        this.columns = this.props.columns ? this.props.columns : [];
        this.generateColumns(this.columns);
    }

    addActionBtns = (view, colFormat, record) => {
        let icon;
        for (let action of colFormat.actions) {
            if (action.actionType === 'delete') {
                icon = (this.props.disabled) ? (
                    <Icon style={{ fontSize: 16, padding: 4 }} type="delete" />
                ) : (this.state.dataSource.length > 0) ?
                        (
                            <Popconfirm title="בטוח ברצונך למחוק?" onConfirm={
                                () => {
                                    let key = record.key;
                                    let s = { ...this.state };
                                    this.setState({ dataSource: s.dataSource.filter(item => item.key !== key) }, ...s);
                                }
                            }>
                                <Icon style={{ fontSize: 16, padding: 4 }} type="delete" />
                            </Popconfirm>
                        ) : null;
            }
            if (action.actionType === 'edit') {
                icon = (this.props.disabled) ?
                    (
                        <Icon style={{ fontSize: 16, padding: 4 }} type="edit" />
                    ) : (this.state.dataSource.length > 0) ?
                        (
                            <span onClick={() => {
                                let s = Object.assign({}, this.state);
                                s.isEditRow = record.key;
                                //this.edit_line_tmp = Object.values(record);
                                this.edit_line_tmp = { ...record };
                                this.setState(s);
                            }}>
                                <Icon style={{ fontSize: 16, padding: 4 }} type="edit" />
                            </span>
                        ) : null;
            }
            view.push(icon);
        }
    }

    generateColumns = (_columns: any[]) => {
        let index = 1;
        for (let colFormat of this.props.tableFormat) {
            let col = {};

            col.dataIndex = 'col_' + index;
            col.title = colFormat.title;
            col.width = colFormat.width;
            if (colFormat.sorter) {
                defaultSortOrder: 'descend',
                    col.sorter = true; //(a, b) => 0;   // TODO chk api to disable
            }
            if (colFormat.editable) {
                col.render = (text, record) => {
                    return (<EditableCell
                        value={text}
                        onChange={this.onCellChange(record.key, col.dataIndex)}
                        onUpdate={this.update_edit_line_tmp}
                        tableID={this.props.id}
                        position={{ key: record.key, col: col.dataIndex }}
                        isEditRow={this.state.isEditRow === record.key ||
                            (this.state.isCreateRow == 1 && record.key == this.keyIndexer)}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                    />)
                }
            }
            if (colFormat.editableNumeric) {
                col.render = (text, record) => {
                    return (<EditableCell
                        value={text}
                        onChange={this.onCellChange(record.key, col.dataIndex)}
                        onUpdate={this.update_edit_line_tmp}
                        tableID={this.props.id}
                        position={{ key: record.key, col: col.dataIndex }}
                        isEditRow={this.state.isEditRow === record.key ||
                            (this.state.isCreateRow == 1 && record.key == this.keyIndexer)}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                        isNumeric={true}
                    />)
                }
            }
            if (colFormat.isCheckBox) {
                col.render = (text, record) => {
                    let isDisabled = !(colFormat.editable
                        && ((this.state.isEditRow == record.key)
                            || (this.state.isCreateRow == 1 && record.key == this.keyIndexer)));
                    return (
                        <ExtendedCheckbox2
                            position={{ row: record.key, col: col.dataIndex }}
                            onUpdate={this.update_edit_line_tmp}
                            defaultChecked={record[col.dataIndex] === '1' || record[col.dataIndex] == 1}
                            disabled={isDisabled || this.props.disabled}
                        />
                    )

                }
            }
            if (colFormat.isDropSelect) {
                col.render = (text, record) => {
                    // TODO: add in table drop selector 
                    console.log("selecor by id: ", colFormat.isDropSelect);

                    let isDisabled = !(this.state.isEditRow === record.key ||
                        (this.state.isCreateRow == 1 && record.key == this.keyIndexer));

                    return (
                        <SelectorByID
                            id={colFormat.isDropSelect}
                            user={this.props.user}
                            onSelect={(e) => { this.handleOnSelect(record.key, col.dataIndex, e) }}
                            value={this.state.dataSource.find(i => i.key == record.key)[col.dataIndex]}
                            //value={this.state.formState.saleAlert}
                            title={false}
                            ui={this.props.ui}
                            uiActions={this.props.uiActions}
                            disabled={isDisabled}
                            filter={(item) => !this.state.dataSource.map((item) => {item[col.dataIndex]}).includes(item.code)}
                        />
                    )
                }
            }
            if (colFormat.actions) {
                col.render = (text, record) => {
                    let view = [];
                    this.addActionBtns(view, colFormat, record);
                    if (this.state.isEditRow == record.key && this.state.isCreateRow == 0) {
                        console.log("edit line " + record.key);
                        //console.log("indexer" + this.keyIndexer);
                        return (
                            <div>
                                <span
                                    onClick={() => {
                                        //this.onEdit(record);
                                        let s = { ...this.state };
                                        let target = s.dataSource.find(item => {
                                            //console.log("TEST: ", item.key, "  ", record.key);
                                            return item.key === record.key
                                        });
                                        if (target) {
                                            target = { ...this.edit_line_tmp };
                                        }
                                        if (target) {
                                            s.dataSource = s.dataSource.map(item => {
                                                if (item.key === record.key)
                                                    return { key: item.key, ...target };
                                                else
                                                    return item;
                                            }
                                            )
                                        }
                                        //console.log("state: ", this.state);
                                        //console.log("s: ", s);
                                        //console.log("target: ", target);

                                        s.isEditRow = -1;
                                        s.isCreateRow = 0;
                                        this.edit_line_tmp = [];
                                        this.setState(s);
                                    }}>
                                    <Icon style={{ fontSize: 16, padding: 4 }} type="check" />
                                </span>
                                {this.closeEditLine(record.key)}
                            </div>
                        )
                    }
                    else if (this.state.isCreateRow == 1 && record.key == this.keyIndexer) {
                        //console.log("create line " + record.key);
                        //console.log("indexer" + this.keyIndexer);
                        return (
                            <div>
                                <span
                                    onClick={() => {
                                        console.log("check create", record.key);
                                        let s = { ...this.state };
                                        //console.log("state: ", this.state);
                                        let target = s.dataSource.find(item => item.key === this.keyIndexer);
                                        if (target) {
                                            target = { ...this.edit_line_tmp };
                                        }
                                        if (target) {
                                            s.dataSource = s.dataSource.map(item => {
                                                if (item.key === record.key)
                                                    return { key: item.key, ...target };
                                                else
                                                    return item;
                                            }
                                            )
                                        }
                                        //this.props.onChange({ ...s.dataSource }); // ## TODO: ###
                                        s.isEditRow = -1;
                                        s.isCreateRow = 0;
                                        this.edit_line_tmp = [];
                                        this.setState(s);
                                        this.keyIndexer++;
                                        //console.log("key indexer: ", this.keyIndexer);
                                        //console.log("key indexer: ", this.keyIndexer);
                                    }}>
                                    <Icon style={{ fontSize: 16, padding: 4 }} type="check" />
                                </span>
                                {this.closeEditLine(record.key)}
                            </div>
                        )
                    }
                    else {
                        //console.log("failed to catch conditions","falied line " + record.key, "indexer" + this.keyIndexer);
                        return (<div>{view}</div>);
                    }
                }
            }
            index++;
            _columns.push(col);
        }
    }

    // componentWillReceiveProps(newProps) {
    //     if (newProps.ui.getCompsData) {
    //         if (newProps.ui.getCompsData !== this.props.ui.getCompsData) {
    //             if (newProps.ui.getCompsData.compsID.includes(this.props.id)) { // is it for me?
    //                 //console.log("EEE before send comp data", this.props.id, newProps.ui.getCompsData.compsID);
    //                 this.props.uiActions.sendCompData(
    //                     this.props.id, newProps.ui.getCompsData.senderID, this.state.dataSource);
    //             } else {
    //                 //console.log("EEE Unknown data sender id", this.props.id, newProps.ui.getCompsData.compsID);
    //             }
    //         }
    //         else {
    //             //console.log("EEE No change", newProps.ui.getCompsData, " ", this.props.ui.getCompsData);
    //         }
    //     }
    //     else {
    //         //console.log("EEE: NO getcompsdata");
    //     }
    // }

    render() {
        const columns = this.columns;
        const Search = Input.Search;

        let SearchHiddenStyle = (this.props.showsearch) ?
            { display: 'inline' } : { display: 'none' };

        return (
            <div /*className={styleTable.mainTable}*/ >
                <span>{(this.props.title) ? this.props.title : ''}</span>
                <span style={SearchHiddenStyle}>
                    <Search
                        placeholder="קלט סינון"
                        onSearch={value => {
                            let s = Object.assign({}, this.state);
                            s.searchTerm = value;
                            s.sorting.order = 1; /// TODO 
                            s.sorting.col = 1; /// TODO 
                            this.setState(s);
                        }}
                        disabled={this.props.disabled}
                        style={{ width: 200 }}
                        enterButton
                    />
                </span>
                <span>
                    <Button onClick={this.showCreateRow}
                        disabled={this.props.disabled || this.state.isCreateRow == 1}>צור חדש</Button>
                </span>
                <Table bordered
                    columns={columns}
                    dataSource={this.state.dataSource}
                    //pagination={this.state.pagination}
                    pagination={false}
                    onChange={this.handleTableChange}
                    scroll={{ y: '132px' }} />
            </div>
        )
    }

    handleOnSelect = (row, col, data) => {
        console.log("on select (id)", row, col, data);
        this.edit_line_tmp[col] = data;
    }

    showCreateRow = () => {
        let s = { ...this.state };
        s.isCreateRow = 1;
        s.isEditRow = this.keyIndexer;
        // todo update datasource

        let node = { key: this.keyIndexer };
        for (let i = 0; i < this.props.tableFormat.length; i++) {
            node['col_' + (i + 1)] = '';
        }
        s.dataSource.unshift(node);
        this.setState(s);
    }

    update_edit_line_tmp = (key, col, data) => {
        console.log("update tmp...");
        //let index = parseInt((col.split("_"))[1]);
        //console.log("key: ", key, "data: ", data, " col: ", col);
        //console.log("tmp before", this.edit_line_tmp);
        this.edit_line_tmp[col] = data;
        console.log("tmp after", this.edit_line_tmp);

    }

    onCellChange = (key, dataIndex) => { // edit cell
        return (value) => {
            let s = { ...this.state };
            s.dataSource = this.state.dataSource.map(
                item => {
                    if (item.key === key)
                        item[dataIndex] = value;
                }
            );
            this.setState(s);
        };
    }

    closeEditLine = (key) => {
        return (
            <span onClick={() => {
                let s = Object.assign({}, this.state);

                if (s.isCreateRow == 1) {
                    s.dataSource = this.state.dataSource.filter(item => item.key != key);
                }

                s.isEditRow = -1;
                s.isCreateRow = 0;
                this.setState(s);
            }}>
                <Icon style={{ fontSize: 16, padding: 4 }} type="close" />
            </span>
        )
    }

}

class EditableCell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            editable: false,
        }

        this.key = this.props.position.key;
        this.col = this.props.position.col;
    }
    handleChange = (e) => {
        console.log("handle change...");
        const value = e.target.value;
        this.props.onUpdate(this.key, this.col, e.target.value);
        this.setState({ value });
    }
    check = () => {
        this.setState({ editable: false });
        if (this.props.onChange) {
            this.props.onChange(this.state.value);
        }
    }
    checkRow = () => {
        //this.setState({ editable: false });
        //if (this.props.onChange) {
        //    this.props.onChange(this.state.value);
        //}
        //console.log("vvv", this.props.uiActions);
        this.props.uiActions.onPressEnter(this.props.tableID, this.key);
    }
    edit = () => {
        this.setState({ editable: true });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ value: newProps.value });
        this.filterData = newProps.apiCallData;
    }

    render() {
        const { value, editable } = this.state;
        let fontStyle = { fontSize: 16, /*padding: 4*/ };
        let editFontStyle = { fontSize: 16, /*fontWeight: 'bold',*/ minHeight: 24 }
        let isNumeric = this.props.isNumeric;

        return (
            <div className="editable-cell">
                {
                    (this.props.isEditRow) ?
                        (isNumeric) ?
                            <InputNumeric
                                style={fontStyle}
                                value={value}
                                onChange={this.handleChange}
                            /> :
                            <Input
                                style={fontStyle}
                                value={value}
                                onChange={this.handleChange}
                                onPressEnter={this.checkRow} />
                        //<Input
                        //    style={fontStyle}
                        //    value={value}
                        //    onChange={this.handleChange}
                        //    onPressEnter={this.checkRow}
                        //ref={(e) => { this.rowFocus = e; }}
                        //> 
                        : (
                            (false/*editable*/) ?
                                <div className="editable-cell-input-wrapper">
                                    <Input
                                        style={fontStyle}
                                        value={value}
                                        onChange={this.handleChange}
                                        onPressEnter={this.check}
                                    />
                                    <Icon
                                        style={{ fontSize: 16, padding: 4 }}
                                        type="check"
                                        className="editable-cell-icon-check"
                                        onClick={this.check}
                                    />
                                </div>
                                :
                                <div className="editable-cell-text-wrapper"
                                    style={editFontStyle}
                                    onClick={this.edit}>
                                    {value || ' '}
                                </div>
                        )
                }
            </div>
        );
    }
}

class ExtendedCheckbox2 extends React.Component {
    constructor(props) {
        super(props);
        //console.log("extended checkbox: ", this.props.position.row, this.props.position.col);
    }

    handle = (e) => {
        //console.log("handle: ");
        //console.log("row:", this.props.position.row, " col:", this.props.position.col, " val:", (e.target.checked) ? 1 : 0);
        this.props.onUpdate(
            this.props.position.row, this.props.position.col, (e.target.checked) ? 1 : 0);
    }

    render() {
        //console.log("render chkbox:\n", this.props, "\ndefault: ", this.props.defaultChecked);
        return (
            <Checkbox
                onChange={this.handle}
                //defaultChecked={false}
                defaultChecked={this.props.defaultChecked} //record[col.dataIndex] === '1'/*(text === '1')*/}
                disabled={this.props.disabled} />
        )
    }
}

class InputNumeric extends React.Component {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        //console.log("sss: click: ", e.target.value);
        let { value } = e.target;

        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            this.props.onChange(e);
        }
    }

    render() {
        const { value } = this.props;

        return (
            <Input
                {...this.props}
                onChange={this.onChange}
                onBlur={this.onBlur}
                placeholder=""
                maxLength="25"
            />
        );
    }
}

export default ExtendedStaticTable;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from 'react';
import LangContext from "../contextProvider/LangContext";
import { Checkbox, Radio } from 'antd';

type Props = {
    value: String | Number,
    onChange: Function
}

class CheckboxIncludeSendingDocs extends Component {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        return (<span>
            <Checkbox
                checked={this.props.value > 0}
                onChange={e => { this.props.onChange(e.target.checked ? "1" : "0") }}
            >{this.getText(19113)}</Checkbox>
            <Radio.Group 
                style={{ display: this.props.value > 0 ? "block": "none"}}
                value={this.props.value }
                onChange={e => { this.props.onChange(e.target.value) }}
             >
                <Radio value={"1"}>{this.getText(19114)}</Radio><br/>
                <Radio value={"2"}>{this.getText(19115)}</Radio><br/>
                <Radio value={"3"}>{this.getText(19116)}</Radio>
            </Radio.Group>
        </span>)
    }
}
export default CheckboxIncludeSendingDocs;
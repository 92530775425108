/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Select, Form, Checkbox, Radio } from "antd";
import "../App.css";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';

const { Option } = Select;
const { Item } = Form;
const { Group } = Radio;

class StockTransferStorages extends Component {
    constructor(props: Props) {
        super(props);

        this.state = {
            isCheck: false,
            storageList: [],
            type: 1,
        }

        this.id = "STOCK_TRANSFER_STORAGES";
        if (props.data[this.id] === undefined) props.data[this.id] = { dataset: [] };

        this.loadFavoriteValue = null;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    componentDidMount() {

        this.sendAPI("get_storage_list", "", (ob) => {
            if (ob.data) {
                let dataset = ob.data.split('\r').map(x => {
                    let y = x.split('\f');
                    return { code: y[0], name: y[1], transit: y[2] ?? "0", waster: y[3] ?? "0" }
                });

                this.props.dataActions.setJson(this.id, { dataset });
                let storageList = dataset.filter(f => !this.props.value || !this.props.value.length || this.props.value.indexOf(f.code) > -1)
                let isCheck = this.props.value && this.props.value.length > 0;
                this.setState({ storageList, isCheck }, this.onChange)
            }
        })
    }

    componentDidUpdate(prevProps) {
       
        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            clearTimeout(this.loadFavoriteValue);
            this.loadFavoriteValue = setTimeout(() => {
                this.setType()
            }, 500);
        }
    }

    onChange = () => {
        let storages = this.state.storageList.map(x => x.code)
        let transferStorages = this.state.storageList.map(x => x.transit)
        let wasterStorages = this.state.storageList.map(x => x.waster)
        switch (this.state.type) {
            case 1: this.props.onChange([...storages, ...transferStorages]); break;
            case 2: this.props.onChange(storages); break;
            case 3: this.props.onChange(transferStorages); break;
            case 4: this.props.onChange(wasterStorages); break;
        }
    }

    filterValue = (filter) => {
        let issetCode = this.props.value.indexOf(filter.code) > -1;
        let issetTransit = this.props.value.indexOf(filter.transit) > -1;
        let issetWaster = this.props.value.indexOf(filter.waster) > -1;
        switch (this.state.type) {
            case 1: return issetCode || issetTransit;
            case 2: return issetCode;
            case 3: return issetTransit;
            case 4: return issetWaster;
        }
    }

    setType = () => {
        let dataset = this.props.data && this.props.data[this.id] ? this.props.data[this.id].dataset : [];
        let value = this.props.ui.favoriteParams[this.props.fieldName]
        value = (value) ? value.split(',') : [];

        let issetCode = false;
        let issetTransit = false;
        let issetWaster = false;
        let type = 0;
        let storageList = []

        dataset.forEach(x => {
            if (value.indexOf(x.code) > -1) issetCode = true;
            if (value.indexOf(x.transit) > -1) issetTransit = true;
            if (value.indexOf(x.waster) > -1) issetWaster = true;
            if (issetCode || issetTransit || issetWaster) storageList.push(x);
        })

        if (issetCode && issetTransit) {
            type = 1
        } else if (issetCode) {
            type = 2
        } else if (issetTransit) {
            type = 3
        } else if (issetWaster) {
            type = 4
        }

        this.setState({ isCheck: true, storageList, type }, this.onChange)

    }

    render() {
        let gt = this.getText;
        let dataset = this.props.data && this.props.data[this.id] ? this.props.data[this.id].dataset : []
        // const { dataset } = this.props.data[this.id]

        return (<div>
            <Item label={gt(16106)}>
                <Checkbox
                    checked={this.state.isCheck}
                    onChange={(e) => {
                        let all = e.target.checked;
                        this.setState({ isCheck: all, storageList: dataset }, this.onChange)
                    }} />
                <Select
                    key={"mStorageList"} style={{ width: 250 }} showsearch filterOption={true}
                    optionFilterProp="valuefilter" mode="multiple" maxTagCount={4}
                    onChange={(value) => { this.setState({ isCheck: true, storageList: dataset.filter(f => value && value.indexOf(f.code) > -1) }, this.onChange) }}
                    value={this.state.isCheck ? dataset.filter(this.filterValue).map(x => x.code) : []}
                >
                    {dataset.map((obj, index) => (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>))}
                </Select>
            </Item>
            <Item>
                <Group value={this.state.type} onChange={(e) => { this.setState({ type: e.target.value }, this.onChange) }}>
                    <Radio value={1}>{gt(16107)}</Radio><br />
                    <Radio value={2}>{gt(16108)}</Radio><br />
                    <Radio value={3}>{gt(16109)}</Radio><br />
                    <Radio value={4}>{gt(19221)}</Radio><br />
                </Group>
            </Item>
        </div>)

    }
}

export default StockTransferStorages;
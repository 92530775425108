/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
//import { Button } from "antd";
import "../App.css";
import CustomerPicker from "./CustomerPicker";
import { Job, User, OutputType, ProcessType } from '../JmReact';

// type CustomerPickerProps = {
//     defaultCustomerId: string,
//     onChange: Function,
//     label: string,
//     data: Array<string>,
//     showDialog: Function,
//     hideDialog: Function,
//     disabled?: boolean
// };

type CustPickerProps = {
    data: Object,
    dataActions: Object,
    uiActions: Object,
    user: Object,
    defaultView: String,
    value: String,
    onChange: Function,
    disabled: Boolean,
}

type State = {

};

class CustPicker extends Component<CustPickerProps, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            custName: ''
        }

        if (props.data.customerList === undefined) props.data.customerList = { ...props.data.genericSelector };

    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    componentDidMount() {
        // this.props.dataActions.networkFetchData(
        //     this.props.user.companyCode,
        //     this.props.user.token,
        //     {
        //         // script: "data_get",
        //         script: "get_selector_customers",
        //         params: { dataObject: "customerList" }
        //     },
        //     "customerList",
        //     { loading: "", failed: "Error loading", success: "" },
        //     false,
        //     { action: null, args: [] },
        //     null);

        this.sendAPI("get_selector_customers", "", ob => { this.props.dataActions.setJson("customerList", { data: ob.data?.split("\r") }) })

        if (this.props.value) {
            let custData = this.props.data.customerList.data ?
                this.props.data.customerList.data.find((f) => {
                    let y = f.split('\f');
                    return y[0] === this.props.value;
                }) : null

            if (custData) {
                let custName = custData.split("\f")[1]
                this.setState({ custName });
            }
        }
    }

    render() {
        const { value, onChange, data, uiActions, defaultView } = this.props;
        const { custName } = this.state;
        let dataSelector = (data.customerList) ? data.customerList.data : []
        console.log("dataSelector", dataSelector)

        return (
            <CustomerPicker
                defaultCustomerId={value ? value : null}
                onChange={(v) => {
                    this.setState({ custName: v.customerName }, () => {
                        onChange(v.customerId, v.customerName)
                    })
                }}
                label={value ? value + " - " + custName : defaultView}
                data={dataSelector}
                showDialog={uiActions.uiShowCustomerPickerDialog}
                hideDialog={uiActions.uiHideCustomerPickerDialog}
                disabled={this.props.disabled}
            />);


    }
}

export default CustPicker;
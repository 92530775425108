
import React from "react";
import { notification, Form, Input, Button, Modal, Select, message, Upload } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import moment from "moment";
import LangContext from "../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

//type State = {}
class ModalRunStandingOrders extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            loadingImg: false,
        }

        this.id = 'MODAL_RUN_STANDING_ORDERS';

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state._count = 0;
        this.state._id = 0;
        //this.state.itemPick = null;
        //this.fieldsMeta = { unknown: { validateStatus: null, value: null } };

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

        this.api = {
            //get: "get_branch_by_id",
            //edit: "edit_create_branch",
            //selector: "list_pos",
            //delete: "delete_branch",
            runCharges: "run_standing_order_charges",
            getCount: "get_customer_with_creditcards_count",
        }

        this.actionButtons = [
            /*{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/];

        this.dataSource = [];

        this.openNotificationWithIcon = (type, msg, descr) => {
            notification[type]({
                message: msg,
                description: descr,
            });
        };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //document.title = "Cash On Tab - לקוחות להוראת קבע";
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
    }
    handleClear = () => {
        //this.clear();
    }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    /*
    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataJustSaveEdit,
            args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
        })
        // update main table after change
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataGetRequested,
            args: ["BRANCHES_MAIN", this.props.user.companyCode, this.props.user.token, "get_branches_list_table"]
        });
        this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
    }
    */

    render() {
        const { loading, loadingImg } = this.state;
        //const sqlFormat = "YYYY-MM-DD";
        //const timeFormat = "HH:mm:ss";
        //const dateFormat = "DD/MM/YYYY";

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10917)}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        const list = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        });

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'40%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                <div style={{ textAlign: 'center', fontSize: '24px' }}>
                    {" " + this.getText(10918) + " " + /*JSON.stringify(this.props.ui.data)*/this.state._count + " " + this.getText(10919)}
                </div>

                <div className="action_footer" style={{ textAlign: 'center', marginTop: 16 }}>
                    <Button
                        //style={{ display: 'inline-block' }}
                        onClick={this.runCharges}
                        type="primary"
                        //htmlType="submit" 
                        //form="create_edit_branch_form"
                        loading={this.props.ui.workInProgress}
                        className={"form-button-save"} icon={"caret-right"}>{this.getText(10920)}</Button>
                </div>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toggle === false && this.props.toggle === true) {
            this.setState({ _count: this.props.ui.data.count, _id: this.props.ui.data.mId });
            /*
            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                { _id: this.props.ui.data },
                //{ _id: (id_data) ? id_data : this.props.ui.data },
                this.api.get
            );
            */
        }
    }

    updateBillState = () => {
        var dataSend = "mID\r" + this.state._id;
        //var dataSend = this.state._id;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'update_standing_order_total'/*this.api.runCharges*/,
            OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob: Object) => {
                console.log("success:", ob.data);
                this.props.dataActions.genericDataGetRequested(
                    "CHARGE_DIRECT_DEBIT_TABLE", this.props.user.companyCode, this.props.user.token, "mock_standing_order_total");
            },
            (error: any) => { console.log("error:", JSON.stringify(error)); });
    }

    runCharges = () => {
        this.props.uiActions.raiseWorkInProgressFlag();
        var dataSend = "id\fpos\r" + this.state._id + '\f' + 999;
        //var dataSend = this.state._id;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'charge_standing_orders'/*this.api.runCharges*/,
            OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.SuccessCallback, this.ErrorCallback);
    }

    SuccessCallback = (ob: Object) => {
        this.props.uiActions.lowerWorkInProgressFlag();
        console.log("success:", ob.data);
        this.updateBillState();
        notification.success({
            message: this.getText(10921),
            description: this.getText(10922),
        });
        this.props.generateDocuments(this.props.ui.data ? this.props.ui.data.mId : 0);
        this.props.uiActions.hideModal(TableIDs.modalRunStandingOrders);
        
    }

    ErrorCallback = (error: any) => {
        this.props.uiActions.lowerWorkInProgressFlag();
        console.log("error:", JSON.stringify(error));
        //message.error('שגיאה לא ידועה', 2);
        notification.error({
            message: this.getText(10923),
            description: '',
        });
    }

}

export default ModalRunStandingOrders;

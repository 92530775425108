/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import GenericModal from "./GenericModal";
import { Button, Form, Input, InputNumber } from "antd";
import LangContext from "../contextProvider/LangContext";


class AllocationModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reservedNumber: null,
            custTaxIdentity: null,
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }



    saveReservedNumber = (reservedNumber) => {
        const { saveReservedNumber } = this.props.data.document2.modalInsertReservedNumber ?? {}
        this.props.dataActions.setJson("document2.modalInsertReservedNumber", { custTaxIdentity: this.state.custTaxIdentity })
        setTimeout(() => {
            this.setState({ reservedNumber: null, custTaxIdentity: null }, () => {
                if (saveReservedNumber) saveReservedNumber(reservedNumber);
            })
        }, 1000);
    }

    render() {

        let regexp = new RegExp(/^[0-9]+$/);
        const { modalInsertReservedNumber, documentTypeLabel } = this.props.data.document2;

        return (<GenericModal
            key={"reserved_number_modal"}
            visible={modalInsertReservedNumber ? true : false}
            onCancel={() => { this.saveReservedNumber(null) }}
            title={this.getText(19569)}
            width={500}
            footer={
                modalInsertReservedNumber?.custTaxIdentity ? [
                    <Button key={"reserved_number_btn"} type="primary" onClick={() => {
                        this.saveReservedNumber(this.state.reservedNumber)
                    }}>{this.getText(19570)}</Button>
                ] : false}>
            <div style={{ textAlign: "center" }}>
                <h3>{documentTypeLabel}: {modalInsertReservedNumber?.realDocNumber}</h3>
                {parseInt(modalInsertReservedNumber?.custTaxIdentity) ?
                    <h3>ע.מ./ח.פ.: {modalInsertReservedNumber?.custTaxIdentity}</h3>
                    : <Form.Item label={"ע.מ./ח.פ."}>
                        <Input key={"cust_tax_identity_input"}
                            value={this.state.custTaxIdentity}
                            onChange={e => {
                                let custTaxIdentity = e.target.value;
                                if (regexp.test(custTaxIdentity)) this.setState({ custTaxIdentity })
                            }}
                        />
                    </Form.Item>}
                <Form.Item label={this.getText(19571)}>
                    <Input key={"reserved_number_input"}
                        value={this.state.reservedNumber}
                        onChange={e => {
                            let reservedNumber = e.target.value;
                            if (regexp.test(reservedNumber)) this.setState({ reservedNumber })
                        }}
                    />
                </Form.Item>
                <p>{this.getText(19572)}</p>
            </div>
        </GenericModal>)

    }
}

export default AllocationModal;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import ReportMenu from "../menus/ReportMenu";
import Index from "./reports/index";
import PaymentsAggregate from "./reports/PaymentsAggregateReport";
import HoursReport from "./reports/HoursReport";
import SalesReport from "./reports/SalesReport";
import SalesItemsReport from "./reports/SalesItemsReport";
import SalesAvgReport from "./reports/SalesAvgReport";
import SalesItemProfitReport from "./reports/SalesItemProfitReport";
import SalesDetailedReport from "./reports/SalesDetailedReport";
import PaymentsSearch from "./reports/PaymentsSearchReport";
import ReportMenuSales from "../menus/ReportMenuSales";
import TableIDs from '../data/TableIDs';
import SalesMatrixItems from "./reports/SalesMatrixItemsReport";
import SalesDaysHours from "./reports/SalesDaysHours";
import SalesStoreVisits from "./reports/SalesStoreVisits";
import SalesAnalysisBranches from "./reports/SalesAnalysisBranches";
import SalesAnalysis from "./reports/SalesAnalysis";
import SalesDscounts from "./reports/SalesDscounts";
import ProfitExpanded from "./reports/ProfitExpanded";
import TaxReport from "./reports/TaxReport";
import CustomerSalesMonthlyReport from "./reports/CustomerSalesMonthlyReport";
import ExcelTagsBranches from "./reports/ExcelTagsBranches";
import ItemAnalysis from "./reports/ItemAnalysis";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object
};

type State = {};

// SalesItemsReport // SalesReport
class ReportsSales extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={51} path={this.props.baseUrl + "/reports_sales/sales-compare"} exact render={() => <SalesReport {...routeProps} />} />),
            (<Route key={-1} path={this.props.baseUrl + "/reports_sales/hour-report"} exact render={() => <HoursReport {...routeProps} />} />),
            (<Route key={52} path={this.props.baseUrl + "/reports_sales/sales-by-date"} exact render={() => <SalesItemsReport {...routeProps} />} />),
            (<Route key={53} path={this.props.baseUrl + "/reports_sales/sales-avg"} exact render={() => <SalesAvgReport {...routeProps} />} />),
            (<Route key={54} path={this.props.baseUrl + "/reports_sales/sales-profit"} exact render={() => <SalesItemProfitReport {...routeProps} />} />),
            (<Route key={55} path={this.props.baseUrl + "/reports_sales/sales-detailed"} exact render={() => <SalesDetailedReport {...routeProps} />} />),
            (<Route key={56} path={this.props.baseUrl + "/reports_sales/sales-matrix"} exact render={() => <SalesMatrixItems {...routeProps} />} />),
            (<Route key={57} path={this.props.baseUrl + "/reports_sales/sales-days-hours"} exact render={() => <SalesDaysHours {...routeProps} />} />),
            (<Route key={58} path={this.props.baseUrl + "/reports_sales/sales-store-visits"} exact render={() => <SalesStoreVisits {...routeProps} />} />),
            (<Route key={59} path={this.props.baseUrl + "/reports_sales/sales-analysis-branches"} exact render={() => <SalesAnalysisBranches {...routeProps} />} />),
            (<Route key={60} path={this.props.baseUrl + "/reports_sales/sales-analysis"} exact render={() => <SalesAnalysis {...routeProps} />} />),
            (<Route key={171} path={this.props.baseUrl + "/reports_sales/sales-discounts"} exact render={() => <SalesDscounts {...routeProps} />} />),
            (<Route key={172} path={this.props.baseUrl + "/reports_sales/profit-expanded"} exact render={() => <ProfitExpanded {...routeProps} />} />),
            (<Route key={173} path={this.props.baseUrl + "/reports_sales/tax-report"} exact render={() => <TaxReport {...routeProps} />} />),
            (<Route key={174} path={this.props.baseUrl + "/reports_sales/customer-monthly"} exact render={() => <CustomerSalesMonthlyReport {...routeProps} />} />),
            (<Route key={175} path={this.props.baseUrl + "/reports_sales/excel-tags-branches"} exact render={() => <ExcelTagsBranches {...routeProps} />} />),
            (<Route key={176} path={this.props.baseUrl + "/reports_sales/item-analysis"} exact render={() => <ItemAnalysis {...routeProps} />} />),
            (<Route key={-2} path={this.props.baseUrl + "/reports_sales/payments-search"} exact render={() => <PaymentsSearch {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/reports_sales"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <ReportMenuSales baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default ReportsSales;

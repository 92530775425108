
import React from "react";
import { Input, Icon, Button, Popconfirm, Popover, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
import { fixCurrentPage } from "../utils/utils";
import ResponsiveTable from "./ResponsiveTable";

import "../style/generalUI.css";
import { request } from "https";
import LangContext from "../contextProvider/LangContext";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const topHeight = 200; // px
const rowHeight = 40;  // px

//type State = {}
class ModalAccumulationOfPoints extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = "MODAL_ACCUMULATION_OF_POINTS";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        // if (props.data[TableIDs.genericSelectorTagRelation] === undefined) {
        //     props.data[TableIDs.genericSelectorTagRelation] = { ...props.data.genericSelector };
        // }

        this.api = {
            get: "get_deals_activity_table_list",
            // create: "create_tag_type",
            // edit: "edit_tag_type",
            // delete: "delete_tag_type",
            selector: "get_tag_relation_list", // TODO: tag type categories
            // selector_tags: "get_tag_types_with_relation_list",
        }

        this.actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
            //  {
            //     buttonType: 'print',
            //     buttonFunction: () => { this.generateReport(this.props.data[this.id].filters) }
            // },
        ]

        this.modalData = []

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - ";
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorTagRelation, this.props.user.companyCode,
            this.props.user.token, this.api.selector);
        //this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        //console.log('xxx-yuda ',numOfRowsByHeight);
        let custData = this.props.data.CUSTOMER_POINTS_LIST.data;
        let custIndex = this.props.ui.data ? this.props.ui.data.customerIndex : -1;
        this.modalData = custData && custIndex > -1 && custData[custIndex] ? custData[custIndex].split('\f') : []

        let request = {
            _id: this.modalData[0] ? this.modalData[0] : 0,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            request,
            this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorTagRelation, this.props.user.companyCode,
        //     this.props.user.token, this.api.selector);
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            this.initFilters()
        }
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {

        let request = {
            // just of ui-pagination update

            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            // _sort_by: 'col_1',//+ col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        this.setState({ visible: false });
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }

    // generateReport(filters) {
    //     let params = {
    //         _id: filters._id,
    //         _words: encodeURIComponent(filters._words),
    //         _page_num: 1,
    //         _rows_num: 999999,
    //         _isAsc: 1,
    //         reportName: "reports/CustomerPointsDetails.pdf",
    //         REPORT_LOCALE: this.getText(101),
    //     }
    //     this.props.ActionQueue.addToQueue({
    //         action: this.props.dataActions.generateReport,
    //         args: [this.props.user.companyCode, this.props.user.token, params]
    //     });

    // }

    generateReport = (filters, reportName) => {
        let params = {
            _id: filters._id,
            _words: encodeURIComponent(filters._words),
            _page_num: 1,
            _rows_num: 999999,
            _isAsc: 1,
            reportName: "reports/" + reportName,
            REPORT_LOCALE: this.getText(101),
        }
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [this.props.user.companyCode, this.props.user.token, params]
        });

    }

    reportButton = () => {
        let subButton = (text, type, reportName) => {
            let onClick = () => { this.generateReport(this.props.data[this.id].filters, reportName) }
            return (
                <Tooltip title={this.getText(text)}>
                    <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={onClick} />
                </Tooltip>)
        }

        let content = (<div>
            {subButton(12721, "file-pdf", "CustomerPointsDetails.pdf")}
            {subButton(12722, "file-excel", "CustomerPointsDetailsExcel.xls")}
        </div>);

        return (
            <Tooltip title={this.getText(12720)}>
                <Popover content={content} title={this.getText(12723)} trigger="click">
                    <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                        <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                    </Button>
                </Popover>
            </Tooltip >)
    }

    render() {
        const { loading } = this.state;

        let modalTitleStyle = { padding: '4px', paddingLeft: '40px' }
        let space = window.innerWidth > 600 ? ' ' : (<br />)

        let modalTitle = (<span>
            <span style={modalTitleStyle}>{this.getText(10960)}</span>{space}
            <span style={modalTitleStyle}>{this.getText(10961)}: {this.modalData[1]}</span>{space}
            <span style={modalTitleStyle}>{this.getText(10962)}: {this.modalData[2]}</span>{space}
            <span style={modalTitleStyle}>{this.getText(10963)}: {this.modalData[5]}</span>
        </span>
        );

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={modalTitle}
                    actionButtons={this.actionButtons}
                    genericActionButtons={[this.reportButton()]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        let columns =
            [
                {
                    title: this.getText(10964),
                    dataIndex: "dId",
                    key: "dId",
                    sorter: false,
                    width: '10%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(10965),
                    dataIndex: "dDate",
                    key: "dDate",
                    width: '10%',
                    sorter: false,
                    render: (text, record) => text,
                }, {
                    title: this.getText(10966),
                    dataIndex: "dTime",
                    key: "dTime",
                    width: '10%',
                    sorter: false,
                    render: (text, record) => text,
                }, {
                    title: this.getText(10967),
                    dataIndex: "dType",
                    key: "dType",
                    width: '10%',
                    sorter: false,
                    render: (text, record) => text,
                }, {
                    title: this.getText(16071),
                    dataIndex: "dBranch",
                    key: "dBranch",
                    width: '15%',
                    sorter: false,
                    render: (text, record) => text,
                }, {
                    title: this.getText(10968),
                    dataIndex: "dDocNum",
                    key: "dDocNum",
                    width: '10%',
                    sorter: false,
                    render: (text, record) => text,
                }, {
                    title: this.getText(10969),
                    dataIndex: "dSumPoints",
                    key: "dSumPoints",
                    width: '15%',
                    sorter: false,
                    render: (text, record) => parseFloat(text) > 0 ? text : '',
                }, {
                    title: this.getText(10970),
                    dataIndex: "dSumPoints",
                    key: "dUsePoints",
                    width: '15%',
                    sorter: false,
                    render: (text, record) => parseFloat(text) <= 0 ? text : '',
                }
            ];

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <ResponsiveTable
                    columns={columns}
                    idTable={this.props.data[this.id]}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    tableOnly={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    percentageToMobile={[false, 30, 35, false, false, 35]}
                />
            </Modal >
        )
    }
}

export default ModalAccumulationOfPoints;

import React, { Component } from "react";
import "../../App.css";
import { Select, InputNumber, Checkbox, Button, Switch } from "antd";
import PageToolbar from "../../components/PageToolbar";
import TableIDs from '../../data/TableIDs';
import LangContext from "../../contextProvider/LangContext";
import FormItem from "antd/lib/form/FormItem";
import ResponsiveTable from "../../components/ResponsiveTable";
//import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import DateTimePicker from "../../components/DateTimePicker";
import TableLineIcons from "../../components/TableLineIcons";

const { Option } = Select;

class WorkingHouersDefaults extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            updateData: false,
            groupId: 0,
            modalSettings: false,
        }

        this.id = "WORKING_HOUERS_DEFULTS_SETTINGS";
        this.levels_id = "WORKING_HOUERS_LEVELS";

        this.api = {
            get: "get_working_houers_defaults",
            edit: "edit_working_houers_defaults",

            get_levels: "get_working_houers_levels",
            create_levels: "create_working_houers_levels",
            edit_levels: "edit_working_houers_levels",
            delete_levels: "delete_working_houers_levels",
        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
        if (props.data[this.levels_id] === undefined) { props.data[this.levels_id] = { ...props.data.genericData } }
        // if (props.data[this.levels_id] === undefined) { props.data[this.levels_id] = { dataset: [] } }
        if (props.data[TableIDs.genericSelectorGroupEmployee] === undefined) { props.data[TableIDs.genericSelectorGroupEmployee] = { ...props.data.genericSelector } }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.initFilter
        }];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilter();
        this.apiLevels();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorGroupEmployee, this.props.user.companyCode, this.props.user.token, "get_employee_group_list")
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(13052);
        if (this.state.updateData) this.setState({ updateData: false }, this.initFilter)
    }

    initFilter = () => {
        this.props.dataActions.genericDataGetEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token, { _group: this.state.groupId }, this.api.get);
    }

    handleOk = () => {
        this.setState({ updateData: true }, () => {
            this.props.dataActions.genericDataJustSaveEditWithParams(
                this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, { _group: this.state.groupId })
        });
    }

    apiLevels = () => {
        this.props.dataActions.genericDataSetFilter(
            this.levels_id, this.props.user.companyCode, this.props.user.token, {}, this.api.get_levels);

        // this.sendAPI(this.api.get_levels, "", (ob) => {
        //     let dataset = ob.data ? ob.data.split("\r").map((x, index) => {
        //         let y = x.split("\f");
        //         return {
        //             index,
        //             key: y[0],
        //             _ID: y[0],
        //             _GroupId: y[1],
        //             _DayOfWeek: y[2],
        //             _Hours: y[3],
        //             _Percentage: y[4],
        //             _FromTime: y[5],
        //             _ToTime: y[6],
        //             _NotIncludeShabat: y[7]
        //         }
        //     }) : [];
        //     this.props.dataActions.setJson(this.levels_id, { dataset })
        // })
    }

    // sendAPI = (script, data, sCallback) => {
    //     let user = new User(this.props.user.token, this.props.user.companyCode);
    //     let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
    //     job.setInput(data);
    //     job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    // }

    renderGroupSelector = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.levels_id];

        return (<GenericSelector
            {...this.props}
            id={TableIDs.genericSelectorGroupEmployee}
            addFirstRow={{ code: '0', name: this.getText(15456) }}
            disabled={editing != record.key}
            value={editing == record.key ? editedRecord._GroupId : text}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.levels_id, { _GroupId: e }) }}
        />)
    }

    renderDaysSelector = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.levels_id];

        return (<Select
            disabled={editing != record.key}
            value={editing == record.key ? editedRecord._DayOfWeek : text}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.levels_id, { _DayOfWeek: e }) }}
            style={{ width: '100%' }}>
            <Option value={'0'}>{this.getText(17872)}</Option>
            <Option value={'1'}>{this.getText(13064)}</Option>
            <Option value={'2'}>{this.getText(13065)}</Option>
            <Option value={'3'}>{this.getText(13066)}</Option>
            <Option value={'4'}>{this.getText(13067)}</Option>
            <Option value={'5'}>{this.getText(13068)}</Option>
            <Option value={'6'}>{this.getText(13069)}</Option>
            <Option value={'7'}>{this.getText(13070)}</Option>
        </Select>)
    }

    renderHours = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.levels_id];
        return (editing != record.key) ? text :
            (<InputNumber
                value={editedRecord._Hours}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.levels_id, { _Hours: e }) }}
            />)
    }

    renderPercentage = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.levels_id];
        return (editing != record.key) ? text :
            (<InputNumber
                value={editedRecord._Percentage}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.levels_id, { _Percentage: e }) }}
            />)
    }

    renderFromTime = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.levels_id];
        return (editing != record.key) ? text :
            (<DateTimePicker
                format="HH:mm:ss"
                value={editedRecord._FromTime}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.levels_id, { _FromTime: e }) }}
            />)
    }

    renderToTime = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.levels_id];
        return (editing != record.key) ? text :
            (<DateTimePicker
                format="HH:mm:ss"
                value={editedRecord._ToTime}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.levels_id, { _ToTime: e }) }}
            />)
    }

    renderNotIncludeShabat = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.levels_id];
        return (<Checkbox
            disabled={editing != record.key}
            checked={editing == record.key ? editedRecord._NotIncludeShabat == '1' : text == '1'}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.levels_id, { _NotIncludeShabat: e.target.checked ? '1' : '0' }) }}
        />)
    }

    renderEditLevels = (text, record) => {
        const { editing } = this.props.data[this.levels_id];
        return (editing != record.key) ?
            (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.levels_id, record.key, record.index) }}
                deleteQuetion={{ title: this.getText(17873), okText: this.getText(17874), cancelText: this.getText(17875) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.levels_id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete_levels, this.api.get_levels);
                }} />)
            : (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.levels_id, this.props.user.companyCode, this.props.user.token,
                        editing == 0 ? this.api.create_levels : this.api.edit_levels,
                        this.api.get_levels);
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.levels_id);
                    else this.props.dataActions.genericDataCancelEditing(this.levels_id);
                }} />)

    }

    createLevel = () => {
        if (this.props.data[this.levels_id].editing == -1)
            this.props.dataActions.genericDataStartInlineCreate(this.levels_id)
    }

    calcViewLevels = () => {
        const { data, headers } = this.props.data[this.levels_id]
        const { groupId } = this.state;

        let levelsList = [];

        const levelDataSet = data.map(x => {
            let y = x.split("\f");
            let obj = {}
            headers.forEach((z, i) => { obj = { ...obj, [z]: y[i] } });
            if (levelsList.indexOf(obj._Percentage) == -1) levelsList.push(obj._Percentage)
            return obj;
        })

        levelsList.sort((a, b) => parseInt(a) - parseInt(b));

        const getDayRows = (day) => {
            let rowsToDay = levelDataSet.filter(f => f._GroupId == groupId && f._DayOfWeek == day);
            if (!rowsToDay.length) rowsToDay = levelDataSet.filter(f => f._GroupId == '0' && f._DayOfWeek == day);
            if (!rowsToDay.length) rowsToDay = levelDataSet.filter(f => f._GroupId == groupId && f._DayOfWeek == '0');
            if (!rowsToDay.length) rowsToDay = levelDataSet.filter(f => f._GroupId == '0' && f._DayOfWeek == '0');
            if (!rowsToDay.length) rowsToDay = [{ _GroupId: '0', _DayOfWeek: '0', _Percentage: '100' }];
            rowsToDay.sort((a, b) => parseInt(a._Percentage) - parseInt(b._Percentage));
            let balanceHours = 24
            return rowsToDay.map((x, i) => {
                if (!rowsToDay[i + 1]) {
                    return { ...x, _Hours: balanceHours > 0 ? balanceHours.toFixed(2) : '0.00', _FromTime: '', _ToTime: '', _NotIncludeShabat: '0' }
                } else {
                    balanceHours -= parseFloat(x._Hours);
                    return x;
                }
            })
        }

        let daysList = {
            sun: getDayRows('1'),
            mon: getDayRows('2'),
            tue: getDayRows('3'),
            wed: getDayRows('4'),
            thu: getDayRows('5'),
            fri: getDayRows('6'),
            sat: getDayRows('7'),
        }

        return levelsList.map(x => {
            return {
                rowTitle: x,
                rowKey: x,
                sun: daysList.sun.find(f => f._Percentage == x),
                mon: daysList.mon.find(f => f._Percentage == x),
                tue: daysList.tue.find(f => f._Percentage == x),
                wed: daysList.wed.find(f => f._Percentage == x),
                thu: daysList.thu.find(f => f._Percentage == x),
                fri: daysList.fri.find(f => f._Percentage == x),
                sat: daysList.sat.find(f => f._Percentage == x),
            }
        })
    }

    renderViewLevel = (t) => {
        if (!t) return '0.00';
        return (<span>
            <div>{t._Hours}</div>
            {t._FromTime && t._ToTime ? <div style={{ color: 'red', fontSize: 10 }}>{this.getText(17876) + " " + t._FromTime + " - " + t._ToTime}</div> : ""}
            {t._FromTime && !t._ToTime ? <div style={{ color: 'red', fontSize: 10 }}>{this.getText(17877) + " " + t._FromTime}</div> : ""}
            {!t._FromTime && t._ToTime ? <div style={{ color: 'red', fontSize: 10 }}>{this.getText(17878) + " " + t._ToTime}</div> : ""}
            {t._NotIncludeShabat == '1' ? <div style={{ color: 'red', fontSize: 10 }}>{this.getText(17881)}</div> : ""}
        </span>)
    }


    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto'
        };

        const resToMobile = (n) => (window.innerWidth > 600) ? {
            width: (n - 2) + '%',
            margin: '1%',
            display: 'inline-block',
            verticalAlign: 'text-top'
        } : {
            display: 'block',
            padding: 8
        }

        let editedRecord = this.props.data[this.id].editedRecord;

        let onChange = (field, value) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: value }) }

        let inputNumber = (key1, key2) => {
            let key = key1 + key2;
            let rowKeys = ['mNormal', 'mLevel1', 'mLevel2'];
            let max = 24;
            let dCeil = (n) => n && parseFloat(n) ? Math.round(parseFloat(n) * 100) / 100 : 0;
            if (key2) rowKeys.filter(f => f !== key1).forEach((x) => { max -= dCeil(editedRecord[x + key2]) })

            return (<InputNumber
                min={0} max={max} step={0.1} precision={2}
                value={dCeil(editedRecord[key])}
                onChange={(v) => { onChange([key], v > max ? max : v) }}
            />)
        }

        let inputCol = (title, key, key2) => {

            /// update mekimi 1
            // return { title: this.getText(title), key, dataIndex: key, width: "12%", render: this.renderViewLevel }
            return { title: this.getText(title), key, dataIndex: key, width: "11%", render: (t, r) => inputNumber(r.rowKey, key2) }
        }

        /// update mekimi 2
        //    let dataSource = this.calcViewLevels();
        let dataSource =
            [
                {
                    rowTitle: this.getText(13061),
                    rowKey: "mNormal",
                    sun: editedRecord.mNormalSun,
                    mon: editedRecord.mNormalMon,
                    tue: editedRecord.mNormalTue,
                    wed: editedRecord.mNormalWed,
                    thu: editedRecord.mNormalThu,
                    fri: editedRecord.mNormalFri,
                    sat: editedRecord.mNormalSat,
                }, {
                    rowTitle: this.getText(13062),
                    rowKey: "mLevel1",
                    sun: editedRecord.mLevel1Sun,
                    mon: editedRecord.mLevel1Mon,
                    tue: editedRecord.mLevel1Tue,
                    wed: editedRecord.mLevel1Wed,
                    thu: editedRecord.mLevel1Thu,
                    fri: editedRecord.mLevel1Fri,
                    sat: editedRecord.mLevel1Sat,
                }, {
                    rowTitle: this.getText(13063),
                    rowKey: "mLevel2",
                    sun: editedRecord.mLevel2Sun,
                    mon: editedRecord.mLevel2Mon,
                    tue: editedRecord.mLevel2Tue,
                    wed: editedRecord.mLevel2Wed,
                    thu: editedRecord.mLevel2Thu,
                    fri: editedRecord.mLevel2Fri,
                    sat: editedRecord.mLevel2Sat,
                }
            ];

        let columns = [
            { title: this.getText(17882), key: "rowTitle", dataIndex: "rowTitle", width: "16%" },
            inputCol(13064, "sun", "Sun"),
            inputCol(13065, "mon", "Mon"),
            inputCol(13066, "tue", "Tue"),
            inputCol(13067, "wed", "Wed"),
            inputCol(13068, "thu", "Thu"),
            inputCol(13069, "fri", "Fri"),
            inputCol(13070, "sat", "Sat"),
        ];

        return (<div style={divStyle}>
            <div style={{ padding: '4px' }}>
                <PageToolbar
                    {...this.props}
                    title={this.getText(13053)}
                    actionButtons={this.actionButtons}
                    isModal={false}
                    
                    ///// update mekimi 3
           
                    // genericActionButtons={[
                    //     <Tooltip title={this.getText(17862)}>
                    //         <Button onClick={() => { this.setState({ modalSettings: true }, this.apiLevels) }}>
                    //             <Icon type="setting" />
                    //         </Button>
                    //     </Tooltip>
                    // ]} 
                    />
            </div>
            <div style={resToMobile(30)}>
                <span style={{ display: this.state.groupId == 0 ? 'block' : 'none' }}>
                    <FormItem label={this.getText(13054)}>
                        <Switch
                            checkedChildren={this.getText(13057)} unCheckedChildren={this.getText(13058)}
                            checked={editedRecord.mHowerType == 1 ? true : false}
                            onChange={(v) => { onChange('mHowerType', v ? "1" : "2") }}
                        />
                    </FormItem>
                    <FormItem label={this.getText(13055)}>{inputNumber("mMaxShift", "")}</FormItem>
                    <FormItem label={this.getText(13056)}>
                        <Switch
                            checkedChildren={this.getText(13060)} unCheckedChildren={this.getText(13059)}
                            checked={editedRecord.mSliceEndMonth == 1 ? true : false}
                            onChange={(v) => { onChange('mSliceEndMonth', v ? "1" : "0") }}
                        />
                    </FormItem>
                </span>
                <div>
                    <h2>{this.getText(13102)}</h2>
                    {[12698, 12699, 12702, 12701, 12700, 12703].map((x, i) => (<p>{(i + 1) + " - " + this.getText(x)}</p>))}
                </div>
            </div>
            <div style={resToMobile(70)}>
                <FormItem>
                    <Select value={this.state.groupId} onChange={(e) => { this.setState({ groupId: e }, this.initFilter) }} style={{ width: 200 }}>
                        <Option value={0}>{this.getText(15456)}</Option>
                        {this.props.data[TableIDs.genericSelectorGroupEmployee].dataset.map(x => (<Option value={x.code}>{x.name}</Option>))}
                    </Select>
                </FormItem>
                <ResponsiveTable
                    dataSource={dataSource}
                    columns={columns}
                    tableOnly={true}
                    ui={this.props.ui}
                    pagination={false}
                    percentageToMobile={[100]}
                />
                <h3 style={{ textAlign: 'center' }}>{this.getText(14353)}</h3>

                <Button type="primary" loading={this.props.ui.workInProgress} className={"form-button-save"} icon={"check"}
                    onClick={this.handleOk} style={{ marginTop: 20, float: 'left' }}>{this.getText(13071)}</Button>

            </div>
            {/*
            /// update mekimi 4
           
            <GenericModal
                visible={this.state.modalSettings}
                onCancel={() => { this.setState({ modalSettings: false }) }}
                title={this.getText(17863)}
                actionButtons={[
                    { buttonType: 'refresh', buttonFunction: this.apiLevels },
                    { buttonType: 'add', buttonFunction: this.createLevel },
                ]}>
                <ResponsiveTable
                    idTable={this.props.data[this.levels_id]}
                    columns={[
                        { title: this.getText(17864), key: '_GroupId', dataIndex: '_GroupId', width: '18%', render: this.renderGroupSelector },
                        { title: this.getText(17865), key: '_DayOfWeek', dataIndex: '_DayOfWeek', width: '18%', render: this.renderDaysSelector },
                        { title: this.getText(17866), key: '_Hours', dataIndex: '_Hours', width: '12%', render: this.renderHours },
                        { title: this.getText(17867), key: '_Percentage', dataIndex: '_Percentage', width: '12%', render: this.renderPercentage },
                        { title: this.getText(17868), key: '_FromTime', dataIndex: '_FromTime', width: '12%', render: this.renderFromTime },
                        { title: this.getText(17869), key: '_ToTime', dataIndex: '_ToTime', width: '12%', render: this.renderToTime },
                        { title: this.getText(17870), key: '_NotIncludeShabat', dataIndex: '_NotIncludeShabat', width: '8%', render: this.renderNotIncludeShabat },
                        { title: this.getText(17871), width: '8%', render: this.renderEditLevels },
                    ]}
                    tableOnly={true}
                    ui={this.props.ui}
                    percentageToMobile={[30, 30, 20, 20]}
                />
            </GenericModal> */}
        </div>)
    }
}

export default WorkingHouersDefaults;
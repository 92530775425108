
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, /*Select,*/ Checkbox } from "antd";
//import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import CustPicker from "../../components/CustPicker";
import LangContext from "../../contextProvider/LangContext";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";

const FormItem = Form.Item;
//const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesDscounts extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            dateFrom: null,
            dateTo: null,
            posList: '',
            storageList: '',
            employeeList: '',
            withBargains: 1,
            itemCode: '',
            itemName: '',
            custId: null,
            _withSendingDocs: '1',
            mCustTagList : "",
            reportName: "reports/SalesDscounts.pdf",
        };

        this.selectors = [
            { id: TableIDs.genericSelectorPos, api: "list_pos" },
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
            { id: TableIDs.genericSelectorEmployees, api: "get_employee_list" },
        ];

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(14640);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    createDatePicker = (label, field) => {
        return (<FormItem label={this.getText(label)}>
            <DateTimePicker
                format="DD/MM/YYYY"
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                minDate={field == 'dateTo' ? this.state.dateFrom : null}
                maxDate={field == 'dateFrom' ? this.state.dateTo : null}
            />
        </FormItem>)
    }

    createSelector = (label, field, index) => {
        return (<FormItem label={this.getText(label)}>
            <GenericSelector
                multi
                id={this.selectors[index].id}
                api={this.selectors[index].api}
                value={this.state[field] ? this.state[field].split(',') : []}
                onChange={(e) => { this.setState({ [field]: e ? e.join(',') : '' }) }}
                data={this.props.data}
                dataActions={this.props.dataActions}
                user={this.props.user}
            />
        </FormItem>)
    }


    render() {

        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;

        let params = { ...this.state, itemName: '' };
        // let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        // let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        // if (params.posList === '') params = { ...params, posList: fullPosList }
        // if (params.storageList === '') params = { ...params, storageList: fullStoragesList }


        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={this.getText(14641)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: '95%' }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 5} style={{ padding: 5 }}>
                        {this.createDatePicker(14642, 'dateFrom')}
                        {this.createDatePicker(14643, 'dateTo')}
                        <FormItem>
                            <Checkbox
                                checked={this.state.withBargains == 1}
                                onChange={(e) => { this.setState({ withBargains: e.target.checked ? 1 : 0 }) }}
                            >{this.getText(14647)}</Checkbox>
                        </FormItem>
                        <FormItem>
                            <CheckboxIncludeSendingDocs
                                value={this.state._withSendingDocs }
                                onChange={e => { this.setState({ _withSendingDocs: e }) }}
                            />
                        </FormItem>

                    </Col>
                    <Col span={mobile ? 24 : 12} style={{ padding: 5 }}>
                        <FormItem label={this.getText(14644)}>
                            <GenericSelectorPosStorages  {...this.props} multi
                                onChange={(value, typeList) => this.setState({ posList: value.join(','), storageList: typeList.join(',') })}
                                value={(!this.state.posList) ? [] : this.state.posList.split(',')}
                            />
                        </FormItem>
                        {/* {this.createSelector(14644, 'posList', 0)}
                        {this.createSelector(14645, 'storageList', 1)} */}
                        {this.createSelector(14646, 'employeeList', 2)}

                        <FormItem label={this.getText(19246)}>
                                <GenericSelectorCustTags
                                {...this.props}
                                multi
                                value={this.state.mCustTagList ? this.state.mCustTagList.split(",") : []}
                                onChange={(value) => { this.setState( { mCustTagList: value ? value.join(",") : "" }) }}
                                />
                        </FormItem>

                    </Col>

                    <Col span={mobile ? 24 : 6} style={{ padding: 5 }}>
                        <FormItem label={this.getText(14649)}>
                            <CustPicker
                                user={this.props.user} uiActions={this.props.uiActions}
                                dataActions={this.props.dataActions} data={this.props.data}
                                defaultView={this.getText(14650)}
                                value={this.state.custId}
                                onChange={(e) => { this.setState({ custId: e }) }}
                            />
                        </FormItem>
                        <FormItem label={this.getText(14648)}>
                            <SelectorItemClassic
                                user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
                                dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                                valueId={this.state.itemCode} valueName={this.state.itemName}
                                onChange={(code, name) => { this.setState({ itemCode: code, itemName: name }) }}
                            />
                        </FormItem>



                        <ReportTypeButton
                            {...this.props}
                            params={params}
                            posField={'posList'}
                            storagesField={'storageList'}
                            encodeURIFields={[
                                'itemCode',
                                'itemName',
                            ]}
                            datesToFavorites={[
                                { field: 'dateFrom', label: this.getText(14642) },
                                { field: 'dateTo', label: this.getText(14643) },
                            ]}
                            favoriteSave
                        />
                    </Col>
                </Row>

            </Card>
        </div >
        );
    }
}
export default SalesDscounts; 
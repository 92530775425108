/* @flow */
/*jshint esversion: 6 */
import { Form, Radio } from "antd";
import { Row, Col, DatePicker } from "antd";
import React, { Component } from "react";
import "../../App.css";
import GenericSelector from "../../components/GenericSelector";
import ReportTypeButton from "../../components/ReportTypeButton";
import DateTimePicker from "../../components/DateTimePicker";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import moment from "moment";
import { disabledDatePicker } from "../../utils/utils";

const { MonthPicker } = DatePicker;

class MonthlyUseBenefits extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _fromYear: null,
            _toYear: null,
            _fromMonth: null,
            _toMonth: null,
            _branchList: '',
            _bonusType: '1',
            reportName: "reports/MonthlyUseBenefits.pdf"
        };

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(17970);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 700,
            margin: 'auto'
        };

        const { _fromYear, _toYear, _fromMonth, _toMonth, _branchList, _bonusType } = this.state;

        return (<div style={divStyle}>
            <PageToolbar title={gt(17971)} actionButtons={[]} />
            <Row>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(17972)}>
                        <MonthPicker
                            format="MM/YYYY"
                            value={_fromYear && _fromMonth ? moment(_fromYear + _fromMonth, "YYYYMM") : null}
                            onChange={e => {
                                this.setState({
                                    _fromMonth: e ? moment(e).format("MM") : null,
                                    _fromYear: e ? moment(e).format("YYYY") : null
                                })
                            }}
                            disabledDate={(d) => disabledDatePicker(d, null, _toYear && _toMonth ? _toYear + "-" + _toMonth + "-01" : null)}
                        />
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(17973)}>
                        <MonthPicker
                            format="MM/YYYY"
                            value={_toYear && _toMonth ? moment(_toYear + _toMonth, "YYYYMM") : null}
                            onChange={e => {
                                this.setState({
                                    _toMonth: e ? moment(e).format("MM") : null,
                                    _toYear: e ? moment(e).format("YYYY") : null
                                })
                            }}
                            disabledDate={(d) => disabledDatePicker(d, _fromYear && _fromMonth ? _fromYear + "-" + _fromMonth + "-01" : null, null)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label={gt(17974)}>
                <GenericSelector
                    {...this.props}
                    id={TableIDs.genericSelectorBranches}
                    api={"get_branch_list"}
                    multi
                    value={_branchList ? _branchList.split(",") : []}
                    onChange={e => { this.setState({ _branchList: e ? e.join(",") : "" }) }}
                />
            </Form.Item>
            <Form.Item label={gt(17975)}>
                <Radio.Group value={_bonusType} onChange={e => { this.setState({ _bonusType: e.target.value }) }} >
                    <Radio value={'1'}>{gt(17976)}</Radio>
                    <Radio value={'2'}>{gt(17977)}</Radio>
                    <Radio value={'3'}>{gt(17978)}</Radio>
                </Radio.Group>
            </Form.Item>
            <ReportTypeButton
                {...this.props}
                params={this.state}
                branchesField={"_branchList"}
                datesToFavorites={[
                    { fields: ["_fromMonth", "_fromYear"], label: gt(17972), format: 2 },
                    { fields: ["_toMonth", "_toYear"], label: gt(17973), format: 2 },
                ]}
                favoriteSave
            />
        </div>)
    }

}

export default MonthlyUseBenefits;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Input, Tooltip, Icon } from "antd";
import "../App.css";

type LockPickerProps = {
    onChange: Function,
    onSize: Function,
    value: Object,
};

class LockPicker extends Component<LockPickerProps, State> {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            active: false,
            animate: false,
        }
    }

    getXY = (point) => {
        let rows = this.props.value.size;
        let columns = this.props.value.size;
        let widthCol = 150 / columns;
        let heightRow = 150 / rows;

        let calcX = Math.floor(((point % columns) ? (point % columns) : columns) * widthCol - (widthCol / 2));
        let calcY = Math.floor((Math.ceil(point / rows)) * heightRow - (heightRow / 2));
        return { x: calcX, y: calcY }
    }

    getLine = (a, b) => {
        let calcA = this.getXY(a);
        let calcB = this.getXY(b);
        return (<line x1={calcA.x} y1={calcA.y} x2={calcB.x} y2={calcB.y} style={{ stroke: '#ffffff', strokeWidth: 2 }} />);
    }

    LockPickerContent = () => {

        let rows = this.props.value.size;
        let columns = this.props.value.size;
        let widthCol = 150 / columns;

        let setValue = (num) => {
            let value = this.props.value.content;
            if (this.state.active && (value[value.length - 1] !== num)) {
                value.push(num);
                this.props.onChange(value);
            }
        }

        let getLines = () => {
            if (!this.state.animate) {
                let data = this.props.value.content;
                let res = [];
                if (data.length > 1) data.map((x, index) => { if (index > 0) res.push(this.getLine(data[index - 1], x)) });
                res.push(<line id={'current-line'} x1={0} y1={0} x2={0} y2={0} style={{ stroke: '#ffffff', strokeWidth: 2 }} />);
                return res.map((x) => x);
            }
        }

        let count = rows * columns;
        let res = [];
        for (let num = 1; num <= count; num++) {
            let valuePoint = num;
            let calc = this.getXY(num);
            res.push(<circle
                cx={calc.x}
                cy={calc.y}
                r={Math.floor(widthCol / 9)}
                fill={'#ffffff'}
                onMouseDown={() => {
                    this.setState({ active: true });
                    this.props.onChange([valuePoint]);
                }}
                onMouseOver={() => { setValue(valuePoint) }} />);
        }

        return (
            <svg id={'svg-table'}>
                {getLines()}
                {this.getAnimate()}
                {res.map((x) => x)}
            </svg>
        );
    }

    getAnimate = () => {
        if (this.state.animate) {
            let data = this.props.value.content;
            let lastPoint = data.length - 1;
            return data.map((x, index) => {
                if (index < lastPoint) {
                    let pointXY = this.getXY(x);
                    return (<line
                        id={'animate-line-' + (index + 1)}
                        x1={pointXY.x} y1={pointXY.y} x2={pointXY.x} y2={pointXY.y}
                        style={{ stroke: '#ffffff', strokeWidth: 2 }}
                    />);
                }
            });
        }
    }

    onAnimate = () => {
        let data = this.props.value.content;
        let createLine = (a, b, num) => {
            let newLine = document.getElementById('animate-line-' + num);
            let lastX = a.x;
            let lastY = a.y;
            let betwenX = (b.x - a.x) / 10;
            let betwenY = (b.y - a.y) / 10;
            for (let z = 0; z < 10; z++) {
                setTimeout(() => {
                    lastX += betwenX;
                    lastY += betwenY;
                    newLine.setAttribute('x2', lastX);
                    newLine.setAttribute('y2', lastY);
                }, z * 100);
            }
        }

        data.map((x, index) => {
            if (index > 0) {
                setTimeout(() => {
                    createLine(this.getXY(data[index - 1]), this.getXY(x), index)
                }, 1000 * index);
            }
        });
    }

    clearAnimate = () => {
        let data = this.props.value.content;
        let lastPoint = data.length - 1;
        if (data.length > 1) {
            data.map((x, index) => {
                if (index < lastPoint) {
                    let line = document.getElementById('animate-line-' + (index + 1));
                    let pointXY = this.getXY(x);
                    line.setAttribute('x2', pointXY.x);
                    line.setAttribute('y2', pointXY.y);
                }
            });
        }
    }

    render() {
        let frameStyle = {
            backgroundColor: '#666666',
            width: '150px',
            height: '150px',
            margin: 'auto',
            direction: 'ltr',
        }

        let buttonsStyle = {
            fontSize: 16,
            display: (window.innerWidth > 600) ? 'block' : 'inline-block',
            margin: '10px'
        }

       // //console.log('yuda props lockpicker ', this.props.value);

        return (<div>
            <Input dir={'ltr'} value={this.props.value.content} />
            <div style={(window.innerWidth > 600) ? { float: 'left' } : {}}>
                <Icon
                    style={buttonsStyle}
                    className={"clickable margin-4"}
                    type={"plus-circle"}
                    onClick={() => {
                        let num = (this.props.value.size < 10) ? this.props.value.size + 1 : this.props.value.size;
                        if (!this.props.value.content[0]) this.props.onSize(num);
                    }} />
                <Icon
                    style={buttonsStyle}
                    className={"clickable margin-4"}
                    type={"minus-circle"}
                    onClick={() => {
                        let num = (this.props.value.size > 2) ? this.props.value.size - 1 : this.props.value.size;
                        if (!this.props.value.content[0]) this.props.onSize(num);
                    }} />

                <Icon
                    style={buttonsStyle}
                    className={"clickable margin-4"}
                    type={"delete"}
                    onClick={() => {
                        this.props.onChange(false);
                        this.setState({ animate: false });
                    }} />
                <Icon
                    style={buttonsStyle}
                    className={"clickable margin-4"}
                    type={"eye"}
                    onClick={() => {
                        if (this.props.value.content[1]) {
                            if (this.state.animate) {
                                this.setState({ animate: false }, this.clearAnimate());
                                this.setState({ animate: true }, this.onAnimate());
                            } else {
                                this.setState({ animate: true }, this.onAnimate());
                            }
                        }
                    }} />
            </div>
            <div
                id={'lock-picker-table'}
                style={frameStyle}
                onMouseMove={(e) => {
                    let data = this.props.value.content;
                    if (data.length > 0 && this.state.active) {
                        let last = this.getXY(data[data.length - 1]);
                        let element = document.getElementById('svg-table').getBoundingClientRect();
                        let currentX = Math.ceil(e.pageX - element.x);
                        let currentY = Math.ceil(e.pageY - element.y);
                        let current = document.getElementById('current-line');
                        current.setAttribute('x1', last.x);
                        current.setAttribute('y1', last.y);
                        current.setAttribute('x2', currentX);
                        current.setAttribute('y2', currentY);
                    }
                }}
                onMouseDown={() => this.setState({ active: true })}
                onMouseUp={() => {
                    this.setState({ active: false });
                    document.getElementById('current-line').style.display = 'none';
                }} >
                {this.LockPickerContent()}
            </div>
        </div>);
    }
}

export default LockPicker;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import {
    Modal,
    Form,
    Input,
    InputNumber,
    Select,
    Switch,
    DatePicker,
    TimePicker,
    Divider,
    Row,
    Col,
    Radio,
    Tooltip,
    Button,
    Icon,
    Popconfirm,
    notification,
    message,
    Checkbox,
    Popover,
} from "antd";
import "../../App.css";
import ItemPicker from "../../components/ItemPicker";
import CustomerPicker from "../../components/CustomerPicker";
import moment from "moment";
import Jax from "../../Jax/jax.es6.module";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableIDs from '../../data/TableIDs.js';
import SelectorItems from "../../components/SelectorItems";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import ModalAttributeItemTags from "../../components/ModalAttributeItemTags";
import PictureView from "../../components/PictureView";
import ModalPriceListItemView from "../../components/ModalPriceListItemView";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelector from "../../components/GenericSelector";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

//
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const OptGroup = Select.OptGroup;


type BargainEditorProps = {
    visible: boolean,
    workInProgress: boolean,
    title: string,
    recordData: Object,
    saveAction: Function,
    cancelAction: Function,
    token: string,
    companyCode: string,
    zIndex: number,
    data: Object,
    bargainId: number,
    dataActions: Object,
    uiActions: Object,
    ActionQueue: any
};

type State = { isValidated: boolean };


export class BargainEditor extends Component<BargainEditorProps, State> {
    customerTags: Array<any>;
    itemTags: Array<any>;
    bargainTypes: Array<any>;
    posList: Array<any>;
    mutList: Array<any>;

    constructor(props: BargainEditorProps) {
        super(props);
        this.state = {
            isValidated: true,
            newItemCodeFilter: "",
            newItemNameFilter: "",
            newItemCodeTable: "",
            newItemNameTable: "",
            logoUrlList: {
                mLogoUrl: '',
                mLogoFree: '',
                mLogoUsed: '',
            },

            isToOccasionalCust: false,

            tagDataSelected: null,
        };

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        if (props.data["bargain"] === undefined) {
            props.data["bargain"] = Object.assign({}, props.data.genericData);
            props.data["bargain"].data.pos = [];
            props.data["bargain"].data.zhut = {};
            props.data["bargain"].data.conditions = {};
            props.data["bargain"].data.items = {};
            props.data["bargain"].data.customers = {};
            props.data["bargain"].editedRecord.pos = [];
            props.data["bargain"].editedRecord.zhut = {
                data: [],
                headers: [],
                editing: -1,
                pagination: {
                    current: 1,
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    total: 0,
                    hideOnSinglePage: false,
                    showQuickJumper: false,
                    showTotal: (total: number, range: Array<number>) => `  מציג ${range[0]} - ${range[1]} מתוך ${total}`
                }
            };
            props.data["bargain"].editedRecord.conditions = {};
            props.data["bargain"].editedRecord.items = {};
            props.data["bargain"].editedRecord.customers = {};
        }

        this.tags_id = "TAGS_TABLE_EDIT";
        if (props.data[this.tags_id] === undefined) props.data[this.tags_id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

        this.couponDeclarationList = { id: "COUPON_DECLARATION_LIST", api: "coupon_declaration_list", filters: { cdType: 0 } };
        if (props.data[this.couponDeclarationList.id] === undefined) {
            props.data[this.couponDeclarationList.id] = { ...props.data.genericSelector };
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.companyCode, this.props.token, "list_pos");

        this.customerTags = [];
        this.itemTags = [];
        this.bargainTypes = [];
        this.posList = [];
        this.mutList = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    handleModalCancel() {
        this.props.cancelAction();
        this.clearItemSelector();
    }

    sendAPI = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }

    handleModalOk() {
        // let eRecord = this.props.data["bargain"].editedRecord;

        // if (eRecord && eRecord.bargainDateFrom && eRecord.bargainDateTo) {
        if (this.handleFinalValidation()) {
            this.props.dataActions.networkPostJsonAndThenFetchData(
                this.props.companyCode,
                this.props.token,
                {
                    script: "data_set_json",
                    params: {
                        dataObject: "bargain"
                    }
                },
                {
                    script: "get_bargains_Table", // "data_get",
                    params: this.props.data.bargains.filters,
                    //  {
                    //     dataObject: "bargains"
                    // },
                },
                "bargain.editedRecord",
                "",
                "bargains",
                {
                    loading: this.getText(10457),
                    failed: this.getText(10458),
                    success: this.getText(10459)
                },
                {
                    loading: this.getText(10460),
                    failed: this.getText(10461),
                    success: this.getText(10462)
                },
                false,
                {
                    action: this.props.uiActions.hideModalByName,
                    args: ["bargainEditor"]
                },
                (actionType: string, store: Object, response: string) => {
                    if (actionType === "NETWORK_POST_JSON_AND_THEN_FETCH_DATA_SUCCESS") {
                        let res = response ? JSON.parse(response) : {}
                        this.setMivzaimUrl(res.bargainId);
                        // console.log('response', JSON.parse(response));
                        return Jax.set(store, "bargain.editing", -1);
                    }
                    return store;
                }
            );
        } else {
            notification.open({
                message: this.getText(10463),
                description: this.getText(10464),
            });
        }
        this.clearItemSelector();
        // } else {
        //     message.error(this.getText())
        // }
    }

    clearItemSelector = () => {
        // this.setState({
        //     newItemCodeFilter: "",
        //     newItemNameFilter: "",
        //     newItemCodeTable: "",
        //     newItemNameTable: "",
        // });
    }

    getMivzaimUrl = () => {
        this.sendAPI("get_mivzaim_url", this.props.bargainId, (ob) => {
            let y = ob && ob.data ? ob.data.split("\f") : ['', '', ''];
            this.setState({
                logoUrlList: {
                    mLogoUrl: y[0],
                    mLogoFree: y[1],
                    mLogoUsed: y[2],
                }
            });
        }, (e) => { console.error(e) })
    }

    setMivzaimUrl = (bargainId) => {
        if (parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10) == 9 && bargainId) {

            const { mLogoUrl, mLogoFree, mLogoUsed } = this.state.logoUrlList;
            let dataSend = "mbargainId\fmLogoUrl\fmLogoFree\fmLogoUsed\r" +
                bargainId + "\f" + mLogoUrl + "\f" + mLogoFree + "\f" + mLogoUsed;

            this.sendAPI("set_mivzaim_url", dataSend, (ob) => {
                this.setState({
                    logoUrlList: {
                        mLogoUrl: '',
                        mLogoFree: '',
                        mLogoUsed: '',
                    }
                });
            }, (e) => { console.error(e) })
        }
    }

    componentDidUpdate(prevProps: BargainEditorProps) {
        let showTotal = (total, range) => '  ' + this.getText(10372) + ' ' + range[0] + ' - ' + range[1] + ' ' + this.getText(10373) + ' ' + total;
        this.props.data["bargain"].editedRecord.zhut = {
            ...this.props.data["bargain"].editedRecord.zhut,
            showTotal: showTotal
        }

        if (this.props.bargainId !== prevProps.bargainId && this.props.bargainId > 0) {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericJsonGetAndEdit,
                args: ["bargain", this.props.companyCode, this.props.token, {
                    dataObject: "bargain",
                    bargainId: this.props.bargainId
                }, "data_get_json"]
            });


            setTimeout(() => {

                const { editedRecord } = this.props.data["bargain"];
                const { customerId, tagId, allCustomers } = editedRecord && editedRecord["customers"] ? editedRecord["customers"] : {}
                this.setState({ isToOccasionalCust: (!customerId && !tagId && allCustomers != 1) })

            }, 1000);

            this.getMivzaimUrl();
        }
    }

    getMutBonusDaysToLiveType9 = (def) => {
        let initValue = Jax.get(this.props.data, "bargain.editedRecord.zhutProperties.mutBonusDaysToLive", def);
        // let zList = Jax.get(this.props.data, "bargain.editedRecord.zhut.data", []);
        // return zList[0] ? zList[0].mutBonusDaysToLive : initValue;
        return initValue;
    }

    setMutBonusDaysToLiveType9 = (value) => {
        // let zList = Jax.get(this.props.data, "bargain.editedRecord.zhut.data", []);
        // if (zList.length) this.props.dataActions.setJson("bargain.editedRecord.zhut", { data: zList.map(x => { return { ...x, mutBonusDaysToLive: value } }) })
        // else 
        this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhutProperties", { mutBonusDaysToLive: value })
    }

    handleFinalValidation() {
        let eRecord = this.props.data.bargain.editedRecord ? this.props.data.bargain.editedRecord : {}
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let conditionAmountTo: number = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.amountTo", 0));
        let conditionAmountFrom: number = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.amountFrom", 0));
        let conditionMultiplier: number = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.multiplier", 0));
        let zhutCount = Jax.get(this.props.data, "bargain.editedRecord.zhut.data", []).length;
        let zhutRecord = Jax.get(this.props.data, "bargain.editedRecord.zhut.data", [])[0];
        let mutDaysToLive = Jax.get(this.props.data, "bargain.editedRecord.zhutProperties.mutDaysToLive", -1);
        // let mutBonusDaysToLive = Jax.get(this.props.data, "bargain.editedRecord.zhutProperties.mutBonusDaysToLive", -1);
        let mutBonusDaysToLive = this.getMutBonusDaysToLiveType9(-1);
        let isEditing = Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1);
        let zhutRecordFields = 0;

        if (!eRecord.bargainDateFrom || !eRecord.bargainDateTo) return false;

        if (bargainTypeId === 0) {
            return false;
        }

        if (isEditing > -1) {
            return false;
        }

        if (zhutRecord) {
            for (let i in zhutRecord) {
                if (zhutRecord.hasOwnProperty(i)) {
                    if (i !== "number" && i !== "key" && i !== "index") {
                        zhutRecordFields++;
                    }
                }
            }

            if (zhutRecordFields === 0) {
                window.console.log("Rule: Empty zhut record");
                return false;
            }
        }

        switch (bargainTypeId) {
            case 1:
            case 2:
                if (isNaN(conditionAmountTo) || isNaN(conditionAmountFrom) || isNaN(conditionMultiplier)) {
                    window.console.log("Rule 1");
                    return false;
                }
                window.console.log(conditionAmountTo, conditionAmountFrom, conditionMultiplier);
                if (conditionAmountTo <= 0 || conditionAmountFrom <= 0 || conditionMultiplier <= 0) {
                    window.console.log("Rule 2");
                    return false;
                }

                window.console.log("Rule 3");
                return zhutCount === 1;
            case 3:
                if (isNaN(conditionMultiplier) || conditionMultiplier <= 0) {
                    return false;
                }
                if (zhutCount === 1) {
                    return (!isNaN(zhutRecord.bonusQuantity) && zhutRecord.bonusQuantity > 0 && zhutRecord.bonusQuantity < conditionMultiplier);
                } else {
                    return false;
                }
            case 4:
                if (isNaN(conditionMultiplier) || conditionMultiplier <= 0) {
                    return false;
                }
                return zhutCount === conditionMultiplier;
            case 5:
                if (isNaN(conditionMultiplier) || conditionMultiplier <= 0) {
                    return false;
                }
                if (conditionAmountTo <= 0 || conditionAmountFrom <= 0) {
                    return false;
                }
                return zhutCount > 0;
            case 6:
                if (isNaN(conditionAmountTo) || isNaN(conditionAmountFrom) || isNaN(conditionMultiplier)) {
                    return false;
                }
                if (conditionAmountTo <= 0 || conditionAmountFrom <= 0 || conditionMultiplier < 0) {
                    return false;
                }
                return zhutCount > 0;
            case 7:
                if (isNaN(conditionAmountFrom) || conditionAmountFrom <= 0 || isNaN(conditionMultiplier)) {
                    return false;
                }
                return zhutCount === 1;
            case 8:
                if (isNaN(conditionAmountFrom) || conditionAmountFrom <= 0 || isNaN(conditionAmountTo) || conditionAmountTo <= 0) {
                    return false;
                }
                return zhutCount === 1;
            case 9:
                if (isNaN(conditionMultiplier) || conditionMultiplier <= 0 || isNaN(conditionAmountFrom) || conditionAmountFrom <= 0) {
                    console.log("Rule 1");
                    return false;
                }
                window.console.log(mutDaysToLive, mutBonusDaysToLive);
                if (isNaN(mutDaysToLive) || isNaN(mutBonusDaysToLive) || mutDaysToLive <= 0 || mutBonusDaysToLive <= 0) {
                    console.log("Rule 2");
                    return false;
                }
                console.log("Rule 3");
                return zhutCount > 0;
            case 10:
                return zhutCount > 0;
            case 11:
                if (isNaN(conditionMultiplier) || conditionMultiplier <= 0) {
                    return false;
                }
                if (conditionAmountTo <= 0 || conditionAmountFrom <= 0) {
                    return false;
                }
                return zhutCount === 1;
            case 12:
                if (isNaN(conditionMultiplier) || conditionMultiplier <= 0 || isNaN(conditionAmountTo) || conditionAmountTo <= 0) {
                    return false;
                }
                return zhutCount > 0;
            default:
                return true;
        }
    }

    static getSortColumnId(name: string): number {
        let sortColumn = 0;
        switch (name) {
            case "madrega":
                sortColumn = 1;
                break;
            case "discount":
                sortColumn = 2;
                break;
            case "price":
                sortColumn = 3;
                break;
            case "itemCode":
                sortColumn = 4;
                break;
            case "tagId":
                sortColumn = 5;
                break;
            case "bonusQuantity":
                sortColumn = 6;
                break;
            case "isForSubscription":
                sortColumn = 7;
                break;
            case "mutId":
                sortColumn = 8;
                break;
            default:
                sortColumn = 0;
        }

        return sortColumn;
    }

    handleTableChange(pagination: Object, filters: Object, sorter: Object) {
        const sortColumn = BargainEditor.getSortColumnId(sorter.field);
        const sortType = sorter.order === undefined || sorter.order === "ascend" ? 1 : 0;
        const sortingChanged = this.props.data["bargain"].editedRecord["zhut"].pagination.sortType !== sortType || this.props.data["bargain"].editedRecord["zhut"].pagination.sortColumn !== sortColumn;
        let request = {
            page: sortingChanged ? 1 : pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sortType,
            sortColumn
        };

        this.props.dataActions.genericDataSetFilter("bargain.zhut", this.props.companyCode, this.props.token, request, "");

        window.console.log(filters);
    }

    validateAmountFrom() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let value = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.amountFrom", 0));
        let multiplier = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.multiplier", 0));
        let res = {};

        switch (bargainTypeId) {
            case 1:
            case 2:
            case 6:
            case 7:
            case 8:
            case 9:
                if (value > 0) {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "error";
                    res.help = this.getText(10484);
                    res.hasFeedback = true;
                    res.disabled = false;
                }
                break;

            case 4:
                if (value > 0 && value <= multiplier) {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "error";
                    res.help = this.getText(18104);
                    res.hasFeedback = true;
                    res.disabled = false;
                }
                break;

            // case 3:
            case 10:
                res.validateStatus = "";
                res.help = "";
                res.hasFeedback = false;
                res.disabled = true;
                break;

            default:
                res.validateStatus = "";
                res.help = "";
                res.hasFeedback = false;
                res.disabled = false;
                break;
        }

        return res;
    }

    validateAmountTo() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let value = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.amountTo", 0));
        let multiplier = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.multiplier", 0));
        let res = {};

        switch (bargainTypeId) {
            case 1:
            case 2:
            case 6:
            case 12:
                if (value > 0) {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "error";
                    res.help = this.getText(10484);
                    res.hasFeedback = true;
                    res.disabled = false;
                }
                break;

            case 4:
                if (value > 0 && !(value % multiplier)) {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "error";
                    res.help = this.getText(18105);
                    res.hasFeedback = true;
                    res.disabled = false;
                }
                break;

            // case 3:
            // case 7:
            // case 9:
            case 10:
                res.validateStatus = "";
                res.help = "";
                res.hasFeedback = false;
                res.disabled = true;
                break;
            default:
                res.validateStatus = "";
                res.help = "";
                res.hasFeedback = false;
                res.disabled = false;
                break;
        }

        return res;
    }

    validateMultiplier() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let value = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.multiplier", -1));
        let res = {};

        switch (bargainTypeId) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 9:
            case 11:
            case 12:
                if (value > 0) {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "error";
                    res.help = this.getText(10484);
                    res.hasFeedback = true;
                    res.disabled = false;
                }
                break;
            // case 5:
            //     res.validateStatus = "";
            //     res.help = "";
            //     res.hasFeedback = false;
            //     res.disabled = true;
            //     break;
            case 6:
            case 7:
                if (value >= 0) {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "error";
                    res.help = this.getText(10485);
                    res.hasFeedback = true;
                    res.disabled = false;
                }
                break;
            case 8:
            case 10:
                res.validateStatus = "";
                res.help = "";
                res.hasFeedback = false;
                res.disabled = true;
                break;
            default:
                res.validateStatus = "";
                res.help = "";
                res.hasFeedback = false;
                res.disabled = false;
                break;
        }

        return res;
    }

    shouldAddNewZhutDisabled() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let count = Jax.get(this.props.data, "bargain.editedRecord.zhut.data", []).length;
        let size = Jax.get(this.props.data, "bargain.editedRecord.conditions.multiplier", 0);

        switch (bargainTypeId) {
            case 1:
            case 2:
            case 3:
            case 7:
            case 8:
            case 9:
            case 11:
                return (count > 0);
            case 4:
                return (count >= size);
            default:
                return false;
        }

    }

    validateZhutCount() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let count = Jax.get(this.props.data, "bargain.editedRecord.zhut.data", []).length;
        let size = Jax.get(this.props.data, "bargain.editedRecord.conditions.multiplier", 0);
        let res = {};

        switch (bargainTypeId) {
            case 1:
            case 2:
            case 3:
            case 7:
            case 8:
            case 9:
            case 11:
                if (count > 1 || count === 0) {
                    res.validateStatus = "error";
                    res.help = this.getText(10486);
                    res.hasFeedback = false;
                    res.disabled = false;
                }
                else {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                }
                break;
            case 4:
                if (count !== size || count === 0) {
                    res.validateStatus = "error";
                    res.help = this.getText(10487);
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                }
                break;
            case 10:
            default:
                if (count > 0) {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "error";
                    res.help = this.getText(10488);
                    res.hasFeedback = false;
                    res.disabled = false;
                }
                break;
        }

        return res;
    }

    getCustomerRadioValue() {
        if (this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["customers"] && parseInt(this.props.data["bargain"].editedRecord["customers"].allCustomers, 10) === 1) {
            return 3;
        } else if (this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["customers"] && this.props.data["bargain"].editedRecord["customers"].tagId != null) {
            return 2;
        } else if (this.state.isToOccasionalCust) {
            return 4;
        } else {
            return 1;
        }
    }

    setCustomerRadioValue(value: number) {
        if (value === 1 || value === 4) {
            this.setState({ isToOccasionalCust: value === 4 }, () => {
                this.props.dataActions.genericDataSetEdit("bargain.editedRecord.customers", {
                    allCustomers: 0,
                    tagId: null
                });
            })
        } else if (value === 2) {
            let tagId = this.props.data["bargain"].editedRecord["customers"].tagId || 0;
            this.props.dataActions.genericDataSetEdit("bargain.editedRecord.customers", {
                allCustomers: 0,
                customerId: null,
                customerName: null,
                tagId
            });
        } else if (value === 3) {
            this.props.dataActions.genericDataSetEdit("bargain.editedRecord.customers", {
                allCustomers: 1,
                tagId: null,
                customerId: null,
                customerName: null
            });
        }
    }

    shouldCustomerPickerBeDisabled() {
        return (this.getCustomerRadioValue() !== 1)
    }

    shouldCustomerTagsBeDisabled() {
        return (this.getCustomerRadioValue() !== 2)
    }

    getItemRadioValue() {
        if (this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["items"] && parseInt(this.props.data["bargain"].editedRecord["items"].allItems, 10) === 1) {
            return 3;
        } else if (this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["items"] && this.props.data["bargain"].editedRecord["items"].tagId != null) {
            return 2;
        } else {
            return 1;
        }
    }

    setItemRadioValue(value: number) {
        if (value === 1) {
            this.props.dataActions.genericDataSetEdit("bargain.editedRecord.items", { allItems: 0, tagId: null });
        } else if (value === 2) {
            let tagId = this.props.data["bargain"].editedRecord["items"].tagId || 0;
            this.props.dataActions.genericDataSetEdit("bargain.editedRecord.items", {
                allItems: 0,
                itemCode: null,
                itemName: null,
                tagId
            });
        } else {
            this.props.dataActions.genericDataSetEdit("bargain.editedRecord.items", {
                allItems: 1,
                tagId: null,
                itemCode: null,
                itemName: null
            });
        }
    }

    shouldItemPickerBeDisabled() {
        return (this.getItemRadioValue() !== 1)
    }

    shouldItemTagsBeDisabled() {
        return (this.getItemRadioValue() !== 2)
    }

    shouldConditionTypeQuantityBeDisabled() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        switch (bargainTypeId) {
            case 7:
            case 8:
            // case 9:
            case 10:
                return true;
            default:
                return false;
        }
    }

    shouldConditionTypePriceBeDisabled() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        switch (bargainTypeId) {
            case 3:
            case 4:
            case 10:
                return true;
            default:
                return false;
        }
    }

    filterColumns(col: Object) {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);

        switch (bargainTypeId) {
            case 1:
            case 2:
                switch (col.key) {
                    case "discount":
                    case "price":
                    case "actions":
                        return true;
                    default:
                        return false;
                }
            case 3:
                switch (col.key) {
                    case "discount":
                    case "price":
                    case "actions":
                    case "bonusQuantity":
                        return true;
                    default:
                        return false;
                }
            case 4:
                switch (col.key) {
                    case "key":
                    case "discount":
                    case "actions":
                        return true;
                    default:
                        return false;

                }
            case 5:
            case 11:
                switch (col.key) {
                    case "discount":
                    case "price":
                    case "itemCode":
                    case "tagId":
                    case "bonusQuantity":
                    case "actions":
                        return true;
                    default:
                        return false;
                }
            case 6:
                switch (col.key) {
                    case "bonText":
                    case "actions":
                        return true;
                    default:
                        return false;

                }
            case 7:
                switch (col.key) {
                    case "discount":
                    case "discountFixed":
                    case "actions":
                        return true;
                    default:
                        return false;
                }
            case 8:
                switch (col.key) {
                    case "voucherSum":
                    case "voucherDays":
                    case "actions":
                        return true;
                    default:
                        return false;
                }
            case 9:
                switch (col.key) {
                    // case "itemCode":
                    // case "tagId":
                    // case "price":
                    // case "bonusQuantity":
                    // case "discount":
                    case "actions":
                    case "key":
                    case "mutId":
                    case "mutDaysToLive":
                    case "mutBonusDaysToLive":
                        return true;
                    default:
                        return false;
                }
            case 10:
                switch (col.key) {
                    case "price":
                    case "discount":
                    case "isFromPriceList":
                    case "itemCode":
                    case "bonusQuantity":
                    case "isForSubscription":
                    case "actions":
                    case "key":
                        return true;
                    case "mutBonusDaysToLive":
                        return (parseInt(Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.isForSubscription"), 10) === 1);
                    default:
                        return false;
                }
            case 12:
                switch (col.key) {
                    case "itemCode":
                    case "tagId":
                    case "bonusQuantity":
                    case "actions":
                        return true;
                    default:
                        return false;
                }
            default:
                return true;
        }
    }

    getTableWidth() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);

        switch (bargainTypeId) {
            case 1:
            case 2:
                return 810;
            default:
                return 1300;
        }
    }

    onDiscountChange = () => {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);

        switch (bargainTypeId) {
            case 1:
            case 2:
            case 3:
            case 5:
            case 7:
            case 9:
            case 11:
                this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", { price: null });
                break;

            default:
                break;
        }

    }

    onPriceChange = () => {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);

        switch (bargainTypeId) {
            case 1:
            case 2:
            case 3:
            case 5:
            case 7:
            case 9:
            case 11:
                this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", { discount: null });
                break;

            default:
                break;
        }

    }

    onItemCodeChange() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);

        switch (bargainTypeId) {
            case 6:
            case 9:
            case 12:
                this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", { tagId: null });
                break;

            default:
                break;
        }
    }

    onTagChange() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);

        switch (bargainTypeId) {
            case 6:
            case 9:
            case 12:
                this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", {
                    itemCode: "",
                    itemName: ""
                });
                break;

            default:
                break;
        }
    }

    getBonusQuantityValidation() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let value = Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.bonusQuantity", -1);
        let multiplier = Jax.get(this.props.data, "bargain.editedRecord.conditions.multiplier", -1);
        let validation = {};

        switch (bargainTypeId) {
            case 3:
                if (!(value > 0 && value < multiplier)) {
                    validation.validateStatus = "error";
                    validation.help = this.getText(10489);
                    validation.hasFeedback = true;
                    validation.disabled = false;
                } else {
                    validation.validateStatus = "";
                    validation.help = "";
                    validation.hasFeedback = false;
                    validation.disabled = false;
                }
                break;

            case 10:
                if (!(value > 0 && Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.itemCode", ""))) {
                    validation.validateStatus = "error";
                    validation.help = this.getText(18480);
                    validation.hasFeedback = true;
                    validation.disabled = false;
                }

            case 12:
                if (value <= 0) {
                    validation.validateStatus = "error";
                    validation.help = this.getText(18480);
                    validation.hasFeedback = true;
                    validation.disabled = false;
                } else {
                    validation.validateStatus = "";
                    validation.help = "";
                    validation.hasFeedback = false;
                    validation.disabled = false;
                }
                break;

            default:
                break;
        }

        return validation;
    }

    onBargainTypeChange(value: string) {
        let bargainTypeId = parseInt(value, 10);
        let state = Jax.mutate(this.props.data, "bargain.editedRecord", { bargainTypeId: value, bargainCalcOrder: value == 7 ? 999999999 : 0 });

        switch (bargainTypeId) {
            case 1:
            case 2:
                break;
            case 3:
            case 4:
            case 5:
            case 6:
            case 11:
                state = Jax.mutate(state, "bargain.editedRecord.conditions", { conditionType: 1 });
                break;
            case 7:
            case 8:
            case 9:
                state = Jax.mutate(state, "bargain.editedRecord.conditions", { conditionType: 2 });
                break;
            case 10:
                break;
            case 12:
                state = Jax.mutate(state, "bargain.editedRecord.conditions", { amountFrom: 0 });
                state = Jax.mutate(state, "bargain.editedRecord.items", {
                    allItems: 1,
                    tagId: null,
                    itemCode: null,
                    itemName: null
                });
                break;
            default:
                break;
        }

        this.props.dataActions.setNewDataState(state);
    }

    shouldMutDaysToLiveBeDisplayed() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        return bargainTypeId === 9;
    }

    validateMutDaysToLive() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let value = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.zhutProperties.mutDaysToLive", 0));
        let res = {};

        switch (bargainTypeId) {
            case 9:
                if (value > 0) {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "error";
                    res.help = this.getText(10484);
                    res.hasFeedback = true;
                    res.disabled = false;
                }
                break;


            default:
                res.validateStatus = "";
                res.help = "";
                res.hasFeedback = false;
                res.disabled = false;
                break;
        }

        return res;
    }

    shouldMutBonusDaysToLiveBeDisplayed() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        return bargainTypeId === 9;
    }

    validateMutBonusDaysToLive() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        // let value = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.zhutProperties.mutBonusDaysToLive", 0));
        let value = this.getMutBonusDaysToLiveType9(0);
        let res = {};

        switch (bargainTypeId) {
            case 9:
                if (value > 0) {
                    res.validateStatus = "";
                    res.help = "";
                    res.hasFeedback = false;
                    res.disabled = false;
                } else {
                    res.validateStatus = "error";
                    res.help = this.getText(10484);
                    res.hasFeedback = true;
                    res.disabled = false;
                }
                break;


            default:
                res.validateStatus = "";
                res.help = "";
                res.hasFeedback = false;
                res.disabled = false;
                break;
        }

        return res;
    }

    shouldMadregaNoteBeDisplayed() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        return bargainTypeId === 4;
    }

    getMultiplierLabel() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);

        switch (bargainTypeId) {
            case 4:
                return this.getText(10490)
            case 9:
                // return this.getText(10491);
                return this.getText(18701);
            case 12:
                return this.getText(19283);

            default:
                return this.getText(10492);
        }
    }

    validateBargainType() {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let res = {};

        if (bargainTypeId <= 0) {
            res.validateStatus = "error";
            res.help = this.getText(10493);
            res.hasFeedback = true;
            res.disabled = false;
        } else {
            res.validateStatus = "";
            res.help = "";
            res.hasFeedback = false;
            res.disabled = false;
        }

        return res;
    }

    checkIfItemExist = (s, p) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
            this.sendAPI("check_all_item_code", dataSend, (ob) => {
                let itemsRows = (ob.data).split("\r");
                let _ItemData = itemsRows[0].split('\f');
                let _ItemCode = _ItemData[1];
                let _ItemName = _ItemData[2];

                if (!_ItemCode || itemsRows.length > 1) {
                    this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data, idSelector: p });
                } else {
                    this.onChangeItemCode(_ItemCode, _ItemName, p)
                }
            }, (error) => {
                message.error(this.getText(10494));
            });
        } else {
            this.onChangeItemCode("", "", p)
        }
    }

    keyboardListener = (e, p) => {
        let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                } else {
                    bufferUpdate(e.key)
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                this.checkIfItemExist(this.barcodeReaderBuffer, p)
                break;

            default:
                bufferUpdate(e.key)
                break;
        }
    }

    keyboardListenerFilter(e) {
        this.keyboardListener(e, "filter");
    }

    keyboardListenerTable(e) {
        this.keyboardListener(e, "table");
    }

    onChangeItemCode = (code, name, picker) => {
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            if (picker === "filter") {
                if (!this.shouldItemPickerBeDisabled()) {
                    this.setState({ newItemCodeFilter: code, newItemNameFilter: name }, () => {
                        this.props.dataActions.genericDataSetEdit("bargain.editedRecord.items", { itemCode: code, itemName: name });
                    });
                }
            } else {
                const { bargainTypeId, zhut } = this.props.data.bargain.editedRecord
                const itemIsExists = zhut.data.find(f => f.itemCode === code)
                console.log("check_item_zhut", itemIsExists, bargainTypeId)
                if (bargainTypeId == '10' && itemIsExists) {
                    message.error(this.getText(17781));
                    return;
                }
                this.setState({ newItemCodeTable: code, newItemNameTable: name }, () => {
                    this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", { itemCode: code, itemName: name });
                    this.onItemCodeChange()
                });
            }
        }
    }

    getMivzaZhutUpdateDealOption = (record) => {
        let data = record.updateDealOption ? parseInt(record.updateDealOption) : 0;
        let onChange = (value) => {
            let updateDealOption = (data & 1) + (data & 2) + (data & 4) + (data & 8) + value;
            let newData = [...Jax.get(this.props.data, "bargain.editedRecord.zhut.data", [])];
            newData[record.index] = { ...newData[record.index], updateDealOption }
            this.props.dataActions.setJson("bargain.editedRecord.zhut", { data: newData })
        }

        return (<div
            style={{ direction: 'rtl', textAlign: 'right' }}>
            <Checkbox checked={data & 1} onChange={e => { onChange(e.target.checked ? 1 : -1) }}>{this.getText(16429)}</Checkbox><br />
            <Checkbox checked={data & 2} onChange={e => { onChange(e.target.checked ? 2 : -2) }}>{this.getText(16430)}</Checkbox><br />
            <Checkbox checked={data & 4} onChange={e => { onChange(e.target.checked ? 4 : -4) }}>{this.getText(16431)}</Checkbox><br />
            <Checkbox checked={data & 8} onChange={e => { onChange(e.target.checked ? 8 : -8) }}>{this.getText(16432)}</Checkbox><br />
        </div>)
    }

    renderZhutInputNumber = (text, record, field, disabled, nextOnChange, formItemProps, conditionMultiplier) => {
        if (Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key) {
            let val = Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord." + field);
            let input = (<InputNumber
                disabled={disabled}
                value={conditionMultiplier && val ? Math.round(val * conditionMultiplier * 100) / 100 : val}
                onChange={(value) => {
                    this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", { [field]: conditionMultiplier && value ? Math.round((value / conditionMultiplier) * 10000) / 10000 : value });
                    if (nextOnChange) nextOnChange()
                }} />);

            return formItemProps ? (<FormItem {...formItemProps}>{input}</FormItem>) : input
        } else {
            return conditionMultiplier && text ? Math.round(text * conditionMultiplier, 2) : text;
        }
    }

    renderZhutItemCode = (text, record) => {
        if (Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key) {
            return (<span>
                <SelectorItems
                    idSelector={"table"}
                    user={this.props.user}
                    uiActions={this.props.uiActions}
                    ui={this.props.ui}
                    dataActions={this.props.dataActions}
                    data={this.props.data}
                    ActionQueue={this.props.ActionQueue}
                    ctrlBKeyFlag={null}
                    isExpandMatrixFatherItem={false}
                    itemType={1}
                    value={this.props.data.bargain.editedRecord.zhut.editedRecord.itemCode}
                    onChangeSearch={(s) => { this.onChangeItemCode(s.mCode, s.mName, "table") }}
                    onChange={(s) => { this.setState({ newItemCodeTable: s.target.value }) }}
                    onBlur={(s) => { this.checkIfItemExist(this.state.newItemCodeTable, "table") }}
                    onKeyDown={this.keyboardListenerTable.bind(this)}
                />
                <h4>{this.props.data.bargain.editedRecord.zhut.editedRecord ?
                    this.props.data.bargain.editedRecord.zhut.editedRecord.itemName :
                    this.getText(10476)}</h4>
            </span>)
        } else {
            return record.itemCode ? record.itemCode + " - " + record.itemName : "";
        }
    }

    renderZhutTagId = (text, record) => {
        if (Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key) {
            return (<GenericSelectorItemTags {...this.props} key={"zhut_item_tag_" + record.key} notReport
                //     //Select className={""} combobox={null} labelInValue notFoundContent={this.getText(10477)}
                onChange={(value) => {
                    let tagName = '';
                    this.props.data.GENERIC_MULTI_SELECTOR_ITEM_TAGS.dataset.forEach(x => {
                        x.tags.forEach(y => {
                            if (y.id === value) tagName = y.text;
                        })
                    })
                    this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", {
                        tagId: value ? value : null,
                        tagName
                    });
                    this.onTagChange();
                }}
                //     // style={{ width: 250 }} size={"large"}
                value={Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.tagId") ?
                    // {
                    // key: 
                    // parseInt(
                    Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.tagId")
                    // , 10)
                    // ,
                    // label: Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.tagName")
                    // } 
                    : ""
                    // { key: "" }
                }
            // // >
            // //     {this.itemTags}
            // // </Select>
            />);
        } else {
            return (record.tagName ? record.tagName : "");
        }
    }

    renderZhutMadrega = (text, record) => {
        return this.renderZhutInputNumber(text, record, 'madrege');
    }

    renderZhutDiscount = (text, record) => {
        let eRecord = this.props.data.bargain.editedRecord ? this.props.data.bargain.editedRecord : {}
        return this.renderZhutInputNumber(text, record, 'discount', eRecord.bargainTypeId == 10 && eRecord.zhut.editedRecord && eRecord.zhut.editedRecord.isFromPriceList != '1', this.onDiscountChange)
    }

    renderZhutPrice = (text, record) => {
        let eRecord = this.props.data.bargain.editedRecord ? this.props.data.bargain.editedRecord : {}
        let bargainTypeId = eRecord.bargainTypeId;
        let conditionMultiplier = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.multiplier", 0));
        let type2multi = bargainTypeId == '2' && conditionMultiplier > 1;

        return this.renderZhutInputNumber(text, record, 'price', eRecord.bargainTypeId == 10 && eRecord.zhut.editedRecord && eRecord.zhut.editedRecord.isFromPriceList == '1', this.onPriceChange, {}, type2multi ? conditionMultiplier : null)
    }

    renderZhutBonusQuantity = (text, record) => {
        return this.renderZhutInputNumber(text, record, 'bonusQuantity', false, false, this.getBonusQuantityValidation())
    }

    renderZhutIsFromPriceList = (text, record) => {
        let isEdited = Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key;
        let source = isEdited ? Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.isFromPriceList") : text
        return (
            <Checkbox
                disabled={!isEdited}
                checked={source == '1'}
                onChange={(e) => {
                    if (isEdited) {
                        let dataChange = (e.target.checked) ? { isFromPriceList: '1', price: 0 } : { isFromPriceList: '0', discount: 0 }
                        this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", dataChange);
                    }
                }} />);
    }

    renderZhutIsForSubscription = (text, record) => {
        if (Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key) {
            return (<Switch size={"default"}
                defaultChecked={parseInt(Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.isForSubscription"), 10) === 1}
                onChange={(value) => {
                    this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", {
                        isForSubscription: value ? 1 : 0,
                        mutBonusDaysToLive: value ? Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.mutBonusDaysToLive") : ""
                    });
                }} />);
        } else {
            return parseInt(record.isForSubscription, 10) === 1 ? this.getText(10478) : this.getText(10479);
        }
    }

    renderZhutMutBonusDaysToLive = (text, record) => {
        let bargainTypeId = parseInt(Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0), 10);
        let localStore = bargainTypeId === 9 ? "bargain.editedRecord.zhutProperties" : "bargain.editedRecord.zhut.editedRecord"
        const value = Jax.get(this.props.data, localStore + ".mutBonusDaysToLive")

        if (Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key) {
            return (<FormItem
                validateStatus={value ? "" : "error"}
                help={value ? "" : this.getText(bargainTypeId === 9 ? 10484 : 18524)}
                hasFeedback={!value}>
                <InputNumber
                    value={value}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(localStore, { mutBonusDaysToLive: value })
                    }} />
            </FormItem>);
        } else {
            return bargainTypeId === 9 ? value : text
        }
    }

    renderZhutMutDaysToLive = (text, record) => {
        console.log()
        const value = Jax.get(this.props.data, "bargain.editedRecord.zhutProperties.mutDaysToLive");
        if (Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key) {
            return (<FormItem
                validateStatus={value ? "" : "error"}
                help={value ? "" : this.getText(10484)}
                hasFeedback={!value}>
                <InputNumber
                    value={value}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhutProperties", { mutDaysToLive: value })
                    }} />
            </FormItem>);
        } else {
            return value;
        }
    }

    renderZhutMutId = (text, record) => {
        if (Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key) {
            return (<GenericSelector
                {...this.props}
                {...this.couponDeclarationList}
                value={Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.mutId")}
                onChange={() => { }}
                onChangeGetDetailed={(value) => {
                    this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", {
                        mutId: value?.code,
                        mutDescription: value?.name
                    });
                }}
            />)
            // <Select className={""} combobox={null} labelInValue notFoundContent={this.getText(10480)}
            //     onChange={(value) => {
            //         this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", {
            //             mutId: value.key,
            //             mutDescription: value.label
            //         });
            //     }}
            //     style={{ width: 250 }} size={"large"}
            //     value={Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.mutId") ? {
            //         key: parseInt(Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.mutId"), 10),
            //         label: Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.mutDescription")
            //     } : { key: "" }}
            // >
            //     {this.mutList}
            // </Select>;
        } else {
            const { dataset } = this.props.data[this.couponDeclarationList.id]
            const findCoupon = dataset.find(f => f.code == text)
            return findCoupon ? findCoupon.code + " - " + findCoupon.name : ""
        }
    }

    renderBonText = (text, record) => {
        if (Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key) {
            return (<Input.TextArea
                rows={2}
                onChange={(value) => {
                    this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", {
                        bonText: value.target.value
                    });
                }}
                value={Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.bonText")}
            />);
        } else {
            return text;
        }
    }

    renderZhutActions = (text, record) => {
        if (Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) === record.key) {
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                    onClick={() => {
                        let validation = this.getBonusQuantityValidation();
                        if (validation.validateStatus == "error") {
                            message.error(validation.help)
                        } else if (
                            parseInt(Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.isForSubscription"), 10) === 1
                            && !Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.mutBonusDaysToLive")
                        ) {
                            message.error(this.getText(18524))
                        } else {
                            let editedRecord = Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord", {});
                            if (parseInt(editedRecord.key, 10) === 0) {
                                editedRecord.key = Jax.get(this.props.data, "bargain.editedRecord.zhut.count", 0) + 1;
                                this.props.dataActions.dataTableAddRow("bargain.editedRecord.zhut", editedRecord, true);
                            } else {
                                this.props.dataActions.dataTableSetRow("bargain.editedRecord.zhut", record.index, editedRecord);
                            }
                        }
                    }} />
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                    onClick={() => {
                        window.console.log(Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.key"));
                        if (parseInt(Jax.get(this.props.data, "bargain.editedRecord.zhut.editedRecord.key"), 10) === 0) {
                            this.props.dataActions.genericDataCancelInlineCreate("bargain.editedRecord.zhut", true);
                        } else {
                            this.props.dataActions.genericDataCancelEditing("bargain.editedRecord.zhut");
                        }
                    }} />
            </span>);

        } else {
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                    this.props.dataActions.genericDataStartEditing("bargain.editedRecord.zhut", record.key, record.index, false)
                }} />
                <Popconfirm title={this.getText(10481)}
                    onConfirm={() => this.props.dataActions.dataTableDeleteRow("bargain.editedRecord.zhut", record.index)}
                    okText={this.getText(10482)} cancelText={this.getText(10483)}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                </Popconfirm>
                {Jax.get(this.props.data, "bargain.editedRecord.bargainTypeId", 0) == 10 ? [
                    <Tooltip title={this.getText(12690)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"eye"} onClick={() => {
                            const { itemCode, itemName } = record;
                            this.props.uiActions.showModal(TableIDs.modalPriceListItemView, { ...this.props.ui.data, itemCode, itemName });
                        }} />
                    </Tooltip>,
                    <Tooltip title={this.getText(16428)}>
                        <Popover
                            placement="right"
                            trigger="click"
                            title={this.getText(16428)}
                            content={this.getMivzaZhutUpdateDealOption(record)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"setting"} />
                        </Popover>
                    </Tooltip>
                ] : ""}
            </span>);
        }
    }

    render() {
        this.itemTags = this.props.data.itemTags.map(
            (node: Object) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object) => {
                            return (<Option key={parseInt(item.id, 10)} valuefilter={item.id + ' ' + item.text}>{item.text}</Option>);
                        })}
                    </OptGroup>
                )
            }
        );

        this.customerTags = this.props.data.customerTags.map(
            (node: Object) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object) => {
                            return (<Option key={parseInt(item.id, 10)}>{item.text}</Option>);
                        })}
                    </OptGroup>
                )
            }
        );

        let posListData = this.props.data[TableIDs.genericSelectorPos].dataset;
        let posListNotInclude = posListData.filter(f => Jax.get(this.props.data, "bargain.editedRecord.pos", []).indexOf(f.code) === -1)

        this.posList = posListData.map((obj) => (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>))

        this.mutList = this.props.data.mutList ?
            this.props.data.mutList.data.map(
                (node: Object) => {
                    let mut = node.split("\f");
                    return (<Option key={mut[0] ? parseInt(mut[0], 10) : 0}>{mut[0] + " - " + mut[1]}</Option>);
                }
            )
            : [];

        let types = Array.from(this.props.data.bargainTypes);
        this.bargainTypes = types.map(
            (node: Object) => {
                return (
                    <Option key={parseInt(node.bargainTypeId, 10)}>{node.bargainTypeDescription}</Option>
                )
            }
        );


        const sqlFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm:ss";
        const dateFormat = "DD/MM/YYYY";

        // const dataSource = this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["zhut"] ? this.props.data["bargain"].editedRecord["zhut"].data : [];
        let creationInProgress = false;
        const dataSource = Jax.get(this.props.data, "bargain.editedRecord.zhut.data", []).map((value, index) => {
            value.index = index;
            if (value.key > 0) {
                value.number = index + (creationInProgress ? 0 : 1);
            } else {
                creationInProgress = true;
            }
            return value;
        });

        window.console.log(dataSource);

        let eRecord = this.props.data["bargain"].editedRecord ? this.props.data.bargain.editedRecord : {}
        let bargainTypeId = eRecord.bargainTypeId;
        let mutBonusDaysToLiveType9 = this.getMutBonusDaysToLiveType9(null);
        let conditionMultiplier = parseFloat(Jax.get(this.props.data, "bargain.editedRecord.conditions.multiplier", 0));
        let type2multi = bargainTypeId == '2' && conditionMultiplier > 1;

        let columns = [
            { title: "#", dataIndex: "number", key: "key", width: 100, render: t => t },
            { title: this.getText(10470), dataIndex: "itemCode", key: "itemCode", width: 300, render: this.renderZhutItemCode },
            { title: this.getText(10471), dataIndex: "tagId", key: "tagId", width: 200, render: this.renderZhutTagId },
            { title: this.getText(10465), dataIndex: "madrega", key: "madrega", width: 100, render: this.renderZhutMadrega },
            { title: this.getText(16168), dataIndex: "isFromPriceList", key: "isFromPriceList", width: 100, render: this.renderZhutIsFromPriceList },
            { title: this.getText(10466), dataIndex: "discount", key: "discount", width: 100, render: this.renderZhutDiscount },
            { title: this.getText(type2multi ? 18110 : 10467), dataIndex: "price", key: "price", width: 100, render: this.renderZhutPrice },
            { title: this.getText(10468), dataIndex: "price", key: "discountFixed", width: 100, render: this.renderZhutPrice },
            { title: this.getText(10469), dataIndex: "price", key: "voucherSum", width: 100, render: this.renderZhutPrice },
            { title: this.getText(10472), dataIndex: "bonusQuantity", key: "bonusQuantity", width: 100, render: this.renderZhutBonusQuantity },
            { title: this.getText(10473), dataIndex: "bonusQuantity", key: "voucherDays", width: 100, render: this.renderZhutBonusQuantity },
            { title: this.getText(10474), dataIndex: "isForSubscription", key: "isForSubscription", width: 100, render: this.renderZhutIsForSubscription },
            { title: this.getText(10529), dataIndex: "mutDaysToLive", key: "mutDaysToLive", width: 100, render: this.renderZhutMutDaysToLive },
            { title: this.getText(bargainTypeId == 9 ? 10530 : 15373), dataIndex: "mutBonusDaysToLive", key: "mutBonusDaysToLive", width: 100, render: this.renderZhutMutBonusDaysToLive },
            { title: this.getText(10475), dataIndex: "mutId", key: "mutId", width: 200, render: this.renderZhutMutId },
            { title: this.getText(16952), dataIndex: "bonText", key: "bonText", width: 800, render: this.renderBonText },
            { title: "", key: "actions", width: 100, render: this.renderZhutActions },
        ];


        let rSpan = (n) => (window.innerWidth > 600) ? n : n < 5 ? 12 : 24;

        let footerButtons = [<Button onClick={this.handleModalCancel.bind(this)}>{this.getText(10496)}</Button>];

        if (this.props.data.bargain.editedRecord
            && this.props.data.bargain.editedRecord.items
            && this.props.data.bargain.editedRecord.items.allItems == '1'
            && bargainTypeId != 12) {
            footerButtons.push(<Popconfirm
                title={this.getText(14240)}
                onConfirm={this.handleModalOk.bind(this)}
                okText={this.getText(14241)}
                cancelText={this.getText(14242)}
            >
                <Button type="primary" loading={this.props.workInProgress}>{this.getText(10495)}</Button>
            </Popconfirm>)
        } else {
            footerButtons.push(<Button
                type="primary"
                onClick={this.handleModalOk.bind(this)}
                loading={this.props.workInProgress}
            >{this.getText(10495)}</Button>)
        }

        const tagId = this.props.data["bargain"].editedRecord
            && this.props.data["bargain"].editedRecord["items"]
            && this.props.data["bargain"].editedRecord["items"].tagId ?
            this.props.data["bargain"].editedRecord["items"].tagId
            : null

        return (
            <Modal
                align={null}
                // footer={undefined} 
                footer={footerButtons}
                zIndex={this.props.zIndex}
                title={this.props.data["bargain"].editing == 0 ? this.getText(10428) : this.getText(10429)}
                visible={this.props.visible} closable={false}
                maskClosable={true}
                // okText={this.getText(10495)} cancelText={this.getText(10496)}
                confirmLoading={this.props.workInProgress/* || this.props.data.ongoingRequests > 0*/}
                onCancel={this.handleModalCancel.bind(this)} onOk={this.handleModalOk.bind(this)}
                width={'95%'}>
                <div>
                    <Form layout="vertical" onSubmit={this.handleModalOk.bind(this)}>
                        <Divider orientation="left">{this.getText(10497)}</Divider>
                        <Row>
                            <Col span={rSpan(8)}>

                                {this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord.bargainId ? (
                                    <h3 style={{ paddingBottom: 15 }}>{this.getText(10498)}  {this.props.data["bargain"].editedRecord.bargainId}</h3>
                                ) : (<div />)}

                                <FormItem key={"bargainDescription"} label={this.getText(10499)}><Input style={{ width: '90%' }}
                                    value={this.props.data["bargain"].editedRecord ? this.props.data["bargain"].editedRecord.bargainDescription : ""}
                                    onChange={(e) => {
                                        this.props.dataActions.genericDataSetEdit("bargain", { bargainDescription: e.target.value })
                                    }}
                                /></FormItem>
                                <FormItem key={"bargainTypeId"} label={this.getText(10500)}
                                    validateStatus={this.validateBargainType().validateStatus}
                                    hasFeedback={this.validateBargainType().hasFeedback}
                                    help={this.validateBargainType().help}>
                                    <Select
                                        style={{ width: '90%' }} size={"large"} combobox={null} notFoundContent={this.getText(10501)}
                                        showSearch={true} value={bargainTypeId} allowClear={true} placeholder={this.getText(10502)}
                                        onChange={this.onBargainTypeChange.bind(this)}>
                                        {this.bargainTypes}
                                    </Select>
                                </FormItem>
                                {/* {bargainTypeId != 7 ? */}
                                {/* אילן ביקש לשנות בתאריך 06.11.2024 */}
                                    <FormItem key={"bargainCalcOrder"} label={this.getText(18259)}>
                                        <InputNumber min={0} max={999999999} style={{ width: 200 }}
                                            value={this.props.data["bargain"].editedRecord ? this.props.data["bargain"].editedRecord.bargainCalcOrder : 0}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { bargainCalcOrder: value ? value : 0 })
                                            }} />
                                    </FormItem>
                                    {/* : ""} */}
                                <FormItem key={"isActivateByQuestion"} label={this.getText(18260)}>
                                    <Switch size={"default"}
                                        checked={this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord.isActivateByQuestion == 1}
                                        onChange={(value) => {
                                            this.props.dataActions.genericDataSetEdit("bargain", { isActivateByQuestion: value ? 1 : 0 })
                                        }} />
                                </FormItem>
                            </Col>
                            <Col span={rSpan(4)}>
                                <FormItem key={"bargainDateFrom"} label={this.getText(10503)}
                                    validateStatus={eRecord.bargainDateFrom ? "success" : "error"}
                                    hasFeedback={!eRecord.bargainDateFrom}>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, null, eRecord.bargainDateTo)}
                                        value={eRecord.bargainDateFrom ? moment(eRecord.bargainDateFrom, sqlFormat) : null}
                                        format={dateFormat} onChange={(date: moment) => {
                                            this.props.dataActions.genericDataSetEdit("bargain", { bargainDateFrom: date != null ? date.format("YYYY-MM-DD") : "" })
                                        }} />
                                </FormItem>
                                <FormItem key={"bargainDateTo"} label={this.getText(10504)}
                                    validateStatus={eRecord.bargainDateTo ? "success" : "error"}
                                    hasFeedback={!eRecord.bargainDateTo}>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, eRecord.bargainDateFrom, null)}
                                        value={eRecord.bargainDateTo ? moment(eRecord.bargainDateTo, sqlFormat) : null}
                                        format={dateFormat} onChange={(date: moment) => {
                                            this.props.dataActions.genericDataSetEdit("bargain", { bargainDateTo: date != null ? date.format("YYYY-MM-DD") : "" })
                                        }} />
                                </FormItem>
                            </Col>
                            <Col span={rSpan(4)}>
                                <FormItem key={"bargainHourFrom"} label={this.getText(10505)}><TimePicker
                                    value={this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord.bargainTimeFrom ? moment(this.props.data["bargain"].editedRecord.bargainTimeFrom, timeFormat) : null}
                                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                                        this.props.dataActions.genericDataSetEdit("bargain", { bargainTimeFrom: timeString })
                                    }} /></FormItem>
                                <FormItem key={"bargainHourTo"} label={this.getText(10506)}><TimePicker
                                    value={this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord.bargainTimeTo ? moment(this.props.data["bargain"].editedRecord.bargainTimeTo, timeFormat) : null}
                                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                                        this.props.dataActions.genericDataSetEdit("bargain", { bargainTimeTo: timeString })
                                    }} /></FormItem>
                            </Col>
                            <Col span={rSpan(8)}>
                                <FormItem key={"posList"} label={this.getText(10516)}>
                                    <Checkbox onChange={(e) => { this.props.dataActions.genericDataSetEdit("bargain", { pos: e.target.checked ? posListData.map(x => x.code) : [] }) }} />
                                    <Select size={"default"} combobox={null} mode="multiple" style={{ width: '90%' }} maxTagCount={10}
                                        onChange={(value) => { this.props.dataActions.genericDataSetEdit("bargain", { pos: value }) }}
                                        value={Jax.get(this.props.data, "bargain.editedRecord.pos", [])}
                                    >
                                        {this.posList}
                                    </Select>
                                </FormItem>
                                <FormItem key={"posListNotInclude"} label={this.getText(15664)}>
                                    <Checkbox onChange={(e) => { this.props.dataActions.genericDataSetEdit("bargain", { pos: e.target.checked ? [] : posListData.map(x => x.code) }) }} />
                                    {/* <Select size={"default"} combobox={null} mode="multiple" style={{ width: '90%' }} */}
                                    <GenericSelectorPosStorages {...this.props} multi
                                        maxTagCount={10}
                                        onChange={(value) => {
                                            console.log('posListData', posListData, value)
                                            this.props.dataActions.genericDataSetEdit("bargain", { pos: posListData.map(x => x.code).filter(f => value && value.indexOf(f) === -1) })
                                        }}
                                        value={posListNotInclude.map(x => x.code)}
                                    />
                                    {/* >
                                        {this.posList}
                                    </Select> */}
                                </FormItem>
                                <Row>
                                    <Col span={rSpan(12)}>
                                        <FormItem key={"bargainIsActive"} label={this.getText(10507)}><Switch size={"default"}
                                            checked={this.props.data["bargain"].editedRecord ? parseInt(this.props.data["bargain"].editedRecord.bargainIsActive, 10) === 1 : false}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { bargainIsActive: value ? 1 : 0 })
                                            }} /></FormItem>
                                    </Col>
                                    <Col span={rSpan(12)}>
                                        <FormItem key={"isMultiAllowed"} label={this.getText(10508)}><Switch size={"default"}
                                            checked={this.props.data["bargain"].editedRecord ? parseInt(this.props.data["bargain"].editedRecord.isMultiAllowed, 10) === 1 : false}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { isMultiAllowed: value ? 1 : 0 })
                                            }} /></FormItem>
                                    </Col>
                                    <Col span={rSpan(3)}>
                                        <FormItem key={"activeOnSun"} label={this.getText(10509)}><Switch size={"default"}
                                            checked={this.props.data["bargain"].editedRecord ? parseInt(this.props.data["bargain"].editedRecord.isActiveSun, 10) === 1 : false}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { isActiveSun: value ? 1 : 0 })
                                            }} /></FormItem>
                                    </Col>
                                    <Col span={rSpan(3)}>
                                        <FormItem key={"activeOnMon"} label={this.getText(10510)}><Switch size={"default"}
                                            checked={this.props.data["bargain"].editedRecord ? parseInt(this.props.data["bargain"].editedRecord.isActiveMon, 10) === 1 : false}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { isActiveMon: value ? 1 : 0 })
                                            }} /></FormItem>
                                    </Col>
                                    <Col span={rSpan(3)}>
                                        <FormItem key={"activeOnTue"} label={this.getText(10511)}><Switch size={"default"}
                                            checked={this.props.data["bargain"].editedRecord ? parseInt(this.props.data["bargain"].editedRecord.isActiveTue, 10) === 1 : false}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { isActiveTue: value ? 1 : 0 })
                                            }} /></FormItem>
                                    </Col>
                                    <Col span={rSpan(3)}>
                                        <FormItem key={"activeOnWed"} label={this.getText(10512)}><Switch size={"default"}
                                            checked={this.props.data["bargain"].editedRecord ? parseInt(this.props.data["bargain"].editedRecord.isActiveWed, 10) === 1 : false}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { isActiveWed: value ? 1 : 0 })
                                            }} /></FormItem>
                                    </Col>
                                    <Col span={rSpan(3)}>
                                        <FormItem key={"activeOnThu"} label={this.getText(10513)}><Switch size={"default"}
                                            checked={this.props.data["bargain"].editedRecord ? parseInt(this.props.data["bargain"].editedRecord.isActiveThu, 10) === 1 : false}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { isActiveThu: value ? 1 : 0 })
                                            }} /></FormItem>
                                    </Col>
                                    <Col span={rSpan(3)}>
                                        <FormItem key={"isActiveFri"} label={this.getText(10514)}><Switch size={"default"}
                                            checked={this.props.data["bargain"].editedRecord ? parseInt(this.props.data["bargain"].editedRecord.isActiveFri, 10) === 1 : false}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { isActiveFri: value ? 1 : 0 })
                                            }} /></FormItem>
                                    </Col>
                                    <Col span={rSpan(3)}>
                                        <FormItem key={"isActiveSat"} label={this.getText(10515)}><Switch size={"default"}
                                            checked={this.props.data["bargain"].editedRecord ? parseInt(this.props.data["bargain"].editedRecord.isActiveSat, 10) === 1 : false}
                                            onChange={(value) => {
                                                this.props.dataActions.genericDataSetEdit("bargain", { isActiveSat: value ? 1 : 0 })
                                            }} /></FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={rSpan( 8)}>
                                <Divider orientation="left">{this.getText(10517)}</Divider>
                                <RadioGroup style={{ width: '100%' }} onChange={(e) => {
                                    this.setCustomerRadioValue(e.target.value)
                                }} value={this.getCustomerRadioValue()}>
                                    <Row>
                                        <Col span={8}>
                                            <Radio style={{ width: '100%' }} value={1}>{this.getText(10521)}</Radio>
                                            <Radio style={{ width: '100%' }} value={2}>{this.getText(10522)}</Radio>
                                            <Radio style={{ width: '100%' }} value={3}>{this.getText(10523)}</Radio>
                                            <Radio style={{ width: '100%' }} value={4}>{this.getText(18574)}</Radio>
                                        </Col>
                                        <Col span={16}>
                                            {this.getCustomerRadioValue() === 1 ?
                                                (<CustomerPicker
                                                    disabled={this.shouldCustomerPickerBeDisabled()}
                                                    defaultCustomerId={this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["customers"] ? this.props.data["bargain"].editedRecord["customers"].custmerId : ""}
                                                    onChange={(value) => this.props.dataActions.genericDataSetEdit("bargain.editedRecord.customers", value)}
                                                    label={((this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["customers"] && this.props.data["bargain"].editedRecord["customers"].customerId) || "") + " - " + ((this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["customers"] && this.props.data["bargain"].editedRecord["customers"].customerName) || "")}
                                                    data={this.props.data.customerList ? this.props.data.customerList.data : []}
                                                    showDialog={this.props.uiActions.uiShowCustomerPickerDialog}
                                                    hideDialog={this.props.uiActions.uiHideCustomerPickerDialog}
                                                />
                                                ) : this.getCustomerRadioValue() === 2 ? (
                                                    // <Select combobox={null} size={"default"}
                                                    <GenericSelectorCustTags {...this.props}
                                                        disabled={this.shouldCustomerTagsBeDisabled()}
                                                        // style={{ width: '90%' }}
                                                        onChange={(value) => {
                                                            this.props.dataActions.genericDataSetEdit("bargain.editedRecord.customers", { tagId: value ? value : null })
                                                        }}
                                                        value={this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["customers"] && this.props.data["bargain"].editedRecord["customers"].tagId ? this.props.data["bargain"].editedRecord["customers"].tagId :
                                                            null}
                                                    // >
                                                    //     {this.customerTags}
                                                    // </Select>
                                                    />
                                                ) : ""}
                                        </Col>
                                    </Row>
                                </RadioGroup>
                            </Col>
                            <Col span={rSpan(bargainTypeId == 12 ? 0 : 8)}>
                                <Divider orientation="left">{this.getText(10518)}</Divider>
                                <RadioGroup onChange={(e) => {
                                    this.setItemRadioValue(e.target.value)
                                }} value={this.getItemRadioValue()}>
                                    <Row>
                                        <Col span={8}>
                                            <Radio style={{ width: '100%' }} value={1}>{this.getText(10533)}</Radio>
                                            <Radio style={{ width: '100%' }} value={2}>{this.getText(10534)}</Radio>
                                            <Radio style={{ width: '100%' }} value={3}>{this.getText(10535)}</Radio>
                                        </Col>
                                        <Col span={16}>
                                            {!this.shouldItemPickerBeDisabled() ?
                                                (<span>
                                                    <SelectorItems
                                                        idSelector={"filter"}
                                                        user={this.props.user}
                                                        uiActions={this.props.uiActions}
                                                        ui={this.props.ui}
                                                        dataActions={this.props.dataActions}
                                                        data={this.props.data}
                                                        ActionQueue={this.props.ActionQueue}
                                                        ctrlBKeyFlag={null}
                                                        isExpandMatrixFatherItem={false}
                                                        itemType={1}
                                                        value={this.props.data.bargain.editedRecord.items ?
                                                            this.props.data.bargain.editedRecord.items.itemCode :
                                                            null}
                                                        onChangeSearch={(s) => { this.onChangeItemCode(s.mCode, s.mName, "filter") }}
                                                        onChange={(s) => { this.setState({ newItemCodeFilter: s.target.value }) }}
                                                        onBlur={(s) => { this.checkIfItemExist(this.state.newItemCodeFilter, "filter") }}
                                                        onKeyDown={this.keyboardListenerFilter.bind(this)}
                                                    />
                                                    <h4>{this.props.data.bargain.editedRecord.items ?
                                                        this.props.data.bargain.editedRecord.items.itemName :
                                                        this.getText(10476)}</h4>
                                                </span>)
                                                : !this.shouldItemTagsBeDisabled() ?
                                                    (<div>
                                                        <GenericSelectorItemTags {...this.props} key="tnai_item_tag" notReport
                                                            onChange={(value) => {
                                                                this.props.dataActions.genericDataSetEdit("bargain.editedRecord.items", { tagId: value ? value : null })
                                                            }}
                                                            onChangeGetDetailed={e => { this.setState({ tagDataSelected: e }) }}
                                                            value={tagId}
                                                        />
                                                        <Tooltip title={this.getText(12641)}>
                                                            <Button type={"primary"} className={"btn"} icon="plus"
                                                                style={{ marginTop: "5px", marginLeft: "5px" }}
                                                                onClick={() => {
                                                                    this.props.uiActions.showModal(TableIDs.modalAttributeItemTags, { tID: 0 });
                                                                    this.props.dataActions.genericDataStartInlineCreate(this.tags_id);
                                                                }} />
                                                        </Tooltip>
                                                        <Tooltip title={this.getText(18579)}>
                                                            <Button className={"btn"} icon="eye"
                                                                style={{ marginTop: "5px", marginLeft: "5px" }}
                                                                onClick={() => {
                                                                    const { name, typeCode } = this.state.tagDataSelected ? this.state.tagDataSelected : {}
                                                                    this.props.uiActions.showModal(TableIDs.modalAttributeItemTags, { tID: tagId });
                                                                    //   setTimeout(() => {
                                                                    this.props.dataActions.setJson(this.tags_id, {
                                                                        data: [tagId + "\f" + typeCode + "\f" + name + "\f1"],
                                                                        editedRecord: { key: tagId, index: 0, mID: tagId, mTypeId: typeCode, mName: name, mIsActive: '1' },
                                                                        editing: tagId,
                                                                        headers: "mID\fmTypeId\fmName\fmIsActive"
                                                                    });
                                                                    // }, 1000);
                                                                }}
                                                                disabled={!tagId}
                                                            />
                                                        </Tooltip>
                                                    </div>)
                                                    : ""}
                                        </Col>
                                    </Row>
                                </RadioGroup>
                            </Col>
                            <Col span={rSpan(8)}>
                                <Divider orientation="left">{this.getText(10519)}</Divider>
                                {this.getText(10524)}
                                {bargainTypeId == 12 ? "" :
                                    <RadioGroup
                                        onChange={(e) => {
                                            this.props.dataActions.genericDataSetEdit("bargain.editedRecord.conditions", { conditionType: e.target.value })
                                        }}
                                        value={parseInt(Jax.get(this.props.data, "bargain.editedRecord.conditions.conditionType", 1), 10)}>
                                        <Radio value={1} disabled={this.shouldConditionTypeQuantityBeDisabled()}>{this.getText(10525)}</Radio>
                                        <Radio value={2} disabled={this.shouldConditionTypePriceBeDisabled()}>{this.getText(10526)}</Radio>
                                    </RadioGroup>
                                }
                                <Row>
                                    <Col span={bargainTypeId == 12 ? 0 : 8}>
                                        <FormItem key={"amountFrom"} label={this.getText(bargainTypeId == 9 ? 18699 : 10527)}
                                            validateStatus={this.validateAmountFrom().validateStatus}
                                            hasFeedback={this.validateAmountFrom().hasFeedback}
                                            help={this.validateAmountFrom().help}>
                                            <InputNumber
                                                disabled={this.validateAmountFrom().disabled}
                                                min={0} max={999999} precision={3} step={1}
                                                onChange={(value) => {
                                                    this.props.dataActions.genericDataSetEdit("bargain.editedRecord.conditions", { amountFrom: value })
                                                }}
                                                value={this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["conditions"] ? this.props.data["bargain"].editedRecord["conditions"].amountFrom : null}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem key={"amountTo"} label={this.getText(bargainTypeId == 9 ? 18700 : bargainTypeId == 12 ? 19284 : 10528)}
                                            validateStatus={this.validateAmountTo().validateStatus}
                                            hasFeedback={this.validateAmountTo().hasFeedback}
                                            help={this.validateAmountTo().help}>
                                            <InputNumber
                                                disabled={this.validateAmountTo().disabled}
                                                min={0} max={999999} precision={3} step={1}
                                                onChange={(value) => {
                                                    this.props.dataActions.genericDataSetEdit("bargain.editedRecord.conditions", { amountTo: value })
                                                }}
                                                value={this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["conditions"] ? this.props.data["bargain"].editedRecord["conditions"].amountTo : null} />
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem key={"multiplier"} label={this.getMultiplierLabel()}
                                            validateStatus={this.validateMultiplier().validateStatus}
                                            hasFeedback={this.validateMultiplier().hasFeedback}
                                            help={this.validateMultiplier().help}>
                                            <InputNumber
                                                disabled={this.validateMultiplier().disabled}
                                                min={0} max={9999} precision={3} step={1}
                                                onChange={(value) => {
                                                    this.props.dataActions.genericDataSetEdit("bargain.editedRecord.conditions", { multiplier: value })
                                                }}
                                                value={this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["conditions"] ? this.props.data["bargain"].editedRecord["conditions"].multiplier : null}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider orientation="left">{this.getText(10520)}</Divider>

                        {/* {bargainTypeId == '9' ?
                            <Row>
                                <Col span={rSpan(12)}></Col>
                                {['mLogoUrl', 'mLogoFree', 'mLogoUsed'].map((x, i) => {
                                    return (<Col span={rSpan(4)} style={{ textAlign: 'left' }}>
                                        {this.getText(14451 + i)}
                                        <PictureView
                                            user={this.props.user} data={this.props.data} dataActions={this.props.dataActions}
                                            ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                                            url={this.state.logoUrlList[x]}
                                            onChange={(url) => { this.setState({ logoUrlList: { ...this.state.logoUrlList, [x]: url } }) }}
                                            onRemove={() => { this.setState({ logoUrlList: { ...this.state.logoUrlList, [x]: '' } }) }}
                                            onUploadedSuccess={(response) => { }}
                                            onUploadedFailed={(errorMessage, errorCode) => { }}
                                            imageType={'I'}
                                            aspect={4 / 3}
                                        />
                                    </Col>)
                                })}
                            </Row>
                            : ""} */}
                        <Row>
                            <Col span={8}>
                                <FormItem validateStatus={this.validateZhutCount().validateStatus}
                                    hasFeedback={this.validateZhutCount().hasFeedback}
                                    help={this.validateZhutCount().help}>
                                    <Button size={"default"} className={""} htmlType={"button"} loading={false}
                                        type={"button"} icon={""}
                                        disabled={Jax.get(this.props.data, "bargain.editedRecord.zhut.editing", -1) > -1 || this.shouldAddNewZhutDisabled()}
                                        onClick={() => {
                                            this.props.dataActions.genericDataStartInlineCreate("bargain.editedRecord.zhut")
                                            // if (bargainTypeId == '9') {
                                            //     this.setMutBonusDaysToLiveType9(mutBonusDaysToLiveType9);
                                            //     // this.props.dataActions.genericDataSetEdit("bargain.editedRecord.zhut.editedRecord", { mutBonusDaysToLive: mutBonusDaysToLiveType9 })
                                            // }
                                        }}>{this.getText(10532)}</Button>
                                </FormItem>
                            </Col>
                            <Col span={16}>
                                {this.shouldMadregaNoteBeDisplayed() ? this.getText(10531) : null}
                            </Col>
                        </Row>
                        <ResponsiveTable
                            tableOnly={true}
                            percentageToMobile={[30, 30, 40]}
                            ui={this.props.ui}
                            dataSource={dataSource} //rowSelection={null} dropdownPrefixCls={null}
                            columns={columns.filter(this.filterColumns.bind(this))}
                            //loading={this.props.data.ongoingRequests > 0}
                            loadingPresonal={this.props.workInProgress}
                            scroll={{ x: '100%' }}
                            //bordered
                            pagination={this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["zhut"] ? this.props.data["bargain"].editedRecord["zhut"].pagination : {}}
                            onChange={this.handleTableChange.bind(this)}
                        //locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                        />

                    </Form>
                </div >
                <ModalAttributeItemTags
                    id={TableIDs.modalAttributeItemTags}
                    tagType={"ItemTags"}
                    onSave={(id) => {
                        this.props.dataActions.genericDataSetEdit("bargain.editedRecord.items", { tagId: id });
                        this.props.dataActions.genericMultiSelectorRefreshDataset(
                            TableIDs.genericMultiSelectorItemTags,
                            this.props.user.companyCode,
                            this.props.user.token,
                            "get_tags_with_types");
                    }}
                    onClose={() => {
                        this.props.dataActions.setJson("", { itemTags: this.props.data[TableIDs.genericMultiSelectorItemTags].dataset });
                        this.props.uiActions.showModal("bargainEditor");
                    }}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    ActionQueue={this.props.ActionQueue}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalAttributeItemTags)}
                />
                <ModalPriceListItemView
                    {...this.props}
                    id={TableIDs.modalPriceListItemView}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalPriceListItemView)}
                />

            </Modal >

        );
    }

}

// export default ModalForm;
/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import ModalSearchCoupons from "../../components/ModalSearchCoupons";
import TableLineIcons from "../../components/TableLineIcons";
import { Tooltip, Button, Icon, message } from "antd"
import { escapeRegExp } from "../../utils/utils";

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class CustomerCoupons extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "CUSTOMER_COUPONS_LIST";
        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,

            filterActive: false,
            valueSearch: '',

        };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.api = {
            get: "get_dealsActuals_coupons_list",
            delete: "delete_dealsActuals_coupons",
        }


        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericDataGetWithParams, args: ["customerList", this.props.user.companyCode, this.props.user.token, {
        //         dataObject: "customerList"
        //     }, "data_get"]
        // });
    }


    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10159);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _expiryDateStart: null,
            _expiryDateEnd: null,
            _activityDateStart: null,
            _activityDateEnd: null,
            _customer: null,
            _posList: '',
            _isUsed: 0,
            _supplierList: '',
            _itemList: '',
            _priceList: null,
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

        this.setState({ filterActive: false, valueSearch: "" })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }


    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 7;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "dId": col--;
            case "dItemName": col--;
            case "dCustomerId": col--;
            case "dCustomerName": col--;
            case "dIsUsed": col--;
            case "dDate": col--;
            case "dSum": break; // 7
            default:
                col = 0;
        }

        let request = {
            _id: 0,
            // _fieldId: false,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,

        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    dataSetFilter = (request) => {
        let filters = this.props.data[this.id].filters;
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { ...filters, ...request }, this.api.get)
    }

    reportButton = () => {
        return (
            <Tooltip title={this.getText(12720)}>
                <Button
                    key={"report-print"}
                    className={"btn"}
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => {
                        const {
                            _words, _isAsc, _sort_by,
                            _expiryDateStart, _expiryDateEnd, _activityDateStart, _activityDateEnd,
                            _customer, _posList, _isUsed, _supplierList, _itemList, _priceList
                        } = this.props.data[this.id].filters;

                        this.props.ActionQueue.addToQueue({
                            action: this.props.dataActions.generateReport,
                            args: [
                                this.props.user.companyCode,
                                this.props.user.token,
                                {
                                    _words: encodeURIComponent(_words),
                                    _isAsc, _sort_by,
                                    _expiryDateStart, _expiryDateEnd, _activityDateStart, _activityDateEnd,
                                    _customer, _posList, _isUsed, _supplierList,
                                    _itemList: encodeURIComponent(_itemList),
                                    _priceList,
                                    reportName: "reports/CustomerCoupons.pdf",
                                    REPORT_LOCALE: this.getText(101),
                                }
                            ]
                        });
                    }}>
                    <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                </Button>
            </Tooltip >)
    }

    deleteIcon = (text, record) => {
        if (record.dIsUsed == 1) {
            return (<Icon style={{ fontSize: 16, color: '#aaaaaa' }} className={"clickable margin-4"} type={'delete'} onClick={() => { message.error(this.getText(15558)) }} />)
        } else {
            return (<TableLineIcons
                deleteQuetion={{ title: this.getText(15555), okText: this.getText(15556), cancelText: this.getText(15557) }}
                onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get) }}
            />)
        }
    }


    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let request = {
            // ui-pagination 
            _id: 0,
            _fieldId: false,
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            {
                title: this.getText(11011),
                dataIndex: "dId",
                key: "dId",
                sorter: true,
                width: '7%',
                render: (text, record) => text,
            }, {
                title: this.getText(11012),
                dataIndex: "dItemName",
                key: "dItemName",
                sorter: true,
                width: '15%',
                render: (text, record) => text,
            }, {
                title: this.getText(14063),
                dataIndex: "dSum",
                key: "dSum",
                sorter: true,
                width: '10%',
                render: (text, record) => text,
            }, {
                title: this.getText(11013),
                dataIndex: "dCustomerId",
                key: "dCustomerId",
                width: '8%',
                sorter: true,
                render: (text, record) => text
            }, {
                title: this.getText(11014),
                dataIndex: "dCustomerName",
                key: "dCustomerName",
                width: '15%',
                sorter: true,
                render: (text, record) => text
            }, {
                title: this.getText(11015),
                dataIndex: "dIsUsed",
                key: "dIsUsed",
                width: '6%',
                sorter: true,
                render: (text, record) => text == 1 ? this.getText(11020) : this.getText(11021)
            }, {
                title: this.getText(11016),
                dataIndex: "dDateUsed",
                key: "dDateUsed",
                width: '10%',
                render: (text, record) => record.dIsUsed == 1 ? record.dDate : ''
            }, {
                title: this.getText(11017),
                dataIndex: "dDocNum",
                key: "dDocNum",
                width: '9%',
                render: (text, record) => record.dIsUsed == 1 ? text : ''
            }, {
                title: this.getText(11018),
                dataIndex: "dPosNum",
                key: "dPosNum",
                width: '6%',
                render: (text, record) => record.dIsUsed == 1 ? text : ''
            }, {
                title: this.getText(11019),
                dataIndex: "dDate",
                key: "dDate",
                width: '10%',
                sorter: true,
                render: (text, record) => text
            }, {
                title: '',
                width: '4%',
                render: this.deleteIcon
            },
        ];

        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[30, 35, false, 35]}
                    title={this.getText(11010)}
                    actionButtons={[
                        {
                            buttonType: 'filter',
                            buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchCoupons) }
                        }, {
                            buttonDisabled: !this.state.filterActive,
                            buttonType: 'clear',
                            buttonFunction: this.initFilters,
                        }, {
                            buttonType: 'refresh',
                            buttonFunction: this.refreshData
                        },
                    ]}
                    genericActionButtons={[this.reportButton()]}
                    showsearch={
                        (val) => {
                            if (val) { this.setState({ filterActive: true }) }
                            this.props.dataActions.genericDataSetFilter(
                                this.id, this.props.user.companyCode, this.props.user.token,
                                {
                                    ...request,
                                    _words: escapeRegExp(val),
                                    //  _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                    page: 1,
                                    _page_num: 1,
                                }, this.api.get)
                        }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ModalSearchCoupons
                    id={TableIDs.modalSearchCoupons}
                    dataSetFilter={this.dataSetFilter}
                    user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchCoupons)}
                    filterActive={this.state.filterActive}
                    setFilterActive={e => {
                        if (e) this.setState({ filterActive: true })
                        else this.initFilters()
                    }}
                />
            </div>
        );
    }
}

export default CustomerCoupons;


/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Form, Select, Checkbox, Radio } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import { disabledDatePicker } from "../../utils/utils";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class HoursReport extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            HoursReport: {
                isSumReport: '0',
                EmployeeList: undefined,
                GroupEmployeeList: undefined,
                BranchList: undefined,
                isTimeFormat: '0',
                Overtime: '0',
                SeparatePage: '0',
                NotIsActive: '0',
                FullDates: '0',
                inBranch: '1',
                exceptionOnly: '0',
                calcPrice: '0',
                FromDate: new moment().startOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
                ToDate: new moment().endOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
                reportName: 'reports/WorkingHours.pdf'
            },
            isExtended: 0,
            FromDate: new moment().startOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
            ToDate: new moment().endOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
        };

        this.id = "WORKING_HOUERS_DEFULTS_SETTINGS";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorGroupEmployee] === undefined) props.data[TableIDs.genericSelectorGroupEmployee] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };

        this.api = {
            get: "get_working_houers_defaults",
            selector_employee: "get_employee_list",
            selector_groups: "get_employee_group_list",
            selector_branch: "get_branch_list",
        }

        this.refreshEmployees(!this.state.HoursReport.NotIsActive);
        // this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employee);
        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorGroupEmployee, this.props.user.companyCode, this.props.user.token, this.api.selector_groups);
        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorBranches, this.props.user.companyCode, this.props.user.token, this.api.selector_branch);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prev) {
        document.title = "Cash On Tab - " + this.getText(10168);

        let er = this.props.data[this.id].editedRecord;
        let per = prev.data[this.id].editedRecord;
        if (er && per && er.mHowerType !== per.mHowerType) {
            let isTimeFormat = er.mHowerType === '1' ? '1' : '0';
            this.setReportParameters("HoursReport", { isTimeFormat })
        }

        setTimeout(() => {
            if (prev.ui.favoriteParams !== this.props.ui.favoriteParams) {
                this.setReportParameters("HoursReport", this.props.ui.favoriteParams)
            }
        }, 500);
    }

    refreshEmployees = (active) => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees,
            this.props.user.companyCode,
            this.props.user.token,
            active ? "get_employee_list" : "get_employee_list_with_not_active");
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            if (!params.BranchList) {
                params = { ...params, BranchList: Jax.get(this.props.data, TableIDs.genericSelectorBranches + ".dataset", []).map((obj, index) => obj['code']).join(',') }
            }
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    loadData() {
        console.log(this.props);
        // this.props.dataActions.genericDataGetEditRequested(
        //     this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericDataGetEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token, { _group: 0 }, this.api.get);

        this.refreshEmployees(!this.state.HoursReport.NotIsActive);
        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericSelectorRefreshDataset,
        //     args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employee]
        // });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorBranches, this.props.user.companyCode, this.props.user.token, this.api.selector_branch]
        });
    }

    disabledStartDate = (FromDate) => {
        const ToDate = this.state.HoursReport.ToDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.HoursReport.FromDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    onChange = (field, value) => {
        this.setReportParameters("HoursReport", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('FromDate', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('ToDate', value ? value.format("YYYY-MM-DD") : null);
    }

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }

    createCheckBox = (field, sName) => {
        let data = Jax.get(this.props.data, TableIDs[sName] + ".dataset", []).map((obj, index) => obj['code']).join(',');
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("HoursReport", { [field]: data });
                    else
                        this.setReportParameters("HoursReport", { [field]: '' });
                }} />);
    }

    getSwitch = (field, tText, cText, ucText) => {
        let notViewFields = [
            ['calcPrice'],
            ['SeparatePage', 'Overtime', 'FullDates', 'exceptionOnly'],
            ['Overtime', 'calcPrice'],
        ];

        let notViewFieldsFind = notViewFields[parseInt(this.state.HoursReport.isSumReport)];
        if (notViewFieldsFind && notViewFieldsFind.indexOf(field) > -1) return ''

        let desktopStyle = window.innerWidth < 600 ? {} : {
            style: { marginBottom: 8 },
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }

        return (<FormItem label={this.getText(tText)} {...desktopStyle}>
            <RadioGroup
                value={this.state.HoursReport[field]}
                onChange={(value) => {
                    this.setReportParameters("HoursReport", { [field]: value.target.value });
                    if (field === 'NotIsActive') this.refreshEmployees(!value.target.value);
                }}>
                <Radio value={'1'}>{this.getText(cText ? cText : 13084)}</Radio>
                <Radio value={'0'}>{this.getText(ucText ? ucText : 13085)}</Radio>
            </RadioGroup>
        </FormItem>)
    }

    getSwitchIsSumReport = () => {
        let desktopStyle = window.innerWidth < 600 ? {} : {
            style: { marginBottom: 8 },
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }

        return (<FormItem label={this.getText(17063)} {...desktopStyle}>
            <RadioGroup
                value={this.state.HoursReport['isSumReport']}
                onChange={(value) => {
                    let isSumReport = value.target.value;
                    let reportName = 'reports/WorkingHours' + (isSumReport == '1' ? 'Summary' : isSumReport == '2' ? 'Format2' : '') + '.pdf';
                    let Overtime = this.state.HoursReport['Overtime'];
                    if (isSumReport == '2') Overtime = '1';
                    this.setReportParameters("HoursReport", { isSumReport, reportName, Overtime });
                }}>
                <Radio value={'1'}>{this.getText(17065)}</Radio>
                <Radio value={'0'}>{this.getText(17711)}</Radio>
                <Radio value={'2'}>{this.getText(17712)}</Radio>
            </RadioGroup>
        </FormItem>)
    }

    getSelect = (key, sName, ph) => {
        let lists = Jax.get(this.props.data, TableIDs[sName] + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });

        let desktopStyle = window.innerWidth < 600 ? {} : {
            style: { marginBottom: 8 },
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }

        return (<FormItem label={this.getText(ph)} {...desktopStyle}>
            {this.createCheckBox(key, sName)}
            < Select key={key} style={{ width: "90%" }} showSearch filterOption={true}
                optionFilterProp="valuefilter" mode="multiple" maxTagCount={5} placeholder={this.getText(ph)}
                onChange={(value) => this.setReportParameters("HoursReport", { [key]: value.join(',') })}
                value={!this.state.HoursReport[key] ? [] : this.state.HoursReport[key].split(',')}
            >
                {lists}
            </Select >
        </FormItem>)
    }

    render() {
        const { endOpen } = this.state;
        const { FromDate, ToDate } = this.state.HoursReport;
        const mobile = window.innerWidth <= 600 ? true : false;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        const resToMobile = (n) => (!mobile) ? {
            width: (n - 2) + '%',
            margin: '1%',
            display: 'inline-block',
            verticalAlign: 'text-top'
        } : {
            display: 'block',
            padding: 8
        }

        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(11146)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: '95%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' onSubmit={this.handleSaveChanges}
                        style={{ columnWidth: 'auto', columnCount: '1' }} >


                        <div style={resToMobile(48)}>
                            {this.getSelect('EmployeeList', 'genericSelectorEmployees', 11157)}
                            {this.getSelect('GroupEmployeeList', 'genericSelectorGroupEmployee', 16372)}
                            {this.getSelect('BranchList', 'genericSelectorBranches', 13073)}

                            <div style={resToMobile(50)}>

                                <FormItem label={this.getText(11148)}>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, null, ToDate)}
                                        // disabledDate={this.disabledStartDate}
                                        // showTime
                                        format="DD-MM-YYYY" //"YYYY-MM-DD"
                                        value={FromDate ? moment(FromDate, "YYYY-MM-DD") : null}
                                        placeholder="Start"
                                        onChange={this.onStartChange}
                                        onOpenChange={this.handleStartOpenChange}
                                    />
                                </FormItem>
                            </div>
                            <div style={resToMobile(50)}>

                                <FormItem label={this.getText(11149)}>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, FromDate, null)}
                                        // disabledDate={this.disabledEndDate}
                                        // showTime
                                        format="DD-MM-YYYY" //"YYYY-MM-DD"
                                        value={ToDate ? moment(ToDate, "YYYY-MM-DD") : null}
                                        placeholder="End"
                                        onChange={this.onEndChange}
                                        open={endOpen}
                                        onOpenChange={this.handleEndOpenChange}
                                    />
                                </FormItem>
                            </div>
                            <ReportTypeButton
                                {...this.props}
                                params={this.state.HoursReport}
                                branchesField={'BranchList'}
                                datesToFavorites={[
                                    { field: 'FromDate', label: this.getText(11148) },
                                    { field: 'ToDate', label: this.getText(11149) },
                                ]}
                                xlsReportName={this.state.HoursReport.isSumReport == '1' ? null : "reports/WorkingHoursExcel.xls"}
                                favoriteSave
                            />

                            {/* <div style={resToMobile(50)}>
                                
                                <FormItem key={"reportName"} label={this.getText(11152)}>
                                    <Select style={{ width: 250 }}
                                        className={"reports/WorkingHours.pdf"}
                                        defaultValue={"reports/WorkingHours.pdf"}
                                        onChange={(value) => this.setReportParameters("HoursReport", { reportName: value })}
                                    >
                                        <Option key={"reports/WorkingHours.pdf"}>{this.getText(11153)}</Option>
                                        <Option key={"reports/WorkingHoursExcel.xls"}>{this.getText(11154)}</Option>
                                        <Option key={"reports/WorkingHours.docx"}>{this.getText(11155)}</Option>
                                    </Select>
                                </FormItem>
                            </div>
                            <div style={{ ...resToMobile(50), marginTop: 50 }}>
                                <Button type={"primary"} onClick={() => { this.generateReport.apply(this, ["HoursReport"]) }}>{this.getText(11156)}</Button>
                            </div> */}
                        </div>
                        <div style={{ ...resToMobile(48), marginTop: !mobile ? 0 : 50 }}>
                            {this.getSwitch('inBranch', 14177, 14178, 14179)}
                            {this.getSwitch('isTimeFormat', 13074, 13075, 13076)}
                            {this.getSwitchIsSumReport()}
                            {this.getSwitch('SeparatePage', 13083)}
                            {this.getSwitch('Overtime', 13077)}
                            {this.getSwitch('FullDates', 13080)}
                            {this.getSwitch('NotIsActive', 13103)}
                            {this.getSwitch('exceptionOnly', 16406)}
                            {this.getSwitch('calcPrice', 17066)}
                        </div>
                    </Form>
                </Card>
            </div>
        );
    }
}
export default HoursReport;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericSelector from "../../components/GenericSelector";
import { Button, Col, Form, InputNumber, Row } from "antd";
import PageToolbar from "../../components/PageToolbar";
import InputStairs from "../../components/InputStairs";


class BestClubReportSettings extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            popStairs: null
        };

        this.id = "BEST_CLUB_REPORT_SETTINGS";
       
        this.api = {
            get: "get_best_club_report_settings",
            set: "set_best_club_report_settings",

          }

        this.selectors = [
            { id: "COUPON_DECLARATION_LIST", api: "coupon_declaration_list", filters: { cdType: 1 } },
            { id: "CUSTOMER_DATE_FIELDS", api: "get_customer_free_date_fields" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
     
        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token, {}, this.api.get);

       this.selectors.forEach(x => {
            this.props.dataActions.genericSelectorRefreshDataset(
                x.id, this.props.user.companyCode, this.props.user.token, x.api, x.filters);
        })
    }

    getSelector = (index, field) => {
        return (<GenericSelector
            {...this.props}
            id={this.selectors[index].id}
            value={this.props.data[this.id].editedRecord[field]}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }}
        />)
    }

    getNumber = (field) => {
        return (<InputNumber
            min={0}
            precision={0}
            value={this.props.data[this.id].editedRecord[field]}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }}
        />)
    }

    getStairs = (min, max, field) => {
        return (<InputStairs
            value={this.props.data[this.id].editedRecord[field]}
            onChange={v => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: v }) }}
            visible={this.state.popStairs === field}
            onClickInput={() => { this.setState({ popStairs: this.state.popStairs === field ? null : field }) }}
            min={min} max={max}
        />)
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(17942);
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const colProps = {
            span: window.innerWidth > 600 ? 12 : 24,
            style: { paddingLeft: 20 }
        }

        return (<div style={divStyle}>
            <PageToolbar title={gt(17945)} actionButtons={this.actionButtons} />
            <Row>
                <Col {...colProps}>
                    <Form.Item label={gt(17946)}>{this.getSelector(0, "_JoinBonusCDId")}</Form.Item>
                    <Form.Item label={gt(17947)}>{this.getSelector(0, "_BirthdayBonusCDId")}</Form.Item>
                    <Form.Item label={gt(17948)}>{this.getSelector(0, "_AnniversaryBonusCDId")}</Form.Item>
                    <Form.Item label={gt(17952)}>{this.getNumber("_TopItemsRowsNum")}</Form.Item>
                    <Form.Item label={gt(17953)}>{this.getSelector(1, "_LinkRemoveCustFieldId")}</Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={gt(17949)}>{this.getStairs(0, 29, "_InactiveCustDayStairs")}</Form.Item>
                    <Form.Item label={gt(17950)}>{this.getStairs(1, 11, "_InactiveCustMonthStairs")}</Form.Item>
                    <Form.Item label={gt(17951)}>{this.getStairs(18, 100, "_AgesCustStairs")}</Form.Item>
                </Col>
              
            </Row>
            <div style={{ textAlign: 'center' }}>
                <Button size="large" type="primary" onClick={() => {
                    this.props.dataActions.genericDataJustSaveEdit(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.set);
                    this.refreshData()
                }}>{gt(17955)}</Button>
            </div>
        </div>)
    }

}

export default BestClubReportSettings;
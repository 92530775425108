/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { message, Card, Table, Icon, Select, DatePicker, TimePicker, InputNumber, Checkbox, Popconfirm, Button, Input } from "antd";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";


const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};

type State = { employeeID: string };

class AttendanceClock extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;
    isPunchIn: number;

    constructor(props: Props) {
        super(props);
        this.id = "ATTANDANCE_CLOCK_MAIN";

        this.state = {
            employeeID: ''
        }

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.isPunchIn = 0;
        this.api = { create: "punch_clock" }

        this.actionButtons = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10167);
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        const { employeeID } = this.state;
        let buttonStyle = (type, color, api, text) => {
            return (<Button style={{ fontSize: 20, color: color }} size={'large'} onClick={() => {
                this.isPunchIn = api;
                this.api_punch_clock(api);
            }} >
                <Icon type={type} />
                {this.getText(text)}
            </Button>)
        }

        return (
            <div style={divStyle}>
                <Card hoverable={true}
                    bordered={false}
                    style={{ width: 300 }}
                    cover={<PageToolbar
                        title={this.getText(11136)}
                        actionButtons={this.actionButtons}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions} />}
                    actions={[
                        buttonStyle("arrow-down",'#f20e0e',0,11137),
                        buttonStyle("arrow-up",'#0cd119',1,11138),
                        // <div style={{ fontSize: 28, color: '#f20e0e' }}>
                        //     <Icon type="arrow-down" onClick={() => {
                        //         this.isPunchIn = 0;
                        //         this.api_punch_clock(this.isPunchIn);
                        //     }} />
                        //     {" " + this.getText(11137)}
                        // </div>,
                        // <div style={{ fontSize: 28, color: '#0cd119' }}>
                        //     <Icon type="arrow-up" onClick={() => {
                        //         this.isPunchIn = 1;
                        //         this.api_punch_clock(this.isPunchIn);
                        //     }} />
                        //     {" " + this.getText(11138)}
                        // </div>,
                    ]}>
                    <div>
                        <Input
                            autoFocus={true}
                            value={employeeID}
                            style={{ width: '100%', padding: '4px', marginBottom: '48px' }}
                            onChange={(e) => {
                                this.setState({ employeeID: e.target.value });
                            }}
                            placeholder={this.getText(11139)}
                        >
                        </Input>
                    </div>
                </Card>
            </div>
        );
    }

    api_punch_clock = (_isPunchIn: number) => { // 1 / 0
        let dataSend = "mWorkerId\fmIsPunchIn\r" + this.state.employeeID + '\f' + _isPunchIn;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'punch_clock'/*this.api.create*/, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
    };
    successCallback = (ob: Object) => {
        console.log("success:", ob.data);
        if (ob.data !== '0' && ob.data !== '') {
            let arr = ob.data.split('\f');
            let msg = this.getText(11140) + " ";
            msg += (this.isPunchIn === 1) ? this.getText(11141) : this.getText(11142);
            msg += " " + arr[1];
            msg += " " + this.getText(11143);
            msg += " " + arr[2];
            message.success(msg, 4);
            this.setState({ employeeID: '' });
        }
        else {
            message.error(this.getText(11144));
        }
    }
    errorCallback = (error: any) => {
        message.error(this.getText(11145));
    }
}

export default AttendanceClock;


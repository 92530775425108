/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import { Button, DatePicker, Form } from "antd";
import PageToolbar from "../../components/PageToolbar";
import moment from "moment";
import ReportTypeButton from "../../components/ReportTypeButton";
import DateTimePicker from "../../components/DateTimePicker";

const { MonthPicker } = DatePicker;
const nowMonth = moment().format("MMYY")

class TaxAdvancesReport extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            dateFrom: nowMonth,
            dateTo: nowMonth,
            reportName: "reports/TaxAdvances.pdf"
        };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(19269);
        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    disabledDate = (from, to) => {
        const diff = to.unix() - from.unix();
        const yearTime = 365 * 24 * 60 * 60;
        
        return diff < 0 || diff > yearTime
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            maxWidth: 500,
        };

        const valueDateFrom = this.state.dateFrom ? moment(this.state.dateFrom, "MMYY") : null
        const valueDateTo = this.state.dateTo ? moment(this.state.dateTo, "MMYY") : null
        const onChangeDate = (date, field) => { this.setState({ [field]: date ? date.format("MMYY") : null }) }

        return (

            <div style={divStyle}>

                <PageToolbar title={gt(19276)} actionButtons={[]} />


                <Form.Item label={gt(11866)}>
                    <MonthPicker
                        value={valueDateFrom}
                        format={"MM/YYYY"}
                        onChange={d => { onChangeDate(d, 'dateFrom') }}
                        disabledDate={d => this.disabledDate(d, valueDateTo)}
                    />
                </Form.Item>

                <Form.Item label={gt(11149)}>
                    <MonthPicker
                        value={valueDateTo}
                        format={"MM/YYYY"}
                        onChange={d => { onChangeDate(d, 'dateTo') }}
                        disabledDate={d => this.disabledDate(valueDateFrom, d)}
                    />
                </Form.Item>

                <ReportTypeButton
                    {...this.props}
                    params={this.state}
                    datesToFavorites={[
                        { field: 'dateFrom', label: this.getText(11866), format: 2 },
                        { field: 'dateTo', label: this.getText(11149), format: 2 },
                    ]}
                    favoriteSave
                />

            </div>)
    }

}

export default TaxAdvancesReport;
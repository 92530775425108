
import React from "react";
import GenericSelector from "./GenericSelector";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';

class GenericSelectorItemTags extends React.Component/*<Props, State>*/ {
    constructor(props) {
        super(props);
        this.id = 'GENERIC_MULTI_SELECTOR_ITEM_TAGS';
        this.api = 'get_tags_with_types'
        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericSelector }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        if (!this.props.notReport) {
            this.sendAPI('get_tags_from_type_6', '', ob => { if (ob.data) this.props.onChange(ob.data.split("\r").map(x => x.split("\f")[0])) })
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    onChange = (e) => {
        if (this.props.multi && e && e.length) {
            this.props.onChange(e.sort((a, b) => a - b))
        } else {
            this.props.onChange(e)
        }
    }

    render() {
       
        let gt = this.getText;
        
        return (<GenericSelector
            {...this.props}
            optionsGroup
            id={this.id}
            api={this.props.notApi ? undefined : this.api}
            onChange={this.onChange}
            modalStyle={{
                title: gt(this.props.multi ? 16730 : 16729),
                col1Title: gt(16731),
                col2Title: gt(16732),
                cancelText: gt(16733),
                clearText: gt(16734),
            }}
        />)
    }
}

export default GenericSelectorItemTags;

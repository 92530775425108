/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Col, Row, Button, Form, Select, Checkbox, Icon, Tooltip, Popover } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
//import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
//import ReportTypeButton from "../../components/ReportTypeButton";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import ReportFavoriteSave from "../../components/ReportFavoriteSave";
import TableLineIcons from "../../components/TableLineIcons";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const FormItem = Form.Item;
const Option = Select.Option;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class PaymentsRefunds extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            modalFilter: false,
            PaymentsRefunds: {
                mLowerSelectionDateIssue: null,
                mUpperSelectionDateIssue: null,
                mLowerSelectionDateUse: null,
                mUpperSelectionDateUse: null,
                mPosListIssue: '',
                mPosListUse: '',
                mIsUsed: '0',
                mEmployeeListIssue: '',
                mEmployeeListUse: '',
                reportName: "reports/Refunds.pdf",
            }
        };
        this.state.type = "pdf";

        this.clearState = { ...this.state.PaymentsRefunds }

        this.id = "REFUNDS_TABLE";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorPos] === undefined) props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };


        this.api = {
            get: "get_refunds_table",
            use: "use_refund",

            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
        }

        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        if (params.PosList === '') params = { ...params, PosList: fullPosList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(12767);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        if (posList && posList.dataset[0] && !this.state.loading) this.setState({ loading: true })

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState({ modalFilter: true }, () => {
                this.setReportParameters("PaymentsRefunds", this.props.ui.favoriteParams)
            })
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
    }

    // disabledStartDate = (FromDate) => {
    //     const ToDate = this.state.PaymentsRefunds.mLowerSelectionDateIssue;
    //     if (!FromDate || !ToDate) {
    //         return false;
    //     }
    //     return FromDate.valueOf() > ToDate.valueOf();
    // }

    // disabledEndDate = (ToDate) => {
    //     const FromDate = this.state.PaymentsRefunds.UpperSelectionDate;
    //     if (!ToDate || !FromDate) {
    //         return false;
    //     }
    //     return ToDate.valueOf() <= FromDate.valueOf();
    // }


    // onChange = (field, value) => {
    //     this.setReportParameters("PaymentsRefunds", { [field]: value })
    // }

    // onChange = (field, field2, value) => {
    //     let arr = (value ? value.format("YYYY-MM-DD#hh:mm:ss") : '').split('#');;

    //     console.log("f ", field, "v ", value);
    //     this.setReportParameters("PaymentsRefunds", { [field]: arr[0], [field2]: arr[1] })
    // }

    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/

    // createCheckBox = (field, data) => {
    //     return (
    //         <Checkbox
    //             style={{ display: 'inline' }}
    //             onChange={(e) => {
    //                 if (e.target.checked)
    //                     this.setReportParameters("PaymentsRefunds", { [field]: data });
    //                 else
    //                     this.setReportParameters("PaymentsRefunds", { [field]: '' });
    //             }} />);
    // }

    filterOk = () => {

        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, this.state.PaymentsRefunds, this.api.get);
        })
    }

    createDatePicker = (field) => {
        const { PaymentsRefunds } = this.state;
        let value = PaymentsRefunds[field];
        let minDate = field == 'mUpperSelectionDateIssue' ? PaymentsRefunds.mLowerSelectionDateIssue : field == 'mUpperSelectionDateUse' ? PaymentsRefunds.mLowerSelectionDateUse : null
        let maxDate = field == 'mLowerSelectionDateIssue' ? PaymentsRefunds.mUpperSelectionDateIssue : field == 'mLowerSelectionDateUse' ? PaymentsRefunds.mUpperSelectionDateUse : null
        return (<DatePicker
            format="DD-MM-YYYY"
            disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
            value={value ? moment(value, 'YYYY-MM-DD') : null}
            onChange={(value) => {
                let v = (value ? value.format("YYYY-MM-DD") : null);
                this.setReportParameters("PaymentsRefunds", { [field]: v })
            }} />)
    }

    createMultiSelector = (field, selector) => {
        if (selector == TableIDs.genericSelectorPos) {
            return (<GenericSelectorPosStorages {...this.props} multi
                onChange={(value) => this.setReportParameters("PaymentsRefunds", { [field]: value.join(',') })}
                value={(this.state.PaymentsRefunds[field] === '') ? [] : this.state.PaymentsRefunds[field].split(',')}
            />)
        }
        let list = Jax.get(this.props.data, selector + ".dataset", []).map((obj) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let fullData = Jax.get(this.props.data, selector + ".dataset", []).map((obj) => obj['code']).join(',');
        let cbChange = (e) => { this.setReportParameters("PaymentsRefunds", { [field]: e.target.checked ? fullData : "" }) }
        return (<span>
            <Checkbox style={{ display: 'inline' }} onChange={cbChange} />
            <Select key={field} style={{ width: '90%' }} showSearch filterOption={true} optionFilterProp="valuefilter" mode="multiple" maxTagCount={4}
                onChange={(value) => this.setReportParameters("PaymentsRefunds", { [field]: value.join(',') })}
                value={(this.state.PaymentsRefunds[field] === '') ? [] : this.state.PaymentsRefunds[field].split(',')}>{list}</Select>
        </span>)
    }

    reportBtn = () => {


        let subButton = (text, type, reportName) => {
            let onClick = () => {
                this.setState({ PaymentsRefunds: { ...this.state.PaymentsRefunds, reportName } }, () => {
                    this.generateReport("PaymentsRefunds")
                })
            }
            return (
                <Tooltip title={this.getText(text)}>
                    <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={onClick} />
                </Tooltip>)
        }

        let content = (<div>
            {subButton(16919, "file-pdf", "reports/Refunds.pdf")}
            {subButton(16920, "file-excel", "reports/Refunds.xls")}
            {subButton(16921, "file-word", "reports/Refunds.docs")}
        </div>);

        return (<Popover content={content} title={this.getText(16918)} trigger="click">
            <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
            </Button>
        </Popover>)
    }

    renderUseBtn = (text, record) => {
        return record.rUseDateTime ? "" : (
            <TableLineIcons
                genericIcons={[
                    {
                        type: "check-square",
                        onClick: () => {
                            this.props.dataActions.genericDataSaveEditWithParams(
                                this.id, this.props.user.companyCode, this.props.user.token, this.api.use, this.api.get, record)
                        },
                        tooltip: this.getText(16948),
                        popconfirm: { title: this.getText(16949), okText: this.getText(16950), cancelText: this.getText(16951) },
                    }
                ]}
            />)
    }

    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };


        let gt = this.getText;
        let sorter = true;
        let render = t => t;

        let mobile = window.innerWidth > 600 ? false : true;

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(12768)}
                searchBox
                filters={this.state.PaymentsRefunds}
                columns={[
                    { title: gt(16934), key: "rNumber", dataIndex: "rNumber", width: "9%", sorter, render },
                    { title: gt(16935), key: "rSum", dataIndex: "rSum", width: "9%", sorter, render },
                    { title: gt(16936), key: "rIssueDateTime", dataIndex: "rIssueDateTime", width: "10%", sorter, render },
                    { title: gt(16937), key: "rIssuePOS", dataIndex: "rIssuePOS", width: "7%", sorter, render },
                    { title: gt(16938), key: "rIssueEmployee", dataIndex: "rIssueEmployee", width: "12%", sorter, render },
                    { title: gt(17747), key: "rIssueDoc", dataIndex: "rIssueDoc", width: "9%", sorter, render },
                    { title: gt(16939), key: "rUseDateTime", dataIndex: "rUseDateTime", width: "10%", sorter, render },
                    { title: gt(16940), key: "rUsePOS", dataIndex: "rUsePOS", width: "7%", sorter, render },
                    { title: gt(16941), key: "rUseEmployee", dataIndex: "rUseEmployee", width: "12%", sorter, render },
                    { title: gt(16942), key: "rUseDoc", dataIndex: "rUseDoc", width: "9%", sorter, render },
                    { title: "", width: "6%", render: this.renderUseBtn },
                ]}
                actionButtons={[{
                    buttonType: 'filter',
                    buttonFunction: () => { this.setState({ modalFilter: true }) }
                }]}
                genericActionButtons={[this.reportBtn()]}
            />
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                title={gt(16943)}
                footer={[
                    <ReportFavoriteSave
                        {...this.props}
                        btnText={this.getText(16944)}
                        params={this.state.PaymentsRefunds}
                        posField={'mPosListIssue'}
                        datesToFavorites={[
                            { field: 'mLowerSelectionDateIssue', label: this.getText(12769) },
                            { field: 'mUpperSelectionDateIssue', label: this.getText(12770) },
                            { field: 'mLowerSelectionDateUse', label: this.getText(12771) },
                            { field: 'mUpperSelectionDateUse', label: this.getText(12772) },
                        ]}
                    />,
                    <Button key="Cancel" onClick={() => { this.setState({ modalFilter: false }) }}>{gt(16945)}</Button>,
                    <Button key="Clear" onClick={() => { this.setState({ PaymentsRefunds: this.clearState }) }}>{gt(16946)}</Button>,
                    <Button key="Filter" type={"primary"} onClick={this.filterOk}>{gt(16947)}</Button>,
                ]}
            >
                <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                // onSubmit={this.handleSaveChanges}
                >
                    <Row>
                        <Col span={mobile ? 24 : 12}>
                            <FormItem label={this.getText(12769)}>{this.createDatePicker("mLowerSelectionDateIssue")}</FormItem>
                        </Col>
                        <Col span={mobile ? 24 : 12}>
                            <FormItem label={this.getText(12770)}>{this.createDatePicker("mUpperSelectionDateIssue")}</FormItem>
                        </Col>
                    </Row>

                    <FormItem label={this.getText(12773)}>{this.createMultiSelector("mPosListIssue", TableIDs.genericSelectorPos)}</FormItem>
                    <FormItem label={this.getText(12775)}>{this.createMultiSelector("mEmployeeListIssue", TableIDs.genericSelectorEmployees)}</FormItem>

                    <Row>
                        <Col span={mobile ? 24 : 12}>
                            <FormItem label={this.getText(12771)}>{this.createDatePicker("mLowerSelectionDateUse")}</FormItem>
                        </Col>
                        <Col span={mobile ? 24 : 12}>
                            <FormItem label={this.getText(12772)}>{this.createDatePicker("mUpperSelectionDateUse")}</FormItem>
                        </Col>
                    </Row>

                    <FormItem label={this.getText(12774)}>{this.createMultiSelector("mPosListUse", TableIDs.genericSelectorPos)}</FormItem>
                    <FormItem label={this.getText(12776)}>{this.createMultiSelector("mEmployeeListUse", TableIDs.genericSelectorEmployees)}</FormItem>

                    <FormItem label={this.getText(12777)}>
                        <Select style={{ width: 290 }} value={this.state.PaymentsRefunds.mIsUsed}
                            onChange={(value) => { this.setReportParameters("PaymentsRefunds", { mIsUsed: value }) }}>
                            <Option key={1} value={'0'}> - </Option>
                            <Option key={2} value={'1'}>{this.getText(12778)}</Option>
                            <Option key={3} value={'2'}>{this.getText(12779)}</Option>
                        </Select>
                    </FormItem>

                </Form>
            </GenericModal>
        </div>);
    }
}
export default PaymentsRefunds;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Button, Modal, Input, message, Form } from "antd";

type Props = {
    mobileInput: String,
    mobileOnChange: Function,
    regiCodeOk: Function,
    visible: Boolean,
    onCancel: Function,
    sendAPI: Function,
    getText: Function,

}

class RegiCode extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            mobileToRegiCode: false,
            regiCodeValue: '',
            regiCodeError: false,
            timeoutForNewCode: -1,
        }

        this.mobile = window.innerWidth <= 600 ? true : false;

        this.api = {
            regi_code: "send_registration_code_to_site",
            check_regi_code: "check_registration_code_to_site",
            guest_cust: "save_guest_customer",
        }

        this.sendAPI = this.props.sendAPI;
        this.getText = this.props.getText;

        this.isSending = false;
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) {
            this.setState({ timeoutForNewCode: 60 }, this.timeoutForNewCodeOn)
        }
    }

    timeoutForNewCodeOn = () => {
        function to(a, b) {
            if (a()) {
                b();
                setTimeout(() => { to(a, b) }, 1000);
            }
        }
        let a = () => this.state.timeoutForNewCode > 0;
        let b = () => { this.setState({ timeoutForNewCode: this.state.timeoutForNewCode - 1 }) }
        if (this.state.timeoutForNewCode > -1) to(a, b);
    }

    mobileValidate = (mobile) => {
        var patt = new RegExp(/^05\d([-]{0,1})\d{7}$/);
        return patt.test(mobile);
    }

    sendCode = () => {
        let _mobile = this.props.mobileInput;
        let paramsToCheck = "_mobile\f_code\r" + _mobile + '\f' + this.state.regiCodeValue;

        if (this.props.passwordInput !== undefined && !this.props.passwordInput) {
            message.error(this.props.getText(13540));
        } else {
            this.props.sendAPI(this.api.check_regi_code, paramsToCheck, (ob) => {
                switch (ob.data) {
                    case '1':
                        this.props.regiCodeOk(_mobile);
                        this.onCancel();
                        break;
                    case '2':
                    case '3':
                        this.setState({ regiCodeValue: '', regiCodeError: ob.data });
                        break;
                }
            }, (e) => { console.error(e) });
        }
    }

    sendNewCode = () => {
        let _mobile = this.props.mobileInput;
        if (!this.mobileValidate(_mobile)) {
            message.error(this.getText(13528));
        } else if (!this.isSending) {
            this.isSending = true;
            let dataSend = "_mobile\f_is_reset\r" + _mobile + "\f1";
            this.props.sendAPI(this.api.regi_code, dataSend, (ob) => {
                switch (ob.data) {
                    case '1': this.setState({ regiCodeError: false, timeoutForNewCode: 60 }, this.timeoutForNewCodeOn); break;
                    case '2': this.props.regiCodeOk(_mobile); break;
                }
                this.isSending = false;
            }, (e) => {
                this.isSending = false;
                console.error(e)
            })
        }
    }

    onCancel = () => {
        this.setState({
            mobileToRegiCode: false,
            regiCodeValue: '',
            regiCodeError: false,
        }, this.props.onCancel)
    }

    render() {
        let buttonColors = this.props.buttonStyle;

        let buttonsStyle = {
            margin: 10,
            width: 140,
            height: 50,
            ...buttonColors,
        }

        let _mobile = this.props.mobileInput;
      
        let buttonNewCode = (<div style={{ marginTop: 10 }}>
            <div className={'text_item'}>{this.getText(13529)}</div>
            <Input
                className={"text_item"}
                style={{ height: 50 }}
                value={_mobile}
                onChange={this.props.mobileOnChange}
                onPressEnter={this.sendNewCode} />
            <Button style={buttonsStyle} onClick={this.sendNewCode}>
                <span className={"text_item"} style={buttonColors}>{this.getText(13527)}</span>
            </Button>
        </div>);

        let errorTextStyle = (text) => (<div><div style={{ color: 'red' }}> * {this.getText(text)}</div>{buttonNewCode}</div>);

        let passwordInput = this.props.passwordInput !== undefined ? (<div>
            <div className={'text_item'} style={{ marginTop: 10 }}>{this.props.getText(13539)}</div>
            <Input
                className={"text_item"}
                style={{ height: 50 }}
                value={this.props.passwordInput}
                onChange={this.props.passwordOnChange} />

        </div>) : '';

        let disabled = this.state.regiCodeValue.length < 4 ? true : false;
        if (disabled) {
            buttonColors = { ...buttonColors, background: '#dddddd' }
            buttonsStyle = { ...buttonsStyle, background: '#dddddd' }
        }

        return (<Modal
            title={this.props.getClassNameStyle("title_block")}
            visible={this.props.visible} onCancel={this.onCancel} destroyOnClose={true}
            width={this.mobile ? '100%' : 300}
            footer={
                [<Button style={buttonsStyle} disabled={disabled} onClick={this.sendCode}>
                    <span className={"text_item"} style={buttonColors}>{this.getText(13518)}</span>
                </Button>]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <div className={"text_item"} style={this.mobile ? { minHeight: window.innerHeight * 0.6 } : {}}>
                <h2 style={{ textAlign: 'center' }}>{this.getText(13519)}</h2>
                <h2 style={{ textAlign: 'center' }}>{this.getText(13520)}</h2>
                <Input
                    className={"text_item"}
                    style={{ height: 50 }}
                    value={this.state.regiCodeValue}
                    onBlur={() => {
                        if (this.state.regiCodeValue.length < 4) {
                            this.setState({ timeoutForNewCode: 0 })
                        }
                    }}
                    onChange={(e) => {
                        this.setState({
                            regiCodeValue: e.target.value,
                            regiCodeError: false,
                            timeoutForNewCode: -1,
                        })
                    }}
                    autoFocus={true}
                    onPressEnter={this.sendCode} />
                {
                    this.state.regiCodeError == '2' ? errorTextStyle(13525)
                        : this.state.regiCodeError == '3' ? errorTextStyle(13526)
                            : this.state.timeoutForNewCode > 0 ?
                                this.getText(14155) + " " + this.state.timeoutForNewCode + " " + this.getText(14156)
                                : this.state.timeoutForNewCode === 0 ? buttonNewCode : passwordInput

                }
            </div>
        </Modal >)
    }
}

export default RegiCode;
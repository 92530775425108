/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Icon } from 'antd';
import "../../App.css";
import TableIDs from '../../data/TableIDs';

class FoodFormatMenu extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            mobileMenu: true,
            tagsViewCount: 0,

            // mobileSecondMenu: false,
            secondTagsViewCount: 0,
        };

        this.tagChecked = false;
        this.mobile = window.innerWidth <= 700 ? true : false;

        this.id = "FOOD_FORMAT_LIST";
        this.setting_id = "FORMAT_SITE_SETTINGS";
        this.tags_id = TableIDs.genericSelectorTagsToSite;
        this.second_id = "SECONDARY_TAGS_TO_SITE";

        if (props.data[this.id] === undefined) props.data[this.id] = { data: [] };
        if (props.data[this.setting_id] === undefined) props.data[this.setting_id] = {};
        if (props.data[this.tags_id] === undefined) { props.data[this.tags_id] = { dataset: [] } }
        if (props.data[this.second_id] === undefined) { props.data[this.second_id] = { dataset: [] } }

    }

    componentDidUpdate(prev) {
        if (prev.tagActive !== this.props.tagActive) {
            this.tagChecked = true;
        }

        if (this.props.data[this.second_id].dataset.length && prev.data[this.second_id].dataset != this.props.data[this.second_id].dataset && this.tagChecked) {
            this.setState({ mobileMenu: true }, () => {
                this.tagChecked = false;
            })
        }
    }

    imgUrl = (x) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const baseUrl = isDev ? "http://147.235.163.248" : origin
        return baseUrl + "/uploads/" + this.props.companyCode + "/" + x;
    }

    getMenuStandart = () => {
        let tagsToSite = this.props.data[this.tags_id].dataset;
        if (this.props.secondTags) tagsToSite = this.props.data[this.second_id].dataset;
        if (this.mobile) {
            return (<div className={"mobile_block scrollmenu"}>
                {this.getMenuButtonsList(
                    tagsToSite,
                    {
                        display: 'inline-block',
                        width: this.props.secondTags ? 120 : 180,
                        fontSize: this.props.secondTags ? 15 : 25,
                    })}
            </div>)
        } else {
            let tagsList = [...tagsToSite];
            let spaceToMenu = window.innerWidth;
            let countMenu = Math.floor(spaceToMenu / 140);
            let rightAr = '';
            let leftAr = '';

            if (countMenu < tagsToSite.length) {
                spaceToMenu -= 50;
                countMenu = Math.floor(spaceToMenu / 140);
                if (this.state.tagsViewCount && (this.state.tagsViewCount + 1) * countMenu < tagsToSite.length) {
                    spaceToMenu -= 50;
                    countMenu = Math.floor(spaceToMenu / 140);
                }
                let sliceArray = this.state.tagsViewCount * countMenu;

                if (sliceArray) {
                    rightAr = (<div className={"menu_button"} style={{ width: 50 }} onClick={() => {
                        this.setState({ tagsViewCount: this.state.tagsViewCount - 1 });
                    }} >{'<<'}</div>);
                }

                if ((sliceArray + countMenu) < tagsToSite.length) {
                    leftAr = (<div className={"menu_button"} style={{ width: 50 }} onClick={() => {
                        this.setState({ tagsViewCount: this.state.tagsViewCount + 1 })
                    }}>{'>>'}</div>);
                }

                tagsList = tagsList.slice(sliceArray, sliceArray + countMenu);
            }

            return [rightAr, this.getMenuButtonsList(tagsList, {}), leftAr]
        }
    }

    getMenuMobileVertical = () => {
        let content = this.state.mobileMenu ?
            this.getVerticalTagsMenu()
            : this.getMenuButton(true, {}, false, false, this.props.titleText);

        return (<div className={"mobile_block"}>
            <div className={"mobile_menu_button"} style={{ textAlign: 'center' }}>
                <Icon type={'menu'} onClick={() => {
                    if (this.state.mobileMenu) {
                        if (this.props.tagActive) {
                            this.setState({ mobileMenu: false })
                        } else {
                            let tagsToSite = this.props.data[this.tags_id].dataset;
                            if (tagsToSite[0]) this.onCheckTag(tagsToSite[0]);
                        }
                    } else {
                        this.setState({ mobileMenu: true })
                    }
                }} />
            </div>
            <div className={"mobile_menu_div"}>
                {content}
            </div>
        </div>)
    }

    getMenuButtonsList = (list, style) => {
        return list.map((x) => {
            let apiItems = () => { this.props.apiItems(x.code, this.id, false) }
            let isActive = x.code === this.props.tagActive;
            return this.getMenuButton(isActive, style, x.color, x.image, x.name, () => {
                if (this.props.secondTags) {
                    this.props.onChangeState({ secondTag: x.code, secondTagName: x.name }, apiItems)
                } else {
                    this.props.onChangeState({ tag: x.code, tagName: x.name, secondTag: '', secondTagName: '' }, apiItems)
                }
            })
        });
    }

    getMenuButton = (isActive, style, color, image, name, onClick, typeStyle) => {
        let imgStyle = image ?
            (<div style={{ float: 'right', marginLeft: 10 }}>
                <img src={this.imgUrl(image)} style={{ width: this.props.isStyle5 ? 22 : 30, height: this.props.isStyle5 ? 22 : 30, borderRadius: '50%' }} />
            </div>)
            : '';
        let nameStyle = (<div style={{ width: "100%" }} className={typeStyle && !this.props.isStyle4 && !this.props.isStyle5 && !this.props.isStyle6 ? 'font_style_' + typeStyle : ''}>{name}</div>)
        let content = [imgStyle, nameStyle];

        if (color) {
            style = {
                ...style,
                background: color
            }
        } else if ((this.props.isStyle4 || this.props.isStyle5 || this.props.isStyle6) && !this.mobile) {
            style = {
                ...style,
                background: isActive ? this.props.isStyle5 ? "orange" : this.props.isStyle6 ? "#ddc268" : "#be1e2d" : "white",
                color: this.props.isStyle5 ? '#443325' : isActive && !this.props.isStyle6 ? "white" : "black",
                fontFamily: "FbJoker",
                border: "1px solid #eeeeee",
                textAlign: "right",
                fontWeight: isActive && !this.props.isStyle5 ? "auto" : "bold",
                paddingRight: 40
            }
        } else if ((this.props.isStyle4 || this.props.isStyle5 || this.props.isStyle6) && this.mobile) {
            style = {
                ...style,
                background: "white",
                color: "black",
                fontFamily: "FbJoker",
                textAlign: "center",
                fontWeight: isActive ? "bold" : "normal",
                width: (name.length * 10) + 30
            }
        } else if (this.props.data[this.setting_id].rNavbar2) {
            style = {
                ...style,
                background: this.props.data[this.setting_id].rNavbar2
            }
        }


        return (this.props.isStyle4 || this.props.isStyle5 || this.props.isStyle6) && !this.mobile ?
            <div>
                <hr style={{ borderTop: "1px solid #cccccc", marginTop: 2, marginBottom: 4 }} />
                <div className={"menu_button"} style={style} onClick={onClick}>{content}</div>
            </div>
            : isActive && typeStyle != 2 ?
                this.props.getClassNameStyle("menu_button_active", {
                    ...style,
                    border: (this.props.isStyle4 || this.props.isStyle5 || this.props.isStyle6) ? 0 : '2px solid #777777',
                    borderRadius: 4,
                    height: (this.props.isStyle4 || this.props.isStyle5 || this.props.isStyle6) ? 40 : style.height,
                }, content)
                : (<div className={"menu_button"} style={style} onClick={onClick}>{content}</div>)

    }

    getVerticalTagsMenu = () => {
        const { isStyle3, isStyle4, isStyle5, isStyle6 } = this.props;
        let tagsToSite = this.props.data[this.tags_id].dataset;
        let secondTagsToSite = this.props.data[this.second_id].dataset;
        let isFromPos = this.props.isFromPos;
        let vStyle = {
            display: 'block',
            width: isFromPos ? '90%' : isStyle4 || isStyle5 || isStyle6 ? '99%' : '95%',
            fontSize: isStyle3 || isStyle4 || isStyle5 || isStyle6 ? 22 : 27,
            height: isStyle3 || isStyle4 || isStyle5 || isStyle6 ? 46 : 65,
            color: isStyle3 || isStyle4 || isStyle5 || isStyle6 ? "#000000" : '#ffffff'
        }
        let sStyle = { display: 'block', width: '50%', marginRight: '25%', fontSize: 15 }
        let dPosStyle = { height: window.innerHeight - 240, overflowY: 'scroll', paddingBottom: 130 }
        let dStyle = isStyle3 || isStyle4 || isStyle5 || isStyle6 ? {} : { height: window.innerHeight - 320, overflowY: 'scroll' }

        let menuContent = tagsToSite.map((x, i) => {
            let vvStyle = vStyle;
            if (isStyle3 && i == 0) {
                vvStyle = { ...vvStyle, borderTopLeftRadius: 30 }
            }

            if (isStyle3 && !tagsToSite[i + 1]) {
                vvStyle = { ...vvStyle, borderBottomLeftRadius: 30 }
            }

            return x.code === this.props.tagActive ?
                [
                    this.getMenuButton(true, vvStyle, x.color, x.image, x.name, () => {
                        this.onCheckTag(x)
                    }, 2),

                    secondTagsToSite.map((y) => {
                        return this.getMenuButton(y.code === this.props.secondTagActive, sStyle, y.color, y.image, y.name, () => {
                            this.setState({ mobileMenu: false }, () => {
                                this.props.onChangeState({ secondTag: y.code, secondTagName: y.name }, () => {
                                    this.props.apiItems(y.code, this.id, false)
                                })
                            })
                        }, 2)
                    })
                ] :
                this.getMenuButton(false, vvStyle, x.color, x.image, x.name, () => { this.onCheckTag(x) }, 2)
        });

        return (<div style={isFromPos ? dPosStyle : dStyle}>{menuContent}</div>);
    }

    onCheckTag = (x) => {
        this.setState({ mobileMenu: false }, () => {
            this.props.onChangeState({ tag: x.code, tagName: x.name, secondTag: '', secondTagName: '' }, () => {
                this.props.setJson(this.second_id, { dataset: [] })
                this.props.apiItems(x.code, this.id, false)
                this.props.changeToShopScreen()
            })
        })
    }

    render() {

        return this.props.vertical ?
            this.mobile ? this.getMenuMobileVertical() : this.getVerticalTagsMenu()
            : this.getMenuStandart()

    }
}

export default FoodFormatMenu;
/* @flow */
/*jshint esversion: 6 */
import React, {Component} from "react";
import {Modal, Form, Input, InputNumber, Select, Switch, DatePicker, TimePicker} from "antd";
import ItemPicker from "./ItemPicker";
import "../App.css";
import LangContext from "../contextProvider/LangContext";

const FormItem = Form.Item;



export type FieldNode = Input | InputNumber | Select | Switch | DatePicker | TimePicker | ItemPicker;
export type Field = {
    key: any,
    label: string,
    help: string,
    node: FieldNode,
}

type Props = {
    visible: boolean,
    workInProgress: boolean,
    title: string,
    recordData: Object,
    saveAction: Function,
    cancelAction: Function,
    token: string,
    companyCode: string,
    fields: Array<Field>,
    zIndex: number
};

type State = { };



export class ModalForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {recordData: props.recordData};
    }

	static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    handleModalCancel () {
        this.props.cancelAction();
    }

    handleModalOk () {
        this.props.saveAction(this.props.recordData, this.props.companyCode, this.props.token);
    }

    getFormItems () {
        return this.props.fields.map((value: Field) => {
            return (
                <FormItem key={value.key} label={value.label} help={value.help}>{value.node}</FormItem>
            );
        });
    }

    render() {

        return (
            <Modal 
            zIndex={this.props.zIndex} 
            title={this.props.title} 
            visible={this.props.visible} 
            closable={true} 
            maskClosable={true} 
            okText={this.getText(11423)} 
            cancelText={this.getText(11424)} 
            confirmLoading={this.props.workInProgress} 
            onCancel={this.handleModalCancel.bind(this)} 
            onOk={this.handleModalOk.bind(this)} 
            width={window.innerWidth > 1000 ? '50%': '95%'}>
                <div>
                    <Form className="data-entry-form" layout="vertical" onSubmit={this.handleModalOk.bind(this)}>
                        {this.getFormItems()}
                    </Form>
                </div>
            </Modal>
        );
    }

}

// export default ModalForm;
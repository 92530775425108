/* @flow */
/*jshint esversion: 6 */

import React from "react";
import "../App.css";
import {
    Form, //Row, Col, Input, Icon, 
    Button, //Popconfirm, Tooltip, 
    Modal, //Table, Checkbox, 
    Select, //message 
} from 'antd';
//import { DatePicker } from 'antd';
import moment from "moment";
import Jax from "../Jax/jax.es6.module";
//import ExtendedTable from "./ExtendedTable";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableIDs from '../data/TableIDs.js';
import PageToolbar from "./PageToolbar";
//import ItemPicker from "./ItemPicker";
//import SelectorItems from "./SelectorItems";
import LangContext from "../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

class ModalSearchMultilingual extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.id = "MULTILINGUAL_TABLE";

        this.selectors = [
            { id: TableIDs.genericSelectorLanguages, script: "get_languages_list" },
            { id: TableIDs.genericSelectorDocumentsIDs, script: "get_documents_ids_list" },
            { id: TableIDs.genericSelectorElements, script: "get_elements_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.selectors.map((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.state = {
            loading: false,
            visible: false,
            _language: "2",
            _document_id: "",
            _element: "",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshSelectors();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toggle !== this.props.toggle) this.setState({ visible: this.props.toggle })
        // let _state = { ...this.state };
        // _state.visible = newProps.toggle;
        // this.setState(_state);
        //this.setState({ visible: newProps.toggle });

    }

    // componentDidUpdate(){
    //     let lang = () => {
    //         let res =""
    //         Jax.get(this.props.data, TableIDs.genericSelectorLanguages + ".dataset", []).map((obj) => {
    //             if (obj.code === this.state._language) res = obj.name;
    //         });
    //         return res;
    //     }

    //     if(this.state._language_name === "") this.setState({_language_name: lang()})
    // }

    refreshSelectors = () => {
        this.selectors.map((x) => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericSelectorRefreshDataset,
                args: [x.id, this.props.user.companyCode, this.props.user.token, x.script]
            });
        });
    }

    refreshData = () => {
        this.refreshSelectors();
        this.setState({
            _language: "2",
            _document_id: "",
            _element: "",
        }, () => {

            //  console.log("yuda refreshData ", this.state)
        })
    }

    handleOk = () => {
        let request = {
            ...this.props.data[this.id].filters,
            _language: this.state._language,
            _document_id: this.state._document_id,
            _element: this.state._element,
        }
        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            request,
            "get_multilingual_table");

        this.handleCancel();
        if (this.props.setFilterActive) this.props.setFilterActive(request._document_id || request._element || request._words)
    }

    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }

    getSelector = (num, label, filter) => {
        let sId = this.selectors[num].id;

        let options = Jax.get(this.props.data, sId + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
        });

        return (<FormItem label={label}>
            <Select
                key={filter}
                showSearch
                filterOption={true}
                optionFilterProp="valuefilter"
                onChange={(v) => { this.setState({ [filter]: v }) }}
                value={this.state[filter]}
            >
                {num > 0 ? (<Option value={""}> - </Option>) : ""}
                {options}
            </Select>
        </FormItem>)
    }

    render() {
        const { visible, loading, endOpen } = this.state;

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        // console.log("yuda render ", this.state)
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>);

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={window.innerWidth > 600 ? '70%' : '95%'}
                visible={visible}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={/**/null}
            >
                <Form id="search_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '3' : '1' }}
                    onSubmit={this.handleSaveChanges}
                >
                    {this.getSelector(0, this.getText(10307), "_language")}
                    {this.getSelector(1, this.getText(10308), "_document_id")}
                    {this.getSelector(2, this.getText(10309), "_element")}
                </Form>
                <div className="action_footer">
                    <Button key="back" onClick={this.handleCancel}>{this.getText(10304)}</Button>
                    <Button key="clear" onClick={this.refreshData}>{this.getText(10305)}</Button>
                    <Button form="search_working_hours_form" key="create" type="primary" loading={loading}
                        onClick={this.handleOk}>{this.getText(10306)}</Button>
                </div>
            </Modal >
        )
    }

}

export default ModalSearchMultilingual;

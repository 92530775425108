/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../App.css";
import LangContext from "../contextProvider/LangContext";
import { Button, Input, Popover } from "antd";


class InputStairs extends Component {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        const { value, visible, onChange, onClickInput, min, max } = this.props;
        let valueList = value ? value.split(",").map(x => parseInt(x)) : []
        let numList = [];
        for (let x = min; x <= max; x++) { numList.push(x) }
        let content = (<div>
            {numList.map((y, i) => {
                return (<span>
                    {i && !(i % 10) ? <br /> : ""}
                    <Button
                        style={{ width: 50, margin: 2 }}
                        type={valueList.indexOf(y) > -1 ? "primary" : null}
                        onClick={() => {
                            if (valueList.indexOf(y) == -1) valueList.push(y)
                            else valueList = valueList.filter(f => f != y)
                            valueList.sort((a, b) => a - b);
                            onChange(valueList.join(","))
                        }}>{y}</Button>
                </span>)
            })}
        </div>)
        return (<Popover visible={visible} title={this.getText(17954)} content={content}>
            <Input readOnly value={value} onClick={onClickInput} />
        </Popover>)
    }
}

export default InputStairs;
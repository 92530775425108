/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Table, Icon, Tooltip } from "antd";

import TableIDs from "../../data/TableIDs";
//import PageToolbar from "../../components/PageToolbar";
import ModalAccumulationOfAggregateCard from "../../components/ModalAccumulationOfAggregateCard";
import ModalSearchAggregateCard from "../../components/ModalSearchAggregateCard";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class CustomerAggregateCard extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "CUSTOMER_AGGREGATE_CARD_LIST";
        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,

            filterActive: false,
            valueSearch: '',


        };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }


        this.api = {
            get: "get_dealsActuals_aggregateCard_list",
        }


        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataGetWithParams, args: ["customerList", this.props.user.companyCode, this.props.user.token, {
                dataObject: "customerList"
            }, "data_get"]
        });
    }


    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10160);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _creationDateStart: null,
            _creationDateEnd: null,
            _activityDateStart: null,
            _activityDateEnd: null,
            _customer: null,
            _posList: '',
            _isUsed: 0,
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

        this.setState({ filterActive: false, valueSearch: "" })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }


    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 6;
        // fall through
        switch (sorter.field) {
            case "key": col--; // fallsthrough  // 0
            case "dId": col--; // fallsthrough 
            case "dCustomerId": col--; // fallsthrough 
            case "dCustomerName": col--; // fallsthrough 
            case "dName": col--; // fallsthrough 
            case "dItemId": col--; // fallsthrough 
            case "dItemName": col--; // fallsthrough 
            case "dQuantity": col--; // fallsthrough 
            case "dRemain": break; // 6
            default:
                col = 0;
        }

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,

        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    dataSetFilter = (request) => {
        let filters = this.props.data[this.id].filters;
        console.log("yuda filters ", { ...filters, ...request })
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { ...filters, ...request }, this.api.get)
    }

    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let request = {
            // ui-pagination 
            _id: 0,
            _fieldId: false,
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            {
                title: this.getText(11023),
                dataIndex: "dId",
                key: "dId",
                sorter: false,
                width: '10%',
                render: (text, record) => text,
            }, {
                title: this.getText(11024),
                dataIndex: "dCustomerId",
                key: "dCustomerId",
                width: '10%',
                sorter: false,
                render: (text, record) => text
            }, {
                title: this.getText(11025),
                dataIndex: "dCustomerName",
                key: "dCustomerName",
                width: '15%',
                sorter: false,
                render: (text, record) => text
            }, {
                title: this.getText(11026),
                dataIndex: "dName",
                key: "dName",
                width: '15%',
                sorter: false,
                render: (text, record) => text
            }, {
                title: this.getText(11027),
                dataIndex: "dItemId",
                key: "dItemId",
                width: '10%',
                sorter: false,
                render: (text, record) => text
            }, {
                title: this.getText(11028),
                dataIndex: "dItemName",
                key: "dItemName",
                width: '15%',
                sorter: false,
                render: (text, record) => text
            }, {
                title: this.getText(11029),
                dataIndex: "dQuantity",
                key: "dQuantity",
                width: '8%',
                sorter: false,
                render: (text, record) => text
            }, {
                title: this.getText(11030),
                dataIndex: "dRemain",
                key: "dRemain",
                width: '8%',
                sorter: false,
                render: (text, record) => text
            }, {
                title: this.getText(11031),
                width: '8%',
                render: (text, record) => {
                    return (<Tooltip title={this.getText(11032)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => {
                            this.setState({ customerId: record['dCustomerId'] },
                                this.props.uiActions.showModal(TableIDs.modalAccumulationOfAggregateCard,
                                    { customerId: record['dCustomerId'], customerIndex: record.index }))
                        }} />
                    </Tooltip>);
                }
            }
        ];

        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[false, 30, 35, 35]}
                    title={this.getText(11022)}
                    actionButtons={[
                        {
                            buttonType: 'filter',
                            buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchAggregateCard) }
                        }, {
                            buttonDisabled: !this.state.filterActive,
                            buttonType: 'clear',
                            buttonFunction: this.initFilters,
                        }, {
                            buttonType: 'refresh',
                            buttonFunction: this.refreshData
                        },
                    ]}
                    // genericActionButtons={this.genericActionButtons}
                    showsearch={
                        (val) => {
                            if (val) { this.setState({ filterActive: true }) }
                            this.props.dataActions.genericDataSetFilter(
                                this.id, this.props.user.companyCode, this.props.user.token,
                                {
                                    ...request, _words: val
                                        .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                    page: 1,
                                    _page_num: 1,
                                }, this.api.get)
                        }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ModalAccumulationOfAggregateCard
                    id={TableIDs.modalAccumulationOfAggregateCard}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalAccumulationOfAggregateCard)}
                />
                <ModalSearchAggregateCard
                    id={TableIDs.modalSearchAggregateCard}
                    dataSetFilter={this.dataSetFilter}
                    user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchAggregateCard)}
                    filterActive={this.state.filterActive}
                    setFilterActive={e => {
                        if (e) this.setState({ filterActive: true })
                        else this.initFilters()
                    }}
                />
            </div>
        );
    }
}

export default CustomerAggregateCard;


/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";


type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string
};
type State = {};

class SettingIndex extends Component<Props, State> {

    componentDidMount() {
        document.title = "Cash On Tab - הגדרות";
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        return (
            <div style={divStyle}>
                <h1>הגדרות מערכת</h1>
                <Row gutter={16}>
                    <Col span={8} order={1}>
                        <Card title="צבירת נקודות" bordered={true} extra={<Link to={this.props.baseUrl + "/customers/earning-points"}>ערוך הגדרות</Link>}>שנה את החוקים המשפיעים איך, מתי וכמה נקודות נצברות עבור לקוחות</Card>
                    </Col>
                    <Col span={8} order={3}>
                        <Card title="שימוש בנקודות" bordered={true} extra={<Link to={this.props.baseUrl + "/customers/using-points"}>ערוך הגדרות</Link>}>שנה את ההגדרות המשפיעות על השימוש בנקודות של לקוחות בזמן מכירה</Card>
                    </Col>
                    <Col span={8} order={2}>
                        <Card title="" bordered={true} loading={true} />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default SettingIndex;
/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { Input, Icon, Button, Tooltip, Col, Row, message, List, Pagination } from "antd";
import Jax from "../Jax/jax.es6.module";
import TableIDs from '../data/TableIDs.js';
import LangContext from "../contextProvider/LangContext";


type Props = {
  itemID: any,
  onClick?: (row, col) => void,
};

type State = {};

class MatrixGridQuantities extends React.Component<Props, State> {
  constructor(props/*: Props*/) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      gridPageY: 1,
      gridPageX: 1,
    }
    this.state._id = null;
    //this.state.matrixQuantitiesObj = {};

    this.id = "MATRIX_GRID";
    this.id_edit = "MATRIX_GRID_EDIT";
    // this.id_size_bar = "SIZE_BAR";
    // this.id_color_bar = "COLOR_BAR";
    //this.id_meta = "MODAL_MATRIX_META";

    this.api = {
      //get: "get_matrix_grid",
      get: "get_matrix_grid_with_item_meta",
      create: "add_to_matrix_grid",

      get_color_bar: "get_color_bar",
      get_size_bar: "get_size_bar_per_sons",
    };
    // _rulerId
    if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
    if (props.data[this.id_edit] === undefined) { props.data[this.id_edit] = { ...props.data.genericData }; }
    if (props.data[TableIDs.matrixQuantities] === undefined) { props.data[TableIDs.matrixQuantities] = {} }; //{ ...props.data.genericData }; }

    //if (props.data[this.id_meta] === undefined) { props.data[this.id_meta] = { ...props.data.genericData }; }

    if (props.data[TableIDs.genericSelectorColors] === undefined) {
      props.data[TableIDs.genericSelectorColors] = { ...props.data.genericSelector };
    }
    if (props.data[TableIDs.genericSelectorSizes] === undefined) {
      props.data[TableIDs.genericSelectorSizes] = { ...props.data.genericSelector };
    }
  }

  static contextType = LangContext;

  getText = (id) => {
    return this.context.get(id) || '[' + id + ']'
  }

  componentDidMount() {
    let params = { _id: this.props.itemCode /*, matrixQuantitiesObj: {}*/ };

    if (this.props.itemCode) this.updateAllData(params)

    this.props.dataActions.genericSelectorRefreshDataset(
      TableIDs.genericSelectorColors, this.props.user.companyCode, this.props.user.token, this.api.get_color_bar, params);

    this.props.dataActions.genericSelectorRefreshDataset(
      TableIDs.genericSelectorSizes, this.props.user.companyCode, this.props.user.token, this.api.get_size_bar, params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemCode && (this.props.itemCode !== prevProps.itemCode)) {
      this.updateAllData({ _id: this.props.itemCode });
    }
  }

  updateAllData = (params) => {
    this.setState(params, () => {
      this.getGridData(params, this.id);
      this.getGridData({ _id: -1 }, this.id_edit);
    });

    setTimeout(() => {
      console.log('grid data matrix test', this.props.data[this.id])
    }, 3000);
  }

  render() {
    const { gridPageX, gridPageY } = this.state;
    const maxTileWidth = 48;
    const countTilesInRow = Math.floor((window.innerWidth * 0.4) / maxTileWidth);
    const countTilesInCol = Math.floor((window.innerHeight * 0.4) / maxTileWidth);

    let colorsDataset = [...this.props.data[TableIDs.genericSelectorColors].dataset]
    let sizesDataset = [...this.props.data[TableIDs.genericSelectorSizes].dataset];

    let sumPagesX = 1;
    let sumPagesY = 1;

    console.log('colorsDataset.length', colorsDataset.length)

    if (colorsDataset.length > countTilesInCol) {
      sumPagesY = Math.ceil(colorsDataset.length / countTilesInCol)
      colorsDataset = colorsDataset.slice((gridPageY - 1) * countTilesInCol, gridPageY * countTilesInCol);
    }

    if (sizesDataset.length > countTilesInRow) {
      sumPagesX = Math.ceil(sizesDataset.length / countTilesInRow);
      sizesDataset = sizesDataset.slice((gridPageX - 1) * countTilesInRow, gridPageX * countTilesInRow);
    }

    const maxRowWidth = maxTileWidth * sizesDataset.length + 100;

    const colLength = 100 / sizesDataset.length;
    const rowLength = 100 / colorsDataset.length;

    const sizes_bar = sizesDataset.map((obj, index) => {
      return (
        <div key={obj['code']} value={obj['code']}
          style={{
            //writingMode: 'vertical-lr',
            //textOrientation: 'sideways',
            //textOrientation: 'use-glyph-orientation',
            transform: 'rotate(60deg)',
            //
            textAlign: 'center',
            padding: 4,
            marginBottom: 4,
            display: 'inline-block',
            minHeight: 24, minWidth: maxTileWidth,
            maxHeight: 96, maxWidth: maxTileWidth,
            height: (rowLength).toString() + '%', width: (colLength).toString() + '%'
          }}
        >{obj['name']}</div>
      )
    });

    const colors_bar = colorsDataset.map((obj, index) => {
      return (<div key={obj['code']} value={obj['code']}
        style={{
          textAlign: 'left',
          padding: 4,
          minHeight: maxTileWidth, minWidth: maxTileWidth,
          maxHeight: maxTileWidth, //maxWidth: 48,
          //height: (rowLength).toString() + '%', width: (colLength).toString() + '%'
          height: '100%', width: '100%'
        }}
      ><span style={{ verticalAlign: 'middle', }}>{obj['name']}</span></div>)
    });

    const checkStatus = (rowId, colId) => {
      // 0 - no | 1 - seleceted | 2 - set
      // rowId - color | colId - size
      // 5 - rulerid1 | 7 - colorid
      const isSet = this.props.data[this.id].data
        .map(i => i.split('\f'))
        .filter(i => i[7] === rowId && i[5] === colId)
        .length > 0;

      const isSelected = this.props.data[this.id_edit].data
        .map(i => i.split('\f'))
        .filter(i => i[7] === rowId && i[5] === colId)
        .length > 0;

      if (isSet === true) {
        return 2;
      } else if (isSelected === true) {
        return 1;
      } else {
        return 0;
      }
    };

    const tileColor = (rowId, colId) => {
      const status = checkStatus(rowId, colId);
      if (status === 2) {
        return '#5cd161'; //'#4CAF50'; // green
      } else if (status === 1) {
        return '#f44336'; // red
      }
      else {
        return null
      }
    }

    //(isChecked() === true) ? '#4CAF50' : '#f44336'; // #4CAF50 green // #f44336 red
    const tile = (rowId, rowName, colId, colName) => {
      return (
        <Tooltip title={<div>{this.getText(12541)}: {rowName}<br />{this.getText(12542)}: {colName}</div>}>
          <div
            className={"highlightOnFocus"}
            key={rowId + '#' + colId}
            value={rowId + '#' + colId}
            style={{
              //alignItems: 'center',
              backgroundColor: tileColor(rowId, colId),
              display: 'inline-block',
              borderStyle: 'solid', borderWidth: 0.5,
              minHeight: maxTileWidth, minWidth: maxTileWidth,
              maxHeight: maxTileWidth, maxWidth: maxTileWidth,
              height: (rowLength).toString() + '%',
              width: (colLength).toString() + '%',

            }}
          >
            <input
              key={rowId + '#' + colId}
              type="text"
              //pattern="\d*"
              pattern="\d+"
              //type="number" 
              min="0" step="1"
              style={{ textAlign: 'center', height: 44, marginRight: 2, marginLeft: 2, width: 44, backgroundColor: "transparent", borderColor: "transparent" }}
              //value={(this.state.matrixQuantitiesObj && this.state.matrixQuantitiesObj[rowId + '#' + colId]) ? this.state.matrixQuantitiesObj[rowId + '#' + colId] : ''}
              value={(this.props.data[TableIDs.matrixQuantities] &&
                this.props.data[TableIDs.matrixQuantities][rowId + '#' + colId])
                ? this.props.data[TableIDs.matrixQuantities][rowId + '#' + colId].quantity : ''}
              onChange={(e) => {
                // const val = e.target.value.replace(/\+|-/ig, '');
                const val = parseInt(e.target.value);
                const valKey = rowId + '#' + colId;

                console.log("grid_val", val)

                const _valObj = this.props.data["MATRIX_GRID"].data
                  .map(i => i.split('\f'))
                  .filter(i => i[7] === rowId && i[5] === colId)
                  .map(i => { return { itemName: i[8] } });

                //console.log("vvvvv ", _valObj);
                const valObj = (_valObj.length > 0) ? _valObj[0] : [{}]
                //console.log("vvvvv2 ", { quantity: val, ...valObj })

                // if (val === 0 || val === '0' || val === '') {
                if (!val) {
                  this.props.dataActions.setJson(TableIDs.matrixQuantities, { /*...this.state.matrixQuantitiesObj,*/[valKey]: undefined });
                }
                else {
                  this.props.dataActions.setJson(TableIDs.matrixQuantities, { /*...this.state.matrixQuantitiesObj,*/[valKey]: { quantity: val, ...valObj } });
                }

                //this.setState({ matrixQuantitiesObj: { ...this.state.matrixQuantitiesObj, [valKey]: val } })

              }}
              disabled={(checkStatus(rowId, colId) === 2) ? false : true}
            />
          </div>
        </Tooltip >)
    }

    const cols = (_rowId, _rowName) => {
      return sizesDataset.map((obj, index) => <div key={obj['code']} style={{ display: 'inline' }}>{tile(_rowId, _rowName, obj['code'], obj['name'])}</div>)
    }
    const rows = colorsDataset.map((obj, index) => <div key={obj['code']} style={{ width: maxRowWidth }}>{cols(obj['code'], obj['name'])}</div>)

    const gridData = <div>{rows}</div>;

    const axisLabel = <div style={{ direction: 'rtl', textAlign: 'left' }}><sub>{this.getText(12543)}</sub>/<sup>{this.getText(12544)}</sup></div>;

    const quantities_list = Object.keys(this.props.data[TableIDs.matrixQuantities] /*this.state.matrixQuantitiesObj*/)
      .filter(_key => this.props.data[TableIDs.matrixQuantities][_key])
      .map((_key, index) => {
        return [index + 1,
        this.props.data[TableIDs.matrixQuantities][_key].quantity
          /*this.state.matrixQuantitiesObj[_key]*/, ..._key.split('#')]
      })

    const totalQuantity = quantities_list.reduce((acc, curr) => acc + parseInt(curr[1], 10), 0);

    return (
      <div key="grid" style={{ marginTop: 16, marginBottom: 16 }}>

        <Row type={"flex"}>
          <Col order={1} xs={24} sm={24} md={24} lg={16} /*span={12}*/>
            <div style={{ overflowX: /*'scroll'*/'auto', overflowY: /*'scroll'*/'auto', height: '100%', }}>
              {sumPagesX > 1 ? (<Pagination style={{ direction: 'ltr' }} onChange={x => { this.setState({ gridPageX: x }) }} total={sumPagesX * 10} />) : ""}
              <Row>
                <Col span={3}>{axisLabel}</Col>
                <Col span={21}><div style={{ width: maxRowWidth }}>{sizes_bar}</div></Col>
              </Row>
              <Row>
                <Col span={1}>{sumPagesY > 1 ? (<Pagination style={{ direction: 'ltr' }} onChange={x => { this.setState({ gridPageY: x }) }} total={sumPagesY * 10} />) : ""}</Col>
                <Col span={2}>{colors_bar}</Col>
                <Col span={21}>{gridData}</Col>
              </Row>
            </div>
          </Col>
          <Col order={2} xs={24} sm={24} md={24} lg={8} /*span={12}*/>
            <div style={{ padding: 4, overflowX: /*'scroll'*/'auto', overflowY: /*'scroll'*/'auto', height: '100%', }}>
              <List
                itemLayout="vertical"
                size="small"
                header={<div>
                  <Row><div style={{ textAlign: 'center' }}>{this.getText(12545)}</div></Row>
                  <Row>
                    <Col span={2}></Col>
                    <Col span={7}>{this.getText(12546)}</Col>
                    <Col span={7}>{this.getText(12547)}</Col>
                    <Col span={8}>{this.getText(12548)}</Col>
                  </Row>
                </div>
                }
                //footer={<div>Footer</div>}
                bordered
                //grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 6 }}
                dataSource={quantities_list}
                locale={{ emptyText: this.getText(12549) }}
                renderItem={item => {
                  let sizeFind = this.props.data[TableIDs.genericSelectorSizes].dataset.find((i) => i['code'] === item[3]);
                  let colorFind = this.props.data[TableIDs.genericSelectorColors].dataset.find((i) => i['code'] === item[2])
                  return (<List.Item>
                    <Row>
                      <Col span={2}>{item[0]}</Col>
                      <Col span={7}>{sizeFind ? sizeFind['name'] : ""}</Col>
                      <Col span={7}>{colorFind ? colorFind['name'] : ""}</Col>
                      <Col span={8}>{item[1]}</Col>
                    </Row>
                  </List.Item>)
                }}
              />
              <div style={{ textAlign: 'center' }}>{this.getText(12550) + " : " + totalQuantity}</div>
            </div>
          </Col>
        </Row>
      </div >
    );
  }
  getGridData = (params, id) => {
    this.props.dataActions.genericDataGetEditWithParams(
      id,
      this.props.user.companyCode,
      this.props.user.token,
      params,
      this.api.get
    );
  }
}

export default MatrixGridQuantities;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
//import "../../App.css";
import LangContext from "../contextProvider/LangContext";
import { Upload, Button, Icon, Row, Col, Progress, Switch, Form } from 'antd';
//import { UploadOutlined } from '@ant-design/icons';
import { Job, User, OutputType, ProcessType, JobResponse } from '../JmReact';
//import ImageSlicer from "./ImageSlicer";
//import { SliderPicker } from "react-color";

const FormItem = Form.Item;

type formEntPoint = {
    token: String,
    companyCode: String,
    entpoint: String,
    success: String,
    error: String,
}

type Props = {
    url: string,
    imageType: string,
    boxSize: number,
    token: string,
    companyCode: string,
    onUploadComplete: Function,
    formEntPoint: formEntPoint,
    uploadScriptName: string,
    isShared: Boolean,
    onChange: Function,
    beforeUpload: FunctionComponent,
    fileList: Array<FileItem>,
    failedfileList: Array<FileItem>,
    succeededfileList: Array<FileItem>,
};

type FileItem = { uid: number, name: string, status: string, url: string };

type State = {
    previewVisible: boolean,
    previewImage: string,
    fileList: Array<FileItem>
};

export class MultiUpload extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            //fileList: [],
            isDestroyed: false,
            uploading: false,
            current: 0,
            codeType: 0, // 0=itemcode | 1=barcode
            isDirectory: false,
            fileUrl: null,
            //test: []
        };
    }

    static contextType = LangContext;
    getText = (id) => { return this.context.get(id) || '[' + id + ']' }

    // componentDidUpdate() {
    // document.title = "Cash On Tab - " // + this.getText();
    //}

    sendAPI = (script, data, sCall, eCall, outputType) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType[outputType ? outputType : 'OUTPUT_TYPE_DATA'], ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, eCall);
    }

    sendAPIPromise = (script, data, sCall, eCall, outputType) => {
        return new Promise((resolve, reject) => {
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, script, OutputType[outputType ? outputType : 'OUTPUT_TYPE_DATA'], ProcessType.PROCESS_TYPE_SYNC);
            job.setInput(data);
            job.send("/cgi-bin/CashOnTab",
                (ob: Object) => { resolve(sCall(ob)) },
                (error: any) => { reject(eCall(error)); }
            )
        })
    }

    getCodeFromFileName = (fileName) => {
        return (fileName.includes("."))
            ? fileName.split('.').slice(0, -1).join('.')
            : fileName;
    }

    processFiles = async (e) => {
        // get current
        const { /*data*/  fileList, //failedfileList, succeededfileList, 
            /*callbacks*/ beforeUpload, onChange, onComplete } = this.props;
        const { isDirectory, uploading, current, codeType } = this.state;

        // START
        this.setState({ uploading: true, current: 0 })
        // error | success | done | uploading | removed
        for (let file of fileList) {
            let curr = fileList.findIndex(i => i.uid === file.uid);
            this.setState({ current: curr + 1 })
            let name = this.getCodeFromFileName(file.name);

            await this.sendAPIPromise("check_item_by_fileName", `_code\f_codeType\r${name}\f${codeType}`,
                async (ob) => {
                    const count = parseInt(ob.data);

                    ////////////////////////////////////////////
                    await new Promise(resolve => setTimeout(resolve, 50));
                    ////////////////////////////////////////////

                    if (count === 1) {
                        // exact
                        this.handleUpload(file.originFileObj)
                    } else if (count === 0) {
                        // missing
                        //error | success | done | uploading | removed
                        onChange(file, this.props.fileList, [...this.props.failedfileList,
                        {
                            uid: file.uid, name: file.name, status: "error",
                            error: (codeType === 1)
                                ? this.getText(16658) // "ברקוד לא מוגדר במערכת"
                                : this.getText(16659) // "קוד פריט לא מוגדר במערכת"
                            /*thumbUrl: file.thumbUrl*/
                        }
                            /*file*/], this.props.succeededfileList)
                    } else {
                        // multiple
                        onChange(file, this.props.fileList, [...this.props.failedfileList,
                        {
                            uid: file.uid, name: file.name, status: "error",
                            error: (codeType === 1)
                                ? this.getText(16660) // "קוד פריט לא יחודי במערכת"
                                : this.getText(16661) // "ברקוד לא יחודי במערכת" /*thumbUrl: file.thumbUrl*/
                        } /*file*/], this.props.succeededfileList)
                    }

                    ////////////////////////////////////////////
                    await new Promise(resolve => setTimeout(resolve, 50));
                    ////////////////////////////////////////////
                },
                (e) => {
                    // error | success | done | uploading | removed
                    //console.log("GGG error: ", file, this.props.fileList, this.props.succeededfileList, this.props.failedfileList)
                    //console.log(e)
                    onChange(file, this.props.fileList, [...this.props.failedfileList,
                    { uid: file.uid, name: file.name, status: "error", error: "נכשל בבדיקת זיהוי קוד פריט או ברקוד" /*thumbUrl: file.thumbUrl*/ } /*file*/]
                        , this.props.succeededfileList)
                });

        }

        // END
        this.setState({ uploading: false }, () => {
            this.props.onComplete(null, this.props.fileList, this.props.failedfileList, this.props.succeededfileList);
        })
    }

    handleUpload = (fileBlob) => {
        this.getBase64(fileBlob/*fileList[0]*/,  // info.file.originFileObj 
            fileUrl => {
                console.log("fileUrl: ", fileUrl)
                this.setState(
                    { fileUrl, loading: false, fileBlob: fileBlob },
                    () => { this.savePicture(fileUrl); }
                )
            });
    }

    getBase64 = (file, callback) => {
        console.log("getBase64", file)
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(file);
    }

    savePicture = (fileUrl) => {
        let fileBlob = this.state.fileBlob;

        console.log("save pic: ", fileUrl, fileBlob)

        let formEntPoint = this.props.formEntPoint;
        let token = formEntPoint ? formEntPoint.token : this.props.user.token;
        let companyCode = formEntPoint ? formEntPoint.companyCode : this.props.user.companyCode;
        let entpoint = formEntPoint ? formEntPoint.entpoint : "CashOnTab";

        let user = new User(token, companyCode);
        let job = new Job(user, this.props.uploadScriptName || "upload_image",
            OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC, (entpoint === "CashOnTab") ? false : true);

        job.setSection("imageName", fileBlob.name.split('.')[0]);
        job.setSection("imageExt", fileBlob.name.split('.').slice(-1)[0]);
        job.setSection("imageType", this.props.imageType);
        job.setSection("imageMeta", fileUrl.split(',')[0]);
        job.setSection("imageData", fileUrl.split(',')[1]);

        console.log("imageMeta", fileUrl.split(',')[0]);
        console.log("imageData", fileUrl.split(',')[1]);

        job.send("/cgi-bin/" + entpoint,
            (ob) => { this.successCallback(ob, fileBlob.name, fileBlob) },
            (error: any) => { this.errorCallback(error, fileBlob) }
        );
    }

    onRemove = (file) => {
        const { fileList, failedfileList, succeededfileList } = this.props;
        const { onRemove, onComplete } = this.props;
        console.log("onRemove");
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        onRemove(file, fileList, failedfileList, succeededfileList);
    }

    beforeUpload = (file) => {
        const { fileList, beforeUpload } = this.props
        console.log("beforeUpload:", file, fileList);
        beforeUpload(file, [...fileList, file], [], [])
        return false;
    }

    successCallback = async (ob: Object, name, fileBlob) => {
        const { /*data*/  fileList, failedfileList, succeededfileList, /*callbacks*/ beforeUpload, onComplete } = this.props;
        const { isDirectory, uploading, current, codeType } = this.state;
        
        console.log("success: ", ob.data, fileBlob);

        let companyCode = this.props.formEntPoint ? this.props.formEntPoint.companyCode : this.props.user.companyCode;
        companyCode = (this.props.isShared && this.props.isShared === true) ? 'shared' : companyCode;
        let success = this.props.formEntPoint ? this.props.formEntPoint.success : this.getText(11336);
        // let origin = window.location.origin;
        // const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        // let baseUrl = isDev ? "http://147.235.163.248" : origin;
       
        await this.sendAPIPromise("set_item_image_by_fileName", `_code\f_codeType\f_fileName\r${this.getCodeFromFileName(name)}\f${codeType}\f${ob.data}`,
            (ob) => {
                const { onChange, file, fileList, succeededfileList, failedfileList } = this.props
                console.log(ob.data)
                onChange(fileBlob, fileList, failedfileList, [...succeededfileList,
                {
                    uid: fileBlob.uid, name: fileBlob.name, status: "success"/*"done"*/,
                    error: ""/*thumbUrl: file.thumbUrl*/
                } /*file*/])
            },
            (e) => {
                const { onChange, file, fileList, succeededfileList, failedfileList } = this.props
                console.log(e)
                onChange(fileBlob, fileList, succeededfileList, [...failedfileList,
                {
                    uid: fileBlob.uid, name: fileBlob.name, status: "error",
                    error: e /*thumbUrl: file.thumbUrl*/
                } /*file*/])
            });
    }

    errorCallback = (error: any, fileBlob) => {
        const { onChange, file, fileList, succeededfileList, failedfileList } = this.props
        let mError = this.props.formEntPoint ? this.props.formEntPoint.error : this.getText(11337);
        console.log("error: ", error);
        this.props.onUploadedFailed(error);
        //message.error(mError);

        onChange(fileBlob, fileList, [...failedfileList,
        {
            uid: fileBlob.uid, name: fileBlob.name, status: "error",
            error: error /*thumbUrl: file.thumbUrl*/
        } /*file*/], succeededfileList)
    }

    clearFiles = (e) => {
        this.props.onChange(null, [], [], [])
        this.setState({ current: 0, isDestroyed: true },
            () => { setTimeout(() => { this.setState({ isDestroyed: false }) }, 500); })
    }

    getWaitingFiles = () => {
        const { fileList, succeededfileList, failedfileList } = this.props;
        let succeededFilesIds = succeededfileList.map(i => { return i.uid; });
        let failedFilesIds = failedfileList.map(i => { return i.uid; });
        let waitingList = fileList
            .filter(i => !succeededFilesIds.includes(i.uid))
            .filter(i => !failedFilesIds.includes(i.uid));
        return waitingList;
    }


    render() {
        const { /*data*/  fileList, failedfileList, succeededfileList, /*callbacks*/ beforeUpload, onChange, onComplete } = this.props;
        const { isDirectory, uploading, current, codeType, isDestroyed } = this.state;
        console.log("render: ", fileList, fileList.length, current)

        const waitingList = this.getWaitingFiles();

        return (<div>
            <Row gutter={[8, 16]}>
                <Col span={8} style={{ fontSize: 18, textAlign: "center" }} >{this.getText(16662)}{/*"קבצים ממתינים"*/}</Col>
                <Col span={8} style={{ fontSize: 18, textAlign: "center" }} >{this.getText(16663)}{/*"הועלו בהצלחה"*/}</Col>
                <Col span={8} style={{ fontSize: 18, textAlign: "center" }} >{this.getText(16664)}{/*"קודי פריט אוברקודים לא מזוהים / נכשלו בהעלאה"*/}</Col>
            </Row>
            <Row gutter={[8, 16]}>
                <Col span={8} >
                    <Progress
                        percent={(fileList.length > 0)
                            ? Math.round((current / fileList.length) * 100)
                            : 0
                        }
                        status={(uploading) ? "active" : "normal"}
                    />

                    <FormItem
                        key={'upload_type'} label={this.getText(16665) /*"סוג בחירה"*/} style={{ marginBottom: 8 }} help={''}
                        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} /*validateStatus={}*/>
                        <Switch checkedChildren={this.getText(16666)}/*"בחירת תיקייה"*/ unCheckedChildren={this.getText(16667)}/*"בחירה מרובה"*/ checked={isDirectory}
                            onChange={(checked: boolean, event: Event) => {
                                this.setState({ isDirectory: checked })
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'code_type'} label={this.getText(16668) /*"שמות קבצים לפי"*/} style={{ marginBottom: 8 }} help={''}
                        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} /*validateStatus={}*/>
                        <Switch checkedChildren={this.getText(16669)} /*"ברקוד"*/ unCheckedChildren={this.getText(16670)} /*"קוד פריט"*/
                            checked={(codeType || codeType === 1) ? true : false}
                            onChange={(checked: boolean, event: Event) => {
                                //console.log("SWITCH change", checked)
                                this.setState({ codeType: (checked === true) ? 1 : 0 })
                            }
                            } />
                    </FormItem>
                    {/* <div>
                        <span style={{ paddingLeft: 20 }}>סוג בחירה:</span>
                        <Switch checkedChildren="בחירת תיקייה" unCheckedChildren="בחירה מרובה" checked={isDirectory}
                            onChange={(checked: boolean, event: Event) => {
                                this.setState({ isDirectory: checked })
                            }
                            } />
                    </div>
                    <div>
                        <span style={{ paddingLeft: 20 }}>שמות קבצים לפי:</span>
                        <Switch checkedChildren="ברקוד" unCheckedChildren="קוד פריט"
                            checked={(codeType || codeType === 1) ? true : false}
                            onChange={(checked: boolean, event: Event) => {
                                console.log("SWITCH change", checked)
                                this.setState({ codeType: (checked === true) ? 1 : 0 })
                            }
                            } />
                    </div> */}
                    <Button
                        type="primary"
                        onClick={this.processFiles}
                        disabled={fileList.length === 0}
                        loading={uploading}
                        style={{ marginTop: 24, marginLeft: 8 }}
                    >
                        {uploading ? this.getText(16671)/*'מעלה...'*/
                        : this.getText(16672) /*'התחל העלאה'*/}
                    </Button>
                    <Button
                        onClick={this.clearFiles}
                        loading={uploading}
                        style={{ marginTop: 24, marginLeft: 8 }}
                    >
                        {this.getText(16673) /*'נקה קבצים'*/}
                    </Button>
                    {
                        (isDestroyed) ? <div /> :
                            <Upload
                                onRemove={this.onRemove}
                                beforeUpload={this.beforeUpload}
                                //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                //action={(e) => { console.log("action: ", e) }}
                                listType="text" /*text | picture | picture-card*/
                                //defaultFileList={fileList}
                                //fileList={fileList}
                                showUploadList={false}
                                directory={isDirectory}
                                multiple={!isDirectory}
                                accept=".png,.jpeg,.jpg"
                                onChange={({ e, file, fileList }) => {
                                    //console.log("ccc on change: ", e, file, fileList);
                                    onChange(file, fileList, failedfileList, succeededfileList)
                                }}
                                // customRequest={
                                //     (e) => {
                                //         console.log("customRequest: ", e)
                                //     }
                                // }
                            >
                                <Button icon="upload">{this.getText(16674)}{/*בחר קבצים*/}</Button>
                            </Upload>
                    }
                    <Upload
                        action=""
                        showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
                        beforeUpload={(file) => false}
                        listType="picture" /*text | picture | picture-card*/
                        fileList={waitingList}
                    >
                    </Upload>

                </Col>
                <Col span={8} >
                    <Upload
                        action=""
                        showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
                        beforeUpload={(file) => false}
                        listType="picture" /*text | picture | picture-card*/
                        fileList={succeededfileList}
                    >
                    </Upload>
                </Col>
                <Col span={8} >
                    <Upload
                        action=""
                        showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
                        beforeUpload={(file) => false}
                        listType="picture" /*text | picture | picture-card*/
                        fileList={failedfileList}
                    >
                    </Upload>
                </Col>
            </Row>


        </div>)
    }

}

export default MultiUpload;
/* eslint-disable */
import React from "react";
import { Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message } from 'antd';
import ExtendedTable from "./ExtendedTable";
import ExtendedStaticTable from "./ExtendedStaticTable";
import ModalSalesAlert from "./ModalSalesAlert";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import SelectorSalesAlerts from "./SelectorSalesAlerts";
import SelectorTaxType from "./SelectorTaxType";
import InputNumeric from "./InputNumeric";


const { Option, OptGroup } = Select;
const { TextArea } = Input;
const codeInvalid = () => {
    message.error('קוד הפריט כבר קיים במסד הנתונים');
};
const codeValid = () => {
    message.success('קוד פריט פנוי');
};
const codeExists = () => {
    message.warning('קוד פריט כבר קיים במסד הנתונים אך בסטטוס לא פעיל');
};

/*
type Props = {
    //title: string,
    apiCalls: any[],
    api_create: any,
    text:any,
};
*/
//type State = {}
class ModalEditItem extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            text: '',

            saleAlertList: [],
            tagList: [],
            tagPicks: [], // choosen and by group selected

            formState: {
                itemID: '',
                //checkboxes
                isActive: true,
                isWeightable: false,
                isForSale: false,
                isFixedPrice: false,
                isDiscount: false,
                // inputs
                remarks: '',
                itemCode: '',
                itemBarcode: '',
                itemShortName: '',
                itemLongName: '',
                //itemTax: '',
                itemProfit: '',
                // selectors
                taxType: [],
                saleAlert: '',
                tags: [],
                // prices
                prices: null,  // { code: xxx, text: xxx, price: xxx}
                //prices2: this.props.ui.sendCompData.find(item => item.senderID === TableIDs.createItem_Prices).data,  // { code: xxx, text: xxx, price: xxx}
                // suppliers
                suppliers: null, // { code: xxx, supplier: xxx, cost: xxx, itemID: xxx}
                //suppliers2: this.props.ui.sendCompData.find(item => item.senderID === TableIDs.createItem_Prices).data, // { code: xxx, supplier: xxx, cost: xxx, itemID: xxx}
            },

            isValidItemCode: false,
        }

        this.status;
        this.api_create = this.props.apiCalls.find(item => item.apiType === 'create').apiCall;
        //this.api_getAlerts();
        this.modalID = {
            //create: "ITEMS_CREATE_MODAL",
            //search: "ITEMS_SEARCH_MODAL",
            edit: "SALES_ALERT_EDIT_MODAL",
        }

        this.apiSalesAlerts = [{
            apiType: 'get',
            apiCall: this.api_getSalesAlerts,
        }, {
            apiType: 'create',
            //apiCall: this.api_createItem,
            apiCall: () => { alert("create alert") },
        }, {
            apiType: 'delete',
            //apiCall: this.api_deleteItem,
            apiCall: () => { alert("create delete") },
        }, {
            apiType: 'edit',
            //apiCall: this.api_editItem,
            apiCall: () => { alert("create edit") },
        }];

        this.apiSuppliers = [{
            apiType: 'get',
            apiCall: () => { console.log("supplier get"); },
        }, {
            apiType: 'create',
            //apiCall: this.api_createItem,
            apiCall: () => { console.log("supplier create"); },
        }, {
            apiType: 'delete',
            //apiCall: this.api_deleteItem,
            apiCall: () => { console.log("supplier delete"); },
        }, {
            apiType: 'edit',
            //apiCall: this.api_editItem,
            apiCall: () => { console.log("supplier edit"); },
        }];

        this.actionButtons = [{
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },]

        // table
        this.formatTablePrices = [{
            title: '',
            width: '0%',
            sorter: false,
            editable: false,
            isCheckBox: false,
        }, {
            title: 'מחירון',
            width: '46%',
            sorter: true,
            editable: false,
            isCheckBox: false,
            isDropSelect: TableIDs.selectorPrices, //'SELECTOR_PRICES',
        }, {
            title: 'מחיר',
            width: '36%',
            sorter: true,
            editableNumeric: true,
            isCheckBox: false,
        }, {
            title: 'פעולות',
            width: '18%',
            sorter: false,
            editable: false,
            isCheckBox: false,
            actions: [{
                actionType: 'delete',
                actionFunction: () => { alert('deleting'); },
            }, {
                actionType: 'edit',
                actionFunction: () => { alert('editing'); },
            }],
        }];

        // table
        this.formatTableSuppliers = [{
            title: '',
            width: '0%',
            sorter: false,
            editable: false,
            isCheckBox: false,
        }, {
            title: 'ספק',
            width: '35%',
            sorter: true,
            editable: false,
            isCheckBox: false,
            isDropSelect: TableIDs.selectorSuppliers, //'SELECTOR_SUPPLIERS',
        }, {
            title: 'עלות',
            width: '25%',
            sorter: true,
            editableNumeric: true,
            isCheckBox: false,
        }, {
            title: 'פריט ספק',
            width: '25%',
            sorter: true,
            editable: true,
            isCheckBox: false,
        }, {
            title: 'פעולות',
            width: '15%',
            sorter: false,
            editable: false,
            isCheckBox: false,
            actions: [{
                actionType: 'delete',
                actionFunction: () => { alert('deleting'); },
            }, {
                actionType: 'edit',
                actionFunction: () => { alert('editing'); },
            }],
        }];

        /*this.props.uiActions.showModal(this.modalID.edit)*/
        this.api_getAlerts();
        this.api_getTags();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        //alert("on props: " + JSON.stringify(newProps));        
        let _state = { ...this.state };
        _state.visible = newProps.toggle;
        if (_state.visible == false) {
            _state.isValidItemCode = false;
        }
        else {
            console.log(newProps.ui);
            if (_state.visible != this.state.visible) {
                _state.formState.itemCode = newProps.ui.data.code;
                _state.formState.itemID = newProps.ui.data.id;
                _state.isValidItemCode = true;
                this.get_item_by_code();
            }

        }

        if (newProps.ui.sendCompData) {
            console.log("EEE", "data received");
            if (_state.formState.prices == null || _state.formState.suppliers == null) {
                console.log("EEE", "local data is null");
                if (newProps.ui.sendCompData.map(item => item.compID).includes(TableIDs.createItem)) { // is it for me?
                    console.log("EEE", "updating data");
                    _state.formState.prices = newProps.ui.sendCompData.find(item => item.senderID === TableIDs.createItem_Prices).data; // from who?
                    _state.formState.suppliers = newProps.ui.sendCompData.find(item => item.senderID === TableIDs.createItem_Suppliers).data;
                    this.createItem();
                }
            }
        }
        console.log("EEE data is:", _state.formState.prices, _state.formState.suppliers);
        console.log("EEE form:", _state.formState);

        this.setState(_state);
        //this.setState({ value: newProps.value });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    fillForm = () => {
        this.setState({ loading: true });
        console.log("FILL EDIT ITEM FORM");
        setTimeout(() => {
            this.get_item_by_code();

        }, 500);
    }

    createItem = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            console.log("API_CREATE/EDIT_ITEM\n");
            let form = this.state.formState;
            let dataSend = '';

            var user = new User(this.props.user.token, this.props.user.companyCode);
            var job = new Job(user, "", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            dataSend = "mItemID\fmItemCode\fmItemBarcode\fmItemProfit\fmItemShortName\fmItemLongName\fmIsActive\fmIsDiscount\fmIsFixedPrice\fmIsForSale\fmIsWeightable\r" +
                // inputs
                form.itemBarcode +
                form.itemCode +
                form.itemProfit +
                form.itemShortName +
                form.itemLongName;
            // check boxes
            form.isActive +
                form.isDiscount +
                form.isFixedPrice +
                form.isForSale +
                form.isWeightable;
            job.setSection(dataSend);
            // selectors
            dataSend = "mTaxTypeID\f" + this.state.tagPicks;
            job.setSection(dataSend);
            dataSend = "mSaleAlertID\f" + form.saleAlert;
            console.log("FFFFFFFF", form.saleAlert);
            job.setSection(dataSend);
            // tables
            dataSend = "mSuppID\fmPrice\fmSuppCode\r";
            for (var row of form.suppliers) {
                dataSend += row['col_2'] + '\f'
                    + row['col_3'] + '\f'
                    + row['col_4'] + '\r';
            }
            job.setSection(dataSend);

            dataSend = "mPriceID\fmPriceVal\r";
            for (var row of form.prices) {
                dataSend += row['col_2'] + '\f'
                    + row['col_3'] + '\r';
            }
            job.setSection(dataSend);

            job.send("/cgi-bin/CashOnTab", this.successCallbackGetTags, (e) => { (e) => (e) }); // callback problem here
            console.log("API CREATE ITEM");
            console.log("form state:\n", this.state.formState);

            this.setState({ loading: false, visible: false });
        }, 500);
    }

    onDataReceived = () => {
        this.state.formState.edit;
    }

    handleOkLeave = () => {
        this.props.uiActions.getCompsData(
            TableIDs.createItem, [TableIDs.createItem_Suppliers, TableIDs.createItem_Prices]);
        //setTimeout(() => {
        //}, 2000);
        //this.createItem();
        //this.clear();
        this.clear();
        this.props.uiActions.hideModal(this.props.id);
    }
    handleOk = () => {
        this.props.uiActions.getCompsData(
            TableIDs.createItem, [TableIDs.createItem_Suppliers, TableIDs.createItem_Prices]);
        //setTimeout(() => {
        //}, 2000);
        //this.createItem();
        this.clear();
    }
    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.clear();
        this.props.uiActions.hideModal(this.props.id);
    }
    handleClear = () => {
        this.clear();
    }

    clear = () => {
        let s = { ...this.state };
        //s.isValidItemCode = false;
        // check box
        s.formState.isActive = true;
        s.formState.isDiscount = false;
        s.formState.isFixedPrice = false;
        s.formState.isForSale = false;
        s.formState.isWeightable = false;
        // input
        s.formState.itemBarcode = '';
        //s.formState.itemCode = '';
        //s.formState.itemID = '';
        s.formState.itemLongName = '';
        s.formState.itemProfit = '';
        s.formState.itemShortName = '';
        s.formState.remarks = '';
        // selectors
        s.formState.taxType = null;
        s.formState.saleAlert = null;
        //s.formState.itemTax = {};
        s.tagPicks = [],
            // TODO:  clear tables...   
            console.log("table state: ", s.formState.prices);
        console.log("table state: ", s.formState.suppliers);
        s.formState.prices = null;
        s.formState.suppliers = null;

        this.setState(s);
    }

    render() {
        const { visible, loading } = this.state;
        console.log("## render -  modal create ##")

        let modalStyle = {
            direction: 'rtl',
        };

        let titles = {
            main: "התראות מכירה",
            //add: "הוספת פריט",
            //search: "רשימת פריטים - סינון"
        };
        /*
        let alertList = this.state.saleAlertList.map((obj, index) => {
            var text = obj['name'];
            return (<Option key={"alert_" + obj} value={text}>{text}</Option>)
        });*/

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    actionButtons={this.actionButtons}
                    title={this.props.title}
                    isModal={true}
                    //searchCallback={this.advanceSearchCallback} // pull search field c->p
                    //apiCallData={this.state.status} // push data p->c
                    showsearch={false}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />
            </div>)

        let tagList = this.state.tagList.map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let styleRow = { direction: 'ltr' };
        let styleCol = { direction: 'rtl' };
        let stylePadding = { padding: '4px' };

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'90%'}
                visible={visible}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}
                ui={this.props.ui}
                uiActions={this.props.uiActions} >

                <Row style={styleRow} type="flex" gutter={8}>
                    <Col span={4} order={1}>
                        <PictureView />
                    </Col>
                    <Col style={styleCol} span={8} order={2}>
                        <div style={stylePadding}>
                            <SelectorTaxType
                                styleSelector={{ width: 200, paddingLeft: '4px' }}
                                user={this.props.user}
                                onSelect={this.handleOnSelectTaxType}
                                value={this.state.formState.taxType}
                                ui={this.props.ui}
                                uiActions={this.props.uiActions}
                                data={this.props.data}
                                dataAction={this.props.dataAction}
                                disabled={!this.state.isValidItemCode}
                            /></div>
                        <div style={stylePadding}>

                            <SelectorSalesAlerts
                                styleSelector={{ width: 200, paddingLeft: '4px' }}
                                user={this.props.user}
                                onSelect={this.handleOnSelect}
                                value={this.state.formState.saleAlert}
                                ui={this.props.ui}
                                uiActions={this.props.uiActions}
                                data={this.props.data}
                                dataAction={this.props.dataAction}
                                disabled={!this.state.isValidItemCode}
                            /></div>
                        <div style={stylePadding}>

                            <label>תגיות</label>
                            <Select
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                placeholder="בחירת תגיות"
                                onChange={this.handleTagSelectChange}
                                style={{ width: '100%' }}
                                value={this.state.tagPicks}
                                disabled={!this.state.isValidItemCode}
                            >
                                {tagList}
                            </Select>
                        </div>
                    </Col>
                    <Col style={styleCol} span={4} order={3}>
                        <label><Checkbox onChange={this.handleCheckBoxChangeIsActive} checked={this.state.formState.isActive}
                            style={stylePadding} disabled={!this.state.isValidItemCode} /> פעיל </label><br />
                        <label><Checkbox onChange={this.handleCheckBoxChangeisWeightable} checked={this.state.formState.isWeightable}
                            style={stylePadding} disabled={!this.state.isValidItemCode} /> שקיל </label><br />
                        <label><Checkbox onChange={this.handleCheckBoxChangeisFixedPrice} checked={this.state.formState.isFixedPrice}
                            style={stylePadding} disabled={!this.state.isValidItemCode} /> למכירה  </label><br />
                        <label><Checkbox onChange={this.handleCheckBoxChangeisForSale} checked={this.state.formState.isForSale}
                            style={stylePadding} disabled={!this.state.isValidItemCode} /> מחיר קבוע </label><br />
                        <label><Checkbox onChange={this.handleCheckBoxChangeisDiscount} checked={this.state.formState.isDiscount}
                            style={stylePadding} disabled={!this.state.isValidItemCode} /> חסום להנחה  </label><br />
                    </Col>
                    <Col style={styleCol} span={8} order={4}>
                        <div style={{ padding: '4px 4px 4px 4px' }}>
                            <span style={{ display: 'inline-block', width: '15%', textAlign: 'left' }}>{'קוד פריט'}</span>
                            <Input
                                disabled={this.state.isValidItemCode}
                                type="text"
                                //placeholder="טקסט"
                                value={this.state.formState.itemCode}
                                style={{ width: '65%', marginRight: '3%' }}
                                onChange={this.handleInputChangeItemCode}
                            //onBlur={this.get_item_by_code}
                            //onPressEnter={this.get_item_by_code}
                            />
                        </div>
                        {this.generateInputField('ברקוד', this.handleInputChangeItemBarcode, this.state.formState.itemBarcode)}
                        {this.generateInputField('שם קצר', this.handleInputChangeShortName, this.state.formState.itemShortName)}
                        {this.generateInputField('שם ארוך', this.handleInputChangeLongName, this.state.formState.itemLongName)}
                        {this.generateNumericInputField('רווח', this.handleInputChangeProfit, this.state.formState.itemProfit)}
                    </Col>
                </Row>
                <Row style={styleRow} type="flex" gutter={8}>
                    <Col style={styleCol} span={8} order={1}>
                        <ExtendedStaticTable
                            //ref={instance => { this.child = instance; }} //#
                            id={TableIDs.createItem_Prices}
                            title={'מחירונים'}
                            user={this.props.user}
                            tableFormat={this.formatTablePrices}
                            onChange={this.handleChangePrices}
                            dataSource={this.state.formState.prices} //###
                            apiCalls={this.props.apiCalls}
                            showsearch={false}
                            showCreate={true}
                            isStaticData={false}
                            ui={this.props.ui}
                            uiActions={this.props.uiActions}
                            disabled={!this.state.isValidItemCode}
                        />
                    </Col>
                    <Col style={styleCol} span={10} order={2}>
                        <ExtendedStaticTable
                            id={TableIDs.createItem_Suppliers}
                            title={'מחירי עלות ספקים'}
                            user={this.props.user}
                            tableFormat={this.formatTableSuppliers}
                            onChange={this.handleChangeSuppliers}
                            dataSource={this.state.formState.suppliers} //###
                            apiCalls={this.apiSuppliers}
                            showsearch={false}
                            showCreate={true}
                            isStaticData={false}
                            ui={this.props.ui}
                            uiActions={this.props.uiActions}
                            disabled={!this.state.isValidItemCode}
                        />
                    </Col>
                    <Col style={styleCol} span={6} order={3}>
                        <label>הערה
                            <TextArea
                                value={this.state.formState.remarks}
                                onChange={this.handleInputChangeRemarks}
                                disabled={!this.state.isValidItemCode}
                                autosize={{ minRows: 2, maxRows: 5 }}
                            />
                        </label>
                    </Col>
                </Row>

                <ModalSalesAlert
                    id={this.modalID.edit}
                    user={this.props.user}
                    tableFormat={this.alertFormat}
                    apiCalls={this.props.apiCalls} /// TODO: replace api with local instead of props
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    toggle={this.props.ui.modalStack.includes(this.modalID.edit)}
                    title={titles.main}
                //isStaticData={true}
                />

            </Modal >
        )
    }

    handleChangePrices = () => {

    }

    handleChangeSuppliers = () => {

    }

    get_item_by_code = () => {
        console.log("KKKK 0");
        if (this.state.formState.itemCode !== "") {
            this.api_get_item_by_code();
        }
    }

    api_get_item_by_code = () => {
        console.log("API_GET_ITEM_BY_CODE\n");
        console.log(this.state.formState);
        var dataSend = "mItemCode\r" + this.state.formState.itemCode;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_item", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallbackGetItemByCode, (e) => { (e) => codeInvalid(e); }); // callback problem here
    }

    successCallbackGetItemByCode = (ob) => {
        console.log("get item by code success:" + ob.data);

        let toBool = (val) => {
            return (val === '1') ? true : false;
        }

        if (ob.data.length != 0) {
            // TODO check if active
            let s = { ...this.state };
            let fs = s.formState;
            let rowsRaw = ob.data;
            let rows = rowsRaw.split("\r");
            //let row = rows[1].split("\f");
            let row = rows[0].split("\f");
            console.log("WWWWWWWW", row);

            fs.itemID = row[0];
            fs.itemCode = row[1];
            fs.itemBarcode = row[2];
            fs.itemLongName = row[3];
            fs.itemShortName = row[4];
            // 5
            fs.remarks = row[6];
            fs.isActive = toBool(row[7]);
            fs.isWeightable = toBool(row[8]);
            fs.isForSale = toBool(row[9]);
            fs.isFixedPrice = toBool(row[10]);
            fs.itemProfit = row[11];
            fs.saleAlert = row[12];
            fs.isDiscount = toBool(row[13]);
            fs.taxType = row[14];
            this.status = { ...s, formState: fs };
            this.api_get_tags_by_code();
        }
    }

    api_get_tags_by_code = () => {
        console.log("API_GET_TAGS_BY_CODE\n");
        var dataSend = "mItemCode\r" + this.state.formState.itemCode;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_tags_by_item", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallbackGetTagsByCode, (e) => { (e) => codeInvalid(e); }); // callback problem here       
    }

    successCallbackGetTagsByCode = (ob) => {
        console.log("get tags by code success:" + ob.data);
        let rowsRaw = ob.data;

        if (ob.data.length != 0) {
            let rows = rowsRaw.split("\r");
            console.log("KKKK rows", rows);

            for (var row of rows) {
                let rowArr = row.split("\f");
                console.log("KKKK", rowArr);
                this.status.tagPicks.push(rowArr[1]);
            }
            //this.status = { ...s, formState: fs };
        }
        this.api_get_suppliers_by_code();
    }

    api_get_suppliers_by_code = () => {
        console.log("API_GET_SUPPLIERS_BY_CODE\n");
        var dataSend = "mItemCode\r" + this.state.formState.itemCode;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_suppliers_by_item", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallbackGetSuppliersByCode, (e) => { (e) => codeInvalid(e); }); // callback problem here      
    }

    successCallbackGetSuppliersByCode = (ob) => {
        console.log("get suppliers by code success:" + ob.data);
        let rowsRaw = ob.data;
        let rows = rowsRaw.split("\r");

        console.log("KKKK2", rows);


        this.api_get_prices_by_code();
    }

    api_get_prices_by_code = () => {
        console.log("API_GET_PRICES_BY_CODE\n");
        var dataSend = "mItemCode\r" + this.state.formState.itemCode;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_prices_by_item", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallbackGetPricesByCode, (e) => { (e) => codeInvalid(e); }); // callback problem here   
    }

    successCallbackGetPricesByCode = (ob) => {
        console.log("get prices by code success:" + ob.data);
        let rowsRaw = ob.data;
        let rows = rowsRaw.split("\r");
    }

    handleInputChangeItemCode = (e) => { let s = this.state; s.formState.itemCode = e.target.value; this.setState(s); };
    handleInputChangeItemBarcode = (e) => { let s = this.state; s.formState.itemBarcode = e.target.value; this.setState(s); };
    handleInputChangeShortName = (e) => { let s = this.state; s.formState.itemShortName = e.target.value; this.setState(s); };
    handleInputChangeLongName = (e) => { let s = this.state; s.formState.itemLongName = e.target.value; this.setState(s); };
    handleInputChangeProfit = (e) => { let s = this.state; s.formState.itemProfit = e.target.value; this.setState(s); };
    handleInputChangeRemarks = (e) => { let s = this.state; s.formState.remarks = e.target.value; this.setState(s); };


    generateInputField = (text, handle, val) => {
        return (
            <div style={{ padding: '4px' }}>
                <span style={{ display: 'inline-block', width: '15%', textAlign: 'left' }}>{text}</span>
                <Input
                    disabled={!this.state.isValidItemCode}
                    type="text"
                    //placeholder="טקסט"
                    value={val}
                    style={{ width: '65%', marginRight: '3%' }}
                    onChange={handle} />
            </div>
        )
    }

    generateNumericInputField = (text, handle, val) => {
        return (
            <div style={{ padding: '4px' }}>
                <span style={{ display: 'inline-block', width: '15%', textAlign: 'left' }}>{text}</span>
                <InputNumeric
                    disabled={!this.state.isValidItemCode}
                    type="text"
                    //placeholder="טקסט"
                    value={val}
                    style={{ width: '65%', marginRight: '3%' }}
                    onChange={handle} />
            </div>
        )
    }

    handleOnSelect = (value) => {
        console.log("GGG onSelect alert", value);
        let s = { ...this.state };
        s.formState.saleAlert = value;
        this.setState(s);
        //console.log(`selected ${value}`);
    }

    handleOnSelectTaxType = (value) => {
        console.log("GGG onSelect taxtype", value);
        let s = { ...this.state };
        s.formState.taxType = value;
        this.setState(s);
        //console.log(`selected ${value}`);
    }

    handleCheckBoxChangeIsActive = (e) => {
        console.log("isActive handler: ", e, e.target.checked);
        let s = { ...this.state }; s.formState.isActive = e.target.checked; this.setState(s);
        console.log("2:", s);
    };
    handleCheckBoxChangeisWeightable = (e) => {
        let s = { ...this.state }; s.formState.isWeightable = e.target.checked; this.setState(s);
    };
    handleCheckBoxChangeisForSale = (e) => {
        let s = { ...this.state }; s.formState.isForSale = e.target.checked; this.setState(s);
    };
    handleCheckBoxChangeisFixedPrice = (e) => {
        let s = { ...this.state }; s.formState.isFixedPrice = e.target.checked; this.setState(s);
    };
    handleCheckBoxChangeisDiscount = (e) => {
        let s = { ...this.state }; s.formState.isDiscount = e.target.checked; this.setState(s);
    };

    handleTagSelectChange = (tag_list) => {
        console.log("select tag\n" + tag_list);
        let s = this.state;
        s.tagPicks = tag_list;
        this.setState(s);
    }

    showSalesAlertModal = () => {
        this.props.uiActions.showModal(/*this.tableID....*/ this.modalID.edit);
    }

    handleSelectChange = (value) => {
        this.setState({ saleAlert: value });
        //this.saleAlert = value;
        console.log(`selected ${value}`);
    }

    api_getAlerts = () => {
        console.log("API_GET_ALERTS\n");
        var dataSend = "";
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_sale_alert_list", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallbackGetAlerts, (e) => { (e) => (e) }); // callback problem here
    }

    successCallbackGetAlerts = (ob) => {
        console.log("get alerts success:" + ob.data);
        let s = this.state;
        let rowsRaw = ob.data;
        let rows = rowsRaw.split("\r");

        for (var rowRaw of rows) {
            let row = rowRaw.split("\f");
            s.saleAlertList.push({
                code: row[0],
                name: row[1],
            });
        }
        this.setState(s);
    }


    api_getSalesAlertsWithPaging = (input, successCallback, errorCallback) => {
        //const dataSource = [...this.state.dataSource];
        //this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
        console.log("API_GET_ITEMS" + JSON.stringify(input));
        var dataSend = "_filter\f_page_num\f_rows_num\f_sort_by\f_isAsc\f_tagList\f_isAll\f_isActive\f_isWeightable\f_isFixedPrice\f_isForSale\f_isDiscount\f_saleAlert\r"
            + [
                // paging
                input.filters.searchTerm,
                input.current,//input.filters.current,
                input.pageSize,//input.filters.pageSize,
                input.sortField,//input.filters.sortField,
                input.sortOrder,//input.filters.sortOrder,

                // advance search
                input.filters.tagList,
                input.filters.isAll,
                //
                input.filters.isActive,
                input.filters.isWeightable,
                input.filters.isFixedPrice,
                input.filters.isForSale,
                input.filters.isDiscount,
                //
                input.filters.saleAlert,
            ].join("\f");

        console.log('datasend: ' + dataSend);
        //console.log(this.props.user.token + "  "  + this.props.user.companyCode);

        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_items_list", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", successCallback, errorCallback); // callback problem here
    }
    getSalesAlertsWithPagingSuccessCallback = (ob) => {
        console.log("get success:" + ob.data);
        let data = ob.data;
        let rowsData = data.split("\r");
        let pageing = { ...this.state.pagination };
        //ageing.current = this._page_num;
        if (rowsData.length < 1) {
            pageing.total = 1;
        } else {
            pageing.total = rowsData[rowsData.length - 1];
        }
        let dataSource = [];
        for (var i = 0; i < rowsData.length - 1; i++) {
            let cols = rowsData[i].split("\f");
            let node = { key: i, };
            for (var j = 0; j < cols.length; j++) {
                node['col_' + (j + 1)] = cols[j];
            }
            dataSource.push(node);

            dataSource.push({
                key: i,
                col_1: cols[0],
                col_2: cols[1],
            })
        }


        this.setState({
            pagination: pageing,
            // current
            // pagesize
            // showQuickJumper
            // showTotal
            //showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} pages`,
            //current: _page_num,
            //defaultCurrent: 1,
            //defaultPageSize: 5,
            //total: 0,
            dataSource: dataSource,
            count: dataSource.length,
        });
    }
    getSalesAlertWithPagingErrorCallback = (error) => {
        console.log(error ? error : "Unspecified error get");
    }

    api_getTags = () => {
        console.log("API_GET_TAGS\n");
        var dataSend = "";
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_tags_with_types", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallbackGetTags, (e) => { (e) => (e) }); // callback problem here
    }

    successCallbackGetTags = (ob) => {
        console.log("get success:" + ob.data);
        let s = this.state;
        let rowsRaw = ob.data;
        let rows = rowsRaw.split("\r");
        let lastTagType = "";
        for (var rowRaw of rows) {
            let row = rowRaw.split("\f");

            if (row[0] !== lastTagType) {
                s.tagList.push({
                    type: {
                        id: row[0],
                        text: row[3],
                    },
                    tags: [],
                });
            }

            s.tagList[s.tagList.length - 1].tags.push({
                id: row[1],
                text: row[2],
            })
            lastTagType = row[0];
        }
        this.setState(s);
    }
}

export default ModalEditItem;

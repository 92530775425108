/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Button, Input, InputNumber, Switch, message, Progress, Alert, Row, Col, Tooltip, Popover, Icon, Checkbox, Form, Select, Popconfirm, Card, Radio } from "antd";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import LangContext from "../../contextProvider/LangContext";
import TableLineIcons from "../../components/TableLineIcons";
import GridKeyValues from "../../components/GridKeyValues";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ResponsiveTable from "../../components/ResponsiveTable";
import TableIDs from "../../data/TableIDs";
import ModalNumeratorsPointsOfSale from "../../components/ModalNumeratorsPointsOfSale";
import DateTimePicker from "../../components/DateTimePicker";
import JsonTreeView from "../../components/JsonTreeView";

const { Option } = Select;

class Companies extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.defaultFilters = {
            _isActive: 0,
            _organizationID: 0,
            _cityID: 0,
            _dealerID: 0,
        }

        this.state = {
            modalFilter: false,
            modalFilterFields: this.defaultFilters,
            modalCreateEdit: null,
            modalModules: null,
            modalLabels: null,
            modalQueries: null,
            modalModulesSearch: '',
            modalLabelsSearch: '',
            modalFlags: null,
            modalPosList: null,
            modalRedirect: null,
            modalRedirectHistory: null,
            modalBiling: null,
            flags: {
                clearDocumentsTables: false,    // Documents | DocumentLines | DocumentPayments | RawDocuments | DocumentTaxes
                clearFixLab: false,             // FixLabDocuments | FixLabQuestionAnswers | FixLabDocumentsLogs
                clearCreditCardRecords: false,  // Transactions | ShvaReferences
                clearOrders: false,             // ExternalOrders...
                resetNumerators: false,
                disconnectPos: false,

            },
            loading: null,
            loadingPrecent: 0,
            errorStatment: false,
            addApplicationButton: false,
            addApplicationName: '',
            addApplicationMailName: '',
            addApplicationMailTitle: '',
            editApplicationButton: false,
            editApplicationName: '',
            editApplicationMailName: '',
            editApplicationMailTitle: '',
            loadingMax: 99,
            bilingSumToAdd: 0,
            bilingTypeToAdd: null,

            companyPosListSearch: "",

            modalRedirectHistoryFilter: false,
            modalRedirectHistoryFilterUser: null,
            modalRedirectHistoryFilterFromDate: null,
            modalRedirectHistoryFilterToDate: null,
            modalRedirectHistoryFilterUserIP: null,
            modalRedirectHistoryFilterUserToken: null,
            modalRedirectHistoryFilterErrorJson: null,

            userList: [],
            userIPList: [],
            userListIsAdmin: -1,

            modalSearchIPAll: false,
            modalSearchIPAllStep: -1,
            modalSearchIPAllAddress: [null, null, null, null],

        };

        this.id = "POS_ADMIN_COMPANIES";
        this.modules_id = "POS_ADMIN_ELECTIVE_MODULES";
        this.labels_id = "POS_ADMIN_ELECTIVE_LABELS";
        this.details_id = "COMPANY_DETAILES_TABLE";
        this.queries_id = "POS_ADMIN_QUERIES";
        this.pos_id = "COMPANY_POS_LIST";
        this.log_connect_id = "ADMIN_LOGINS_LOG";
        this.log_all_id = "ADMIN_LOGINS_LOG_ALL";
        this.biling_id = "ADMIN_BILING";
        this.biling_history_id = "ADMIN_BILING_HISTORY";


        this.selectors = [
            { id: "ORGANIZATIONS_LIST", api: "organizations_list" },
            { id: "CITIES_LIST", api: "cities_list" },
            { id: "DEALERS_LIST", api: "dealers_list" },
            { id: "APPLICATIONS_LIST", api: "applications_list" },
            { id: "TEMPLETE_SCHEMAS", api: "templete_schemas" },
        ]

        this.flagsDescription = {
            clearDocumentsTables: 17217,
            clearFixLab: 17218,
            clearCreditCardRecords: 17219,
            clearOrders: 17220,
            resetNumerators: 17221,
            disconnectPos: 17237,
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.modules_id] === undefined) props.data[this.modules_id] = { ...props.data.genericData };
        if (props.data[this.labels_id] === undefined) props.data[this.labels_id] = { ...props.data.genericData };
        if (props.data[this.details_id] === undefined) props.data[this.details_id] = { ...props.data.genericData };
        if (props.data[this.queries_id] === undefined) props.data[this.queries_id] = { ...props.data.genericSelector };
        if (props.data[this.pos_id] === undefined) props.data[this.pos_id] = { ...props.data.genericData };
        if (props.data[this.log_connect_id] === undefined) props.data[this.log_connect_id] = { ...props.data.genericData };
        if (props.data[this.log_all_id] === undefined) props.data[this.log_all_id] = { ...props.data.genericData };
        if (props.data[this.biling_id] === undefined) props.data[this.biling_id] = { ...props.data.genericData };
        if (props.data[this.biling_history_id] === undefined) props.data[this.biling_history_id] = { ...props.data.genericData };

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector }; })

        this.api = {
            get: "get_pos_admin_companies",
            create: "create_pos_admin_company",
            create_schema: "copy_schema_from_template",
            check_schema: "check_schema_by_meta",
            create_franchise: "create_franchise_new_comp",
            edit: "edit_pos_admin_company",
            add_app: "add_application",
            edit_app: "edit_application",
            get_app: "get_application_data",

            get_modules: "get_pos_admin_elective_modules",
            set_modules: "set_pos_admin_elective_modules",

            get_labels: "get_pos_admin_elective_labels",
            set_labels: "set_pos_admin_elective_labels",

            get_details: "get_company_details",
            edit_details: "edit_company_details",

            get_queries: "get_pos_admin_queries",
            run_queries: "run_pos_admin_queries",

            get_pos: "get_pos_list_by_company",
            delete_pos: "delete_pos_by_company",

            redirect: "create_redirect_admin_link",

            get_log_connect: "get_admin_logins_log",

            get_biling: "get_message_summeraized_admin",
            set_biling: "add_biling_admin",

            get_biling_history: "get_biling_history_admin",


            // api by flags
            post_create_schema_actions: "post_create_schema_actions",

        }

        this.fields = [
            { text: 17224, field: 'cType', isRequired: false, type: 'select6' },
            { text: 15232, field: 'cID', isRequired: false, type: 'text' },
            { text: 15233, field: 'cLoginCode', isRequired: false, type: 'text' },
            { text: 15234, field: 'cServerAddress', isRequired: false, type: 'text' },
            { text: 15372, field: 'cTemplateOfCopy', isRequired: true, type: 'select4' },
            { text: 15235, field: 'cName', isRequired: true, type: 'input' },
            { text: 17028, field: 'cdVatID', isRequired: true, type: 'input' },
            { text: 15236, field: 'cOrganizationID', isRequired: true, type: 'select0' },
            { text: 15237, field: 'cCityID', isRequired: true, type: 'select1' },
            { text: 15238, field: 'cCRMCode', isRequired: true, type: 'input' },
            { text: 15239, field: 'cAccountingCode', isRequired: true, type: 'inputNumber' },

            { text: 15240, field: 'cDealerID', isRequired: true, type: 'select2' },
            { text: 17022, field: 'cdAddress1', isRequired: false, type: 'input' },
            { text: 17023, field: 'cdAddress2', isRequired: false, type: 'input' },
            { text: 17024, field: 'cdAddress3', isRequired: false, type: 'input' },
            { text: 17025, field: 'cdPhone', isRequired: false, type: 'input' },
            { text: 17026, field: 'cdFax', isRequired: false, type: 'input' },
            { text: 15241, field: 'cEmail', isRequired: true, type: 'input' },
            { text: 19614, field: 'cIsActive', isRequired: false, type: 'switch' },

            { text: 17027, field: 'cdWebSite', isRequired: false, type: 'input' },
            { text: 15242, field: 'cRemarks', isRequired: false, type: 'textarea' },
            { text: 15243, field: 'cIsTemplate', isRequired: false, type: 'switch' },
            { text: 15244, field: 'cApplicationId', isRequired: true, type: 'select3' },
            { text: 15246, field: 'cBackgroundURL', isRequired: false, type: 'image' },
            { text: 15247, field: 'cCashOnTabApiServer', isRequired: true, type: 'input', readOnly: true },
            { text: 15248, field: 'cAllowedSessions', isRequired: true, type: 'inputNumber' },
            { text: 17197, field: 'cCustomerDebtFetchType', isRequired: true, type: 'select5' },

        ];

        this.redirectList = [
            { icon: 'shopping-cart', text: 17581, url: '/items/itemList' },
            { icon: 'team', text: 17582, url: '/customers/customerList' },
            { icon: 'user', text: 17575, url: '/admin/users' },
            { icon: 'build', text: 17576, url: '/admin/franchisees' },
        ];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.forEach(x => {
            this.props.dataActions.genericSelectorRefreshDataset(
                x.id, this.props.user.companyCode, this.props.user.token, x.api);
        });

        this.sendAPI(this.api.get_queries, "", ob => {
            let dataset = ob.data ? ob.data.split('\r').map(x => {
                let y = x.split('\f');
                return { code: y[0], name: y[1], prmCount: parseInt(y[2]) }
            }) : [];
            this.props.dataActions.setJson(this.queries_id, { dataset })
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15219);
    }

    loadingPrecentUpdate = () => {
        // let step = this.state.loading;
        // if (step && !this.state.errorStatment) {

        //let max = 99;//60;
        let max = this.state.loadingMax;

        //     let min = 0;
        //     // switch (step) {
        //     //     case 2: max = 90; min = 60; break;
        //     //     case 3: max = 95; min = 90; break;
        //     //     case 4: max = 100; min = 95; break;
        //     //     case 5: max = 100; min = 100; break;
        //     // }
        if (this.state.loadingPrecent < 99 && !this.state.errorStatment) {
            if (this.state.loadingPrecent < max) {
                let res = this.state.loadingPrecent + 1;
                this.setState({ loadingPrecent: res });
            }
            setTimeout(this.loadingPrecentUpdate, 1500);
        }
    }

    createNew = () => {
        let newOb = { key: 0 }
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'

        let apiUrl = isDev ? 'http://147.235.163.248' : origin;
        this.props.data[this.id].headers.forEach(x => {
            switch (x) {
                case 'cCashOnTabApiServer': newOb = { ...newOb, [x]: apiUrl }; break;
                case 'cCustomerDebtFetchType': newOb = { ...newOb, [x]: '0' }; break;
                default: newOb = { ...newOb, [x]: '' }; break;
            }
        });
        newOb = {
            ...newOb,
            cdVatID: '',
            cdAddress1: '',
            cdAddress2: '',
            cdAddress3: '',
            cdPhone: '',
            cdFax: '',
            cdWebSite: '',
            cIsActive: '1'
        }

        this.setState({ modalCreateEdit: newOb });
    }

    getEditIcons = (text, record) => {
        let genericIcons = [
            {
                type: 'edit',
                disabled: record.cIsTemplate == 1,
                onClick: () => {
                    let modalCreateEdit = { ...record }
                    this.sendAPI(this.api.get_details, "cID\r" + record.key, (ob) => {
                        let res = ob.data ? ob.data.split("\r") : [];
                        let h = res[0] ? res[0].split("\f") : [];
                        let d = res[1] ? res[1].split("\f") : [];
                        h.forEach((x, i) => { modalCreateEdit = { ...modalCreateEdit, [x]: d[i] } })
                        this.setState({ modalCreateEdit });
                    })
                }
            }, {
                type: 'tags',
                tooltip: this.getText(16883),
                onClick: () => {
                    this.setState({ modalModules: record }, () => {
                        this.props.dataActions.genericDataSetFilter(
                            this.modules_id,
                            this.props.user.companyCode,
                            this.props.user.token,
                            { _compID: record.key },
                            this.api.get_modules);
                    })
                },
                limitUiComponent: '519',
            }, {
                type: 'barcode',
                tooltip: this.getText(18401),
                onClick: () => {
                    this.setState({ modalLabels: record }, () => {
                        this.props.dataActions.genericDataSetFilter(
                            this.labels_id,
                            this.props.user.companyCode,
                            this.props.user.token,
                            { _compID: record.key },
                            this.api.get_labels);
                    })
                }
            }, {
                type: 'code-sandbox',
                tooltip: this.getText(17144),
                onClick: () => {
                    this.setState({
                        modalQueries: {
                            companyId: record.cID ? parseInt(record.cID) : 0,
                            companyName: record.cName,
                            queryId: null,
                            prmCount: 0
                        }
                    })
                }
            }, {
                type: 'table',
                tooltip: this.getText(17565),
                onClick: () => {
                    let compId = record.cID ? parseInt(record.cID) : 0;
                    this.setState({ modalPosList: { compId, compName: record.cName, compCode: record.cLoginCode } }, () => {
                        this.getPosListByCompany(compId)
                    })
                }
            }, {
                type: 'api',
                tooltip: this.getText(17573),
                onClick: () => { this.setState({ modalRedirect: { name: record.cName, code: record.cLoginCode } }) }
            }, {
                type: 'history',
                tooltip: this.getText(17586),
                onClick: () => { this.setState({ modalRedirectHistory: { ...record, admins: 0 } }) }
            }, {
                type: 'history',
                tooltip: this.getText(18855),
                onClick: () => { this.setState({ modalRedirectHistory: { ...record, admins: 1 } }) }
            }, {
                type: 'dollar',
                tooltip: this.getText(17637),
                onClick: () => { this.setState({ modalBiling: record }) },
                limitUiComponent: '507',
            }
        ]

        return (<TableLineIcons
            {...this.props}
            genericIcons={genericIcons}
        />)
    }

    getPosListByCompany = (compId) => {
        if (compId) {
            this.props.dataActions.genericDataSetFilter(this.pos_id, this.props.user.companyCode, this.props.user.token, { compId }, this.api.get_pos);
        }
    }

    saveModules = () => {
        const { cID } = this.state.modalModules;
        let _moduleList = this.props.data[this.modules_id].data.map(x => {
            let y = x.split('\f');
            return { id: y[0], isChecked: y[2] }
        }).filter(f => f.isChecked == '1').map(z => z.id).join(',');

        this.sendAPI(this.api.set_modules, '_moduleList\f_cId\r' + _moduleList + "\f" + cID, () => { this.setState({ modalModules: null }) })
    }

    saveLabels = () => {
        const { cID } = this.state.modalLabels;
        let _labelList = this.props.data[this.labels_id].data.map(x => {
            let y = x.split('\f');
            return { id: y[0], isChecked: y[2] }
        }).filter(f => f.isChecked == '1').map(z => z.id).join(',');

        this.sendAPI(this.api.set_labels, '_labelList\f_cId\r' + _labelList + "\f" + cID, () => { this.setState({ modalLabels: null }) })
    }

    getSelectorDisabled = (index, value) => {
        let props = {
            value,
            disabled: true,
            id: this.selectors[index].id,
            ...this.props,
        };
        return (<GenericSelector {...props} />)
    }

    modalCEContent = () => {
        let m = this.state.modalCreateEdit ? this.state.modalCreateEdit : {}
        // let dData = this.props.data[this.details_id].editedRecord;
        // m = { ...dData, ...m }
        let isNew = m.key === 0;

        let gt = this.getText;
        let switchProps = { checkedChildren: gt(15249), unCheckedChildren: gt(15250) }

        let res = this.fields.map((y) => {
            let value = m[y.field];
            let onChange = (v) => { this.setState({ modalCreateEdit: { ...m, [y.field]: v }, editApplicationButton: false }) }
            let label = y.isRequired ? (<span><span style={{ color: 'red' }}> * </span>{gt(y.text)}</span>) : gt(y.text);
            let gSelector = (index) => (<GenericSelector id={this.selectors[index].id} value={value} onChange={onChange}  {...this.props} />)

            switch (y.type) {
                case 'text': return isNew ? false : { key: label, value };
                case 'input': return { key: label, value: (<Input value={value} onChange={(e) => { onChange(e.target.value) }} readOnly={y.readOnly} />) };
                case 'inputNumber': return { key: label, value: (<InputNumber value={value} onChange={onChange} style={{ width: '100%' }} />) };
                case 'textarea': return { key: label, value: (<Input.TextArea value={value} onChange={(e) => { onChange(e.target.value) }} rows={5} />) };
                case 'select0': return { key: label, value: gSelector(0) };
                case 'select1': return { key: label, value: gSelector(1) };
                case 'select2': return { key: label, value: gSelector(2) };
                case 'select3':
                    let valueFind = this.props.data[this.selectors[3].id].dataset.find(f => f.code === value);
                    let valueName = <h3 style={{ textAlign: "center" }}>{value} - {valueFind ? valueFind.name : ""}</h3>
                    return {
                        key: label,
                        value: (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code == '510')) ?
                            (<Row>
                                <Col span={3}>{this.addApplicationButton()}</Col>
                                <Col span={3}>{this.editApplicationButton()}</Col>
                                {/* <Col span={18}>{gSelector(3)}</Col> */}
                                <Col span={18}>{valueName}</Col>
                            </Row>)
                            : valueName
                    };
                case 'select4': return isNew ? { key: label, value: gSelector(4) } : false;
                case 'select5':
                    return {
                        key: label, value: (<Select {...{ value, onChange }} style={{ width: '100%' }}>
                            <Option value="0">{gt(17198)}</Option>
                            <Option value="1">{gt(17199)}</Option>
                            <Option value="2">{gt(17200)}</Option>
                        </Select>)
                    };
                case 'select6':
                    return {
                        key: label, value: (<Row>
                            <Col span={4}>
                                <Tooltip title={gt(17216)}>
                                    <Button onClick={() => { this.setState({ modalFlags: true }) }}>
                                        <Icon style={{ fontSize: 16 }} type={"setting"} theme="outlined" />
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col span={20}>
                                <Select onChange={(e) => {
                                    let flags = {};
                                    switch (parseInt(e, 10)) {
                                        case 1:
                                            flags.clearCreditCardRecords = true;
                                            flags.clearDocumentsTables = true;
                                            flags.clearFixLab = true;
                                            flags.clearOrders = true;
                                            flags.resetNumerators = true;
                                            flags.disconnectPos = true;
                                            break;
                                        case 0:
                                        default:
                                            flags.clearCreditCardRecords = false;
                                            flags.clearDocumentsTables = false;
                                            flags.clearFixLab = false;
                                            flags.clearOrders = false;
                                            flags.resetNumerators = false;
                                            flags.disconnectPos = false;
                                            break;
                                    }
                                    this.setState({ flags: { ...this.state.flags, ...flags } })
                                }} style={{ width: '100%' }}>
                                    <Option value="0">{gt(17222)}</Option>
                                    <Option value="1">{gt(17223)}</Option>
                                </Select>
                            </Col>
                        </Row>)
                    };
                case 'switch': return { key: label, value: (<Switch checked={value == '1'} onChange={(e) => { onChange(e ? '1' : '0') }} {...switchProps} />) };
                //
                case 'image': return { key: label, value: (<Input value={value} onChange={(e) => { onChange(e.target.value) }} />) };
            }
        });

        return [
            { span: 8, list: res.slice(0, 11).filter((f, i) => (i === 0) ? ((isNew) ? true : false) : f) /*.filter(f => f) */ },
            { span: 8, list: res.slice(11, 18) },
            { span: 8, list: res.slice(18) },
        ];
    }

    editApplicationButton = () => {
        let re = new RegExp(/^[a-zA-Z0-9]+$/);
        let buttonTitle = this.getText(18269);

        const {
            modalCreateEdit,
            editApplicationButton,
            editApplicationName,
            editApplicationMailName,
            editApplicationMailTitle
        } = this.state;

        let content = (<div>
            <h3>{this.getText(51810)}</h3>
            <Input
                value={editApplicationName}
                onChange={(e) => {
                    let v = e.target.value;
                    let valid = v ? re.test(v) && v.length <= 11 : true;
                    if (valid) this.setState({ editApplicationName: e.target.value })
                }} />
            <br />
            <br />
            <h4>{this.getText(17531)}</h4>
            <Input
                value={editApplicationMailName}
                onChange={(e) => {
                    let v = e.target.value;
                    let valid = v ? re.test(v) && v.length <= 40 : true;
                    if (valid) this.setState({ editApplicationMailName: e.target.value })
                }} />
            <br />
            <br />
            <h4>{this.getText(17532)}</h4>
            <Input
                value={editApplicationMailTitle}
                onChange={(e) => {
                    let v = e.target.value;
                    let valid = v ? v.length <= 100 : true;
                    if (valid) this.setState({ editApplicationMailTitle: e.target.value })
                }} />

            <Button key={"back"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                this.setState({
                    editApplicationButton: false,
                    editApplicationName: '',
                    editApplicationMailName: '',
                    editApplicationMailTitle: ''
                })
            }}>{this.getText(51812)}</Button>
            <Button
                key={"editOk"}
                disabled={!editApplicationName}
                className={"btn"}
                style={{ marginTop: "5px", marginLeft: "5px" }}
                type="primary"
                onClick={() => {
                    let dataSend = "_id\f_name\f_smsName\f_mailName\f_mailTitle\r" +
                        modalCreateEdit.cApplicationId + "\f" +
                        editApplicationName + "\f" +
                        editApplicationName + "\f" +
                        editApplicationMailName + "\f" +
                        editApplicationMailTitle;
                    this.sendAPI(this.api.edit_app, dataSend, () => {
                        this.setState({
                            editApplicationButton: false,
                            editApplicationName: '',
                            editApplicationMailName: '',
                            editApplicationMailTitle: ''
                        }, this.refreshData)
                    })
                }}>{this.getText(51811)}</Button>
        </div>)

        return (<Tooltip title={buttonTitle}>
            <Popover
                content={content}
                title={buttonTitle}
                placement="bottomLeft"
                trigger="click"
                visible={editApplicationButton}>
                <Button key={"editIcon"} className={"btn"} disabled={!modalCreateEdit || !modalCreateEdit.cApplicationId} onClick={() => {
                    this.sendAPI(this.api.get_app, "_id\r" + modalCreateEdit.cApplicationId, (ob) => {
                        let dataApp = ob.data ? ob.data.split("\f") : ["", "", ""]
                        this.setState({
                            editApplicationButton: true,
                            editApplicationName: dataApp[0],
                            editApplicationMailName: dataApp[1],
                            editApplicationMailTitle: dataApp[2],
                            addApplicationButton: false,
                            addApplicationName: '',
                            addApplicationMailName: '',
                            addApplicationMailTitle: '',
                        })
                    })
                }}>
                    <Icon style={{ fontSize: 16 }} type={"edit"} theme="outlined" />
                </Button>
            </Popover>
        </Tooltip >)
    }

    addApplicationButton = () => {
        let re = new RegExp(/^[a-zA-Z0-9]+$/);
        let buttonTitle = this.getText(51809);

        const {
            modalCreateEdit,
            addApplicationButton,
            addApplicationName,
            addApplicationMailName,
            addApplicationMailTitle
        } = this.state;

        let content = (<div>
            <h3>{this.getText(51810)}</h3>
            <Input
                value={addApplicationName}
                onChange={(e) => {
                    let v = e.target.value;
                    let valid = v ? re.test(v) && v.length <= 11 : true;
                    if (valid) this.setState({ addApplicationName: e.target.value })
                }} />
            <br />
            <br />
            <h4>{this.getText(17531)}</h4>
            <Input
                value={addApplicationMailName}
                onChange={(e) => {
                    let v = e.target.value;
                    let valid = v ? re.test(v) && v.length <= 40 : true;
                    if (valid) this.setState({ addApplicationMailName: e.target.value })
                }} />
            <br />
            <br />
            <h4>{this.getText(17532)}</h4>
            <Input
                value={addApplicationMailTitle}
                onChange={(e) => {
                    let v = e.target.value;
                    let valid = v ? v.length <= 100 : true;
                    if (valid) this.setState({ addApplicationMailTitle: e.target.value })
                }} />

            <Button key={"back"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                this.setState({
                    addApplicationButton: false,
                    addApplicationName: '',
                    addApplicationMailName: '',
                    addApplicationMailTitle: '',
                })
            }}>{this.getText(51812)}</Button>
            <Button
                key={"addOk"}
                disabled={!addApplicationName || !addApplicationMailName || !addApplicationMailTitle}
                className={"btn"}
                style={{ marginTop: "5px", marginLeft: "5px" }}
                type="primary"
                onClick={() => {
                    let dataSend = "_name\f_mailName\f_mailTitle\r" + addApplicationName + "\f" + addApplicationMailName + "\f" + addApplicationMailTitle;
                    this.sendAPI(this.api.add_app, dataSend, (ob) => {
                        this.setState({
                            addApplicationButton: false,
                            addApplicationName: '',
                            addApplicationMailName: '',
                            addApplicationMailTitle: '',
                            modalCreateEdit: { ...modalCreateEdit, cApplicationId: ob.data }
                        }, this.refreshData)
                    })
                }}>{this.getText(51811)}</Button>
        </div>)

        return (<Tooltip title={buttonTitle}>
            <Popover
                content={content}
                title={buttonTitle}
                placement="bottomLeft"
                trigger="click"
                visible={addApplicationButton}>
                <Button key={"addIcon"} className={"btn"} onClick={() => {
                    this.setState({
                        addApplicationButton: true,
                        editApplicationButton: false,
                        editApplicationName: '',
                        editApplicationMailName: '',
                        editApplicationMailTitle: '',
                    })
                }}>
                    <Icon style={{ fontSize: 16 }} type={"plus"} theme="outlined" />
                </Button>
            </Popover>
        </Tooltip >)
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, this.errorCallback);
    }

    sendAPIwithErr = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }


    handleCancel = () => {
        this.setState({
            modalCreateEdit: null,
            addApplicationButton: false,
            addApplicationName: '',
            addApplicationMailName: '',
            addApplicationMailTitle: '',
        });
    }

    errorCallback = (e) => {
        console.error(e);
        this.setState({ errorStatment: true })
    }

    dataSendFormat = (m) => {
        console.log("dataSendFormat", m)
        let keys = Object.keys(m);
        return keys.join('\f') + '\r' + keys.map(x => m[x]).join('\f');
    }

    handleOk = () => {
        let m = this.state.modalCreateEdit;
        m = { ...m, cIsForWizard: 1, cIsNew: m.key === 0 ? "1" : "0" }
        let notValid = this.fields.filter(f => f.isRequired && !m[f.field] && (f.field != 'cTemplateOfCopy' || m.key === 0));

        if (!notValid.length) {

            // let keys = Object.keys(m);
            // let dataSend = keys.join('\f') + '\r' + keys.map(x => m[x]).join('\f');
            // let fCity = this.props.data.CITIES_LIST.dataset.find(f => f.code === m.cCityID);
            // let franchiseSend = "fID\ffName\ffOsek\ffNikuy\ffStreet\ffHouse\ffCity\ffZip\ffManagersWhatsapp\ffEmployeesWhatsapp\ffEmail\r"
            //     + "0\f" + m.cName + "\f\f\f\f\f" + (fCity ? fCity.name : "") + "\f\f\f\f" + m.cEmail;

            let dataSend = this.dataSendFormat(m);

            if (m.key === 0) {
                this.setState({ loading: 0.5 }, () => {
                    // this.setState({ loadingMax: 50 });
                    this.loadingPrecentUpdate();
                    this.sendAPI(this.api.create, dataSend, (ob) => {
                        if (ob.data) {
                            console.log("saved company data")
                            this.setState({ loading: 1 }, () => {
                                this.sendAPIwithErr(this.api.create_schema, ob.data,
                                    // (ob1) => {
                                    //     if (ob1.data == '1') {
                                    //         this.sendAPI(this.api.create_franchise, ob.data, () => {

                                    //             const flags = this.state.flags;
                                    //             const [headers, values] = ob.data.split('\r');
                                    //             const input = headers + '\f' + '_flags' + '\r' + values + '\f' + JSON.stringify(flags);
                                    //             const newId = values ? values.split("\f")[0] : 0;

                                    //             this.sendAPI(this.api.edit_details, this.dataSendFormat({ ...m, cID: newId }), () => {
                                    //                 this.sendAPI(this.api.post_create_schema_actions, input, () => {
                                    //                     this.setState({ loading: 5, loadingPrecent: 100 }, () => {
                                    //                         setTimeout(this.closeLoadingModal, 3000);
                                    //                     })
                                    //                 })
                                    //             })
                                    //         })
                                    //     } else {
                                    //         console.log("copy schema: finished with no confirmation")
                                    //         this.setState({ errorStatment: true });
                                    //     }
                                    // },
                                    // (err) => {
                                    () => {
                                        console.log("start cop triger checker")
                                        // console.log(err)
                                        // START TRIGER
                                        // this.setState({ loadingMax: 99 }, () => {
                                        const intervalTime = 5 * 1000;
                                        const totalTime = 25 * 60 * 1000;
                                        // const totalTime = 15 * 60 * 1000;
                                        let counter = totalTime / intervalTime;
                                        let checker = setInterval(() => {
                                            counter--;
                                            if (counter <= 0) {
                                                clearInterval(checker);
                                                this.setState({ errorStatment: true });
                                            } else {
                                                this.sendAPI(this.api.check_schema, ob.data, (ob2) => {
                                                    if (ob2.data) {
                                                        clearInterval(checker);
                                                        this.sendAPI(this.api.create_franchise, ob.data, () => {
                                                            const flags = this.state.flags;
                                                            const [headers, values] = ob.data.split('\r');
                                                            const input = headers + '\f' + '_flags' + '\r' + values + '\f' + JSON.stringify(flags)
                                                            const newId = values ? values.split("\f")[0] : 0;

                                                            this.sendAPI(this.api.edit_details, this.dataSendFormat({ ...m, cID: newId }), () => {
                                                                this.sendAPI(this.api.post_create_schema_actions, input, () => {
                                                                    this.setState({ loading: 5, loadingPrecent: 100 }, () => {
                                                                        setTimeout(this.closeLoadingModal, 3000);
                                                                    })
                                                                })

                                                            })
                                                        })
                                                    }
                                                })
                                            }
                                        }, intervalTime);
                                        // });
                                    },
                                    (err) => {
                                        console.log("copy schema: finished with no confirmation", err)
                                        this.setState({ errorStatment: true });
                                    }
                                )
                            })
                        } else {
                            console.log("failed to save company data")
                            this.setState({ errorStatment: true });
                        }
                    })
                })
            } else {
                this.sendAPI(this.api.edit, dataSend, () => {
                    this.sendAPI(this.api.edit_details, dataSend, () => {
                        this.refreshData();
                        this.setState({ loading: null }, this.handleCancel);
                        message.success(this.getText(15281));
                    })
                })
            }
        } else {
            message.error(this.getText(15346))
        }
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

        this.selectors.forEach(x => {
            this.props.dataActions.genericSelectorRefreshDataset(
                x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })
    }

    closeLoadingModal = () => {
        this.setState({ loading: null, errorStatment: false, loadingPrecent: 0, modalCreateEdit: null }, this.refreshData)
    }

    checkedModule = (text, record) => {
        return (<Checkbox checked={text == 1} onChange={e => {
            let isChecked = e.target.checked ? '1' : '0';
            let data = this.props.data[this.modules_id].data.map((x, i) => {
                if (i === record.index) return x.split('\f').slice(0, -1).join('\f') + '\f' + isChecked
                else return x;
            });
            this.props.dataActions.setJson(this.modules_id, { data })
        }} />)
    }

    checkedLabels = (text, record) => {
        let checked = text == 1;
        let indeterminate = false;
        if (text === "All") {
            let checkedList = this.props.data[this.labels_id].data.map(x => x.split("\f")[2])
            let countChecked = checkedList.filter(f => f == 1).length;
            checked = checkedList.length && checkedList.length == countChecked;
            indeterminate = checkedList.length && countChecked && checkedList.length > countChecked;
        }
        return (<Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={e => {
                let isChecked = e.target.checked ? '1' : '0';
                let data = this.props.data[this.labels_id].data.map((x, i) => {
                    if (text === "All" || i === record.index) return x.split('\f').map((y, yi) => yi == 2 ? isChecked : y).join('\f')
                    else return x;
                });
                this.props.dataActions.setJson(this.labels_id, { data })
            }} />)
    }

    onChangeQuery = (queryId) => {
        let findQuery = this.props.data[this.queries_id].dataset.find(f => f.code === queryId);
        let prmCount = findQuery ? findQuery.prmCount : 0;
        let params = [];
        if (prmCount > 0) for (let x = 1; x <= prmCount; x++) { params.push('') }

        this.setState({ modalQueries: { ...this.state.modalQueries, queryId: parseInt(queryId), params } });
    }

    runQuery = () => {
        const { companyId, queryId, params } = this.state.modalQueries;
        let dataSend = "data\r" + JSON.stringify({ companyId, queryId, params });
        this.sendAPI(this.api.run_queries, dataSend, () => {
            this.setState({ modalQueries: null }, () => { message.success(this.getText(17148)) })
        })
    }

    deActivePosButton = (text, record) => {
        const compId = this.state.modalPosList ? this.state.modalPosList.compId : null;
        const compCode = this.state.modalPosList ? this.state.modalPosList.compCode : null;

        let res = [
            <Tooltip title={this.getText(11700)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"diff"} onClick={() => {
                    this.props.uiActions.showModal(TableIDs.modalNumeratorsPointsOfSale, { sID: record.pId, sName: record.pName, compIdFromAdmin: compId })
                }} />
            </Tooltip>,
            <Tooltip title={this.getText(11701)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"plus-circle"} onClick={() => {
                    this.sendAPI("create_document_numerators_admin", 'compId\fposId\r' + compId + '\f' + record.pId, () => message.success(this.getText(11697)))
                }} />
            </Tooltip>
        ]

        if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code == '514')) {
            res.push(<Tooltip title={this.getText(17570)}>
                <Popconfirm
                    title={this.getText(11702)}
                    okText={this.getText(11694)}
                    cancelText={this.getText(11695)}
                    onConfirm={() => {
                        if (compId) {
                            this.sendAPI(this.api.delete_pos, 'compId\fposId\r' + compId + '\f' + record.pId, () => {
                                message.success(this.getText(11696));
                                this.getPosListByCompany(compId);
                            });
                        }
                    }}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close-square"} />
                </Popconfirm>
            </Tooltip>)
        }

        if (record.pSerial) {
            res.push(<Tooltip title={this.getText(18397)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"api"} onClick={() => {
                    this.cotAdminPosConnect(compId, record.pId, compCode)
                }} />
            </Tooltip>)
        }

        return res;

    }


    cotAdminPosConnect = (compId, posId, compCode) => {
        this.sendAPI("add_talk_back_task_bo", "company\ftaskAction\fposId\fpayload\r" + compCode + "\fopen_support\f" + posId + "\f{}", () => {
            this.sendAPI("sunmi_remote_control", "mPosList\fmCompanyId\r" + posId + "\f" + compId, (ob) => {
                if (ob.data) {
                    let jsonData = JSON.parse(ob.data);
                    if (jsonData && jsonData.data && jsonData.data.uri) {
                        window.open(jsonData.data.uri, "_blank")
                        return;
                    }
                }
                message.error(this.getText(18398))
            })
        })
    }

    renderAddBiling = (text, record) => {
        const { bilingSumToAdd, bilingTypeToAdd } = this.state;
        const { cLoginCode } = this.state.modalBiling;
        const resetEdit = () => {
            this.setState({ bilingSumToAdd: 0, bilingTypeToAdd: null }, () => {
                this.props.dataActions.genericDataGetRequested(this.biling_id, this.props.user.companyCode, this.props.user.token, this.api.get_biling);
                this.props.dataActions.genericDataGetRequested(this.biling_history_id, this.props.user.companyCode, this.props.user.token, this.api.get_biling_history);
            })
        }

        if (record.mType == bilingTypeToAdd) {
            return (<span>
                <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="check" theme="outlined" onClick={() => {
                    let dataSend = "mCompCode\fmType\fmSum\r" + cLoginCode + "\f" + bilingTypeToAdd + "\f" + bilingSumToAdd
                    this.sendAPI(this.api.set_biling, dataSend, resetEdit)
                }} />
                <InputNumber precision={0} value={bilingSumToAdd} onChange={e => {
                    this.setState({ bilingSumToAdd: e })
                }} />
                <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="close" theme="outlined" onClick={resetEdit} />
            </span>)
        } else {
            return (<Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="plus" theme="outlined" onClick={() => {
                this.setState({ bilingTypeToAdd: record.mType })
            }} />)
        }
    }

    renderBilingType = (text) => {
        switch (text) {
            case '1': return this.getText(12390);
            case '2': return this.getText(12391);
            case '3': return this.getText(17638);
            case '4': return this.getText(13991);
            case '5': return this.getText(18109);
            default: return '';
        }
    }

    dataSourceCompanyPosList = () => {
        const { data, headers } = this.props.data[this.pos_id];
        const { companyPosListSearch } = this.state;
        let dataSource = data.map((x, index) => {
            let y = x.split("\f")
            let inFilter = companyPosListSearch ? false : true;
            let obj = { key: y[0], index }
            headers.forEach((z, i) => {
                obj = { ...obj, [z]: y[i] }
                if (companyPosListSearch && y[i] && y[i].indexOf(companyPosListSearch) > -1) inFilter = true;
            })
            if (inFilter) return obj;
        })
        return dataSource.filter(f => f)
    }

    updateUserList = () => {
        let userList = new Set();
        // let userIPList = new Set();

        const { modalRedirectHistoryFilterUser, modalRedirectHistoryFilterUserIP, userListIsAdmin, modalRedirectHistory } = this.state;
        if ((!modalRedirectHistoryFilterUser && !modalRedirectHistoryFilterUserIP) || userListIsAdmin != modalRedirectHistory.admins) {
            const dataSend =
                "_page_num\f_rows_num\f_words\f_sort_by\f_isAsc\f_compId\f_adminsLog\f_errorsLog\f_userId\f_fromDate\f_toDate\f_userIP\f_userToken\f_errorJson\r" +
                "1\f99999999\f\f\f\f" + this.state.modalRedirectHistory.cID +
                "\f" + this.state.modalRedirectHistory.admins +
                "\f" + this.state.modalRedirectHistory.errors + "\f\f\f\f\f\f";

            this.sendAPI(this.api.get_log_connect, dataSend, ob => {
                if (ob.data) {
                    ob.data.split("\r")
                        .slice(1, -1)
                        .map(x => x.split("\f"))
                        .sort((a, b) => a[1] - b[1])
                        .forEach(y => { userList.add(y[1] + "#" + y[2]) })
                }

                this.setState({
                    userList,
                    //  userIPList, 
                    userListIsAdmin: modalRedirectHistory.admins
                })
            })
        }
    }

    onSearchIPAll = () => {
        const { modalSearchIPAllStep, modalSearchIPAllAddress } = this.state;
        const { count } = this.props.data[this.id];
        if (modalSearchIPAllStep < count && modalSearchIPAllStep > -1) {
            this.sendAPI("get_company_id_per_index", modalSearchIPAllStep, ob => {
                const [compID, compName, compLoginCode] = ob.data?.split('\f')
                let dataSend = "_compId\f_adminsLog\f_errorsLog\f_userId\f_fromDate\f_toDate\f_userIP\f_userToken\f_errorJson\f_words\f_page_num\f_rows_num\f_sort_by\f_isAsc\r"
                    + compID + "\f0\f0\f\f\f\f" + modalSearchIPAllAddress.join('.') + "\f\f\f\f1\f99999999\fcol_0\f1"
                this.sendAPI(this.api.get_log_connect, dataSend, ob2 => {
                    let dataGet = ob2.data.split("\r");
                    let headers = !this.props.data[this.log_all_id].headers.length ? [...dataGet[0].split("\f"), "compID", "compName"] : this.props.data[this.log_all_id].headers
                    let count = parseInt(dataGet.slice(-1)[0]) + this.props.data[this.log_all_id].count;
                    let data = [...this.props.data[this.log_all_id].data, ...dataGet.slice(1, -1).map(x => x + "\f" + compID + "\f" + compName)]
                    this.props.dataActions.setJson(this.log_all_id, { headers, count, data })
                    if (this.state.modalSearchIPAllStep > -1) this.setState({ modalSearchIPAllStep: modalSearchIPAllStep + 1 }, this.onSearchIPAll)
                })
            })
        }
    }

    setSearchIPAllAddress = (index, value) => {
        if (parseInt(value) >= 0 && parseInt(value) <= 999) {
            let modalSearchIPAllAddress = [...this.state.modalSearchIPAllAddress]
            modalSearchIPAllAddress[index] = parseInt(value);
            this.setState({ modalSearchIPAllAddress })
        }

    }

    colImageFile = (text) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        let baseUrl = isDev ? "http://147.235.163.248" : origin;
        return text ? (<img src={baseUrl + '/uploads/shared/' + text} height={70} />) : ""
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            //whiteSpace: "unset",
        };

        const render = t => t;

        const gt = this.getText;

        let rt = (text) => text;
        let rso = (text) => this.getSelectorDisabled(0, text)
        let rss = (text) => this.getSelectorDisabled(1, text)

        let columns = [
            { title: gt(15221), key: "cID", dataIndex: "cID", sorter: true, width: "8%", render: rt },
            { title: gt(15222), key: "cName", dataIndex: "cName", sorter: true, width: "25%", render: rt },
            { title: gt(15225), key: "cOrganizationID", dataIndex: "cOrganizationID", sorter: true, width: "15%", render: rso },
            { title: gt(15226), key: "cCityID", dataIndex: "cCityID", sorter: true, width: "15%", render: rss },
            { title: gt(15227), key: "cEmail", dataIndex: "cEmail", sorter: true, width: "15%", render: rt },
            { title: gt(15223), key: "cLoginCode", dataIndex: "cLoginCode", sorter: true, width: "10%", render: rt },
            { title: gt(15224), width: "12%", render: this.getEditIcons },
        ];

        let isNew = this.state.modalCreateEdit && this.state.modalCreateEdit.key === 0;

        let messageAlert = '';
        if (this.state.errorStatment) {
            messageAlert = gt(15342);
        } else {
            switch (this.state.loading) {
                case 0.5: messageAlert = gt(15337); break;
                case 1: messageAlert = gt(15338); break;
                case 2: messageAlert = gt(15344); break;
                case 3: messageAlert = gt(15339); break;
                case 4: messageAlert = gt(15340); break;
                case 5: messageAlert = gt(15341); break;
            }
        }

        let dataSourceModules = this.props.data[this.modules_id].data.map((x, index) => {
            let y = x.split('\f');
            return {
                key: y[0],
                mID: y[0],
                mName: y[1],
                mIsChecked: y[2],
                mImageFile: y[3],
                index
            }
        }).filter(f => {
            let s = this.state.modalModulesSearch
            if (!s) return true;
            if (f.mID && f.mID.indexOf(s) > -1) return true
            if (f.mName && f.mName.indexOf(s) > -1) return true
        })

        let dataSourceLabels = this.props.data[this.labels_id].data.map((x, index) => {
            let y = x.split('\f');
            return {
                key: y[0],
                mID: y[0],
                mName: y[1],
                mIsChecked: y[2],
                mImageFile: y[3],
                index
            }
        }).filter(f => {
            let s = this.state.modalLabelsSearch
            if (!s) return true;
            if (f.mID && f.mID.indexOf(s) > -1) return true
            if (f.mName && f.mName.indexOf(s) > -1) return true
        })


        let actionButtons = [{
            buttonType: 'filter',
            buttonFunction: () => { this.setState({ modalFilter: true }) }
        }];

        if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code == '508')) {
            actionButtons.push({
                buttonType: 'add',
                buttonFunction: this.createNew
            });
        }

        let firstRowBiling = this.props.data[this.biling_id].data[0];
        let lastDateUpdate = firstRowBiling ? firstRowBiling.split("\f")[4] : null

        let modalRedirectHistoryTitle = this.state.modalRedirectHistory ?
            this.state.modalRedirectHistory.errors == 1 ? this.getText(19833)
                : this.getText(this.state.modalRedirectHistory.admins == 1 ? 18855 : 17586) + ' - ' + this.state.modalRedirectHistory.cName
            : ""

        let genericActionButtons = [
            <Tooltip title={this.getText(19986)}>
                <Button key={"search_ip_all"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => { this.setState({ modalSearchIPAll: true }) }}
                ><Icon style={{ fontSize: 16 }} type={"search"} theme="outlined" /></Button>
            </Tooltip>
        ];

        if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code == '526')) {
            genericActionButtons.push(<Tooltip title={this.getText(19833)}>
                <Button key={"loginErrors"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => { this.setState({ modalRedirectHistory: { cID: 0, admins: 0, errors: 1 } }) }}
                ><Icon style={{ fontSize: 16 }} type={"history"} theme="outlined" /></Button>
            </Tooltip>)
        }


        const { modalSearchIPAll, modalSearchIPAllStep, modalSearchIPAllAddress } = this.state;
        const { count } = this.props.data[this.id];
        const modalSearchIPAllDisabled = modalSearchIPAllAddress.filter(f => f === null).length ? true : false

        return (<div style={divStyle}>
            <GenericTable
                id={this.id}
                getApi={this.api.get}
                title={gt(15220)}
                columns={columns}
                actionButtons={actionButtons}
                genericActionButtons={genericActionButtons}
                filters={this.defaultFilters}
                percentageToMobile={[20, 60, 0, 0, 0, 20]}
                searchBox={true}
                rowHeight={40}
                {...this.props}
            />
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                width={500}
                title={gt(19615)}
                footer={[
                    <Button key={"filter-cancel"} onClick={() => {
                        this.setState({ modalFilter: false })
                    }} style={{ margin: 5 }}>{gt(19616)}</Button>,
                    <Button key={"filter-clear"} onClick={() => {
                        this.setState({ modalFilterFields: this.defaultFilters })
                    }} style={{ margin: 5 }}>{gt(19617)}</Button>,
                    <Button key={"filter-ok"} type="primary" onClick={() => {
                        this.setState({ modalFilter: false }, () => {
                            this.props.dataActions.genericDataSetFilter(this.id,
                                this.props.user.companyCode,
                                this.props.user.token,
                                this.state.modalFilterFields,
                                this.api.get)
                        })
                    }} style={{ margin: 5 }}>{gt(19618)}</Button>,
                ]}>
                <Form.Item>
                    <Radio.Group
                        value={this.state.modalFilterFields._isActive}
                        onChange={e => { this.setState({ modalFilterFields: { ...this.state.modalFilterFields, _isActive: e.target.value } }) }}>
                        <Radio value={0}>{gt(19619)}</Radio>
                        <Radio value={1}>{gt(19620)}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={gt(19621)}>
                    <GenericSelector {...this.props} {...this.selectors[0]}
                        value={this.state.modalFilterFields._organizationID}
                        onChange={e => { this.setState({ modalFilterFields: { ...this.state.modalFilterFields, _organizationID: e } }) }} />
                </Form.Item>
                <Form.Item label={gt(19622)}>
                    <GenericSelector {...this.props} {...this.selectors[1]}
                        value={this.state.modalFilterFields._cityID}
                        onChange={e => { this.setState({ modalFilterFields: { ...this.state.modalFilterFields, _cityID: e } }) }} />
                </Form.Item>
                <Form.Item label={gt(19623)}>
                    <GenericSelector {...this.props} {...this.selectors[2]}
                        value={this.state.modalFilterFields._dealerID}
                        onChange={e => { this.setState({ modalFilterFields: { ...this.state.modalFilterFields, _dealerID: e } }) }} />
                </Form.Item>
            </GenericModal>
            <GenericModal
                visible={this.state.modalCreateEdit !== null}
                onCancel={this.handleCancel}
                title={gt(isNew ? 15228 : 15229)}
                footer={[
                    <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{gt(15231)}</Button>,
                    <Button key={"create"} type="primary" loading={this.state.loading} onClick={this.handleOk} style={{ margin: 5 }}>{gt(15230)}</Button>
                ]}>
                <GridKeyValues content={this.modalCEContent()} />
            </GenericModal>
            <GenericModal
                visible={this.state.loading}
                onCancel={this.closeLoadingModal}
                title={gt(15336)}
                width={600}>
                <Progress percent={this.state.loadingPrecent} status={this.state.errorStatment ? "exception" : this.state.loading == 5 ? "success" : "active"} />
                <Alert style={{ marginTop: 20 }} message={messageAlert} type={this.state.errorStatment ? "error" : "info"} />
                {this.state.errorStatment ? '' : (<h1 style={{ color: 'red', padding: 5 }}>{gt(15345)}</h1>)}
            </GenericModal>
            <GenericModal
                visible={this.state.modalModules !== null}
                onCancel={() => { this.setState({ modalModules: null }) }}
                title={gt(16884) + " " + (this.state.modalModules ? this.state.modalModules.cName : "")}
                width={500}
                showsearch={val => { this.setState({ modalModulesSearch: val }) }}
                footer={[
                    <Button onClick={() => { this.setState({ modalModules: null }) }} style={{ margin: 5 }}>{gt(15231)}</Button>,
                    <Button type="primary" onClick={this.saveModules} style={{ margin: 5 }}>{gt(15230)}</Button>
                ]}>
                <ResponsiveTable
                    dataSource={dataSourceModules}
                    tableOnly
                    pagination={false}
                    scroll={{ y: window.innerHeight - 300 }}
                    columns={[
                        { title: gt(16885), key: 'mID', dataIndex: 'mID', width: '20%', render: t => t },
                        { title: gt(16886), key: 'mName', dataIndex: 'mName', width: '70%', render: t => t },
                        { title: '', key: 'mIsChecked', dataIndex: 'mIsChecked', width: '10%', render: this.checkedModule },
                    ]}
                />
            </GenericModal>
            <GenericModal
                visible={this.state.modalLabels !== null}
                onCancel={() => { this.setState({ modalLabels: null }) }}
                title={gt(18401) + " " + (this.state.modalLabels ? this.state.modalLabels.cName : "")}
                width={1000}
                showsearch={val => { this.setState({ modalLabelsSearch: val }) }}
                genericActionButtons={[
                    <Button>{this.checkedLabels("All")}</Button>
                ]}
                footer={[
                    <Button onClick={() => { this.setState({ modalLabels: null }) }} style={{ margin: 5 }}>{gt(15231)}</Button>,
                    <Button type="primary" onClick={this.saveLabels} style={{ margin: 5 }}>{gt(15230)}</Button>
                ]}>
                <ResponsiveTable
                    dataSource={dataSourceLabels}
                    tableOnly
                    pagination={false}
                    scroll={{ y: window.innerHeight - 300 }}
                    columns={[
                        { title: gt(16885), key: 'mID', dataIndex: 'mID', width: '20%', render: t => t },
                        { title: gt(16886), key: 'mName', dataIndex: 'mName', width: '50%', render: t => t },
                        { title: gt(20044), key: 'mImageFile', dataIndex: 'mImageFile', width: '20%', render: this.colImageFile },
                        { title: '', key: 'mIsChecked', dataIndex: 'mIsChecked', width: '10%', render: this.checkedLabels },
                    ]}
                />
            </GenericModal>
            <GenericModal
                visible={this.state.modalQueries !== null}
                onCancel={() => { this.setState({ modalQueries: null }) }}
                title={gt(17145) + " - " + (this.state.modalQueries ? this.state.modalQueries.companyName : gt(17150))}
                width={600}
                footer={[
                    <Button onClick={() => { this.setState({ modalQueries: null }) }} style={{ margin: 5 }}>{gt(15231)}</Button>,
                    <Button type="primary" disabled={!this.state.modalQueries || !this.state.modalQueries.queryId} onClick={this.runQuery} style={{ margin: 5 }}>{gt(17147)}</Button>
                ]}>
                <Form.Item label={gt(17146)}>
                    <GenericSelector
                        {...this.props}
                        id={this.queries_id}
                        value={this.state.modalQueries ? this.state.modalQueries.queryId : null}
                        onChange={this.onChangeQuery}
                    />
                </Form.Item>
                {this.state.modalQueries && this.state.modalQueries.params ?
                    this.state.modalQueries.params.map((x, i) => {
                        return (<Form.Item label={'prm' + (i + 1)} >
                            <Input value={x} onChange={e => {
                                let params = this.state.modalQueries.params.map((y, ii) => {
                                    return i === ii ? e.target.value : y
                                })
                                this.setState({ modalQueries: { ...this.state.modalQueries, params } })
                            }} />
                        </Form.Item>)
                    }) : ""}
            </GenericModal>
            <GenericModal
                visible={this.state.modalFlags !== null}
                onCancel={() => { this.setState({ modalFlags: null }) }}
                title={"פעולות לביצוע בהקמה"/*zzz*/}
                width={500}
                footer={[
                    <Button key={"back"} onClick={() => { this.setState({ modalFlags: null }) }} style={{ margin: 5 }}>{"חזור"/*gt(15231) zzz*/}</Button>,
                ]}>
                {(this.state.modalFlags) ?
                    Object.keys(this.state.flags).map(key => {
                        const flags = this.state.flags;
                        return (
                            <Row style={{ padding: 4 }}>
                                <Checkbox checked={flags[key]} onChange={e => this.setState({ flags: { ...this.state.flags, [key]: e.target.checked } })}>{gt(this.flagsDescription[key])}</Checkbox>
                            </Row>
                        )
                    }) : ""}
            </GenericModal>

            <GenericModal
                visible={this.state.modalPosList}
                onCancel={() => { this.setState({ modalPosList: null }) }}
                width={1000}
                title={this.getText(17566) + (this.state.modalPosList ? ' - ' + this.state.modalPosList.compName : "")}
                showsearch={v => { this.setState({ companyPosListSearch: v }) }}
                actionButtons={[{
                    buttonType: 'refresh',
                    buttonFunction: () => { this.getPosListByCompany(this.state.modalPosList ? this.state.modalPosList.compId : null) }
                }]}>
                <ResponsiveTable
                    tableOnly
                    // idTable={this.props.data[this.pos_id]}
                    dataSource={this.dataSourceCompanyPosList()}
                    pagination={{ pageSize: parseInt((window.innerHeight - 340) / 40) }}
                    columns={[
                        { title: this.getText(17567), key: 'pId', dataIndex: 'pId', width: '15%', render: t => t },
                        { title: this.getText(17568), key: 'pName', dataIndex: 'pName', width: '40%', render: t => t },
                        { title: this.getText(17569), key: 'pSerial', dataIndex: 'pSerial', width: '15%', render: t => t },
                        { title: this.getText(18279), key: 'pVersion', dataIndex: 'pVersion', width: '15%', render: t => t },
                        { title: this.getText(18147), width: '15%', render: this.deActivePosButton },
                    ]} />
            </GenericModal>
            <GenericModal
                visible={this.state.modalRedirectHistory}
                onCancel={() => { this.setState({ modalRedirectHistory: null }) }}
                width={1000}
                title={modalRedirectHistoryTitle}
                actionButtons={[{ buttonType: 'filter', buttonFunction: () => { this.setState({ modalRedirectHistoryFilter: true }, this.updateUserList) } }]}
            >
                {this.state.modalRedirectHistory ?
                    (<GenericTable
                        {...this.props}
                        tableOnly
                        id={this.log_connect_id}
                        getApi={this.api.get_log_connect}
                        filters={{
                            _compId: this.state.modalRedirectHistory.cID,
                            _adminsLog: this.state.modalRedirectHistory.admins,
                            _errorsLog: this.state.modalRedirectHistory.errors,
                            _userId: this.state.modalRedirectHistoryFilterUser,
                            _fromDate: this.state.modalRedirectHistoryFilterFromDate,
                            _toDate: this.state.modalRedirectHistoryFilterToDate,
                            _userIP: this.state.modalRedirectHistoryFilterUserIP,
                            _userToken: this.state.modalRedirectHistoryFilterUserToken,
                            _errorJson: this.state.modalRedirectHistoryFilterErrorJson,
                        }}
                        // searchBox
                        columns={this.state.modalRedirectHistory?.errors == 1 ? [
                            { title: this.getText(19840), key: 'nUserName', dataIndex: 'nUserName', width: '40%', render: t => t },
                            { title: this.getText(17589), key: 'nDateTime', dataIndex: 'nDateTime', width: '20%', render: t => t },
                            { title: this.getText(17590), key: 'nIP', dataIndex: 'nIP', width: '30%', render: t => t },
                            { title: this.getText(19841), key: 'nCompCode', dataIndex: 'nCompCode', width: '10%', render: t => (<Icon type="table" onClick={() => { this.setState({ modalRawParse: t }) }} />) },
                        ] : [
                            { title: this.getText(17587), key: 'nUserID', dataIndex: 'nUserID', width: '10%', render: t => t },
                            { title: this.getText(17588), key: 'nUserName', dataIndex: 'nUserName', width: '40%', render: t => t },
                            { title: this.getText(17589), key: 'nDateTime', dataIndex: 'nDateTime', width: '20%', render: t => t },
                            { title: this.getText(17590), key: 'nIP', dataIndex: 'nIP', width: '30%', render: t => t },
                        ]}
                    />)
                    : ""}
            </GenericModal>
            <GenericModal
                visible={this.state.modalRawParse != null}
                onCancel={() => { this.setState({ modalRawParse: null }) }}
                width={600}
                title={this.getText(19842)}
            >
                {this.state.modalRawParse ? <JsonTreeView jsonData={this.state.modalRawParse} /> : ""}
            </GenericModal>
            <GenericModal
                visible={this.state.modalRedirectHistoryFilter}
                onCancel={() => { this.setState({ modalRedirectHistoryFilter: false }) }}
                width={600}
                title={this.getText(18859)}
                footer={[
                    <Button onClick={() => {
                        this.setState({ modalRedirectHistoryFilter: false })
                    }}>{this.getText(18864)}</Button>,
                    <Button onClick={() => {
                        this.setState({
                            modalRedirectHistoryFilterUser: null,
                            modalRedirectHistoryFilterFromDate: null,
                            modalRedirectHistoryFilterToDate: null,
                            modalRedirectHistoryFilterUserIP: null,
                            modalRedirectHistoryFilterUserToken: null,
                            modalRedirectHistoryFilterErrorJson: null,
                        });
                    }}>{this.getText(18865)}</Button>,
                    <Button type="primary" onClick={() => {
                        this.setState({ modalRedirectHistoryFilter: false }, () => {
                            this.props.dataActions.genericDataSetFilter(
                                this.log_connect_id,
                                this.props.user.companyCode,
                                this.props.user.token,
                                {
                                    _userId: this.state.modalRedirectHistoryFilterUser,
                                    _fromDate: this.state.modalRedirectHistoryFilterFromDate,
                                    _toDate: this.state.modalRedirectHistoryFilterToDate,
                                    _userIP: this.state.modalRedirectHistoryFilterUserIP,
                                    _userToken: this.state.modalRedirectHistoryFilterUserToken,
                                    _errorJson: this.state.modalRedirectHistoryFilterErrorJson,
                                },
                                this.api.get_log_connect)
                        })
                    }}>{this.getText(18866)}</Button>,
                ]}>
                <Form.Item label={this.getText(18860)}>
                    {this.state.modalRedirectHistory?.errors ?
                        <Input
                            value={this.state.modalRedirectHistoryFilterUserToken}
                            onChange={e => { this.setState({ modalRedirectHistoryFilterUserToken: e.target.value }) }}
                        />
                        :
                        <Select
                            key={"USER_SELECTOR"}
                            showSearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            style={{ width: 300 }}
                            value={this.state.modalRedirectHistoryFilterUser}
                            onChange={e => { this.setState({ modalRedirectHistoryFilterUser: e }) }}>
                            {[...this.state.userList].map(x => {
                                let y = x.split("#");
                                return (<Select.Option dir={"rtl"} key={x} value={y[0]} valuefilter={y[0] + " " + y[1]}>{y[0] + " - " + y[1]}</Select.Option>)
                            })}
                        </Select>
                    }
                </Form.Item>
                <Form.Item label={this.getText(18861)}>
                    <DateTimePicker
                        format="DD/MM/YYYY"
                        value={this.state.modalRedirectHistoryFilterFromDate}
                        onChange={e => { this.setState({ modalRedirectHistoryFilterFromDate: e }) }}
                        maxDate={this.state.modalRedirectHistoryFilterToDate}
                    />
                </Form.Item>
                <Form.Item label={this.getText(18862)}>
                    <DateTimePicker
                        format="DD/MM/YYYY"
                        value={this.state.modalRedirectHistoryFilterToDate}
                        onChange={e => { this.setState({ modalRedirectHistoryFilterToDate: e }) }}
                        minDate={this.state.modalRedirectHistoryFilterFromDate}
                    />
                </Form.Item>
                <Form.Item label={this.getText(18863)}>
                    <Input
                        value={this.state.modalRedirectHistoryFilterUserIP}
                        onChange={e => { this.setState({ modalRedirectHistoryFilterUserIP: e.target.value }) }}
                    />
                    {/* <Select
                        key={"USER_IP_SELECTOR"}
                        showSearch
                        filterOption={true}
                        optionFilterProp="valuefilter"
                        style={{ width: 300 }}
                        value={this.state.modalRedirectHistoryFilterUserIP}
                        onChange={e => { this.setState({ modalRedirectHistoryFilterUserIP: e }) }}>
                        {[...this.state.userIPList].map(x => (<Select.Option dir={"rtl"} key={x} value={x} valuefilter={x}>{x}</Select.Option>))}
                    </Select> */}
                </Form.Item>
                {this.state.modalRedirectHistory?.errors ?
                    <Form.Item label={this.getText(19925)}>
                        <Input
                            value={this.state.modalRedirectHistoryFilterErrorJson}
                            onChange={e => { this.setState({ modalRedirectHistoryFilterErrorJson: e.target.value }) }}
                        />
                    </Form.Item>
                    : ""}
            </GenericModal>
            <GenericModal
                visible={this.state.modalBiling}
                onCancel={() => { this.setState({ modalBiling: null }) }}
                width={1000}
                title={this.getText(17637) + (this.state.modalBiling ? ' - ' + this.state.modalBiling.cName : "")}
            >
                {this.state.modalBiling ?
                    (<span>
                        <h3 style={{ float: 'left' }}>{lastDateUpdate ? this.getText(18135) + " " + lastDateUpdate : ""}</h3>
                        <GenericTable
                            {...this.props}
                            tableOnly
                            id={this.biling_id}
                            getApi={this.api.get_biling}
                            filters={{ compCode: this.state.modalBiling.cLoginCode }}
                            pagination={false}
                            columns={[
                                { title: gt(12384), dataIndex: "mType", key: "mType", width: '20%', render: this.renderBilingType },
                                { title: gt(12385), dataIndex: "mPurchased", key: "mPurchased", width: '20%', render },
                                { title: gt(12386), dataIndex: "mUsed", key: "mUsed", width: '20%', render },
                                { title: gt(12387), dataIndex: "mBalance", key: "mBalance", width: '20%', render },
                                { title: gt(17639), width: '20%', render: this.renderAddBiling },
                            ]} />
                        <h3 style={{ textAlign: "center", margin: 5 }}>{gt(17683)}</h3>
                        <GenericTable
                            {...this.props}
                            tableOnly
                            id={this.biling_history_id}
                            getApi={this.api.get_biling_history}
                            filters={{ compCode: this.state.modalBiling.cLoginCode }}
                            scroll={{ y: 400 }}
                            columns={[
                                { title: gt(17680), dataIndex: "lTimestamp", key: "lTimestamp", width: '20%', render },
                                { title: gt(17681), dataIndex: "lText", key: "lText", width: '60%', render },
                                { title: gt(17682), dataIndex: "lUserName", key: "lUserName", width: '20%', render },
                            ]} />
                    </span>)
                    : ""}
            </GenericModal>
            <GenericModal
                width={600}
                visible={this.state.modalRedirect}
                onCancel={() => { this.setState({ modalRedirect: null }) }}
                title={(this.state.modalRedirect ? this.state.modalRedirect.name + ' - ' : "") + this.getText(17574)}>
                <Row>
                    {this.redirectList.map(x => {
                        return (<Col span={6}>
                            <Card
                                style={{ textAlign: 'center', margin: 10, cursor: 'pointer', width: 120, height: 120 }}
                                onClick={() => {
                                    let code = this.state.modalRedirect ? this.state.modalRedirect.code : '';
                                    if (code) {
                                        this.sendAPI(this.api.redirect, "compCode\fpageUrl\r" + code + "\f" + x.url, (ob) => {
                                            let anchor = document.createElement('a');
                                            let origin = window.location.origin;
                                            const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
                                            if (isDev) origin += '/cash-on-tab/build';
                                            anchor.href = origin + "/?" + ob.data;
                                            anchor.target = "_blank";
                                            anchor.click();
                                        })
                                    }
                                }}>
                                <Icon type={x.icon} style={{ fontSize: 50 }} />
                                <br />
                                <br />
                                {this.getText(x.text)}
                            </Card>
                        </Col>)
                    })}
                </Row>
            </GenericModal>
            <ModalNumeratorsPointsOfSale
                id={TableIDs.modalNumeratorsPointsOfSale}
                user={this.props.user}
                ActionQueue={this.props.ActionQueue}
                uiActions={this.props.uiActions} ui={this.props.ui}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalNumeratorsPointsOfSale)}
            />
            <GenericModal
                visible={modalSearchIPAll}
                onCancel={() => {
                    this.setState({ modalSearchIPAll: false, modalSearchIPAllStep: -1, modalSearchIPAllAddress: [null, null, null, null] }, () => {
                        this.props.dataActions.setJson(this.log_all_id, { data: [], count: 0 })
                    })
                }}
                title={this.getText(19986)}
                width={1000}>
                <div style={{ direction: "ltr", textAlign: "center" }}>
                    <Input autoFocus style={{ width: 50 }} value={modalSearchIPAllAddress[0]} onChange={e => { this.setSearchIPAllAddress(0, e.target.value) }} /> .&nbsp;
                    <Input style={{ width: 50 }} value={modalSearchIPAllAddress[1]} onChange={e => { this.setSearchIPAllAddress(1, e.target.value) }} /> .&nbsp;
                    <Input style={{ width: 50 }} value={modalSearchIPAllAddress[2]} onChange={e => { this.setSearchIPAllAddress(2, e.target.value) }} /> .&nbsp;
                    <Input style={{ width: 50 }} value={modalSearchIPAllAddress[3]} onChange={e => { this.setSearchIPAllAddress(3, e.target.value) }} />
                    &nbsp;&nbsp;&nbsp;  :{this.getText(19987)}
                    <br />
                    <Button
                        disabled={modalSearchIPAllStep == -1}
                        style={{ margin: 5 }}
                        onClick={() => { this.setState({ modalSearchIPAllStep: -1 }) }}
                    >{this.getText(19989)}</Button>
                    <Button
                        disabled={modalSearchIPAllAddress.find(f => f === null) || modalSearchIPAllStep > -1 ? true : false}
                        style={{ margin: 5 }}
                        type={"primary"}
                        onClick={() => { this.setState({ modalSearchIPAllStep: 0 }, this.onSearchIPAll) }}
                    >{this.getText(19988)}</Button>
                </div>
                <Progress
                    percent={parseInt((modalSearchIPAllStep / count) * 100)}
                    status={modalSearchIPAllStep == count ? "success" : "active"}
                />
                <ResponsiveTable
                    tableOnly
                    idTable={this.props.data[this.log_all_id]}
                    columns={[
                        { title: this.getText(19990), key: 'compID', dataIndex: 'compID', width: '10%', render: t => t },
                        { title: this.getText(19991), key: 'compName', dataIndex: 'compName', width: '25%', render: t => t },
                        { title: this.getText(19992), key: 'compLoginCode', dataIndex: 'compLoginCode', width: '10%', render: t => t },
                        { title: this.getText(17587), key: 'nUserID', dataIndex: 'nUserID', width: '10%', render: t => t },
                        { title: this.getText(17588), key: 'nUserName', dataIndex: 'nUserName', width: '25%', render: t => t },
                        { title: this.getText(17589), key: 'nDateTime', dataIndex: 'nDateTime', width: '20%', render: t => t },
                    ]}
                    pagination={{ pageSize: 10 }}
                />
            </GenericModal>
        </div >)
    }
}
export default Companies;
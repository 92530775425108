
import React from "react";
import "../App.css";
import { Form, Row, Col, Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Checkbox, Select } from 'antd';
import { DatePicker } from 'antd';
import moment from "moment";
import Jax from "../Jax/jax.es6.module";
//import ExtendedTable from "./ExtendedTable";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableIDs from '../data/TableIDs.js';
import PageToolbar from "./PageToolbar";
import LangContext from "../contextProvider/LangContext";
import { disabledDatePicker } from "../utils/utils";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
/*
type Props = {
    //title: string,
    apiCalls: any[],
    api_create: any,
    text:any,
};
*/
//type State = {}


type TagObj = {
    type: {
        id: string,
        text: string,
    },
    tags: {
        id: string,
        text: string,
    }[],
}

class ModalMessageHistoryAdvanceSearch extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.id = "ADVANCE_MESSAGE_HISTORY_SEARCH";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorSalesAlerts] === undefined) {
            props.data[TableIDs.genericSelectorSalesAlerts] = { ...props.data.genericSelector };
        }

        //if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) {
        //    props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        //}

        if (props.data[TableIDs.genericSelectorMsgType] === undefined) {
            props.data[TableIDs.genericSelectorMsgType] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorPreDefinedMsg] === undefined) {
            props.data[TableIDs.genericSelectorPreDefinedMsg] = { ...props.data.genericSelector };
        }

        this.api = {

            selector_msg_types: "get_msg_types_list",
            selector_pre_defined_msg: "get_pre_defined_msg_list",

            get: "get_message_queue_table_list",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.state = {
            loading: false,
            visible: false,
            request: {
                _words: '',

                _toDate: null,
                _fromDate: null,
                _msgType: '',
                _preDefineType: '',
                _sentSuccessfully: '',
            }
        }
        //this.api_create = this.props.apiCalls.find(item => item.apiType === 'create').apiCall;

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorMsgType, this.props.user.companyCode, this.props.user.token, this.api.selector_msg_types);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPreDefinedMsg, this.props.user.companyCode, this.props.user.token, this.api.selector_pre_defined_msg);

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    // send data to parent

    UNSAFE_componentWillReceiveProps(newProps) {
        //alert("on props: " + JSON.stringify(newProps));        
        let _state = this.state;
        _state.visible = newProps.toggle;
        this.setState(_state);

        //this.props.ActionQueue.addToQueue({
        //    action: this.props.dataActions.genericSelectorRefreshDataset,
        //    args: [TableIDs.genericSelectorPreDefinedMsg, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        //});
    }

    componentDidUpdate(prev) {
        if (prev.filterActive && !this.props.filterActive) this.resetFilter()
        else if (prev.valueSearch !== this.props.valueSearch) this.setState({ request: { ... this.state.request, _words: this.props.valueSearch } })
    }


    refreshData = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorMsgType, this.props.user.companyCode, this.props.user.token, this.api.selector_msg_types]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPreDefinedMsg, this.props.user.companyCode, this.props.user.token, this.api.selector_pre_defined_msg]
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    handleOk = () => {
        console.log("search...");

        this.props.dataActions.genericDataSetFilter(
            'MESSAGE_HISTORY_MAIN', this.props.user.companyCode, this.props.user.token, { ...this.state.request }, this.api.get);

        if (this.props.setFilterActive) this.props.setFilterActive(true)
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }
    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }

    disabledStartDate = (mFormDate) => {
        const _ToDate = this.state._ToDate;
        if (!mFormDate || !_ToDate) {
            return false;
        }
        return mFormDate.valueOf() > _ToDate.valueOf();
    }

    disabledEndDate = (_ToDate) => {
        const mFormDate = this.state.mFormDate;
        if (!_ToDate || !mFormDate) {
            return false;
        }
        return _ToDate.valueOf() <= mFormDate.valueOf();
    }

    onChange = (field, value) => {
        //this.setReportParameters("HoursReport", { [field]: value })
        let r = this.state.request;
        this.setState({
            request: {
                ...r, [field]: value,
            }
        });
    }

    onStartChange = (value) => {
        this.onChange('_fromDate', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('_toDate', value ? value.format("YYYY-MM-DD") : null);
    }

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }

    createCheckBox = (field, data) => {
        let r = this.state.request;
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setState({ request: { ...r, [field]: data } });
                //this.setReportParameters("SalesItems", { [field]: data });
                else
                    this.setState({ request: { ...r, [field]: '' } });
                //this.setReportParameters("SalesItems", { [field]: '' });
            }} />);
    }

    render() {
        console.log("render - modal advance search ");
        const { visible, loading, endOpen } = this.state;
        const { _fromDate, _toDate } = this.state.request;

        //const formItemLayout = {
        //    labelCol: { span: 4 },
        //    wrapperCol: { span: 14 },
        //}

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        let listMsgType = Jax.get(this.props.data, TableIDs.genericSelectorMsgType + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listPreDef = Jax.get(this.props.data, TableIDs.genericSelectorPreDefinedMsg + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let sentSuccessfullyTypes = [
            { code: '0', name: this.getText(12367) },
            { code: '1', name: this.getText(12368) },
            { code: '2', name: this.getText(12369) },
            { code: '3', name: this.getText(12370) },
            { code: '4', name: this.getText(17202) },
        ];

        let listSentSuccessfully = sentSuccessfullyTypes.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let mobile = window.innerWidth > 600 ? false : true;

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(12371)}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={mobile ? '95%' : '60%'}
                visible={visible}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={/**/null}

            >
                <Form id="search_message_history_form" layout="horizontal" //className="ant-advanced-search-form"
                    style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '2' }}
                    onSubmit={this.handleSearch}>

                    <FormItem label={this.getText(12372)} style={{ margin: '4px', breakInside: 'avoid-column' }}/*{...formItemLayout}*/>
                        <Input placeholder={this.getText(12380)} style={{ width: '80%' }}
                            value={//this.props.data['ITEMS_TABLE'].filters['_words']
                                this.state.request['_words']
                            }
                            onChange={this.onChangeInput}
                            onBlur={this.handleFilterBlur}
                        />
                    </FormItem>
                    <FormItem label={this.getText(12373)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        {this.createCheckBox('_msgType', Jax.get(this.props.data, TableIDs.genericSelectorMsgType + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                        <Select key={"_msgType"} style={{ width: '80%' }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4}
                            //placeholder="Please select"
                            //className={""}
                            //defaultValue={1}
                            onChange={(value) => {
                                let r = this.state.request;
                                this.setState({ request: { ...r, _msgType: value.join(',') } });
                            }} //this.setReportParameters("SalesItems", { _msgType: value.join(',') })}
                            value={(this.state.request._msgType === '') ? [] : this.state.request._msgType.split(',')}
                            notFoundContent={this.getText(12381)}
                        >
                            {listMsgType}
                        </Select>
                    </FormItem>

                    <FormItem label={this.getText(12374)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        {this.createCheckBox('_preDefineType', Jax.get(this.props.data, TableIDs.genericSelectorPreDefinedMsg + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                        <Select key={"_preDefineType"} style={{ width: '80%' }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4}
                            //placeholder="Please select"
                            //className={""}
                            //defaultValue={1}
                            onChange={(value) => {
                                let r = this.state.request;
                                this.setState({ request: { ...r, _preDefineType: value.join(',') } });
                            }} //this.setReportParameters("SalesItems", { _preDefineType: value.join(',') })}
                            value={(this.state.request._preDefineType === '') ? [] : this.state.request._preDefineType.split(',')}
                            notFoundContent={this.getText(12382)}
                        >
                            {listPreDef}
                        </Select>
                    </FormItem>

                    <FormItem label={this.getText(12366)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        {this.createCheckBox('_sentSuccessfully', sentSuccessfullyTypes.map((obj, index) => obj['code']).join(','))}
                        <Select key={"_sentSuccessfully"} style={{ width: '80%' }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4}
                            onChange={(value) => {
                                let r = this.state.request;
                                this.setState({ request: { ...r, _sentSuccessfully: value.join(',') } });
                            }}
                            value={(this.state.request._sentSuccessfully === '') ? [] : this.state.request._sentSuccessfully.split(',')}
                            notFoundContent={this.getText(12382)}
                        >
                            {listSentSuccessfully}
                        </Select>
                    </FormItem>

                    <FormItem label={this.getText(12375)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        <DatePicker
                            disabledDate={d => disabledDatePicker(d, null, _toDate)}
                            // showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            value={_fromDate ? moment(_fromDate, "YYYY-MM-DD") : null}
                            placeholder="Start"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                        />
                    </FormItem>
                    <FormItem label={this.getText(12376)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        <DatePicker
                            disabledDate={d => disabledDatePicker(d, _fromDate, null)}
                            // showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            value={_toDate ? moment(_toDate, "YYYY-MM-DD") : null}
                            placeholder="End"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                        />
                    </FormItem>
                </Form >
                <div className="action_footer">
                    <Button key="back" onClick={this.handleCancel}>{this.getText(12377)}</Button>
                    <Button key="clear" onClick={this.handleReset}>{this.getText(12378)}</Button>
                    <Button form="search_message_history_form" key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(12379)}</Button>
                </div>
            </Modal >
        )
    }

    // bind input field
    onChangeInput = (e) => {
        //this.props.dataActions.genericDataSetFilter(
        //    'ITEMS_TABLE', this.props.user.companyCode, this.props.user.token, { _words: e.target.value }, this.api.get)
        let r = { ...this.state.request };
        r._words = e.target.value;
        this.setState({ ...this.state, request: r });
        if (this.props.onChangeSearch) this.props.onChangeSearch(e)
    }

    handleFilterBlur = (e) => {
        //this.props.dataActions.genericDataSetFilter(
        //    'ITEMS_TABLE', this.props.user.companyCode, this.props.user.token, { _words: e.target.value }, this.api.get)
        let r = { ...this.state.request };
        r._words = e.target.value;
        this.setState({ ...this.state, request: r });
    }

    // tag select
    handleTagSelectChange = (e) => {
        //this.props.dataActions.genericDataSetFilter(
        //   'ITEMS_TABLE', this.props.user.companyCode, this.props.user.token, { _tagList: e.join(',') }, this.api.get)
        let r = { ...this.state.request };
        r._tagList = e.join(',');
        this.setState({ ...this.state, request: r });

    }
    /// search
    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log('Received values of form: ', values);
        });
    }

    handleReset = () => {
        if (this.props.setFilterActive) this.props.setFilterActive(false)
        else this.resetFilter()
    }
    resetFilter = () => {
        //this.props.form.resetFields();
        let _request = {
            _words: '',
            _toDate: null,
            _fromDate: null,
            _msgType: '',
            _preDefineType: '',
            _sentSuccessfully: '',

        };
        this.setState({ request: _request },
            () => {
                this.props.dataActions.genericDataSetFilter(
                    'MESSAGE_HISTORY_MAIN', this.props.user.companyCode, this.props.user.token, _request, this.api.get);
            }
        );
    }
}

export default ModalMessageHistoryAdvanceSearch;

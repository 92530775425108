/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Modal, Button, Form, Input, Select, message } from "antd";
import "../App.css";
import LangContext from "../contextProvider/LangContext";
import TableIDs from "../data/TableIDs";
import Jax from "../Jax/jax.es6.module";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";

const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

class ModalShareMessage extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            message: "",
            wtspWindow: null,
        }

        if (props.data[TableIDs.genericSelectorPreDefinedMsg] === undefined) {
            props.data[TableIDs.genericSelectorPreDefinedMsg] = { ...props.data.genericSelector };
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPreDefinedMsg, this.props.user.companyCode, this.props.user.token, "get_pre_defined_msg_list");

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    handleCancel = () => { this.props.uiActions.hideModal(TableIDs.ModalShareMessage) }

    handleOk = () => {
        let mobileCust = this.props.ui.data["customerPhone"] ? this.props.ui.data["customerPhone"].split("").map((x, i) => i ? x : "972").join("") : ""
        let link = "https://wa.me/" + mobileCust + "?text=" + this.state.message + " \n " + this.props.ui.data["dLink"];
        let wtspWindow = this.state.wtspWindow
        if (wtspWindow) wtspWindow.close();
        wtspWindow = window.open(link, 'wtspWindow');
        this.setState({ wtspWindow: wtspWindow });
        this.handleCancel();
    }

    mailingSelector = () => {
        let listPreDef = Jax.get(this.props.data, TableIDs.genericSelectorPreDefinedMsg + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let onChange = (value) => {
            this.sendAPI("get_message_def_value", value, (ob) => { this.setState({ message: ob.data }) });
        }
        return (<Select style={{ width: '100%' }} showSearch filterOption={true} optionFilterProp="valuefilter" onChange={onChange}>{listPreDef}</Select>)
    }

    sendAPI = (script, data, sCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, (e) => { message.error(e) });
    };

    render() {
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.getText(12924)}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        return (<Modal
            destroyOnClose={true}
            width={500}
            title={titleBar}
            visible={false || this.props.toggle}
            onCancel={this.handleCancel}
            footer={[
                <Button key="back" onClick={this.handleCancel}>{this.getText(12925)}</Button>,
                <Button key="send" type="primary" onClick={this.handleOk}>{this.getText(12926)}</Button>,
            ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <FormItem label={this.getText(12928)}>
                {this.mailingSelector()}
            </FormItem>
            <FormItem label={this.getText(12927)}>
                <TextArea rows={5} value={this.state.message} onChange={(v) => { this.setState({ message: v.target.value }) }} />
            </FormItem>
        </Modal >)
    }
}

export default ModalShareMessage;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, Select, Checkbox, Radio, DatePicker } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import GenericSelector from "../../components/GenericSelector";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const { MonthPicker } = DatePicker;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const RadioGroup = Radio.Group;

class PosTurnover extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            PosTurnover: {
                PosId: '999',
                FromDate: null,
                ToDate: null,
                IsMonthly: 1,
                MonthReport: null,
                YearReport: null,
                CalcMethod: '1',
                reportName: "reports/PosTurnover.pdf",
            }
        };

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    componentDidMount() {
        let d = new Date;
        let backMonth = d.getMonth()
        let year = d.getFullYear()
        if (!backMonth) {
            backMonth = 12;
            year--;
        }
        if (backMonth < 10) backMonth = '0' + backMonth
        this.setReportParameters('PosTurnover', { MonthReport: backMonth, YearReport: year })
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(14250);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("PosTurnover", this.props.ui.favoriteParams)
        }

    }

    disabledStartDate = (FromDate) => {
        const ToDate = this.state.PosTurnover.ToDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.PosTurnover.FromDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    createDatePicker = (label, field) => {
        let value = this.state.PosTurnover[field];
        let minDate = field === "ToDate" ? this.state.PosTurnover.FromDate : null;
        let maxDate = field === "FromDate" ? this.state.PosTurnover.ToDate : null;
        return (<FormItem label={this.getText(label)}>
            <DatePicker disabledDate={d => disabledDatePicker(d, minDate, maxDate)} format="DD-MM-YYYY"
                value={value ? moment(value, "YYYY-MM-DD") : null}
                onChange={(v) => {
                    this.setReportParameters("PosTurnover", { [field]: v ? v.format("YYYY-MM-DD") : null })
                }} />
        </FormItem>)
    }

    createMonthlyDatePicker = () => {
        let value = "" + this.state.PosTurnover.MonthReport + this.state.PosTurnover.YearReport;
        return (<MonthPicker
            format="MM-YYYY"
            value={value ? moment(value, "MMYYYY") : null}
            onChange={(v) => {
                this.setReportParameters("PosTurnover", {
                    MonthReport: v ? v.format("MM") : null,
                    YearReport: v ? v.format("YYYY") : null,
                })
            }} />)
    }

    render() {
        const { PosTurnover } = this.state;

        console.log('PosTurnover', PosTurnover);

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;
        let monthlyStyle = (monthly) => {
            return { display: PosTurnover.IsMonthly == monthly ? 'block' : 'none' }
        }

        let datesToFavorites = PosTurnover.IsMonthly == 1 ?
            [{ fields: ['MonthReport', 'YearReport'], label: this.getText(14254), format: 2 }]
            : [
                { field: 'FromDate', label: this.getText(14252) },
                { field: 'ToDate', label: this.getText(14253) },
            ]

        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(14251)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '60%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '2', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(14256)}>
                            <GenericSelectorPosStorages
                                // id={TableIDs.genericSelectorPos} api={"list_pos"}
                                value={PosTurnover.PosId}
                                onChange={(data) => { this.setReportParameters("PosTurnover", { PosId: data }) }}
                                user={this.props.user} dataActions={this.props.dataActions} data={this.props.data} />
                        </FormItem>

                        <FormItem>
                            <Checkbox
                                checked={PosTurnover.IsMonthly == 1}
                                onChange={(e) => { this.setReportParameters("PosTurnover", { IsMonthly: e.target.checked ? 1 : 0 }) }}
                            >{this.getText(14255)}</Checkbox>
                        </FormItem>


                        <Row style={monthlyStyle(0)}>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14252, "FromDate")}</Col>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14253, "ToDate")}</Col>
                        </Row>


                        <FormItem label={this.getText(14254)} style={monthlyStyle(1)}>
                            {this.createMonthlyDatePicker()}
                        </FormItem>

                        <FormItem label={this.getText(14257)}>
                            <RadioGroup
                                value={this.state.PosTurnover.CalcMethod}
                                onChange={(e) => { this.setReportParameters("PosTurnover", { CalcMethod: e.target.value }) }}>
                                <Radio value={'1'}>{this.getText(14258)}</Radio><br />
                                <Radio value={'2'}>{this.getText(14259)}</Radio>
                            </RadioGroup>
                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.PosTurnover}
                            datesToFavorites={datesToFavorites}
                            favoriteSave
                        />
                        {/* 
                        <FormItem key={"reportName"} label={this.getText(12159)}>
                            <Select style={{ width: 250 }}
                                className={"reports/PosTurnover.pdf"}
                                defaultValue={"pdf"}
                                onChange={(value) => {
                                    this.setReportParameters("PosTurnover", {
                                        reportName: "reports/PosTurnover." + value,
                                    });
                                }}
                            >
                                <Option key={"pdf"}>{this.getText(12160)}</Option>
                                <Option key={"xls"}>{this.getText(12161)}</Option>
                                <Option key={"docx"}>{this.getText(12162)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem key={"submit"}>
                            <Button type={"primary"} onClick={() => {
                                this.generateReport.apply(this, ["PosTurnover"])
                            }}>{this.getText(12163)}</Button>
                        </FormItem> */}
                    </Form>
                </Card>
            </div>
        );
    }
}
export default PosTurnover;




import React from "react";
import GenericSelector from "./GenericSelector";
import LangContext from "../contextProvider/LangContext";

class GenericSelectorSuppliers extends React.Component/*<Props, State>*/ {
    constructor(props) {
        super(props);
        this.id = 'GENERIC_SELECTOR_SUPPLIERS';
        this.api = 'get_suppliers'
        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericSelector }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        let gt = this.getText;

        return (<GenericSelector
            {...this.props}
            id={this.id}
            api={this.props.notApi ? undefined : this.api}
            modalStyle={{
                title: gt(this.props.multi ? 16512 : 16511),
                col1Title: gt(16513),
                col2Title: gt(16514),
                cancelText: gt(16515),
                clearText: gt(16516),
            }}
        />)
    }
}

export default GenericSelectorSuppliers;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
import "../../custOrder.css";
import { Row, Col, Spin } from "antd";
import moment from "moment";
import ResponsiveTable from "../../components/ResponsiveTable";
import FormLinkStyle from "../../forms/FormLinkStyle";

// const { Panel } = Collapse;

class OrderStatusToCustomer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            ownerID: null,
            sectionView: 3,
            linkToDoc: null,
        }

        this.id = "EXTERNAL_ORDERS_DETAILS";
        this.cart_id = "EXTERNAL_ORDER_LINES";
        this.statuses_id = "EXTERNAL_ORDER_STATUSES";
        this.comp_id = "COMPANY_DETAILS_DATA";

        if (props.data[this.id] === undefined) props.data[this.id] = {};
        if (props.data[this.cart_id] === undefined) props.data[this.cart_id] = { data: [] };
        if (props.data[this.statuses_id] === undefined) props.data[this.statuses_id] = { data: [] };
        if (props.data[this.comp_id] === undefined) props.data[this.comp_id] = {};


        this.api = {
            get: "get_external_order_details",
            get_cart: "get_external_order_lines",
            get_statuses: "get_external_order_statuses",
            get_details: "get_company_details_to_form",
            get_doc_link: "get_doc_link_from_order",
        }
    }

    componentDidUpdate(p) {

        if (!this.state.ownerID && this.props.data.formData.params) {
            let params = {}

            this.props.data.formData.params.split('&').forEach((x) => {
                let y = x.split('=');
                params = { ...params, [y[0]]: y[1] }
            });

            this.props.sendAPI(this.api.get_details, '', (ob3) => {
                let d = ob3.data.split("\f");
                this.props.setJson(this.comp_id, {
                    name: d[0],
                    desc: d[1],
                    // address1: d[2],
                    // address2: d[3],
                    // address3: d[4],
                    // telephone: d[5],
                    // term: d[6],
                    logo: d[7],
                    // about: d[8],
                    topBanner: d[9],
                    bootomBanner: d[10],
                });
            }, (e) => {
                console.error(e)
            });

            let eCallback = (e) => { console.error(e) }

            if (params.ownerID) {

                this.setState({ ownerID: params.ownerID }, () => {
                    this.props.sendAPI(this.api.get, params.ownerID, this.setDetails, eCallback);
                    this.props.sendAPI(this.api.get_cart, 'oID\r' + params.ownerID, this.setCart, eCallback);
                    this.props.sendAPI(this.api.get_statuses, params.ownerID, this.setStatuses, eCallback);
                    this.props.sendAPI(this.api.get_doc_link, params.ownerID, this.setDocLink, eCallback);
                })
            }

        }
    }

    setDetails = (ob) => {
        if (ob && ob.data) {
            let sData = ob.data.split("\r");
            let obj = {};
            let headers = sData[0] ? sData[0].split('\f') : [];
            let values = sData[1] ? sData[1].split('\f') : [];
            headers.forEach((x, i) => {
                obj = { ...obj, [x]: values[i] }
            });
            this.props.setJson(this.id, obj);
        }
    }

    setCart = (ob) => {
        if (ob && ob.data) {
            let sData = ob.data.split("\r");
            let headers = sData[0] ? sData[0].split('\f') : [];
            let data = sData[1] ? sData.slice(1, -1) : [];
            let count = data.length;
            this.props.setJson(this.cart_id, { data, headers, count });
        }
    }

    setStatuses = (ob) => {
        if (ob && ob.data) {
            let sData = ob.data.split("\r");
            let list = [];
            sData.forEach((x) => {
                let y = x.split('\f');
                let isDelivery = this.props.data[this.id].eMethodNeedAddress == '1';
                let newRow = { time: y[2], code: y[0] }
                let prepared = { ...newRow, name: this.props.getText(90024), icon: "muhan_icon" }
                switch (y[0]) {
                    case '1': newRow = { ...newRow, name: this.props.getText(90020), icon: "itkabel_icon" }; break;
                    case '3': newRow = { ...newRow, name: this.props.getText(90021), icon: "beahana_icon" }; break;
                    case '4': newRow = isDelivery ? { ...newRow, name: this.props.getText(90022), icon: "car_icon" } : prepared; break;
                    case '5': newRow = isDelivery ? { ...newRow, name: this.props.getText(90023), icon: "muhan_icon" } : prepared; break;
                }

                let dateIndex = list.findIndex(f => f.date === y[1]);

                if (dateIndex > -1) {
                    list[dateIndex] = { ...list[dateIndex], data: [...list[dateIndex].data, newRow] }
                } else {
                    list.push({ date: y[1], data: [newRow] })
                }
            })
            this.props.setJson(this.statuses_id, { data: list });
        }
    }

    setDocLink = (ob) => {
        if (ob.data) {
            this.props.sendAPI("get_document_report_link", ob.data, (ob1) => {
                this.setState({ linkToDoc: ob1.data });
            }, (e) => { console.error(e) });
        }
    }

    statusesView = () => {
        let data = this.props.data[this.statuses_id].data;
        let lastDate = data.slice(-1)[0];
        let curStatus = lastDate ? lastDate.data.slice(-1)[0] : {};

        return data.map((x) => {
            let dateText = x.date;
            let curdate = new Date();
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            if (dateText === moment(curdate).format("YYYY-MM-DD")) {
                dateText = this.props.getText(90018);
            } else if (dateText === moment(yesterday).format("YYYY-MM-DD")) {
                dateText = this.props.getText(90019);
            }

            let isDelivery = this.props.data[this.id].eMethodNeedAddress == '1';
            let curOver = parseInt(curStatus.code);
            let widthFull = window.innerWidth < 700 ? window.innerWidth : 700
            let widthTransView = (widthFull - 90) / (isDelivery ? 7 : 5);
            let widthProgresGreen = (widthTransView / 2) - 10;
            if (curOver >= 3) widthProgresGreen += (widthTransView * 1.8) + 20;
            if (curOver >= 4 && isDelivery) widthProgresGreen += (widthTransView * 1.8) + 20;
            if (curOver >= 5) widthProgresGreen += (widthTransView * 1.8) + 20;



            return (<div style={{ textAlign: 'center' }}>
                {/* <div style={{ fontSize: 60, fontWeight: 'bold', padding: 30 }}>
                    <Icon type={curStatus.icon} style={{ margin: 10 }} />
                    {curStatus.name}
                </div> */}
                <div style={{ direction: 'ltr', marginBottom: 10 }}>
                    <div className={"progres_green"} style={{ width: widthProgresGreen, height: 10 }} />
                    <div className={"progres_but"} style={{ width: 30, height: 30 }} />
                    <div className={"progres_red"} style={{ width: (widthFull - widthProgresGreen) - 90, height: 10 }} />
                </div>
                <div style={{ direction: 'ltr', marginBottom: 25 }}>
                    <div className={"step1_" + (curOver == 1 ? "red" : "green")} style={{ width: widthTransView, height: widthTransView }} />
                    <div className={"trans_" + (curOver == 1 ? "gray" : "green")} style={{ width: widthTransView * 0.8, height: widthTransView / 20 }} />
                    <div className={"step2_" + (curOver == 3 ? "red" : curOver > 3 ? "green" : "gray")} style={{ width: widthTransView, height: widthTransView }} />
                    <div className={"trans_" + (curOver <= 3 ? "gray" : "green")} style={{ width: widthTransView * 0.8, height: widthTransView / 20 }} />
                    {isDelivery ? [
                        <div className={"step3_" + (curOver == 4 ? "red" : curOver > 4 ? "green" : "gray")} style={{ width: widthTransView, height: widthTransView * 0.75 }} />,
                        <div className={"trans_" + (curOver <= 4 ? "gray" : "green")} style={{ width: widthTransView * 0.8, height: widthTransView / 20 }} />
                    ] : ""}
                    <div className={"step4_" + (curOver == 5 ? "red" : curOver > 5 ? "green" : "gray")} style={{ width: widthTransView, height: widthTransView * 0.6 }} />
                </div>
                <span style={{ fontSize: 10, fontWeight: 'bold', padding: 5, margin: 2, border: '1px solid black', borderRadius: 5 }}>{dateText}</span>
                <br />
                <br />
                {x.data.map((y) => {
                    return (<div className={"text_item"} style={{ height: 70, width: 350, textAlign: 'right' }}>
                        <Row>
                            <Col span={4}><div className={y.icon} /></Col>
                            <Col span={10}><h3>{y.name}</h3></Col>
                            <Col span={10}><h4>{y.time}</h4></Col>
                        </Row>
                    </div>)
                })}
            </div>)
        })
    }

    // getTitle = (title, key, content) => {
    //     let isActive = this.state.sectionView === key;
    //     let onClick = () => { this.setState({ sectionView: isActive ? null : key }) }
    //     return (<Panel key={key} header={(
    //         <div className={"text_item"} onClick={onClick}>
    //             <h2>{this.props.getText(title)}</h2>
    //             <div className={isActive ? 'arrowdown_icon' : 'arrow_icon'} />
    //         </div>)}>{content}</Panel>)
    // }

    getField = (label, field, isDateTime) => {
        let dData = this.props.data[this.id];
        let val = dData[field]
        if(isDateTime) val = moment(val).format("HH:mm DD/MM/YYYY")

        return (<Row style={{
            border: "0.5px solid #bbbbbb",
            // width: window.innerWidth > 1000 ? 500 : "95%" 
        }}>
            <Col span={10} style={{ background: '#dddddd', padding: 5, minHeight: 30 }}><span className={'text-item'}>{this.props.getText(label)}</span></Col>
            <Col span={14} style={{ minHeight: 30, padding: 5, }}><span className={'text-item'}>{val}</span></Col>
        </Row>)
    }

    itemDetails = (text, record) => {
        //iGroup, iCode, iName, iPrice, iFoodDishId, iLevelJson, iOriginalPrice, iFatherCode, iColorID, iColorDesc, iSize1ID, iSize2ID, iSizeDesc, iQty, iMivza, iTotalSum   
        if (record.iFoodDishId && record.iLevelJson) {
            let dataObj = JSON.parse(record.iLevelJson);
            return dataObj.levels.map((l) => {
                return (<div className={'text_item'} style={{ marginTop: 5, padding: 5 }}>
                    <div style={{ fontSize: 17, fontWeight: 600 }}>{l.name}</div>
                    <div style={{ fontSize: 15 }}>
                        {l.ownerData.map((o) => {
                            return (<div>
                                <div>{o.oItemName}</div>
                                <div>{o.oExtraItems ? this.sortExtraItems(o.oExtraItems) : ""}</div>
                            </div>)
                        })}
                    </div>
                </div>)
            })
        } else if (record.iSizeDesc || record.iColorDesc) {
            let sDiv = record.iSizeDesc ? (<div>{this.props.getText(90051)}: {record.iSizeDesc}</div>) : ""
            let cDiv = record.iColorDesc ? (<div>{this.props.getText(90052)}: {record.iColorDesc}</div>) : ""
            return (<div className={'text_item'} style={{ fontSize: 20, lineHeight: 1.2, minHeight: 40 }}>{sDiv}{cDiv}</div>)
        } else {
            return ''
        }
    }

    sortExtraItems = (e) => {
        let gt = this.props.getText;

        let eType2 = e.filter(f => f.eType == 2);
        let eType3 = e.filter(f => f.eType == 3);
        let eType4 = e.filter(f => f.eType == 4);

        return (<div>
            {eType4[0] ? (<div>
                <u>{gt(90048)}:</u>
                {eType4.map((et, ii) => (<span>{' ' + et.eItemName}{(ii === eType4.length - 1) ? "." : ","}</span>))}
            </div>) : ""}
            {eType2[0] ? (<div>
                <u>{gt(90049)}:</u>
                {eType2.map((et, ii) => (<span>{' ' + et.eItemName}{(ii === eType2.length - 1) ? "." : ","}</span>))}
            </div>) : ""}
            {eType3[0] ? (<div>
                <u>{gt(90050)}:</u>
                {eType3.map((et, ii) => (<span>{' ' + et.eItemName}{(ii === eType3.length - 1) ? "." : ","}</span>))}
            </div>) : ""}
        </div>)
    }

    getCartTable = () => {
        let gt = this.props.getText;
        let txt = (text) => text;

        let columns = [
            { title: gt(90041), key: 'iCode', dataIndex: 'iCode', width: '10%', render: txt },
            { title: gt(90042), key: 'iName', dataIndex: 'iName', width: '20%', render: txt },
            { title: gt(90047), width: '30%', render: this.itemDetails },
            { title: gt(90043), key: 'iPrice', dataIndex: 'iPrice', width: '10%', render: this.sumFormat },
            { title: gt(90044), key: 'iQty', dataIndex: 'iQty', width: '10%', render: this.sumFormat },
            { title: gt(90046), key: 'iMivza', dataIndex: 'iMivza', width: '10%', render: this.sumFormat },
            { title: gt(90045), key: 'iTotalSum', dataIndex: 'iTotalSum', width: '10%', render: this.sumFormat },
        ];

        return (<div className={'cart_title'}>
            <ResponsiveTable
                idTable={this.props.data[this.cart_id]}
                columns={columns}
                scroll={{ y: window.innerHeight - 300 }}
                tableOnly
                getText={gt}
                percentageToMobile={[0, 40, 0, 0, 30, 0, 30]}
            />
        </div>)
    }

    sumFormat = (n) => {
        return n ? (Math.round(parseFloat(n) * 100) / 100).toFixed(2) : '0.00'
    }

    render() {
        let cData = this.props.data[this.comp_id];
        let gt = this.props.getText;

        // let headerStyle = (<div className={"logo_frame"}>
        //     <h1 style={{ textAlign: 'center',  padding: 10 }}>
        //         <img src={cData.logo} alt={'Logo'} title={cData.name} />
        //         <br />
        //         {cData.name + (cData.desc && cData.desc !== cData.name ? " - " + cData.desc : "")}
        //         <br />

        //     </h1>
        // </div>)

        let deliveryRemarks = this.props.data[this.id].eDeliveryRemarks;

        let orderDetails = (<Row>
            <Col span={window.innerWidth > 1000 ? 8 : 24}>
                {/* {this.getField(90025, 'eNum')} */}
                {this.getField(90026, 'eDateTime', true)}
                {this.getField(90027, 'eRequieredDateTime', true)}
                {this.getField(90028, 'eMethodName')}
                {this.getField(90037, 'eTotal')}
                {this.getField(90038, 'eRemainingPayment')}
                {this.getField(90039, 'ePaymentName')}
            </Col>
            <Col span={window.innerWidth > 1000 ? 8 : 24}>
                {this.getField(90029, 'eCustomerName')}
                {this.getField(90030, 'eDeliveryPhone')}
                {this.getField(90031, 'eDeliveryCity')}
                {this.getField(90032, 'eDeliveryStreet')}
                {this.getField(90033, 'eDeliveryBuilding')}
                {this.getField(90034, 'eDeliveryEntrance')}
                {this.getField(90036, 'eDeliveryApartement')}
                {this.getField(90035, 'eDeliveryFloor')}
            </Col>
            <Col span={window.innerWidth > 1000 ? 8 : 24}
            // style={{ /*width: window.innerWidth > 1000 ? 500 : "95%",*/ padding: 10 }}
            >
                <div style={{ background: '#dddddd', minHeight: 30, padding: 5, }}>
                    <span className={'text-item'}>{this.props.getText(90040)}</span>
                </div>
                <div style={{ border: "0.5px solid #bbbbbb", background: 'white', padding: 5, margin: 5 }}>
                    {deliveryRemarks ? deliveryRemarks.split("|").map(x => (<div style={{ padding: 5 }}>{x}</div>)) : ""}
                </div>
            </Col>
        </Row>);

        let sections = [
            { title: gt(90015), key: 1, content: orderDetails },
            { title: gt(90016), key: 2, content: this.getCartTable() },
            { title: gt(90017), key: 3, content: this.statusesView() },
        ];

        let eNum = this.props.data[this.id].eNum;

        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
       

        return eNum ? (<FormLinkStyle
            topBanner={cData.topBanner}
            bootomBanner={cData.bootomBanner}
            defaultSection={3}
            compData={this.props.data[this.comp_id]}
            formTitle={gt(90053) + " " + eNum}
            sections={sections}
            linkToDoc={this.state.linkToDoc ? { title: gt(90054), key: 4, link: this.state.linkToDoc } : null}
        />)
            : (<div style={{ paddingTop: window.innerHeight / 3, textAlign: 'center' }}>
                <img src={(isDev ? "/cash-on-tab/build/" : "/") + "img/logo.png"} />
                <br /><br /><Spin />
            </div>)
    }
}

export default OrderStatusToCustomer;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Radio, InputNumber, Select, Form, Row, Col, message } from "antd";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from '../../data/TableIDs.js';
//import ResponsiveTable from "../../components/ResponsiveTable";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";
import GenericTable from "../../components/GenericTable";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

class CreditTerms extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalCreateEdit: null
        }

        this.id = "CREDIT_TERMS_TABLE";

        this.selectors = [
            { id: TableIDs.genericSelectorCerditCardCompanies, api: "get_credit_card_companies_list" },
            { id: "CREDIT_TYPES_LIST", api: "get_credit_types_list" },
        ];

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector }; })

        this.api = {
            get: "get_credit_terms_table",
            delete: "delete_credit_terms",
            create_edit: "create_edit_credit_terms",
        }

        this.actionButtons = [{
            buttonType: 'add',
            buttonFunction: this.createNew
        }];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15282);
    }

    // componentDidMount() {
    //     this.refreshData();
    // }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    createNew = () => {
        let newOb = {}
        this.props.data[this.id].headers.forEach(x => { newOb = { ...newOb, [x]: '' } })
        this.setState({ modalCreateEdit: newOb });
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: null });
    }

    handleOk = () => {
        let m = this.state.modalCreateEdit;
        if (!m.sCardCompanyID || !m.sDealType || !m.sType) {
            message.error(this.getText(15316));
        } else {
            let keys = Object.keys(m);
            let dataSend = keys.join('\f') + '\r' + keys.map(x => m[x] ? m[x] : '').join('\f');
            this.sendAPI(this.api.create_edit, dataSend, (ob) => {
                if (ob.data === '1') {
                    this.refreshData();
                    this.handleCancel();
                    message.success(this.getText(15291));
                } else if (ob.data === '0') {
                    message.error(this.getText(15314));
                } else {
                    this.handleCancel();
                    message.error(this.getText(15315));
                }
            });
        }
    }

    getTypeDetails = (text, record) => {
        let gt = this.getText;
        let gf = (f) => parseInt(record[f]) ? parseInt(record[f]) : 0;
        let spaceText = (arr) => arr.join(' ');

        switch (record.sType) {
            case '1':
                return (<div>
                    <div>
                        {spaceText([
                            gt(15295),
                            gf('sRegularTransFromFirstToDay') + ',',
                            gt(15296) + gf('sRegularTransPeriod1PayDate'),
                            gt(gf('sRegularTransPeriod1PayMonth') == '1' ? 15297 : 15298) + '.'
                        ])}
                    </div>
                    <div>
                        {spaceText([
                            gt(15299) + gf('sRegularTransFromSecondDay'),
                            gt(15300) + ',',
                            gt(15296) + gf('sRegularTransPeriod2PayDate'),
                            gt(15298) + '.'
                        ])}
                    </div>
                </div>)

            case '2': return spaceText([gt(15301), gf('sDaysToPay'), gt(15302) + '.']);
            case '3': return spaceText([gt(15303), gf('sNextMonthPayDay'), gt(15298) + '.']);
            case '4': return spaceText([gt(15304), gt(gf('sNextWeekPayDay') + 15304), gt(15312) + '.']);
            default: return '';
        }
    }

    getTypeDetailsModal = (type) => {
        let record = this.state.modalCreateEdit ? this.state.modalCreateEdit : {}
        let gt = this.getText;

        let gNumber = (f) => {
            return (<InputNumber
                min={record[f] ? 1 : undefined}
                max={f === 'sDaysToPay' ? undefined : 31}
                style={{ width: 60 }}
                value={parseInt(record[f]) ? parseInt(record[f]) : null}
                onChange={(v) => { this.onChange(f, v) }} />)
        };

        let gSelect = (f, list) => {
            return (<Select
                value={record[f]}
                onChange={(v) => { this.onChange(f, v) }}
                style={{ width: 150 }}
                placeholder={gt(15313)}>
                {list.map(x => (<Select.Option value={x.id}>{gt(x.text)}</Select.Option>))}
            </Select>)
        };

        let mList = [
            { id: '1', text: 15297 },
            { id: '2', text: 15298 },
        ];

        let wList = [
            { id: '1', text: 15305 },
            { id: '2', text: 15306 },
            { id: '3', text: 15307 },
            { id: '4', text: 15308 },
            { id: '5', text: 15309 },
            { id: '6', text: 15310 },
            { id: '7', text: 15311 },
        ];

        switch (type) {
            case '1':
                return (<span>
                    {gt(15295)} {gNumber('sRegularTransFromFirstToDay')}, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {gt(15296)}{gNumber('sRegularTransPeriod1PayDate')} {gSelect('sRegularTransPeriod1PayMonth', mList)}
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {gt(15299)}{gNumber('sRegularTransFromSecondDay')} {gt(15300)}, &nbsp;&nbsp;
                    {gt(15296)}{gNumber('sRegularTransPeriod2PayDate')} {gt(15298)}.
                </span>);

            case '2': return (<span>{gt(15301)} {gNumber('sDaysToPay')} {gt(15302)}.</span>);
            case '3': return (<span>{gt(15303)} &nbsp;&nbsp;&nbsp; {gNumber('sNextMonthPayDay')} {gt(15298)}.</span>);
            case '4': return (<span>{gt(15304)} {gSelect('sNextWeekPayDay', wList)} {gt(15312)}.</span>);

            default: return '';
        }
    }

    onChange = (field, value) => {
        let ob = { ...this.state.modalCreateEdit, [field]: value }
        if (field === 'sType') {
            switch (value) {
                case '1':
                    ob = {
                        ...ob,
                        sRegularTransFromFirstToDay: 10,
                        sRegularTransFromSecondDay: 11,
                        sRegularTransPeriod1PayDate: 1,
                        sRegularTransPeriod1PayMonth: '2',
                        sRegularTransPeriod2PayDate: 1,
                        sDaysToPay: '',
                        sNextWeekPayDay: '',
                        sNextMonthPayDay: ''
                    }
                    break;
                case '2':
                    ob = {
                        ...ob,
                        sRegularTransFromFirstToDay: '',
                        sRegularTransFromSecondDay: '',
                        sRegularTransPeriod1PayDate: '',
                        sRegularTransPeriod1PayMonth: '',
                        sRegularTransPeriod2PayDate: '',
                        sDaysToPay: 10,
                        sNextWeekPayDay: '',
                        sNextMonthPayDay: ''
                    }
                    break;
                case '3':
                    ob = {
                        ...ob,
                        sRegularTransFromFirstToDay: '',
                        sRegularTransFromSecondDay: '',
                        sRegularTransPeriod1PayDate: '',
                        sRegularTransPeriod1PayMonth: '',
                        sRegularTransPeriod2PayDate: '',
                        sDaysToPay: '',
                        sNextWeekPayDay: '',
                        sNextMonthPayDay: 1
                    }
                    break;
                case '4':
                    ob = {
                        ...ob,
                        sRegularTransFromFirstToDay: '',
                        sRegularTransFromSecondDay: '',
                        sRegularTransPeriod1PayDate: '',
                        sRegularTransPeriod1PayMonth: '',
                        sRegularTransPeriod2PayDate: '',
                        sDaysToPay: '',
                        sNextWeekPayDay: '1',
                        sNextMonthPayDay: ''
                    }
                    break;
            }
        }
        this.setState({ modalCreateEdit: ob })
    }

    getEditIcons = (text, record) => {
        let onEdit = () => { this.setState({ modalCreateEdit: record }) }
        let onDelete = () => {
            this.props.dataActions.genericDataDeleteRowWithParams(
                this.id, { cID: record.sCardCompanyID, tID: record.sDealType }, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
        }
        let deleteQuetion = { title: this.getText(15288), okText: this.getText(15289), cancelText: this.getText(15290) }

        return (<TableLineIcons onEdit={onEdit} onDelete={onDelete} deleteQuetion={deleteQuetion} />)
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            //whiteSpace: "unset",
        };

        let gt = this.getText;
        let rt = (text) => text;

        let columns = [
            { title: gt(15284), key: "sCardCompanyName", dataIndex: "sCardCompanyName", width: '16%', sorter: true, render: rt },
            { title: gt(15285), key: "sDealTypeName", dataIndex: "sDealTypeName", width: '16%', sorter: true, render: rt },
            { title: gt(15286), width: '60%', render: this.getTypeDetails },
            { title: gt(15287), width: '8%', render: this.getEditIcons },
        ];

        let mData = this.state.modalCreateEdit ? this.state.modalCreateEdit : {}

        let cardCompanyField = mData.sCardCompanyName;
        let dealTypeField = mData.sDealTypeName;
        let typeField = (<Radio.Group value={mData.sType} onChange={(v) => { this.onChange('sType', v.target.value) }}>
            {['1', '2', '3', '4'].map(x => (<span><Radio style={{ marginBottom: 5 }} value={x}>{this.getTypeDetailsModal(x)}</Radio><br /></span>))}
        </Radio.Group>)

        if (!mData.key) {
            cardCompanyField = (<GenericSelector value={mData.sCardCompanyID} onChange={(v) => { this.onChange('sCardCompanyID', v) }} {...this.selectors[0]} {...this.props} />);
            dealTypeField = (<GenericSelector value={mData.sDealType} onChange={(v) => { this.onChange('sDealType', v) }} {...this.selectors[1]} {...this.props} />);
        }

        return (<div style={divStyle}>
            <GenericTable
                id={this.id}
                getApi={this.api.get}
                title={gt(15283)}
                actionButtons={this.actionButtons}
                columns={columns}
                percentageToMobile={[50, 50]}
                {...this.props}
            />
            <GenericModal
                visible={this.state.modalCreateEdit!==null}
                onCancel={this.handleCancel}
                title={gt(15292)}
                width={700}
                footer={[
                    <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{gt(15293)}</Button>,
                    <Button key={"create"} type="primary" onClick={this.handleOk} style={{ margin: 5 }}>{gt(15294)}</Button>
                ]}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>
                        <Form.Item label={gt(15284)}>{cardCompanyField}</Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>
                        <Form.Item label={gt(15285)}>{dealTypeField}</Form.Item>
                    </Col>
                </Row>
                <Form.Item label={gt(15286)}>{typeField}</Form.Item>
            </GenericModal>
        </div>)
    }
}
export default CreditTerms;
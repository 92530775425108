import React, { Component } from "react";
import "../App.css";
import { Modal, Card, Button, Input, Icon, Popconfirm, message } from "antd";
import PageToolbar from "./PageToolbar";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';

const Search = Input.Search;

class ImageSearch extends Component<Props, State> {
    state = {
        imageSearch: false,
        imageSearchValue: '',
        googleBilling: 0,
        googleItems: null,
        nextPage: null,
        previousPage: null,
    };

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI("use_google_images", "isUse\r0", (ob) => {
            if (ob.data) {
                this.setState({ googleBilling: parseInt(ob.data) })
            }
        });
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    googleApiBilling = (q, i) => {
        this.sendAPI("use_google_images", "isUse\r1", (ob) => {
            if (ob.data) {
                this.setState({ googleBilling: parseInt(ob.data) }, () => {
                    if (parseInt(ob.data) > 0) {
                        this.googleApi(q, i);
                    } else {
                        message.error(this.getText(13992));
                    }
                })
            } else {
                message.error(this.getText(13992));
            }
        });
    }

    googleApi = (q, i) => {
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                let json = JSON.parse(xmlHttp.response);

                let getStartIndex = (field) => json.queries[field] && json.queries[field][0].startIndex <= 100 ?
                    json.queries[field][0].startIndex : null

                this.setState({
                    googleItems: json.items,
                    nextPage: getStartIndex('nextPage'),
                    previousPage: getStartIndex('previousPage')
                });
            }
        }
        let key = "AIzaSyAkXPjTCk3mXYwFp39_rsN9zjS855CzICY";
        let cx = "018122850742097613232:trki8auoui0";

        let url = "https://www.googleapis.com/customsearch/v1?key=" + key + "&cx=" + cx + "&q=" + q + (i ? "&start=" + i : "");

        xmlHttp.open('GET', url, true);
        xmlHttp.send();
    }

    getImageFormUrl = (url, callback) => {
        var img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = function (a) {
            var canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(this, 0, 0);

            var dataURI = canvas.toDataURL("image/jpg");

            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataURI.split(',')[1]);
            } else {
                byteString = unescape(dataURI.split(',')[1]);
            }

            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            let blobImage = new Blob([ia], { type: "image/jpg"/*mimeString */ });
            var formData = new FormData();
            formData.append('imageToUpload', blobImage);

            if (blobImage) {
                let reader = new FileReader();
                reader.readAsDataURL(blobImage);
                reader.onloadend = () => {
                    blobImage.name = 'itemImage.jpg'
                    callback({
                        fileBlob: blobImage,
                        base64: reader.result,
                    });
                }
            }
        }
        img.src = url;
    }

    closeModal = () => {
        this.setState({
            imageSearch: false,
            googleItems: null,
        }, () => {
            this.props.onChange(null);
        });
    }

    openModal = () => {
        if (this.state.googleBilling > 0) {
            this.setState({
                imageSearch: true,
                imageSearchValue: this.props.defaultValue ? this.props.defaultValue.trim() : ''
            }, () => {
                if (this.props.defaultValue) {
                    this.googleApiBilling(this.props.defaultValue)
                }
            })
        } else {
            message.error(this.getText(13992));
        }
    }


    render() {
        const { googleItems } = this.state;

        let imgSrc = googleItems ?
            googleItems.filter(x => x.pagemap && x.pagemap.cse_image && x.pagemap.cse_image[0] && x.pagemap.cse_image[0].src)
            : [];

        console.log('yuda2', imgSrc)

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(13597)}
                    actionButtons={[]}
                    isModal={true}
                />
            </div>);

        let popTitle = this.getText(14320);
        let popYes = this.getText(14321);
        let popNo = this.getText(14322);

        return (<div style={{ textAlign: 'center' }}>
            <Popconfirm title={popTitle} onConfirm={this.openModal} okText={popYes} cancelText={popNo}>
                <Button type="primary" disabled={this.props.disabled}>
                    <Icon type="google" style={{ marginLeft: 10 }} />
                    {this.getText(13599)}
                </Button>
            </Popconfirm>
            <Modal
                visible={this.state.imageSearch}
                onCancel={this.closeModal}
                width={window.innerWidth > 800 ? 750 : '95%'}
                title={titleBar}
                footer={[<Button key="Close" onClick={this.closeModal}>{this.getText(13598)}</Button>]}
            >
                <Button disabled={!this.state.previousPage} onClick={() => {
                    this.googleApiBilling(this.state.imageSearchValue, this.state.previousPage)
                }}><Icon type="right" /></Button>
                <Button disabled={!this.state.nextPage} onClick={() => {
                    this.googleApiBilling(this.state.imageSearchValue, this.state.nextPage)
                }}><Icon type="left" /></Button>
                <Search
                    placeholder={"חפש תמונה"}
                    value={this.state.imageSearchValue}
                    onChange={(e) => { this.setState({ imageSearchValue: e.target.value }) }}
                    onSearch={(value) => { this.googleApiBilling(value) }}
                    size={"large"}
                    enterButton
                />
                <div style={{ minHeight: 500 }}>
                    {imgSrc.map((x) => (
                        <Card
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'text-top',
                                margin: '1%',
                                width: '31%',
                                curser: 'pointer',
                            }}
                            onClick={() => {
                                this.getImageFormUrl(x.pagemap.cse_thumbnail[0].src, this.props.onChange);
                                this.closeModal();
                            }}>
                            {/* <Tooltip title={x.pagemap.cse_image[0].src}> */}
                            <img
                                id={x.cacheId}
                                src={x.pagemap.cse_image[0].src}
                                width={'100%'}
                            />
                            {/* </Tooltip> */}
                        </Card>
                    ))}
                </div>
            </Modal>
        </div >);
    }
}

export default ImageSearch;

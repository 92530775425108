/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import PaymentDailyReport from "./reports/PaymentsDailyReport";
import PaymentMonthlyReport from "./reports/PaymentsMonthlyReport";
import PaymentsAggregateReport from "./reports/PaymentsAggregateReport";
import PaymentsDetailedReport from "./reports/PaymentsDetailedReport";
import PaymentsShvaReport from "./reports/PaymentsShvaReport";
import PaymentsChecksReport from "./reports/PaymentsChecksReport";
import PaymentsCreditCardReport from "./reports/PaymentsCreditCardReport";
//import PaymentsSearch from "./reports/PaymentsSearchReport";
import PaymentsZReport from "./reports/PaymentsZReport";
import PaymentsRefunds from "./reports/PaymentsRefunds";
import PosTurnover from "./reports/PosTurnover";
import PaymentsAnalysis from "./reports/PaymentsAnalysis";
import CateringTickets from "./reports/CateringTickets";
import AccountingBranches from "./reports/AccountingBranches";
import GiftCardReport from "./reports/GiftCardReport";
import ReportMenuPayments from "../menus/ReportMenuPayments";
import TableIDs from '../data/TableIDs';

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object
};

type State = {};

// SalesItemsReport // SalesReport
class ReportsPayments extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }
       
        const routes = [
            (<Route key={61} path={this.props.baseUrl + "/reports_payments/payments-daily"} exact render={() => <PaymentDailyReport {...routeProps} />} />),
            (<Route key={62} path={this.props.baseUrl + "/reports_payments/payments-monthly"} exact render={() => <PaymentMonthlyReport {...routeProps} />} />),
            (<Route key={63} path={this.props.baseUrl + "/reports_payments/payment-aggregate"} exact render={() => <PaymentsAggregateReport {...routeProps} />} />),
            (<Route key={64} path={this.props.baseUrl + "/reports_payments/payment-detailed"} exact render={() => <PaymentsDetailedReport {...routeProps} />} />),
            (<Route key={65} path={this.props.baseUrl + "/reports_payments/payments-shva"} exact render={() => <PaymentsShvaReport {...routeProps} />} />),
            (<Route key={66} path={this.props.baseUrl + "/reports_payments/payments-checks"} exact render={() => <PaymentsChecksReport {...routeProps} />} />),
            (<Route key={67} path={this.props.baseUrl + "/reports_payments/payments-creditCard"} exact render={() => <PaymentsCreditCardReport {...routeProps} />} />),
            // (<Route key={68} path={this.props.baseUrl + "/reports_payments/payments-search"} exact render={() => <PaymentsSearch {...routeProps} />} />),
            (<Route key={69} path={this.props.baseUrl + "/reports_payments/payments-z-report"} exact render={() => <PaymentsZReport {...routeProps} />} />),
            (<Route key={70} path={this.props.baseUrl + "/reports_payments/payments-refunds"} exact render={() => <PaymentsRefunds {...routeProps} />} />),
            (<Route key={161} path={this.props.baseUrl + "/reports_payments/pos-turnover"} exact render={() => <PosTurnover {...routeProps} />} />),
            (<Route key={162} path={this.props.baseUrl + "/reports_payments/payments-analysis"} exact render={() => <PaymentsAnalysis {...routeProps} />} />),
            (<Route key={163} path={this.props.baseUrl + "/reports_payments/catering-tickets"} exact render={() => <CateringTickets {...routeProps} />} />),
            (<Route key={164} path={this.props.baseUrl + "/reports_payments/accounting-branches"} exact render={() => <AccountingBranches {...routeProps} />} />),
            (<Route key={165} path={this.props.baseUrl + "/reports_payments/gift-card-report"} exact render={() => <GiftCardReport {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/reports_payments"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <ReportMenuPayments baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default ReportsPayments;

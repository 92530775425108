/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../App.css";
import {  Icon, Tooltip, message, } from "antd";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';


class ViewDoc extends Component {
   
    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCall, eCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, eCall);
    };


    render() {
        const { docNum, docType, docPos, printFormat } = this.props
        return (<Tooltip title={this.getText(19768)}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"file-pdf"} onClick={() => {
                let dataSend = "docNum\fdocType\fdocPos\fprintFormat\r" + docNum + "\f" + docType + "\f" + docPos + "\f" + (printFormat == '1' ? '1' : '0')

                this.sendAPI("get_document_report_link", dataSend, (ob) => {
                    window.open(ob.data, "_blank")
                }, (e) => {
                    message.error(e)
                })
            }} />
        </Tooltip>)
    }
}
export default ViewDoc;


import React from "react";
import { Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import SelectorSalesAlerts from "./SelectorSalesAlerts";
import SelectorTaxType from "./SelectorTaxType";
import InputNumeric from "./InputNumeric";
import { fixCurrentPage, escapeRegExp } from "../utils/utils";

const { Option, OptGroup } = Select;
const { TextArea } = Input;
const codeInvalid = () => {
    message.error('קוד הפריט כבר קיים במסד הנתונים');
};
const codeValid = () => {
    message.success('קוד פריט פנוי');
};
const codeExists = () => {
    message.warning('קוד פריט כבר קיים במסד הנתונים אך בסטטוס לא פעיל');
};

//type State = {}
class ModalCreatePriceList extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = "CREATE_PRICE_LIST";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[TableIDs.genericSelectorTaxRegion] === undefined) {
            props.data[TableIDs.genericSelectorTaxRegion] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_priceList_list_paging",
            create: "create_priceList",
            edit: "edit_priceList",
            delete: "delete_priceList",
            selector: "get_tax_region_list",
        }

        this.actionButtons = [{
            buttonType: 'add',
            buttonFunction: this.handleCreateNew
        }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        },/* {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/]

        this.columns =
            [
                {
                    title: "מזהה",
                    dataIndex: "mId",
                    key: "mId",
                    sorter: true,
                    width: '10%',
                    render: (text, record) => text,
                }, {
                    title: 'שם',
                    dataIndex: "mName",
                    key: "mName",
                    width: '20%',
                    sorter: true,
                    render: (text, record) => {
                        if (this.props.data[this.id].editing === record.key) {
                            return (
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { mName: value.target.value });
                                    }} />);
                        } else {
                            return text;
                        }
                    },
                }, {
                    title: 'אזור מס',
                    dataIndex: "mRegionId",
                    key: "mRegionId",
                    width: '20%',
                    sorter: true,
                    render: (text, record) => {
                        let list = this.props.data[TableIDs.genericSelectorTaxRegion].dataset.map((obj, index) => {
                            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
                        });

                        return (
                            <Select
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                disabled={!(this.props.data[this.id].editing === record.key)}
                                value={(this.props.data[this.id].editing === record.key) ?
                                    this.props.data[this.id].editedRecord['mRegionId'] : text}
                                defaultValue=""
                                style={{ width: '100%', paddingLeft: '4px' }}
                                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { mRegionId: e }) }}
                            >
                                {list}
                            </Select>
                        )
                    },
                }, {
                    title: 'כולל מע"מ',
                    dataIndex: "mIsIncludeVat",
                    key: "mIsIncludeVat",
                    width: '15%',
                    sorter: true,
                    render: (text, record) => {
                        return (<Checkbox
                            onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsIncludeVat", record); }}
                            checked={this.checkIfChecked('mIsIncludeVat', text, record)}
                            defaultChecked={false}
                            disabled={!(this.props.data[this.id].editing === record.key)} />
                        )
                    }

                }, {
                    title: "עריכה",
                    key: "Settings",
                    width: '8%',
                    render: (text, record) => {
                        if (this.props.data[this.id].editing !== record.key) {
                            return (
                                <span>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                        this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                    }} />
                                    <Popconfirm title={"האם למחוק?"}
                                        onConfirm={() => {
                                            fixCurrentPage.bind(this)();
                                            this.props.dataActions.genericDataDeleteRow(
                                                this.id,
                                                record['mId'],
                                                this.props.user.companyCode,
                                                this.props.user.token,
                                                this.api.delete,
                                                this.api.get);
                                        }
                                        }
                                        okText={"כן"} cancelText={"לא"}>
                                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                    </Popconfirm>
                                </span >);
                        } else {
                            let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                            return (<span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                    onClick={() => {
                                        if (isCreate)
                                            this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get);
                                        else
                                            this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                    }} />
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                    onClick={() => {
                                        if (isCreate)
                                            this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                        else
                                            this.props.dataActions.genericDataCancelEditing(this.id);
                                    }} />
                            </span>);
                        }
                    },
                }
            ];

    }

    componentDidMount() {
        document.title = "Cash On Tab - ";
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorTaxRegion, this.props.user.companyCode,
            this.props.user.token, this.api.selector);
        //this.refreshData();
    }

    initFilters = () => {
        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: 5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: 5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 1
            case "mId": col--;
            case "mName": col--;
            case "mRegionId": col--;
            case "mIsIncludeVat": break; // 5
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        this.setState({ visible: false });
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleClear = () => {
        //this.clear();
    }

    render() {
        const { loading } = this.state;
        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={'עריכת מחירונים'}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, 
                                _words: escapeRegExp(val),
                                //   _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mName: y[1],
                mRegionId: y[2],
                mIsIncludeVat: y[3],
                index: index
            })
        });

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}
                /*footer={
                    [
                        <Button key="back"
                            onClick={this.handleCancel}>ביטול</Button>,
                        <Button key="clear"
                            loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                        <Button key="create" disabled={!this.state.isValidItemCode}
                            type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                        <Button key="createLeave" disabled={!this.state.isValidItemCode}
                            type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                    ]}*/
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >

                <Table dataSource={dataSource}
                    columns={this.columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                />


            </Modal >
        )
    }
}

export default ModalCreatePriceList;


import React from "react";
import GenericSelector from "./GenericSelector";
import LangContext from "../contextProvider/LangContext";

class GenericSelectorCustomers extends React.Component/*<Props, State>*/ {
    constructor(props) {
        super(props);
        this.id = "GENERIC_SELECTOR_CUSTOMERS";
        this.g_id = "GENERIC_SELECTOR_CONNECTED_CUSTOMERS";
        this.p_id = "GENERIC_SELECTOR_PRIMARY_CUSTOMERS";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericSelector }
        if (props.data[this.g_id] === undefined) props.data[this.g_id] = { ...props.data.genericSelector }
        if (props.data[this.p_id] === undefined) props.data[this.p_id] = { ...props.data.genericSelector }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        let gt = this.getText;
        let _id = this.props.isGroupFromConnected == '1' ? this.p_id
            : this.props.isGroupFromConnected == '2' ? this.g_id
                : this.id;

        let _api = this.props.notApi ? undefined
            : this.props.isGroupFromConnected == "1" ? 'get_connected_customers_primary_list'
                : this.props.isGroupFromConnected == "2" ? 'get_selector_connected_customers'
                    : 'get_selector_customers';

        console.log("GenericSelectorCustomers", _id,_api, this.props.data[_id])

        return (<GenericSelector
            {...this.props}
            id={_id}
            api={_api}
            optionsGroup={this.props.isGroupFromConnected == '2'}
            modalStyle={this.props.notModalStyle ? null : {
                title: gt(this.props.multi ? 19100 : 19099),
                col1Title: gt(19101),
                col2Title: gt(19102),
                cancelText: gt(19103),
                clearText: gt(19104),
            }}
        />)
    }
}

export default GenericSelectorCustomers;


import React from "react";
import GenericSelector from "./GenericSelector";
import LangContext from "../contextProvider/LangContext";

class GenericSelectorCustTags extends React.Component/*<Props, State>*/ {
    constructor(props) {
        super(props);
        this.id = 'GENERIC_MULTI_SELECTOR_CUSTOMER_TAGS';
        this.api = 'get_customer_tags_list'
        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericSelector }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        let gt = this.props.getText ?? this.getText;

        return (<GenericSelector
            {...this.props}
            optionsGroup
            id={this.id}
            api={this.props.notApi ? undefined : this.api}
            modalStyle={this.props.notModalStyle ? null : {
                title: gt(this.props.multi ? 16730 : 16729),
                col1Title: gt(16731),
                col2Title: gt(16732),
                cancelText: gt(16733),
                clearText: gt(16734),
            }}
        />)
    }
}

export default GenericSelectorCustTags;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import {
    message, Card, Table, Icon, Select, DatePicker, TimePicker, InputNumber,
    Checkbox, Popconfirm, Button, Input, Switch, Alert, Modal, Form, Row, Col
} from "antd";

//import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import { Job, User, OutputType, ProcessType, hashLoginInformationTest } from '../../JmReact';
//import PictureView from "../../components/PictureView";
//import { UploadImage } from "../../components/UploadImage";
import ResponsiveTable from "../../components/ResponsiveTable";
//import SwiichButton from "../../components/SwiichButton";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
//import VideoView from "../../components/VideoView";
//import PizzaView from "../../pages/VirtualShop/PizzaView";
//import baseImg from "../../skins/pizza/base.png";
import greenOlive from "../../skins/pizza/greenOlive.png";
import blackOlive from "../../skins/pizza/blackOlive.png";
//import { MultiUpload } from "../../components/MultiUpload";
import moment from "moment";
//import { join } from "lodash";


const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};

type State = { employeeID: string };

class TestingPage extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;
    isPunchIn: number;

    constructor(props: Props) {
        super(props);
        this.id = "TESTING";

        this.state = {
            employeeID: '',
            xxx: 'xxx',
            lang: 'iw_IL',

            url: null,
            // wallet
            walletId: undefined,
            yudaTesting: false,
            yudaTestingAraay: [],
            tableCheck: null,

            videoValue: null,

            //webpushr
            webpushr: null,

            extraList: [
                { eID: 1, eItemCode: 1, eItemName: 'זיתים ירוקים', ePrice: 3, eType: 2, eIsChecked: 0, eLocals: [], eImage: greenOlive },
                { eID: 2, eItemCode: 2, eItemName: 'זיתים שחורים', ePrice: 3, eType: 2, eIsChecked: 0, eLocals: [], eImage: blackOlive },
            ],


            tasks_data: [],
            lastTasksRender: 0,
            inputTasksSum: false,

            modalTestYuda: false,
        }
        this.xxx2 = 'xxx2';

        // multiupload
        this.state.multi = {}
        this.state.multi.isDirectory = false
        this.state.multi.FileType = "I"
        this.state.multi.fileList = [
            //{uid: 'rc-upload-1634547405987-6', name: 'cibus.png', lastModified: 1604474458730, webkitRelativePath: ''},
            //{uid: 'rc-upload-1634547405987-7', name: 'mishloca.png', lastModified: 1604474374220, webkitRelativePath: ''},
            //{uid: 'rc-upload-1634547405987-8', name: 'paidit.png', lastModified: 1604474441614, webkitRelativePath: ''},
            //{uid: 'rc-upload-1634547405987-9', name: 'tenbis.png', lastModified: 1604474418725, webkitRelativePath: ''},
            //{uid: 'rc-upload-1634547405987-10', name: 'wordpress.png', lastModified: 1626941013753, webkitRelativePath: ''}
        ]
        this.state.multi.failedfileList = [
            //{uid: 'rc-upload-1634547405987-9', name: 'tenbis.png', lastModified: 1604474418725, webkitRelativePath: ''},
        ]
        this.state.multi.missingfileList = [
            //{uid: 'rc-upload-1634547405987-8', name: 'paidit.png', lastModified: 1604474441614, webkitRelativePath: ''},
        ]
        //

        this.state.dataSource = [{}, {}];

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data["EXPORT_TABLE_LIST"] === undefined) props.data["EXPORT_TABLE_LIST"] = { ...props.data.genericSelector };
        if (props.data["EXPORT_COL_LIST"] === undefined) props.data["EXPORT_COL_LIST"] = { ...props.data.genericSelector };

        this.isPunchIn = 0;
        this.api = {
            //get: "",
            create: "punch_clock",
            //edit: "",
            //delete: "",
            //selector: ""
        }

        this.actionButtons = [
            /*
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalCreateEditEmployee) }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },*/
        ];
        this.columns =
            [
                {
                    title: "מזהה",
                    dataIndex: "id",
                    key: "id",
                    sorter: false,
                    width: '10%',
                    render: (text, record) => text,
                }, {
                    title: 'תאריך',
                    dataIndex: "",
                    key: "dDate",
                    width: '15%',
                    sorter: false,
                    render: (text, record) => {
                        if (true) {
                            return (
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {


                                    }} />);
                        } else {
                            return text;
                        }
                    }

                }
            ];

        this.tasksLoop = null;


        this.apiDataComp = []
        this.apiDataStyles = []

    }

    componentDidMount() {
        document.title = "Cash On Tab - בדיקות";
        // this.indexedDbCheck()
        // this.tasksLoopRender()
       
      


    }

  

    tasksLoopRender = () => {
        this.setState({ lastTasksRender: Date.now() }, () => {
            clearTimeout(this.tasksLoop);
            this.tasksLoop = setTimeout(this.tasksLoopRender, 1000);
        })
    }

    onSelectFile = (e) => {
        console.log(e, 'file upload')
    };

    sendAPI = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }


    getWebPushrSID = (e) => {
        window.webpushr('fetch_id', (sid) => {
            //save id to database
            console.log('webpushr subscriber id: ' + sid);
            this.setState({ webpushr: sid })
        })
    }

    getWebPushrSetAttributes = (e) => {
        window.webpushr('attributes',
            //{"Gender" : "Male", "User ID" : "123456",  "E-mail" : "test@test"}
            { "updated": new Date().toLocaleString(), "2f5a_company": "2f5a", "2f5a_customerId": 6 }
        );
    }

    getWebPushrSetAttributes2 = (e) => {
        window.webpushr('attributes',
            //{"Gender" : "Male", "User ID" : "123456",  "E-mail" : "test@test"}
            { "updated": new Date().toLocaleString(), "2f5a_company": "2f5a", "2f5a_customerId": 4 }
        );
    }

    yudaTestingModal = () => {
        const { yudaTesting, tableCheck, colsCheck } = this.state;
        let xlsFilePrint = () => {

            this.sendAPI("export_excel", "tName\fcolList\r" + tableCheck + '\f' + (colsCheck ? colsCheck : ''), (ob) => {
                let data = ob.data ? ob.data.split('\r').map(x => x.split('\f').join(',')).join('\r\n') : [];
                let blob = new Blob(["\ufeff", data], { type: "text/csv;charset=utf8" });
                let a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.download = tableCheck + '.csv';
                a.click();
            }, (e) => { console.error(e) })
        }
        return (<GenericModal
            visible={yudaTesting}
            onCancel={() => { this.setState({ yudaTesting: false }) }}
            width={800}
            title={'יצוא טבלאות נתונים'}
            footer={[<Button type="primary" onClick={xlsFilePrint}>{"יצא לאקסל"}</Button>]}>
            <Form.Item label={'בחר טבלה'}>
                <GenericSelector
                    id={"EXPORT_TABLE_LIST"}
                    api={"export_table_list"}
                    value={this.state.tableCheck}
                    onChange={(e) => {
                        this.setState({ tableCheck: e });
                        this.props.dataActions.genericSelectorRefreshDataset(
                            "EXPORT_COL_LIST",
                            this.props.user.companyCode,
                            this.props.user.token,
                            'export_col_list',
                            { tName: e });
                    }}
                    user={this.props.user}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </Form.Item>
            <Form.Item label={'בחר עמודות להצגה'}>
                <GenericSelector
                    multi
                    maxTagCount={20}
                    requestFilter={{ tName: this.state.tableCheck }}
                    id={"EXPORT_COL_LIST"}
                    api={"export_col_list"}
                    value={this.state.colsCheck ? this.state.colsCheck.split(',') : []}
                    onChange={(e) => { this.setState({ colsCheck: e ? e.join(',') : "" }) }}
                    user={this.props.user}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </Form.Item>

            {/* <Form.Item label={'העלאת וידאו'}>
                <VideoView
                    {...this.props}
                    value={this.state.videoValue}
                    onChange={(e) => { this.setState({ videoValue: e }) }}
                    onRemove={() => { this.setState({ videoValue: null }) }}
                />
            </Form.Item> */}


            {/* <div>
                <PizzaView
                    extraList={this.state.extraList}
                    baseImg={baseImg}
                    areasQty={4}
                    onChange={(e) => { this.setState({ extraList: this.state.extraList.map(x => { return x.eID === e.eID ? e : x }) }) }}
                 getText={this.getText}
                />
            </div> */}
            <ResponsiveTable
                tableOnly={true}
                dataSource={null}
                columns={[{ title: 'A', dataIndex: 'A' }, { title: 'B', dataIndex: 'B' },]}
                pagination={false}
                scroll={{ y: window.innerHeight / 2 }}
            />
        </GenericModal>)
    }

    close_socket = () => {
        const { socket } = this.state;
        socket.close()
    }

    setup_socket = () => {
        // Create WebSocket connection.
        const socket = new WebSocket('ws://127.0.0.1:51000');
        // Connection opened
        socket.addEventListener('open', function (event) {
            console.log('sending to server (on open) ');
            socket.send('Hello Server by on open');
        });

        // Listen for messages
        socket.addEventListener('message', function (event) {
            console.log('Message from server ', event.data);
        });

        socket.addEventListener('error', function (event) {
            console.log('error ', event);
        });

        this.setState({ socket: socket })
    }

    sent_to_socket = () => {
        const { socket } = this.state;
        console.log('sending to server (on click)');
        socket.send(new Blob());
    }

    sent_to_socket_v0 = () => {
        const { socket } = this.state;
        console.log('sending to server (on click)');
        socket.send(
            `~MDELF,format5
^Fformat5
^Q25,3
^W50
^H19
^P1
^S5
^AD
^C1
^R0
~Q+0
^O0
^D0
^E18
~R255
^L
V00,50,itemName,Jc50
V01,30,mybarcode,Jc50
V02,30,price,Jc50
AB,0,0,2,2,0,0E,^V00
AD,0,60,1,1,0,0E,^V02
BQ2,10,120,2,2,30,0,0,^V01
AB,0,160,1,1,0,0E,^V01
E
^Kformat5
בננה יריב
00000000006
 ש"ח20.00 מחיר  
E
~P2
`);
    }

    indexedDbConnect = (onsuccess) => {
        let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
        let open = indexedDB.open("tasks_list_autosoft", 1);
        open.onupgradeneeded = () => {
            let db = open.result;
            let store = db.createObjectStore("user_data", { keyPath: "field_name" });
            store.createIndex("field_name", "field_name");
            store.createIndex("field_value", "field_value");
        };
        open.onsuccess = () => { onsuccess(open) }
    }

    indexedDbCheck = () => {
        this.indexedDbConnect((open) => {
            let db = open.result;
            let tx = db.transaction("user_data", "readwrite");
            let store = tx.objectStore("user_data");
            store.index("field_name");
            let getComp = store.get("tasks_data");
            getComp.onsuccess = () => {
                if (getComp.result && getComp.result.field_value) {
                    this.setState({ tasks_data: getComp.result.field_value });
                }
            };
        })
    }

    indexedDbCreate = () => {
        const { tasks_data } = this.state;
        this.indexedDbConnect((open) => {
            console.log("test db - create");

            let db = open.result;
            let tx = db.transaction("user_data", "readwrite");
            let store = tx.objectStore("user_data");
            store.index("field_name");
            store.put({ field_name: "tasks_data", field_value: tasks_data });
            tx.oncomplete = () => { db.close() };
        })
    }

    onChangeTasks = (key, data) => {
        let tasks_data = this.state.tasks_data.map(x => {
            return x.key === key ? { ...x, ...data } : x
        });
        this.setState({ tasks_data })
    }

    renderTasksDesc = (text, record) => {
        if (record.isPlay) {
            return (<Input
                value={text}
                onChange={e => { this.onChangeTasks(record.key, { desc: e.target.value }) }}
                onBlur={this.indexedDbCreate} />)
        } else {
            return text
        }
    }

    renderTasksTime = (text, record) => {
        let curtime = Date.now();

        return moment(new Date((22 * 60 * 60 * 1000) + (record.isPlay ? (curtime - record.timeStart) + text : text))).format("HH:mm:ss")
    }

    renderTasksRemarks = (text, record) => {
        if (record.isPlay) {
            return (<Input.TextArea
                value={text}
                onChange={e => { this.onChangeTasks(record.key, { remarks: e.target.value }) }}
                onBlur={this.indexedDbCreate} />)
        } else {
            return text
        }
    }

    renderTasksStart = (text, record) => {
        return <span>
            {record.isPlay ?
                <Icon style={{ margin: 5, fontSize: 16 }} type={"stop"} onClick={() => {
                    this.setState({
                        tasks_data: this.state.tasks_data.map(x => {
                            return x.key === record.key ? { ...x, time: record.time + (Date.now() - record.timeStart), timeStart: 0, isPlay: false } : x
                        })
                    }, this.indexedDbCreate)
                }} />
                : <Icon style={{ margin: 5, fontSize: 16 }} type={"poweroff"} onClick={() => {
                    this.setState({
                        tasks_data: this.state.tasks_data.map(x => {
                            let curtime = Date.now();
                            if (x.key === record.key) return { ...x, timeStart: curtime, isPlay: true }
                            else return { ...x, time: x.time + (x.timeStart ? curtime - x.timeStart : 0), timeStart: 0, isPlay: false }
                        })
                    }, this.indexedDbCreate)
                }} />
            }
            <Icon style={{ margin: 5, fontSize: 16 }} type={"delete"} onClick={() => {
                this.setState({ tasks_data: this.state.tasks_data.filter(f => f.key !== record.key) }, this.indexedDbCreate)
            }} />
        </span>
    }

    addTaskRow = () => {
        let curtime = Date.now();
        let tasks_data = [
            ...this.state.tasks_data.map(x => {
                let time = x.time + (x.timeStart ? curtime - x.timeStart : 0);
                return { ...x, time, timeStart: 0, isPlay: false }
            }),
            { key: curtime, desc: "", remarks: "", time: 0, timeStart: curtime, isPlay: true }
        ]
        this.setState({ tasks_data }, this.indexedDbCreate)
    }


    render() {
       
        const { fileList, isDirectory, FileType, failedfileList, missingfileList } = this.state.multi;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '100%',
            // width: '100%',
            // flexDirection: 'column',
        };

        const { employeeID, tasks_data } = this.state;
        const dataSource = this.state.dataSource;



        return (
            <div style={divStyle}>
                <InputNumber addOnBefore={<Icon type={"dollar"} />} addOnAfter="$" style={{ width: 350 }} />
                <Row>
                    {/* <Col span={13}>
                        <Button onClick={() => { this.setState({ inputTasksSum: !this.state.inputTasksSum }) }}>{!this.state.inputTasksSum ? "סיכום יומי" : "טבלה"}</Button>
                        {this.state.inputTasksSum ? (
                            <Input.TextArea
                                key={"inputTasksSum"}
                                value={tasks_data.map(x => moment().format("YYYY-MM-DD") + "\t" + x.desc + "\t" + this.renderTasksTime(x.time, x) + "\t" + x.remarks).join(`
                                `)}
                                onFocus={e => {
                                    e.target.select()
                                    document.execCommand('copy');
                                    message.success("המידע הועתק")
                                }}
                            />) :
                            <ResponsiveTable
                                title={"דיווח פעילות עובדים"}
                                actionButtons={[{ buttonType: 'add', buttonFunction: this.addTaskRow }]}
                                genericActionButtons={[
                                    <Button onClick={() => {
                                        this.setState({ tasks_data: [] }, this.indexedDbCreate)
                                    }}><Icon style={{ margin: 5, fontSize: 16 }} type={"delete"} /></Button>,
                                ]}
                                dataSource={tasks_data}
                                columns={[
                                    { title: "משימה", key: "desc", dataIndex: "desc", width: "30%", render: this.renderTasksDesc },
                                    { title: "זמן", key: "time", dataIndex: "time", width: "15%", render: this.renderTasksTime },
                                    { title: "הערות", key: "remarks", dataIndex: "remarks", width: "45%", render: this.renderTasksRemarks },
                                    { title: "הפעל", width: "10%", render: this.renderTasksStart },
                                ]}
                            />}
                    </Col> */}
                    <Col span={1}></Col>

                    <Col span={23}>
                        <Card hoverable={true}
                            //title="שעון נוכחות"
                            bordered={false}
                            style={{ width: 500 }}
                            cover={<PageToolbar
                                title="בדיקה"
                                actionButtons={this.actionButtons}
                                genericActionButtons={[(<Button type="primary" style={{ margin: 5 }} onClick={() => { this.setState({ yudaTesting: true }) }}>yuda</Button>)]}
                                //showsearch={(val) => {
                                //    this.props.dataActions.genericDataSetFilter(
                                //        this.id, this.props.user.companyCode, this.props.user.token,
                                //        { ...request, _words: val }, this.api.get)
                                //}}
                                ui={this.props.ui}
                                uiActions={this.props.uiActions} />}

                        /* actions={[
                            <Icon onClick={
                                () => {
                                    this.isPunchIn = 0;
                                    this.api_punch_clock(this.isPunchIn);
                                }} type="arrow-down" style={{ fontSize: 28, color: '#f20e0e' }}>יציאה</Icon>,
                            <Icon onClick={
                                () => {
                                    this.isPunchIn = 1;
                                    this.api_punch_clock(this.isPunchIn);
                                }} type="arrow-up" style={{ fontSize: 28, color: '#0cd119' }}>כניסה</Icon>
                        ]}*/
                        >


                            <div>
                                <Button onClick={() => { this.api_renew_get_user(); }}>RENEW GET USER</Button>
                                <Button onClick={() => { this.api_renew(); }}>RENEW</Button>
                            </div>
                            <div>
                                <Button onClick={() => { this.api_get_pos_remote_control(); }}>GET POS REMOTE CONTROL</Button>
                            </div>
                            <div>
                                <Input value={this.state._user} onChange={(e) => this.setState({ _user: e.target.value })} placeholder={"user"} />
                                <Input value={this.state._psw} onChange={(e) => this.setState({ _psw: e.target.value })} placeholder={"psw"} />
                                <Button onClick={() => {
                                    let loginString = hashLoginInformationTest(this.state._user, this.state._psw);
                                    console.log("HASH: ", loginString);
                                }}>LOGIN HASH</Button>
                            </div>

                            <div>

                                <Button onClick={() => { this.api_store_next_export_xml(); }}>EXPORT STORENEXT</Button>
                            </div>

                            <div>
                                <Button onClick={() => { this.close_socket(); }}>CLOSE SOCKET</Button>
                                <Button onClick={() => { this.setup_socket(); }}>SETUP SOCKET</Button>
                                <Button onClick={() => { this.sent_to_socket(); }}>SENT TO SOCKET</Button>
                            </div>
                            <div>
                                <Button onClick={() => { this.hashavshevet_post(); }}>hashavshevet_post</Button>
                                <Button onClick={() => { this.hashavshevet_get(); }}>hashavshevet_get</Button>
                            </div>
                            <div>
                                <Button onClick={() => {
                                    this.api_http_get_item_balance();
                                    //window.top.close();
                                }}>http_get_item_balance</Button>

                                <Button onClick={() => {
                                    this.api_get_priority_item_balance();
                                    //window.top.close();
                                }}>get_priority_item_balance</Button>
                            </div>

                            <div>
                                <span><Button onClick={this.getWebPushrSetAttributes}>set webpushr attributes (cust6)</Button></span>
                                <span><Button onClick={this.getWebPushrSetAttributes2}>set webpushr attributes (cust4)</Button></span>
                                <span><Button onClick={this.getWebPushrSID}>get webpushr sid</Button></span>
                                <span>
                                    {`webpushr sid:${(this.state.webpushr) ? this.state.webpushr : null}`}
                                </span>
                            </div>

                            <div>
                                <div>ffffffffffff</div>
                                <div>
                                    <Button onClick={() => {
                                        this.api_multipass();
                                        //window.top.close();
                                    }}>MULTIPASS</Button>

                                    <Select style={{ width: 300 }} onChange={(e) => this.setState({ multipassVal: e })}>
                                        <Option key={1} value={"multi_pass_add_transaction"} >multi_pass_add_transaction</Option>
                                        <Option key={2} value={"multi_pass_delete_transaction"} >multi_pass_delete_transaction</Option>
                                        <Option key={3} value={"multi_pass_get_budget"} >multi_pass_get_budget</Option>
                                        <Option key={4} value={"multi_pass_load_budget"} >multi_pass_load_budget</Option>
                                        <Option key={5} value={"multi_pass_delete_load_budget"} >multi_pass_delete_load_budget</Option>
                                        <Option key={6} value={"multi_pass_get_user_details"} >multi_pass_get_user_details</Option>
                                        <Option key={7} value={"multi_pass_get_last_transaction"} >multi_pass_get_last_transaction</Option>
                                        <Option key={8} value={"multi_pass_setup"} >multi_pass_setup</Option>
                                    </Select>
                                </div>

                                {/* <SwiichButton
                            user={this.props.user} ui={this.props.ui} uiActions={this.props.uiActions}
                            data={this.props.data} dataActions={this.props.dataActions} ActionQueue={this.props.ActionQueue}

                            elementId="swiich-payment-button"
                            width={40} height={40}
                            eventsConfig={{}}
                            customerName={'shooky'}
                            amount={1}
                            orderId={654645645}
                            ///orderId={Date.now()}//{88888}
                            //phoneNumber={'0544365054'} // not used in bit

                            walletList={[1, 2, 3, 4, 5, 6]}
                            value={this.state.walletId}
                            onChange={(e) => {
                                console.log(e)
                                this.setState({ walletId: e })
                            }}
                        /> */}
                            </div>

                            <div>
                                <div>
                                    <Button onClick={() => {
                                        this.api_test();
                                        //window.top.close();
                                    }}>TEST META</Button>
                                </div>

                                <div>
                                    <Button onClick={() => {
                                        this.api_priority();
                                        //window.top.close();
                                    }}>api_priority_table</Button>
                                    <Button onClick={() => {
                                        this.api_priority_delete();
                                        //window.top.close();
                                    }}>api_priority_delete</Button>
                                </div>

                                <div>
                                    <Button onClick={() => {
                                        message.success("", 2);
                                    }}>testMsg</Button>

                                </div>
                                <div>
                                    <Button onClick={() => {
                                        this.api_schema_build();
                                        //window.top.close();
                                    }}>Rebuild schema table</Button>
                                </div>
                                <div>
                                    <Input
                                        //autoFocus={true}
                                        value={this.state.migrate}
                                        style={{ width: '70%', padding: '4px', marginBottom: '4px' }}
                                        onChange={(e) => { this.setState({ migrate: e.target.value }); }}
                                        placeholder={'schema name'}
                                    />
                                    <Button onClick={() => {
                                        this.api_migrate(this.state.migrate/*'sale_884'*/);
                                        //window.top.close();
                                    }}>Fetch Schema</Button>
                                </div>
                                <div>
                                    <Button onClick={() => {
                                        window.top.close();
                                    }}>CLOSE WINDOW</Button>
                                </div>
                                <div>
                                    <Button onClick={this.api_no_token_form}>Test Form Endpoint</Button>
                                    <Button onClick={this.api_with_token_form}>Test Form Endpoint With Token</Button>
                                </div>


                                <Select style={{ width: 200 }} onChange={(e) => this.setState({ lang: e })}>
                                    <Option key={1} value={"iw_IL"} >עברית</Option>
                                    <Option key={2} value={"ar"} >ערבית</Option>
                                </Select>

                                <Button onClick={() => {
                                    let params = {
                                        //reportName: "reports/test_oz.pdf",
                                        reportName: "reports/testGoogle.pdf",
                                        //REPORT_LOCALE: "ar" | "iw_IL"
                                        //REPORT_LOCALE: this.state.lang
                                    }
                                    this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
                                }
                                }>test report</Button>

                                <Button onClick={() => {
                                    let params = {
                                        // reportName: "reports/test_oz.pdf",
                                        reportName: "reports/Report80SalesTagsDetails.pdf",
                                        FromWorkerId: 1,
                                        ToWorkerId: 2002064,
                                        FromDate: '2000-01-01',
                                        ToDate: '2020-01-31',
                                        FromTime: '00:00:00',
                                        ToTime: '23:59:59',
                                        PosId: 2,

                                        REPORT_LOCALE: this.state.lang
                                    }
                                    this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
                                }
                                }>test report 80</Button>

                                <Button onClick={() => {
                                    let params = {
                                        // reportName: "reports/test_oz.pdf",
                                        reportName: "reports/Report80SalesTurnover.pdf",
                                        IsMonthly: 1,
                                        MonthReport: 1,
                                        YearReport: '2020',
                                        CalcMethod: 1,
                                        FromDate: '2020-09-01',
                                        ToDate: '2020-09-30',

                                        //FromTime: '00:00:00',
                                        //</div>/ToTime: '23:59:59',
                                        PosId: 2,

                                        REPORT_LOCALE: this.state.lang
                                    }
                                    this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
                                }
                                }>test shiran report 80</Button>

                                <Table dataSource={dataSource}
                                    columns={this.columns}
                                    loading={this.props.ui.workInProgress}
                                    //scroll={{ x: 1650 }}
                                    bordered
                                    pagination={this.props.data[this.id].pagination}
                                    onChange={this.handleTableChange}
                                    locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                                />
                                <Input
                                    autoFocus={true}
                                    value={employeeID}
                                    style={{ width: '100%', padding: '4px', marginBottom: '48px' }}
                                    onChange={(e) => {
                                        //this.setState({ employeeID: e.target.value });
                                        this.setState({ employeeID: e.target.value });
                                    }}
                                    placeholder={'token'}
                                >
                                </Input>

                                <Switch />

                                <Input
                                    //autoFocus={true}
                                    //={this.state.xxx}
                                    value={this.xxx2}
                                    style={{ width: '20%', padding: '4px', marginBottom: '48px' }}
                                    onChange={(e) => {
                                        //this.setState({ employeeID: e.target.value });
                                        //this.setState({ xxx: e.target.value });
                                        this.xxx2 = e.target.value;
                                    }}
                                    placeholder={'token'}
                                >
                                </Input>


                                <Icon onClick={
                                    () => {
                                        //this.api_func();
                                        console.log("host ", window.location.hostname);
                                    }} type="arrow-up" style={{ fontSize: 28, color: '#0cd119' }}>הרצה</Icon>

                                <Icon onClick={() => {
                                    window.print();
                                    console.log("host ", window.location.hostname);
                                }} type="camera" style={{ fontSize: 28, color: '#0cd119' }}>צילום מסך</Icon>


                                {//<img src={"http://cashontab.com/uploads/a:/" + this.state.employeeID/*"315446803893228881387-T.png"*/}/>
                                }

                                <img src={"http://147.235.163.248/uploads/" + this.props.user.companyCode + "/" + this.state.employeeID/*"315446803893228881387-T.png"*/}
                                    alt={this.state.employeeID}
                                />
                                {/* 
                                <PictureView
                                    user={this.props.user} data={this.props.data} dataActions={this.props.dataActions} ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                                    // url={"http://147.235.163.248/uploads/" + this.props.user.companyCode + "/" + this.state.employeeID}
                                    onChange={(url) => { this.setState({ url: url }) }}
                                    url={this.state.url}
                                    onUploadedSuccess={(response) => { }}
                                    onUploadedFailed={(errorMessage, errorCode) => { }}
                                    imageType={'B'}
                                    aspect={1.5}
                                /> */}
                                {/* 
                                <UploadImage
                                    user={this.props.user} data={this.props.data} dataActions={this.props.dataActions} ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                                    url={"http://147.235.163.248/uploads/" + this.props.user.companyCode + "/" + this.state.employeeID}
                                    onChange={(url) => { console.log(url) }}
                                    onUploadedSuccess={(response) => { }}
                                    onUploadedFailed={(errorMessage, errorCode) => { }}
                                    imageType={'B'}
                                />
                                 */}

                                <Button onClick={() => { this.setState({ modalTestYuda: true }) }}>test_yuda_39</Button>


                                <div>
                                    <Button onClick={this.api_call}>api_call</Button>

                                </div>

                                <Button onClick={this.api_terms}>api_terms</Button>

                                <Button onClick={this.addDir}>test file system api</Button>

                                <Button onClick={this.api_fetch_10bis}>test api_fetch_10bis</Button>

                                <Button onClick={() => {
                                    if (navigator.geolocation) {
                                        navigator.geolocation.getCurrentPosition((pos) => { console.log(pos.coords.latitude, ' | ', pos.coords.longitude) });
                                    } else {
                                        console.log("Geolocation is not supported by this browser.");
                                    }
                                }} >Geolocation</Button>
                                <div>
                                    <button id="find-me" onClick={() => {
                                        const status = document.querySelector('#status');
                                        const mapLink = document.querySelector('#map-link');

                                        mapLink.href = '';
                                        mapLink.textContent = '';

                                        function success(position) {
                                            console.log("gio succ")
                                            const latitude = position.coords.latitude;
                                            const longitude = position.coords.longitude;

                                            status.textContent = '';
                                            mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}`;
                                            mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
                                        }

                                        function error() {
                                            console.log("gio fail")
                                            status.textContent = 'Unable to retrieve your location';
                                        }

                                        if (!navigator.geolocation) {
                                            status.textContent = 'Geolocation is not supported by your browser';
                                        } else {
                                            status.textContent = 'Locating…';
                                            navigator.geolocation.getCurrentPosition(success, error);
                                        }

                                    }}>Show my location</button><br />
                                    <p id="status"></p>
                                    <a id="map-link" target="_blank"></a>
                                </div>
                            </div>
                            <Button onClick={() => { this.setState({ homeScreenAlert: true }) }}>הוספה למסך הבית</Button>

                            <Button onClick={this.api_generic_call}>api_generic_call</Button>

                            {/* <Modal
                                visible={this.state.homeScreenAlert}
                                footer={[
                                    <Button onClick={() => { this.setState({ homeScreenAlert: false }) }} type={"primary"} style={{ margin: 5 }}>הוסף כעת</Button>,
                                    <Button onClick={() => { this.setState({ homeScreenAlert: false }) }} style={{ margin: 5 }}>פעם אחרת</Button>,
                                ]}
                                onCancel={() => { this.setState({ homeScreenAlert: false }) }}>
                                <div style={{ textAlign: "center", color: '#1E90FF' }}>
                                    <Icon type={"home"} style={{ fontSize: 50, marginBottom: 25 }} />
                                    <h2>האם ברצונך להוסיף את האתר לדף הבית?</h2>
                                </div>
                            </Modal>
                            {this.yudaTestingModal()}
                            {this.yudaTestingModal39()} */}

                        </Card>
                    </Col>
                </Row>
            </div >
        );
   
    }

    yudaTestingModal39 = () => {
        return (<GenericModal
            visible={this.state.modalTestYuda}
            onCancel={() => { this.setState({ modalTestYuda: false }) }}
            title="סרגל רוזנברג - מדידת רמת חרדיות"
        >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(x => this.getRullStepBtn(x))}
        </GenericModal>)
    }

    getRullStepBtn = (step) => {
        const btnStyle = {
            cursor: 'pointer',
            fontSize: 40
        }
        const symbolCode = '&#' + (10101 + step) + ';'
        return (<span style={btnStyle}>&#{10101 + step};</span>)
    }

    addDir = () => {
        /*
        window.requestFileSystem(window.TEMPORARY, 1024 * 1024,
            function (fs) {
                fs.root.getDirectory('testDir', { create: true }, function (dirEntry) { }, (err) => { console.log("test1 ", err) });
            }, (err) => { console.log("test2 ", err) });
        */
    }

    api_no_token_form = () => {
        let dataSend = "mToken\fmType\r61f00ff0-1fcb-11ea-8212-fa163e4d9b1d\f1";
        //let user = new User(this.props.user.token, this.props.user.companyCode);
        let user = new User("", this.props.user.companyCode);
        let job = new Job(user, 'get_form_token'/*'get_images_urls'*/, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"], true);
        job.setInput(dataSend);
        //job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
        job.send("/cgi-bin/JMForms", this.successCallback, this.errorCallback);
    }
    api_with_token_form = () => {
        //let dataSend = "mToken\fmType\r61f00ff0-1fcb-11ea-8212-fa163e4d9b1d\f1";
        //let user = new User(this.props.user.token, this.props.user.companyCode);
        let user = new User("bfd1201d-1fcf-11ea-8212-fa163e4d9b1d", this.props.user.companyCode);
        let job = new Job(user, 'test'/*'get_images_urls'*/, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"], true);
        //job.setInput(dataSend);
        //job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
        job.send("/cgi-bin/JMForms", this.successCallback, this.errorCallback);
    }


    api_func = (/*_isPunchIn: number*/) => { // 1 / 0
        //let dataSend = "mWorkerId\fmIsPunchIn\r" + this.state.employeeID + '\f' + _isPunchIn;
        let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'get_images_urls'/*this.api.create*/, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
    };

    api_terms = () => {
        let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'app_autoclub_terms_of_use'/*this.api.create*/, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        //job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
        job.send("/cgi-bin/JobMasterGeneral", this.successCallback, this.errorCallback);
    }

    api_fetch_10bis = () => {
        //let dataSend = "" /*this.state.employeeID*/;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user,
            'fetch_cibus'
            /*this.api.create*/
            //'fetch_all_external_orders_async'

            , OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        //job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
        //job.send("/cgi-bin/JobMasterGeneral", this.successCallback, this.errorCallback);
    }

    api_call = () => {
        let dataSend = "schemaName\fdebug\rsales_981\f1" /*this.state.employeeID*/;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user,
            'import_from_bi_server'
            /*this.api.create*/
            //'fetch_all_external_orders_async'

            , OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
        //job.send("/cgi-bin/JobMasterGeneral", this.successCallback, this.errorCallback);
    }

    successCallback = (ob: Object) => {
        console.log("success:", ob.data);
        message.success(ob.data + ' בדיקה ');

    }
    errorCallback = (error: any) => {
        console.log('error:', error);
        message.error('שגיאה לא ידועה');
    }

    api_generic_call = () => {
        let dataSend = "";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'test_generic', OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob: Object) => {
                const arr = ob.data.split("\f");
                const input = arr[0];
                const scriptName = arr[1];

                const dataSend = input.split("#")
                    .map(r => r.split("|").join("\f"))
                    .join("\r")

                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, scriptName, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab",
                    (ob: Object) => {
                        console.log("generic call response:")
                        console.log(ob.data)
                    }, this.errorCallback)
            }, this.errorCallback);
    }


    api_migrate = (_from) => { // 1 / 0
        let dataSend = "schemaName" + '\r' + _from;
        //let let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'import_from_bi_server'/*this.api.create*/, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            () => { message.success('succ') },
            () => { message.error('error') });
    };

    api_schema_build = () => { // 1 / 0
        let dataSend = "";
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "rebuild_schema_table", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            () => { message.success('succ') },
            () => { message.error('error') });
    };

    api_test = () => { // 1 / 0
        //let dataSend = "num\fstr\r1\faaa\r2\fbbb\r3\fccc\r4\fddd";
        let d = [
            ['num', 'str', 'dt'],
            [1, null, '2000-01-01 00:00:00'],
            [null, 'bbb', '2000-01-01 00:00:00'],
            [3, 'ccc', null],
            [4, 'ddd', '2000-01-01 00:00:00'],
        ]

        let dataSend = d.map(a => a.join('\f')).join('\r');
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "test", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); },
            (err) => { message.error('error'); });
    };

    api_priority = () => { // 1 / 0
        let dataSend = "";
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "get_priority_convertor_table", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); console.log(ob.data.split('\f')); },
            (err) => { message.error('error'); console.log(err); });
    };

    api_priority_delete = () => { // 1 / 0
        let dataSend = "cID\r5";
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "delete_priority_convertor", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); console.log(ob.data.split('\f')); },
            (err) => { message.error('error'); console.log(err); });
    };

    api_multipass = () => { // 1 / 0
        let dataSend = "";
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.state.multipassVal, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        if (this.state.multipassVal === "multi_pass_add_transaction") {
            dataSend = `data\r{
                    "CardCode": "101614724667",
                    "PosId" : 11281,
                
                    "TranValue" : 16000, 
                    "TranSum" : 16000,
                    "CatItemsList": 
                        [
                            { 
                                "PosItemID": 1,			
                                "DisplayIndex" : 0,
                                "CatalogItemId" : 0,		
                                "CatalogItemCode" : "55555",
                                "Amount" : 100,
                                "Price" : 4000,			
                                "PriceSum" : 4000,			
                                "IsActive" : true,			
                                "Bussiness" : 0 	
                            }
                        ],
                    "PosTranId": "12345" 
                    }`
        } else if (this.state.multipassVal === "multi_pass_get_last_transaction") {
            dataSend = `data\r{
                "CardCode": "101614724667",
                "PosId" : 11281,
                "RecordsNumToShow": 1
            }`
        } else if (this.state.multipassVal === "multi_pass_get_user_details") {
            dataSend = `data\r{
                "CardCode": "101614724667",
                "PosId" : 11281,
                "ClientID": 0
            }`
        } else if (this.state.multipassVal === "multi_pass_get_budget") {
            dataSend = `data\r{
                "CardCode": "101614724667",
                "PosId" : 11281
            }`
        } else if (this.state.multipassVal === "multi_pass_setup") {
            dataSend = `data\r{
                "CardCode": "101614724667",
                "PosId" : 11281
            }`
        }

        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); console.log(ob.data.split('\f')); },
            (err) => { message.error('error'); console.log(err); });
    };

    hashavshevet_post = () => {
        let dataSend = "station\fcompany\fplugin\fpluginData\r54137791-ea1e-4033-b985-cceba54a8019\fdemo\fheshin\f[{"
            + "\"phone\":\"10\","
            + "\"stockid\":\"1111\","
            + "\"date\":\"1/298\","
            + "\"kabSort\":\"11\","
            + "\"docNumber\":\"5\","
            + "\"accountKey\":444},"
            + "{"
            + "\"phone\":\"10\","
            + "\"stockid\":\"1111\","
            + "\"date\":\"1/298\","
            + "\"kabSort\":\"11\","
            + "\"docNumber\":\"5\","
            + " \"accountKey\":444}"
            + "]"
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "hashavshevet_post", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); console.log(ob.data); },
            (err) => { message.error('error'); console.log(err); });
    }

    hashavshevet_get = () => {
        let dataSend = "";
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "hashavshevet_get", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); console.log(ob.data); },
            (err) => { message.error('error'); console.log(err); });
    }

    api_http_get_item_balance = () => {
        let dataSend = "PARTNAME\fORGANIZATION\fAPP\r014754\fAUTOSOFT_POS\fMAGAVOT_ARAD";
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "http_get_item_balance", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); console.log(ob.data.split('\f')); },
            (err) => { message.error('error'); console.log(err); });

    }

    api_get_priority_item_balance = () => {
        let dataSend = "PARTNAME\fORGANIZATION\fAPP\r014754\fAUTOSOFT_POS\fMAGAVOT_ARAD";
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "get_priority_item_balance", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); console.log(ob.data.split('\f')); },
            (err) => { message.error('error'); console.log(err); });

    }

    api_store_next_export_xml = () => {
        let dataSend = "";
        //let dataSend = this.state.employeeID;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "store_next_export_xml", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); console.log('store_next: ', ob.data); },
            (err) => { message.error('error'); console.log('store_next: ', err); });
    }

    api_get_pos_remote_control = () => {
        //	const char *pos = (const char *)hash_map_get_pointer(hm, "$.input.mPos");
        // const char *compId = (const char *)hash_map_get_pointer(hm, "$.input.mCompanyId");
        let dataSend = "mPos\fmCompanyId\r2\f24";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "sunmi_remote_control", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob) => { message.success('succ'); console.log('sunmi_remote_control (succ): ', ob.data); },
            (err) => { message.error('error'); console.log('sunmi_remote_control (error): ', err); });
    }

    api_renew_get_user = () => {
        // auth | confirmation | user_name | user_display | phone | email | psw1 | psw2
        // USED auth|confirmation
        let dataSend = "04b7771117065153159e20839b8c72bde77d3b3ec611af90861ce812c8bfcd93\f1\f2\f3\f4\f5\f6\f7 ";
        let user = new User("", this.props.user.companyCode);
        let job = new Job(user, "get_user_for_renew", OutputType["OUTPUT_TYPE_BLOB"], ProcessType["PROCESS_TYPE_SYNC"], true);
        job.setInput(dataSend);
        job.send("/cgi-bin/CotRenew",
            (ob) => { message.success('succ'); console.log('get_user_for_renew (succ): ', ob.data); },
            (err) => { message.error('error'); console.log('get_user_for_renew (error): ', err); });
    }

    api_renew = () => {
        // auth | confirmation | user_name | user_display | phone | email | psw1 | psw2
        // USED auth|confirmation
        // NOT USED = user_name|user_display|phone|email|psw1|psw2
        let dataSend = "04b7771117065153159e20839b8c72bde77d3b3ec611af90861ce812c8bfcd93\f\f\f\f\f";
        let user = new User("", this.props.user.companyCode);
        let job = new Job(user, "renew", OutputType["OUTPUT_TYPE_BLOB"], ProcessType["PROCESS_TYPE_SYNC"], true);
        job.setInput(dataSend);
        job.send("/cgi-bin/CotRenew",
            (ob) => { message.success('succ'); console.log('renew (succ): ', ob.data); },
            (err) => { message.error('error'); console.log('renew (error): ', err); });
    }




}

export default TestingPage;
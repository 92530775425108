/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Checkbox, Modal, Icon, Input, InputNumber, DatePicker, Select, Radio, } from "antd";
import PageToolbar from "./PageToolbar";
import TableIDs from "../data/TableIDs";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";
import moment from "moment";
import PictureView from "./PictureView";
import GenericSelector from "./GenericSelector";
import SignatureInput from "./SignatureInputV2";
import GenericSelectorCustTags from "./GenericSelectorCustTags";

import "../App.css";

const { Option, OptGroup } = Select;

class ModalCustomerFieldRoles extends Component<Props, State> {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {}

        this.id = "CUSTOMER_FIELD_ROLES";

        this.api = {
            get: "get_customer_field_role_list",
            edit: "edit_customer_field_role_list",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorPriceList, script: "get_prices", multi: false },
            { id: TableIDs.genericMultiSelectorCustomerTags, script: "get_customer_tags_list", multi: true },
            { id: TableIDs.genericSelectorEmployees, script: "get_employee_list", multi: false },
            { id: 'QUESTIONNAIRE_LIST', script: "get_questionnaire_list", multi: false },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }

        this.selectors.map((x) => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector }
        });



    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prev) {
        let data = this.props.ui.data ? this.props.ui.data.rID : 0;
        let pData = prev.ui.data ? prev.ui.data.rID : 0;
        if (data !== pData) this.setState({ rID: data }, this.refreshData);
    }

    refreshData = () => {
        console.log("test_req_get_questionnaire_list")
        let data = this.props.ui.data ? this.props.ui.data.rID : 0;
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { rID: data }, this.api.get);
        this.selectors.map((x) => {
            let action = x.multi ? "genericMultiSelectorRefreshDataset" : "genericSelectorRefreshDataset";
            this.props.dataActions[action](x.id, this.props.user.companyCode, this.props.user.token, x.script);
        });
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalCustomerFieldRoles);
    }

    getInput = (text, record) => {
        let data = this.props.data[this.id];
        let editedRecord = this.props.data[this.id].editedRecord;
        let isEdit = data.editing === record.key && editedRecord["rIsAssociated"] == "1" ? true : false;
        let value = isEdit ? editedRecord["rDefaultValue"] : text;
        let onChange = (v) => { if (isEdit) this.props.dataActions.genericDataSetEdit(this.id, { rDefaultValue: v }) }

        const sqlFormat = "YYYY-MM-DD";
        const dateFormat = "DD/MM/YYYY";
        const field = this.props.ui.data ? this.props.ui.data.rID : 0;

        switch (record.rTypeId) {
            case '0': // image or questionnaire
                let valueData = value ? value.split(":") : ['U', '']

                return (<div>
                    {isEdit ?
                        (<Radio.Group value={valueData[0]} onChange={e => { onChange(e.target.value + ':') }}>
                            <Radio value={'Q'}>{this.getText(17615)}</Radio>
                            <Radio value={'U'}>{this.getText(17614)}</Radio>
                        </Radio.Group>)
                        : ""}
                    {valueData[0] == 'U' ?
                        (<PictureView
                            {...this.props}
                            url={valueData[1]} onChange={e => { onChange('U:' + e) }} onRemove={() => onChange("U:")}
                            onUploadedSuccess={() => { }} onUploadedFailed={() => { }}
                            imageType={'C'} readOnly={!isEdit} aspect={4 / 1}
                        />) :
                        (<GenericSelector
                            {...this.props}
                            id={'QUESTIONNAIRE_LIST'}
                            value={valueData[1]}
                            style={{ width: "100%" }}
                            onChange={e => { onChange('Q:' + e) }}
                            disabled={!isEdit}
                        />)}
                </div>);

            case '1':   // text
                return (<Input disabled={!isEdit} value={value} onChange={(v) => { onChange(v.target.value) }} />);
            case '2':   // price
                return (<InputNumber min={0} precision={2} disabled={!isEdit} value={value} onChange={onChange} />);
            case '3':   // float
                return (<InputNumber precision={2} disabled={!isEdit} value={value} onChange={onChange} />);
            case '4':   // int
                return (<InputNumber min={0} disabled={!isEdit} value={value} onChange={onChange} />);
            case '5':   // date
                return (<span>
                    <DatePicker disabled={!isEdit} value={value === '' || value === 'NOW' ? null : moment(value, sqlFormat)}
                        format={dateFormat} onChange={(date) => { onChange(date != null ? date.format(sqlFormat) : "") }}
                    />
                    <Checkbox
                        style={{ marginRight: 10 }} checked={value === 'NOW'} onChange={(e) => {
                            onChange(e.target.checked ? 'NOW' : "")
                        }}>{this.getText(17373)}</Checkbox>
                </span>);
            case '6':   // bool
                return (<Checkbox onChange={(e) => { onChange((e.target.checked) ? 1 : 0) }}
                    checked={(value == 1) ? true : false} defaultChecked={false} disabled={!isEdit}
                />)

            case '7': case '8':  // select
                if (field == 6) {
                    return (
                        <GenericSelectorCustTags
                            {...this.props}
                            multi
                            onChange={(e) => { onChange(e ? e.join(',') : '') }}
                            value={value ? value.split(",") : []}
                            disabled={!isEdit}
                        />
                    );
                } else {

                    let list = [];
                    if (record.rDataJson?.indexOf('[') > -1) list = JSON.parse(record.rDataJson);

                    if (record.rTypeId == '7') {

                        return (<Select {...{ value, onChange }} style={{ width: '100%', paddingLeft: '4px' }} disabled={!isEdit}>
                            <Option key={"0"} value={""} >-</Option>
                            {list.map(obj => (<Option key={obj['code']} value={obj['code']?.toString()} valuefilter={obj['code'] + obj['name']}>{obj['code'] + " - " + obj['name']}</Option>))}
                        </Select>)

                    } else {

                        return (<Checkbox
                            disabled={!isEdit}
                            checked={value && list.length === value.split(",").length}
                            indeterminate={value?.split(",").length && value?.split(",").length < list.length}
                            onChange={(e) => { onChange(e.target.checked ? list.map(x => x.code).join(",") : "") }}
                        >
                            <Select
                                mode={"multiple"} maxTagCount={4} style={{ width: '100%', paddingLeft: '4px' }} disabled={!isEdit}
                                value={value ? value.split(",") : []}
                                onChange={(e) => { onChange(e ? e.join(",") : "") }}>
                                {list.map(obj => (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>))}
                            </Select>
                        </Checkbox>)
                    }
                }
            // case '7':   // select
            //     let list = field == 105 ?
            //         [
            //             <Option value={'0'}>{this.getText(18979)}</Option>,
            //             <Option value={'1'}>{this.getText(18980)}</Option>,
            //             <Option value={'6'}>{this.getText(19360)}</Option>,
            //             <Option value={'2'}>{this.getText(18981)}</Option>,
            //             <Option value={'3'}>{this.getText(18982)}</Option>,
            //             <Option value={'5'}>{this.getText(19359)}</Option>,
            //             <Option value={'7'}>{this.getText(19361)}</Option>,
            //             <Option value={'4'}>{this.getText(19105)}</Option>,
            //         ]
            //         : this.props.data[TableIDs.genericSelectorPriceList] ?
            //             [
            //                 <Option value={'0'}>-</Option>,
            //                 ...this.props.data[TableIDs.genericSelectorPriceList].dataset.map((obj, index) => {
            //                     return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            //                 })
            //             ]
            //             : [];

            //     return (<Select disabled={!isEdit} value={value} style={{ width: '100%', paddingLeft: '4px' }} onChange={onChange}>
            //         {list}
            //     </Select>)

            // case '8':   // multi select
            //     let tagList = this.props.data[TableIDs.genericMultiSelectorCustomerTags] ?
            //         this.props.data[TableIDs.genericMultiSelectorCustomerTags].dataset.map((node) => {
            //             return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
            //                 {node.tags.map((item) => (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>))}
            //             </OptGroup>)
            //         }) : []
            //     let fullSelect = value !== "" ? value.split(",") : [];
            //     return (<Select showSearch filterOption={true} optionFilterProp="valuefilter" mode="multiple"
            //         style={{ width: "100%" }}
            //         onChange={(e) => { onChange(fullSelect[0] ? e.join(",") : e[0]) }}
            //         value={fullSelect} disabled={!isEdit}>
            //         {tagList}
            //     </Select>);

            case '9':  // image
                return (<PictureView
                    {...this.props}
                    url={value} onChange={onChange} onRemove={() => onChange("")}
                    onUploadedSuccess={() => { }} onUploadedFailed={() => { }}
                    imageType={'C'} readOnly={!isEdit} aspect={1}
                />);

            case '10':   // text
                return (<Input.Password disabled={!isEdit} value={value} onChange={(v) => { onChange(v.target.value) }} />);

            case '11':
                return (<Select
                    value={value}
                    defaultValue=""
                    style={{ width: "100%" }}
                    onChange={onChange}
                    disabled={!isEdit}>
                    {/* <Option value={'0'}>-</Option> */}
                    <Option value={'1'}>{this.getText(16788)}</Option>
                    <Option value={'2'}>{this.getText(16790)}</Option>
                    <Option value={'3'}>{this.getText(16789)}</Option>
                    <Option value={'4'}>{this.getText(16791)}</Option>
                    <Option value={'5'}>{this.getText(18136)}</Option>
                </Select>)

            case '12':
                return (<GenericSelector
                    {...this.props}
                    id={TableIDs.genericSelectorEmployees}
                    value={value}
                    style={{ width: "100%" }}
                    onChange={onChange}
                    disabled={!isEdit}
                    addFirstRow={{ code: "0", name: "-" }}
                />)

            case '13':
                return (<SignatureInput
                    {...this.props}
                    key={record.key}
                    idKey={record.key}
                    value={value}
                    onChange={onChange}
                    disabled={!isEdit}
                />)

            default:
                return (<div>undefined controller</div>);
        }


    }

    render() {
        let rFieldName = this.props.ui.data ? this.props.ui.data.rFieldName : "";

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.getText(12584) + ": " + rFieldName}
                    actionButtons={[{
                        buttonType: 'refresh',
                        buttonFunction: this.refreshData
                    }]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let columns = [
            {
                title: this.getText(12587),
                dataIndex: "rDocName",
                key: "rDocName",
                width: '40%',
                render: (text, record) => text
            }, {
                title: this.getText(12588),
                dataIndex: "rIsAssociated",
                key: "rIsAssociated",
                width: '10%',
                render: (text, record) => {
                    let data = this.props.data[this.id];
                    let isEdit = data.editing === record.key ? true : false;
                    let source = isEdit ? data.editedRecord["rIsAssociated"] : text;
                    return (<Checkbox
                        disabled={!isEdit}
                        checked={source === '1' ? true : false}
                        onChange={(value) => {
                            let updateField = value.target.checked ? { rIsAssociated: '1' } : { rIsAssociated: '0', rIsReadOnly: '0', rDefaultValue: '' }
                            this.props.dataActions.genericDataSetEdit(this.id, updateField)
                        }}
                    />)
                }
            }, {
                title: this.getText(12589),
                dataIndex: "rIsReadOnly",
                key: "rIsReadOnly",
                width: '10%',
                render: (text, record) => {
                    let data = this.props.data[this.id];
                    let isEdit = data.editing === record.key ? true : false;
                    let source = isEdit ? data.editedRecord["rIsReadOnly"] : text;
                    return (<Checkbox
                        disabled={!isEdit || data.editedRecord.rIsAssociated != "1"}
                        checked={source === '1' ? true : false}
                        onChange={(value) => {
                            this.props.dataActions.genericDataSetEdit(this.id, { rIsReadOnly: value.target.checked ? '1' : '0' })
                        }}
                    />)
                }
            }, {
                title: this.getText(16413),
                dataIndex: "rVisible",
                key: "rVisible",
                width: '10%',
                render: (text, record) => {
                    let data = this.props.data[this.id];
                    let isEdit = data.editing === record.key ? true : false;
                    let source = isEdit ? data.editedRecord["rVisible"] : text;
                    return (<Checkbox
                        disabled={!isEdit || data.editedRecord.rIsAssociated != "1"}
                        checked={source === '1' ? true : false}
                        onChange={(value) => {
                            this.props.dataActions.genericDataSetEdit(this.id, { rVisible: value.target.checked ? '1' : '0' })
                        }}
                    />)
                }
            }, {
                title: this.getText(12604),
                dataIndex: "rDefaultValue",
                key: "rDefaultValue",
                width: '25%',
                render: this.getInput
            }, {
                title: this.getText(12590),
                width: '5%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                            }} />
                        </span>);
                    } else {
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>);
                    }
                }
            },
        ]


        return (<Modal
            destroyOnClose={true}
            width={"95%"}
            visible={false || this.props.toggle}
            title={titleBar}
            onCancel={this.handleCancel}
            footer={null}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                columns={columns}
                tableOnly={true}
                percentageToMobile={[60, 20, 20]}
                pagination={false}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />

        </Modal >)
    }
}

export default ModalCustomerFieldRoles;

import React from "react";
import {
    Form, Button, DatePicker, Modal, Checkbox, Select, message
} from 'antd';
import moment from "moment";
import PageToolbar from "./PageToolbar";
import LangContext from "../contextProvider/LangContext";
import TableIDs from "../data/TableIDs";
import SelectorItems from "./SelectorItems";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import CustPicker from "./CustPicker";
import ReportFavoriteSave from "./ReportFavoriteSave";
import DateTimePicker from "./DateTimePicker";

const FormItem = Form.Item;
const { Option } = Select;

type TagObj = {
    type: {
        id: string,
        text: string,
    },
    tags: {
        id: string,
        text: string,
    }[],
}

class ModalSearchSubscribers extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.selectors = [
            { id: TableIDs.genericSelectorCustomers, script: "get_customers_list_v3" },
            { id: TableIDs.genericSelectorPos, script: "list_pos" },
        ]

        this.selectors.map((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.state = {
            loading: false,
            visible: false,
            request: {
                _creationDateStart: null,
                _creationDateEnd: null,
                _activityDateStart: null,
                _activityDateEnd: null,
                _validityDateStart: null,
                _validityDateEnd: null,
                _itemCode: '',
                _itemName: '',
                _customer: null,
                _posList: '',
                _remainZero: 0,
            }
        }

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData();
    }


    componentDidUpdate(prev) {
        if (prev.filterActive && !this.props.filterActive) this.resetFilter()
    }


    // send data to parent
    // componentWillReceiveProps(newProps) {
    //     let _state = this.state;
    //     _state.visible = newProps.toggle;
    //     this.setState(_state);
    //     console.log("yuda componentWillReceiveProps")
    // }

    refreshData = () => {
        this.selectors.map((x) => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.script, { _words: null });
        })
    }

    createCheckBox = (field, data) => {
        let r = this.state.request;
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setState({ request: { ...r, [field]: data } });
                else
                    this.setState({ request: { ...r, [field]: '' } });
            }} />);
    }

    // bind input field
    onChange = (field, value) => {
        let r = this.state.request;
        this.setState({
            request: {
                ...r, [field]: value,
            }
        });
    }

    createDatePicker = (text, field) => {
        const value = this.state.request[field];
        const format = "DD/MM/YYYY";
        const onChange = (v) => { this.onChange(field, v) }
        const minDate = field == '_creationDateEnd' ? this.state.request._creationDateStart
            : field == '_activityDateEnd' ? this.state.request._activityDateStart
                : field == '_validityDateEnd' ? this.state.request._validityDateStart
                    : null;
        const maxDate = field == '_creationDateStart' ? this.state.request._creationDateEnd
            : field == '_activityDateStart' ? this.state.request._activityDateEnd
                : field == '_validityDateStart' ? this.state.request._validityDateEnd
                    : null;

        return (<FormItem label={this.getText(text)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
            <DateTimePicker {...{ format, value, onChange, minDate, maxDate }} />
        </FormItem>)
    }

    createSelector = (text, field, selector, multi, keyDown) => {
        let onInputKeyDown = keyDown ? (e) => {
            clearTimeout(this.timeOut);
            this.timeout = setTimeout((ev) => {
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.genericSelectorRefreshDataset,
                    args: [this.selectors[selector].id, this.props.user.companyCode, this.props.user.token,
                    this.selectors[selector].script, { _words: ev }]
                });
            }, 500, e.target.value)
        } : false;

        let data = this.state.request[field];
        let s = this.props.data[this.selectors[selector].id]
        let sData = s ? s.dataset : [];
        let list = sData.map((x) => (<Option key={x['code']} value={x['code']} valuefilter={x['code'] + ' ' + x['name']}>{x['code'] + ' - ' + x['name']}</Option>));
        let fullList = sData.map((x) => x.code).join(',');
        return (<FormItem label={this.getText(text)}>
            {multi ? this.createCheckBox(field, fullList) : ""}
            <Select key={field} showSearch filterOption={true} optionFilterProp="valuefilter"
                mode={multi ? "multiple" : null} maxTagCount={10}
                style={{ width: multi ? '90%' : '95%', paddingLeft: '4px', paddingRight: '4px' }}
                value={data && data.length > 0 ? multi ? data.split(',') : data : multi ? [] : ""}
                onChange={(e) => { this.onChange(field, multi ? e.join(',') : e) }}
                onInputKeyDown={onInputKeyDown}
            >{list}</Select>
        </FormItem>)
    }

    createYesNoSelector = (label, field, yes, no) => {
        return (<FormItem label={this.getText(label)}>
            <Select key={field} style={{ width: '95%', paddingLeft: '4px', paddingRight: '4px' }}
                value={this.state.request[field]} onChange={(value) => { this.onChange(field, value) }}
            >
                <Option key={2} value={1}>{this.getText(yes)}</Option>
                <Option key={3} value={0}>{this.getText(no)}</Option>
            </Select>
        </FormItem>)
    }

    createItemPicker = (label, codeField, nameField) => {

        let onChangeItemCode = (code, name) => {

            if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
                this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
            } else {
                let newRequest = { ...this.state.request, [codeField]: code, [nameField]: name }
                setTimeout(() => { this.setState({ request: newRequest }) }, 300);
            }
        }

        let checkIfItemExist = (s) => {
            if (s) {
                let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab",
                    (ob) => {
                        let itemsRows = (ob.data).split("\r");
                        let _ItemData = itemsRows[0].split('\f');
                        let _ItemCode = _ItemData[1];
                        let _ItemName = _ItemData[2];

                        if (!_ItemCode || itemsRows.length > 1) {
                            this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data, idSelector: codeField });
                        } else {
                            onChangeItemCode(_ItemCode, _ItemName)
                        }
                    },
                    (error) => {
                        message.error(this.getText(12713));
                    }
                );
            } else {
                onChangeItemCode("", "")
            }
        }

        let keyboardListener = (e) => {
            let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
            switch (e.key) {
                case "b": case "B": case "נ": case "M": case "m": case "צ":
                    if (e.ctrlKey) {
                        this.ctrlBKeyFlag = true;
                        this.barcodeReaderBuffer = '';
                    } else {
                        bufferUpdate(e.key)
                    }
                    break;

                case "Enter":
                    if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                    checkIfItemExist(this.barcodeReaderBuffer)
                    break;

                default:
                    bufferUpdate(e.key)
                    break;
            }
        }

        let source = this.state.request;

        return (<FormItem label={this.getText(label)}>
            <SelectorItems
                idSelector={codeField}
                user={this.props.user}
                uiActions={this.props.uiActions}
                ui={this.props.ui}
                dataActions={this.props.dataActions}
                data={this.props.data}
                ActionQueue={this.props.ActionQueue}
                ctrlBKeyFlag={null}
                isExpandMatrixFatherItem={false}
                itemType={1}
                value={source[codeField]}
                onChangeSearch={(s) => { onChangeItemCode(s.mCode, s.mName) }}
                onChange={(s) => { this.onChange(codeField, s.target.value) }}
                onBlur={(s) => { checkIfItemExist(s.target.value) }}
                onKeyDown={keyboardListener}
            />
            <h4>{source[codeField] !== "" ? source[nameField] : this.getText(12712)}</h4>
        </FormItem>)
    }

    resetFilter = () => {
        let _request = {
            _creationDateStart: null,
            _creationDateEnd: null,
            _activityDateStart: null,
            _activityDateEnd: null,
            _validityDateStart: null,
            _validityDateEnd: null,
            _itemCode: '',
            _itemName: '',
            _customer: null,
            _posList: '',
            _remainZero: 0,
        }

        this.setState({ request: _request }, () => { this.props.dataSetFilter({ ..._request, _words: "" }) });
    }

    handleReset = () => {
        if (this.props.setFilterActive) this.props.setFilterActive(false)
        else this.resetFilter()
    }

    handleOk = () => {
        this.props.dataSetFilter(this.state.request);
        if (this.props.setFilterActive) this.props.setFilterActive(true)
        this.handleCancel();
    }

    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }

    render() {
        const { visible, loading } = this.state;

        const { _words, _creationDateStart, _creationDateEnd, _activityDateStart, _activityDateEnd, _validityDateStart, _validityDateEnd, _itemCode, _itemName, _customer, _posList, _remainZero } = this.props.data.CUSTOMER_SUBSCRIBERS_LIST.filters;
        let reportParams = { _words, _creationDateStart, _creationDateEnd, _activityDateStart, _activityDateEnd, _validityDateStart, _validityDateEnd, _itemCode, _itemName, _customer, _posList, _remainZero };


        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        let colDivStyle = {
            width: window.innerWidth > 600 ? '33%' : '100%',
            padding: 10,
            verticalAlign: 'text-top',
            display: 'inline-block'
        }

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(12704)}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>);

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'70%'}
                visible={this.props.toggle}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <ReportFavoriteSave
                        {...this.props}
                        btnText={this.getText(16682)}
                        notSaveUrl
                        params={{
                            ...reportParams,
                            _words: encodeURIComponent(reportParams._words),
                            _itemCode: encodeURIComponent(reportParams._itemCode),
                            _itemName: encodeURIComponent(reportParams._itemName),
                            reportName: "reports/CustomersSubscribers.pdf",
                            REPORT_LOCALE: this.getText(101),
                        }}
                        datesToFavorites={[
                            { label: this.getText(12705), field: '_creationDateStart' },
                            { label: this.getText(12706), field: '_creationDateEnd' },
                            { label: this.getText(12707), field: '_activityDateStart' },
                            { label: this.getText(12708), field: '_activityDateEnd' },
                            { label: this.getText(18562), field: '_validityDateStart' },
                            { label: this.getText(18563), field: '_validityDateEnd' },
                        ]}
                    />,
                    <Button key="back" onClick={this.handleCancel}>{this.getText(12714)}</Button>,
                    <Button key="clear" onClick={this.handleReset}>{this.getText(12715)}</Button>,
                    <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(12716)}</Button>
                ]}
            >
                <Form layout="horizontal" className="ant-advanced-search-form">
                    <div style={colDivStyle}>
                        {this.createItemPicker(12711, '_itemCode', '_itemName')}
                        {/* {this.createSelector(12710, '_customer', 0, false, true)} */}
                        <FormItem label={this.getText(12710)}>
                            <CustPicker
                                value={this.state.request ? this.state.request._customer : null}
                                onChange={(value) => { this.onChange('_customer', value) }}
                                defaultView={this.getText(12121)}
                                data={this.props.data}
                                dataActions={this.props.dataActions}
                                uiActions={this.props.uiActions}
                                user={this.props.user}
                            />
                        </FormItem>
                    </div>
                    <div style={colDivStyle}>
                        {this.createDatePicker(12705, '_creationDateStart')}
                        {this.createDatePicker(12706, '_creationDateEnd')}
                        {this.createDatePicker(12707, '_activityDateStart')}
                        {this.createDatePicker(12708, '_activityDateEnd')}
                    </div>
                    <div style={colDivStyle}>
                        {this.createDatePicker(18562, "_validityDateStart")}
                        {this.createDatePicker(18563, "_validityDateEnd")}
                        {this.createSelector(12709, '_posList', 1, true, false)}
                        {this.createYesNoSelector(12717, '_remainZero', 12718, 12719)}

                    </div>
                </Form >
            </Modal >
        )
    }


}

export default ModalSearchSubscribers;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import {
    Checkbox, InputNumber, Select, Button, Row, Col, Modal, Icon, Input, Tooltip, message, Popconfirm,
    TimePicker, DatePicker, Form, Popover, Card, Table
} from "antd";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericSelector from "../../components/GenericSelector";
import moment from "moment";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericModal from "../../components/GenericModal"


class VirtualShopDowHours extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalNonValidDates: false,
            modalOrderTimes: false,
            modalShabatTimes: null,

            modalCopy: null,
            modalCopyCB1: false,
            modalCopyCB2: false,
            modalCopyCB3: false,
            modalCopyCB4: false,

            branchListToCopy: '',
            modalNonValidDatesCopy: false,

            valueSearch: "",
        }

        this.hours_id = "VALID_DOW_HOURS";
        this.order_time_id = "BRANCHES_ORDER_TIME";
        this.dates_id = "NON_VALID_DATES";

        this.api = {

            get_hours: "get_valid_dow_hours",
            save_hours: "save_valid_dow_hours",
            copy_hours: "copy_valid_dow_hours",

            get_order_time: "get_branch_order_time",
            save_order_time: "save_branch_order_time",

            get_dates: "get_non_valid_dates",
            save_dates: "save_non_valid_dates",
            delete_dates: "delete_non_valid_dates",
            copy_dates: "copy_non_valid_dates",

            save_shabat: "save_shabat_hours_branch",

        }

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" },
        ]

        if (props.data[this.hours_id] === undefined) props.data[this.hours_id] = { ...props.data.genericData };
        if (props.data[this.order_time_id] === undefined) props.data[this.order_time_id] = { ...props.data.genericData };
        if (props.data[this.dates_id] === undefined) props.data[this.dates_id] = { ...props.data.genericData };

        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.mobile = window.innerWidth <= 600 ? true : false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.forEach((x) => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.api)
        })
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.loadingHours()
    }

    loadingHours = () => {
        this.props.dataActions.genericDataSetFilter(this.hours_id, this.props.user.companyCode, this.props.user.token, {}, this.api.get_hours);
    }

    loadingNonValidDates = (key) => {
        this.props.dataActions.genericDataSetFilter(this.dates_id, this.props.user.companyCode, this.props.user.token, { bID: key }, this.api.get_dates);
    }

    loadingOrderTimes = (key) => {
        this.props.dataActions.genericDataGetEditWithParams(this.order_time_id, this.props.user.companyCode, this.props.user.token, { bID: key }, this.api.get_order_time);
    }

    getModalTitle = (title, actions, gActions, moreAttr) => {
        return (<PageToolbar {...this.props} {...moreAttr}
            isModal={true}
            title={title}
            actionButtons={actions ? actions : []}
            genericActionButtons={gActions ? gActions : undefined}
        />)
    }

    cancelEditInline = (id) => {
        if (this.props.data[id].editedRecord.key == 0)
            this.props.dataActions.genericDataCancelInlineCreate(id);
        else
            this.props.dataActions.genericDataCancelEditing(id);
    }

    saveEdit = (id, saveScript, getScript, params) => {
        if (params) {
            this.props.dataActions.genericDataSaveEditWithParams(
                id, this.props.user.companyCode, this.props.user.token, saveScript, getScript, params);
        } else if (getScript) {
            this.props.dataActions.genericDataSaveEdit(
                id, this.props.user.companyCode, this.props.user.token, saveScript, getScript);
        } else {
            this.props.dataActions.genericDataJustSaveEdit(
                id, this.props.user.companyCode, this.props.user.token, saveScript);
        }
    }


    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    modalNonValidDates = () => {
        let bID = this.state.modalNonValidDates ? this.state.modalNonValidDates.bID : '';
        let bName = this.state.modalNonValidDates ? this.state.modalNonValidDates.bName : '';

        let editedRecord = this.props.data[this.dates_id].editedRecord;
        let editing = this.props.data[this.dates_id].editing;
        let onChange = (k, v) => { this.props.dataActions.genericDataSetEdit(this.dates_id, { [k]: v }) }
        let isEdit = (r) => r.key === editing;

        let tInput = (t, r, f) => isEdit(r) ? (<Input value={editedRecord[f]} onChange={(e) => { onChange(f, e.target.value) }} />) : t

        let timePicker = (t, r, f) => {
            let value = editedRecord[f] ? moment(editedRecord[f], "HH:mm:ss") : null;
            let oc = (tt, ts) => { onChange(f, ts) }

            return isEdit(r) ? (<TimePicker style={{ width: '100%' }} format={"HH:mm:ss"} value={value} onChange={oc} />) : t
        }

        let datePicker = (t, r, f) => {
            let value = editedRecord[f] ? moment(editedRecord[f], "YYYY-MM-DD") : null;
            let oc = (d) => {
                onChange(f, d ? d.format("YYYY-MM-DD") : "")
            }

            return isEdit(r) ? (<DatePicker style={{ width: '100%' }} format={"YYYY-MM-DD"} value={value} onChange={oc} />) : t
        }

        let bIcon = (t, oc) => (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={t} onClick={oc} />)

        let popIcon = (t, texts, oc) => {
            let gt = (i) => this.getText(texts[i]);
            return (<Popconfirm title={gt(0)} okText={gt(1)} cancelText={gt(2)} onConfirm={oc}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={t} />
            </Popconfirm>)
        }


        let buttonsEdit = (r) => {
            return !isEdit(r) ?
                (<span>
                    {bIcon("edit", () => { this.props.dataActions.genericDataStartEditing(this.dates_id, r.key, r.index) })}
                    {popIcon("delete", [13514, 13485, 13486], () => {
                        this.props.dataActions.genericDataDeleteRow(
                            this.dates_id, r.key, this.props.user.companyCode, this.props.user.token, this.api.delete_dates, this.api.get_dates);
                    })}
                    <Tooltip title={this.getText(15591)}>{bIcon("copy", () => { this.setState({ modalNonValidDatesCopy: r.key }) })}</Tooltip>
                </span >)
                : (<span>
                    {bIcon("check", () => { this.saveEdit(this.dates_id, this.api.save_dates, this.api.get_dates, { bID: bID }) })}
                    {bIcon("close", () => { this.cancelEditInline(this.dates_id) })}
                </span>)
        }


        let columns = [
            { title: this.getText(13509), key: 'nvdDate', dataIndex: 'nvdDate', width: '20%', render: (t, r) => datePicker(t, r, 'nvdDate') },
            { title: this.getText(13510), key: 'nvdDesc', dataIndex: 'nvdDesc', width: '30%', render: (t, r) => tInput(t, r, 'nvdDesc') },
            { title: this.getText(13511), key: 'nvdHourFrom', dataIndex: 'nvdHourFrom', width: '20%', render: (t, r) => timePicker(t, r, 'nvdHourFrom') },
            { title: this.getText(13512), key: 'nvdHourTo', dataIndex: 'nvdHourTo', width: '20%', render: (t, r) => timePicker(t, r, 'nvdHourTo') },
            { title: this.getText(13513), width: '10%', render: (t, r) => buttonsEdit(r) },
        ];

        return (<Modal
            visible={this.state.modalNonValidDates}
            onCancel={() => { this.setState({ modalNonValidDates: false }) }}
            width={this.mobile ? '95%' : '70%'}
            footer={false}
            title={this.getModalTitle(this.getText(13508) + ' - ' + bName, [{
                buttonType: 'add',
                buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.dates_id) }
            }])}
        >
            <ResponsiveTable
                tableOnly={true}
                idTable={this.props.data[this.dates_id]}
                columns={columns}
                percentageToMobile={[100]}
                ui={this.props.ui}
                pagination={{ pageSize: 10 }}
            />
        </Modal>)
    }

    modalOrderTimes = () => {
        let fieldStyle = (label, field) => {
            return (<Form.Item label={this.getText(label)}>
                <InputNumber
                    min={0} step={1}
                    value={this.props.data[this.order_time_id].editedRecord[field]}
                    onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.order_time_id, { [field]: e }) }}
                />
            </Form.Item>)
        }

        let checkboxStyle = (label, field) => {
            return (<div>
                <Checkbox
                    style={{ margin: 10 }}
                    checked={this.props.data[this.order_time_id].editedRecord[field] == 1 ? true : false}
                    onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.order_time_id, { [field]: e.target.checked ? '1' : '0' }) }}
                />
                <span>{this.getText(label)}</span>
            </div>)
        }

        return (<Modal
            visible={this.state.modalOrderTimes}
            onCancel={() => { this.setState({ modalOrderTimes: false }) }}
            width={this.mobile ? '95%' : 1000}
            footer={[
                <Button type="primary" onClick={() => {
                    this.setState({ modalOrderTimes: false }, () => {
                        this.saveEdit(this.order_time_id, this.api.save_order_time, 0, 0);
                    });
                }}>{this.getText(13572)}</Button>
            ]}
            title={this.getModalTitle(this.getText(13573) + ' - ' + this.props.data[this.order_time_id].editedRecord['bName'])}
        >
            <Row>
                <Col span={this.mobile ? 24 : 12}>
                    {checkboxStyle(13578, 'bAcceptDate')}
                    {checkboxStyle(13579, 'bAcceptTime')}
                    {checkboxStyle(16978, 'bAcceptNow')}
                    {fieldStyle(13580, 'bMinDays')}
                    {fieldStyle(13581, 'bMaxDays')}
                </Col>
                <Col span={this.mobile ? 24 : 12}>
                    {fieldStyle(13574, 'bFirst')}
                    {fieldStyle(13575, 'bLast')}
                    {fieldStyle(13576, 'bPrepare')}
                </Col>
            </Row>
        </Modal>)
    }

    modalShabatTimes = () => {
        let data = this.state.modalShabatTimes ? this.state.modalShabatTimes : {}

        let fieldStyle = (label, field) => {
            return (<Form.Item label={this.getText(label)}>
                <InputNumber
                    min={0} step={1}
                    value={data[field]}
                    onChange={(e) => { this.setState({ modalShabatTimes: { ...data, [field]: e } }) }}
                />
            </Form.Item>)
        }

        return (<Modal
            visible={this.state.modalShabatTimes}
            onCancel={() => { this.setState({ modalShabatTimes: false }) }}
            width={400}
            footer={[
                <Button type="primary" onClick={() => {
                    const { bID, dSatOpen, dSatClose } = data;
                    this.setState({ modalShabatTimes: false }, () => {
                        this.saveEdit(this.hours_id, this.api.save_shabat, this.api.get_hours, { bID, dSatOpen, dSatClose })
                    });
                }}>{this.getText(13572)}</Button>
            ]}
            title={this.getModalTitle(this.getText(15525) + ' - ' + data['bName'])}
        >
            {fieldStyle(15526, 'dSatOpen')}
            {fieldStyle(15527, 'dSatClose')}
        </Modal>)
    }

    modalNonValidDatesCopy = () => {
        console.log('modalNonValidDates', this.state.modalNonValidDates)
        let branchList = this.props.data[this.selectors[0].id].dataset.filter(f => f.code !== this.state.modalNonValidDates.key)
        return (<Modal
            visible={this.state.modalNonValidDatesCopy}
            onCancel={() => { this.setState({ modalNonValidDatesCopy: null }) }}
            width={400}
            footer={[
                <Button type="primary" onClick={() => {
                    let dataSend = '_id\f_branchList\r' + this.state.modalNonValidDatesCopy + '\f' + this.state.branchListToCopy;
                    this.sendAPI(this.api.copy_dates, dataSend, (ob) => {
                        if (ob.data == '1') message.success(this.getText(15592));
                        this.setState({ modalNonValidDatesCopy: null })
                    })
                }}>{this.getText(15594)}</Button>
            ]}
            title={this.getText(15593)}>
            <Checkbox onChange={(e) => { this.setState({ branchListToCopy: e.target.checked ? branchList.map(x => x.code).join(',') : '' }) }} />
            <Select
                key={this.selectors[0].id}
                showSearch
                filterOption={true}
                optionFilterProp="valuefilter"
                mode={"multiple"}
                maxTagCount={4}
                style={{ width: '90%' }}
                value={this.state.branchListToCopy ? this.state.branchListToCopy.split(',') : []}
                onChange={(e) => { this.setState({ branchListToCopy: e ? e.join(',') : '' }) }}>
                {branchList.map(x => (<Select.Option value={x.code}>{x.code} - {x.name}</Select.Option>))}
            </Select>
        </Modal>)
    }

    modalCopyHoursAllBranches = () => {
        const { modalCopyCB1, modalCopyCB2, modalCopyCB3, modalCopyCB4, modalCopy } = this.state;
        return (<GenericModal
            visible={this.state.modalCopy !== null}
            onCancel={() => { this.setState({ modalCopy: null }) }}
            width={400}
            footer={[
                <Button type="primary" disabled={!modalCopyCB1 && !modalCopyCB2 && !modalCopyCB3 && !modalCopyCB4} onClick={() => {
                    this.setState({ modalCopy: null }, () => {
                        this.saveEdit(this.hours_id, this.api.copy_hours, this.api.get_hours, {
                            bID: modalCopy.key,
                            copyType1: modalCopyCB1 ? "1" : "0",
                            copyType2: modalCopyCB2 ? "1" : "0",
                            copyType3: modalCopyCB3 ? "1" : "0",
                            copyType4: modalCopyCB4 ? "1" : "0",
                        })
                    })
                }}>{this.getText(18873)}</Button>,
                <Button onClick={() => { this.setState({ modalCopy: null }) }}>{this.getText(18867)}</Button>
            ]}
            title={this.getText(18661)}>
            {[
                { id: '1', t: 18869 },
                { id: '2', t: 18870 },
                { id: '3', t: 18871 },
                { id: '4', t: 18872 },
            ].map(x => {
                return (<p>
                    <Checkbox
                        checked={this.state["modalCopyCB" + x.id]}
                        onChange={e => { this.setState({ ["modalCopyCB" + x.id]: e.target.checked }) }}>
                        {this.getText(x.t)}
                    </Checkbox>
                </p>)
            })}
        </GenericModal>)
    }

    render() {
        let isEdit = (r) => r.key === this.props.data[this.hours_id].editing;

        let timesField = (t, r, f) => {
            let tNotEdit = t ? t.split(' - ').map(x => x.slice(0, 5)).reverse().join(' - ') : '';
            let editedRecord = this.props.data[this.hours_id].editedRecord;
            let hours = editedRecord[f] ? editedRecord[f].split(' - ') : ['', ''];

            let timePicker = (i) => {
                return (<TimePicker
                    style={{ width: '100%' }} format={"HH:mm"}
                    value={hours[i] ? moment(hours[i], "HH:mm:ss") : null}
                    onChange={(t, ts) => {
                        hours[i] = ts;
                        this.props.dataActions.genericDataSetEdit(this.hours_id, { [f]: hours.join(' - ') })
                    }}
                />)
            }

            return !isEdit(r) ? tNotEdit :
                (<div>
                    <div>{this.getText(13506)}</div>
                    <div>{timePicker(0)}</div>
                    <div>{this.getText(13507)}</div>
                    <div>{timePicker(1)}</div>
                </div>)
        }

        let bIcon = (t, oc) => (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={t} onClick={oc} />)

        let buttonsEdit = (r) => {
            return !isEdit(r) ? (<span>
                {bIcon("edit", () => { this.props.dataActions.genericDataStartEditing(this.hours_id, r.key, r.index) })}
                <Tooltip title={this.getText(13508)}>
                    {bIcon("schedule", () => { this.setState({ modalNonValidDates: r }, () => { this.loadingNonValidDates(r.key) }) })}
                </Tooltip>
                <Tooltip title={this.getText(13571)}>
                    {bIcon("clock-circle", () => { this.setState({ modalOrderTimes: r }, () => { this.loadingOrderTimes(r.key) }) })}
                </Tooltip>
                <Tooltip title={this.getText(15525)}>
                    {bIcon("global", () => { this.setState({ modalShabatTimes: r }) })}
                </Tooltip>
                <Tooltip title={this.getText(18661)}>
                    {/* <Popconfirm
                        onConfirm={() => { this.saveEdit(this.hours_id, this.api.copy_hours, this.api.get_hours, { bID: r.key }) }}
                        title={this.getText(18662)}
                        okText={this.getText(18663)}
                        cancelText={this.getText(18664)}
                    > */}
                    {bIcon("copy", () => { this.setState({ modalCopy: r }) })}
                    {/* </Popconfirm> */}
                </Tooltip>
            </span>)
                : (<span>
                    {bIcon("check", () => { this.saveEdit(this.hours_id, this.api.save_hours, this.api.get_hours, 0) })}
                    {bIcon("close", () => { this.cancelEditInline(this.hours_id) })}
                </span>)
        }


        let columns = [
            { title: this.getText(13497), key: 'bName', dataIndex: 'bName', width: '20%', render: (t, r) => t },
            { title: this.getText(13498), key: 'bSun', dataIndex: 'bSun', width: '10%', render: (t, r) => timesField(t, r, 'bSun') },
            { title: this.getText(13499), key: 'bMon', dataIndex: 'bMon', width: '10%', render: (t, r) => timesField(t, r, 'bMon') },
            { title: this.getText(13500), key: 'bTue', dataIndex: 'bTue', width: '10%', render: (t, r) => timesField(t, r, 'bTue') },
            { title: this.getText(13501), key: 'bWed', dataIndex: 'bWed', width: '10%', render: (t, r) => timesField(t, r, 'bWed') },
            { title: this.getText(13502), key: 'bThu', dataIndex: 'bThu', width: '10%', render: (t, r) => timesField(t, r, 'bThu') },
            { title: this.getText(13503), key: 'bFri', dataIndex: 'bFri', width: '10%', render: (t, r) => timesField(t, r, 'bFri') },
            { title: this.getText(13504), key: 'bSat', dataIndex: 'bSat', width: '10%', render: (t, r) => timesField(t, r, 'bSat') },
            { title: this.getText(13505), width: '10%', render: (t, r) => buttonsEdit(r) },
        ];

        const { data, headers } = this.props.data[this.hours_id]

        let dataSource = data.map((x, index) => {
            let y = x.split("\f");
            let res = { key: y[0], index }
            headers.forEach((z, i) => { res = { ...res, [z]: y[i] } })
            return res;
        }).filter(f => {
            if (!this.state.valueSearch) return true;
            if (f.key.indexOf(this.state.valueSearch) > -1) return true;
            if (f.bName.indexOf(this.state.valueSearch) > -1) return true;
        })

        return (<Modal
            visible={this.props.visible}
            onCancel={this.props.onCancel}
            width={'95%'}
            footer={false}
            title={this.getModalTitle(this.getText(13496), null, null, {
                showsearch: () => { },
                valueSearch: this.state.valueSearch,
                onChangeSearch: e => { this.setState({ valueSearch: e.target.value }) }
            })}
        >
            <ResponsiveTable
                tableOnly={true}
                // idTable={this.props.data[this.hours_id]}
                dataSource={dataSource}
                columns={columns}
                percentageToMobile={[100]}
                ui={this.props.ui}
                pagination={{ pageSize: 10 }}
            />
            {this.modalNonValidDates()}
            {this.modalNonValidDatesCopy()}
            {this.modalOrderTimes()}
            {this.modalShabatTimes()}
            {this.modalCopyHoursAllBranches()}
        </Modal>)

    }

}
export default VirtualShopDowHours;
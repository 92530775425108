/* @flow */
/*jshint esversion: 6 */

import React from "react";
import PageToolbar from "../../components/PageToolbar";
import PictureView from "../../components/PictureView";
import LangContext from "../../contextProvider/LangContext";

class RearScreenImages extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {}

        this.id = "REAR_SCREEN_IMAGES";

        this.api = {
            get: "get_image_files_per_type",
            delete: "delete_image_files_per_type",
        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - הגדרת תמונות למסך אחורי";
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode,
            this.props.user.token,
            { _types: 'S,X,T' },
            this.api.get);
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10222);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    imagesData = () => {
        let data = this.props.data[this.id];
        let sData = [];
        let xData = undefined;
        let tData = undefined;
        if (data && data.data[0]) {
            data.data.map((x, index) => {
                let y = x.split("\f");
                switch (y[1]) {
                    case 'S': sData.push(y[0]); break;
                    case 'X': xData = y[0]; break;
                    case 'T': tData = y[0]; break;
                }
            });
        }
        return { slider: sData, birthDay: xData, thanks: tData };
    }

    titleBar = (title) => {

        let actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

        return (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={title}
                    actionButtons={actionButtons}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);
    }

    pictureView = (url, type) => {
        return (<div style={{ display: 'inline-block' }}>
            <PictureView
                user={this.props.user} data={this.props.data} dataActions={this.props.dataActions}
                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                url={url} imageType={type}
                onChange={this.refreshData}
                onUploadedSuccess={() => { }}
                onRemove={(e) => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, url, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}
                aspect={30 / 19} />
        </div>)
    }

    render() {
        let iData = this.imagesData();
        let sliderData = () => {
            let res = []
            for (let c = 0; c < 10; c++) { res.push(this.pictureView(iData.slider[c], 'S')) }
            return res;
        }
        let respoStyle = (s) => (window.innerWidth > 600) ?
            { width: s + '%', marginLeft: '5%', marginRight: '2%', verticalAlign: 'text-top', display: 'inline-block' } : {}
        return (
            <div>
                {this.titleBar(this.getText(11571))}
                <div style={respoStyle(70)}>
                    <h2 style={{ margin: 10 }}>{this.getText(11572)}</h2> {sliderData()}</div>
                <div style={respoStyle(15)}>
                    <h2 style={{ margin: 10 }}>{this.getText(11573)}</h2> {this.pictureView(iData.birthDay, 'X')}
                    <h2 style={{ margin: 10 }}>{this.getText(11574)}</h2> {this.pictureView(iData.thanks, 'T')}
                </div>

            </div>
        )
    }

}

export default RearScreenImages;


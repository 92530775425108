/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Modal, Button } from "antd";
import PageToolbar from "./PageToolbar";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
import "../App.css";

const topHeight = 340; // px
const rowHeight = 30;  // px


class ModalChangeMoveinFormat extends Component<Props, State> {

    constructor(props/*: Props*/) {
        super(props);
        this.state = { height: window.innerHeight }
        this.doOnResize = null;

        this.api = {
            get: "get_movein_defs_table",
        }

        this.id = 'MOVEIN_DEFS_TABLE';

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
        };
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: 1,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCancel = () => { this.props.uiActions.hideModal(TableIDs.modalChangeMoveinFormat) }

    handleChange = (mID) => {
        this.props.onChange(mID)
        this.handleCancel();
    }

    render() {

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.getText(12912)}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let columns = [
            {
                dataIndex: "mDesc",
                key: "mDesc",
                width: '75%',
                render: (text, record) => text
            }, {
                width: '25%',
                render: (text, record) => (<Button type="primary" size={"small"} onClick={() => { this.handleChange(record.mID) }}>{this.getText(12913)}</Button>)
            },
        ];



        return (<Modal
            //style={modalStyle}
            destroyOnClose={true}
            width={300}
            visible={false || this.props.toggle}
            title={titleBar}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={false}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                tableOnly={true}
                columns={columns}
                onChange={this.handleTableChange}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
        </Modal >)
    }
}

export default ModalChangeMoveinFormat;
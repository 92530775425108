/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import {
    Select, DatePicker, Form, Radio, Button, Row, Col, message, Checkbox
} from "antd";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import moment from "moment";
import ModalWorkingHouersPerEmployee from "../../components/ModalWorkingHouersPerEmployee";
import Jax from "../../Jax/jax.es6.module";
import LangContext from "../../contextProvider/LangContext";
import { disabledDatePicker } from "../../utils/utils";

const { Option, OptGroup } = Select;

const RadioGroup = Radio.Group;
const FormItem = Form.Item;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};

type State = {};

class HoursReport extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);

        this.id = "ADVANCE_HOURS_REPORT_SEARCH";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }


        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }

        this.state = {
            loading: false,
            visible: false,
            activeOnly: true,
            request: {
                _FromDate: this.dateFormatSql(false),
                _ToDate: this.dateFormatSql(true),
                _Employee: '',
                _EmployeeName: '',
                _inBranch: 1,
            }
        }

        this.refreshEmployees(this.state.activeOnly);

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    // send data to parent

    // componentWillReceiveProps(newProps) {
    //     let _state = this.state;
    //     _state.visible = newProps.toggle;
    //     this.setState(_state);
    // }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10166);
    }

    refreshEmployees = (active) => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees,
            this.props.user.companyCode,
            this.props.user.token,
            active ? "get_employee_list" : "get_employee_list_with_not_active");
    }

    dateFormatSql = (cur) => {
        let d = new Date();
        let y = d.getFullYear();
        let m = d.getMonth() + 1;
        let cd = cur ? d.getDate() : "1";
        return y + "-" + (m < 10 ? "0" : "") + m + "-" + (cd < 10 ? "0" : "") + cd;
    }

    dateParsing = (date) => {
        if (date) return date.split('-').map((x) => parseInt(x))
    }

    dateCreateFormat = (y, m, d) => {
        let zeros = (n) => String(n).length === 1 ? "0" + n : n
        return y + "-" + zeros(m) + "-" + zeros(d)
    }

    dateNowCreate = (s) => {
        let today = new Date();
        return this.dateCreateFormat(today.getFullYear(), today.getMonth(), s ? 1 : today.getDate())
    }

    changeBetwenDates = (sy, sm, sd, ey, em, ed, field) => {
        let validDate = (m, d) => {
            switch (m) {
                case 1: case 3: case 5: case 7: case 8: case 10: case 12: return d
                case 2: return d < 29 ? d : 28
                case 4: case 6: case 9: case 11: return d === 31 ? 30 : d
            }
        }

        if (!((sy === ey && (sm === em || ((em - sm) === 1 && ed <= sd))) || ((ey - sy) === 1 && sm === 12 && em === 1 && ed <= sd))) {
            if (field === "_FromDate") {
                let endY = sm === 12 ? sy + 1 : sy;
                let endM = sm === 12 ? 1 : sm + 1;
                let endD = validDate(endM, sd);
                this.setState({
                    request: {
                        ...this.state.request,
                        _FromDate: this.dateCreateFormat(sy, sm, sd),
                        _ToDate: this.dateCreateFormat(endY, endM, endD),
                    }
                })
            } else {
                let stY = em === 1 ? ey - 1 : ey;
                let stM = em === 1 ? 12 : em - 1;
                let stD = validDate(stM, ed);
                this.setState({
                    request: {
                        ...this.state.request,
                        _FromDate: this.dateCreateFormat(stY, stM, stD),
                        _ToDate: this.dateCreateFormat(ey, em, ed),
                    }
                })
            }
        } else {
            return true
        }
    }


    onChange = (field, value) => {
        let r = this.state.request;
        this.setState({
            request: {
                ...r, [field]: value,
            }
        });
    }

    onStartChange = (value) => {
        let sd = value ? value.format("YYYY-MM-DD") : this.dateNowCreate(true);
        let ed = this.state.request._ToDate ? this.state.request._ToDate : this.dateNowCreate(false);
        if (sd && ed) {
            let sdp = this.dateParsing(sd);
            let edp = this.dateParsing(ed);
            if (this.changeBetwenDates(sdp[0], sdp[1], sdp[2], edp[0], edp[1], edp[2], "_FromDate")) this.onChange('_FromDate', sd);
        } else {
            this.onChange('_FromDate', sd);
        }
    }

    onEndChange = (value) => {
        let sd = this.state.request._FromDate ? this.state.request._FromDate : this.dateNowCreate(true);
        let ed = value ? value.format("YYYY-MM-DD") : this.dateNowCreate(false);
        let sdp = this.dateParsing(sd);
        let edp = this.dateParsing(ed);
        if (this.changeBetwenDates(sdp[0], sdp[1], sdp[2], edp[0], edp[1], edp[2], "_ToDate")) this.onChange('_ToDate', ed);

    }

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }

    render() {

        console.log("this.dateFormatSql(true)", this.dateFormatSql(true))
        const divStyle = {
            textAlign: 'center',
            margin: "auto",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: window.innerWidth > 600 ? '60%' : '100%'
        };


        const { visible, loading, endOpen } = this.state;
        const { _FromDate, _ToDate } = this.state.request;

        let employeeData = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []);
        let listEmployee = employeeData.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let getName = (code) => {
            let res = '';
            employeeData.map((obj, index) => { if (obj['code'] === code) res = obj['name'] });
            return res;
        }

        let modalTitle = this.getText(11107) + ": " + this.state._Employee + " - " + this.state._EmployeeName;

        return (
            <div style={divStyle}>
                <PageToolbar
                    title={this.getText(11100)}
                    actionButtons={[]}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />
                <Row>
                    <Col span={window.innerWidth >= 600 ? 12 : 24}>
                        <FormItem label={this.getText(14176)}>
                            <RadioGroup
                                value={this.state.request._inBranch}
                                onChange={(e) => {
                                    let r = this.state.request;
                                    this.setState({ request: { ...r, _inBranch: e.target.value } });
                                }}>
                                <Radio value={1}>{this.getText(14174)}</Radio>
                                <Radio value={0}>{this.getText(14175)}</Radio>
                            </RadioGroup>
                        </FormItem>
                        <Row>
                            <Col span={window.innerWidth >= 600 ? 12 : 24}>
                                <FormItem label={this.getText(11101)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, null, _ToDate)}
                                        // disabledDate={this.disabledStartDate}
                                        // showTime
                                        format="DD-MM-YYYY"//"YYYY-MM-DD"
                                        value={_FromDate ? moment(_FromDate, "YYYY-MM-DD") : null}
                                        placeholder="Start"
                                        onChange={this.onStartChange}
                                        onOpenChange={this.handleStartOpenChange}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={window.innerWidth >= 600 ? 12 : 24}>
                                <FormItem label={this.getText(11102)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, _FromDate, this.dateFormatSql(true))}
                                        // disabledDate={this.disabledEndDate}
                                        // showTime
                                        format="DD-MM-YYYY"//"YYYY-MM-DD"
                                        value={_ToDate ? moment(_ToDate, "YYYY-MM-DD") : null}
                                        placeholder="End"
                                        onChange={this.onEndChange}
                                        open={endOpen}
                                        onOpenChange={this.handleEndOpenChange}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={window.innerWidth >= 600 ? 12 : 24}>
                        <FormItem>
                            <Checkbox
                                checked={this.state.activeOnly}
                                onChange={(e) => {
                                    let activeOnly = e.target.checked ? true : false
                                    this.setState({ activeOnly });
                                    this.refreshEmployees(activeOnly)
                                }}
                            >{this.getText(14470)}</Checkbox>
                        </FormItem>

                        <FormItem label={this.getText(11103)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                            <Select key={"_Employee"} style={{ width: '80%' }}
                                showSearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                onChange={(value) => {
                                    let r = this.state.request;
                                    this.setState({ request: { ...r, _Employee: value, _EmployeeName: getName(value) } });
                                }}
                                value={this.state.request._Employee}
                                notFoundContent={this.getText(11104)}
                            >
                                {listEmployee}
                            </Select>
                        </FormItem>
                        <Button form="search_working_hours_form" key="create" type="primary" loading={loading}
                            style={{ marginTop: 40 }} onClick={() => {
                                let req = this.state.request;
                                if (req._Employee === '') {
                                    message.error(this.getText(11105))
                                } else {
                                    this.props.uiActions.showModal(TableIDs.modalWorkingHouersPerEmployee, this.state.request)
                                }
                            }}
                        >{this.getText(11106)}</Button>

                    </Col >
                </Row>

                <ModalWorkingHouersPerEmployee
                    id={TableIDs.modalWorkingHouersPerEmployee}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalWorkingHouersPerEmployee)}
                    title={modalTitle}
                />
            </div>
        );
    }
}

export default HoursReport;
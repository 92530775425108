
import React from "react";
import {
    Checkbox, Input, InputNumber, Icon, Popconfirm, message, Form, Tooltip, Popover, Button, Select
} from 'antd';
//import ItemPicker from "../../components/ItemPicker";
import PageToolbar from "../../components/PageToolbar";
import { TwitterPicker, } from 'react-color';
import { colorPallete, colourHexToName, } from "../../utils/utils";
import TableIDs from '../../data/TableIDs.js';
import TextArea from "antd/lib/input/TextArea";
import SelectorItems from "../../components/SelectorItems";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";

const FormItem = Form.Item;
const { Option } = Select;


class FixLabSettings extends React.Component/*<Props, State>*/ {
    // DATA_SET_NAME: string;

    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            draggOpen: null
        }

        this.statuses_id = "FIX_LAB_STATUSES_LIST";
        this.questions_id = "FIX_LAB_QUESTIONS_SETTINGS_LIST";
        this.parameters_id = "FIX_LAB_PARAMETERS_LIST";
        this.answer_types_id = TableIDs.genericSelectorFixLabQuestionAnswerTypes;

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        this.api = {
            get_statuses: "get_fix_lab_statuses",
            answer_types_selector: "get_answer_types_list",
            get_questions: "get_fix_lab_questions_settings_list",
            get_parameters: "get_fix_lab_parameters_list",

            create_edit_statuses: "create_edit_fix_lab_statuses",
            create_edit_questions: "create_edit_fix_lab_questions",
            order_questions: "edit_order_fix_lab_questions",
            edit_parameters: "edit_fix_lab_parameters",

            delete_statuses: "delete_fix_lab_statuses",
            delete_questions: "delete_fix_lab_questions",
        }

        this.modalActions = [
            { id: this.statuses_id, script: this.api.get_statuses },
            { id: this.questions_id, script: this.api.get_questions },
            { id: this.parameters_id, script: this.api.get_parameters },
            { id: this.answer_types_id, selector: this.api.answer_types_selector },
        ];

        this.modalActions.map((x) => {
            if (props.data[x.id] === undefined) {
                props.data[x.id] = (x.script) ? { ...props.data.genericData } : { ...props.data.genericSelector };
            }
        });

        // if (props.data[this.DATA_SET_NAME] === undefined) {
        //     props.data[this.DATA_SET_NAME] = Object.assign({}, props.data.genericData);
        // }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    statusLock = (id) => {
        let paramsData = this.dataObject(this.parameters_id)[0];
        if (paramsData) {
            let a = [
                paramsData.pInStatus,
                paramsData.pFinStatus,
                paramsData.pCanStatus,
                paramsData.pCDStatus
            ];

            if (a.indexOf(id) > -1) return true;
        }
    }

    componentDidMount() {
        //  document.title = "Cash On Tab - הגדרות תיקונים";
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10220);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);

        this.modalActions.map((x) => {
            if (x.script) {
                if (x.id === this.parameters_id) {
                    this.props.dataActions.genericDataGetEditWithParams(
                        x.id, this.props.user.companyCode, this.props.user.token, {}, x.script);

                } else {
                    this.props.dataActions.genericDataSetFilter(
                        x.id, this.props.user.companyCode, this.props.user.token, {}, x.script);
                }
            } else {
                this.props.dataActions.genericSelectorRefreshDataset(
                    x.id, this.props.user.companyCode, this.props.user.token, x.selector);
            }
        });
    }

    refreshData = () => {
        this.modalActions.map((x) => {
            if (x.script) {
                this.props.dataActions.genericDataGetRequested(
                    x.id, this.props.user.companyCode, this.props.user.token, x.script);
            }
        })
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
    //         this.setState({}, this.initFilters(), {});
    //     }
    // }

    dataObject = (id) => {
        let headers = this.props.data[id].headers;
        return this.props.data[id].data.map((x, indexData) => {
            let y = x.split("\f");
            let ob = { key: y[0] }
            headers.map((z, index) => ob = { ...ob, [z]: y[index], index: indexData });
            return ob;
        });
    }

    titleBar = (title, buttons, id) => {
        let actionButtons = [];
        buttons.map((x) => {
            switch (x) {
                case 'add':
                    actionButtons.push({
                        buttonType: 'add',
                        buttonFunction: () => this.props.dataActions.genericDataStartInlineCreate(id)
                    });
                case 'refresh':
                    actionButtons.push({
                        buttonType: 'refresh',
                        buttonFunction: this.refreshData
                    });
            }
        });

        return (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={title}
                    actionButtons={actionButtons}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);
    }

    getTable = (title, id, colTable, draggable) => {

        let responsiveStyle = {
            width: (window.innerWidth > 600 && id !== this.parameters_id) ? '49%' : '98%',
            padding: '1%',
            display: 'inline-block',
            verticalAlign: 'text-top',
        }

        if (draggable) {
            let tableElement = document.getElementById(id);
            let lineElement = tableElement ? tableElement.getElementsByClassName('ant-table-row-level-0') : [];
            let dataObject = this.dataObject(id);
            if (tableElement && lineElement[0]) {
                dataObject.map((record, index) => {
                    let line = lineElement[index];
                    if (line) {
                        line.draggable = true;
                        line.ondragstart = (e) => {
                            e.dataTransfer.setData("keyStart", e.target.dataset.rowKey);
                        }
                        line.ondragover = (e) => { e.preventDefault() }
                        line.ondrop = (e) => {
                            this.props.dataActions.genericDataSaveEditWithParams(
                                id, this.props.user.companyCode, this.props.user.token, draggable[0], draggable[1],
                                { orderKey: e.dataTransfer.getData("keyStart"), order2: record.qOrder });
                        }
                    }
                });
            }
        }

        let titleButtons = (id !== this.parameters_id) ? ['add', 'refresh'] : ['refresh'];

        return (
            <div style={responsiveStyle} >
                {this.titleBar(title, titleButtons, id)}
                <ResponsiveTable ui={this.props.ui} tableOnly={true}
                    id={id}
                    bordered
                    dataSource={this.dataObject(id)}
                    columns={(window.innerWidth > 600) ? colTable :
                        colTable.map((x) => { return { ...x, title: (<span style={{ fontSize: 10 }}>{x.title}</span>) } })}
                    pagination={(id !== this.parameters_id) ? { pageSize: 14 } : false}
                    scroll={(id !== this.parameters_id) ? { y: 200 } : false}
                    locale={{ emptyText: this.getText(11503) }}
                />
            </div>)
    }

    getInputEdit = (id, field, record) => {
        let valueIn = this.props.data[id].editedRecord[field];
        let updateIn = (value) => {
            this.props.dataActions.genericDataSetEdit(id, { [field]: value.target.value })
        }

        if (this.props.data[id].editing !== record.key) {
            return record[field];
        }
        else {
            if (id === this.parameters_id)
                return (<TextArea value={valueIn} onChange={(value) => { updateIn(value) }} />);
            else
                return (<Input value={valueIn} onChange={(value) => { updateIn(value) }} />)
        }
    }

    getInputNumberEdit = (id, field, record) => {
        if ((this.props.data[id].editing !== record.key) || (field === 'sId' && this.statusLock(record.sId))) {
            return record[field];
        }
        else {
            return (<InputNumber min={0} step={1}
                value={this.props.data[id].editedRecord[field]}
                onChange={(value) => {
                    this.props.dataActions.genericDataSetEdit(id, { [field]: parseInt(value) })
                }} />)
        }
    }

    getCheckBoxEdit = (id, field, record) => {
        let data = this.props.data[id];
        if (data.editing !== record.key) {
            return record[field] === '1' ? this.getText(11504) : this.getText(11505);
        }
        else {
            return (<Checkbox
                checked={data.editedRecord[field] === '1' ? true : false}
                onChange={(value) => {
                    this.props.dataActions.genericDataSetEdit(id,
                        { [field]: value.target.checked ? '1' : '0' })
                }}
            />)
        }
    }

    getColorPicker = () => {
        const colorPickerContent = (
            <TwitterPicker
                colors={colorPallete}
                width={'100%'}
                triangle='hide'
                onChange={(color) => {
                    this.props.dataActions.genericDataSetEdit(this.statuses_id,
                        { ['sColor']: colourHexToName(color.hex) })
                }} />);

        return (
            <Tooltip title={this.getText(11506)}>
                <Popover
                    placement="top"
                    content={colorPickerContent}
                    title={
                        <PageToolbar
                            title={this.getText(11507)} actionButtons={[]} isModal={true}
                            ui={this.props.ui} uiActions={this.props.uiActions}
                            data={this.props.data} dataActions={this.props.dataActions}
                        />
                    }
                    trigger="click">
                    <Button className={"btn"} style={{
                        marginTop: "5px", marginLeft: "5px",
                        width: "100px", backgroundColor: this.props.data[this.statuses_id].editedRecord['sColor']
                    }}>
                        <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="edit" theme="outlined" />
                    </Button>
                </Popover>
            </Tooltip>
        )
    }

    getEditIcon = (store, record) => {
        return (
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                this.props.dataActions.genericDataStartEditing(store, record.key, record.index);
            }} />
        )
    }

    getDeleteIcon = (id, store, APIdelete, APIget) => {
        if (store === this.statuses_id && this.statusLock(id)) {
            return ''
        } else {
            return (
                <Popconfirm title={this.getText(11500)}
                    onConfirm={() => {
                        this.props.dataActions.genericDataDeleteRow(
                            store, id, this.props.user.companyCode, this.props.user.token, APIdelete, APIget);
                    }}
                    okText={this.getText(11501)} cancelText={this.getText(11502)}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                </Popconfirm>
            )
        }
    }

    getDragIcon = (store, record, save, get) => {
        let tableElement = document.getElementById(store).getElementsByClassName('ant-table-row-level-0');
        let lIndex = tableElement[record.index];

        if (lIndex) {
            lIndex.draggable = true;
            lIndex.ondragstart = (e) => { e.dataTransfer.setData("keyStart", record.key); }
            lIndex.ondragover = (e) => { e.preventDefault() }
            lIndex.ondrop = (e) => {
                this.props.dataActions.genericDataSaveEditWithParams(
                    store, this.props.user.companyCode, this.props.user.token, save, get,
                    { orderKey: e.dataTransfer.getData("keyStart"), order2: record.qOrder });
            }

        }

        return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"ordered-list"} />)
    }

    getInlineEditIcons = (store, onCheck) => {
        let isCreate = (this.props.data[store].editing == 0) ? true : false;
        return (<span>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                onClick={() => { onCheck() }} />
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                onClick={() => {
                    if (isCreate)
                        this.props.dataActions.genericDataCancelInlineCreate(store, true);
                    else
                        this.props.dataActions.genericDataCancelEditing(store);
                }} />
        </span>)
    }

    getSelector = (store, record, field, fieldValue, list) => {
        let data = this.props.data[store];
        let selectStyle = { minWidth: (window.innerWidth > 600 || store === this.parameters_id) ? 120 : 80 }
        let optionsList = null;
        let getOptionValue = null;
        let valueStatus = () => {
            let statusOb = this.dataObject(store)[0];
            return data.editedRecord[field] || !statusOb ? data.editedRecord[field] : statusOb[field];
        }

        if (store === this.parameters_id) {
            let statusOb = this.dataObject(list);
            optionsList = statusOb.map((x, index) => {
                return (<Option key={x['sId']} value={x['sId']} valuefilter={x['sId']}
                    style={{ ...selectStyle, backgroundColor: x['sColor'] }}>
                    <span style={{ backgroundColor: x['sColor'] }}>{x['sName']}</span></Option>)
            });
            getOptionValue = (code) => {
                let res = null;
                statusOb.map((obj, index) => { if (obj.sId === code) res = obj.sName });
                return res;
            }
        } else {
            optionsList = this.props.data[list].dataset.map((obj, index) => {
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']} style={selectStyle}>
                    {obj['name']}</Option>)
            });
            getOptionValue = (code) => {
                let res = null;
                this.props.data[list].dataset.map((obj, index) => { if (obj.code === code) res = obj.name });
                return res;
            }
        }
        if (data.editing !== record.key) {
            return getOptionValue(record[field]);
        }
        else {
            return (
                <Select
                    style={selectStyle}
                    key={field} showsearch filterOption={true}
                    value={(store !== this.parameters_id) ? data.editedRecord[field] : valueStatus()}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(
                            store, { [field]: value, [fieldValue]: getOptionValue(value) })
                    }}>
                    {optionsList}
                </Select>
            );
        }
    }

    responsiveParametersTable = () => {
        let record = this.dataObject(this.parameters_id)[0];
        if (record) {
            let pCol = this.parametersColumns()
            return pCol.map((x) => {
                if (x.key === 'pK') {
                    return (<div style={{ margin: 20, padding: 10, backgroundColor: 'yellow' }}> {this.getText(11508)} {x.render(text, record)}</div>)
                }
                let text = record[x.key];
                return (<div style={{ padding: 12, display: 'inline-block', width: 170 }}><h3> {x.title} </h3><div>{x.render(text, record)}</div> </div>)
            });
        }
    }

    createItemPicker = (local, codeField, nameField) => {

        let source = this.props.data[local].editedRecord;

        let onChangeItemCode = (code, name) => {
            if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
                this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
            } else {
                this.props.dataActions.genericDataSetEdit(local, { [codeField]: code, [nameField]: name });
            }
        }

        let checkIfItemExist = (s) => {
            if (s) {
                let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab",
                    (ob) => {
                        let itemsRows = (ob.data).split("\r");
                        let _ItemData = itemsRows[0].split('\f');
                        let _ItemCode = _ItemData[1];
                        let _ItemName = _ItemData[2];

                        if (!_ItemCode || itemsRows.length > 1) {
                            this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data, idSelector: codeField });
                        } else {
                            onChangeItemCode(_ItemCode, _ItemName)
                        }
                    },
                    (error) => {
                        message.error(this.getText(11509));
                    }
                );
            } else {
                onChangeItemCode("", "")
            }
        }

        let keyboardListener = (e) => {
            let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
            switch (e.key) {
                case "b": case "B": case "נ": case "M": case "m": case "צ":
                    if (e.ctrlKey) {
                        this.ctrlBKeyFlag = true;
                        this.barcodeReaderBuffer = '';
                    } else {
                        bufferUpdate(e.key)
                    }
                    break;

                case "Enter":
                    if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                    checkIfItemExist(this.barcodeReaderBuffer)
                    break;

                default:
                    bufferUpdate(e.key)
                    break;
            }
        }

        return (<span>
            <SelectorItems
                idSelector={codeField}
                user={this.props.user}
                uiActions={this.props.uiActions}
                ui={this.props.ui}
                dataActions={this.props.dataActions}
                data={this.props.data}
                ActionQueue={this.props.ActionQueue}
                ctrlBKeyFlag={null}
                isExpandMatrixFatherItem={false}
                itemType={1}
                value={source[codeField]}
                onChangeSearch={(s) => { onChangeItemCode(s.mCode, s.mName) }}
                onChange={(s) => { this.props.dataActions.genericDataSetEdit(local, { [codeField]: s.target.value }) }}
                onBlur={(s) => { checkIfItemExist(s.target.value) }}
                onKeyDown={keyboardListener}
            />
            <h4>{source[codeField] !== "" ? source[nameField] : this.getText(11510)}</h4>
        </span>)
    }

    parametersColumns = () => {
        return [
            {
                title: this.getText(11495),
                dataIndex: "pEsDays",
                key: "pEsDays",
                sorter: false,
                width: '10%',
                render: (text, record) => this.getInputNumberEdit(this.parameters_id, 'pEsDays', record)
            }, {
                title: this.getText(11496),
                dataIndex: "pEsHours",
                key: "pEsHours",
                sorter: false,
                width: '10%',
                render: (text, record) => this.getInputNumberEdit(this.parameters_id, 'pEsHours', record)
            }, {
                title: this.getText(11497),
                dataIndex: "pComment",
                key: "pComment",
                sorter: false,
                width: '15%',
                render: (text, record) => this.getInputEdit(this.parameters_id, 'pComment', record)
            }, {
                title: this.getText(11498),
                dataIndex: "pItem",
                key: "pItem",
                sorter: false,
                width: '15%',
                render: (text, record) => {
                    if (this.props.data[this.parameters_id].editing !== 'p') {
                        return record.pItemName;
                    } else {
                        return this.createItemPicker(this.parameters_id, "pItem", "pItemName");
                        //  let labelPicker = this.props.data[this.parameters_id].editedRecord['pItemName'];

                        // return (<ItemPicker
                        //     onChange={(value) => {
                        //         this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubNewItem: value.itemCode, clubNewItemName: value.itemName })
                        //         this.props.dataActions.genericDataSetEdit(this.parameters_id, { pItem: value.itemCode, pItemName: value.itemName })
                        //     }}
                        //     label={labelPicker}
                        //     data={this.props.data.itemCodes}
                        //     showDialog={this.props.uiActions.uiShowItemPickerDialog}
                        //     hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                        // />);
                    }
                }
            }, {
                title: this.getText(18318),
                dataIndex: "pPrintOptions",
                key: "pPrintOptions",
                sorter: false,
                width: '40%',
                render: (text, record) => {
                    const { editing, editedRecord } = this.props.data[this.parameters_id];
                    let value = editing !== 'p' ? text : editedRecord.pPrintOptions
                    if (!value) value = "00000000";
                    value = value.split("")

                    const sectionNames = [18319, 18320, 18321, 18322, 18323, 18324, 18325, 18326]
                    const onChange = (i, v) => {
                        value[i] = v;
                        this.props.dataActions.genericDataSetEdit(this.parameters_id, { pPrintOptions: value.join("") })
                    }

                    return sectionNames.map((x, i) => {
                        return (<Checkbox
                            checked={value[i] == '1'}
                            onChange={e => { onChange(i, e.target.checked ? "1" : "0") }}
                            disabled={editing !== 'p'}
                        >{this.getText(x)}</Checkbox>)
                    })
                }
            }, {
                title: this.getText(11499),
                dataIndex: "pK",
                key: "pK",
                sorter: false,
                width: '10%',
                render: (text, record) => {
                    if (this.props.data[this.parameters_id].editing !== 'p') {
                        return this.getEditIcon(this.parameters_id, record)
                    } else {
                        let onCheck = () => {
                            this.props.dataActions.genericDataSaveEdit(
                                this.parameters_id, this.props.user.companyCode, this.props.user.token,
                                this.api.edit_parameters, this.api.get_parameters);
                            message.success(this.getText(11511));
                        }
                        return this.getInlineEditIcons(this.parameters_id, onCheck);
                    }
                }
            },
        ];
    }

    render() {

        let statusesColumns = [
            {
                title: this.getText(11486),
                dataIndex: "sId",
                key: "sId",
                sorter: false,
                width: '15%',
                render: (text, record) => this.getInputNumberEdit(this.statuses_id, 'sId', record)
            }, {
                title: this.getText(11487),
                dataIndex: "sName",
                key: "sName",
                sorter: false,
                width: '40%',
                render: (text, record) => this.getInputEdit(this.statuses_id, 'sName', record)
            }, {
                title: this.getText(11488),
                dataIndex: "sColor",
                key: "sColor",
                sorter: false,
                width: '20%',
                render: (text, record) => {
                    if (this.props.data[this.statuses_id].editing !== record.key)
                        return (<div style={{ backgroundColor: text, width: '100%', height: '24px' }}></div>)
                    else return this.getColorPicker();
                }
            }, {
                title: this.getText(11489),
                dataIndex: "sId",
                key: "sId",
                sorter: false,
                width: '15%',
                render: (text, record) => {

                    if (this.props.data[this.statuses_id].editing !== record.key) {
                        return (
                            <span>
                                {this.getEditIcon(this.statuses_id, record)}
                                {this.getDeleteIcon(record.key, this.statuses_id, this.api.delete_statuses, this.api.get_statuses)}
                            </span >);
                    } else {
                        let onCheck = () => {
                            let data = this.dataObject(this.statuses_id);
                            let recordData = this.props.data[this.statuses_id].editedRecord;
                            let validStatus = true;

                            if (!recordData.sId) {
                                validStatus = false;
                                message.error(this.getText(11512));
                            } else if (!recordData.sName) {
                                validStatus = false;
                                message.error(this.getText(11513));
                            } else {
                                data.map((x, index) => {
                                    if (x.sId == recordData.sId && index != record.index) {
                                        validStatus = false;
                                        message.error(this.getText(11514));
                                    } else if (x.sName == recordData.sName && index != record.index) {
                                        validStatus = false;
                                        message.error(this.getText(11515));
                                    }
                                });
                            }

                            if (validStatus) {
                                this.props.dataActions.genericDataSaveEdit(
                                    this.statuses_id, this.props.user.companyCode, this.props.user.token,
                                    this.api.create_edit_statuses, this.api.get_statuses);
                                message.success(this.getText(11511));
                            }
                        }

                        return this.getInlineEditIcons(this.statuses_id, onCheck);

                    }
                }
            },
        ];

        let questionsColumns = [
            {
                title: this.getText(11490),
                dataIndex: "qText",
                key: "qText",
                sorter: false,
                width: '36%',
                render: (text, record) => this.getInputEdit(this.questions_id, 'qText', record)
            }, {
                title: this.getText(11491),
                dataIndex: "qType",
                key: "qType",
                sorter: false,
                width: '24%',
                render: (text, record) => this.getSelector(this.questions_id, record, 'qType', 'qTypeText', this.answer_types_id)

            }, {
                title: this.getText(11492),
                dataIndex: "qRequired",
                key: "qRequired",
                sorter: false,
                width: '12%',
                render: (text, record) => this.getCheckBoxEdit(this.questions_id, 'qRequired', record)
            }, {
                title: this.getText(11493),
                dataIndex: "qActive",
                key: "qActive",
                sorter: false,
                width: '12%',
                render: (text, record) => this.getCheckBoxEdit(this.questions_id, 'qActive', record)
            }, {
                title: this.getText(11494),
                dataIndex: "qId",
                key: "qId",
                sorter: false,
                width: '16%',
                render: (text, record) => {
                    if (this.props.data[this.questions_id].editing !== record.key) {
                        return (
                            <span>
                                {this.getEditIcon(this.questions_id, record)}
                                {this.getDeleteIcon(record.key, this.questions_id, this.api.delete_questions, this.api.get_questions)}
                            </span >);
                    } else {
                        let onCheck = () => {
                            let recordData = this.props.data[this.questions_id].editedRecord;
                            let valid = true;

                            if (!recordData.qText) {
                                valid = false;
                                message.error(this.getText(11516));
                            } else if (!recordData.qType) {
                                valid = false;
                                message.error(this.getText(11517));
                            }

                            if (valid) {
                                this.props.dataActions.genericDataSaveEdit(
                                    this.questions_id, this.props.user.companyCode, this.props.user.token,
                                    this.api.create_edit_questions, this.api.get_questions);
                                message.success(this.getText(11511));
                            }

                        }

                        return this.getInlineEditIcons(this.questions_id, onCheck);

                    }
                }
            },
        ];


        return (<div>
            {this.getTable(this.getText(11483), this.statuses_id, statusesColumns, false)}
            {this.getTable(this.getText(11484), this.questions_id, questionsColumns, [this.api.order_questions, this.api.get_questions])}
            {(window.innerWidth > 600) ?
                this.getTable(this.getText(11485), this.parameters_id, this.parametersColumns(), false) :
                this.responsiveParametersTable()}

        </div >)
    }

}

export default FixLabSettings;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import {
    Button, Modal, Select, Form, Checkbox, DatePicker, Radio, Switch, message, Icon, Tooltip, Popover, Popconfirm, InputNumber, Input, Row, Col
} from "antd";
import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import PageToolbar from "../../components/PageToolbar";
import GenericSelector from "../../components/GenericSelector";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import TagRelationRadio from "../../components/TagRelationRadio";
import { escapeRegExp } from "../../utils/utils";
import DateTimePicker from "../../components/DateTimePicker";
import GenericModal from "../../components/GenericModal";
import GenericModalForm from "../../components/GenericModalForm";

const { Option, OptGroup } = Select;
const topHeight = 340; // px
const rowHeight = 30;  // px


class Inventories extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.defaultFilters = {
            _fromDate: null,
            _toDate: null,
            _storageList: null,
            _isClosed: "0",
        }

        this.state = {
            height: window.innerHeight,
            modalCreateEdit: false,
            modalLines: false,
            modalDocs: false,
            modalDetGrid: false,
            modalFilter: false,
            filters: this.defaultFilters,

            filterItemsType: 1,
            inventoryNum: 0,
            inventoryIsClosed: false,
            itemCodeDetGrid: '',
            filterLinesType: 1,
            sumType: 1,
            filterLinesTags: '',
            filterLinesSuppliers: '',
            filterLinesItem: '',
            filterLinesItemName: '',
            filterLinesAddQty: null,
            iDocList: [],
            resetFixQty: '0',
            resetPopover: null,
            continuousEditing: false,
            loading: false,

            // filterItemsIncludeNoActive: false,
            // filterItemsIncludeDeletins: false,
            // filterItemsIncludeNoForInventory: false,

            sumInventoryCalculated: null,
            sumInventoryActual: null,
        }

        this.doOnResize = null;

        this.id = "INVENTORY_TABLE";
        this.lines_id = "INVENTORY_ITEMS_TABLE";
        this.docs_id = "INVENTORY_DOCUMENTS";
        this.det_id = "INV_ITEM_DET_TABLE";

        this.selectors = [
            { id: TableIDs.genericSelectorStorages, script: "get_storage_list" },
            { id: TableIDs.genericMultiSelectorItemTags, script: "get_tags_with_types" },
            { id: TableIDs.genericSelectorSuppliers, script: "get_suppliers" },
        ];

        this.selectors.map((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        });

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.lines_id] === undefined) props.data[this.lines_id] = { ...props.data.genericData };
        if (props.data[this.docs_id] === undefined) props.data[this.docs_id] = { ...props.data.genericData };
        if (props.data[this.det_id] === undefined) props.data[this.det_id] = { ...props.data.genericData };


        this.api = {
            get: "get_inventory_table",
            create_edit: "create_edit_inventory_table",
            delete: "delete_inventory",

            check_uniq: "check_uniq_inventory",
            reset: "reset_inventory_items",
            create_doc: "create_inventory_document",
            delete_doc: "delete_inventory_document",

            get_sum: "get_sum_items_for_inventory",
            get_lines: "get_inventory_items_table",
            get_docs: "get_inventory_documents",
            absorption: "absorption_inventory_documents",

            get_det: "get_inv_item_det",
            create_edit_det: "create_edit_inv_item_det",

        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: this.addItem
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }, {
                buttonType: 'filter',
                buttonFunction: () => { this.setState({ modalFilter: true }) }
            },
        ];

        this.filterItemRun = null
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        this.selectors.forEach((x, i) => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions[i === 1 ? 'genericMultiSelectorRefreshDataset' : 'genericSelectorRefreshDataset'],
                args: [x.id, this.props.user.companyCode, this.props.user.token, x.script]
            });
        });
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(13906);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 0,
            _sort_by: 'col_0',

            ...this.state.filters
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    linesInitFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _id: this.state.inventoryNum,
            _filterLinesType: this.state.filterLinesType,
            _filterLinesTags: this.state.filterLinesTags,
            _filterLinesSuppliers: this.state.filterLinesSuppliers,
            _filterLinesItem: this.state.filterLinesItem,
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
        };
        this.props.dataActions.genericDataSetFilter(
            this.lines_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_lines);

    }


    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    addItem = () => {
        this.setState({ filterItemsType: 1, modalCreateEdit: true }, () => {
            this.props.dataActions.genericDataStartInlineCreate(this.id);
        })
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let headers = this.props.data[this.id].headers;
        let col = headers.indexOf(sorter.field) + 1;

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleLinesTableChange = (pagination) => {
        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,

            _id: this.state.inventoryNum,
            _filterLinesType: this.state.filterLinesType,
            _filterLinesTags: this.state.filterLinesTags,
            _filterLinesSuppliers: this.state.filterLinesSuppliers,
            _filterLinesItem: this.state.filterLinesItem,
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
        };

        this.props.dataActions.genericDataSetFilter(
            this.lines_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_lines);
    }

    showsearch = (val) => {
        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            {
                ...this.props.data[this.id].filters,
                _words: escapeRegExp(val),
                // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                page: 1,
                _page_num: 1,
            },
            this.api.get)
    }

    closeInventory = (id, countingOnly) => {
        let dataSend = "_id\f_countingOnly\r" + id + "\f" + countingOnly;
        this.setState({ loading: true }, () => {
            this.sendAPI(this.api.create_doc, dataSend, () => {
                this.setState({ loading: false }, this.refreshData)
            })
        })
    }

    getEditIcons = (text, record) => {

        return (<span>
            <Tooltip title={this.getText(13953)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"eye"} onClick={() => {
                    this.setState({
                        modalLines: true,
                        inventoryNum: record['yNumber'],
                        filterLinesType: 1,
                        filterLinesTags: '',
                        filterLinesSuppliers: '',
                        filterLinesItem: '',
                        filterLinesItemName: '',
                        filterLinesAddQty: '',
                        inventoryIsClosed: record.yUpdateTime ? true : false,
                    }, () => {
                        this.linesInitFilters()
                        this.sendAPI(this.api.get_sum, record['yNumber'], (ob) => {
                            let data = ob.data ? ob.data.split("\f") : [null, null];
                            this.setState({
                                sumInventoryCalculated: data[0],
                                sumInventoryActual: data[1],
                            })
                        })
                    });
                }} />
            </Tooltip>
            {record.yUpdateTime ?
                (<Tooltip title={this.getText(13954)}>
                    <Popconfirm title={this.getText(13959)} okText={this.getText(13960)} cancelText={this.getText(13961)}
                        onConfirm={() => {
                            this.checkIfExsistStorage(record.yStorageId, () => {
                                this.sendAPI(this.api.delete_doc, record.key, this.refreshData);
                            })
                        }} >
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"unlock"} />
                    </Popconfirm>
                </Tooltip>)
                : (<span>
                    <Tooltip title={this.getText(13951)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                            let jsonData = this.getJson(record.yComments);
                            let filterType = jsonData.itemTags.length > 0 ? 2 : jsonData.suppliers.length > 0 ? 3 : jsonData.minusItems ? 4 : 1;
                            this.setState({ modalCreateEdit: true, filterItemsType: filterType }, () => {
                                this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                            });
                        }} />
                    </Tooltip>
                    <Tooltip title={this.getText(13952)}>
                        <Popconfirm
                            title={this.getText(18101)}
                            okText={this.getText(18102)}
                            cancelText={this.getText(18103)}
                            onConfirm={() => { this.closeInventory(record.key, 0) }}
                            onCancel={() => { this.closeInventory(record.key, 1) }}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"lock"} />
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip title={this.getText(13955)}>
                        <Popover
                            trigger="click"
                            title={this.getText(15481)}
                            visible={this.state.resetPopover === record.yNumber}
                            content={(<div>
                                <Checkbox
                                    checked={this.state.resetFixQty == '1'}
                                    onChange={(e) => { this.setState({ resetFixQty: e.target.checked ? '1' : '0' }) }}>{this.getText(15482)}</Checkbox>
                                <div>
                                    <Button style={{ margin: 5 }} type="primary" onClick={() => {
                                        this.setState({ resetPopover: null }, () => {
                                            this.sendAPI(this.api.reset, "iNumber\fwithFix\r" + record.key + '\f' + this.state.resetFixQty, (ob) => {
                                                if (ob.data == '1') message.success(this.getText(13957))
                                            })
                                        })
                                    }}>{this.getText(15483)}</Button>
                                    <Button style={{ margin: 5 }} onClick={() => { this.setState({ resetPopover: null }) }}>{this.getText(15484)}</Button>
                                </div>
                            </div>)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"retweet"} onClick={() => { this.setState({ resetPopover: record.yNumber }) }} />
                        </Popover>
                    </Tooltip>
                    <Tooltip title={this.getText(13956)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"vertical-align-top"} onClick={() => {
                            this.setState({ modalDocs: true, inventoryNum: record.key }, () => {
                                this.props.dataActions.genericDataSetFilter(
                                    this.docs_id,
                                    this.props.user.companyCode,
                                    this.props.user.token,
                                    { yNumber: record.key },
                                    this.api.get_docs);
                            });
                        }} />
                    </Tooltip>
                </span>)}
            <Tooltip title={this.getText(16831)}>
                <Popconfirm title={this.getText(16828)} okText={this.getText(16829)} cancelText={this.getText(16830)}
                    onConfirm={() => {
                        this.sendAPI(this.api.delete, record.key, this.refreshData);
                    }} >
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                </Popconfirm>
            </Tooltip>
        </span>)
    }

    getJsonParse = () => {
        let editedRecord = this.props.data[this.id].editedRecord;
        return this.getJson(editedRecord && editedRecord['yComments'] ? editedRecord['yComments'] : '');
    }

    getJson = (json) => {
        return json ? JSON.parse(json) :
            {
                itemTags: [],
                suppliers: [],
                tagRelation: true,
                minusItems: false,
                includeNoActive: true,
                includeDeletions: false,
                includeNoForInv: false,
            }
    }

    getSelector = (num, label, field, multi, group) => {

        let selectData = this.props.data[this.selectors[num].id].dataset;
        let editedRecord = this.props.data[this.id].editedRecord;

        let oSelect = selectData.map((obj) => {
            if (group) {
                return (<OptGroup label={obj.type.text} key={"type_" + obj.type.id}>
                    {obj.tags.map((item) => (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>))}
                </OptGroup>)
            } else {
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            }
        });

        let value = editedRecord ? editedRecord[field] : '';

        let onChange = null

        if (multi) {
            let jsonData = this.getJsonParse();
            value = jsonData[field];
            onChange = (v) => {
                jsonData = { ...jsonData, [field]: v }
                this.props.dataActions.genericDataSetEdit(this.id, { yComments: JSON.stringify(jsonData) })
            }
        } else {
            onChange = (v) => {
                this.checkIfExsistStorage(v, () => { this.props.dataActions.genericDataSetEdit(this.id, { yStorageId: v }) })
            };

        }

        let multiCheckbox = multi ? (<Checkbox onChange={(e) => {
            let fullList = group ?
                selectData.reduce((acc, i) => acc.concat((i.tags.map(j => j.id))), [])
                : selectData.map((obj) => obj['code']);
            onChange(e.target.checked ? fullList : []);
        }} />) : '';

        return (<Form.Item label={this.getText(label)}>
            {field === 'suppliers' ?
                (<GenericSelectorSuppliers {...this.props} notApi multi={multi} onChange={onChange} value={value} />)
                : field === 'itemTags' ?
                    (<GenericSelectorItemTags {...this.props} notApi notReport multi={multi} onChange={onChange} value={value} />)
                    : [
                        multiCheckbox,
                        <Select
                            disabled={!multi && this.props.data[this.id].editing != 0}
                            key={field} showSearch filterOption={true} optionFilterProp="valuefilter"
                            style={{ width: 300 }} onChange={onChange} value={value}
                            mode={multi ? "multiple" : undefined} maxTagCount={multi ? 4 : undefined}
                        >{oSelect}</Select>
                    ]}
        </Form.Item>);
    }

    titleBar = (title, gab, ab) => {
        return (<div style={{
            paddingLeft: '4px',
            paddingRight: '4px',
            paddingTop: '4px',
            paddingBottom: '4px',
        }}>
            <PageToolbar
                title={title}
                actionButtons={ab ? ab : []}
                genericActionButtons={gab}
                isModal={true}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                data={this.props.data}
                dataActions={this.props.dataActions}
            />
        </div>)
    }

    modalCreateEdit = () => {
        let editedRecord = this.props.data[this.id].editedRecord;
        console.log('yuda editedRecord ', editedRecord)
        let editing = this.props.data[this.id].editing;

        let jsonData = this.getJsonParse();

        let cbItem = (field, label) => {
            return (<Form.Item>
                <Checkbox
                    //checked={this.state[field]} onChange={e=> { this.setState({[field]: e.target.checked})}}
                    onChange={(value) => {
                        jsonData = { ...jsonData, [field]: value.target.checked }
                        this.props.dataActions.genericDataSetEdit(this.id, { yComments: JSON.stringify(jsonData) });
                    }}
                    checked={jsonData[field]}
                >{this.getText(label)}</Checkbox>
            </Form.Item>)
        }

        return (<Modal
            destroyOnClose={true}
            width={window.innerWidth > 600 ? "50%" : "95%"}
            visible={this.state.modalCreateEdit}
            title={this.titleBar(this.getText(editing == 0 ? 13917 : 13918), [])}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
                <Button key="back" onClick={this.handleCancel}>{this.getText(11719)}</Button>,
                <Button key="create" type="primary" onClick={this.handleOk}>{this.getText(11721)}</Button>,
            ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction} >
            <Form style={{ columnWidth: 'auto', columnCount: '1' }} >
                {this.getSelector(0, 13911, 'yStorageId', 0, 0)}
                <Form.Item label={this.getText(13919)}>
                    <DateTimePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        value={editedRecord['yBoundTime']}
                        onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { yBoundTime: e }) }}
                        maxDate={moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")}
                    />
                </Form.Item>
                <Form.Item label={this.getText(13923)}>
                    <Radio.Group
                        onChange={(v) => {
                            this.setState({ filterItemsType: v.target.value }, () => {
                                let newJson = { ...this.getJson(), minusItems: v.target.value == 4 }
                                // let minusItems = v.target.value == 4 ? 'true' : 'false';
                                // let yComments = '{ "itemTags": [], "suppliers": [], "tagRelation": true, "minusItems": ' + minusItems + ' }';
                                this.props.dataActions.genericDataSetEdit(this.id, { yComments: JSON.stringify(newJson) });
                            })
                        }}
                        value={this.state.filterItemsType}>
                        <Radio value={1}>{this.getText(13920)}</Radio>
                        <Radio value={2}>{this.getText(13921)}</Radio>
                        <Radio value={3}>{this.getText(13922)}</Radio>
                        <Radio value={4}>{this.getText(15394)}</Radio>
                    </Radio.Group>
                </Form.Item>
                {this.state.filterItemsType == 2 ? (<span>
                    {this.getSelector(1, 13924, 'itemTags', 1, 1)}
                    {/* <Switch checkedChildren={this.getText(12112)} unCheckedChildren={this.getText(12113)} */}
                    <TagRelationRadio showed={jsonData.itemTags ? jsonData.itemTags.join(',') : ""}
                        onChange={(value) => {
                            jsonData = { ...jsonData, tagRelation: value }
                            this.props.dataActions.genericDataSetEdit(this.id, { yComments: JSON.stringify(jsonData) });
                        }}
                        checked={jsonData.tagRelation}
                    />
                </span>)
                    : this.state.filterItemsType == 3 ? this.getSelector(2, 13925, 'suppliers', 1, 0)
                        : ''}

                {cbItem('includeNoActive', 16931)}
                {cbItem('includeDeletions', 16932)}
                {cbItem('includeNoForInv', 16933)}

            </Form>
        </Modal >)
    }

    getStatusText = (num) => {
        switch (num) {
            case '0': return this.getText(13941);
            case '1': return this.getText(13942);
            case '2': return this.getText(13943);
            case '3': return this.getText(13944);
        }
    }

    getTypeText = (num) => {
        switch (num) {
            case 1: return this.getText(13945);
            case 2: return this.getText(13946);
            case 3: return this.getText(13947);
            case 4: return this.getText(13948);
            case 5: return this.getText(13949);
            case 6: return this.getText(13950);
        }
    }

    reportButton = (obj) => {
        let generateReport = (reportName) => {
            let jasperParams = obj ? {
                DocumentNumber: obj.iDocNum,
                DocumentTypeID: 11,
                DocumentPOSID: obj.iDocPos,
                userID: this.props.user.userId,
                userName: encodeURIComponent(this.props.user.displayName),
                isOriginal: false,
            } : {
                _id: this.state.inventoryNum,
                _filterLinesType: this.state.filterLinesType,
                _filterLinesTags: this.state.filterLinesTags,
                _filterLinesSuppliers: this.state.filterLinesSuppliers,
                _filterLinesItem: this.state.filterLinesItem,
            }
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, {
                    ...jasperParams,
                    _words: encodeURIComponent(jasperParams._words),
                    _filterLinesItem: encodeURIComponent(jasperParams._filterLinesItem),
                    reportName: "reports/" + reportName,
                    REPORT_LOCALE: this.getText(101),
                }]
            });
        }

        let subButton = (text, type, reportName) => {
            let onClick = () => { generateReport(reportName) }
            return (
                <Tooltip title={this.getText(text)}>
                    <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={onClick} />
                </Tooltip>)
        }

        let content = obj ? (<div>
            {subButton(12721, "file-pdf", "DocumentCopy.pdf")}
            {subButton(12722, "file-excel", "DocumentCopy.xls")}
            {subButton(11855, "file-word", "DocumentCopy.docx")}
        </div>) : (<div>
            {subButton(12721, "file-pdf", "InventoryItems.pdf")}
            {subButton(12722, "file-excel", "InventoryItemsExcel.xls")}
            {subButton(11855, "file-word", "InventoryItems.docx")}
        </div>);

        return (<Tooltip title={this.getText(12720)}>
            <Popover content={content} title={this.getText(12723)} trigger="click">
                {obj ? (<Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />) : (
                    <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                        <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                    </Button>
                )}
            </Popover>
        </Tooltip >)
    }

    getDetGrid = (text, record) => {
        return (<Tooltip title={this.getText(15478)}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => {
                this.setState({ modalDetGrid: true, itemCodeDetGrid: record['mCode'] }, () => {
                    this.props.dataActions.genericDataSetFilter(
                        this.det_id,
                        this.props.user.companyCode,
                        this.props.user.token,
                        { _inventory: this.state.inventoryNum, _item: record['mCode'] },
                        this.api.get_det);
                })
            }} />
        </Tooltip>)
    }

    getEditDet = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.det_id];
        if (editing === record.key) {
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"} onClick={() => {
                    if (editedRecord.tFixQuantity == null) {
                        message.error(this.getText(18402));
                        return;
                    }
                    this.props.dataActions.genericDataJustSaveEditWithParams(
                        this.det_id,
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.api.create_edit_det,
                        { _inventory: this.state.inventoryNum, _item: this.state.itemCodeDetGrid });
                    setTimeout(() => {
                        this.props.dataActions.genericDataGetRequested(this.det_id, this.props.user.companyCode, this.props.user.token, this.api.get_det);
                        this.props.dataActions.genericDataGetRequested(this.lines_id, this.props.user.companyCode, this.props.user.token, this.api.get_lines);
                    }, 1000);
                }} />
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"} onClick={() => {
                    if (editing == 0)
                        this.props.dataActions.genericDataCancelInlineCreate(this.det_id);
                    else
                        this.props.dataActions.genericDataCancelEditing(this.det_id);
                }} />
            </span>)
        } else {
            return (<Tooltip title={this.getText(15479)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                    this.props.dataActions.genericDataStartEditing(this.det_id, record.key, record.index);
                }} />
            </Tooltip>)
        }
    }


    getQtyFixDet = (text, record) => {
        if (this.props.data[this.det_id].editing === record.key) {
            return (<InputNumber
                precision={2}
                value={this.props.data[this.det_id].editedRecord ? this.props.data[this.det_id].editedRecord.tFixQuantity : null}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.det_id, { tFixQuantity: e }) }} />)
        } else {
            return text;
        }
    }

    getLocationInput = (text, record) => {
        if (this.props.data[this.det_id].editing === record.key) {
            return (<Input
                value={this.props.data[this.det_id].editedRecord ? this.props.data[this.det_id].editedRecord.tLocation : ''}
                onChange={(e) => {
                    let value = e.target.value;
                    if (value.length > 10) {
                        message.error(this.getText(15486))
                    } else {
                        this.props.dataActions.genericDataSetEdit(this.det_id, { tLocation: e.target.value })
                    }
                }} />)
        } else {
            return text;
        }
    }

    getFilterLineField = (label, input, span) => {
        return (<Col span={window.innerWidth > 600 ? span : 24}>
            <Form.Item label={this.getText(label)}>{input}</Form.Item>
        </Col>)
    }

    filterLinesAddQty = () => {
        let params = 'tID\f_inventory\f_item\ftFixQuantity\ftLocation\r0\f' +
            this.state.inventoryNum + '\f' +
            this.state.filterLinesItem + '\f' +
            this.state.filterLinesAddQty + '\f';

        this.sendAPI(this.api.create_edit_det, params, this.linesInitFilters);
        this.setState({ filterLinesAddQty: null }, this.refreshData)
    }

    getGenericSelectorLines = (index, state, optionsGroup) => {
        let data = this.props.data[this.lines_id].data
        let dataSplit = data[0] ? data[0].split('\f') : null

        let filterList = dataSplit && dataSplit[index + 9] ? dataSplit[index + 9].split(',') : undefined;


        let props = {
            ...this.props,
            ...this.selectors[index],
            optionsGroup,
            filterList,
            multi: true,
            notApi: true,
            value: this.state[state] ? this.state[state].split(',') : [],
            onChange: (e) => { this.setState({ [state]: e ? e.join(',') : '' }, this.linesInitFilters) },
        }
        return state === 'filterLinesSuppliers' ? (<GenericSelectorSuppliers {...props} />) :
            state === 'filterLinesTags' ? (<GenericSelectorItemTags {...props} notReport />)
                : (<GenericSelector {...props} />)
    }

    renderCalcQty = (text, record) => {
        let cost = this.state.sumType == 1 ? 1 : parseFloat(record.mCost) ? parseFloat(record.mCost) : 0;
        let qty = parseFloat(text) ? parseFloat(text) : 0;
        return (qty * cost).toFixed(3);
    }

    renderDifference = (text, record) => {
        const { mCalculatedQuantity, mActualQuantity, mCost } = record;
        let cost = this.state.sumType == 1 ? 1 : parseFloat(mCost) ? parseFloat(mCost) : 0;
        let calcQty = parseFloat(mCalculatedQuantity) ? parseFloat(mCalculatedQuantity) : 0;
        let actQty = parseFloat(mActualQuantity) ? parseFloat(mActualQuantity) : 0;
        return ((actQty - calcQty) * cost).toFixed(3);
    }

    getContinuousEditingInput = (text, record) => {
        return (<InputNumber
            autoFocus={record.index == 0}
            value={null}
            onBlur={(e) => {
                const { count, filters, data } = this.props.data[this.lines_id];
                const { _page_num, _rows_num } = filters;

                let params = 'tID\f_inventory\f_item\ftFixQuantity\ftLocation\r0\f' +
                    this.state.inventoryNum + '\f' +
                    record.mCode + '\f' +
                    e.target.value + '\f';

                this.sendAPI(this.api.create_edit_det, params, () => {
                    this.refreshData()
                    if (record.index === (data.length - 1)) {
                        if (_page_num < (count / _rows_num)) {
                            this.props.dataActions.genericDataSetFilter(
                                this.lines_id, this.props.user.companyCode, this.props.user.token, { ...filters, page: _page_num + 1, _page_num: _page_num + 1 }, this.api.get_lines);
                        } else {
                            this.setState({ continuousEditing: false }, () => {
                                this.props.dataActions.genericDataSetFilter(
                                    this.lines_id, this.props.user.companyCode, this.props.user.token, { ...filters, page: 1, _page_num: 1 }, this.api.get_lines);
                            })
                        }
                    } else {
                        this.props.dataActions.genericDataSetFilter(
                            this.lines_id, this.props.user.companyCode, this.props.user.token, filters, this.api.get_lines);
                    }
                });
            }} />)
    }

    modalLines = () => {
        let continuousEditing = this.state.continuousEditing;

        let columns = [
            { title: this.getText(13934), dataIndex: 'mCode', key: 'mCode', width: '11%', render: (t, r) => (r.mIsMatrix == 1) ? r.mBarcode : t },
            { title: this.getText(13935), dataIndex: 'mBarcode', key: 'mBarcode', width: '11%', render: (t, r) => (r.mIsMatrix == 1) ? r.mBarcode2 : t },
            { title: this.getText(13936), dataIndex: 'mName', key: 'mName', width: '19%', render: (t) => t },
            { title: this.getText(13939), dataIndex: 'mCost', key: 'mCost', width: '8%', render: (t) => t },
            { title: this.getText(13937), dataIndex: 'mCalculatedQuantity', key: 'mCalculatedQuantity', width: '8%', render: this.renderCalcQty },
            { title: this.getText(13938), dataIndex: 'mActualQuantity', key: 'mActualQuantity', width: '8%', render: this.renderCalcQty },
            { title: this.getText(16317), key: 'mDifference', width: '8%', render: this.renderDifference },
            { title: this.getText(13940), dataIndex: 'mStatus', key: 'mStatus', width: '14%', render: this.getStatusText },
            { title: this.getText(15470), width: '5%', render: this.getDetGrid },
        ];

        if (continuousEditing) {
            columns.push({ title: this.getText(15496), width: '8%', render: this.getContinuousEditingInput })
        }

        let gridColumns = [
            { title: this.getText(15471), dataIndex: "tQuantity", key: "tQuantity", width: "15%", render: t => t },
            { title: this.getText(15472), dataIndex: "tFixQuantity", key: "tFixQuantity", width: "15%", render: this.getQtyFixDet },
            { title: this.getText(15473), dataIndex: "tSerial", key: "tSerial", width: "15%", render: t => t },
            { title: this.getText(15474), dataIndex: "tDocNo", key: "tDocNo", width: "15%", render: t => t },
            { title: this.getText(15475), dataIndex: "tDocTypeName", key: "tDocTypeName", width: "15%", render: t => t },
            { title: this.getText(15476), dataIndex: "tLocation", key: "tLocation", width: "15%", render: this.getLocationInput },
        ];

        if (!this.state.inventoryIsClosed) {
            gridColumns.push({ title: this.getText(15477), width: "10%", render: this.getEditDet });
        }

        let addDetButton = this.state.inventoryIsClosed ? []
            : [{
                buttonType: 'add',
                buttonFunction: () => { if (this.props.data[this.det_id].editing == -1) this.props.dataActions.genericDataStartInlineCreate(this.det_id) }
            }];

        const labelStyle = { marginRight: 30, marginLeft: 6 }

        return (<Modal
            destroyOnClose={true}
            width={"95%"}
            visible={this.state.modalLines}
            title={this.titleBar(this.getText(13933), [this.reportButton(false)])}
            onCancel={() => { this.setState({ modalLines: false, sumInventoryCalculated: null, sumInventoryActual: null }) }}
            footer={false}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}>
            <label style={labelStyle}>{this.getText(16368)}</label>
            <Select
                key={"filterLinesType"} style={{ width: 200 }}
                onChange={(v) => { this.setState({ filterLinesType: v }, this.linesInitFilters) }}
                value={this.state.filterLinesType}>
                {[1, 2, 3, 4, 5, 6].map((x) => (<Option key={x} value={x}>{this.getTypeText(x)}</Option>))}
            </Select>
            <label style={labelStyle}>{this.getText(16369)}</label>
            <Select
                key={"sumType"} style={{ width: 200 }}
                onChange={(v) => { this.setState({ sumType: v }) }}
                value={this.state.sumType}>
                <Option key={1} value={1}>{this.getText(16370)}</Option>
                <Option key={2} value={2}>{this.getText(16371)}</Option>
            </Select>
            <label style={labelStyle}>{this.getText(17264)}: <b>{this.state.sumInventoryCalculated}</b></label>
            <label style={labelStyle}>{this.getText(17265)}: <b>{this.state.sumInventoryActual}</b></label>
            <Row style={{ paddingBottom: 20 }}>
                {this.getFilterLineField(15494, this.getGenericSelectorLines(1, 'filterLinesTags', true), 7)}
                {this.getFilterLineField(15504, this.getGenericSelectorLines(2, 'filterLinesSuppliers'), 7)}
                {this.getFilterLineField(15495, (
                    <SelectorItemClassic {...this.props}
                        valueId={this.state.filterLinesItem} valueName={this.state.filterLinesItemName}
                        onChange={(code, name) => { this.setState({ filterLinesItem: code, filterLinesItemName: name, filterLinesAddQty: null }) }}
                        onEnter={this.linesInitFilters} notViewName
                    />)
                    , 5)}
                {!this.state.inventoryIsClosed ?
                    this.props.data[this.lines_id].data.length == 1 && this.state.filterLinesItem ?
                        this.getFilterLineField(15503, (
                            <span>
                                <InputNumber autoFocus precision={2}
                                    value={this.state.filterLinesAddQty}
                                    onChange={(e) => { this.setState({ filterLinesAddQty: e }) }}
                                    onPressEnter={() => { if (this.state.filterLinesAddQty) this.filterLinesAddQty() }}
                                />
                                <Button
                                    type="primary"
                                    style={{ margin: 5 }}
                                    onClick={this.filterLinesAddQty}
                                    disabled={this.state.filterLinesAddQty == null}
                                >{this.getText(15496)}</Button>
                            </span>)
                            , 4)
                        : (<Button type="primary" onClick={() => { this.setState({ continuousEditing: !continuousEditing }) }}>{this.getText(continuousEditing ? 16567 : 16568)}</Button>)
                    : ""}
            </Row>



            <ResponsiveTable
                idTable={this.props.data[this.lines_id]}
                tableOnly={true}
                columns={columns}
                percentageToMobile={[30, 35, 35]}
                onChange={this.handleLinesTableChange}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            // onRowClick={(record) => { if (!continuousEditing) this.setState({ filterLinesItem: record.mCode, filterLinesItemName: record.mName }, this.linesInitFilters) }}
            />

            <Modal
                destroyOnClose={true}
                width={"95%"}
                visible={this.state.modalDetGrid}
                title={this.titleBar(this.getText(15480), [], addDetButton)}
                onCancel={() => { this.setState({ modalDetGrid: false, itemCodeDetGrid: '' }, this.refreshData) }}
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                <ResponsiveTable
                    idTable={this.props.data[this.det_id]}
                    tableOnly={true}
                    columns={gridColumns}
                    percentageToMobile={[30, 35, 35]}
                    pagination={{ pageSize: 10 }}
                    // onChange={this.handleLinesTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />

            </Modal >

        </Modal >)
    }

    renderCheckboxDoc = (text) => {
        const { iDocList } = this.state;
        const { data, count } = this.props.data[this.docs_id];

        return (<Checkbox
            checked={(iDocList && iDocList.find(f => f == text)) || (!text && iDocList.length && iDocList.length == count)}
            indeterminate={!text && iDocList.length && iDocList.length != count}
            onChange={e => {
                if (text) {
                    this.setState({ iDocList: e.target.checked ? [...iDocList, text] : iDocList.filter(f => f !== text) })
                } else {
                    this.setState({ iDocList: e.target.checked ? data.map(x => x ? x.split("\f")[0] : '') : [] })
                }
            }} />)
    }

    modalDocs = () => {
        let columns = [
            { title: this.renderCheckboxDoc(), dataIndex: 'iDocNum', key: 'cb', width: '10%', render: this.renderCheckboxDoc },
            { title: this.getText(13965), dataIndex: 'iDocNum', key: 'iDocNum', width: '40%', render: (t) => t },
            { title: this.getText(13966), dataIndex: 'iDocTime', key: 'iDocTime', width: '40%', render: (t) => t },
            { title: this.getText(13967), width: '10%', render: (t, r) => this.reportButton(r) },
        ];

        let onCancel = () => { this.setState({ modalDocs: false, inventoryNum: 0 }) }

        return (<Modal
            destroyOnClose={true}
            width={600}
            visible={this.state.modalDocs}
            title={this.titleBar(this.getText(13962), [])}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>{this.getText(13963)}</Button>,
                <Button key="ok" type="primary" onClick={() => {
                    let dataSend = "iNumber\fiDocList\fwithFix\r" + this.state.inventoryNum + "\f" + this.state.iDocList.join(',') + "\f0";
                    this.sendAPI(this.api.absorption, dataSend, () => {
                        this.refreshData();
                        onCancel();
                    });
                }}>{this.getText(13964)}</Button>,
            ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction} >
            <ResponsiveTable
                idTable={this.props.data[this.docs_id]}
                tableOnly={true}
                columns={columns}
                pagination={{ pageSize: 10 }}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
        </Modal >)
    }

    getStorageName = (code) => {
        let dataset = this.props.data[this.selectors[0].id].dataset;
        let s = dataset.find(f => f.code === code);
        return s ? s.name : ''
    }

    getParseComment = (json) => {
        let jsonData = this.getJson(json);
        let selectorData = [];
        let titleText = '';
        let includeText = '';

        if (jsonData.includeNoActive) includeText += ' ' + this.getText(16931)
        if (jsonData.includeDeletions) includeText += ' ' + this.getText(16932)
        if (jsonData.includeNoForInv) includeText += ' ' + this.getText(16933)


        if (jsonData.itemTags.length > 0) {
            selectorData = this.props.data[this.selectors[1].id].dataset.reduce((acc, i) => acc.concat((i.tags)), []);
            titleText = this.getText(13926) + " ";
            titleText += jsonData.tagRelation ? this.getText(13928) : this.getText(13927);
            selectorData.forEach((x, i) => {
                if (jsonData.itemTags.indexOf(x.id) > -1) {
                    titleText += i === 0 ? ': ' : ', ';
                    titleText += x.id + " - " + x.text;
                }
            });

            titleText += includeText;

            return (<div style={{ maxHeight: 50, overflowY: 'scroll' }}>{titleText}</div>);

        } else if (jsonData.suppliers.length > 0) {
            selectorData = this.props.data[this.selectors[2].id].dataset;
            titleText = this.getText(13926) + " " + this.getText(13929);
            selectorData.forEach((x, i) => {
                if (jsonData.suppliers.indexOf(x.code) > -1) {
                    titleText += i === 0 ? ': ' : ', ';
                    titleText += x.code + " - " + x.name;
                }
            });

            titleText += includeText;

            return (<div style={{ maxHeight: 80, overflowY: 'scroll' }}>{titleText}</div>);

        } else if (jsonData.minusItems) {
            return this.getText(15394) + includeText;
        } else {
            return this.getText(13920) + includeText;
        }
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    checkIfExsistStorage = (sID, onSuccess) => {
        this.sendAPI(this.api.check_uniq, sID, (ob) => {
            let data = ob.data ? ob.data.split("\f") : []
            let issetInventory = parseInt(data[0]);
            let issetTransit = parseInt(data[1]);
            if (issetInventory && issetInventory != this.props.data[this.id].editedRecord['yNumber']) {
                message.error(this.getText(13932) + " " + issetInventory);
            } else if (issetTransit > 0) {
                message.error(issetTransit + " " + this.getText(17263));
            } else {
                onSuccess()
            }
        });
    }

    handleOk = () => {
        const { headers, editedRecord } = this.props.data[this.id];
        const onOk = () => {
            this.setState({ loading: true, modalCreateEdit: false }, () => {
                const dataSend = headers.join("\f") + "\r" + headers.map(x => editedRecord[x]).join("\f");
                this.sendAPI(this.api.create_edit, dataSend, () => {
                    this.setState({ loading: false }, this.refreshData)
                })
            })
        }

        if (!editedRecord['yStorageId']) {
            message.error(this.getText(13930))
        } else if (!editedRecord['yBoundTime']) {
            message.error(this.getText(13931))
        } else if (editedRecord.key > 0) {
            Modal.confirm({
                title: "",
                content: this.getText(19272),
                iconType: 'exclamation-circle',
                okText: this.getText(19273),
                cancelText: this.getText(19274),
                onOk,
                onCancel() { },
            })
        } else {
            onOk()
        }
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: false }, () => {
            if (this.props.data[this.id].editing == 0) {
                this.props.dataActions.genericDataCancelInlineCreate(this.id);
            } else {
                this.props.dataActions.genericDataCancelEditing(this.id);
            }
        })
    }

    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let columns = [
            { title: this.getText(13908), dataIndex: 'yNumber', key: 'yNumber', width: '8%', sorter: true, render: (t) => t },
            { title: this.getText(13909), dataIndex: 'yTime', key: 'yTime', width: '10%', sorter: true, render: (t) => t },
            { title: this.getText(13910), dataIndex: 'yBoundTime', key: 'yBoundTime', width: '10%', sorter: true, render: (t) => t },
            { title: this.getText(13911), dataIndex: 'yStorageId', key: 'yStorageId', width: '10%', sorter: true, render: (t) => this.getStorageName(t) },
            { title: this.getText(13912), dataIndex: 'yComments', key: 'yComments', width: '25%', sorter: true, render: (t) => this.getParseComment(t) },
            { title: this.getText(13913), dataIndex: 'yUpdateTime', key: 'yUpdateTime', width: '10%', sorter: true, render: (t) => t },
            { title: this.getText(13914), dataIndex: 'yFinalDoc', key: 'yFinalDoc', width: '8%', sorter: true, render: (t) => t },
            { title: this.getText(13915), dataIndex: 'yFinalZeroDoc', key: 'yFinalZeroDoc', width: '8%', sorter: true, render: (t) => t },
            { title: this.getText(13916), width: '10%', render: this.getEditIcons },
        ];

        const { _fromDate, _toDate, _storageList, _isClosed } = this.state.filters;
        const onChangeFilters = (e, f) => { this.setState({ filters: { ...this.state.filters, [f]: e } }) }
        const fromDateFilter = (<DateTimePicker format="DD/MM/YYYY" value={_fromDate} onChange={e => { onChangeFilters(e, '_fromDate') }} maxDate={_toDate} />)
        const toDateFilter = (<DateTimePicker format="DD/MM/YYYY" value={_toDate} onChange={e => { onChangeFilters(e, '_toDate') }} minDate={_fromDate} />)
        const storageListFilter = (<GenericSelector
            {...this.props} {...this.selectors[0]} multi
            value={_storageList?.split(',') ?? []} onChange={e => { onChangeFilters(e?.join(',') ?? "", '_storageList') }}
        />)
        const isCloseFilter = (<Radio.Group value={_isClosed} onChange={e => { onChangeFilters(e.target.value, "_isClosed") }}>
            <Radio value={'-1'}>{this.getText(19489)}</Radio>
            <Radio value={'0'}>{this.getText(19490)}</Radio>
            <Radio value={'1'}>{this.getText(19491)}</Radio>
        </Radio.Group>)

        return (<div style={divStyle}>
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                columns={columns}
                percentageToMobile={[30, 35, 35]}
                title={this.getText(13907)}
                actionButtons={this.actionButtons}
                // showsearch={this.showsearch}
                onChange={this.handleTableChange}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                loadingPresonal={this.state.loading || this.props.ui.workInProgress}
            />
            {this.modalCreateEdit()}
            {this.modalLines()}
            {this.modalDocs()}
            <GenericModalForm
                visible={this.state.modalFilter}
                onCancel={this.onCancelFilter}
                title={this.getText(19433)}
                width={1000}
                columnCount={2}
                footerBtn={[
                    { type: "primary", text: this.getText(19434), onClick: this.onFilter },
                    { text: this.getText(19435), onClick: this.onClearFilter },
                    { text: this.getText(19436), onClick: this.onCancelFilter },
                ]}
                formItems={[
                    { label: this.getText(19485), content: fromDateFilter },
                    { label: this.getText(19486), content: toDateFilter },
                    { label: this.getText(19487), content: storageListFilter },
                    { label: this.getText(19488), content: isCloseFilter },
                ]} />

        </div>)
    }

    onFilter = () => {
        const { filters } = this.state;
        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, { ...filters, _page_num: 1, page: 1 }, this.api.get);
        })
    }

    onClearFilter = () => {
        const filters = this.defaultFilters
        this.setState({ modalFilter: false, filters }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, { ...filters, _page_num: 1, page: 1 }, this.api.get);
        })
    }

    onCancelFilter = () => {
        this.setState({ modalFilter: false, filters: this.props.data[this.id].filters });
    }

}
export default Inventories;

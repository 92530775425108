/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import TableIDs from '../../data/TableIDs.js';
import { Button, Icon, Tooltip, Select, Form, Checkbox, Input, Row, Col } from "antd";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";
import ColorPicker from "../../components/ColorPicker";
import PictureView from "../../components/PictureView";
import ImageSearch from "../../components/ImageSearch";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

const { Option } = Select;
const { Item } = Form;

class VirtualShopCategories extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalItemOrders: false,
            tagParnetId: null,
            tagParnetName: null,
            modalAttribute: null,
            modalCategoryView: null,
            googleImage: null,
            modalItemsGrid: false,
        }

        this.settings_id = "ORDERS_SITE_SETTINGS";
        this.items_id = "VIRTUAL_SHOP_ITEMS_ORDER";

        this.api = {
            get_tags: "get_tags_to_site",
            get_items: "get_virtual_shop_items",
            order_tags: "order_tags_to_site",
            order_items: "order_items_to_site",
            tag_attr: "attribute_tag_to_parnet",
            save_view: "save_virtual_shop_categories",
            save_view_item: "save_virtual_shop_items_display",
        }

        this.selectorTags = TableIDs.genericSelectorTagsToSite;

        if (props.data[this.settings_id] === undefined) props.data[this.settings_id] = { ...props.data.genericData };
        if (props.data[this.selectorTags] === undefined) props.data[this.selectorTags] = { ...props.data.genericSelector }
        if (props.data[this.items_id] === undefined) props.data[this.items_id] = { ...props.data.genericSelector }
        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector }

        this.mobile = window.innerWidth <= 600 ? true : false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p, s) {
        if (!p.visible && this.props.visible || s.tagParnetId !== this.state.tagParnetId) {
            this.loadingData();
            setTimeout(this.loadingCategoriesOrder, 200);
        }

        if (p.visible && !this.props.visible) {
            this.setState({
                modalItemOrders: false,
                tagParnetId: null,
                tagParnetName: null,
                modalAttribute: null,
            })
        }
    }

    loadingData = () => {
        let tParnetId = this.state.tagParnetId ? this.state.tagParnetId : '';
        this.sendAPI(this.api.get_tags, 'tParnetId\r' + tParnetId, (ob) => {
            let dataset = [];
            if (ob.data) {
                dataset = ob.data.split('\r').map(x => {
                    let y = x.split('\f');
                    return { code: y[0], name: y[1], color: y[2], image: y[3], hide: y[4], branchList: y[5] }
                });
            }
            this.props.dataActions.setJson(this.selectorTags, { dataset })
        }, (e) => { console.error(e) })
        // this.props.dataActions.genericSelectorRefreshDataset(this.selectorTags, this.props.user.companyCode, this.props.user.token, this.api.get_tags, { tParnetId: this.state.tagParnetId });
    }

    dragOrderTable = (id, dataSource, ondrop) => {
        let tableElement = document.getElementById(id);
        let lineElement = tableElement ? tableElement.getElementsByClassName('ant-table-row-level-0') : [];

        if (tableElement && lineElement[0]) {
            tableElement.getElementsByClassName('ant-table-thead')[0].setAttribute("style", "display:none;")
            dataSource.forEach((record, index) => {
                let line = lineElement[index];
                if (line) {
                    line.setAttribute("style", "cursor: all-scroll;height:40px;");
                    line.draggable = true;
                    line.ondragstart = (e) => {
                        e.dataTransfer.setData("keyStart", e.target.dataset.rowKey);
                    }
                    line.ondragover = (e) => { e.preventDefault() }
                    line.ondrop = (e) => { ondrop(e, record) }
                }
            });
        }
    }

    dragOrderGrid = (id, dataSource, ondrop) => {
        let tableElement = document.getElementById(id);
        let lineElement = tableElement ? tableElement.getElementsByTagName('div') : [];
        console.log("tableElement:", tableElement, "lineElement:", lineElement)
        if (tableElement && lineElement[0]) {
            dataSource.forEach((record, index) => {
                let line = lineElement[index];
                if (line) {
                    line.draggable = true;
                    line.ondragstart = (e) => {
                        e.dataTransfer.setData("keyStart", e.target.dataset.rowKey);
                    }
                    line.ondragover = (e) => { e.preventDefault() }
                    line.ondrop = (e) => { ondrop(e, record) }
                }
            });
        }
    }

    loadingItemsOrder = (tag, ondrop) => {
        this.sendAPI(this.api.get_items, "_tag" + "\r" + tag, (ob) => {
            let dataset = ob.data ? ob.data.split("\r").map((x, i) => {
                let y = x.split("\f");
                return { key: y[0], name: y[1], image: y[2], isForDisplay: y[3] == '1', order: i + 1 }
            }) : [];
            this.props.dataActions.setJson(this.items_id, { dataset });
            // if (ondrop) this.dragOrderTable("items_order_table", dataset, ondrop)
        })
    }

    loadingCategoriesOrder = () => {
        this.dragOrderTable(
            'tags_order_table',
            this.props.data[this.selectorTags].dataset
                .filter(f => f.code !== this.props.data[this.settings_id].editedRecord['pSliderTag'])
                .map((x, i) => { return { ...x, key: x.code, order: i + 1 } }),
            (e, record) => {
                let tagParnetId = this.state.tagParnetId ? this.state.tagParnetId : ''
                let dataSend = "tId\ftToReplace\ftParnetId\r" + e.dataTransfer.getData("keyStart") + "\f" + record.key + '\f' + tagParnetId
                this.sendAPI(this.api.order_tags, dataSend, this.loadingData, (e) => { console.error(e) });
            })
    }

    sendAPI = (script, data, sCal, eCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, eCal);
    }

    orderItemsOpen = (key) => {
        this.setState({ modalItemOrders: key }, () => {
            this.loadingItemsOrder(key, (e, record) => {
                let dataSend = "tId\fiCode\fiToReplace\r" +
                    key + "\f" +
                    e.dataTransfer.getData("keyStart") + "\f" +
                    record.key;
                this.sendAPI(this.api.order_items, dataSend, () => {
                    this.loadingItemsOrder(key)
                }, (e) => { console.error(e) });
            })
        })
    }

    setAttrTag = (id, parnet) => {
        this.sendAPI(this.api.tag_attr, '_id\f_parnet\r' + id + '\f' + parnet, () => {
            this.setState({ modalAttribute: null }, this.loadingData)
        }, (e) => { console.error(e) })
    }

    editCategoryView = (obj) => {
        this.setState({ modalCategoryView: { ...this.state.modalCategoryView, ...obj } })
    }

    saveCategoryView = () => {
        const { code, name, color, image, hide, branchList } = this.state.modalCategoryView;
        let dataSend = '_id\f_color\f_image\f_hide\f_branchList\f_displayName\r' +
            (code ? code : '') + '\f' +
            (color ? color : '') + '\f' +
            (image ? image : '') + '\f' +
            (hide ? hide : '') + '\f' +
            (branchList ? branchList : '') + '\f' +
            (name ? name : '');

        this.sendAPI(this.api.save_view, dataSend, () => {
            this.setState({ modalCategoryView: null, googleImage: null }, this.loadingData)
        }, (e) => { console.error(e) })
    }

    saveItemView = (arr) => {
        let dataSend = "_tag\f_item\fisForDisplay\r" + arr.map(x => this.state.modalItemOrders + "\f" + x.key + "\f" + (x.isForDisplay ? "0" : "1")).join("\r");
        this.sendAPI(this.api.save_view_item, dataSend, () => { this.loadingItemsOrder(this.state.modalItemOrders) }, (e) => { console.error(e) });
    }

    render() {
        console.log(this.props.data[this.selectorTags].dataset, 'this.selectorTags', this.state.modalCategoryView)
        let pSliderTag = this.props.data[this.settings_id].editedRecord['pSliderTag']

        let dataSource = this.props.data[this.selectorTags].dataset
            .filter(f => f.code !== pSliderTag && parseInt(f.code))
            .map((x, i) => { return { ...x, key: x.code, order: i + 1 } });

        let columns = [
            { key: 'order', dataIndex: 'order', width: '20%', render: (t) => t },
            { key: 'name', dataIndex: 'name', width: '60%', render: (t) => t },
            {
                width: '20%', render: (t, r) => {
                    let icons = this.state.tagParnetId ?
                        [
                            { type: 'table', onClick: () => { this.orderItemsOpen(r.key) }, tooltip: this.getText(51801) },
                            { type: 'logout', onClick: () => { this.setAttrTag(r.key, null) }, tooltip: this.getText(51804) },
                            { type: 'eye', onClick: () => { this.setState({ modalCategoryView: r }) }, tooltip: this.getText(15816) },
                        ]
                        : [
                            { type: 'table', onClick: () => { this.orderItemsOpen(r.key) }, tooltip: this.getText(51801) },
                            { type: 'appstore', onClick: () => { this.setState({ tagParnetId: r.key, tagParnetName: r.name }, this.loadingData) }, tooltip: this.getText(51802) },
                            { type: 'tags', onClick: () => { this.setState({ modalAttribute: { id: r.key, name: r.name, attr: null } }) }, tooltip: this.getText(51803) },
                            { type: 'eye', onClick: () => { this.setState({ modalCategoryView: r }) }, tooltip: this.getText(15816) },
                        ]

                    return (<div style={{ cursor: 'pointer' }}><TableLineIcons genericIcons={icons} /></div>)
                }
            },
        ];

        let title = this.state.tagParnetId ?
            (<span>
                <span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ tagParnetId: null, tagParnetName: null }, this.loadingData) }}>{this.state.tagParnetName} > </span>
                {this.getText(51805)}
            </span>)
            : this.getText(14157)

        let tagData = this.props.data[this.selectorTags].dataset.find(f => f.code === this.state.modalItemOrders);

        let modalAttributeName = this.state.modalAttribute ? this.state.modalAttribute.name : ''
        let modalAttributeId = this.state.modalAttribute ? this.state.modalAttribute.id : null
        let modalAttributeAttr = this.state.modalAttribute ? this.state.modalAttribute.attr : null;

        let itemsList = this.props.data[this.items_id].dataset;
        let allItemsIsForDisplay = itemsList.filter(f => f.isForDisplay ).length == itemsList.length


console.log("allItemsIsForDisplay", allItemsIsForDisplay)

        return (<GenericModal
            visible={this.props.visible} onCancel={this.props.onCancel} width={600} title={title}
            genericActionButtons={[
                <Tooltip title={this.getText(14307)}>
                    <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => { this.orderItemsOpen(pSliderTag) }}>
                        <Icon style={{ fontSize: 16 }} type={"apartment"} />
                    </Button>
                </Tooltip>
            ]}>
            <ResponsiveTable
                tableOnly={true}
                id={"tags_order_table"}
                ui={this.props.ui}
                dataSource={dataSource}
                columns={columns}
                scroll={{ y: window.innerHeight - 300 }}
                pagination={false}
            />
            <GenericModal
                visible={this.state.modalItemOrders}
                onCancel={() => { this.setState({ modalItemOrders: false }) }}
                width={550}
                title={this.getText(14306) + (tagData ? ' - ' + tagData.name : "")}
                genericActionButtons={[
                    <Tooltip title={this.getText(allItemsIsForDisplay ? 19728 : 19726)}>
                        <Button className={"btn"} style={{ marginTop: 5, marginLeft: 5 }} onClick={() => {
                            this.saveItemView(itemsList.map(x => { return { key: x.key, isForDisplay: allItemsIsForDisplay  } }))
                        }} ><Icon type={"poweroff"} style={{ fontSize: 16 }} /></Button>
                    </Tooltip>,
                    <Tooltip title={this.getText(this.state.modalItemsGrid ? 18710 : 18709)}>
                        <Button className={"btn"} style={{ marginTop: 5, marginLeft: 5 }} onClick={() => {
                            this.setState({ modalItemsGrid: !this.state.modalItemsGrid })
                        }}><Icon type={this.state.modalItemsGrid ? "menu" : "table"} style={{ fontSize: 16 }} /></Button>
                    </Tooltip>,

                ]}
            >
                <div id={"items_order_table"}>
                    <p style={{ padding: 15, background: "lightblue", borderRadius: 20 }}>{this.getText(19727)}</p>
                    {itemsList.map(x => {
                        let origin = window.location.origin;
                        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
                        const originUrl = isDev ? "http://147.235.163.248" : origin
                        const itemImg = x.image ? (<img src={originUrl + '/uploads/' + this.props.user.companyCode + '/' + x.image} style={{ width: "100%" }} />) : ""
                        const propsItem = {
                            draggable: true,
                            onDragStart: (e) => {
                                e.dataTransfer.setData("keyStart", x.key);
                            },
                            onDragOver: (e) => { e.preventDefault() },
                            onDrop: (e) => {
                                let dataSend = "tId\fiCode\fiToReplace\r" +
                                    this.state.modalItemOrders + "\f" +
                                    e.dataTransfer.getData("keyStart") + "\f" +
                                    x.key;
                                this.sendAPI(this.api.order_items, dataSend, () => {
                                    this.loadingItemsOrder(this.state.modalItemOrders)
                                }, (e) => { console.error(e) });
                            },
                            onClick: e => {
                                if (e.detail == 2) {
                                    this.saveItemView([x])
                                    // this.sendAPI(this.api.save_view_item, "_tag\f_item\fisForDisplay\r" + this.state.modalItemOrders + "\f" + x.key + "\f" + (x.isForDisplay ? "0" : "1"), () => {
                                    //     this.loadingItemsOrder(this.state.modalItemOrders)
                                    // }, (e) => { console.error(e) });
                                }
                            }
                        }

                        if (this.state.modalItemsGrid) {
                            return (<div {...propsItem}
                                style={{
                                    display: "inline-block",
                                    verticalAlign: "text-top",
                                    width: 100,
                                    height: 150,
                                    margin: 10,
                                    border: "1px solid #aaaaaa",
                                    borderRadius: 5,
                                    cursor: "all-scroll",
                                    textAlign: "center",
                                    fontSize: 16,
                                }}>
                                {itemImg}{x.name}
                                {!x.isForDisplay ?
                                    <div style={{ position: "relative", top: x.image ? -150 : -50, left: 0, color: "red", fontSize: 100, fontWeight: "bold" }}>X</div>
                                    : ""}
                            </div>)
                        } else {
                            return (<div {...propsItem}
                                style={{
                                    margin: 10,
                                    border: "1px solid #aaaaaa",
                                    borderRadius: 5,
                                    cursor: "all-scroll",
                                    textAlign: "center",
                                    fontSize: 25,
                                }}>
                                <Row>
                                    <Col span={4}>{itemImg}</Col>
                                    <Col span={20}>{x.name}</Col>
                                </Row>
                                {!x.isForDisplay ?
                                    <div style={{ position: "relative", top: x.image ? -70 : -50, left: 0, color: "red", fontSize: 50, fontWeight: "bold", height: 0 }}>X</div>
                                    : ""}
                            </div>)
                        }
                    })}
                </div>

                {/* <ResponsiveTable
                    tableOnly={true}
                    id={"items_order_table"}
                    ui={this.props.ui}
                    dataSource={this.props.data[this.items_id].dataset}
                    columns={[
                        { key: 'order', dataIndex: 'order', width: '20%', render: (t) => t },
                        { key: 'name', dataIndex: 'name', width: '80%', render: (t) => t },
                    ]}
                    scroll={{ y: window.innerHeight - 300 }}
                    pagination={false}
                /> */}
            </GenericModal>
            <GenericModal
                visible={this.state.modalAttribute !== null} onCancel={() => { this.setState({ modalAttribute: null }) }}
                title={modalAttributeName + ' > ' + this.getText(51803)} width={400}
                footer={[
                    <Button key={"back"} onClick={() => { this.setState({ modalAttribute: null }) }} style={{ margin: 5 }}>{this.getText(51808)}</Button>,
                    <Button key={"create"} type="primary" onClick={() => { this.setAttrTag(modalAttributeId, modalAttributeAttr) }} style={{ margin: 5 }}>{this.getText(51807)}</Button>
                ]}>
                <Item label={this.getText(51806)}>
                    <Select
                        value={this.state.modalAttribute ? this.state.modalAttribute.attr : null}
                        onChange={(e) => { this.setState({ modalAttribute: { ...this.state.modalAttribute, attr: e } }) }}
                        style={{ width: '100%' }}>
                        {dataSource.filter(f => f.code !== modalAttributeId).map((x) => (<Option key={x.code} value={x.code}>{x.code + ' - ' + x.name}</Option>))}
                    </Select>
                </Item>
            </GenericModal>
            <GenericModal
                visible={this.state.modalCategoryView !== null}
                onCancel={() => { this.setState({ modalCategoryView: null, googleImage: null }) }}
                title={this.getText(15817)}
                width={400}
                footer={[
                    <Button key={"back"} onClick={() => { this.setState({ modalCategoryView: null, googleImage: null }) }} style={{ margin: 5 }}>{this.getText(51808)}</Button>,
                    <Button key={"create"} type="primary" onClick={this.saveCategoryView} style={{ margin: 5 }}>{this.getText(51807)}</Button>
                ]}>
                <Item>
                    <Checkbox
                        checked={this.state.modalCategoryView && this.state.modalCategoryView.hide === '1'}
                        onChange={(e) => { this.editCategoryView({ hide: e.target.checked ? '1' : '0' }) }}
                    >{this.getText(16250)}</Checkbox>
                </Item>
                <Item label={this.getText(17534)} style={{ display: this.state.modalCategoryView && this.state.modalCategoryView.hide === '1' ? "none" : "block" }}>
                    <Input
                        value={this.state.modalCategoryView ? this.state.modalCategoryView.name : ""}
                        onChange={(e) => { this.editCategoryView({ name: e.target.value }) }} />
                </Item>
                <Item label={this.getText(16390)} >
                    <GenericSelector
                        {...this.props}
                        multi
                        id={TableIDs.genericSelectorBranches}
                        api={"get_branch_list"}
                        value={this.state.modalCategoryView && this.state.modalCategoryView.branchList ? this.state.modalCategoryView.branchList.split(',') : []}
                        onChange={e => {
                            this.editCategoryView({ branchList: e ? e.join(',') : '' })
                        }} />
                </Item>
                <Item label={this.getText(15818)} >
                    <ColorPicker
                        value={this.state.modalCategoryView ? this.state.modalCategoryView.color : null}
                        onChange={(e) => { this.editCategoryView({ color: e }) }}
                    />
                </Item>
                <Item label={this.getText(15819)}>
                    <PictureView
                        {...this.props} imageType={'V'} aspect={1}
                        url={this.state.modalCategoryView ? this.state.modalCategoryView.image : null}
                        onRemove={() => { this.editCategoryView({ image: null }) }}
                        onChange={(url) => { this.editCategoryView({ image: url }) }}
                        onUploadedSuccess={() => { }} onUploadedFailed={() => { }}
                        googleImage={this.state.googleImage}
                    />
                    <div style={{ float: 'left' }}>
                        <ImageSearch
                            {...this.props}
                            onChange={(base64) => this.setState({ googleImage: base64 })}
                            defaultValue={this.state.modalCategoryView ? this.state.modalCategoryView.name : null}
                        />
                    </div>
                </Item>
            </GenericModal>
        </GenericModal >)
    }


}
export default VirtualShopCategories;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";
import { Checkbox, Input, message } from "antd";


class ServicesTypes extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "SERVICES_TYPES";

        this.api = {
            get: "get_services_types",
            edit: "edit_services_types",
            create: "create_services_types",
            delete: "delete_services_types",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20214);
    }

    renderDesc = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        if (editing == record.key) {
            return (<Input
                value={editedRecord.sDesc}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { sDesc: e.target.value }) }}
            />)
        } else {
            return text;
        }
    }

    renderIsActive = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        return (<Checkbox
            disabled={editing != record.key}
            checked={editing == record.key ? editedRecord.sIsActive == '1' : text == 1}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { sIsActive: e.target.checked ? '1' : '0' }) }}
        />)

    }

    renderEditCol = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];

        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={() => {
                    if (!editedRecord.sDesc) {
                        message.error(this.getText(19946))
                    } else {
                        this.props.dataActions.genericDataSaveEdit(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            editing == 0 ? this.api.create : this.api.edit,
                            this.api.get);
                    }
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }} />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                deleteQuetion={{ title: this.getText(20220), okText: this.getText(19942), cancelText: this.getText(19943) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}
            />)
        }
    }


    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 800,
            margin: "auto"
        };

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(20215)}
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
                columns={[
                    { title: gt(20216), key: "sID", dataIndex: "sID", width: "15%", render: t => t },
                    { title: gt(20217), key: "sDesc", dataIndex: "sDesc", width: "45%", render: this.renderDesc },
                    { title: gt(20218), key: "sIsActive", dataIndex: "sIsActive", width: "15%", render: this.renderIsActive },
                    { title: gt(20219), width: "15%", render: this.renderEditCol },
                ]}
            />
        </div>)
    }

}

export default ServicesTypes;
import React, { Component } from "react";
import "../../App.css";
import { Tooltip, Select, InputNumber, Checkbox, Button, message, Form, Radio } from "antd";
import PageToolbar from "../../components/PageToolbar";
import TableIDs from '../../data/TableIDs';
import LangContext from "../../contextProvider/LangContext";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";

const { Option, OptGroup } = Select;

class ItemDefaults extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { updateData: false }

        this.id = "ITEM_DEFULTS_SETTINGS";

        this.api = {
            get: "get_item_defaults",
            edit: "edit_item_defaults",
        }

        this.selectors = [
            // { id: TableIDs.genericSelectorPriceList, api: this.api.selector_price_list },
            { id: TableIDs.genericSelectorTaxType, api: "get_tax_types", action: "genericSelectorRefreshDataset" },
            { id: TableIDs.genericSelectorSalesAlerts, api: "get_sale_alert_list", action: "genericSelectorRefreshDataset" },
            { id: TableIDs.genericMultiSelectorItemTags, api: "get_tags_with_types", action: "genericMultiSelectorRefreshDataset" },
            { id: TableIDs.genericSelectorSuppliers, api: "get_suppliers", action: "genericSelectorRefreshDataset" },
            { id: TableIDs.genericSelectorRuler, api: "get_rulers_list", action: "genericSelectorRefreshDataset" },
            { id: "CURRENCY_SELECTOR", api: "get_currency_list", action: "genericSelectorRefreshDataset" },
        ];

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
        this.selectors.map((x) => { if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } } })

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.initFilter
        }];

        this.picksSuppliers = [];
        this.picksTaxType = [];
        this.picksAlerts = [];
        this.picksRuler = [];
        this.picksCurrency = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilter();
        this.selectors.map((x) => {
            this.props.dataActions[x.action](x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10223);
        if (this.state.updateData) this.setState({ updateData: false }, this.initFilter)
    }

    initFilter = () => {
        this.props.dataActions.genericDataGetEditRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleOk = () => {
        let recordData = this.props.data[this.id].editedRecord;
        if (!recordData.tSupplierID || recordData.tSupplierID === '') {
            message.error(this.getText(11575))
        } else {
            this.setState({ updateData: true }, () => {
                this.props.dataActions.genericDataJustSaveEdit(
                    this.id, this.props.user.companyCode, this.props.user.token, this.api.edit)
            });
        }
    }

    titleBar = (title, buttons) => {
        return (<div style={{ padding: '4px' }}>
            <PageToolbar title={title} actionButtons={buttons} isModal={false}
                ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataActions={this.props.dataActions} />
        </div>)
    }

    generateCheckboxField = (text, field) => {
        return (<label style={{ display: 'block' }}><Checkbox style={{ padding: '4px' }}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: (e.target.checked) ? 1 : 0 }) }}
            checked={(this.props.data[this.id].editedRecord[field] == 1) ? true : false} />{text}</label>)
    }

    inputLabelStyle = (text, con) => {
        let labelStyle = { display: 'inline-block', width: '30%', textAlign: 'right' }
        return (<div style={{ padding: '4px' }}><div style={labelStyle}>{text}</div>{con}</div>)
    }

    generateSelectOptions = (text, field, TableID, pick) => {
        if (field === 'tSupplierID') {
            return this.inputLabelStyle(text, (
                <div style={{ width: '65%', marginRight: '3%', display: 'inline-block' }}>
                    <GenericSelectorSuppliers {...this.props}
                        value={this.props.data[this.id].editedRecord[field]}
                        onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }} />
                </div>))
        } else {

            let list = this.props.data[TableID].dataset.
                filter((obj) => !pick.includes(obj['code'])).map((obj, index) => {
                    return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>
                        {obj['code'] + ' - ' + obj['name']}</Option>)
                });

            return this.inputLabelStyle(text, (<Select
                value={this.props.data[this.id].editedRecord[field]} defaultValue={''}
                style={{ width: '65%', marginRight: '3%' }} showSearch filterOption={true} optionFilterProp="valuefilter"
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }}
            ><Option key={0} value={''}>-</Option>{list}</Select>))

        }
    }

    generateSelectorItemTags = (text, field, TableID) => {

        let dataRecord = this.props.data[this.id].editedRecord[field];

        let tooltipList = () => {
            if (dataRecord) {
                let res = text + ": ";
                this.props.data[TableID].dataset.map((x) => {
                    x.tags.map((y) => { if (dataRecord.split(',').indexOf(y.id) >= 0) res += y.text + ', ' })
                });
                return res;
            }
        }

        return this.inputLabelStyle(text, (
            <Tooltip title={tooltipList()}>
                <div style={{ width: '67%', marginRight: '3%', display: 'inline-block' }}>
                    <GenericSelectorItemTags multi notApi  notReport {...this.props}
                        // <Select showSearch showArrow filterOption={true} optionFilterProp="valuefilter" mode="multiple"
                        // maxTagCount={2}
                        // style={{ width: '65%', marginRight: '3%' }} value={dataRecord ? dataRecord.split(',') : []}
                        onChange={(e) => {
                            this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.join(',') })
                        }}
                        value={this.props.data[this.id].editedRecord[field] ? this.props.data[this.id].editedRecord[field].split(',') : []}
                    // >
                    //      {this.props.data[TableID].dataset.map((node) => {
                    //         return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                    //             {node.tags.map((item) => {
                    //                 return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                    //             })}
                    //         </OptGroup>)
                    //     })}
                    // </Select> 
                    />
                </div>
            </Tooltip>))
    }

    generateInputNumberField = (text, field) => {
        return this.inputLabelStyle(text, (
            <InputNumber
                step={0.001}
                precision={3}
                value={this.props.data[this.id].editedRecord[field]}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }}
            />
        ))
    }

    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: (window.innerWidth > 600) ? '100%' : '100%',
            margin: 'auto'
        };

        const resToMobile = (n) => (window.innerWidth > 900) ? {
            width: (n * 0.9) + '%',
            margin: '1%',
            display: 'inline-block',
            verticalAlign: 'text-top'
        } : {
            display: 'block',
            padding: 8
        }

        let issetVirtualShop =
            this.props.data.PSEUDO_SELECTOR_UI_COMPONENTS_MODULES.dataset.find(f => f.name === '3' || f.name === '485') ? true
                : false;

        return (<div style={divStyle}>
            {this.titleBar(this.getText(11576), this.actionButtons)}
            <div style={resToMobile(20)}>
                {this.generateCheckboxField(this.getText(11577), 'tIsActive')}
                {this.generateCheckboxField(this.getText(11578), 'tIsByWeight')}
                {this.generateCheckboxField(this.getText(11579), 'tIsSold')}
                {this.generateCheckboxField(this.getText(11580), 'tIsPriceFixed')}
                {this.generateCheckboxField(this.getText(11581), 'tIsDiscountProhibited')}
                {this.generateCheckboxField(this.getText(11582), 'tIsForInventory')}
                {/* {this.generateCheckboxField(this.getText(11583), 'tIsSerialize')} */}
                {this.generateCheckboxField(this.getText(12696), 'tIsMatrixReverse')}
             
                <Form.Item label={this.getText(11583)}>
                    <Radio.Group
                        value={this.props.data[this.id].editedRecord['tIsSerialize']}
                        onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['tIsSerialize']: e.target.value }) }}
                        >
                        <Radio value="0">{this.getText(18499)}</Radio><br />
                        <Radio value="1">{this.getText(18500)}</Radio><br />
                        <Radio value="2">{this.getText(18501)}</Radio><br />
                        <Radio value="3">{this.getText(18502)}</Radio>
                    </Radio.Group>
                </Form.Item>
            </div>
            <div style={resToMobile(40)}>
                {this.generateSelectOptions(this.getText(11584), 'tSupplierID', TableIDs.genericSelectorSuppliers, this.picksSuppliers)}
                {this.generateSelectorItemTags(this.getText(11585), 'tTagList', TableIDs.genericMultiSelectorItemTags)}
                {this.generateSelectOptions(this.getText(11586), 'tTaxTypeId', TableIDs.genericSelectorTaxType, this.picksTaxType)}
                {this.generateSelectOptions(this.getText(11587), 'tSaleAlertId', TableIDs.genericSelectorSalesAlerts, this.picksAlerts)}
                {this.generateSelectOptions(this.getText(12697), 'tRulerId', TableIDs.genericSelectorRuler, this.picksRuler)}
                {this.generateSelectOptions(this.getText(17422), 'tCostCurrency', "CURRENCY_SELECTOR", this.picksCurrency)}
            </div>
            <div style={resToMobile(20)}>
                <div style={{ paddingRight: 8, paddingLeft: 8 }}><b>{this.getText(11588)}</b></div>
                {this.generateCheckboxField(this.getText(11589), 'tUpdateMatrixPrices')}
                {this.generateCheckboxField(this.getText(11590), 'tUpdateMatrixCost')}
                {this.generateCheckboxField(this.getText(11591), 'tUpdateMatrixTags')}
                {this.generateCheckboxField(this.getText(18448), 'tUpdateMatrixName')}
            </div>
            <div style={{ ...resToMobile(20), display: issetVirtualShop ? 'inline-block' : 'none' }}>
                <div style={{ paddingRight: 8, paddingLeft: 8 }}><b>{this.getText(13552)}</b></div>
                {this.generateInputNumberField(this.getText(13553), 'tVirtualShopMin')}
                {this.generateInputNumberField(this.getText(13554), 'tVirtualShopMax')}
                {this.generateInputNumberField(this.getText(13555), 'tVirtualShopStep')}
            </div>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
                <Button
                    type="primary"
                    loading={this.props.ui.workInProgress}
                    className={"form-button-save"}
                    icon={"check"}
                    onClick={this.handleOk}
                >{this.getText(11592)}</Button>
            </div>

        </div>)
    }
}

export default ItemDefaults;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Button, Checkbox, Input, message, Row } from "antd";
import { Col } from "antd/es/grid";
import ColorPicker from "../../components/ColorPicker";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";
import { Job, OutputType, ProcessType, User } from "../../JmReact";



class CustomerBusinessBranches extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "CUSTOMER_BUSINESS_BRANCHES";

        this.api = {
            get: "get_customer_business_branches",
            create: "create_customer_business_branches",
            edit: "edit_customer_business_branches",
            edit_active: "edit_active_customer_business_branches",
            delete: "delete_customer_business_branches",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.refreshData()
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (p.visible !== this.props.visible) {
            this.refreshData()
        }
    }

    refreshData = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { custID: this.props.visible?.mId }, this.api.get);
    }

    renderIsActive = (text, record) => {
        return (<Checkbox
            checked={text == 1}
            onChange={e => { this.sendAPI(this.api.edit_active, "bIsActive\fbID\r" + (e.target.checked ? "1" : "0") + "\f" + record.key, this.refreshData) }}
        />)
    }

    renderEditCol = (text, record) => {
        return (<TableLineIcons
            onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
            deleteQuetion={{ title: this.getText(20246), okText: this.getText(20247), cancelText: this.getText(20248) }}
            onDelete={() => {
                this.props.dataActions.genericDataDeleteRow(
                    this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
            }}
        />)
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    handleCancel = () => {
        if (this.props.data[this.id].editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
        else this.props.dataActions.genericDataCancelEditing(this.id);
    }

    handleOk = () => {
        const { editing } = this.props.data[this.id]
        this.props.dataActions.genericDataSaveEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token,
            editing == 0 ? this.api.create : this.api.edit,
            this.api.get,
            { custID: this.props.visible?.mId });
    }

    getField = (label, field) => {
        const { editedRecord } = this.props.data[this.id]
        const value = editedRecord[field]
        const onChange = e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }

        return (<Row style={{ padding: 5 }}>
            <Col span={9}>{label}</Col>
            <Col span={15}>
                {field == "bIsActive" ?
                    (<Checkbox checked={value == 1} onChange={e => { onChange(e.target.checked ? '1' : '0') }} />)
                    : field == "bRemark" ? (<Input.TextArea value={value} onChange={e => { onChange(e.target.value) }} />)
                        : (<Input value={value} onChange={e => { onChange(e.target.value) }} />)}
            </Col>
        </Row>)
    }

    render() {
        const gt = this.getText;

        const render = t => t

        const { editing } = this.props.data[this.id] ?? {}

        return (<GenericModal
            visible={this.props.visible != null}
            onCancel={this.props.onCancel}
            title={gt(20224) + " " + this.props.visible?.mId + " - " + this.props.visible?.mContactPersonName}
            actionButtons={[
                {
                    buttonType: 'add',
                    buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) }
                }, {
                    buttonType: 'refresh',
                    buttonFunction: this.refreshData
                },
            ]}>
            <ResponsiveTable
                tableOnly
                rowsFilter={r => r.key > 0}
                idTable={this.props.data[this.id]}
                columns={[
                    { title: gt(20225), key: "bNumber", dataIndex: "bNumber", width: "10%", render },
                    { title: gt(20226), key: "bName", dataIndex: "bName", width: "25%", render },
                    { title: gt(20227), key: "bAdr3", dataIndex: "bAdr3", width: "15%", render },
                    { title: gt(20228), key: "bTel1", dataIndex: "bTel1", width: "15%", render },
                    { title: gt(20229), key: "bTel2", dataIndex: "bTel2", width: "15%", render },
                    { title: gt(20230), key: "bIsActive", dataIndex: "bIsActive", width: "10%", render: this.renderIsActive },
                    { title: gt(20231), width: "10%", render: this.renderEditCol },
                ]}
                pagination={{ pageSize: 10 }}
            />
            <GenericModal
                visible={editing > -1}
                onCancel={this.handleCancel}
                title={gt(20232) + " " + this.props.visible?.mId + " - " + this.props.visible?.mContactPersonName}
                width={600}
                footer={[
                    <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{gt(20233)}</Button>,
                    <Button key={"create"} type={"primary"} onClick={this.handleOk} style={{ margin: 5 }}>{gt(20234)}</Button>,
                ]}>
                {this.getField(gt(20235), "bNumber")}
                {this.getField(gt(20236), "bName")}
                {this.getField(gt(20237), "bAdr1")}
                {this.getField(gt(20238), "bAdr2")}
                {this.getField(gt(20239), "bAdr3")}
                {this.getField(gt(20240), "bTel1")}
                {this.getField(gt(20241), "bTel2")}
                {this.getField(gt(20242), "bTel3")}
                {this.getField(gt(20243), "bManager")}
                {this.getField(gt(20244), "bRemark")}
                {this.getField(gt(20245), "bIsActive")}
            </GenericModal>
        </GenericModal>)
    }

}
export default CustomerBusinessBranches;
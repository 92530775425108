/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Table, Icon, Tooltip, Popover, Button, Row, Col } from "antd";

import TableIDs from "../../data/TableIDs";
//import PageToolbar from "../../components/PageToolbar";
import ModalAccumulationOfPoints from "../../components/ModalAccumulationOfPoints";
import ModalCreateEditPointsCustomer from "../../components/ModalCreateEditPointsCustomer";
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericModal from "../../components/GenericModal";
import FormItemInput from "../../components/FormItemInput";
import ReportTypeButton from "../../components/ReportTypeButton";
import ReportFavoriteSave from "../../components/ReportFavoriteSave";
import LangContext from "../../contextProvider/LangContext";

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class CustomerPoints extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "CUSTOMER_POINTS_LIST";
        this.state = {
            height: window.innerHeight,
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            modalReportBranches: false,
            BranchesPoints: {
                dateFrom: null,
                dateTo: null,
                isPerBranch: 0,
                branchList: '',
                reportName: 'reports/BranchesPoints.pdf',
            }
        };

        // calc page height
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[this.DATA_SET_NAME] === undefined) {
            props.data[this.DATA_SET_NAME] = Object.assign({}, props.data.genericData);
        }

        if (props.data["posList"] === undefined) {
            props.data["posList"] = Object.assign({}, props.data.genericData);
        }

        if (props.data["mutList"] === undefined) {
            props.data["mutList"] = Object.assign({}, props.data.genericData);
        }
        if (props.data["customerList"] === undefined) {
            props.data["customerList"] = Object.assign({}, props.data.genericData);
        }

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { dataset: [] }

        this.api = {
            get: "get_dealsActuals_list",
        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => {
                    this.setState({},
                        this.props.uiActions.showModal(TableIDs.modalCreateEditPointsCustomer,
                            { ...this.props.ui.data, customerId: 0 }));
                    this.props.dataActions.genericDataSetEdit("customer.editedRecord.customers",
                        { customerId: '0' });

                }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];


        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataGetWithParams, args: ["customerList", this.props.user.companyCode, this.props.user.token, {
                dataObject: "customerList"
            }, "data_get"]
        });
    }


    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10157);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            let p = this.props.ui.favoriteParams;
            if (p && p.reportName && p.reportName.indexOf("BranchesPoints") > -1) {
                this.setState({
                    modalReportBranches: true,
                    BranchesPoints: p,
                })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        // if (!this.props.ui.modalStack.includes(TableIDs.modalCreateEditPointsCustomer)) {
        this.setState({ height: window.innerHeight }, this.refreshData);
        // }
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }


    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 6;
        // fall through
        switch (sorter.field) {
            case "key": col--; // fallsthrough  // 0
            case "dId": col--; // fallsthrough 
            case "dCustomerId": col--; // fallsthrough 
            case "dCustomerName": col--; // fallsthrough 
            case "dCustomerPhone": col--; // fallsthrough 
            case "dName": col--; // fallsthrough 
            case "dSumPoint": break; // 6
            default:
                col = 0;
        }

        let request = {
            _id: 0,
            // _fieldId: false,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',

        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    reportButton = () => {
        let generateReport = (f, reportName) => {
            let params = {
                _id: 0,
                _words: encodeURIComponent(f._words),
                _page_num: 1,
                _rows_num: 999999,
                _isAsc: 1,
                reportName: "reports/" + reportName,
                REPORT_LOCALE: this.getText(101),
            }
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, params]
            });
        }

        let subButton = (text, type, reportName) => {
            let onClick = () => { generateReport(this.props.data[this.id].filters, reportName) }
            return (
                <Tooltip title={this.getText(text)}>
                    <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={onClick} />
                </Tooltip>)
        }

        let content = (<div>
            {subButton(12721, "file-pdf", "CustomerPoints.pdf")}
            {subButton(12722, "file-excel", "CustomerPointsExcel.xls")}
        </div>);

        return (
            <Tooltip title={this.getText(12720)}>
                <Popover content={content} title={this.getText(12723)} trigger="click">
                    <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                        <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                    </Button>
                </Popover>
            </Tooltip >)
    }

    getModalField = (label, type, field) => {
        return (<FormItemInput
            label={this.getText(label)}
            inputType={type}
            value={this.state.BranchesPoints[field]}
            onChange={e => {
                this.setState({
                    BranchesPoints: {
                        ...this.state.BranchesPoints,
                        [field]: e
                    }
                })
            }} />)
    }

    render() {
        const { BranchesPoints } = this.state;

        let gt = this.getText;
        let render = text => text;

        let renderEditCol = (text, record) => {
            return (
                <span>
                    <Tooltip title={gt(10944)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => {
                            this.setState({ customerId: record['dCustomerId'] },
                                this.props.uiActions.showModal(TableIDs.modalAccumulationOfPoints,
                                    { customerId: record['dCustomerId'], customerIndex: record.index }))
                        }} />
                    </Tooltip>
                    <Tooltip title={gt(10945)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"plus"} onClick={() => {
                            this.setState({ customerId: record['dCustomerId'] },
                                this.props.uiActions.showModal(TableIDs.modalCreateEditPointsCustomer,
                                    { customerId: record['dCustomerId'], customerIndex: record.index }));
                            this.props.dataActions.genericDataSetEdit("customer.editedRecord.customers",
                                { customerId: record['dCustomerId'], customerName: record['dCustomerName'] });
                            this.props.dataActions.genericDataSetFilter(
                                'MODAL_CREATE_EDIT_POINTS',
                                this.props.user.companyCode,
                                this.props.user.token,
                                { _id: record['dCustomerId'] },
                                'get_customer_points_data');
                        }} />
                    </Tooltip>
                </span>
            );
        }

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let request = {
            // ui-pagination 
            _id: 0,
            _fieldId: false,
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            // { title: gt(10938), dataIndex: "dId", key: "dId", width: '10%', render },
            { title: gt(10939), dataIndex: "dCustomerId", key: "dCustomerId", width: '15%', render },
            { title: gt(10940), dataIndex: "dCustomerName", key: "dCustomerName", width: '30%', render },
            { title: gt(12983), dataIndex: "dCustomerPhone", key: "dCustomerPhone", width: '15%', render },
            { title: gt(10941), dataIndex: "dName", key: "dName", width: '15%', render },
            { title: gt(10942), dataIndex: "dSumPoint", key: "dSumPoint", width: '15%', render },
            { title: gt(10943), width: '8%', render: renderEditCol }
        ];


        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[false, 30, 35, false, 35]}
                    title={gt(10937)}
                    actionButtons={this.actionButtons}
                    genericActionButtons={[
                        this.reportButton(),
                        <ReportFavoriteSave
                            {...this.props}
                            btnText={(<Icon type="save" />)}
                            btnTooltip={gt(16682)}
                            notSaveUrl
                            favoriteDesc={gt(16683)}
                            params={{
                                _id: 0,
                                _words: '',
                                _page_num: 1,
                                _rows_num: 999999,
                                _isAsc: 1,
                                reportName: "reports/CustomerPoints.pdf",
                                REPORT_LOCALE: this.getText(101),
                            }}
                        />,
                        <Tooltip title={gt(16332)}>
                            <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                                this.setState({ modalReportBranches: true })
                            }}><Icon style={{ fontSize: 16 }} type={"database"} theme="outlined" /></Button>
                        </Tooltip>
                    ]}
                    showsearch={
                        (val) => {
                            this.props.dataActions.genericDataSetFilter(
                                this.id, this.props.user.companyCode, this.props.user.token,
                                {
                                    ...request, _words: val
                                        .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                    page: 1,
                                    _page_num: 1,
                                }, this.api.get)
                        }}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ModalAccumulationOfPoints
                    id={TableIDs.modalAccumulationOfPoints}
                    user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalAccumulationOfPoints)}
                />

                <ModalCreateEditPointsCustomer
                    id={TableIDs.modalCreateEditPointsCustomer}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditPointsCustomer)}
                />
                <GenericModal
                    visible={this.state.modalReportBranches}
                    onCancel={() => { this.setState({ modalReportBranches: false }) }}
                    width={600}
                    title={gt(16331)}>
                    <Row>
                        <Col span={12}>{this.getModalField(16333, 'date', 'dateFrom')}</Col>
                        <Col span={12}>{this.getModalField(16334, 'date', 'dateTo')}</Col>
                    </Row>
                    {this.getModalField(16335, 'checkbox', 'isPerBranch')}
                    {BranchesPoints.isPerBranch == '1' ? (
                        <FormItemInput
                            {...this.props}
                            label={gt(16336)}
                            inputType={'select'}
                            id={TableIDs.genericSelectorBranches}
                            api={'get_branch_list'}
                            multi
                            value={BranchesPoints.branchList ? BranchesPoints.branchList.split(',') : []}
                            onChange={e => {
                                this.setState({
                                    BranchesPoints: {
                                        ...this.state.BranchesPoints,
                                        branchList: e ? e.join(',') : ''
                                    }
                                })
                            }}
                        />
                    ) : ""}
                    <ReportTypeButton
                        {...this.props}
                        params={BranchesPoints}
                        branchesField={'branchList'}
                        xlsReportName={'reports/BranchesPointsExcel.xls'}
                        datesToFavorites={[
                            { field: 'dateFrom', label: gt(16333) },
                            { field: 'dateTo', label: gt(16334) },
                        ]}
                        favoriteSave
                        favoriteDesc={gt(16331)}
                    />

                </GenericModal>
            </div>
        );
    }
}

export default CustomerPoints;



import React from "react";
import GenericSelector from "./GenericSelector";
import LangContext from "../contextProvider/LangContext";
import { Radio, Card } from "antd";
import GenericSelectorItemTags from "./GenericSelectorItemTags";

type State = {

}

type Props = {
    value: String,
    tagRelationValue: String,
    onChange: Function,
    tagRelationOnChange: Function,
}

class GenericSelectorItemTagsWithRelation extends React.Component<Props, State> {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        const gt = this.getText;
        const { tagRelationValue, tagRelationOnChange, value, onChange } = this.props;
        const valueJson = value.indexOf("{") > -1 ? JSON.parse(value) : {}
        console.log("valueJson", valueJson, value, tagRelationValue)

        const radioAndOr = (val, oc) => {
            return (<Radio.Group value={val} onChange={oc}>
                <Radio value={"0"}>And</Radio>
                <Radio value={"1"}>Or</Radio>
            </Radio.Group>)
        }

        const selectorCard = (num) => {
            return (<Card
                title={<div>
                    {gt(19223)}
                    {radioAndOr(valueJson["tagRelation" + num], e => { onChange(JSON.stringify({ ...valueJson, ["tagRelation" + num]: e.target.value })) })}
                </div>}>
                <GenericSelectorItemTags
                    {...this.props} multi notReport 
                    value={valueJson["tagList" + num] ? valueJson["tagList" + num].split(",") : []}
                    onChange={e => { onChange(JSON.stringify({ ...valueJson, ["tagList" + num]: e ? e.join(",") : "" })) }}
                />
            </Card>)
        }
        return (<div>
            {tagRelationValue == '2' ?
                <Card>
                    {selectorCard("1")}
                    {radioAndOr(valueJson.betwentRelation, e => { onChange(JSON.stringify({ ...valueJson, betwentRelation: e.target.value })) })}
                    {selectorCard("2")}
                </Card>
                : <GenericSelectorItemTags {...this.props} multi value={value ? value.split(",") : []} onChange={e => { onChange(e ? e.join(",") : "") }} />}
            <Radio.Group value={tagRelationValue} onChange={tagRelationOnChange}>
                <Radio value={'1'}>{gt(16697)}</Radio><br />
                <Radio value={'0'}>{gt(16696)}</Radio><br />
                <Radio value={'2'}>{gt(19222)}</Radio>
            </Radio.Group>
        </div>)
    }
}

export default GenericSelectorItemTagsWithRelation;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";
import KdsView from "./KdsView";
import { Button, Form, Checkbox, InputNumber, Select, Input, Row, Col, message } from "antd";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import PictureView from "../../components/PictureView";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const { Item } = Form;
const { Option } = Select;

class KdsConfigurations extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalCreateEdit: null,
            modalView: null,
        };

        this.id = "KDS_CONFIGURATIONS";

        this.api = {
            get: "get_kds_configurations",
            create_edit: "create_edit_kds_configurations",
            delete: "delete_kds_configurations",
        }

        this.newTemplate = {
            sID: '0',
            sName: '',
            sDispType: '1',
            sStatusList: '',
            sStatusListLeft: '',
            sPosList: '',
            sDeliveryMethods: '',
            sItemTagId: '',
            sZoomEnable: '0',
            sDisplayOrder: '1',
            sRefreshTime: '60',
            sScrollType: '1',
            sScrollTime: '10',
            sViewTimeRight: '',
            sViewTimeLeft: ''
        }

        this.kdsTypes = [
            { id: '1', text: 15868 },
            { id: '2', text: 15869 },
            { id: '3', text: 15870 },
            { id: '4', text: 16339 },
            { id: '5', text: 16480 },
            { id: '6', text: 17151 },
        ]

        this.selectors = [
            { id: TableIDs.genericSelectorOrderStatuses, api: 'external_orders_statuses_list' },
            { id: TableIDs.genericSelectorPos, api: 'list_pos' },
            { id: TableIDs.genericSelectorDeliveryMethods, api: 'delivery_methods_list' },
            { id: 'BONIM_TAGS', api: 'get_tags_from_type_2' },
            { id: TableIDs.genericSelectorBranches, api: 'get_branch_list' },
        ];

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        });

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.forEach(x => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15842);
    }

    createNew = () => {
        this.setState({ modalCreateEdit: this.newTemplate })
    }

    editRow = (text, record) => {
        return (<TableLineIcons
            onEdit={() => { this.setState({ modalCreateEdit: record }) }}
            onDelete={() => { this.deleteItem(record.key) }}
            deleteQuetion={{ title: this.getText(15851), okText: this.getText(15852), cancelText: this.getText(15853) }}
            genericIcons={[
                { type: 'copy', onClick: () => { this.saveEdit({ ...record, sID: '0', sName: record.sName + ' - ' + this.getText(15876) }) }, tooltip: this.getText(15875) },
                { type: 'eye', onClick: () => { this.setState({ modalView: record }) }, tooltip: this.getText(15877) },
            ]}
        />)
    }

    deleteItem = (key) => {
        this.props.dataActions.genericDataDeleteRow(
            this.id, key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
    }

    saveEdit = (request) => {
        this.props.dataActions.genericDataSaveEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, request);
        this.setState({ modalCreateEdit: null })
    }

    editInput = (field, text) => {
        let x = this.state.modalCreateEdit;
        if (x) {
            let value = x[field];
            let onChange = (v) => { this.setState({ modalCreateEdit: { ...x, [field]: v } }) }
            let gSelector = (i) => (<GenericSelector {...this.props} id={this.selectors[i].id} value={value} onChange={onChange} />)
            let gmSelector = (i) => (<GenericSelector {...this.props} id={this.selectors[i].id} multi value={value ? value.split(',') : []} onChange={(e) => { onChange(e ? e.join(',') : '') }} />)
            let psSelector = () => (<GenericSelectorPosStorages {...this.props} multi value={value ? value.split(',') : []} onChange={(e) => { onChange(e ? e.join(',') : '') }} />)
            let sSelector = (data) => (<Select style={{ width: '100%' }} value={value} onChange={onChange}>{data.map(x => (<Option value={x.id}>{this.getText(x.text)}</Option>))}</Select>)

            switch (field) {
                case 'sName': return (<Input value={value} onChange={(v) => { onChange(v.target.value) }} />)
                case 'sRefreshTime': case 'sScrollTime': return (<InputNumber value={value} onChange={onChange} />)
                case 'sStatusList': return gmSelector(0);
                case 'sPosList': return x.sDispType == '6' ? gmSelector(4) : psSelector();
                case 'sDeliveryMethods': return gmSelector(2);
                case 'sItemTagId': return gSelector(3);
                // case 'sDispType': return sSelector([{ id: '1', text: 15868 }, { id: '2', text: 15869 }, { id: '3', text: 15870 }, { id: '4', text: 16339 }]);
                case 'sDispType': return sSelector(this.kdsTypes);
                case 'sDisplayOrder': return sSelector([{ id: '1', text: 15871 }, { id: '2', text: 15872 }]);
                case 'sScrollType': return sSelector([{ id: '1', text: 15873 }, { id: '2', text: 15874 }]);
                case 'sZoomEnable': return sSelector([{ id: '0', text: 15885 }, { id: '1', text: 15886 }, { id: '2', text: 15887 }]);
                case 'sStatusListLeft': return this.state.modalCreateEdit && this.state.modalCreateEdit.sDispType == '1' ? gmSelector(0) : gSelector(0);
                case 'sViewTimeRight': case 'sViewTimeLeft':
                    return (<Checkbox checked={value == '1'} onChange={(e) => { onChange(e.target.checked ? '1' : '0') }}>{this.getText(text)}</Checkbox>)
                case 'sUrlToken':
                    let origin = window.location.origin;
                    const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
                    if (isDev) origin += '/cash-on-tab/build';
                    let fullUrl = origin + '/?token=' + value + '&type=9&code=' + this.props.user.companyCode + '&lang=' + this.getText(100);
                    return (<Input.TextArea value={fullUrl} onClick={(e) => { window.open(fullUrl, '_blank') }} />);
                case 'sDeviceToken':
                    return (<Input value={value}
                        onFocus={(e) => {
                            e.target.select()
                            document.execCommand('copy');
                            message.success(this.getText(15971))
                        }}
                    />);
                case 'sBackgroundImage':
                    return (<PictureView
                        {...this.props}
                        url={value}
                        onChange={onChange}
                        onRemove={() => { onChange('') }}
                        onUploadedSuccess={(response) => { }}
                        onUploadedFailed={(errorMessage, errorCode) => { }}
                        imageType={'K'}
                        aspect={30 / 19}
                    />)

                default: return ''
            }
        } else {
            return ''
        }
    }

    getEditFieldsCol1 = (issetId) => {
        let res = [
            { text: 15857, field: 'sName' },
            { text: 15858, field: 'sDispType' },
            { text: 17153, field: 'sBackgroundImage' },
        ];

        if (issetId) {
            res = [
                ...res,
                { text: 15969, field: 'sUrlToken' },
                { text: 15970, field: 'sDeviceToken' },
            ]
        }

        return res;
    }

    getEditFieldsCol2 = (type) => {
        switch (type) {
            case '1':
                return [
                    { text: 15880, field: 'sStatusList' },
                    { text: 15879, field: 'sStatusListLeft' },
                    { text: 15860, field: 'sPosList' },
                    { text: 15861, field: 'sDeliveryMethods' },
                ];
            case '2': case '3': case '5':
                return [
                    { text: 15859, field: 'sStatusList' },
                    { text: 15860, field: 'sPosList' },
                    { text: 15861, field: 'sDeliveryMethods' },
                ];
            case '6':
                return [
                    { text: 17152, field: 'sPosList' },
                    { text: 16069, field: 'sViewTimeRight', isCheckbox: true },
                    { text: 16070, field: 'sViewTimeLeft', isCheckbox: true },
                ];
            default:
                return []
        }
    }

    getEditFieldsCol3 = (type) => {

        switch (type) {
            case '1':
                return [
                    { text: 15865, field: 'sRefreshTime' },
                    { text: 15867, field: 'sScrollTime' },
                    { text: 15866, field: 'sScrollType' },
                    { text: 16069, field: 'sViewTimeRight', isCheckbox: true },
                    { text: 16070, field: 'sViewTimeLeft', isCheckbox: true },
                ];
            case '2':
                return [
                    { text: 15865, field: 'sRefreshTime' },
                    { text: 15864, field: 'sDisplayOrder' },
                    { text: 15888, field: 'sStatusListLeft' },
                    { text: 15863, field: 'sZoomEnable' },
                ];
            case '3':
                return [
                    { text: 15865, field: 'sRefreshTime' },
                    { text: 15862, field: 'sItemTagId' },
                    { text: 15864, field: 'sDisplayOrder' },
                    { text: 15888, field: 'sStatusListLeft' },
                    { text: 15863, field: 'sZoomEnable' },
                ];
            case '5':
                return [
                    { text: 15865, field: 'sRefreshTime' },
                    // { text: 16541, field: 'sScrollTime' },
                    { text: 15862, field: 'sItemTagId' },
                    { text: 15864, field: 'sDisplayOrder' },
                    { text: 15888, field: 'sStatusListLeft' },
                ];
            case '6':
                return [
                    { text: 15865, field: 'sRefreshTime' },
                    { text: 15867, field: 'sScrollTime' },
                    { text: 15866, field: 'sScrollType' },
                ];
            default:
                return []
        }
    }

    getModalEditCol = (list) => {
        let modalColStyle = { paddingRight: 20, paddingLeft: 20 }
        return (<Col span={window.innerWidth > 600 ? 8 : 24} style={modalColStyle}>
            {list.map(x => {
                return (x.isCheckbox) ?
                    (<Item>{this.editInput(x.field, x.text)}</Item>) :
                    (<Item label={this.getText(x.text)}>{this.editInput(x.field)}</Item>)
            })}
        </Col>)
    }

    getDispTypeText = (id) => {
        let findItem = this.kdsTypes.find(f => f.id === id);
        return this.getText(findItem.text)
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        const divStyle = {
            align: 'center',
            dir: 'rtl', // dir: 'ltr'
            padding: 4,
        }

        let gt = this.getText;
        let mEdit = this.state.modalCreateEdit;
        let sDispType = mEdit ? mEdit.sDispType : '0';


        return (<div style={divStyle}>
            <GenericTable
                id={this.id}
                getApi={this.api.get}
                title={gt(15843)}
                columns={[
                    { title: gt(15844), key: 'sID', dataIndex: 'sID', sorter: true, width: '10%', render: t => t },
                    { title: gt(15845), key: 'sName', dataIndex: 'sName', sorter: true, width: '35%', render: t => t },
                    { title: gt(15846), key: 'sDispType', dataIndex: 'sDispType', sorter: true, width: '35%', render: this.getDispTypeText },
                    { title: gt(15847), key: 'sRefreshTime', dataIndex: 'sRefreshTime', sorter: true, width: '10%', render: t => t },
                    // { title: gt(15848), key: 'sScrollType', dataIndex: 'sScrollType', sorter: true, width: '10%', render: t => t },
                    // { title: gt(15849), key: 'sScrollTime', dataIndex: 'sScrollTime', sorter: true, width: '10%', render: t => t },
                    { title: gt(15850), width: '10%', render: this.editRow },
                ]}
                addButton={this.createNew}
                searchBox
                {...this.props}
            />


            <GenericModal
                visible={mEdit}
                onCancel={() => { this.setState({ modalCreateEdit: null }) }}
                title={gt(15854)}
                footer={[
                    <Button key="Back" onClick={() => { this.setState({ modalCreateEdit: null }) }}>{gt(15855)}</Button>,
                    <Button key="Save" type="primary" onClick={() => { this.saveEdit(mEdit) }}>{gt(15856)}</Button>,
                ]}>
                <Row>
                    {this.getModalEditCol(this.getEditFieldsCol1(mEdit && parseInt(mEdit.sID)))}
                    {this.getModalEditCol(this.getEditFieldsCol2(sDispType))}
                    {this.getModalEditCol(this.getEditFieldsCol3(sDispType))}
                </Row>
            </GenericModal>
            <GenericModal
                visible={this.state.modalView !== null}
                onCancel={() => { this.setState({ modalView: null }) }}
                height={window.innerHeight - 300}
                title={this.state.modalView ? this.state.modalView.sName : ''}
            >
                <KdsView {...this.props}
                    kdsData={this.state.modalView}
                    height={window.innerHeight - 200}
                    width={window.innerWidth - 100}
                    getText={this.getText}
                    sendAPI={this.sendAPI}
                    setJson={this.props.dataActions.setJson}
                    logo={this.props.user.profilePicture}
                    companyCode={this.props.user.companyCode}
                    isFromConfig
                />
            </GenericModal>

        </div>)
    }

}

export default KdsConfigurations;

import React from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import { Tooltip, Icon } from "antd";
import { Job, User, OutputType, ProcessType } from '../JmReact';


class ReportExcelBtn extends React.Component/*<Props, State>*/ {

    onPrint = () => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.props.api, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(this.props.dataSend);
        job.send("/cgi-bin/CashOnTab", ob => {
            let dataset = []
            if (ob.data) {
                ob.data.split("@").forEach(x => {
                    if (!x) return;
                    let name = "";
                    let data = [];
                    x.split("\r").forEach((y, i) => {
                        if (!y) return;
                        let z = y.split("\f");
                        if (!i) name = z.splice(0, 1)[0];
                        data.push(z)
                    })
                    dataset.push({ name, data })
                })
            }

            // console.log({ dataset })
            this.createExcel(dataset)
        }, e => { console.error(e) });
    }

    colValue = (index) => {
        let colList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        if (index <= 25) return colList[index];
        let a = index + 1;
        let b = a % 26;
        let c = (a - b) / 26;
        return colList[c - 1] + colList[b - 1];
    }

    extractArrayToExcel = (data) => {
        let res = {}
        let styles = []
        let lastCol = "A";
        let lastColIndex = 0;
        let lastRow = 1;
        data.forEach((x, i) => {
            lastRow = i + 1;
            x.forEach((y, yi) => {
                if (!i) styles.push({ width: 20 })
                if (y) {
                    let col = this.colValue(yi)
                    if (yi > lastColIndex) {
                        lastColIndex = yi
                        lastCol = col;
                    }
                    res = { ...res, [col + lastRow]: { t: "s", v: y } }
                }
            })
        })
        return {
            ...res,
            ['!cols']: styles,
            ["!ref"]: "A1:" + lastCol + lastRow
        }
    }

    createExcel = (data) => {
        let Sheets = {}
        let SheetNames = [];

        if (data) {
            data.forEach(x => {
                SheetNames.push(x.name);
                Sheets = { ...Sheets, [x.name]: this.extractArrayToExcel(x.data) }
            });
        }

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const wb = { Sheets, SheetNames };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: fileType });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = this.props.reportName + ".xlsx";
        a.click();
    }

    render() {
        return (<Tooltip title={this.props.tooltip}>
            <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={"file-excel"} onClick={this.onPrint} />
        </Tooltip>)
    }
}

export default ReportExcelBtn;

/* @flow */
/*jshint esversion: 6 */
/*jslint es6*/
import {StringView, Job, JobResponse} from "./JmReact";

export default class JmMultiJobDispatcher {
  jobs: Array<JmMultiJob>;
  callbackMap: Object;

  constructor(jobs: Array<Job>) {
    this.jobs = jobs;

    this.callbackMap = {};
    for (let i = 0, j = jobs.length; i < j; i++)
    {
      this.callbackMap[jobs[i].job.hash] = {
        error: jobs[i].callbackError,
        success: jobs[i].callbackSuccess,
        type: jobs[i].job.outputType
      };
    }
  }

  send(url: string, callbackSuccess: Function, callbackError: Function) {
    // let bin: Array<DataView> = [];
      let bin:Array<string> = [];
    let size:number = 0;
    for (let i = 0, j = this.jobs.length; i < j; i++)
    {
      // bin.push(this.jobs[i].job.getRawData());
        let json:string = JSON.stringify(this.jobs[i].job.getJsonData());
      //bin.push(Job.encodeLength(json) + json);
      bin.push(Job.writeLengthAsString(json) + ';' + json);

      // size += bin[bin.length - 1].byteLength;
    }

    // let mergedBin:Uint8Array = new Uint8Array(size);
    // let offset:number = 0;
    // for (let i = 0, j = bin.length; i < j; i++)
    // {
    //   mergedBin.set(new Uint8Array(bin[i].buffer), offset);
    //   offset += bin[i].byteLength;
    // }

    let oAjax = new XMLHttpRequest();
    let oThat = this;
    oAjax.open("POST", url, true);
    oAjax.responseType = "arraybuffer";
    oAjax.onerror = function (oEvent) {
      callbackError(oAjax.statusText, oAjax.status);
    };
    oAjax.onload = function (oEvent) {
      if (oAjax.status === 200) {
        let response:DataView = new DataView(oAjax.response);

        let offset:number = 0;
        let successes:number = 0;
        let fails:number = 0;


        while (offset < response.byteLength)
        {
          let size:number = response.getUint32(offset);
          offset += 4;

          let hash:string = new StringView(response.buffer, "UTF-8", offset, 40).toString().substr(0, 32);

          if (oThat.callbackMap[hash] !== undefined)
          {
            let jobResponse:JobResponse = new JobResponse(response.buffer.slice(offset - 4, offset + size));
            offset += size;

            if (jobResponse.success === 1)
            {
              oThat.callbackMap[hash].success(jobResponse);
              successes++;
            }
            else
            {
              oThat.callbackMap[hash].error(jobResponse.errorCode, jobResponse.errorMessage);
              fails++;
            }
          }
          else
          {
            window.console.log("Orphan hash: " + hash);
            // This is a blob response, there isn't a sure way to know which job
            // request it, so we have to ignore it
            offset += size;
            fails++;
          }

        }

        // Now that we are done dispaching the individual jobs, we can call the
        // general success callback
          callbackSuccess(successes, fails);

      } else {
        oAjax.onerror(oEvent);
      }
    };

    /*const saveData = (function () {
          var a = document.createElement("a");
          if (document != null && document.body != null) {
            document.body.appendChild(a);
          }
          a.style.display = "none";
          return function (data, fileName) {
              var blob = new Blob([data], {type: "octet/stream"}),
                  url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
          };
      }());
      saveData(mergedBin, "multi.job");*/

    // oAjax.send(mergedBin);
    oAjax.send(bin.join(""));
  }
}

export class JmMultiJob {
    job: Job;
    callbackError: Function;
    callbackSuccess: Function;

    constructor(job: Job, callbackSuccess: Function, callbackError: Function) {
        this.job = job;
        this.callbackSuccess = callbackSuccess;
        this.callbackError = callbackError;
    }
}

/* @flow */
/*jshint esversion: 6 */
import React, {Component} from "react";
import {Button} from "antd";
import "../App.css";

type CustomerPickerProps = {
    defaultCustomerId: string,
    onChange: Function,
    label: string,
    data: Array<string>,
    showDialog: Function,
    hideDialog: Function,
    disabled?: boolean
};

type State = {

};

class CustomerPicker extends Component<CustomerPickerProps, State> {


    handleClick() {
        this.props.showDialog(this.props.defaultCustomerId, this.props.data,this.handleChange.bind(this));
    }

    handleChange(state: Object) {
        if (state.selection !== undefined) {
            this.props.onChange({
                customerId: state.selection ? state.selection : "",
                customerName: state.customerName ? state.customerName : ""
            });
        }
        this.props.hideDialog();
    }

    render() {
        return (<div>
            <Button className={""} htmlType={"button"} loading={false} size={"default"} disabled={this.props.disabled} style={{width: '95%'}} type={"primary"} onClick={this.handleClick.bind(this)} icon={"edit"}>
                {this.props.label}
            </Button>

        </div>);
    }
}

export default CustomerPicker;
/* @flow */
/*jshint esversion: 6 */
import { Form, Row, Switch, Col, Card, Button, Checkbox, Icon, Select, message, Progress, Modal, Popconfirm } from "antd";
import React, { Component } from "react";
import "../../App.css";
import DateTimePicker from "../../components/DateTimePicker";
import GenericModal from "../../components/GenericModal";
import PageToolbar from "../../components/PageToolbar";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import moment from "moment";
import TableIDs from "../../data/TableIDs";
import GenericSelector from "../../components/GenericSelector";
import { indexOf } from "lodash";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";


class SumDeliverieDocs extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            DateStart: null,
            DateEnd: null,
            UserDocDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            UserDocPos: "999",
            preDefinedMsg: null,
            custTagList: '',

            modalView: false,
            modalDocs: null,
            modalDocsCustID: null,
            modalDocsCustName: null,
            modalDocsCustIsPrimary: null,

            modalCreateDocList: null,
            modalCreateDocListStatus: null,
            modalCreateDocListStatus2: null,
        };

        this.id = "SUM_DELIVERIE_DOCS";

        this.api = {
            get: "get_sum_deliverie_docs",
            create_docs: "close_open_deliveries_v3",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorPreDefinedMsg] === undefined) props.data[TableIDs.genericSelectorPreDefinedMsg] = { ...props.data.genericSelector };
        if (props.data.POS_LIST_BO_USER === undefined) props.data.POS_LIST_BO_USER = { ...props.data.genericSelector };

        this.userAcceptsPreDate = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI("get_message_event_doc_sms", "", ob => {
            this.setState({ preDefinedMsg: ob.data })
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16745);
    }

    createDatePicker = (label, field, showTime) => {
        return (<Form.Item label={this.getText(label)}>
            <DateTimePicker
                showTime={showTime}
                format={showTime ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY"}
                value={this.state[field]}
                onChange={e => { this.setState({ [field]: e }) }}
                minDate={field == 'DateEnd' ? this.state.DateStart : null}
                maxDate={field == 'DateStart' ? this.state.DateEnd : field == 'UserDocDate' ? moment(Date.now()).format("YYYY-MM-DD") : null}
            />
        </Form.Item>)
    }

    openView = () => {
        this.setState({ modalView: true }, () => {
            const { DateStart, DateEnd, custTagList } = this.state;
            let dataSend = "DateStart\fDateEnd\fcustTagList\r";
            if (DateStart) dataSend += DateStart;
            dataSend += '\f';
            if (DateEnd) dataSend += DateEnd;
            dataSend += '\f';
            if (custTagList) dataSend += custTagList;

            this.sendAPI(this.api.get, dataSend, (ob) => {
                let custTable = [];
                let curCustId = null;
                let curCustName = null;
                let curCustSum = 0;
                let curCustSumTax = 0;
                let curCustFirstDate = null;
                let curCustLastDate = null;
                let notRangeOfDates = 0;
                let notRangeFirstDate = null;
                let curCustPrintFormat = null;
                let curCustSendType = null;
                let curCustDestination = null;
                let curIsPrimary = null;

                let curCustData = [];

                let sortPerSecondary = (a, b) => parseInt(a.secondaryID ? a.secondaryID : 0) - parseInt(b.secondaryID ? b.secondaryID : 0)

                let data = ob.data ? ob.data.split('\r') : [];

                data.forEach((x) => {
                    let y = x.split('\f');
                    let docData = {
                        DocNum: y[0],
                        DocTypeId: y[1],
                        DocTypeName: y[2],
                        PosId: y[3],
                        PosName: y[4],
                        DocTimestamp: y[5],
                        DocTax: y[6],
                        DocSum: y[7],
                        secondaryID: y[15],
                        secondaryName: y[16],
                        summerySeparately: y[17],
                        docIsChecked: false,
                    }
                    let custId = y[8];
                    let custName = y[9];
                    let isRangeOfDates = y[10] == '1';
                    let PrintFormat = y[11] ? parseInt(y[11]) : 0;
                    let sendType = y[12];
                    let custDestination = y[13];
                    let isPrimary = y[14];

                    if (custId !== curCustId) {
                        if (curCustData.length) {
                            custTable.push({
                                custId: curCustId,
                                custName: curCustName,
                                custSum: curCustSum,
                                custSumTax: curCustSumTax,
                                custFirstDate: curCustFirstDate,
                                custLastDate: curCustLastDate,
                                PrintFormat: curCustPrintFormat,
                                sendType: curCustSendType,
                                custDestination: curCustDestination,
                                isPrimary: curIsPrimary,
                                notRangeOfDates,
                                notRangeFirstDate,
                                custData: curCustData.sort(sortPerSecondary),
                                custIsChecked: false,
                            });
                        }


                        curCustId = custId;
                        curCustName = custName;
                        curCustSum = isRangeOfDates ? parseFloat(docData.DocSum) : 0;
                        curCustSumTax = isRangeOfDates ? parseFloat(docData.DocTax) : 0;
                        curCustFirstDate = isRangeOfDates ? docData.DocTimestamp : null;
                        curCustLastDate = isRangeOfDates ? docData.DocTimestamp : null;
                        notRangeOfDates = isRangeOfDates ? 0 : 1;
                        notRangeFirstDate = docData.DocTimestamp;
                        curCustPrintFormat = PrintFormat;
                        curCustSendType = sendType;
                        curCustDestination = custDestination;
                        curIsPrimary = isPrimary;

                        curCustData = isRangeOfDates ? [docData] : [];

                    } else {
                        if (isRangeOfDates) {
                            curCustSum += parseFloat(docData.DocSum);
                            curCustSumTax += parseFloat(docData.DocTax);
                            curCustData.push(docData)
                            curCustLastDate = docData.DocTimestamp;
                            if (!curCustFirstDate) curCustFirstDate = docData.DocTimestamp;
                            if (!curIsPrimary && isPrimary) curIsPrimary = isPrimary
                        } else {
                            notRangeOfDates++;
                        }
                    }
                });

                if (curCustData.length) {

                    custTable.push({
                        custId: curCustId,
                        custName: curCustName,
                        custSum: curCustSum,
                        custSumTax: curCustSumTax,
                        custFirstDate: curCustFirstDate,
                        custLastDate: curCustLastDate,
                        PrintFormat: curCustPrintFormat,
                        sendType: curCustSendType,
                        custDestination: curCustDestination,
                        isPrimary: curIsPrimary,
                        notRangeOfDates,
                        notRangeFirstDate,
                        custData: curCustData.sort(sortPerSecondary),
                        custIsChecked: false,
                    });
                }

                this.props.dataActions.setJson(this.id, { data: custTable })
            })
        })
    }


    sendAPI = (script, data, sCal) => {
        let outputType = script === "generate_report" ? "OUTPUT_TYPE_REPORT_A4" : "OUTPUT_TYPE_DATA";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType[outputType], ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    createCheckbox = (text, record) => {
        let allData = [...this.props.data[this.id].data];
        let checked = false;
        let indeterminate = false;

        if (text === 'all') {
            let checkedCount = allData.filter(f => f.custIsChecked).length;
            checked = checkedCount == allData.length;
            indeterminate = checkedCount && checkedCount < allData.length;
        } else {
            let docsChecked = record.custData.filter(f => f.docIsChecked).length
            checked = record.custIsChecked;
            indeterminate = docsChecked && docsChecked < record.custData.length;
        }

        let onChange = (e) => {
            let newData = allData.map(x => {
                if (text === 'all' || x.custId === record.custId) {
                    return {
                        ...x,
                        custIsChecked: e.target.checked,
                        custData: x.custData.map(y => {
                            return { ...y, docIsChecked: e.target.checked }
                        })
                    }
                } else {
                    return x;
                }
            })

            this.props.dataActions.setJson(this.id, { data: newData })
        };

        return (<Checkbox {...{ checked, onChange, indeterminate }} />)
    }

    createCheckbox2 = (text, record) => {
        let allData = [...this.props.data[this.id].data];
        let modalData = [...this.state.modalDocs];
        let checked = false;
        let indeterminate = false;

        if (text === 'all') {
            let checkedCount = modalData.filter(f => f.docIsChecked).length;
            checked = checkedCount == modalData.length;
            indeterminate = checkedCount && checkedCount < modalData.length;
        } else {
            checked = record.docIsChecked;
        }

        let onChange = (e) => {
            let custIsChecked = true;
            let newCustData = [];

            if (text === 'all') {
                custIsChecked = e.target.checked;
                newCustData = modalData.map(y => {
                    return { ...y, docIsChecked: e.target.checked }
                })
            } else {
                newCustData = modalData.map(y => {
                    let docIsChecked = y.DocNum === record.DocNum ? e.target.checked : y.docIsChecked;
                    if (!docIsChecked) custIsChecked = false;
                    return { ...y, docIsChecked }
                })
            }

            let newData = allData.map(x => {
                return (this.state.modalDocsCustID === x.custId) ? { ...x, custIsChecked, custData: newCustData } : x
            });

            this.setState({ modalDocs: newCustData }, () => {
                this.props.dataActions.setJson(this.id, { data: newData })
            })
        };

        return (<Checkbox {...{ checked, onChange, indeterminate }} />)
    }

    createAllDoc = () => {
        const { UserDocDate, UserDocPos, custTagList } = this.state;
        const nowDatetime = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
        if (UserDocDate && UserDocDate.split(" ")[0] != nowDatetime.split(" ")[0] && !this.userAcceptsPreDate) {
            Modal.confirm({
                title: this.getText(18221),
                content: this.getText(18222),
                iconType: 'exclamation-circle',
                okText: this.getText(12450),
                cancelText: this.getText(12451),
                onOk: () => {
                    this.userAcceptsPreDate = true;
                    this.createAllDoc();
                },
                onCancel: () => { },
            });
        } else {
            this.userAcceptsPreDate = false;
            let createDocList = []
            let createDocListPush = (data, x) => {
                const { custId, custName, PrintFormat, sendType, custSum, custDestination, _secondaryID, _secondaryName } = x;
                if (data.length) {
                    createDocList.push({
                        _custId: custId,
                        _custName: custName,
                        _custSum: custSum,
                        _PrintFormat: PrintFormat,
                        _sendType: sendType,
                        _custDestination: custDestination,
                        _custData: data.map(y => {
                            return {
                                DocNum: parseInt(y.DocNum),
                                DocTypeId: parseInt(y.DocTypeId),
                                PosId: parseInt(y.PosId),
                                UserDocDate: UserDocDate ? UserDocDate : nowDatetime,
                                UserDocPos: UserDocPos ? parseInt(UserDocPos) : 999,
                                CustId: parseInt(custId),
                                PrintFormat: x.PrintFormat ? x.PrintFormat : 0
                            }
                        }),
                        _sumDocNum: null,
                        _isSend: false,
                        _secondaryID,
                        _secondaryName
                    })
                }
            }
            this.props.data[this.id].data.forEach(x => {
                // const { custId, custName, PrintFormat, sendType, custSum, custDestination } = x;
                let custData = x.custData.filter(f => f.docIsChecked && f.summerySeparately != '1');
                let secondaryGroups = [];
                x.custData.filter(f => f.docIsChecked && f.summerySeparately == '1').forEach(y => {
                    let _secondaryID = y.secondaryID;
                    let _secondaryName = y.secondaryName;
                    let secondaryIndex = secondaryGroups.findIndex(f => f._secondaryID === _secondaryID);
                    if (secondaryIndex > -1) {
                        secondaryGroups[secondaryIndex]._secondaryData.push(y);
                    } else {
                        secondaryGroups.push({
                            _secondaryID,
                            _secondaryName,
                            _secondaryData: [y],
                        })
                    }

                })

                createDocListPush(custData, x);
                secondaryGroups.forEach(g => {
                    createDocListPush(g._secondaryData, { ...x, ...g });
                })
                // if (custData.length) {
                //     createDocList.push({
                //         _custId: custId,
                //         _custName: custName,
                //         _custSum: custSum,
                //         _PrintFormat: PrintFormat,
                //         _sendType: sendType,
                //         _custDestination: custDestination,
                //         _custData: custData,
                //         _sumDocNum: null,
                //         _isSend: false
                //     })
                // }
            });

            if (!createDocList.length) {
                message.error(this.getText(16786))
            } else {
                this.setState({ modalCreateDocList: createDocList }, () => {
                    this.createDoc(0)
                })
            }
        }
    }

    createDoc = (index) => {
        const { modalCreateDocList } = this.state;
        if (!modalCreateDocList[index]) {
            message.success(this.getText(16787))
            this.sendDoc(0)
        } else {
            // if (modalCreateDocList[index]._custSum > 0) {
            let dataSend = JSON.stringify(modalCreateDocList[index]._custData)
            this.sendAPI(this.api.create_docs, dataSend, (ob) => {
                let _sumDocNum = ob.data ? ob.data.split('\f')[0] : null;
                let newData = [...modalCreateDocList];
                if (modalCreateDocList[index]._custSum > 0 && _sumDocNum)
                    newData[index] = { ...newData[index], _sumDocNum }
                else
                    newData[index] = { ...newData[index], _sendType: '0' }
                // if (_sumDocNum) {
                    this.setState({ modalCreateDocList: newData }, () => {
                        this.openView()
                        this.createDoc(index + 1);
                    })
                // } else {
                //     this.setState({ modalCreateDocList: newData, modalCreateDocListStatus: 'exception' })
                // }
            });
            // } else {
            //     let newData = [...modalCreateDocList];
            //     newData[index] = { ...newData[index], _sendType: '0' }
            //     this.setState({ modalCreateDocList: newData }, () => {
            //         this.openView()
            //         this.createDoc(index + 1);
            //     })
            // }
        }
    }

    createTable = (dataSource, columns, rowClassName) => {
        let props = {
            dataSource,
            columns,
            tableOnly: true,
            pagination: false,
            scroll: { y: (window.innerHeight - 300) },
            rowClassName

        }
        return (<ResponsiveTable {...props} />)
    }

    createProggres = (label, percent, status) => {
        return (<Row>
            <Col span={4}>{this.getText(label)}</Col>
            <Col span={20}><Progress percent={parseInt(100 * (percent))} status={status} /></Col>
        </Row>)
    }

    generateReport = (docNum, PrintFormat, index) => {
        const { companyCode, userId, displayName } = this.props.user;
        let userName = encodeURIComponent(displayName);
        // let reportName = PrintFormat == '1' ? "reports/DocumentCopyCentralDetails.pdf" : "reports/DocumentCopyCentral.pdf";
        let reportName = "reports/DocumentCopyCentral.pdf";
        // if (PrintFormat == '1') reportName = "reports/DocumentCopyCentralDetails.pdf";
        // if (PrintFormat == '2') reportName = "reports/DocumentCopyCentralPerCust.pdf";
        // if (PrintFormat == '3') reportName = "reports/DocumentCopyCentralPerCustDetails.pdf";
        // if (PrintFormat == '4') reportName = "reports/DocumentCopyCentralSum.pdf";
        let dataSend = "DocumentNumber\fDocumentTypeID\fDocumentPOSID\fCompanyCode\fuserID\fuserName\fisOriginal\fCentralFatherItem\fPrintFormat\freportName\r" +
            docNum + "\f5\f999\f" + companyCode + "\f" + userId + "\f" + userName + "\f1\f0\f" + PrintFormat + "\f" + reportName;

        this.sendAPI("generate_report", dataSend, (ob) => {
            if (ob && ob.blob && ob.blob.size > 0) {
                let newData = [...this.state.modalCreateDocList];
                newData[index] = { ...newData[index], _isSend: true }
                this.setState({ modalCreateDocList: newData }, () => {
                    this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReportSuccess, args: [ob.blob, reportName] });
                    this.sendDoc(index + 1);

                })
            }
        })
    }

    mailingReport = (docNum, PrintFormat, _sendType, index) => {
        let dataSend = "docNum\fdocType\fdocPos\fisOriginal\fprintFormat\r" + docNum + "\f5\f999\f1\f" + (PrintFormat ? PrintFormat : '0');
        this.sendAPI("get_document_report_link", dataSend, (ob) => {
            const { _custId, _custName, _custDestination, _custSum } = this.state.modalCreateDocList[index];
            let dataSend2 = "_custId\f_custName\f_custDestination\f_messageId\f_prm1\f_prm2\f_prm3\f_prm4\f_prm5\f_messageText\f_messageType\r"
                + _custId + "\f"
                + _custName + "\f"
                + _custDestination + "\f"
                + this.state.preDefinedMsg + "\f"
                + _custSum + "\f999\f"
                + _custName + "\f"
                + ob.data + "\f\f\f"
                + _sendType;

            this.sendAPI("ac_insert_message", dataSend2, () => {
                let newData = [...this.state.modalCreateDocList];
                newData[index] = { ...newData[index], _isSend: true }
                this.setState({ modalCreateDocList: newData }, () => {
                    this.sendDoc(index + 1);
                })
            })
        })
    }

    updateDocNotPrintOriginal = (docNumber, index) => {
        this.sendAPI("update_doc_print_original", "docNumber\fdocType\fposId\r" + docNumber + "\f5\f999", () => {
            this.sendDoc(index + 1);
        })
    }

    sendDoc = (index) => {
        const { modalCreateDocList } = this.state;
        if (!modalCreateDocList[index]) {
            message.success(this.getText(16796))
        } else {
            const { _sendType, _sumDocNum, _PrintFormat } = modalCreateDocList[index];

            switch (_sendType) {
                case '1': this.generateReport(_sumDocNum, _PrintFormat, index); break;
                case '2': case '3': this.mailingReport(_sumDocNum, _PrintFormat, _sendType, index); break;
                case '0': this.sendDoc(index + 1); break;
                default: this.updateDocNotPrintOriginal(_sumDocNum, index);
            }
        }
    }

    openModalDocs = (record) => {
        this.setState({
            modalDocs: record.custData,
            modalDocsCustID: record.custId,
            modalDocsCustName: record.custName,
            modalDocsCustIsPrimary: record.isPrimary,
        })
    }


    render() {
        let gt = this.getText;

        const mobile = window.innerWidth <= 600

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 800,
            margin: 'auto'
        };


        const { modalDocsCustID, modalDocsCustName, modalDocsCustIsPrimary, modalDocs, modalCreateDocList, modalCreateDocListStatus, modalCreateDocListStatus2 } = this.state;

        let checkIcon = (<Icon type="check" style={{ color: 'green', marginRight: 10 }} />)

        let render = t => t;
        let renderIconChecked = t => parseInt(t) ? (<Icon type="check" />) : "";
        let renderWithTax = t => parseFloat(t).toFixed(2)
        let renderNoTax = (t, r) => (parseFloat(r.custSum) - parseFloat(t)).toFixed(2);
        let renderNoTax2 = (t, r) => (parseFloat(r.DocSum) - parseFloat(t)).toFixed(2);
        let renderDocCount = (t, r) => r.custData.length;
        let renderDocsViewCol = (t, r) => (<Icon type="eye" onClick={() => { this.openModalDocs(r) }} />)
        let renderSumDocNum = t => t ? (<div>{t}{checkIcon}</div>) : ""
        let renderNotRangeFirstDate = (t, r) => r.notRangeOfDates > 0 ? t : ""
        let renderPrintFormat = t => this.getText(t == '1' ? 18984 : t == '2' ? 18985 : t == '3' ? 18986 : t == '4' ? 19105 : t == '5' ? 19359 : t == '6' ? 19360 : t == '7' ? 19361 : 18983);
        let renderSendType = (t, r) => (<div>{this.getText(t == '1' ? 16775 : t == '2' ? 16777 : t == '3' ? 16776 : t == '0' ? 19705 : 16778)}{r._isSend ? checkIcon : ""}</div>);
        let renderSecondaryName = (t, r) => r.secondaryID ? t : ""

        let countDocSend = modalCreateDocList ? modalCreateDocList.filter(f => f._sendType > 0 && f._sendType < 4).length : 0

        let custColumns = [
            { title: gt(16756), key: "custId", dataIndex: "custId", width: "7%", render },
            { title: gt(16757), key: "custName", dataIndex: "custName", width: "18%", render },
            { title: gt(17041), key: "isPrimary", dataIndex: "isPrimary", width: "5%", render: renderIconChecked },
            { title: gt(16758), key: "custDocCount", width: "6%", render: renderDocCount },
            { title: gt(16759), key: "custSumTax", dataIndex: "custSumTax", width: "8%", render: renderNoTax },
            { title: gt(16760), key: "custSum", dataIndex: "custSum", width: "8%", render: renderWithTax },
            { title: gt(16761), key: "custFirstDate", dataIndex: "custFirstDate", width: "10%", render },
            { title: gt(16762), key: "custLastDate", dataIndex: "custLastDate", width: "10%", render },
            { title: gt(16763), key: "notRangeFirstDate", dataIndex: "notRangeFirstDate", width: "10%", render: renderNotRangeFirstDate },
            { title: gt(16764), key: "notRangeOfDates", dataIndex: "notRangeOfDates", width: "6%", render },
            { title: gt(16765), key: "docsViewCol", width: "7%", render: renderDocsViewCol },
            { title: this.createCheckbox('all'), key: "editCol", width: "5%", render: this.createCheckbox },
        ];

        let docsColumns = [];

        if (modalDocs) {

            let wc = p => (modalDocsCustIsPrimary ? parseInt(p * 0.8) : p) + "%";

            if (modalDocsCustIsPrimary) {
                docsColumns = [
                    { title: gt(17042), key: "secondaryName", dataIndex: "secondaryName", width: "15%", render: renderSecondaryName },
                    { title: gt(17043), key: "summerySeparately", dataIndex: "summerySeparately", width: "5%", render: renderIconChecked },
                ]
            }

            docsColumns = [
                ...docsColumns,
                { title: gt(16767), key: "PosId", dataIndex: "PosId", width: wc(10), render },
                { title: gt(16768), key: "PosName", dataIndex: "PosName", width: wc(18), render },
                { title: gt(16769), key: "DocTypeName", dataIndex: "DocTypeName", width: wc(18), render },
                { title: gt(16770), key: "DocNum", dataIndex: "DocNum", width: wc(10), render },
                { title: gt(16771), key: "DocTimestamp", dataIndex: "DocTimestamp", width: wc(12), render },
                { title: gt(16772), key: "DocTax", dataIndex: "DocTax", width: wc(12), render: renderNoTax2 },
                { title: gt(16773), key: "DocSum", dataIndex: "DocSum", width: wc(12), render: renderWithTax },
                { title: this.createCheckbox2('all'), key: "docIsChecked", dataIndex: "docIsChecked", width: wc(8), render: this.createCheckbox2 },
            ]
        }



        return (<div style={divStyle}>
            <PageToolbar title={gt(16746)} actionButtons={[]} />
            <div style={{ textAlign: "center" }}>
                <Row>
                    <Col span={mobile ? 24 : 12}>
                        {this.createDatePicker(16747, 'DateStart')}
                        {this.createDatePicker(16748, 'DateEnd')}
                        <Form.Item label={gt(19142)}>
                            <GenericSelectorCustTags
                                {...this.props} multi
                                value={this.state.custTagList ? this.state.custTagList.split(",") : []}
                                onChange={e => { this.setState({ custTagList: e ? e.join(",") : "" }) }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={mobile ? 24 : 12}>
                        {this.createDatePicker(16749, 'UserDocDate', true)}
                        <Form.Item label={gt(18642)}>
                            <GenericSelector
                                {...this.props}
                                id={"POS_LIST_BO_USER"}
                                api={"get_pos_list_bo_user"}
                                value={this.state.UserDocPos}
                                onChange={e => { this.setState({ UserDocPos: e }) }}
                            />
                        </Form.Item>
                        <Form.Item label={gt(16797)}>
                            <GenericSelector
                                {...this.props}
                                id={TableIDs.genericSelectorPreDefinedMsg}
                                api={"get_pre_defined_msg_list"}
                                value={this.state.preDefinedMsg}
                                onChange={e => { this.setState({ preDefinedMsg: e }) }}
                                addFirstRow={{ code: '-', name: "-" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {this.state.preDefinedMsg ?
                    <Button type="primary" onClick={this.openView}>{gt(16754)}</Button>
                    : <Popconfirm title={gt(19143)} okText={gt(19144)} cancelText={gt(19145)} onConfirm={this.openView}>
                        <Button type="primary">{gt(16754)}</Button>
                    </Popconfirm>
                }
            </div>
            <GenericModal
                visible={this.state.modalView}
                onCancel={() => { this.setState({ modalView: false }) }}
                title={gt(16755)}
                footer={[
                    <Button type="primary" onClick={this.createAllDoc}>{gt(16779)}</Button>,
                    <Button onClick={() => { this.setState({ modalView: false }) }}>{gt(16780)}</Button>,
                ]}>
                {this.createTable(this.props.data[this.id].data, custColumns)}
            </GenericModal>
            <GenericModal
                visible={modalDocs}
                onCancel={() => { this.setState({ modalDocs: null }) }}
                title={gt(16766) + ' ' + modalDocsCustID + ' - ' + modalDocsCustName}>
                {modalDocs ? this.createTable(modalDocs, docsColumns) : ""}
            </GenericModal>

            <GenericModal
                visible={modalCreateDocList}
                onCancel={() => { this.setState({ modalCreateDocList: null, modalCreateDocListStatus: null }) }}
                title={gt(16784)}
                width={800}>
                {modalCreateDocList ? (<div>
                    {this.createProggres(16794, modalCreateDocList.filter(f => f._sumDocNum).length / modalCreateDocList.length, modalCreateDocListStatus)}
                    {countDocSend ? this.createProggres(16795, modalCreateDocList.filter(f => f._isSend).length / countDocSend, modalCreateDocListStatus2) : ""}
                    <br />
                    {this.createTable(modalCreateDocList, [
                        { title: gt(16756), key: "_custId", dataIndex: "_custId", width: "10%", render },
                        { title: gt(16757), key: "_custName", dataIndex: "_custName", width: "25%", render },
                        { title: gt(17044), key: "_secondaryName", dataIndex: "_secondaryName", width: "25%", render },
                        { title: gt(16785), key: "_sumDocNum", dataIndex: "_sumDocNum", width: "15%", render: renderSumDocNum },
                        { title: gt(16792), key: "_PrintFormat", dataIndex: "_PrintFormat", width: "10%", render: renderPrintFormat },
                        { title: gt(16793), key: "_sendType", dataIndex: "_sendType", width: "15%", render: renderSendType },
                    ], r => r._sendType == '0' ? "row_pink" : ""
                    )}
                </div>) : ""}
            </GenericModal>
        </div>
        )
    }

}

export default SumDeliverieDocs;

import React from "react";
import { Form, Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Popover, Row, Col, Checkbox, message } from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import Jax from "../Jax/jax.es6.module";
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext";


const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const topHeight = 300; // px
const rowHeight = 40;  // px

//type State = {}
class ModalEditLabelField extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            type: null,
        }

        this.id = "LABEL_FORMATS_DATA";
        this.editId = "LABEL_FIELD_EDIT";

        // calc page height

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[this.editId] === undefined) {
            props.data[this.editId] = { ...props.data.genericData };
        }

        if (props.data[this.selector_types] === undefined) {
            props.data[this.selector_types] = { ...props.data.genericSelector };
        }

        if (props.data[this.selector_Attributes_types] === undefined) {
            props.data[this.selector_Attributes_types] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_labelFields_table",
            createEdit: "create_edit_labelFields_table",
            selector_types: "get_labelFieldTypes_table",
            selector_Attributes_types: "get_LabelDataTypeAttributes_table",
        }

        this.actionButtons = []

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_types);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {

        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {

            let request = {
                _id: this.props.ui.data ? this.props.ui.data.labelId : 0,
                _fieldId: this.props.ui.data ? this.props.ui.data.fieldId : 0,

            };

            this.props.dataActions.genericDataGetEditWithParams(
                this.editId,
                this.props.user.companyCode,
                this.props.user.token,
                request,
                this.api.get
            );

            this.props.dataActions.genericSelectorRefreshDataset(
                TableIDs.genericSelectorAttributesTypes,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.selector_Attributes_types,
                { _typeId: this.props.ui.data.fType ? this.props.ui.data.fType : 0 });

        }
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: this.props.ui.data ? this.props.ui.data.labelId : 0,
            _fieldId: this.props.ui.data ? this.props.ui.data.fieldId : 0,

        };

        this.props.dataActions.genericDataSetFilter(
            this.editId, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.editId, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleOk = () => {
        let record = this.props.data[this.editId].editedRecord;
        let fieldsValid = [
            record.fName,
            record.fAttributes,
            record.fTypeId,
        ];
        let labelValid = fieldsValid.reduce((a, b) => { return a && b });
        if (labelValid !== '') {
            let jsonObject = JSON.parse(fieldsValid[1]);
            let jsonKeys = Object.keys(jsonObject);
            let jsonValid0 = [];
            jsonKeys.map((l, i) => { jsonValid0.push(jsonObject[l]) });
            let jsonValid = jsonValid0.reduce((a, b) => { return a && b });
            //console.log('xxx-yuda ', jsonValid);
            if (jsonValid !== '' && jsonValid !== null) {

                this.props.dataActions.genericDataJustSaveEdit(
                    this.editId, this.props.user.companyCode, this.props.user.token, this.api.createEdit);
                this.props.uiActions.hideModal(TableIDs.modalEditLabelField);
                this.props.dataActions.genericDataGetRequested(
                    'LABEL_FORMATS_DATA', this.props.user.companyCode, this.props.user.token, 'get_labelFields_table');

            } else {
                message.error(this.getText(11649));
            }
        } else {
            message.error(this.getText(11650));
        }
    }

    handleCancel = () => {
        this.setState({ visible: false });
        this.props.uiActions.hideModal(TableIDs.ModalLabelFormatsData);
    }

    render() {
      
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(11651)}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        let typesList = Jax.get(this.props.data, TableIDs.genericSelectorTypes + ".dataset", []).map((l, i) => {
            return (<Option key={l['code']} value={l['code']} >{l['code'] + '-' + l['name']}</Option>);
        });

        let fieldData = this.props.data[this.editId].editedRecord;

        let attributesObject = {};

        if (fieldData['fAttributes']) {
            attributesObject = JSON.parse(fieldData['fAttributes']);
        } else {
            Jax.get(this.props.data, TableIDs.genericSelectorAttributesTypes + ".dataset", []).map((l, i) => {
                if (l.name) {
                    attributesObject[l.name] = null;
                }
            });
        }

        let fAttributesList = Jax.get(this.props.data, TableIDs.genericSelectorAttributesTypes + ".dataset", []).map((l, i) => {
            if (l.name) {
                return (
                    <Col span={5} style={{ margin: '10px' }}>
                        <FormItem label={l.name}>
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                value={attributesObject[l.name]}
                                onChange={(value) => {
                                    attributesObject[l.name] = value.target.value;
                                    let newJsonValue = attributesObject ? JSON.stringify(attributesObject) : '';
                                    this.props.dataActions.genericDataSetEdit(this.editId, { ['fAttributes']: newJsonValue });
                                   
                                }}
                            />
                        </FormItem>
                    </Col>
                );
            } else {
                return (<div></div>);
            }
        });

        return (
            <Modal
                destroyOnClose={true}
                width={'60%'}
                visible={false || this.props.toggle}
                 title={titleBar}
                 onCancel={this.handleCancel}
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <Form id="save_label_fields" layout="horizontal">
                    <Row type="flex" gutter={8}>
                        <Col span={10} order={1}>
                            <FormItem label={this.getText(11652)}>
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    value={fieldData ? fieldData['fName'] : ''}
                                    onChange={(value) => {
                                         this.props.dataActions.genericDataSetEdit(this.editId, { ['fName']: value.target.value, ['fLabelId']: this.props.ui.data.labelId });
                                    }} />

                            </FormItem>
                        </Col>
                        <Col span={10} order={1}>
                            <FormItem label={this.getText(11653)}>
                                <Select key={"fType"} style={{ width: 250 }}
                                    showsearch
                                    filterOption={true}
                                    optionFilterProp="valuefilter"
                                    placeholder={this.getText(11654)}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.editId, { ['fTypeId']: value, ['fType']: '', ['fAttributes']: '' });
                                        if (value) {
                                            this.props.dataActions.genericSelectorRefreshDataset(
                                                TableIDs.genericSelectorAttributesTypes,
                                                this.props.user.companyCode,
                                                this.props.user.token,
                                                this.api.selector_Attributes_types,
                                                { _typeId: value });

                                            attributesObject = {};
                                            Jax.get(this.props.data, TableIDs.genericSelectorAttributesTypes + ".dataset", []).map((l, i) => {
                                                if (l.name) {
                                                    attributesObject[l.name] = null;
                                                }
                                            });
                                        }
                                    }}
                                     value={fieldData ? fieldData.fTypeId : undefined}
                                    notFoundContent={this.getText(11655)}
                                >
                                    {typesList}
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <h3>{this.getText(11656)}</h3>
                    <Row type="flex" gutter={8} style={{ direction: 'ltr', textAlign: 'left' }}>
                        {fAttributesList}
                    </Row>
                </Form>
                <div className="action_footer">
                    <Button
                        type="primary" htmlType="submit" form="save_label_fields"
                        loading={this.props.ui.workInProgress}
                        onClick={this.handleOk}
                        className={"form-button-save"} icon={"check"}>{this.getText(11657)}</Button>
                </div>
            </Modal>
        )
    }

}

export default ModalEditLabelField;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import { ModalForm } from "../../components/ModalForm";
import { ModalFilterForm } from "../../components/ModalFilterForm";
import type { Field } from "../../components/ModalForm";
import PageToolbar from "../../components/PageToolbar";
import "../../App.css";
import { Table, Icon, Popconfirm, DatePicker, TimePicker, Switch, Select, InputNumber } from "antd";
import moment from "moment";
import ItemPicker from "../../components/ItemPicker";
import LangContext from "../../contextProvider/LangContext";
import { disabledDatePicker } from "../../utils/utils";

const Option = Select.Option;
const OptGroup = Select.OptGroup;


type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class EarningPoints extends Component<Props, State> {
    //data = [];
    constructor(props: Props) {
        super(props);

        this.doOnResize = null;

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            height: window.innerHeight,
        };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    refreshData() {
        this.props.dataActions.getEarningPoints(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getCustomerTags(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getItemTags(this.props.user.companyCode, this.props.user.token);
    }


    componentDidMount() {
        this.refreshData();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10216);

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        let rowHeight = 30;
        let topHeight = 340;
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight) ? (this.state.height - topHeight) / rowHeight : 1);

        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { recordsPerPage: numOfRowsByHeight })
        // this.setState({ height: window.innerHeight }, this.initFilter);
    };

    handleTableChange(pagination: Object, filters: Object, sorter: Object) {
        let sortColumn = 0;
        switch (sorter.field) {
            case "key":
                sortColumn = 1;
                break;
            case "dateFrom":
                sortColumn = 2;
                break;
            case "dateTo":
                sortColumn = 3;
                break;
            case "hourFrom":
                sortColumn = 4;
                break;
            case "hourTo":
                sortColumn = 5;
                break;
            case "isForAccumulation":
                sortColumn = 6;
                break;
            case "customerTag":
                sortColumn = 7;
                break;
            case "itemCode":
                sortColumn = 8;
                break;
            case "itemTag":
                sortColumn = 9;
                break;
            case "multiplication":
                sortColumn = 10;
                break;
            case "accumulation":
                sortColumn = 11;
                break;
            case "partialAccumulation":
                sortColumn = 12;
                break;
            case "maxDiscount":
                sortColumn = 13;
                break;
            default:
                sortColumn = 0;
        }

        const sortType = sorter.order === undefined || sorter.order === "ascend" ? 1 : 0;
        const sortingChanged = this.props.data.earningPoints.pagination.sortType !== sortType || this.props.data.earningPoints.pagination.sortColumn !== sortColumn;
        let request = {
            page: sortingChanged ? 1 : pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sortType,
            sortColumn
        };

        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, request)

        window.console.log(filters);
    }

    handleCreateNew() {
        this.props.dataActions.earningPointsStartNew();
    }

    handleCreateFormCancel() {
        this.props.dataActions.earningPointsCancelNew();
    }

    handleShowFilters() {
        this.props.dataActions.earningPointsShowFilters();
    }

    handleHideFilters() {
        this.props.dataActions.earningPointsHideFilters()
    }

    handleClearFilters() {
        this.props.dataActions.earningPointsClearFilters(this.props.user.companyCode, this.props.user.token, this.props.data.earningPoints.pagination.defaultPageSize);
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dateFormat = "YYYY-MM-DD";
        const sqlFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm:ss";

        const itemTags = this.props.data.itemTags.map(
            (node: Object) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object) => {
                            return (<Option key={item.id}>{item.text}</Option>);
                        })}
                    </OptGroup>
                )
            }
        );

        const customerTags = this.props.data.customerTags.map(
            (node: Object) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object) => {
                            return (<Option key={item.id}>{item.text}</Option>);
                        })}
                    </OptGroup>
                )
            }
        );

        const YesNoNull = [
            <Option key={"-1"} />,
            <Option key={"1"}>{this.getText(15765)}</Option>,
            <Option key={"0"}>{this.getText(15766)}</Option>
        ];

        const dataSource = this.props.data.earningPoints.data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                dateFrom: y[1],
                dateTo: y[2],
                hourFrom: y[3],
                hourTo: y[4],
                isForAccumulation: y[5],
                customerTag: y[6],
                itemCode: y[7],
                tagId: y[8],
                multiplication: y[9],
                accumulation: y[10],
                partialAccumulation: y[11],
                maxDiscount: y[12],
                customerTagName: y[13],
                itemTagName: y[14],
                itemName: y[15],
                index: index
            })
        });

        const columns = [
            // {
            //     title: "",
            //     dataIndex: "key",
            //     key: "key",
            //     fixed: "right",
            //     sorter: true,
            //     width: 100
            // },
            {
                title: this.getText(15767),
                dataIndex: "dateFrom",
                key: "dateFrom",
                fixed: "right",
                sorter: true,
                width: 125,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (<DatePicker
                            disabledDate={d => disabledDatePicker(d, null, this.props.data.earningPoints.editedRecord.dateTo)}
                            defaultValue={text.length > 0 ? moment(text, dateFormat) : null}
                            format={dateFormat} onChange={(date: moment, dateString: string) => {
                                this.props.dataActions.earningPointsSetEdit({ dateFrom: date != null ? date.format("YYYY-MM-DD") : "" })
                            }} />);
                    }
                    else {
                        const date = text.split("-");
                        if (date.length === 3) {
                            return date[2] + "/" + date[1] + "/" + date[0];
                        } else {
                            return text;
                        }
                    }
                }
            },
            {
                title: this.getText(15768),
                dataIndex: "dateTo",
                key: "dateTo",
                fixed: "right",
                sorter: true,
                width: 125,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (<DatePicker
                            disabledDate={d => disabledDatePicker(d, this.props.data.earningPoints.editedRecord.dateFrom, null)}
                            defaultValue={text.length > 0 ? moment(text, dateFormat) : null}
                            format={dateFormat} onChange={(date: moment, dateString: string) => {
                                this.props.dataActions.earningPointsSetEdit({ dateTo: date != null ? date.format("YYYY-MM-DD") : "" })
                            }} />);
                    }
                    else {
                        const date = text.split("-");
                        if (date.length === 3) {
                            return date[2] + "/" + date[1] + "/" + date[0];
                        } else {
                            return text;
                        }
                    }
                }
            },
            {
                title: this.getText(15769),
                dataIndex: "hourFrom",
                key: "hourFrom",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (<TimePicker style={{ width: 90 }}
                            defaultValue={text.length > 0 ? moment(text, timeFormat) : null}
                            format={timeFormat} onChange={(time: moment, timeString: string) => {
                                this.props.dataActions.earningPointsSetEdit({ hourFrom: timeString })
                            }} />);
                    }
                    else {
                        return text;
                    }
                }
            },
            {
                title: this.getText(15770),
                dataIndex: "hourTo",
                key: "hourTo",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (<TimePicker style={{ width: 90 }}
                            defaultValue={text.length > 0 ? moment(text, timeFormat) : null}
                            format={timeFormat} onChange={(time: moment, timeString: string) => {
                                this.props.dataActions.earningPointsSetEdit({ hourTo: timeString })
                            }} />);
                    }
                    else {
                        return text;
                    }
                }
            },
            {
                title: this.getText(15771),
                dataIndex: "isForAccumulation",
                key: "isForAccumulation",
                width: 100,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (
                            <Switch defaultChecked={record.isForAccumulation === "1"} onChange={(checked: boolean) => {
                                this.props.dataActions.earningPointsSetEdit({ isForAccumulation: checked ? 1 : 0 })
                            }} size={"default"} />);
                    } else {
                        if (record.isForAccumulation === "1") {
                            return (<span>{this.getText(15765)}</span>);
                        } else {
                            return (<span>{this.getText(15766)}</span>);
                        }
                    }
                },
                sorter: true
            },
            {
                title: this.getText(15772),
                dataIndex: "customerTagName",
                key: "customerTag",
                width: 200,
                sorter: true,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (<Select style={{ width: 250 }} size={"large"} notFoundContent={this.getText(15779)}
                            showsearch={true} defaultValue={record.customerTag} allowClear={true}
                            onChange={(value, option) => {
                                this.props.dataActions.earningPointsSetEdit({ customerTag: value })
                            }} placeholder={this.getText(15780)}>{customerTags}</Select>);
                    } else {
                        return text;
                    }
                }
            },
            {
                title: this.getText(15773),
                dataIndex: "itemCode",
                key: "itemCode",
                width: 200,
                sorter: true,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        // return (<Select style={{width: 100}} size={"large"} notFoundContent={"לא נמצאו פריטים"} showsearch={true} default={record.itemCode + " - " + record.itemName} allowClear={true} showArrow={false} mode={"combobox"} onChange={(value, option) => {this.lookupItemCodes(value); if (option) {this.props.dataActions.earningPointsSetEdit({itemCode: option.key})}}} placeholder={"בחר פריט"}>{itemCodes}</Select>);
                        // return (<Button style={{width: 175}} type={"primary"} onClick={() => {this.setState({...this.state, defaultItemCode: this.props.data.earningPoints.editedRecord.itemCode, itemCodeSelectionVisible: true})}} icon={"edit"}>{this.props.data.earningPoints.editedRecord.itemCode + " - " + this.props.data.earningPoints.editedRecord.itemName}</Button>);
                        return (<ItemPicker defaultItemCode={this.props.data.earningPoints.editedRecord.itemCode}
                            onChange={(value) => this.props.dataActions.earningPointsSetEdit(value)}
                            label={this.props.data.earningPoints.editedRecord.itemCode + " - " + this.props.data.earningPoints.editedRecord.itemName}
                            data={this.props.data.itemCodes}
                            showDialog={this.props.uiActions.uiShowItemPickerDialog}
                            hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                        />);
                    } else {
                        return record.itemCode.length > 0 ? record.itemCode + " - " + record.itemName : ""
                    }
                }
            },
            {
                title: this.getText(15774),
                dataIndex: "itemTagName",
                key: "tagId",
                width: 200,
                sorter: true,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (<Select style={{ width: 250 }} size={"large"} notFoundContent={this.getText(15779)}
                            showsearch={true} defaultValue={record.tagId} allowClear={true}
                            onChange={(value, option) => {
                                this.props.dataActions.earningPointsSetEdit({ tagId: value })
                            }} placeholder={this.getText(15781)}>{itemTags}</Select>);
                    } else {
                        return text;
                    }
                }
            },
            {
                title: this.getText(15775),
                dataIndex: "multiplication",
                key: "multiplication",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (<InputNumber min={0} defaultValue={text} onChange={(value: number) => {
                            this.props.dataActions.earningPointsSetEdit({ multiplication: value })
                        }} />);
                    } else {
                        return text;
                    }
                }
            },
            {
                title: this.getText(15776),
                dataIndex: "accumulation",
                key: "accumulation",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (
                            <InputNumber style={{ width: 80 }} min={0} defaultValue={text} onChange={(value: number) => {
                                this.props.dataActions.earningPointsSetEdit({ accumulation: value })
                            }} />);
                    } else {
                        return text;
                    }
                }
            },
            {
                title: this.getText(15777),
                dataIndex: "partialAccumulation",
                key: "partialAccumulation",
                width: 100,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (
                            <Switch defaultChecked={record.partialAccumulation === "1"} onChange={(checked: boolean) => {
                                this.props.dataActions.earningPointsSetEdit({ partialAccumulation: checked ? 1 : 0 })
                            }} size={"default"} />);
                    } else {
                        if (record.partialAccumulation === "1") {
                            return (<span>{this.getText(15765)}</span>);
                        } else {
                            return (<span>{this.getText(15766)}</span>);
                        }
                    }
                },
                sorter: true
            },
            {
                title: this.getText(15778),
                dataIndex: "maxDiscount",
                key: "maxDiscount",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing === record.key) {
                        return (<InputNumber style={{ width: 80 }} min={0} max={100} defaultValue={text}
                            onChange={(value: number) => {
                                this.props.dataActions.earningPointsSetEdit({ maxDiscount: value })
                            }} />);
                    } else {
                        return text;
                    }
                }
            },
            {
                title: "",
                fixed: "left",
                width: 100,
                render: (text, record) => {
                    if (this.props.data.earningPoints.editing !== record.key) {
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                this.props.dataActions.earningPointsStartEditing(record.key, record.index)
                            }} />
                            <Popconfirm title={this.getText(15800)}
                                onConfirm={() => this.props.dataActions.earningPointsDeleteRow(record.key, this.props.user.companyCode, this.props.user.token)}
                                okText={this.getText(15765)} cancelText={this.getText(15766)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                            </Popconfirm>
                        </span>);
                    } else {
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.props.dataActions.earningPointsSaveEdit(this.props.data.earningPoints.editedRecord, this.props.user.companyCode, this.props.user.token)
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    this.props.dataActions.earningPointsCancelEditing()
                                }} />
                        </span>);
                    }
                }
            }
        ];

        const filterFormFields: Array<Field> = [
            {
                key: "dateFrom",
                label: this.getText(15767),
                help: "",
                node: (<DatePicker
                    disabledDate={d => disabledDatePicker(d, null, this.props.data.earningPoints.filters.dateTo)}
                    value={this.props.data.earningPoints.filters.dateFrom !== "1970-01-01" ? moment(this.props.data.earningPoints.filters.dateFrom, sqlFormat) : null}
                    format={dateFormat} onChange={(date: moment, dateString: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { dateFrom: date != null ? date.format("YYYY-MM-DD") : "" })
                    }} />),
            },
            {
                key: "dateTo",
                label: this.getText(15768),
                help: "",
                node: (<DatePicker
                    disabledDate={d => disabledDatePicker(d, this.props.data.earningPoints.filters.dateFrom, null)}
                    value={this.props.data.earningPoints.filters.dateTo !== "1970-01-01" ? moment(this.props.data.earningPoints.filters.dateTo, sqlFormat) : null}
                    format={dateFormat} onChange={(date: moment, dateString: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { dateTo: date != null ? date.format("YYYY-MM-DD") : "" })
                    }} />)
            },
            {
                key: "hourFrom",
                label: this.getText(15769),
                help: "",
                node: (<TimePicker value={moment(this.props.data.earningPoints.filters.hourFrom, timeFormat)}
                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { hourFrom: timeString })
                    }} />)
            },
            {
                key: "hourTo",
                label: this.getText(15770),
                help: "",
                node: (<TimePicker value={moment(this.props.data.earningPoints.filters.hourTo, timeFormat)}
                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { hourTo: timeString })
                    }} />)
            },
            {
                key: "isForAccumulation",
                label: this.getText(15771),
                help: "",
                node: (<Select
                    value={this.props.data.earningPoints.filters.isForAccumulation > -1 ? this.props.data.earningPoints.filters.isForAccumulation : ""}
                    onChange={(value: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { isForAccumulation: value })
                    }} size={"default"}>{YesNoNull}</Select>)
            },
            {
                key: "customerTag",
                label: this.getText(15772),
                help: "",
                node: (<Select size={"large"} notFoundContent={this.getText(15779)}
                    value={this.props.data.earningPoints.filters.customerTag > -1 ? this.props.data.earningPoints.filters.customerTag : ""}
                    showsearch={true} allowClear={true}
                    onChange={(value, option) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { customerTag: value })
                    }} placeholder={this.getText(15780)}>{customerTags}</Select>)
            },
            {
                key: "itemCode",
                label: this.getText(15773),
                help: "",
                node: (<ItemPicker defaultItemCode={this.props.data.earningPoints.filters.itemCode}
                    onChange={(value) => this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, value)}
                    label={this.props.data.earningPoints.filters.itemCode + (this.props.data.earningPoints.filters.itemName ? " - " + this.props.data.earningPoints.filters.itemName : "")}
                    data={this.props.data.itemCodes}
                    showDialog={this.props.uiActions.uiShowItemPickerDialog}
                    hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                />)
            },
            {
                key: "itemTag",
                label: this.getText(15774),
                help: "",
                node: (<Select size={"large"} notFoundContent={this.getText(15779)}
                    value={this.props.data.earningPoints.filters.itemTag > -1 ? this.props.data.earningPoints.filters.itemTag : ""}
                    showsearch={true} allowClear={true}
                    onChange={(value, option) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { tagId: value })
                    }} placeholder={this.getText(15781)}>{itemTags}</Select>)
            },
            {
                key: "multiplication",
                label: this.getText(15775),
                help: "",
                node: (<InputNumber min={-1}
                    value={this.props.data.earningPoints.filters.multiplication > -1 ? this.props.data.earningPoints.filters.multiplication : ""}
                    onChange={(value: number) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { multiplication: value })
                    }} />)
            },
            {
                key: "accumulation",
                label: this.getText(15776),
                help: "",
                node: (<InputNumber min={-1}
                    value={this.props.data.earningPoints.filters.accumulation > -1 ? this.props.data.earningPoints.filters.accumulation : ""}
                    onChange={(value: number) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { accumulation: value })
                    }} />)
            },
            {
                key: "partialAccumulation",
                label: this.getText(15777),
                help: "",
                node: (<Select
                    value={this.props.data.earningPoints.filters.partialAccumulation > -1 ? this.props.data.earningPoints.filters.partialAccumulation : ""}
                    onChange={(value: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { partialAccumulation: value })
                    }} size={"default"}>{YesNoNull}</Select>)
            },
            {
                key: "maxDiscount",
                label: this.getText(15778),
                help: "",
                node: (<InputNumber min={-1}
                    value={this.props.data.earningPoints.filters.maxDiscount > -1 ? this.props.data.earningPoints.filters.maxDiscount : ""}
                    max={100} onChange={(value: number) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { maxDiscount: value })
                    }} />)
            },
        ];

        const createFormFields: Array<Field> = [
            {
                key: "dateFrom",
                label: this.getText(15767),
                help: this.getText(15782),
                node: (<DatePicker
                    disabledDate={d => disabledDatePicker(d, null, this.props.data.earningPoints.editedRecord.dateTo)}
                    format={dateFormat} onChange={(date: moment, dateString: string) => {
                        this.props.dataActions.earningPointsSetEdit({ dateFrom: date != null ? date.format("YYYY-MM-DD") : "" })
                    }} />),
            },
            {
                key: "dateTo",
                label: this.getText(15768),
                help: this.getText(15783),
                node: (<DatePicker
                    disabledDate={d => disabledDatePicker(d, this.props.data.earningPoints.editedRecord.dateFrom, null)}
                    format={dateFormat} onChange={(date: moment, dateString: string) => {
                        this.props.dataActions.earningPointsSetEdit({ dateTo: date != null ? date.format("YYYY-MM-DD") : "" })
                    }} />)
            },
            {
                key: "hourFrom",
                label: this.getText(15769),
                help: this.getText(15784),
                node: (<TimePicker
                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                        this.props.dataActions.earningPointsSetEdit({ hourFrom: timeString })
                    }} />)
            },
            {
                key: "hourTo",
                label: this.getText(15770),
                help: this.getText(15785),
                node: (<TimePicker
                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                        this.props.dataActions.earningPointsSetEdit({ hourTo: timeString })
                    }} />)
            },
            {
                key: "isForAccumulation",
                label: this.getText(15771),
                help: this.getText(15786),
                node: (<Switch onChange={(checked: boolean) => {
                    this.props.dataActions.earningPointsSetEdit({ isForAccumulation: checked ? 1 : 0 })
                }} size={"default"} />)
            },
            {
                key: "customerTag",
                label: this.getText(15772),
                help: this.getText(15787),
                node: (<Select size={"large"} notFoundContent={this.getText(15779)}
                    showsearch={true} allowClear={true}
                    onChange={(value, option) => {
                        this.props.dataActions.earningPointsSetEdit({ customerTag: value })
                    }} placeholder={this.getText(15780)}>{customerTags}</Select>)
            },
            {
                key: "itemCode",
                label: this.getText(15773),
                help: this.getText(15788),
                node: (<ItemPicker defaultItemCode={""}
                    onChange={(value) => this.props.dataActions.earningPointsSetEdit(value)}
                    label={this.props.data.earningPoints.editedRecord.itemCode + (this.props.data.earningPoints.editedRecord.itemName ? " - " + this.props.data.earningPoints.editedRecord.itemName : "")}
                    data={this.props.data.itemCodes}
                    showDialog={this.props.uiActions.uiShowItemPickerDialog}
                    hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                />)
            },
            {
                key: "itemTag",
                label: this.getText(15774),
                help: this.getText(15789),
                node: (<Select size={"large"} notFoundContent={this.getText(15779)}
                    showsearch={true} allowClear={true}
                    onChange={(value, option) => {
                        this.props.dataActions.earningPointsSetEdit({ tagId: value })
                    }} placeholder={this.getText(15781)}>{itemTags}</Select>)
            },
            {
                key: "multiplication",
                label: this.getText(15775),
                help: this.getText(15790),
                node: (<InputNumber min={0} onChange={(value: number) => {
                    this.props.dataActions.earningPointsSetEdit({ multiplication: value })
                }} />)
            },
            {
                key: "accumulation",
                label: this.getText(15776),
                help: this.getText(15791),
                node: (<InputNumber min={0} onChange={(value: number) => {
                    this.props.dataActions.earningPointsSetEdit({ accumulation: value })
                }} />)
            },
            {
                key: "partialAccumulation",
                label: this.getText(15777),
                help: this.getText(15792),
                node: (<Switch onChange={(checked: boolean) => {
                    this.props.dataActions.earningPointsSetEdit({ partialAccumulation: checked ? 1 : 0 })
                }} size={"default"} />)
            },
            {
                key: "maxDiscount",
                label: this.getText(15778),
                help: this.getText(15793),
                node: (<InputNumber min={0} max={100} onChange={(value: number) => {
                    this.props.dataActions.earningPointsSetEdit({ maxDiscount: value })
                }} />)
            },


        ];

        const toolbarActions: Array<Object> = [
            /*{
                buttonType: "filter",
                buttonFunction: this.handleShowFilters.bind(this)
            },*/
            {
                buttonType: "add",
                buttonFunction: this.handleCreateNew.bind(this)
            },
            {
                buttonType: "refresh",
                buttonFunction: this.refreshData.bind(this)
            }
        ];

        /*
        <h1>צבירת נקודות
                    <div className="table-actions">
                        <Button shape={"circle"} icon={"filter"} onClick={this.handleShowFilters.bind(this)}/>
                        <Button type={"primary"} icon={"plus"} onClick={this.handleCreateNew.bind(this)}>הוסף חדש</Button>
                    </div>
                </h1>
         */
        return (
            <div style={divStyle}>

                <PageToolbar title={this.getText(15794)} actionButtons={toolbarActions} isOfModal={false} />

                <Table dataSource={dataSource} columns={columns} loading={this.props.ui.workInProgress}
                    scroll={{ x: 1650 }} bordered
                    pagination={this.props.data.earningPoints.pagination}
                    onChange={this.handleTableChange.bind(this)}

                    locale={{ filterConfirm: this.getText(15797), filterReset: this.getText(15796), emptyText: this.getText(15795) }}
                />


                <ModalForm zIndex={50} visible={this.props.ui.modalStack.indexOf("EarningPointsNew") > -1}
                    workInProgress={this.props.ui.workInProgress} title={this.getText(15798)}
                    token={this.props.user.token} companyCode={this.props.user.companyCode}
                    cancelAction={this.handleCreateFormCancel.bind(this)}
                    recordData={this.props.data.earningPoints.editedRecord}
                    fields={createFormFields} saveAction={this.props.dataActions.earningPointsSaveEdit} />

                <ModalFilterForm zIndex={50} visible={this.props.ui.modalStack.indexOf("EarningPointsFilters") > -1}
                    workInProgress={this.props.ui.workInProgress} title={this.getText(15799)}
                    token={this.props.user.token} companyCode={this.props.user.companyCode}
                    cancelAction={this.handleClearFilters.bind(this)}
                    recordData={this.props.data.earningPoints.filters}
                    fields={filterFormFields} saveAction={this.handleHideFilters.bind(this)} />
            </div>
        );
    }
}

export default EarningPoints;
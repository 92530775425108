/* @flow */
/*jshint esversion: 6 */
import React from "react";
import ReactDOM from "react-dom";
import { Menu, Icon, Badge } from "antd";
import { withRouter } from "react-router-dom";
const SubMenu = Menu.SubMenu;

type History = {
    push: Function,
    location: {
        pathname: string
    }
};

type Props = {
    history: History,
    baseUrl: string
};

type State = {
  menuTag: any,
  menuItemsTop: Array<Object>,
  menuItemsDrop: Array<Object>
};

class ReportMenu extends React.Component<Props, State> {
    myHistory: History;
    baseUrl: string;

    constructor(props:Props) {
        super(props);
        this.myHistory = this.props.history;
        this.baseUrl = this.props.baseUrl;
        this.state = {
            menuTag: (<div></div>),
            menuItemsTop: [
                { key: "sales-compare", text: "השוואת מסמכים", icon: "setting" },
                { key: "hour-report", text: "דוח שעות", icon: "setting" },
                { key: "sales-by-date", text: "מכירות - לתקופה", icon: "setting" },
                { key: "sales-avg", text: "מכירות - ממוצע", icon: "setting" },
                { key: "sales-profit", text: "מכירות - רווח", icon: "setting" },
                { key: "sales-detailed", text: "מכירות - מפורט", icon: "setting" },
                { key: "payments-daily", text: "תקבולים - יומי", icon: "setting" },
                { key: "payments-monthly", text: "תקבולים - חודשי", icon: "setting" },
                { key: "payment-aggregate", text: "תקבולים - מסוכם", icon: "setting" },
                { key: "payment-detailed", text: "תקבולים - מפורט", icon: "setting" },
                { key: "payments-shva", text: "שידורי שב\"א", icon: "setting" },
                { key: "payments-checks", text: "דוח פירוט שקים", icon: "setting" },
                { key: "payments-creditCard", text: "סיכום עסקאות אשראי", icon: "setting" },
                { key: "stock-items-balance", text: "יתרת מלאי פריטים", icon: "setting" },
                { key: "dead-stock", text: "מלאי מת", icon: "setting" },
                { key: "stock-Item-Card", text: "כרטיס פריט", icon: "setting" },
                { key: "payments-search", text: "חיפוש מסמך", icon: "setting" }, 
            ],
            menuItemsDrop: []
        };
    }

    onMenuSelect(args) {
        this.myHistory.push(
            this.baseUrl + "/reports/" + (args.key !== "home" ? args.key : ""),
            {}
        );
    }

    updatePriorityMenu = () => {
        let maxHeight = 50; //px
        let _height = -1;

        let listTop = this.state.menuItemsTop;
        let listDrop = this.state.menuItemsDrop;
        // re-populate menu
        for (let i = 0; i < listDrop.length; i++) {
            listTop.push(listDrop.pop());
        }

        //ReactDOM.findDOMNode(this).clientHeight;
        while (_height > maxHeight || _height < 0) {
            this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            this.setState((prevState, props) => {
                return {
                    menuTag: <Menu
                        mode="horizontal"
                        defaultSelectedKeys={[
                            this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[2] || "home"
                        ]}
                        onSelect={this.onMenuSelect.bind(this)}
                    >
                        {this.renderMenuItemsTop()}
                        <SubMenu title={<Badge count={listDrop.length}>
                            <span><Icon type="ellipsis" /></span></Badge>}>
                            {this.renderMenuItemsDrop()}
                        </SubMenu>
                    </Menu>
                }
            });
            console.log("findDomNode report: ",(this) ? "true" : "false", this);
            let e:Element|null|Text = ReactDOM.findDOMNode(this);
            _height = (e instanceof HTMLElement) ? e.clientHeight : 0;
            //alert(_height);
        }
    }

    renderMenuItemsTop() {
        let res = [];
        let list = this.state.menuItemsTop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.text}</span>
                    </Menu.Item>);
            }
            return res;
        }
    }
    
    renderMenuItemsDrop() {
        let res = [];
        let list = this.state.menuItemsDrop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.text}</span>
                    </Menu.Item>);
            }
        }
        return res;
    }

    doUpdateOfPriorityMenu = () => {
        setTimeout(this.updatePriorityMenu);
    };

    // Add event listener
    componentDidMount() {
        setTimeout(this.updatePriorityMenu);
        //window.addEventListener("resize", this.updatePriorityMenu);
        window.addEventListener("resize", this.doUpdateOfPriorityMenu );
    }

    // Remove event listener
    componentWillUnmount() {
        //window.removeEventListener("resize", this.updatePriorityMenu);
        window.removeEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    render() {
        return this.state.menuTag;
    }
}

export default withRouter(ReportMenu);

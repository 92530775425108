
import React from "react";
import { Button, Select, Switch, Icon, Modal, Input } from 'antd';
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";
import ResponsiveTable from "./ResponsiveTable";
import { escapeRegExp } from "../utils/utils";

const { Option } = Select;
const Search = Input.Search;

const topHeight = 350; // px
const rowHeight = 40;  // px

class SelectorItemsMulti extends React.Component/*<Props, State>*/ {
    constructor(props: DataMultiSelectModalProps) {
        super(props);
        this.state = {
            itemsChecked: [],
            modalOpen: false,
        };

        this.items_id = "ITEMS_LIST_TO_ATTREBUTE";
        if (props.data[this.items_id] === undefined) props.data[this.items_id] = { ...props.data.genericData };

        this.loadFavoriteData = null;
        this.favoriteData = null;
        this.favoriteUpdated = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilterItems('');
    }

    componentDidUpdate(prevProps) {
        console.log('setFromFavorite -1 ', prevProps.ui.favoriteParams, this.props.ui.favoriteParams)

        if (this.props.ui.favoriteParams && this.props.fieldName && (!this.favoriteUpdated || this.props.ui.favoriteParams !== prevProps.ui.favoriteParams)) {
            this.favoriteUpdated = true;
            this.favoriteData = this.props.ui.favoriteParams[this.props.fieldName];
            console.log('setFromFavorite 0 ', this.favoriteData)
            clearTimeout(this.loadFavoriteData);
            this.loadFavoriteData = setTimeout(() => {
                this.setFromFavorite()
            }, 1000);
        }
    }

    setFromFavorite = () => {
        let data = this.props.data[this.items_id].data;

        console.log('setFromFavorite 1', data)
        if (this.favoriteData) {
            console.log('setFromFavorite 2')
            if (data.length) {
                console.log('setFromFavorite 3')

                let itemsChecked = this.favoriteData.split(',').map(x => {
                    let findItem = data.find(f => f.split('\f')[1] === x)
                    return { mCode: x, mShortName: findItem ? findItem.split('\f')[2] : '' }
                });
                this.setState({ itemsChecked })
            } else {
                console.log('setFromFavorite 4')
                clearTimeout(this.loadFavoriteData);
                this.loadFavoriteData = setTimeout(() => {
                    this.setFromFavorite()
                }, 1000);
            }

        }
    }

    initFilterItems = (_filter) => {
        let request = {
            _filter,
            _tagList: '',
            _isActive: '',
            _isWeightable: '',
            _isFixedPrice: '',
            _isForSale: '',
            _isDiscount: '',
            _saleAlert: '',
            _supplierList: '',
            _isForInventory: '',
            _isSerialize: '',
            _isMatrixReverse: '',
            _fatherItemsList: '',
            _connectedItems: '',
            _taxType: '',
            _searchColFilter: '1',
            _notAssociatedTag: '0',
            _notAssociatedSupp: '0',
            _rulerList: '',
            _notAssociatedRuler: '0',
            _itemSerialNumber: '',
            _supplierItemCode: '',
            _tagRelation: '1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.items_id, this.props.user.companyCode, this.props.user.token, request, "get_items_list_all_with_filter");
    }

    handleCancel = () => {
        this.setState({ modalOpen: false });
    }

    handleClear = () => {
        this.setState({ itemsChecked: [] }, this.handleOk);
    }

    handleOk = () => {
        this.props.onChange(this.state.itemsChecked);
    }

    getSwitch = (text, record) => {
        let oldDataset = [...this.state.itemsChecked];
        return (<Switch
            onChange={(v) => {
                let newDataset = v ? [...oldDataset, { mCode: record.mCode, mShortName: record.mShortName }] : oldDataset.filter(f => f.mCode !== record.mCode)
                this.setState({ itemsChecked: newDataset }, this.handleOk)
            }}
            checked={oldDataset.findIndex(f => f.mCode === record.mCode) > -1}
        />)
    }

    render() {
        let numOfRowsByHeight = parseInt((window.innerHeight - topHeight > rowHeight)
            ? (window.innerHeight - topHeight) / rowHeight
            : 1);

        let itemsColumns = [
            { title: this.getText(15450), dataIndex: "mCode", key: "mCode", width: '40%' },
            { title: this.getText(15451), dataIndex: "mShortName", key: "mShortName", width: '40%' },
            { width: '20%', render: this.getSwitch }
        ];

        return (<span>
            <Select
                key={"itemsMultiSelect"}
                style={{ width: "80%", marginLeft: 8 }}
                mode="multiple"
                maxTagCount={6}
                onChange={(value) => {
                    this.setState({ itemsChecked: this.state.itemsChecked.filter(f => value.find(ff => ff === f.mCode)) }, this.handleOk)
                }}
                value={this.props.value ? this.props.value : this.state.itemsChecked.map(x => x.mCode)}
                notFoundContent={this.getText(15449)}
                showArrow={false}
            >
                {this.state.itemsChecked.map((x) => (<Option value={x.mCode}>{x.mShortName}</Option>))}
            </Select>

            <Button htmlType={"button"} onClick={() => { this.setState({ modalOpen: true }) }}><Icon type="plus" /> {this.getText(15448)}</Button>

            <Modal
                title={this.getText(15445)}
                visible={this.state.modalOpen}
                onCancel={this.handleCancel}
                width={600}
                footer={[
                    <Button key={"back"} onClick={this.handleCancel}>{this.getText(15446)}</Button>,
                    <Button type="primary" key={"check"} onClick={this.handleClear}>{this.getText(15447)}</Button>
                ]}>
                <div>
                    <Search onSearch={(val) => {
                        this.initFilterItems(
                            escapeRegExp(val),
                            // val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
                        )
                    }} size={"large"} enterButton />
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.items_id]}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                        columns={itemsColumns}
                        pagination={{ pageSize: numOfRowsByHeight }} />
                </div>
            </Modal>
        </span>);
    }

}

export default SelectorItemsMulti;

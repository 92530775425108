
import React from "react";
import {
    Form, Button, DatePicker, Modal, Checkbox, Select, message
} from 'antd';
import moment from "moment";
import PageToolbar from "./PageToolbar";
import LangContext from "../contextProvider/LangContext";
import CustPicker from "./CustPicker";
import TableIDs from "../data/TableIDs";
import DateTimePicker from "./DateTimePicker";

const FormItem = Form.Item;
const { Option } = Select;

class ModalSearchAggregateCard extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.selectors = [
            { id: TableIDs.genericSelectorCustomers, script: "get_customers_list_v3" },
            { id: TableIDs.genericSelectorPos, script: "list_pos" },
        ]

        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.state = {
            loading: false,
            visible: false,
            request: {
                _creationDateStart: null,
                _creationDateEnd: null,
                _activityDateStart: null,
                _activityDateEnd: null,
                _customer: null,
                _posList: '',
                _isUsed: 0,
            }
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData();
    }

    // send data to parent
    UNSAFE_componentWillReceiveProps(newProps) {
        let _state = this.state;
        _state.visible = newProps.toggle;
        this.setState(_state);
        console.log("yuda componentWillReceiveProps")
    }

    refreshData = () => {
        this.selectors.forEach((x) => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.script, { _words: null });
        })
    }

    createCheckBox = (field, data) => {
        let r = this.state.request;
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setState({ request: { ...r, [field]: data } });
                else
                    this.setState({ request: { ...r, [field]: '' } });
            }} />);
    }

    // bind input field
    onChange = (field, value) => {
        let r = this.state.request;
        this.setState({
            request: {
                ...r, [field]: value,
            }
        });
    }

    createDatePicker = (text, field) => {
        return (<FormItem label={this.getText(text)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
            {/* <DatePicker
                format="DD/MM/YYYY"//"YYYY-MM-DD"
                value={this.state.request[field] ? moment(this.state.request[field], "YYYY-MM-DD") : null}
                onChange={(v) => { this.onChange(field, v ? v.format("YYYY-MM-DD") : null) }}
            /> */}
            <DateTimePicker
                format="DD/MM/YYYY"//"YYYY-MM-DD"
                value={this.state.request[field]}
                onChange={(v) => { this.onChange(field, v) }}
                minDate={field == '_creationDateEnd' ? this.state.request._creationDateStart : field == '_activityDateEnd' ? this.state.request._activityDateStart : null}
                maxDate={field == '_creationDateStart' ? this.state.request._creationDateEnd : field == '_activityDateStart' ? this.state.request._activityDateEnd : null}
            />
        </FormItem>)
    }

    createSelector = (text, field, selector, multi, keyDown) => {
        let onInputKeyDown = keyDown ? (e) => {
            clearTimeout(this.timeOut);
            this.timeout = setTimeout((ev) => {
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.genericSelectorRefreshDataset,
                    args: [this.selectors[selector].id, this.props.user.companyCode, this.props.user.token,
                    this.selectors[selector].script, { _words: ev }]
                });
            }, 500, e.target.value)
        } : false;

        let data = this.state.request[field];
        let s = this.props.data[this.selectors[selector].id]
        let sData = s ? s.dataset : [];
        let list = sData.map((x) => (<Option key={x['code']} value={x['code']} valuefilter={x['code'] + ' ' + x['name']}>{x['code'] + ' - ' + x['name']}</Option>));
        let fullList = sData.map((x) => x.code).join(',');
        return (<FormItem label={this.getText(text)}>
            {multi ? this.createCheckBox(field, fullList) : ""}
            <Select key={field} showSearch filterOption={true} optionFilterProp="valuefilter"
                mode={multi ? "multiple" : null} maxTagCount={10}
                style={{ width: multi ? '90%' : '95%', paddingLeft: '4px', paddingRight: '4px' }}
                value={data && data.length > 0 ? multi ? data.split(',') : data : multi ? [] : ""}
                onChange={(e) => { this.onChange(field, multi ? e.join(',') : e) }}
                onInputKeyDown={onInputKeyDown}
            >{list}</Select>
        </FormItem>)
    }

    createYesNoSelector = (label, field, yes, no) => {
        return (<FormItem label={this.getText(label)}>
            <Select key={field} style={{ width: '95%', paddingLeft: '4px', paddingRight: '4px' }}
                value={this.state.request[field]} onChange={(value) => { this.onChange(field, value) }}
            >
                <Option key={1} value={0}> - </Option>
                <Option key={2} value={1}>{this.getText(yes)}</Option>
                <Option key={3} value={2}>{this.getText(no)}</Option>
            </Select>
        </FormItem>)
    }

    resetFilter = () => {
        let _request = {
            _creationDateStart: null,
            _creationDateEnd: null,
            _activityDateStart: null,
            _activityDateEnd: null,
            _customer: null,
            _posList: '',
            _isUsed: 0,
        }

        this.setState({ request: _request }, () => { this.props.dataSetFilter(_request) });
    }


    handleReset = () => {
        if (this.props.setFilterActive) this.props.setFilterActive(false)
        else this.resetFilter()
    }

    handleOk = () => {
        this.props.dataSetFilter(this.state.request);
        if (this.props.setFilterActive) this.props.setFilterActive(true)
        this.handleCancel();
    }

    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }

    componentDidUpdate(prev) {
        if (prev.filterActive && !this.props.filterActive) this.resetFilter()
    }

    render() {
        const { visible, loading } = this.state;

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        let colDivStyle = {
            width: window.innerWidth > 600 ? '33%' : '100%',
            padding: 10,
            verticalAlign: 'text-top',
            display: 'inline-block'
        }

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(12747)}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>);

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'70%'}
                visible={visible}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(12757)}</Button>,
                    <Button key="clear" onClick={this.handleReset}>{this.getText(12758)}</Button>,
                    <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(12759)}</Button>
                ]}
            >
                <Form layout="horizontal" className="ant-advanced-search-form">
                    <div style={colDivStyle}>
                        {this.createDatePicker(12748, '_creationDateStart')}
                        {this.createDatePicker(12749, '_creationDateEnd')}
                    </div>
                    <div style={colDivStyle}>
                        {this.createDatePicker(12750, '_activityDateStart')}
                        {this.createDatePicker(12751, '_activityDateEnd')}
                    </div>
                    <div style={colDivStyle}>
                        {/* {this.createSelector(12752, '_customer', 0, false, true)} */}
                        <FormItem label={this.getText(12752)}>
                            <CustPicker
                                value={this.state.request ? this.state.request._customer : null}
                                onChange={(value) => { this.onChange('_customer', value) }}
                                defaultView={this.getText(12121)}
                                data={this.props.data}
                                dataActions={this.props.dataActions}
                                uiActions={this.props.uiActions}
                                user={this.props.user}
                            />
                        </FormItem>
                        {this.createSelector(12753, '_posList', 1, true, false)}
                        {this.createYesNoSelector(12754, '_isUsed', 12755, 12756)}
                    </div>
                </Form >
            </Modal >
        )
    }


}

export default ModalSearchAggregateCard;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Select, Tooltip, Popconfirm, Button, Row, Col, message } from "antd";
import Jax from "../../Jax/jax.es6.module";
import ResponsiveTable from "../../components/ResponsiveTable";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import GenericSelector from "../../components/GenericSelector";
import GenericModal from "../../components/GenericModal";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

import { fixCurrentPage } from "../../utils/utils.js"
import LangContext from "../../contextProvider/LangContext";
import { escapeRegExp } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 34;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class PermissionTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "PERMISSIONS";
        this.state = {
            height: window.innerHeight,
            modalCopy: false,
            fromPos: null,
            toPos: null,
        };

        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[TableIDs.genericSelectorGroupEmployee] === undefined) {
            props.data[TableIDs.genericSelectorGroupEmployee] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

        this.api = {
            edit: "edit_permissions_table",
            get: "get_permissions_table",
            copy: "copy_permissions_table",
            selector_employees: "get_employee_list",
            selector_group_employees: "get_employee_group_list",
            selector_pos: "list_pos",
        }

        this.actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10214);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        console.log("kkkk initfilter - num rows: ", numOfRowsByHeight, " h: ", this.state.height)

        this.setFilter({
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            _pos: '0',
        });
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorGroupEmployee, this.props.user.companyCode, this.props.user.token, this.api.selector_group_employees);

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        console.log("employee - handle table change: ",
            JSON.stringify(pagination), "\n",
            JSON.stringify(filters), "\n",
            JSON.stringify(sorter));

        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mPermissionId": col--;
            case "mDescription": col--;
            case "mEmployeeGroupIdList": col--;
            case "mEmployeeIdList": break; // 4
            default:
                col = 0;
        }

        this.setFilter({
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //col
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        })
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    setFilter = (request) => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    renderEmployeeGroupIdList = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        let listGroupEmployee = Jax.get(this.props.data, TableIDs.genericSelectorGroupEmployee + ".dataset", []).map((obj, index) => {
            return (<Option dir={"rtl"} key={obj['code']} value={obj['code']} valuefilter={obj['name'] + obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        return (
            <Select
                showsearch
                filterOption={true}
                optionFilterProp="valuefilter"
                mode="multiple"
                maxTagCount={3}
                //filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                //optionFilterProp="children"
                //placeholder="בחירת תגיות"
                defaultValue='none'
                allowClear={true}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { mEmployeeGroupIdList: e.join(',') }) }}
                style={{ width: '100%', paddingLeft: '4px' }}
                value={(editing === record.key)
                    ? (editedRecord['mEmployeeGroupIdList'] ? editedRecord['mEmployeeGroupIdList'].split(',') : [])
                    : ((text) ? text.split(',') : [])}
                disabled={!(editing === record.key)}
            >
                {listGroupEmployee}
            </Select>
        )
    }

    renderEmployeeIdList = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        let listEmployee = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => {
            return (<Option dir={"rtl"} key={obj['code']} value={obj['code']} valuefilter={obj['name'] + obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        return (
            <Select
                showsearch
                filterOption={true}
                optionFilterProp="valuefilter"
                mode="multiple"
                maxTagCount={2}
                //filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                //optionFilterProp="children"
                //placeholder="בחירת תגיות"
                defaultValue='none'
                allowClear={true}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { mEmployeeIdList: e.join(',') }) }}
                style={{ width: '100%', paddingLeft: '4px' }}
                value={(editing === record.key)
                    ? (editedRecord['mEmployeeIdList'] ? editedRecord['mEmployeeIdList'].split(',') : [])
                    : ((text) ? text.split(',') : [])}
                disabled={!(editing === record.key)}
            >
                {listEmployee}
            </Select>)
    }

    renderEditCol = (text, record) => {
        let gt = this.getText;
        const { editing, filters } = this.props.data[this.id];

        if (editing !== record.key) {
            return (
                <span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                        this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                    }} />
                    <Popconfirm title={gt(11344)}
                        onConfirm={() => {
                            console.log("record", record);
                            fixCurrentPage.bind(this)();
                            this.props.dataActions.genericDataDeleteRow(
                                this.id,
                                record['mPermissionId'],
                                this.props.user.companyCode,
                                this.props.user.token,
                                this.api.delete,
                                this.api.get);
                        }
                        }
                        okText={gt(11345)} cancelText={gt(11346)}>
                        <Icon style={{ fontSize: 16, display: 'none' /*remove this to show*/ }}
                            className={"clickable margin-4"} type={"delete"} />
                    </Popconfirm>
                </span >)
        } else {
            let isCreate = (editing == 0) ? true : false;
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                    onClick={() => {
                        this.props.dataActions.genericDataSaveEditWithParams(
                            this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get, { _pos: filters['_pos'] });
                    }} />
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                    onClick={() => {
                        if (isCreate)
                            this.props.dataActions.genericDataCancelInlineCreate(this.id);
                        else
                            this.props.dataActions.genericDataCancelEditing(this.id);
                    }} />
            </span>)
        };
    }

    okCopy = () => {
        const { fromPos, toPos } = this.state;
        this.sendAPI(this.api.copy, '_fromPos\f_toPos\r' + fromPos + '\f' + toPos, () => {
            message.success(16325);
            this.cancelCopy();
            this.refreshData();
        })
    }

    cancelCopy = () => {
        this.setState({ modalCopy: false, fromPos: null, toPos: null });
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, e => { console.error(e) });
    }

    render() {
        let gt = this.getText;
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            // padding: "2% 2% 2% 2%",
            paddingLeft: '2%',
            paddingRight: '2%',
        };

        const selectorStyle = {
            marginTop: 4,
            marginBottom: 4,
            marginLeft: 4,
            width: 300,
            display: 'inline-block'
        }

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mPermissionId: y[0],
                mDescription: y[1],
                mEmployeeGroupIdList: y[2],
                mEmployeeIdList: y[3],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let _pos = this.props.data[this.id].filters['_pos'];
        let sorter = true;
        let render = t => t;

        let columns = [
            { title: gt(11339), dataIndex: "mPermissionId", key: "mPermissionId", sorter, width: '4%', render },
            { title: gt(11340), dataIndex: "mDescription", key: "mDescription", sorter, width: '20%', render },
            { title: gt(11341), dataIndex: "mEmployeeGroupIdList", key: "mEmployeeGroupIdList", width: '34%', sorter, render: this.renderEmployeeGroupIdList },
            { title: gt(11342), dataIndex: "mEmployeeIdList", key: "mEmployeeIdList", width: '36%', sorter, render: this.renderEmployeeIdList },
            { title: gt(11343), width: '6%', render: this.renderEditCol }
        ];

        return (
            <div style={divStyle}>
                <div style={selectorStyle}>
                    <GenericSelectorPosStorages
                        {...this.props}
                        // id={TableIDs.genericSelectorPos}
                        // api={this.api.selector_pos}
                        value={_pos}
                        onChange={(e) => {
                            this.setFilter({ ...request, _pos: e })
                        }}
                        // addFirstRow={{ code: '0', name: gt(16318) }}
                    />
                </div>
                <Tooltip title={gt(16319)}>
                    <Button type="primary" icon="copy" onClick={() => { this.setState({ modalCopy: true }) }} />
                </Tooltip>
                <PageToolbar
                    title={gt(11338)}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.setFilter({
                            ...request,
                            _words: escapeRegExp(val),
                            //  _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                            page: 1,
                            _page_num: 1,
                        })
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />
                <ResponsiveTable
                    tableOnly={true}
                    percentageToMobile={[false, 30, 70]}
                    ui={this.props.ui}
                    idTable={this.props.data[this.id]}
                    dataSource={dataSource}
                    columns={columns}
                    onChange={this.handleTableChange}
                />
                <GenericModal
                    visible={this.state.modalCopy}
                    onCancle={this.cancelCopy}
                    title={gt(16320)}
                    width={400}
                    footer={[
                        <Button onClick={this.cancelCopy}>{gt(16321)}</Button>,
                        <Button type="primary" onClick={this.okCopy}>{gt(16322)}</Button>
                    ]}>
                    <Row style={{ marginBottom: 10 }}>
                        <Col span={8}>{gt(16323)}:</Col>
                        <Col span={16}>
                            <GenericSelectorPosStorages  {...this.props}
                                // id={TableIDs.genericSelectorPos}
                                value={this.state.fromPos}
                                onChange={(e) => { this.setState({ fromPos: e }) }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>{gt(16324)}:</Col>
                        <Col span={16}>
                            <GenericSelectorPosStorages  {...this.props}
                                // id={TableIDs.genericSelectorPos}
                                value={this.state.toPos}
                                onChange={(e) => { this.setState({ toPos: e }) }} />
                        </Col>
                    </Row>
                </GenericModal>
            </div>
        );
    }
}

export default PermissionTable;

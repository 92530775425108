
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Switch, Card, Col, Row, Form } from "antd";
import DateTimePicker from "../../components/DateTimePicker";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";

const FormItem = Form.Item;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class PosVersions extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            dateFrom: null,
            dateTo: null,
            isActive: 1,
            versionList: '',
            reportName: "reports/PosVersions.pdf",
        };

        this.selectors = [
            { id: 'POS_ADMIN_VERSION_LIST', api: "pos_admin_version_list" },
        ];

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15328);
    }

    createDatePicker = (label, field) => {

        return (<FormItem label={this.getText(label)}>
            <DateTimePicker
                format="DD/MM/YYYY"
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                minDate={field == 'dateTo' ? this.state.dateFrom : null}
                maxDate={field == 'dateFrom' ? this.state.dateTo : null}
            />
        </FormItem>)
    }

    render() {
        let mobile = window.innerWidth > 600 ? false : true;

        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            maxWidth: 1000,
            margin: 'auto',
            flexDirection: 'column',
        };


        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={this.getText(15329)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: '95%' }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 10} style={{ padding: 5 }}>
                        <FormItem>
                            <Switch
                                checkedChildren={this.getText(15330)} unCheckedChildren={this.getText(15331)}
                                checked={this.state.isActive == '1'}
                                onChange={(e) => { this.setState({ isActive: e ? '1' : '0' }) }} />
                        </FormItem>
                        {this.createDatePicker(15332, 'dateFrom')}
                        {this.createDatePicker(15333, 'dateTo')}
                    </Col>
                    <Col span={mobile ? 24 : 14} style={{ padding: 5 }}>
                        <FormItem label={this.getText(15334)}>
                            <GenericSelector
                                multi
                                value={this.state.versionList ? this.state.versionList.split(',') : []}
                                onChange={(e) => { this.setState({ versionList: e ? e.join(',') : '' }) }}
                                {...this.selectors[0]}
                                {...this.props} />
                        </FormItem>
                        <ReportTypeButton  {...this.props} params={this.state} xlsReportName={"reports/PosVersionsExcel.xls"} />
                    </Col>
                </Row>
            </Card>
        </div>);
    }
}
export default PosVersions; 
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Button, Card, Tooltip, Icon, Modal } from "antd";
import KdsBonItem from "./KdsBonItem";
import KdsBonPrevOrders from "./KdsBonPrevOrders";

type Props = {
    kdsData: Object,
    noDataText: String,
    sendAPI: Function,
    setJson: Function,
    data: Object,
    height: Number,
}

class KdsBonimView extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalOwner: null,
        };

        this.loop1 = null;
        this.counterRefresh = 0;

        this.id = "KDS_BONIM_VIEW";

        this.api = {
            get: "get_kds_bonim_view",
        }

        this.selectors = [
            { id: 'BONIM_TAGS', api: 'get_tags_from_type_2' },
        ];

        if (props.data[this.id] === undefined) props.data[this.id] = { data: [] };

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { dataset: [] };
        });

    }

    componentDidMount() {
        this.getDataApi();
        this.loopRefresh();

        this.props.sendAPI(this.selectors[0].api, '', (obj) => {
            let dataset = obj.data ? obj.data.split('\r').map(x => {
                let y = x.split('\f');
                return { code: y[0], name: y[1] }
            }) : []

            this.props.setJson(this.id, { dataset });
        });
    }

    componentWillUnmount() {
        clearTimeout(this.loop1);
    }

    loopRefresh = () => {
        let refreshTime = parseInt(this.props.kdsData.sRefreshTime);

        this.counterRefresh++;

        if (refreshTime <= this.counterRefresh) {
            this.getDataApi();
            this.counterRefresh = 0;
        }

        this.loop1 = setTimeout(this.loopRefresh, 1000);
    }

    getDataApi = () => {
        // 
        this.props.sendAPI(this.api.get, '_id\r' + this.props.kdsData.sID, (obj) => {
            let data = []
            if (obj.data) {
                let dataRes = obj.data.split('\r').map(x => {
                    let y = x.split('\f');
                    return {
                        oID: y[0],
                        oNumber: y[1],
                        id: y[2],
                        itemCode: y[3],
                        itemName: y[4],
                        qty: y[5],
                        readyStatus: y[6],
                        title: y[7],
                        group: y[8],
                        groupTitle: y[9],
                        orderTime: y[10],
                        orderMethod: y[11],
                    }
                });

                dataRes.forEach(x => {
                    let bonReadyStatus = {}
                    if (parseInt(x.readyStatus)) {
                        bonReadyStatus = { _finish: true }
                    } else {
                        bonReadyStatus = { _notFinish: true }
                    }
                    let oIndex = data.findIndex(f => f.oID === x.oID)
                    if (oIndex > -1) {
                        data[oIndex] = {
                            ...data[oIndex],
                            ...bonReadyStatus,
                            oBonData: [...data[oIndex].oBonData, x]
                        }
                    } else {
                        data.push({
                            oID: x.oID,
                            oNumber: x.oNumber,
                            orderTime: x.orderTime,
                            orderMethod: x.orderMethod,
                            oBonData: [x],
                            ...bonReadyStatus,
                        })
                    }
                })
            }

            this.props.setJson(this.id, { data });

            let x = this.state.modalOwner ? data.find(f => f.oID === this.state.modalOwner.owner.oID) : null;
            if (x) {
                let tagData = this.props.data[this.selectors[0].id].dataset.find(f => f.code === this.props.kdsData.sItemTagId);
                // let color = (x._finish && x._notFinish) ? 'yellow' : (x._finish && !x._notFinish) ? 'lawngreen' : (!x._finish && x._notFinish) ? 'pink' : ''
                let color = this.getColor(x);
                let modalOwner = {
                    owner: {
                        oID: x.oID,
                        oNumber: x.oNumber,
                        orderTime: x.orderTime,
                        orderMethod: x.orderMethod,
                        _notFinishBons: x._notFinish && tagData ? [tagData.code] : []
                    },
                    bon: { ...tagData, color },
                    bonData: x.oBonData
                }
                this.setState({ modalOwner })
            }
        });

    }

    getColor = (x) => {
        let color = 'lawngreen';
        if (!this.props.isShort) {
            if (x._finish && x._notFinish) color = 'yellow';
            if (!x._finish && x._notFinish) color = 'pink';
        }
        return color;
    }

    render() {

        let rowHeight = this.props.height / 2;

        const divStyle = {
            height: this.props.height,
            overflowY: 'auto',
        }

        let tagData = this.props.data[this.selectors[0].id].dataset.find(f => f.code === this.props.kdsData.sItemTagId);

        return (<div style={divStyle}>
            {/* {this.props.isShort ? (<KdsBonPrevOrders {...this.props}  handleChange={this.getDataApi} />) : ""} */}
            {this.props.data[this.id].data.length ?
                this.props.data[this.id].data.map(x => {
                    // let color = (x._finish && x._notFinish) ? 'yellow' : (x._finish && !x._notFinish) ? 'lawngreen' : (!x._finish && x._notFinish) ? 'pink' : '';
                    let color = this.getColor(x);

                    let dataProps = {
                        owner: {
                            oID: x.oID,
                            oNumber: x.oNumber,
                            orderTime: x.orderTime,
                            orderMethod: x.orderMethod,
                            _notFinishBons: x._notFinish && tagData ? [tagData.code] : []
                        },
                        bon: { ...tagData, color },
                        bonData: x.oBonData
                    }
                    return (<KdsBonItem
                        {...this.props}
                        {...dataProps}
                        cardWidth={this.props.isShort ? 600 : 400}
                        onClick={() => {
                            if (parseInt(this.props.kdsData.sZoomEnable) && !this.props.isShort) {
                                this.setState({ modalOwner: dataProps })
                            }
                        }}
                        getDataApi={this.getDataApi}
                    />)
                })
                :
                (<div style={{ height: rowHeight }}>
                    {this.props.getText(15878)}
                </div>)}
            {this.state.modalOwner ? (
                <KdsBonItem
                    {...this.props}
                    {...this.state.modalOwner}
                    isModalZoom
                    cardWidth={window.innerWidth / 2.5}
                    handleChange={this.getDataApi}
                    closeModal={() => { this.setState({ modalOwner: false }) }}
                    getDataApi={this.getDataApi}
                />
            ) : ''}


        </div>)
    }
}

export default KdsBonimView;
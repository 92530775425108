import React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Select, Button } from 'antd';
import ExtendedSelector from "./ExtendedSelector";


const { Option, OptGroup } = Select;

class SelectorPrices extends React.Component/*<Props, State>*/ {
    constructor(props) {
        super(props)
    }

    render() {
        let attr = {
            styleSelector: this.props.styleSelector,
            user: this.props.user,
            onSelect: this.props.onSelect,
            value: this.props.value,
            ui: this.props.ui,
            uiActions: this.props.uiActions,
            disabled: this.props.disabled,
            filter: this.props.filter,

            title: "מחירונים",
            placeholder: "בחר מחירון",
            api: "get_prices",
            onButtonClick: () => {},
        }

        return (
            <ExtendedSelector {...attr} />
        )
    }
};


export default SelectorPrices;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import DateTimePicker from "../../components/DateTimePicker";
import { Card, Col, Row, Form, Checkbox, InputNumber } from "antd";
import TableIDs from "../../data/TableIDs";


class AccountingBranches extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            dateFrom: null,
            dateTo: null,
            branchList: '',
            withLines: 0,
            commissionPercentage: 0,
            reportType: 'pdf'
        };

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(15673);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            let reportType = this.props.ui.favoriteParams['reportName'].split('.')[1];
            this.setState({ ...this.props.ui.favoriteParams, reportType })

        }
    }

    createDatePicker = (label, field) => {
        return (<Form.Item label={this.getText(label)}>
            <DateTimePicker
                format="DD/MM/YYYY"
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                minDate={field == 'dateTo' ? this.state.dateFrom : null}
                maxDate={field == 'dateFrom' ? this.state.dateTo : null}
            />
        </Form.Item>)
    }

    render() {
        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;
        let reportName = 'reports/' + (this.state.withLines == 1 ? "AccountingBranchesDetailed" : "AccountingBranches") + "." + this.state.reportType
        // let branchList = this.state.branchList ? this.state.branchList : this.props.data[TableIDs.genericSelectorBranches].dataset.map(x => x.code).join(',');

        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={this.getText(15674)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: window.innerWidth < 1200 ? '95%' : 1100 }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 18}>
                        <Form.Item label={this.getText(15675)}>
                            <GenericSelector
                                multi
                                id={TableIDs.genericSelectorBranches}
                                api={"get_branch_list"}
                                maxTagCount={10}
                                value={this.state.branchList ? this.state.branchList.split(',') : []}
                                onChange={(e) => { this.setState({ branchList: e ? e.join(',') : '' }) }}
                                data={this.props.data}
                                dataActions={this.props.dataActions}
                                user={this.props.user}
                            />
                        </Form.Item>
                        <Row>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(15676, 'dateFrom')}</Col>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(15677, 'dateTo')}</Col>
                        </Row>
                        <Form.Item label={this.getText(15679)}>
                            <InputNumber
                                precision={2} max={100} min={0} step={1}
                                value={this.state.commissionPercentage}
                                onChange={(e) => { this.setState({ commissionPercentage: e }) }} />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox
                                checked={this.state.withLines == 1}
                                onChange={(e) => { this.setState({ withLines: e.target.checked ? 1 : 0 }) }}
                            >{this.getText(15678)}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={mobile ? 24 : 6}>
                        <ReportTypeButton
                            {...this.props}
                            params={{ ...this.state, reportName }}
                            branchesField={'branchList'}
                            xlsReportName={"reports/AccountingBranchesExcel.xls"}
                            datesToFavorites={[
                                { field: 'dateFrom', label: this.getText(15676) },
                                { field: 'dateTo', label: this.getText(15677) },
                            ]}
                            favoriteSave
                            onChange={reportType => { this.setState({ reportType }) }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>);
    }

}

export default AccountingBranches;
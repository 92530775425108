/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Input, Checkbox, InputNumber, Button, Row, Form, Col, Icon } from "antd";
import "../../App.css";
import TableIDs from "../../data/TableIDs";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable"
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons"
import ResponsiveTable from "../../components/ResponsiveTable";
import EditorContainer from "../../components/EditorContainer";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class Questionnaires extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalQuestionnaireQuestions: null,
            modalQuesIdentity: null,
        };

        this.id = "QUESTIONNAIRES_TABLE";
        this.q_id = "QUESTIONNAIRES_QUESTIONS";
        this.a_id = "QUES_IDENTITY_LIST";
        this.answer_types_id = TableIDs.genericSelectorFixLabQuestionAnswerTypes;

        this.api = {
            get: "get_questionnaires_table",
            create_edit: "create_edit_questionnaires",
            delete: "delete_questionnaires",
            copy: "copy_questionnaires",

            get_q: "get_questionnaires_questions",
            create_edit_q: "create_edit_questionnaires_questions",
            delete_q: "delete_questionnaires_questions",

            answer_types_selector: "get_answer_types_list",

            get_a: "get_ques_identity_per_q",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.q_id] === undefined) props.data[this.q_id] = { ...props.data.genericData };
        if (props.data[this.a_id] === undefined) props.data[this.a_id] = { ...props.data.genericData };

        if (props.data[this.answer_types_id] === undefined) props.data[this.answer_types_id] = { ...props.data.genericSelector };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.answer_types_id,
            this.props.user.companyCode,
            this.props.user.token,
            this.api.answer_types_selector);
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16294);
    }

    nameField = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        if (record.key === editing) {
            return (<Input value={editedRecord['qName']} onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.id, { qName: e.target.value })
            }} />)
        } else {
            return text;
        }
    }

    activeField = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        if (record.key === editing) {
            return (<Checkbox checked={editedRecord['qIsActive'] == '1'} onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.id, { qIsActive: e.target.checked ? '1' : '0' })
            }} />)
        } else {
            return (<Checkbox checked={text == '1'} disabled />)
        }
    }

    isAllowsDraftField = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        if (record.key === editing) {
            return (<Checkbox checked={editedRecord['qIsAllowsDraft'] == '1'} onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.id, { qIsAllowsDraft: e.target.checked ? '1' : '0' })
            }} />)
        } else {
            return (<Checkbox checked={text == '1'} disabled />)
        }
    }

    sendAPI = (script, data, sCall, eCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, e => { console.error(e) });
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    getEditIcons = (text, record) => {
        const { editing } = this.props.data[this.id]
        let gt = this.getText;

        if (record.key === editing) {
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get);
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }} />)
        } else {
            return (<TableLineIcons
                genericIcons={[
                    { type: 'copy', onClick: () => { this.sendAPI(this.api.copy, record.key, this.refreshData) }, tooltip: gt(17686) },
                    { type: 'table', onClick: () => { this.openQuestionnaireQuestions(record) }, tooltip: gt(16303) },
                    { type: 'eye', onClick: () => { this.setState({ modalQuesIdentity: record }) }, tooltip: gt(17617) },
                ]}
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                deleteQuetion={{ title: gt(16300), okText: gt(16301), cancelText: gt(16302) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }} />)
        }
    }

    openQuestionnaireQuestions = (record) => {
        this.setState({ modalQuestionnaireQuestions: record }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.q_id, this.props.user.companyCode, this.props.user.token, { qID: record.key, custId: null, qDraftIdentity: null }, this.api.get_q);
        })
    }

    closeQuestionnaireQuestions = () => {
        this.setState({ modalQuestionnaireQuestions: null }, () => {
            this.props.dataActions.setJson(this.q_id, { data: [], count: 0 });
        })
    }

    qqEditField = (label, field) => {
        const { editedRecord } = this.props.data[this.q_id];
        const gt = this.getText;
        const value = editedRecord[field];
        const onChange = e => { this.props.dataActions.genericDataSetEdit(this.q_id, { [field]: e }) }

        switch (field) {
            case 'qqOrder': case 'qqGroup': return (<Form.Item label={gt(label)}><InputNumber value={value} onChange={onChange} /></Form.Item>)
            case 'qqText': return editedRecord.qqAnswerType == 'V' ? "" : (<Form.Item label={gt(label)}><Input.TextArea rows={3} value={value} onChange={e => { onChange(e.target.value) }} /></Form.Item>)
            case 'qqIsRequired': case 'qqIsActive': return (<Checkbox checked={value == '1'} onChange={e => { onChange(e.target.checked ? '1' : '0') }} >{gt(label)}</Checkbox>)
            case 'qqAnswerType': return (<Form.Item label={gt(label)}>{this.qqAnswerTypeField(value)}</Form.Item>)
            case 'qqAnswerOptions': return ['A', 'R', 'Z'].indexOf(editedRecord.qqAnswerType) > -1 ? (<Form.Item label={gt(label)}>{this.qqAnswerOptionsField(value, onChange)}</Form.Item>) : ""
            case 'qqHTML': return editedRecord.qqAnswerType == 'V' ? this.getAssimilationField(value) : (<Form.Item label={gt(label)}>{this.qqHTMLField(value ? value : '', onChange, editedRecord.qqID)}</Form.Item>)
        }

    }

    qqAnswerTypeField = (value, disabled) => {
        return (<GenericSelector
            {...this.props}
            id={this.answer_types_id}
            disabled={disabled}
            value={value}
            onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.q_id, {
                    qqAnswerType: e,
                    qqHTML: value == 'V' || e == 'V' ? "" : this.props.data[this.q_id].editedRecord.qqHTML
                })
            }}
        />)
    }

    qqAnswerOptionsField = (value, onChange) => {
        let list = value ? (value + ',').split(",") : [""]
        return (<div>
            {list.map((x, i) => {
                return (<Input value={x} onChange={e => {
                    list[i] = e.target.value;
                    onChange(list.filter(f => f).join(","))
                }} />)
            })}
        </div>)
    }

    qqHTMLField = (value, onChange, key) => {
        return (<EditorContainer key={key} getText={this.getText} topMenu={false} value={value} onChange={onChange} />)
    }

    getAssimilationField = (value) => {
        const onChange = e => { this.props.dataActions.genericDataSetEdit(this.q_id, { qqHTML: e }) }
        if (value) {
            // setTimeout(() => {
            console.log({ test_qqq: this.props.data[this.q_id].editedRecord })
            // let d = document.getElementById("iframe-qq-video-link");
            // if (d) d.innerHTML = value;
            // }, 1000);
            return (<span>
                <Button onClick={() => { onChange("") }}>{this.getText(18316)}</Button>
                <div id="iframe-qq-video-link" dangerouslySetInnerHTML={{ __html: value }}></div>
            </span>)
        } else {
            return (<Form.Item label={this.getText(18317)}>
                <Input.TextArea value={value} onChange={e => { onChange(e.target.value) }} />
            </Form.Item>)
        }
    }

    getEditIconsQQ = (text, record) => {
        let gt = this.getText;
        // let qID = this.state.modalQuestionnaireQuestions ? this.state.modalQuestionnaireQuestions.qID : '';

        // if (record.key === editing) {
        //     return (<TableLineIcons
        //         onCheck={() => {
        //             this.props.dataActions.genericDataSaveEditWithParams(
        //                 this.q_id, this.props.user.companyCode, this.props.user.token, this.api.create_edit_q, this.api.get_q, { qID });
        //         }}
        //         onClose={() => {
        //             if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.q_id);
        //             else this.props.dataActions.genericDataCancelEditing(this.q_id);
        //         }} />)
        // } else {
        return (<TableLineIcons
            onEdit={() => { this.props.dataActions.genericDataStartEditing(this.q_id, record.key, record.index) }}
            deleteQuetion={{ title: gt(16300), okText: gt(16301), cancelText: gt(16302) }}
            onDelete={() => {
                this.props.dataActions.genericDataDeleteRow(
                    this.q_id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete_q, this.api.get_q);
            }} />)
        // }
    }

    saveQQEdit = () => {
        let qID = this.state.modalQuestionnaireQuestions ? this.state.modalQuestionnaireQuestions.qID : '';

        this.props.dataActions.genericDataSaveEditWithParams(
            this.q_id, this.props.user.companyCode, this.props.user.token, this.api.create_edit_q, this.api.get_q, { qID });
    }

    cancelQQEdit = () => {
        const { editing } = this.props.data[this.q_id]
        if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.q_id);
        else this.props.dataActions.genericDataCancelEditing(this.q_id);
    }

    getPrintAnswersIcon = (text, record) => {
        return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"printer"} onClick={() => {
            const { iID, iDatetime, iType, iValue } = record
            const params = {
                _id: iID,
                _datetime: encodeURIComponent(iDatetime),
                _type: iType,
                _value: iValue,
                _withNType: 1,
                reportName: "reports/QuestionnaireAnswers.pdf",
                REPORT_LOCALE: this.getText(101)
            }

            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, params]
            });

        }} />)
    }

    getEntityTypeText = (type) => {
        switch (type) {
            case '1': return this.getText(17625)
            case '2': return this.getText(17626)
            case '3': return this.getText(17627)
            case '4': return this.getText(17628)
            case '5': return this.getText(17629)
            case '6': return this.getText(19117)
        }
    }

    render() {
        let gt = this.getText;

        const render = t => t;
        const renderCB = t => (<Checkbox disabled checked={t == '1'} />)

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 1000,
            margin: 'auto'
        };

        let modalQ = this.state.modalQuestionnaireQuestions ? this.state.modalQuestionnaireQuestions : {};
        let modalA = this.state.modalQuesIdentity ? this.state.modalQuesIdentity : {};

        let qqEdit = this.props.data[this.q_id].editedRecord;

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16295)}
                columns={[
                    { title: gt(16296), key: 'qID', dataIndex: 'qID', width: '15%', sorter: true, render: t => t },
                    { title: gt(16297), key: 'qName', dataIndex: 'qName', width: '50%', sorter: true, render: this.nameField },
                    { title: gt(16298), key: 'qIsActive', dataIndex: 'qIsActive', width: '10%', sorter: true, render: this.activeField },
                    { title: gt(17684), key: 'qIsAllowsDraft', dataIndex: 'qIsAllowsDraft', width: '10%', sorter: true, render: this.isAllowsDraftField },
                    { title: gt(16299), width: '15%', render: this.getEditIcons },
                ]}
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
            />
            <GenericModal
                visible={modalQ.qID}
                onCancel={this.closeQuestionnaireQuestions}
                title={gt(16304) + ' - ' + (modalQ.qName ? modalQ.qName : '')}
                actionButtons={[{
                    buttonType: 'add',
                    buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.q_id) }
                }, {
                    buttonType: 'refresh',
                    buttonFunction: () => {
                        this.props.dataActions.genericDataGetRequested(
                            this.q_id, this.props.user.companyCode, this.props.user.token, this.api.get_q);
                    }
                }]}>
                <ResponsiveTable
                    idTable={this.props.data[this.q_id]}
                    tableOnly
                    columns={[
                        { title: gt(17843), key: 'qqID', dataIndex: 'qqID', width: '6%', render },
                        { title: gt(16305), key: 'qqOrder', dataIndex: 'qqOrder', width: '8%', render },
                        { title: gt(17604), key: 'qqGroup', dataIndex: 'qqGroup', width: '8%', render },
                        { title: gt(16306), key: 'qqText', dataIndex: 'qqText', width: '35%', render },
                        { title: gt(16307), key: 'qqAnswerType', dataIndex: 'qqAnswerType', width: '22%', render: t => this.qqAnswerTypeField(t, true) },
                        { title: gt(16308), key: 'qqIsRequired', dataIndex: 'qqIsRequired', width: '7%', render: renderCB },
                        { title: gt(16309), key: 'qqIsActive', dataIndex: 'qqIsActive', width: '7%', render: renderCB },
                        { title: gt(16310), width: '7%', render: this.getEditIconsQQ },
                    ]}
                    percentageToMobile={[30, 70]}
                    pagination={{ pageSize: parseInt(window.innerHeight / 50) }}
                />
                <GenericModal
                    visible={this.props.data[this.q_id].editing > -1}
                    onCancel={this.cancelQQEdit}
                    title={gt(17608)}
                    footer={[
                        <Button onClick={this.cancelQQEdit}>{gt(17609)}</Button>,
                        <Button type="primary" onClick={this.saveQQEdit}>{gt(17610)}</Button>
                    ]}>
                    <Row>
                        <Col span={window.innerWidth > 600 ? 12 : 24}>
                            {this.qqEditField(16305, 'qqOrder')}
                            {this.qqEditField(17604, 'qqGroup')}
                            {this.qqEditField(16307, 'qqAnswerType')}
                            {this.qqEditField(17611, 'qqAnswerOptions')}
                            {this.qqEditField(16308, 'qqIsRequired')}
                            {this.qqEditField(16309, 'qqIsActive')}
                        </Col>
                        <Col span={window.innerWidth > 600 ? 12 : 24}>
                            {this.qqEditField(16306, 'qqText')}
                            {this.qqEditField(17613, 'qqHTML')}
                        </Col>
                    </Row>
                </GenericModal>

            </GenericModal>
            <GenericModal
                visible={modalA.qID}
                onCancel={() => { this.setState({ modalQuesIdentity: null }) }}
                title={gt(17619) + ' - ' + (modalA.qName ? modalA.qName : '')}
                actionButtons={[{
                    buttonType: 'refresh',
                    buttonFunction: () => {
                        this.props.dataActions.genericDataGetRequested(
                            this.a_id, this.props.user.companyCode, this.props.user.token, this.api.get_a);
                    }
                }]}>
                {modalA.qID ?
                    (<GenericTable
                        {...this.props}
                        tableOnly
                        id={this.a_id}
                        getApi={this.api.get_a}
                        filters={{ _id: modalA.qID }}
                        columns={[
                            { title: gt(17620), key: 'iID', dataIndex: 'iID', width: '15%', render },
                            { title: gt(17621), key: 'iDatetime', dataIndex: 'iDatetime', width: '20%', render },
                            { title: gt(17622), key: 'iType', dataIndex: 'iType', width: '30%', render: this.getEntityTypeText },
                            { title: gt(17623), key: 'iValue', dataIndex: 'iValue', width: '30%', render },
                            { title: gt(17624), width: '5%', render: this.getPrintAnswersIcon },
                        ]}
                    />) : ""}
            </GenericModal>
        </div>)
    }

}

export default Questionnaires;
/* eslint-disable */
import React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Select, Button } from 'antd';


const { Option, OptGroup } = Select;

class ExtendedSelector extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            pick: this.props.value,
            optionList: (this.props.dataset) ? this.props.dataset : [],
            //disabled: (this.props.disabled === undefined) ? false : this.props.disabled,
        }
        if (this.props.api) {
            this.api_get();
        }
    }

    render() {
        let filterList = (this.props.filter) ? this.props.filter : (item) => true;

        let list = this.state.optionList.filter(filterList).map((obj, index) => {
            var text = obj['name'];
            return (<Option key={obj['code']} value={obj['code']}>{text}</Option>)
        });

        let styleShowHideButton = (this.props.showButton) ? { display: 'inline' } : { display: 'none' };
        let styleShowHideTitle = (this.props.showTitle) ? { display: 'inline' } : { display: 'none' };

        // מחירונים
        return (
            <div>
                <label style={{ paddingLeft: '4px', ...styleShowHideTitle }}>
                    {this.props.title}
                </label>
                <Select
                    //disabled={this.state.disabled}
                    disabled={(this.props.disabled === undefined) ? false : this.props.disabled}
                    showsearch
                    value={this.state.pick} //############## TODO:flux this
                    //value={this.props.value}
                    defaultValue=""
                    //style={{ width: 200, paddingLeft: '4px' }}
                    style={this.props.styleSelector}
                    placeholder={this.props.placeholder} /*"בחר מחירון"*/
                    optionFilterProp="children"
                    onChange={this.handleSelectChange} //############## TODO:flux this
                    filterOption={(input, option) => {
                        return option.props.children ?
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    }>
                    {list}
                    <Option key="key_" value="">-</Option>
                </Select>
                <Button key="showModal" type="primary"
                    //disabled={this.state.disabled}
                    style={styleShowHideButton}
                    disabled={(this.props.disabled === undefined) ? false : this.props.disabled}
                    onClick={this.props.onButtonClick /*this.showSalesAlertModal*/}>הרחב</Button>
            </div>
        )

    }

    //showSalesAlertModal = () => {
    //   this.props.uiActions.showModal(this.modalID.edit);
    //}

    handleSelectChange = (value) => {
        this.setState({ pick: value });
        this.props.onSelect(value); /*callback*/
        //this.saleAlert = value;
        //console.log(`selected ${value}`);
    }

    api_get = () => {
        console.log("API_GET\n");
        var dataSend = "";
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, this.props.api /*"get_prices"*/, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, (e) => { (e) => (e) }); // callback problem here  
    }

    successCallback = (ob) => {
        console.log("get success selector" + ob.data);
        let s = this.state;
        let rowsRaw = ob.data;
        let rows = rowsRaw.split("\r");

        for (var rowRaw of rows) {
            let row = rowRaw.split("\f");
            s.optionList.push({
                code: row[0],
                name: row[1],
            });
        }
        this.setState(s);
    }

    /*
UNSAFE_componentWillReceiveProps(newProps) {
    let flag = false;
    let s = { ...this.state };

    //console.log("selector new props ", this.props, "\nval: ", this.props.value);
    if (this.props.value) {
        //console.log("val: ", this.props.value);
        if (this.props.value !== newProps.value) {
            //console.log("pick", s.pick);
            s.pick = newProps.value;
            console.log("pick", s.pick);
            flag = true;
        }
    }
    if (flag) this.setState(s);
}*/

}
export default ExtendedSelector;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Form, Button, InputNumber, Select, message, Row, Col } from "antd";
import PageToolbar from "../../components/PageToolbar";
import type { Field } from "../../components/ModalForm";
import ItemPicker from "../../components/ItemPicker";
import SelectorItems from "../../components/SelectorItems";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import EditorContainer from "../../components/EditorContainer";


const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {};

class CustomerClub extends Component<Props, State> {
    DATA_SET_NAME: string = "CustomerClub";

    constructor(props: Props) {
        super(props);

        this.details_id = "COMPANY_DETAILS_FOR_VIRTUAL_SHOP";

        if (props.data[this.DATA_SET_NAME] === undefined) {
            props.data[this.DATA_SET_NAME] = Object.assign({}, props.data.genericData);
        }

        if (props.data[this.details_id] === undefined) {
            props.data[this.details_id] = Object.assign({}, props.data.genericData);
        }

        window.console.log(this.DATA_SET_NAME, props.data[this.DATA_SET_NAME]);

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        this.mobile = window.innerWidth <= 600;

        this.api = {
            get: "get_customer_club_settings",
            save: "set_customer_club_settings",

            get_details: "get_comp_details_for_virtual_shop",
            save_details: "set_company_detail_term_of_use",

        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    refreshData() {
        this.props.dataActions.genericDataGetEditRequested(this.DATA_SET_NAME, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericDataGetEditRequested(this.details_id, this.props.user.companyCode, this.props.user.token, this.api.get_details);
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getCustomerTags(this.props.user.companyCode, this.props.user.token);
    }

    componentDidMount() {
        // document.title = "Cash On Tab - ניהול מועדון לקוחות";
        this.refreshData();
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10153);
    }

    createFormItem(field: Field) {
        return (<FormItem
            key={field.key} style={{ marginTop: 25 }} label={field.label} help={field.help}
            labelCol={{ span: this.mobile ? 24 : 8 }} wrapperCol={{ span: this.mobile ? 24 : 12 }}>
            {field.node}
        </FormItem>);
    }

    createItemPicker = (codeField, nameField) => {

        let source = this.props.data[this.DATA_SET_NAME].editedRecord;

        let onChangeItemCode = (code, name) => {
            if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
                this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
            } else {
                this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { [codeField]: code, [nameField]: name });
            }
        }

        let checkIfItemExist = (s) => {
            if (s) {
                let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab",
                    (ob) => {
                        let itemsRows = (ob.data).split("\r");
                        let _ItemData = itemsRows[0].split('\f');
                        let _ItemCode = _ItemData[1];
                        let _ItemName = _ItemData[2];

                        if (!_ItemCode || itemsRows.length > 1) {
                            this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data, idSelector: codeField });
                        } else {
                            onChangeItemCode(_ItemCode, _ItemName)
                        }
                    },
                    (error) => {
                        message.error(this.getText(10773));
                    }
                );
            } else {
                onChangeItemCode("", "")
            }
        }

        let keyboardListener = (e) => {
            let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
            switch (e.key) {
                case "b": case "B": case "נ": case "M": case "m": case "צ":
                    if (e.ctrlKey) {
                        this.ctrlBKeyFlag = true;
                        this.barcodeReaderBuffer = '';
                    } else {
                        bufferUpdate(e.key)
                    }
                    break;

                case "Enter":
                    if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                    checkIfItemExist(this.barcodeReaderBuffer)
                    break;

                default:
                    bufferUpdate(e.key)
                    break;
            }
        }

        return (<span>
            <SelectorItems
                idSelector={codeField}
                user={this.props.user}
                uiActions={this.props.uiActions}
                ui={this.props.ui}
                dataActions={this.props.dataActions}
                data={this.props.data}
                ActionQueue={this.props.ActionQueue}
                ctrlBKeyFlag={null}
                isExpandMatrixFatherItem={false}
                itemType={1}
                value={source[codeField]}
                onChangeSearch={(s) => { onChangeItemCode(s.mCode, s.mName) }}
                onChange={(s) => { this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { [codeField]: s.target.value }) }}
                onBlur={(s) => { checkIfItemExist(s.target.value) }}
                onKeyDown={keyboardListener}
            />
            <h4>{source[codeField] !== "" ? source[nameField] : this.getText(10774)}</h4>
        </span>)
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

        };

        const toolbarActions: Array<Object> = [

            {
                buttonType: "refresh",
                buttonFunction: this.refreshData.bind(this)
            }
        ];

        const customerTags = this.props.data.customerTags.map(
            (node: Object) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object) => {
                            return (<Option key={item.id} valuefilter={item.text}>{item.text}</Option>);
                        })}
                    </OptGroup>
                )
            }
        );

        /*
        <h1>שימוש בנקודות</h1>
         */

        const formFields: Array<Field> = [
            {
                key: "clubNewItem",
                label: this.getText(10775),
                help: this.getText(10782),
                // node: (<ItemPicker defaultItemCode={this.props.data[this.DATA_SET_NAME].editedRecord.clubNewItem}
                //     onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubNewItem: value.itemCode, clubNewItemName: value.itemName }) }}
                //     label={this.props.data[this.DATA_SET_NAME].editedRecord.clubNewItemName}
                //     data={this.props.data.itemCodes}
                //     showDialog={this.props.uiActions.uiShowItemPickerDialog}
                //     hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                // />),
                node: this.createItemPicker("clubNewItem", "clubNewItemName")
            },
            {
                key: "clubRenewItem",
                label: this.getText(10776),
                help: this.getText(10783),
                // node: (<ItemPicker defaultItemCode={this.props.data[this.DATA_SET_NAME].editedRecord.clubRenewItem}
                //     onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubRenewItem: value.itemCode, clubRenewItemName: value.itemName }) }}
                //     label={this.props.data[this.DATA_SET_NAME].editedRecord.clubRenewItemName}
                //     data={this.props.data.itemCodes}
                //     showDialog={this.props.uiActions.uiShowItemPickerDialog}
                //     hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                // />),
                node: this.createItemPicker("clubRenewItem", "clubRenewItemName")
            },
            {
                key: "clubValidDays",
                label: this.getText(10777),
                help: this.getText(10784),
                node: (<InputNumber min={0} max={9999} value={this.props.data[this.DATA_SET_NAME].editedRecord.clubValidDays} onChange={(value: number) => {
                    this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubValidDays: value })
                }} />),
            },
            {
                key: "clubMinPointsAutoRenewal",
                label: this.getText(10778),
                help: this.getText(10785),
                node: (<InputNumber min={0} value={this.props.data[this.DATA_SET_NAME].editedRecord.clubMinPointsAutoRenewal} onChange={(value: number) => {
                    this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubMinPointsAutoRenewal: value })
                }} />),
            },
            {
                key: "clubDaysForMinAccumulatedSum",
                label: this.getText(16165),
                help: this.getText(16166),
                node: (<InputNumber min={0} value={this.props.data[this.DATA_SET_NAME].editedRecord.clubDaysForMinAccumulatedSum} onChange={(value: number) => {
                    this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubDaysForMinAccumulatedSum: value })
                }} />),
            },
            {
                key: "clubFixedDiscount",
                label: this.getText(10779),
                help: this.getText(10786),
                node: (<InputNumber min={0} max={100} value={this.props.data[this.DATA_SET_NAME].editedRecord.clubFixedDiscount} onChange={(value: number) => {
                    this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubFixedDiscount: value })
                }} />),
            },
            {
                key: "clubDiscountItem",
                label: this.getText(10780),
                help: this.getText(10787),
                // node: (<ItemPicker defaultItemCode={this.props.data[this.DATA_SET_NAME].editedRecord.clubDiscountItem}
                //     onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubDiscountItem: value.itemCode, clubDiscountItemName: value.itemName }) }}
                //     label={this.props.data[this.DATA_SET_NAME].editedRecord.clubDiscountItemName}
                //     data={this.props.data.itemCodes}
                //     showDialog={this.props.uiActions.uiShowItemPickerDialog}
                //     hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                // />),
                node: this.createItemPicker("clubDiscountItem", "clubDiscountItemName")
            },
            {
                key: "clubTagId",
                label: this.getText(10781),
                help: this.getText(10788),
                node: (<GenericSelectorCustTags
                    {...this.props}
                    // <Select size={"large"}  notFoundContent={this.getText(10789)}
                    // style={{ width: '100%' }}
                    // filterOption={true}
                    // optionFilterProp="valuefilter"
                    // showsearch={true} allowClear={true}
                    value={this.props.data[this.DATA_SET_NAME].editedRecord.clubTagId}
                    onChange={(value, option) => {
                        this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubTagId: value })
                    }} placeholder={this.getText(10790)}
                // >{customerTags}</Select>
                />),
            },
            {
                key: "dTerm",
                label: this.getText(18922),
                help: "",
                node: (<EditorContainer key={"dTerm"} getText={this.getText} topMenu={false}
                    value={this.props.data[this.details_id].editedRecord["dTerm"] ?? ""}
                    onChange={e => { this.props.dataActions.genericDataSetEdit(this.details_id, { ["dTerm"]: e }) }}
                />),
            },
        ];

        return (
            <div style={divStyle}>
                <PageToolbar title={this.getText(10791)} actionButtons={toolbarActions} isModal={false} />
                {/* 
                <Form className="data-entry-form" layout="vertical" onSubmit={this.handleSaveChanges.bind(this)}>
                    {formFields.map((value) => this.createFormItem(value))}

                    <Button type="primary" htmlType="submit" loading={this.props.ui.workInProgress} className={"form-button-save"} icon={"check"}>
                        {this.getText(10792)}
                    </Button>
                </Form> */}

                <Row>
                    <Col span={this.mobile ? 24 : 8}>
                        {this.createFormItem(formFields[0])}
                        {this.createFormItem(formFields[1])}
                        {this.createFormItem(formFields[2])}
                        {this.createFormItem(formFields[3])}
                        {this.createFormItem(formFields[4])}
                    </Col>
                    <Col span={this.mobile ? 24 : 8}>
                        {this.createFormItem(formFields[5])}
                        {this.createFormItem(formFields[6])}
                        {this.createFormItem(formFields[7])}
                    </Col>
                    <Col span={this.mobile ? 24 : 8}>
                        <Form.Item label={formFields[8].label}>{formFields[8].node}</Form.Item>
                    </Col>
                </Row>

                <div style={{ textAlign: 'center', marginTop: 25 }}>
                    <Button type="primary" onClick={this.handleSaveChanges.bind(this)} loading={this.props.ui.workInProgress} className={"form-button-save"} icon={"check"}>
                        {this.getText(11446)}
                    </Button>
                </div>
            </div>
        );
    }

    handleSaveChanges(e: Event) {
        e.preventDefault();
        this.props.dataActions.genericDataJustSaveEdit("CustomerClub", this.props.user.companyCode, this.props.user.token, this.api.save);
        this.props.dataActions.genericDataJustSaveEdit(this.details_id, this.props.user.companyCode, this.props.user.token, this.api.save_details);
    }

}

export default CustomerClub;
/* @flow */
/*jshint esversion: 6 */
import { Checkbox, Form, Row, Col, Button, Radio } from "antd";
import React, { Component } from "react";
import "../../App.css";
import GenericSelector from "../../components/GenericSelector";
//import ReportTypeButton from "../../components/ReportTypeButton";
import DateTimePicker from "../../components/DateTimePicker";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import * as XLSX from 'xlsx/xlsx.mjs';
import ReportFavoriteSave from "../../components/ReportFavoriteSave";
import moment from "moment";


class ExcelTagsBranches extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _fromDate: null,
            _toDate: null,
            _branchList: '',
            _tagList: '',
            _isByDefault: '0',
            _valueType: '_qty',
            reportName: "ExcelTagsBranches.xls"
        };

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };

        if (props.data.TAG_TYPE_6 === undefined) props.data.TAG_TYPE_6 = { dataset: [] }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            'TAG_TYPE_6', this.props.user.companyCode, this.props.user.token, 'get_tags_from_type_6');
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(18365);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    generateReport = () => {
        const { _fromDate, _toDate, _branchList, _tagList, _isByDefault, _valueType } = this.getReportParams();

        let dataSend = "_fromDate\f_toDate\f_branchList\f_tagList\f_isByDefault\r" +
            _fromDate + "\f" + _toDate + "\f" + _branchList + "\f" + _tagList + "\f" + _isByDefault;

        this.sendAPI("generate_excel_tags_branches_report", dataSend, (ob) => {
            let fullData = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return {
                    _branchID: y[0],
                    _branchName: y[1],
                    _tagID: y[2],
                    _tagName: y[3],
                    _date: y[4],
                    _dayOfWeek: y[5],
                    _qty: y[6],
                    _sum: y[7]
                }
            }) : [];

            let daysList = [
                "א",
                "ב",
                "ג",
                "ד",
                "ה",
                "ו",
                "ש",
            ]

            let abcList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
            let colList = [...abcList];
            abcList.forEach(x => {
                abcList.forEach(y => {
                    colList.push(x + y)
                })
            });

            let colIndex = 0;
            let partitionedData = [];
            let dateListSet = new Set()
            let tagListSet = new Set()
            let sumAll = 0;

            fullData.forEach(x => {
                const { _branchID, _branchName, _tagID, _tagName, _date, _dayOfWeek } = x;
                const _sum = x[_valueType] ? Math.round(parseFloat(x[_valueType])) : 0;
                const dataBox = { _date, _dayOfWeek, _sum }
                let findData = partitionedData.findIndex(y => y._branchID == _branchID)
                dateListSet.add(_date + "#" + _dayOfWeek);
                tagListSet.add(_tagID + "#" + _tagName);
                if (findData === -1) {
                    colIndex += 2;
                    let _tagList = [{ _tagID, _tagName, _dateList: [dataBox], _sumCol: _sum, _col: colList[colIndex] }]
                    partitionedData.push({ _branchID, _branchName, _tagList, _sumBranch: _sum, _colStart: colList[colIndex], _colEnd: colList[colIndex + 1] })
                } else {
                    let _tagList = [...partitionedData[findData]._tagList]
                    let _sumBranch = partitionedData[findData]._sumBranch + _sum;
                    let findTag = _tagList.findIndex(z => z._tagID == _tagID)
                    if (findTag === -1) {
                        colIndex++;
                        _tagList.push({ _tagID, _tagName, _dateList: [dataBox], _sumCol: _sum, _col: colList[colIndex] })
                    } else {
                        _tagList[findTag] = { ..._tagList[findTag], _dateList: [..._tagList[findTag]._dateList, dataBox], _sumCol: _tagList[findTag]._sumCol + _sum }
                    }
                    partitionedData[findData] = { ...partitionedData[findData], _tagList, _sumBranch, _colEnd: colList[colIndex + 1] }
                }
            })

            let dateList = [...dateListSet]
                .map(x => {
                    let y = x.split("#");
                    return { date: y[0], dayOfWeek: y[1] }
                })
                .sort((a, b) => a.date.localeCompare(b.date));

            let tagList = [...tagListSet]
                .map(x => {
                    let y = x.split("#");
                    return { id: y[0], name: y[1] }
                })
                .sort((a, b) => a.id - b.id);


            let lastRow = dateList.length + 3

            let excelData = {
                A1: { t: "s", v: "תאריך" },
                B1: { t: "s", v: "יום" },
                A2: { t: "s", v: "" },
                B2: { t: "s", v: "" },
                ["A" + lastRow]: { t: "s", v: 'סה"כ' },
                ["B" + lastRow]: { t: "s", v: '' },
                ["A" + (lastRow + 3)]: { t: "s", v: 'סניף' },
                ["B" + (lastRow + 3)]: { t: "s", v: '' },
                ["A" + (lastRow + 4 + partitionedData.length)]: { t: "s", v: 'סה"כ' },
                ["B" + (lastRow + 4 + partitionedData.length)]: { t: "s", v: '' },
                [colList[tagList.length + 2] + (lastRow + 3)]: { t: "s", v: 'סה"כ' },
            }

            let stylesList = [
                { width: 11, fill: { patternType: 'solid', fgColor: { rgb: 'FFD3D3D3' } } },
                { width: 3 },
            ];

            let mergesList = [
                { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
                { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
                { s: { r: lastRow - 1, c: 0 }, e: { r: lastRow - 1, c: 1 } },
                { s: { r: lastRow + 2, c: 0 }, e: { r: lastRow + 2, c: 1 } },
                { s: { r: lastRow + 3 + partitionedData.length, c: 0 }, e: { r: lastRow + 3 + partitionedData.length, c: 1 } },
            ]

            partitionedData.forEach((x, i) => {
                x._tagList.forEach(y => {
                    excelData = {
                        ...excelData,
                        [y._col + "1"]: { t: "s", v: x._branchID + " - " + x._branchName },
                        [y._col + "2"]: { t: "s", v: y._tagName },
                    }
                    stylesList = [
                        ...stylesList,
                        { width: 2 + (y._tagName.length * 0.8) }
                    ]

                    if (y._sumCol) {
                        excelData = {
                            ...excelData,
                            [y._col + lastRow]: { t: "n", v: y._sumCol, z: "#,##0" },
                        }
                    }
                })

                excelData = {
                    ...excelData,
                    [x._colEnd + "2"]: { t: "s", v: 'סה"כ' },
                    ["A" + (lastRow + 4 + i)]: { t: "s", v: x._branchName },
                    ["B" + (lastRow + 4 + i)]: { t: "s", v: "" },
                }

                stylesList = [
                    ...stylesList,
                    { width: 8 }
                ]


                if (x._sumBranch) {
                    excelData = {
                        ...excelData,
                        [x._colEnd + lastRow]: { t: "n", v: x._sumBranch, z: "#,##0" },
                        [colList[tagList.length + 2] + (lastRow + 4 + i)]: { t: "n", v: x._sumBranch, z: "#,##0" },
                    }
                    sumAll += x._sumBranch;
                }

                mergesList = [
                    ...mergesList,
                    { s: { r: 0, c: colList.findIndex(f => f == x._colStart) }, e: { r: 0, c: colList.findIndex(f => f == x._colEnd) } },
                    { s: { r: lastRow + 3 + i, c: 0 }, e: { r: lastRow + 3 + i, c: 1 } },
                ]
            })

            if (sumAll) {
                excelData = {
                    ...excelData,
                    [colList[tagList.length + 2] + (lastRow + 4 + partitionedData.length)]: { t: "n", v: sumAll, z: "#,##0" },
                }
            }

            dateList.forEach((x, i) => {
                excelData = {
                    ...excelData,
                    ["A" + (i + 3)]: { t: "s", v: moment(x.date).format("DD/MM/YYYY") },
                    ["B" + (i + 3)]: { t: "s", v: daysList[x.dayOfWeek - 1] },
                }
                partitionedData.forEach(y => {
                    let sumBranchInline = 0;
                    y._tagList.forEach(z => {
                        let findData = z._dateList.find(f => f._date == x.date);
                        if (findData && findData._sum) {
                            excelData = { ...excelData, [z._col + (i + 3)]: { t: "n", v: findData._sum, z: "#,##0" } }
                            sumBranchInline += findData._sum;
                        }
                    })
                    if (sumBranchInline) excelData = { ...excelData, [y._colEnd + (i + 3)]: { t: "n", v: sumBranchInline, z: "#,##0" } }
                })
            })

            tagList.forEach((x, i) => {
                excelData = {
                    ...excelData,
                    [colList[i + 2] + (lastRow + 3)]: { t: "s", v: x.name },
                }
                let sumTagInline = 0;
                partitionedData.forEach((y, ii) => {
                    let findData = y._tagList.find(f => f._tagID == x.id);
                    if (findData && findData._sumCol) {
                        excelData = { ...excelData, [colList[i + 2] + (lastRow + 4 + ii)]: { t: "n", v: findData._sumCol, z: "#,##0" } }
                        sumTagInline += findData._sumCol;
                    }
                })
                if (sumTagInline) excelData = { ...excelData, [colList[i + 2] + (lastRow + 4 + partitionedData.length)]: { t: "n", v: sumTagInline, z: "#,##0" } }
            })

            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            // const ws = XLSX.utils.json_to_sheet([{ a: 1, b: 2 }, { a: 3, b: 4 }]);
            const ws = {
                ...excelData,
                ['!cols']: stylesList,
                ["!ref"]: "A1:" + colList[colIndex + 1] + (lastRow + 4 + partitionedData.length),
                ["!merges"]: mergesList
            }

            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const blob = new Blob([excelBuffer], { type: fileType });
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = 'ExcelTagsBranches.xlsx';
            a.click();
        })

    }


    sendAPI = (script, data, sCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, (e) => { console.error(e) });
    };

    getReportParams = () => {
        const { _fromDate, _toDate, _branchList, _tagList, _isByDefault, _valueType, reportName } = this.state;

        return {
            _fromDate: (_fromDate ? _fromDate : ""),
            _toDate: (_toDate ? _toDate : ""),
            _branchList: (_branchList ? _branchList : this.props.data[TableIDs.genericSelectorBranches].dataset.map(x => x.code).join(',')),
            _tagList: (_tagList ? _tagList : this.props.data.TAG_TYPE_6.dataset.map(x => x.code).join(',')),
            _isByDefault,
            _valueType,
            reportName
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 700,
            margin: 'auto'
        };

        const { _fromDate, _toDate, _branchList, _tagList, _isByDefault, _valueType } = this.state;

        return (<div style={divStyle}>
            <PageToolbar title={gt(18366)} actionButtons={[]} />
            <Form.Item label={gt(18375)}>
                <Radio.Group
                    value={_valueType}
                    onChange={e => this.setState({ _valueType: e.target.value })}
                >
                    <Radio value={"_qty"}>{gt(18376)}</Radio>
                    <Radio value={"_sum"}>{gt(18377)}</Radio>
                </Radio.Group>
            </Form.Item>

            <Row>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18367)}>
                        <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_fromDate}
                            onChange={e => { this.setState({ _fromDate: e }) }}
                            maxDate={_toDate}
                        />
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18368)}>
                        <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_toDate}
                            onChange={e => { this.setState({ _toDate: e }) }}
                            minDate={_fromDate}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label={gt(18369)}>
                <GenericSelector
                    {...this.props}
                    id={TableIDs.genericSelectorBranches}
                    api={"get_branch_list"}
                    multi
                    value={_branchList ? _branchList.split(",") : []}
                    onChange={e => this.setState({ _branchList: e ? e.join(",") : "" })}
                />
            </Form.Item>
            <Form.Item label={gt(18370)}>
                <GenericSelectorItemTags
                    {...this.props}
                    multi
                    value={_tagList ? _tagList.split(",") : []}
                    onChange={e => this.setState({ _tagList: e ? e.join(",") : "" })}
                />
            </Form.Item>
            <Form.Item>
                <Checkbox checked={_isByDefault == '1'} onChange={e => this.setState({ _isByDefault: e.target.checked ? '1' : '0' })}>
                    {gt(18371)}
                </Checkbox>
            </Form.Item>

            <Form.Item key={"submit"}>
                <Button type={"primary"} loading={this.props.ui.workInProgress} onClick={this.generateReport}>{gt(18372)}</Button>
                <ReportFavoriteSave
                    {...this.props}
                    params={this.getReportParams()}
                    datesToFavorites={[
                        { field: '_fromDate', label: gt(18367) },
                        { field: '_toDate', label: gt(18368) },
                    ]}
                />
            </Form.Item>
        </div>)
    }

}

export default ExcelTagsBranches;
/* @flow */
/*jshint esversion: 6 */

import React from "react";
import PageToolbar from "../../components/PageToolbar";
import PictureView from "../../components/PictureView";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import { Select, message, Card } from "antd";

const { Option, OptGroup } = Select;

class ExternalOrderSettings extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {}

        this.id = "EXTERNAL_ORDER_SETTINGS";

        this.api = {
            //     get: "get_image_files_per_type",
            //     delete: "delete_image_files_per_type",
            //     update: "update_logo_picture",
            get: "get_image_by_external_order_supplier",
            delete: "update_external_order_image",
            update: "update_external_order_image",
            selector_suppliers: "external_orders_supplier_list",

        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
        if (props.data[TableIDs.genericSelectorExternalOrderSuppliers] === undefined) props.data[TableIDs.genericSelectorExternalOrderSuppliers] = { ...props.data.genericSelector };

        //this.props.dataActions.genericSelectorRefreshDataset( TableIDs.genericSelectorExternalOrderSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_suppliers);

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadSelectors();
        this.props.dataActions.genericDataGetEditWithParams(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            { mSupplierID: "1" },
            this.api.get
        );
        /*
    this.props.dataActions.genericDataSetFilter(
        this.id, this.props.user.companyCode,
        this.props.user.token,
        { _types: 'L', mSupplierID: "1" },
        this.api.get);
        */
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10209); // TODO: change id
    }

    loadSelectors() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorExternalOrderSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_suppliers]
        });
    }

    generateSelector = (selector, field) => {
        let list = this.props.data[selector].dataset.map((obj) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });
        return (<Select style={{ width: '100%' }}
            onChange={(e) => {
                this.props.dataActions.genericDataSetEdit(this.id, { [field]: e });
                /*
                this.props.dataActions.genericDataSetFilter(
                    this.id, this.props.user.companyCode,
                    this.props.user.token,
                    { _types: 'L', mSupplierID: e },
                    this.api.get);
                */
                //this.refreshData(); 
                this.props.dataActions.genericDataGetEditWithParams(
                    this.id,
                    this.props.user.companyCode,
                    this.props.user.token,
                    { mSupplierID: e },
                    this.api.get
                );
            }}
            value={this.props.data[this.id].editedRecord[field]}
        >{list}</Select>)
    }


    refreshData = () => {
        //this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

        this.props.dataActions.genericDataGetEditWithParams(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            { mSupplierID: this.props.data[this.id].editedRecord['mSupplierID'] },
            //{ _id: (id_data) ? id_data : this.props.ui.data },
            this.api.get
        );
    }

    imagesData = () => {
        let data = this.props.data[this.id];
        if (data && data.data[0]) return data.data[0].split("\f")[0]
    }

    titleBar = (title) => {

        let actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

        return (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={title}
                    actionButtons={actionButtons}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);
    }

    updateImageUrl = (url) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.api.update, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(url);
        job.send("/cgi-bin/CashOnTab",
            () => { message.success("url updated successfully"/*this.getText(11763)*/) }, // TODO: change id
            (err) => { message.success("failed to update url"/*this.getText(11763)*/) });
    }

    pictureView = (url, type) => {
        return (<div style={{ display: 'inline-block' }}>

            <PictureView
                user={this.props.user}
                data={this.props.data}
                dataActions={this.props.dataActions}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                ActionQueue={this.props.ActionQueue}
                // url={url}
                imageType={type}
                uploadScriptName={"upload_shared_image"}
                isShared={true}
                //aspect={30 / 19}
                url={this.props.data[this.id].editedRecord['mLogo']}
                onChange={(url) => this.props.dataActions.genericDataSetEdit(this.id, { ['mLogo']: url })}
                onRemove={() => this.props.dataActions.genericDataSetEdit(this.id, { ['mLogo']: '' })}
                onUploadedSuccess={(response, url) => {
                    console.log(response, url);
                    let user = new User(this.props.user.token, this.props.user.companyCode);
                    let job = new Job(user, this.api.update, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                    let input = ['mSupplierID\fmLogo\r',
                        this.props.data[this.id].editedRecord['mSupplierID'], '\f',
                        this.props.data[this.id].editedRecord['mLogo']]
                        .join('');
                    job.setInput(input);
                    job.send("/cgi-bin/CashOnTab",
                        () => { message.success(this.getText(11763)) }, // TODO: change id
                        (e) => e);
                }}
                onUploadedFailed={(error) => {
                    //  //console.log('yuda upload failed',error)
                    //this.props.dataActions.dataTableSetRow(this.id, 0, { iUrl: 'x', iType: 'L' }, true);
                }} />
        </div>)
    }

    render() {
        let iData = this.imagesData();
        //let mobile = window.innerWidth > 600 ? false : true

        return (
            <div style={{ width: window.innerWidth > 600 ? 500 : '95%', margin: 'auto', }}>
                {/* <div style={divStyle}> */}
                <Card title={this.titleBar(this.getText(13481))}
                    bordered={true} //style={{ width:mobile ? '95%': '60%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 0, height: 56 }}>
                    {/* {this.titleBar(this.getText(13481))} */}
                    <div>{this.generateSelector(TableIDs.genericSelectorExternalOrderSuppliers, 'mSupplierID')}</div>
                    <h2 style={{ margin: 10 }}>{this.getText(11765)}</h2>
                    <div style={{ paddingRight: 16, paddingLeft: 16 }}>{this.pictureView(iData, 'R')}</div>
                </Card>
            </div>
        )
    }

}

export default ExternalOrderSettings;


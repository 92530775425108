/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Tooltip, Button, Upload, message, Checkbox, Form, Input } from "antd";
import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import ModalCreateEditDirectDebit from "../../components/ModalCreateEditDirectDebit";
import ModalCreateCreditCard from "../../components/ModalCreateCreditCard";
import ModalDownloadCustomersWithCreditCard from "../../components/ModalDownloadCustomersWithCreditCard";
import ModalStandingOrdersByCustomer from "../../components/ModalStandingOrdersByCustomer";
import LangContext from "../../contextProvider/LangContext";
import CustomerFilter from './CustomerFilter';
import GenericModal from "../../components/GenericModal";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class DirectDebit extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "CUSTOMER_DIRECT_DEBIT_TABLE";
        this.id_banks = "CUST_BANKS";
        this.id_chargeTypes = "CHARGE_TYPES";

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            modalFilter: false,

            filterActive: false,
            valueSearch: '',

            uploadMultiFromExcel: false,
            fileList: [],
            custList: [],
            curCustIndex: -1,
            lastCustIndex: -1,
            errorCustRows: 0,
            modalBankAccount: null,
            modalAddBankAccount: null,

        };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }
        if (props.data[this.id_banks] === undefined) {
            props.data[this.id_banks] = { ...props.data.genericData };
        }
        if (props.data[this.id_chargeTypes] === undefined) {
            props.data[this.id_chargeTypes] = { ...props.data.genericSelector };
        }


        //if (props.data[TableIDs.genericSelectorPrices] === undefined) {
        //    props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        //}

        this.api = {
            get: "get_customers",
            create: "",
            edit: "",
            delete: "delete_customer",
            selector: "",
            modalPriceListGet: "",
            cardcom_check: "cardcom_tokenize_and_save_cust",
            cardcom_save: "cardcom_tokenize",
            get_banks: "cust_banks_get_table",
            selector_charge_types: "charge_types_list",
            create_bank: "create_edit_cust_bank"
        };


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - הוראות קבע";
        //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            this.id_chargeTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_charge_types);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        //this.refreshData();

        /*
        // Verify existence of module 421 - הוראות קבע
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [this.props.user.companyCode, this.props.user.token, {
                script: "verify_module",
                params: { moduleId: 421 }
            }, "", { loading: "", failed: "", success: "" }, false, {
                action: null,
                args: []
            }, function (action, state, data) {
                window.console.log(data);
                window.console.log(typeof data);
                window.console.log(data === "1");
                let value = data === "1";
                return Jax.mutate(state, "modules", { m421: value });
            }]
        });
        */

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10154);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 5;
        // fall through
        switch (sorter.field) {
            case "key": col--; // fallsthrough  // 0
            case "mId": col--; // fallsthrough 
            case "mFirstName": col--; // fallsthrough 
            case "mLastName": col--; // fallsthrough 
            case "mContactPersonName": col--; // fallsthrough 
            case "mContactPersonMobile": break; // 6
            default:
                col = 0;
        }

        console.log("pagination: ", pagination,
            "\nsorter: ", sorter,
            "\ncol: ", col);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //sortColumn
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: this.props.data[this.id].filters._words || '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0,
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            customerTags: '',
            birthDayLower: '',
            birthDayUpper: '',
            clubJoinLower: '',
            clubJoinUpper: '',
            lastClubJoinLower: '',
            lastClubJoinUpper: '',
            updateDateLower: '',
            updateDateUpper: '',
            creationPos: '',
            salesView: 0,
            custCredit: 0,
            custMailingIsActive: 0,
            notActiveView: 0,
            custEmployeeId: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);


        this.setState({ filterActive: false, valueSearch: "" })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    deletedText = (text, record) => record['mIsActive'] == '1' ? text : <div style={{ color: 'red', textDecoration: 'line-through' }}>{text}</div>

    uploadExcelButton = () => {

        const { fileList } = this.state;

        let GetTableFromExcel = (data) => {
            let workbook = XLSX.read(data, { type: 'binary' });
            let Sheet = workbook.SheetNames[0];
            let fullData = workbook.Sheets[Sheet];
            let ref = fullData['!ref'].split(':');
            let refStart = parseInt(ref[0].replace(/[A-Z]+/, ''));
            let refEnd = parseInt(ref[1].replace(/[A-Z]+/, ''));
            let dataSend = [];
            let fd = (key) => {
                let res = fullData[key] && fullData[key].w ? fullData[key].w : '';
                return res.replace(/[\x00-\x1F\x80-\xFF\']/, '').trim()
            }

            if (refStart == 1) refStart = 2;


            for (let n = refStart; n <= refEnd; n++) {
                dataSend.push({ A: fd('A' + n), B: fd('B' + n), C: fd('C' + n), D: fd('D' + n), E: fd('E' + n), F: fd('F' + n) });
            }

            this.setState({
                custList: dataSend,
                curCustIndex: 0,
                lastCustIndex: dataSend.length - 1,
            });
        };

        let beforeUpload = (fileUpload) => {
            this.setState({ fileList: [fileUpload] });
            let reader = new FileReader();

            //For Browsers other than IE.
            if (reader.readAsBinaryString) {
                reader.onload = function (e) {
                    GetTableFromExcel(e.target.result);
                };
                reader.readAsBinaryString(fileUpload);
            } else {
                //For IE Browser.
                reader.onload = function (e) {
                    let data = "";
                    let bytes = new Uint8Array(e.target.result);
                    for (let i = 0; i < bytes.byteLength; i++) {
                        data += String.fromCharCode(bytes[i]);
                    }
                    GetTableFromExcel(data);
                };
                reader.readAsArrayBuffer(fileUpload);
            }
            return false;
        }

        return (<Upload fileList={fileList} beforeUpload={beforeUpload}>
            <Button key={"upButton"}><Icon type="upload" style={{ marginLeft: 5 }} />{this.getText(17484)}</Button>
        </Upload>)
    }

    saveExcelButton = () => {
        if (this.state.curCustIndex <= 0) {
            return (<div>
                <Button key={"saveButton"} onClick={this.saveEndCheckCust}>
                    <Icon type="save" style={{ marginLeft: 5 }} />
                    {this.getText(17493)}
                </Button>
            </div>)
        }
    }

    saveEndCheckCust = () => {
        const { custList } = this.state;

        let dataSend = "customerId\fcustomerName\flast4\fexpiry\fnationalId";
        custList.forEach(x => {
            dataSend += "\r" + x.A + "\f" + x.B + "\f" + (x.D ? x.D.slice(-4) : "") + "\f" + x.E + "\f" + x.C
        });

        this.sendAPI(this.api.cardcom_check, dataSend, (ob) => {
            let resList = ob.data ? ob.data.split("\r") : [];
            let newCustList = [...custList];
            console.log("test_check", resList, newCustList)
            resList.forEach((x, i) => {
                let y = x.split("\f");
                newCustList[i] = { ...newCustList[i], issetCust: y[0] == '1', issetCard: y[1] == '1' }
            });
            console.log("test_check_2", resList, newCustList)
            this.setState({ custList: newCustList }, this.create_card)
        })
    }

    create_card = () => {
        const { custList, curCustIndex, lastCustIndex } = this.state;
        if (curCustIndex <= lastCustIndex) {
            let rowData = custList[curCustIndex];
            let newCustList = [...custList];
            let existingCust = '';
            if (rowData.issetCust) existingCust = " ,Existing cust";

            if (!rowData.issetCard) {
                let dataSend = "customerId\fcustomerName\fcreditCard\fexpiry\fcvv\fnationalId\fisActive\fremarks\fsource\fposId\r"
                    + rowData.A + "\f" + rowData.B + "\f" + rowData.D + "\f" + rowData.E + "\f" + rowData.F + "\f" + rowData.C + "\f1\f\f3\f999"

                this.sendAPI(this.api.cardcom_save, dataSend, ob => {
                    let response = (ob.data) ? ob.data.split('\f') : [2, "error - no response"];
                    newCustList[curCustIndex] = { ...newCustList[curCustIndex], status: response[0], message: response[1] + existingCust }
                    this.setState({ custList: newCustList, curCustIndex: curCustIndex + 1 }, this.create_card)
                })
            } else {
                newCustList[curCustIndex] = { ...newCustList[curCustIndex], status: '0', message: 'Existing card' + existingCust }
                setTimeout(() => {
                    this.setState({ custList: newCustList, curCustIndex: curCustIndex + 1 }, this.create_card)
                }, 100);
            }
        } else {
            let errorRows = custList.filter(f => f.status != '0');
            if (errorRows.length) {
                this.setState({ errorCustRows: errorRows.length, custList: errorRows })
            } else {
                message.success(this.getText(17497));
                this.closeModalUploadExcel();
            }
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    closeModalUploadExcel = () => {
        this.setState({
            uploadMultiFromExcel: false,
            fileList: [],
            custList: [],
            curCustIndex: -1,
            lastCustIndex: -1,
            errorCustRows: 0,
        })
    }

    getChargeType = (text, record) => {
        const chargeTypeData = this.props.data[this.id_chargeTypes].dataset.find((f) => f.code == text)
        return chargeTypeData ? chargeTypeData.name : ''


    }

    getAddBankAccountField = (label, field, size) => {
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const { modalAddBankAccount } = this.state
        const value = modalAddBankAccount ? modalAddBankAccount[field] : ''

        const onChange = (e) => {
            this.setState({
                modalAddBankAccount: { ...modalAddBankAccount, [field]: e }
            })
        }

        return (<Form.Item
            label={this.getText(label)}
            {...layout}
            required={field != "mBankIsActive"}
            validateStatus={value ? "success" : "error"}
        >
            {field == 'mChargeType' ?
                <GenericSelector
                    {...this.props}
                    id={this.id_chargeTypes}
                    value={value}
                    onChange={onChange}
                />
                : field == 'mBankIsActive' ?
                    <Checkbox
                        checked={value == 1}
                        onChange={e => {
                            onChange(e.target.checked ? 1 : 0)
                        }}
                    />
                    :
                    <Input
                        value={value}
                        onChange={e => {
                            if (e.target.value.length > size) {
                                message.error(this.getText(18974) + size + ' ' + this.getText(18975))
                            } else {
                                onChange(e.target.value)
                            }
                        }}
                    />
            }
        </Form.Item>)

    }

    onSaveNewBank = () => {
        const { modalAddBankAccount } = this.state
        const {data} =this.props.data[this.id_banks]
        const {
            mBankNum,
            mBranchNum,
            mAccountNum,
            mBankOwnerName,
            mChargeType,
            mBankIsActive,
            mID
        } = modalAddBankAccount;

        if (!mBankNum ||
            !mBranchNum ||
            !mAccountNum ||
            !mBankOwnerName ||
            !mChargeType) {

            message.error(this.getText(18976))

        } else if (mBankIsActive==1 && data.find(f=>{
            let y =f.split("\f")
            return y[5]==mChargeType && y[6]==1 && y[0] !=mID

        })) {

            message.error(this.getText(18978))

        } else {
            console.log(" modalAddBankAccount",modalAddBankAccount)

            this.props.dataActions.genericDataSaveEditWithParams(
                this.id_banks,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.create_bank,
                this.api.get_banks,
                modalAddBankAccount)
            this.setState({ modalAddBankAccount: null })
        }

    }

    onEditBankLine = (text,record)=>{
        return(
        <TableLineIcons onEdit={()=>{
            this.setState({
                modalAddBankAccount: {
                    'mCustId': this.state.modalBankAccount,
                    ...record

                }
            })


        }} />
        )
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mFirstName: y[1],
                mLastName: y[2],
                mContactPersonName: y[3],
                mContactPersonMobile: y[4],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        //let list = this.props.data[TableIDs.genericSelectorPrices].dataset.map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        //});


        let genericActionButtons = [
            (<Tooltip /*title="הורדת קובץ לקוחות בעלי כרטיס אשראי"*/ title={<div>{this.getText(10802)}<br />{this.getText(10803)}</div>}>
                <Button key="download_file" className={"btn"} /*disabled={(this.props.data[this.id_directdebit].editing > -1)}*/
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    //onClick={this.getFile}
                    onClick={() => this.props.uiActions.showModal(TableIDs.modalDownloadCustomersWithCreditCard)}
                >
                    <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="download" theme="outlined" />
                </Button>
            </Tooltip>),
            //(<Button key="create_direct_debit" /*disabled={!this.state.isValidItemCode}*/onClick={this.handleDirectDebitCreate}>צור חדש</Button>),
        ]

        if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset && this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '505')) {
            genericActionButtons.push(<Tooltip title={this.getText(17482)}>
                <Button key="upload_file" className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                    this.setState({ uploadMultiFromExcel: true })
                }}><Icon style={{ fontSize: 16 }} type="upload" theme="outlined" /></Button>
            </Tooltip>)
        }

        const sorter = true;
        const render = this.deletedText;

        let columns = [
            { title: this.getText(10794), dataIndex: "mId", key: "mId", sorter, width: '10%', render },
            { title: this.getText(10795), dataIndex: "mFirstName", key: "mFirstName", sorter, width: '15%', render },
            { title: this.getText(10796), dataIndex: "mLastName", key: "mLastName", sorter, width: '20%', render },
            { title: this.getText(10797), dataIndex: "mContactPersonName", key: "mContactPersonName", sorter, width: '20%', render },
            { title: this.getText(10798), dataIndex: "mContactPersonMobile", key: "mContactPersonMobile", sorter, width: '15%', render },
            {
                title: this.getText(10799), width: '10%', render: (text, record) => {
                    return (<span>
                        <Tooltip title={this.getText(10800)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"credit-card"} onClick={() => {
                                //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                this.props.uiActions.showModal(TableIDs.modalCreateEditDirectDebit, record['mId']);
                            }} />
                        </Tooltip>
                        <Tooltip title={this.getText(10801)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => {
                                //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                this.props.uiActions.showModal(TableIDs.modalStandingOrdersByCustomer, record['mId']);
                            }} />
                        </Tooltip>
                        <Tooltip title={this.getText(18966)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"bank"} onClick={() => {
                                this.setState({ modalBankAccount: record['mId'] }, () => {
                                    this.props.dataActions.genericDataSetFilter(
                                        this.id_banks, this.props.user.companyCode, this.props.user.token, { _cust: record['mId'] }, this.api.get_banks);
                                        


                                }
                                
                                
                                )




                            }} />

                        </Tooltip>
                    </span>)
                }
            }
        ];

        const excelTitle = (key, text) => (<div> <h3>{key}</h3><h3>{this.getText(text)}</h3></div>)
        const renderStatus = (text) => text ? (<Icon type={text == '0' ? 'check' : 'close'} style={{ color: text == '0' ? 'yellowgreen' : 'red' }} />) : ""


        return (
            <div style={divStyle}>

                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[30, 35, 35]}
                    title={this.getText(10793)}
                    actionButtons={[
                        {
                            buttonType: 'refresh',
                            buttonFunction: this.refreshData
                        }, {
                            buttonType: 'filter',
                            buttonFunction: () => {
                                this.setState({ modalFilter: true });
                            }
                        }, {
                            buttonDisabled: !this.state.filterActive,
                            buttonType: 'clear',
                            buttonFunction: this.initFilters,
                        },
                    ]}
                    genericActionButtons={genericActionButtons}
                    showsearch={(val) => {
                        if (val) { this.setState({ filterActive: true }) }
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <ModalCreateEditDirectDebit
                    id={TableIDs.modalCreateEditDirectDebit}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditDirectDebit)}
                    //  title={"ניהול הוראות קבע"}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalCreateCreditCard
                    id={TableIDs.modalCreateCreditCard}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateCreditCard)}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalDownloadCustomersWithCreditCard
                    id={TableIDs.modalDownloadCustomersWithCreditCard}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalDownloadCustomersWithCreditCard)}
                    // title={"הורדת קובץ לקוחות בעלי כרטיס אשראי"}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalStandingOrdersByCustomer
                    id={TableIDs.modalStandingOrdersByCustomer}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalStandingOrdersByCustomer)}
                    // title={"פירוט הוראות קבע"}
                    ActionQueue={this.props.ActionQueue}
                />

                <CustomerFilter
                    mainId={this.id}
                    visible={this.state.modalFilter}
                    onClose={() => { this.setState({ modalFilter: false }) }}
                    user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                    filterActive={this.state.filterActive}
                    setFilterActive={e => {
                        if (e) this.setState({ filterActive: true })
                        else this.initFilters()
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                />

                <GenericModal
                    visible={this.state.uploadMultiFromExcel}
                    onCancel={this.closeModalUploadExcel}
                    title={this.getText(17483)}
                    width={1000}>
                    {this.state.custList.length ? this.saveExcelButton() : this.uploadExcelButton()}
                    {this.state.errorCustRows ? (<div style={{ color: 'red' }}>{this.state.errorCustRows} {this.getText(17496)}</div>) : ""}
                    <br />
                    <ResponsiveTable
                        tableOnly
                        dataSource={this.state.custList}
                        columns={[
                            { title: excelTitle("A", 17485), key: "A", dataIndex: "A", width: "10%", render: t => t },
                            { title: excelTitle("B", 17486), key: "B", dataIndex: "B", width: "20%", render: t => t },
                            { title: excelTitle("C", 17487), key: "C", dataIndex: "C", width: "10%", render: t => t },
                            { title: excelTitle("D", 17488), key: "D", dataIndex: "D", width: "15%", render: t => t },
                            { title: excelTitle("E", 17490), key: "E", dataIndex: "E", width: "10%", render: t => t },
                            { title: excelTitle("F", 17489), key: "F", dataIndex: "F", width: "10%", render: t => t },
                            { title: this.getText(17491), key: "status", dataIndex: "status", width: "7%", render: renderStatus },
                            { title: this.getText(17492), key: "message", dataIndex: "message", width: "18%", render: t => t },
                        ]}
                    />
                </GenericModal>
                <GenericModal
                    visible={this.state.modalBankAccount != null}
                    onCancel={() => {
                        this.setState({ modalBankAccount: null }, () => {
                            this.props.dataActions.genericDataSetFilter(
                                this.id_banks, this.props.user.companyCode, this.props.user.token, { _cust: 0 }, this.api.get_banks);
                        })
                    }}
                    title={this.getText(18966)}
                    actionButtons={[

                        {
                            buttonType: 'add',
                            buttonFunction: () => {
                                this.setState({
                                    modalAddBankAccount: {
                                        'mCustId': this.state.modalBankAccount,
                                        'mBankNum': '',
                                        'mBranchNum': '',
                                        'mAccountNum': '',
                                        'mBankOwnerName': '',
                                        'mChargeType': '',
                                        'mBankIsActive': 1,
                                        'mID':0

                                    }
                                })
                            }
                        }

                    ]}
                    width={1000}>

                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.id_banks]}
                        columns={[
                            { title: this.getText(18967), key: "mBankNum", dataIndex: "mBankNum", width: "10%", render: t => t },
                            { title: this.getText(18968), key: "mBranchNum", dataIndex: "mBranchNum", width: "10%", render: t => t },
                            { title: this.getText(18969), key: "mAccountNum", dataIndex: "mAccountNum", width: "10%", render: t => t },
                            { title: this.getText(18970), key: "mBankOwnerName", dataIndex: "mBankOwnerName", width: "10%", render: t => t },
                            { title: this.getText(18971), key: "mChargeType", dataIndex: "mChargeType", width: "10%", render: this.getChargeType },
                            { title: this.getText(18972), key: "mBankIsActive", dataIndex: "mBankIsActive", width: "10%", render: t => (<Checkbox checked={t == 1} />) },
                            { title: this.getText(18988), key: "mID", dataIndex: "mID", width: "10%", render: this.onEditBankLine },
                        ]}
                    />



                </GenericModal>

                <GenericModal
                    visible={this.state.modalAddBankAccount != null}
                    onCancel={() => {
                        this.setState({ modalAddBankAccount: null })
                    }}
                    
                    title={this.getText(this.state.modalAddBankAccount && this.state.modalAddBankAccount.mID >0 ? 18987 :18973)}
                    width={600}
                    footer={[
                        <Button type="primary" onClick={this.onSaveNewBank}>{this.getText(18977)}</Button>
                    ]}
                >

                    {this.getAddBankAccountField(18967, 'mBankNum', 2)}
                    {this.getAddBankAccountField(18968, 'mBranchNum', 5)}
                    {this.getAddBankAccountField(18969, 'mAccountNum', 9)}
                    {this.getAddBankAccountField(18970, 'mBankOwnerName', 30)}
                    {this.getAddBankAccountField(18971, 'mChargeType', 1)}
                    {this.getAddBankAccountField(18972, 'mBankIsActive', 1)}

                </GenericModal>
            </div>
        )
    }
}
export default DirectDebit;

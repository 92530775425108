/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Button, Form, Select } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
//import { DatePicker } from 'antd';
//import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class CustomerCreditSettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            //CustomerCreditSettings: {
            //    mId: 0,
            //    mInvoiceTagId: 0,
            //    mDeliveryTagId: 0,
            //}
        };

        //if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
        //    props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        //}
        if (props.data[TableIDs.genericSelectorCustomerHakafaDocs] === undefined) {
            props.data[TableIDs.genericSelectorCustomerHakafaDocs] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_customer_hakafaDocs_tags",
            edit: "edit_customer_hakafaDocs_tags",
            selector: "get_customer_hakafaDocs_tags_list",
        }

        this.id = 'EDIT_CUSTOMER_HAKAFA_DOCS_TAGS';
        /*
                this.props.dataActions.genericSelectorRefreshDataset(
                    TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employee);
        */
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorCustomerHakafaDocs, this.props.user.companyCode,
            this.props.user.token, this.api.selector);

        //this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport,
        // args: [this.props.user.companyCode, this.props.user.token, params] });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - הגדרות";
        this.loadData();
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10203);
    }

   

    setStateVal(root: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, root, params);
        this.setState(newState);
    }

    loadData() {
        console.log(this.props);
        /*
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employee]
        });*/
        this.props.ActionQueue.addToQueue({
            action: this.props.genericSelectorCustomerHakafaDocs,
            args: [TableIDs.genericSelectorCustomerHakafaDocs, this.props.user.companyCode, this.props.user.token, this.api.selector]
        });

        this.props.dataActions.genericDataGetEditWithParams(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            { _id: this.props.ui.data },
            this.api.get
        );

        /*
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericSelectorCustomerHakafaDocs, this.props.user.companyCode,
            this.props.user.token, this.api.selector);
        */
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});

        this.props.dataActions.genericDataJustSaveEdit(
            this.id, this.props.user.companyCode,
            this.props.user.token, this.api.edit);

        //this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode,
        //    this.props.user.token, this.api.edit, this.api.get);
    }

    render() {
        //const { FromDate, ToDate, endOpen } = this.state;
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //let lists = this.props.data[TableIDs.genericSelectorEmployees].dataset.map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        //});

        let lists = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let tagList = Jax.get(this.props.data, TableIDs.genericSelectorCustomerHakafaDocs + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{/*obj['code'] + '-' + */obj['name']}</Option>)
        });

        let mobile = window.innerWidth > 600 ? false : true

        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(11593)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '60%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '2', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(11594)}>
                            <Select key={"mmInvoiceTagId"} style={{ width: 250 }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                defaultValue={0}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { ['mInvoiceTagId']: value });
                                }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mInvoiceTagId'] : ''}
                                notFoundContent={this.getText(11599)}
                            >
                                <Option key={0} value={0}>-</Option>
                                {tagList}
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(11595)}>
                            <Select key={"mDeliveryTagId"} style={{ width: 250 }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                defaultValue={0}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { ['mDeliveryTagId']: value });
                                }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mDeliveryTagId'] : ''}
                                notFoundContent={this.getText(11599)}
                            >
                                <Option key={0} value={0}>-</Option>
                                {tagList}
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(18266)}>
                            <Select key={"mHideDocCustomerInfo"} style={{ width: 250 }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                defaultValue={0}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { ['mHideDocCustomerInfo']: value });
                                }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mHideDocCustomerInfo'] : ''}
                                notFoundContent={this.getText(11599)}
                            >
                                <Option key={0} value={0}>-</Option>
                                {tagList}
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(11596)}>
                            <Select key={"mIsActive"} style={{ width: 220 }}
                                defaultValue={-1}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { ['mDoesObligoContainFutureChecks']: value });
                                }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mDoesObligoContainFutureChecks'] : ''}
                            //notFoundContent={"לא נמצאו עובדים"}       
                            >
                                <Option key={1} value={''}>-</Option>
                                <Option key={2} value={'1'}>{this.getText(11597)}</Option>
                                <Option key={3} value={'0'}>{this.getText(11598)}</Option>
                            </Select>
                        </FormItem>
                        <Button type="primary" htmlType="submit" className={"form-button-save"} icon={"check"}
                            loading={this.props.ui.workInProgress}>{this.getText(11600)}</Button>
                    </Form>
                </Card>
            </div>
        );
    }
}
export default CustomerCreditSettings;
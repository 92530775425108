/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import { Input, InputNumber, message } from "antd";
import TableLineIcons from "../../components/TableLineIcons";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericModal from "../../components/GenericModal";


class BenefitsDistributors extends Component {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalCompanies: null
        };

        this.id = "BENEFITS_DISTRIBUTORS_ADMIN";

        this.api = {
            get: "get_benefits_distributors_admin",
            create: "create_benefits_distributors_admin",
            edit: "edit_benefits_distributors_admin",
            create_comp: "create_benefits_companies_admin",
            edit_comp: "edit_benefits_companies_admin",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericSelector }

        this.actionButtons = [
            { buttonType: 'add', buttonFunction: this.createNewRow },
            { buttonType: 'refresh', buttonFunction: this.refreshData }
        ]

        this.compActionButtons = [
            { buttonType: 'add', buttonFunction: this.createCompNewRow },
            { buttonType: 'refresh', buttonFunction: this.refreshData }
        ];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate(prev) {
        document.title = "Cash On Tab - " + this.getText(17453);

        if (prev.data[this.id].dataset !== this.props.data[this.id].dataset) {
            let mc = this.state.modalCompanies;
            if (mc) {
                let bData = this.props.data[this.id].dataset.find(f => f.type.id === mc.id);
                this.setState({ modalCompanies: { ...this.state.modalCompanies, data: bData ? bData.tags.filter(f => f.id) : [] } })
            }
        }
    }

    refreshData = () => {
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    createNewRow = () => {
        this.props.dataActions.setJson(this.id, { dataset: [{ type: { id: null, text: "", isNew: true, isEdit: true }, tags: [] }, ...this.props.data[this.id].dataset] })
    }

    createCompNewRow = () => {
        let mc = this.state.modalCompanies;
        this.setState({ modalCompanies: { ...mc, data: [{ id: null, text: "", isNew: true, isEdit: true }, ...mc.data] } })
    }

    onChange = (data) => {
        let dataset = this.props.data[this.id].dataset.map(x => x.type.isEdit ? { type: data, tags: data.data } : x);
        this.props.dataActions.setJson(this.id, { dataset });
    }

    onEdit = (id) => {
        let dataset = this.props.data[this.id].dataset.map(x => { return x.type.id === id ? { ...x, type: { ...x.type, isEdit: true } } : x });
        this.props.dataActions.setJson(this.id, { dataset });
    }

    onChangeComp = (data) => {
        let dataset = this.state.modalCompanies.data.map(x => x.isEdit ? data : x);
        this.setState({ modalCompanies: { ...this.state.modalCompanies, data: dataset } });
    }

    onEditComp = (id) => {
        let dataset = this.state.modalCompanies.data.map(x => { return x.id === id ? { ...x, isEdit: true } : x });
        this.setState({ modalCompanies: { ...this.state.modalCompanies, data: dataset } });
    }

    renderIdCol = (text, record) => {
        return record.isNew ?
            (<InputNumber
                value={text}
                onBlur={e => {
                    if (this.props.data[this.id].dataset.find(f => f.type.id == e.target.value && !f.type.isNew)) {
                        message.error(this.getText(17460))
                        this.onChange({ ...record, id: null })
                    }
                }}
                onChange={e => { this.onChange({ ...record, id: e }) }}
            />)
            : text
    }

    renderTextCol = (text, record) => {
        return record.isEdit ? (<Input value={text} onChange={e => { this.onChange({ ...record, text: e.target.value }) }} />) : text
    }

    renderEditCol = (text, record) => {
        return record.isEdit ?
            (<TableLineIcons onCheck={this.saveRow} onClose={this.refreshData} />)
            : (<TableLineIcons
                onEdit={() => { this.onEdit(record.id) }}
                genericIcons={[{ type: "table", onClick: () => { this.setState({ modalCompanies: record }) }, tooltip: this.getText(17458) }]} />)
    }

    renderCompIdCol = (text, record) => {
        return record.isNew ?
            (<InputNumber
                value={text}
                onBlur={e => {
                    if (this.props.data[this.id].dataset.find(f => f.tags.find(ff => ff.id == e.target.value))) {
                        message.error(this.getText(17460))
                        this.onChangeComp({ ...record, id: null })
                    }
                }}
                onChange={e => { this.onChangeComp({ ...record, id: e }) }}
            />)
            : text
    }

    renderCompTextCol = (text, record) => {
        return record.isEdit ? (<Input value={text} onChange={e => { this.onChangeComp({ ...record, text: e.target.value }) }} />) : text
    }

    renderCompEditCol = (text, record) => {
        return record.isEdit ?
            (<TableLineIcons onCheck={this.saveCompRow} onClose={this.refreshData} />)
            : (<TableLineIcons onEdit={() => { this.onEditComp(record.id) }} />)
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    saveRow = () => {
        let d = this.props.data[this.id].dataset.find(f => f.type.isEdit);
        if (d) {
            let dataSave = d.type;
            if (!dataSave.id || !dataSave.text) message.error(this.getText(17461))
            else if (dataSave.isNew) this.sendAPI(this.api.create, "_id\f_text\r" + dataSave.id + "\f" + dataSave.text, this.refreshData)
            else this.sendAPI(this.api.edit, "_id\f_text\r" + dataSave.id + "\f" + dataSave.text, this.refreshData)
        }
    }

    saveCompRow = () => {
        const { data, id } = this.state.modalCompanies;
        let dataSave = data.find(f => f.isEdit);
        if (dataSave) {
            if (!dataSave.id || !dataSave.text) message.error(this.getText(17461))
            else if (dataSave.isNew) this.sendAPI(this.api.create_comp, "_id\f_text\f_bId\r" + dataSave.id + "\f" + dataSave.text + "\f" + id, this.refreshData)
            else this.sendAPI(this.api.edit_comp, "_id\f_text\r" + dataSave.id + "\f" + dataSave.text, this.refreshData)
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            width: 500,
            margin: "auto"
        };

        let dataSource = this.props.data[this.id].dataset.map((x) => {
            return { ...x.type, data: x.tags.filter(f => f.id) }
        });

        let columns = [
            { title: gt(17455), key: "id", dataIndex: "id", width: "15%", render: this.renderIdCol },
            { title: gt(17456), key: "text", dataIndex: "text", width: "70%", render: this.renderTextCol },
            { title: gt(17457), width: "15%", render: this.renderEditCol },
        ]

        let compColumns = [
            { title: gt(17455), key: "id", dataIndex: "id", width: "15%", render: this.renderCompIdCol },
            { title: gt(17456), key: "text", dataIndex: "text", width: "70%", render: this.renderCompTextCol },
            { title: gt(17457), width: "15%", render: this.renderCompEditCol },
        ]

        let mc = this.state.modalCompanies;

        return (<div style={divStyle}>
            <ResponsiveTable
                title={gt(17454)}
                actionButtons={this.actionButtons}
                dataSource={dataSource}
                columns={columns}
            />
            <GenericModal
                visible={mc !== null}
                onCancel={() => { this.setState({ modalCompanies: null }) }}
                title={gt(17459) + " " + (mc ? mc.id + " - " + mc.text : "")}
                width={600}
                actionButtons={this.compActionButtons}
            >
                <ResponsiveTable
                    tableOnly
                    dataSource={mc ? mc.data : []}
                    columns={compColumns}
                />
            </GenericModal>
        </div>)
    }

}

export default BenefitsDistributors;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Avatar } from 'antd';
import LangContext from "../contextProvider/LangContext"

type History = {
  push: Function,
  location: {
    pathname: string
  }
};

type Props = {
  displayName: string,
  profilePicture: string,
  baseUrl: string,
  history: History,
  logoutFunction: Function,
  isAutoIphone: any
};

type State = {};

class UserProfile extends Component<Props, State> {
  logout() {
    this.props.logoutFunction();
    /*
    console.log("debuz 100 ", this.props.isAutoIphone);
    if(this.props.isAutoIphone){
      console.log("debuz 101 ", this.props.isAutoIphone);
      window.location.replace("https://appassets.androidplatform.net/assets/index.html?afterLogout=1")
    }
    */
  }

  static contextType = LangContext;

  getText = (id) => {
    return this.context.get(id) || '[' + id + ']'
  }

  render() {
    return (<div className="header-user-profile">

      {this.props.profilePicture.length > 0 ?
        <Avatar
          size="large"
          src={this.props.profilePicture}
        />
        :
        <Avatar
          size="large"
          icon="user"
        />
      }

      <div className="header-user-profile-name">{this.props.displayName}</div>
      <div className="header-user-profile-logout" 
        onClick={this.logout.bind(this)}>{this.getText(10001)}</div>
    </div>);
  }
}

export default withRouter(UserProfile);

// @flow
/*jshint esversion: 6 */
/*jslint es6*/
import { ReduceStore } from "flux/utils";
import ActionTypes from "./ActionTypes";
import Actions from "./Actions";
import Dispatcher from "./Dispatcher";
import { Login, JobResponse, StringView, Job, User, ProcessType, OutputType, Get_User_For_Renew, Renew } from "../JmReact";
import JmMultiJobDispatcher, { JmMultiJob } from "../JmMultiJob";
import TableIDs from "./TableIDs";

type UserState = {
    token: string,
    companyCode: string,
    companyName: string,
    userId: number,
    error: string,
    favorites: Array<Object>,
    menu: Array<Object>,
    displayName: string,
    profilePicture: string,

    currName: string,
    currDisplayName: string,
    currphone: string,
    currmail: string,
    currpsw1: string,
    currpsw2: string,
}

class UserStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            token: "", companyCode: "", companyName: "", userId: -1, error: "", favorites: [], menu: [], displayName: "", profilePicture: "",
            currName: "", currDisplayName: "", currphone: "", currmail: "", currpsw1: "", currpsw2: ""
        };
    }

    reduce(state: UserState, action: Object) {
        switch (action.type) {

            case ActionTypes.USER_SET_USER_FOR_RENEW:
                return Object.assign({}, state, {
                    currName: action.name, currDisplayName: action.display,
                    currphone: action.phone, currmail: action.email,
                    currpsw1: action.psw1, currpsw2: action.psw2
                });

            case ActionTypes.USER_GET_USER_FOR_RENEW:
                console.log("A: USER_GET_USER_FOR_RENEW");
                Get_User_For_Renew(
                    action.username,
                    action.password,
                    action.companyCode, //(action.companyCode).toLowerCase(),
                    "/cgi-bin/CotRenew",
                    action.confirmation || "",
                    (o: JobResponse) => {
                        let oToken = new StringView(o.data.buffer, "UTF-8", 4);
                        let res = oToken.toString();
                        //const [token, isTwoWayStr, isExpired] = res.split('\f');
                        const [_name, _display, _phone, _email, _days] = res.split('\f');
                        console.log("DEBUG: ", _name, _display, _phone, _email, _days)
                        /*
                            isTwoWayStr
                            0=one way
                            1=two way

                            isExpired
                            0=valid (return token)
                            1=expires in a week (return token)
                            2=expired (no return token)
                        */
                        Actions.userGetUserForRenewSucceeded(_name, _display, _phone, _email, _days);
                        /*
                        const isTwoWay = parseInt(isTwoWayStr);
                        if (isTwoWay > 0) {   // two way auth
                            // TODO: implemenet use case for `isExpired`
                            if (token && token.length > 0) {
                                Actions.userLoginSucceeded(token, isTwoWay);
                                Actions.toggleConfirmationModal(false);
                                Actions.saveFormValues({});
                            } else {
                                Actions.toggleConfirmationModal(true);
                            }
                        } else {    
                            // TODO: implemenet use case for `isExpired`
                            Actions.userLoginSucceeded(token, isTwoWay);
                            Actions.toggleConfirmationModal(false);
                            Actions.saveFormValues({});
                        }
                        */
                    },
                    (err: string) => {
                        console.log("STEP FAILED")
                        //Actions.userLoginFailed(err);
                        Actions.userGetUserForRenewFailed(err);
                        Actions.toggleRenewConfirmationModal(false); // just to be on the safe side
                    });

                return Object.assign({}, state, { companyCode: action.companyCode });

            case ActionTypes.USER_GET_USER_FOR_RENEW_SUCCESS:
                return Object.assign({}, state, { currName: action.name, currDisplayName: action.display, currphone: action.phone, currmail: action.email, currdays: action.days });

            case ActionTypes.USER_GET_USER_FOR_RENEW_ERROR:
                return Object.assign({}, state, { currName: "", currDisplayName: "", currphone: "", currmail: "", error: action.error });



            case ActionTypes.USER_RENEW:
                console.log("USER_RENEW FLUX")

                Renew(action.username,
                    action.password,
                    action.companyCode, //(action.companyCode).toLowerCase(),
                    "/cgi-bin/CotRenew",
                    action.confirmation || "",
                    action.user_name,
                    action.user_display,
                    action.phone,
                    action.email,
                    action.psw1,
                    action.psw2,
                    (o: JobResponse) => {
                        console.log("CALL OK")
                        let oToken = new StringView(o.data.buffer, "UTF-8", 4);
                        let res = oToken.toString();
                        //const [token, isTwoWayStr, isExpired] = res.split('\f');
                        const [isTwoWayStr, isOK] = res.split('\f');
                        const isTwoWay = parseInt(isTwoWayStr);
                        if (isTwoWay > 0) {   // two way auth
                            /*
                            if (token && token.length > 0) {
                                Actions.userLoginSucceeded(token, isTwoWay);
                                Actions.toggleConfirmationModal(false);
                                Actions.saveFormValues({});
                            } else {
                                Actions.toggleConfirmationModal(true);
                            }
                            */
                            Actions.toggleRenewConfirmationModal(true);
                        } else {
                            //Actions.userLoginSucceeded(token, isTwoWay);
                            //Actions.toggleConfirmationModal(false);
                            //Actions.saveFormValues({});
                            Actions.toggleRenewConfirmationModal(false);
                        }
                    },
                    (err: string) => {
                        console.log("CALL FAILED")
                        //console.log("STEP FAILED")
                        Actions.userLoginFailed(err);
                        Actions.toggleRenewConfirmationModal(false);
                        Actions.toggleConfirmationModal(false); // just to be on the safe side
                    });

                return Object.assign({}, state, { companyCode: action.companyCode });

            /* old version */
            case ActionTypes.USER_LOGIN_v00:
                Login(action.username,
                    action.password,
                    action.companyCode,
                    //(action.companyCode).toLowerCase(),
                    "/cgi-bin/CotLogin",
                    action.confirmation || "",
                    (o: JobResponse) => {
                        let oToken = new StringView(o.data.buffer, "UTF-8", 4);
                        let res = oToken.toString();
                        const [token, isTwoWayStr] = res.split('\f');
                        const isTwoWay = parseInt(isTwoWayStr);
                        if (isTwoWay > 0) {   // two way auth
                            if (token && token.length > 0) {
                                // STEP 2 - console.log("STEP 2")
                                Actions.userLoginSucceeded(token, isTwoWay);
                                Actions.toggleConfirmationModal(false);
                                Actions.saveFormValues({});
                            } else {
                                // STEP 1 - console.log("STEP 1")
                                Actions.toggleConfirmationModal(true);
                            }
                        } else {
                            // regular auth - console.log("STEP 0")
                            Actions.userLoginSucceeded(token, isTwoWay);
                            Actions.toggleConfirmationModal(false);
                            Actions.saveFormValues({});
                        }
                    },
                    (err: string) => {
                        console.log("STEP FAILED")
                        Actions.userLoginFailed(err);
                        Actions.toggleConfirmationModal(false); // just to be on the safe side
                    });

                return Object.assign({}, state, { companyCode: action.companyCode });


            case ActionTypes.USER_JUST_LOGIN:
                Login(action.username,
                    action.password,
                    action.companyCode,
                    //(action.companyCode).toLowerCase(),
                    "/cgi-bin/CotLogin",
                    action.confirmation || "",
                    (o: JobResponse) => {
                        let oToken = new StringView(o.data.buffer, "UTF-8", 4);
                        let res = oToken.toString();
                        const [token, isTwoWayStr] = res.split('\f');
                        const isTwoWay = parseInt(isTwoWayStr);
                        if (isTwoWay > 0) {   // two way auth
                            if (token && token.length > 0) {
                                // STEP 2 - console.log("STEP 2")
                                Actions.userLoginSucceeded(token, isTwoWay);
                                Actions.toggleConfirmationModal(false);
                                Actions.saveFormValues({});
                                Actions.toggleRenewModal(0);
                            } else {
                                // STEP 1 - console.log("STEP 1")
                                Actions.toggleConfirmationModal(true);
                            }
                        } else {
                            // regular auth - console.log("STEP 0")
                            Actions.userLoginSucceeded(token, isTwoWay);
                            Actions.toggleConfirmationModal(false);
                            Actions.saveFormValues({});
                            Actions.toggleRenewModal(0);
                        }
                    },
                    (err: string) => {
                        console.log("STEP FAILED")
                        Actions.userLoginFailed(err);
                        Actions.toggleConfirmationModal(false); // just to be on the safe side
                    });

                return Object.assign({}, state, { companyCode: action.companyCode });

            /* new version */
            case ActionTypes.USER_LOGIN:
                console.log("LOG USER_LOGIN: ", action.username, action.password, action.companyCode)
                Login(action.username,
                    action.password,
                    action.companyCode,
                    //(action.companyCode).toLowerCase(),
                    "/cgi-bin/CotLogin",
                    action.confirmation || "",
                    (o: JobResponse) => {
                        let oToken = new StringView(o.data.buffer, "UTF-8", 4);
                        let res = oToken.toString();
                        const [token, isTwoWayStr, isExpiredStr] = res.split('\f');

                        console.log("LOG USER_LOGIN: res: ", token, isTwoWayStr, isExpiredStr)

                        /*
                            ## isTwoWayStr ##
                            0=one way
                            1=two way
                            ## isExpired ##
                            0=valid (return token)
                            1=expires in a week (return token)
                            2=expired (no return token)
                        */
                        const isTwoWay = parseInt(isTwoWayStr);
                        const isExpired = parseInt(isExpiredStr);
                        if (isTwoWay > 0) {   // two way auth
                            const MIN_TOKEN_LENGTH = 0;
                            if (token && token.length > MIN_TOKEN_LENGTH) {
                                if (isExpired === 1) {
                                    Actions.toggleConfirmationModal(false);
                                    Actions.toggleRenewModal(isExpired);
                                    //Actions.toggleRenewConfirmationModal(isExpired);
                                } else if (isExpired === 2) {
                                    Actions.toggleConfirmationModal(false);
                                    Actions.toggleRenewConfirmationModal(isExpired);
                                } else { 
                                    Actions.userLoginSucceeded(token, isTwoWay);
                                    Actions.toggleConfirmationModal(false);
                                    //Actions.toggleRenewModal(0);
                                    Actions.toggleRenewConfirmationModal(0);
                                    //Actions.saveFormValues({});
                                }

                            } else if (isExpired === 3) {
                                // pass auth but expired
                                Actions.toggleConfirmationModal(true);
                            } else {
                                // pass auth but expired
                                Actions.toggleConfirmationModal(false);
                                Actions.toggleRenewModal(isExpired)
                                //Actions.saveFormValues({});
                            }
                        } else {
                            Actions.toggleConfirmationModal(false); // to be on safe side
                            if (isExpired === 1) {
                                Actions.toggleRenewModal(isExpired);
                                //Actions.saveFormValues({});
                            } else if (isExpired === 2) {
                                Actions.toggleRenewModal(isExpired);
                            } else {
                                Actions.userLoginSucceeded(token, isTwoWay);
                                Actions.toggleConfirmationModal(false);
                                Actions.toggleRenewModal(0);
                                Actions.toggleRenewConfirmationModal(0);
                                //Actions.saveFormValues({});
                            }
                        }
                    },
                    (err: string) => {
                        console.log("STEP FAILED")
                        Actions.userLoginFailed(err);
                        Actions.toggleConfirmationModal(false); // just to be on the safe side
                    });

                return Object.assign({}, state, { companyCode: action.companyCode });


            case ActionTypes.USER_LOGIN_SUCCESS:
                // We have token in action.token
                // Now, let's get all the ui data
                let user: User = new User(action.token, state.companyCode);

                // TODO: action.isTwoWay:number is now available for use 
                console.log('3A: USER_LOGIN_SUCCESS')//alert('A: expired')

                // company name to title
                let companyName: Job = new Job(user, "get_company_name", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                const companyNameSuccess: Function = (response: JobResponse) => {
                    Actions.companyNameLoaded(response.data);
                };
                const companyNameFail: Function = (errorCode: number, errorMessage: string) => {
                    // Do nothing
                    window.console.error("Loading company name failed with error " + errorCode + ": " + errorMessage);
                };
                let companyNameMultiJob: JmMultiJob = new JmMultiJob(companyName, companyNameSuccess, companyNameFail);
                //

                let userProfileJob: Job = new Job(user, "get_user_profile", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                const userProfileSuccess: Function = (response: JobResponse) => {
                    let data: Array<string> = response.data.split("\f");
                    Actions.userProfileLoaded(data[0], data[1], parseInt(data[2], 10));
                };
                const userProfileFail: Function = (errorCode: number, errorMessage: string) => {
                    // Do nothing
                    window.console.error("Loading profile failed with error " + errorCode + ": " + errorMessage);
                };
                let userProfileMultiJob: JmMultiJob = new JmMultiJob(userProfileJob, userProfileSuccess, userProfileFail);

                let userMenuJob: Job = new Job(user, "get_user_menu", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                const userMenuSuccess: Function = (response: JobResponse) => {
                    Actions.userMenuLoaded([]);
                };
                const userMenuFail: Function = (errorCode: number, errorMessage: string) => {
                    // Do nothing
                    window.console.error("Loading menu failed with error " + errorCode + ": " + errorMessage);
                };
                let userMenuMultiJob: JmMultiJob = new JmMultiJob(userMenuJob, userMenuSuccess, userMenuFail);

                let userFavoritesJob: Job = new Job(user, "get_user_favorites", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                const userFavoritesSuccess: Function = (response: JobResponse) => {
                    let data: Array<string> = response.data.split("\r");
                    let favorites: Array<Object> = [];
                    for (let i = 0, j = data.length; i < j; i++) {
                        let row = data[i].split("\f");
                        favorites.push({
                            title: row[1],
                            url: row[0]
                        });
                    }
                    Actions.userFavoritesLoaded(favorites);
                };
                const userFavoritesFail: Function = (errorCode: number, errorMessage: string) => {
                    // Do Nothing
                    window.console.error("Loading favorites failed with error " + errorCode + ": " + errorMessage);
                };
                let userFavoritesMultiJob: JmMultiJob = new JmMultiJob(userFavoritesJob, userFavoritesSuccess, userFavoritesFail);

                const multiJobFail = (error: string) => {
                    Actions.userUiDataFailed(error);
                };

                const multiJobSuccess = (successes: number, fails: number) => {

                    if (successes === 4 && fails === 0) {
                        Actions.userUiDataSucceeded(action.token);
                    }
                    else {
                        console.log("כשלון חלקי", successes, fails);
                        multiJobFail("כשלון חלקי");
                    }
                };

                let multiDispatch: JmMultiJobDispatcher = new JmMultiJobDispatcher([userFavoritesMultiJob, userMenuMultiJob, userProfileMultiJob, companyNameMultiJob]);
                multiDispatch.send("/cgi-bin/CashOnTab", multiJobSuccess, multiJobFail);

                /* get active component list by active modules */
                Actions.genericSelectorRefreshDataset(
                    TableIDs.pseudoSelectorUIcomponentsModules,
                     /*user.companyCode*/state.companyCode, /*user.token*/action.token, "get_ui_components_modules_list");
                /* ------- */
                return state;

            case ActionTypes.USER_REFRESH:
                window.setTimeout(() => {
                    Actions.userLoginSucceeded(action.token, 0 /* isTwoWay*/);
                    Actions.toggleConfirmationModal(false);
                    Actions.saveFormValues({});
                }, 50);
                return Object.assign({}, state, { companyCode: action.companyCode });;

            case ActionTypes.USER_LOGIN_ERROR:
                return Object.assign({}, state, { token: "", error: action.error });

            case ActionTypes.USER_GET_UI_DATA_SUCCESS:
                // Share the token with the app so the main view could be displayed
                return Object.assign({}, state, { token: action.token, error: "" });

            case ActionTypes.USER_FAVORITES_LOADED:
                return Object.assign({}, state, { favorites: action.favorites });

            case ActionTypes.USER_MENU_LOADED:
                return Object.assign({}, state, { menu: action.menu });

            case ActionTypes.COMPANY_NAME_LOADED:
                return Object.assign({}, state, { companyName: action.name });

            case ActionTypes.USER_PROFILE_LOADED:
                return Object.assign({}, state, {
                    displayName: action.displayName,
                    profilePicture: action.profilePicture,
                    userId: action.userId
                });

            case ActionTypes.USER_LOGOUT:
                let callingUser: User = new User(state.token, state.companyCode);
                let logoutJob: Job = new Job(callingUser, "logout_user", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                logoutJob.send("/cgi-bin/CashOnTab", () => {
                }, (error: string) => {
                    window.console.error("Logout failed: " + error)
                });

                return Object.assign({}, state, {
                    token: "",
                    companyCode: "",
                    companyName: "",
                    error: "",
                    favorites: [],
                    menu: [],
                    displayName: "",
                    profilePicture: ""
                });

            case ActionTypes.API_FAILED_FORBIDDEN:
                return Object.assign({}, state, {
                    token: "",
                    companyCode: "",
                    companyName: "",
                    error: "",
                    favorites: [],
                    menu: [],
                    displayName: "",
                    profilePicture: ""
                });

            default:
                return state;
        }
    }
}

export default new UserStore();

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import {
    Card, //Col, Row, Button, 
    Form, //Select, 
    Checkbox, InputNumber, Radio, Select
} from "antd";
import Jax from "../../Jax/jax.es6.module";
//import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import { disabledDatePicker } from "../../utils/utils";

const FormItem = Form.Item;
//const Option = Select.Option;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class BirthdaysReport extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            BirthdaysReport: {
                mFilterType: "1",
                mLowerSelectionDate: null,
                mUpperSelectionDate: null,
                mApplyRangeApply: 0,
                mApplyRangeLower: 0,
                mApplyRangeUpper: 0,
                reportName: 'reports/BirthdaysReport.pdf'
            }
        };
        this.state.type = "pdf";

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10161);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("BirthdaysReport", this.props.ui.favoriteParams)
        }
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    disabledStartDate = (FromDate) => {
        const ToDate = this.state.BirthdaysReport.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.BirthdaysReport.mUpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("BirthdaysReport", { [field]: data });
                    else
                        this.setReportParameters("BirthdaysReport", { [field]: '' });
                }} />);
    }

    onChange = (field, value) => {
        this.setReportParameters("BirthdaysReport", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }


    render() {
        const { BirthdaysReport, endOpen } = this.state;
        const {
            mLowerSelectionDate,
            mUpperSelectionDate,
            mApplyRangeApply,
            mApplyRangeLower,
            mApplyRangeUpper,
            reportName
        } = BirthdaysReport;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };


        let resStyle = (first) => {
            let res = {
                display: 'inline-block',
                verticalAlign: 'text-top',
                width: '50%'
            }
            let mRes = (first) ? { marginTop: 80 } : {}
            return (window.innerWidth > 600) ? res : mRes
        }
        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(11043)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: (window.innerWidth > 600) ? '60%' : '96%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <div style={resStyle(true)}>
                        <FormItem label={this.getText(18602)} >
                            <Radio.Group
                                value={this.state.BirthdaysReport.mFilterType}
                                onChange={(value) => {
                                    this.setReportParameters("BirthdaysReport", {
                                        mFilterType: value.target.value,
                                        mUpperSelectionDate: null,
                                        mLowerSelectionDate: null
                                    })
                                }}>
                                <Radio value={"1"}>{this.getText(18603)}</Radio><br />
                                <Radio value={"2"}>{this.getText(18604)}</Radio><br />
                                <Radio value={"3"}>{this.getText(18605)}</Radio>
                            </Radio.Group>
                        </FormItem>
                        {this.state.BirthdaysReport.mFilterType == '1' ?
                            <FormItem label={this.getText(18606)} >
                                <Select
                                    value={mUpperSelectionDate}
                                    onChange={(value) => this.setReportParameters("BirthdaysReport", { mUpperSelectionDate: value })}
                                    style={{ width: 250 }}>
                                    <Select.Option value={"2000-01-01"}>{this.getText(18607)}</Select.Option>
                                    <Select.Option value={"2000-02-01"}>{this.getText(18608)}</Select.Option>
                                    <Select.Option value={"2000-03-01"}>{this.getText(18609)}</Select.Option>
                                    <Select.Option value={"2000-04-01"}>{this.getText(18610)}</Select.Option>
                                    <Select.Option value={"2000-05-01"}>{this.getText(18611)}</Select.Option>
                                    <Select.Option value={"2000-06-01"}>{this.getText(18612)}</Select.Option>
                                    <Select.Option value={"2000-07-01"}>{this.getText(18613)}</Select.Option>
                                    <Select.Option value={"2000-08-01"}>{this.getText(18614)}</Select.Option>
                                    <Select.Option value={"2000-09-01"}>{this.getText(18615)}</Select.Option>
                                    <Select.Option value={"2000-10-01"}>{this.getText(18616)}</Select.Option>
                                    <Select.Option value={"2000-11-01"}>{this.getText(18617)}</Select.Option>
                                    <Select.Option value={"2000-12-01"}>{this.getText(18618)}</Select.Option>
                                </Select>
                            </FormItem>
                            : [
                                <FormItem label={this.getText(11044)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, null, mUpperSelectionDate)}
                                        // disabledDate={this.disabledStartDate}
                                        //showTime
                                        format={this.state.BirthdaysReport.mFilterType == '2' ? "DD/MM" : "DD/MM/YYYY"}//"YYYY-MM-DD"
                                        //format="D/M/YY"
                                        value={mLowerSelectionDate ? moment(mLowerSelectionDate, "YYYY-MM-DD") : null}
                                        placeholder={this.getText(11047)}
                                        onChange={this.onStartChange}
                                    //onOpenChange={this.handleStartOpenChange}
                                    />
                                </FormItem>,
                                <FormItem label={this.getText(11045)}>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, mLowerSelectionDate, null)}
                                        //  disabledDate={this.disabledEndDate}
                                        //showTime
                                        format={this.state.BirthdaysReport.mFilterType == '2' ? "DD/MM" : "DD/MM/YYYY"}//"YYYY-MM-DD"
                                       //format="D/M/YY"
                                        value={mUpperSelectionDate ? moment(mUpperSelectionDate, "YYYY-MM-DD") : null}
                                        placeholder={this.getText(11048)}
                                        onChange={this.onEndChange}
                                    //open={endOpen}
                                    //onOpenChange={this.handleEndOpenChange}
                                    />
                                </FormItem>
                            ]}
                    </div>
                    <div style={resStyle(false)}>
                        <FormItem label={this.getText(11046)}>
                            <Checkbox
                                //onChange={this.handleCheckBoxRange}
                                //ApplyRange_Apply
                                checked={mApplyRangeApply == 1}
                                onChange={(value) => {
                                    let req = { mApplyRangeApply: value.target.checked ? 1 : 0 }
                                    if (!value.target.checked) req = { ...req, mApplyRangeLower: 0, mApplyRangeUpper: 0 }
                                    this.setReportParameters("BirthdaysReport", req)
                                }}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Lower
                                min={1}
                                value={this.state.BirthdaysReport.mApplyRangeApply &&
                                    this.state.BirthdaysReport.mApplyRangeLower ?
                                    this.state.BirthdaysReport.mApplyRangeLower : ''}
                                onChange={(value) => this.setReportParameters("BirthdaysReport", { mApplyRangeLower: value })}
                                disabled={this.state.BirthdaysReport.mApplyRangeApply != 1}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Upper
                                min={1}
                                value={this.state.BirthdaysReport.mApplyRangeApply &&
                                    this.state.BirthdaysReport.mApplyRangeUpper ?
                                    this.state.BirthdaysReport.mApplyRangeUpper : ''}
                                onChange={(value) => this.setReportParameters("BirthdaysReport", { mApplyRangeUpper: value })}
                                disabled={this.state.BirthdaysReport.mApplyRangeApply != 1}
                                style={{ marginLeft: '4px' }}

                            />
                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.BirthdaysReport}
                            datesToFavorites={[
                                { field: 'mLowerSelectionDate', label: this.getText(11044) },
                                { field: 'mUpperSelectionDate', label: this.getText(11045) },
                            ]}
                            favoriteSave
                        />
                        {/* <FormItem key={"reportName"} label={this.getText(11049)}>
                            <Select style={{ width: 220 }}
                                //size={"large"}
                                className={"reports/BirthdaysReport.pdf"}
                                defaultValue={"pdf"}
                                //combobox={null}
                                //defaultValue={"reports/BirthdaysReport.pdf"}
                                onChange={(value) => {
                                    this.setState({ ...this.state, type: value },
                                        () => {
                                            this.setReportParameters("BirthdaysReport",
                                                { reportName: this.reportNameBuilder() });
                                        }
                                    );
                                }}
                            >
                                <Option key={"pdf"}>{this.getText(11050)}</Option>
                                <Option key={"xls"}>{this.getText(11051)}</Option>
                                <Option key={"docx"}>{this.getText(11052)}</Option>
                            </Select>
                        </FormItem>
                        <Button type={"primary"} onClick={() => {
                            ////console.log('yuda BirthdaysReport ', this.state.BirthdaysReport)
                            this.generateReport.apply(this, ["BirthdaysReport"])
                        }}>{this.getText(11053)}</Button> */}
                    </div>
                    {/* </Form> */}
                </Card>
            </div>
        );
    }
}
export default BirthdaysReport;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Table, Icon, Select, InputNumber, Input, Checkbox, Tooltip } from "antd";
// import PageToolbar from "../../components/PageToolbar";
// import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import ModalCustomerFieldRoles from "../../components/ModalCustomerFieldRoles";
import TableIDs from "../../data/TableIDs";
import GenericTable from "../../components/GenericTable";
import GenericModalForm from "../../components/GenericModalForm";

const { Option } = Select;

const topHeight = 350; // px
const rowHeight = 36;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {};

class CustomerField extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "CUSTOMER_FIELDS";
        this.defaultFilters = {
            _isActive: '-1',
            _controlType: '-1',
            _roleType: '-1',
            _container: '-1',
        }

        this.state = {
            modalFilter: false,
            filters: this.defaultFilters,
        };

        // this.state.height = window.innerHeight;
        // this.doOnResize = null;

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

        this.api = {
            get: "get_customer_fields",
            create: "",
            edit: "edit_customer_field",
            delete: "",
        }

        this.controlTypeList = [
            { code: '0', name: 10374 },
            { code: '1', name: 10375 },
            { code: '2', name: 10376 },
            { code: '3', name: 10377 },
            { code: '4', name: 10378 },
            { code: '5', name: 10379 },
            { code: '6', name: 10380 },
            { code: '7', name: 10381 },
            { code: '8', name: 10382 },
            { code: '9', name: 10383 },
            { code: '10', name: 13356 },
            { code: '11', name: 17175 },
            { code: '12', name: 17529 },
            { code: '13', name: 17560 },
        ]

        // this.actionButtons = [/*{
        //     buttonType: 'add',
        //     buttonFunction: () => {
        //         //this.props.uiActions.showModal(TableIDs.modalCreateItem);
        //         this.props.dataActions.genericDataStartNew(this.id);
        //     }
        // }, {
        //         buttonType: 'print',
        //         buttonFunction: () => { alert('printing'); },
        // }, {
        //         buttonType: 'export',
        //         buttonFunction: () => { alert('exporting'); },
        // }, {
        //     buttonType: 'search',
        //     buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchItem); },
        // }, {
        //     buttonType: 'filter',
        //     buttonFunction: () => { },
        // }, */{
        //         buttonType: "refresh",
        //         buttonFunction: this.refreshData
        //     }
        // ];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - שדות לקוחות";
        // this.initFilters();
        //this.refreshData();
        // window.addEventListener("resize", () => {
        //     clearTimeout(this.doOnResize);
        //     this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        // });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10215);
    }

    // componentWillUnmount() {
    //     window.removeEventListener("resize", this.handleWindowSizeChange);
    // }

    // handleWindowSizeChange = () => {
    //     this.setState({ height: window.innerHeight }, this.initFilters);
    //     //console.log("re render", window.innerHeight, " state: ", this.state.height)
    // };

    // initFilters = () => {
    //     let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
    //         ? (this.state.height - topHeight) / rowHeight
    //         : 1);

    //     let request = {
    //         _id: 0,
    //         _page_num: 1,
    //         _rows_num: numOfRowsByHeight,
    //         _isAsc: 1,
    //         _sort_by: "col_1",
    //         _words: "",
    //         recordsPerPage: numOfRowsByHeight,
    //     };
    //     this.props.dataActions.genericDataSetFilter(
    //         this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    // }

    // refreshData = () => {
    //     this.props.dataActions.genericDataGetRequested(
    //         this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

    //     //this.props.dataActions.genericDataGetEditRequested(
    //     //    this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

    // }

    // handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
    //     let col = 9;
    //     // fall through
    //     switch (sorter.field) {
    //         case "key": col--; // 1
    //         // falls through
    //         case "Id": col--;
    //         // falls through
    //         case "Description": col--;
    //         // falls through
    //         case "IsActive": col--;
    //         // falls through
    //         case "IsMandatory": col--;
    //         // falls through
    //         case "IsSearchable": col--;
    //         // falls through
    //         case "Container": col--;
    //         // falls through
    //         case "ContainerOrder": col--;
    //         // falls through
    //         case "ControlType": col--;
    //         // falls through
    //         case "MaxLength": break; // 10
    //         default:
    //             col = 0;
    //     }

    //     let request = {
    //         // just of ui-pagination update
    //         page: pagination.current,
    //         recordsPerPage: pagination.pageSize,
    //         sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
    //         //
    //         _page_num: pagination.current,
    //         _rows_num: pagination.pageSize,
    //         _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
    //         _sort_by: "col_" + col,
    //         _words: "",
    //     };

    //     console.log("CustomerField - handle table change: ",
    //         pagination, filters, sorter);
    //     console.log(request);

    //     this.props.dataActions.genericDataSetFilter(
    //         this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    // }


    // handleCreateNew = () => { /*this.props.dataActions.earningPointsStartNew();*/
    //     this.props.dataActions.genericDataStartNew(this.id);
    // }
    // handleCreateFormCancel = () => { /*this.props.dataActions.earningPointsCancelNew();*/
    //     this.props.dataActions.genericDataCancelNew(this.id);
    // }
    // handleShowFilters = () => { /*this.props.dataActions.earningPointsShowFilters();*/
    //     this.props.dataActions.genericDataShowFilters(this.id);
    // }
    // handleHideFilters = () => { /*this.props.dataActions.earningPointsHideFilters()*/
    //     this.props.dataActions.genericDataHideFilters(this.id);
    // }
    // handleClearFilters = () => {
    //     /*
    //     this.props.dataActions.earningPointsClearFilters(
    //         this.props.user.companyCode, this.props.user.token, this.props.data.earningPoints.pagination.defaultPageSize);*/
    //     this.props.dataActions.genericDataClearFilters(
    //         this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    // }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });

        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal === 1 || checkedVal === "1");
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        // const dataSource = this.props.data[this.id].data.map((x, index) => {
        //     let y = x.split("\f");
        //     return ({
        //         key: y[0],
        //         Id: y[0],
        //         Description: y[1],
        //         IsActive: y[2],
        //         IsMandatory: y[3],
        //         IsSearchable: y[4],
        //         Container: y[5],
        //         ContainerOrder: y[6],
        //         ControlType: y[7],
        //         MaxLength: y[8],
        //         index: index
        //     })
        // });

        // let request = {
        //     //_id: 0,
        //     // just of ui-pagination update
        //     page: 1,
        //     sortType: 1,
        //     //
        //     _page_num: 1,
        //     _isAsc: 1,
        //     _sort_by: "col_1",
        // };

        let columns = [
            {
                title: this.getText(11348),
                dataIndex: "Id",
                key: "Id",
                sorter: true,
                width: "6%",
                render: (text, record) => text,
            }, {
                title: this.getText(11349),
                dataIndex: "Description",
                key: "Description",
                width: "22%",
                sorter: true,
                //render: (text, record) => text,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (<Input style={{ width: '98%' }} defaultValue={text}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { Description: value.target.value });
                            }} />);
                    } else {
                        return text;
                    }
                }
            },
            {
                title: this.getText(11350),
                dataIndex: "IsActive",
                key: "IsActive",
                width: "7%",
                sorter: true,
                render: (text, record) => {
                    return (<Checkbox
                        onChange={(e) => { this.handleChangeCheckBox(e, text, "IsActive", record); }}
                        checked={this.checkIfChecked("IsActive", text, record)}
                        defaultChecked={false}
                        disabled={!(this.props.data[this.id].editing === record.key)}
                    />)
                }
            },
            {
                title: this.getText(11351),
                dataIndex: "IsMandatory",
                key: "IsMandatory",
                width: "7%",
                sorter: true,
                render: (text, record) => {
                    return (<Checkbox
                        onChange={(e) => { this.handleChangeCheckBox(e, text, "IsMandatory", record); }}
                        checked={this.checkIfChecked('IsMandatory', text, record)}
                        defaultChecked={false}
                        disabled={!(this.props.data[this.id].editing === record.key)}
                    />)
                }
            },
            {
                title: this.getText(11352),
                dataIndex: "IsSearchable",
                key: "IsSearchable",
                width: "7%",
                sorter: true,
                render: (text, record) => {
                    return (<Checkbox
                        onChange={(e) => { this.handleChangeCheckBox(e, text, "IsSearchable", record); }}
                        checked={this.checkIfChecked("IsSearchable", text, record)}
                        defaultChecked={false}
                        disabled={!(this.props.data[this.id].editing === record.key) || record.ControlType == '0'}
                    />)
                },
            }, {
                title: this.getText(11353),
                dataIndex: "Container",
                key: "Container",
                width: "7%",
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <InputNumber
                                min={0}
                                defaultValue={text}
                                onChange={(value: number) => {
                                    //this.props.dataActions.earningPointsSetEdit({ multiplication: value })
                                    this.props.dataActions.genericDataSetEdit(this.id, { Container: value });
                                }} />
                        )
                    } else {
                        return text;
                    }
                }
            }, {
                title: this.getText(11354),
                dataIndex: "ContainerOrder",
                key: "ContainerOrder",
                width: "7%",
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (<InputNumber min={0} defaultValue={text} onChange={(value: number) => {
                            this.props.dataActions.genericDataSetEdit(this.id, { ContainerOrder: value });
                        }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title: this.getText(11355),
                dataIndex: "ControlType",
                key: "ControlType",
                width: "16%",
                sorter: true,
                render: (text, record) => {

                    return (
                        <Select
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            disabled
                            // disabled={!(this.props.data[this.id].editing === record.key)}
                            value={(this.props.data[this.id].editing === record.key) ?
                                // parseInt(this.props.data[this.id].editedRecord['ControlType'], 10) : parseInt(text, 10)}
                                this.props.data[this.id].editedRecord['ControlType'] : text}
                            defaultValue=""
                            style={{ width: '100%', paddingLeft: '4px' }}
                            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ControlType: e }) }}
                        >
                            {this.controlTypeList.map(x => {
                                return <Option key={x.code} value={x.code} valuefilter={this.getText(x.name)}>{this.getText(x.name)}</Option>
                            })}
                            {/* <Option key={0} value={0} valuefilter={this.getText(10374)}>{this.getText(10374)}</Option>
                            <Option key={1} value={1} valuefilter={this.getText(10375)}>{this.getText(10375)}</Option>
                            <Option key={2} value={2} valuefilter={this.getText(10376)}>{this.getText(10376)}</Option>
                            <Option key={3} value={3} valuefilter={this.getText(10377)}>{this.getText(10377)}</Option>
                            <Option key={4} value={4} valuefilter={this.getText(10378)}>{this.getText(10378)}</Option>
                            <Option key={5} value={5} valuefilter={this.getText(10379)}>{this.getText(10379)}</Option>
                            <Option key={6} value={6} valuefilter={this.getText(10380)}>{this.getText(10380)}</Option>
                            <Option key={7} value={7} valuefilter={this.getText(10381)}>{this.getText(10381)}</Option>
                            <Option key={8} value={8} valuefilter={this.getText(10382)}>{this.getText(10382)}</Option>
                            <Option key={9} value={9} valuefilter={this.getText(10383)}>{this.getText(10383)}</Option>
                            <Option key={10} value={10} valuefilter={this.getText(13356)}>{this.getText(13356)}</Option>
                            <Option key={11} value={11} valuefilter={this.getText(17175)}>{this.getText(17175)}</Option>
                            <Option key={12} value={12} valuefilter={this.getText(17529)}>{this.getText(17529)}</Option>
                            <Option key={13} value={13} valuefilter={this.getText(17560)}>{this.getText(17560)}</Option> */}
                        </Select>
                    )
                }
            }, {
                title: this.getText(11356),
                dataIndex: "MaxLength",
                key: "MaxLength",
                width: "10%",
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (<InputNumber style={{ width: 80 }} min={0} max={100} defaultValue={text}
                            onChange={(value: number) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { MaxLength: value });
                            }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title: this.getText(11357),
                width: "7%",
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return (<span>
                            <Tooltip title={this.getText(12591)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                }} />
                            </Tooltip>
                            <Tooltip title={this.getText(12592)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"tag"} onClick={() => {
                                    this.props.uiActions.showModal(TableIDs.modalCustomerFieldRoles, { rID: record.Id, rFieldName: record.Description });
                                }} />
                            </Tooltip>
                        </span>);
                    } else {
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>);
                    }
                }
            }
        ];


        return (
            <div style={divStyle}>
                {/* <PageToolbar
                    title={this.getText(11347)}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }
                    }
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} /> 
                    
                    <Select
                    //disabled={!(this.props.data[this.id].editing === record.key)}
                    showsearch
                    filterOption={true}
                    optionFilterProp="valuefilter"
                    value={this.props.data[this.id].filters['_id']}
                    defaultValue={0}
                    style={{ width: '256px', padding: '4px' }}
                    onChange={(e) => {
                        const r = {
                            _id: e,
                            // just of ui-pagination update
                            page: 1,
                            sortType: 1,
                            //
                            _page_num: 1,
                            _isAsc: 1,
                            _sort_by: "col_1",
                        };

                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            r, this.api.get)
                    }}>
                    <Option key={0} value={0}>-{this.getText(11358)}-</Option>
                    {[...Array(11).keys()].map(i => {
                        const j = i + 1;
                        return (<Option key={j} value={j}>{(j).toString() + ' ' + this.getText(11359)}</Option>)
                    })
                    }
                </Select> 
                 <ResponsiveTable
                    tableOnly={true}
                    percentageToMobile={[false, 50, false, false, false, false, false, 50]}
                    ui={this.props.ui}
                    idTable={this.props.data[this.id]}
                    dataSource={dataSource}
                    columns={columns}
                    onChange={this.handleTableChange}
                /> */}

                <GenericTable
                    {...this.props}
                    id={this.id}
                    getApi={this.api.get}
                    title={this.getText(11347)}
                    searchBox
                    actionButtons={[{ buttonType: 'filter', buttonFunction: () => { this.setState({ modalFilter: true }) } }]}
                    columns={columns}
                    filters={this.state.filters}
                    rowHeight={40}
                />

                <GenericModalForm
                    visible={this.state.modalFilter}
                    onCancel={this.onCancelFilter}
                    title={this.getText(19433)}
                    width={1000}
                    columnCount={2}
                    footerBtn={[
                        { type: "primary", text: this.getText(19434), onClick: this.onFilter },
                        { text: this.getText(19435), onClick: this.onClearFilter },
                        { text: this.getText(19436), onClick: this.onCancelFilter },
                    ]}
                    formItems={[
                        { label: this.getText(19437), content: this.getFilterSelect("_isActive") },
                        { label: this.getText(19438), content: this.getFilterSelect("_controlType") },
                        { label: this.getText(19439), content: this.getFilterSelect("_roleType") },
                        { label: this.getText(19440), content: this.getFilterSelect("_container") },
                    ]} />


                <ModalCustomerFieldRoles
                    id={TableIDs.modalCustomerFieldRoles}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCustomerFieldRoles)}
                />
            </div>
        );
    }

    onFilter = () => {
        const { filters } = this.state;
        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, { ...filters, _page_num: 1, page: 1 }, this.api.get);
        })
    }

    onClearFilter = () => {
        const filters = this.defaultFilters
        this.setState({ modalFilter: false, filters }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, { ...filters, _page_num: 1, page: 1 }, this.api.get);
        })
    }

    onCancelFilter = () => {
        this.setState({ modalFilter: false, filters: this.props.data[this.id].filters });
    }

    getFilterSelect = (field) => {
        const { filters } = this.state;
        return (<Select
            style={{ width: "90%" }}
            value={filters[field]}
            onChange={e => { this.setState({ filters: { ...filters, [field]: e } }) }}>
            <Select.Option value={'-1'}>{this.getText(19441)}</Select.Option>
            {this.getFilterSelectOptions(field)?.map(x => (<Select.Option value={x.code}>{x.name}</Select.Option>))}
        </Select>)
    }

    getFilterSelectOptions = (field) => {
        const gt = this.getText;
        switch (field) {
            case '_isActive':
                return [
                    { code: '1', name: gt(19443) },
                    { code: '0', name: gt(19442) }
                ]

            case '_controlType':
                return this.controlTypeList.map(x => { return { ...x, name: gt(x.name) } })

            case '_roleType':
                return [
                    { code: '1', name: gt(19444) },
                    { code: '2', name: gt(19445) },
                    { code: '3', name: gt(19446) },
                    { code: '4', name: gt(19447) }
                ]

            case '_container':
                return [...Array(12).keys()].map(x => { return { code: String(x + 1), name: this.getText(11359) + ' ' + (x + 1) } })
        }
    }

}

export default CustomerField;


/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button } from "antd";
import "../App.css";

type ItemPickerProps = {
    defaultItemCode: string,
    onChange: Function,
    label: string,
    data: Array<string>,
    showDialog: Function,
    hideDialog: Function,
    disabled?: boolean
};

type State = {

};

class ItemPicker extends Component<ItemPickerProps, State> {

    handleClick() {
        this.props.showDialog(this.props.defaultItemCode, this.props.data, this.handleChange.bind(this));
    }

    handleChange(state: Object) {
        if (state.selection !== undefined) {
            this.props.onChange({
                itemCode: state.selection ? state.selection : "", // null
                itemName: state.itemName ? state.itemName : "" // null
            });
        }
        this.props.hideDialog();
    }

    render() {
        return (<div>
            <Button className={""} htmlType={"button"} loading={false} size={"default"}
                disabled={this.props.disabled} style={{ width: 175 }} type={"primary"}
                onClick={this.handleClick.bind(this)} icon={"edit"}>
                {this.props.label}
            </Button>

        </div>);
    }
}

export default ItemPicker;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Checkbox, Button, Input, InputNumber, Select, Form, Row, Col, message, Tooltip, Icon } from "antd";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from '../../data/TableIDs.js';
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

class Priority extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalCreateEdit: null,
            modalStorages: false,
            valueSearch: "",
            valueSearchStorages: "",
        }

        this.id = "PRIORITY_CONVERTOR_TABLE";
        this.id_s = "PRIORITY_STORAGES_CONVERTOR_TABLE";

        this.selectors = [
            { id: TableIDs.genericSelectorPos, api: "list_pos" },
        ];

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.id_s] === undefined) props.data[this.id_s] = { ...props.data.genericSelector };
        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector }; })

        this.api = {
            get: "get_priority_convertor_table",
            delete: "delete_priority_convertor",
            create_edit: "create_edit_priority_convertor",

            get_s: "get_priority_storages_convertor_table",
            create_edit_s: "create_edit_priority_storages_convertor",
            delete_s: "delete_priority_storages_convertor",

        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: this.createNew
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15251);
    }

    componentDidMount() {
        this.refreshData();
    }

    parseApiData = (apiData) => {
        let fData = apiData.split('\r');
        let headers = fData[0] ? fData[0].split('\f') : [];
        let data = fData.slice(1, -1);
        let count = parseInt(fData.slice(-1));
        return { headers, data, count }
    }

    parseDataSource = (headers, data) => {
        return data.map(x => {
            let y = x.split("\f");
            let res = {}
            headers.forEach((z, i) => { res = { ...res, [z]: y[i] } })
            return res;
        })
    }

    refreshData = () => {
        this.sendAPI(this.api.get, '', (ob) => {
            let dataStore = ob.data ? this.parseApiData(ob.data) : { headers: [], data: [], count: 0 }
            this.props.dataActions.setJson(this.id, dataStore)
        });

        this.sendAPI(this.api.get_s, '', (ob) => {
            this.sendAPI("get_storage_list", '', (ob1) => {
                let priorityData = ob.data ? this.parseApiData(ob.data) : { headers: [], data: [], count: 0 };
                let storages = ob1.data ? ob1.data.split("\r") : [];
                let pData = this.parseDataSource(priorityData.headers, priorityData.data)
                let sData = this.parseDataSource(["code", "name"], storages)

                let dataset = sData.map((x, index) => {
                    let key = x.code;
                    let cCotStorage = x.code;
                    let cCotName = x.name;
                    let cID = 0;
                    let cPriStorage = '';
                    let isEditing = false;

                    let findPri = pData.find(f => f.cCotStorage == cCotStorage);
                    if (findPri) {
                        cID = findPri.cID;
                        cPriStorage = findPri.cPriStorage;
                    }
                    return { key, cCotName, cID, cCotStorage, cPriStorage, isEditing, index }
                })

                this.props.dataActions.setJson(this.id_s, { dataset })
            });
        });
    }

    createNew = () => {
        let newOb = {}
        this.props.data[this.id].headers.forEach(x => { newOb = { ...newOb, [x]: '' } })
        this.setState({ modalCreateEdit: { ...newOb, cID: '0' } });
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: null });
    }

    handleOk = () => {
        let cbFields = ["cIsActive", "cOccOustHK", "cSpecOustHK", "cInvoice", "cCreditInvoice", "cReceipt", "cPayBackReceipt"];
        let strFields = ["cPriorityOrganization", "cDefCust", "cDefSpecCust"];

        let m = this.state.modalCreateEdit;
        let keys = Object.keys(m);
        let dataSend = keys.join('\f') + '\r' +
            keys.map(x => !m[x] && cbFields.indexOf(x) > -1 ? (x === 'cIsActive' ? '0' : '1') : !m[x] && strFields.indexOf(x) == -1 ? '0' : m[x]).join('\f');
        this.sendAPI(this.api.create_edit, dataSend, () => {
            this.refreshData();
            this.handleCancel();
            message.success(this.getText(15281));
        })
    }

    getField = (label, field) => {
        let gt = this.getText;
        let m = this.state.modalCreateEdit ? this.state.modalCreateEdit : {}
        let value = m[field];
        let onChange = (v) => { this.setState({ modalCreateEdit: { ...m, [field]: v } }) }

        switch (field) {
            case 'cPosId':
                return (<Form.Item label={gt(label)}>
                    <GenericSelectorPosStorages value={value} onChange={onChange} {...this.props} />
                </Form.Item>);

            case 'cByPosOrBranchOrZ':
                return (<Form.Item label={gt(label)}>
                    <Select value={value} onChange={onChange} style={{ width: '100%' }}>
                        <Select.Option key={'0'} value={'0'}>{this.getText(15275)}</Select.Option>
                        <Select.Option key={'1'} value={'1'}>{this.getText(15276)}</Select.Option>
                        <Select.Option key={'2'} value={'2'}>{this.getText(15277)}</Select.Option>
                    </Select>
                </Form.Item>);

            case 'cExtraSum':
                return (<Form.Item label={gt(label)}>
                    <InputNumber value={parseInt(value) ? parseInt(value) / 100 : 0} onChange={(e) => { onChange(parseInt(e * 100)) }} precision={2} min={0} />
                </Form.Item>);

            case "cOccOustHK": case "cSpecOustHK": case "cInvoice": case "cCreditInvoice": case "cReceipt": case "cPayBackReceipt":
                return (<Form.Item><Checkbox checked={value == '0'} onChange={(e) => { onChange(e.target.checked ? '0' : '1') }}>{gt(label)}</Checkbox></Form.Item>);

            case 'cIsActive':
                return (<Form.Item><Checkbox checked={value == '1'} onChange={(e) => { onChange(e.target.checked ? '1' : '0') }}>{gt(label)}</Checkbox></Form.Item>);

            default:
                return (<Form.Item label={gt(label)}><Input value={value} onChange={(e) => { onChange(e.target.value) }} /></Form.Item>)
        }
    }

    onChangeStorageTable = (data, index) => {
        let dataset = this.props.data[this.id_s].dataset.map((x, i) => {
            return i === index ? { ...x, ...data } : x
        });

        this.props.dataActions.setJson(this.id_s, { dataset })
    }

    renderPriStorage = (text, record) => {
        if (record.isEditing) {
            return (<Input value={text} onChange={(e) => { this.onChangeStorageTable({ cPriStorage: e.target.value }, record.index) }} />)
        } else {
            return text
        }
    }

    renderStorageEditCol = (text, record) => {
        if (record.isEditing) {
            return (<TableLineIcons
                onCheck={() => {
                    const { cID, cCotStorage, cPriStorage } = record;
                    const dataSend = 'cID\fcCotStorage\fcPriStorage\r' + cID + '\f' + cCotStorage + '\f' + cPriStorage;
                    this.sendAPI(this.api.create_edit_s, dataSend, this.refreshData)
                }}
                onClose={this.refreshData}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.onChangeStorageTable({ isEditing: true }, record.index) }}
                onDelete={record.cID > 0 ? () => { this.sendAPI(this.api.delete_s, 'cID\r' + record.cID, this.refreshData) } : undefined}
                deleteQuetion={{ title: this.getText(15278), okText: this.getText(15279), cancelText: this.getText(15280) }}
            />)
        }
    }

    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            //whiteSpace: "unset",
        };

        const gt = this.getText;
        const rt = (text) => text;
        const re = (text) => (<Checkbox disabled checked={text == '1'} />)
        const rp = (text) => (<GenericSelector disabled value={text} {...this.selectors[0]} {...this.props} />)
        const ri = (text, record) => {
            return (<TableLineIcons
                onEdit={() => { this.setState({ modalCreateEdit: record }) }}
                onDelete={() => { this.sendAPI(this.api.delete, 'cID\r' + record.key, this.refreshData) }}
                deleteQuetion={{ title: gt(15278), okText: gt(15279), cancelText: gt(15280) }}
            />)
        }

        let numOfRowsByHeight = parseInt((window.innerHeight - 340 > 40) ? (window.innerHeight - 340) / 40 : 1);

        const { data, headers } = this.props.data[this.id];
        const { valueSearch, valueSearchStorages } = this.state;

        let dataSource = data.map((x, index) => {
            let y = x.split("\f");
            let findPos = this.props.data[TableIDs.genericSelectorPos].dataset.find(f => f.code === y[1]);
            let obj = { key: y[0], index, cPosName: findPos ? findPos.name : "" }
            headers.forEach((z, i) => { obj = { ...obj, [z]: y[i] } })
            return obj
        }).filter(f => {
            if (!valueSearch) return true;
            if (f.cPosName.indexOf(valueSearch) > -1) return true;
            if (headers.find(ff => f[ff] && f[ff].indexOf(valueSearch) > -1)) return true;
            return false;
        })
        
        let dataSourceStorages = this.props.data[this.id_s].dataset.filter(f => {
            if (!valueSearchStorages) return true;
            if (f.cCotStorage.indexOf(valueSearchStorages) > -1) return true;
            if (f.cCotName.indexOf(valueSearchStorages) > -1) return true;
            if (f.cPriStorage.indexOf(valueSearchStorages) > -1) return true;
            return false;
        })


        return (<div style={divStyle}>
            <ResponsiveTable
                dataSource={dataSource}
                title={gt(15252)}
                actionButtons={this.actionButtons}
                genericActionButtons={[
                    <Tooltip title={gt(17640)}>
                        <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => { this.setState({ modalStorages: true }) }}>
                            <Icon style={{ fontSize: 16 }} type="setting" theme="outlined" />
                        </Button>
                    </Tooltip>
                ]}
                columns={[
                    { title: gt(15253), key: "cPosId", dataIndex: "cPosId", width: '18%', render: rp },
                    { title: gt(15254), key: "cPriorityOrganization", dataIndex: "cPriorityOrganization", width: '18%', render: rt },
                    { title: gt(15270), key: "cPriorityPosId", dataIndex: "cPriorityPosId", width: '10%', render: rt },
                    { title: gt(15255), key: "cAgent", dataIndex: "cAgent", width: '10%', render: rt },
                    { title: gt(15256), key: "cStorage", dataIndex: "cStorage", width: '10%', render: rt },
                    { title: gt(15257), key: "cBranch", dataIndex: "cBranch", width: '10%', render: rt },
                    { title: gt(15268), key: "cNumerator", dataIndex: "cNumerator", width: '10%', render: rt },
                    { title: gt(15269), key: "cIsActive", dataIndex: "cIsActive", width: '7%', render: re },
                    { title: gt(15271), width: '7%', render: ri },
                ]}
                pagination={{ pageSize: numOfRowsByHeight }}
                showsearch={valueSearch => { this.setState({ valueSearch }) }}
                // percentageToMobile={[20, 60, 0, 0, 0, 20]}
                {...this.props}
            />
            <GenericModal
                visible={this.state.modalCreateEdit !== null}
                onCancel={this.handleCancel}
                title={gt(15272)}
                footer={[
                    <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{gt(15274)}</Button>,
                    <Button key={"create"} type="primary" onClick={this.handleOk} style={{ margin: 5 }}>{gt(15273)}</Button>
                ]}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 6 : 24} style={{ paddingLeft: 30 }}>
                        {this.getField(15253, "cPosId")}
                        {this.getField(15254, "cPriorityOrganization")}
                        {this.getField(15270, "cPriorityPosId")}
                        {this.getField(15255, "cAgent")}
                    </Col><Col span={window.innerWidth > 600 ? 6 : 24} style={{ paddingLeft: 30 }}>
                        {this.getField(15256, "cStorage")}
                        {this.getField(15257, "cBranch")}
                        {this.getField(15258, "cExtraSum")}
                        {this.getField(15259, "cByPosOrBranchOrZ")}
                    </Col><Col span={window.innerWidth > 600 ? 4 : 24} style={{ paddingLeft: 30 }}>
                        {this.getField(15266, "cDefCust")}
                        {this.getField(15267, "cDefSpecCust")}
                        {this.getField(15268, "cNumerator")}
                        {this.getField(15269, "cIsActive")}
                    </Col><Col span={window.innerWidth > 600 ? 8 : 24} style={{ paddingLeft: 30 }}>
                        {this.getField(15260, "cOccOustHK")}
                        {this.getField(15261, "cSpecOustHK")}
                        {this.getField(15262, "cInvoice")}
                        {this.getField(15263, "cCreditInvoice")}
                        {this.getField(15264, "cReceipt")}
                        {this.getField(15265, "cPayBackReceipt")}
                    </Col>
                </Row>
            </GenericModal>
            <GenericModal
                visible={this.state.modalStorages}
                onCancel={() => { this.setState({ modalStorages: false }) }}
                title={gt(17641)}
                actionButtons={[{ buttonType: 'refresh', buttonFunction: this.refreshData }]}
                showsearch={v => { this.setState({ valueSearchStorages: v }) }}
                width={1000}>
                <ResponsiveTable
                    tableOnly
                    dataSource={dataSourceStorages}
                    columns={[
                        { title: gt(17642), key: "cCotStorage", dataIndex: "cCotStorage", width: '15%', render: rt },
                        { title: gt(17643), key: "cCotName", dataIndex: "cCotName", width: '35%', render: rt },
                        { title: gt(17644), key: "cPriStorage", dataIndex: "cPriStorage", width: '35%', render: this.renderPriStorage },
                        { title: gt(17645), width: '15%', render: this.renderStorageEditCol },
                    ]}
                    pagination={{ pageSize: numOfRowsByHeight }}

                />
            </GenericModal>
        </div>)
    }
}
export default Priority;
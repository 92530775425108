import React, { Component } from "react";
import "../App.css";
import { Form, Select, Button, Modal } from 'antd';
import LangContext from "../contextProvider/LangContext";
import TableIDs from "../data/TableIDs";
import ReportFavoriteSave from "./ReportFavoriteSave";

import { Job, User, OutputType, ProcessType } from '../JmReact';
import ReportShowButton from "./ReportShowButton";

//import { Document, Page,  } from  '@react-pdf/renderer'
//import { pdfjs,Document, Page } from 'react-pdf'
//import { Document } from 'react-pdf/dist/entry.noworker';

//const pdfjsLib = require("pdfjs-dist");

//import pdfjsLib from '/pdfjs-dist/legacy'; 

// import pdfjsLib from 'pdfjs-dist'; 
// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
const pdfjsLib = null;

const { Option } = Select;

type Props = {
    ActionQueue: Object,
    dataActions: Object,
    user: Object,
    params: Object,
    xlsReportName: String,
    disabled: Boolean,
    branchesField: String,
    storagesField: String,
    posField: String,
    encodeURIFields: Array,
    favoriteDates: Boolean,
    datesToFavorites: [
        { field: String, label: String, format: Number }
    ],
    validSubmit: Function,
}

class ReportTypeButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: "pdf",
            isReportModalShown: false,
            url: null,
            pageNumber: 1,
            numberOfPages: 1,
            loading: false,
        }
        // this.id = this.getRandomInt();


        if (props.posField && props.data[TableIDs.genericSelectorPos] === undefined) props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        if (props.storagesField && props.data[TableIDs.genericSelectorStorages] === undefined) props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        if (props.branchesField && props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };
    }


    static contextType = LangContext;

    // getRandomInt = () => {
    //     const max = 1000000;
    //     return Math.floor(Math.random() * max);
    // }

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        if (this.props.params && this.props.params.reportName && this.props.params.reportName.indexOf('.') > -1) {
            this.setState({ type: this.props.params.reportName.split('.')[1] })
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.reportName !== this.props.params.reportName && this.props.params.reportName) {
            let type = this.props.params.reportName.split('.')[1];
            this.setState({ type })
        }
    }

    getReportParams = () => {
        let params = this.props.params;
        if (params) {
            let reportName = params.reportName ? params.reportName.split('.')[0] + "." + this.state.type : '';
            if (this.props.xlsReportName && this.state.type === "xls") reportName = this.props.xlsReportName;

            params = { ...params, REPORT_LOCALE: this.getText(101), reportName }

            if (params._words) params = { ...params, _words: encodeURIComponent(params._words) }

            if (this.props.encodeURIFields) {
                this.props.encodeURIFields.forEach(x => {
                    params = { ...params, [x]: encodeURIComponent(params[x]) }
                })
            }

            if (this.props.branchesField && !params[this.props.branchesField]) {
                params = { ...params, [this.props.branchesField]: this.props.data[TableIDs.genericSelectorBranches].dataset.map(x => x.code).join(',') }
            }

            if (this.props.storagesField && !params[this.props.storagesField]) {
                params = { ...params, [this.props.storagesField]: this.props.data[TableIDs.genericSelectorStorages].dataset.map(x => x.code).join(',') }
            }

            if (this.props.posField && !params[this.props.posField]) {
                params = { ...params, [this.props.posField]: this.props.data[TableIDs.genericSelectorPos].dataset.map(x => x.code).join(',') }
            }

        }

        return params;
    }

    generateReport = () => {
        setTimeout(() => {
            let isOpenModalItems = this.props.ui.modalStack.includes(TableIDs.SelectorItemsModal)
            let params = this.getReportParams();
            let validSubmit = this.props.validSubmit ? this.props.validSubmit() : true
            if (params !== null && params.reportName && validSubmit && !isOpenModalItems) {
                if (this.props.generateXlsxReport && this.state.type == "xls") this.props.generateXlsxReport(params)
                else this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
            }
        }, 500);
    }


    // renderPage = (url) => {
    //     //const dejavuFont = new FontFace('DejaVu Sans', 'url(fonts/fft/DejavuSans.ttf)');
    //     //dejavuFont.load().then(() => {
    //     //document.fonts.add(dejavuFont);
    //     //     });
    //     let loadingTask = pdfjsLib.getDocument({
    //         url: url,
    //         //cMapUrl: '../../node_modules/pdfjs-dist/cmaps/',
    //         //cMapPacked: true
    //     })

    //     loadingTask.promise.then((pdf) => {
    //         // Fetch the first page
    //         const numPages = pdf.numPages;
    //         const pageNumber = this.state.pageNumber;

    //         this.setState({ pageNumber: pageNumber, numberOfPages: numPages });

    //         pdf.getPage(pageNumber).then((page) => {
    //             console.log('Page loaded');
    //             var container = document.getElementById(this.get_container_id());
    //             var canvas = document.getElementById(this.get_canvas_id());
    //             var context = canvas.getContext('2d');

    //             //context.letterSpacing = "10px";

    //             var scale = container.clientWidth / page.getViewport({ scale: 1.0 }).width;
    //             var viewport = page.getViewport({ scale: scale });
    //             // Prepare canvas using PDF page dimensions
    //             canvas.height = viewport.height;
    //             canvas.width = viewport.width;
    //             // Render PDF page into canvas context
    //             var renderContext = {
    //                 canvasContext: context,
    //                 viewport: viewport
    //             };
    //             var renderTask = page.render(renderContext);
    //             renderTask.promise.then(function () {
    //                 console.log('Page rendered');
    //             });
    //         });
    //     }, function (reason) {
    //         // PDF loading error
    //         console.error(reason);
    //     })



    // }

    // showReport = () => {
    //     let params = this.getReportParams();
    //     let validSubmit = this.props.validSubmit ? this.props.validSubmit() : true
    //     if (params !== null && params.reportName && validSubmit) {
    //         //this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
    //         const succCallback = (response: ReportBlob) => {
    //             const data = response.blob;
    //             let blob;

    //             if (this.state.type === "pdf") {
    //                 blob = data.slice(0, data.size, "application/pdf");
    //             } else if (this.state.type === "xls") {
    //                 blob = data.slice(0, data.size, "application/vnd.ms-excel");
    //             } else if (this.state.type === "docx") {
    //                 blob = data.slice(0, data.size, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
    //             } else {
    //                 blob = data;
    //             }
    //             const url = window.URL.createObjectURL(blob);
    //             this.setState({ url, isReportModalShown: true, loading: false })
    //         }

    //         const errCallback = () => {
    //             const { url } = this.state;
    //             if (url) { window.URL.revokeObjectURL(url); }
    //             this.setState({ isReportModalShown: false, url: null, loading: false })
    //         }
    //         if (!this.state.loading) {
    //             this.setState({ loading: true }, () => {
    //                 let user = new User(this.props.user.token, this.props.user.companyCode);
    //                 let request = new Job(user, "generate_report", OutputType.OUTPUT_TYPE_REPORT_A4, ProcessType.PROCESS_TYPE_SYNC);
    //                 request.setInput(params);
    //                 request.send("/cgi-bin/CashOnTab", succCallback, errCallback);
    //             })
    //         }
    //     }

    // }

    // showReportModal = () => {
    //     this.showReport()
    // }

    // get_canvas_id = () => {
    //     return `canvas_${this.id}`;
    // }

    // get_container_id = () => {
    //     return `container_${this.id}`;
    // }


    // nextPage = () => {
    //     const { pageNumber, numberOfPages, url } = this.state;
    //     let to = pageNumber + 1;
    //     if (to > numberOfPages) { to = numberOfPages; }
    //     if (to < 1) { to = 1; }
    //     this.setState({ pageNumber: to },
    //         () => { this.renderPage(url) });
    // }

    // prevPage = () => {
    //     const { pageNumber, numberOfPages, url } = this.state;
    //     let to = pageNumber - 1;
    //     if (to > numberOfPages) { to = numberOfPages; }
    //     if (to < 1) { to = 1; }
    //     this.setState({ pageNumber: to },
    //         () => { this.renderPage(url) });
    // }

    render() {

        console.log("url", this.state.url)

        // const nav = () => {
        //     const { pageNumber, numberOfPages } = this.state
        //     const style = { marginLeft: "8px" };
        //     return (<div style={{ display: "flex", justifyContent: "center" }}>
        //         <Button style={style} onClick={this.prevPage}>{"<"}</Button>
        //         <Button style={style} onClick={this.nextPage}>{">"}</Button>
        //         <p style={{ fontSize: "16px", verticalAlign: "middle", ...style }}>{`עמוד ${pageNumber} מתוך ${numberOfPages}`}</p>
        //     </div>)
        // }

        const optionList = this.props.xlsOnly ?
            <Option key={"xls"}>{this.getText(12109)}</Option>
            : [
                <Option key={"pdf"}>{this.getText(12108)}</Option>,
                <Option key={"xls"}>{this.getText(12109)}</Option>,
                <Option key={"docx"}>{this.getText(12110)}</Option>
            ]

        return (<span>
            <Form.Item key={"reportName"} label={this.getText(12107)}>
                <Select style={{ width: 250 }}
                    value={this.state.type}
                    onChange={(value) => { this.setState({ type: value }, () => { if (this.props.onChange) this.props.onChange(value) }) }}
                >{optionList}</Select>
            </Form.Item>
            <Form.Item key={"submit"}>
                <Button type={"primary"} disabled={this.props.disabled} loading={this.props.ui.workInProgress||this.props.loading} onClick={this.generateReport}>{this.getText(12111)}</Button>
                <ReportShowButton
                    {...this.props}
                    params={this.getReportParams()}
                    disabled={this.props.disabled || this.state.type !== "pdf"}
                />
                {/* <Button type={"primary"} disabled={this.props.disabled || this.state.type !== "pdf"} loading={this.state.loading} onClick={this.showReportModal} style={{ marginRight: 4 }}>
                    {this.getText(18904)}</Button> */}
                {this.props.favoriteSave ? (<ReportFavoriteSave {...this.props} params={this.getReportParams()} />) : ""}
            </Form.Item>

            {/* <Modal
                visible={this.state.isReportModalShown}
                title={false} footer={false}
                width={window.innerWidth > 1200 ? 1200 : '96%'}
                onCancel={() => {
                    const { url } = this.state;
                    if (url) { window.URL.revokeObjectURL(url); }
                    this.setState({ isReportModalShown: false, url: null })
                }} >
                <div style={{ height: window.innerWidth > 1200 ? window.innerHeight - 150 : window.innerHeight - 20 }}>
                    <iframe style={{ position: "relative", height: "100%", width: "100%" }}
                        src={(this.state.url) ? this.state.url : "about:blank"}></iframe>
                </div>
            </Modal> */}

        </span>)
    }
}

export default ReportTypeButton;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import JournalEntriesFormat from "./JournalEntriesFormat";


class LoadfncFormat extends Component {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(18357);
    }

    render() {

        return (<JournalEntriesFormat
            {...this.props}
            typeFormat="loadfnc"
            title={this.getText(18358)}
        />)
    }

}

export default LoadfncFormat;
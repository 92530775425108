/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Form, Select, Checkbox, message, Switch } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import SelectorItems from "../../components/SelectorItems";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import { disabledDatePicker } from "../../utils/utils";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesGroup extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            reportType: "salesAvg",
            reportFormat: ".pdf",
            SalesAvg: {
                ApplyRangeApplyItemCode: 0,
                ApplyRangeLowerItemCode: 0,
                ApplyRangeUpperItemCode: 0,
                ApplyRangeApplyItemName: 0,
                ApplyRangeLowerItemName: '',
                ApplyRangeUpperItemName: '',
                ApplyRangeApplySupplierCode: 0,
                ApplyRangeLowerSupplierCode: 0,
                ApplyRangeUpperSupplierCode: 0,
                ApplyRangeApplySupplierName: 0,
                ApplyRangeLowerSupplierName: '',
                ApplyRangeUpperSupplierName: '',
                ItemGroupList: '',
                StorageList: '',
                ItemTagList: '',
                PosList: '',
                LowerSelectionDate: null,
                UpperSelectionDate: null,
                IsActive: '-1',
                SortField: 'code',
                ShowPricesWithTax: 0,
                TagRelation: '1',
                isByFatherItem: 0,
                _withSendingDocs: '1',
                mCustTagList: "",
                reportName: "reports/salesAvg.pdf",
            }
        };

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        //if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
        //    props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        //}
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        //if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
        //    props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        //}
        //if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
        //    props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        //}
        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
            check: "check_all_item_code",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);
        //this.setReportParameters("SalesAvg", { mRequestedReport: 0 });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
        //this.setReportParameters("SalesAvg", { mDaysOfWeek: [1, 2, 3, 4, 5, 6, 7] });
        //const list = this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',');
        //this.setReportParameters("SalesAvg", { mPosList: list });
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.PosList === '') params = { ...params, PosList: fullPosList }
        if (params.StorageList === '') params = { ...params, StorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10199);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            posList && posList.dataset[0]
            &&
            storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            let reportName = this.props.ui.favoriteParams.reportName.split('.');
            let reportType = reportName[0] ? reportName[0].split('/')[1] : '';
            let reportFormat = '.' + reportName[1];
            this.setState({ reportType, reportFormat }, () => {
                this.setReportParameters("SalesAvg", this.props.ui.favoriteParams)
            })
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        });
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.SalesAvg.LowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.SalesAvg.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("SalesAvg", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('LowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('UpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/

    createCheckBox = (field, data) => {
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setReportParameters("SalesAvg", { [field]: data });
                else
                    this.setReportParameters("SalesAvg", { [field]: '' });
            }} />);
    }

    checkIfItemExist = (s, m) => {
        if (s) {
            let p = m ? "\f0\f2" : "\f1\f0";
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + p;
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let itemsRows = (ob.data).split("\r");
                    let _ItemCode = itemsRows[0].split('\f')[1];
                    // //console.log('yuda items selector ', itemsRows, _ItemCode)
                    if (!_ItemCode || itemsRows.length > 1) {
                        this.props.uiActions.showModal(TableIDs.SelectorItemsModal/*, modalParamObj*/);
                    } else {

                        this.onChangeItemCode(_ItemCode)
                    }
                },
                (error) => {
                    message.error(this.getText(12034));
                }
            );
        }
    }

    keyboardListener(e) {
        let m = this.state.SalesAvg.isByFatherItem == 1 ? true : false;
        ////console.log('yuda test ', this.barcodeReaderBuffer, m);
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.onChangeItemCode('');
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                if (this.barcodeReaderBuffer || this.isBufferFull === true) this.checkIfItemExist(this.barcodeReaderBuffer, m)
                break;

            default: break;
        }
    }


    onChangeItemCode = (code) => {
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            this.setReportParameters("SalesAvg", {
                ApplyRangeApplyItemCode: code ? 1 : 0,
                ApplyRangeLowerItemCode: code ? code : 0,
                ApplyRangeUpperItemCode: code ? code : 0,
            })
        }
    }

    sortFromItem = (label, isMatrix) => {

        return (<FormItem label={label}>
            <SelectorItems
                doFocus={true}
                user={this.props.user}
                uiActions={this.props.uiActions}
                ui={this.props.ui}
                dataActions={this.props.dataActions}
                data={this.props.data}
                ActionQueue={this.props.ActionQueue}
                ctrlBKeyFlag={null}
                isExpandMatrixFatherItem={false}
                itemType={isMatrix ? 2 : 1}
                value={this.state.SalesAvg.ApplyRangeApplyItemCode == 1 ? this.state.SalesAvg.ApplyRangeLowerItemCode : ''}
                onChangeSearch={(s) => { this.onChangeItemCode(s.mCode) }}
                onChange={(s) => { this.onChangeItemCode(s.target.value) }}
                onBlur={(s) => { this.checkIfItemExist(s.target.value, isMatrix) }}
                onKeyDown={this.keyboardListener.bind(this)}
            />
        </FormItem>)
    }


    render() {
        const { SalesAvg, endOpen } = this.state;
        const { LowerSelectionDate, LowerSelectionTime,
            UpperSelectionDate, UpperSelectionTime } = SalesAvg;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { SalesAvg } = this.state;

        //const isSumShownStyle = { display: (SalesAvg.mRequestedReport !== 3) ? 'block' : 'none' }

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listItemTags = Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", []).map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listSuppliers = Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });


        //this.setReportParameters("SalesAvg", {
        //    mPosList: this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',')
        //});

        let mobile = window.innerWidth > 600 ? false : true;

        let encodeURIFields = [
            'ApplyRangeLowerItemCode',
            'ApplyRangeUpperItemCode',
            'ApplyRangeLowerItemName',
            'ApplyRangeUpperItemName',
        ]

        if (this.state.SalesAvg.TagRelation == '2') encodeURIFields.push("ItemTagList")


        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(12035)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '80%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(14161)}>
                            <Select style={{ width: 220 }}
                                value={this.state.reportType}
                                onChange={(value) => {
                                    this.setState({ reportType: value }, () => {
                                        this.setReportParameters("SalesAvg", {
                                            reportName: "reports/" + value + this.state.reportFormat
                                        })
                                    })
                                }}
                            >
                                <Option key={"salesAvg"}>{this.getText(14159)}</Option>
                                <Option key={"salesStoragesAvg"}>{this.getText(14160)}</Option>
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(12036)}>
                            <Switch checkedChildren={this.getText(12053)} unCheckedChildren={this.getText(12054)}
                                onChange={(e) => {
                                    this.setReportParameters("SalesAvg", {
                                        isByFatherItem: (e) ? 1 : 0,
                                        ApplyRangeApplyItemCode: 0,
                                        ApplyRangeLowerItemCode: 0,
                                        ApplyRangeUpperItemCode: 0,
                                    });
                                }}
                                checked={(this.state.SalesAvg.isByFatherItem == 1) ? true : false} />
                        </FormItem>

                        {
                            (this.state.SalesAvg.isByFatherItem == 1) ?
                                this.sortFromItem(this.getText(12037), true) :
                                this.sortFromItem(this.getText(12038), false)
                        }

                        <FormItem label={this.getText(12039)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                            <DatePicker
                                // disabledDate={this.disabledStartDate}
                                disabledDate={d => disabledDatePicker(d, null, SalesAvg.UpperSelectionDate)}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={SalesAvg.LowerSelectionDate ? moment(SalesAvg.LowerSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12055)}
                                onChange={this.onStartChange}
                            //onOpenChange={this.handleStartOpenChange}
                            />

                        </FormItem>
                        <FormItem label={this.getText(12040)} /*style={isEndDateShownStyle}*/>
                            <DatePicker
                                disabledDate={d => disabledDatePicker(d, SalesAvg.LowerSelectionDate, null)}
                                // disabledDate={this.disabledEndDate}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                //value={ToDate ? moment(ToDate, "YYYY-MM-DD") : null}
                                value={SalesAvg.UpperSelectionDate ? moment(SalesAvg.UpperSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12056)}
                                onChange={this.onEndChange}
                            //open={endOpen}
                            //onOpenChange={this.handleEndOpenChange}
                            />
                        </FormItem>

                        <FormItem>
                            <CheckboxIncludeSendingDocs
                                value={this.state.SalesAvg._withSendingDocs}
                                onChange={e => { this.setReportParameters("SalesAvg", { _withSendingDocs: e }) }}
                            />
                            {/* <Checkbox
                                checked={this.state.SalesAvg._withSendingDocs == 1}
                                onChange={e => { this.setReportParameters("SalesAvg", { _withSendingDocs: e.target.checked ? 1 : 0 }) }}
                            >{this.getText(16615)}</Checkbox> */}
                        </FormItem>


                        <FormItem label={this.getText(12041)} >
                            {/* {this.createCheckBox('mSupplierList', Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => obj['code']).join(','))
                            } */}
                            {/* <Select key={"mSupplierList"} style={{ width: '90%' }}
                                showSearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                //mode="multiple"
                                //maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesAvg", {
                                    ApplyRangeApplySupplierCode: value ? 1 : 0,
                                    ApplyRangeLowerSupplierCode: value ? value : 0,
                                    ApplyRangeUpperSupplierCode: value ? value : 0,
                                })}
                                value={(this.state.SalesAvg.ApplyRangeApplySupplierCode === 0) ? '' : this.state.SalesAvg.ApplyRangeLowerSupplierCode}
                            >
                                {listSuppliers}
                            </Select> */}
                            <GenericSelectorSuppliers
                                {...this.props}
                                notApi
                                onChange={(value) => this.setReportParameters("SalesAvg", {
                                    ApplyRangeApplySupplierCode: value ? 1 : 0,
                                    ApplyRangeLowerSupplierCode: value ? value : 0,
                                    ApplyRangeUpperSupplierCode: value ? value : 0,
                                })}
                                value={(this.state.SalesAvg.ApplyRangeApplySupplierCode == 0) ? '' : this.state.SalesAvg.ApplyRangeLowerSupplierCode}
                            />
                        </FormItem>
                        <FormItem label={this.getText(12042)}>
                            <GenericSelectorPosStorages  {...this.props} multi
                                onChange={(value) => this.setReportParameters("SalesAvg", { PosList: value.join(',') })}
                                value={(this.state.SalesAvg.PosList === '') ? [] : this.state.SalesAvg.PosList.split(',')}
                            />
                            {/* {this.createCheckBox('PosList', Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"PosList"} style={{ width: '90%' }}
                                showSearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesAvg", { PosList: value.join(',') })}
                                value={(this.state.SalesAvg.PosList === '') ? [] : this.state.SalesAvg.PosList.split(',')}
                            >
                                {listPos}
                            </Select> */}
                        </FormItem>
                        {/* <FormItem label={this.getText(12043)}>
                            {this.createCheckBox('StorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"StorageList"} style={{ width: '90%' }}
                                showSearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesAvg", { StorageList: value.join(',') })}
                                value={(this.state.SalesAvg.StorageList === '') ? [] : this.state.SalesAvg.StorageList.split(',')}
                            >
                                {listStorage}
                            </Select>
                        </FormItem> */}
                        <FormItem label={this.getText(12044)}>
                            {/* {
                                this.createCheckBox('ItemTagList', Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", [])
                                    .reduce((acc, i) => {
                                        //console.log("acc: ", acc);
                                        return acc.concat((i.tags.map(j => j.id)));
                                    }, [])
                                    .join(',')
                                )
                            }
                            <Select key={"ItemTagList"} style={{ width: '90%' }}
                                showSearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesAvg", { ItemTagList: value.join(',') })}
                                value={(this.state.SalesAvg.ItemTagList === '') ? [] : this.state.SalesAvg.ItemTagList.split(',')}
                            >
                                {listItemTags}
                            </Select> 
                            <Switch checkedChildren={this.getText(12057)} unCheckedChildren={this.getText(12058)} 
                            <GenericSelectorItemTags {...this.props} multi
                                onChange={(value) => this.setReportParameters("SalesAvg", { ItemTagList: value.join(',') })}
                                value={(this.state.SalesAvg.ItemTagList === '') ? [] : this.state.SalesAvg.ItemTagList.split(',')}
                            />
                            <TagRelationRadio showed={this.state.SalesAvg.ItemTagList}
                                checked={this.state.SalesAvg.TagRelation == 1}
                                onChange={(value) => this.setReportParameters("SalesAvg", { TagRelation: (value === true) ? 1 : 0 })}
                            //checked={}
                            />*/}

                            <GenericSelectorItemTagsWithRelation {...this.props} notApi
                                value={this.state.SalesAvg.ItemTagList}
                                onChange={(value) => { this.setReportParameters("SalesAvg", { ItemTagList: value }) }}
                                tagRelationValue={this.state.SalesAvg.TagRelation}
                                tagRelationOnChange={(value) => {
                                    let ItemTagList = this.state.SalesAvg.ItemTagList;
                                    let from = this.state.SalesAvg.TagRelation;
                                    let to = value.target.value;
                                    let oldJson = ItemTagList.indexOf("{") > -1 ? JSON.parse(ItemTagList) : {}
                                    if (from == "2") ItemTagList = oldJson.tagList1 ?? ""
                                    if (to == "2") ItemTagList = JSON.stringify({ tagList1: ItemTagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                                    this.setReportParameters("SalesAvg", { TagRelation: to, ItemTagList })
                                }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(19246)}>
                            <GenericSelectorCustTags
                                {...this.props}
                                multi
                                value={this.state.SalesAvg.mCustTagList ? this.state.SalesAvg.mCustTagList.split(",") : []}
                                onChange={(value) => { this.setReportParameters("SalesAvg", { mCustTagList: value ? value.join(",") : "" }) }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(12045)}>
                            <Select key={"IsActive"} style={{ width: 220 }}
                                value={SalesAvg.IsActive}
                                onChange={(value) => { this.setReportParameters("SalesAvg", { IsActive: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'-1'}>-</Option>
                                <Option key={2} value={'1'}>{this.getText(12059)}</Option>
                                <Option key={3} value={'0'}>{this.getText(12060)}</Option>
                            </Select>

                        </FormItem>
                        <FormItem label={this.getText(12046)}>
                            <Select key={"SortField"} style={{ width: 220 }}
                                value={SalesAvg.SortField}
                                onChange={(value) => { this.setReportParameters("SalesAvg", { SortField: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'code'}>{this.getText(12061)}</Option>
                                <Option key={2} value={'name'}>{this.getText(12062)}</Option>
                            </Select>

                        </FormItem>
                        <FormItem label={this.getText(12047)}>
                            <Switch checkedChildren={this.getText(12059)} unCheckedChildren={this.getText(12060)}
                                checked={this.state.SalesAvg.ShowPricesWithTax == 1}
                                onChange={(value) => this.setReportParameters("SalesAvg", { ShowPricesWithTax: (value === true) ? 1 : 0 })} />
                        </FormItem>
                        <ReportTypeButton
                            {...this.props}
                            params={this.state.SalesAvg}
                            storagesField={'StorageList'}
                            tagsField={'ItemTagList'}
                            posField={'PosList'}
                            encodeURIFields={encodeURIFields}
                            datesToFavorites={[
                                { field: 'LowerSelectionDate', label: this.getText(12039) },
                                { field: 'UpperSelectionDate', label: this.getText(12040) },
                            ]}
                            favoriteSave
                            xlsReportName={this.state.SalesAvg.reportName.split(".")[0] + "Excel.xls"}
                        />
                        {/* <FormItem key={"reportName"} label={this.getText(12048)}>
                            <Select style={{ width: 220 }}
                                value={this.state.reportFormat}
                                onChange={(value) => {
                                    this.setState({ reportFormat: value }, () => {
                                        this.setReportParameters("SalesAvg", {
                                            reportName: "reports/" + this.state.reportType + value
                                        })
                                    })
                                }}
                            >
                                <Option key={".pdf"}>{this.getText(12049)}</Option>
                                <Option key={".xls"}>{this.getText(12050)}</Option>
                                <Option key={".docx"}>{this.getText(12051)}</Option>
                            </Select>
                        </FormItem>
                        <Button type={"primary"} onClick={() => {
                            this.generateReport.apply(this, ["SalesAvg"])
                        }}>{this.getText(12052)}</Button> */}
                    </Form>
                </Card>
            </div>
        );
    }
}
export default SalesGroup;
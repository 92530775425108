import React from "react";
import { Radio } from "antd";
import LangContext from "../contextProvider/LangContext";

const { Group } = Radio;

class TagRelationRadio extends React.Component {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        const { checked, onChange, showed } = this.props;
        let gt = this.getText;

        return showed && showed.indexOf(',') > -1 ?
            (<Group
                value={checked == 1 ? '1' : '0'}
                onChange={e => { onChange(e.target.value == '1') }}>
                <Radio value={'1'}>{gt(16697)}</Radio><br />
                <Radio value={'0'}>{gt(16696)}</Radio>
            </Group>)
            : ""
    }
}

export default TagRelationRadio;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Tooltip, Icon } from "antd";
import moment from "moment";
import ResponsiveTable from "../../components/ResponsiveTable";
import ModalEmployeeHours from "../../components/ModalEmployeeHours";
import LangContext from "../../contextProvider/LangContext";
import "../Dashboard.css";
import GenericModal from "../../components/GenericModal";
import CopyDocumentReport from "../reports/PaymentsSearchReport";
import { indexOf } from "lodash";

class BranchTableSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalEmployeeHours: false,
            modalDocumentCopy: false,
            lastRefreshTime: null,
            posToHour: '',
            sorterTable: {}
        }

        this.id = "DASHBOARD_COMPLETION_TABLE";
        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getParseInt = (str) => {
        return str ? parseInt(str) : 0
    }

    componentDidUpdate(p) {
        if (
            p.dashboardDate !== this.props.dashboardDate ||
            p.dashboardPos !== this.props.dashboardPos ||
            p.dDateCheck !== this.props.dDateCheck
        ) {
            this.getDataCompletionTable()
        }
    }


    initFilter = (request) => {
        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            request,
            "get_dashboard_completion_table");

        this.setState({ lastRefreshTime: moment().format('HH:mm:ss'/*'LTS'*/) });
    }

    getDataCompletionTable = () => {
        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: 20,
            // recordsPerPage: 9999,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: 20,
            // _rows_num: 9999,
            _isAsc: 0,
            _sort_by: '',
            dashboardDate: this.props.dashboardDate ? moment(this.props.dashboardDate).format("YYYY-MM-DD") : null,
            dashboardPos: this.props.dashboardPos,
            dDateCheck: this.props.dDateCheck ? moment(this.props.dDateCheck).format("YYYY-MM-DD") : null,
            isPerBranch: this.props.branchTablePerPos ? "0" : "1"
        };

        this.initFilter(request);
    }

    completionTableTableChange = (pagination, filters, sorter) => {
        let data = this.props.data[this.id];
        let col = (data && data.headers[0]) ? data.headers.indexOf(sorter.field) : 0;
        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === "ascend" ? 1 : 0,
            _sort_by: sorter.field ? sorter.field : '',
            _words: '',
            dashboardDate: moment(this.props.dashboardDate).format("YYYY-MM-DD"),
            dashboardPos: this.props.dashboardPos,
            dDateCheck: this.props.dDateCheck ? moment(this.props.dDateCheck).format("YYYY-MM-DD") : null,
            isPerBranch: this.props.branchTablePerPos ? "0" : "1"
        };

        this.initFilter(request);
    }

    createButton = (title, icon, onClick) => {
        return (<Tooltip title={this.getText(title)}>
            <Button className={"btn"} disabled={this.props.ui.workInProgress} onClick={onClick}>
                <Icon type={icon} style={{ fontSize: 16, padding: 4 }} />
            </Button>
        </Tooltip>)
    }

    renderCol = (text, record) => {
        return (record.key == '0') ? (<b>{text}</b>) : text
    }


    render() {
        let mobile = window.innerWidth <= 600;

        let tPerPos = this.props.branchTablePerPos;

        const gt = this.getText;
        const sorter = true;
        const render = this.renderCol;
        const renderAvg = (text, record) => this.renderCol(this.getParseInt(text) ? Math.round((this.getParseInt(text) / this.getParseInt(record._lines)) * 10) / 10 : '', record)
        const renderSum = (text, record) => this.renderCol(this.getParseInt(text) ? Math.round(text) : '', record)

        const renderPosOn = (text) => {
            let cColor = 'red';
            let cTitle = gt(10106)
            switch (text) {
                case '1': cColor = '#7CFC00'; cTitle = gt(10107); break;
                case '2': cColor = '#FFD700'; cTitle = gt(10108); break;
                default: cColor = 'red';
            }
            return (mobile) ? (<span><span style={{ fontSize: 25, color: cColor }}>&bull; </span>{cTitle}</span>)
                : (<Tooltip title={cTitle}>
                    <span style={{ fontSize: 25, color: cColor }}>&bull;</span>
                </Tooltip>)
        }

        const renderPosName = (t, r) => r.key == '0' ? <b>{gt(tPerPos ? 16356 : 17260)}</b> : t


        const renderSummery = (text, record) => {
            let bgColor = record._status === '1' ? 'GreenYellow' : 'lightpink'
            return this.renderCol(<div style={{ backgroundColor: bgColor, width: '100%', height: '24px' }}>{this.getParseInt(text) ? Math.round(text) : ''}</div>, record)
        }

        const renderStatus = (text, record) => {
            let preNum = record._yesterday ? this.getParseInt(record._yesterday) : 0;
            let curNum = record._summary ? this.getParseInt(record._summary) : 0;
            let percent = (preNum ? (((curNum - preNum) / preNum) * 100) : curNum ? 100 : 0).toFixed(2);
            let colorStyle = { color: text === '1' ? '#7CFC00' : 'red' }
            let iconStr = "arrow-" + (text === '1' ? "up" : "down");
            return this.renderCol(<span style={colorStyle}><Icon type={iconStr} />{this.getParseInt(percent) + '%'}</span>, record)
        }

        const renderButton = (title, onClick, icon) => {
            return mobile ? (<Button onClick={onClick}><Icon type={icon} style={{ marginLeft: 5 }} />{title}</Button>)
                : (<Tooltip title={title}><Icon type={icon} onClick={onClick} /></Tooltip>)
        }

        const renderEmployeeButton = (text, record) => renderButton(gt(14058), () => { this.setState({ modalEmployeeHours: true, posToHour: record._filterPosList }) }, 'clock-circle')
        const renderDocButton = (text, record) => renderButton(gt(tPerPos ? 17050 : 17259), () => { this.setState({ modalDocumentCopy: true, posToHour: record._filterPosList }) }, 'file')

        const titleYesterday = gt(10073) + " " + (this.props.dDateCheck ? gt(10040) + '-' + moment(this.props.dDateCheck).format("DD/MM/YY") : gt(10074))


        const columns = [
            { title: mobile ? gt(10080) : "", dataIndex: '_posOn', key: '_posOn', width: '3%', render: renderPosOn },
            { title: gt(tPerPos ? 10081 : 17258), dataIndex: '_pos', key: '_pos', width: '8%', render: renderPosName },
            { title: gt(10082), dataIndex: '_lines', key: '_lines', sorter, width: '5%', render },
            { title: gt(10083), dataIndex: '_summary', key: '_summaryAvg', sorter, width: '7%', render: renderAvg },
            { title: gt(10084), dataIndex: '_items', key: '_items', sorter, width: '7%', render },
            { title: gt(10085), dataIndex: '_items', key: '_itemsAvg', sorter, width: '7%', render: renderAvg },
            { title: gt(10086), dataIndex: '_discountSum', key: '_discountSum', sorter, width: '7%', render: renderSum },
            { title: gt(10087), dataIndex: '_returnSum', key: '_returnSum', sorter, width: '7%', render: renderSum },
            { title: gt(10088), dataIndex: '_returnCount', key: '_returnCount', sorter, width: '7%', render },
            { title: gt(10089), dataIndex: '_employeesSum', key: '_employeesSum', sorter, width: '7%', render },
            { title: gt(19128), dataIndex: '_summaryPays', key: '_summaryPays', sorter, width: '7%', render },
            { title: gt(10090), dataIndex: '_summary', key: '_summary', sorter, width: '7%', render: renderSummery },
            { title: titleYesterday, dataIndex: '_yesterday', key: '_yesterday', sorter, width: '7%', render: renderSum },
            { title: gt(17495), dataIndex: '_monthly', key: '_monthly', sorter, width: '7%', render: renderSum },
            { title: gt(10091), dataIndex: '_status', key: '_status', width: '4%', render: renderStatus },
            { width: '3%', render: renderEmployeeButton },
            { width: '3%', render: renderDocButton }
        ];

        // let sumDataSource = {
        //     key: "Sum",
        //     _posOn: "",
        //     _status: "",
        //     _pos: gt(tPerPos ? 16356 : 17260),
        //     _discountSum: 0,
        //     _employeesSum: 0,
        //     _items: 0,
        //     _lines: 0,
        //     _returnCount: 0,
        //     _returnSum: 0,
        //     _summary: 0,
        //     _yesterday: 0,
        //     _monthly: 0,
        // }
        // let sumFields = ['_discountSum', '_employeesSum', '_items', '_lines', '_returnCount', '_returnSum', '_summary', '_yesterday', '_monthly']
        let dataSource = this.props.data[this.id] ? this.props.data[this.id].data.map((x, index) => {
            let y = x.split('\f');
            let obj = { key: y[0], index }
            this.props.data[this.id].headers.forEach((z, i) => {
                obj = { ...obj, [z]: y[i] }
                // if (sumFields.indexOf(z) > -1 && parseFloat(y[i])) sumDataSource[z] += parseFloat(y[i]);
                // if (z == '_posOn' && y[i] == '1') sumDataSource[z] = '1';
                // if (z == '_posOn' && y[i] == '2' && !sumDataSource[z]) sumDataSource[z] = '2';
            });
            return obj;
        }) : [];

        // if (this.state.sorterTable.field && this.state.sorterTable.order) {
        //     const { field, order } = this.state.sorterTable;
        //     dataSource.sort((a, b) => {
        //         if (order == 'ascend') return a[field] - b[field]
        //         if (order == 'descend') return b[field] - a[field]
        //     })
        // }

        // sumDataSource._status = sumDataSource._yesterday > sumDataSource._summary ? "-1" : sumDataSource._summary > sumDataSource._yesterday ? "1" : "0";

        // if (!tPerPos) {
        //     let bDataSource = [];
        //     dataSource.forEach(x => {
        //         let bIndex = bDataSource.findIndex(f => f._branchID == x._branchID);
        //         if (bIndex > -1) {
        //             sumFields.forEach(z => {
        //                 let _oldSum = parseFloat(bDataSource[bIndex][z]);
        //                 let _newSum = parseFloat(x[z]);
        //                 bDataSource[bIndex][z] = (_oldSum ? _oldSum : 0) + (_newSum ? _newSum : 0)
        //             });
        //             if (x._posOn == '1') bDataSource[bIndex]._posOn = '1';
        //             if (x._posOn == '2' && !bDataSource[bIndex]._posOn) bDataSource[bIndex]._posOn = '2';
        //             if (bDataSource[bIndex]._yesterday > bDataSource[bIndex]._summary) bDataSource[bIndex]._status = "-1"
        //             else if (bDataSource[bIndex]._yesterday < bDataSource[bIndex]._summary) bDataSource[bIndex]._status = "1"
        //             else bDataSource[bIndex]._status = "0"
        //             bDataSource[bIndex].key += ',' + x.key;
        //         } else {
        //             bDataSource.push({ ...x, _pos: x._branchID + ' - ' + x._branchName })
        //         }
        //     });

        //     dataSource = bDataSource
        // }

        // dataSource = [sumDataSource, ...dataSource];

        console.log('dataSource', dataSource)

        return (<div style={{ marginBottom: "24px" }}>
            <ResponsiveTable
                columns={columns}
                dataSource={dataSource}
                cardStyle={true}
                percentageToMobile={[false, 40, false, false, false, false, false, false, false, false, false, 30, false, 30]}
                title={(<div>
                    <span>{this.getText(tPerPos ? 17257 : 10138)}</span>
                    <span style={{ float: "left" }}>
                        <span style={{ marginLeft: 4 }}>{this.state.lastRefreshTime}</span>
                        {this.createButton(14057, "reload", this.getDataCompletionTable)}
                        {this.createButton(14058, "clock-circle", () => {
                            this.setState({ modalEmployeeHours: true })
                        })}
                    </span>
                </div>)}
                actionButtons={[]}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                // pagination={{ pageSize: 20 }}
                // onChange={(p, f, s) => { this.setState({ sorterTable: s }) }}
                pagination={this.props.data[this.id] ? this.props.data[this.id].pagination : { pageSize: 20 }}
                onChange={this.completionTableTableChange}
            // idTable={this.props.data[this.id]}
            />
            <ModalEmployeeHours
                visible={this.state.modalEmployeeHours}
                onCancel={() => { this.setState({ modalEmployeeHours: false }) }}
                date={moment(this.props.dashboardDate).format("YYYY-MM-DD")}
                pos={this.state.posToHour === "Sum" ? "" : this.state.posToHour}
                ui={this.props.ui} uiActions={this.props.uiActions} user={this.props.user}
                data={this.props.data} dataActions={this.props.dataActions}
            />
            <CopyDocumentReport
                {...this.props}
                isModalFromDashboard
                visible={this.state.modalDocumentCopy}
                onCancel={() => { this.setState({ modalDocumentCopy: false }) }}
                date={moment(this.props.dashboardDate).format("YYYY-MM-DD")}
                pos={this.state.posToHour === "Sum" ? "" : this.state.posToHour}
            />
        </div>);
    }
}

export default BranchTableSection;
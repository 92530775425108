/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Icon, Input, InputNumber, Select } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";

const { Option } = Select;

class MailingLinkSettings extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = { height: window.innerHeight };
        this.doOnResize = null;

        this.id = "MAILING_LINK_SETTINGS";

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.api = {
            get: "get_mailing_link_settings",
            edit: "edit_mailing_link_settings",
            selector_pre_defined_msg: "get_pre_defined_msg_list",
        }

        if (props.data[TableIDs.genericSelectorPreDefinedMsg] === undefined) {
            props.data[TableIDs.genericSelectorPreDefinedMsg] = { ...props.data.genericSelector };
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPreDefinedMsg, this.props.user.companyCode, this.props.user.token, this.api.selector_pre_defined_msg);

        this.actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(12594);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let request = {}
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    getInputEdit = (field, record) => {
        if (this.props.data[this.id].editing !== record.key) {
            return record[field];
        } else {
            let value = this.props.data[this.id].editedRecord[field];
            let number = field !== "mName" ? true : false;
            let onChange = (v) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: number ? parseInt(v) : v.target.value }) }
            return number ?
                (<InputNumber min={0} step={1} value={value} onChange={onChange} />) :
                (<Input value={value} onChange={onChange} />)
        }
    }

    getInlineEditIcons = (record) => {

        let cIcon = "clickable margin-4";
        let sIcon = { fontSize: 16 };

        if (this.props.data[this.id].editing !== record.key) {
            let onEdit = () => {
                this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index)
            }
            return (<Icon style={sIcon} className={cIcon} type={"edit"} onClick={onEdit} />)
        } else {
            let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
            let onCheck = () => {
                this.props.dataActions.genericDataSaveEdit(
                    this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
            }
            let onClose = () => {
                if (isCreate) this.props.dataActions.genericDataCancelInlineCreate(this.id, true);
                else this.props.dataActions.genericDataCancelEditing(this.id);
            }
            return (<span>
                <Icon style={sIcon} className={cIcon} type={"check"} onClick={onCheck} />
                <Icon style={sIcon} className={cIcon} type={"close"} onClick={onClose} />
            </span>)
        }
    }

    render() {
        let listPreDef = Jax.get(this.props.data, TableIDs.genericSelectorPreDefinedMsg + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let columns = [
            {
                title: this.getText(12595),
                dataIndex: "mID",
                key: "mID",
                width: '10%',
                render: (text, record) => text,
            }, {
                title: this.getText(12596),
                dataIndex: "mName",
                key: "mName",
                width: '30%',
                render: (text, record) => this.getInputEdit("mName", record)
            }, {
                title: this.getText(12597),
                dataIndex: "mMessageId",
                key: "mMessageId",
                width: '30%',
                render: (text, record) => {
                    let isEdit = this.props.data[this.id].editing === record.key ? true : false;
                    let value = isEdit ? this.props.data[this.id].editedRecord.mMessageId : text
                    let onChange = (value) => { this.props.dataActions.genericDataSetEdit(this.id, { mMessageId: value }) }
                    return (<Select
                        key={"mMessageId"} style={{ width: '80%' }} showSearch filterOption={true} optionFilterProp="valuefilter"
                        disabled={!isEdit} onChange={onChange} value={value} notFoundContent={this.getText(12602)}
                    >{listPreDef}</Select>)
                }
            }, {
                title: this.getText(12598),
                dataIndex: "mLimitedDays",
                key: "mLimitedDays",
                width: '10%',
                render: (text, record) => this.getInputEdit("mLimitedDays", record),
            }, {
                title: this.getText(12599),
                dataIndex: "mLimitedCount",
                key: "mLimitedCount",
                width: '10%',
                render: (text, record) => this.getInputEdit("mLimitedCount", record),
            }, {
                title: this.getText(12600),
                width: '10%',
                render: (text, record) => this.getInlineEditIcons(record)
            },
        ];

        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };

        return (<div style={divStyle}>
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                columns={columns}
                percentageToMobile={[false, 50, 50]}
                title={this.getText(12601)}
                actionButtons={this.actionButtons}
                pagination={false}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
        </div>)


    }
}

export default MailingLinkSettings;
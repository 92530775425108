
import React from "react";
import { Input, Modal, Table, Select } from 'antd';

import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
//import { fixCurrentPage } from "../utils/utils";

import "../style/generalUI.css";
//import { request } from "https";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const topHeight = 200; // px
const rowHeight = 40;  // px

//type State = {}
class ModalAccumulationOfAggregateCard extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = "MODAL_ACCUMULATION_OF_AGGREGATE_CARD";
        this.main_id = "CUSTOMER_AGGREGATE_CARD_LIST";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        // if (props.data[TableIDs.genericSelectorTagRelation] === undefined) {
        //     props.data[TableIDs.genericSelectorTagRelation] = { ...props.data.genericSelector };
        // }

        this.api = {
            get: "get_deals_activity_table_list",
            // create: "create_tag_type",
            // edit: "edit_tag_type",
            // delete: "delete_tag_type",
            selector: "get_tag_relation_list", // TODO: tag type categories
            // selector_tags: "get_tag_types_with_relation_list",
        }

        this.actionButtons = [{
            //     buttonType: 'add',
            //     buttonFunction: this.handleCreateNew
            // }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }/*, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/]

        this.modalData = []

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorTagRelation, this.props.user.companyCode,
            this.props.user.token, this.api.selector);
        //this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        //console.log('xxx-yuda ',numOfRowsByHeight);

        let request = {
            _id: 0,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            request,
            this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorTagRelation, this.props.user.companyCode,
        //     this.props.user.token, this.api.selector);
    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };

        let uiData = this.props.ui.data? this.props.ui.data['customerIndex']: null;
        let puiData = prevProps.ui.data? prevProps.ui.data['customerIndex']: null;

        if (uiData !== puiData || (prevProps.toggle === false && this.props.toggle === true)) {

            let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
                ? (this.state.height - topHeight) / rowHeight
                : 1);

            // console.log('xxx-yuda ',numOfRowsByHeight);

            let request = {
                _id: 0,
                page: 1,
                recordsPerPage: numOfRowsByHeight, //5,
                sortType: 1,
                sortColumn: 1,
                // api
                _page_num: 1,
                _rows_num: numOfRowsByHeight, //5,
                _isAsc: 1,
                _sort_by: 'col_1',
                _words: '',
            };

            let mainStore = this.props.data[this.main_id];

            this.modalData = mainStore && mainStore.data && mainStore.data[uiData]? mainStore.data[uiData].split("\f"):[];
                   
            if (this.modalData[0]) {
                this.props.dataActions.genericDataSetFilter(
                    this.id,
                    this.props.user.companyCode,
                    this.props.user.token,
                    { ...request, _id: this.modalData[0] },
                    this.api.get);
            }
        }
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 1
            case "dId": col--;
            case "dDate": col--;
            case "dTime": col--;
            case "dType": col--;
            case "dDocNum": col--;
            case "dSumPoints": col--;
            case "dUseSubscribers": break; // 5
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update

            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        this.setState({ visible: false });
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleClear = () => {
        //this.clear();
    }

    render() {
        const { loading } = this.state;
        let request = {

            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let modalTitleStyle = { padding: '4px', paddingLeft: '40px' }
        let space = window.innerWidth > 600 ? ' ' : (<br />)

        let modalTitle = (<span>
            <span style={modalTitleStyle}>{this.getText(11033)}</span>{space}
            <span style={modalTitleStyle}>{this.getText(11034)}: {this.modalData[1]}</span>{space}
            <span style={modalTitleStyle}>{this.getText(11035)}: {this.modalData[2]}</span>{space}
            <span style={modalTitleStyle}>{this.getText(11036)}: {this.modalData[7]}</span>
        </span>
        );

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={modalTitle}
                    actionButtons={this.actionButtons}
                    // showsearch={(val) => {
                    //     this.props.dataActions.genericDataSetFilter(
                    //         this.id, this.props.user.companyCode, this.props.user.token,
                    //         { ...request, _words: val
                    //             .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
                    //          }, this.api.get)
                    // }}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                dId: y[0],
                dDate: y[1],
                dTime: y[2],
                dType: y[3],
                dDocNum: y[4],
                dUseSubscribers: y[6],
                index: index
            })
        });

        let columns =
            [
                {
                    title: this.getText(11037),
                    dataIndex: "dId",
                    key: "dId",
                    sorter: false,
                    width: '10%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(11038),
                    dataIndex: "dDate",
                    key: "dDate",
                    width: '15%',
                    sorter: false,
                    render: (text, record) => text,
                }, {
                    title: this.getText(11039),
                    dataIndex: "dTime",
                    key: "dTime",
                    width: '15%',
                    sorter: false,
                    render: (text, record) => text,
                }, {
                    title: this.getText(11040),
                    dataIndex: "dType",
                    key: "dType",
                    width: '10%',
                    sorter: false,
                    render: (text, record) => text,
                }, {
                    title: this.getText(11041),
                    dataIndex: "dDocNum",
                    key: "dDocNum",
                    width: '15%',
                    sorter: false,
                    render: (text, record) => text,
                }, {
                    title: this.getText(11042),
                    dataIndex: "dUseSubscribers",
                    key: "dUseSubscribers",
                    width: '15%',
                    sorter: false,
                    render: (text, record) => text,
                }
            ];

      

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}
                /*footer={
                    [
                        <Button key="back"
                            onClick={this.handleCancel}>ביטול</Button>,
                        <Button key="clear"
                            loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                        <Button key="create" disabled={!this.state.isValidItemCode}
                            type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                        <Button key="createLeave" disabled={!this.state.isValidItemCode}
                            type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                    ]}*/
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >

                {/* <Table dataSource={dataSource}
                    columns={columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                /> */}
                <ResponsiveTable
                    dataSource={dataSource}
                    columns={columns}
                    // loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    tableOnly={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    percentageToMobile={[false, 30, 35, false, false, 35]}
                />
            </Modal >
        )
    }
}

export default ModalAccumulationOfAggregateCard;

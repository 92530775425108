import React, { Component } from "react";
import "../App.css";
import { DatePicker, TimePicker } from 'antd';
import moment from "moment";
import { disabledDatePicker } from "../utils/utils";

class DateTimePicker extends Component {

    render() {
        let isDate = this.props.format && this.props.format.indexOf("DD") > -1;
        let isTime = this.props.format && this.props.format.indexOf("HH") > -1;
        let showTime = this.props.showTime;
        let sqlFormat = this.props.sqlFormat ? this.props.sqlFormat : isDate && (isTime || showTime) ? "YYYY-MM-DD HH:mm:ss" : isDate ? "YYYY-MM-DD" : "HH:mm:ss";
        let minDate = isTime && this.props.minDate ? this.props.minDate.split(" ")[0] : this.props.minDate;
        let maxDate = isTime && this.props.maxDate ? this.props.maxDate.split(" ")[0] : this.props.maxDate;

        let props = {
            ...this.props,
            value: this.props.value ? moment(this.props.value, sqlFormat) : null,
            onChange: (e) => { this.props.onChange(e ? e.format(sqlFormat) : null) },
        }

        return isDate ?
            (<DatePicker {...props} disabledDate={(d) => disabledDatePicker(d, minDate, maxDate)} />)
            : (<TimePicker {...props} />)
    }
}

export default DateTimePicker;
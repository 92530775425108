/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Table, Icon, Select, InputNumber, Checkbox, Popconfirm, Button, Input, Tooltip, message } from "antd";

import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import ModalCreateEditItem from "../../components/ModalCreateEditItem";
import ModalAdvanceSearch from "../../components/ModalAdvanceSearch";
import { fixCurrentPage, escapeRegExp } from "../../utils/utils";
import ModalCreateEditMatrixItem from "../../components/ModalCreateEditMatrixItem";
import ModalCreateEditFatherItem from "../../components/ModalCreateEditFatherItem";
import ModalMatrix from "../../components/ModalMatrix";
import LangContext from "../../contextProvider/LangContext";
import ModalSalesAlert from "../../components/ModalSalesAlert";
import GenericModal from "../../components/GenericModal";
import RecyclingBin from "../../components/RecyclingBin";

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class MatrixItemsTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "MATRIX_ITEMS_TABLE";

        this.id_item = 'ITEM_CREATE_EDIT';
        this.id_priceList = 'ITEM_PRICE_LIST_CREATE_EDIT';
        this.id_suppliers = 'ITEM_SUPPLIERS_CREATE_EDIT';
        this.id_tags = "ITEM_TAGS_CREATE_EDIT";
        this.fathers_id = "GENERIC_SELECTOR_FATHER_ITEMS";
        this.recycling_id = "MATRIX_ITEMS_DELETION_RECYCLING";


        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,


            filterActive: false,
            valueSearch: '',

            modalPrintItems: false,
            modalPrintItemsSearch: '',

            modalSonsTriggerOpen: false,
        };
        this.state.isValidItemCode = false;
        this.state.searchFilter = '';
        this.state.mParentItemCode = null;

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }
        if (props.data[this.id_item] === undefined) { props.data[this.id_item] = { ...props.data.genericData }; }
        if (props.data[this.id_priceList] === undefined) { props.data[this.id_priceList] = { ...props.data.genericData }; }
        if (props.data[this.id_suppliers] === undefined) { props.data[this.id_suppliers] = { ...props.data.genericData }; }
        if (props.data[this.id_tags] === undefined) { props.data[this.id_tags] = { ...props.data.genericData }; }
        if (props.data[this.fathers_id] === undefined) { props.data[this.fathers_id] = { ...props.data.genericSelector }; }
        if (props.data[this.recycling_id] === undefined) props.data[this.recycling_id] = { ...props.data.genericData };


        this.api = {
            get: "get_matrix_items_table",
            create: "",
            edit: "",
            delete: "delete_item",
            selector: "",
            //modalPriceListGet: "",
            get_recycling: "get_matrix_items_deletion_recycling",
            restoration: "restoration_items",

        }


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - פריטי מטריצה";
        //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        //this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(prev) {
        document.title = "Cash On Tab - " + this.getText(10180);


        // let curFilter = this.props.data[this.id].filters ? this.props.data[this.id].filters._filter : "";
        // let prevFilter = prev.data[this.id].filters ? prev.data[this.id].filters._filter : "";
        // if (prevFilter !== curFilter) {
        //     this.setState({ valueSearch: curFilter })
        // }

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 12;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mId": col--;
            case "mCode": col--;
            case "mBarCode": col--;
            case "mShortName": col--;
            case "mIsActive": col--;
            case "mIsByWeight": col--;
            case "mIsSold": col--;
            case "mIsPriceFixed": col--;
            case "mIsDiscountProhibited": col--;
            case "mPrice": col--;
            case "mIsForInventory": col--;
            case "mIsSerialize": break; // 9
            default:
                col = 0;
        }

        console.log("pagination: ", pagination, "sorter: ", sorter, "\ncol: ", col);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //sortColumn: 'col_1',
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0,
            // ui
            page: 1,
            recordsPerPage: /*5,*/numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            //dataObject: this.id,
            // api
            _filter: '',
            _page_num: 1,
            _rows_num: /*5,*/numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            _tagList: '',
            _isAll: '',
            _isActive: '',
            _isWeightable: '',
            _isFixedPrice: '',
            _isForSale: '',
            _isDiscount: '',
            _saleAlert: '',
            _supplierList: '',

            _isForInventory: '',
            _isSerialize: '',
            _taxType: '',
            _notAssociatedTag: '0',
            _notAssociatedSupp: '0',
            _rulerList: '',
            _notAssociatedRuler: '0',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

        this.setState({ filterActive: false, valueSearch: "" })

    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal === 1 || checkedVal === "1");
    }

    checkboxTable = (title, key) => {
        return {
            title: title,
            dataIndex: key,
            key: key,
            sorter: false,
            width: '5%',
            render: (text, record) => (<Checkbox checked={text > 0} disabled={true} />)
        }
    }

    modalPrintItemsOpen = () => {
        this.setState({ modalPrintItems: true }, () => {
            this.props.dataActions.genericSelectorRefreshDataset(
                this.fathers_id, this.props.user.companyCode, this.props.user.token, "get_father_items_list")
        })
    }

    printItems = () => {
        let _fatherItemsList = this.props.data[this.fathers_id].dataset
            .filter(f => f.checked)
            .map(x => x.code)
            .join(',');

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                {
                    _fatherItemsList,
                    _user: this.props.user.userId,
                    REPORT_LOCALE: this.getText(101),
                    reportName: "reports/MatrixItems.xls",
                }
            ]
        });
    }

    clearCheckedModalPrintItems = () => {
        let dataset = this.props.data[this.fathers_id].dataset.map(x => { return { ...x, checked: false } })
        this.props.dataActions.setJson(this.fathers_id, { dataset })
    }

    modalEditCheckbox = (value, record) => {
        let modalData = this.props.data[this.fathers_id].dataset;
        return (<Checkbox
            checked={value === 'all' ? !modalData.filter(this.filterSearch).find(f => !f.checked) : value}
            onChange={e => {
                let dataset = modalData.map(x => {
                    if ((value !== 'all' && record.code !== x.code) || !this.filterSearch(x)) return x;
                    else return { ...x, checked: e.target.checked }
                })
                if (dataset.filter(f => f.checked).length > 50) message.error(this.getText(17428))
                else this.props.dataActions.setJson(this.fathers_id, { dataset })
            }}
        />)
    }

    filterSearch = (f) => {
        let s = this.state.modalPrintItemsSearch;
        return !s || f.code.indexOf(s) > -1 || f.name.indexOf(s) > -1
    }

    render() {


        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            //whiteSpace: "unset",
        };

        let columns = [
            {
                title: this.getText(10565),
                dataIndex: "mCode",
                key: "mCode",
                sorter: true,
                width: '15%',
                render: (text, record) => text,
            }, {
                title: this.getText(10566),
                dataIndex: "mBarCode",
                key: "mBarCode",
                sorter: true,
                width: '15%',
                render: (text, record) => text,
            }, {
                title: this.getText(10567),
                dataIndex: "mShortName",
                key: "mShortName",
                sorter: true,
                width: '15%',
                render: (text, record) => text,
            }, {
                title: this.getText(10568),
                dataIndex: "mPrice",
                key: "mPrice",
                sorter: true,
                width: '10%',
                render: (text, record) => text,
            },
            this.checkboxTable(this.getText(10569), "mIsActive"),
            this.checkboxTable(this.getText(10570), "mIsByWeight"),
            this.checkboxTable(this.getText(10571), "mIsSold"),
            this.checkboxTable(this.getText(10572), "mIsPriceFixed"),
            this.checkboxTable(this.getText(10573), "mIsDiscountProhibited"),
            this.checkboxTable(this.getText(10574), 'mIsForInventory'),
            this.checkboxTable(this.getText(10575), 'mIsSerialize'),
            {
                title: this.getText(10576),
                width: '9%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return (
                            <span>
                                <Tooltip title={this.getText(10577)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                        this.props.uiActions.setIsEditFatherItem(true);
                                        this.props.uiActions.showModal(TableIDs.modalCreateEditMatrixItem, { ...this.props.ui.data, itemId: record['mCode'] })
                                    }} />
                                </Tooltip>
                                {/*
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                    this.props.uiActions.showModal(TableIDs.modalCreateEditFatherItem, record['mId'])
                                }} />
                                */}
                                <Popconfirm title={this.getText(10578)}
                                    onConfirm={() => {
                                        console.log("record", record);
                                        fixCurrentPage.bind(this)();
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.id,
                                            record['mCode'],
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.get);
                                    }
                                    }
                                    okText={this.getText(10579)} cancelText={this.getText(10580)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm>
                                <Tooltip title={this.getText(10581)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => {
                                        this.setState({ mParentItemCode: record['mCode'] },
                                            this.props.uiActions.showModal(TableIDs.modalMatrix/*, record['mCode']*/)
                                        )
                                    }} />
                                </Tooltip>

                            </span >);
                    } else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)
                    }
                }
            }
        ];


        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[30, false, 40, 30]}
                    title={this.getText(10582)}
                    actionButtons={[
                        {
                            buttonType: 'add',
                            buttonFunction: () => {
                                this.props.uiActions.setIsEditFatherItem(true);
                                this.props.uiActions.showModal(TableIDs.modalCreateEditMatrixItem, { ...this.props.ui.data, itemId: null })
                            }
                        }, {
                            buttonIsClicked: false,
                            buttonType: 'filter',
                            buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchItem); },
                        }, {
                            buttonDisabled: !this.state.filterActive,
                            buttonType: 'clear',
                            buttonFunction: this.initFilters,
                        }, {
                            buttonType: 'refresh',
                            buttonFunction: this.refreshData
                        },
                    ]}
                    genericActionButtons={[
                        <Tooltip title={this.getText(17424)}>
                            <Button key="printItems" className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={this.modalPrintItemsOpen}>
                                <Icon style={{ fontSize: 16 }} type="file-excel" />
                            </Button>
                        </Tooltip>,
                        <RecyclingBin
                            id={this.recycling_id}
                            getApi={this.api.get_recycling}
                            restorationApi={this.api.restoration}
                            title={this.getText(15403)}
                            columnTitles={[this.getText(15404), this.getText(15405), this.getText(15406), this.getText(15421), this.getText(15407)]}
                            popconfirmText={{ title: this.getText(15408), okText: this.getText(15409), cancelText: this.getText(15410) }}
                            onClose={this.refreshData}
                            onRestoration={fItem => {
                                this.setState({ modalSonsTriggerOpen: true, mParentItemCode: fItem, }, () => {
                                    this.props.uiActions.showModal(TableIDs.modalMatrix)
                                })
                            }}
                            {...this.props}
                        />
                    ]}
                    showsearch={(val) => {
                        if (val) { this.setState({ filterActive: true }) }
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request,
                                _filter: escapeRegExp(val),
                                //_filter: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}

                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />

                <ModalSalesAlert
                    id={TableIDs.modalSalesAlert}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSalesAlert)}
                    title={this.getText(12574)}
                />



                <ModalMatrix
                    id={TableIDs.modalMatrix}
                    context={this.id} context_api_get={this.api.get} mParentItemCode={this.state.mParentItemCode}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalMatrix)}
                    modalSonsTriggerOpen={this.state.modalSonsTriggerOpen}
                    modalSonsTriggerClose={() => { this.setState({ modalSonsTriggerOpen: false }) }}
                // title={"עדכון מטריצה"}
                />

                <ModalAdvanceSearch
                    id={TableIDs.modalSearchItem}
                    user={this.props.user}
                    getApi={this.api.get} context={this.id}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchItem)}
                    setFilterActive={e => { this.setState({ filterActive: e }) }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e }) }}
                //  title={"חיפוש מתקדם"}
                //searchInput={this.state.searchFilter}
                //searchInputCallback={(e) => this.setState({ searchFilter: e.target.value.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'") })}
                />

                <ModalCreateEditMatrixItem
                    id={TableIDs.modalCreateEditMatrixItem}
                    context={this.id} context_api_get={this.api.get}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditMatrixItem)}
                    onChangeParentItemCode={(mParentItemCode) => { this.setState({ mParentItemCode }) }}
                // title={"יצירת/עריכת פריט"}
                />

                <ModalCreateEditItem
                    id={TableIDs.modalCreateEditItem}
                    context={"MATRIX_BARCODE"} context_api_get={"get_barcode_table"}
                    barCodeDisabled={true}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditItem)}
                    isMatrix
                // title={"יצירת/עריכת פריט"}
                />
                <GenericModal
                    title={this.getText(17425)}
                    visible={this.state.modalPrintItems}
                    onCancel={() => { this.setState({ modalPrintItems: false }) }}
                    width={600}
                    footer={[
                        <Button type="primary" onClick={this.printItems}>{this.getText(17429)}</Button>,
                        <Button onClick={this.clearCheckedModalPrintItems}>{this.getText(17430)}</Button>,
                    ]}>
                    <Input.Search
                        value={this.state.modalPrintItemsSearch}
                        onChange={e => { this.setState({ modalPrintItemsSearch: e.target.value }) }}
                    />
                    <Table
                        dataSource={this.props.data[this.fathers_id].dataset.filter(this.filterSearch)}
                        columns={[
                            { title: this.getText(17426), key: 'code', dataIndex: 'code', sorter: true, width: '25%' },
                            { title: this.getText(17427), key: 'name', dataIndex: 'name', sorter: true, width: '50%' },
                            { title: this.modalEditCheckbox('all'), key: 'checked', dataIndex: 'checked', width: '25%', render: this.modalEditCheckbox },
                        ]}
                        onChange={(pagination, filters, sorter) => {
                            let dataset = [...this.props.data[this.fathers_id].dataset].sort((a, b) => {
                                if (sorter.order === "ascend") {
                                    return sorter.field === 'code' ? a.code - b.code : a.name.localeCompare(b.name)
                                } else {
                                    return sorter.field === 'code' ? b.code - a.code : b.name.localeCompare(a.name)
                                }
                            })
                            this.props.dataActions.setJson(this.fathers_id, { dataset })
                        }}
                    />
                </GenericModal>
            </div>
        )
    }
}
export default MatrixItemsTable;

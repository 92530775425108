import React, { Component } from "react";
import "../../App.css";
import { Card, Button, Form, Row, Col, Table, Select, Input, Icon, Switch } from "antd";
import PageToolbar from "../../components/PageToolbar";
import GenericSelector from "../../components/GenericSelector";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ResponsiveTable from "../../components/ResponsiveTable";


class ExportExcel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tableName: "",
            colList: "",
            whereList: [],
            whereType: 1,
            modalViewTable: false,
            modalSaveFormat: false,
            formatID: null,
            formatDesc: null,
        }

        this.id = "EXPORT_EXCEL_VIEW_TABLE";
        this.tables_id = "EXPORT_TABLE_LIST";
        this.cols_id = "EXPORT_COL_LIST";
        this.formats_id = "EXPORT_EXCEL_FORMAT";

        this.api = {
            tables: "export_table_list",
            cols: "export_col_list",
            export: "export_excel",

            get_formats: "get_export_excel_formats",
            create_edit_formats: "create_edit_export_excel_formats",
            delete_formats: "delete_export_excel_formats",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.formats_id] === undefined) props.data[this.formats_id] = { ...props.data.genericData };

        if (props.data[this.tables_id] === undefined) props.data[this.tables_id] = { ...props.data.genericSelector };
        if (props.data[this.cols_id] === undefined) props.data[this.cols_id] = { ...props.data.genericSelector };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericDataSetFilter(
            this.formats_id, this.props.user.companyCode, this.props.user.token, {}, this.api.get_formats);
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(14635);
    }

    getParamsToApi = () => {
        const { tableName, colList, whereList, whereType } = this.state;

        let jsonList = whereList.filter(f => f.colID).map(x => { return { ...x, colID: parseInt(x.colID) } });

        return {
            tableName,
            colList: colList ? colList : "",
            whereList: JSON.stringify(jsonList),
            whereType
        }
    }

    xlsFilePrint = () => {
        const { tableName, colList, whereList, whereType } = this.getParamsToApi();

        let dataSend = "tName\fcolList\fwhereList\fwhereType\f_page_num\f_rows_num\r" +
            tableName + '\f' + colList + '\f' + whereList + '\f' + whereType + '\f1\f999999999';

        this.sendAPI(this.api.export, dataSend, (ob) => {
            let data = ob.data ? ob.data.split('\r').map(x => x.split('\f').join(',')).join('\r\n') : [];
            let blob = new Blob(["\ufeff", data], { type: "text/csv;charset=utf8" });
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = tableName + '.csv';
            a.click();
        }, (e) => { console.error(e) })
    }

    sendAPI = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }

    addToWhereList = () => {
        let newRow = {
            colID: '',
            operator: '',
            value: '',
            index: this.state.whereList.length,
        }
        this.setState({ whereList: [...this.state.whereList, newRow] });
    }

    colSelector = (text, record) => {
        if (this.props.admin) {
            return (<GenericSelector
                requestFilter={{ tName: this.state.tableName }}
                id={this.cols_id}
                // api={this.api.cols}
                value={text ? text.toString() : null}
                onChange={(e) => {
                    let newWhereList = this.state.whereList.map(x => { return x.index === record.index ? { ...x, colID: e } : x })
                    this.setState({ whereList: newWhereList })
                }}
                user={this.props.user}
                data={this.props.data}
                dataActions={this.props.dataActions}
            />)
        } else {
            return record.formatColName
        }
    }

    operatorSelector = (text, record) => {
        let operators = [15352, 15353, 15354, 15355, 15356, 15357, 15358, 15359, 15360];
        return (<Select style={{ width: '100%' }} value={text} onChange={(e) => {
            let newWhereList = this.state.whereList.map(x => { return x.index === record.index ? { ...x, operator: e } : x })
            this.setState({ whereList: newWhereList })
        }}>
            {operators.map((x, i) => (<Select.Option value={i + 1}>{this.getText(x)}</Select.Option>))}
        </Select>)
    }

    valueInput = (text, record) => {
        return (record.operator != 8 && record.operator != 9) ? (
            <Input value={text} onChange={(e) => {
                let newWhereList = this.state.whereList.map(x => { return x.index === record.index ? { ...x, value: e.target.value } : x })
                this.setState({ whereList: newWhereList })
            }} />) : ""
    }

    deleteIcon = (text, record) => {
        return (<Icon type="delete" style={{ fontSize: 16 }} onClick={() => {
            let newWhereList = this.state.whereList.filter(f => f.index != record.index)
            this.setState({ whereList: newWhereList })
        }} />)
    }

    renderTxt = (text) => {
        return (text && text[0] === '"') ? text.slice(1, -1).split('""').join('"') : text
    }

    saveFormat = () => {
        const { formatID, formatDesc } = this.state;
        const { tableName, colList, whereList, whereType } = this.getParamsToApi();
        let request = { tableName, colList, whereList, whereType, formatID, formatDesc }

        this.props.dataActions.genericDataSaveEditWithParams(
            this.formats_id, this.props.user.companyCode, this.props.user.token, this.api.create_edit_formats, this.api.get_formats, request);

        this.setState({ modalSaveFormat: false })
    }

    modalSaveFormatOpen = () => {
        this.setState({
            modalSaveFormat: true,
            whereList: this.state.whereList.map(x => { return { ...x, formatColName: this.getText(15717) + ' ' + x.colID } })
        })
    }

    render() {
        const { tableName, colList, whereList, whereType } = this.getParamsToApi();

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            width: '100%'
        };

        let mobile = window.innerWidth <= 600;

        let formatList = this.props.data[this.formats_id].data.map(x => {
            let y = x.split('\f');
            let obj = {}
            this.props.data[this.formats_id].headers.map((h, i) => {
                obj = { ...obj, [h]: h === 'whereList' && y[i] ? JSON.parse(y[i]) : y[i] }
            })
            return obj;
        })

        return (<div style={divStyle}>
            <PageToolbar title={this.getText(14636)} actionButtons={[]} width={'100%'} />

            <Card actions={[
                <Button type="primary" onClick={this.xlsFilePrint} disabled={!this.state.tableName}>{this.getText(14637)}</Button>,
                <Button type="primary" onClick={() => { this.setState({ modalViewTable: true }) }} disabled={!this.state.tableName}>{this.getText(15371)}</Button>,
                this.props.admin ? <Button type="primary" onClick={this.modalSaveFormatOpen} disabled={!this.state.tableName}>{this.getText(15713)}</Button> : '',

            ]}>
                <Row>
                    <Col span={mobile ? 24 : 8} style={{ padding: 5 }}>
                        <Form.Item label={this.getText(15718)}>
                            <Select
                                value={this.state.formatID}
                                onChange={(e) => {
                                    let formatChecked = formatList.find(f => f.formatID === e);
                                    this.setState(formatChecked, () => {
                                        this.props.dataActions.genericSelectorRefreshDataset(
                                            this.cols_id,
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.cols,
                                            { tName: formatChecked.tableName });
                                    })
                                }}
                                style={{ width: '100%' }}>
                                {formatList.map(x => (<Select.Option value={x.formatID}>{x.formatDesc}</Select.Option>))}
                            </Select>
                        </Form.Item>
                        {this.props.admin ? [
                            <Form.Item label={this.getText(14638)}>
                                <GenericSelector
                                    id={this.tables_id}
                                    api={this.api.tables}
                                    value={this.state.tableName}
                                    onChange={(e) => {
                                        this.setState({ tableName: e, colList: "", whereList: [] });
                                        this.props.dataActions.genericSelectorRefreshDataset(
                                            this.cols_id,
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.cols,
                                            { tName: e });
                                    }}
                                    user={this.props.user}
                                    data={this.props.data}
                                    dataActions={this.props.dataActions}
                                />
                            </Form.Item>,
                            <Form.Item label={this.getText(14639)}>
                                <GenericSelector
                                    multi
                                    maxTagCount={20}
                                    requestFilter={{ tName: this.state.tableName }}
                                    id={this.cols_id}
                                    // api={this.api.cols}
                                    value={this.state.colList ? this.state.colList.split(',') : []}
                                    onChange={(e) => { this.setState({ colList: e ? e.join(',') : "" }) }}
                                    user={this.props.user}
                                    data={this.props.data}
                                    dataActions={this.props.dataActions}
                                />
                            </Form.Item>
                        ]
                            : ''}
                    </Col>
                    <Col span={mobile ? 24 : 16} style={{ padding: 5 }}>
                        <Form.Item label={this.getText(15347)}>
                            {this.props.admin ? (<Button type="primary" onClick={this.addToWhereList} disabled={!this.state.tableName}> + {this.getText(15348)}</Button>) : ''}
                            <Switch
                                style={{ marginRight: 50 }}
                                disabled={!this.state.tableName || !this.props.admin}
                                checked={this.state.whereType == 1}
                                onChange={(e) => { this.setState({ whereType: e ? 1 : 0 }) }}
                                checkedChildren={this.getText(15361)}
                                unCheckedChildren={this.getText(15362)}
                            />
                            <ResponsiveTable
                                tableOnly
                                dataSource={this.state.whereList}
                                columns={[
                                    { title: this.getText(15349), key: "colID", dataIndex: "colID", width: this.props.admin ? "30%" : "40%", render: this.colSelector },
                                    { title: this.getText(15350), key: "operator", dataIndex: "operator", width: "30%", render: this.operatorSelector },
                                    { title: this.getText(15351), key: "value", dataIndex: "value", width: "30%", render: this.valueInput },
                                    { title: "", width: this.props.admin ? "10%" : 0, render: this.props.admin ? this.deleteIcon : null },
                                ]}
                                pagination={{ pageSize: 5 }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
            <GenericModal
                visible={this.state.modalViewTable}
                onCancel={() => { this.setState({ modalViewTable: false }) }}
                title={this.state.tableName}>
                {this.state.modalViewTable ? (
                    <GenericTable
                        {...this.props} tableOnly id={this.id} getApi={this.api.export}
                        columns={this.props.data[this.id].headers.map((x) => { return { title: x.slice(1, -1), key: x, dataIndex: x, width: 200, render: this.renderTxt } })}
                        filters={{ tName: tableName, colList, whereList, whereType }}
                        percentageToMobile={[50, 50]}
                        scroll={{ x: window.innerWidth * 0.9 }}
                        rowHeight={40}
                    />) : ""}

            </GenericModal>
            <GenericModal
                visible={this.state.modalSaveFormat}
                onCancel={() => { this.setState({ modalSaveFormat: false }) }}
                title={this.getText(15715)}
                width={600}
                footer={[<Button type="primary" onClick={this.saveFormat}>{this.getText(15713)}</Button>]}>
                <Form.Item label={this.getText(15714)}>
                    <Input value={this.state.formatDesc} onChange={(e) => { this.setState({ formatDesc: e.target.value }) }} />
                </Form.Item>
                {this.state.whereList.length ?
                    (<Form.Item label={this.getText(15716)}>
                        {this.state.whereList.map((x, i) => {
                            return (<Input
                                value={x.formatColName}
                                onChange={(e) => {
                                    let newList = [...this.state.whereList];
                                    newList[i] = { ...newList[i], formatColName: e.target.value }
                                    this.setState({ whereList: newList })
                                }}
                            />)
                        })}
                    </Form.Item>)
                    : ''}
            </GenericModal>
        </div>)
    }
}
export default ExportExcel;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import PageToolbar from "../../components/PageToolbar";
import ReportTypeButton from "../../components/ReportTypeButton";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import { Checkbox, DatePicker, Form } from "antd";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";

const { MonthPicker } = DatePicker;

class TaxReport extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _fromMonth: null,
            _fromYear: null,
            _toMonth: null,
            _toYear: null,
            _branchList: '',
            reportName: "reports/TaxReport.pdf"
        };

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { dataset: [] }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(17446);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            width: 400,
            margin: 'auto'
        };

        const { _fromMonth, _fromYear, _toMonth, _toYear, _branchList, reportName } = this.state;

        let monthAndYearFrom = _fromMonth && _fromYear ? moment(_fromMonth + _fromYear, "MMYYYY") : null;
        let monthAndYearTo = _toMonth && _toYear ? moment(_toMonth + _toYear, "MMYYYY") : null;

        const onChangeMonthAndYear = (e, isFrom) => {
            let newMonth = null;
            let newYear = null;
            if (e) {
                newMonth = e.format("MM")
                newYear = e.format("YYYY")
            }

            if (isFrom) this.setState({ _fromMonth: newMonth, _fromYear: newYear, _toMonth: _toMonth ? _toMonth : newMonth, _toYear: _toYear ? _toYear : newYear })
            else this.setState({ _toMonth: newMonth, _toYear: newYear })
        }

        return (<div style={divStyle}>
            <PageToolbar title={gt(17447)} actionButtons={[]} />
            <Form.Item label={gt(18881)} required validateStatus={!_fromMonth || !_fromYear ? "error" : "success"}>
                <MonthPicker format={"MM/YYYY"} value={monthAndYearFrom} onChange={e => { onChangeMonthAndYear(e, true) }} />
            </Form.Item>
            <Form.Item label={gt(18882)} required validateStatus={!_toMonth || !_toYear ? "error" : "success"}>
                <MonthPicker format={"MM/YYYY"} value={monthAndYearTo} onChange={e => { onChangeMonthAndYear(e) }} />
            </Form.Item>
            <Form.Item label={gt(17748)}>
                <GenericSelector
                    {...this.props}
                    multi
                    id={TableIDs.genericSelectorBranches}
                    api={"get_branch_list"}
                    value={_branchList ? _branchList.split(",") : []}
                    onChange={e => { this.setState({ _branchList: e ? e.join(",") : '' }) }}
                />
            </Form.Item>
            <Form.Item>
                <Checkbox
                    checked={reportName == "reports/TaxReportV2.pdf"}
                    onChange={e => { this.setState({ reportName: e.target.checked ? "reports/TaxReportV2.pdf" : "reports/TaxReport.pdf" }) }}
                >{gt(19224)}</Checkbox>
            </Form.Item>
            <ReportTypeButton
                {...this.props}
                params={this.state}
                datesToFavorites={[
                    { fields: ['_fromMonth', '_fromYear'], label: gt(18881), format: 2 },
                    { fields: ['_toMonth', '_toYear'], label: gt(18882), format: 2 },
                ]}
                favoriteSave
                favoriteDesc={gt(17447)}
                disabled={!_fromMonth || !_fromYear || !_toMonth || !_toYear}
            />
        </div>)
    }

}

export default TaxReport;
/* @flow */
/*jshint esversion: 6 */
import React from 'react';
import "../App.css";
import { Row, Col, Button, Icon, Tooltip, Card, Form, Checkbox, message } from "antd";
// import { Link } from "umi";
import { NavLink } from "react-router-dom";
// Pages
import Customers from "./Customers";
//import Dashboard from "./Dashboard";
import Employees from "./Employees";
import Items from "./Items";
import MatrixItems from "./MatrixItems";
import Settings from "./Settings";
import UserSettings from "./UserSettings";
import BestClub from "./BestClub";
import Documents from "./Documents";

import ReportsInventory from "./ReportsInventory";
import ReportsPayments from "./ReportsPayments";
import ReportsSales from "./ReportsSales";
import ReportsItems from "./ReportsItems";
import TableIDs from "../data/TableIDs";
import Mailing from "./Mailing";
import Taxes from "./Taxes";
import Admin from "./Admin";

import PageToolbar from "../components/PageToolbar";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';

import GenericModal from '../components/GenericModal';
//import MenuContext from 'antd/lib/menu/MenuContext';
import GenericSelector from '../components/GenericSelector';
import DateTimePicker from '../components/DateTimePicker';
//import moment from 'moment';


class UserFavoritesPages extends React.Component {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalReports: false,
            tasksModal: null,
            modalCopy: null,
            modalCopyUserList: "",
        }

        this.id = "USER_FAVORITES_PAGES";
        this.report_id = "REPORT_FAVORITES";

        this.api = {
            get: "get_user_favorites_pages",
            delete: "delete_user_favorites_page",
            order: "order_user_favorites",

            get_reports: "get_report_favorites_list",
            get_report_params: "get_report_favorite_data",
            delete_report: "delete_report_favorite",
            order_report: "order_report_favorites",

            get_tasks: "get_report_tasks",
            edit_tasks: "edit_report_tasks",
            create_tasks: "create_report_tasks",
        };

        this.selectors = [
            { id: this.id, api: this.api.get },
            { id: this.report_id, api: this.api.get_reports },
            { id: TableIDs.genericSelectorPreDefinedMsg, api: "get_pre_defined_msg_list" },
            { id: TableIDs.genericSelectorMsgType, api: "get_msg_types_list" },
            { id: "REPORT_FAVORITE_DINAMIC_VALUE_TYPE_11", api: "get_report_favorite_dinamic_value_type_11" },
            { id: TableIDs.genericSelectorUsers, api: "get_users_list" },
        ];

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

        this.drag_url = null;
        this.drag_report = null;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16412);
    }

    apiSelector = (id, api) => {
        this.sendAPI(api, "", (ob) => {
            let dataset = [];
            if (ob.data) {
                dataset = ob.data.split("\r").map(x => {
                    let y = x.split('\f');
                    let res = {
                        code: y[0],
                        name: y[1],
                        order: y[2],
                    }
                    if (id === this.report_id) {
                        res = { ...res, url: y[3] }
                    }
                    return res;
                })
            }
            this.props.dataActions.setJson(id, { dataset });
        })
    }

    refreshData = () => {
        this.apiSelector(this.id, this.api.get);
    }

    refreshDataReports = () => {
        this.apiSelector(this.report_id, this.api.get_reports);
    }

    deleteItem = (url) => {
        this.sendAPI(this.api.delete, "_url\r" + url, this.refreshData)
    }

    deleteReportItem = (_id) => {
        this.sendAPI(this.api.delete_report, "_id\r" + _id, this.refreshDataReports)
    }

    orderChange = (order) => {
        let dataSend = "_url\f_order\r" + this.drag_url + "\f" + order
        this.drag_url = null;
        this.sendAPI(this.api.order, dataSend, this.refreshData)
    }

    orderReportChange = (order) => {
        let dataSend = "_id\f_order\r" + this.drag_report + "\f" + order
        this.drag_report = null;
        this.sendAPI(this.api.order_report, dataSend, this.refreshDataReports)
    }

    generateReport = (_id) => {
        this.getReportParams(_id, params => {
            if (params !== null && params.reportName) {
                this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
            }
        })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    getReportParams = (_id, onSuccess) => {
        this.sendAPI(this.api.get_report_params, '_id\r' + _id, (ob) => {
            if (ob.data) {
                let params = {}
                ob.data.split('\r').forEach(x => {
                    let y = x.split('\f');
                    params = { ...params, [y[0]]: y[1] === 'null' || y[1] === 'undefined' ? null : y[1] }
                })

                console.log('get_report_params', params);
                onSuccess(params);
            }
        })
    }

    getTasksData = (record) => {
        this.sendAPI(this.api.get_tasks, '_id\r' + record.code, (ob) => {
            let y = ob.data ? ob.data.split('\f') : null;
            let tasksModal = {
                ...record,
                tID: y ? y[0] : 0,
                tMsgType: y ? y[1] : 2,
                tMsgId: y ? y[2] : null,
                tWhenDynamicValue: y ? y[3] : null,
                tWhenTime: y ? y[4] : '08:00:00',
                tIsActive: y ? y[5] : 1,
                tDestinations: y ? y[6].split(',') : [],
                tIsForManagers: y ? y[7] : 0,
                tPhone: y ? y[8] : '',
                tEmail: y ? y[9] : '',

            }

            this.setState({ tasksModal })
        })
    }

    saveTasks = () => {
        let m = this.state.tasksModal;
        if (!m.tMsgId || !m.tWhenDynamicValue || !m.tWhenTime) {
            message.error(this.getText(16648))
            return;
        }
        let k = Object.keys(m);
        let script = m.tID ? this.api.edit_tasks : this.api.create_tasks
        let dataSend = k.join('\f') + '\r' + k.map(x => {
            return ((x === 'tDestinations')) ? m[x].join(',') : m[x]
        }
        ).join('\f');
        this.sendAPI(script, dataSend, () => {
            this.setState({ tasksModal: null }, () => {
                message.success(this.getText(16647))
            })
        })
    }

    getTasksSelector = (index, field, label) => {
        return (<Form.Item label={this.getText(label)}>
            <GenericSelector
                {...this.props}
                {...this.selectors[index]}
                value={this.state.tasksModal[field]}
                onChange={e => { this.setState({ tasksModal: { ...this.state.tasksModal, [field]: e } }) }}
            />
        </Form.Item>)
    }

    checkValidty = () => {
        const { tasksModal } = this.state
        if (tasksModal) {
            const isEmail = parseInt(tasksModal.tMsgType) === 3;
            const destination = isEmail ? tasksModal.tEmail : tasksModal.tPhone;
            const invalidConfig = (destination && destination.length > 0) ? {} : {
                validateStatus: "error",
                help: (isEmail)
                    ? "לא מוגדר כתובת אימייל למשתמש"
                    : "לא מוגדר מספר טלפון למשתמש"
            }
            return invalidConfig;
        } else {
            return {};
        }

    }

    onCopyReport = () => {
        const { modalCopy, modalCopyUserList } = this.state;
        if (modalCopy && modalCopyUserList) {
            let dataSend = "_id\f_user\r" + modalCopy + "\f"
                + modalCopyUserList.split(",").filter(f => f != this.props.user.userId).join("\r" + modalCopy + "\f")
            this.sendAPI("copy_favorite_report", dataSend, () => {
                this.setState({ modalCopy: null, modalCopyUserList: "" }, () => { message.success(this.getText(19387)) })
            })
        } else {
            message.error(this.getText(19386))
        }
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            width: '100%'
        };

        let dataset = this.props.data[this.id].dataset;
        let reportsDataset = this.props.data[this.report_id].dataset;
        let routeProps = { ...this.props, isFromUserFavorites: true };
        const { tasksModal } = this.state


        return (<div style={divStyle}>
            <PageToolbar
                title={this.getText(16412)}
                actionButtons={[{
                    buttonType: 'refresh',
                    buttonFunction: this.refreshData
                }]}
                genericActionButtons={[
                    <Tooltip title={this.getText(16596)}>
                        <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                            onClick={() => { this.setState({ modalReports: true }, this.refreshDataReports) }}>
                            <Icon type={"file"} style={{ fontSize: 16 }} theme={"filled"} />
                        </Button>
                    </Tooltip>
                ]}
                width={'100%'} />

            <Items {...routeProps} />
            <MatrixItems {...routeProps} />
            <Customers {...routeProps} />
            <BestClub {...routeProps} />
            <Employees {...routeProps} />
            <Documents {...routeProps} />
            <UserSettings {...routeProps} />
            <Settings  {...routeProps} />
            <ReportsInventory {...routeProps} />
            <ReportsSales {...routeProps} />
            <ReportsPayments  {...routeProps} />
            <ReportsItems {...routeProps} />
            <Mailing {...routeProps} />
            <Taxes {...routeProps} />
            <Admin {...routeProps} />
            <Row>
                {dataset.map(x => x.code ? (
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ padding: 5 }}>
                        <NavLink to={x.code}>
                            <Button
                                size="large"
                                style={{ width: 300 }}
                                draggable
                                onDragStart={() => { this.drag_url = x.code }}
                                onDragOver={(e) => { e.preventDefault() }}
                                onDrop={() => { this.orderChange(x.order) }}
                            >{x.name}</Button>
                        </NavLink>
                        <Button size="large" type="primary" onClick={() => { this.deleteItem(x.code) }}>
                            <Icon type="delete" />
                        </Button>
                    </Col>
                ) : "")}
            </Row>
            <GenericModal
                visible={this.state.modalReports}
                onCancel={() => { this.setState({ modalReports: false }) }}
                title={this.getText(16597)}
                actionButtons={[{
                    buttonType: 'refresh',
                    buttonFunction: this.refreshDataReports
                }]}>
                <Row>
                    {reportsDataset.map(x => {
                        if (x.code) {
                            let reportActions = [
                                <Tooltip title={this.getText(16636)}>
                                    <Icon type="delete" onClick={() => { this.deleteReportItem(x.code) }} />
                                </Tooltip>
                            ];


                            if (!x.url || x.url.indexOf('excel-tags-branches') == -1) {
                                reportActions = [
                                    ...reportActions,
                                    // <Tooltip title={this.getText(10000)}>
                                    //     <Icon type="FilePdfOutlined" onClick={() => { this.showReport(x.code) }} />
                                    // </Tooltip>,
                                    <Tooltip title={this.getText(16635)}>
                                        <Icon type="printer" onClick={() => { this.generateReport(x.code) }} />
                                    </Tooltip>,
                                    <Tooltip title={this.getText(16634)}>
                                        <Icon type="clock-circle" onClick={() => { this.getTasksData(x) }} />
                                    </Tooltip>
                                ]
                            }

                            if (x.url) {
                                reportActions.push(
                                    <Tooltip title={this.getText(16633)}>
                                        <NavLink to={x.url} onClick={() => {
                                            this.getReportParams(x.code, favoriteParams => { this.props.uiActions.setJsonUI('favoriteParams', favoriteParams) })
                                        }}><Icon type="eye" /></NavLink>
                                    </Tooltip>)
                            }

                            if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '521')) {
                                reportActions.push(
                                    <Tooltip title={this.getText(19381)}>
                                        <Icon type="copy" onClick={() => { this.setState({ modalCopy: x.code }) }} />
                                    </Tooltip>)
                            }


                            return (<Card
                                style={{ width: 250, display: 'inline-block', verticalAlign: 'text-top', margin: 15, cursor: 'all-scroll' }}
                                draggable
                                onDragStart={() => { this.drag_report = x.code }}
                                onDragOver={(e) => { e.preventDefault() }}
                                onDrop={() => { this.orderReportChange(x.order) }}
                                // onClick={() => { this.generateReport(x.code) }}
                                actions={reportActions}
                            >
                                <h3>{x.name}</h3>
                            </Card>)
                        }
                    })}
                </Row>
                <GenericModal
                    visible={this.state.tasksModal !== null}
                    onCancel={() => { this.setState({ tasksModal: null }) }}
                    width={600}
                    title={this.getText(16639) + (this.state.tasksModal ? " - " + this.state.tasksModal.name : "")}
                    footer={[
                        <Button onClick={() => { this.setState({ tasksModal: null }) }}>{this.getText(16641)}</Button>,
                        <Button type="primary" onClick={this.saveTasks}>{this.getText(16640)}</Button>
                    ]}>
                    {this.state.tasksModal ? (<Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                    >
                        {this.getTasksSelector(2, 'tMsgId', 16642)}
                        {this.getTasksSelector(4, 'tWhenDynamicValue', 16644)}

                        <Form.Item label={this.getText(16645)}>
                            <DateTimePicker
                                format="HH:mm:ss"
                                value={this.state.tasksModal.tWhenTime}
                                onChange={tWhenTime => {
                                    this.setState({ tasksModal: { ...this.state.tasksModal, tWhenTime } })
                                }} />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox
                                checked={this.state.tasksModal.tIsActive == 1}
                                onChange={e => {
                                    this.setState({ tasksModal: { ...this.state.tasksModal, tIsActive: e.target.checked ? 1 : 0 } })
                                }}>{this.getText(16646)}</Checkbox>
                        </Form.Item>

                        <Form.Item label={this.getText(16643)} {...this.checkValidty()}>
                            <GenericSelector
                                {...this.props}
                                {...this.selectors[3]}
                                filterList={['2', '3', '4']}
                                value={this.state.tasksModal['tMsgType']}
                                onChange={e => {
                                    this.setState({
                                        tasksModal: {
                                            ...this.state.tasksModal,
                                            ['tMsgType']: e,
                                            //tDestinations: [],
                                            //tIsForManagers: (parseInt(e) === 1 || parseInt(e) === 2) ? false : tasksModal.tIsForManagers
                                        }
                                    })
                                }}
                            />
                        </Form.Item>

                    </Form>) : ""}
                </GenericModal>
                <GenericModal
                    visible={this.state.modalCopy !== null}
                    onCancel={() => { this.setState({ modalCopy: null }) }}
                    title={this.getText(19382)}
                    width={500}
                    footer={[
                        <Button onClick={() => { this.setState({ modalCopy: null }) }}>{this.getText(19384)}</Button>,
                        <Button type="primary" onClick={this.onCopyReport}>{this.getText(19383)}</Button>,
                    ]}>
                    <Form.Item label={this.getText(19385)}>
                        <GenericSelector
                            {...this.props}
                            {...this.selectors[5]}
                            multi
                            value={this.state.modalCopyUserList ? this.state.modalCopyUserList.split(",") : []}
                            onChange={e => { this.setState({ modalCopyUserList: e?.join(",") }) }}
                        />
                    </Form.Item>
                </GenericModal>
            </GenericModal>
        </div >)
    }
}

export default UserFavoritesPages;

import React from "react";
import {
    notification, Input, Icon, Button, Upload, Popconfirm,
    Tooltip, Modal, Table, Select, Row, Col, Checkbox, message
} from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
import { fixCurrentPage } from "../utils/utils";
import { isConstructSignatureDeclaration } from "typescript";
import LangContext from "../contextProvider/LangContext";

//type State = {}
class ModalUploadDebitCardChargesFile extends React.Component/*<Props, State>*/ {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "DEBIT_CARGES_READ_FILE";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state.fileList = [];
        this.state.uploading = false;

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }
        /*
        this.api = {
            get: "get_customers",
            create: "",
            edit: "",
            delete: "delete_customer",
            selector: "",
            modalPriceListGet: "",
        };
        */

        this.actionButtons = [/* {
                buttonType: 'add',
                buttonFunction: () => {
                    this.props.uiActions.showModal(TableIDs.modalCreateEditCustomer, null);
                }
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        */];

        this.openNotificationWithIcon = (type, msg, descr) => {
            notification[type]({
                message: msg,
                description: descr,
            });
        };

    }

	static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
       // document.title = "Cash On Tab - הוראות קבע";
        /*
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });*/
    }

    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalUploadDebitCardChargesFile);
    }

    getBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(file);
    }

    handleUpload = () => {
        const { fileList } = this.state;
        this.getBase64(fileList[0],  // info.file.originFileObj 
            fileUrl => {
                this.setState({ fileUrl, loading: false, },
                    () => {
                        let user = new User(this.props.user.token, this.props.user.companyCode);
                        let job = new Job(user, "upload_debit_charges_file",
                            OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                        //job.setInput(fileUrl.split(',')[1]);
                        job.setSection("fileName", fileList[0].name);
                        job.setSection("fileData", fileUrl.split(',')[1]);
                        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
                    }
                )
            });
    }

    render() {
        const { loading, uploading, fileList } = this.state;

        const props = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file) => {
                console.log("file: ", file);
                this.setState(
                    state => ({ fileList: [...state.fileList, file], })
                );
                return false;
            },
            fileList,
        };

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10870)}
                    actionButtons={this.actionButtons}
                    showsearch={false/*(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            { ...request, _words: val
                                .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
                             }, this.api.get)
                    }*/}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'40%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataAction={this.props.dataAction}
            >
                <div>
        <div style={{ fontSize: 18, marginBottom: 16 }}>{this.getText(10871)}</div>
                    <Upload {...props}>
                        <Button disabled={fileList.length !== 0}>
                            <Icon type="upload" />{this.getText(10872)}
                            </Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={this.handleUpload}
                        disabled={fileList.length === 0}
                        loading={uploading}
                        style={{ marginTop: 16 }}
                    >
                        {uploading ? this.getText(10873) : this.getText(10874)}
                    </Button>
                </div>
            </Modal >
        )
    }

    successCallback = (ob: Object) => {
        console.log("success:", ob.data);
        //message.success('קובץ נקלט בהצלחה', 2);
        this.openNotificationWithIcon('success', this.getText(10875), this.getText(10876) + " " + ob.data);

        this.setState({ fileList: [] },
            () => {
                // refresh main table
                this.props.dataActions.genericDataGetRequested(
                    "CHARGE_DIRECT_DEBIT_TABLE", this.props.user.companyCode, this.props.user.token, "mock_standing_order_total");

                    this.props.uiActions.hideModal(TableIDs.modalUploadDebitCardChargesFile);
            })
    }

    errorCallback = (error: any) => {
        //message.error('שגיאה לא ידועה', 2);
        console.log("error: ", error)
        this.openNotificationWithIcon('error', this.getText(10877), '');
    }

}

export default ModalUploadDebitCardChargesFile;

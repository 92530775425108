
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";
import { Input } from "antd";
import PictureView from "../../components/PictureView";

class KosherTypes extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {};

        this.id = "KOSHER_TYPES";

        this.api = {
            get: "get_kosher_types",
            save: "save_kosher_types",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15577);
    }

    descValue = (text, record) => {
        let mData = this.props.data[this.id]
        if (mData.editing === record.key) {
            return (<Input value={mData.editedRecord.kDesc} onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { kDesc: e.target.value }) }} />)
        } else {
            return text
        }
    }

    imgValue = (text, record) => {
        let mData = this.props.data[this.id]
        if (mData.editing === record.key) {
            return (<PictureView
                {...this.props}
                isShared={true}
                uploadScriptName={"upload_shared_image"}
                // imageType={'V'}
                url={mData.editedRecord.kImage}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { kImage: e }) }}
                onRemove={() => { this.props.dataActions.genericDataSetEdit(this.id, { kImage: "" }) }}
                onUploadedSuccess={() => { }} onUploadedFailed={() => { }} />)
        } else if (text) {
            let origin = window.location.origin;
            const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
            let baseUrl = isDev ? "http://147.235.163.248" : origin
            return (<img src={baseUrl + "/uploads/shared/" + text} width={50} />)
        }
    }

    editButtons = (text, record) => {
        let mData = this.props.data[this.id];

        let onEdit = () => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }
        let onCheck = () => { this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.save, this.api.get) }
        let onClose = () => {
            if (mData.editedRecord.key == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
            else this.props.dataActions.genericDataCancelEditing(this.id);
        }

        return (record.key !== mData.editing) ? (<TableLineIcons {...{ onEdit }} />) : (<TableLineIcons {...{ onCheck, onClose }} />)
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            width: 600,
            margin: 'auto'
        };

        let columns = [
            { title: gt(15579), key: 'kID', dataIndex: 'kID', width: '20%', render: t => t },
            { title: gt(15580), key: 'kDesc', dataIndex: 'kDesc', width: '50%', render: this.descValue },
            { title: gt(18657), key: 'kImage', dataIndex: 'kImage', width: '20%', render: this.imgValue },
            { title: gt(15581), width: '10%', render: this.editButtons },
        ];

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id} getApi={this.api.get} title={gt(15578)} columns={columns} searchBox
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
            />
        </div>)
    }

}

export default KosherTypes;
/* @flow */
/*jshint esversion: 6 */
import { Form } from "antd";
import { Row, Col, DatePicker } from "antd";
import React, { Component } from "react";
import "../../App.css";
import GenericSelector from "../../components/GenericSelector";
import ReportTypeButton from "../../components/ReportTypeButton";
import InputStairs from "../../components/InputStairs";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class ClubNotActive extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            popStairs: null,

            _dayStairs: '',
            _monthStairs: '',
            _branchList: '',
            reportName: "reports/ClubNotActive.pdf"
        };

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI("get_best_club_report_settings", "", (ob)=>{
            let x = ob.data? ob.data.split("\r"): []
            let y = x[1]? x[1].split("\f"): [];
            this.setState({
                _dayStairs: y[3] ? y[3] : "",
                _monthStairs: y[4] ? y[4] : "",
            })
        })
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(18068);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 700,
            margin: 'auto'
        };

        const { _dayStairs, _monthStairs, _branchList } = this.state;

        return (<div style={divStyle}>
            <PageToolbar title={gt(18072)} actionButtons={[]} />
            <Row>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18070)}>
                        <InputStairs
                            value={_dayStairs}
                            onChange={e => { this.setState({ _dayStairs: e }) }}
                            visible={this.state.popStairs === '_dayStairs'}
                            onClickInput={() => { this.setState({ popStairs: this.state.popStairs === '_dayStairs' ? null : '_dayStairs' }) }}
                            min={0} max={29}
                        />
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18071)}>
                    <InputStairs
                            value={_monthStairs}
                            onChange={e => { this.setState({ _monthStairs: e }) }}
                            visible={this.state.popStairs === '_monthStairs'}
                            onClickInput={() => { this.setState({ popStairs: this.state.popStairs === '_monthStairs' ? null : '_monthStairs' }) }}
                            min={1} max={11}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label={gt(18069)}>
                <GenericSelector
                    {...this.props}
                    id={TableIDs.genericSelectorBranches}
                    api={"get_branch_list"}
                    multi
                    value={_branchList ? _branchList.split(",") : []}
                    onChange={e => this.setState({ _branchList: e ? e.join(",") : "" })}
                />
            </Form.Item>
            <ReportTypeButton
                {...this.props}
                params={this.state}
                branchesField={"_branchList"}
                favoriteSave
            />
        </div>)
    }

}

export default ClubNotActive;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Icon, Popconfirm, Tooltip, message, Checkbox, Button, Form } from "antd";
import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import ModalCreateEditUsers from "../../components/ModalCreateEditUsers";
import ModalChangePasswordUser from "../../components/ModalChangePasswordUser";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import { escapeRegExp } from "../../utils/utils";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";

const topHeight = 340; // px
const rowHeight = 30;  // px


type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class UsersTable extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            height: window.innerHeight,
            modalFilter: false,
            filterStorageList: "",
        };

        this.api = {
            get: "get_users_table",
            delete: "delete_users_table",
        }

        this.id = 'USERS_TABLE';

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }

        this.doOnResize = null;

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.createEditModal(0) }
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }, {
                buttonType: 'filter',
                buttonFunction: () => { this.setState({ modalFilter: true }) }
            }
        ]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    createButton = (title, type, onclick) => {
        return (<Tooltip title={title}><Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={type} onClick={onclick} /></Tooltip>)
    }

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10210);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_0',

            _storageList: "",
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 6;
        // fall through
        switch (sorter.field) {
            case "key": col--; // fallsthrough // 0
            case "rID": col--; // fallsthrough 
            case "rName": col--; // fallsthrough 
            case "rDisplayName": col--; // fallsthrough 
            case "rIsSuper": col--; // fallsthrough 
            case "rIsActive": col--; // fallsthrough 
            case "rCanUpdateUsers": break; // 7
            default:
                col = 0;
        }

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            // _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    createCheckBox = (data) => {
        return (<Checkbox checked={data} disabled={true} />);
    }

    createEditModal = (id) => {
        this.props.uiActions.showModal(TableIDs.modalCreateEditUsers, { rID: id });
    }

    createEditModalCopy = (id) => {
        this.props.uiActions.showModal(TableIDs.modalCreateEditUsers, { rID: id, rCopy: true });
    }

    deActivePos = (id) => {
        this.sendAPI(this.api.delete, id, () => message.success(this.getText(11777)));
        this.refreshData();
    }

    createPasswordModal = (id, name) => {
        this.props.uiActions.showModal(TableIDs.modalChangePasswordUser, { rID: id, rName: name });
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => e);
    }

    onFilterStorages = () => {
        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token,
                { _storageList: this.state.filterStorageList, page: 1, _page_num: 1 },
                this.api.get)
        })
    }


    render() {
        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };


        let columns = [
            {
                title: this.getText(11767),
                dataIndex: "rID",
                key: "rID",
                width: '8%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11768),
                dataIndex: "rName",
                key: "rName",
                width: '25%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11769),
                dataIndex: "rDisplayName",
                key: "rDisplayName",
                width: '25%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11770),
                dataIndex: "rIsSuper",
                key: "rIsSuper",
                width: '10%',
                sorter: true,
                render: (text, record) => this.createCheckBox(text === '1' ? true : false),
            }, {
                title: this.getText(11771),
                dataIndex: "rIsActive",
                key: "rIsActive",
                width: '10%',
                sorter: true,
                render: (text, record) => this.createCheckBox(text === '1' ? true : false),
            }, {
                title: this.getText(11772),
                dataIndex: "rCanUpdateUsers",
                key: "rCanUpdateUsers",
                width: '10%',
                sorter: true,
                render: (text, record) => this.createCheckBox(text === '1' ? true : false),
            }, {
                title: this.getText(11773),
                width: '12%',
                render: (text, record) => {
                    let id = record['rID'];
                    let name = record['rName'];
                    return (
                        <span>
                            {this.createButton(this.getText(11774), "edit", () => { this.createEditModal(id) })}
                            {this.createButton(this.getText(11775), "copy", () => { this.createEditModalCopy(id) })}
                            {name === 'autosoft' ? "" : this.createButton(this.getText(11776), "login", () => { this.createPasswordModal(id, name) })}
                        </span >);
                }
            }
        ]

        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[20, 40, 40]}
                    title={this.getText(11766)}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                _words: escapeRegExp(val),
                                // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            },
                            this.api.get)
                    }}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <ModalCreateEditUsers
                    id={TableIDs.modalCreateEditUsers}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditUsers)}
                />
                <ModalChangePasswordUser
                    id={TableIDs.modalChangePasswordUser}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalChangePasswordUser)}
                />
                <GenericModal
                    visible={this.state.modalFilter}
                    onCancel={() => { this.setState({ modalFilter: false }) }}
                    title={this.getText(18933)}
                    width={500}
                    footer={[
                        <Button type="primary" onClick={this.onFilterStorages}>{this.getText(18935)}</Button>,
                        <Button onClick={() => { this.setState({ filterStorageList: "" }) }}>{this.getText(18936)}</Button>,
                        <Button onClick={() => { this.setState({ modalFilter: false }) }}>{this.getText(18937)}</Button>,
                    ]}>
                    <Form.Item label={this.getText(18934)}>
                        <GenericSelector
                            {...this.props}
                            multi
                            id={TableIDs.genericSelectorStorages}
                            api={"get_storage_list"}
                            onChange={e => { this.setState({ filterStorageList: e ? e.join(",") : "" }) }}
                            value={this.state.filterStorageList ? this.state.filterStorageList.split(",") : []}
                        />
                    </Form.Item>
                </GenericModal>
            </div>
        )

    }
}
export default UsersTable;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Form, Select, Checkbox, InputNumber, Input } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
//import { DatePicker } from 'antd';
//import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import StockTransferStorages from "../../components/StockTransferStorages";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

// TODO: add item selector to form 

class DeadStock extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            DeadStock: {
                //mRequestedReport: 0,
                //mLowerSelectionDate: null,
                //mLowerSelectionTime: "00:00:00",
                //mUpperSelectionDate: null,
                //mUpperSelectionTime: "23:59:59",
                mApplyRangeApplyItemCode: 0,
                mApplyRangeLowerItemCode: '',
                mApplyRangeUpperItemCode: '',
                mApplyRangeApplyItemName: 0,
                mApplyRangeLowerItemName: '',
                mApplyRangeUpperItemName: '',
                mApplyRangeApplySupplierCode: 0,
                mApplyRangeLowerSupplierCode: '',
                mApplyRangeUpperSupplierCode: '',
                mApplyRangeApplySupplierName: 0,
                mApplyRangeLowerSupplierName: '',
                mApplyRangeUpperSupplierName: '',
                //mPosList: '',
                //mEmployeeList: '',
                mStorageList: '',
                mDocumentList: '',
                //mDocumentTypes: '',
                mGroupList: '',
                mSupplierList: '',
                mTagList: '',
                //mMinimalPercentage: 0,
                mTagRelation: '1',
                mSortField: 'code',
                mDays: 30,
                mShowZeroBalanceItems: 0,
                mCalculateBalanceSeparateStorage: 0,
                mIsActive: '-1',

                mCentralType: '0',
                mCentralOnly: '0',

                reportName: "reports/deadStock.pdf"
            }
        };
        this.state.type = "pdf";

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
            props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
            props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorDocumentTypes] === undefined) {
            props.data[TableIDs.genericSelectorDocumentTypes] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
            selector_documents: "get_document_types_list",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes);//
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group); //
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorDocumentTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_documents);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params, "mPosList", "mStorageList");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params, posField, storagesField) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params[posField] === '') params = { ...params, [posField]: fullPosList }
        if (params[storagesField] === '') params = { ...params, [storagesField]: fullStoragesList }
        // //console.log('yuda report ', params)
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10186);
        if (this.props.data[TableIDs.genericSelectorPos].dataset[0] &&
            this.props.data[TableIDs.genericSelectorStorages].dataset[0] &&
            !this.state.loading) {
            this.setState({ loading: true })
        }


        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("DeadStock", this.props.ui.favoriteParams)
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorDocumentTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_documents]
        });
        /*
        this.setReportParameters("DeadStock", {
            mStorageList:
                Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code'])
                    .join(',')
        });
        */

    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.DeadStock.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.DeadStock.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("DeadStock", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/
    createCheckBox = (field, data) => {
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setReportParameters("DeadStock", { [field]: data });
                else
                    this.setReportParameters("DeadStock", { [field]: '' });
            }} />);
    }

    render() {
        console.log("valueJson4", this.state)


        const { DeadStock, endOpen } = this.state;
        const { mLowerSelectionDate, mLowerSelectionTime,
            mUpperSelectionDate, mUpperSelectionTime } = DeadStock;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { DeadStock } = this.state;
        //const isMinPercShownStyle = { display: (DeadStock.mRequestedReport === 5 || DeadStock.mRequestedReport === 6) ? 'block' : 'none' }

        //let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        //});
        //let listEmployee = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        //});
        //let listItemTags = Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", []).map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        //});

        let listItemTags = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        //let listItemTagTypes = Jax.get(this.props.data, TableIDs.genericSelectorItemTagTypes + ".dataset", []).map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        //});
        let listItemGroup = Jax.get(this.props.data, TableIDs.genericSelectorItemGroup + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listSuppliers = Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listDocuments = Jax.get(this.props.data, TableIDs.genericSelectorDocumentTypes + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let mobile = window.innerWidth > 600 ? false : true;

        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(11935)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '80%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >

                        <FormItem label={this.getText(11936)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                            <InputNumber
                                //ApplyRange_Lower
                                value={this.state.DeadStock.mDays}
                                min={0} defaultValue={30} step={1} precision={0}
                                onChange={(value) => this.setReportParameters("DeadStock", { mDays: value })}
                                //disabled={!this.state.DeadStock.mDays}
                                style={{ marginLeft: '4px' }}

                            />
                        </FormItem>

                        <FormItem label={this.getText(11937)} >
                            <Checkbox //onChange={this.handleCheckBoxRange}
                                //ApplyRange_Apply
                                //checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                                checked={this.state.DeadStock.mApplyRangeApplySupplierCode == 1}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeApplySupplierCode: (value.target.checked) ? 1 : 0 })}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Lower
                                //value={}
                                value={this.state.DeadStock.mApplyRangeLowerSupplierCode}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeLowerSupplierCode: value })}
                                disabled={!this.state.DeadStock.mApplyRangeApplySupplierCode}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Upper
                                //value={}
                                value={this.state.DeadStock.mApplyRangeUpperSupplierCode}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeUpperSupplierCode: value })}
                                disabled={!this.state.DeadStock.mApplyRangeApplySupplierCode}
                                style={{ marginLeft: '4px' }}

                            />
                        </FormItem>
                        <FormItem label={this.getText(11938)} >
                            <Checkbox
                                //onChange={this.handleCheckBoxSum}
                                //ApplySum_Apply
                                //checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                                checked={this.state.DeadStock.mApplyRangeApplySupplierName == 1}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeApplySupplierName: (value.target.checked) ? 1 : 0 })}
                            />
                            <Input
                                //ApplySum_Lower
                                //value={}
                                value={this.state.DeadStock.mApplyRangeLowerSupplierName}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeLowerSupplierName: encodeURIComponent(value.target.value) })}
                                disabled={!this.state.DeadStock.mApplyRangeApplySupplierName}
                            />
                            <Input
                                //ApplySum_Upper
                                //value={}
                                value={this.state.DeadStock.mApplyRangeUpperSupplierName}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeUpperSupplierName: encodeURIComponent(value.target.value) })}
                                disabled={!this.state.DeadStock.mApplyRangeApplySupplierName}
                            />
                        </FormItem>
                        <FormItem label={this.getText(11939)} >
                            <Checkbox
                                //onChange={this.handleCheckBoxSum}
                                //ApplySum_Apply
                                //checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                                checked={this.state.DeadStock.mApplyRangeApplyItemCode == 1}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeApplyItemCode: (value.target.checked) ? 1 : 0 })}
                            />
                            <InputNumber
                                //ApplySum_Lower
                                //value={}
                                value={this.state.DeadStock.mApplyRangeLowerItemCode}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeLowerItemCode: value })}
                                disabled={!this.state.DeadStock.mApplyRangeApplyItemCode}
                            />
                            <InputNumber
                                //ApplySum_Upper
                                //value={}
                                value={this.state.DeadStock.mApplyRangeUpperItemCode}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeUpperItemCode: value })}
                                disabled={!this.state.DeadStock.mApplyRangeApplyItemCode}
                            />
                        </FormItem>
                        <FormItem label={this.getText(11940)} >
                            <Checkbox
                                //onChange={this.handleCheckBoxSum}
                                //ApplySum_Apply
                                //checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                                checked={this.state.DeadStock.mApplyRangeApplyItemName == 1}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeApplyItemName: (value.target.checked) ? 1 : 0 })}
                            />
                            <Input
                                //ApplySum_Lower
                                //value={}
                                value={this.state.DeadStock.mApplyRangeLowerItemName}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeLowerItemName: encodeURIComponent(value.target.value) })}
                                disabled={!this.state.DeadStock.mApplyRangeApplyItemName}
                            />
                            <Input
                                //ApplySum_Upper
                                //value={}
                                value={this.state.DeadStock.mApplyRangeUpperItemName}
                                style={{ marginLeft: '4px' }}
                                onChange={(value) => this.setReportParameters("DeadStock", { mApplyRangeUpperItemName: encodeURIComponent(value.target.value) })}
                                disabled={!this.state.DeadStock.mApplyRangeApplyItemName}
                            />
                        </FormItem>

                        {/* <FormItem label={this.getText(11941)}>
                            {this.createCheckBox('mStorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mStorageList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("DeadStock", { mStorageList: value.join(',') })}
                                value={(this.state.DeadStock.mStorageList === '') ? [] : this.state.DeadStock.mStorageList.split(',')}
                            >
                                {listStorage}
                            </Select>
                        </FormItem> */}
                        <StockTransferStorages
                            {...this.props}
                            fieldName={'mStorageList'}
                            onChange={(value) => this.setReportParameters("DeadStock", { mStorageList: value.join(',') })}
                            value={(this.state.DeadStock.mStorageList === '') ? [] : this.state.DeadStock.mStorageList.split(',')}
                        />
                        <FormItem label={this.getText(11942)} >
                            {this.createCheckBox('mDocumentList', Jax.get(this.props.data, TableIDs.genericSelectorDocumentTypes + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mDocumentList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("DeadStock", { mDocumentList: value.join(',') })}
                                value={(this.state.DeadStock.mDocumentList === '') ? [] : this.state.DeadStock.mDocumentList.split(',')}
                            >
                                {listDocuments}
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(11943)}>
                            {/* {
                                this.createCheckBox('mTagList', Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", [])
                                    .reduce((acc, i) => {
                                        //console.log("acc: ", acc); 
                                        return acc.concat((i.tags.map(j => j.id)));
                                    }, [])
                                    .join(',')
                                )
                            }
                            <Select key={"mTagList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("DeadStock", { mTagList: value.join(',') })}
                                value={(this.state.DeadStock.mTagList === '') ? [] : this.state.DeadStock.mTagList.split(',')}
                            >
                                {listItemTags}
                            </Select> */}
                            {/* <Switch checkedChildren={this.getText(11952)} unCheckedChildren={this.getText(11953)} */}
                            <GenericSelectorItemTagsWithRelation {...this.props} notApi
                                value={this.state.DeadStock.mTagList}
                                onChange={(value) => { this.setReportParameters("DeadStock", { mTagList: value }) }}
                                tagRelationValue={this.state.DeadStock.mTagRelation}
                                tagRelationOnChange={(value) => {
                                    let mTagList = this.state.DeadStock.mTagList;
                                    let from = this.state.DeadStock.mTagRelation;
                                    let to = value.target.value;
                                    let oldJson = mTagList.indexOf("{") > -1 ? JSON.parse(mTagList) : {}
                                    if (from == "2") mTagList = oldJson.tagList1 ?? ""
                                    if (to == "2") mTagList = JSON.stringify({ tagList1: mTagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                                    this.setReportParameters("DeadStock", { mTagRelation: to, mTagList })
                                }}
                            />
                            {/* <GenericSelectorItemTags {...this.props} multi
                                onChange={(value) => this.setReportParameters("DeadStock", { mTagList: value.join(',') })}
                                value={(this.state.DeadStock.mTagList === '') ? [] : this.state.DeadStock.mTagList.split(',')}
                            />
                            <TagRelationRadio showed={this.state.DeadStock.mTagList}
                                checked={this.state.DeadStock.mTagRelation == 1}
                                onChange={(value) => this.setReportParameters("DeadStock", { mTagRelation: (value === true) ? 1 : 0 })}
                            //checked={}
                            /> */}
                        </FormItem>

                        <FormItem label={this.getText(11944)}>
                            {/* {this.createCheckBox('mSupplierList', Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mSupplierList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("DeadStock", { mSupplierList: value.join(',') })}
                                value={(this.state.DeadStock.mSupplierList === '') ? [] : this.state.DeadStock.mSupplierList.split(',')}
                            >
                                {listSuppliers}
                            </Select> */}
                            <GenericSelectorSuppliers
                                {...this.props} multi notApi
                                onChange={(value) => this.setReportParameters("DeadStock", { mSupplierList: value.join(',') })}
                                value={(this.state.DeadStock.mSupplierList === '') ? [] : this.state.DeadStock.mSupplierList.split(',')}
                            />
                        </FormItem>



                        <FormItem label={this.getText(11945)}>
                            <Select key={"mIsActive"} style={{ width: 220 }}
                                value={this.state.DeadStock.mIsActive}
                                onChange={(value) => { this.setReportParameters("DeadStock", { mIsActive: value }); }}
                            >
                                <Option key={1} value={'-1'}>-</Option>
                                <Option key={2} value={'1'}>{this.getText(11954)}</Option>
                                <Option key={3} value={'0'}>{this.getText(11955)}</Option>
                            </Select>

                        </FormItem>

                        <FormItem label={this.getText(19472)}>
                            <Select key={"mCentralType"} style={{ width: 220 }}
                                value={this.state.DeadStock.mCentralType}
                                onChange={(value) => { this.setReportParameters("DeadStock", { mCentralType: value }); }}
                            >
                                <Option key={0} value={'0'}>-</Option>
                                <Option key={1} value={'1'}>{this.getText(19473)}</Option>
                                <Option key={2} value={'2'}>{this.getText(19474)}</Option>
                                <Option key={3} value={'3'}>{this.getText(19475)}</Option>
                            </Select>

                        </FormItem>

                        <FormItem style={{ display: this.state.DeadStock.mCentralType > 0 ? "block" : "none" }}>
                            <Checkbox key={"mCentralOnly"}
                                value={this.state.DeadStock.mCentralOnly == 1}
                                onChange={(value) => { this.setReportParameters("DeadStock", { mCentralOnly: value.target.checked ? "1" : "0" }); }}
                            >{this.getText(19476)}</Checkbox>

                        </FormItem>

                        <FormItem label={this.getText(11946)}>
                            <Select key={"mSortField"} style={{ width: 220 }}
                                value={this.state.DeadStock.mSortField}
                                onChange={(value) => { this.setReportParameters("DeadStock", { mSortField: value }); }}
                            >
                                <Option key={1} value={'code'}>{this.getText(11956)}</Option>
                                <Option key={2} value={'name'}>{this.getText(11957)}</Option>
                            </Select>
                        </FormItem>
                        <ReportTypeButton
                            {...this.props}
                            params={this.state.DeadStock}
                            storagesField={'mStorageList'}
                            encodeURIFields={this.state.DeadStock.mTagRelation == '2' ? ['mTagList'] : null}

                            // tagsField={'mTagList'}
                            favoriteSave
                        />
                        {/* <FormItem key={"reportName"} label={this.getText(11947)}>
                            <Select style={{ width: 220 }}
                                //size={"large"}
                                className={"reports/deadStock.pdf"}
                                //combobox={null}
                                defaultValue={"pdf"}
                                onChange={(value) => {
                                    //const type = this.state.DeadStock.mRequestedReport;
                                    //console.log(type);
                                    //const str = (type === 0) ? '' : type.toString();
                                    //console.log(value,str);
                                    //this.setReportParameters("DeadStock", { reportName: "reports/DeadStock" + str + ".pdf"})}
                                    this.setState({ ...this.state, type: value });
                                    this.setReportParameters("DeadStock", { reportName: this.state.DeadStock.reportName.split('.')[0] + "." + value });
                                }}
                            >
                                <Option key={"pdf"}>{this.getText(11948)}</Option>
                                <Option key={"xls"}>{this.getText(11949)}</Option>
                                <Option key={"docx"}>{this.getText(11950)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem key={"submit"}>
                            <Button type={"primary"} onClick={() => {
                                //const type = this.state.DeadStock.mRequestedReport;
                                //console.log("report", type);
                                //const str = (type === 0) ? '' : type.toString();
                                //console.log("report str", str);
                                //this.setReportParameters("DeadStock", { reportName: "reports/DeadStock" + str + "." + this.state.type });
                                this.generateReport.apply(this, ["DeadStock"])
                            }}>{this.getText(11951)}</Button>
                        </FormItem> */}
                    </Form>
                </Card>
            </div>
        );
    }
}
export default DeadStock;


/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import TagTable from "./TagTable";


type Props = {
    user: any,
    ui: any,
    uiActions: any,
    data: Object,
    dataActions: Object
};
type State = {};

class TagIndex extends Component<Props, State> {

    // componentDidMount() {
    //     document.title = "Cash On Tab - תגיות";
    // }

    render() {
        //console.log("tag Index.js props: " + JSON.stringify(this.props));

        const divStyle = {
            align: 'center',
            dir: 'rtl', // dir: 'ltr'
            padding: '2% 2% 2% 2%',
        };
        
        return (
            <div style={divStyle}>
                <TagTable
                    user={this.props.user}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} 
                    ActionQueue={this.props.ActionQueue} />
            </div>
        );
    }
}
export default TagIndex;

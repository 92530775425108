/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import { Button, Card, Form, message } from "antd";
import PageToolbar from "../../components/PageToolbar";
import DateTimePicker from "../../components/DateTimePicker";
import moment from "moment";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class Imovein extends Component {
    constructor(props: Props) {
        super(props);

        let firstInMonth = moment().format("YYYY-MM-01")
        this.state = {
            fromDate: moment(firstInMonth).add(-1, "month").format("YYYY-MM-DD"),
            toDate: moment(firstInMonth).add(-1, "day").format("YYYY-MM-DD"),
            branchList: ''
        };

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" }
        ]

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector }
        })


        this.api = {
            create: "create_i_movein_doc",
        }


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(19599);
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    printImoveinDoc = () => {
        const { fromDate, toDate, branchList } = this.state;
        let dataSend = "fromDate\ftoDate\fbranchList\r"
            + (fromDate ?? "") + "\f"
            + (toDate ?? "") + "\f"
            + branchList

        this.sendAPI(this.api.create, dataSend, ob => {
            let x = ob.data ? ob.data.split("\r") : []
            if (!x[0]) {
                message.error(this.getText(19613))
            } else if (x[0] === "Error") {
                message.error(x[1])
            } else {
                this.createPrmFile();
                this.createDocFile(x);

            }
        })
    }

    reverseHe = (str) => {
        let res = '';
        let notHeChars = '';
        let notHeRegexp = /[0-9a-zA-Z]/;
        let heCharsAdd = () => {
            if (notHeChars) {
                res += notHeChars.split('').reverse().join('');
                notHeChars = '';
            }
        }

        str.split('').forEach((x) => {
            if (x.search(notHeRegexp) > -1) {
                notHeChars += x;
            } else {
                heCharsAdd();
                res += x;
            }
        });
        heCharsAdd();

        return res.split('').reverse().join('');
    }

    printFile = (data, type, fileName, encoding) => {
        let blob = new Blob([data], { type, encoding });
        let anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = [type, anchor.download, anchor.href].join(':');
        anchor.click();
    }

    createPrmFile = () => {
        let data = `179	;אורך רשומה 		שורה 1
1  8	; מפתח לקוח		שורה 2
9  17	;אסמכתא 		שורה 3
18  19	; סוג מסמך		שורה 4
20  69	;שם לקוח 		שורה 5
0  0	;כתובת 1 			שורה 6
0  0	; כתובת 2			שורה 7
0  0	;אסמכתא 2 		שורה 8
70  79	; תאריך אסמכתא		שורה 9
80  89	;תאריך ערך 		שורה 10
0  0	;סוכן 			שורה 11 
0  0	;מחסן 			שורה 12
0  0	;פרטים 			שורה 13
0  0	;מחסן לבנים 		שורה 14
0  0	;סוכן לבנים 		שורה 15
0  0	;מחירון לבנים 		שורה 16
0  0	;הנחה כללית % 		שורה 17
90  94	;מע"מ  % 		שורה 18
0  0	;מס' עותקים 		שורה 19
0  0 	;מטבע המסמך 		שורה 20
0  0	; שער  המסמך		שורה 21
95  115	;מפתח פריט 		שורה 22
116  125	; כמות			שורה 23
126  135	; מחיר			שורה 24
0  0	;מטבע תנועה 		שורה 25
136  140	;הנחה % 			שורה 26
0  0	; שער תנועה		שורה 27 
141  179	;שם פריט 		שורה 28`;

        this.printFile(data, 'text/plain', "IMOVEIN.prm");
    }

    createDocFile = (data) => {
        let text = data.map(y => {
            let z = y.split("\f");
            let heRegexp = /[א-ת]/;
            if (z[3] && z[3].search(heRegexp) > -1) z[3] = this.reverseHe(z[3]);
            if (z[11] && z[11].search(heRegexp) > -1) z[11] = this.reverseHe(z[11]);
            return z.join("")
        }).join("\r\n");

        let uint8 = new Uint8Array(text.length);
        for (let i = 0; i < uint8.length; i++) {
            let b = text.charCodeAt(i);
            uint8[i] = (b >= 1488 && b <= 1514) ? b - 1360 : b;
        }

        this.printFile(uint8, 'text/plain;charset=cp862', "IMOVEIN.doc")

    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",

            maxWidth: 600,
            margin: "auto",
        };


        const { fromDate, toDate, branchList } = this.state

        return (<div style={divStyle}>
            <Card
                title={<PageToolbar title={gt(19600)} actionButtons={[]} />}
                actions={[
                    <Button type="primary" onClick={this.printImoveinDoc}>{gt(19604)}</Button>
                ]}>
                <Form.Item label={gt(19601)}>
                    <DateTimePicker
                        format="DD/MM/YYYY"
                        value={fromDate}
                        onChange={e => { this.setState({ fromDate: e }) }}
                        minDate={toDate ? moment(toDate, "YYYY-MM-DD").add(-70, "days").format("YYYY-MM-DD") : null}
                        maxDate={toDate}
                    />
                </Form.Item>
                <Form.Item label={gt(19602)}>
                    <DateTimePicker
                        format="DD/MM/YYYY"
                        value={toDate}
                        onChange={e => { this.setState({ toDate: e }) }}
                        minDate={fromDate}
                        maxDate={fromDate ? moment(fromDate, "YYYY-MM-DD").add(70, "days").format("YYYY-MM-DD") : null}
                    />
                </Form.Item>
                <Form.Item label={gt(19603)}>
                    <GenericSelector
                        {...this.props} multi
                        {...this.selectors[0]}
                        value={branchList ? branchList.split(",") : []}
                        onChange={e => { this.setState({ branchList: e?.join(",") ?? "" }) }}
                    />
                </Form.Item>
            </Card>
        </div>)
    }

}

export default Imovein;
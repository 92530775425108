/* @flow */
/*jshint esversion: 6 */
import React, { Component, message } from "react";

import { ModalForm } from "../../components/ModalForm";
import { ModalFilterForm } from "../../components/ModalFilterForm";
import /*type*/ { Field } from "../../components/ModalForm";
import ResponsiveTable from "../../components/ResponsiveTable";
import PageToolbar from "../../components/PageToolbar";
import "../../App.css";
import { Table, Icon, Popconfirm, DatePicker, TimePicker, Switch, Select, InputNumber } from "antd";
import moment from "moment";
import ItemPicker from "../../components/ItemPicker";
import SelectorItems from "../../components/SelectorItems";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import TableIDs from "../../data/TableIDs";
import LangContext from "../../contextProvider/LangContext";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import { disabledDatePicker } from "../../utils/utils";

const Option = Select.Option;
const OptGroup = Select.OptGroup;


type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class EarningPoints extends Component<Props, State> {
    //data = [];
    constructor(props: Props) {
        super(props);

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        if (props.data.earningPoints === undefined) props.data.earningPoints = { ...props.data.genericData };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    refreshData() {
        this.props.dataActions.getEarningPoints(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getCustomerTags(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getItemTags(this.props.user.companyCode, this.props.user.token);
    }

    componentDidMount() {
        // document.title = "Cash On Tab - צבירת נקודות";
        this.refreshData();
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10216);
    }


    handleTableChange(pagination: Object, filters: Object, sorter: Object) {
        let sortColumn = 0;
        switch (sorter.field) {
            case "key":
                sortColumn = 1;
                break;
            case "dateFrom":
                sortColumn = 2;
                break;
            case "dateTo":
                sortColumn = 3;
                break;
            case "hourFrom":
                sortColumn = 4;
                break;
            case "hourTo":
                sortColumn = 5;
                break;
            case "isForAccumulation":
                sortColumn = 6;
                break;
            case "customerTag":
                sortColumn = 7;
                break;
            case "itemCode":
                sortColumn = 8;
                break;
            case "itemTag":
                sortColumn = 9;
                break;
            case "multiplication":
                sortColumn = 10;
                break;
            case "accumulation":
                sortColumn = 11;
                break;
            case "partialAccumulation":
                sortColumn = 12;
                break;
            case "maxDiscount":
                sortColumn = 13;
                break;
            default:
                sortColumn = 0;
        }

        const sortType = sorter.order === undefined || sorter.order === "ascend" ? 1 : 0;
        const sortingChanged = this.props.data.earningPoints.pagination.sortType !== sortType || this.props.data.earningPoints.pagination.sortColumn !== sortColumn;
        let request = {
            page: sortingChanged ? 1 : pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sortType,
            sortColumn
        };

        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, request)

        window.console.log(filters);
    }

    handleCreateNew() {
        this.props.dataActions.earningPointsStartNew();
    }

    handleCreateFormCancel() {
        this.props.dataActions.earningPointsCancelNew();
        this.props.dataActions.genericDataCancelEditing('earningPoints');

    }

    handleShowFilters() {
        this.props.dataActions.earningPointsShowFilters();
    }

    handleHideFilters() {
        this.props.dataActions.earningPointsHideFilters()
    }

    handleClearFilters() {
        this.props.dataActions.earningPointsClearFilters(this.props.user.companyCode, this.props.user.token, this.props.data.earningPoints.pagination.defaultPageSize);
    }

    createItemPicker = (local, codeField, nameField) => {

        let source = this.props.data[local].editedRecord;

        let onChangeItemCode = (code, name) => {
            if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
                this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
            } else {
                this.props.dataActions.genericDataSetEdit(local, { [codeField]: code, [nameField]: name });
            }
        }

        let checkIfItemExist = (s) => {
            if (s) {
                let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab",
                    (ob) => {
                        let itemsRows = (ob.data).split("\r");
                        let _ItemData = itemsRows[0].split('\f');
                        let _ItemCode = _ItemData[1];
                        let _ItemName = _ItemData[2];

                        if (!_ItemCode || itemsRows.length > 1) {
                            this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data, idSelector: codeField });
                        } else {
                            onChangeItemCode(_ItemCode, _ItemName)
                        }
                    },
                    (error) => {
                        message.error(this.getText(11360));
                    }
                );
            } else {
                onChangeItemCode("", "")
            }
        }

        let keyboardListener = (e) => {
            let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
            switch (e.key) {
                case "b": case "B": case "נ": case "M": case "m": case "צ":
                    if (e.ctrlKey) {
                        this.ctrlBKeyFlag = true;
                        this.barcodeReaderBuffer = '';
                    } else {
                        bufferUpdate(e.key)
                    }
                    break;

                case "Enter":
                    if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                    checkIfItemExist(this.barcodeReaderBuffer)
                    break;

                default:
                    bufferUpdate(e.key)
                    break;
            }
        }

        return (<span>
            <SelectorItems
                idSelector={codeField}
                user={this.props.user}
                uiActions={this.props.uiActions}
                ui={this.props.ui}
                dataActions={this.props.dataActions}
                data={this.props.data}
                ActionQueue={this.props.ActionQueue}
                ctrlBKeyFlag={null}
                isExpandMatrixFatherItem={false}
                itemType={1}
                value={source[codeField]}
                onChangeSearch={(s) => { onChangeItemCode(s.mCode, s.mName) }}
                onChange={(s) => { this.props.dataActions.genericDataSetEdit(local, { [codeField]: s.target.value }) }}
                onBlur={(s) => { checkIfItemExist(s.target.value) }}
                onKeyDown={keyboardListener}
            />
            <h4>{source[codeField] !== "" ? source[nameField] : this.getText(11361)}</h4>
        </span>)
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dateFormat = "YYYY-MM-DD";
        const sqlFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm:ss";

        const itemTags = this.props.data.itemTags.map(
            (node: Object) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object) => {
                            return (<Option key={item.id}>{item.text}</Option>);
                        })}
                    </OptGroup>
                )
            }
        );

        const customerTags = this.props.data.customerTags.map(
            (node: Object) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object) => {
                            return (<Option key={item.id}>{item.text}</Option>);
                        })}
                    </OptGroup>
                )
            }
        );

        const YesNoNull = [
            <Option key={"-1"} />,
            <Option key={"1"}>{this.getText(11362)}</Option>,
            <Option key={"0"}>{this.getText(11363)}</Option>
        ];

        const dataSource = this.props.data.earningPoints.data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                dateFrom: y[1],
                dateTo: y[2],
                hourFrom: y[3],
                hourTo: y[4],
                isForAccumulation: y[5],
                customerTag: y[6],
                itemCode: y[7],
                tagId: y[8],
                multiplication: y[9],
                accumulation: y[10],
                partialAccumulation: y[11],
                maxDiscount: y[12],
                customerTagName: y[13],
                itemTagName: y[14],
                itemName: y[15],
                index: index
            })
        });

        const columns = [
            // {
            //     title: "",
            //     dataIndex: "key",
            //     key: "key",
            //     fixed: "right",
            //     sorter: true,
            //     width: 100
            // },
            {
                title: this.getText(11365),
                dataIndex: "dateFrom",
                key: "dateFrom",
                fixed: "right",
                sorter: true,
                width: 125,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (<DatePicker defaultValue={text.length > 0 ? moment(text, dateFormat) : null}
                    //         format={dateFormat} onChange={(date: moment, dateString: string) => {
                    //             this.props.dataActions.earningPointsSetEdit({ dateFrom: date != null ? date.format("YYYY-MM-DD") : "" })
                    //         }} />);
                    // } else {
                    const date = text.split("-");
                    if (date.length === 3) {
                        return date[2] + "/" + date[1] + "/" + date[0];
                    } else {
                        return text;
                    }
                    // }
                }
            },
            {
                title: this.getText(11366),
                dataIndex: "dateTo",
                key: "dateTo",
                fixed: "right",
                sorter: true,
                width: 125,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (<DatePicker defaultValue={text.length > 0 ? moment(text, dateFormat) : null}
                    //         format={dateFormat} onChange={(date: moment, dateString: string) => {
                    //             this.props.dataActions.earningPointsSetEdit({ dateTo: date != null ? date.format("YYYY-MM-DD") : "" })
                    //         }} />);
                    // }
                    // else {
                    const date = text.split("-");
                    if (date.length === 3) {
                        return date[2] + "/" + date[1] + "/" + date[0];
                    } else {
                        return text;
                    }
                    // }
                }
            },
            {
                title: this.getText(11367),
                dataIndex: "hourFrom",
                key: "hourFrom",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (<TimePicker style={{ width: 90 }}
                    //         defaultValue={text.length > 0 ? moment(text, timeFormat) : null}
                    //         format={timeFormat} onChange={(time: moment, timeString: string) => {
                    //             this.props.dataActions.earningPointsSetEdit({ hourFrom: timeString })
                    //         }} />);
                    // }
                    // else {
                    return text;
                    // }
                }
            },
            {
                title: this.getText(11368),
                dataIndex: "hourTo",
                key: "hourTo",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (<TimePicker style={{ width: 90 }}
                    //         defaultValue={text.length > 0 ? moment(text, timeFormat) : null}
                    //         format={timeFormat} onChange={(time: moment, timeString: string) => {
                    //             this.props.dataActions.earningPointsSetEdit({ hourTo: timeString })
                    //         }} />);
                    // }
                    // else {
                    return text;
                    // }
                }
            },
            {
                title: this.getText(11369),
                dataIndex: "isForAccumulation",
                key: "isForAccumulation",
                width: 100,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (
                    //         <Switch defaultChecked={record.isForAccumulation === "1"} onChange={(checked: boolean) => {
                    //             this.props.dataActions.earningPointsSetEdit({ isForAccumulation: checked ? 1 : 0 })
                    //         }} size={"default"} />);
                    // } else {
                    if (record.isForAccumulation === "1") {
                        return (<span>{this.getText(11418)}</span>);
                    } else {
                        return (<span>{this.getText(11419)}</span>);
                    }
                    // }
                },
                sorter: true
            },
            {
                title: this.getText(11370),
                dataIndex: "customerTagName",
                key: "customerTag",
                width: 200,
                sorter: true,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (<Select style={{ width: 250 }} size={"large"} notFoundContent={this.getText(11420)}
                    //         showsearch={true} defaultValue={record.customerTag} allowClear={true}
                    //         onChange={(value, option) => {
                    //             this.props.dataActions.earningPointsSetEdit({ customerTag: value })
                    //         }} placeholder={this.getText(11421)}>{customerTags}</Select>);
                    // } else {
                    return text;
                    // }
                }
            },
            {
                title: this.getText(11371),
                dataIndex: "itemCode",
                key: "itemCode",
                width: 200,
                sorter: true,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return this.createItemPicker("earningPoints", "itemCode", "itemName")
                    //     // return (<Select style={{width: 100}} size={"large"} notFoundContent={"לא נמצאו פריטים"} showsearch={true} default={record.itemCode + " - " + record.itemName} allowClear={true} showArrow={false} mode={"combobox"} onChange={(value, option) => {this.lookupItemCodes(value); if (option) {this.props.dataActions.earningPointsSetEdit({itemCode: option.key})}}} placeholder={"בחר פריט"}>{itemCodes}</Select>);
                    //     // return (<Button style={{width: 175}} type={"primary"} onClick={() => {this.setState({...this.state, defaultItemCode: this.props.data.earningPoints.editedRecord.itemCode, itemCodeSelectionVisible: true})}} icon={"edit"}>{this.props.data.earningPoints.editedRecord.itemCode + " - " + this.props.data.earningPoints.editedRecord.itemName}</Button>);
                    //     // return (<ItemPicker defaultItemCode={this.props.data.earningPoints.editedRecord.itemCode}
                    //     //     onChange={(value) => this.props.dataActions.earningPointsSetEdit(value)}
                    //     //     label={this.props.data.earningPoints.editedRecord.itemCode + " - " + this.props.data.earningPoints.editedRecord.itemName}
                    //     //     data={this.props.data.itemCodes}
                    //     //     showDialog={this.props.uiActions.uiShowItemPickerDialog}
                    //     //     hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                    //     // />);
                    // } else {
                    return record.itemCode.length > 0 ? record.itemCode + " - " + record.itemName : ""
                    // }
                }
            },
            {
                title: this.getText(11372),
                dataIndex: "itemTagName",
                key: "tagId",
                width: 200,
                sorter: true,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (<Select style={{ width: 250 }} size={"large"} notFoundContent={this.getText(11420)}
                    //         showsearch={true} defaultValue={record.tagId} allowClear={true}
                    //         onChange={(value, option) => {
                    //             this.props.dataActions.earningPointsSetEdit({ tagId: value })
                    //         }} placeholder={this.getText(11422)}>{itemTags}</Select>);
                    // } else {
                    return text;
                    // }
                }
            },
            {
                title: this.getText(11373),
                dataIndex: "multiplication",
                key: "multiplication",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (<InputNumber min={0} defaultValue={text} onChange={(value: number) => {
                    //         this.props.dataActions.earningPointsSetEdit({ multiplication: value })
                    //     }} />);
                    // } else {
                    return text;
                    // }
                }
            },
            {
                title: this.getText(11374),
                dataIndex: "accumulation",
                key: "accumulation",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (
                    //         <InputNumber style={{ width: 80 }} min={0} defaultValue={text} onChange={(value: number) => {
                    //             this.props.dataActions.earningPointsSetEdit({ accumulation: value })
                    //         }} />);
                    // } else {
                    return text;
                    // }
                }
            },
            {
                title: this.getText(11375),
                dataIndex: "partialAccumulation",
                key: "partialAccumulation",
                width: 100,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (
                    //         <Switch defaultChecked={record.partialAccumulation === "1"} onChange={(checked: boolean) => {
                    //             this.props.dataActions.earningPointsSetEdit({ partialAccumulation: checked ? 1 : 0 })
                    //         }} size={"default"} />);
                    // } else {
                    if (record.partialAccumulation === "1") {
                        return (<span>{this.getText(11418)}</span>);
                    } else {
                        return (<span>{this.getText(11419)}</span>);
                    }
                    // }
                },
                sorter: true
            },
            {
                title: this.getText(11376),
                dataIndex: "maxDiscount",
                key: "maxDiscount",
                width: 100,
                sorter: true,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing === record.key) {
                    //     return (<InputNumber style={{ width: 80 }} min={0} max={100} defaultValue={text}
                    //         onChange={(value: number) => {
                    //             this.props.dataActions.earningPointsSetEdit({ maxDiscount: value })
                    //         }} />);
                    // } else {
                    return text;
                    // }
                }
            },
            {
                title: "",
                fixed: "left",
                width: 100,
                render: (text, record) => {
                    // if (this.props.data.earningPoints.editing !== record.key) {
                    return (<span>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                            this.props.dataActions.earningPointsStartEditing(record.key, record.index)
                        }} />
                        <Popconfirm title={this.getText(11377)}
                            onConfirm={() => this.props.dataActions.earningPointsDeleteRow(record.key, this.props.user.companyCode, this.props.user.token)}
                            okText={this.getText(11378)} cancelText={this.getText(11379)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                        </Popconfirm>
                    </span>);
                    // } else {
                    //     return (<span>
                    //         <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                    //             onClick={() => {
                    //                 this.props.dataActions.earningPointsSaveEdit(this.props.data.earningPoints.editedRecord, this.props.user.companyCode, this.props.user.token)
                    //             }} />
                    //         <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                    //             onClick={() => {
                    //                 this.props.dataActions.earningPointsCancelEditing()
                    //             }} />
                    //     </span>);
                    // }
                }
            }
        ];

        const filterFormFields: Array<Field> = [
            {
                key: "dateFrom",
                label: this.getText(11380),
                help: "",
                node: (<DatePicker
                    disabledDate={d => disabledDatePicker(d, null, this.props.data.earningPoints.filters.dateTo)}
                    value={this.props.data.earningPoints.filters.dateFrom !== "1970-01-01" ? moment(this.props.data.earningPoints.filters.dateFrom, sqlFormat) : null}
                    format={dateFormat} onChange={(date: moment, dateString: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { dateFrom: date != null ? date.format("YYYY-MM-DD") : "" })
                    }} />),
            },
            {
                key: "dateTo",
                label: this.getText(11381),
                help: "",
                node: (<DatePicker
                    disabledDate={d => disabledDatePicker(d, this.props.data.earningPoints.filters.dateFrom, null)}
                    value={this.props.data.earningPoints.filters.dateTo !== "1970-01-01" ? moment(this.props.data.earningPoints.filters.dateTo, sqlFormat) : null}
                    format={dateFormat} onChange={(date: moment, dateString: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { dateTo: date != null ? date.format("YYYY-MM-DD") : "" })
                    }} />)
            },
            {
                key: "hourFrom",
                label: this.getText(11382),
                help: "",
                node: (<TimePicker value={moment(this.props.data.earningPoints.filters.hourFrom, timeFormat)}
                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { hourFrom: timeString })
                    }} />)
            },
            {
                key: "hourTo",
                label: this.getText(11383),
                help: "",
                node: (<TimePicker value={moment(this.props.data.earningPoints.filters.hourTo, timeFormat)}
                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { hourTo: timeString })
                    }} />)
            },
            {
                key: "isForAccumulation",
                label: this.getText(11384),
                help: "",
                node: (<Select
                    value={this.props.data.earningPoints.filters.isForAccumulation > -1 ? this.props.data.earningPoints.filters.isForAccumulation : ""}
                    onChange={(value: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { isForAccumulation: value })
                    }} size={"default"}>{YesNoNull}</Select>)
            },
            {
                key: "customerTag",
                label: this.getText(11385),
                help: "",
                node: (<GenericSelectorCustTags {...this.props}
                    // <Select size={"large"} notFoundContent={this.getText(11420)}
                    value={this.props.data.earningPoints.filters.customerTag > -1 ? this.props.data.earningPoints.filters.customerTag : ""}
                    // showsearch={true} allowClear={true}
                    onChange={(value, option) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { customerTag: value })
                    }} placeholder={this.getText(11421)}
                // >{customerTags}</Select>
                />)
            },
            {
                key: "itemCode",
                label: this.getText(11386),
                help: "",
                node: (<ItemPicker defaultItemCode={this.props.data.earningPoints.filters.itemCode}
                    onChange={(value) => this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, value)}
                    label={this.props.data.earningPoints.filters.itemCode + (this.props.data.earningPoints.filters.itemName ? " - " + this.props.data.earningPoints.filters.itemName : "")}
                    data={this.props.data.itemCodes}
                    showDialog={this.props.uiActions.uiShowItemPickerDialog}
                    hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                />)
            },
            {
                key: "itemTag",
                label: this.getText(11387),
                help: "",
                node: (<GenericSelectorItemTags {...this.props} notReport
                    // <Select size={"large"} notFoundContent={this.getText(11420)}
                    value={this.props.data.earningPoints.filters.itemTag > -1 ? this.props.data.earningPoints.filters.itemTag : ""}
                    // showsearch={true} allowClear={true}
                    onChange={(value, option) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { tagId: value })
                    }} placeholder={this.getText(11422)}
                // >{itemTags}</Select>
                />)
            },
            {
                key: "multiplication",
                label: this.getText(11388),
                help: "",
                node: (<InputNumber min={-1}
                    value={this.props.data.earningPoints.filters.multiplication > -1 ? this.props.data.earningPoints.filters.multiplication : ""}
                    onChange={(value: number) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { multiplication: value })
                    }} />)
            },
            {
                key: "accumulation",
                label: this.getText(11389),
                help: "",
                node: (<InputNumber min={-1}
                    value={this.props.data.earningPoints.filters.accumulation > -1 ? this.props.data.earningPoints.filters.accumulation : ""}
                    onChange={(value: number) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { accumulation: value })
                    }} />)
            },
            {
                key: "partialAccumulation",
                label: this.getText(11390),
                help: "",
                node: (<Select
                    value={this.props.data.earningPoints.filters.partialAccumulation > -1 ? this.props.data.earningPoints.filters.partialAccumulation : ""}
                    onChange={(value: string) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { partialAccumulation: value })
                    }} size={"default"}>{YesNoNull}</Select>)
            },
            {
                key: "maxDiscount",
                label: this.getText(11391),
                help: "",
                node: (<InputNumber min={-1}
                    value={this.props.data.earningPoints.filters.maxDiscount > -1 ? this.props.data.earningPoints.filters.maxDiscount : ""}
                    max={100} onChange={(value: number) => {
                        this.props.dataActions.earningPointsSetFilter(this.props.user.companyCode, this.props.user.token, { maxDiscount: value })
                    }} />)
            },
        ];

        let editedRecord = this.props.data.earningPoints.editedRecord;

        const createEditFormFields: Array<Field> = [
            {
                key: "dateFrom",
                label: this.getText(11392),
                help: this.getText(11393),
                node: (<DatePicker
                    disabledDate={d => disabledDatePicker(d, null, editedRecord.dateTo)}
                    defaultValue={editedRecord.dateFrom ? moment(editedRecord.dateFrom, dateFormat) : null}
                    format={dateFormat} onChange={(date: moment, dateString: string) => {
                        this.props.dataActions.earningPointsSetEdit({ dateFrom: date != null ? date.format("YYYY-MM-DD") : "" })
                    }} />),
            },
            {
                key: "dateTo",
                label: this.getText(11394),
                help: this.getText(11395),
                node: (<DatePicker
                    disabledDate={d => disabledDatePicker(d, editedRecord.dateFrom, null)}
                    defaultValue={editedRecord.dateTo ? moment(editedRecord.dateTo, dateFormat) : null}
                    format={dateFormat} onChange={(date: moment, dateString: string) => {
                        this.props.dataActions.earningPointsSetEdit({ dateTo: date != null ? date.format("YYYY-MM-DD") : "" })
                    }} />)
            },
            {
                key: "hourFrom",
                label: this.getText(11396),
                help: this.getText(11397),
                node: (<TimePicker
                    defaultValue={moment(editedRecord.hourFrom, timeFormat) ?? null}
                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                        this.props.dataActions.earningPointsSetEdit({ hourFrom: timeString })
                    }} />)
            },
            {
                key: "hourTo",
                label: this.getText(11398),
                help: this.getText(11399),
                node: (<TimePicker
                    defaultValue={moment(editedRecord.hourTo, timeFormat) ?? null}
                    format={timeFormat} onChange={(time: moment, timeString: string) => {
                        this.props.dataActions.earningPointsSetEdit({ hourTo: timeString })
                    }} />)
            },
            {
                key: "isForAccumulation",
                label: this.getText(11400),
                help: this.getText(11401),
                node: (<Switch
                    defaultChecked={editedRecord.isForAccumulation === "1"}
                    onChange={(checked: boolean) => {
                        this.props.dataActions.earningPointsSetEdit({ isForAccumulation: checked ? 1 : 0 })
                    }} size={"default"} />)
            },
            {
                key: "customerTag",
                label: this.getText(11402),
                help: this.getText(11403),
                node: (<GenericSelectorCustTags {...this.props}
                    // <Select size={"large"} notFoundContent={this.getText(11420)}
                    value={editedRecord.customerTag}
                    // showsearch={true} allowClear={true}
                    onChange={(value, option) => {
                        this.props.dataActions.earningPointsSetEdit({ customerTag: value })
                    }} placeholder={this.getText(11421)}
                // >{customerTags}</Select>
                />)
            },
            {
                key: "itemCode",
                label: this.getText(11404),
                help: this.getText(11405),
                // node: (<ItemPicker defaultItemCode={""}
                //     onChange={(value) => this.props.dataActions.earningPointsSetEdit(value)}
                //     label={this.props.data.earningPoints.editedRecord.itemCode + (this.props.data.earningPoints.editedRecord.itemName ? " - " + this.props.data.earningPoints.editedRecord.itemName : "")}
                //     data={this.props.data.itemCodes}
                //     showDialog={this.props.uiActions.uiShowItemPickerDialog}
                //     hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                // />)
                node: this.createItemPicker("earningPoints", "itemCode", "itemName")

            },
            {
                key: "itemTag",
                label: this.getText(11406),
                help: this.getText(11407),
                node: (<GenericSelectorItemTags {...this.props} notReport
                    // <Select size={"large"} notFoundContent={this.getText(11420)}
                    value={editedRecord.tagId}
                    // showsearch={true} allowClear={true}
                    onChange={(value, option) => {
                        this.props.dataActions.earningPointsSetEdit({ tagId: value })
                    }} placeholder={this.getText(11422)}
                // >{itemTags}</Select>
                />)
            },
            {
                key: "multiplication",
                label: this.getText(11408),
                help: this.getText(11409),
                node: (<InputNumber min={0} value={editedRecord.multiplication} onChange={(value: number) => {
                    this.props.dataActions.earningPointsSetEdit({ multiplication: value })
                }} />)
            },
            {
                key: "accumulation",
                label: this.getText(11410),
                help: this.getText(11411),
                node: (<InputNumber min={0} value={editedRecord.accumulation} onChange={(value: number) => {
                    this.props.dataActions.earningPointsSetEdit({ accumulation: value })
                }} />)
            },
            {
                key: "partialAccumulation",
                label: this.getText(11412),
                help: this.getText(11413),
                node: (<Switch
                    defaultChecked={editedRecord.partialAccumulation === "1"}
                    onChange={(checked: boolean) => {
                        this.props.dataActions.earningPointsSetEdit({ partialAccumulation: checked ? 1 : 0 })
                    }} size={"default"} />)
            },
            {
                key: "maxDiscount",
                label: this.getText(11414),
                help: this.getText(11415),
                node: (<InputNumber min={0} max={100} value={editedRecord.maxDiscount} onChange={(value: number) => {
                    this.props.dataActions.earningPointsSetEdit({ maxDiscount: value })
                }} />)
            },
        ];

        const toolbarActions: Array<Object> = [
            /*{
                buttonType: "filter",
                buttonFunction: this.handleShowFilters.bind(this)
            },*/
            {
                buttonType: "add",
                buttonFunction: this.handleCreateNew.bind(this)
            },
            {
                buttonType: "refresh",
                buttonFunction: this.refreshData.bind(this)
            }
        ];

        /*
        <h1>צבירת נקודות
                    <div className="table-actions">
                        <Button shape={"circle"} icon={"filter"} onClick={this.handleShowFilters.bind(this)}/>
                        <Button type={"primary"} icon={"plus"} onClick={this.handleCreateNew.bind(this)}>הוסף חדש</Button>
                    </div>
                </h1>
         */
        return (
            <div style={divStyle}>

                <PageToolbar title={this.getText(11364)} actionButtons={toolbarActions} isOfModal={false} />
                <ResponsiveTable
                    tableOnly={true}
                    percentageToMobile={[30, 30, 40]}
                    ui={this.props.ui}
                    idTable={this.props.data.earningPoints}
                    dataSource={dataSource}
                    columns={columns}
                    onChange={this.handleTableChange.bind(this)}
                    scroll={window.innerWidth > 600 ? { x: 1650 } : false}
                />
                {/* <Table dataSource={dataSource} columns={columns} loading={this.props.ui.workInProgress}
                       scroll={{x: 1650}} bordered
                       pagination={this.props.data.earningPoints.pagination}
                       onChange={this.handleTableChange.bind(this)}

                       locale={{filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים"}}
                /> */}


                <ModalForm
                    zIndex={50}
                    // visible={this.props.ui.modalStack.indexOf("EarningPointsNew") > -1}
                    visible={this.props.data.earningPoints.editing !== -1}
                    workInProgress={this.props.ui.workInProgress} title={this.getText(11416)}
                    token={this.props.user.token} companyCode={this.props.user.companyCode}
                    // cancelAction={this.handleCreateFormCancel.bind(this)}
                    cancelAction={this.handleCreateFormCancel.bind(this)}
                    recordData={this.props.data.earningPoints.editedRecord}
                    fields={createEditFormFields} saveAction={this.props.dataActions.earningPointsSaveEdit} />

                <ModalFilterForm zIndex={50} visible={this.props.ui.modalStack.indexOf("EarningPointsFilters") > -1}
                    workInProgress={this.props.ui.workInProgress} title={this.getText(11417)}
                    token={this.props.user.token} companyCode={this.props.user.companyCode}
                    cancelAction={this.handleClearFilters.bind(this)}
                    recordData={this.props.data.earningPoints.filters}
                    fields={filterFormFields} saveAction={this.handleHideFilters.bind(this)} />
            </div>
        );
    }
}

export default EarningPoints;
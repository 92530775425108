/* @flow */
/*jshint esversion: 6 */
/*jslint es6*/
import ActionTypes from "./ActionTypes";
import Dispatcher from "./Dispatcher";
import type { duplet } from "./DataStore";

const Actions = {
    toggleRenewConfirmationModal(flag: boolean){
        console.log("step toggleRenewConfirmationModal", flag)
        Dispatcher.dispatch({
            type: ActionTypes.RENEW_CONFIRMATION_MODAL,
            flag
        });
    },
    toggleConfirmationModal(flag: boolean){
        console.log("step toggleConfirmationModal", flag)
        Dispatcher.dispatch({
            type: ActionTypes.CONFIRMATION_MODAL,
            flag
        });
    },

    toggleRenewModal(flag: boolean){
        console.log("step toggleRenewnModal", flag)
        Dispatcher.dispatch({
            type: ActionTypes.RENEW_MODAL,
            flag
        });
    },

    userSetUserForRenew(//companyCode: string, username: string, password: string, confirmation: string,
        user_name: string, user_display: string, phone: string, email: string, psw1: string, psw2: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_SET_USER_FOR_RENEW,
            //companyCode,
            //username,
            //password,
            //confirmation,
            user_name,
            user_display,
            phone,
            email,
            psw1,
            psw2
        }); 
    },

    userGetUserForRenew(companyCode: string, username: string, password: string, confirmation: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_GET_USER_FOR_RENEW,
            companyCode,
            username,
            password,
            confirmation
        }); 
    },

    userGetUserForRenewSucceeded(name: string, display: string, phone: string, email: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_GET_USER_FOR_RENEW_SUCCESS,
            name,
            display,
            phone,
            email
        }); 
    },

    userGetUserForRenewFailed(error: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_GET_USER_FOR_RENEW_ERROR,
            error
        });
    },

    userRenew(companyCode: string, username: string, password: string, confirmation: string,
        user_name: string, user_display: string, phone: string, email: string, psw1: string, psw2: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_RENEW,
            companyCode,
            username,
            password,
            confirmation,
            user_name,
            user_display,
            phone,
            email,
            psw1,
            psw2
        }); 
    },


    userJustLogin(companyCode: string, username: string, password: string, confirmation: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_JUST_LOGIN,
            companyCode,
            username,
            password,
            confirmation 
        });
    },

    userLogin(companyCode: string, username: string, password: string, confirmation: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_LOGIN,
            companyCode,
            username,
            password,
            confirmation 
        });
    },

    userLogout() {
        Dispatcher.dispatch({
            type: ActionTypes.USER_LOGOUT
        })
    },

    userLoginSucceeded(token: string, isTwoWay: number ) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_LOGIN_SUCCESS,
            token,
            isTwoWay
        });
    },

    userLoginFailed(error: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_LOGIN_ERROR,
            error
        });
    },

    userRefresh(companyCode: string, username: string, token: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_REFRESH,
            companyCode,
            username,
            token
        })
    },

    toggleMainMenu() {
        Dispatcher.dispatch({
            type: ActionTypes.TOGGLE_MENU
        });
    },

    saveFormValues(values: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.SAVE_FORM_VALUES,
            values
        });
    },

    getUserUiData(token: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_GET_UI_DATA,
            token
        });
    },

    userUiDataFailed(error: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_GET_UI_DATA_ERROR,
            error
        })
    },

    userUiDataSucceeded(token: string) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_GET_UI_DATA_SUCCESS,
            token
        });
    },

    userProfileLoaded(displayName: string, profilePicture: string, userId: number) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_PROFILE_LOADED,
            displayName,
            profilePicture,
            userId
        });
    },

    userMenuLoaded(menu: Array<Object>) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_MENU_LOADED,
            menu
        });
    },

    userFavoritesLoaded(favorites: Array<Object>) {
        Dispatcher.dispatch({
            type: ActionTypes.USER_FAVORITES_LOADED,
            favorites
        })
    },

    companyNameLoaded(name: String) {
        Dispatcher.dispatch({
            type: ActionTypes.COMPANY_NAME_LOADED,
            name
        });
    },

    setIsEditFatherItem(data: any) {
        Dispatcher.dispatch({
            type: ActionTypes.SET_IS_EDIT_FATHER_ITEM,
            data,
        })
    },

    showModal(id: string, data: any) {
        Dispatcher.dispatch({
            type: ActionTypes.SHOW_MODAL,
            id,
            data,
        })
    },

    hideModal(id: string) {
        Dispatcher.dispatch({
            type: ActionTypes.HIDE_MODAL,
            id,
        })
    },

    hideModalByName(name: string) {
        Dispatcher.dispatch({
            type: ActionTypes.HIDE_MODAL_BY_NAME,
            name
        })
    },

    setModalUIData(data: string) {
        Dispatcher.dispatch({
            type: ActionTypes.SET_MODAL_UI_DATA,
            data,
        })
    },

    onPressEnter(id: string, record: any) {
        Dispatcher.dispatch({
            type: ActionTypes.PRESS_ENTER,
            id,
            record
        })
    },

    onClickCreateBtn(id: string) {
        Dispatcher.dispatch({
            type: ActionTypes.ON_CREATE_BTN,
            id,
        })
    },

    getCompsData(senderID: string, compsID: string[]) {
        console.log("EEE", senderID, " ", compsID);
        Dispatcher.dispatch({
            type: ActionTypes.GET_COMPS_DATA,
            senderID,
            compsID,
        })
    },

    getCompData(senderID: string, compID: string) {
        console.log("EEE", senderID, " ", compID);
        Dispatcher.dispatch({
            type: ActionTypes.GET_COMP_DATA,
            senderID,
            compID,
        })
    },

    sendCompData(senderID: string, compID: string, data: any) {
        console.log("EEE", senderID, " ", compID, " ", data);
        Dispatcher.dispatch({
            type: ActionTypes.SEND_COMP_DATA,
            senderID,
            compID,
            data,
        })
    },

    // DIALOG ACTIONS
    uiShowItemPickerDialog(defaultValue: string, data: Array<string>, onChange: Function) {
        Dispatcher.dispatch({
            type: ActionTypes.UI_SHOW_ITEM_PICKER,
            defaultValue,
            data,
            onChange
        })
    },

    uiHideItemPickerDialog() {
        Dispatcher.dispatch({
            type: ActionTypes.UI_HIDE_ITEM_PICKER
        });
    },

    uiShowCustomerPickerDialog(defaultValue: string, data: Array<string>, onChange: Function) {
        Dispatcher.dispatch({
            type: ActionTypes.UI_SHOW_CUSTOMER_PICKER,
            defaultValue,
            data,
            onChange
        })
    },

    uiHideCustomerPickerDialog() {
        Dispatcher.dispatch({
            type: ActionTypes.UI_HIDE_CUSTOMER_PICKER
        });
    },

    // DATA REQUESTS
    apiFailedForbidden() {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.API_FAILED_FORBIDDEN
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    getUsingPoints(companyCode: string, token: string) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GET_USING_POINTS_REQUESTED,
                companyCode,
                token
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        } else {
            dispatchEvent();
        }
    },

    getUsingPointsSucceeded(data: Array<any>) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_USING_POINTS_SUCCEEDED,
            data
        });
    },

    getUsingPointsFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_USING_POINTS_FAILED,
            errorCode,
            errorMessage
        })
    },

    getItemTags(companyCode: string, token: string) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GET_ITEM_TAGS_REQUESTED,
                companyCode,
                token
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    getItemTagsSucceeded(data: Array<any>) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_ITEM_TAGS_SUCCEEDED,
            data
        });
    },

    getItemTagsFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_ITEM_TAGS_FAILED,
            errorCode,
            errorMessage
        })
    },

    getItemCodes(companyCode: string, token: string) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GET_ITEM_CODES_REQUESTED,
                companyCode,
                token
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    getItemCodesSucceeded(data: Array<any>) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_ITEM_CODES_SUCCEEDED,
            data
        });
    },

    getItemCodesFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_ITEM_CODES_FAILED,
            errorCode,
            errorMessage
        })
    },

    getCustomerTags(companyCode: string, token: string) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GET_CUSTOMER_TAGS_REQUESTED,
                companyCode,
                token
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    getCustomerTagsSucceeded(data: Array<any>) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_CUSTOMER_TAGS_SUCCEEDED,
            data
        });
    },

    getCustomerTagsFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_CUSTOMER_TAGS_FAILED,
            errorCode,
            errorMessage
        })
    },

    getEarningPoints(companyCode: string, token: string) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GET_EARNING_POINTS_REQUESTED,
                companyCode,
                token,
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    getEarningPointsSucceeded(data: Array<any>) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_EARNING_POINTS_SUCCEEDED,
            data
        });
    },

    getEarningPointsFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GET_EARNING_POINTS_FAILED,
            errorCode,
            errorMessage
        })
    },

    saveUsingPoints(companyCode: string, token: string, data: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.SAVE_USING_POINTS_REQUESTED,
            companyCode,
            token,
            data
        });
    },

    saveUsingPointsSucceeded() {
        Dispatcher.dispatch({
            type: ActionTypes.SAVE_USING_POINTS_SUCCEEDED
        });
    },

    saveUsingPointsFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.SAVE_USING_POINTS_FAILED,
            errorCode,
            errorMessage
        })
    },

    earningPointsStartEditing(id: Number, index: Number) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_START_EDITING,
            id,
            index
        })
    },

    earningPointsStartNew() {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_START_NEW
        })
    },

    earningPointsCancelNew() {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_CANCEL_NEW
        })
    },

    earningPointsCancelEditing() {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_CANCEL_EDITING,
        })
    },

    earningPointsSaveEdit(data: Object, companyCode: string, token: string) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_SAVE_EDIT,
            data,
            companyCode,
            token
        })
    },

    earningPointsSetEdit(data: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_SET_EDIT,
            data
        })
    },

    earningPointsDeleteRow(id: Number, companyCode: string, token: string) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_DELETE_ROW,
            id,
            companyCode,
            token
        })
    },

    earningPointsDeleteRowSucceeded(companyCode: string, token: string) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_DELETE_ROW_SUCCEEDED,
            companyCode,
            token
        })
    },

    earningPointsDeleteRowFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_DELETE_ROW_FAILED,
            errorCode,
            errorMessage
        })
    },

    earningPointsSaveEditSucceeded(companyCode: string, token: string) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_SAVE_EDIT_SUCCEEDED,
            companyCode,
            token
        })
    },

    earningPointsSaveEditFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_SAVE_EDIT_FAILED,
            errorCode,
            errorMessage
        })
    },

    earningPointsSetFilter(companyCode: string, token: string, filter: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_SET_FILTER,
            filter,
            companyCode,
            token
        });
    },

    earningPointsClearFilters(companyCode: string, token: string, pageSize: number) {
        Dispatcher.dispatch({
            type: ActionTypes.EARNING_POINTS_CLEAR_FILTERS,
            companyCode,
            token,
            filter: {
                dateFrom: "1970-01-01",
                dateTo: "1970-01-01",
                hourFrom: "00:00:00",
                hourTo: "00:00:00",
                isForAccumulation: -1,
                customerTag: -1,
                itemCode: "",
                itemTag: -1,
                multiplication: -1,
                accumulation: -1,
                partialAccumulation: -1,
                maxDiscount: -1,
                page: 1,
                recordsPerPage: pageSize,
                sortColumn: 1,
                sortType: 1
            }
        });
    },

    earningPointsShowFilters() {
        Dispatcher.dispatch({ type: ActionTypes.EARNING_POINTS_SHOW_FILTERS });
    },

    earningPointsHideFilters() {
        Dispatcher.dispatch({ type: ActionTypes.EARNING_POINTS_HIDE_FILTERS });
    },

    setData(field: string, newData: any) {
        Dispatcher.dispatch({
            type: ActionTypes.SET_DATA,
            field,
            newData
        })
    },

    // selectorPick(id: string) {
    //     Dispatcher.dispatch({
    //         type: ActionTypes.SELECTOR_PICK,
    //         id,
    //     })
    // },

    selectorUpdateSalesAlert(id: string) {
        Dispatcher.dispatch({
            type: ActionTypes.SELECTOR_UPDATE_SALESALERTS,
            id,
        })
    },

    selectorPickSalesAlert(id: string, pick: duplet) {
        Dispatcher.dispatch({
            type: ActionTypes.SELECTOR_PICK_SALESALERTS,
            id,
            pick,
        })
    },

    selectorUpdatePrices(id: string) {
        Dispatcher.dispatch({
            type: ActionTypes.SELECTOR_UPDATE_PRICES,
            id,
        })
    },

    selectorPickPrices(id: string, pick: duplet) {
        Dispatcher.dispatch({
            type: ActionTypes.SELECTOR_PICK_PRICES,
            id,
            pick,
        })
    },

    selectorUpdateTaxType(id: string) {
        Dispatcher.dispatch({
            type: ActionTypes.SELECTOR_UPDATE_TAXTYPE,
            id,
        })
    },

    selectorPickTaxType(id: string, pick: duplet) {
        Dispatcher.dispatch({
            type: ActionTypes.SELECTOR_PICK_TAX_TYPE,
            id,
            pick,
        })
    },

    selectorUpdateSuppliers(id: string) {
        Dispatcher.dispatch({
            type: ActionTypes.SELECTOR_UPDATE_SUPPLIERS,
            id,
        })
    },

    selectorPickSuppliers(id: string, pick: duplet) {
        Dispatcher.dispatch({
            type: ActionTypes.SELECTOR_PICK_SUPPLIERS,
            id,
            pick,
        })
    },

    selectorPickCustomerFields(id: string, pick: number) {
        Dispatcher.dispatch({
            type: ActionTypes.SELECTOR_PICK_CUSTOMER_FIELDS,
            id,
            pick,
        })
    },

    genericSelectorPick(id: string, pick: number) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_SELECTOR_PICK,
            id,
            pick,
        })
    },
    genericSelectorPickUpdate(id: string, pick: number) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_SELECTOR_PICK_UPDATE,
            id,
            pick,
        })
    },
    // TODO: HHH - test selectors
    genericSelectorRefreshDataset(id: string, companyCode: string, token: string, getScript: string | Object, data: any) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_SELECTOR_REFRESH_DATASET,
                id,
                companyCode,
                token,
                getScript,
                data
            })
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        } else {
            dispatchEvent();
        }

    },

    genericSelectorGetSucceeded(id: string, data: Array<any>) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_SELECTOR_GET_SUCCEEDED,
            id,
            data,
        });
    },

    genericSelectorGetFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_SELECTOR_GET_FAILED,
            errorCode,
            errorMessage,
        });
    },

    genericMultiSelectorRefreshDataset(id: string, companyCode: string, token: string, getScript: string | Object) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_MULTI_SELECTOR_REFRESH_DATASET,
                id,
                companyCode,
                token,
                getScript,
            })
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        } else {
            dispatchEvent();
        }

    },

    genericMultiSelectorGetSucceeded(id: string, data: Array<any>) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_MULTI_SELECTOR_GET_SUCCEEDED,
            id,
            data,
        });
    },

    genericMultiSelectorGetFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_MULTI_SELECTOR_GET_FAILED,
            errorCode,
            errorMessage,
        });
    },


    genericDataGetRequested(who: string, companyCode: string, token: string, getScript: string | Object) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_DATA_GET_REQUESTED,
                companyCode,
                token,
                who,
                getScript
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    genericDataGetSucceeded(who: string, data: Array<any>) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_GET_SUCCEEDED,
            data,
            who
        });
    },

    genericDataGetEditRequested(who: string, companyCode: string, token: string, getScript: string | Object) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_DATA_GET_EDIT_REQUESTED,
                companyCode,
                token,
                who,
                getScript
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    genericDataGetEditSucceeded(who: string, data: Array<any>) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_GET_EDIT_SUCCEEDED,
            data,
            who
        });
    },

    genericDataGetFailed(who: string, errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_GET_FAILED,
            errorCode,
            errorMessage
        })
    },

    genericDataStartEditing(who: string, id: Number, index: Number, firstFieldIsKey: boolean, defaults: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_START_EDITING,
            id,
            index,
            who,
            firstFieldIsKey,
            defaults
        })
    },

    genericDataStartEditingPath(who: string, path: string, id: Number, index: Number) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_START_EDITING_PATH,
            id,
            index,
            who,
            path
        })
    },

    genericDataStartNew(who: string, defaults: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_START_NEW,
            who,
            defaults
        })
    },

    genericDataCancelNew(who: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_CANCEL_NEW,
            who
        })
    },

    genericDataCancelEditing(who: string, ignoreModal: boolean) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_CANCEL_EDITING,
            who,
            ignoreModal
        })
    },

    genericDataSaveEditWithParams(who: string, companyCode: string, token: string, saveScript: string, getScript: string | Object, params: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_SAVE_EDIT_WITH_PARAMS,
            companyCode,
            token,
            saveScript,
            getScript,
            who,
            params,
        })
    },

    genericDataSaveEditWithParamsNext(who: string, companyCode: string, token: string, saveScript: string, getScript: string | Object, params: Object, next: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_SAVE_EDIT_WITH_PARAMS_NEXT,
            companyCode,
            token,
            saveScript,
            getScript,
            who,
            params,
            next,
        })
    },

    genericDataSaveEdit(who: string, companyCode: string, token: string, saveScript: string, getScript: string | Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_SAVE_EDIT,
            companyCode,
            token,
            saveScript,
            getScript,
            who
        })
    },

    genericDataJustSaveEdit(who: string, companyCode: string, token: string, saveScript: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT,
            companyCode,
            token,
            saveScript,
            who
        })
    },

    genericDataJustSaveEditWithParams(who: string, companyCode: string, token: string, saveScript: string, params: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_WITH_PARAMS,
            companyCode,
            token,
            saveScript,
            who,
            params,
        })
    },

    genericDataJustSaveEditNext(who: string, companyCode: string, token: string, saveScript: string, next: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_NEXT,
            companyCode,
            token,
            saveScript,
            who,
            next
        })
    },

    genericDataSetEdit(who: string, data: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_SET_EDIT,
            data,
            who
        })
    },

    genericDataDeleteRow(who: string, id: Number, companyCode: string, token: string, deleteScript: string, getScript: string | Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_DELETE_ROW,
            id,
            companyCode,
            token,
            who,
            deleteScript,
            getScript
        })
    },

    genericDataDeleteRowWithParams(who: string, params: Object, companyCode: string, token: string, deleteScript: string, getScript: string | Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_DELETE_ROW_WITH_PARAMS,
            params,
            companyCode,
            token,
            who,
            deleteScript,
            getScript
        })
    },

    genericDataDeleteRowSucceeded(who: string, companyCode: string, token: string, getScript: string | Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_DELETE_ROW_SUCCEEDED,
            companyCode,
            token,
            who,
            getScript
        })
    },

    genericDataDeleteRowFailed(who: string, errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_DELETE_ROW_FAILED,
            errorCode,
            errorMessage,
            who
        })
    },

    genericDataSaveEditSucceeded(who: string, companyCode: string, token: string, getScript: string | Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_SAVE_EDIT_SUCCEEDED,
            companyCode,
            token,
            who,
            getScript
        })
    },

    genericDataJustSaveEditSucceeded(who: string, companyCode: string, token: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_SUCCEEDED,
            companyCode,
            token,
            who,
        })
    },

    genericDataSaveEditFailed(who: string, errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_SAVE_EDIT_FAILED,
            errorCode,
            errorMessage,
            who
        })
    },

    genericDataSetFilter(who: string, companyCode: string, token: string, filter: Object, getScript: string | Object) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_DATA_SET_FILTER,
                filter,
                companyCode,
                token,
                who,
                getScript
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    genericDataClearFilters(who: string, companyCode: string, token: string, defaultFilter: Object, getScript: string | Object) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_DATA_CLEAR_FILTERS,
                companyCode,
                token,
                filter: defaultFilter,
                who,
                getScript
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    genericDataShowFilters(who: string) {
        Dispatcher.dispatch({ type: ActionTypes.GENERIC_DATA_SHOW_FILTERS, who });
    },

    genericDataHideFilters(who: string) {
        Dispatcher.dispatch({ type: ActionTypes.GENERIC_DATA_HIDE_FILTERS, who });
    },

    genericDataStartInlineCreate(who: string, defaults: Object) {
        Dispatcher.dispatch({ type: ActionTypes.GENERIC_DATA_START_INLINE_CREATE, who, defaults });
    },

    genericDataCancelInlineCreate(who: string, ignoreModal: boolean) {
        Dispatcher.dispatch({ type: ActionTypes.GENERIC_DATA_CANCEL_INLINE_CREATE, who, ignoreModal });
    },

    bargainTypesGetRequested(companyCode: string, token: string) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.BARGAIN_TYPES_GET_REQUESTED,
                companyCode,
                token,
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    bargainTypesGetSucceeded(data: Array<string>) {
        Dispatcher.dispatch({
            type: ActionTypes.BARGAIN_TYPES_GET_SUCCEEDED,
            data
        });
    },

    bargainTypesGetFailed(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.BARGAIN_TYPES_GET_FAILED,
            errorCode,
            errorMessage
        })
    },



    genericDataGetEditWithParams(who: string, companyCode: string, token: string, params: Object, getScript: string | Object) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_DATA_GET_EDIT_WITH_PARAMS,
                companyCode,
                token,
                who,
                getScript,
                params
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    genericDataSaveEditMulti(who: string, companyCode: string, token: string, saveScript: string, getScript: string | Object, sections: Array<Object>) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_SAVE_EDIT_MULTI,
            companyCode,
            token,
            saveScript,
            getScript,
            who,
            sections
        })
    },

    genericDataJustSaveEditMulti(who: string, companyCode: string, token: string, saveScript: string, sections: Array<Object>, next: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_MULTI,
            companyCode,
            token,
            saveScript,
            who,
            sections,
            next
        })
    },

    genericDataGetWithParams(who: string, companyCode: string, token: string, params: Object, getScript: string | Object) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_DATA_GET_WITH_PARAMS,
                companyCode,
                token,
                who,
                params,
                getScript
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    dataTableAddRow(who: string, row: string | Object, wasInline: boolean, flattenData: boolean) {
        Dispatcher.dispatch({
            type: ActionTypes.DATA_TABLE_ADD_ROW,
            who,
            row,
            wasInline,
            flattenData
        })
    },

    dataTableSetRow(who: string, index: number, row: string | Object, flattenData: boolean) {
        Dispatcher.dispatch({
            type: ActionTypes.DATA_TABLE_SET_ROW,
            who,
            index,
            row,
            flattenData
        })
    },

    dataTableDeleteRow(who: string, index: number) {
        Dispatcher.dispatch({
            type: ActionTypes.DATA_TABLE_DELETE_ROW,
            who,
            index
        })
    },

    genericJsonGet(who: string, companyCode: string, token: string, params: Object, getScript: string | Object) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_JSON_GET,
                companyCode,
                token,
                who,
                params,
                getScript
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    genericJsonGetAndEdit(who: string, companyCode: string, token: string, params: Object, getScript: string | Object) {
        const dispatchEvent = function () {
            Dispatcher.dispatch({
                type: ActionTypes.GENERIC_JSON_GET_AND_EDIT,
                companyCode,
                token,
                who,
                params,
                getScript
            });
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchEvent, 1);
        }
        else {
            dispatchEvent();
        }
    },

    genericJsonGetSucceeded(who: string, data: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_JSON_GET_SUCCEEDED,
            who,
            data
        })
    },

    genericJsonGetAndEditSucceeded(who: string, data: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_JSON_GET_AND_EDIT_SUCCEEDED,
            who,
            data
        })
    },

    genericJsonGetFailed(who: string, errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_JSON_GET_FAILED,
            who,
            errorCode,
            errorMessage
        })
    },

    genericJsonSet(who: string, path: string, companyCode: string, token: string, saveScript: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_JSON_SET,
            who,
            path,
            companyCode,
            token,
            saveScript
        })
    },

    genericJsonSetAndReload(who: string, path: string, companyCode: string, token: string, saveScript: string, getScript: string | Object) {
        Dispatcher.dispatch(({
            type: ActionTypes.GENERIC_JSON_SET_AND_RELOAD,
            who,
            path,
            companyCode,
            token,
            saveScript,
            getScript
        }))
    },

    genericJsonSetSucceeded(who: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_JSON_SET_SUCCEEDED,
            who
        })
    },

    genericJsonSetAndReloadSucceeded(who: string, companyCode: string, token: string, getScript: string | Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_JSON_SET_AND_RELOAD_SUCCEEDED,
            who,
            companyCode,
            token,
            getScript
        })
    },

    genericJsonSetFailed(who: string, errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_JSON_SET_FAILED,
            who,
            errorCode,
            errorMessage
        })
    },

    setNewDataState(newState: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.SET_DATA_STATE,
            newState
        })
    },

    /**
     * Performs a major generic network request to the Job Master server. Major in the sense that it will show ui indications and will not be a silent background request.
     * @param script The name of the script for the job
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param payloadBuilder A function that receives the request object as it sole parameter and whose job is to set the input/payload
     * @param stateBuilder A function that will be called upon the request return (either failed or successful). The function has 3 parameters:
     * + (A clone of) the current state
     * + error information or null in case of success
     * + data as it was returned from the server or null in case of failure
     * @param messages An object of message strings to the three states of the operation:
     * + loading
     * + failed
     * + success
     */
    genericNetworkRequest(script: string, companyCode: string, token: string, payloadBuilder: Function, stateBuilder: Function, messages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_NETWORK_REQUEST,
            script,
            companyCode,
            token,
            payloadBuilder,
            stateBuilder,
            messages
        });
    },

    genericNetworkRequestSucceeded(stateBuilder: Function, data: any, messages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_NETWORK_REQUEST_SUCCEEDED,
            stateBuilder,
            data,
            messages
        })
    },

    genericNetworkRequestFailed(stateBuilder: Function, errorCode: number, errorMessage: string, messages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERIC_NETWORK_REQUEST_FAILED,
            stateBuilder,
            errorCode,
            errorMessage,
            messages
        })
    },

    ////////////////////////////////////////////////////////
    /**
     * Fetches data from the server and saves it in the data store
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param request Object containing the script to call and the call parameters
     * @param path The path in the store where the data is to be saved
     * @param messages Objects containing the strings for the message ui
     * @param autoEditMode Boolean whether should enter edit mode automatically
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkFetchData(companyCode: string, token: string, request: { script: string, params: Object }, path: string, messages: { loading: string, failed: string, success: string }, autoEditMode: boolean, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        const dispatchAction = function () {
            Dispatcher.dispatch({
                type: ActionTypes.NETWORK_FETCH_DATA,
                companyCode,
                token,
                request,
                path,
                messages,
                autoEditMode,
                successAction,
                dataStoreWriter
            })
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchAction, 1);
        } else {
            dispatchAction();
        }

    },
    networkFetchDataSuccess(path: string, data: string, autoEditMode: boolean,
        messages: { loading: string, failed: string, success: string },
        dataStoreWriter: Function | null,
        successAction: { action: Function, args: Array<any> }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_FETCH_DATA_SUCCESS,
            path,
            data,
            autoEditMode,
            messages,
            dataStoreWriter,
            successAction
        })
    },
    networkFetchDataFail(errorCode: number, errorMessage: string, messages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_FETCH_DATA_FAIL,
            errorCode,
            errorMessage,
            messages
        })
    },
    /**
     * Fetches a JSON object from the server and stores it in the data store
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param request Request object containing the script and the call's parameters
     * @param path The path in the store where to store the new data
     * @param messages String object for the messages ui
     * @param autoEditMode Boolean whether should enter edit mode automatically
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkFetchJson(companyCode: string, token: string, request: { script: string, params: Object }, path: string, messages: { loading: string, failed: string, success: string }, autoEditMode: boolean, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        const dispatchAction = function () {
            Dispatcher.dispatch({
                type: ActionTypes.NETWORK_FETCH_JSON,
                companyCode,
                token,
                request,
                path,
                messages,
                autoEditMode,
                successAction,
                dataStoreWriter
            })
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchAction, 1);
        } else {
            dispatchAction();
        }
    },
    networkFetchJsonSuccess(path: string, data: string, autoEditMode: boolean, messages: { loading: string, failed: string, success: string }, dataStoreWriter: Function | null, successAction: { action: Function, args: Array<any> }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_FETCH_JSON_SUCCESS,
            path,
            data,
            autoEditMode,
            messages,
            dataStoreWriter,
            successAction
        })
    },
    networkFetchJsonFail(errorCode: number, errorMessage: string, messages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_FETCH_JSON_FAIL,
            errorCode,
            errorMessage,
            messages
        })
    },
    /**
     * Fetches data from the server and stores it AS IS in the data store
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param request Request object containing the script and the call's parameters
     * @param path The path in the store where to store the new data
     * @param messages String object for the messages ui
     * @param autoEditMode Boolean whether should enter edit mode automatically
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkFetch(companyCode: string, token: string, request: { script: string, params: Object }, path: string, messages: { loading: string, failed: string, success: string }, autoEditMode: boolean, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        const dispatchAction = function () {
            Dispatcher.dispatch({
                type: ActionTypes.NETWORK_FETCH,
                companyCode,
                token,
                request,
                path,
                messages,
                autoEditMode,
                successAction,
                dataStoreWriter
            })
        };

        if (Dispatcher.isDispatching()) {
            setTimeout(dispatchAction, 1);
        } else {
            dispatchAction();
        }
    },
    networkFetchSuccess(path: string, data: string, autoEditMode: boolean, messages: { loading: string, failed: string, success: string }, dataStoreWriter: Function | null, successAction: { action: Function, args: Array<any> }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_FETCH_SUCCESS,
            path,
            data,
            autoEditMode,
            messages,
            dataStoreWriter,
            successAction
        })
    },
    networkFetchFail(errorCode: number, errorMessage: string, messages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_FETCH_FAIL,
            errorCode,
            errorMessage,
            messages
        })
    },
    /**
     * Sends to the server the data located in the store, found by path. Response if exists updates the store at the response path
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param request Object specifying the script and params
     * @param path Path indicating the location of the data to be sent
     * @param headersPath Path for the data headers
     * @param responsePath Path indicating the location of where to store (Jax mutate) the result
     * @param messages String object for the ui message
     * @param autoEditMode whether should go directly to edit mode upon arrival
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkPostData(companyCode: string, token: string, request: { script: string, params: Object }, path: string, headersPath: string, responsePath: string, messages: { loading: string, failed: string, success: string }, autoEditMode: boolean, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_DATA,
            companyCode,
            token,
            request,
            path,
            headersPath,
            responsePath,
            messages,
            successAction,
            dataStoreWriter
        })
    },
    networkPostDataSuccess(data: string, responsePath: string, messages: { loading: string, failed: string, success: string }, dataStoreWriter: Function | null, successAction: { action: Function, args: Array<any> }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_DATA_SUCCESS,
            data,
            responsePath,
            messages,
            dataStoreWriter,
            successAction
        })
    },
    networkPostDataFail(errorCode: number, errorMessage: string, messages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_DATA_FAIL,
            errorCode,
            errorMessage,
            messages
        })
    },
    /**
     * Sends to the server the JSON object indicated by path and merges the response back into the store at responsePath
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param request The request object containing the script and parameters
     * @param path The path of the data to be sent in the store
     * @param responsePath The path of the response destination
     * @param messages String object for the message ui
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkPostJson(companyCode: string, token: string, request: { script: string, params: Object }, path: string, responsePath: string, messages: { loading: string, failed: string, success: string }, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_JSON,
            companyCode,
            token,
            request,
            path,
            responsePath,
            messages,
            successAction,
            dataStoreWriter
        })
    },
    networkPostJsonAsData(companyCode: string, token: string, request: { script: string, params: Object }, path: string, responsePath: string, messages: { loading: string, failed: string, success: string }, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_JSON_AS_DATA,
            companyCode,
            token,
            request,
            path,
            responsePath,
            messages,
            successAction,
            dataStoreWriter
        })
    },
    networkPostJsonSuccess(data: string, responsePath: string, messages: { loading: string, failed: string, success: string }, dataStoreWriter: Function | null, successAction: { action: Function, args: Array<any> }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_JSON_SUCCESS,
            data,
            responsePath,
            messages,
            dataStoreWriter,
            successAction
        })
    },
    networkPostJsonFail(errorCode: number, errorMessage: string, messages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_JSON_FAIL,
            errorCode,
            errorMessage,
            messages
        })
    },
    /**
     * Sends to the server the JSON object indicated by path and merges the response back into the store at responsePath
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param request The request object containing the script and parameters
     * @param path The path of the data to be sent in the store
     * @param responsePath The path of the response destination
     * @param messages String object for the message ui
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkPost(companyCode: string, token: string, request: { script: string, params: Object }, path: string, responsePath: string, messages: { loading: string, failed: string, success: string }, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST,
            companyCode,
            token,
            request,
            path,
            responsePath,
            messages,
            successAction,
            dataStoreWriter
        })
    },
    networkPostSuccess(data: string, responsePath: string, messages: { loading: string, failed: string, success: string }, dataStoreWriter: Function | null, successAction: { action: Function, args: Array<any> }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_SUCCESS,
            data,
            responsePath,
            messages,
            dataStoreWriter,
            successAction
        })
    },
    networkPostFail(errorCode: number, errorMessage: string, messages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_FAIL,
            errorCode,
            errorMessage,
            messages
        })
    },
    /**
     * Double action of posting (and updating store with post response if any) and then performing fetch
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param requestPost Request object for the post action (script and parameters)
     * @param requestFetch Request object for the fetch action (script and parameters)
     * @param pathPost Path in the store of the data to be sent
     * @param headersPath Path for the data headers
     * @param pathResponse Path in the store to be merges with the response
     * @param pathFetch Path in the store where to save the data coming from the server
     * @param postMessages Post messages for the ui
     * @param fetchMessages Fetch messages for the ui
     * @param autoEditMode Should enter edit mode after fetch
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkPostAndThenFetchData(companyCode: string, token: string, requestPost: { script: string, params: Object }, requestFetch: { script: string, params: Object }, pathPost: string, headersPath: string, pathResponse: string, pathFetch: string, postMessages: { loading: string, failed: string, success: string }, fetchMessages: { loading: string, failed: string, success: string }, autoEditMode: boolean, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_AND_THEN_FETCH_DATA,
            companyCode,
            token,
            requestPost,
            requestFetch,
            pathPost,
            headersPath,
            pathResponse,
            pathFetch,
            postMessages,
            fetchMessages,
            autoEditMode,
            successAction,
            dataStoreWriter
        })
    },
    networkPostAndThenFetchDataSuccess(companyCode: string, token: string, data: string, pathResponse: string, requestFetch: { script: string, params: Object }, pathFetch: string, postMessages: { loading: string, failed: string, success: string }, fetchMessages: { loading: string, failed: string, success: string }, autoEditMode: boolean, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_AND_THEN_FETCH_DATA_SUCCESS,
            companyCode,
            token,
            data,
            pathResponse,
            requestFetch,
            pathFetch,
            postMessages,
            fetchMessages,
            autoEditMode,
            dataStoreWriter
        })
    },
    networkPostAndThenFetchDataFail(errorCode: number, errorMessage: string, postMessages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_AND_THEN_FETCH_DATA_FAIL,
            errorCode,
            errorMessage,
            postMessages
        })
    },
    /**
     * Double action of posting (and updating store with post response if any) and then performing fetch
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param requestPost Request object for the post action (script and parameters)
     * @param requestFetch Request object for the fetch action (script and parameters)
     * @param pathPost Path in the store of the data to be sent
     * @param pathResponse Path in the store to be merges with the response
     * @param pathFetch Path in the store where to save the data coming from the server
     * @param postMessages Post messages for the ui
     * @param fetchMessages Fetch messages for the ui
     * @param autoEditMode Should enter edit mode after fetch
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkPostAndThenFetchJson(companyCode: string, token: string, requestPost: { script: string, params: Object }, requestFetch: { script: string, params: Object }, pathPost: string, pathResponse: string, pathFetch: string, postMessages: { loading: string, failed: string, success: string }, fetchMessages: { loading: string, failed: string, success: string }, autoEditMode: boolean, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_AND_THEN_FETCH_JSON,
            companyCode,
            token,
            requestPost,
            requestFetch,
            pathPost,
            pathResponse,
            pathFetch,
            postMessages,
            fetchMessages,
            autoEditMode,
            successAction,
            dataStoreWriter
        })
    },
    networkPostAndThenFetchJsonSuccess(companyCode: string, token: string, data: string, pathResponse: string, requestFetch: { script: string, params: Object }, pathFetch: string, postMessages: { loading: string, failed: string, success: string }, fetchMessages: { loading: string, failed: string, success: string }, autoEditMode: boolean, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_AND_THEN_FETCH_JSON_SUCCESS,
            companyCode,
            token,
            data,
            pathResponse,
            requestFetch,
            pathFetch,
            postMessages,
            fetchMessages,
            autoEditMode,
            dataStoreWriter
        })
    },
    networkPostAndThenFetchJsonFail(errorCode: number, errorMessage: string, postMessages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_AND_THEN_FETCH_JSON_FAIL,
            errorCode,
            errorMessage,
            postMessages
        })
    },
    /**
     * Double action of posting (and updating store with post response if any) and then performing fetch
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param requestPost Request object for the post action (script and parameters)
     * @param requestFetch Request object for the fetch action (script and parameters)
     * @param pathPost Path in the store of the data to be sent
     * @param pathResponse Path in the store to be merges with the response
     * @param pathFetch Path in the store where to save the data coming from the server
     * @param postMessages Post messages for the ui
     * @param fetchMessages Fetch messages for the ui
     * @param autoEditMode Should enter edit mode after fetch
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkPostJsonAndThenFetchData(companyCode: string, token: string, requestPost: { script: string, params: Object }, requestFetch: { script: string, params: Object }, pathPost: string, pathResponse: string, pathFetch: string, postMessages: { loading: string, failed: string, success: string }, fetchMessages: { loading: string, failed: string, success: string }, autoEditMode: boolean, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_JSON_AND_THEN_FETCH_DATA,
            companyCode,
            token,
            requestPost,
            requestFetch,
            pathPost,
            pathResponse,
            pathFetch,
            postMessages,
            fetchMessages,
            autoEditMode,
            successAction,
            dataStoreWriter
        })
    },
    networkPostJsonAndThenFetchDataSuccess(companyCode: string, token: string, data: string, pathResponse: string, requestFetch: { script: string, params: Object }, pathFetch: string, postMessages: { loading: string, failed: string, success: string }, fetchMessages: { loading: string, failed: string, success: string }, autoEditMode: boolean, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_JSON_AND_THEN_FETCH_DATA_SUCCESS,
            companyCode,
            token,
            data,
            pathResponse,
            requestFetch,
            pathFetch,
            postMessages,
            fetchMessages,
            autoEditMode,
            dataStoreWriter
        })
    },
    networkPostJsonAndThenFetchDataFail(errorCode: number, errorMessage: string, postMessages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_JSON_AND_THEN_FETCH_DATA_FAIL,
            errorCode,
            errorMessage,
            postMessages
        })
    },
    /**
     * Double action of posting (and updating store with post response if any) and then performing fetch
     * @param companyCode Caller's company code
     * @param token Caller's token
     * @param requestPost Request object for the post action (script and parameters)
     * @param requestFetch Request object for the fetch action (script and parameters)
     * @param pathPost Path in the store of the data to be sent
     * @param headersPath Path for the data headers
     * @param pathResponse Path in the store to be merges with the response
     * @param pathFetch Path in the store where to save the data coming from the server
     * @param postMessages Post messages for the ui
     * @param fetchMessages Fetch messages for the ui
     * @param autoEditMode Should enter edit mode after fetch
     * @param successAction Object the defining the action and args to dispatch upon success
     * @param dataStoreWriter An optional function to further manipulate the data store
     */
    networkPostDataAndThenFetchJson(companyCode: string, token: string, requestPost: { script: string, params: Object }, requestFetch: { script: string, params: Object }, pathPost: string, headersPath: string, pathResponse: string, pathFetch: string, postMessages: { loading: string, failed: string, success: string }, fetchMessages: { loading: string, failed: string, success: string }, autoEditMode: boolean, successAction: { action: Function, args: Array<any> }, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_DATA_AND_THEN_FETCH_JSON,
            companyCode,
            token,
            requestPost,
            requestFetch,
            pathPost,
            headersPath,
            pathResponse,
            pathFetch,
            postMessages,
            fetchMessages,
            autoEditMode,
            successAction,
            dataStoreWriter
        })
    },
    networkPostDataAndThenFetchJsonSuccess(companyCode: string, token: string, data: string, pathResponse: string, requestFetch: { script: string, params: Object }, pathFetch: string, postMessages: { loading: string, failed: string, success: string }, fetchMessages: { loading: string, failed: string, success: string }, autoEditMode: boolean, dataStoreWriter: Function | null) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_DATA_AND_THEN_FETCH_JSON_SUCCESS,
            companyCode,
            token,
            data,
            pathResponse,
            requestFetch,
            pathFetch,
            postMessages,
            fetchMessages,
            autoEditMode,
            dataStoreWriter
        })
    },
    networkPostDataAndThenFetchJsonFail(errorCode: number, errorMessage: string, postMessages: { loading: string, failed: string, success: string }) {
        Dispatcher.dispatch({
            type: ActionTypes.NETWORK_POST_DATA_AND_THEN_FETCH_JSON_FAIL,
            errorCode,
            errorMessage,
            postMessages
        })
    },

    generateReport(companyCode: string, token: string, reportData: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERATE_REPORT,
            companyCode,
            token,
            reportData
        })
    },

    generateReportSuccess(data: any, reportName: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERATE_REPORT_SUCCESS,
            data,
            reportName
        })
    },

    generateReportFail(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.GENERATE_REPORT_FAIL,
            errorCode,
            errorMessage
        })
    },

    imageUploadRequested(companyCode: string, token: string, imageType: string, box: number, data: string, setImageCallback: Function) {
        Dispatcher.dispatch({
            type: ActionTypes.IMAGE_UPLOAD_REQUESTED,
            companyCode,
            token,
            imageType,
            box,
            data,
            setImageCallback
        })
    },

    imageUploadSucceeded(data: string, setImageCallback: Function) {
        Dispatcher.dispatch({
            type: ActionTypes.IMAGE_UPLOAD_SUCCESS,
            data,
            setImageCallback
        })
    },

    imageUploadFail(errorCode: number, errorMessage: string) {
        Dispatcher.dispatch({
            type: ActionTypes.IMAGE_UPLOAD_FAILED,
            errorCode,
            errorMessage
        })
    },

    // update default value at specific column of table 
    setDefault(who: string, rowKey: any, colIndex: any) {
        Dispatcher.dispatch({
            type: ActionTypes.SET_DEFAULT,
            who,
            rowKey,
            colIndex
        })
    },

    // update default value at specific column of table or none
    setActive(who: string, rowKey: any, colKey: string, colIndex: any) {
        Dispatcher.dispatch({
            type: ActionTypes.SET_ACTIVE,
            who,
            rowKey,
            colKey,
            colIndex
        })
    },

    raiseWorkInProgressFlag() {
        Dispatcher.dispatch({
            type: ActionTypes.RAISE_WORKINPROGRESS_FLAG
        })
    },

    lowerWorkInProgressFlag() {
        Dispatcher.dispatch({
            type: ActionTypes.LOWER_WORKINPROGRESS_FLAG
        })
    },

    setJson(who: string, obj: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.SET_JSON,
            who,
            obj,
        })
    },

    overrideJson(who: string, obj: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.OVERRIDE_JSON,
            who,
            obj,
        })
    },

    setJsonUI(who: string, obj: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.SET_JSON_UI,
            who,
            obj,
        })
    },

    overrideJsonUI(who: string, obj: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.OVERRIDE_JSON_UI,
            who,
            obj,
        })
    },

    clearDataStore() {
        Dispatcher.dispatch({
            type: ActionTypes.CLEAR_DATA_STORE,
        })
    }

};
export default Actions;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Button, Card, Tooltip, Icon, Row, Col } from "antd";
import moment from "moment";


class KdsBonItem extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            changeStatusModal: false,
            shortFinish: false,
            hideWhenFinish: false,
        };

        this.api = {
            edit: 'edit_bon_ready_status',
            update_status: "update_status_external_orders_force",
        }

        this.secTitle = '';
    }

    timeFormatHHmm = (time) => {
        return time ? time.split(':').slice(0, -1).join(':') : ''
    }

    getItemView = (item, last) => {
        let readyStatus = parseInt(item.readyStatus) ? true : false;

        return (<Row
            style={{
                background: this.props.isShort ? this.getColorWhenShort() : readyStatus ? 'lawngreen' : '#FAEBD7',
                fontSize: this.props.cardWidth * 0.05,
                border: '1px solid black',
                borderBottomRightRadius: last ? 10 : 0,
                borderBottomLeftRadius: last ? 10 : 0,
            }}
            onClick={() => { if (!this.props.onClick) this.updateReadyStatus('\r' + (readyStatus ? '0' : '2') + '\f' + item.id) }}>
            <Col span={20} style={{ borderLeft: '1px solid black', padding: 2, paddingRight: this.props.cardWidth * 0.02, cursor: 'pointer' }}><b>{item.itemName}</b></Col>
            <Col span={4} style={{ padding: 2, paddingRight: this.props.cardWidth * 0.02 }}><b>{parseFloat(item.qty)}</b></Col>
        </Row>
        )
    }

    getGroupView = (group, last) => {
        let itemStyle = {
            borderLeft: '1px solid black',
            padding: 2,
            paddingRight: this.props.cardWidth * 0.02,
            cursor: 'pointer'
        }

        return (<div
            style={{
                border: '1px solid black',
                borderBottomRightRadius: last ? 10 : 0,
                borderBottomLeftRadius: last ? 10 : 0,
            }}>
            <Row
                style={{
                    background: this.props.isShort ? this.getColorWhenShort() : group.readyStatus ? 'lawngreen' : '#FAEBD7',
                    fontSize: this.props.cardWidth * 0.05,
                }}
                onClick={() => { if (!this.props.onClick) this.updateReadyStatus('\r' + group.data.map(x => (group.readyStatus ? '0' : '2') + '\f' + x.id).join('\r')) }}>
                <Col span={20} style={itemStyle}><b>{group.groupTitle}</b></Col>
                <Col span={4}></Col>
            </Row>

            {group.data.map((item, index) => {
                let readyStatus = parseInt(item.readyStatus) ? true : false;

                return (<span>
                    <Row
                        style={{
                            background: this.props.isShort ? this.getColorWhenShort() : readyStatus ? 'lawngreen' : '#FAEBD7',
                            fontSize: this.props.cardWidth * 0.03,
                            borderBottomRightRadius: last && !group.data[index + 1] ? 10 : 0,
                            borderBottomLeftRadius: last && !group.data[index + 1] ? 10 : 0,
                        }}
                        onClick={() => { if (!this.props.onClick) this.updateReadyStatus('\r' + (readyStatus ? '0' : '2') + '\f' + item.id) }}>
                        <Col span={8}>{this.getGroupTitle(item.title)}</Col>
                        <Col span={12} style={itemStyle}><b>{item.itemName}</b></Col>
                        <Col span={4} style={{ padding: 2, paddingRight: this.props.cardWidth * 0.02, }}><b>{parseFloat(item.qty)}</b></Col>
                    </Row>
                </span>)
            })}
        </div>)
    }

    getGroupTitle = (title) => {
        let style = {
            paddingRight: this.props.cardWidth * 0.02,
            fontSize: this.props.cardWidth * 0.02
        }
        if (this.props.isShort) {
            let background = this.getColorWhenShort();
            let rStyle = {
                width: 20,
                height: 20,
                borderRadius: 10,
                padding: 2,
                background: '#444444',
                margin: 'auto',
            }
            let h1Style = {
                width: 8,
                height: 16,
                background,
                marginRight: 8,
                marginLeft: -8,
                borderBottomLeftRadius: 8,
                borderTopLeftRadius: 8,
            }
            let h2Style = {
                width: 8,
                height: 16,
                background,
                borderBottomRightRadius: 8,
                borderTopRightRadius: 8,
            }
            switch (title) {
                case 'על הכל': return (<div style={rStyle}></div>);
                case 'חצי ימני': return (<div style={rStyle}><div style={h1Style}></div></div>);
                case 'חצי שמאלי': return (<div style={rStyle}><div style={h2Style}></div></div>);
            }
        }
        return (<b style={style}>{title}</b>)
    }

    updateReadyStatus = (dataSend) => {
        if (this.props.kdsData.sZoomEnable == '2')
            this.props.sendAPI(this.api.edit, '_readyStatus\f_lineID' + dataSend, (ob) => {
                let data = ob.data ? ob.data.split("\r") : []
                this.setState({ changeStatusModal: data.length && data.slice(-1)[0] === '0' }, this.props.handleChange)
            })
    }

    modalZoom = (content, color) => {
        let gt = this.props.getText;
        let oNumber = this.props.owner ? this.props.owner.oNumber : "";

        let topStart = 50;
        let leftStart = (window.innerWidth - this.props.cardWidth) / 2;
        let widthCard = this.props.cardWidth;

        let titleStyle = {
            fontSize: widthCard * 0.06,
            padding: widthCard * 0.02,
            marginBottom: 0,
            background: color,
            textAlign: 'center',
        }

        return [
            <div className={'ant-modal-mask'} />,
            <div
                onClick={this.props.closeModal}
                style={{
                    position: 'fixed',
                    top: topStart + 10,
                    left: leftStart + 10,
                    zIndex: this.state.changeStatusModal ? 200 : 3000,
                    borderRadius: '50%',
                    background: '#FAEBD7',
                    width: 40,
                    height: 40,
                    fontSize: 25,
                    padding: 2,
                    textAlign: 'center',
                    cursor: 'pointer'
                }}>
                <Icon type="close" />
            </div>,
            <div style={{
                position: 'fixed',
                top: topStart,
                left: leftStart,
                zIndex: this.state.changeStatusModal ? 100 : 2000,

                background: '#FAEBD7',
                maxHeight: window.innerHeight - 100,
                width: widthCard,
                overflowY: 'auto',
                border: '2px solid ' + color,
                borderRadius: 10,
            }}>
                {this.props.isFromChecker ? (<h1 style={titleStyle}>{gt(15889)}: {oNumber}</h1>) : ""}
                <h2
                    style={{ ...titleStyle, background: this.props.bon.color, cursor: 'pointer' }}
                    onClick={() => {
                        let sendUpdate = '';
                        this.props.bonData.forEach(x => {
                            sendUpdate += '\r' + (this.props.bon.color === 'lawngreen' ? '0' : '2') + '\f' + x.id
                        })
                        this.updateReadyStatus(sendUpdate)
                    }}>
                    {this.props.isFromChecker ? this.props.bon ? this.props.bon.name : "" : gt(15889) + ': ' + oNumber}
                </h2>
                {content}
            </div>,
            this.state.changeStatusModal && this.props.kdsData.sZoomEnable == '2' ? this.modalChangeStatus(color) : ""
        ]
    }

    modalChangeStatus = (color) => {
        let gt = this.props.getText;

        let topStart = 50;
        let leftStart = (window.innerWidth - this.props.cardWidth) / 2;
        let widthCard = this.props.cardWidth;

        const { isShort } = this.props;

        return [
            <div className={'ant-modal-mask'} />,

            <div style={{
                position: 'fixed',
                top: topStart,
                left: leftStart,
                zIndex: 2000,
                fontSize: 40,
                background: '#FAEBD7',
                maxHeight: window.innerHeight - 100,
                width: widthCard,
                overflowY: 'auto',
                border: '2px solid ' + color,
                borderRadius: 10,
            }}>
                <p>{gt(16039)}</p>
                <Button style={{ background: color }} onClick={this.finishOrder}>{gt(isShort ? 16484 : 16040)}</Button>
                {isShort ? <Button style={{ background: color }} onClick={this.printLabel}>{gt(16485)}</Button> : ""}
                <Button onClick={() => { this.setState({ changeStatusModal: false, shortFinish: false }) }}>{gt(16041)}</Button>
            </div>
        ]
    }


    finishOrder = () => {
        this.setState({ hideWhenFinish: true }, () => {
            let dataSend = 'eStatus\feOwnerId\r' + this.props.kdsData.sStatusListLeft + '\f' + this.props.owner.oID;
            this.props.sendAPI(this.api.update_status, dataSend, () => {
                if (this.props.isShort) this.printLabel()
                else this.closeModal()
            })
        })
    }

    closeModal = () => {
        this.setState({ changeStatusModal: false, shortFinish: false }, () => {
            if (this.props.closeModal) this.props.closeModal()
            if (this.props.getDataApi) this.props.getDataApi()
        })
    }

    printLabel = () => {
        let params = window.location.search ? window.location.search.split('&').map(x => x.split('=')) : [];
        let pLang = params.find(f => f[0] === 'lang');
        let lang = pLang ? pLang[1] : '2'

        let dataSend = '_ownerId\f_bonId\freportName\fREPORT_LOCALE\r' + this.props.owner.oID + '\f' + this.props.kdsData.sItemTagId + '\fLabels/KdsBon.pdf\f';
        switch (lang) {
            case '1': dataSend += 'en'; break;
            case '2': dataSend += 'iw_IL'; break;
            case '3': dataSend += 'ar'; break;
        }

        this.props.sendAPI('generate_report', dataSend, (ob) => {
            this.closeModal()
            const { blob } = ob;
            const a = document.createElement("a");
            let fileName = 'KdsBon.pdf';
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
        })
    }

    getColorWhenShort = () => {
        // let changeColorTime = this.props.kdsData.sScrollTime;
        // let orderTime = this.props.owner.orderTime
        // let overTime = moment(orderTime, "DD/MM HH:mm").valueOf() + (changeColorTime * 1000);
        // return Date.now() < overTime ? 'lawngreen' : 'pink';
        return 'lawngreen';
    }


    render() {

        let gt = this.props.getText;

        let cardWidth = this.props.cardWidth;
        let color = this.props.isShort ? this.getColorWhenShort() : !this.props.owner || !this.props.owner._notFinishBons || !this.props.owner._notFinishBons.length ? 'lawngreen' : 'pink';

        let cardStyle = {
            background: '#FAEBD7',
            display: 'inline-block',
            verticalAlign: 'text-top',
            maxHeight: cardWidth * 1.2,
            width: cardWidth,
            overflowY: 'auto',
            border: '2px solid ' + color,
            borderRadius: 10,
            cursor: this.props.onClick ? 'pointer' : 'auto',
            margin: 10,
        }

        let titleStyle = {
            fontSize: cardWidth * 0.06,
            padding: cardWidth * 0.02,
            marginBottom: 0,
            background: this.props.isShort ? this.getColorWhenShort() : this.props.bon ? this.props.bon.color : "auto",
            textAlign: 'center',
        }


        let bonDataGroups = [];
        if (this.props.bonData && this.props.bonData.length) {
            this.props.bonData.forEach(x => {
                let itemIndex = bonDataGroups.findIndex(f => f.group === x.group)
                if (itemIndex === -1) {
                    bonDataGroups.push({ group: x.group, groupTitle: x.groupTitle, data: [x] })
                } else {
                    bonDataGroups[itemIndex] = { ...bonDataGroups[itemIndex], data: [...bonDataGroups[itemIndex].data, x] }
                }
            })
        }

        bonDataGroups = bonDataGroups.map(x => {
            return { ...x, readyStatus: x.data.length === x.data.filter(f => parseInt(f.readyStatus)).length }
        });

        let content = bonDataGroups.map((x, i) => {
            if (x.data.length === 1 && !x.groupTitle) {
                return this.getItemView(x.data[0], !bonDataGroups[i + 1]);
            } else {
                return this.getGroupView(x, !bonDataGroups[i + 1]);
            }
        })

        if (this.props.isModalZoom) {
            return this.modalZoom(content, color)
        } else if (!this.state.hideWhenFinish) {
            return (<div
                style={cardStyle}
                onClick={() => {
                    if (this.props.isShort && !this.state.shortFinish) this.setState({ shortFinish: true })
                    else if (!this.props.isShort && this.props.onClick) this.props.onClick()
                }} >
                {this.props.isShort ? (
                    <h1 style={{ ...titleStyle, fontSize: cardWidth * 0.04 }}>
                        <Row>
                            <Col span={8}>{this.props.owner ? this.props.owner.orderMethod : ""}</Col>
                            <Col span={8}>{this.props.owner ? this.props.owner.orderTime : ""}</Col>
                            <Col span={8}>{this.props.owner ? this.props.owner.oNumber : ""}</Col>
                        </Row>
                    </h1>
                ) : (
                    <h1 style={titleStyle}>{gt(15889)}: {this.props.owner ? this.props.owner.oNumber : ""}</h1>
                )}
                {content}
                {this.state.shortFinish ? this.modalChangeStatus(color) : ""}
            </div>)

        } else {
            return ''
        }
    }
}

export default KdsBonItem;
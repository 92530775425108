/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { Input, Icon, Button, Tooltip } from "antd";
import LangContext from "../contextProvider/LangContext";
import moment from "moment";

type Props = {
  title: string,
  actionButtons: any[],
  showsearch?: any,
  setSearch: any,
  searchCallback?: (any) => void,
  apiCallData?: any,
  isModal?: boolean,
};

type State = {};

class PageToolbar extends React.Component<Props, State> {

  state = { valueSearch: "" }

  static contextType = LangContext;

  getText = (id) => {
    if (this.props.getText) return this.props.getText(id)
    else return this.context && this.context.get(id) ? this.context.get(id) : '[' + id + ']'
  }

  getBtnFunction = (type: string) => {
    let btn = this.props.actionButtons ? this.props.actionButtons.find((e) => e.buttonType === type) : null;
    return btn ? btn.buttonFunction : () => { alert("function failed to load"); }

  }

  getBtnDisabled = (type: string) => {
    let btn = this.props.actionButtons ? this.props.actionButtons.find((e) => e.buttonType === type) : null;
    return btn ? btn.buttonDisabled : false
  }

  setShowHideStyle = (type: string) => {
    let btn = this.props.actionButtons ? this.props.actionButtons.find((e) => e.buttonType === type) : null;
    return btn ? { display: "inline" } : { display: "none" };

  }

  getBtnTheme = (type: string) => {
    let btn = this.props.actionButtons ? this.props.actionButtons.find((e) => e.buttonType === type) : null;
    return btn ? btn.buttonIsClicked : () => { alert("function failed to load"); }

  }

  createButton = (btnType: string, iconType: string, tooltipText: string) => {
    let btnStyle = { marginTop: "5px", marginLeft: "5px" }
    let iconStyle = { fontSize: "16px", }
    return (
      <span style={this.setShowHideStyle(btnType)}>
        <Tooltip title={tooltipText}>
          <Button className={"btn"} style={btnStyle} onClick={this.getBtnFunction(btnType)} disabled={this.getBtnDisabled(btnType)}>
            <Icon type={iconType} style={iconStyle} theme={(this.getBtnTheme(btnType) === true) ? "filled" : null} />
          </Button>
        </Tooltip>
      </span>
    )
  }

  generateGenericButtons = () => {
    const buttons = this.props.genericActionButtons;
    //console.log(buttons);
    return (buttons) ? buttons : <div />;
  }

  translationSuffix = (value, onChange) => {
    return (<Icon type="font-colors" onClick={() => {
      const enChars = "qwertyuiopasdfghjkl;'zxcvbnm,./".split("");
      const heChars = "/'קראטוןםפשדגכעיחלךף,זסבהנמצתץ.".split("");
      let isHe = new RegExp(/[א-ת]/).test(value)

      onChange({
        target: {
          value: value?.split("").map(x => {
            return (isHe ?
              enChars[heChars.findIndex(f => f == x)]
              : heChars[enChars.findIndex(f => f == x)]
            ) ?? x
          }).join("") ?? ""
        }
      })
    }} />)
  }



  render() {
    const Search = Input.Search;
    let toolBarStyle = {
      direction: "rtl",
      // backgroundColor: "#001529",
      //backgroundColor: "#1890ff",
      marginBottom: '5px',
      paddingLeft: (this.props.isModal) ? '48px' : '0px',
      height: (window.innerWidth > 500) ? '42px' : '100px',
      clear: 'both',
      width: '100%'
    }

    let titleStyle = {
      margin: '5px',
      color: 'white',
      //float: 'right',
      fontSize: 'large',
    }

    let genericButtons = this.generateGenericButtons();

    const value = this.props.valueSearch === undefined ? this.state.valueSearch : this.props.valueSearch
    const onChange = this.props.onChangeSearch === undefined ? (e) => { this.setState({ valueSearch: e.target.value }) } : this.props.onChangeSearch


    return (
      <div className="page-toolbar" style={toolBarStyle}>
        <span><label style={titleStyle}>
          {this.props.title}
        </label></span>
        <span style={{ float: 'left', paddingLeft: '5px', marginLeft: '5px' }}>
          {this.props.showRangeDates ?
            <Search
              className="hide_search_icon"
              readOnly
              placeholder={this.props.showRangeDatesPlaceholder}
              style={{ dir: "rtl", marginTop: '5px', paddingLeft: '5px', width: this.props.showsearchWidth ?? 200 }}
              value={''}
              suffix={this.props.showRangeDatesSuffix}
            />
            : ""}
          {this.props.showsearch ?
            <Search
              placeholder={this.props.showsearchPlaceholder ?? this.getText(10342)}
              onSearch={value => { if (this.props.showsearch) this.props.showsearch(value) }}
              style={{ dir: "rtl", marginTop: '5px', paddingLeft: '5px', width: this.props.showsearchWidth ?? 250 }}
              value={value}
              onChange={onChange}
              showsearch={true}
              // enterButton={this.props.showsearchSuffix ? true : false}
              enterButton={true}
              suffix={this.props.showsearchSuffix ?? this.translationSuffix(value, onChange)}
            />
            : ""}
          {this.createButton('search', 'search', this.getText(10343))}
          {this.createButton('filter', 'filter', this.getText(10344))}
          {this.createButton('clear', 'funnel-plot', this.getText(17305))}
          {this.createButton('add', 'plus', this.getText(10345))}
          {this.createButton('export', 'export', this.getText(10346))}
          {this.createButton('print', 'printer', this.getText(10347))}
          {this.createButton('refresh', 'reload', this.getText(10348))}
          {genericButtons}
        </span>
      </div>
    );
  }


  dataCallback = (val: any) => {
    let data = {};
    data.searchTerm = val;
    data.pagination = {
      current: 1,
      defaultPageSize: 5,
    };
    data.sorting = {
      col: 'col_1',
      order: 0, // 0 - desend // 1 - asend
    };
    // tag filter
    data.tagList = null;
    data.isAll = null;
    // chk filter
    data.isActive = 'none';
    data.isWeightable = 'none';
    data.isFixedPrice = 'none';
    data.isForSale = 'none';
    data.isDiscount = 'none';
    // alert filter
    data.saleAlert = '';

    //alert("MODAL_DATA: " + JSON.stringify(data));

    if (this.props.searchCallback) {
      this.props.searchCallback(data);
    }
  }
}

export default PageToolbar;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { InputNumber, message, Form, Select, Modal, Button, Row, Col, Tooltip, Icon } from "antd";
import PageToolbar from "./PageToolbar";
import TableIDs from "../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import LangContext from "../contextProvider/LangContext";
import "../App.css";

const FormItem = Form.Item;
const { Option } = Select;

type LabelPrinterProps = {
    labels: Array,
    qtyInput: Boolean,
    defaultQty: Number
};

class LabelPrinter extends Component<LabelPrinterProps, State> {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            labelId: -1,
            priceListId: '1',
            quantity: 0,
            packageQty: 1,

            isFormatA4: '0',
            reportName: "",
            startRow: 1,
            startCol: 1,
        }

        this.clearState = this.state;

        this.selectors = [
            { id: TableIDs.genericSelectorLabelsList, api: "get_labels_list" },
            { id: TableIDs.genericSelectorPriceList, api: "get_prices" }
        ];

        this.selectors.map((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.map((x) => {
            this.props.dataActions.genericSelectorRefreshDataset(
                x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })

        
    }

    componentDidUpdate(prev){
        if(!prev.toggle && this.props.toggle){
            this.sendAPI("get_price_list_default", "999", ob => { this.setState({ priceListId: ob.data }) })
        }
    }

    
    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    stringRTL = (code) => {
        let resCode = '';
        let isZebraFormat = code.indexOf("^XA^JBE^XZ") === 0;

        let bdSplit = (s, r) => {
            let triger = r ? s.match(/[a-zA-Z0-9`]/) : s.match(/[א-ת]/);
            return (triger) ? [s.slice(0, (triger.index)), s.slice(triger.index)] : null;
        }
        let bdFun = (text) => {
            let isR = text.match(/[א-ת]/);
            let isL = text.match(/[a-zA-Z0-9`]/);

            if (isZebraFormat && isR) {
                if (isL) {
                    text = text.split(" ").map(x => {
                        return x.match(/[א-ת]/) ? x.split("").reverse().join("") : x
                    }).join(" ")
                } else {
                    text = text.split("").reverse().join("")
                }
            }

            if (isR && isL) {
                let corR = (isR.index === 0) ? true : false;
                let resArray = [];
                while (bdSplit(text, corR)) {
                    resArray.push(bdSplit(text, corR)[0])
                    text = bdSplit(text, corR)[1];
                    corR = corR ? false : true;
                }
                resArray.push(text);

                let res = '';
                for (let i = resArray.length - 1; i >= 0; i--) {
                    res += resArray[i];
                }
                return ' ' + res + ' ';
            } else {
                return text;
            }
        }


        if (isZebraFormat) {
            resCode += code
            // resCode += code.split("^XFE:format").map((label, index) => {
            //     if (index === 0) {
            //         return label
            //     } else {
            //         return label.split("^FS\n").map((x) => {
            //             if (x && x.indexOf("^FN") === 0) {
            //                 let zSplit2 = x.split("^FD");
            //                 let zRes = zSplit2[1]
            //                 return zSplit2[0] + "^FD" + bdFun(zRes)
            //             } else {
            //                 return x;
            //             }
            //         }).join("^FS\n")
            //     }
            // }).join("^XFE:format");

        } else {

            code.split('^Kformat').map((x, i) => {
                if (i === 0) {
                    resCode += x;
                } else {
                    x.split("\n").map((y, index) => {
                        if (index === 0) {
                            resCode += '^Kformat' + y + "\n";
                        } else {
                            if (['', 'E', '~'].indexOf(y[0]) < 0) resCode += bdFun(y) + "\n";
                            else resCode += y + "\n";
                        }
                    })
                }
            })
        }


        return resCode;
    }

    getQty = () => {
        return (this.state.quantity > 0)
            ? this.state.quantity
            : (this.props.defaultQty && this.props.defaultQty > 0) ? this.props.defaultQty : 1
    }

    handleOk = () => {
        const { labelId, isFormatA4, reportName, priceListId, packageQty, startRow, startCol } = this.state;
        let _id = labelId;

        const { dataset } = this.props.data[this.selectors[0].id];
        if (dataset.length === 1) _id = dataset[0].code;

        if ((isFormatA4 != '1' && _id === -1) || (isFormatA4 == '1' && !reportName)) {
            message.info(this.getText(10349), 3, null);
            return;
        }

        if (isFormatA4 == '1') {
            let params = { ...this.props.labelsA4Params, priceListId, startRow, startCol, reportName, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
            return;
        }

        let request = this.props.labels.map((x) => {
            return {
                _id,
                _PRICE_LIST_ID: priceListId,
                _quantity: this.getQty(),
                _PACKAGE_QTY: packageQty,
                ...x, //override partial data
            }
        })
        let dataSend = Object.keys(request[0]).join('\f') + request.map((x) => '\r' + Object.values(x).join('\f'));
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "get_EZPL_code_to_printer", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
    }

    successCallback = (obj) => {
        if (obj.data) {
            let data = "\n" + this.stringRTL(obj.data) + "\n";
            let blob = new Blob([data.replace(/([^\r])\n/g, "$1\r\n")], { type: 'text/plain' });
            let anchor = document.createElement('a');

            anchor.download = "labelJob.lpjob";
            anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
            anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
            anchor.click();
            this.handleCancel();
        } else {
            message.error(this.getText(10350));
        }
    }

    errorCallback = () => {
        message.error(this.getText(10350));
    }

    handleCancel = () => {
        //this.handleClear();
        this.setState({ ...this.clearState, quantity: 0, })
        this.props.uiActions.hideModal(TableIDs.LabelPrinter);
    }

    handleClear = () => {
        this.setState(this.clearState);
    }

    getSelectOptions = (selector, stateName) => {
        if (this.props.data[selector] && this.props.data[selector].dataset[0]) {
            let list = this.props.data[selector].dataset.map((obj, index) => {
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            });
            return (<Select
                disabled={false} value={this.state[stateName]} style={{ width: '100%', paddingLeft: '4px' }}
                onChange={(e) => { this.setState({ [stateName]: e }) }}>
                <Option key={-1} value={-1} valuefilter={-1}>- {this.getText(10351)} -</Option>
                {list}
            </Select>)
        }
    }

    getQtyInput = () => {
        if (this.props.qtyInput === true) {
            return (<FormItem label={this.getText(10352)}>
                <InputNumber min={1} value={/*this.state.quantity*/this.getQty()} style={{ width: '100%', paddingLeft: '4px' }}
                    onChange={(e) => { this.setState({ quantity: e }) }} />
            </FormItem>);
        }
    }

    getPackageQtyInput = () => {
        if (this.props.qtyInput === true) {
            return (<FormItem label={this.getText(16483)}>
                <InputNumber min={1} value={this.state.packageQty} style={{ width: '100%', paddingLeft: '4px' }}
                    onChange={(e) => { this.setState({ packageQty: e }) }} />
            </FormItem>);
        }
    }

    render() {
        let issetMultiLabels = this.props.data[this.selectors[0].id] && this.props.data[this.selectors[0].id].dataset.length > 1;


        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);


        return (<Modal
            //style={modalStyle}
            destroyOnClose={true}
            width={400}
            visible={false || this.props.toggle}
            title={titleBar}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={
                [
                    <Button key="back" onClick={this.handleCancel}>{this.getText(10353)}</Button>,
                    <Button key="clear" onClick={this.handleClear}>{this.getText(10354)}</Button>,
                    <Button key="create" type="primary" onClick={this.handleOk}>{this.getText(10355)}</Button>,
                ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            {this.props.labelsA4Params ?
                (<FormItem label={this.getText(17316)}>
                    <Select
                        style={{ width: "100%" }}
                        value={this.state.isFormatA4}
                        onChange={e => { this.setState({ isFormatA4: e, labelId: -1, reportName: "" }) }}>
                        <Option value={'0'}>{this.getText(17314)}</Option>
                        <Option value={'1'}>{this.getText(17315)}</Option>
                    </Select>
                </FormItem>)
                : ""}
            {this.state.isFormatA4 == '1' ?
                <span>
                    <FormItem label={this.getText(10356)}>
                        <Select
                            style={{ width: "100%" }}
                            value={this.state.reportName}
                            onChange={e => { this.setState({ reportName: e }) }}>
                            <Option value={''}>- {this.getText(10351)} -</Option>
                            <Option value={'Labels/docItems4on12.pdf'}>{this.getText(17317)}</Option>
                            <Option value={'Labels/docItems4on6.pdf'}>{this.getText(18438)}</Option>
                            <Option value={'Labels/docItems6on12.pdf'}>{this.getText(18769)}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label={this.getText(17320)}>
                        {this.getText(17321)} {" : "} <InputNumber value={this.state.startRow} onChange={e => { this.setState({ startRow: e }) }} />
                        {this.getText(17322)} {" : "} <InputNumber value={this.state.startCol} onChange={e => { this.setState({ startCol: e }) }} />
                    </FormItem>
                </span>
                : issetMultiLabels ?
                    <FormItem label={this.getText(10356)}>
                        {this.getSelectOptions(this.selectors[0].id, 'labelId')}
                    </FormItem>
                    : ""}
            <FormItem label={this.getText(10357)}>{this.getSelectOptions(this.selectors[1].id, 'priceListId')}</FormItem>
            <Row>
                <Col span={12} style={{ padding: 5 }}>{this.getQtyInput()}</Col>
                <Col span={12} style={{ padding: 5 }}>{this.getPackageQtyInput()}</Col>
            </Row>
        </Modal >)
    }
}

export default LabelPrinter;

import React from "react";
import { Input, Icon, Popconfirm, Tooltip, Modal, Table, message } from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import { fixCurrentPage } from "../utils/utils";
import Jax from "../Jax/jax.es6.module";
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
	
const { TextArea } = Input;

const topHeight = 300; // px
const rowHeight = 40;  // px

//type State = {}
class ModalLabelFormatsData extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            type: null,
        }

        this.id = "LABEL_FORMATS_DATA";

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.api = {
            get: "get_labelFields_table",
            delete: "delete_labelFields_table",
        }


        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: this.handleCreateNew
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ]
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data ||
            this.props.labelId !== prevProps.labelId ||
            (prevProps.toggle === false && this.props.toggle === true)) {

            let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
                ? (this.state.height - topHeight) / rowHeight
                : 1);

            let request = {
                _id: this.props.labelId ? this.props.labelId : 0,
                _fieldId: null,

            };

            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        }
    }

    setReportParametersAndDo(report: string, params: Object, func) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState, func);
    }

    componentDidMount() {
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {

        let request = {
            _id: this.props.labelId ? this.props.labelId : 0,
            _fieldId: null,

        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 9;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mNumber": col--;
            case "mItemCode": col--;
            case "mItemBarcode": col--;
            case "mDescription": col--;
            case "mUnitPriceNoTax": col--;
            case "mQuantity": col--;
            case "mTotalSumNoTax": col--;
            case "mCost": col--;
            case "mActualQuantity": break; // 9
            default:
                col = 0;
        }

        let request = {
            _id: this.props.labelId ? this.props.labelId : 0,
            _fieldId: null,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.uiActions.showModal(TableIDs.modalEditLabelField, { ...this.props.ui.data, fType: false, fIndex: false, fieldId: false })
    }

    handleCancel = () => {
        this.setState({ visible: false });
        this.props.uiActions.hideModal(TableIDs.ModalLabelFormatsData);
    }

    render() {

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(11658)}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                fId: y[0],
                fName: y[1],
                fType: y[2],
                fAttributes: y[3],
                fTypeId: y[4],
                index: index,
            })
        });

        let columns =
            [
                {
                    title: this.getText(11659),
                    dataIndex: "fName",
                    key: "fName",
                    sorter: false,
                    width: '25%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(11660),
                    dataIndex: "fType",
                    key: "fType",
                    sorter: false,
                    width: '25%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(11661),
                    dataIndex: "fAttributes",
                    key: "fAttributes",
                    sorter: false,
                    width: '25%',
                    render: (text, record) => {

                        let strJson = JSON.parse(text);
                        let keysJson = Object.keys(strJson);
                        let strResult = keysJson.map(key => key + ': ' + strJson[key]).join(',' + "\n");

                        return (
                            <Tooltip title={strResult} placement="left">
                                <TextArea
                                    style={{ resize: 'none', direction: 'ltr' }}
                                    readOnly={true}
                                    value={strResult}
                                    rows={2}
                                />
                            </Tooltip>
                        );
                    },
                }, {
                    title: this.getText(11662),
                    key: "Settings",
                    width: '10%',
                    render: (text, record) => {
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.setState({ lFieldId: record['fId'] }, () => {
                                        this.props.uiActions.showModal(TableIDs.modalEditLabelField, { ...this.props.ui.data, fIndex: record.index, fType: record['fTypeId'], fieldId: record['fId'] });
                                    }
                                    );
                                }} />
                                <Popconfirm title={this.getText(11663)}
                                    onConfirm={() => {
                                        fixCurrentPage.bind(this)();
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.id,
                                            record['fId'],
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.get);
                                    }
                                    }
                                    okText={this.getText(11664)} cancelText={this.getText(11665)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm>
                            </span>
                        );

                    },
                }
            ];

        return (
            <Modal
                destroyOnClose={true}
                width={'60%'}
                visible={false || this.props.toggle}
                title={titleBar}
                onCancel={this.handleCancel}
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <div style={{ fontSize: 18, textWeight: 'bold', color: 'rgba(0, 0, 0, 0.9)', paddingBottom: 4, paddingLeft: 8, paddingRight: 8 }}>
                    {(this.props.labelId) ?this.getText(11666) +' ' + this.props.labelId : null}
                </div>

                <ResponsiveTable ui={this.props.ui} tableOnly={true} dataSource={dataSource}
                    columns={columns}
                    loading={this.props.ui.workInProgress}
                    scroll={{ y: window.innerHeight / 2 }}
                    bordered
                    pagination={false}
                    onChange={this.handleTableChange}
                    locale={{ emptyText: this.getText(11667) }}
                />
            </Modal>
        )
    }

}

export default ModalLabelFormatsData;


import React from "react";
import {
    Input, Icon, Button, Popconfirm, Tooltip, Modal, Table,
    Select, Row, Col, Checkbox, notification, message, InputNumber, Switch
} from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import { fixCurrentPage, escapeRegExp } from "../utils/utils";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";


const { Option, OptGroup } = Select;
const { TextArea } = Input;

const topHeight = 340; // px
const rowHeight = 30;  // px

//type State = {}
class ModalCreatePriceList extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            addButton: false,
            addCode: '',
            addSum: '',
            addDesc: '',

            modalMarkRecordsForRe: null,
            countMarkRecordsForRe: 0,

        }

        this.id = "STANDING_ORDERS_TABLE";
        this.id_markRecords = "MODAL_MARK_RECORDS_FOR_RE";

     
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[this.id_markRecords] === undefined) {
            props.data[this.id_markRecords] = { ...props.data.genericData };
        }

        if (props.data[TableIDs.genericSelectorTaxRegion] === undefined) {
            props.data[TableIDs.genericSelectorTaxRegion] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "mock_standing_order",
            reset: "reset_standing_orders",
            create: "create_standing_orders",
            change_status: "change_status_standing_orders",
            //edit: "edit_priceList",
            //delete: "delete_priceList",
            selector: "get_tax_region_list",

            get_mark_records: "get_standing_order_count_per_status",
            save_mark_records: "save_standing_order_count_per_status",
        
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        },]

        this.genericActionButtons = [];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - ";
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorTaxRegion, this.props.user.companyCode,
            this.props.user.token, this.api.selector);

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    modalMarkRecordsForReCheckbox = (text, record) => {
        let checked = text == 1;
        let indeterminate = false;
        if (text === "all") {
            let checkedList = this.props.data[this.id_markRecords].data.map(x => x.split("\f").slice(-1))
            let countChecked = checkedList.filter(f => f == 1).length;
            checked = checkedList.length && checkedList.length == countChecked;
            indeterminate = checkedList.length && countChecked && checkedList.length > countChecked;
        }
        return (<Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={e => {
                let isChecked = e.target.checked ? '1' : '0';
                let data = this.props.data[this.id_markRecords].data.map((x, i) => {
                    if (text === "all" || i === record.index) return x.split('\f').slice(0, -1).join('\f') + '\f' + isChecked
                    else return x;
                });
                let countMarkRecordsForRe = 0;
                data.forEach(x => {
                    let y = x.split("\f");
                    if (y[3] == 1) countMarkRecordsForRe += parseInt(y[2])
                })
                this.setState({ countMarkRecordsForRe }, () => {
                    this.props.dataActions.setJson(this.id_markRecords, { data })
                })
            }} />)
    }


    sendAPI = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: (this.props.ui.data) ? this.props.ui.data : -1, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        return request;
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 11;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mId": col--;
            case "mCustomerId": col--;
            case "mSum": col--;
            case "mActionDate": col--;
            case "mStatus": col--;
            case "mTotalId": col--;
            case "mCardcomResponse": col--;
            case "mStatusDescription": col--;
            case "mCardcomResponse": col--;
            case "mFirstName": col--;
            case "mLastName": break; // 11
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            _id: (this.props.ui.data) ? this.props.ui.data : -1,
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        this.setState({ visible: false });
        //this.clear();
        this.refreshDataMain();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleClear = () => {
        //this.clear();
    }

    refreshDataMain = () => {
        this.props.dataActions.genericDataGetRequested(
            "CHARGE_DIRECT_DEBIT_TABLE", this.props.user.companyCode, this.props.user.token, "mock_standing_order_total");
    }

    inlineField = (label, input) => {
        return (<Row style={{ marginTop: 10 }}>
            <Col span={8}>{this.getText(label)}:</Col>
            <Col span={16}>{input}</Col>
        </Row>)
    }

    render() {
        const { loading } = this.state;
        let request = {
            // ui-pagination 
            _id: (this.props.ui.data) ? this.props.ui.data : -1,
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        // let _TODO_genericActionButtons = [
        //     (<Tooltip title={<div>איפוס רשומות<br />לחיוב מחדש</div>}>
        //         <Button key="download_file" className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
        //             onClick={() => {
        //                 this.props.dataActions.genericDataSaveEditWithParams(
        //                     this.id, this.props.user.companyCode, this.props.user.token,
        //                     this.api.reset, this.api.get, { _id_so: 0, _id_sot: this.props.ui.data });
        //             }
        //             }
        //         >
        //             <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="sync" theme="outlined" />
        //         </Button>
        //     </Tooltip>),]


        let addModule = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset ?
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '501')
            : false;

        let actionButtons = addModule ? [
            {
                buttonType: 'add',
                buttonFunction: () => {
                    this.setState({
                        addButton: true,
                        addCode: '',
                        addSum: '',
                        addDesc: '',
                    });
                }
            },
            ...this.actionButtons
        ] : this.actionButtons


        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10878)}
                    actionButtons={actionButtons}
                    genericActionButtons={  [<Tooltip title={this.getText(19030)}>
                            <Button onClick={() => {
                                this.setState({ modalMarkRecordsForRe: this.props.ui.data }, () => {
                                    this.props.dataActions.genericDataSetFilter(
                                        this.id_markRecords, this.props.user.companyCode, this.props.user.token,
                                        { _idTotal: this.props.ui.data }, this.api.get_mark_records)
                                })
                            }}><Icon type="file-sync" /></Button>
                        </Tooltip>]}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request,
                                _words: escapeRegExp(val),
                                //   _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mCustomerId: y[1],
                mSum: y[2],
                mActionDate: y[3],
                mStatus: y[4],
                mTotalId: y[5],
                mCardcomResponse: y[6],
                mStatusDescription: y[7],
                mFirstName: y[8],
                mLastName: y[9],
                mTotalStatus: y[10],
                index: index
            })
        });

        let columns =
            [
                {
                    title: "",
                    dataIndex: "mTotalStatus",
                    key: "mTotalStatus",
                    width: '1%',
                    render: (text, record) => { return <div style={{ display: 'hide' }} /> }
                }, {
                    title: this.getText(10880),
                    dataIndex: "mId",
                    key: "mId",
                    sorter: true,
                    width: '8%',
                    render: (text, record) => { return text }
                }, {
                    title: this.getText(10881),
                    dataIndex: "mCustomerId",
                    key: "mCustomerId",
                    width: '10%',
                    sorter: true,
                    render: (text, record) => { return text }
                }, {
                    title: this.getText(10882),
                    dataIndex: "mFirstName",
                    key: "mFirstName",
                    width: '10%',
                    sorter: true,
                    render: (text, record) => { return text }
                }, {
                    title: this.getText(10883),
                    dataIndex: "mLastName",
                    key: "mLastName",
                    width: '10%',
                    sorter: true,
                    render: (text, record) => { return text }
                }, {
                    title: this.getText(10884),
                    dataIndex: "mSum",
                    key: "mSum",
                    width: '10%',
                    sorter: true,
                    render: (text, record) => { return text }
                }, {
                    title: this.getText(10885),
                    dataIndex: "mActionDate",
                    key: "mActionDate",
                    width: '16%',
                    sorter: true,
                    render: (text, record) => { return text }
                }, {
                    title: this.getText(10886),
                    dataIndex: "mStatus",
                    key: "mStatus",
                    width: '6%',
                    sorter: true,
                    render: (text, record) => { return text }
                }, {
                    title: this.getText(10887),
                    dataIndex: "mStatusDescription",
                    key: "mStatusDescription",
                    width: '10%',
                    sorter: true,
                    render: (text, record) => { return text }
                }, {
                    title: this.getText(10888),
                    dataIndex: "mCardcomResponse",
                    key: "mCardcomResponse",
                    width: '10%',
                    sorter: true,
                    render: (text, record) => {
                        return (
                            <Tooltip title={text} placement="left">
                                <TextArea
                                    style={{ resize: 'none' }}
                                    readOnly={true}
                                    value={text}
                                    //autosize={{ minRows: 2, maxRows: 2 }}
                                    //autosize={false}
                                    rows={1}
                                />
                            </Tooltip>
                        )
                    },
                }, {
                    title: this.getText(10889),
                    width: '5%',
                    render: (text, record) => {
                        let isShownStyle = (record['mTotalStatus'] === '4' || record['mStatus'] === '4') ? { display: 'none' } : { display: 'inline' };
                        return (<span>
                            <Tooltip title={this.getText(10890)}>
                                <Icon style={{ fontSize: 16, ...isShownStyle }} className={"clickable margin-4"} type={"sync"}
                                    onClick={() => {

                                        console.log('mTotalStatus:\n' + JSON.stringify(record) + '\n' + record['mTotalStatus']);

                                        const isValid: boolean = record['mStatus'] === '1'
                                            || record['mStatus'] === '3'
                                            || record['mStatus'] === '0'
                                            || record['mTotalStatus'] !== '4';

                                        if (!isValid) {
                                            if (record['mTotalStatus'] === '4') {
                                                notification.error({
                                                    message: this.getText(10891),
                                                    description: this.getText(10892),
                                                });
                                            } else {
                                                notification.error({
                                                    message: this.getText(10893),
                                                    description: this.getText(10894),
                                                });
                                            }
                                        } else {
                                            this.props.dataActions.genericDataSaveEditWithParams(
                                                this.id, this.props.user.companyCode, this.props.user.token,
                                                this.api.reset, this.api.get, { _id_so: record['mId'], _id_sot: this.props.ui.data });
                                        }
                                    }}
                                />
                            </Tooltip>
                            {addModule && (record['mStatus'] === '0' || record['mStatus'] === '5') ? (
                                <Switch
                                    checkedChildren={this.getText(14042)}
                                    unCheckedChildren={this.getText(14043)}
                                    checked={record['mStatus'] === '0'}
                                    onChange={(value) => {
                                        this.sendAPI(
                                            this.api.change_status,
                                            "mId\fmStatus\r" + record.mId + "\f" + (value ? "0" : "5"),
                                            this.refreshData,
                                            (e) => { console.error(e) })
                                    }} />
                            ) : ''}
                        </span>)
                    }
                }
            ];

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <ResponsiveTable dataSource={dataSource}
                    columns={columns}
                    // loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    tableOnly={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    percentageToMobile={[false, false, 30, 35, 35]}
                // locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                />
                <Modal
                    destroyOnClose={true}
                    width={500}
                    visible={this.state.addButton}
                    title={this.getText(14025)}
                    onCancel={() => { this.setState({ addButton: false }) }}
                    footer={[
                        <Button type="primary" onClick={() => {
                            if (!this.state.addCode) {
                                message.error(this.getText(14030))
                            } else if (!this.state.addSum || this.state.addSum <= 0) {
                                message.error(this.getText(14031))
                            } else {
                                let sendData = "dCode\fdSum\fdDesc\ftID\r" +
                                    this.state.addCode + "\f" +
                                    this.state.addSum + "\f" +
                                    this.state.addDesc + "\f" +
                                    this.props.ui.data;

                                this.sendAPI(this.api.create, sendData, (ob) => {
                                    if (ob.data == '1') {
                                        this.setState({ addButton: false }, () => {
                                            message.success(this.getText(14032))
                                            this.refreshData();
                                        })
                                    } else {
                                        message.error(this.getText(14033))
                                    }
                                }, (e) => { console.error(e) })
                            }
                        }}>{this.getText(14026)}</Button>
                    ]}
                >
                    {this.inlineField(14027, (<InputNumber
                        style={{ width: '100%' }} min={1} step={1} value={this.state.addCode}
                        onChange={(e) => { this.setState({ addCode: e }) }}
                    />))}
                    {this.inlineField(14028, (<InputNumber
                        style={{ width: '100%' }} min={0.01} precision={2} step={1} value={this.state.addSum}
                        onChange={(e) => { this.setState({ addSum: e }) }}
                    />))}
                    {this.inlineField(14029, (<Input
                        value={this.state.addDesc}
                        onChange={(e) => { this.setState({ addDesc: e.target.value }) }}
                    />))}
                </Modal>
                <GenericModal
                    visible={this.state.modalMarkRecordsForRe !== null}
                    onCancel={() => { this.setState({ modalMarkRecordsForRe: null }) }}
                    width={600}
                    title={this.getText(19030)}
                    footer={[
                        <Popconfirm
                            title={this.getText(19033) + " " + this.state.countMarkRecordsForRe + " " + this.getText(19034)}
                            okText={this.getText(19035)}
                            cancelText={this.getText(19036)}
                            onConfirm={() => {
                                let dataSend = "mID\fmStatusList\r" + this.state.modalMarkRecordsForRe + "\f" +
                                    this.props.data[this.id_markRecords].data
                                        .filter(x => x.split("\f").slice(-1) == 1)
                                        .map(y => y.split("\f").slice(0, 1))
                                        .join(",");

                                this.sendAPI(this.api.save_mark_records, dataSend, () => {
                                    this.setState({ modalMarkRecordsForRe: null }, this.refreshData)
                                })
                            }}
                            onCancel={() => { this.setState({ modalMarkRecordsForRe: null }) }}
                            disabled={!this.state.countMarkRecordsForRe}
                        >
                            <Button type="primary" disabled={!this.state.countMarkRecordsForRe}>{this.getText(19031)}</Button>
                        </Popconfirm>,
                        <Button onClick={() => { this.setState({ modalMarkRecordsForRe: null }) }}>{this.getText(19032)}</Button>,
                    ]}>
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.id_markRecords]}
                        columns={[
                            { title: this.getText(19037), key: "sName", dataIndex: "sName", width: "60%", render: t => t },
                            { title: this.getText(19038), key: "sCount", dataIndex: "sCount", width: "20%", render: t => t },
                            { title: this.modalMarkRecordsForReCheckbox("all"), key: "sChecked", dataIndex: "sChecked", width: "20%", render: this.modalMarkRecordsForReCheckbox },
                        ]}
                    />
                </GenericModal>
              
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        console.log("test_open_modal", this.state.modalMarkRecordsForRe);

        if (this.props.ui.data !== prevProps.ui.data ||
            (prevProps.toggle === false && this.props.toggle === true)) {

            console.log("prev", prevProps.ui.data, "next", this.props.ui.data);

            let request = this.initFilters();
            request._id = (this.props.ui.data) ? this.props.ui.data : -1;

            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                request
                /*{ _id: this.props.ui.data }*/,
                //this.api.get_suppliers
                this.api.get
            );
        }
    }
}

export default ModalCreatePriceList;

/* @flow */
/*jshint esversion: 6 */
import { Form, Row, Col, Button, Radio } from "antd";
import React, { Component } from "react";
import "../../App.css";
import DateTimePicker from "../../components/DateTimePicker";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import * as XLSX from 'xlsx/xlsx.mjs';
import ReportFavoriteSave from "../../components/ReportFavoriteSave";
import moment from "moment";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";


class CostChangesLogExcel extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            _fromDate: null,
            _toDate: null,
            _supplierList: "",
            _itemTagList: "",
            reportName: "CostChangesLogExcel.xlsx"
        };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(19326);
        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    generateReport = () => {
        this.setState({ loading: true }, () => {

            const { _fromDate, _toDate, _supplierList, _itemTagList, reportName } = this.state;

            let dataSend = "_fromDate\f_toDate\f_supplierList\f_itemTagList\r" +
                (_fromDate ?? "") + "\f" +
                (_toDate ?? "") + "\f" +
                _supplierList + "\f" +
                _itemTagList;

            this.sendAPI("get_cost_changes_log_excel", dataSend, (ob) => {
                let excelData = {}
                let lastRow = 0;
                let lastCol = "AA";
                let styles = []
                let colList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
                colList.push(lastCol);


                if (ob.data) {
                    ob.data.split("\r").forEach((x, i) => {
                        lastRow++;
                        x.split("\f").forEach((y, yi) => {
                            let colAndRow = colList[yi] + lastRow
                            if (!i) {
                                styles.push({ width: 18 });
                                excelData = { ...excelData, [colAndRow]: { t: "s", v: y } }
                            } else if (y) {
                                switch (yi) {
                                    case 4: case 7: case 8: case 9: case 10:
                                        excelData = { ...excelData, [colAndRow]: { t: "s", v: y } }
                                        break;
                                    case 5:
                                        excelData = { ...excelData, [colAndRow]: { t: "s", v: moment(y).format("DD/MM/YYYY HH:mm:ss") } }
                                        break;
                                    case 0: case 3: case 6:
                                        excelData = { ...excelData, [colAndRow]: { t: "n", v: y } }
                                        break;
                                    default:
                                        excelData = { ...excelData, [colAndRow]: { t: "n", v: y, z: "#,##0.00" } }
                                }
                            }
                        })
                    })
                }

                excelData = { ...excelData, ['!cols']: styles, ["!ref"]: "A1:" + lastCol + lastRow }

                console.log("excelData", excelData)

                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const wb = { Sheets: { data: excelData }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const blob = new Blob([excelBuffer], { type: fileType });
                let a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.download = reportName;
                a.click();

                this.setState({ loading: false })
            })
        })

    }


    sendAPI = (script, data, sCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, (e) => { console.error(e) });
    };

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 700,
            margin: 'auto'
        };

        const { _fromDate, _toDate, _supplierList, _itemTagList, reportName } = this.state;
        let params = { _fromDate: _fromDate ?? "", _toDate: _toDate ?? "", _supplierList, _itemTagList, reportName }

        return (<div style={divStyle}>
            <PageToolbar title={gt(19326)} actionButtons={[]} />

            <Row>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(19327)}>
                        <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_fromDate}
                            onChange={e => { this.setState({ _fromDate: e }) }}
                            maxDate={_toDate}
                        />
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(19328)}>
                        <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_toDate}
                            onChange={e => { this.setState({ _toDate: e }) }}
                            minDate={_fromDate}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item label={gt(19329)}>
                <GenericSelectorSuppliers
                    {...this.props}
                    multi
                    value={_supplierList ? _supplierList.split(",") : []}
                    onChange={e => { this.setState({ _supplierList: e ? e.join(",") : "" }) }}
                />
            </Form.Item>

            <Form.Item label={gt(19330)}>
                <GenericSelectorItemTags
                    {...this.props}
                    multi
                    notReport
                    value={_itemTagList ? _itemTagList.split(",") : []}
                    onChange={e => { this.setState({ _itemTagList: e ? e.join(",") : "" }) }}
                />
            </Form.Item>

            <Form.Item key={"submit"}>
                <Button type={"primary"} loading={this.state.loading} onClick={this.generateReport}>{gt(18372)}</Button>
                <ReportFavoriteSave
                    {...this.props}
                    params={params}
                    datesToFavorites={[
                        { field: '_fromDate', label: gt(18367) },
                        { field: '_toDate', label: gt(18368) },
                    ]}
                />
            </Form.Item>
        </div>)
    }

}

export default CostChangesLogExcel;

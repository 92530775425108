import React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Select, Button } from 'antd';
import ExtendedSelector from "./ExtendedSelector";


const { Option, OptGroup } = Select;

class SelectorCustomerFields extends React.Component/*<Props, State>*/ {
    constructor(props) {
        super(props)
    }

    render() {
        let attr = {
            styleSelector: this.props.styleSelector,
            user: this.props.user,
            onSelect: this.props.onSelect,
            value: this.props.value,
            ui: this.props.ui,
            uiActions: this.props.uiActions,
            disabled: this.props.disabled,

            dataset: [{
                code: 0,
                name: 'תווית',
            }, {
                code: 1,
                name: 'קלט טקסט',
            }, {
                code: 2,
                name: 'קלט מחיר',
            } , {
                code: 3,
                name: 'קלט דצימלי',
            } , {
                code: 4,
                name: 'קלט נומרי',
            } , {
                code: 5,
                name: 'בורר תאריכים',
            } , {
                code: 6,
                name: 'תיבת סימון',
            } , {
                code: 7,
                name: 'בחירה',
            } , {
                code: 8,
                name: 'בחירה מרובה',
            } , {
                code: 9,
                name: 'תמונה',
            }]
        }

        return (
            <ExtendedSelector {...attr} />
        )
    }
};


export default SelectorCustomerFields;

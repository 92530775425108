/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
// import TableIDs from '../../data/TableIDs.js';
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
// import { fixCurrentPage } from "../../utils/utils";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
// import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

import {
    Input, Form, Button, Switch, Radio, Tooltip, Modal, Table, Select, Row, Col, message, Divider, Checkbox, InputNumber, Icon
} from 'antd';
import { parseInt } from "lodash";

const topHeight = 340; // px
const rowHeight = 30;  // px

const { Item } = Form;
const { Option } = Select;

class WooSites extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalCreateEdit: null,
            modalCreateEditTask: null,
            isSDIActive: false,
            modalCreateEditPayments: null,
        };

        this.id = "WooSites";
        this.id_pays = "WooPayments";

        this.api = {
            get: "woo_sites_table_get",
            create: "woo_sites_table_create",
            edit: "woo_sites_table_edit",
            delete: "woo_sites_table_delete",
            get_tasks: "woo_sites_tasks_get",
            edit_tasks: "woo_sites_tasks_edit",
            delete_tasks: "woo_sites_tasks_delete",
            // sdi = start day inventory
            get_sdi_is_active: "get_start_day_inventory_is_active",
            change_sdi_is_active: "change_start_day_inventory_is_active",
            validateStorage: "woo_sites_validate_storage",
            // payments
            get_pays: "woo_payments_get",
            edit_pays: "woo_payments_edit",
            //create_pays: "woo_payments_create",
            //delete_pays: "woo_payments_delete",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.selectors = [
            { isMulti: 0, optionsGroup: 1, id: TableIDs.genericMultiSelectorTagTypes, api: 'get_tag_types_with_relation_list' },
            { isMulti: 0, id: TableIDs.genericSelectorPrices, api: 'get_prices' },
            { isMulti: 0, id: TableIDs.genericSelectorStorages, api: 'get_storage_list' },
            //{ isMulti: 0, id: TableIDs.genericSelectorPos, api: 'list_pos' },
            { isMulti: 0, id: TableIDs.genericSelectorPosByStorage, api: 'get_pos_list_of_branch_by_storage', defaultFilters: { wStorageId: 0 } },
            { isMulti: 1, id: TableIDs.genericSelectorWooStatuses, api: 'get_woo_status_list' },
        ];

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        });

        this.actionButtons = [{
            buttonType: 'add',
            buttonFunction: this.createSite
        }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }]

        this.actionButtons_pays = [
            {
                buttonType: 'add',
                buttonFunction: () => {
                    let newArr = [...this.state.modalCreateEditPayments]
                    let len = this.state.modalCreateEditPayments?.length;
                    let newObj = { ...this.newPaysTemplate, ind: len }
                    newArr.unshift(newObj);
                    this.setState({ modalCreateEditPayments: newArr })
                }
            }/*, {
                buttonType: 'refresh',
                buttonFunction: this.refreshPaysData
            }*/
        ]

        this.newTemplate = {
            wId: 0,
            wName: '',
            wRegularPriceListId: null,
            wSalePriceListId: null,
            wTagsTagTypeId: null,
            wCatagoriesTagTypeId: null,
            wAttributeTagTypeId: null,
            wStorageId: null,
            wColorDesc: '',
            wSizeDesc: '',

            wTagsIsVisable: 0,
            wCatagoriesIsVisable: 0,
            wAttributeIsVisable: 0,
        }

        this.newTasksTemplate = {
            wGetOrders: 0, // 48
            wExportItems: 0, // 49
            wExportInventory: 0, // 51|58
            wExportPrices: 0, // 71
            wUser: '',
            wPassword: '',
            wUrl: '',
            wPOS: null,
            wExportInventoryIsNew: 1, // wExportInventoryIsNew: 0
            wStatusList: ''
        }

        this.newPaysTemplate = {
            pId: 0,
            pWooId: '',
            pIsPayed: 0
        }

        this.field_list = [
            { text: 17734, field: 'wId' },
            { text: 17735, field: 'wName' },
            { text: 17820, field: 'wColorDesc' },
            { text: 17821, field: 'wSizeDesc' },
            { text: 17738, field: 'wTagsTagTypeId' },
            { text: 17739, field: 'wCatagoriesTagTypeId' },
            { text: 17740, field: 'wAttributeTagTypeId' },
            { text: 17736, field: 'wRegularPriceListId' },
            { text: 17737, field: 'wSalePriceListId' },
            { text: 17741, field: 'wStorageId' },

            { text: 18472, field: 'wTagsIsVisable' },
            { text: 18473, field: 'wCatagoriesIsVisable' },
            { text: 18474, field: 'wAttributeIsVisable' },
        ];

        this.tasks_field_list = [
            { text: 17765, field: 'wUrl' },
            { text: 17766, field: 'wUser' },
            { text: 17767, field: 'wPassword' },
            { text: 17768, field: 'wGetOrders' },
            { text: 17769, field: 'wExportItems' },
            { text: 17770, field: 'wExportInventory' },
            { text: 19079, field: 'wExportPrices' },
            //{ text: 18518, field: 'wExportInventoryIsNew' },
            { text: 17822, field: 'wPOS' },
            { text: 18942, field: 'wStatusList' },
        ]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(17697);
    }
    componentDidMount() {
        this.initFilters();
        this.initSelectors();
    }

    // table functions
    initFilters = () => {
        let numOfRowsByHeight = parseInt((window.innerHeight - topHeight > rowHeight) ? (window.innerHeight - topHeight) / rowHeight : 1);
        let request = {
            _id: 0,
            page: 1, recordsPerPage: numOfRowsByHeight, sortType: 1, sortColumn: 1,// ui
            _page_num: 1, _rows_num: numOfRowsByHeight, _isAsc: 1, _sort_by: 'col_1', _words: '',// api
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    initSelectors = () => {
        this.selectors.forEach(i => {
            if (i.optionsGroup > 0) {
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.genericMultiSelectorRefreshDataset,
                    args: [i.id, this.props.user.companyCode, this.props.user.token, i.api]
                });
            } else {
                this.props.dataActions.genericSelectorRefreshDataset(i.id, this.props.user.companyCode, this.props.user.token, i.api, i.defaultFilters);
            }
        })
    }

    refreshPosSelector = () => {
        const pos_selector = this.selectors[3];
        const { isMulti, optionsGroup, id, api } = pos_selector;
        const { wStorageId } = this.state.modalCreateEdit;
        if (optionsGroup > 0) {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericMultiSelectorRefreshDataset,
                args: [id, this.props.user.companyCode, this.props.user.token, api, { wStorageId }]
            });
        } else {
            this.props.dataActions.genericSelectorRefreshDataset(id, this.props.user.companyCode, this.props.user.token, api, { wStorageId });
        }
    }

    getStartDayInv = () => {
        this.callApi("", this.api.get_sdi_is_active, (ob) => {
            if (ob.data) {
                const data = ob.data;
                const [id, isActive] = data.split('\f')
                const val = (isActive && isActive > 0 ? true : false);
                this.setState({ isSDIActive: val });
            }
        }, (e) => { return e })
    }

    setStartDayInv = (isActive) => {
        this.callApi(isActive, this.api.get_sdi_is_active,
            (ob) => { this.getStartDayInv() },
            (e) => { this.getStartDayInv() })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.callApi("", this.api.get_sdi_is_active, (ob) => { }, (e) => { })
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 14;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 1
            case "wId": col--;
            case "wName": col--;
            case "wRegularPriceListId": col--;
            case "wSalePriceListId": col--;
            case "wCatagoriesTagTypeId": col--;
            case "wTagsTagTypeId": col--;
            case "wAttributeTagTypeId": col--;
            case "wStorageId": col--;
            case "wColorDesc": col--;
            case "wSizeDesc": col--; // 11
            case "wTagsIsVisable": col--;
            case "wCatagoriesIsVisable": col--;
            case "wAttributeIsVisable": break; // 14
            default: col = 0;
        }

        let request = {
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    callApi = (inputData, action, succCallback, errCallback) => {
        let dataSend = inputData;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, action, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", succCallback, errCallback);
    }

    callAPIPromised = (script, data, sCall, eCall, outputType) => {
        return new Promise((resolve, reject) => {
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, script, OutputType[outputType ? outputType : 'OUTPUT_TYPE_DATA'], ProcessType.PROCESS_TYPE_SYNC);
            job.setInput(data);
            job.send("/cgi-bin/CashOnTab",
                (ob: Object) => { resolve(sCall(ob)) },
                (error: any) => { reject(eCall(error)); }
            )
        })
    }

    renderCol = (dataIndex, type) => {
        return (text, record) => {
            let comp = text;
            let getSelectorDescription = (ind) => {
                let sData = this.props.data[this.selectors[ind].id].dataset;
                let option = sData.find(i => i['code'] === text)
                return (option) ? option['name'] : ''
            }

            let getMultiSelectorDescription = (ind) => {
                return <GenericSelector {...this.props} id={this.selectors[ind].id} disabled multi hideCheckbox
                    optionsGroup={this.selectors[ind].optionsGroup} value={text ? text.split(',') : []} />
            }

            switch (dataIndex) {
                case 'wRegularPriceListId':
                case 'wSalePriceListId':
                    comp = getSelectorDescription(1); break;
                case 'wCatagoriesTagTypeId':
                case 'wTagsTagTypeId':
                case 'wAttributeTagTypeId':
                    comp = getMultiSelectorDescription(0); break;
                case 'wStorageId':
                    comp = getSelectorDescription(2); break;
                default:
                    comp = text
                    break;
            }
            return comp
        }
    }


    /* return function of the form (text, record) => {} */
    renderPayCol = (dataIndex, text, record) => {
        const records = this.state.modalCreateEditPayments;
        
        console.log("_" + dataIndex+ ":", dataIndex, text, record);

        let input = (<Input value={text} onChange={e => { 
            const updates_records = records.map(i => { return (i.ind === record.ind) ? {...i, [dataIndex]: e.target.value } : i })
            this.setState({ modalCreateEditPayments: updates_records })
        }} />) 
        let chk = (<Checkbox
                checked={parseInt(text) === 1 ? true : false}
                onChange={(e) => {
                    const updates_records = records.map(i => { return (i.ind === record.ind) ? {...i, [dataIndex]: (e.target.checked) ? 1 : 0 } : i })
                    this.setState({ modalCreateEditPayments: updates_records })
                }} />)

        switch (dataIndex) {
            case 'pWooId':
                return input;
            case 'pIsPayed':
                return chk;
            default:
                return text;
        }
        //return (text, record) => text;
    }

    checkIfValidForm = async () => {
        let { wId, wName, wRegularPriceListId, wTagsTagTypeId, wCatagoriesTagTypeId, wAttributeTagTypeId,
            wStorageId, wColorDesc, wSizeDesc } = this.state.modalCreateEdit;
        let { wGetOrders, wExportItems, wExportInventory, wUser, wPassword, wUrl, wPOS, wExportInventoryIsNew, wExportPrices } = this.state.modalCreateEditTask;
        let isOK = true;
        await this.callAPIPromised(this.api.validateStorage, 'wId\fwStorageId\r' + wId + '\f' + wStorageId,
            (ob) => {
                if (ob.data) {
                    if (parseInt(ob.data) ? true : false) { message.error(this.getText(18455)); isOK = false; return false; }
                    if (wGetOrders || wExportItems || wExportInventory || wExportPrices) {
                        if (!wUrl || !wUser || !wPassword) {
                            message.error(this.getText(18449)); isOK = false; return false;
                        }
                    }
                    if (!wTagsTagTypeId) { message.error(this.getText(18450)); isOK = false; return false; }
                    if (!wColorDesc) { message.error(this.getText(18451)); isOK = false; return false; }
                    if (!wSizeDesc) { message.error(this.getText(18452)); isOK = false; return false; }
                    if (!wRegularPriceListId) { message.error(this.getText(18453)); isOK = false; return false; }
                    /// TODO: pos from branch only
                    if (!wPOS) { message.error(this.getText(18454)); isOK = false; return false; }
                    // TODO: check wStorageId if diff site with the same stor_id
                } else {
                    isOK = false;
                }
            },
            (e) => { return e; });
        let p = new Promise((resolve, reject) => { (isOK) ? resolve(1) : reject(0) });
        return p;
    }

    saveEdit = async (request) => {
        this.checkIfValidForm()
            .then((resolve) => {
                if (request && request.wId !== 0 && request.wId !== '0') {
                    this.props.dataActions.genericDataSaveEditWithParams(this.id, this.props.user.companyCode,
                        this.props.user.token, this.api.edit, this.api.get, request);
                } else {
                    this.props.dataActions.genericDataSaveEditWithParams(this.id, this.props.user.companyCode,
                        this.props.user.token, this.api.create, this.api.get, request);
                }

                setTimeout(this.editTasks, 1500);
                //this.editTasks();
                setTimeout(() => { this.setState({ modalCreateEdit: null, modalCreateEditTask: null }) }, 2000);

            })
            .catch((err) => { console.log(err) });
    }

    saveEditPay = () => {
        const pays = this.state.modalCreateEditPayments;
        let input = "pId\fpWooId\fpIsPayed";
        let data = pays.map(obj => {
            const { pId, pWooId, pIsPayed } = obj;
            return [pId, pWooId, pIsPayed].join("\f")
        }).join("\r")

        if (data) {
            input += "\r";
            input += data;
        }

        this.callApi(input, this.api.edit_pays, (ob) => {
            if (ob.data) { console.log(ob.data) }
            this.setState({ modalCreateEditPayments: null })
        }, (e) => { return e })

    }

    createSite = () => {
        this.setState({ modalCreateEdit: this.newTemplate, modalCreateEditTask: this.newTasksTemplate })
    }

    deleteSite = (key) => {
        this.props.dataActions.genericDataDeleteRow(
            this.id, key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
        this.deleteTasks(key);
    }

    getTasks = (key) => {
        this.callApi('wId\r' + key, this.api.get_tasks, (ob) => {
            if (ob.data) {
                const data = ob.data;
                const [orders_active, items_active, inv_active, pos, user, psw, url, site, inv_is_new, statusList, prices_active] = data.split('\f')
                this.setState({
                    modalCreateEditTask: {
                        ...this.state.modalCreateEditTask,
                        wGetOrders: parseInt(orders_active),
                        wExportItems: parseInt(items_active),
                        wExportInventory: parseInt(inv_active),
                        wExportPrices: parseInt(prices_active),
                        wUser: user,
                        wPassword: psw,
                        wUrl: url,
                        wPOS: pos,
                        wExportInventoryIsNew: 1, // parseInt(inv_is_new)
                        wStatusList: statusList
                    }
                });
            }
        }, (e) => { return e })
    }

    editTasks = () => {
        let m = this.state.modalCreateEdit?.wId;
        let mt = this.state.modalCreateEditTask;
        let req = 'wId' + '\f' + Object.keys(mt).join('\f') +
            '\r' + m + '\f' + Object.values(mt).join('\f');
        this.callApi(req, this.api.edit_tasks, (ob) => { this.getTasks(m) }, (e) => { return e })
    }

    deleteTasks = (wId) => {
        let req = 'wId' + '\r' + wId;
        this.callApi(req, this.api.delete_tasks, (ob) => { return null; }, (e) => { return e })
    }



    getPays = () => {
        this.callApi('', this.api.get_pays, (ob) => {
            if (ob.data) {
                const data = ob.data;
                let result = [];
                result = data.split('\r').map((d, i) => {
                    const [pId, pWooId, pIsPayed] = d.split('\f')
                    return { ind: i, pId, pWooId, pIsPayed: parseInt(pIsPayed) }
                })

                this.setState({ modalCreateEditPayments: result });
            }
        }, (e) => { return e })
    }

    editPays = () => {
        let p = this.state.modalCreateEditPayments;
        let req = Object.keys(p).join('\f') + '\r' + Object.values(p).join('\f');
        this.callApi(req, this.api.edit_pays, (ob) => { this.getPays(p) }, (e) => { return e })
    }

    deletePays = (pId) => {
        let req = 'pId' + '\r' + pId;
        this.callApi(req, this.api.delete_pays, (ob) => { return null; }, (e) => { return e })
    }

    renderButtons = (text, record) => {
        return (<TableLineIcons
            onEdit={() => {
                this.setState({ modalCreateEdit: record }, () => { this.refreshPosSelector() })
                this.getTasks(record.key);
            }}
            onDelete={() => { this.deleteSite(record.key) }}
        />)
    }

    renderPaysButtons = (text, record) => {
        return (<TableLineIcons onDelete={() => {
            let records = (this.state.modalCreateEditPayments).filter((d, i) => (record.ind != d.inv && record.pWooId != d.pWooId))
            this.setState({ modalCreateEditPayments: records })
        }} />)
    }

    editInput = (field, text) => {
        let x = this.state.modalCreateEdit;
        let y = this.state.modalCreateEditTask;
        if (x && y) {
            let value = x[field];
            let valueTask = y[field];
            let onChange = (v) => { this.setState({ modalCreateEdit: { ...x, [field]: v } }) }
            let onChangeStorage = (v) => {
                this.setState({
                    modalCreateEdit: { ...x, [field]: v },
                    modalCreateEditTask: { ...y, 'wPOS': null }
                }, () => { this.refreshPosSelector() })
            }
            let gSelectorFiltered = (i) => (<GenericSelector {...this.props} id={this.selectors[i].id} optionsGroup={this.selectors[i].optionsGroup} value={value} onChange={onChange} />)
            let gSelector = (i) => (<GenericSelector {...this.props} id={this.selectors[i].id} optionsGroup={this.selectors[i].optionsGroup} value={value} onChange={onChange} />)
            let gSelectorDef = (i) => (<GenericSelector {...this.props} id={this.selectors[i].id} optionsGroup={this.selectors[i].optionsGroup} value={value} onChange={onChange} addFirstRow={{ code: "0", name: "ללא", id: "0", text: "ללא" }} />)
            let gStorageSelector = (i) => (<GenericSelector {...this.props} id={this.selectors[i].id} optionsGroup={this.selectors[i].optionsGroup} value={value} onChange={onChangeStorage} addFirstRow={{ code: "0", name: "ללא", id: "0", text: "ללא" }} />)
            let gmSelector = (i) => (<GenericSelector {...this.props} id={this.selectors[i].id} multi optionsGroup={this.selectors[i].optionsGroup} value={value ? value.split(',') : []} onChange={(e) => { onChange(e ? e.join(',') : '') }} />)
            // let sSelector = (data) => (<Select style={{ width: '100%' }} value={value} onChange={onChange}>{data.map(x => (<Option value={x.id}>{this.getText(x.text)}</Option>))}</Select>)
            // task functions
            let onChangeTask = (v) => { this.setState({ modalCreateEditTask: { ...y, [field]: v } }) }
            let gSelectorTask = (i) => (<GenericSelector {...this.props} id={this.selectors[i].id} optionsGroup={this.selectors[i].optionsGroup} value={valueTask} onChange={onChangeTask} />)
            let gmSelectorTask = (i) => (<GenericSelector {...this.props} id={this.selectors[i].id} multi optionsGroup={this.selectors[i].optionsGroup} value={valueTask ? valueTask.split(',') : []} onChange={(e) => { onChangeTask(e ? e.join(',') : '') }} />)

            let toggleTable = () => (<Switch checked={(value && parseInt(value) > 0) ? true : false}
                onChange={e => { this.setState({ modalCreateEdit: { ...x, [field]: (e) ? 1 : 0 } }) }} ></Switch>)

            let toggle = () => (<Switch required checked={(valueTask) ? true : false}
                onChange={e => { this.setState({ modalCreateEditTask: { ...y, [field]: (e) ? 1 : 0 } }) }} ></Switch>)

            switch (field) {
                case 'wId': return (<div>{value}</div>)
                case 'wColorDesc': return (<Input value={value} onChange={(v) => { onChange(v.target.value) }} />)
                case 'wSizeDesc': return (<Input value={value} onChange={(v) => { onChange(v.target.value) }} />)
                case 'wName': return (<Input value={value} onChange={(v) => { onChange(v.target.value) }} />)
                case 'wRegularPriceListId': return gSelector(1);
                case 'wSalePriceListId': return gSelectorDef(1); // gSelector(1);
                case 'wTagsTagTypeId': return gSelector(0);
                case 'wCatagoriesTagTypeId': return gSelectorDef(0); // gSelector(0);
                case 'wAttributeTagTypeId': return gmSelector(0);
                case 'wStorageId': return gStorageSelector(2);

                case "wTagsIsVisable": return toggleTable();
                case "wCatagoriesIsVisable": return toggleTable();
                case "wAttributeIsVisable": return toggleTable();

                case 'wGetOrders': return toggle();
                case 'wExportItems': return toggle();
                case 'wExportInventory': return toggle();
                case 'wExportInventoryIsNew': return toggle();
                case 'wExportPrices': return toggle();

                case 'wUser': return (<Input value={valueTask} onChange={(v) => { onChangeTask(v.target.value) }} />);
                case 'wPassword': return (<Input value={valueTask} onChange={(v) => { onChangeTask(v.target.value) }} />);
                case 'wUrl': return (<Input value={valueTask} onChange={(v) => { onChangeTask(v.target.value) }} />);
                case 'wPOS': return gSelectorTask(3);
                case 'wStatusList': return gmSelectorTask(4);

                default: return ''
            }
        } else {
            return ''
        }
    }

    getModalEditCol = (list) => {
        let modalColStyle = { paddingRight: 20, paddingLeft: 20 }
        return (<Col span={window.innerWidth > 600 ? 6 : 24} style={modalColStyle}>
            {list.map(ele => { return (<Item labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} label={this.getText(ele.text)}>{this.editInput(ele.field)}</Item>) })}
        </Col>)
    }

    getModalEditColTask = (list) => {
        let modalColStyle = { paddingRight: 20, paddingLeft: 20 }
        return (<Col span={window.innerWidth > 600 ? 8 : 24} style={modalColStyle}>
            {list.map(ele => { return (<Item labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} label={this.getText(ele.text)}>{this.editInput(ele.field)}</Item>) })}
        </Col>)
    }

    openPaymentsModal = () => {
        this.getPays()
        //this.setState({ modalCreateEditPayments: this.newPaysTemplate })
    }

    render() {
        let gt = this.getText;
        const divStyle = { align: "center", dir: "rtl", padding: "2% 2% 2% 2%", };
        let request = {
            page: 1, sortType: 1, // ui-pagination 
            _page_num: 1, _isAsc: 1, _sort_by: 'col_1', // api
        };
        const mEdit = this.state.modalCreateEdit
        let columns = [
            { title: this.getText(17722), dataIndex: "wId", key: "wId", sorter: true, width: '10%', render: (text) => text },
            { title: this.getText(17723), dataIndex: "wName", key: "wName", width: '16%', sorter: true, render: this.renderCol("wName", "text") },
            { title: this.getText(17724), dataIndex: "wRegularPriceListId", key: "wRegularPriceListId", width: '10%', sorter: true, render: this.renderCol("wRegularPriceListId", "edit") },
            { title: this.getText(17725), dataIndex: "wSalePriceListId", key: "wSalePriceListId", width: '10%', sorter: true, render: this.renderCol("wSalePriceListId", "edit") },
            { title: this.getText(17726), dataIndex: "wCatagoriesTagTypeId", key: "wCatagoriesTagTypeId", width: '10%', sorter: true, render: this.renderCol("wCatagoriesTagTypeId", "edit") },
            { title: this.getText(17727), dataIndex: "wTagsTagTypeId", key: "wTagsTagTypeId", width: '10%', sorter: true, render: this.renderCol("wTagsTagTypeId", "edit") },
            { title: this.getText(17728), dataIndex: "wAttributeTagTypeId", key: "wAttributeTagTypeId", width: '10%', sorter: true, render: this.renderCol("wAttributeTagTypeId", "edit") },
            { title: this.getText(17729), dataIndex: "wStorageId", key: "wStorageId", width: '10%', sorter: true, render: this.renderCol("wStorageId", "edit") },
            { title: this.getText(17730), key: "Settings", width: '14%', render: this.renderButtons }
        ];


        const genericActionButtons = [
            (<Tooltip title={this.getText(18706)}><Button onClick={this.openPaymentsModal} style={{ marginLeft: 5 }}><Icon type={'dollar'} style={{ fontSize: 16 }} /></Button></Tooltip>)
        ];
        const mPays = this.state.modalCreateEditPayments;
        let columns_pays = [
            //{ title: this.getText(10000), dataIndex: "pId", key: "pId", sorter: true, width: '15%', render: (text) => text },
            { title: this.getText(18707), dataIndex: "pWooId", key: "pWooId", width: '50%', sorter: true, render: (text, record) => { return this.renderPayCol("pWooId", text, record) }  },
            { title: this.getText(18708), dataIndex: "pIsPayed", key: "pIsPayed", width: '15%', sorter: true, render: (text, record) => { return this.renderPayCol("pIsPayed", text, record)} },
            //{ title: this.getText(10000), key: "Settings", width: '20%', sorter: true, render: this.renderPaysButtons },  
        ]

        return (<div style={divStyle}>
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                columns={columns}
                percentageToMobile={[false, 40, 40, 20]}
                title={this.getText(17732)}
                onChange={this.handleTableChange}
                actionButtons={this.actionButtons}
                genericActionButtons={genericActionButtons}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />

            <GenericModal
                visible={mEdit}
                onCancel={() => { this.setState({ modalCreateEdit: null }) }}
                title={gt(17733)}
                footer={[
                    <Button key="Back" onClick={() => { this.setState({ modalCreateEdit: null }) }}>{gt(17742)}</Button>, // ביטול
                    <Button key="Save" type="primary" onClick={() => { this.saveEdit(mEdit) }}>{gt(17743)}</Button>, // שמירה
                ]}>
                <Divider orientation="center" plain>{gt(17771)}</Divider>
                <Row>
                    {this.getModalEditColTask(this.field_list.slice(0, 4))}
                    {this.getModalEditColTask([...this.field_list.slice(4, 7), ...this.field_list.slice(12, 13)])}
                    {this.getModalEditColTask(this.field_list.slice(7, 10))}
                </Row>
                <Divider orientation="center" plain>{gt(17772)}</Divider>
                <Row>
                    {this.getModalEditColTask(this.tasks_field_list.slice(0, 3))}
                    {this.getModalEditColTask(this.tasks_field_list.slice(3, 7))}
                    {this.getModalEditColTask(this.tasks_field_list.slice(7, 9))}
                </Row>
            </GenericModal>


            <GenericModal
                visible={!!mPays}
                width={500}
                onCancel={() => { this.setState({ modalCreateEditPayments: null }) }}
                title={gt(18703)}
                footer={[
                    <Button key="Back" onClick={() => { this.setState({ modalCreateEditPayments: null }) }}>{gt(18705)}</Button>, // ביטול
                    <Button key="Save" type="primary" onClick={() => { this.saveEditPay() }}>{gt(18704)}</Button>, // שמירה
                ]}>
                <ResponsiveTable
                    idTable={this.props.data[this.id_pays]}
                    columns={columns_pays}
                    dataSource={this.state.modalCreateEditPayments}
                    percentageToMobile={[false, 40, 40, 20]}
                    title={""/*this.getText(10000)*/}
                    onChange={this.handlePaymentTableChange}
                    actionButtons={this.actionButtons_pays}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
            </GenericModal>
        </div>)
    }

}

export default WooSites;
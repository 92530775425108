/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Form, Select, Checkbox, InputNumber } from "antd";
import PageToolbar from "../../components/PageToolbar";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker, TimePicker } from 'antd';
import moment from "moment";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const FormItem = Form.Item;
const Option = Select.Option;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class PaymentsAggregate extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            PaymentsAggregate: {
                RequestedReport: '0',
                LowerSelectionDate: null,
                LowerSelectionTime: "00:00:00",
                UpperSelectionDate: null,
                UpperSelectionTime: "23:59:59",
                PosList: '',
                ApplyRangeApply: '0',
                ApplyRangeLower: '0',
                ApplyRangeUpper: '0',
                reportName: "reports/paymentsAggregate.pdf",
            }
        };
        this.state.type = "pdf";

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos"
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        //this.setReportParameters("PaymentsAggregate", { RequestedReport: 0 });


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
        //const list = this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',');
        //this.setReportParameters("PaymentsAggregate", { mPosList: list });
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        // let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.PosList === '') params = { ...params, PosList: fullPosList }
        //  if (params.StorageList === '') params = { ...params, StorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10191);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        // let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            posList && posList.dataset[0]
            // &&
            // storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("PaymentsAggregate", this.props.ui.favoriteParams)
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.PaymentsAggregate.LowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.PaymentsAggregate.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    /*
        onChange = (field, value) => {
            this.setReportParameters("PaymentsAggregate", { [field]: value })
            this.setState({
                [field]: value,
            });
        }*/

    onChange = (field, value, field2, value2) => {
        console.log("f ", field, "v ", value);
        this.setReportParameters("PaymentsAggregate", { [field]: value, [field2]: value2 })
    }

    onStartChange = (value) => {
        let arr = (value ? value.format("YYYY-MM-DD#hh:mm:ss") : '').split('#');;
        console.log("start date ", arr);
        this.onChange('LowerSelectionDate', arr[0], 'LowerSelectionTime', arr[1]);
        //this.onChange('LowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        //this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        let arr = (value ? value.format("YYYY-MM-DD#hh:mm:ss") : '').split('#');;
        console.log("end date ", arr);
        this.onChange('UpperSelectionDate', arr[0], 'UpperSelectionTime', arr[1]);

        //this.onChange('UpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        //this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("PaymentsAggregate", { [field]: data });
                    else
                        this.setReportParameters("PaymentsAggregate", { [field]: '' });
                }} />);
    }

    render() {
        const { PaymentsAggregate, endOpen } = this.state;
        const {
            LowerSelectionDate,
            LowerSelectionTime,
            UpperSelectionDate,
            UpperSelectionTime
        } = PaymentsAggregate;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { PaymentsAggregate } = this.state;
        let isEndDateShown = PaymentsAggregate.RequestedReport == 2;

        const isDocsShownStyle = { display: (PaymentsAggregate.RequestedReport == 2) ? 'block' : 'none' }
        const isEndDateShownStyle = { display: isEndDateShown ? 'block' : 'none' }

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        //this.setReportParameters("PaymentsAggregate", {
        //    mPosList: this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',')
        //});

        let mobile = window.innerWidth > 600 ? false : true;

        let datesToFavorites = [{ field: 'LowerSelectionDate', label: this.getText(12183) }];
        if (isEndDateShown) datesToFavorites.push({ field: 'UpperSelectionDate', label: this.getText(12184) });


        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(12180)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '60%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '2', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(12181)}>
                            <Select key={"RequestedReport"} style={{ width: 250 }}
                                //size={"large"}
                                //className={""}
                                //combobox={null}
                                value={this.state.PaymentsAggregate.RequestedReport}
                                onChange={
                                    (value) => {
                                        this.setReportParameters("PaymentsAggregate", { RequestedReport: value });
                                    }
                                }
                            //value={}
                            >
                                <Option key={1} value={'0'}>{this.getText(12191)}</Option>
                                <Option key={2} value={'1'}>{this.getText(12192)}</Option>
                                <Option key={3} value={'2'}>{this.getText(12193)}</Option>
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(12182)} style={isDocsShownStyle}>
                            <Checkbox onChange={this.handleCheckBoxRange}
                                //ApplyRange_Apply
                                //checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                                checked={this.state.PaymentsAggregate.ApplyRangeApply == 1}
                                onChange={(value) => this.setReportParameters("PaymentsAggregate", { ApplyRangeApply: value.target.checked ? 1 : 0 })}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Lower
                                value={this.state.PaymentsAggregate.ApplyRangeLower}
                                onChange={(value) => this.setReportParameters("PaymentsAggregate", { ApplyRangeLower: value })}
                                disabled={this.state.PaymentsAggregate.ApplyRangeApply != '1'}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Upper
                                value={this.state.PaymentsAggregate.ApplyRangeUpper}
                                onChange={(value) => this.setReportParameters("PaymentsAggregate", { ApplyRangeUpper: value })}
                                disabled={this.state.PaymentsAggregate.ApplyRangeApply != '1'}
                                style={{ marginLeft: '4px' }}

                            />
                        </FormItem>

                        <FormItem label={this.getText(12183)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                            <DatePicker
                                // disabledDate={this.disabledStartDate}
                                disabledDate={d => disabledDatePicker(d, null, UpperSelectionDate)}
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                placeholder={this.getText(12194)}
                                value={LowerSelectionDate ? moment(LowerSelectionDate, "YYYY-MM-DD") : null}
                                onChange={(value) => {
                                    this.setReportParameters("PaymentsAggregate", {
                                        LowerSelectionDate: value ? value.format("YYYY-MM-DD") : null,
                                    })
                                }}
                            />
                            <TimePicker
                                format="HH:mm:ss"
                                value={LowerSelectionTime ? moment(LowerSelectionTime, "HH:mm:ss") : null}
                                onChange={(value) => {
                                    this.setReportParameters("PaymentsAggregate", {
                                        LowerSelectionTime: value ? value.format("HH:mm:ss") : null,
                                    })
                                }}
                            />
                        </FormItem>
                        <FormItem label={this.getText(12184)} style={isEndDateShownStyle}>
                            <DatePicker
                                // disabledDate={this.disabledEndDate}
                                disabledDate={d => disabledDatePicker(d, LowerSelectionDate, null)}
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                placeholder={this.getText(12195)}
                                value={UpperSelectionDate ? moment(UpperSelectionDate, "YYYY-MM-DD") : null}
                                onChange={(value) => {
                                    this.setReportParameters("PaymentsAggregate", {
                                        UpperSelectionDate: value ? value.format("YYYY-MM-DD") : null,
                                    })
                                }}
                            />
                            <TimePicker
                                format="HH:mm:ss"
                                value={UpperSelectionTime ? moment(UpperSelectionTime, "HH:mm:ss") : null}
                                onChange={(value) => {
                                    this.setReportParameters("PaymentsAggregate", {
                                        UpperSelectionTime: value ? value.format("HH:mm:ss") : null,
                                    })
                                }}
                            />
                        </FormItem>
                        <FormItem label={this.getText(12185)}>
                            {/* {this.createCheckBox('PosList', Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => obj['code']).join(','))} */}
                            {/* <Select key={"PosList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1} */}
                                 <GenericSelectorPosStorages {...this.props} multi 
                           
                                onChange={(value) => this.setReportParameters("PaymentsAggregate", { PosList: value.join(',') })}
                                value={(this.state.PaymentsAggregate.PosList === '') ? [] : this.state.PaymentsAggregate.PosList.split(',')}
                                />
                            {/* >
                                {listPos}
                            </Select> */}
                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.PaymentsAggregate}
                            posField={'PosList'}
                            datesToFavorites={datesToFavorites}
                            favoriteSave
                        />

                        {/* <FormItem key={"reportName"} label={ this.getText(12186)}>
                            <Select style={{ width: 250 }}
                                //size={"large"}
                                className={"reports/PaymentsAggregate.pdf"}
                                defaultValue={"pdf"}
                                //combobox={null}
                                //defaultValue={"reports/PaymentsAggregate.pdf"}
                                onChange={(value) => {
                                    //const type = this.state.PaymentsAggregate.RequestedReport;
                                    //console.log(type);
                                    //const str = (type === 0) ? '' : type.toString();
                                    //console.log(value,str);
                                    //this.setReportParameters("PaymentsAggregate", { reportName: "reports/PaymentsAggregate" + str + ".pdf"})}
                                    //this.setState({ ...this.state, type: value });
                                    this.setReportParameters("PaymentsAggregate", {
                                        reportName: "reports/paymentsAggregate." + value,
                                        type: value
                                    });

                                }}
                            >
                                <Option key={"pdf"}>{ this.getText(12187)}</Option>
                                <Option key={"xls"}>{ this.getText(12188)}</Option>
                                <Option key={"docx"}>{ this.getText(12189)}</Option>
                            </Select>
                        </FormItem>
                        <Button type={"primary"} onClick={() => {
                            //const type = this.state.PaymentsAggregate.RequestedReport;
                            this.generateReport.apply(this, ["PaymentsAggregate"])
                        }}>{ this.getText(12190)}</Button> */}
                    </Form>
                </Card>
            </div>
        );
    }
}
export default PaymentsAggregate;
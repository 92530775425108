
/* eslint-disable no-unused-expressions */
import React from "react";
import {
    Input, Form, Icon, Button, Popconfirm, Tooltip, Modal, Table,
    Select, Row, Col, Checkbox, message, InputNumber, Radio
} from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import ResponsiveTable from "./ResponsiveTable";
// import ModalPricesHistory from "./ModalPricesHistory";
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import moment from "moment";
import LangContext from "../contextProvider/LangContext";
import ModalCreateEditItemPrices from "./ModalCreateEditItemPrices";
import ModalCreateEditItemSuppliers from "./ModalCreateEditItemSuppliers";
import GenericSelectorItemTags from "./GenericSelectorItemTags";
import GenericModal from "./GenericModal";
import TableLineIcons from "./TableLineIcons";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

//type State = {}
class ModalCreateEditMatrixItem extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            // modalPricesHistory: false,
            useItemCodeNumerator: false,

            modalWooConvItem: false,


        }

        this.id = 'ITEM_CREATE_EDIT';
        this.id_priceList = 'ITEM_PRICE_LIST_CREATE_EDIT';
        this.id_suppliers = 'ITEM_SUPPLIERS_CREATE_EDIT';
        this.id_tags = "ITEM_TAGS_CREATE_EDIT";
        this.id_defaults = "ITEM_DEFULTS_SETTINGS";
        this.woo_id = "WOO_CONV_ITEM";

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state.isValidItemCode = false;
        this.itemCode = null;
        this.loopCheckIfSaveItem = null;

        this.api = {
            get: "get_father_item",

            create_edit: "create_edit_item",
            create_edit_father: "create_edit_item_father",

            get_priceList: "get_prices_by_item",
            edit_priceList: "",
            get_suppliers: "get_suppliers_by_item",
            edit_suppliers: "",
            get_tags: "get_tags_by_item_with_headers",
            get_defaults: "get_item_defaults",

            get_woo: "get_woo_conv_item",
            save_woo: "save_woo_conv_item",
            copy_woo: "copy_woo_conv_item",


            selector_tax_type: "get_tax_types",
            selector_sale_alert: "get_sale_alert_list",
            selector_tags: "get_tags_with_types",
            selector_price_list: "get_prices",
            selector_suppliers: "get_suppliers",

            selector_rulers: "get_rulers_list",
        }

        this.IDs = [
            { id: this.id, api: this.api.get },
            { id: this.id_priceList, api: this.api.get_priceList },
            { id: this.id_suppliers, api: this.api.get_suppliers },
            { id: this.id_tags, api: this.api.get_tags },
            { id: this.id_defaults, api: this.api.get_defaults },
            { id: this.woo_id, api: this.api.get_woo }
        ];

        this.selectors = [
            { id: TableIDs.genericSelectorPriceList, api: this.api.selector_price_list },
            { id: TableIDs.genericSelectorTaxType, api: this.api.selector_tax_type },
            { id: TableIDs.genericSelectorSalesAlerts, api: this.api.selector_sale_alert },
            { id: TableIDs.genericMultiSelectorItemTags, api: this.api.selector_tags },
            { id: TableIDs.genericSelectorSuppliers, api: this.api.selector_suppliers },
            { id: TableIDs.genericSelectorRuler, api: this.api.selector_rulers },
            { id: "CURRENCY_SELECTOR", api: "get_currency_list" },
        ];

        this.IDs.map((x) => { if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericData } } })
        this.selectors.map((x) => { if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } } })

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.picksPriceList = [];
        this.picksSuppliers = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //document.title = "Cash On Tab - פריטים";
        this.selectors.map((x) => {
            let action = (x.api === this.api.selector_tags) ? 'genericMultiSelectorRefreshDataset' : 'genericSelectorRefreshDataset';
            this.props.dataActions[action](x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })

        this.sendAPI('get_GP_UseItemCodeNumerator', '', (ob) => {
            this.setState({ useItemCodeNumerator: ob.data === '1' })
        })

    }
    codeInvalid = () => {
        message.warning(this.getText(10612));
    }
    codeWithSpaces = () => {
        message.warning(this.getText(10613));
    }
    codeValid = () => {
        message.success(this.getText(10614));
    }
    codeExists = () => {
        message.warning(this.getText(10615));
    }
    codeError = () => {
        message.error(this.getText(10616));
    }
    errorSuppliers = () => {
        message.error(this.getText(10617));
    }
    errorPriceList = () => {
        message.error(this.getText(10618));
    }
    errorSuppliersIsEdit = () => {
        message.error(this.getText(10619));
    }
    errorPriceListIsEdit = () => {
        message.error(this.getText(10620));
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditMatrixItem);
        this.clearIsFather();
    }

    onChangeTagList = (e) => {
        let findDefault = this.props.data[this.id_tags].data.find(f => f.split("\f")[1] == '1')
        let valueTagDefault = findDefault ? findDefault.split("\f")[0] : null;
        let allTags = [];
        this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.forEach(node => {
            node.tags.forEach(item => { allTags.push(item) })
        })

        let newData = e.map(id => {
            let findText = allTags.find(f => f.id === id);
            let text = findText ? findText.text : "";
            let isDef = valueTagDefault === id || e.length === 1 ? '1' : '0';
            return id + "\f" + isDef + "\f" + text
        })
        this.props.dataActions.setJson(this.id_tags, { data: newData });
    }

    onSaveItem = (action) => {
        const { workInProgress } = this.props.ui;

        if (workInProgress) {
            clearTimeout(this.loopCheckIfSaveItem);
            this.loopCheckIfSaveItem = setTimeout(() => {
                this.onSaveItem(action);
            }, 1000);
        } else {
            action();
        }
    }



    handleOk = (next) => {
        //e.preventDefault();
        let isValid = this.checkMultiEntries() && this.isMainRulerSet();
        if (isValid == true) {
            this.props.dataActions.genericDataJustSaveEditMulti(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.create_edit_father,

                [
                    { name: this.id },
                    { name: this.id_suppliers, sendDataTable: true },
                    { name: this.id_priceList, sendDataTable: true },
                    { name: this.id_tags, sendDataTable: true }
                ],
                {
                    action: this.props.dataActions.genericDataGetRequested,
                    args: [this.props.context, this.props.user.companyCode,
                    this.props.user.token, this.props.context_api_get/*"get_items_list"*/]
                }
            );


            setTimeout(() => {
                this.onSaveItem(() => {
                    this.props.uiActions.hideModal(TableIDs.modalCreateEditMatrixItem);
                    this.clearIsFather();
                    if (next) next();
                })
            }, 1000);
        }
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalCreateEditMatrixItem);
        this.clearIsFather();
    }

    clearIsFather = () => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsMatrixFather']: null });
    }

    handleClear = () => {
        console.log('clear form');
        this.props.data[this.id].editedRecord = { mCode: this.props.data[this.id].editedRecord['mCode'] };
        this.props.data[this.id_priceList].data = [];
        this.props.data[this.id_suppliers].data = [];
        this.props.data[this.id_tags].data = [];
        this.setState({ ...this.state });
    }

    isMainRulerSet = () => {
        const mainRuler = this.props.data[this.id].editedRecord['mRuler1ID'];
        if (mainRuler && mainRuler !== '') {
            return true;
        }
        else {
            message.error(this.getText(10621));
            return false
        }
    }

    checkMultiEntries = () => {
        let suppliers = this.props.data[this.id_suppliers]
            .data.map((x, index) => { let y = x.split("\f"); return y[1] });
        let unique_suppliers = suppliers.filter((elem, index, self) => {
            return index == self.indexOf(elem);
        });
        let priceList = this.props.data[this.id_priceList]
            .data.map((x, index) => { let y = x.split("\f"); return y[1] });
        let unique_priceList = priceList.filter((elem, index, self) => {
            return index == self.indexOf(elem);
        });

        if (unique_suppliers.length != suppliers.length) {
            this.errorSuppliers()
            return false;
        }
        if (unique_priceList.length != priceList.length) {
            this.errorPriceList()
            return false;
        }

        // editing
        if (parseInt(this.props.data[this.id_suppliers].editing) > -1) {
            this.errorSuppliersIsEdit();
            return false;
        }

        if (parseInt(this.props.data[this.id_priceList].editing) > -1) {
            this.errorPriceListIsEdit();
            return false;
        }
        // not defined
        if (parseInt(this.props.data[this.id_suppliers].data.length) <= 0) {
            message.error(this.getText(10622));
            return false;
        }

        if (parseInt(this.props.data[this.id_priceList].data.length) <= 0) {
            message.error(this.getText(10623));
            return false;
        }

        return true;
    }

    handlePriceListCreate = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id_priceList);
    }

    handleSuppliersCreate = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id_suppliers, { mIsDefault: "0" });
    }

    frameStyle = (text, con) => {
        let labelStyle = { display: 'inline-block', width: '20%', textAlign: 'right', verticalAlign: 'top' }
        return (<div style={{ padding: '4px' }}><div style={labelStyle}><div>{text}</div></div>{con}</div>)
    }

    generateSelectOptions = (text, ph, field, TableID) => {
        const selectorList = (this.props.data[TableID]) ? this.props.data[TableID].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + obj['name']}>{obj['code']} - {obj['name']}</Option>)
        }) : [];
        let mSaleAlertID = field === "mSaleAlertID" ? true : false;
        let gSelector = (<Select placeholder={ph} disabled={!this.state.isValidItemCode}
            value={this.props.data[this.id].editedRecord[field]} defaultValue={''}
            style={{ width: mSaleAlertID ? '60%' : '65%', marginRight: '3%' }}
            showSearch filterOption={true} optionFilterProp="valuefilter"
            onChange={(e) => {
                if (field === 'mRuler1ID' && parseInt(this.props.data[this.id].editedRecord._issetSons)) {
                    message.error(this.getText(16517))
                } else {
                    this.props.dataActions.genericDataSetEdit(this.id, { [field]: e })
                }
            }}
        >
            {field !== 'mTaxTypeId' ? <Option key={0} value={''}>-</Option> : ""}
            {selectorList}
        </Select>);
        let mSaleAlert = (<span>
            {gSelector}
            <Button type={"primary"} icon={"plus"} disabled={!this.state.isValidItemCode}
                onClick={() => { this.props.uiActions.showModal(TableIDs.modalSalesAlert, { ...this.props.ui.data }) }}
            />
        </span>)
        return this.frameStyle(text, mSaleAlertID ? mSaleAlert : gSelector)
    }


    generateInputField = (text, handle, field, len, disb) => {
        let val = this.props.data[this.id].editedRecord[field];
        let isItem = this.state.isValidItemCode;
        //  let isBarcode = this.props.barCodeDisabled;
        let disbld = () => {
            switch (disb) {
                case 1: return !isItem;
                case 2: return isItem;
                case 3: return true //!isItem || isBarcode;
            }
        }
        let getByCode = () => { if (text === this.getText(10624) && val.length > 0) this.get_item_by_code() }


        return this.frameStyle(text, [
            <Input disabled={disbld()} type="text" value={val} maxLength={len}
                style={{ width: '65%', marginRight: '3%' }} onChange={handle} onBlur={getByCode} onPressEnter={getByCode}
                autoFocus={(text === this.getText(10624) && !isItem) || (text === this.getText(10626) && isItem) ? true : false} />,
            field === 'mCode' && !isItem && this.state.useItemCodeNumerator ? (
                <Tooltip title={this.getText(16251)}>
                    <Button type={"primary"} icon={"setting"} onClick={() => {
                        this.sendAPI("get_item_code_numerator", '', (ob) => {
                            handle({ target: { value: ob.data } });
                            setTimeout(this.get_item_by_code, 500);
                        })
                    }} />
                </Tooltip>
            ) : ''])
    }

    generateNumericInputField = (text, handle, val) => {
        return this.frameStyle(text, (<InputNumeric disabled={!this.state.isValidItemCode} value={val}
            style={{ width: '65%', marginRight: '3%' }} onChange={handle} />))
    }



    generateCheckboxField = (text, field) => {
        return (<label style={{ display: 'block' }}><Checkbox style={{ padding: '4px' }} disabled={!this.state.isValidItemCode}
            onChange={(e) => {
                if (field === 'mIsActive' && !e.target.checked) {
                    Modal.confirm({
                        title: "",
                        content: this.getText(18559),
                        iconType: 'exclamation-circle',
                        okText: this.getText(18560),
                        cancelText: this.getText(18561),
                        onOk: () => {
                            this.props.dataActions.genericDataSetEdit(this.id, { [field]: (e.target.checked) ? 1 : 0 })
                        },
                        onCancel() { },
                    })
                } else {
                    this.props.dataActions.genericDataSetEdit(this.id, { [field]: (e.target.checked) ? 1 : 0 })
                }
            }}
            checked={(this.props.data[this.id].editedRecord[field] == 1) ? true : false} />{text}</label>)
    }

    generateTextareaField = (text, field) => {
        return this.frameStyle(text, (<TextArea style={{ display: 'inline-block', width: '65%', marginRight: '3%' }}
            value={this.props.data[this.id].editedRecord[field]}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.target.value }) }}
            disabled={!this.state.isValidItemCode} autoSize={{ minRows: 2, maxRows: 5 }}
        />))
    }

    handleInputChangeItemShortName = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mShortName']: e.target.value });
    }
    handleInputChangeItemLongName = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mName']: e.target.value, ['mShortName']: e.target.value.substring(0, 20) });
    }
    handleInputChangeItemProfit = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mProfitMargin']: e.target.value });
    }
    handleInputChangeItemBarcode = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mBarCode']: e.target.value });
    }
    handleInputChangeItemCode = (e) => {
        this.itemCode = e.target.value;
        this.props.dataActions.genericDataSetEdit(this.id, { ['mCode']: e.target.value });
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id_suppliers].editing == record.key)
            ? this.props.data[this.id_suppliers].editedRecord[col] : text;
        return (checkedVal === 1 || checkedVal === "1");
    }
    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id_suppliers, { [dataIndex]: (e.target.checked) ? "1" : "0" }
        )
    }

    handleCheckBoxChangeIsActive = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsActive']: (e.target.checked) ? 1 : 0 });
    }
    handleCheckBoxChangeisWeightable = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsByWeight']: (e.target.checked) ? 1 : 0 });
    }
    handleCheckBoxChangeisFixedPrice = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsPriceFixed']: (e.target.checked) ? 1 : 0 });
    }
    handleCheckBoxChangeisForSale = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsSold']: (e.target.checked) ? 1 : 0 });
    }
    handleCheckBoxChangeisDiscount = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsDiscountProhibited']: (e.target.checked) ? 1 : 0 });
    }

    titleBar = (title, buttons) => {
        let primary = title === this.getText(10645) ? true : false;
        return (<div style={{ padding: '4px' }}><PageToolbar title={title}
            actionButtons={primary ? buttons : []} genericActionButtons={!primary ? buttons : false} isModal={primary}
            ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataActions={this.props.dataActions} />
        </div>)
    }
    tooltipButton = (title, type, onClick, disabled) => {
        return (<Tooltip title={this.getText(title)}>
            <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={onClick} disabled={disabled}>
                <Icon type={type} style={{ fontSize: "16px" }} />
            </Button>
        </Tooltip>)
    }
    // getTable = (title, source, col, data, id, span) => {
    //     let addButton = this.tooltipButton(10281, 'plus',
    //         this['handle' + data + 'Create'],
    //         (!this.state.isValidItemCode || this.props.ui.workInProgress || this.props.data[id].editing != -1));

    //     let priceListHistory = this.tooltipButton(14287, 'table', () => {
    //         this.setState({ modalPricesHistory: true })
    //     }, !this.props.ui.data || !this.props.ui.data.itemId);


    //     let cols = (window.innerWidth > 600) ? col :
    //         col.map((x) => { return { ...x, title: (<span style={{ fontSize: 11 }}>{x.title}</span>) } });
    //     return this.getCol({ marginLeft: 12 }, span, (<span>
    //         {this.titleBar(title, data == 'PriceList' ? [addButton, priceListHistory] : addButton)}
    //         <ResponsiveTable
    //             idTable={id}
    //             tableOnly={true}
    //             percentageToMobile={[80, 20]}
    //             dataSource={source}
    //             columns={cols}
    //             scroll={{ y: 160 }}
    //             bordered
    //             pagination={false}
    //             onChange={this['handleTableChange' + data]}
    //             ui={this.props.ui}
    //             uiActions={this.props.uiActions} />
    //     </span>))
    // }
    getCol = (style, span, con) => {
        let mSpan = (window.innerWidth > 600) ? (span * 4) + '%' : '96%';
        let mStyle = {
            display: 'inline-block',
            width: mSpan,
            marginLeft: 4,
            marginBottom: 10,
            verticalAlign: 'text-top',
            ...style
        };
        return (<div style={mStyle}>{con}</div>);
    }



    profitCalculator = (record) => {
        let costPrice = 0;
        let mTaxTypeId = this.props.data[this.id].editedRecord.mTaxTypeId;
        let fieldToCalc = mTaxTypeId == 1 ? "mPriceNoVat" : "mPriceVal";
        let sellingPrice = parseFloat(record[fieldToCalc]);

        this.props.data[this.id_suppliers].data.map((x) => {
            let y = x.split("\f");
            if (y[4] === '1') costPrice = parseFloat(y[2]);
        });
        let calculator = (s, c) => parseFloat(s) && parseFloat(c) ? parseFloat((s / c) * 100 - 100).toFixed(2) : '0.00';
        if (record.key != this.props.data[this.id_priceList].editing) {
            console.log('sellingPrice', record.mPriceNoVat, record.mPriceVal, record.mTaxTypeId)
            return calculator(sellingPrice, costPrice);
        } else {
            sellingPrice = parseFloat(this.props.data[this.id_priceList].editedRecord[fieldToCalc])
            return (<InputNumber
                step={1} precision={2}
                style={{ width: '98%', textAlign: 'center' }}
                value={calculator(sellingPrice, costPrice)}
                onChange={(value) => {
                    let plIsTax = record.mPriceNoVat != record.mPriceVal;
                    let mPriceVal = parseFloat(((value + 100) / 100) * costPrice).toFixed(2);
                    let mPriceNoVat = plIsTax ? parseFloat(parseFloat(mPriceVal) / 1.17).toFixed(2) : value;
                    let toUpdate = { mPriceVal: mPriceVal ? mPriceVal : '0.00', mPriceNoVat: mPriceNoVat ? mPriceNoVat : '0.00' }
                    this.props.dataActions.genericDataSetEdit(this.id_priceList, toUpdate);
                }} />);
        }

    }


    wooConvItemEdit = (text, record) => {
        const { editing } = this.props.data[this.woo_id];
        let isEdit = record.key === editing;
        let onEdit = null;
        let onCheck = null;
        let onClose = null;
        let genericIcons = []

        if (isEdit) {
            onClose = () => { this.props.dataActions.genericDataCancelEditing(this.woo_id) }
            onCheck = () => {
                this.props.dataActions.genericDataSaveEdit(
                    this.woo_id, this.props.user.companyCode, this.props.user.token, this.api.save_woo, this.api.get_woo);
            }
        } else {
            onEdit = () => { this.props.dataActions.genericDataStartEditing(this.woo_id, record.key, record.index) }
            genericIcons.push({
                type: "copy",
                onClick: () => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.woo_id, this.props.user.companyCode, this.props.user.token, this.api.copy_woo, this.api.get_woo, {
                        ...record, _all: 1
                    });
                },
                onCancel: () => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.woo_id, this.props.user.companyCode, this.props.user.token, this.api.copy_woo, this.api.get_woo, {
                        ...record, _all: 0
                    });
                },
                tooltip: this.getText(18913),
                popconfirm: { title: this.getText(18913), okText: this.getText(18914), cancelText: this.getText(18915) },
            })
        }

        return (<TableLineIcons {...{ onEdit, onClose, onCheck, genericIcons }} />)
    }

    wooConvItemInput = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.woo_id];
        let isEdit = record.key === editing;
        return isEdit ? (<InputNumber
            value={editedRecord.mWooId}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.woo_id, { mWooId: e }) }}
        />) : text
    }

    render() {
        const { visible, loading } = this.state;
        if (parseInt(this.props.data[this.id_suppliers].editing) > -1) console.log("parseInt(this.props.data[this.id_suppliers].editing) > -1", this.props.data[this.id_suppliers].editing, parseInt(this.props.data[this.id_suppliers].editing))

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10645)}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        let styleRow = { direction: 'ltr' };
        let styleCol = { direction: 'rtl' };
        let stylePadding = { padding: '4px' };
        let modalStyle = { direction: 'rtl' };

        // let tagList = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map(
        //     (node: TagObj, index) => {
        //         return (
        //             <OptGroup label={node.type.text} key={"type_" + node.type.id}>
        //                 {node.tags.map((item: string, index) => {
        //                     return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
        //                 })}
        //             </OptGroup>
        //         )
        //     }
        // )

        let valueTagList = this.props.data[this.id_tags].data ? Object.values(this.props.data[this.id_tags].data) : [];
        let valueTagDefault = null;
        let valueTagObjList = valueTagList.map(x => {
            let y = x.split("\f");
            if (y[1] == '1') valueTagDefault = y[0];
            return { id: y[0], isDefault: y[1], text: y[2] }
        })
        let tagDefaultList = valueTagObjList.map(x => (<Option value={x.id}>{x.text}</Option>));


        // let tooltipTagList = this.getText(10653) + ": ";
        // let tagDefaultList = [];
        // let valueTagDefault = '';

        // this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.forEach((x) => {
        //     x.tags.map((y) => {
        //         let tagNormal = (valueTagList.indexOf(y.id + '\f0') >= 0);
        //         let tagDef = (valueTagList.indexOf(y.id + '\f1') >= 0);
        //         if (tagNormal || tagDef) {
        //             tooltipTagList += y.text + ', ';
        //             tagDefaultList.push(<Option value={y.id}>{y.text}</Option>)
        //             if (tagDef) valueTagDefault = y.id;
        //         }
        //     })
        // });

        let costPrice = 0;
        let mTaxTypeId = this.props.data[this.id].editedRecord.mTaxTypeId;
        const { isValidItemCode } = this.state;

        this.props.data[this.id_suppliers].data.forEach((x) => {
            let y = x.split("\f");
            if (y[4] === '1') costPrice = parseFloat(y[2]);
        });

        let defaultCurrencyCode = this.props.data[this.id_defaults].editedRecord.tCostCurrency

        let modalCreateEditItemProps = { ...this.props, costPrice, mTaxTypeId, isValidItemCode, defaultCurrencyCode }


        let isUserWooSettings = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset &&
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '518')


        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'98%'}
                visible={/*visible*/ false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={() => { this.handleOk() }}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(10654)}</Button>,
                    <Button key="clear" loading={loading} onClick={this.handleClear}>{this.getText(10655)}</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={() => { this.handleOk() }}>{this.getText(10656)}</Button>,
                ]}
                ui={this.props.ui}
                uiActions={this.props.uiActions} >

                {this.getCol(styleCol, 6, (<span>

                    {this.generateInputField(this.getText(10624), this.handleInputChangeItemCode, 'mCode', 15, 2)}
                    {this.generateInputField(this.getText(10625), this.handleInputChangeItemBarcode, 'mBarCode', 15, 3)}
                    {this.generateInputField(this.getText(10626), this.handleInputChangeItemLongName, 'mName', 50, 1)}
                    {this.generateInputField(this.getText(10627), this.handleInputChangeItemShortName, 'mShortName', 20, 1)}
                    {this.generateSelectOptions(this.getText(10628), this.getText(10629), 'mTaxTypeId', TableIDs.genericSelectorTaxType)}
                </span>))}

                {this.getCol({ paddingRight: '1%' }, 12, (<span>
                    {this.generateSelectOptions(this.getText(10630), this.getText(10657), 'mSaleAlertID', TableIDs.genericSelectorSalesAlerts)}

                    {this.frameStyle(this.getText(10631), (
                        // <Tooltip title={tooltipTagList}>
                        <div style={{ width: '65%', marginRight: '3%', display: 'inline-block' }}>
                            <GenericSelectorItemTags {...this.props} multi notReport

                                // <Select
                                // showSearch
                                // filterOption={true}
                                // optionFilterProp="valuefilter"
                                // mode="multiple"
                                // maxTagCount={4}
                                placeholder={this.getText(10658)}
                                onChange={this.onChangeTagList}
                                // style={{ width: '65%', marginRight: '3%' }}
                                value={valueTagObjList.map((x) => x.id)}
                                disabled={!this.state.isValidItemCode}
                            // >
                            //     {tagList}
                            // </Select>
                            />
                        </div>
                        // </Tooltip>
                    ))}

                    {this.frameStyle(this.getText(15363), (<Select
                        showSearch
                        filterOption={true}
                        optionFilterProp="valuefilter"
                        onChange={(e) => {
                            let newData = valueTagObjList.map((x) => {
                                let isDef = x.id === e ? '1' : '0';
                                return x.id + "\f" + isDef + "\f" + x.text
                            })
                            this.props.dataActions.setJson(this.id_tags, { data: newData })
                        }}
                        style={{ width: '65%', marginRight: '3%' }}
                        value={valueTagDefault}
                        disabled={!this.state.isValidItemCode}
                    >
                        {tagDefaultList}
                    </Select>))}

                    {this.generateSelectOptions(this.getText(10632), '', 'mRuler1ID', TableIDs.genericSelectorRuler)}
                    {/* {this.generateSelectOptions(this.getText(10633), '', 'mRuler2ID', TableIDs.genericSelectorRuler)} */}

                    {/* {this.frameStyle(this.getText(10634), (<TextArea style={{ display: 'inline-block', width: '65%', marginRight: '3%' }}
                        value={this.props.data[this.id].editedRecord['mRemarks']}
                        onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mRemarks']: e.target.value }) }}
                        disabled={!this.state.isValidItemCode} autosize={{ minRows: 2, maxRows: 5 }}
                    />))} */}

                    {this.generateTextareaField(this.getText(10634), 'mRemarks')}
                    {this.generateTextareaField(this.getText(18851), 'mInternalRemarks')}


                    {this.frameStyle(this.getText(10276),
                        (<Radio.Group
                            value={this.props.data[this.id].editedRecord['mIsSerialize']}
                            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mIsSerialize']: e.target.value }) }}
                            disabled={!this.state.isValidItemCode} >
                            <Radio value="0">{this.getText(18499)}</Radio><br />
                            <Radio value="1">{this.getText(18500)}</Radio><br />
                            <Radio value="2">{this.getText(18501)}</Radio><br />
                            <Radio value="3">{this.getText(18502)}</Radio>
                        </Radio.Group>))}

                </span>))}

                {this.getCol({}, 3.5, (<span>
                    {this.generateCheckboxField(this.getText(10635), 'mIsActive')}
                    {this.generateCheckboxField(this.getText(10636), 'mIsByWeight')}
                    {this.generateCheckboxField(this.getText(10637), 'mIsSold')}
                    {this.generateCheckboxField(this.getText(10638), 'mIsPriceFixed')}
                    {this.generateCheckboxField(this.getText(10639), 'mIsDiscountProhibited')}
                    {this.generateCheckboxField(this.getText(10640), 'mIsForInventory')}
                    {/* {this.generateCheckboxField(this.getText(10641), 'mIsSerialize')} */}

                </span>))}

                {this.getCol({ paddingRight: '1%' }, 3, (<span>
                    <div>

                        <PictureView
                            user={this.props.user} data={this.props.data} dataActions={this.props.dataActions} ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                            url={this.props.data[this.id].editedRecord['mPictureFile']}
                            onChange={(url) => this.props.dataActions.genericDataSetEdit(this.id, { ['mPictureFile']: url })}
                            onRemove={() => this.props.dataActions.genericDataSetEdit(this.id, { ['mPictureFile']: '' })}
                            onUploadedSuccess={(response) => { }}
                            onUploadedFailed={(errorMessage, errorCode) => { }}
                            imageType={'I'}
                            aspect={4 / 3}
                        />
                    </div>

                    <div>
                        <Button
                            type="primary"
                            // disabled={!this.props.data[this.id].editedRecord.key}
                            style={{ marginTop: 10, width: '100%' }}
                            onClick={() => {
                                const { mCode } = this.props.data[this.id].editedRecord
                                this.handleOk(() => {
                                    this.props.onChangeParentItemCode(mCode)
                                    this.props.uiActions.showModal(TableIDs.modalMatrix)
                                })
                            }}>{this.getText(16359)}</Button>

                        {isUserWooSettings ?
                            <Button
                                style={{ marginTop: 10, width: '100%' }}
                                type="primary"
                                onClick={() => { this.setState({ modalWooConvItem: true }) }}
                            >{this.getText(18909)}</Button>
                            : ""}
                    </div>
                </span>))}
                {this.getCol({ marginLeft: 12 }, 12, (<ModalCreateEditItemPrices {...modalCreateEditItemProps} />))}
                {this.getCol({ marginLeft: 12 }, 12, (<ModalCreateEditItemSuppliers {...modalCreateEditItemProps} />))}

                {/* {this.getTable(this.getText(10642), dataSourcePriceList, columnsPriceList, 'PriceList', this.id_priceList, 7)} */}
                {/* {this.getTable(this.getText(10643), dataSourceSuppliers, columnsSuppliers, 'Suppliers', this.id_suppliers, 12)} */}

                {/* <ModalPricesHistory
                    visible={this.state.modalPricesHistory}
                    close={() => { this.setState({ modalPricesHistory: false }) }}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                    user={this.props.user}
                /> */}
                <GenericModal
                    visible={this.state.modalWooConvItem}
                    onCancel={() => { this.setState({ modalWooConvItem: false }) }}
                    width={600}
                    title={this.getText(18909)}>
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.woo_id]}
                        columns={[
                            { title: this.getText(18910), width: "50%", key: "mWooSiteName", dataIndex: "mWooSiteName", render: t => t },
                            { title: this.getText(18911), width: "35%", key: "mWooId", dataIndex: "mWooId", render: this.wooConvItemInput },
                            { title: this.getText(18912), width: "15%", render: this.wooConvItemEdit },
                        ]}
                        pagination={{ pageSize: 10 }}
                    />
                </GenericModal>
            </Modal >
        )
    }


    refreshData = () => {
        let mItemCode = this.props.ui.data ? this.props.ui.data.itemId : 0
        this.IDs.map((x) => {
            if (x.id === this.id_priceList || x.id === this.id_suppliers || x.id === this.woo_id) {
                this.props.dataActions.genericDataSetFilter(x.id, this.props.user.companyCode, this.props.user.token, { mItemCode }, x.api);
            } else {
                this.props.dataActions.genericDataGetEditWithParams(x.id, this.props.user.companyCode, this.props.user.token, { mItemCode }, x.api);
            }
        })
        // this.IDs.map((x) => {
        //     this.props.dataActions.genericDataGetEditWithParams(x.id, this.props.user.companyCode, this.props.user.token,
        //         { mItemCode: this.props.ui.data ? this.props.ui.data.itemId : 0 }, x.api);
        // })

        // setTimeout(() => {
        //     this.props.dataActions.genericDataCancelEditing(this.id_priceList, true);
        //     this.props.dataActions.genericDataCancelEditing(this.id_suppliers, true);
        // }, 1500);
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log('shouldComponentUpdate: modalCreateEditMatrixItem ');
        //const isFather = nextProps.data[this.id].editedRecord['mIsMatrixFather'];
        const isFather = nextProps.ui.isEditFatherItem;
        console.log('CCC111  ', nextProps);
        console.log('CCC222  ', isFather);
        //console.log('CCC333  ', (!isFather || isFather == 1));
        return (isFather === true) ? true : false;
    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };
        let pui = this.props.ui.data;
        let ppui = prevProps.ui.data;

        if ((pui && ppui && pui.itemId !== ppui.itemId) ||
            (prevProps.toggle === false && this.props.toggle === true)) {
            this.state.isValidItemCode = (this.props.ui.data && !this.props.ui.data.itemId) ? false : true;
            if (this.props.ui.data && this.props.ui.data.isNew) {
                this.props.ActionQueue.addToQueue({
                    action: this.props.uiActions.setModalUIData,
                    args: [{ itemId: null }]
                });
            }
            this.refreshData();
        }

    }

    get_item_by_code = (e) => {
        if (this.itemCode && this.itemCode !== '') {
            if (this.itemCode.search(" ") > -1) {
                this.codeWithSpaces();
            } else {
                this.api_get_item_by_code();
            }
        }
    }

    api_get_item_by_code = () => {
        console.log("API_GET_MATRIX_ITEM_BY_CODE\n");
        var dataSend = "mItemCode\r" + this.itemCode;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "check_if_exsist_item", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        // var job = new Job(user, "get_item"/*"get_father_item"*/, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallbackGetItemByCode, (e) => { this.codeInvalid(e); }); // callback problem here
    }

    successCallbackGetItemByCode = (ob) => {
        console.log("get item by code success:" + ob.data);

        if (ob.data.length == 0) {
            this.codeError();
        } else {
            let defOb = () => {
                let defaults = this.props.data[this.id_defaults];
                let y = defaults.data[0] ? defaults.data[0].split("\f") : [];
                let ob = {}
                defaults.headers.map((x, i) => { ob = { ...ob, [x]: y[i] } });
                return ob;
            }

            // let arr = ob.data.split("\r");
            // if (arr[arr.length - 1] == 0) {
            let arr = ob.data.split("\f");
            if (arr[0] == 0) {
                this.codeValid();
                let s = this.state;
                s.isValidItemCode = true;
                this.setState(s, /*tmp defaults TODO: remove this callback*/() => {
                    this.props.dataActions.genericDataSetEdit(this.id,
                        {
                            // ['mShortName']: this.getText(10644) + " " + this.itemCode,
                            // ['mName']: this.getText(10644) + " " + this.itemCode,
                            ['mIsActive']: defOb().tIsActive,
                            ['mIsByWeight']: defOb().tIsByWeight,
                            ['mIsSold']: defOb().tIsSold,
                            ['mIsPriceFixed']: defOb().tIsPriceFixed,
                            ['mIsDiscountProhibited']: defOb().tIsDiscountProhibited,
                            ['mIsForInventory']: defOb().tIsForInventory,
                            ['mIsSerialize']: defOb().tIsSerialize,
                            ['mTaxTypeId']: defOb().tTaxTypeId,
                            ['mSaleAlertID']: defOb().tSaleAlertId,
                        });

                    this.props.dataActions.dataTableSetRow(this.id_suppliers, 0, {
                        key: 0,
                        mItemCode: '',
                        mSupplierId: defOb().tSupplierID,
                        mItemCostNoVat: '0.00',
                        mCurrencyCode: defOb().tCostCurrency ? defOb().tCostCurrency : '1',
                        mSupplierItemCode: '',
                        mIsDefault: '1',
                        number: 1
                    }, true);

                    let tTagList = defOb().tTagList;
                    if (tTagList) this.onChangeTagList(tTagList.split(','));
                    // this.props.dataActions.setData('tags', tTagList.split(',').map((x, i) => i === 0 ? x + '\f1' : x + '\f0'));

                    let priceList = this.props.data[TableIDs.genericSelectorPriceList].dataset;

                    for (let x = priceList.length; x > 0; x--) {
                        this.handlePriceListCreate();
                        this.props.dataActions.dataTableSetRow(this.id_priceList, 0, {
                            key: 0,
                            mId: "",
                            mItemCode: "",
                            mPriceListId: priceList[x - 1].code,
                            mPriceVal: '0.00',
                            number: this.props.data[this.id_priceList].data.length + 1
                        }, true);
                    }
                });

            } else {
                // this.codeInvalid();
                message.warning(arr[1]);
            }
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


}
export default ModalCreateEditMatrixItem;

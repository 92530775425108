/* @flow */
/*jshint esversion: 6 */
import { Button, Col, Form, InputNumber, message } from "antd";
import { Row } from "antd/es/grid";
import moment from "moment";
import React, { Component } from "react";
import "../../App.css";
import DateTimePicker from "../../components/DateTimePicker";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";


class CurrencyRates extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _currency: null,
            _fromDate: null,
            _toDate: null,

            modalFilter: false
        };

        this.id = "CURRENCY_RATES";

        this.api = {
            get: "get_currency_rates",
            create_edit: "create_edit_currency_rates",
            delete: "delete_currency_rates",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data.CURRENCY_SELECTOR === undefined) props.data.CURRENCY_SELECTOR = { ...props.data.genericSelector };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            'CURRENCY_SELECTOR', this.props.user.companyCode, this.props.user.token, 'get_currency_list');
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(18170);
    }

    getDatePicker = (value, onChange, minDate, maxDate) => {
        return (<DateTimePicker format="DD/MM/YYYY" {...{ value, onChange, minDate, maxDate }} />)
    }

    getCurrencySelector = (value, onChange, disabled) => {
        const { dataset } = this.props.data.CURRENCY_SELECTOR;
        const filterList = dataset.map(x => x.code).filter(f => f > 1)
        return (<GenericSelector {...this.props} id={"CURRENCY_SELECTOR"}  {...{ value, onChange, disabled, filterList }} />)
    }

    handleCreateNew = () => {
        if (this.props.data[this.id].editing === -1) {
            this.props.dataActions.genericDataStartInlineCreate(this.id);
            setTimeout(() => {
                this.props.dataActions.genericDataSetEdit(this.id, { rDate: moment().format("YYYY-MM-DD") })
            }, 500);
        }
    }



    render() {
        const gt = this.getText;
        const sorter = true;

        const { editedRecord, editing } = this.props.data[this.id];
        const { _currency, _fromDate, _toDate, modalFilter } = this.state

        const onChange = (data) => { this.props.dataActions.genericDataSetEdit(this.id, data) }
        const renderDate = (text, record) => record.key == editing && editing == 0 ? this.getDatePicker(editedRecord.rDate, e => { onChange({ rDate: e }) }) : moment(text, "YYYY-MM-DD").format("DD/MM/YYYY");
        const renderValue = (text, record) => record.key == editing ? (<InputNumber value={editedRecord.rValue} onChange={e => { onChange({ rValue: e }) }} />) : text;
        const renderCurrencyID = (text, record) => this.getCurrencySelector(record.key != editing || editing > 0 ? text : editedRecord.rCurrencyID, e => { onChange({ rCurrencyID: e }) }, record.key != editing || editing > 0);
        const renderEdit = (text, record) => {
            if (record.key == editing) {
                return (<TableLineIcons
                    onCheck={() => {
                        if(!editedRecord.rDate||!editedRecord.rCurrencyID||!editedRecord.rValue){
                            message.error(this.getText(18442));
                            return;
                        }
                        this.props.dataActions.genericDataSaveEdit(
                            this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get)
                    }}
                    onClose={() => {
                        if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                        else this.props.dataActions.genericDataCancelEditing(this.id);
                    }}
                />)
            } else {
                return (<TableLineIcons
                    onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                    onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get) }}
                    deleteQuetion={{ title: gt(18176), okText: gt(18177), cancelText: gt(18178) }}
                />);
            }
        }

        const setFilter = () => {
            const { _currency, _fromDate, _toDate } = this.state

            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, { _currency, _fromDate, _toDate }, this.api.get);
        }

        const closeModalFilter = () => { this.setState({ modalFilter: false }) }

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const modalButtons = [
            <Button type="primary" onClick={() => { this.setState({ modalFilter: false }, setFilter) }}>{gt(18183)}</Button>,
            <Button onClick={() => { this.setState({ _currency: null, _fromDate: null, _toDate: null }, setFilter) }}>{gt(18184)}</Button>,
            <Button onClick={closeModalFilter}>{gt(18185)}</Button>,
        ]

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(18171)}
                columns={[
                    { title: gt(18172), key: "rDate", dataIndex: "rDate", width: "25%", sorter, render: renderDate },
                    { title: gt(18173), key: "rCurrencyID", dataIndex: "rCurrencyID", width: "40%", sorter, render: renderCurrencyID },
                    { title: gt(18174), key: "rValue", dataIndex: "rValue", width: "25%", sorter, render: renderValue },
                    { title: gt(18175), width: "10%", render: renderEdit },
                ]}
                actionButtons={[{
                    buttonType: 'add',
                    buttonFunction: this.handleCreateNew
                }, {
                    buttonType: 'filter',
                    buttonFunction: () => { this.setState({ modalFilter: true }) }
                }]}
                searchBox
                filters={{ _currency, _fromDate, _toDate, _isAsc: 0 }}
            />
            <GenericModal visible={modalFilter} onCancel={closeModalFilter} title={gt(18179)} width={700} footer={modalButtons}>
                <Row>
                    <Col span={window.innerWidth >= 600 ? 12 : 24}>
                        <Form.Item label={gt(18180)}>{this.getDatePicker(_fromDate, e => { this.setState({ _fromDate: e }) }, null, _toDate)}</Form.Item>
                    </Col>
                    <Col span={window.innerWidth >= 600 ? 12 : 24}>
                        <Form.Item label={gt(18181)}>{this.getDatePicker(_toDate, e => { this.setState({ _toDate: e }) }, _fromDate)}</Form.Item>
                    </Col>
                </Row>
                <Form.Item label={gt(18182)}>{this.getCurrencySelector(_currency, e => { this.setState({ _currency: e }) })}</Form.Item>
            </GenericModal>
        </div>)
    }

}

export default CurrencyRates;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import TableLineIcons from "../../components/TableLineIcons";
import { Icon, Button, Tooltip, Popover, Form, InputNumber, Modal, message, Radio, Row, Col } from "antd";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import * as XLSX from 'xlsx/xlsx.mjs';
import GenericModalForm from "../../components/GenericModalForm";
import DateTimePicker from "../../components/DateTimePicker";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const { confirm } = Modal;


class GiftCardReport extends Component {
    constructor(props: Props) {
        super(props);

        this.defaultFilters = {
            _fromDate: null,
            _toDate: null,
            _posList: '',
            _fromSum: null,
            _toSum: null,
            _cardType: null,
            _isUse: '-1',
        }

        this.state = {
            modalView: null,
            modalNewGiftCard: false,
            modalNewGiftCardFromNum: null,
            modalNewGiftCardToNum: null,
            modalNewGiftCardSum: null,
            modalNewGiftCardQty: null,
            modalNewGiftCardIsContinuous: '0',
            modalNewGiftCardType: '7',

            modalAddToGiftCard: null,
            modalAddToGiftCardSum: null,

            modalFilter: false,
            filters: this.defaultFilters,
        };

        this.id = "GIFT_CARD_TABLE";
        this.activity_id = "GIFT_CARD_ACTIVITY";

        this.api = {
            get: "get_gift_card_table",
            get_activity: "get_deals_activity_table_list",

            check_num: "check_numbers_gift_cards",
            create: "create_gift_cards",
            reset: "reset_gift_card",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorPos] === undefined) props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, 'list_pos');
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15950);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    viewActivity = (text, record) => {
        let genericIcons = [
            {
                type: 'eye',
                tooltip: this.getText(15959),
                onClick: () => { this.setState({ modalView: record }) }
            }]

        if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code == '512')) {
            genericIcons.push({
                type: 'stop',
                tooltip: this.getText(18554),
                popconfirm: { title: this.getText(18555), okText: this.getText(18556), cancelText: this.getText(18557) },
                onClick: () => {
                    this.sendAPI(this.api.reset, record.key, () => {
                        message.success(this.getText(18558))
                        this.refreshData()
                    })
                },
                disabled: record.gcBalance == 0
            })
        }

        return (<TableLineIcons genericIcons={genericIcons} />)
    }

    generateReport = (reportName, params) => {

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                {
                    ...params,
                    reportName,
                    REPORT_LOCALE: this.getText(101),
                }
            ]
        });
    }

    renderPos = (pos) => {
        let data = this.props.data[TableIDs.genericSelectorPos].dataset.find(f => f.code === pos)
        return data ? pos + ' - ' + data.name : pos
    }

    reportBtn = (reportName, params) => {
        let contentReportTypes = [
            <Tooltip title={this.getText(11217)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                    type={"file-pdf"} onClick={() => { this.generateReport(reportName + ".pdf", params) }} />
            </Tooltip>,
            <Tooltip title={this.getText(11218)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                    type={"file-excel"} onClick={() => { this.generateReport(reportName + ".xls", params) }} />
            </Tooltip>,
            <Tooltip title={this.getText(11219)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                    type={"file-word"} onClick={() => { this.generateReport(reportName + ".docx", params) }} />
            </Tooltip>
        ]

        return [
            <Tooltip title={this.getText(15964)}>
                <Popover content={contentReportTypes} title={this.getText(15964)} trigger="click">
                    <Button style={{ marginLeft: 5, marginTop: 5 }}><Icon type={'printer'} style={{ fontSize: 16 }} /></Button>
                </Popover>
            </Tooltip>]
    }

    createInputNumberField = (label, field) => {
        return (<Form.Item label={this.getText(label)}>
            <InputNumber
                value={this.state[field]}
                onChange={e => { this.setState({ [field]: e }) }}
                style={{ width: "100%" }}
            />
        </Form.Item>)
    }

    createNewGiftCard = () => {
        const { modalNewGiftCardFromNum, modalNewGiftCardToNum, modalNewGiftCardSum, modalNewGiftCardIsContinuous, modalNewGiftCardQty, modalNewGiftCardType } = this.state;
        const dataSend = "_from\f_to\f_sum\f_type\f_qty\f_isContinuous\r"
            + modalNewGiftCardFromNum + "\f"
            + modalNewGiftCardToNum + "\f"
            + modalNewGiftCardSum + "\f"
            + modalNewGiftCardType + "\f"
            + modalNewGiftCardQty + "\f"
            + modalNewGiftCardIsContinuous;

        const countCards = modalNewGiftCardIsContinuous == 1 ? modalNewGiftCardQty : modalNewGiftCardToNum - modalNewGiftCardFromNum + 1;

        const onCreate = () => {
            confirm({
                title: this.getText(18545),
                content: this.getText(18546) + " " + countCards + " " + this.getText(18547) + " " + modalNewGiftCardSum + " " + this.getText(18548),
                iconType: 'exclamation-circle',
                okText: this.getText(18541),
                cancelText: this.getText(18542),
                onOk: () => {
                    this.sendAPI(this.api.create, dataSend, (ob) => {
                        this.setState({ modalNewGiftCard: false }, this.refreshData)
                        const apiData = ob.data ? ob.data.split("\r").map(x => { return { num: x, sum: modalNewGiftCardSum } }) : []
                        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                        const ws = XLSX.utils.json_to_sheet(apiData);
                        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                        const blob = new Blob([excelBuffer], { type: fileType });
                        let a = document.createElement("a");
                        a.href = URL.createObjectURL(blob);
                        a.download = 'newGiftCards.xlsx';
                        a.click();


                    })
                },
                onCancel() { },
            });
        }

        if (modalNewGiftCardIsContinuous == 1) {
            onCreate()
        } else {
            this.sendAPI(this.api.check_num, dataSend, ob => {
                if (ob.data === "not exists") {
                    onCreate()
                } else {
                    confirm({
                        title: this.getText(ob.data ? 18544 : 18543),
                        content: ob.data ? ob.data.split("\r").join(",") : "",
                        iconType: 'exclamation-circle',
                        okButtonProps: { style: { display: 'none' } },
                        okText: this.getText(18541),
                        cancelText: this.getText(18542),
                        onOk() { },
                        onCancel() { },
                    });
                }
            })
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    onFilter = () => {
        const { filters } = this.state;
        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, { ...filters, _page_num: 1, page: 1 }, this.api.get);
        })
    }

    onClearFilter = () => {
        const filters = this.defaultFilters
        this.setState({ modalFilter: false, filters }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, { ...filters, _page_num: 1, page: 1 }, this.api.get);
        })
    }

    onCancelFilter = () => {
        this.setState({ modalFilter: false, filters: this.props.data[this.id].filters });
    }

    getFilterField = (field) => {
        const { filters } = this.state;
        const value = filters[field];
        const onChange = v => this.setState({ filters: { ...filters, [field]: v } })

        switch (field) {
            case '_fromDate': return (<DateTimePicker format="DD/MM/YYYY" maxDate={filters._toDate} {...{ value, onChange }} />)
            case '_toDate': return (<DateTimePicker format="DD/MM/YYYY" minDate={filters._fromDate} {...{ value, onChange }} />)
            case '_posList': return (<GenericSelectorPosStorages {...this.props} multi value={value ? value.split(",") : []} onChange={e => onChange(e ? e.join(",") : "")} />)
            case '_fromSum': case '_toSum': return (<InputNumber {...{ value, onChange }} />)
            case '_cardType': return this.getCardTypeRadio(value, e => { onChange(e.target.value) })
            case '_isUse': return this.getIsUseRadio(value, e => { onChange(e.target.value) })
        }

    }

    getCardTypeRadio = (value, onChange) => {
        return (<Radio.Group {...{ value, onChange }}>
            <Radio value={"5"}>{this.getText(19199)}</Radio>
            <Radio value={"6"}>{this.getText(19200)}</Radio>
            <Radio value={"7"}>{this.getText(19201)}</Radio>
        </Radio.Group>)
    }

    getIsUseRadio = (value, onChange) => {
        return (<Radio.Group {...{ value, onChange }}>
            <Radio value={"-1"}>{this.getText(19702)}</Radio>
            <Radio value={"1"}>{this.getText(19700)}</Radio>
            <Radio value={"0"}>{this.getText(19701)}</Radio>
        </Radio.Group>)
    }


    render() {
        const { modalNewGiftCardFromNum, modalNewGiftCardToNum, modalNewGiftCardSum, modalNewGiftCardIsContinuous, modalNewGiftCardQty } = this.state;
        const createNewGiftCardDisabled = modalNewGiftCardIsContinuous == 1 ?
            !(modalNewGiftCardQty && modalNewGiftCardSum)
            : !(modalNewGiftCardFromNum && modalNewGiftCardToNum && modalNewGiftCardSum)

        const divStyle = {
            align: 'center',
            dir: 'rtl', // dir: 'ltr'
            padding: 4,
        }

        const gt = this.getText

        const { _words, _sort_by, _isAsc, _fromDate, _toDate, _posList, _fromSum, _toSum, _cardType, _isUse } = this.props.data[this.id].filters;

        let reportParams = { _words: encodeURIComponent(_words), _sort_by, _isAsc, _fromDate, _toDate, _posList, _fromSum, _toSum, _cardType, _isUse }
        let reportActivityParams = this.state.modalView ?
            {
                _id: this.state.modalView.gcID,
                _type: encodeURIComponent(this.state.modalView.gcType),
                _number: this.state.modalView.gcNumber,
            }
            : {}


        let render = t => t;
        let renderPlus = t => t > 0 ? t : '';
        let renderMinus = t => t < 0 ? t : '';

        let columns = [
            { title: this.getText(15952), key: "gcNumber", dataIndex: "gcNumber", sorter: true, width: '10%', render },
            { title: this.getText(15953), key: "gcType", dataIndex: "gcType", sorter: true, width: '30%', render },
            { title: this.getText(15954), key: "gcDate", dataIndex: "gcDate", sorter: true, width: '10%', render },
            { title: this.getText(15955), key: "gcDocNum", dataIndex: "gcDocNum", sorter: true, width: '10%', render },
            { title: this.getText(15956), key: "gcDocType", dataIndex: "gcDocType", sorter: true, width: '15%', render },
            { title: this.getText(15957), key: "gcDocPos", dataIndex: "gcDocPos", sorter: true, width: '10%', render: this.renderPos },
            { title: this.getText(15958), key: "gcBalance", dataIndex: "gcBalance", sorter: true, width: '10%', render },
            { title: this.getText(15959), width: '5%', render: this.viewActivity },
        ];

        let activityColumns = [
            { title: this.getText(15960), dataIndex: "dDate", key: "dDate", width: '15%', render },
            { title: this.getText(15961), dataIndex: "dTime", key: "dTime", width: '15%', render },
            { title: this.getText(15962), dataIndex: "dDocNum", key: "dDocNum", width: '15%', render },
            { title: this.getText(16677), dataIndex: "dPos", key: "dPos", width: '25%', render: this.renderPos },
            { title: this.getText(16678), dataIndex: "dSumPoints", key: "dSumPointsPlus", width: '15%', render: renderPlus },
            { title: this.getText(16679), dataIndex: "dSumPoints", key: "dSumPointsMinus", width: '15%', render: renderMinus },
        ];

        let modalTitle = this.state.modalView ? (<span>
            <span>{this.state.modalView.gcType + ' - ' + this.state.modalView.gcNumber}</span>
            <span style={{ paddingRight: 30 }}>{this.getText(16676) + ': ' + this.state.modalView.gcBalance}</span>
        </span>) : ''

        let actionButtons = [{
            buttonType: 'filter',
            buttonFunction: () => { this.setState({ modalFilter: true }) }
        }]

        if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code == '511')) {
            actionButtons.push({
                buttonType: 'add',
                buttonFunction: () => {
                    this.setState({
                        modalNewGiftCard: true,
                        modalNewGiftCardFromNum: null,
                        modalNewGiftCardToNum: null,
                        modalNewGiftCardSum: null,
                        modalNewGiftCardQty: null,
                    })
                }
            })
        }

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={this.getText(15951)}
                columns={columns}
                filters={this.state.filters}
                percentageToMobile={[30, 40, 0, 0, 0, 0, 20]}
                searchBox
                actionButtons={actionButtons}
                genericActionButtons={this.reportBtn("reports/GiftCards", reportParams)}
            />
            <GenericModal
                visible={this.state.modalView !== null}
                onCancel={() => { this.setState({ modalView: null }, () => { this.props.dataActions.setJson(this.activity_id, { data: [] }) }) }}
                width={1000}
                title={modalTitle}
                actionButtons={[{
                    buttonType: 'add',
                    buttonFunction: () => {
                        this.setState({
                            modalAddToGiftCard: this.state.modalView?.gcID,
                            modalAddToGiftCardSum: null,
                        })
                    }
                }]}
                genericActionButtons={this.reportBtn("reports/GiftCardDetailed", reportActivityParams)}
            >
                {this.state.modalView ? (
                    <GenericTable
                        {...this.props}
                        id={this.activity_id}
                        getApi={this.api.get_activity}
                        tableOnly
                        columns={activityColumns}
                        filters={{ _id: this.state.modalView.gcID }}
                    />
                ) : ""}
            </GenericModal>

            <GenericModal
                visible={this.state.modalAddToGiftCard !== null}
                onCancel={() => { this.setState({ modalAddToGiftCard: null }) }}
                width={300}
                title={this.getText(19477)}
                footer={[
                    <Button onClick={() => { this.setState({ modalAddToGiftCard: null }) }}>{this.getText(19478)}</Button>,
                    <Button type="primary" disabled={!this.state.modalAddToGiftCardSum} onClick={() => {
                        const { modalAddToGiftCard, modalAddToGiftCardSum, modalView } = this.state;
                        this.sendAPI("add_to_gift_card", "_id\f_sum\r" + modalAddToGiftCard + "\f" + modalAddToGiftCardSum, () => {
                            this.props.dataActions.genericDataGetRequested(
                                this.activity_id, this.props.user.companyCode, this.props.user.token, this.api.get_activity);
                            this.refreshData()
                            this.setState({
                                modalAddToGiftCard: null,
                                modalView: {
                                    ...modalView,
                                    gcBalance: parseFloat(parseFloat(modalView.gcBalance ?? 0) + modalAddToGiftCardSum).toFixed(2)
                                }
                            })
                        })
                    }}>{this.getText(19479)}</Button>,
                ]}
            >

                <Form.Item label={this.getText(19480)}>
                    <InputNumber
                        min={0}
                        max={10000}
                        value={this.state.modalAddToGiftCardSum}
                        onChange={e => { this.setState({ modalAddToGiftCardSum: e }) }} />
                </Form.Item>
            </GenericModal>

            <GenericModal
                visible={this.state.modalNewGiftCard}
                onCancel={() => { this.setState({ modalNewGiftCard: false }) }}
                width={400}
                title={this.getText(18537)}
                footer={[
                    <Button onClick={() => { this.setState({ modalNewGiftCard: false }) }}>{this.getText(18542)}</Button>,
                    <Button type="primary" disabled={createNewGiftCardDisabled} onClick={this.createNewGiftCard}>{this.getText(18541)}</Button>,
                ]}
            >
                <Form.Item>
                    <Radio.Group
                        value={this.state.modalNewGiftCardIsContinuous}
                        onChange={e => { this.setState({ modalNewGiftCardIsContinuous: e.target.value }) }}>
                        <Radio value={"0"}>{this.getText(19196)}</Radio>
                        <Radio value={"1"}>{this.getText(19197)}</Radio>
                    </Radio.Group>
                </Form.Item>
                {this.state.modalNewGiftCardIsContinuous == '1' ?
                    this.createInputNumberField(19198, "modalNewGiftCardQty")
                    : (<Row>
                        <Col span={12}>{this.createInputNumberField(18538, "modalNewGiftCardFromNum")}</Col>
                        <Col span={12}>{this.createInputNumberField(18539, "modalNewGiftCardToNum")}</Col>
                    </Row>)}
                {this.createInputNumberField(18540, "modalNewGiftCardSum")}
                <Form.Item label={this.getText(19202)}>
                    {this.getCardTypeRadio(this.state.modalNewGiftCardType, e => { this.setState({ modalNewGiftCardType: e.target.value }) })}
                </Form.Item>
            </GenericModal>

            <GenericModalForm
                visible={this.state.modalFilter}
                onCancel={this.onCancelFilter}
                title={this.getText(19690)}
                width={1000}
                columnCount={2}
                footerBtn={[
                    { type: "primary", text: this.getText(19691), onClick: this.onFilter },
                    { text: this.getText(19692), onClick: this.onClearFilter },
                    { text: this.getText(19693), onClick: this.onCancelFilter },
                ]}
                formItems={[
                    { label: gt(19694), content: this.getFilterField('_fromDate') },
                    { label: gt(19695), content: this.getFilterField('_toDate') },
                    { label: gt(19696), content: this.getFilterField('_posList') },
                    { label: gt(19697), content: this.getFilterField('_fromSum') },
                    { label: gt(19698), content: this.getFilterField('_toSum') },
                    { label: gt(19699), content: this.getFilterField('_cardType') },
                    { content: this.getFilterField('_isUse') }
                ]} />


        </div>)
    }

}

export default GiftCardReport;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import PageTitle from "../../components/PageTitle";
import { Table } from "antd";
import ItemListTable from "./ItemListTable";
import ItemsTable from "./ItemsTable";

import PageToolbar from "../../components/PageToolbar"

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    data: any,
    dataActions: any,
};
type State = {};

class ItemListIndex extends Component<Props, State> {

  
    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            //whiteSpace: "unset",
        };

        return (
            <div style={divStyle}>
                <ItemsTable
                    user={this.props.user}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                    ActionQueue={this.props.ActionQueue}
                />
            </div>
        );
    }
}
export default ItemListIndex;

/*
                <ItemListTable
                    user={this.props.user}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
*/
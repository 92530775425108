import React, { Component } from "react";
import { Icon, message, Tooltip } from 'antd';
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext"
import { Job, User, OutputType, ProcessType } from '../JmReact';

class AddUserFavoritesButton extends Component<Props, State> {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    addUserFavorites = () => {
        let _url = window.location.href.replace(window.location.origin, '');
        let _title = document.title.replace('Cash On Tab - ', '');
        this.sendAPI("save_user_favorites", "_url\f_title\r" + _url + "\f" + _title, () => {
            message.success(this.getText(16408))
        })
    }

    render() {
        let mobile = window.innerWidth < 600;
        return mobile ?
            (<div style={{ display: "inline-block", width: 100, textAlign: "center", verticalAlign: "text-top" }}>
                <div style={{ height: 60, marginTop: -20 }}>
                    <Icon
                        type={'user-add'}
                        style={{ marginLeft: '40px', color: '#fff', fontSize: 20 }}
                        onClick={this.addUserFavorites}
                    />
                </div>
                {/* <div style={{  marginTop: -40, color: "white", fontSize: 10, width: 100, lineBreak: "normal" }}>
                    {this.getText(16407)}
                </div> */}
            </div>) :
            (<Tooltip title={this.getText(16407)}>
                <Icon
                    type={'user-add'}
                    style={{ marginLeft: '40px', color: '#fff' }}
                    onClick={this.addUserFavorites}
                />
            </Tooltip>)
    }

}

export default AddUserFavoritesButton;
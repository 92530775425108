
import React from "react";
import { Input, Modal, Form, Button, message, Icon, Tooltip } from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext"

const FormItem = Form.Item;
const { TextArea } = Input;

//type State = {}
class ModalServiceCall extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            userName: '',
            userPhone: '',
            userMessage: '',
        }

        this.id = "MODAL_SERVICE_CALL";

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.actionButtons = []

    }

    static contextType = LangContext;

    componentDidUpdate(prevProps) {

        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {

            this.setState({ userName: this.props.user.displayName });
        }
    }

    handleOk = () => {
        this.setState({loading: true}, ()=>{

            let canvasElement = (document.getElementById('screenshot')) ?
            document.getElementById('screenshot').getElementsByTagName('canvas') : false;
            let imgData = canvasElement ? canvasElement['0'].toDataURL() : false;
            
            let request = {
                mWorkerName: this.state.userName,
                mPhoneNumber: this.state.userPhone,
                mDescription: this.state.userMessage,
                mImageBase64: imgData,
            }
            
            let dataSend = Object.keys(request).join('\f') + '\r' + Object.values(request).join('\f');
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, 'open_service_call', OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
        })
    }

    successCallback = (ob: Object) => {
        message.success(this.getText(10024));
        this.handleCancel();
    }

    errorCallback = (error: any) => {
        message.error(this.getText(10025), 2);
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            loading: false,
            userName: '',
            userPhone: '',
            userMessage: '',
        });
        this.props.uiActions.hideModal(TableIDs.modalServiceCall);
    }

    handleClear = () => {
        this.setState({
            userName: '',
            userPhone: '',
            userMessage: '',
        });
    }

    handleValidation = () => {
        if (this.state.userName &&
            this.regExpPhone(this.state.userPhone) &&
            this.state.userMessage.length > 2 && this.state.userMessage.length < 256)
            return true;
    }

    regExpPhone = (phone) => {
        if (RegExp(/^0[2-9]([\d]{0,1})\d{7}$/).test(phone)) return true;
    }

    // getText = (id) => {
    //     // let LangText = this.props.LangText;
    //     // return (<LangText>{v=> v.get(id)}</LangText>)

    // }

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        const { loading } = this.state;
        //const LangText = this.props.LangText;

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={<span>{this.getText(10017)}{this.props.user.companyName}</span>}
                    actionButtons={this.actionButtons}
                    genericActionButtons={[
                        (<Tooltip title={this.getText(12914)}>
                            <Button key={"souportOnline"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                                onClick={() => { window.open("https://www.autonline.co.il:8040/", "blank") }}
                            ><Icon style={{ fontSize: 16 }} type={"chrome"} theme="outlined" /></Button>
                        </Tooltip>)
                    ]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'80%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                //onOk={this.handleOk}
                onCancel={this.handleCancel}
                //footer={false}
                footer={
                    [
                        <Button key="back" onClick={this.handleCancel}>{this.getText(10021)}</Button>,
                        <Button key="clear" onClick={this.handleClear}>{this.getText(10022)}</Button>,
                        <Button key="send" disabled={!this.handleValidation()} type="primary" loading={loading} onClick={this.handleOk}>{this.getText(10023)}</Button>,
                    ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <Form style={{ columnWidth: 'auto', columnCount: '2' }}>
                    <FormItem label={this.getText(10018)} style={{ width: '50%' }}>
                        <Input
                            value={this.state.userName}
                            onChange={(value) => {
                                this.setState({ userName: value.target.value });
                            }}
                            onBlur={(value) => {
                                if (!value.target.value) message.error(this.getText(10026));
                            }}
                        />
                    </FormItem>
                    <FormItem label={this.getText(10019)} style={{ width: '50%' }}>
                        <Input
                            value={this.state.userPhone}
                            onChange={(value) => {
                                let lastCharIndex = value.target.value.length - 1;
                                let lastChar = value.target.value[lastCharIndex];
                                if (parseInt(lastChar) || lastChar === '0') {
                                    this.setState({ userPhone: value.target.value })
                                } else {
                                    message.error(this.getText(10027));
                                    let previousString = value.target.value.slice(0, lastCharIndex)
                                    this.setState({ userPhone: previousString });
                                }
                            }}
                            onBlur={(value) => {
                                if (!this.regExpPhone(value.target.value)) message.error(this.getText(10027));
                            }}
                        />
                    </FormItem>
                    <FormItem label={this.getText(10020)} >
                        <TextArea
                            value={this.state.userMessage}
                            onChange={(value) => {
                                this.setState({ userMessage: value.target.value });
                            }}
                            onBlur={(value) => {
                                if (value.target.value.length < 3 || value.target.value.length > 255) message.error(this.getText(10028));
                            }}
                        />
                    </FormItem>
                    <div id={'screenshot'} style={{ height: '300px', width: '500px', backgroundColor: '#777', padding: '3px' }}></div>
                </Form>
            </Modal >
        )
    }
}

export default ModalServiceCall;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import CustomerTable from "./CustomerTable";

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    data: any,
    dataActions: any,
    ActionQueue: Object
};
type State = {};

class CustomerIndex extends Component<Props, State> {

//   componentDidMount() {
//     document.title = "Cash On Tab - רשימת לקוחות";
//   }

  render() {
    var divStyle = {
        align: 'center',
        dir: 'rtl', // dir: 'ltr'
        padding: '2% 2% 2% 2%',
        //whiteSpace: 'unset',
    }
    return (
        <div style={divStyle}>
            <CustomerTable
                user={this.props.user}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                data={this.props.data}
                dataActions={this.props.dataActions}
                ActionQueue={this.props.ActionQueue}
            />
        </div>
    );
}
}
export default CustomerIndex;

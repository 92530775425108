import React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Select, Button } from 'antd';
import ExtendedSelector from "./ExtendedSelector";
import ExtendedSelector2 from "./ExtendedSelector2";


const { Option, OptGroup } = Select;

class SelectorSalesAlerts extends React.Component/*<Props, State>*/ {
    constructor(props) {
        super(props)
    }

    render() {
        let attr = {
            styleSelector: this.props.styleSelector,
            user: this.props.user,
            disabled: this.props.disabled,
            onSelect: this.props.onSelect,
            value: this.props.value,
            filter: this.props.filter,
            
            ui: this.props.ui,
            uiActions: this.props.uiActions,      
            data: this.props.data, 

            showButton: true,
            showTitle: true,
            title: "התראת מכירה",
            placeholder: "בחר התראת מכירה",
            api: "get_sale_alert_list",
            onButtonClick: () => {
                this.props.uiActions.showModal("SALES_ALERT_EDIT_MODAL");
            },
        }

        return (
            <ExtendedSelector {...attr} />
        )
    }
};


export default SelectorSalesAlerts;

/* @flow */
/*jshint esversion: 6 */
import React from "react";
import ReactDOM from "react-dom";
import { Menu, Icon, Badge } from "antd";
import { withRouter } from "react-router-dom";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext"

const SubMenu = Menu.SubMenu;

type History = {
  push: Function,
  location: {
    pathname: string
  }
};

type Props = {
  history: History,
  baseUrl: string
};

type State = {
  menuTag: any,
  menuItemsTop: Array<Object>,
  menuItemsDrop: Array<Object>
};

class BestClubMenu extends React.Component<Props, State> {
  myHistory: History;
  baseUrl: string;

  constructor(props: Props) {
    super(props);
    this.myHistory = this.props.history;
    this.baseUrl = this.props.baseUrl;

    if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
      props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
    }

    this.state = {
      menuTag: (<div></div>),
      menuItemsTop: [
        { KeyComp: "231", key: "monthly-join-rate", text: "קצב מצטרפים חודשי", keyLang: 17962, icon: "team" },
        { KeyComp: "232", key: "monthly-use-benefits", text: "מימוש הטבות חודשי", keyLang: 17970, icon: "like" },
        { KeyComp: "233", key: "monthly-use-points", text: "צבירה / מימוש נקודות", keyLang: 17979, icon: "dot-chart" },
        { KeyComp: "234", key: "use-bargains", text: "מימוש מבצעים", keyLang: 17984, icon: "gift" },
        { KeyComp: "235", key: "purchases-and-visits", text: "דו\"ח רכישות וביקורים", keyLang: 17989, icon: "home" },
        { KeyComp: "236", key: "club-not-active", text: "דו\"ח חברים לא פעילים", keyLang: 18068, icon: "usergroup-delete" },
        { KeyComp: "237", key: "visits-by-days", text: "דו\"ח ביקורים לפי ימים", keyLang: 18075, icon: "schedule" },
        { KeyComp: "238", key: "top-items", text: "פריטים הכי נמכרים", keyLang: 18080, icon: "shopping-cart" },
        { KeyComp: "239", key: "customers-by-age", text: "התפלגות לקוחות לפי גיל", keyLang: 18086, icon: "apartment" },
        { KeyComp: "240", key: "removals", text: "דוח הסרות", keyLang: 18089, icon: "rest" },
        { KeyComp: "241", key: "cust-branches", text: "לקוחות לפי סניפים", keyLang: 18338, icon: "hdd" },
      ].filter((menuItem, index) => this.isActive(menuItem.KeyComp)),

      menuItemsDrop: []
    };
  
  }

  static contextType = LangContext;

  getText = (id) => {
    return this.context.get(id) || '[' + id + ']'
  }

  isActive = (key) => {
    return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
      .map(obj => obj.code).includes(key);
  };

  onMenuSelect(args) {
    this.myHistory.push(
      this.baseUrl + "/best-club/" + (args.key !== "home" ? args.key : ""),
      {}
    );
  }

  updatePriorityMenu = () => {
    let maxHeight = 50; //px
    let _height = -1;
    let firstIter = true;

    let listTop = this.state.menuItemsTop;
    let listDrop = this.state.menuItemsDrop;
    // re-populate menu
    for (let i = 0; i < listDrop.length; i++) {
      listTop.push(listDrop.pop());
    }

    //ReactDOM.findDOMNode(this).clientHeight;
    while (_height > maxHeight || _height < 0) {
      //this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
      if (!firstIter) {
        this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
      }
      else {
        firstIter = false;
      }

      this.setState((prevState, props) => {
        return {
          menuTag: <Menu
            mode="horizontal"
            defaultSelectedKeys={[
              this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[2] || "home"
            ]}
            onSelect={this.onMenuSelect.bind(this)}
          >
            {this.renderMenuItemsTop()}
            <SubMenu title={<Badge count={listDrop.length}><span><Icon type="ellipsis" /></span></Badge>}>
              {this.renderMenuItemsDrop()}
            </SubMenu>
          </Menu>
        }
      });
      let e: Element | null | Text = ReactDOM.findDOMNode(this);
      _height = (e instanceof HTMLElement) ? e.clientHeight : 0;
      //alert(_height);
    }
  }

  renderMenuItemsTop() {
    let res = [];
    let list = this.state.menuItemsTop;
    if (list.length > 0) {
      for (let item of list) {
        res.push(
          <Menu.Item key={item.key}>
            <Icon type={item.icon} className="menu-icon" />
            <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
          </Menu.Item>);
      }
      return res;
    }
  }

  renderMenuItemsDrop() {
    let res = [];
    let list = this.state.menuItemsDrop;
    if (list.length > 0) {
      for (let item of list) {
        res.push(
          <Menu.Item key={item.key}>
            <Icon type={item.icon} className="menu-icon" />
            <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
          </Menu.Item>);
      }
    }
    return res;
  }

  doUpdateOfPriorityMenu = () => {
    setTimeout(this.updatePriorityMenu);
  };

  // Add event listener
  componentDidMount() {
    setTimeout(this.updatePriorityMenu);
    //window.addEventListener("resize", this.updatePriorityMenu);
    window.addEventListener("resize", this.doUpdateOfPriorityMenu);
  }

  // Remove event listener
  componentWillUnmount() {
    //window.removeEventListener("resize", this.updatePriorityMenu);
    window.removeEventListener("resize", this.doUpdateOfPriorityMenu);
  }

  render() {
    return this.state.menuTag;
  }
}

export default withRouter(BestClubMenu);


import React from "react";
import { InputNumber, Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
import SelectorItems from "./SelectorItems";
//import ItemPicker from "./ItemPicker";
import { fixCurrentPage, escapeRegExp } from "../utils/utils";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";


const { Option, OptGroup } = Select;
const { TextArea } = Input;
// const codeInvalid = () => {
//     message.error('קוד הפריט כבר קיים במסד הנתונים');
// };
// const codeValid = () => {
//     message.success('קוד פריט פנוי');
// };
// const codeExists = () => {
//     message.warning('קוד פריט כבר קיים במסד הנתונים אך בסטטוס לא פעיל');
// };

//type State = {}
class ModalCreatePrice extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = "CREATE_PRICE";
        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            newItemCode: "",
            newItemName: "",
        };
        this.state.itemPick = null;

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        //if (props.data[TableIDs.genericSelectorTaxRegion] === undefined) {
        //    props.data[TableIDs.genericSelectorTaxRegion] = { ...props.data.genericSelector };
        //}

        this.api = {
            get: "get_price_by_item_full",
            edit: "edit_price_by_item", // and create
            delete: "delete_price_by_item",//"delete_priceList",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        },/* {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        document.title = "Cash On Tab - ";
        //this.initFilters();
        //    this.props.dataActions.genericSelectorRefreshDataset(
        //        TableIDs.genericSelectorTaxRegion, this.props.user.companyCode,
        //        this.props.user.token, this.api.selector);
        //this.refreshData();
    }

    initFilters = () => {
        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: 5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: 5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 3;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 1
            case "mId": col--;
            case "mName": col--;
            case "mPriceVal": break; // 4
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    /*
    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }*/

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePrice);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        this.setState({ visible: false, itemPick: null });
        this.onChangeItemCode("", "")
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePrice);
    }
    handleClear = () => {
        //this.clear();
    }

    checkIfItemExist = (s) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let itemsRows = (ob.data).split("\r");
                    let _ItemData = itemsRows[0].split('\f');
                    let _ItemCode = _ItemData[1];
                    let _ItemName = _ItemData[2];

                    if (!_ItemCode || itemsRows.length > 1) {
                        this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data });
                    } else {
                        this.onChangeItemCode(_ItemCode, _ItemName)
                    }
                },
                (error) => {
                    message.error(this.getText(10391));
                }
            );
        } else {
            this.onChangeItemCode("", "")
        }
    }

    keyboardListener(e) {
        let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                } else {
                    bufferUpdate(e.key)
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                this.checkIfItemExist(this.barcodeReaderBuffer)
                break;

            default:
                bufferUpdate(e.key)
                break;
        }
    }


    onChangeItemCode = (code, name) => {
        let request = {
            mItem: code,
            _page_num: 1,
            _rows_num: 5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        }
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            this.setState({ newItemCode: code, newItemName: name }, () => {
                this.props.dataActions.genericDataSetFilter(
                    this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
                //  this.props.dataActions.genericDataSetEdit(this.items_id, { iCode: code, iName: name, iDocId: this.props.ui.data["fixId"] });
            });
        }
    }

    render() {
        const { loading } = this.state;
        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: 5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: 5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, 
                                _words: escapeRegExp(val),
                                //   _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        let columns = [
            {
                title: this.getText(10384),
                dataIndex: "mId",
                key: "mId",
                sorter: true,
                width: '10%',
                render: (text, record) => text,
            }, {
                title: this.getText(10385),
                dataIndex: "mName",
                key: "mName",
                width: '20%',
                sorter: true,
                render: (text, record) => text,
                /*
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { mName: value.target.value });
                                }} />);
                    } else {
                        return text;
                    }
                },*/
            }, {
                title: this.getText(10386),
                dataIndex: "mPriceVal",
                key: "mPriceVal",
                width: '20%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <InputNumber min={0} step={0.01}
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value: number) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mPriceVal: value });
                                }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title: this.getText(10387),
                key: "Settings",
                width: '8%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mItem: this.props.data[this.id].filters.mItem });
                                }} />
                                <Popconfirm title={this.getText(10388)}
                                    onConfirm={() => {
                                        fixCurrentPage.bind(this)();
                                        this.props.dataActions.genericDataDeleteRowWithParams(
                                            this.id,
                                            { mId: record['mId'], mItem: this.props.data[this.id].filters.mItem },
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.get);
                                    }
                                    }
                                    okText={this.getText(10389)} cancelText={this.getText(10390)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm>
                            </span >);
                    } else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get);
                                    else
                                        this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>);
                    }
                },
            }
        ];


        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mName: y[1],
                mPriceVal: y[2],
                //mRegionId: y[2],
                //mIsIncludeVat: y[3],
                index: index
            })
        });

        // let list = this.props.data[TableIDs.genericSelectorPrices].dataset.map((obj, index) => {
        //     return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
        // });

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true} width={'90%'} visible={false || this.props.toggle} title={titleBar}
                // title={this.props.title} // pass title in props for generics
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                {/* <ItemPicker
                    //defaultItemCode={this.props.data[this.id].editedRecord.mItem}
                    defaultItemCode={this.state.itemPick}
                    onChange={(value) => {
                        this.setState(...this.state, { itemPick: value.itemName });
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            { ...request, mItem: value.itemCode, mName: value.itemName }, this.api.get);
                        //this.props.dataActions.genericDataSetEdit(this.id, { mItem: value.itemCode, mName: value.itemName, xxx: 'xxx' });            
                    }}
                    //label={this.props.data[this.id].editedRecord.mItem}
                    label={this.state.itemPick}
                    data={this.props.data.itemCodes}
                    showDialog={this.props.uiActions.uiShowItemPickerDialog}
                    hideDialog={this.props.uiActions.uiHideItemPickerDialog} /> */}
                <SelectorItems
                    doFocus={true}
                    user={this.props.user}
                    uiActions={this.props.uiActions}
                    ui={this.props.ui}
                    dataActions={this.props.dataActions}
                    data={this.props.data}
                    ActionQueue={this.props.ActionQueue}
                    ctrlBKeyFlag={null}
                    isExpandMatrixFatherItem={false}
                    itemType={1}
                    value={this.state.newItemCode}
                    onChangeSearch={(s) => { this.onChangeItemCode(s.mCode, s.mName) }}
                    onChange={(s) => { this.setState({ newItemCode: s.target.value }) }}
                    onBlur={(s) => { this.checkIfItemExist(this.state.newItemCode) }}
                    onKeyDown={this.keyboardListener.bind(this)}
                />
                <span style={{}}>{this.state.newItemCode !== "" ? this.state.newItemName : this.getText(10392)}</span>
                <ResponsiveTable ui={this.props.ui} tableOnly={true} dataSource={dataSource}
                    columns={columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ emptyText: this.getText(10393) }}
                />


            </Modal >
        )
    }
}

export default ModalCreatePrice;

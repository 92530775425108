/* @flow */
/*jshint esversion: 6 */

import React from "react";
import PageToolbar from "../../components/PageToolbar";
import PictureView from "../../components/PictureView";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import { message } from "antd";

class LogoSettings extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            logo_url: ''
        }

        // this.id = "LOGO_SETTINGS";

        this.api = {
            get: "get_logo_company_details",
            // delete: "delete_logo_company_details",
            update: "update_logo_picture"
        }

        // if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10209);
    }

    refreshData = () => {
        this.sendAPI(this.api.get, "", (ob) => {
            let logo_url = ob.data ? ob.data.split("/").slice(-1)[0] : ''
            this.setState({ logo_url })
        }, e => { console.error(e) });
        // this.props.dataActions.genericDataGetRequested(
        //     this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    // imagesData = () => {
    //     let data = this.props.data[this.id];
    //     if (data && data.data[0]) return data.data[0].split("\f")[0]
    // }

    titleBar = (title) => {

        let actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

        return (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={title}
                    actionButtons={actionButtons}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);
    }

    pictureView = (url, type) => {
        return (<div style={{ display: 'inline-block' }}>
            <PictureView
                // aspect={1}
                user={this.props.user}
                data={this.props.data}
                dataActions={this.props.dataActions}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                ActionQueue={this.props.ActionQueue}
                url={url}
                imageType={type}
                onChange={(nURL) => {
                    //console.log('yuda url ', nURL);
                    // this.props.dataActions.dataTableSetRow(this.id, 0, { iUrl: nURL, iType: 'L' }, true);
                    this.setState({ logo_url: nURL })
                }}
                onRemove={(e) => {
                    this.sendAPI(this.api.update, null, this.refreshData, error => { console.error(error) })
                    // this.props.dataActions.genericDataDeleteRow(
                    //     this.id, url, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}
                onUploadedSuccess={(response, url) => {
                    this.sendAPI(this.api.update, url, () => {
                        message.success(this.getText(11763));
                        this.refreshData()
                    }, (e) => { console.error(e) });
                }}
                onUploadedFailed={(error) => {
                    this.setState({ logo_url: '' })
                    //  //console.log('yuda upload failed',error)
                    // this.props.dataActions.dataTableSetRow(this.id, 0, { iUrl: 'x', iType: 'L' }, true);
                }} />
        </div>)
    }

    sendAPI = (script, input, onSuccess, onError) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(input);
        job.send("/cgi-bin/CashOnTab", onSuccess, onError);
    }

    render() {
        let iData = this.state.logo_url//   this.imagesData();

        return (
            <div style={{ width: window.innerWidth > 600 ? 500 : '95%', margin: 'auto', }}>
                {this.titleBar(this.getText(11764))}
                <h2 style={{ margin: 10 }}>{this.getText(11765)}</h2> {this.pictureView(iData, 'L')}
            </div>
        )
    }

}

export default LogoSettings;


/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Icon, Input } from 'antd';
import "../../App.css";
import "../../virtualShop.css";
import searchicon from "../../skins/index/searchicon.png"
import { escapeRegExp } from "../../utils/utils";

type Props = {
    btnText: String,
    placeholder: String,
    onSearch: Function,
    onChange: Function,
}

class SearchBox extends Component<Props, State> {

    timeSearch = null;

    state = {
        value: ""
    }

    onChange = (e) => {
        const value = e && e.target.value ? e.target.value : "";
        this.setState({ value }, () => {

            this.props.onChange(escapeRegExp(value));

            if (this.props.searchTime) {
                clearTimeout(this.timeSearch);
                this.timeSearch = setTimeout(() => {
                    this.props.onSearch()
                }, this.props.searchTime);
            }
        })
    }


    render() {
        const { btnText, placeholder, onSearch } = this.props;

        if (this.props.isStyle4 || this.props.isStyle5|| this.props.isStyle6) {
            return (<Input
                {...this.props}
                // className={'search_field'}
                style={{ fontFamily: "FbTipograf" }}
                className={"input_suffix_close"}
                placeholder={placeholder}
                prefix={<Icon type="search" />}
                suffix={<Icon type="close-circle" style={{ cursor: "pointer" }} onClick={() => { this.onChange("") }} />}
                onPressEnter={onSearch}
                onChange={this.onChange}
                value={this.state.value}
            />)
        } else if (this.props.isStyle3) {
            let sStyle = { display: "inline-block", verticalAlign: "text-top" }
            let divSearchBtnStyle = { background: "orange", borderRadius: 5, cursor: "pointer", color: "white", padding: 3, textAlign: "center", height: 25 }
            return (<div style={{ fontFamily: "FbTipograf", padding: 3, height: 46 }}>
                <div style={{ ...sStyle, width: "30%" }}>
                    <div style={divSearchBtnStyle} onClick={onSearch}>
                        {btnText}
                        <img src={searchicon} style={{ width: 15, marginRight: 10 }} />
                    </div>
                </div>
                <div style={{ ...sStyle, width: "66%", paddingRight: 10 }}>
                    <Input
                        {...this.props}
                        placeholder={""}
                        style={{ height: 25 }}
                        onPressEnter={onSearch}
                        onChange={this.onChange}
                    />
                </div>
            </div>)
        } else {
            let inputStyle = { maxWidth: window.innerWidth - 130 }
            if (this.props.style) inputStyle = { ...inputStyle, ...this.props.style }
            // let mobile = window.innerWidth <= 700
            return (<span>
                <div className="search_button" >
                    <div className="search_but_orange" onClick={onSearch}>{btnText}</div>
                </div>
                <div className="search_slide">
                    <Input
                        {...this.props}
                        className={'search_field'}
                        style={inputStyle}
                        placeholder={placeholder}
                        onPressEnter={onSearch}
                        onChange={this.onChange}
                    />
                </div>
            </span>)
        }
    }
}
export default SearchBox;

import React from "react";
import { DatePicker, notification, Form, Input, Button, Modal, Select, message, Upload, Checkbox, InputNumber, Switch } from 'antd';

import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import Jax from "../Jax/jax.es6.module";
import LangContext from "../contextProvider/LangContext";
import ReportTypeButton from "./ReportTypeButton";
import moment from "moment";
import GenericSelectorCustTags from "./GenericSelectorCustTags";
import DateTimePicker from "./DateTimePicker";
import CustPicker from "./CustPicker";
import GenericSelector from "./GenericSelector";
import GenericSelectorCustomers from "./GenericSelectorCustomers";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

//type State = {}
class ModalCustomersDebtReport extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            loadingImg: false,
        }

        this.id = 'MODAL_CUSTOMERS_DEBT_REPORT';

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            CustomersDebt: {
                customerCredit: '0', // 0-all, 1-חובה, x2-זכות
                sortField: '1', // 0-customer, 1-credit
                sortDirection: '0', // 0-desc, 1-asc  
                isWithPurchase: '1',
                mStorageList: '',
                mTagList: '',
                mCustList: '',
                mDateStart: null,
                mDateEnd: null,
                mConnectCust: '0',
                reportName: "reports/CustomersDebtShortened.pdf",
            }
        };


        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorStandingOrderStatus] === undefined) {
            props.data[TableIDs.genericSelectorStandingOrderStatus] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        }

        this.api = {
            selector: "get_standing_order_status_list",
            selector_storages: "get_storage_list",
            selector_tags: "get_customer_tags_list",
            //getCount: "get_customer_with_creditcards_count",
        }

        this.actionButtons = [];
        this.dataSource = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()
    }

    getFullSelector = (params) => {
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.mStorageList === '') params = { ...params, mStorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (storagesList && storagesList.dataset[0] && !this.state.loading) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("CustomersDebt", this.props.ui.favoriteParams)
        }
    }


    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("CustomersDebt", {
                            [field]: data,
                            StatusTextList: encodeURIComponent(Jax.get(this.props.data, TableIDs.genericSelectorStandingOrderStatus + ".dataset", [])
                                .filter((obj, index) => data.split(',').includes(obj['code']))
                                .map((obj, index) => obj['name'])
                                .join(','))
                        });
                    else
                        this.setReportParameters("CustomersDebt", { [field]: '', StatusTextList: '' });
                }} />);
    }

    handleOkLeave = () => { this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch); }
    handleOk = () => { }
    handleCancel = () => { this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch); }
    handleClear = () => { }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStandingOrderStatus, this.props.user.companyCode, this.props.user.token, this.api.selector);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorCustomerTags, this.props.user.companyCode,
            this.props.user.token, this.api.selector_tags);
    }

    disabledStartDate = (FromDate) => {
        const ToDate = this.state.CustomersDebt.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.CustomersDebt.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("CustomersDebt", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => { this.onChange('mDateStart', value ? value.format("YYYY-MM-DD") : null); }
    onEndChange = (value) => { this.onChange('mDateEnd', value ? value.format("YYYY-MM-DD") : null); }

    // onChangeAction = (field, value) => {
    //     this.setReportParameters("CustomersDebt", { [field]: value })
    //     this.setState({
    //         [field]: value,
    //     });
    // }

    // onStartChangeAction = (value) => { this.onChangeAction('mDateStart', value ? value.format("YYYY-MM-DD") : null); }
    // onEndChangeAction = (value) => { this.onChangeAction('mDateEnd', value ? value.format("YYYY-MM-DD") : null); }

    render() {
        const { loading, loadingImg } = this.state;
        const {
            customerCredit,
            sortField,
            sortDirection,
            mConnectCust,
            mStorageList,
            mTagList,
            mCustList,
            mDateStart,
            mDateEnd,
            reportName,
        } = this.state.CustomersDebt;

        //const sqlFormat = "YYYY-MM-DD";
        //const timeFormat = "HH:mm:ss";
        //const dateFormat = "DD/MM/YYYY";

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let tagList = this.props.data[TableIDs.genericMultiSelectorCustomerTags].dataset.map((node) => {
            return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                {node.tags.map((item) => (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>))}
            </OptGroup>)
        })

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'50%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                <Form id="report_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '2' : '1' }}
                    onSubmit={this.handleSaveChanges}
                >
                    <FormItem label={this.getText(10743)}>
                        <Select
                            defaultValue={0}
                            style={{ width: '70%' }}
                            onChange={(value) => this.setReportParameters("CustomersDebt", { customerCredit: value })}
                            value={customerCredit}
                        >
                            <Option value={'0'}>{this.getText(10744)}</Option>
                            <Option value={'1'}>{this.getText(10745)}</Option>
                            <Option value={'2'}>{this.getText(10746)}</Option>
                            <Option value={'3'}>{this.getText(17029)}</Option>
                        </Select>
                    </FormItem>

                    <FormItem label={this.getText(17030)}>
                        <Select
                            defaultValue={0}
                            style={{ width: '70%' }}
                            onChange={(value) => {
                                let obj = value == '2' ? { mConnectCust: '2', sortDirection: '1', sortField: '0' } : { mConnectCust: value }
                                this.setReportParameters("CustomersDebt", obj)
                            }}
                            value={mConnectCust}
                        >
                            <Option value={'0'}>{this.getText(17031)}</Option>
                            <Option value={'1'}>{this.getText(17032)}</Option>
                            <Option value={'2'}>{this.getText(17033)}</Option>
                        </Select>
                    </FormItem>

                    <FormItem label={this.getText(17034)}>
                        <Select
                            defaultValue={0}
                            style={{ width: '70%' }}
                            onChange={(value) => this.setReportParameters("CustomersDebt", { reportName: value })}
                            value={reportName}
                        >
                            <Option value={'reports/CustomersDebtShortened.pdf'}>{this.getText(17035)}</Option>
                            <Option value={'reports/CustomersDebtExpanded.pdf'}>{this.getText(17036)}</Option>
                        </Select>
                    </FormItem>

                    <FormItem label={this.getText(10761)} /*label={"מתאריך חיוב:"}*/>
                        {/* <DatePicker
                            disabledDate={this.disabledStartDate}
                            // showTime
                            value={mDateStart ? moment(mDateStart, "YYYY-MM-DD") : null}
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            placeholder={this.getText(10765)}
                            onChange={this.onStartChange}
                        /> */}
                        <DateTimePicker
                            maxDate={mDateEnd}
                            value={mDateStart}
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            placeholder={this.getText(10765)}
                            onChange={(value) => { this.onChange('mDateStart', value) }}
                        />

                    </FormItem>
                    <FormItem label={this.getText(10762)} /*label={"עד תאריך חיוב:"} style={isEndDateShownStyle}*/>
                        {/* <DatePicker
                            disabledDate={this.disabledEndDate}
                            // showTime
                            value={mDateEnd ? moment(mDateEnd, "YYYY-MM-DD") : null}
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            placeholder={this.getText(10766)}
                            onChange={this.onEndChange}
                        /> */}
                        <DateTimePicker
                            minDate={mDateStart}
                            value={mDateEnd}
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            placeholder={this.getText(10766)}
                            onChange={(value) => { this.onChange('mDateEnd', value) }}
                        />
                    </FormItem>


                    <FormItem label={this.getText(12007)}>
                        {this.createCheckBox('mStorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                        <Select key={"mStorageList"} style={{ width: '90%' }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4}
                            onChange={(value) => this.setReportParameters("CustomersDebt", { mStorageList: value.join(',') })}
                            value={(mStorageList === '') ? [] : mStorageList.split(',')}
                        >
                            {listStorage}
                        </Select>
                    </FormItem>

                    <FormItem label={this.getText(14019)}>
                        {/* {this.createCheckBox('mTagList',
                            this.props.data[TableIDs.genericMultiSelectorCustomerTags].dataset.map((node) => {
                                return node.tags.map((item) => item.id).join(',')
                            }).join(','))}
                        <Select key={"mTagList"} style={{ width: '90%' }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4}
                            onChange={(value) => this.setReportParameters("CustomersDebt", { mTagList: value.join(',') })}
                            value={(mTagList === '') ? [] : mTagList.split(',')}
                        >
                            {tagList}
                        </Select> */}
                        <GenericSelectorCustTags
                            {...this.props} notApi multi
                            onChange={(value) => this.setReportParameters("CustomersDebt", { mTagList: value.join(',') })}
                            value={(mTagList === '') ? [] : mTagList.split(',')}
                        />
                    </FormItem>
                    <FormItem label={this.getText(19097)}>
                        <GenericSelectorCustomers
                            {...this.props}
                            multi
                            onChange={(value) => this.setReportParameters("CustomersDebt", { mCustList: value.join(',') })}
                            value={(mCustList === '') ? [] : mCustList.split(',')}
                        />
                    </FormItem>

                    {mConnectCust != '2' ? [
                        <FormItem label={this.getText(10747)}>
                            <Switch checkedChildren={this.getText(10748)} unCheckedChildren={this.getText(10749)}
                                onChange={(value) => this.setReportParameters("CustomersDebt", { sortField: (value === true) ? 1 : 0 })}
                                checked={(sortField == 1) ? true : false}
                            />
                        </FormItem>,

                        <FormItem label={this.getText(10750)}>
                            <Switch checkedChildren={this.getText(10751)} unCheckedChildren={this.getText(10752)}
                                onChange={(value) => this.setReportParameters("CustomersDebt", { sortDirection: (value === true) ? 1 : 0 })}
                                checked={(sortDirection == 1) ? true : false}
                            />
                        </FormItem>
                    ] : ""}

                    {/* <FormItem label={this.getText(10753)}>
                        <Switch checkedChildren={this.getText(10754)} unCheckedChildren={this.getText(10755)}
                            onChange={(value) => this.setReportParameters("CustomersDebt", { isWithPurchase: (value === true) ? 1 : 0 })}
                            checked={(isWithPurchase == 1) ? true : false}
                        />
                    </FormItem> */}

                    <ReportTypeButton
                        {...this.props}
                        params={this.state.CustomersDebt}
                        storagesField={'mStorageList'}
                        datesToFavorites={[
                            { field: 'mDateStart', label: this.getText(10765) },
                            { field: 'mDateEnd', label: this.getText(10766) },
                        ]}
                        favoriteSave
                        favoriteDesc={this.props.title}
                    />


                    {/* <FormItem key={"reportName"} label={this.getText(10756)}>
                        <Select style={{ width: 250 }}
                            className={"reports/CustomersDebt.pdf"}
                            defaultValue={"pdf"}
                            onChange={(value) => {
                                this.setReportParameters("CustomersDebt", {
                                    reportName: "reports/CustomersDebt." + value,
                                    type: value
                                });
                            }}
                        >
                            <Option key={"pdf"}>{this.getText(10757)}</Option>
                            <Option key={"xls"}>{this.getText(10758)}</Option>
                            <Option key={"docx"}>{this.getText(10759)}</Option>
                        </Select>
                    </FormItem> */}

                </Form>
                {/* <div className="action_footer">
                    <Button type="primary" htmlType="submit" form="report_form" onClick={() => {
                        this.generateReport.apply(this, ["CustomersDebt"])
                    }}>{this.getText(10760)}</Button>
                </div> */}
            </Modal >
        )
    }
}

export default ModalCustomersDebtReport;


import React from "react";
import { Input, Card, Button, Modal, Select, Icon, Tooltip, Popover, Radio, Row, Col, message } from 'antd';
import PageToolbar from "./PageToolbar";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext";
import Jax from "../Jax/jax.es6.module";
import ResponsiveTable from "./ResponsiveTable";
import GenericModal from "./GenericModal";
import GenericSelectorPosStorages from "./GenericSelectorPosStorages";

const { Option } = Select;
const RadioGroup = Radio.Group;

//type State = {}
class ModalPosPayTypeHAccounts extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            height: window.innerHeight,
            visible: false,
            visiblePop: false,
            _posId: "",
            _posIdCopy: "",
            copyAll: 0,
        }

        this.doOnResize = null;

        this.id_t = this.props.id + '_T';
        this.id_c = this.props.id + '_C';

        this.api = {
            get_type: "get_pos_pay_type_h_accounts",
            get_comp: "get_pos_pay_comp_h_accounts",
            create_edit: "create_edit_pos_pay_type_h_accounts",
            copyAll: "copy_pos_pay_type_h_accounts",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorPos, script: "list_pos" },
        ]

        if (props.data[this.id_t] === undefined) props.data[this.id_t] = { ...props.data.genericData };
        if (props.data[this.id_c] === undefined) props.data[this.id_c] = { ...props.data.genericData };
        this.selectors.map((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });

        this.actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        this.refreshSelector();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    initFilters = () => {
        this.props.dataActions.genericDataSetFilter(this.id_t, this.props.user.companyCode, this.props.user.token, { _posId: this.state._posId }, this.api.get_type);
        this.props.dataActions.genericDataSetFilter(this.id_c, this.props.user.companyCode, this.props.user.token, { _posId: this.state._posId }, this.api.get_comp);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(this.id_t, this.props.user.companyCode, this.props.user.token, this.api.get_type);
        this.props.dataActions.genericDataGetRequested(this.id_c, this.props.user.companyCode, this.props.user.token, this.api.get_comp);
        this.refreshSelector();
    }

    refreshSelector = () => {
        this.selectors.map((x) => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.script)
        })
    }

    handleOk = (close) => {
        this.saveData(this.state._posId, () => {
            message.success(this.getText(12963));
            if (close) this.handleCancel();
        })
    }

    handleCancel = () => {
        this.setState({ visible: false, _posId: "" }, this.props.uiActions.hideModal(TableIDs.modalPosPayTypeHAccounts));
    }

    handleClear = () => {
        let changeData = (id) => {
            this.props.data[id].data.map((x, i) => {
                let y = x.split("\f");
                let data = y[0] + "\f" + y[1] + "\f";
                this.props.dataActions.dataTableSetRow(id, i, data);
            })
        }
        changeData(this.id_t);
        changeData(this.id_c);
    }

    handleCopy = () => {
        switch (this.state.copyAll) {
            case 0:
                this.saveData(this.state._posIdCopy, () => {
                    this.setState({ visiblePop: false }, () => {
                        message.success(this.getText(12963));
                    });
                })
                break;
            case 1: case 2:
                this.saveData(this.state._posId, () => {
                    this.sendAPI(this.api.copyAll, "mPosId\fmInBranch\r" + this.state._posId + "\f" + (this.state.copyAll == 1 ? '1' : '0'), () => {
                        this.setState({ visiblePop: false }, () => {
                            message.success(this.getText(12963));
                        });
                    }, () => {
                        message.error(this.getText(12964))
                    })
                })
                break;
        }
    }

    getSelectorPos = (state) => {
        let value = this.state[state];
        let onChange = (v) => { this.setState({ [state]: v }, this.initFilters) }

        // let options = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj) => {
        //     return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + " " + obj['name']}>{obj['code'] + " - " + obj['name']}</Option>)
        // });

        return (<div style={{ paddingBottom: 20 }}>
            {/* <Select style={{ width: 300 }} key={"selectTable"} showSearch filterOption={true} optionFilterProp="valuefilter" onChange={onChange} value={value}
            >{options}</Select> */}
            <GenericSelectorPosStorages {...this.props} {...{value,onChange}}  />
        </div>)
    }

    saveData = (pos, next) => {
        let data = "mPosId\fmTypeId\fmCompId\fmAccount\fmDetails";
        this.props.data[this.id_t].data.forEach((x) => {
            let y = x.split("\f");
            data += "\r" + pos + "\f" + y[0] + "\f-1\f" + y[2] + "\f" + y[3];
        });
        this.props.data[this.id_c].data.forEach((x) => {
            let y = x.split("\f");
            data += "\r" + pos + "\f3\f" + y[0] + "\f" + y[2] + "\f" + y[3];
        });

        let deleteApi = "mPosId\r" + pos;
        // console.log("yuda data ", data.split("\r"))
        this.sendAPI(this.api.create_edit, [
            { name: "_DELETE", data: deleteApi },
            { name: "_SAVE", data: data },
        ], () => {
            next();
        }, () => {
            message.error(this.getText(12964))
        })
    }

    sendAPI = (script, data, sCal, eCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        if (typeof data === "string") job.setInput(data);
        else data.map((x) => { job.setSection(x.name, x.data) })
        job.send("/cgi-bin/CashOnTab", sCal, eCal);
    }

    changeIfValid = () => {
        return true;
        // let data = [
        //     ...this.props.data[this.id_t].data,
        //     ...this.props.data[this.id_c].data,
        // ].map((x) => {
        //     let y = x.split("\f");
        //     return y[2];
        // });
        // return data[0] ? data.reduce((a, b) => a && b) : false;
    }

    //     copyButton = () => {
    //         let isValid = this.changeIfValid();
    //         let copyAll = this.state.copyAll;
    //         let copyPos = this.state._posIdCopy;

    //         let activeCopy = isValid && (copyAll || copyPos);

    //         let content = (
    //             <RadioGroup onChange={(v) => { this.setState({ copyAll: v.target.value }) }} value={this.state.copyAll}>
    //                 <Radio value={2}>{this.getText(14299)}</Radio><br />
    //                 <Radio value={1}>{this.getText(12967)}</Radio><br />
    //                 <Radio value={0}>{this.getText(12968)}</Radio><br />
    //                 <div style={{ paddingTop: 5, display: this.state.copyAll ? 'none' : 'block' }}>
    //                     {this.getSelectorPos('_posIdCopy')}
    //                 </div>
    //                 <Button style={{ marginTop: 10 }} key="copy" disabled={!activeCopy} type="primary" onClick={this.handleCopy}>
    //                     {this.getText(12969)}
    //                 </Button>
    //             </RadioGroup>)

    //         let button = (
    //             <Button key={"copy-button"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} disabled={!this.state._posId} onClick={()=>{this.setState({visiblePop: true})}}>
    //                 <Icon style={{ fontSize: 16 }} type={"copy"} theme="outlined" />
    //             </Button>)
    // /////////// todoz:
    //         let popover = (
    //             <Tooltip title={this.getText(12965)}>
    //                 <Popover content={content} title={this.getText(12966)} visible={this.state.visiblePop}>{button}</Popover>
    //             </Tooltip >)

    //         return isValid ? popover : button

    //     }


    copyButton = () => {
        return (<Tooltip title={this.getText(12965)}>
            <Button key={"copy-button"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} disabled={!this.state._posId} onClick={() => { this.setState({ visiblePop: true }) }}>
                <Icon style={{ fontSize: 16 }} type={"copy"} theme="outlined" />
            </Button>
        </Tooltip>)
    }


    copyButtonModal = () => {
        let copyAll = this.state.copyAll;
        let copyPos = this.state._posIdCopy;

        let activeCopy = copyAll || copyPos;

        let content = (
            <RadioGroup onChange={(v) => { this.setState({ copyAll: v.target.value }) }} value={this.state.copyAll}>
                <Radio value={2}>{this.getText(14299)}</Radio><br />
                <Radio value={1}>{this.getText(12967)}</Radio><br />
                <Radio value={0}>{this.getText(12968)}</Radio><br />
                <div style={{ paddingTop: 5, display: this.state.copyAll ? 'none' : 'block' }}>
                    {this.getSelectorPos('_posIdCopy')}
                </div>
            </RadioGroup>)

        return (<GenericModal
            title={this.getText(12966)}
            visible={this.state.visiblePop}
            onCancel={() => { this.setState({ visiblePop: false }) }}
            width={300}
            footer={[
                <Button style={{ marginTop: 10 }} key="copy" disabled={!activeCopy} type="primary" onClick={this.handleCopy}>
                    {this.getText(12969)}
                </Button>
            ]}
        >{content}</GenericModal>)
    }


    getInput = (record, field, id) => {
        return (<Input
            disabled={!this.state._posId}
            value={record[field]}
            onChange={(e) => {
                let value = e.target.value;
                let res = record.key + "\f" + record.mName + "\f";
                let valid = 22;
                if (field === 'mAccount') {
                    valid = 15;
                    res += value + '\f' + record.mDetails;
                } else {
                    res += record.mAccount + '\f' + value;
                }
                if (value.length <= valid) this.props.dataActions.dataTableSetRow(id, record.index, res);
                else message.error(this.getText(12932) + " " + valid + " " + this.getText(12933))
            }} />)
    }

    getTable = (id, title) => {
        let numOfRowsByHeight = parseInt((this.state.height - 400 > 30) ? (this.state.height - 450) / 30 : 1);

        return (<Col span={(window.innerWidth > 600) ? 12 : 24} style={{ padding: 5 }}>
            <ResponsiveTable
                idTable={this.props.data[id]}
                title={this.getText(title)}
                actionButtons={[]}
                columns={[
                    { title: this.getText(15463), key: "mName", dataIndex: "mName", width: '30%', render: t => t },
                    { title: this.getText(15464), key: "mAccount", dataIndex: "mAccount", width: '30%', render: (t, r) => this.getInput(r, 'mAccount', id) },
                    { title: this.getText(15465), key: "mDetails", dataIndex: "mDetails", width: '40%', render: (t, r) => this.getInput(r, 'mDetails', id) },
                ]}
                pagination={{ pageSize: numOfRowsByHeight }}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
        </Col>)
    }

    render() {

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.getText(12948)}
                    actionButtons={this.actionButtons}
                    genericActionButtons={[this.copyButton()]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let isValid = this.changeIfValid();

        return (<div>
            <Modal
                destroyOnClose={true} width={'95%'} visible={false || this.props.toggle}
                title={titleBar} onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(12959)}</Button>,
                    <Button key="clear" onClick={this.handleClear}>{this.getText(12960)}</Button>,
                    <Button key="create" disabled={!isValid} type="primary" onClick={() => {
                        this.handleOk(false);
                    }}>{this.getText(12961)}</Button>,
                    <Button key="createAndClose" disabled={!isValid} type="primary" onClick={() => {
                        this.handleOk(true);
                    }}>{this.getText(14303)}</Button>
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction} >

                <span>{this.getText(12962)}: </span> {this.getSelectorPos('_posId')}

                <Row>
                    {this.getTable(this.id_t, 15466)}
                    {this.getTable(this.id_c, 15467)}
                </Row>
                {this.copyButtonModal()}
            </Modal>
        </div>)
    }
}

export default ModalPosPayTypeHAccounts;

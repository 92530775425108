
import React from "react";
import { Input, Card, Button, Modal, Select, Form, Checkbox, Radio, InputNumber, message } from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import GenericSelector from "./GenericSelector";

const { Option } = Select;

//type State = {}
class ModalCreateEditMoveinSettings extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            height: window.innerHeight,
            visible: false,
        }

        this.doOnResize = null;

        this.id = this.props.id;

        this.api = {
            get: "get_movein_defs_row_data",
            create_edit: "create_edit_movein_defs",
            check: "check_if_change_pos_pay_h_accounts",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, script: "get_branch_list" },
            { id: TableIDs.genericSelectorDocumentTypes, script: "get_document_types_list" },
            { id: TableIDs.genericSelectorPos, script: "list_pos" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.selectors.map((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    initFilters = () => {
        let request = { mID: this.props.ui.data ? this.props.ui.data['mID'] : 0 }
        this.props.dataActions.genericDataGetEditWithParams(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        // this.selectors.map((x) => { this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.script) })
    }

    componentDidUpdate(prevProps) {
        let uiData = this.props.ui.data;
        let puiData = prevProps.ui.data;
        if ((uiData && puiData && uiData['mID'] !== puiData['mID']) || (prevProps.toggle === false && this.props.toggle === true)) {
            this.setState({ visible: true }, this.initFilters);
        }
    }

    handleOk = () => {
        const { editedRecord } = this.props.data[this.id];
        if ((editedRecord.mCollect2 == 1 && !editedRecord.mDefSpecCust) || !editedRecord.mInH || !editedRecord.mInP) {
            message.error(this.getText(19542))
        } else {
            this.props.dataActions.genericDataJustSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit);
            this.props.onClose();
            this.handleCancel();
        }
    }

    handleCancel = () => {
        this.setState({ visible: false }, this.props.uiActions.hideModal(TableIDs.modalCreateEditMoveinSettings));
    }

    fieldsUpdate = (value) => {
        let uFields = [
            "mDefCust",
            "mInH",
            "mInP",
            "mOutH",
            "mVATin",
            "mVATout",
            "mVAThrd",
            "mBank",
            "mCash",
            "mCheck",
            "mRfnd",
            "mIsra",
            "mVisa",
            "mDiners",
            "mAmex",
            "mLeumi"
        ];

        let er = this.props.data[this.id].editedRecord;
        let obj = { mAccntLen: value }
        uFields.map((x) => { obj = { ...obj, [x]: er[x].slice(0, parseInt(value)) } });
        this.props.dataActions.genericDataSetEdit(this.id, obj);
    }

    sendAPI = (script, data, sCall) => {
        let outputType = script === "generate_report" ? "OUTPUT_TYPE_REPORT_A4" : "OUTPUT_TYPE_DATA";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType[outputType], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, () => { this.errorCallback(12922) });
    };

    render() {

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.getText(12819)}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let colStyle = {
            padding: 5,
            width: (window.innerWidth > 600) ? '33%' : '100%',
            display: 'inline-block',
            verticalAlign: 'text-top'
        }

        let cardStyle = {
            backgroundColor: '#dddddd',
            marginBottom: 10,
        }

        let fieldStyle = (label, content, isRequired) => {
            return (<div style={{ paddingTop: 5 }}>
                <div style={{ display: 'inline-block', width: '40%' }}>
                    {isRequired ? <span style={{ color: "red" }}> * </span> : ""}
                    {this.getText(label)}
                </div>
                {content}
            </div>)
        }

        let editedRecord = this.props.data[this.id].editedRecord;

        let updateIn = (f, v) => this.props.dataActions.genericDataSetEdit(this.id, { [f]: v });

        let tInput = (l, f, c) => {
            return fieldStyle(l, (<Input style={{ display: 'inline-block', width: '60%' }} value={editedRecord[f]} onChange={(v) => {
                if (v.target.value.length <= parseInt(c)) updateIn(f, v.target.value);
                else message.error(this.getText(12932) + " " + c + " " + this.getText(12933))
            }} />), f == 'mInH' || f == 'mInP' || (f == "mDefSpecCust" && editedRecord.mCollect2 == 1))
        }
        let cbInput = (l, f) => fieldStyle(l, (<Checkbox checked={editedRecord[f] == 1 ? true : false} onChange={(v) => updateIn(f, v.target.checked ? 1 : 0)} />))

        let radioInput = (label, field, options) => {
            let list = options.map((x) => (<Radio value={x.code}>{x.name}</Radio>))
            return fieldStyle(label, (<Radio.Group onChange={(e) => {
                if (field === 'mByPos') {
                    this.props.dataActions.genericDataSetEdit(this.id, { mByPos: e.target.value, mBranches: '', mPosList: '' });
                } else if (field === 'mAccntLen') {
                    this.fieldsUpdate(e.target.value)
                } else {
                    updateIn(field, e.target.value);
                }
            }} value={editedRecord[field]}>{list}</Radio.Group>))
        }

        let selectInput = (label, field, options) => {
            let list = options.map((text, index) => (<Option key={index} value={index + 1}>{this.getText(text)}</Option>));
            let sStyle = { width: '60%', paddingLeft: '4px' }
            return fieldStyle(label, (
                <Select value={editedRecord[field] ? parseInt(editedRecord[field]) : null} style={sStyle} onChange={(e) => { updateIn(field, e) }}>{list}</Select>))
        }

        let multiSelector = (label, field, num, filter) => {
            // let list = this.props.data[this.selectors[num].id].dataset ?
            //     this.props.data[this.selectors[num].id].dataset.map((obj, index) => {
            //         return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            //     }) : [];

            // return fieldStyle(label, (
            //     <Select showSearch filterOption={true} optionFilterProp="valuefilter"
            //         value={editedRecord[field] ? editedRecord[field].split(",") : []} key={field} mode={"multiple"} maxTagCount={4}
            //         style={{ width: '60%', paddingLeft: '4px' }} onChange={(e) => {
            //             if (num === 0 || num === 2) {
            //                 let me = (text) => { message.error(this.getText(text)) }
            //                 let dataSend = "mBranche\fmPos\r" + (num === 0 ? e[e.length - 1] + "\f0" : "0\f" + e[e.length - 1]);
            //                 this.sendAPI(this.api.check, dataSend, (ob) => {
            //                     let d = ob.data ? ob.data.split("\r") : []
            //                     if (parseInt(d[0]) <= 0) me(12974)
            //                     if (parseInt(d[1]) <= 0) me(num === 0 ? 12979 : 12980)
            //                     else updateIn(field, e.join(","));
            //                 }, () => { me(12981) });
            //             } else {
            //                 updateIn(field, e.join(","))
            //             }
            //         }} >{list}</Select>))

            return fieldStyle(label, (<div
                style={{
                    width: '60%',
                    paddingLeft: '4px',
                    display: 'inline-block',
                    verticalAlign: 'text-top'
                }}>
                <GenericSelector
                    id={this.selectors[num].id}
                    api={this.selectors[num].script}
                    multi={true}
                    value={editedRecord[field] ? editedRecord[field].split(",") : []}
                    onChange={(e) => {
                        if (num === 0 || num === 2) {
                            let me = (text) => { message.error(this.getText(text)) }
                            let dataSend = "mBranche\fmPos\r" + (num === 0 ? e[e.length - 1] + "\f0" : "0\f" + e[e.length - 1]);
                            this.sendAPI(this.api.check, dataSend, (ob) => {
                                let d = ob.data ? ob.data.split("\r") : []
                                if (parseInt(d[0]) <= 0) me(12974)
                                if (parseInt(d[1]) <= 0) me(num === 0 ? 12979 : 12980)
                                else updateIn(field, e.join(","));
                            }, () => { me(12981) });
                        } else {
                            updateIn(field, e.join(","))
                        }
                    }}
                    filterList={filter}
                    user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
                />
            </div>))
        }

        return (
            <Modal
                destroyOnClose={true} width={'95%'} visible={false || this.props.toggle}
                title={titleBar} onOk={this.handleOk} onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(12820)}</Button>,
                    <Button key="create" type="primary" onClick={this.handleOk}>{this.getText(12822)}</Button>,
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction} >
                <Form style={{ columnWidth: 'auto', columnCount: '1' }}  >
                    <div style={colStyle}>
                        <Card title={this.getText(12823)} style={cardStyle}>
                            {[
                                tInput(12826, "mDesc", 45),
                                cbInput(12827, "mDefault"),
                                tInput(12828, "mHesh", 3),
                                tInput(12829, "mZikuy", 3),
                                tInput(12830, "mPatur", 3),
                                tInput(12831, "mBuy", 3),
                                tInput(18566, "mRicuzH", 3),
                                tInput(18567, "mRicuzP", 3),
                                radioInput(12970, "mByPos", [{ code: "0", name: this.getText(12971) }, { code: "1", name: this.getText(12972) }]),
                                editedRecord['mByPos'] !== '1' ? multiSelector(12848, "mBranches", 0) : multiSelector(12973, "mPosList", 2),
                                multiSelector(12852, "mDocs", 1, ['1', '2', '3', '5', '16', '17', '20', '21', '22', '23', '42', '43']),
                                selectInput(12863, "mDates", [12879, 12880, 12881, 12882, 12883, 12884]),
                            ]}
                        </Card>
                    </div>
                    <div style={colStyle}>
                        <Card title={this.getText(12824)} style={cardStyle}>
                            {[
                                tInput(12832, "mDefCust", editedRecord["mAccntLen"]),
                                tInput(16066, "mDefSpecCust", editedRecord["mAccntLen"]), // added by oz - 23/6/21
                                tInput(12833, "mInH", editedRecord["mAccntLen"]),
                                tInput(12834, "mInP", editedRecord["mAccntLen"]),
                                tInput(12835, "mOutH", editedRecord["mAccntLen"]),
                                tInput(12836, "mVATin", editedRecord["mAccntLen"]),
                                tInput(12837, "mVATout", editedRecord["mAccntLen"]),
                                tInput(12838, "mVAThrd", editedRecord["mAccntLen"]),
                                tInput(12839, "mBank", editedRecord["mAccntLen"]),
                            ]}
                        </Card>
                    </div>
                    <div style={colStyle}>
                        <Card title={this.getText(12825)} style={cardStyle}>
                            {[
                                radioInput(12849, "mFileType", [{ code: "Doc", name: "Doc" }, { code: "Dat", name: "Dat" }]),
                                radioInput(12850, "mRefLen", [{ code: "5", name: "5" }, { code: "8", name: "8" }]),
                                radioInput(12851, "mAccntLen", [{ code: "8", name: "8" }, { code: "15", name: "15" }]),
                                selectInput(12853, "mCollect1", [12864, 12865]),
                                selectInput(12854, "mCollect2", [12864, 12865]),
                                selectInput(12855, "mCollect3", [12864, 12865]),
                                selectInput(12856, "mCollect4", [12864, 12865]),
                                selectInput(12857, "mCollect5", [12866, 12867]),
                                selectInput(12858, "mCollect6", [12868, 12869]),
                                selectInput(12859, "mCollect7", [12870, 12871]),
                                selectInput(12860, "mCollect8", [12872, 12873]),
                                selectInput(12861, "mCollect9", [12874, 12875]),
                                selectInput(12862, "mNOD", [12876, 12877, 12878]),
                                selectInput(19650, "mSuppRef", [12876, 12877]),
                                // radioInput(18137, "mAutoDeposit", [{ code: "1", name: this.getText(18138) }, { code: "0", name: this.getText(18139) }]),
                            ]}
                        </Card>
                    </div>
                </Form>
            </Modal >)
    }
}

export default ModalCreateEditMoveinSettings;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";

import "../../App.css";
import { Icon, Popconfirm, Tooltip, Button, message, Modal, Input } from "antd";
import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import PageToolbar from "../../components/PageToolbar";
import ModalCreateEditMoveinSettings from "../../components/ModalCreateEditMoveinSettings";
import ModalPosPayTypeHAccounts from "../../components/ModalPosPayTypeHAccounts";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
//import Jax from "../../Jax/jax.es6.module";

const topHeight = 340; // px
const rowHeight = 30;  // px

class MoveinSettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            height: window.innerHeight,
            modalPosHAccounts: false,
        }

        this.api = {
            get: "get_movein_defs_table",
            delete: "delete_movein_defs_table",
            copy: "copy_movein_defs_table",

            get_h_accounts: "get_pos_h_accounts_table",
            edit_h_accounts: "edit_pos_h_accounts_table",
        }

        this.id = 'MOVEIN_DEFS_TABLE';
        this.h_accounts_id = 'POS_H_ACCOUNTS_TABLE';

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.h_accounts_id] === undefined) props.data[this.h_accounts_id] = { ...props.data.genericData };

        this.doOnResize = null;

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.modalOpen(0) },
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ]
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        this.getHAccounts();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        document.title = "Cash On Tab - " + this.getText(12809);
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
        };
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    getHAccounts = () => {
        this.props.dataActions.genericDataGetRequested(this.h_accounts_id, this.props.user.companyCode, this.props.user.token, this.api.get_h_accounts);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: 1,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    modalOpen = (mID) => {
        this.props.uiActions.showModal(TableIDs.modalCreateEditMoveinSettings, { mID: mID });
    }

    sendAPI = (script, data, sCal, eCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, eCal);
    }

    paymentsAccounts = () => {
        this.props.uiActions.showModal(TableIDs.modalPosPayTypeHAccounts, { ...this.props.ui.data });
    }

    getInputEdit = (text, record, field) => {
        const source = this.props.data[this.h_accounts_id];
        const value = source.editedRecord[field]
        const onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.h_accounts_id, { [field]: e }) }
        if (record.key === source.editing) {
            //return field == 'mOccCust' ?
            //    (<InputNumber {...{ value, onChange, style: { width: '100%' } }} />)
            //    : (<Input value={value} onChange={e => { onChange(e.target.value) }} />)
            return  (<Input value={value} onChange={e => { onChange(e.target.value) }} />)
        } else {
            return text;
        }
    }

    inputWithVat = (t, r) => this.getInputEdit(t, r, 'mWithVat');
    inputNoVat = (t, r) => this.getInputEdit(t, r, 'mNoVat');
    inputExp = (t, r) => this.getInputEdit(t, r, 'mExp');
    inputOccCust = (t, r) => this.getInputEdit(t, r, 'mOccCust');
    editButton = (t, r) => {
        let editing = this.props.data[this.h_accounts_id].editing;
        let iconStyle = (type, onClick) => (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={type} onClick={onClick} />)

        if (editing === r.key) {
            return (<span>
                {iconStyle("check", () => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.h_accounts_id, this.props.user.companyCode, this.props.user.token,
                        this.api.edit_h_accounts, this.api.get_h_accounts);
                })}
                {iconStyle("close", () => {
                    if (editing == 0)
                        this.props.dataActions.genericDataCancelInlineCreate(this.h_accounts_id);
                    else
                        this.props.dataActions.genericDataCancelEditing(this.h_accounts_id);
                })}
            </span>)
        } else {
            return iconStyle("edit", () => {
                this.props.dataActions.genericDataStartEditing(this.h_accounts_id, r.key, r.index);
            })
        }
    }

    render() {
        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            width: window.innerWidth > 600 ? '40%' : '100%',
        };

        let columns = [
            {
                title: this.getText(12811),
                dataIndex: "mDesc",
                key: "mDesc",
                width: '75%',
                render: (text, record) => {
                    let defIcon = (<Icon type={"check-circle"} style={{ paddingRight: 10, color: '#0cd119', fontSize: 16 }} />)
                    return record.mDefault == 1 ? (<span>{text}{defIcon}</span>) : text
                }
            }, {
                title: this.getText(12812),
                width: '25%',
                render: (text, record) => {
                    let deleteRow = () => { this.sendAPI(this.api.delete, record.mID, this.refreshData, (e) => { message.error(e) }) }
                    let copyRow = () => { this.sendAPI(this.api.copy, record.mID, this.refreshData, (e) => { message.error(e) }) }

                    return (<span>
                        <Tooltip title={this.getText(12813)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => { this.modalOpen(record.mID) }} />
                        </Tooltip>
                        <Tooltip title={this.getText(12814)}>
                            <Popconfirm title={this.getText(12816)} onConfirm={deleteRow} okText={this.getText(12817)} cancelText={this.getText(12818)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title={this.getText(12815)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"copy"} onClick={copyRow} />
                        </Tooltip>
                    </span>)
                }
            },
        ];

        let modalPosHAccountsColumns = [
            { title: this.getText(14113), dataIndex: "mPos", key: "mPos", width: '15%', render: t => t },
            { title: this.getText(14114), dataIndex: "mWithVat", key: "mWithVat", width: '20%', render: this.inputWithVat },
            { title: this.getText(14115), dataIndex: "mNoVat", key: "mNoVat", width: '20%', render: this.inputNoVat },
            { title: this.getText(14116), dataIndex: "mExp", key: "mExp", width: '20%', render: this.inputExp },
            { title: this.getText(17601), dataIndex: "mOccCust", key: "mOccCust", width: '15%', render: this.inputOccCust },
            { title: this.getText(14117), width: '10%', render: this.editButton },
        ];

        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    title={this.getText(12810)}
                    actionButtons={this.actionButtons}
                    genericActionButtons={[
                        <Tooltip title={this.getText(14112)}>
                            <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                                this.setState({ modalPosHAccounts: true }, this.getHAccounts)
                            }}>
                                <Icon style={{ fontSize: 16 }} type={"setting"} theme="outlined" />
                            </Button>
                        </Tooltip>,
                        <Tooltip title={this.getText(12950)}>
                            <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={this.paymentsAccounts}>
                                <Icon style={{ fontSize: 16 }} type={"pay-circle"} theme="outlined" />
                            </Button>
                        </Tooltip>,
                    ]}
                    columns={columns}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <ModalCreateEditMoveinSettings
                    id={TableIDs.modalCreateEditMoveinSettings}
                    onClose={this.refreshData}
                    user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditMoveinSettings)}
                />
                <ModalPosPayTypeHAccounts
                    id={TableIDs.modalPosPayTypeHAccounts}
                    onClose={this.refreshData}
                    user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalPosPayTypeHAccounts)}
                />
                <Modal
                    destroyOnClose={true}
                    width={window.innerWidth > 1000 ? 1000 : '95%'}
                    visible={this.state.modalPosHAccounts}
                    onCancel={() => { this.setState({ modalPosHAccounts: false }) }}
                    title={(<PageToolbar
                        title={this.getText(14111)}
                        actionButtons={[{
                            buttonType: 'refresh',
                            buttonFunction: this.getHAccounts
                        }]}
                        isModal={true}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                    />)}
                    footer={false}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataAction={this.props.dataAction} >
                    <ResponsiveTable
                        idTable={this.props.data[this.h_accounts_id]}
                        tableOnly={true}
                        columns={modalPosHAccountsColumns}
                        pagination={{ pageSize: numOfRowsByHeight }}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                    />
                </Modal>
            </div>
        )

    }
}
export default MoveinSettings;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";

class VirtualShopFooter extends Component {
    render() {
        return (<div className="cotvs_footer">
            cotvs_footer
        </div>)
    }
}
export default VirtualShopFooter;
import React, { Component } from "react";
import ResponsiveTable from "./ResponsiveTable";
import GenericModal from "./GenericModal";
import LangContext from "../contextProvider/LangContext";
import { Button, Input, message } from "antd";
import { Job, OutputType, ProcessType, User } from "../JmReact";



class SelectorZList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalZList: false,
            modalZListData: [],
            modalZListSearch: ""
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    apiZList = (dateStart, dateEnd, posList, number, next) => {
        this.sendAPI("get_z_list", "dateStart\fdateEnd\fposList\fnumber\r"
            + (dateStart ?? "") + "\f"
            + (dateEnd ?? "") + "\f"
            + (posList ?? "") + "\f"
            + (number ?? ""),
            ob => {
                next(ob.data ? ob.data.split("\r").map(x => {
                    let y = x.split("\f");
                    return { num: y[0], pos: y[1], posName: y[2], count: y[3], start: y[4], end: y[5] }
                }) : [])
            })
    }

    onBlurZInput = () => {
        const { zNumber } = this.props.filters;
        if (zNumber) {
            this.apiZList("", "", "", zNumber, list => {
                if (!list[0]) {
                    this.props.onChange( { zNumber: "" });
                    message.error(this.getText(19492))
                } else {
                    const { num, pos, start, end } = list[0];
                    this.props.onChange( { zNumber: num, PosList: pos, StartDate: start?.split(" ")[0], EndDate: end?.split(" ")[0] });
                }
            })
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }



    render() {
        return (<span>
            <Button type="primary" icon="unordered-list" onClick={() => {
                const { zNumber, StartDate, EndDate, PosList } = this.props.filters;
                this.apiZList(StartDate, EndDate, PosList, "", list => {
                    this.setState({ modalZList: true, modalZListData: list, modalZListSearch: zNumber })
                })
            }} />
            <Input
                style={{ width: 300 }}
                value={this.props.filters.zNumber}
                onChange={(e) => {
                    if (e.target.value === "") this.props.onChange({ zNumber: "" })
                    if (parseInt(e.target.value)) this.props.onChange({ zNumber: parseInt(e.target.value) })
                }}
                onBlur={this.onBlurZInput}
            />
            <GenericModal
                visible={this.state.modalZList}
                onCancel={() => { this.setState({ modalZList: false }, this.onBlurZInput) }}
                title={this.getText(19493)}
                width={1000}
                showsearch={() => { }}
                valueSearch={this.state.modalZListSearch}
                onChangeSearch={e => { this.setState({ modalZListSearch: e.target.value }) }}
            >
                <ResponsiveTable
                    tableOnly
                    dataSource={this.state.modalZListData.filter(f => !this.state.modalZListSearch || f.num?.indexOf(this.state.modalZListSearch) > -1)}
                    columns={[
                        { title: this.getText(19494), key: "num", dataIndex: "num" },
                        { title: this.getText(19495), key: "posName", dataIndex: "posName" },
                        { title: this.getText(19496), key: "count", dataIndex: "count" },
                        { title: this.getText(19497), key: "start", dataIndex: "start" },
                        { title: this.getText(19498), key: "end", dataIndex: "end" },
                        {
                            render: (t, r) => (<Button type="primary" onClick={() => {
                                this.props.onChange({ zNumber: r.num, PosList: r.pos, StartDate: r.start?.split(" ")[0], EndDate: r.end?.split(" ")[0] });
                                this.setState({ modalZList: false })
                            }}>{this.getText(19499)}</Button>)
                        },
                    ]}
                    pagination={{ pageSize: 10 }}
                />
            </GenericModal>
        </span>)
    }
}
export default SelectorZList;
/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";

import "../../App.css";
import {
    Icon, Popconfirm, //ooltip, //Button, message, 
    Modal, //Row, Col, 
    Input, // Form, Select, 
    // Switch, Upload, 
    Checkbox
} from "antd";
//import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import PageToolbar from "../../components/PageToolbar";
//import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import TextArea from "antd/lib/input/TextArea";
import ImportExcel from "../UserSettings/ImportExcel";
// import XLSX from 'xlsx';

// const { Option } = Select;
// const { Item } = Form;

class ImportExcelSettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            height: window.innerHeight,
            modalSqlOnExcel: false,
            defID: 0,
            defDesc: '',
        }

        this.api = {
            get: "get_import_excel_table",
            get_sql: "get_sql_on_excel",
            create_edit_sql: "create_edit_sql_on_excel",
            delete_sql: "delete_sql_on_excel",
        }

        // this.id = 'IMPORT_EXCEL_TABLE';
        this.id_sql = 'SQL_ON_EXCEL_TABLE';

        // if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.id_sql] === undefined) props.data[this.id_sql] = { ...props.data.genericData };

        // this.doOnResize = null;

        // this.actionButtons = [
        //     {
        //         buttonType: 'refresh',
        //         buttonFunction: this.refreshData
        //     }
        // ]
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    // componentDidMount() {
    //     this.initFilters();

    //     window.addEventListener("resize", () => {
    //         clearTimeout(this.doOnResize);
    //         this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
    //     });

    // }

    // componentWillUnmount() {
    //     window.removeEventListener("resize", this.handleWindowSizeChange);
    // }

    // componentDidUpdate() {
    //     document.title = "Cash On Tab - " + this.getText(13874);
    // }

    // handleWindowSizeChange = () => {
    //     this.setState({ height: window.innerHeight }, this.refreshData);
    // };

    // initFilters = () => {
    //     let request = {
    //         _id: 0, // for testing. TODO: "read" from selector
    //         _fieldId: false,
    //         page: 1,
    //         recordsPerPage: 10, //5,
    //         sortType: 1,
    //         sortColumn: 1,
    //         // api
    //         _words: '',
    //         _page_num: 1,
    //         _rows_num: 10,
    //         _isAsc: 1,

    //     };
    //     this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    // }

    // refreshData = () => {
    //     this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    // }

    // handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {

    //     let request = {
    //         _id: 0,
    //         // just of ui-pagination update
    //         page: pagination.current,
    //         recordsPerPage: 10,
    //         sortType: sorter.order === "ascend" ? 1 : 0,
    //         sortColumn: 1,

    //         _page_num: pagination.current,
    //         _rows_num: 10,
    //         _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
    //     };

    //     this.props.dataActions.genericDataSetFilter(
    //         this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    // }

    // sendAPI = (script, data, sCal, eCal) => {
    //     let user = new User(this.props.user.token, this.props.user.companyCode);
    //     let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
    //     job.setInput(data);
    //     job.send("/cgi-bin/CashOnTab", sCal, eCal);
    // }

    apiSqlOnExcel = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id_sql,
            this.props.user.companyCode,
            this.props.user.token,
            { _def: this.state.defID },
            this.api.get_sql);
    }

    render() {

        const mobile = window.innerWidth <= 600 ? true : false;

        // const divStyle = {
        //     dir: "rtl", // dir: 'ltr'
        //     padding: "2% 2% 2% 2%",
        //     margin: 'auto',
        //     width: mobile ? '100%' : '60%',
        // };

        // let pData = this.props.data[this.id];

        // let lDesc = (n) => this.getText(parseInt(n))

        // let columns = [
        //     {
        //         title: this.getText(13123),
        //         dataIndex: "mDesc",
        //         key: "mDesc",
        //         width: '40%',
        //         render: (text, record) => text
        //     }, {
        //         title: this.getText(13124),
        //         dataIndex: "mType",
        //         key: "mType",
        //         width: '30%',
        //         render: (text, record) => lDesc(text)
        //     }, {
        //         title: this.getText(13125),
        //         dataIndex: "mIsActive",
        //         key: "mIsActive",
        //         width: '5%',
        //         render: (text, record) => (<Icon type={text == 1 ? "check-circle" : "close-circle"} style={{ color: text == 1 ? 'green' : 'red' }} />)
        //     }, {
        //         title: this.getText(13601),
        //         dataIndex: "mFirstTitle",
        //         key: "mFirstTitle",
        //         width: '10%',
        //         render: (text, record) => (<Icon type={text == 1 ? "check-circle" : "close-circle"} style={{ color: text == 1 ? 'green' : 'red' }} />)
        //     }, {
        //         title: this.getText(12812),
        //         width: '15%',
        //         render: (text, record) => {
        //             return (<span>
        //                 <Tooltip title={this.getText(13876)}>
        //                     <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"setting"} onClick={() => {
        //                         this.setState({ modalSqlOnExcel: true, defID: record.key, defDesc: record.mDesc }, this.apiSqlOnExcel)
        //                     }} />
        //                 </Tooltip>
        //             </span>)
        //         }
        //     },
        // ];


        let mData = this.props.data[this.id_sql];

        let getDesc = (text, record) => {
            if (mData.editing !== record.key) {
                return text;
            } else {
                return (<Input
                    value={mData.editedRecord['mDesc']}
                    onChange={(e) => {
                        this.props.dataActions.genericDataSetEdit(this.id_sql, { mDesc: e.target.value })
                    }} />)
            }
        }

        let getCmd = (text, record) => {
            if (mData.editing !== record.key) {
                return text;
            } else {
                return (<TextArea
                    autoSize={true}
                    style={{ direction: 'ltr' }}
                    value={mData.editedRecord['mCmd']}
                    onChange={(e) => {
                        this.props.dataActions.genericDataSetEdit(this.id_sql, { mCmd: e.target.value })
                    }} />)
            }
        }

        let getIsActive = (text, record) => {
            let source = mData.editing !== record.key ? text : mData.editedRecord['mIsActive'];
            return (<Checkbox
                disabled={mData.editing !== record.key}
                checked={source == '1' ? true : false}
                onChange={(e) => {
                    this.props.dataActions.genericDataSetEdit(this.id_sql, { mIsActive: e.target.checked ? '1' : '0' })
                }} />)
        }

        let getEditButton = (text, record) => {
            if (mData.editing !== record.key) {
                return (<span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                        this.props.dataActions.genericDataStartEditing(this.id_sql, record.key, record.index);
                    }} />
                    <Popconfirm title={this.getText(11452)}
                        onConfirm={() => {
                            this.props.dataActions.genericDataDeleteRow(
                                this.id_sql,
                                record['mID'],
                                this.props.user.companyCode,
                                this.props.user.token,
                                this.api.delete_sql,
                                this.api.get_sql);
                        }}
                        okText={this.getText(11453)} cancelText={this.getText(11454)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                    </Popconfirm>
                </span>);
            } else {
                let isCreate = (mData.editing == 0) ? true : false;
                return (<span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                        onClick={() => {
                            this.props.dataActions.genericDataSaveEdit(
                                this.id_sql,
                                this.props.user.companyCode,
                                this.props.user.token,
                                this.api.create_edit_sql,
                                this.api.get_sql);
                        }} />
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                        onClick={() => {
                            if (isCreate)
                                this.props.dataActions.genericDataCancelInlineCreate(this.id_sql);
                            else
                                this.props.dataActions.genericDataCancelEditing(this.id_sql);
                        }} />
                </span>)
            }
        }

        let SQLcolumns = [
            { title: this.getText(13877), key: 'mDesc', dataIndex: 'mDesc', width: '30%', render: getDesc },
            { title: this.getText(13878), key: 'mCmd', dataIndex: 'mCmd', width: '50%', render: getCmd },
            { title: this.getText(13879), key: 'mIsActive', dataIndex: 'mIsActive', width: '10%', render: getIsActive },
            { title: this.getText(13880), width: '10%', render: getEditButton },
        ]

        return (<span>
            <ImportExcel
                {...this.props}
                isAdmin
                onClickSqlOnExcel={(record) => {
                    this.setState({ modalSqlOnExcel: true, defID: record.key, defDesc: record.mDesc }, this.apiSqlOnExcel)
                }} />
            <Modal
                destroyOnClose={true}
                width={mobile ? '95%' : '70%'}
                visible={this.state.modalSqlOnExcel}
                title={(<PageToolbar
                    isModal={true}
                    title={this.getText(13876) + ' - ' + this.state.defDesc}
                    showsearch={false}
                    actionButtons={[{
                        buttonType: 'add',
                        buttonFunction: () => {
                            this.props.dataActions.genericDataStartInlineCreate(this.id_sql);
                            this.props.dataActions.genericDataSetEdit(this.id_sql, { mDef: this.state.defID });
                        }
                    }]}
                />)}
                footer={false}
                onCancel={() => { this.setState({ modalSqlOnExcel: false }) }}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <ResponsiveTable
                    idTable={this.props.data[this.id_sql]}
                    tableOnly={true}
                    actionButtons={[]}
                    columns={SQLcolumns}
                    pagination={{ pageSize: 10 }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
            </Modal>
        </span>)
    }
}

export default ImportExcelSettings;
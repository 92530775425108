/* @flow */
/*jshint esversion: 6 */

import React from "react";
import { Tooltip, Popover, Button, Icon, InputNumber } from "antd";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";

class GoogleBilling extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            addToPackValue: 0
        }

        this.id = "GOOGLE_BILLING";

        this.api = {
            get: "get_google_billing",
            update: "update_google_billing",
        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, {}, this.api.get);
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(14084);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    startEdit = (r) => {
        this.props.dataActions.genericDataStartEditing(this.id, r.key, r.index)
    }

    setEdit = (r, v) => {
        this.setState({ addToPackValue: v }, () => {
            this.props.dataActions.genericDataSetEdit(this.id, { gPurchased: v + parseInt(r.gPurchased) })
        })
    }

    saveEdit = () => {
        this.props.dataActions.genericDataSaveEdit(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.update, this.api.get);
    }

    packName = (t) => {
        switch (t) {
            case '1': return this.getText(14089);
            default: return '';
        }
    }

    addButton = (t, r) => {
        let buttonTitle = this.getText(14090);
        let content = (<div>
            <InputNumber value={this.state.addToPackValue} onChange={(v) => { this.setEdit(r, v) }} style={{ width: 150 }} />
            <br />
            <Button key="save" type="primary" onClick={this.saveEdit} style={{ margin: 5 }}>{this.getText(14091)}</Button>
            <Button key="cancle" onClick={this.refreshData} style={{ margin: 5 }}>{this.getText(14323)}</Button>
        </div>)

        return (<Tooltip title={buttonTitle}>
            <Popover
                content={content} title={buttonTitle} placement="topRight" trigger="click"
                visible={this.props.data[this.id].editing == r.key}>
                <Icon style={{ fontSize: 16 }} type={"plus"} onClick={() => { this.startEdit(r) }} />
            </Popover>
        </Tooltip >)
    }

    render() {
        let actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        let columns = [
            { title: this.getText(14086), key: "gID", dataIndex: "gID", width: "30%", render: this.packName },
            { title: this.getText(14087), key: "gPurchased", dataIndex: "gPurchased", width: "30%", render: (t) => t },
            { title: this.getText(14088), key: "gUsed", dataIndex: "gUsed", width: "30%", render: (t) => t },
            { title: "", width: "10%", render: this.addButton },
        ];

        return (
            <div style={{ width: 500, margin: 'auto', marginTop: 20 }}>
                <ResponsiveTable
                    title={this.getText(14085)}
                    actionButtons={actionButtons}
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    pagination={false}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
            </div>
        )
    }

}

export default GoogleBilling;



import React from "react";
import { Row, Col, Card } from "antd";

type Props = {
    content: [{
        span: Integer,
        title: String,
        list: [{
            key: String,
            value: Object | String
        }]
    }],
}
class GridKeyValues extends React.Component<Props, State>{

    render() {
        return (<Row>
            {this.props.content ?
                this.props.content.map((x) => {
                    return (<Col span={window.innerWidth > 600 ? x.span : 24}>
                        <Card style={{ backgroundColor: '#dddddd', margin: 5 }} title={x.title ? x.title : false}>
                            {x.list ? x.list.map((y) => {
                                return (<Row style={{ padding: 3 }}>
                                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ padding: 5 }}>{y.key}</Col>
                                    <Col span={window.innerWidth > 600 ? 16 : 24} style={{ backgroundColor: '#ffffff', padding: 5, minHeight: 30 }}>{y.value}</Col>
                                </Row>)
                            }) : ""}
                        </Card>
                    </Col>)
                }) : ""}
        </Row>)
    }

}
export default GridKeyValues;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
//import ReportMenu from "../menus/ReportMenu";
import Index from "./reports/index";
import CostChangesLogExcel from "./reports/CostChangesLogExcel";
import ReportMenuItems from "../menus/ReportMenuItems";
//import GenerateBarcode from "./reports/GenerateBarcode";
import TableIDs from '../data/TableIDs';

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object
};

type State = {};

// SalesItemsReport // SalesReport
class ReportsItems extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={'71'} path={this.props.baseUrl + "/reports_items/report-prices"} exact render={() => <Index {...routeProps} />} />),
            (<Route key={'73'} path={this.props.baseUrl + "/reports_items/cost-changes-log"} exact render={() => <CostChangesLogExcel {...routeProps} />} />),
              // (<Route key={'72'} path={this.props.baseUrl + "/reports_items/generate-barcode"} exact render={() => <GenerateBarcode {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/reports_items"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <ReportMenuItems baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default ReportsItems;


import React from "react";
import { Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message, InputNumber } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
import { fixCurrentPage, escapeRegExp } from "../utils/utils";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";

import "../style/generalUI.css";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const topHeight = 300; // px
const rowHeight = 40;  // px

//type State = {}
class ModalCreatePriceList extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = "CREATE_EDIT_TAG_TYPES";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[TableIDs.genericSelectorTagRelation] === undefined) {
            props.data[TableIDs.genericSelectorTagRelation] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_tag_types_table_list",
            create: "create_tag_type",
            edit: "edit_tag_type",
            delete: "delete_tag_type",

            selector: "get_tag_relation_list", // TODO: tag type categories
            selector_tags: "get_tag_types_with_relation_list",
        }

        this.actionButtons = [{
            buttonType: 'add',
            buttonFunction: this.handleCreateNew
        }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }/*, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //document.title = "Cash On Tab - ";
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorTagRelation, this.props.user.companyCode,
            this.props.user.token, this.api.selector);
        //this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorTagRelation, this.props.user.companyCode,
            this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 1
            case "mId": col--;
            case "mDescription": col--;
            case "mRelationTable": col--;
            case "mNotForDeletion": break; // 5
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        if (this.props.data[this.id].editing === -1)
            this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        this.setState({ visible: false });
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleClear = () => {
        //this.clear();
    }

    render() {
        const { loading } = this.state;
        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10418)}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request,
                                _words: escapeRegExp(val),
                                //  _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mDescription: y[1],
                mRelationTable: y[2],
                mNotForDeletion: y[4],
                mRelationDescription: y[5],
                mTagTypeMaxItemTags: y[6],
                index: index
            })
        });

        let columns =
            [
                {
                    title: this.getText(10419),
                    dataIndex: "mId",
                    key: "mId",
                    sorter: true,
                    width: '10%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(10420),
                    dataIndex: "mDescription",
                    key: "mDescription",
                    width: '40%',
                    sorter: true,
                    render: (text, record) => {
                        if (this.props.data[this.id].editing === record.key) {
                            return (
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { mDescription: value.target.value });
                                    }} />);
                        } else {
                            return text;
                        }
                    },
                },{
                    title: this.getText(19249),
                    dataIndex: "mTagTypeMaxItemTags",
                    key: "mTagTypeMaxItemTags",
                    width: '10%',
                    render: (text, record) => {
                        if (this.props.data[this.id].editing === record.key) {
                            return (
                                <InputNumber
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { mTagTypeMaxItemTags: value });
                                    }} />);
                        } else {
                            return text;
                        }
                    },
                }, {
                    title: this.getText(10421),
                    dataIndex: "mRelationTable",
                    key: "mRelationTable",
                    width: '30%',
                    sorter: true,
                    render: (text, record) => {
                        let list = this.props.data[TableIDs.genericSelectorTagRelation].dataset.map((obj, index) => {
                            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
                        });
                        let editedRecord = this.props.data[this.id].editedRecord;

                        const viewOption = (v1, v2) => v1 && v2 ? v1 + "#" + v2 : null

                        return (
                            <Select
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                disabled={!(this.props.data[this.id].editing === record.key)}
                                value={(this.props.data[this.id].editing === record.key) ?
                                    viewOption(editedRecord['mRelationTable'], editedRecord['mRelationDescription']) :
                                    viewOption(text, record['mRelationDescription'])}
                                defaultValue=""
                                style={{ width: '100%', paddingLeft: '4px' }}
                                onChange={(e) => {
                                    let newData = e ? e.split('#') : ['', ''];
                                    this.props.dataActions.genericDataSetEdit(this.id, { mRelationTable: newData[0], mRelationDescription: newData[1] })
                                }}
                            >
                                {list}
                            </Select>
                        )
                    },
                }, {
                    title: this.getText(10422),
                    key: "Settings",
                    width: '10%',
                    render: (text, record) => {
                        console.log("xxxxx record: ", record, record['mNotForDeletion']);

                        let classIsHidden = (record['mNotForDeletion'] === 1
                            || record['mNotForDeletion'] === '1')
                            ? "isHidden"
                            : ""

                        if (this.props.data[this.id].editing !== record.key) {
                            return (
                                <span>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                        this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                    }} />
                                    <Popconfirm title={this.getText(10423)}
                                        onConfirm={() => {
                                            fixCurrentPage.bind(this)();
                                            this.props.dataActions.genericDataDeleteRow(
                                                this.id,
                                                record['mId'],
                                                this.props.user.companyCode,
                                                this.props.user.token,
                                                this.api.delete,
                                                this.api.get);
                                        }
                                        }
                                        okText={this.getText(10424)} cancelText={this.getText(10425)}>
                                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4 " + classIsHidden} type={"delete"} />
                                    </Popconfirm>
                                </span >);
                        } else {
                            let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                            return (<span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                    onClick={() => {
                                        let editedRecord = this.props.data[this.id].editedRecord;
                                        if (!editedRecord['mRelationTable'] || !editedRecord['mRelationDescription']) {
                                            message.error(this.getText(17689))
                                        } else {
                                            if (isCreate)
                                                this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get);
                                            else
                                                this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                            // update selector for main page
                                            this.props.dataActions.genericMultiSelectorRefreshDataset(
                                                TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selector_tags);

                                        }
                                    }} />
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                    onClick={() => {
                                        if (isCreate)
                                            this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                        else
                                            this.props.dataActions.genericDataCancelEditing(this.id);
                                    }} />
                            </span>);
                        }
                    },
                }
            ];

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={window.innerWidth>1000 ? 1000 : '90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}
                /*footer={
                    [
                        <Button key="back"
                            onClick={this.handleCancel}>ביטול</Button>,
                        <Button key="clear"
                            loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                        <Button key="create" disabled={!this.state.isValidItemCode}
                            type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                        <Button key="createLeave" disabled={!this.state.isValidItemCode}
                            type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                    ]}*/
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >


                <ResponsiveTable ui={this.props.ui} tableOnly={true} dataSource={dataSource}
                    columns={columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ emptyText: this.getText(10426) }}
                />
            </Modal >
        )
    }
}

export default ModalCreatePriceList;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import UserSettingMenu from "../menus/UserSettingMenu";

import PermissionTable from "./UserSettings/Permissions";
import EarningPoints from "./Customers/EarningPoints";
import UsingPoints from "./Customers/UsingPoints";
// import CustomerField from "./Customers/CustomerField";
import CustomerClub from "./Settings/CustomerClub";
import CustomerCreditSettings from "./Settings/CustomerCreditSettings";
import StorageTable from "./UserSettings/StorageTable";
import BranchesTable from "./UserSettings/BranchTable";
import FixLabSettings from "./UserSettings/FixLabSettings";
import WinnerSettings from "./UserSettings/WinnerSettings";
import RearScreenImages from "./UserSettings/RearScreenImages";
import ItemDefaults from "./UserSettings/ItemDefaults";
import WorkingHouersDefaults from "./UserSettings/WorkingHouersDefaults";
import TableIDs from '../data/TableIDs';
import PrinterLabels from "./UserSettings/PrinterLabels";
import ImportExcel from "./UserSettings/ImportExcel";
import ExportExcel from "./Settings/ExportExcel";
import GiftCards from "./UserSettings/GiftCards";
import KuponDeclarations from "./UserSettings/KuponDeclarations";
import Advertisements from "./UserSettings/Advertisements";
import ButtonsEditor from "./Settings/ButtonsEditor";
import UpdateParameters from "./Admin/UpdateParameters";
import DocumentRemarks from "./UserSettings/DocumentRemarks";
import CouponDeclarations from "./UserSettings/CouponDeclarations";
import BestClubReportSettings from "./UserSettings/BestClubReportSettings";
import CurrencyRates from "./UserSettings/CurrencyRates";
import Events from "./UserSettings/Events";
import CancelReasons from "./UserSettings/CancelReasons";
import CitiesAreas from "./UserSettings/CitiesAreas";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object
};

type State = {};

class UserSettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };
    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={132} path={this.props.baseUrl + "/user-settings/edit-buttons"} exact render={() => <ButtonsEditor {...routeProps} />} />),
            (<Route key={81} path={this.props.baseUrl + "/user-settings/permissions"} exact render={() => <PermissionTable {...routeProps} />} />),
            // (<Route key={82} path={this.props.baseUrl + "/user-settings/customer-fields"} exact render={ () => <CustomerField baseUrl={this.props.baseUrl} user={this.props.user} ui={this.props.ui} uiActions={this.props.uiActions} dataActions={this.props.dataActions} data={this.props.data} />} />),
            (<Route key={83} path={this.props.baseUrl + "/user-settings/earning-points"} exact render={() => <EarningPoints {...routeProps} />} />),
            (<Route key={84} path={this.props.baseUrl + "/user-settings/using-points"} exact render={() => <UsingPoints {...routeProps} />} />),
            (<Route key={85} path={this.props.baseUrl + "/user-settings/storage-table"} exact render={() => <StorageTable {...routeProps} />} />),
            (<Route key={86} path={this.props.baseUrl + "/user-settings/branch-table"} exact render={() => <BranchesTable {...routeProps} />} />),
            (<Route key={87} path={this.props.baseUrl + "/user-settings/fix-lab-settings"} exact render={() => <FixLabSettings {...routeProps} />} />),
            (<Route key={88} path={this.props.baseUrl + "/user-settings/winner-settings"} exact render={() => <WinnerSettings {...routeProps} />} />),
            (<Route key={89} path={this.props.baseUrl + "/user-settings/rear-screen-images"} exact render={() => <RearScreenImages {...routeProps} />} />),
            (<Route key={131} path={this.props.baseUrl + "/user-settings/item-defaults"} exact render={() => <ItemDefaults {...routeProps} />} />),
            (<Route key={133} path={this.props.baseUrl + "/user-settings/working-houers-defaults"} exact render={() => <WorkingHouersDefaults {...routeProps} />} />),
            (<Route key={142} path={this.props.baseUrl + "/user-settings/import-excel"} exact render={() => <ImportExcel {...routeProps} />} />),
            (<Route key={134} path={this.props.baseUrl + "/user-settings/gift-cards"} exact render={() => <GiftCards {...routeProps} />} />),
            (<Route key={135} path={this.props.baseUrl + "/user-settings/kupon-declarations"} exact render={() => <KuponDeclarations {...routeProps} />} />),
            (<Route key={136} path={this.props.baseUrl + "/user-settings/advertisements"} exact render={() => <Advertisements {...routeProps} />} />),
            (<Route key={137} path={this.props.baseUrl + "/user-settings/update-parameters"} exact render={() => <UpdateParameters {...routeProps} />} />),
            (<Route key={138} path={this.props.baseUrl + "/user-settings/export-excel"} exact render={() => <ExportExcel {...routeProps} />} />),
            (<Route key={139} path={this.props.baseUrl + "/user-settings/document-remarks"} exact render={() => <DocumentRemarks {...routeProps} />} />),
            (<Route key={140} path={this.props.baseUrl + "/user-settings/coupon-declarations"} exact render={() => <CouponDeclarations {...routeProps} />} />),
            (<Route key={221} path={this.props.baseUrl + "/user-settings/best-club-report-settings"} exact render={() => <BestClubReportSettings {...routeProps} />} />),
            (<Route key={222} path={this.props.baseUrl + "/user-settings/currency-rates"} exact render={() => <CurrencyRates {...routeProps} />} />),
            (<Route key={223} path={this.props.baseUrl + "/user-settings/events"} exact render={() => <Events {...routeProps} />} />),
            (<Route key={224} path={this.props.baseUrl + "/user-settings/cancel-reasons"} exact render={() => <CancelReasons {...routeProps} />} />),
            (<Route key={225} path={this.props.baseUrl + "/user-settings/cities-areas"} exact render={() => <CitiesAreas {...routeProps} />} />),
            (<Route key={-1} path={this.props.baseUrl + "/user-settings/printer-labels"} exact render={() => <PrinterLabels {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/user-settings"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <UserSettingMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default UserSettings;

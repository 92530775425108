import React, { Component } from "react";
import { Input } from "antd";

class InputScanner extends Component {
    barcodeReaderBuffer = '';
    isBufferFull = false;
    ctrlBKeyFlag = false;
    skipChars = 0;

    render() {
        return (<Input
            {...this.props}
            autoFocus
            onChange={e => { this.props.onChange(e.target.value) }}
            onKeyDown={(e) => {
                ////console.log('yuda test ', this.barcodeReaderBuffer, m);
                console.log('this.barcodeReaderBuffer', this.props.value)
                switch (e.key) {
                    case "b": case "B": case "נ": case "M": case "m": case "צ":
                        if (e.ctrlKey) {
                            this.props.onChange('');
                            this.ctrlBKeyFlag = true;
                            this.barcodeReaderBuffer = '';
                            if (this.props.skipCharsCount) this.skipChars = this.props.skipCharsCount;
                        }
                        break;

                    case "Enter":
                        if (this.ctrlBKeyFlag == true) {
                            this.ctrlBKeyFlag = false;
                            if (this.barcodeReaderBuffer || this.isBufferFull === true) {
                                this.props.onChange(this.barcodeReaderBuffer, true);
                            }
                        }

                        break;

                    default:
                        if (this.ctrlBKeyFlag == true) {
                            if (this.skipChars < 1 || !this.props.skipCharsCount) {
                                this.barcodeReaderBuffer += e.key;
                            } else {
                                this.skipChars--;
                            }
                        }
                        break;
                }
            }}
        />)
    }

}
export default InputScanner;

import React from "react";
import "../App.css";
import { Form, Row, Col, Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Checkbox, Select } from 'antd';
import { DatePicker } from 'antd';
import moment from "moment";
import Jax from "../Jax/jax.es6.module";
//import ExtendedTable from "./ExtendedTable";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableIDs from '../data/TableIDs.js';
import PageToolbar from "./PageToolbar";


const FormItem = Form.Item;
const { Option, OptGroup } = Select;
/*
type Props = {
    //title: string,
    apiCalls: any[],
    api_create: any,
    text:any,
};
*/
//type State = {}


type TagObj = {
    type: {
        id: string,
        text: string,
    },
    tags: {
        id: string,
        text: string,
    }[],
}

class ModalHoursReportAdvanceSearch extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.id = "ADVANCE_HOURS_REPORT_SEARCH";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorSalesAlerts] === undefined) {
            props.data[TableIDs.genericSelectorSalesAlerts] = { ...props.data.genericSelector };
        }

        //if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) {
        //    props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        //}

        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }

        this.api = {
            selectorTags: "get_tags_with_types",
            selectorAlerts: "get_sale_alert_list",
            selector_employees: "get_employee_list",

            get: "get_working_hours_table_list",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.state = {
            loading: false,
            visible: false,
            request: {
                _words: '',
                _FromDate: undefined,
                _ToDate: undefined,
                _EmployeeList: '',
            }
        }
        //this.api_create = this.props.apiCalls.find(item => item.apiType === 'create').apiCall;

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
    }

    // send data to parent

    // componentWillReceiveProps(newProps) {
    //     //alert("on props: " + JSON.stringify(newProps));        
    //     let _state = this.state;
    //     _state.visible = newProps.toggle;
    //     this.setState(_state);

    //     //this.props.ActionQueue.addToQueue({
    //     //    action: this.props.dataActions.genericSelectorRefreshDataset,
    //     //    args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
    //     //});
    // }

    refreshData = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    handleOk = () => {
        console.log("search...");

        this.props.dataActions.genericDataSetFilter(
            'HOURS_REPORT_MAIN', this.props.user.companyCode, this.props.user.token, { ...this.state.request }, this.api.get);

        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }
    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }

    disabledStartDate = (mFormDate) => {
        const _ToDate = this.state._ToDate;
        if (!mFormDate || !_ToDate) {
            return false;
        }
        return mFormDate.valueOf() > _ToDate.valueOf();
    }

    disabledEndDate = (_ToDate) => {
        const mFormDate = this.state.mFormDate;
        if (!_ToDate || !mFormDate) {
            return false;
        }
        return _ToDate.valueOf() <= mFormDate.valueOf();
    }

    onChange = (field, value) => {
        //this.setReportParameters("HoursReport", { [field]: value })
        let r = this.state.request;
        this.setState({
            request: {
                ...r, [field]: value,
            }
        });
    }

    onStartChange = (value) => {
        this.onChange('_FromDate', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('_ToDate', value ? value.format("YYYY-MM-DD") : null);
    }

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }

    createCheckBox = (field, data) => {
        let r = this.state.request;
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setState({ request: { ...r, [field]: data } });
                //this.setReportParameters("SalesItems", { [field]: data });
                else
                    this.setState({ request: { ...r, [field]: '' } });
                //this.setReportParameters("SalesItems", { [field]: '' });
            }} />);
    }

    render() {
        console.log("render - modal advance search ");
        const { visible, loading, endOpen } = this.state;
        const { _FromDate, _ToDate } = this.state.request;

        //const formItemLayout = {
        //    labelCol: { span: 4 },
        //    wrapperCol: { span: 14 },
        //}

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        let listEmployee = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={'חיפוש מתקדם'}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'60%'}
                visible={this.props.toggle}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={/**/null}
            // <Button key="back" onClick={this.handleCancel}>ביטול</Button>,
            // <Button key="clear" onClick={this.handleReset}>ניקוי</Button>,
            // <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>חיפוש</Button>,
            //]} 
            >
                <Form id="search_working_hours_form" layout="horizontal" //className="ant-advanced-search-form"
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '2' : '1'}}
                    onSubmit={this.handleSearch}>

                    <FormItem label={'סינון טקסטואלי'} style={{ margin: '4px', breakInside: 'avoid-column' }}/*{...formItemLayout}*/>
                        <Input placeholder="טקסט" style={{ width: '80%' }}
                            value={//this.props.data['ITEMS_TABLE'].filters['_words']
                                this.state.request['_words']
                            }
                            onChange={this.onChangeInput}
                            onBlur={this.handleFilterBlur}
                        />
                    </FormItem>
                    <FormItem label={"רשימת עובדים:"} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        {this.createCheckBox('_EmployeeList', Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                        <Select key={"_EmployeeList"} style={{ width: '80%' }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4}
                            //placeholder="Please select"
                            //className={""}
                            //defaultValue={1}
                            onChange={(value) => {
                                let r = this.state.request;
                                this.setState({ request: { ...r, _EmployeeList: value.join(',') } });
                            }} //this.setReportParameters("SalesItems", { _EmployeeList: value.join(',') })}
                            value={(this.state.request._EmployeeList === '') ? [] : this.state.request._EmployeeList.split(',')}
                            notFoundContent={"לא נמצאו עובדים"}
                        >
                            {listEmployee}
                        </Select>
                    </FormItem>

                    <FormItem label={"מתאריך:"} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        <DatePicker
                            disabledDate={this.disabledStartDate}
                            showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            value={_FromDate ? moment(_FromDate, "YYYY-MM-DD") : null}
                            placeholder="Start"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                        />
                    </FormItem>
                    <FormItem label={"עד תאריך:"} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                        <DatePicker
                            disabledDate={this.disabledEndDate}
                            showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            value={_ToDate ? moment(_ToDate, "YYYY-MM-DD") : null}
                            placeholder="End"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                        />
                    </FormItem>
                </Form >
                <div className="action_footer">
                    <Button key="back" onClick={this.handleCancel}>ביטול</Button>
                    <Button key="clear" onClick={this.handleReset}>ניקוי</Button>
                    <Button form="search_working_hours_form" key="create" type="primary" loading={loading} onClick={this.handleOk}>חיפוש</Button>
                </div>
            </Modal >
        )
    }

    // bind input field
    onChangeInput = (e) => {
        //this.props.dataActions.genericDataSetFilter(
        //    'ITEMS_TABLE', this.props.user.companyCode, this.props.user.token, { _words: e.target.value }, this.api.get)
        let r = { ...this.state.request };
        r._words = e.target.value;
        this.setState({ ...this.state, request: r });
    }

    handleFilterBlur = (e) => {
        //this.props.dataActions.genericDataSetFilter(
        //    'ITEMS_TABLE', this.props.user.companyCode, this.props.user.token, { _words: e.target.value }, this.api.get)
        let r = { ...this.state.request };
        r._words = e.target.value;
        this.setState({ ...this.state, request: r });
    }

    // tag select
    handleTagSelectChange = (e) => {
        //this.props.dataActions.genericDataSetFilter(
        //   'ITEMS_TABLE', this.props.user.companyCode, this.props.user.token, { _tagList: e.join(',') }, this.api.get)
        let r = { ...this.state.request };
        r._tagList = e.join(',');
        this.setState({ ...this.state, request: r });

    }
    /// search
    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log('Received values of form: ', values);
        });
    }

    handleReset = () => {
        //this.props.form.resetFields();
        let _request = {
            _words: '',
            _FromDate: null,
            _ToDate: null,
            _EmployeeList: '',
        };
        this.setState({ request: _request },
            () => {
                this.props.dataActions.genericDataSetFilter(
                    'HOURS_REPORT_MAIN', this.props.user.companyCode, this.props.user.token, _request, this.api.get);
            }
        );
    }
}

export default ModalHoursReportAdvanceSearch;



/*
                    <FormItem label={'סינון התראת מכירה'} >//{...formItemLayout}
                    <Select
                    showsearch
                    value={//this.props.data['ITEMS_TABLE'].filters['_saleAlert']
                        this.state.request['_saleAlert']
                    }
                    defaultValue=""
                    style={{ width: 200 }}
                    placeholder="בחר התראת מכירה"
                    optionFilterProp="children"
                    onChange={this.handleSelectChange}
                    onFocus={this.handleSelectFocus}
                    onBlur={this.handleSelectBlur}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    <Option value="">- ללא התראה -</Option>
                    {alertList}
                </Select>
            </FormItem>
*/


import React from "react";
import { Input, InputNumber, Form, Button, Modal, Select, message, Switch, notification, Popconfirm, Row, Col, Radio } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import { fixCurrentPage } from "../utils/utils";
import CustomerPicker from "./CustomerPicker";

import "../style/generalUI.css";
//import { request } from "https";
import FormItem from "antd/lib/form/FormItem";
//import RadioGroup from "antd/lib/radio/group";
import LangContext from "../contextProvider/LangContext";



//const { Option, OptGroup } = Select;
//const { TextArea } = Input;

const topHeight = 200; // px
const rowHeight = 40;  // px

//type State = {}
class ModalCreateEditSubscribersCustomer extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            addType: 1,
            defaultItemCode: "", 
            itemCodeSelectionVisible: false 
        };
        
        this.id = "CUSTOMER_SUBSCRIBERS_LIST";

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }


        this.api = {
            get: "get_dealsActuals_subscribers_list",
            createEdit: "create_edit_customer_subscribers",
            // edit: "edit_tag_type",
            // delete: "delete_tag_type",
            // selector: "get_tag_relation_list", // TODO: tag type categories
            // selector_tags: "get_tag_types_with_relation_list",
        }

        this.actionButtons = [{
            //     buttonType: 'add',
            //     buttonFunction: this.handleCreateNew
            // }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }]

        this.modalData = []

        this.columns =
            [

            ];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //  document.title = "Cash On Tab - ";
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        // console.log('xxx-yuda ',numOfRowsByHeight);

        let request = {
            _id: 0,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        // this.props.dataActions.genericDataSetFilter(
        //     this.id,
        //     this.props.user.companyCode,
        //     this.props.user.token,
        //     request,
        //     this.api.get);
    }

    refreshData = () => {
        // this.props.dataActions.genericDataGetRequested(
        //     this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };

        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {

            let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
                ? (this.state.height - topHeight) / rowHeight
                : 1);


            let request = {
                _id: 0,
                page: 1,
                recordsPerPage: numOfRowsByHeight, //5,
                sortType: 1,
                sortColumn: 1,
                // api
                _page_num: 1,
                _rows_num: numOfRowsByHeight, //5,
                _isAsc: 1,
                _sort_by: 'col_1',
                _words: '',
            };


        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }


    handleCancel = () => {
        this.props.dataActions.genericDataCancelEditing(this.id);
        this.props.uiActions.hideModal(TableIDs.modalCreateEditSubscribersCustomer);
    }
    handleClear = () => {
        //this.clear();
        if (this.props.data[this.id].editing > -1) this.props.dataActions.genericDataCancelEditing(this.id);
    }

    render() {
        const { loading } = this.state;
        let request = {

            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.getText(10984)}
                    actionButtons={this.actionButtons}

                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        //console.log(this.props.data[this.id].data, ' xxx-yuda');
        let idStore = this.props.data[this.id];
        let dataSource = [];
        if (this.props.ui.data && this.props.ui.data['customerIndex'] > -1 && idStore.data[0]) {
            let dataCustomer = idStore.data[this.props.ui.data['customerIndex']];
            dataSource = dataCustomer ? dataCustomer.split("\f") : [];
        }
        // console.log('xxx-yuda ', dataSource);

        const { editedRecord, editing } = this.props.data[this.id];

        let valueToUse = (editedRecord.dRemain != dataSource[7] && editing > -1) ? (dataSource[7] - editedRecord.dRemain) * this.state.addType : 0


        let modalContent = (<Row>
            <Col span={12}>
                <h3>{this.getText(10985)}: {dataSource[1]}</h3>
                <h3>{this.getText(10986)}: {dataSource[2]}</h3>
                <h3>{this.getText(10987)}: {dataSource[3]}</h3>
                <h3>{this.getText(10988)}: {dataSource[5]}</h3>
                <h3>{this.getText(10989)}: {dataSource[6]}</h3>
                <h3>{this.getText(10990)}: {dataSource[7]}</h3>
            </Col>
            <Col span={12}>

                <FormItem>
                    <Radio.Group
                        value={this.state.addType}
                        onChange={e => { this.setState({ addType: e.target.value }) }}>
                        <Radio value={-1}>{this.getText(19642)}</Radio>
                        <Radio value={1}>{this.getText(19643)}</Radio>
                    </Radio.Group>
                </FormItem>
                <FormItem label={this.getText(this.state.addType == -1 ? 19644 : 10991)}>
                    <InputNumber
                        // min={0}  //max={dataSource[7]?dataSource[7]:0} 
                        step={1} precision={0}
                        value={valueToUse}
                        onChange={(value) => {
                            if (editing == -1 && this.props.ui.data) {
                                this.props.dataActions.genericDataStartEditing(this.id, dataSource[0], this.props.ui.data.customerIndex);
                            }
                            this.props.dataActions.genericDataSetEdit(this.id, { ['dRemain']: dataSource[7] - (value * this.state.addType) });
                        }} />
                </FormItem>
            </Col>
        </Row>)

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'50%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                // footer={false}
                footer={
                    [
                        <Button key="back" onClick={this.handleCancel}>{this.getText(10992)}</Button>,
                        <Button key="clear" loading={loading} onClick={this.handleClear}>{this.getText(10993)}</Button>,
                        <Popconfirm
                            title={this.getText(editedRecord['dRemain'] < dataSource[7] ? 16155 : 16156)}
                            okText={this.getText(16157)}
                            cancelText={this.getText(16158)}
                            onCancel={this.handleCancel}
                            onConfirm={() => {
                                if (editing > -1 && editedRecord['dRemain'] != dataSource[7]) {
                                    if (editedRecord['dRemain'] > editedRecord['dQuantity']) {
                                        message.error(this.getText(16159));
                                        this.handleClear();
                                    } else if (editedRecord['dRemain'] < 0) {
                                        message.error(this.getText(10996) + ': ' + dataSource[7]);
                                        this.handleClear();
                                    } else {
                                        this.props.dataActions.genericDataJustSaveEdit(
                                            this.id, this.props.user.companyCode, this.props.user.token, this.api.createEdit);
                                        message.success(this.getText(10995));
                                        this.handleCancel();
                                        this.props.dataActions.genericDataGetRequested(
                                            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
                                    }
                                } else {
                                    message.error(this.getText(10997));
                                }
                            }}>
                            <Button key="create" type="primary" loading={loading}  >{this.getText(10994)}</Button>
                        </Popconfirm>,
                        //<Button key="createLeave" disabled={!this.state.isValidItemCode}
                        // type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                    ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >

                <Form id="create_edit_customer_points_form" className="data-entry-form" layout='vertical' /*layout='horizontal' layout='inline'*/
                    style={{ columnWidth: 'auto', columnCount: '1' }}
                    onSubmit={this.handleSaveChanges}>
                    {modalContent}
                </Form>

            </Modal >
        )
    }

}

export default ModalCreateEditSubscribersCustomer;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import GenericTable from "../../components/GenericTable";
import LangContext from "../../contextProvider/LangContext";


class InterCard extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "INTER_CARD_TABLE";

        this.api = {
            get: "get_inter_card_table",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16819);
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        let sorter = true;
        let render = t => t;

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16820)}
                searchBox
                columns={[
                    { title: gt(16821), key: "_Id", dataIndex: "_Id", width: "10%", sorter, render },
                    { title: gt(16822), key: "_CardNumber", dataIndex: "_CardNumber", width: "15%", sorter, render },
                    { title: gt(16823), key: "_PhoneNumber", dataIndex: "_PhoneNumber", width: "15%", sorter, render },
                    { title: gt(16824), key: "_FirstName", dataIndex: "_FirstName", width: "15%", sorter, render },
                    { title: gt(16825), key: "_LastName", dataIndex: "_LastName", width: "15%", sorter, render },
                    { title: gt(16826), key: "_CreationDateTime", dataIndex: "_CreationDateTime", width: "15%", sorter, render },
                    { title: gt(16827), key: "_ClearDateTime", dataIndex: "_ClearDateTime", width: "15%", sorter, render },
                ]}
                filters={{ _number: 0 }}
                percentageToMobile={[0, 40, 0, 30, 30]}
            />
        </div>)
    }

}

export default InterCard;
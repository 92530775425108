/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
//import { Table, Icon, Select, InputNumber, Checkbox, Popconfirm, Button, Input, message } from "antd";
import { Select, Checkbox, Button, message } from "antd";
import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
//import ModalCreatePriceList from "../../components/ModalCreatePriceList";
import ModalCreateEditTagTypes from "../../components/ModalCreateEditTagTypes";
import ModalAttributeItemTags from "../../components/ModalAttributeItemTags";
import Jax from "../../Jax/jax.es6.module";
import { escapeRegExp, fixCurrentPage } from "../../utils/utils";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import RecyclingBin from "../../components/RecyclingBin";

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 40;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class TagTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "TAGS_TABLE";
        this.edit_id = "TAGS_TABLE_EDIT";
        this.recycling_id = "TAGS_DELETION_RECYCLING";

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.edit_id] === undefined) props.data[this.edit_id] = { ...props.data.genericData };
        if (props.data[this.recycling_id] === undefined) props.data[this.recycling_id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericMultiSelectorTagTypes] === undefined) {
            props.data[TableIDs.genericMultiSelectorTagTypes] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_tags_by_page",


            create: "create_new_tag",
            edit: "edit_tag",
            delete: "delete_tag",

            selector: "get_tag_types_with_relation_list",
            //modalPriceListGet: "Prices_List_v2",

            default: "update_default_item_tags",

            get_recycling: "get_tags_deletion_recycling",
            restoration: "restoration_tags",
        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: this.handleCreateNew
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];

        this.list = [];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //document.title = "Cash On Tab - תגיות";
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector]
        });

        // this.props.dataActions.genericMultiSelectorRefreshDataset(
        //     TableIDs.genericMultiSelectorTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector);


        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10173);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,//5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,//5,
            _isAsc: 1,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector);

    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            //fall through
            case "mId": col--;
            //fall through
            case "mTypeId": col--;
            //fall through
            case "mName": col--;
            //fall through
            case "mIsActive": break; // 4
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            // _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.uiActions.showModal(TableIDs.modalAttributeItemTags, { tID: 0 });
        // this.props.dataActions.genericDataStartInlineCreate(this.id);
        // this.props.dataActions.genericDataSetEdit(this.id, { mIsActive: 1 });
        this.props.dataActions.setJson(this.edit_id, {
            data: ['0\f\f\f\f'],
            editedRecord: {
                key: 0,
                mID: "",
                mIsActive: 1,
                mName: "",
                mTypeId: ""
            },
            editing: '0',
            headers: this.props.data[this.id].headers
        });
    }

    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        // console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal === 1 || checkedVal === "1");
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        Jax.get(this.props.data, TableIDs.genericSelectorItemGroup + ".dataset", [])

        let rawList = Jax.get(this.props.data, TableIDs.genericMultiSelectorTagTypes + ".dataset", []);
        let list = rawList.map(
            (node: Object, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )
        this.list = list;

        let columns = [
            {
                title: this.getText(10406),
                dataIndex: "mId",
                key: "mId",
                sorter: true,
                width: '7%',
                render: (text, record) => text,
            }, {
                title: this.getText(10407),
                dataIndex: "mName",
                key: "mName",
                width: '45%',
                sorter: true,
                render: (text, record) => text
            }, {
                title: this.getText(10408),
                dataIndex: "mTypeId",
                key: "mTypeId",
                width: '30%',
                sorter: true,
                render: (text, record) => {
                    return (
                        <Select
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            optionFilterProp="children"
                            placeholder={this.getText(10417)}
                            disabled={!(this.props.data[this.id].editing === record.key)}
                            value={(this.props.data[this.id].editing === record.key) ?
                                this.props.data[this.id].editedRecord['mTypeId'] : text}
                            defaultValue='none'
                            style={{ width: '100%', paddingLeft: '4px' }}
                            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { mTypeId: e }) }}
                        >
                            {this.list}
                        </Select>
                    )
                }
            }, {
                title: this.getText(10409),
                dataIndex: "mIsActive",
                key: "mIsActive",
                width: '10%',
                sorter: true,
                render: (text, record) => {
                    return (<Checkbox
                        onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsActive", record); }}
                        checked={this.checkIfChecked("mIsActive", text, record)}
                        defaultChecked={false}
                        disabled={true/*!(this.props.data[this.id].editing === record.key)*/}
                    />)
                },
            }, {
                title: this.getText(10410),
                width: '8%',
                render: (text, record) => {

                    let props = {
                        onEdit: () => {
                            this.props.uiActions.showModal(TableIDs.modalAttributeItemTags, { tID: record.key });
                            this.props.dataActions.setJson(this.edit_id, {
                                data: [this.props.data[this.id].data[record.index]],
                                editedRecord: record,
                                editing: record.key,
                                headers: this.props.data[this.id].headers
                            });
                        },
                        onDelete: () => {
                            fixCurrentPage.bind(this)();
                            this.props.dataActions.genericDataDeleteRow(
                                this.id,
                                record['mId'],
                                this.props.user.companyCode,
                                this.props.user.token,
                                this.api.delete,
                                this.api.get);
                        },
                        deleteQuetion: { title: this.getText(10411), okText: this.getText(10412), cancelText: this.getText(10413) },
                        genericIcons: [{
                            type: 'setting',
                            onClick: () => { this.sendAPI(this.api.default, record.key, () => { message.success(this.getText(15367)) }) },
                            tooltip: this.getText(15365),
                            popconfirm: { title: this.getText(15366), okText: this.getText(10412), cancelText: this.getText(10413) },
                        }],
                    }

                    return (<TableLineIcons {...props} />)
                    // <span>
                    //     <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                    //         // this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                    //         this.props.uiActions.showModal(TableIDs.modalAttributeItemTags, { tID: record.key });
                    //         this.props.dataActions.setJson(this.edit_id, {
                    //             data: this.props.data[this.id].data[record.index],
                    //             editedRecord: record,
                    //             editing: record.key,
                    //             headers: this.props.data[this.id].headers
                    //         });
                    //     }} />
                    //     <Popconfirm title={this.getText(10411)}
                    //         onConfirm={() => {
                    //             // console.log("record", record);
                    //             fixCurrentPage.bind(this)();
                    //             this.props.dataActions.genericDataDeleteRow(
                    //                 this.id,
                    //                 record['mId'],
                    //                 this.props.user.companyCode,
                    //                 this.props.user.token,
                    //                 this.api.delete,
                    //                 this.api.get);
                    //         }
                    //         }
                    //         okText={this.getText(10412)} cancelText={this.getText(10413)}>
                    //         <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                    //     </Popconfirm>
                    // </span >);
                }
            }
        ];

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mTypeId: y[1],
                mName: y[2],
                mIsActive: y[3],
                mVSImage: y[4],
                mVSDescription: y[5],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        return (
            <div/* style={divStyle}*/>
                <PageToolbar
                    title={this.getText(10414)}
                    actionButtons={this.actionButtons}
                    genericActionButtons={[
                        <RecyclingBin
                            {...this.props}
                            id={this.recycling_id}
                            getApi={this.api.get_recycling}
                            restorationApi={this.api.restoration}
                            title={this.getText(16888)}
                            columnTitles={[this.getText(16889), this.getText(16890), this.getText(16891), this.getText(16892), this.getText(16893)]}
                            popconfirmText={{ title: this.getText(16894), okText: this.getText(16895), cancelText: this.getText(16896) }}
                            onClose={this.refreshData}
                        />
                    ]}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: escapeRegExp(val),
                                // .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <Select
                    //disabled={!(this.props.data[this.id].editing === record.key)}
                    showsearch
                    filterOption={true}
                    optionFilterProp="valuefilter"
                    value={this.props.data[this.id].filters['_id']}
                    defaultValue='none'
                    style={{ width: '256px', padding: '4px' }}
                    onChange={(e) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            { _id: e, _page_num: 1, page: 1 }, this.api.get)
                    }}>
                    <Option key={0} value={0}>-{this.getText(10415)}-</Option>
                    {list}
                </Select>

                <Button key="showModal" type="primary"
                    onClick={() => { this.props.uiActions.showModal(TableIDs.modalCreateEditTagTypes) }}>{this.getText(10416)}</Button>

                <ResponsiveTable
                    dataSource={dataSource}
                    tableOnly={true}
                    idTable={this.props.data[this.id]}
                    percentageToMobile={[false, 30, 70]}
                    columns={columns}
                    ui={this.props.ui}
                    onChange={this.handleTableChange}
                />

                <ModalCreateEditTagTypes
                    id={TableIDs.modalCreateEditTagTypes}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditTagTypes)}
                />
                <ModalAttributeItemTags
                    id={TableIDs.modalAttributeItemTags}
                    onClose={this.refreshData}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    ActionQueue={this.props.ActionQueue}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalAttributeItemTags)}
                />
            </div>
        );
    }
}

export default TagTable;


//ProductivityReport

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Button, Form, Select, Checkbox, Radio } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import { disabledDatePicker } from "../../utils/utils";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class ProductivityReport extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            ProductivityReport: {
                FromDate: null,
                ToDate: null,
                EmployeeList: '',
                BranchList: '',
                TagList: '',
                TagRelation: '1',
                isBranchCentral: '0',
                sortField: '1',
                // StorageList: undefined,
                InBranch: '1',
                TimeFormat: '1',
                HourDetails: '0',
                ProfitCalcUp: '1',
                zeroCostItems: '1',
                reportName: 'reports/ProductivityReport.pdf'
            }
        };

        // if (props.data[TableIDs.genericSelectorEmployees] === undefined) props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        // if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };
        // if (props.data[TableIDs.genericSelectorStorages] === undefined) props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };

        // this.api = {
        //     selector_employee: "get_employee_list",
        //     selector_branch: "get_branch_list",
        //     // selector_storages: "get_storage_list",
        // }

        this.selectors = [
            { id: TableIDs.genericSelectorEmployees, api: "get_employee_list" },
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" },
            { id: TableIDs.genericMultiSelectorItemTags, api: "get_tags_with_types", optionsGroup: true },
        ];

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

        // this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employee);
        // this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorBranches, this.props.user.companyCode, this.props.user.token, this.api.selector_branch);
        // this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    // componentDidMount() {
    //     this.loadData();
    // }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10169);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("ProductivityReport", this.props.ui.favoriteParams);
        }
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    // generateReport(report: string) {
    //     let params = Jax.get(this.state || {}, report, null);
    //     if (params !== null && params.reportName) {
    //         window.console.log("Generating report...");
    //         params = {
    //             ...params,
    //             reportName: params.HourDetails > 0 ?
    //                 "reports/ProductivityReportDetails." + params.reportName.split(".")[1] :
    //                 params.reportName,
    //             REPORT_LOCALE: this.getText(101)
    //         }
    //         this.props.ActionQueue.addToQueue({
    //             action: this.props.dataActions.generateReport,
    //             args: [this.props.user.companyCode, this.props.user.token, params]
    //         });
    //     }
    // }

    // loadData() {
    //     console.log(this.props);
    //     this.props.ActionQueue.addToQueue({
    //         action: this.props.dataActions.genericSelectorRefreshDataset,
    //         args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employee]
    //     });
    //     this.props.ActionQueue.addToQueue({
    //         action: this.props.dataActions.genericSelectorRefreshDataset,
    //         args: [TableIDs.genericSelectorBranches, this.props.user.companyCode, this.props.user.token, this.api.selector_branch]
    //     });
    //     // this.props.ActionQueue.addToQueue({
    //     //     action: this.props.dataActions.genericSelectorRefreshDataset,
    //     //     args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
    //     // });
    // }

    disabledStartDate = (FromDate) => {
        const ToDate = this.state.ToDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.FromDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    onChange = (field, value) => {
        this.setReportParameters("ProductivityReport", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('FromDate', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('ToDate', value ? value.format("YYYY-MM-DD") : null);
    }

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }

    // createCheckBox = (field, data) => {
    //     return (
    //         <Checkbox
    //             style={{ display: 'inline' }}
    //             onChange={(e) => {
    //                 if (e.target.checked)
    //                     this.setReportParameters("ProductivityReport", { [field]: data });
    //                 else
    //                     this.setReportParameters("ProductivityReport", { [field]: '' });
    //             }} />);
    // }

    createRadioGroup = (label, field, yesText, noText, options) => {
        return (<FormItem label={this.getText(label)}>
            <RadioGroup
                value={this.state.ProductivityReport[field]}
                onChange={(e) => {
                    let newParam = { [field]: e.target.value }
                    if (field === "InBranch" && e.target.value != '1') newParam = { ...newParam, isBranchCentral: '0' }
                    this.setReportParameters("ProductivityReport", newParam)
                }}>
                {options ?
                    options.map((x, i) => (<Radio value={i.toString()}>{this.getText(x)}</Radio>))
                    : [
                        <Radio value={'1'}>{this.getText(yesText)}</Radio>,
                        <Radio value={'0'}>{this.getText(noText)}</Radio>
                    ]}
            </RadioGroup>
        </FormItem >)
    }

    createSelector = (index, field, label, placeholder) => {
        let s = this.state.ProductivityReport;
        let props = {
            ...this.props,
            multi: true,
            placeholder: this.getText(placeholder),
            value: s[field] ? s[field].split(',') : [],
            onChange: (value) => this.setReportParameters("ProductivityReport", { [field]: value ? value.join(',') : '' }),
        }
        return (<FormItem label={this.getText(label)}>
            {field === 'TagList' ? (<GenericSelectorItemTags {...props} />) : (<GenericSelector {...props}  {...this.selectors[index]} />)}
        </FormItem>)
    }

    render() {
        const { FromDate, ToDate } = this.state.ProductivityReport;
        const { endOpen } = this.state;
        const mobile = window.innerWidth > 600 ? false : true;
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        // let lists = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => {
        //     return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        // });

        // let branchLists = Jax.get(this.props.data, TableIDs.genericSelectorBranches + ".dataset", []).map((obj, index) => {
        //     return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        // });

        // let storageLists = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
        //     return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        // });

        let params = { ...this.state.ProductivityReport }
        // if (params.isBranchCentral == 1) {
        //     if (params.HourDetails > 0) {
        //         params = { ...params, reportName: "reports/ProductivityReportCentralDetails.pdf" }
        //     } else {
        //         params = { ...params, reportName: "reports/ProductivityReportCentral.pdf" }
        //     }
        // } else {
        if (params.HourDetails > 0) {
            params = { ...params, reportName: "reports/ProductivityReportDetails.pdf" }
        }
        // }

        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(11158)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: '95%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: '1' }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <Row style={{ marginTop: !mobile ? 0 : 50 }}>

                            <Col span={mobile ? 24 : 8}>

                                {this.createRadioGroup(14183, "InBranch", 14185, 14186)}
                                {this.state.ProductivityReport.InBranch == 1 ?
                                    (<FormItem>
                                        <Checkbox
                                            checked={this.state.ProductivityReport.isBranchCentral == 1}
                                            onChange={(value) => {
                                                this.setReportParameters("ProductivityReport", { isBranchCentral: value.target.checked ? '1' : '0' })
                                            }}>{this.getText(16705)}</Checkbox>
                                    </FormItem>)
                                    : ""}
                                {this.createRadioGroup(14184, "TimeFormat", 14187, 14188)}
                                {this.createRadioGroup(14191, "HourDetails", 0, 0, [14192, 14193, 14194, 16744])}
                                {this.state.ProductivityReport.HourDetails == 0 ?
                                    this.createRadioGroup(16706, "sortField", 16707, 16708)
                                    : ""}
                                {this.createRadioGroup(17118, "ProfitCalcUp", 17119, 17120)}
                            </Col>
                            <Col span={mobile ? 24 : 8}>
                                {this.createSelector(0, 'EmployeeList', 11159, 11167)}
                                {this.createSelector(1, 'BranchList', 14189, 14190)}
                                {this.createSelector(2, 'TagList', 16703, 16704)}

                                <TagRelationRadio showed={this.state.ProductivityReport.TagList}
                                    onChange={(value) => { this.setReportParameters("ProductivityReport", { TagRelation: value ? '1' : '0' }) }}
                                    checked={this.state.ProductivityReport.TagRelation == 1}
                                />
                                <FormItem label={this.getText(18276)}>
                                    <Select
                                        style={{ width: 250 }}
                                        value={this.state.ProductivityReport.zeroCostItems}
                                        onChange={(e) => { this.setReportParameters("ProductivityReport", { zeroCostItems: e }) }}>
                                        <Option value={"1"}>{this.getText(18277)}</Option>
                                        <Option value={"0"}>{this.getText(18278)}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={mobile ? 0 : 2}>
                            </Col>
                            <Col span={mobile ? 24 : 6}>
                                <FormItem label={this.getText(11160)}>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, null, ToDate)}
                                        //  disabledDate={this.disabledStartDate}
                                        // showTime
                                        format="DD-MM-YYYY" //"YYYY-MM-DD"
                                        value={FromDate ? moment(FromDate, "YYYY-MM-DD") : null}
                                        placeholder="Start"
                                        onChange={this.onStartChange}
                                        onOpenChange={this.handleStartOpenChange}
                                    />
                                </FormItem>
                                <FormItem label={this.getText(11161)}>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, FromDate, null)}
                                        // disabledDate={this.disabledEndDate}
                                        // showTime
                                        format="DD-MM-YYYY" //"YYYY-MM-DD"
                                        value={ToDate ? moment(ToDate, "YYYY-MM-DD") : null}
                                        placeholder="End"
                                        onChange={this.onEndChange}
                                        open={endOpen}
                                        onOpenChange={this.handleEndOpenChange}
                                    />
                                </FormItem>
                                <ReportTypeButton
                                    {...this.props}
                                    params={params}
                                    branchesField={'BranchList'}
                                    datesToFavorites={[
                                        { field: 'FromDate', label: this.getText(11160) },
                                        { field: 'ToDate', label: this.getText(11161) },
                                    ]}
                                    favoriteSave
                                />
                            </Col>
                        </Row>
                        {/* <FormItem label={this.getText(11159)} >
                            {this.createCheckBox('EmployeeList', Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"EmployeeList"} style={{ width: "90%" }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={5}
                                placeholder={this.getText(11167)}
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("ProductivityReport", { EmployeeList: value.join(',') })}
                                value={(this.state.ProductivityReport.EmployeeList === ''
                                    || this.state.ProductivityReport.EmployeeList === undefined) ? [] : this.state.ProductivityReport.EmployeeList.split(',')}
                            //notFoundContent={"לא נמצאו עובדים"}       
                            >
                                {lists}
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(14189)}>
                            {this.createCheckBox('BranchList', Jax.get(this.props.data, TableIDs.genericSelectorBranches + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"BranchList"} style={{ width: "90%" }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={5}
                                placeholder={this.getText(14190)}
                                onChange={(value) => this.setReportParameters("ProductivityReport", { BranchList: value.join(',') })}
                                value={(this.state.ProductivityReport.BranchList === ''
                                    || this.state.ProductivityReport.BranchList === undefined) ? [] : this.state.ProductivityReport.BranchList.split(',')}
                            >
                                {branchLists}
                            </Select>
                        </FormItem> */}

                        {/* <FormItem label={this.getText(12987)}>
                            {this.createCheckBox('StorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"StorageList"} style={{ width: "90%" }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={5}
                                placeholder={this.getText(12988)}
                                onChange={(value) => this.setReportParameters("ProductivityReport", { StorageList: value.join(',') })}
                                value={(this.state.ProductivityReport.StorageList === ''
                                    || this.state.ProductivityReport.StorageList === undefined) ? [] : this.state.ProductivityReport.StorageList.split(',')}
                            >
                                {storageLists}
                            </Select>
                        </FormItem> */}
                        {/* 
                        <FormItem key={"reportName"} label={this.getText(11162)}>
                            <Select style={{ width: 250 }}
                                //size={"large"}
                                className={"reports/ProductivityReport.pdf"}
                                //combobox={null}
                                defaultValue={"reports/ProductivityReport.pdf"}
                                onChange={(value) => this.setReportParameters("ProductivityReport", { reportName: value })}
                            >
                                <Option key={"reports/ProductivityReport.pdf"}>{this.getText(11163)}</Option>
                                <Option key={"reports/ProductivityReport.xls"}>{this.getText(11164)}</Option>
                                <Option key={"reports/ProductivityReport.docx"}>{this.getText(11165)}</Option>
                            </Select>
                        </FormItem>
                        <Button type={"primary"} onClick={() => { this.generateReport.apply(this, ["ProductivityReport"]) }}>{this.getText(11166)}</Button> */}


                    </Form>
                </Card>
            </div>
        );
    }
}
export default ProductivityReport;

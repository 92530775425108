/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Card, Modal, Row, Col, Checkbox, Radio } from "antd";
import moment from "moment";
import { Pie, Bar } from "ant-design-pro/lib/Charts";
import TimelineChart2 from "../../components/TimelineChart";
import Nis from "../../components/Nis";
import LangContext from "../../contextProvider/LangContext";
import "../Dashboard.css";

class SegmentationSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isMonthlySalesGraphExtended: false,
            isHourlySalesGraphExtended: false,
            isPaymentsGraphExtended: false,
            stateMobile: 'tab1',


        }

        this.shortPos_id = "SELECTOR_SHORT_POS";
        this.catering_id = "CATERING_TICKETS_DATA";

        if (props.data[this.shortPos_id] === undefined) props.data[this.shortPos_id] = { ...props.data.genericSelector }
        if (props.data[this.catering_id] === undefined) props.data[this.catering_id] = { ...props.data.genericData }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.shortPos_id, this.props.user.companyCode, this.props.user.token, "list_short_pos");
    }



    getCard = (title, content, stateName) => {
        let isExtended = this.state[stateName];

        let footer = (<Button
            htmlType={"button"} style={{ marginTop: 8 }}
            onClick={() => { this.setState({ [stateName]: !isExtended }) }}
        >{this.getText(isExtended ? 10134 : 10122)}</Button>);

        return (<Col xl={8} lg={12} md={24} sm={24} xs={24} style={{ marginBottom: "24px" }}>
            <Card loading={this.props.isLoading} bordered={false} title={title} bodyStyle={{ padding: 24, direction: "ltr" }}>
                {content}{footer}
            </Card>
        </Col>)
    }

    getModal = (title, content, stateName) => {
        return (<Modal title={title} centered onCancel={() => { this.setState({ [stateName]: false }) }} visible={this.state[stateName]} footer={null} width={800}>
            {content}
        </Modal>)
    }

    getShortNamePos = (num) => {
        let shortPos = this.props.data[this.shortPos_id].dataset.find(f => f.code == num);
        return shortPos ? shortPos['name'] : false
    }

    render() {
        const { monthly, pos, hourly, payments } = this.props.apiData;
        let gt = this.getText;
        let isPerPos = this.props.graphHoursPerPos == 1;
        let hourlyData = hourly.map((x) => {
            let k = Object.keys(x);
            let obj = { ...x }
            let y1 = 0;
            k.forEach((y) => {
                if (y !== 'x') {
                    let value = (obj[y] < 0) ? 0 : Math.round(obj[y])
                    obj[y] = value;
                    y1 += value;
                }
            });
            return isPerPos ? obj : { x: obj.x, y1 };
        });

        let countHourly = 1;
        let posLegend = { y1: '' };

        if (isPerPos) {
            countHourly = pos.length;
            posLegend = {};

            pos.forEach((x, i) => {
                let shortName = this.getShortNamePos(x.key);
                posLegend["y" + (i + 1)] = shortName ? shortName : gt(10061) + " " + x.key
            });
        }

        let cateringDataParTag = [];

        let cateringData = this.props.data[this.catering_id] ?
            this.props.data[this.catering_id].data.map(((x) => {
                let y = x.split('\f');
                let findTag = cateringDataParTag.findIndex(f => f.tag == y[2])
                if (findTag > -1) {
                    cateringDataParTag[findTag] = {
                        ...cateringDataParTag[findTag],
                        total: cateringDataParTag[findTag].total + parseFloat(y[1]),
                        data: [...cateringDataParTag[findTag].data, { x: y[0], y: parseFloat(y[1]) }]
                    }
                } else {
                    cateringDataParTag.push({
                        tag: y[2],
                        tagName: y[3],
                        total: parseFloat(y[1]) ?? 0,
                        data: [{ x: y[0], y: parseFloat(y[1]) }]
                    })
                }
                return { x: y[0], y: parseFloat(y[1]), tag: y[2], tagName: y[3] };
            })) : [];

        let piePaymentData = []

        if (this.props.viewPaysType != 2) piePaymentData = [...payments]
        if (this.props.viewPaysType != 1) piePaymentData = [...piePaymentData, ...cateringData]

        let paymentTotal = payments.reduce((pre, now) => now.y + pre, 0);
        let piePaymentTotal = piePaymentData.reduce((pre, now) => now.y + pre, 0);

        let monthlyContent = (height) => (<Bar height={height} title={""} data={monthly.map(x => { return { ...x, y: Math.round(x.y) } })} />)
        let hourlyContent = (height) => (<TimelineChart2 height={height} data={hourlyData} yCount={countHourly} titleMap={posLegend} />)
        let paymentsContent = (height, data, subTitle, total) => {
            return (<div style={{ direction: "ltr" }}>
                <Pie
                    hasLegend subTitle={subTitle} data={data}
                    total={() => <Nis>{total}</Nis>}
                    valueFormat={value => <Nis>{value}</Nis>}
                    height={height} lineWidth={4} style={{ padding: '8px 0' }} />

            </div>)
        }

        let paySmall = (<div style={{ direction: "rtl" }}>
            <Radio.Group value={this.props.viewPaysType} onChange={this.props.viewPaysTypeChange}>
                <Radio value={'0'}>{this.getText(19334)}</Radio><br />
                <Radio value={'1'}>{this.getText(19335)}</Radio><br />
                <Radio value={'2'}>{this.getText(19336)}</Radio>
            </Radio.Group>
            {paymentsContent(270, piePaymentData, gt(10137), piePaymentTotal)}
        </div>);

        let payModal = (<div>
            {paymentsContent(500, piePaymentData, gt(10137), piePaymentTotal)}
            {this.props.viewPaysType == 0 ? paymentsContent(270, payments, "תקבולים", paymentTotal) : ""}
            {this.props.viewPaysType != 1 ? cateringDataParTag.map(x => paymentsContent(270, x.data, x.tagName, x.total)) : ""}

        </div>)

        let mobile = window.innerWidth <= 600;
        const { stateMobile } = this.state;

        return mobile ?
            (<Card
                style={{ marginBottom: 20, direction: "ltr" }}
                loading={this.props.isLoading}
                bordered={false}
                bodyStyle={{ padding: 24, direction: "ltr" }}
                activeTabKey={stateMobile}
                onTabChange={key => { this.setState({ stateMobile: key }) }}
                title={stateMobile === 'tab1' ? gt(10133) : stateMobile === 'tab2' ? gt(10135) : stateMobile === 'tab3' ? gt(10136) : ""}
                tabList={[
                    { key: 'tab1', tab: gt(16959) },
                    { key: 'tab2', tab: gt(16960) },
                    { key: 'tab3', tab: gt(16961) },
                ]}>
                {stateMobile === 'tab1' ? monthlyContent(295) : stateMobile === 'tab2' ? hourlyContent(265) : stateMobile === 'tab3' ? paySmall : ""}
            </Card>)
            :
            (<Row gutter={24}>
                {this.getCard(gt(10133), monthlyContent(295), 'isMonthlySalesGraphExtended')}
                {this.getCard(gt(10135), hourlyContent(265), 'isHourlySalesGraphExtended')}
                {this.getCard(gt(10136), paySmall, 'isPaymentsGraphExtended')}

                {this.getModal(gt(10150), monthlyContent(600), 'isMonthlySalesGraphExtended')}
                {this.getModal(gt(10151), hourlyContent(500), 'isHourlySalesGraphExtended')}
                {this.getModal(gt(10136), payModal, 'isPaymentsGraphExtended')}
            </Row>);

    }
}
export default SegmentationSection;

/* @flow */
/*jshint esversion: 6 */
import { Form } from "antd";
import { Row, Col, DatePicker } from "antd";
import React, { Component } from "react";
import "../../App.css";
import GenericSelector from "../../components/GenericSelector";
import ReportTypeButton from "../../components/ReportTypeButton";
import DateTimePicker from "../../components/DateTimePicker";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";


class VisitsByDays extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _fromDate: null,
            _toDate: null,
            _branchList: '',
            reportName: "reports/VisitsByDays.pdf"
        };

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(18075);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 700,
            margin: 'auto'
        };

        const { _fromDate, _toDate, _branchList } = this.state;

        return (<div style={divStyle}>
            <PageToolbar title={gt(18079)} actionButtons={[]} />
            <Row>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18076)}>
                        <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_fromDate}
                            onChange={e => { this.setState({ _fromDate: e }) }}
                            maxDate={_toDate}
                        />
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18077)}>
                    <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_toDate}
                            onChange={e => { this.setState({ _toDate: e }) }}
                            minDate={_fromDate}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label={gt(18078)}>
                <GenericSelector
                    {...this.props}
                    id={TableIDs.genericSelectorBranches}
                    api={"get_branch_list"}
                    multi
                    value={_branchList ? _branchList.split(",") : []}
                    onChange={e => this.setState({ _branchList: e ? e.join(",") : "" })}
                />
            </Form.Item>
            <ReportTypeButton
                {...this.props}
                params={this.state}
                branchesField={"_branchList"}
                datesToFavorites={[
                    { field: '_fromDate', label: gt(18076) },
                    { field: '_toDate', label: gt(18077) },
                ]}
                favoriteSave
            />
        </div>)
    }

}

export default VisitsByDays;
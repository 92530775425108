/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import SuppliersTable from "./SuppliersTable";

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    data: any,
    dataActions: any,
};
type State = {};

class SuppliersTableIndex extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    // componentDidMount() {
    //     document.title = "Cash On Tab - רשימת פריטים";
    // }

    render() {
        var divStyle = {
            align: 'center',
            dir: 'rtl', // dir: 'ltr'
            padding: '2% 2% 2% 2%',
            //whiteSpace: 'unset',
        }

        return (
            <div style={divStyle}>
                <SuppliersTable
                    user={this.props.user}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                    ActionQueue={this.props.ActionQueue}
                />
            </div>
        );
    }
}
export default SuppliersTableIndex;

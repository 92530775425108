/* @flow */
/*jshint esversion: 6 */
import React from "react";
import "../App.css";
import { Button, Form } from "antd";
import GenericModal from "./GenericModal";

type Props = {
    footerBtn: [{ type: String, onClick: Function, text: String }],
    formItems: [{ label: String, content: Object }],
    columnCount: Number,
}

class GenericModalForm extends React.Component<Props> {

    render() {
        return (<GenericModal
            {...this.props}
            footer={this.props.footerBtn?.map(x => (<Button {...x}>{x.text}</Button>))}>
            <Form
                className="data-entry-form"
                layout='horizontal'
                style={{
                    columnWidth: 'auto',
                    columnCount: window.innerWidth > 600 ? (this.props.columnCount ?? 3) : 1
                }}>
                {this.props.formItems?.map(x => (<Form.Item label={x.label}>{x.content}</Form.Item>))}
            </Form>
        </GenericModal>)
    }
}
export default GenericModalForm;
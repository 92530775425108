/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import { Card, InputNumber, Input, Checkbox, Form, DatePicker, Select, Button, message, Spin } from "antd";
import "../../App.css";
import moment from "moment";
import TableIDs from '../../data/TableIDs.js';
import PictureView from "../../components/PictureView";
import FormLinkStyle from "../../forms/FormLinkStyle";
import QuestionnaireForm from "../../forms/QuestionnaireForm";
import SignatureInput from "../../components/SignatureInputV2"
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags.js";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

class SelfCreateCustomer extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            termToggle: false,
            isTermOk: false,
            loading: false,
        }

        this.id = "SELF_CREATE_CUSTOMER_FULL";
        // this.data_id = "SELF_CREATE_CUSTOMER_DATA";
        this.edit_id = "SELF_CREATE_CUSTOMER_EDIT";
        this.view_id = "SELF_CREATE_CUSTOMER_VIEW";
        this.comp_id = "COMPANY_DETAILS_DATA";
        this.declar_id = "CUSTOMER_PORTAL_DECLARATIONS";
        this.qq_id = "QUESTIONNAIRE_QUESTIONS_LIST";

        this.api = {
            get_format: "get_customer_fields_full",
            get_data: "get_customer_data",
            edit: "set_customer_v5",
            selector: "get_customer_tags_list",
            selector_price_list: "get_prices",
            selector_titles: "get_customer_titles",
            get_details: "get_company_details_to_form",
            delete: "delete_form_token",
            delete_a: "delete_form_alternate_token",
            firebase_token: "save_firebase_from_customer",

            get_declar: "get_customer_portal_declarations",

            save_qa: "set_questionnaire_answers",

            coupons: "coupons_for_single_customer_v2",

        }

        this.params = this.props.forms.formsFunctions.getParams();
        this.formsFunctions = this.props.forms.formsFunctions;

        if (props.forms[this.id] === undefined) props.forms[this.id] = {}
        if (props.forms[this.edit_id] === undefined) props.forms[this.edit_id] = {}
        if (props.forms[this.view_id] === undefined) props.forms[this.view_id] = { data: [] }
        if (props.forms[this.comp_id] === undefined) props.forms[this.comp_id] = {}
        if (props.forms[this.declar_id] === undefined) props.forms[this.declar_id] = {}
        if (props.forms[this.qq_id] === undefined) props.forms[this.qq_id] = { data: [], qID: null }

        if (props.forms[TableIDs.genericMultiSelectorCustomerTags] === undefined) props.forms[TableIDs.genericMultiSelectorCustomerTags] = { dataset: [] }
        if (props.forms[TableIDs.genericSelectorPriceList] === undefined) props.forms[TableIDs.genericSelectorPriceList] = { dataset: [] }
        if (props.forms[TableIDs.genericSelectorCustomerTitles] === undefined) props.forms[TableIDs.genericSelectorCustomerTitles] = { dataset: [] }
    }

    getText = (id) => {
        let data = this.props.forms.langTexts;
        return data && data["t" + id] ? data["t" + id] : '[' + id + ']'
    }

    componentDidMount() {
        this.getFormData(this.api.get_format, { rID: 3 }, this.filterFields);
        this.getFormData(this.api.selector, {}, this.filterSelectorCustomerTags);
        this.getFormData(this.api.selector_price_list, {}, this.filterSelectorPriceList);
        this.getFormData(this.api.selector_titles, {}, this.filterSelectorTitles);
        this.getFormData(this.api.get_details, {}, this.getCompanyDetails);
        this.getFormData(this.api.get_declar, { _id: 1 }, this.getDeclarList);
    }

    componentDidUpdate(prev) {
        let compData = this.props.forms[this.comp_id];
        let companyName = compData && compData.name ? compData.name : "";
        document.title = companyName + " - " + this.getText(90001);
    }

    filterFields = (fullData) => {
        let headers = fullData[0].split("\f");
        let perentData = [];
        let count = parseInt(fullData[fullData.length - 1]);
        fullData.map((x, i) => {
            if (i > 0 && i < (fullData.length - 1)) {
                let z = x.split("\f");
                let perentObj = {}
                headers.map((y, ii) => { perentObj = { ...perentObj, [y]: z[ii] } })
                perentData.push(perentObj)
            }
        });

        let d = {
            headers: headers,
            data: perentData,
            count: count
        }

        let editedRecord = { key: 0 }

        perentData.map((x) => {
            let keyName = x.CustomerFieldName;
            let defValue = x.CustomerFieldRoleListDefaultValue;

            //////////////////
            ///// שותל מספר קופה בשדה חופשי מספר שלם
            if (keyName === 'CustomerFreeNum1' && this.params && this.params.code === 'eb06' && this.params.pos) {
                defValue = this.params.pos
            }

            if (keyName === 'CustomerPosCreation' && this.params && this.params.pos) {
                defValue = this.params.pos
            }

            if (x.CustomerFieldControlType === "5" && defValue === 'NOW') {
                defValue = moment().format("YYYY-MM-DD");
            }
            //////////////////
            if (keyName) {
                editedRecord = { ...editedRecord, [keyName]: defValue }
            }
        })
        let containers = [];
        let formFields = [];
        perentData.map((x) => {
            let num = parseInt(x.CustomerFieldContainer);
            let isTitle = x.CustomerFieldControlType == "0" ? true : false;
            let isActive = x.CustomerFieldIsActive == "1" ? true : false;
            let indexContainer = containers.indexOf(num);
            if (isActive || isTitle) {
                if (indexContainer === -1) {
                    let toPush = isTitle ? { title: x, fields: [] } : { title: {}, fields: [x] }
                    containers.push(num);
                    formFields.push(toPush);
                } else {
                    let toPush = isTitle ? { title: x } : { fields: [...formFields[indexContainer].fields, x] }
                    formFields[indexContainer] = { ...formFields[indexContainer], ...toPush }
                }
            }
        });

        let formParams = this.props.forms.formData ? this.props.forms.formData.params : ""

        let data = formParams.split('&');
        let mobile = ""
        data.map((x) => {
            let y = x.split("=");
            if (y[0] === "mobile") mobile = y[1]
        });

        this.getFormData(this.api.get_data, { _mobile: mobile }, (fullData) => {

            if (fullData[1]) {
                editedRecord = {}
                let headers = fullData[0].split("\f");
                let data = fullData[1].split("\f");
                headers.map((x, i) => {
                    editedRecord = { ...editedRecord, [x]: data[i] }
                })
            }

            editedRecord = { ...editedRecord, CustomerContactPersonMobile: mobile }

            this.props.formsActions.setJson(this.id, d);
            this.props.formsActions.setJson(this.edit_id, editedRecord);
            this.props.formsActions.setJson(this.view_id, { data: formFields });
        });
    }

    filterEditFields = (fullData) => {

        let obj = {}
        let headers = fullData[0].split("\f");
        let data = fullData[1] ? fullData[1].split("\f") : [];
        headers.map((x, i) => {
            obj = { ...obj, [x]: data[i] ? data[i] : "" }
        })

        this.props.formsActions.setJson(this.edit_id, obj);
    }

    filterSelectorCustomerTags = (data) => {
        let types = [];
        let dataset = [];
        data.map((x) => {
            let y = x.split("\f");
            let indexType = types.indexOf(y[0]);
            if (indexType === -1) {
                types.push(y[0])
                dataset.push({
                    tags: [{ id: y[1], text: y[2] }],
                    type: { id: y[0], text: y[3] }
                });
            } else {
                dataset[indexType] = {
                    ...dataset[indexType],
                    tags: [...dataset[indexType].tags, { id: y[1], text: y[2] }]
                }
            }
        })

        this.props.formsActions.setJson(TableIDs.genericMultiSelectorCustomerTags, { dataset: dataset });
    }

    filterSelectorPriceList = (data) => {
        let dataset = [];
        data.map((x) => {
            let y = x.split("\f");
            dataset.push({ code: y[0], name: y[1] });
        })
        this.props.formsActions.setJson(TableIDs.genericSelectorPriceList, { dataset });
    }

    filterSelectorTitles = (data) => {
        let dataset = [];
        data.map((x) => {
            let y = x.split("\f");
            dataset.push({ code: y[0], name: y[1] });
        })
        this.props.formsActions.setJson(TableIDs.genericSelectorCustomerTitles, { dataset });
    }

    getCompanyDetails = (data) => {
        let d = data[0].split("\f");
        this.props.formsActions.setJson(this.comp_id, {
            name: d[0],
            desc: d[1],
            address1: d[2],
            address2: d[3],
            address3: d[4],
            telephone: d[5],
            term: d[11],
            logo: d[7],
            about: d[8],
            color: d[9],
        });
    }

    getDeclarList = (dataGet) => {
        if (dataGet[0]) {
            let y = dataGet[0].split('\f');

            this.props.formsActions.setJson(this.declar_id, {
                topBanner: y[5],
                bottomBanner: y[6],
                colorForm: y[8],
            });

            if (y[7] == '1') {
                const { custID, custMobile } = this.getDBParams();

                this.getFormData(this.api.check_password, { custID, custPassword: '' }, (data) => {
                    if (data[0] == '1') this.resetPassword(custMobile);
                })
            }
        }
    }

    getDBParams = () => {
        let dbParams = {}
        if (this.props.forms.formData && this.props.forms.formData.params) {
            this.props.forms.formData.params.split('&').forEach(x => {
                let y = x.split('=');
                dbParams = { ...dbParams, [y[0]]: y[1] }
            })
        }
        return dbParams;
    }

    getFormData = (script, params, next) => {
        let sCallback = (ob) => {
            let fullData = ob.data.split("\r");
            next(fullData);
        }
        let data = this.parseParamsToApi(params);
        this.formsFunctions.sendAPI(script, data, sCallback, this.formsFunctions.errorMessage)
    }

    setFormData = (script, params, next) => {
        let data = this.parseParamsToApi(params);
        this.formsFunctions.sendAPI(script, data, next, this.formsFunctions.errorMessage)
    }

    setFormDataMultiSections = (script, params, next) => {
        let data = params.map((x) => { return { ...x, data: this.parseParamsToApi(x.data) } })
        this.formsFunctions.sendAPI(script, data, next, this.formsFunctions.errorMessage)
    }

    parseParamsToApi = (params) => {
        let keys = Object.keys(params)
        let data1 = "";
        let data2 = "";
        keys.map((x, i) => {
            if (i > 0) {
                data1 += "\f";
                data2 += "\f";
            }
            data1 += x;
            data2 += params[x];
        })
        return data1 + "\r" + data2;
    }

    getField = (field) => {
        const sqlFormat = "YYYY-MM-DD";
        const dateFormat = "DD/MM/YYYY";

        let label = field.CustomerFieldDescription;
        let key = field.CustomerFieldName;
        let readOnly = field.CustomerFieldRoleListIsReadOnly == '1' || this.getDBParams().readOnly == '1';
        let isMandatory = field.CustomerFieldIsMandatory == '1';

        let editedRecord = this.props.forms[this.edit_id];
        let value = editedRecord[key];
        let onChange = (v) => { this.formsFunctions.setEditProps(this.edit_id, key, v) }
        let isError = isMandatory && !value;


        let input = () => {
            let iStyle = { width: '100%', paddingLeft: '4px' }
            switch (field.CustomerFieldControlType) {
                case '1':   // text
                    return (<Input disabled={readOnly} value={value} onChange={(v) => { onChange(v.target.value) }} 
                      // autoComplete="off" // לא עובד
                      readOnly={true}
                      onFocus={readOnly ? () => { } : e => e.target.removeAttribute('readonly')} // על מנת למנוע השלמה אוטומטית של סיסמאות
                    />);
                case '2':   // price
                    return (<InputNumber style={iStyle} min={0} precision={2} disabled={readOnly} value={value} onChange={onChange} />);
                case '3':   // float
                    return (<InputNumber style={iStyle} precision={2} disabled={readOnly} value={value} onChange={onChange} />);
                case '4':   // int
                    return (<InputNumber style={iStyle} min={0} disabled={readOnly} value={value} onChange={onChange} />);
                case '5':   // date
                    return (<DatePicker disabled={readOnly} value={value === '' ? null : moment(value, sqlFormat)}
                        format={dateFormat} onChange={(date) => { onChange(date != null ? date.format(sqlFormat) : "") }}
                    />);
                case '6':   // bool
                    return (<Checkbox onChange={(e) => { onChange((e.target.checked) ? 1 : 0) }}
                        checked={(value == 1) ? true : false} defaultChecked={false} disabled={readOnly}
                    />)

                case '7': case '8':  // select


                    if (key === "CustomerGroupID") {

                        return (
                            <GenericSelectorCustTags
                                {...this.props}
                                data={this.props.forms}
                                getText={this.getText}
                                multi
                                notApi
                                value={value ? value.split(",") : []}
                                onChange={(e) => { onChange(e ? e.join(",") : "") }}
                                disabled={readOnly}
                            />
                        );

                    } else {
                        let list = [];
                        if (field.CustomerFieldSelectorData?.indexOf('[') > -1) list = JSON.parse(field.CustomerFieldSelectorData);
                       
                        if (field.CustomerFieldControlType == '7') {

                            return (<Select {...{  value, onChange, disabled: readOnly, style: iStyle }}>
                                <Option key={"0"} value={""} >-</Option>
                                {/* {list.map(obj => (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>))} */}
                                {list.map(obj => (<Option key={obj['code']} value={obj['code']?.toString()} valuefilter={obj['code'] + obj['name']}>{obj['code'] + " - " + obj['name']}</Option>))}
                         </Select>)

                        } else {

                            return (<Checkbox
                                disabled={readOnly}
                                checked={value && list.length === value.split(",").length}
                                indeterminate={value?.split(",").length && value?.split(",").length < list.length}
                                onChange={(e) => { onChange(e.target.checked ? list.map(x => x.code).join(",") : "") }}
                            >
                                <Select
                                    mode={"multiple"} maxTagCount={4} disabled={readOnly} style={iStyle}
                                    value={value ? value.split(",") : []}
                                    onChange={(e) => { onChange(e ? e.join(",") : "") }}>
                                    {list.map(obj => (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>))}
                                </Select>
                            </Checkbox>)
                        }
                    }


                // case '7':   // select
                //     let selectorName = key === 'CustomerTitleID' ? TableIDs.genericSelectorCustomerTitles : TableIDs.genericSelectorPriceList

                //     let list = this.props.forms[selectorName] ?
                //         this.props.forms[selectorName].dataset.map((obj, index) => {
                //             return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
                //         })
                //         : [];
                //     return (<Select disabled={readOnly} value={value} style={iStyle} onChange={onChange}>{list}</Select>)

                // case '8':   // multi select
                //     let tagList = this.props.forms[TableIDs.genericMultiSelectorCustomerTags] ?
                //         this.props.forms[TableIDs.genericMultiSelectorCustomerTags].dataset.map((node) => {
                //             return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                //                 {node.tags.map((item) => (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>))}
                //             </OptGroup>)
                //         }) : []
                //     let fullSelect = value !== "" ? value.split(",") : [];
                //     return (<Select showSearch filterOption={true} optionFilterProp="valuefilter" mode="multiple"
                //         onChange={(e) => { onChange(fullSelect[0] ? e.join(",") : e[0]) }}
                //         value={fullSelect} disabled={readOnly}>{tagList}</Select>);

                case '9':   // image
                    let formEntPoint = {
                        token: this.props.forms.formData.token,
                        companyCode: this.params.code,
                        entpoint: "JMForms",
                        success: this.getText(90004),
                        error: this.getText(90005),
                    }

                    return (<PictureView
                        formEntPoint={formEntPoint} url={value} onChange={onChange} onRemove={() => onChange("")}
                        aspect={1} slicerTitle={this.getText(90067)} slicerButton={this.getText(90068)}
                        onUploadedSuccess={() => { }} onUploadedFailed={() => { }} imageType={'C'} readOnly={readOnly} />);

                case '10':   // text
                    return (<Input.Password disabled={readOnly} value={value} onChange={(v) => { onChange(v.target.value) }} />);


                case '11':
                    return (<Select
                        value={value}
                        defaultValue=""
                        style={{ width: "100%" }}
                        onChange={onChange}
                        disabled={readOnly}>
                        <Option value={'1'}>{this.getText(90060)}</Option>
                        <Option value={'2'}>{this.getText(90062)}</Option>
                        <Option value={'3'}>{this.getText(90061)}</Option>
                        <Option value={'4'}>{this.getText(90063)}</Option>
                        <Option value={'5'}>{this.getText(90072)}</Option>
                    </Select>)

                // case '12':
                //     return (<GenericSelector
                //         {...this.props}
                //         id={TableIDs.genericSelectorEmployees}
                //         api={"get_employee_list"}
                //         onBlur={onBlur}
                //         disabled={readOnly}
                //         value={this.props.data[this.id].editedRecord[field]}
                //         style={iStyle}
                //         onChange={(e) => {
                //             this.props.dataActions.genericDataSetEdit(this.id, { [field]: e });
                //         }} />)

                case '13':
                    return (<SignatureInput
                        {...this.props}
                        user={{
                            token: this.props.forms.formData.token,
                            companyCode: this.params.code,
                            entpoint: "JMForms",
                        }}
                        key={key}
                        idKey={key}
                        value={value}
                        onChange={onChange}
                        disabled={readOnly}
                    />)



                default:
                    return (<div>undefined controller</div>);
            }
        }
        let sRed = { color: '#f5222d' }
        return (<FormItem
            key={key}
            label={label}
            style={{ marginBottom: 8 }}
            validateStatus={(isError) ? "error" : "success"}
            required={isMandatory}
            extra={<label style={isError ? sRed : { display: 'none' }}>{this.getText(90006)}</label>}
            labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
            {input()}
        </FormItem>)

    }

    getContainer = (container) => {
        let fields = container.fields[0] ? container.fields.map((x) => this.getField(x)) : []
        let cTitle = (<span>
            <div>{container.title ? container.title.CustomerFieldDescription : ""}</div>
            {container.title && container.title.CustomerFieldRoleListDefaultValue ? (<img src={this.imgFormat(container.title.CustomerFieldRoleListDefaultValue)} />) : ""};
        </span>)

        return fields[0] ? (<Card
            style={{ breakInside: 'avoid-column', marginBottom: 5 }}
            headStyle={{ fontSize: 18, backgroundColor: '#dce0e8' }}
            title={cTitle}
            hoverable={true}
        >{fields}</Card>) : ""
    }

    getContainerV2 = (container, key) => {
        let mobile = window.innerWidth <= 600 ? true : false;
        let custId = this.props.forms[this.edit_id].CustomerID;

        let formStyle = {
            columnWidth: 'auto',
            columnCount: mobile ? '1' : '3',
            padding: 5,
            margin: 5,
        }

        let fields = container.fields[0] ? container.fields.map((x) => this.getField(x)) : null;

        let titleExtra = container.title ? container.title.CustomerFieldRoleListDefaultValue : null;
        titleExtra = titleExtra ? titleExtra.split(':') : [null, null];

        let titleImg = titleExtra[0] == 'U' && titleExtra[1] ? (<img src={this.imgFormat(titleExtra[1])} style={{ width: '100%' }} />) : null;
        let titleQuestionnaire = titleExtra[0] == 'Q' && titleExtra[1] ?
            (<QuestionnaireForm
                {...this.props}
                qId={titleExtra[1]}
                custId={custId}
                data={this.props.forms}
                sendAPI={this.formsFunctions.sendAPI}
                setJson={this.props.formsActions.setJson}
                companyCode={this.params.code}
                token={this.props.forms.formData.token}
                getText={this.getText}
                readOnly={this.getDBParams().readOnly == '1'}
            />)
            : null;

        let title = titleExtra[0] == 'Q' && titleExtra[1] ?
            [
                container.title ? container.title.CustomerFieldDescription : "",
                <QuestionnaireForm
                    {...this.props}
                    qId={titleExtra[1]}
                    custId={custId}
                    data={this.props.forms}
                    sendAPI={this.formsFunctions.sendAPI}
                    setJson={this.props.formsActions.setJson}
                    companyCode={this.params.code}
                    token={this.props.forms.formData.token}
                    getText={this.getText}
                    readOnly={this.getDBParams().readOnly == '1'}
                    isNullComponent
                />]
            : container.title ? container.title.CustomerFieldDescription : "";


        return fields || titleImg || titleQuestionnaire ? {
            title,
            key,
            content: (<div>
                {titleImg}
                <Form className="data-entry-form" layout='vertical' style={formStyle}>
                    {fields}
                    {titleQuestionnaire}
                </Form>
            </div>),
            isQuestionnaireForm: titleExtra[0] == 'Q'
        } : null
    }

    validationFields = () => {
        let editedRecord = this.props.forms[this.edit_id];
        let mandatories = this.props.forms[this.view_id].data;
        let qqData = this.props.forms[this.qq_id].data;

        let valid = true;
        mandatories.forEach((x) => {
            x.fields.forEach(y => {

                if (y.CustomerFieldIsMandatory === "1" && y.CustomerFieldRoleListIsReadOnly !== "1") {
                    if (y.CustomerFieldControlType == '6' && editedRecord[y.CustomerFieldName] == '0') valid = false
                    if (!editedRecord[y.CustomerFieldName]) valid = false
                }
            })
        });

        qqData.forEach((x) => {
            if (x.qqIsRequired === "1" && !x.qaValue) valid = false
        });


        return valid
    }

    handleOk = () => {
        let editedRecord = this.props.forms[this.edit_id];
        // let mandatories = this.props.forms[this.view_id].data;
        let qqData = this.props.forms[this.qq_id].data;
        let qqQID = this.props.forms[this.qq_id].qID;

        // let valid = true;
        // mandatories.forEach((x) => {
        //     x.fields.forEach(y => {

        //         if (y.CustomerFieldIsMandatory === "1" && y.CustomerFieldRoleListIsReadOnly !== "1") {
        //             if (y.CustomerFieldControlType == '6' && editedRecord[y.CustomerFieldName] == '0') valid = false
        //             if (!editedRecord[y.CustomerFieldName]) valid = false
        //         }
        //     })
        // });

        // qqData.forEach((x) => {
        //     if (x.qqIsRequired === "1" && !x.qaValue) valid = false
        // });
        let valid = this.validationFields()
        let emailValue = editedRecord.CustomerContactPersonEmail


        if (!this.state.isTermOk) {
            message.error(this.getText(90007));
            this.setState({ termToggle: true });
        } else if (!valid) {
            message.error(this.getText(90008));
        } else if (emailValue && (emailValue.indexOf("@") == -1 || ["il", "com"].indexOf(emailValue.split(".").slice(-1)[0]) == -1)) {
            message.error(this.getText(19340));
        } else {
            this.setState({ loading: true })
            let customer = this.parseParamsToApi(editedRecord)
            let CustomerTa = "CustTagTagId\r" + editedRecord.CustomerGroupID.split(",").join("\r")
            let params = [
                { section: "customer", data: customer },
                { section: "CustomerTa", data: CustomerTa }
            ]

            this.formsFunctions.sendAPI(this.api.edit, params, (ob) => {
                const dataGet = ob.data ? ob.data.split("\f") : [];
                if (dataGet[0] == -1) {
                    message.error(dataGet[1]);
                    return;
                }

                const custNum = parseInt(dataGet[0]);
                const custId = this.props.forms[this.edit_id].CustomerID;

                const onSuccess = () => {
                    let message = {
                        title: this.getText(90009),
                        desc: this.getText(90010) + custNum
                    }
                    this.props.formsActions.setJson("message", message);
                    if (custNum) {
                        this.saveFirebaseToken(custNum); // TEST SAVE FBT
                        if (!parseInt(custId)) this.formsFunctions.sendAPI(this.api.coupons, "cCustomerId\r" + custNum, () => { })
                    }
                    this.formsFunctions.sendAPI(this.api.delete, this.props.forms.formData.linkId, (e) => {
                        this.props.formsActions.overrideJson("formData", {});
                    }, this.formsFunctions.errorMessage)
                }

                if (qqData.length) {
                    let qaList = "_qId\f_qqId\f_value\f_datetime\f_entityType\f_entityValue";
                    qaList += "\r" + qqQID + "\f-1\f\f" + moment().format("YYYY-MM-DD HH:mm:ss") + "\f1\f" + custNum;
                    qqData.forEach(x => {
                        qaList += "\r" + qqQID + "\f" + x.qqID + "\f" + x.qaValue + "\f" + x.qaDatetime + "\f\f";
                    })
                    this.formsFunctions.sendAPI(this.api.save_qa, qaList, onSuccess, this.formsFunctions.errorMessage)
                } else {
                    onSuccess()
                }

            }, this.formsFunctions.errorMessage)

        }
    }

    saveFirebaseToken = (custCode) => {
        let er = this.props.forms[this.edit_id];
        let data = "rFireBaseToken\frCompanyCode\frCode\frPhoneNumber\frEmail\frName\frPicUrl\frBirthDate\frAltitude\frLongitude\r";
        data += this.props.forms.formData.firebaseToken + "\f";
        data += this.params.code + "\f";
        data += custCode + "\f";
        data += er.CustomerContactPersonMobile + "\f";
        data += er.CustomerContactPersonEmail + "\f";
        data += er.CustomerContactPersonName + "\f";
        data += er.CustomerPicUrl + "\f";
        data += er.CustomerBirthDate + "\f";
        // data += this.props.forms.formData.custLocation.latitude + "\f";
        // data += this.props.forms.formData.custLocation.longitude;
        data += " " + "\f";
        data += " ";

        this.formsFunctions.sendAPI(this.api.firebase_token, data, () => { })

    }

    imgFormat = (url) => {
        return url ? window.location.origin + '/uploads/' + this.params.code + '/' + url : null
    }

    render() {
        let formFields = this.props.forms[this.view_id];
        let resForm = formFields && formFields.data && formFields.data[0] ? formFields.data.map((x, i) => x ? this.getContainerV2(x, i + 1) : null).filter(f => f) : [];
        let companyName = this.props.forms[this.comp_id].name
        let companyLogo = this.props.forms[this.comp_id].logo
        let companyTerm = this.props.forms[this.comp_id].term
        const { topBanner, bottomBanner, colorForm } = this.props.forms[this.declar_id]

        if (companyTerm) companyTerm = companyTerm.split("\n").join("<br/>");

        let mobile = window.innerWidth <= 600 ? true : false;

        let formStyle = {
            columnWidth: 'auto',
            columnCount: mobile ? '1' : '3',
            padding: 5,
            margin: 5,
        }

        let isTermOk = this.state.isTermOk;

        let termHtml = (<div style={{ ...formStyle, fontSize: 10 }} id={"termHtml"}></div>);

        let termElement = document.getElementById("termHtml");

        if (termElement) {
            termElement.innerHTML = companyTerm;
        }
        let notValidFields = !this.validationFields()
        let submitButton = (<Button
            style={{ background: notValidFields ? "gray" : colorForm, color: 'white' }}
            size="large"
            onClick={this.handleOk}
            loading={this.state.loading}
            disabled={notValidFields}
        >{this.getText(90011)}</Button>)

        let URLtoken = window.location.search.split("&token=")[1];

        return URLtoken ?
            [<FormLinkStyle
                topBanner={this.imgFormat(topBanner)}
                bootomBanner={this.imgFormat(bottomBanner)}
                colorForm={colorForm}
                defaultSection={1}
                compData={{ name: companyName, logo: companyLogo }}
                formTitle={""/*this.getText(90001)*/}
                sections={[
                    ...resForm,
                    {
                        title: (<div onClick={() => { setTimeout(() => { document.getElementById("termHtml").innerHTML = companyTerm }, 500) }} >
                            {this.getText(90012)}
                            < br />
                            <label style={{ color: '#f5222d', fontSize: 12 }}> * {this.getText(90013)}</label>
                        </div >),
                        key: 'term',
                        content: (<div style={{ textAlign: 'center' }}>
                            <div>{termHtml}</div>
                            {this.getDBParams().readOnly == '1' ? "" :
                                <div style={{ margin: 10, padding: 10, display: 'inline-block', background: colorForm }}>
                                    <Checkbox checked={isTermOk} onChange={(e) => { this.setState({ isTermOk: e.target.checked }) }}>
                                        <span style={{ color: 'white' }}>{this.getText(90014)}</span>
                                    </Checkbox>
                                </div>}
                        </div>)
                    }
                ]}
            />,
            this.getDBParams().readOnly == '1' ? "" : <div style={{ textAlign: 'center' }}>{submitButton}</div>
            ]
            : (<span></span>)

    }
}
export default SelfCreateCustomer;

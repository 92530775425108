/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import { Icon, Checkbox, Button, Row, Col, Radio, Input, Form, InputNumber, Select, message, Switch } from "antd"
import GenericModal from "../../components/GenericModal";
import DateTimePicker from "../../components/DateTimePicker";
import TableIDs from "../../data/TableIDs";
import GenericSelector from "../../components/GenericSelector";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

const { Option } = Select;

class CouponDeclarations extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalCreateEdit: null,
        };

        this.id = "COUPON_ECLARATIONS_TABLE";

        this.api = {
            get: "get_coupon_declarations_table",
            set: "set_coupon_declaration",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.selectors = [
            { id: TableIDs.genericMultiSelectorItemTags, api: "get_tags_with_types" },
            { id: "CUSTOMER_DATE_FIELDS", api: "get_customer_date_fields" },
            { id: TableIDs.genericSelectorPreDefinedMsg, api: "get_pre_defined_msg_list" },
            { id: "CUSTOMER_ALT_CONTACT_FIELDS", api: "get_alt_contact_customer_date_fields" },
        ];

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })

        this.actionButtons = [{
            buttonType: 'add',
            buttonFunction: () => { this.handleClear('0') }
        }];


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(17324);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: null }, this.refreshData)
    }

    handleClear = (key) => {
        this.setState({
            modalCreateEdit: {
                key,
                _Id: key,
                _IsActive: '1',
                _Type: '0',
                _DateFrom: null,
                _DateTo: null,
                _DaysBeforeIssue: 0,
                _PreDefinedMessage: null,
                _CustomerDateFieldId: null,
                _DateCheckValidity: null,
                _ScheduleDateFrom: null,
                _ScheduleDateTo: null,
                _DaysBeforeEvent: 0,
                _DaysAfterEvent: 0,
                _CouponType: null,
                _CouponItemCode: '',
                _CouponTagId: null,
                _CouponQty: null,
                _CouponPrice: null,
                _CustFieldAltContact: null,
                _Description: '',
                _IsOnce: '0',
                _MinimumTotalForUse : null,
                _IsOverrideDiscount: 0,
                _MaxBenefitValue: null
            }
        }, this.refreshData)
    }

    handleOk = () => {
        const {
            _Id, _IsActive, _Type, _DateFrom, _DateTo, _DaysBeforeIssue, _PreDefinedMessage, _CustomerDateFieldId,
            _DateCheckValidity, _ScheduleDateFrom, _ScheduleDateTo, _DaysBeforeEvent, _DaysAfterEvent, _CouponType,
            _CouponItemCode, _CouponTagId, _CouponQty, _CouponPrice, _CustFieldAltContact, _Description, _IsOnce, _MinimumTotalForUse, _IsOverrideDiscount,
            _MaxBenefitValue
        } = this.state.modalCreateEdit;

        let fieldsValid = true;

        if (!_Description || !_PreDefinedMessage || !_CouponType || !_CouponPrice) fieldsValid = false;
        if (_Type != '1' && (!_DateFrom || !_DateTo)) fieldsValid = false;
        if (_Type == '1' && (!_DaysBeforeIssue || !_CustomerDateFieldId || !_DateCheckValidity || !_ScheduleDateFrom || !_ScheduleDateTo)) fieldsValid = false;
        if (_Type == '1' && _ScheduleDateFrom == '3' && !_DaysBeforeEvent && _DaysBeforeEvent !== 0) fieldsValid = false;
        if (_Type == '1' && _ScheduleDateTo == '3' && !_DaysAfterEvent) fieldsValid = false;
        if (['1', '2', '3'].indexOf(_CouponType) > -1 && !_CouponItemCode) fieldsValid = false;
        if (['4', '5', '6'].indexOf(_CouponType) > -1 && !_CouponTagId) fieldsValid = false;
        if (['1', '2', '3', '4', '5', '6'].indexOf(_CouponType) > -1 && !_CouponQty) fieldsValid = false;

        if (fieldsValid) {
            let dataSend = "_Id\f_IsActive\f_Type\f_DateFrom\f_DateTo\f_DaysBeforeIssue\f_PreDefinedMessage\f_CustomerDateFieldId\f" +
                "_DateCheckValidity\f_ScheduleDateFrom\f_ScheduleDateTo\f_DaysBeforeEvent\f_DaysAfterEvent\f_CouponType\f" +
                "_CouponItemCode\f_CouponTagId\f_CouponQty\f_CouponPrice\f_CustFieldAltContact\f_Description\f_IsOnce\f_MinimumTotalForUse\f_IsOverrideDiscount\f_MaxBenefitValue\r" +
                _Id + "\f" +
                (_IsActive ? _IsActive : '') + "\f" +
                (_Type ? _Type : '') + "\f" +
                (_DateFrom ? _DateFrom : '') + "\f" +
                (_DateTo ? _DateTo : '') + "\f" +
                (_DaysBeforeIssue ? _DaysBeforeIssue : '') + "\f" +
                (_PreDefinedMessage ? _PreDefinedMessage : '') + "\f" +
                (_CustomerDateFieldId ? _CustomerDateFieldId : '') + "\f" +
                (_DateCheckValidity ? _DateCheckValidity : '') + "\f" +
                (_ScheduleDateFrom ? _ScheduleDateFrom : '') + "\f" +
                (_ScheduleDateTo ? _ScheduleDateTo : '') + "\f" +
                (_DaysBeforeEvent || _DaysBeforeEvent === 0 ? _DaysBeforeEvent : '') + "\f" +
                (_DaysAfterEvent ? _DaysAfterEvent : '') + "\f" +
                (_CouponType ? _CouponType : '') + "\f" +
                (_CouponItemCode ? _CouponItemCode : '') + "\f" +
                (_CouponTagId ? _CouponTagId : '') + "\f" +
                (_CouponQty ? _CouponQty : '') + "\f" +
                (_CouponPrice ? _CouponPrice : '') + "\f" +
                (_CustFieldAltContact ? _CustFieldAltContact : '') + "\f" +
                (_Description ? _Description : '') + "\f" +
                (_IsOnce ? _IsOnce : '')+ "\f" +
                (_MinimumTotalForUse ? _MinimumTotalForUse : '') + "\f" +
                (_IsOverrideDiscount ? _IsOverrideDiscount : "0") + "\f" +
                (_MaxBenefitValue ? _MaxBenefitValue : "0");

            this.sendAPI(this.api.set, dataSend, this.handleCancel);
        } else {
            message.error(this.getText(17353));
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        let gt = this.getText;

        let colTxt = (text) => text
        let colCb = (text) => (<Checkbox checked={text == '1'} />)
        let colEdit = (text, record) => {
            return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={'edit'} onClick={() => {
                this.setState({ modalCreateEdit: record })
            }} />)
        }

        let ceModal = this.state.modalCreateEdit ? this.state.modalCreateEdit : {};
        let isNew = ceModal.key == '0';

        let modalTitle = gt(isNew ? 14398 : 14397)
        let modalButtons = [
            <Button key="back" onClick={this.handleCancel}>{gt(14399)}</Button>,
            <Button key="clear" onClick={() => { this.handleClear(ceModal.key) }}>{gt(14400)}</Button>,
            <Button key="create" type="primary" onClick={this.handleOk}>{gt(14401)}</Button>
        ];

        const onChange = (newData) => { this.setState({ modalCreateEdit: { ...ceModal, ...newData } }) }

        console.log("ceModal", ceModal)


        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(14387)}
                actionButtons={this.actionButtons}
                genericActionButtons={[
                    <Switch
                        checkedChildren={gt(17354)}
                        unCheckedChildren={gt(17355)}
                        checked={this.props.data[this.id].filters._onlyActive == '1'}
                        onChange={(value) => {
                            this.props.dataActions.genericDataSetFilter(
                                this.id, this.props.user.companyCode, this.props.user.token, { _onlyActive: value ? '1' : '0' }, this.api.get);
                        }}
                        style={{ margin: '10px' }} />
                ]}
                searchBox
                filters={{ _onlyActive: '1' }}
                columns={[
                    { title: gt(14388), dataIndex: "_Id", key: "_Id", width: '10%', sorter: true, render: colTxt },
                    { title: gt(14389), dataIndex: "_Description", key: "_Description", width: '30%', sorter: true, render: colTxt },
                    { title: gt(14390), dataIndex: "_IsActive", key: "_IsActive", width: '5%', sorter: true, render: colCb },
                    { title: gt(14391), dataIndex: "_DateFrom", key: "_DateFrom", width: '15%', sorter: true, render: colTxt },
                    { title: gt(14392), dataIndex: "_DateTo", key: "_DateTo", width: '15%', sorter: true, render: colTxt },
                    { title: gt(14446), dataIndex: "_DaysBeforeEvent", key: "_DaysBeforeEvent", width: '10%', sorter: true, render: colTxt },
                    { title: gt(14447), dataIndex: "_DaysAfterEvent", key: "_DaysAfterEvent", width: '10%', sorter: true, render: colTxt },
                    { title: gt(14393), width: '5%', render: colEdit },
                ]}
                percentageToMobile={[20, 60, 20]}
            />
            <GenericModal visible={this.state.modalCreateEdit !== null} onCancel={this.handleCancel} title={modalTitle} footer={modalButtons}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ padding: 10 }}>
                        <Form.Item label={gt(14389)}>
                            <Input value={ceModal._Description} onChange={e => {
                                onChange({ _Description: e.target.value })
                            }} />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox checked={ceModal._IsActive == '1'} onChange={e => {
                                onChange({ _IsActive: e.target.checked ? '1' : '0' })
                            }}>{gt(14390)}</Checkbox>
                        </Form.Item>
                        <Form.Item label={gt(17342)}>
                            <Radio.Group value={ceModal._Type} onChange={(e) => {
                                onChange({
                                    _Type: e.target.value,
                                    _DateFrom: null,
                                    _DateTo: null,
                                    _DaysBeforeIssue: null,
                                    _CustomerDateFieldId: null,
                                    _DateCheckValidity: null,
                                    _ScheduleDateFrom: null,
                                    _ScheduleDateTo: null,
                                    _DaysBeforeEvent: null,
                                    _DaysAfterEvent: null,
                                })
                            }}>
                                <Radio value={'0'}>{gt(14432)}</Radio>
                                <Radio value={'1'}>{gt(14433)}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label={gt(14408)}>
                            <GenericSelector
                                {...this.props}
                                {...this.selectors[2]}
                                value={ceModal._PreDefinedMessage}
                                onChange={e => { onChange({ _PreDefinedMessage: e }) }}
                            />
                        </Form.Item>
                        <Form.Item label={gt(16688)}>
                            <GenericSelector
                                {...this.props}
                                {...this.selectors[3]}
                                value={ceModal._CustFieldAltContact}
                                onChange={e => { onChange({ _CustFieldAltContact: e }) }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox checked={ceModal._IsOnce == '1'} onChange={(e) => {
                                onChange({ _IsOnce: e.target.checked ? '1' : '0' })
                            }}>{gt(14407)}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={ceModal._Type == '0' ? window.innerWidth > 600 ? 8 : 24 : 0} style={{ padding: 10 }}>
                        <Form.Item label={gt(14391)}>
                            <DateTimePicker format="DD/MM/YYYY" value={ceModal._DateFrom} onChange={e => { onChange({ _DateFrom: e }) }} maxDate={ceModal._DateTo} />
                        </Form.Item>,
                        <Form.Item label={gt(14392)}>
                            <DateTimePicker format="DD/MM/YYYY" value={ceModal._DateTo} onChange={e => { onChange({ _DateTo: e }) }} minDate={ceModal._DateFrom} />
                        </Form.Item>
                    </Col>
                    <Col span={ceModal._Type == '1' ? window.innerWidth > 600 ? 8 : 24 : 0} style={{ padding: 10 }}>
                        <Form.Item label={gt(17326)}>
                            <InputNumber min={1} value={ceModal._DaysBeforeIssue} onChange={e => { onChange({ _DaysBeforeIssue: e }) }} />
                        </Form.Item>
                        <Form.Item label={gt(14445)}>
                            <GenericSelector
                                {...this.props}
                                {...this.selectors[1]}
                                value={ceModal._CustomerDateFieldId}
                                onChange={e => { onChange({ _CustomerDateFieldId: e }) }}
                            />
                        </Form.Item>
                        <Form.Item label={gt(17330)}>
                            <Radio.Group value={ceModal._DateCheckValidity} onChange={e => { onChange({ _DateCheckValidity: e.target.value }) }}>
                                <Radio value={'1'}>{gt(17331)}</Radio><br />
                                <Radio value={'2'}>{gt(17332)}</Radio><br />
                                <Radio value={'3'}>{gt(17333)}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label={gt(17334)}>
                            <Radio.Group value={ceModal._ScheduleDateFrom} onChange={e => { onChange({ _ScheduleDateFrom: e.target.value, _DaysBeforeEvent: 0 }) }}>
                                <Radio value={'1'}>{gt(17335)}</Radio><br />
                                <Radio value={'2'}>{gt(17336)}</Radio><br />
                                <Radio value={'3'}>
                                    <InputNumber
                                        min={0}
                                        value={ceModal._ScheduleDateFrom == '3' ? ceModal._DaysBeforeEvent : null}
                                        onChange={e => { onChange({ _DaysBeforeEvent: e }) }}
                                        disabled={ceModal._ScheduleDateFrom != '3'} />
                                    {' ' + gt(17337)}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label={gt(17338)}>
                            <Radio.Group value={ceModal._ScheduleDateTo} onChange={e => { onChange({ _ScheduleDateTo: e.target.value, _DaysAfterEvent: 1 }) }}>
                                <Radio value={'1'}>{gt(17339)}</Radio><br />
                                <Radio value={'2'}>{gt(17340)}</Radio><br />
                                <Radio value={'3'}>
                                    <InputNumber
                                        min={1}
                                        value={ceModal._ScheduleDateTo == '3' ? ceModal._DaysAfterEvent : null}
                                        onChange={e => { onChange({ _DaysAfterEvent: e }) }}
                                        disabled={ceModal._ScheduleDateTo != '3'} />
                                    {' ' + gt(17341)}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ padding: 10 }}>
                        <Form.Item label={gt(14402)}>
                            <Select value={ceModal._CouponType} style={{ width: "100%" }} onChange={e => {
                                onChange({
                                    _CouponType: e,
                                    _CouponItemCode: null,
                                    _CouponItemName: null,
                                    _CouponTagId: null,
                                    _CouponQty: null,
                                    _CouponPrice: null,
                                })
                            }}>
                                <Option value={'1'}>{gt(17343)}</Option>
                                <Option value={'2'}>{gt(17344)}</Option>
                                <Option value={'3'}>{gt(17345)}</Option>
                                <Option value={'4'}>{gt(17346)}</Option>
                                <Option value={'5'}>{gt(17347)}</Option>
                                <Option value={'6'}>{gt(17348)}</Option>
                                <Option value={'7'}>{gt(17349)}</Option>
                                <Option value={'8'}>{gt(17350)}</Option>
                            </Select>
                        </Form.Item>
                        {['1', '2', '3'].indexOf(ceModal._CouponType) > -1 ?
                            <Form.Item label={gt(14437)}>
                                <SelectorItemClassic
                                    {...this.props}
                                    ctrlBKeyFlag={null} isExpandMatrixFatherItem={false} itemType={1}
                                    valueId={ceModal._CouponItemCode} valueName={ceModal._CouponItemName}
                                    onChange={(code, name) => { onChange({ _CouponItemCode: code, _CouponItemName: name }) }} />
                            </Form.Item>
                            : ""}
                        {['4', '5', '6'].indexOf(ceModal._CouponType) > -1 ?
                            <Form.Item label={gt(14438)}>
                                <GenericSelectorItemTags
                                    {...this.props} notReport 
                                    value={ceModal._CouponTagId}
                                    onChange={e => { onChange({ _CouponTagId: e }) }} />
                            </Form.Item>
                            : ""}
                        {['1', '2', '3', '4', '5', '6'].indexOf(ceModal._CouponType) > -1 ?
                            <Form.Item label={gt(17351)}>
                                <InputNumber value={ceModal._CouponQty} onChange={e => { onChange({ _CouponQty: e }) }} />
                            </Form.Item>
                            : ""}
                        <Form.Item label={gt(17352)}>
                            <InputNumber value={ceModal._CouponPrice} onChange={e => { onChange({ _CouponPrice: e }) }} />
                        </Form.Item>
                        <Form.Item label={gt(18097)}>
                            <InputNumber value={ceModal._MinimumTotalForUse} onChange={e => { onChange({ _MinimumTotalForUse: e }) }} />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox checked={ceModal._IsOverrideDiscount == '1'} onChange={e => { onChange({ _IsOverrideDiscount: e.target.checked ? '1' : '0' })
                            }}>{gt(19639)}</Checkbox>
                        </Form.Item>
                        {['1', '4', '7'].indexOf(ceModal._CouponType) > -1 ?
                            <Form.Item label={gt(19688)}>
                                <InputNumber value={ceModal._MaxBenefitValue} onChange={e => { onChange({ _MaxBenefitValue: e }) }} />
                            </Form.Item>
                            : ""}
                    </Col>

                </Row>
            </GenericModal>
        </div>)
    }

}

export default CouponDeclarations;
/* @flow */
/*jshint esversion: 6 */
import { Form, InputNumber } from "antd";
import { Row, Col, DatePicker } from "antd";
import React, { Component } from "react";
import "../../App.css";
import ReportTypeButton from "../../components/ReportTypeButton";
import InputStairs from "../../components/InputStairs";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class CustomersByAge extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            popStairs: false,

            _ageStairs: '',
            reportName: "reports/CustomersByAge.pdf"
        };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(18086);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    componentDidMount() {
        this.sendAPI("get_best_club_report_settings", "", (ob) => {
            let x = ob.data ? ob.data.split("\r") : []
            let y = x[1] ? x[1].split("\f") : [];
            this.setState({
                _ageStairs: y[5] ? y[5] : null,
            })
        })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 700,
            margin: 'auto'
        };

        const { _ageStairs, popStairs } = this.state;

        return (<div style={divStyle}>
            <PageToolbar title={gt(18087)} actionButtons={[]} />

            <Form.Item label={gt(18088)}>
                <InputStairs
                    value={_ageStairs}
                    onChange={e => { this.setState({ _ageStairs: e }) }}
                    visible={popStairs}
                    onClickInput={() => { this.setState({ popStairs: !popStairs }) }}
                    min={18} max={100}
                />
            </Form.Item>

            <ReportTypeButton
                {...this.props}
                params={this.state}
                favoriteSave
            />
        </div>)
    }

}

export default CustomersByAge;

import React from "react";
import {
    InputNumber, DatePicker, Table, Switch, notification, Form, Input, Button, Modal, Select,
    Popconfirm, Icon, message, Upload
} from 'antd';

import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import moment from "moment";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";

//type State = {}
class ModalEditEventConsts extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            loadingImg: false,
        }

        this.id = 'MODAL_EDIT_EVENT_CONSTS';
        this.eventId = 'BUSINESS_LOGIC_MAIN';
        this.activeId = 'EVENT_IS_ACTIVE';

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state._count = 0;
        this.state._id = 0;

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.activeId] === undefined) { props.data[this.activeId] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_consts_from_event",
            getEventIsActive: "get_event_is_active",
            getMainPage: "get_business_logic_list",
            delete: "delete_consts_from_event",
            createEdit: "create_edit_consts_from_event",
        }

        this.actionButtons = [];

        this.openNotificationWithIcon = (type, msg, descr) => {
            notification[type]({
                message: msg,
                description: descr,
            });
        };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    errorSuppliers = () => {
        message.error(this.getText(11620));
    }

    errorEditLine = () => {
        message.error(this.getText(11621));
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
    }

    handleOk = () => {
        //e.preventDefault();

        let baseData = this.props.data[this.id].data;
        console.log(baseData.map(x => x.split('\f')), 'this.props.data[this.id].data')
        if (baseData[0]) {
            // let constsValue = baseData.map((x, index) => { let y = x.split("\f"); return y[4] });
            // let constValid = constsValue.reduce((a, b) => { return a && b });
            // if (!constValid) {
            //     this.errorSuppliers()
            //     return false;
            // }
            if (this.props.data[this.id].editing >= 0) {
                this.errorEditLine()
                return false;
            }
            this.props.dataActions.genericDataJustSaveEditMulti(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.createEdit,
                [{ name: this.id, sendDataTable: true }],
                {
                    action: this.props.dataActions.genericDataGetRequested,
                    args: [this.eventId, this.props.user.companyCode,
                    this.props.user.token, this.api.getMainPage/*"get_items_list"*/]
                }
            );
            this.props.uiActions.hideModal(TableIDs.modalEditEventConsts);

        } else {
            this.props.dataActions.genericDataJustSaveEditMulti(
                this.activeId,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.createEdit,
                [{ name: this.activeId, sendDataTable: true }],
                {
                    action: this.props.dataActions.genericDataGetRequested,
                    args: [this.eventId, this.props.user.companyCode,
                    this.props.user.token, this.api.getMainPage/*"get_items_list"*/]
                }
            );
            this.props.uiActions.hideModal(TableIDs.modalEditEventConsts);
        }
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
    }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    initFilters = () => {

        let request = {
            cEventId: this.state._id,
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        this.props.dataActions.genericDataSetFilter(
            this.activeId, this.props.user.companyCode, this.props.user.token, request, this.api.getEventIsActive);
    }

    getInputFromType = (type, text) => {

        const typeName = (typeof type) == "string" ? type.toLowerCase() : type;
        const fieldKey = 'cConstValue';
        const sqlFormat = "YYYY-MM-DD";
        const dateFormat = "DD/MM/YYYY";

        switch (typeName) {

            case 'int':
                return (
                    <InputNumber
                        min={0} step={1} precision={0}
                        style={{ width: '98%', textAlign: 'center' }}
                        value={this.props.data[this.id].editedRecord[fieldKey]}
                        onChange={(value) => {
                            this.props.dataActions.genericDataSetEdit(this.id, { cConstValue: value ? value : '0' });
                        }} />
                );

            case 'double':
                return (
                    <InputNumber
                        min={0} step={1} precision={2}
                        style={{ width: '98%', textAlign: 'center' }}
                        value={this.props.data[this.id].editedRecord[fieldKey]}
                        onChange={(value) => {
                            this.props.dataActions.genericDataSetEdit(this.id, { cConstValue: value ? value : '0.00' });
                        }} />
                );

            case 'string':
                return (
                    <Input
                        value={this.props.data[this.id].editedRecord[fieldKey]}
                        onChange={(value) => {
                            this.props.dataActions.genericDataSetEdit(this.id, { cConstValue: value.target.value });
                        }
                        } />
                );

            case 'date':
                return (
                    <DatePicker
                        value={(this.props.data[this.id].editedRecord[fieldKey] === '') ? null :
                            moment(this.props.data[this.id].editedRecord[fieldKey], sqlFormat)}
                        format={dateFormat}
                        onChange={(date) => {
                            this.props.dataActions.genericDataSetEdit(this.id, { [fieldKey]: date != null ? date.format("YYYY-MM-DD") : "" });
                        }}
                    />
                );


        }
    }

    render() {

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                cConstActive: y[1],
                cConstName: y[2],
                cConstType: y[3],
                cConstValue: y[4],
                cParameterId: y[5],
                cEventId: y[6],
                index: index
            })
        });

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        let columns = [
            {
                title: this.getText(11622),
                dataIndex: "cConstName",
                key: "cConstName",
                sorter: false,
                width: '60%',
                render: (text, record) => text

            }, {
                title: this.getText(11623),
                dataIndex: "cConstValue",
                key: "cConstValue",
                sorter: false,
                width: '20%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing == record.key) {
                        return this.getInputFromType(record.cConstType, text);
                    } else {
                        if (text) {
                            if (record.cConstType.toLowerCase() === 'date')
                                return (<div>{moment(text).format("DD/MM/YYYY")}</div>);
                            else if (record.cConstType.toLowerCase() === 'double')
                                return (<div>{parseFloat(text).toFixed(2)}</div>);
                            else
                                return text;
                        } else {
                            return '';
                        }
                    }
                },

            }, {
                title: this.getText(11624),
                dataIndex: "cEdit",
                key: "cEdit",
                sorter: false,
                width: '20%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing != record.key) {
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                }} />
                            </span >);
                    } else {
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    let lineRecord = this.props.data[this.id].editedRecord;
                                    this.props.dataActions.dataTableSetRow(
                                        this.id, record.index, lineRecord, true);
                                    this.props.uiActions.hideModal(this.id + "Editor");
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)
                    }


                },

            },
        ];



        return (
            <Modal
                destroyOnClose={true} width={window.innerWidth > 600 ? '60%' : '95%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                <div style={{ textAlign: 'center', fontSize: '24px' }}>

                    {this.state._name}

                    <Form id="edit_event_consts_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: '1' }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <Switch checkedChildren={this.getText(11625)} unCheckedChildren={this.getText(11626)}
                            checked={this.props.data[this.activeId].data[0] ? (this.props.data[this.activeId].data[0].split("\f")[2] == 1) ? true : false : false}
                            onChange={(value) => {
                                let isData = this.props.data[this.activeId].data[0];
                                if (isData) {
                                    let record = isData.split("\f");
                                    record[2] = value ? 1 : 0;
                                    this.props.dataActions.dataTableSetRow(this.activeId, 0, record.join("\f"), true);
                                }
                            }}
                            style={{ margin: '10px' }}
                        />

                        <ResponsiveTable ui={this.props.ui} tableOnly={true} dataSource={dataSource}
                            columns={columns}
                            loading={this.props.ui.workInProgress}
                            //scroll={{ x: 1650 }}
                            bordered
                            pagination={false}
                            onChange={this.handleTableChange}
                            locale={{ emptyText: this.getText(11627) }}
                        />
                    </Form>
                </div>

                <div className="action_footer" style={{ textAlign: 'center', marginTop: 16 }}>
                    {(() => {
                        let isActive = this.props.data[this.activeId].data.map((x, index) => { let y = x.split("\f"); return y[2] });
                        if (isActive[0] == 1) {
                            return (
                                <Button
                                    style={{ display: 'inline-block' }}
                                    onClick={this.handleOk}
                                    type="primary"
                                    htmlType="submit"
                                    form="edit_event_consts_form"
                                    loading={this.props.ui.workInProgress}
                                    className={"form-button-save"}>{this.getText(11628)}
                                </Button>
                            );
                        } else {
                            return (
                                <Popconfirm title={this.getText(11629)}
                                    onConfirm={() => {
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.eventId,
                                            this.props.data[this.activeId].data.map((x, index) => { let y = x.split("\f"); return y[1] }),
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.getMainPage);
                                        this.props.uiActions.hideModal(TableIDs.modalEditEventConsts);
                                    }
                                    }
                                    okText={this.getText(11630)} cancelText={this.getText(11631)}>
                                    <Button
                                        style={{ display: 'inline-block' }}
                                        type="primary"
                                        htmlType="submit"
                                        form="edit_event_consts_form"
                                        loading={this.props.ui.workInProgress}
                                        className={"form-button-save"}>{this.getText(11628)}
                                    </Button>
                                </Popconfirm>
                            );
                            //   }
                        }
                    })()
                    }

                </div>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toggle === false && this.props.toggle === true) {
            this.setState(
                { _name: this.props.ui.data.eDescription, _id: this.props.ui.data.eId, _active: this.props.ui.data.eActive },
                () => {
                    this.props.dataActions.genericDataSetFilter(
                        this.id, this.props.user.companyCode, this.props.user.token, { cEventId: this.props.ui.data.eId }, this.api.get);
                    this.props.dataActions.genericDataSetFilter(
                        this.activeId, this.props.user.companyCode, this.props.user.token, { cEventId: this.props.ui.data.eId }, this.api.getEventIsActive);
                }
            );
        }
    }


}

export default ModalEditEventConsts;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Button, Form, Select, Input, Switch } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
//import { DatePicker } from 'antd';
//import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class StandingOrdersSettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {};

        this.api = {
            get: "get_standing_orders_settings",
            edit: "edit_standing_orders_settings",
            selector_pre_defined_msg: "get_pre_defined_msg_list",
        }

        this.id = 'STANDING_ORDERS_SETTINGS';

        if (props.data[TableIDs.genericSelectorPreDefinedMsg] === undefined) {
            props.data[TableIDs.genericSelectorPreDefinedMsg] = { ...props.data.genericSelector };
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPreDefinedMsg, this.props.user.companyCode, this.props.user.token, this.api.selector_pre_defined_msg);

    }

	static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
     //   document.title = "Cash On Tab - הגדרות";
        this.loadData();
    }

	componentDidUpdate(){
        document.title = "Cash On Tab - " + this.getText(10204);
    } 
	
    setStateVal(root: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, root, params);
        this.setState(newState);
    }

    loadData() {
        console.log(this.props);

        this.props.dataActions.genericDataGetEditWithParams(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            { _id: this.props.ui.data },
            this.api.get
        );
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();

        this.props.dataActions.genericDataJustSaveEdit(
            this.id, this.props.user.companyCode,
            this.props.user.token, this.api.edit);
    }

    render() {
        //const { FromDate, ToDate, endOpen } = this.state;
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let listPreDef = Jax.get(this.props.data, TableIDs.genericSelectorPreDefinedMsg + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let mobile = window.innerWidth > 600 ? false : true

        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(11601)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width:mobile ? '95%': '60%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form id="standing_orders_settings_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '2', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(11602)}>
                            <Input
                                style={{ width: '98%', /*textAlign: 'center'*/ }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mDescription'] : ''}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { mDescription: value.target.value });
                                }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(11603)}>
                            <Input
                                style={{ width: '98%', /*textAlign: 'center'*/ }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mAccount'] : ''}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { mAccount: value.target.value });
                                }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(11604)}>
                            <Switch key={"mIsMsgActive"} checkedChildren={this.getText(11608)} unCheckedChildren={this.getText(11609)}
                                onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { mIsMsgActive: (value) ? 1 : 0 }); }}
                                checked={(this.props.data[this.id]) ? (this.props.data[this.id].editedRecord['mIsMsgActive'] === 1
                                    || this.props.data[this.id].editedRecord['mIsMsgActive'] === '1') ? true : false
                                    : false}
                                style={{ marginLeft: 4 }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(11605)}>
                            <Select key={"mOnSuccessMsg"} style={{ width: '80%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { mOnSuccessMsg: value }); }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mOnSuccessMsg'] : ''}
                                notFoundContent={this.getText(11610)}
                            >
                                {listPreDef}
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(11606)}>
                            <Select key={"mOnFailMsg"} style={{ width: '80%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                //placeholder="Please select"
                                //defaultValue={1}
                                onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { mOnFailMsg: value }); }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mOnFailMsg'] : ''}
                                notFoundContent={this.getText(11610)}
                            >
                                {listPreDef}
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(11607)}>
                            <Select key={"mAboutToExpireMsg"} style={{ width: '80%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                //placeholder="Please select"
                                //defaultValue={1}
                                onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { mAboutToExpireMsg: value }); }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mAboutToExpireMsg'] : ''}
                                notFoundContent={this.getText(11610)}
                            >
                                {listPreDef}
                            </Select>
                        </FormItem>
                       
                        <FormItem label={this.getText(14108)}>
                            <Select key={"mDocLinkMsg"} style={{ width: '80%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                //placeholder="Please select"
                                //defaultValue={1}
                                onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { mDocLinkMsg: value }); }}
                                value={(this.props.data[this.id]) ? this.props.data[this.id].editedRecord['mDocLinkMsg'] : ''}
                                notFoundContent={this.getText(11610)}
                            >
                                {listPreDef}
                            </Select>
                        </FormItem>
                    </Form>
                    <div className="action_footer">
                        <Button type="primary" htmlType="submit" form="standing_orders_settings_form" className={"form-button-save"} icon={"check"}
                            style={{ marginLeft: 16 }} loading={this.props.ui.workInProgress}>{this.getText(11611)}</Button>
                    </div>
                </Card>
            </div>
        );
    }
}
export default StandingOrdersSettings;
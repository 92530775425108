/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Popover, Tooltip, Button, Form, Select, Modal, Icon } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import { disabledDatePicker } from "../../utils/utils";

const FormItem = Form.Item;
const Option = Select.Option;

class AppRegistration extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            mStartDate: null,
            mEndDate: null,
            modal: null,
        };

        this.id = "APP_REGISTRATION";
        this.expired_id = "APP_REGISTRATION_EXPIRED";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.expired_id] === undefined) props.data[this.expired_id] = { ...props.data.genericData };

        this.api = {
            get: "get_app_registration",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    initFilters = () => {
        let local = [this.id, this.expired_id]
        for (let x = 0; x < 2; x++) {
            let req = {
                ...this.state,
                mExpired: x,
                mToPrint: 0
            }
            this.props.dataActions.genericDataSetFilter(local[x], this.props.user.companyCode, this.props.user.token, req, this.api.get);
        }
    }

    componentDidMount() {
        this.initFilters();
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(12935);
    }

    reportButton = (isExpired) => {
        let generateReport = (reportName) => {
            let s = this.state;
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, {
                    companyCode: this.props.user.companyCode,
                    mStartDate: s.mStartDate,
                    mEndDate: s.mEndDate,
                    mExpired: isExpired,
                    mToPrint: 1,
                    reportName: "reports/" + reportName,
                    REPORT_LOCALE: this.getText(101),
                }]
            });
        }

        let subButton = (text, type, reportName) => {
            let onClick = () => { generateReport(reportName) }
            return (
                <Tooltip title={this.getText(text)}>
                    <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={onClick} />
                </Tooltip>)
        }

        let content = (<div>
            {subButton(12721, "file-pdf", "AppRegistration.pdf")}
            {subButton(12722, "file-excel", "AppRegistrationExcel.xls")}
        </div>);

        return (
            <Tooltip title={this.getText(12720)}>
                <Popover content={content} title={this.getText(12723)} trigger="click">
                    <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                        <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                    </Button>
                </Popover>
            </Tooltip >)
    }


    titleBar = (title, isModal) => {
        let buttons = isModal ? [] : [{ buttonType: 'refresh', buttonFunction: this.initFilters }];
        let gButtons = isModal ? [this.reportButton(this.state.modal === TableIDs.modalAppRegistration ? 0 : 1)] : [];

        return (<PageToolbar
            style={{ padding: 4 }}
            title={this.getText(title)}
            actionButtons={buttons}
            genericActionButtons={gButtons}
            isModal={isModal}
            ui={this.props.ui}
            uiActions={this.props.uiActions} />)
    }

    getModal = (isExpired, title, idTable) => {
        let id = [
            TableIDs.modalAppRegistration,
            TableIDs.modalAppRegistrationExpired
        ];

        let lastCol = isExpired === 1 ? 12947 : 12946

        let columns = [
            { title: this.getText(12942), dataIndex: "rCode", key: "rCode", width: '20%', render: (text) => text },
            { title: this.getText(12943), dataIndex: "rName", key: "rName", width: '20%', render: (text) => text },
            { title: this.getText(12944), dataIndex: "rPhone", key: "rPhone", width: '20%', render: (text) => text },
            { title: this.getText(12945), dataIndex: "rEmail", key: "rEmail", width: '20%', render: (text) => text },
            { title: this.getText(lastCol), dataIndex: "rDate", key: "rDate", width: '20%', render: (text) => text },
        ];

        return (<Modal
            destroyOnClose={true} width={'80%'} visible={this.state.modal === id[isExpired]}
            title={this.titleBar(title, [])} onCancel={() => { this.setState({ modal: null }) }}
            footer={false} ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <ResponsiveTable
                idTable={this.props.data[idTable]}
                tableOnly={true}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                columns={columns}
                pagination={{ pageSize: 10 }}
            />
        </Modal>)
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let colDiv = (w, wm, c) => {
            let mobile = window.innerWidth <= 600 ? true : false;
            let cStyle = {
                width: (mobile ? wm : w) + "%",
                display: 'inline-block',
                verticalAlign: 'text-top',
                margin: 10,
            }
            return (<div style={cStyle}>{c}</div>)
        }

        let boxCard = (num, text, modal) => {
            return (<Card style={{ backgroundColor: '#FFFFE5', textAlign: 'center', borderRadius: 30 }} >
                <div style={{ fontSize: 100 }}>{num}</div>
                <div style={{ fontSize: 30 }}>{this.getText(text)}</div>
                <Button style={{ fontSize: 16, margin: 10 }} onClick={() => { this.setState({ modal: modal }) }} >
                    <Icon type="table" key="table" />
                </Button>
            </Card>)
        }

        let datePicker = (field) => {
            let minDate = field == 'mEndDate' ? this.state.mStartDate : null
            let maxDate = field == 'mStartDate' ? this.state.mEndDate : null
            return (<DatePicker
                disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
                format="DD/MM/YYYY"
                defaultValue={moment(new Date(), "YYYY-MM-DD")}
                onChange={(value) => { this.setState({ [field]: value ? value.format("YYYY-MM-DD") : null }, this.initFilters) }}
            />)
        }

        let datesBox = (<Card title={this.getText(12936)} style={{ textAlign: 'right' }}>
            {colDiv(100, 40, <FormItem label={this.getText(12937)}>{datePicker("mStartDate")}</FormItem>)}
            {colDiv(100, 40, <FormItem label={this.getText(12938)}>{datePicker("mEndDate")}</FormItem>)}
        </Card>);

        return (
            <div style={divStyle}>
                {this.titleBar(12941, false)}

                {colDiv(20, 100, datesBox)}
                {colDiv(35, 100, boxCard(this.props.data[this.id].count, 12939, TableIDs.modalAppRegistration))}
                {colDiv(35, 100, boxCard(this.props.data[this.expired_id].count, 12940, TableIDs.modalAppRegistrationExpired))}

                {this.getModal(0, 12939, this.id)}
                {this.getModal(1, 12940, this.expired_id)}
            </div>
        );
    }

}
export default AppRegistration;
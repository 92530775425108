
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Tooltip, Icon, Popconfirm } from "antd";
import "../App.css";
import GenericTable from "./GenericTable";
import GenericModal from "./GenericModal";
import { escapeRegExp } from "../utils/utils";

type Props = {
    id: String,
    getApi: String,
    restorationApi: String,
    title: String,
    columnTitles: Array,
    popconfirmText: Object,
    onClose: Function,
    onRestoration: Function,
    triggerOpen: Boolean,

    //genericProps
    data: Object,
    dataActions: Object,
    ui: Object,
    uiActions: Object,
    user: Object,

}

class RecyclingBin extends Component {
    state = { modalTable: false }

    componentDidUpdate(prev) {
        if (this.props.triggerOpen && !prev.triggerOpen) {
            this.setState({ modalTable: true })
        }
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.props.id, this.props.user.companyCode, this.props.user.token, this.props.getApi);
    }

    restorationButton = (text, record) => {
        return (<Popconfirm {...this.props.popconfirmText}
            onConfirm={() => {
                this.props.dataActions.genericDataDeleteRow(
                    this.props.id,
                    record['mID'],
                    this.props.user.companyCode,
                    this.props.user.token,
                    this.props.restorationApi,
                    this.props.getApi);

                if (this.props.onRestoration) {
                    this.setState({ modalTable: false }, () => {
                        this.props.onClose()
                        this.props.onRestoration(record['mID'])
                    })
                }
            }}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"rest"} />
        </Popconfirm>)
    }

    showsearch = (val) => {
        this.props.dataActions.genericDataSetFilter(
            this.props.id,
            this.props.user.companyCode,
            this.props.user.token,
            {
                ...this.props.data[this.props.id].filters,
                // ui-pagination 
                page: 1,
                // sortType: 1,
                // api
                _page_num: 1,
                // _isAsc: 1,
                // _sort_by: 'col_1',
                _words: escapeRegExp(val),
                // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
            },
            this.props.getApi)
    }

    render() {
        const { id, getApi, title, columnTitles, data, dataActions, ui, uiActions, user, filters, pagination, genericActionButtons } = this.props;

        let columns = this.props.columns ?
            this.props.columns.map(x => x.key ? x : { ...x, render: this.restorationButton })
            : [
                { title: columnTitles[0], key: 'mID', dataIndex: 'mID', width: '15%', render: (t) => t },
                { title: columnTitles[1], key: 'mName', dataIndex: 'mName', width: '45%', render: (t) => t },
                { title: columnTitles[2], key: 'mDate', dataIndex: 'mDate', width: '15%', render: (t) => t },
                { title: columnTitles[3], key: 'mUser', dataIndex: 'mUser', width: '15%', render: (t) => t },
                { title: columnTitles[4], width: '10%', render: this.restorationButton },
            ];

        let tableProps = { id, getApi, columns, data, dataActions, ui, uiActions, user, filters, pagination }


        return (<span>
            <Tooltip title={title}>
                <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                    this.setState({ modalTable: true })
                }}><Icon type="interaction" style={{ fontSize: 16 }} /></Button>
            </Tooltip>
            <GenericModal
                visible={this.state.modalTable}
                onCancel={() => { this.setState({ modalTable: false }, this.props.onClose) }}
                title={title}
                width={this.props.modalWidth ? this.props.modalWidth : 1000}
                actionButtons={[{ buttonType: 'refresh', buttonFunction: this.refreshData }]}
                genericActionButtons={genericActionButtons}
                showsearch={this.showsearch}>
                {this.state.modalTable ? (<GenericTable tableOnly percentageToMobile={[30, 70]} {...tableProps} />) : ""}
            </GenericModal>
        </span>)
    }
}
export default RecyclingBin;
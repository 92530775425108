/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";
import { Checkbox } from "antd";


class PrintDocuments extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "DOCUMENT_TYPES_PRINT_SETTINGS";

        this.api = {
            get: "get_document_types_print_settings",
            save: "save_document_types_print_settings",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16373);
    }

    editCol = (text, record) => {
        const { editing } = this.props.data[this.id];
        let isEdit = record.key === editing;
        return (<TableLineIcons
            onEdit={isEdit ? null : () => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
            onCheck={isEdit ? () => {
                this.props.dataActions.genericDataSaveEdit(
                    this.id, this.props.user.companyCode, this.props.user.token, this.api.save, this.api.get);
            } : null}
            onClose={isEdit ? () => {
                if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                else this.props.dataActions.genericDataCancelEditing(this.id);
            } : null}
        />)
    }

    checkboxCol = (text, record, field) => {
        const { editing, editedRecord } = this.props.data[this.id];
        let isEdit = record.key === editing;
        return (<Checkbox
            checked={isEdit ? editedRecord[field] == '1' : text == '1'}
            disabled={!isEdit}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.target.checked ? '1' : '0' }) }}
        />)
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        let sorter = true;
        let render = text => text;

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16374)}
                columns={[
                    { title: gt(16375), key: "tID", dataIndex: "tID", width: "12%", sorter, render },
                    { title: gt(16376), key: "tName", dataIndex: "tName", width: "30%", sorter, render },
                    { title: gt(16377), key: "tWithPrice", dataIndex: "tWithPrice", width: "10%", sorter, render: (t, r) => this.checkboxCol(t, r, 'tWithPrice') },
                    { title: gt(16378), key: "tWithVat", dataIndex: "tWithVat", width: "10%", sorter, render: (t, r) => this.checkboxCol(t, r, 'tWithVat') },
                    { title: gt(17478), key: "tIsForZDocSum", dataIndex: "tIsForZDocSum", width: "10%", sorter, render: (t, r) => this.checkboxCol(t, r, 'tIsForZDocSum') },
                    { title: gt(16379), key: "tIsForZItemList", dataIndex: "tIsForZItemList", width: "10%", sorter, render: (t, r) => this.checkboxCol(t, r, 'tIsForZItemList') },
                    { title: gt(16380), key: "tIsForBI", dataIndex: "tIsForBI", width: "10%", sorter, render: (t, r) => this.checkboxCol(t, r, 'tIsForBI') },
                    { title: gt(16381), width: "8%", render: this.editCol },
                ]}
                percentageToMobile={[20, 80]}
                searchBox
            />
        </div>)
    }

}

export default PrintDocuments;
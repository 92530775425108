/* @flow */
/*jshint esversion: 6 */
import { Card, Icon, Modal } from "antd";
import React, { Component } from "react";
import SignatureCanvas from 'react-signature-canvas'
import { Job, User, OutputType, ProcessType, JobResponse } from '../JmReact';


class SignatureInputV2 extends Component {

    state = {
        modalEdit: false,
    }

    sigPad = {}

    clear = () => {
        // this.sigPad.clear()
        this.props.onChange(null)
        // window.addEventListener('DOMMouseScroll', e => { e.preventDefault() }, false);
        // const { scrollX, scrollY, scrollTo } = window;
        // window.onscroll = function () { scrollTo(scrollX, scrollY) }
    }

    trim = () => {

        if (this.sigPad.isEmpty()) {
            this.clear()
        } else {
            // window.removeEventListener('DOMMouseScroll', e => { e.preventDefault() }, false);
            // window.onscroll = function () { }
            this.setState({ modalEdit: false }, () => {
                this.saveImage(
                    this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
                    (this.props.user.entpoint ? this.props.user.entpoint : "CashOnTab"),
                    (ob) => { this.props.onChange(ob.data) },
                    (e) => { console.error(e) })
            })
        }
    }

    openModal = () => {
        this.setState({ modalEdit: true }, this.clear)
    }

    saveImage = (fileUrl, entpoint, successCallback, errorCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "upload_image", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC, (entpoint === "CashOnTab") ? false : true);
        job.setSection("imageName", "signatureImg");
        job.setSection("imageExt", "png");
        job.setSection("imageType", "Z");
        job.setSection("imageMeta", fileUrl.split(',')[0]);
        job.setSection("imageData", fileUrl.split(',')[1]);
        job.send("/cgi-bin/" + entpoint, successCallback, errorCallback);
    }

    render() {
        // let actions = [<Icon type="delete" onClick={this.clear} />];
        // if (!this.props.value) actions = [<Icon type="delete" onClick={() => { this.sigPad.clear() }} />, <Icon type="check" onClick={this.trim} />]
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        let baseUrl = isDev ? "http://147.235.163.248" : origin;
        baseUrl += "/uploads/" + this.props.user.companyCode + "/";

        let imgFormat = this.props.value ? (<img src={baseUrl + this.props.value} style={{ maxWidth: "100%" }} />) : ""

        return this.props.disabled ? imgFormat :
            (<div>
                <Card
                    key={this.props.idKey}
                    bodyStyle={{ padding: 1 }}
                    actions={[<Icon type={"edit"} onClick={this.openModal} />]}>
                    {imgFormat}
                </Card>
                <Modal
                    visible={this.state.modalEdit}
                    onCancel={() => { this.setState({ modalEdit: false }) }}
                    width={300}
                    footer={false}
                >
                    <Card
                        key={this.props.idKey + "_modal"}
                        bodyStyle={{ padding: 1 }}
                        actions={[<Icon type="delete" onClick={() => { this.sigPad.clear() }} />, <Icon type="check" onClick={this.trim} />]}>
                        <SignatureCanvas
                            canvasProps={{ style: { border: '1px solid #aaaaaa', borderRadius: 5, width: '100%', height: 150 } }}
                            ref={(ref) => { this.sigPad = ref }}
                        />
                    </Card>
                </Modal>
            </div>)
    }

}

export default SignatureInputV2;

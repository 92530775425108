
import React from "react";
import {
    Input, Icon, Button, Popconfirm, Tooltip, Modal, Table,
    Select, Row, Col, Checkbox, message
} from 'antd';

//import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
//import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import ResponsiveTable from "./ResponsiveTable";
//import { fixCurrentPage } from "../utils/utils";
import LangContext from "../contextProvider/LangContext";

const topHeight = 340; // px
const rowHeight = 30;  // px

//type State = {}
class ModalCreatePriceList extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = "STANDING_ORDERS_BY_CUSTOMER_TABLE";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[TableIDs.genericSelectorTaxRegion] === undefined) {
            props.data[TableIDs.genericSelectorTaxRegion] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_standing_orders_by_customer_table",
            //create: "create_priceList",
            //edit: "edit_priceList",
            //delete: "delete_priceList",
            selector: "get_tax_region_list",
        }

        this.actionButtons = [
            /*{
            buttonType: 'add',
            buttonFunction: this.handleCreateNew
        },*/ {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },/* {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/]

        // mId', 'mSum', 'mActionDate', 'mStatus', 'mPeriod

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //  document.title = "Cash On Tab - הוראות קבע";
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorTaxRegion, this.props.user.companyCode,
            this.props.user.token, this.api.selector);
        //this.refreshData();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: (this.props.ui.data) ? this.props.ui.data : 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        return request;
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 6;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mId": col--;
            case "mSum": col--;
            case "mActionDate": col--;
            case "mPeriod": col--;
            case "mStatus": col--;
            case "mStatusDescription": break; // 6
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            _id: (this.props.ui.data) ? this.props.ui.data : 1,
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        this.setState({ visible: false });
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreatePriceList);
    }
    handleClear = () => {
        //this.clear();
    }

    render() {
        const { loading } = this.state;
        let request = {
            // ui-pagination 
            _id: (this.props.ui.data) ? this.props.ui.data : 1,
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10824)}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mSum: y[1],
                mActionDate: y[2],
                mPeriod: y[3],
                mStatus: y[4],
                mStatusDescription: y[5],
                index: index
            })
        });

        let columns =
            [
                {
                    title: this.getText(10825),
                    dataIndex: "mId",
                    key: "mId",
                    sorter: true,
                    width: '15%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(10826),
                    dataIndex: "mPeriod",
                    key: "mPeriod",
                    width: '15%',
                    sorter: true,
                    render: (text, record) => (text.length === 4) ? text.slice(0, 2) + '/' + text.slice(-2) : text,
                    /*
                    render: (text, record) => {
                        if (this.props.data[this.id].editing === record.key) {
                            return (
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { mCustomerId: value.target.value });
                                    }} />);
                        } else {
                            return text;
                        }
                    },
                    */
                }, {
                    title: this.getText(10827),
                    dataIndex: "mActionDate",
                    key: "mActionDate",
                    width: '20%',
                    sorter: true,
                    render: (text, record) => text,
                    /*
                    render: (text, record) => {
                        if (this.props.data[this.id].editing === record.key) {
                            return (
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { mActionDate: value.target.value });
                                    }} />);
                        } else {
                            return text;
                        }
                    },
                    */
                }, {
                    title: this.getText(10828),
                    dataIndex: "mSum",
                    key: "mSum",
                    width: '20%',
                    sorter: true,
                    render: (text, record) => text,
                    /*
                                    render: (text, record) => {
                                        if (this.props.data[this.id].editing === record.key) {
                                            return (
                                                <Input
                                                    style={{ width: '98%', textAlign: 'center' }}
                                                    defaultValue={text}
                                                    onChange={(value) => {
                                                        this.props.dataActions.genericDataSetEdit(this.id, { mSum: value.target.value });
                                                    }} />);
                                        } else {
                                            return text;
                                        }
                                    },
                                    */
                }, {
                    title: this.getText(10829),
                    dataIndex: "mStatus",
                    key: "mStatus",
                    width: '10%',
                    sorter: true,
                    render: (text, record) => text,
                    /*
                                    render: (text, record) => {
                                        if (this.props.data[this.id].editing === record.key) {
                                            return (
                                                <Input
                                                    style={{ width: '98%', textAlign: 'center' }}
                                                    defaultValue={text}
                                                    onChange={(value) => {
                                                        this.props.dataActions.genericDataSetEdit(this.id, { mStatus: value.target.value });
                                                    }} />);
                                        } else {
                                            return text;
                                        }
                                    },
                                    */
                }, {
                    title: this.getText(10830),
                    dataIndex: "mStatusDescription",
                    key: "mStatusDescription",
                    width: '20%',
                    sorter: true,
                    render: (text, record) => text,
                    /*
                                    render: (text, record) => {
                                        if (this.props.data[this.id].editing === record.key) {
                                            return (
                                                <Input
                                                    style={{ width: '98%', textAlign: 'center' }}
                                                    defaultValue={text}
                                                    onChange={(value) => {
                                                        this.props.dataActions.genericDataSetEdit(this.id, { mStatusDescription: value.target.value });
                                                    }} />);
                                        } else {
                                            return text;
                                        }
                                    },
                                    */
                },/* {
                title: 'משוב',
                dataIndex: "mCardcomResponse",
                key: "mCardcomResponse",
                width: '10%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { mCardcomResponse: value.target.value });
                                }} />);
                    } else {
                        return text;
                    }
                },
            },
            */
            ];

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >

                <ResponsiveTable dataSource={dataSource}
                    columns={columns}
                    // loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    tableOnly={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    percentageToMobile={[30, 35, 35]}
                // locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                />
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data ||
            (prevProps.toggle === false && this.props.toggle === true)) {

            console.log("prev", prevProps.ui.data, "next", this.props.ui.data);

            let request = this.initFilters();
            request._id = (this.props.ui.data) ? this.props.ui.data : 0;

            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                request
                /*{ _id: this.props.ui.data }*/,
                //this.api.get_suppliers
                this.api.get
            );
        }
    }
}

export default ModalCreatePriceList;

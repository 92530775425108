
import React from "react";
import {
    Card, DatePicker, Form, InputNumber, Input, Icon, Button, Popconfirm, Tooltip, Modal,
    Table, Select, Row, Col, Checkbox, message, Upload
} from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import Jax from "../Jax/jax.es6.module";
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
//import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import moment from "moment";
import LangContext from "../contextProvider/LangContext";
import DateTimePicker from "./DateTimePicker";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

message.config({ maxCount: 2, });

//type State = {}
class modalCreateEditEmployee extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            loadingImg: false,
        }

        this.id = 'CREATE_EDIT_EMPLOYEE';
        this.id_validator = 'IS_EMPLOYEE_EXIST';

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        //this.state.itemPick = null;
        //this.fieldsMeta = { unknown: { validateStatus: null, value: null } };

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.id_validator] === undefined) { props.data[this.id_validator] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorGroupEmployee] === undefined) {
            props.data[TableIDs.genericSelectorGroupEmployee] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_employee_by_id",
            edit: "edit_create_employee",
            selector: "get_employee_group_list",
            selector_storages: "get_storage_list",
            //get_tags: "get_customer_tags_by_id",
            //delete: "delete_customer", //"delete_priceList",
        }

        this.actionButtons = [
            /*{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/];

        this.dataSource = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorGroupEmployee, this.props.user.companyCode, this.props.user.token, this.api.selector);

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditEmployee);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditEmployee);
        this.props.dataActions.setJson(this.id_validator, { editedRecord: {} })
    }
    handleClear = () => {
        //this.clear();
    }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorGroupEmployee, this.props.user.companyCode, this.props.user.token, this.api.selector);

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });

    }

    sendAPI = (script, data, sCal, eCol) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, eCol);
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        if (this.isWorkerValid() === 'success') {

            const { headers, editedRecord, editing } = this.props.data[this.id];
            let dataSend = headers.join('\f') + "\r" + headers.map(x => editedRecord[x]).join('\f');

            this.sendAPI(this.api.edit, dataSend, (ob) => {
                if (ob.errorCode) {
                    message.error(ob.errorMessage);
                } else {

                    console.log(ob, 'editemployee')
                    message.success(this.getText(50621))
                    // refresh main table after change
                    this.props.ActionQueue.addToQueue({
                        action: this.props.dataActions.genericDataGetRequested,
                        args: ["EMPLOYEES_MAIN", this.props.user.companyCode, this.props.user.token, "get_employees_table_list"]
                    });

                    this.props.uiActions.hideModal(TableIDs.modalCreateEditEmployee);

                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate("EMPLOYEES_MAIN");
                    else this.props.dataActions.genericDataCancelEditing("EMPLOYEES_MAIN");
                }

            }, (e) => { message.error(e) })

            // this.props.ActionQueue.addToQueue({
            //     action: this.props.dataActions.genericDataJustSaveEdit,
            //     args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
            // })


        }
        else {
            message.warning(this.getText(11075));
        }
    }

    onEmployeeGroupButtonClick = () => {
        this.props.uiActions.showModal(TableIDs.modalCreateEditEmployeeGroup);
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    handleUpload = (info) => {
        if (info.file.status === "uploading") {
            this.setState({ loadingImg: true });
            return;
        }
        if (info.file.status === "done") {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, imageUrl => {
                this.props.dataActions.genericDataSetEdit(this.id, { ['mPictureFile']: imageUrl });
                this.setState({
                    loadingImg: false
                })
            });
        }
    }

    createCheckBox = () => {
        //this.setReportParameters("PaymentsCreditCard", { [field]: data });
        const list = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", [])
            .map((obj, index) => { return obj['code'] }).join(',');
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.props.dataActions.genericDataSetEdit(this.id, { ['mStorageList']: list })
                    else
                        this.props.dataActions.genericDataSetEdit(this.id, { ['mStorageList']: '' })
                }} />);
    }

    render() {
        const { loading, loadingImg } = this.state;
        const sqlFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm:ss";
        const dateFormat = "DD/MM/YYYY";

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        const uploadButton = (
            <div>
                <Icon type={loadingImg ? "loading" : "plus"} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        const groupList = this.props.data[TableIDs.genericSelectorGroupEmployee].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        });

        const listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'90%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>
                <Form id="create_edit_employee_form" //className="data-entry-form" //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    // style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '3' : '1' }}
                    onSubmit={this.handleSaveChanges}>
                    <Row>
                        <Col span={window.innerWidth > 600 ? 8 : 24}>
                            <FormItem
                                key={'mID'} label={this.getText(11076)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                                validateStatus={!this.isEmployeeIDValid() ? "" : this.isEmployeeIDNotUsed() ? 'success' : 'error'}
                                extra={ (!this.isEmployeeIDNotUsed())
                                        ? <label style={{ color: '#f5222d' }}>{this.getText(11078)}</label>
                                        : <label></label>
                                }
                                hasFeedback
                                required={true}
                            >
                                <Input
                                    value={this.props.data[this.id].editedRecord['mID']}
                                    disabled={(this.props.ui.data > 0) ? true : false}
                                    onChange={(value) => {
                                        if (parseInt(value.target.value) || value.target.value === "") {
                                            // for validation
                                            this.props.dataActions.genericDataGetEditWithParams(
                                                this.id_validator,
                                                this.props.user.companyCode, this.props.user.token,
                                                { _id: value.target.value }, this.api.get
                                            );
                                            // for form
                                            this.props.dataActions.genericDataSetEdit(this.id, { ['mID']: value.target.value });
                                        } else {
                                            message.error(this.getText(18396))
                                        }
                                    }} />
                            </FormItem>

                            <FormItem
                                key={'mName'} label={this.getText(11079)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                                //validateStatus={}
                                validateStatus={(this.isNameValid() ? 'success' : 'error')}
                                extra={<label style={(this.isNameValid()/* === 'success'*/) ? { display: 'none' } : { color: '#f5222d' }}>{this.getText(11096)}</label>}
                                required={true}
                            >
                                <Input
                                    value={this.props.data[this.id].editedRecord['mName']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mName']: value.target.value });
                                    }
                                    } />
                            </FormItem>
                            <FormItem
                                key={'mIdentification'} label={this.getText(11080)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                                //validateStatus={}
                                validateStatus={this.isIDValid() ? 'success' : 'error'}
                                extra={<label style={(this.isIDValid()/* === 'success'*/) ? { display: 'none' } : { color: '#f5222d' }}>{this.getText(11097)}</label>}
                                required={true}

                            >
                                <Input
                                    value={this.props.data[this.id].editedRecord['mIdentification']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mIdentification']: value.target.value });
                                    }}
                                />
                            </FormItem>
                            <FormItem
                                key={'mAddress'} label={this.getText(11081)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <Input
                                    value={this.props.data[this.id].editedRecord['mAddress']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mAddress']: value.target.value });
                                    }
                                    } />
                            </FormItem>
                            <FormItem
                                key={'mMobilePhone'} label={this.getText(11082)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <Input
                                    value={this.props.data[this.id].editedRecord['mMobilePhone']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mMobilePhone']: value.target.value });
                                    }
                                    } />
                            </FormItem>
                            <FormItem
                                key={'mHomePhone'} label={this.getText(11083)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <Input
                                    value={this.props.data[this.id].editedRecord['mHomePhone']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mHomePhone']: value.target.value });
                                    }
                                    } />
                            </FormItem>
                            <FormItem
                                key={'mEmail'} label={this.getText(11091)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <Input
                                    value={this.props.data[this.id].editedRecord['mEmail']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mEmail']: value.target.value });
                                    }
                                    } />
                            </FormItem>
                        </Col>
                        <Col span={window.innerWidth > 600 ? 8 : 24}>
                            <FormItem
                                key={'mRemarks'} label={this.getText(11084)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <TextArea
                                    value={this.props.data[this.id].editedRecord['mRemarks']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mRemarks']: value.target.value });
                                    }}
                                    autosize={{ minRows: 2, maxRows: 5 }}
                                />
                            </FormItem>

                            <FormItem
                                key={'mPassword'} label={this.getText(11087)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <Input
                                    value={this.props.data[this.id].editedRecord['mPassword']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mPassword']: value.target.value });
                                    }
                                    } />
                            </FormItem>
                            <FormItem
                                key={'mMagneticCard'} label={this.getText(11089)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <Input
                                    value={this.props.data[this.id].editedRecord['mMagneticCard']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mMagneticCard']: value.target.value });
                                    }}
                                />
                            </FormItem>
                            <FormItem
                                key={'mPictureFile'} label={this.getText(11088)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <PictureView
                                    user={this.props.user} data={this.props.data} dataActions={this.props.dataActions} ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                                    url={this.props.data[this.id].editedRecord['mPictureFile']}
                                    onChange={(url) => this.props.dataActions.genericDataSetEdit(this.id, { mPictureFile: url })}
                                    onRemove={() => this.props.dataActions.genericDataSetEdit(this.id, { mPictureFile: '' })}
                                    onUploadedSuccess={(response) => { }}
                                    onUploadedFailed={(errorMessage, errorCode) => { }}
                                    imageType={'E'}
                                    aspect={1}
                                />
                            </FormItem>
                            <FormItem
                                key={'mBirthDate'}
                                label={this.getText(16695)}
                                style={{ marginBottom: 8 }}
                                help={''}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 12 }}>
                                <DateTimePicker
                                    format="DD/MM/YYYY"
                                    value={this.props.data[this.id].editedRecord['mBirthDate']}
                                    onChange={(e) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mBirthDate']: e });
                                    }} />
                            </ FormItem>
                        </Col>
                        <Col span={window.innerWidth > 600 ? 8 : 24}>

                            <FormItem
                                key={'mStartDate'} label={this.getText(11090)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <DatePicker
                                    value={(this.props.data[this.id].editedRecord['mStartDate'] === '') ? null :
                                        moment(this.props.data[this.id].editedRecord['mStartDate'], sqlFormat)}
                                    //defaultValue={moment('2000/01/01', dateFormat)}
                                    format={dateFormat}
                                    onChange={(date: moment, dateString: string) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mStartDate']: date != null ? date.format("YYYY-MM-DD") : "" });
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                key={'mHourRate'} label={this.getText(17060)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <InputNumber
                                    precision={2} step={1}
                                    value={this.props.data[this.id].editedRecord['mHourRate']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mHourRate']: value });
                                    }}
                                    type={'float'}
                                />
                            </FormItem>
                            <FormItem
                                key={'mGroupID'} label={this.getText(11085)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                            //validateStatus={}
                            >
                                <Select
                                    value={this.props.data[this.id].editedRecord['mGroupID']}
                                    defaultValue={'0'}
                                    style={{ width: '60%', paddingLeft: '4px' }}
                                    onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mGroupID']: e }) }}
                                >
                                    {groupList}
                                </Select>
                                <Button key="showEmployeeGroupModal" type="primary"
                                    onClick={this.onEmployeeGroupButtonClick}>{this.getText(11098)}</Button>
                            </FormItem>
                            <FormItem
                                key={'mMaxDiscount'} label={this.getText(11086) + ' (%)'} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <InputNumber
                                    min={0} max={100} precision={2} step={0.1}
                                    value={this.props.data[this.id].editedRecord['mMaxDiscount']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mMaxDiscount']: value });
                                    }}
                                    //formatter={value => value.replace('%', '')}
                                    //parser={value => value.replace('%', '')}
                                    type={'float'}
                                    validator={(rule, value, maxDiscountCallback) => {

                                    }}
                                />
                            </FormItem>
                            <FormItem
                                key={'mIsSeller'} label={this.getText(11092)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <Checkbox
                                    //onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsIncludeVat", record); }}
                                    onChange={(e) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsSeller']: (e.target.checked) ? 1 : 0 });
                                    }}
                                    checked={(this.props.data[this.id].editedRecord['mIsSeller'] == 1) ? true : false}
                                    defaultChecked={false}
                                    //disabled={!(this.props.data[this.id].editing === record.key)} />
                                    disabled={false} />
                            </FormItem>
                            <FormItem
                                key={'mIsActive'} label={this.getText(11093)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            //validateStatus={}
                            >
                                <Checkbox
                                    //onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsIncludeVat", record); }}
                                    onChange={(e) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsActive']: (e.target.checked) ? 1 : 0 });
                                    }}
                                    checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                                    defaultChecked={false}
                                    //disabled={!(this.props.data[this.id].editing === record.key)} />
                                    disabled={false} />
                            </FormItem>
                            < FormItem
                        /*key={'mStorageList'}*/ label={this.getText(11094)}
                                validateStatus={this.isStoragesValid() ? 'success' : 'error'}
                                /////// hasFeedback={false} //help={"בלה בלה"}
                                extra={<label style={(this.isStoragesValid()/* === 'success'*/) ? { display: 'none' } : { color: '#f5222d' }}></label>}
                                required={true}
                                style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                            >
                                {this.createCheckBox()}
                                <Select key={"mStorageList"} style={{ width: '90%' }}
                                    placeholder={this.getText(11099)}
                                    mode="multiple"
                                    maxTagCount={6}
                                    showsearch
                                    filterOption={true}
                                    optionFilterProp="valuefilter"
                                    //defaultValue={0}
                                    onChange={(e) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mStorageList']: e.join(',') });
                                    }}
                                    value={(this.props.data[this.id].editedRecord['mStorageList']) ?
                                        this.props.data[this.id].editedRecord['mStorageList'].split(',') : []}
                                //notFoundContent={"לא נמצאו עובדים"}       
                                >
                                    {listStorage}
                                </Select>
                            </ FormItem>

                        </Col>
                    </Row>

                </Form>
                <div className="action_footer">
                    <Button
                        type="primary" htmlType="submit" form="create_edit_employee_form"
                        loading={this.props.ui.workInProgress}
                        className={"form-button-save"} icon={"check"}>{this.getText(11095)}</Button>

                </div>
            </Modal >
        )
    }

    isWorkerValid = () => {
        const isValid =
            this.isNameValid()
            && this.isIDValid()
            && this.isStoragesValid()
            // && this.isEmployeeIDValid()
            && this.isEmployeeIDNotUsed()

        return (isValid) ? 'success' : 'error';
    }

    isStoragesValid = () => {
        let list = (this.props.data[this.id].editedRecord['mStorageList']);
        return (list === '' || list === null || list === []) ? false : true;
    }

    isNameValid = () => {
        let name = (this.props.data[this.id].editedRecord['mName']);
        return (name === '' || name === null) ? false : true;
    }

    isEmployeeIDValid = () => {
        let name = (this.props.data[this.id].editedRecord['mID']);
        return (name === '' || name === null) ? false : true;
    }

    isEmployeeIDNotUsed = () => {
        let validator = this.props.data[this.id_validator].editedRecord['mID'];
        let formEmployeeID = this.props.data[this.id].editedRecord['mID'];
        return (validator && validator === formEmployeeID) ? false : true;
    }

    isIDValid = () => {
        let id = (this.props.data[this.id].editedRecord['mIdentification']);
        return (id === '' || id === null) ? false : true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toggle === false && this.props.toggle === true) {
            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                { _id: this.props.ui.data },
                //{ _id: (id_data) ? id_data : this.props.ui.data },
                this.api.get
            );
        }
    }
}

export default modalCreateEditEmployee;

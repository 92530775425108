/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import TableIDs from "../data/TableIDs";
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import GenericModal from "./GenericModal";
import ResponsiveTable from "./ResponsiveTable";
import { Button, Checkbox, Input, InputNumber, Row, Col, Icon, Tooltip, message, Select, Popconfirm, Form, Radio } from "antd";
import GenericSelector from "./GenericSelector";
import TableLineIcons from "./TableLineIcons";
import GenericSelectorPosStorages from "./GenericSelectorPosStorages"

const { Option } = Select;
class ModalDocumentPrintOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalFormats: false,
            modalCopy: false,
            modalCopyPosList: "",
            modalMoreSettings: false,
        }

        this.id = "DOCUMENT_POS_PRINT_OPTIONS";

        this.api = {
            get: "get_document_pos_print_options",
            create_edit: "create_edit_document_pos_print_options",
            delete: "delete_document_pos_print_options",
            delete_cust: "delete_document_cust_print_options",
            copy: "copy_document_pos_print_options",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorDocumentTypes, api: "get_document_types_list" },
            { id: "DOC_PRINT_FORMATS", api: "doc_print_formats_list" },
            { id: "QUESTIONNAIRE_LIST", api: "get_questionnaire_list" },
            { id: TableIDs.genericSelectorItemTagTypes, api: "get_tag_types_with_relation_list", optionsGroup: true },
        ];

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector }
        });

        this.actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }, {
                buttonType: 'add',
                buttonFunction: this.addRow
            }
        ];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.selectors[0].id, this.props.user.companyCode, this.props.user.token, this.selectors[0].api);

        this.sendAPI(this.selectors[1].api, "", ob => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return { code: y[0], desc: y[1], image: y[2], isForBO: y[3] }
            }) : []
            this.props.dataActions.setJson(this.selectors[1].id, { dataset });
        })
    }

    componentDidUpdate(prev) {
        if (!prev.visible && this.props.visible) this.refreshData()
    }

    refreshData = () => {
        const { visible, isCustSetting, isBO } = this.props;

        const request = {
            _posId: isCustSetting ? null : visible,
            _custId: isCustSetting ? visible : null,
            _isBO: isBO
        }

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    addRow = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
        this.props.dataActions.genericDataSetEdit(this.id, { _PrintWithTax: '1', _Copies: 0 });
    }

    renderDocTypeId = (text, record) => {
        const { editing, editedRecord, data } = this.props.data[this.id];
        const { dataset } = this.props.data[this.selectors[0].id];

        if (record.key === editing) {
            return (<GenericSelector {...this.props} id={this.selectors[0].id} value={editedRecord._DocTypeId} onChange={e => {
                let isExists = data.filter(f => {
                    let y = f.split("\f");
                    return y[1] === e && y[0] !== editing;
                })
                if (isExists.length) {
                    message.error(this.getText(17415))
                } else {
                    let sData = dataset.find(f => f.code === e)
                    this.props.dataActions.genericDataSetEdit(this.id, { _DocTypeId: e, _DocName: sData ? sData.name : "" });
                }
            }} />)
        } else {
            return text;
        }
    }

    renderDocName = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];
        if (record.key === editing) {
            return (<Input value={editedRecord._DocName} onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.id, { _DocName: e.target.value });
            }} />)
        } else {
            return text;
        }
    }

    renderPrintFormatId = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];
        const { dataset } = this.props.data[this.selectors[1].id];
        if (record.key === editing) {
            let sData = dataset.find(f => f.code === editedRecord._PrintFormatId);
            let value = sData ? sData.desc : "";
            return (<Row>
                <Col span={4}>
                    <Tooltip title={this.getText(17409)}>
                        <Button type="primary" onClick={() => { this.setState({ modalFormats: true }) }}>
                            <Icon type="menu" />
                        </Button>
                    </Tooltip>
                </Col>
                <Col span={20}>
                    {this.formatView(value)}
                </Col>
            </Row>)
        } else {
            let sData = dataset.find(f => f.code === text);
            let value = sData ? sData.desc : "";
            return this.formatView(value)
        }
    }

    renderPrintWithTax = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];
        return (<Checkbox
            disabled={record.key !== editing}
            checked={record.key === editing ? editedRecord._PrintWithTax == '1' : text == '1'}
            onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.id, { _PrintWithTax: e.target.checked ? '1' : '0' });
            }} />)
    }

    renderCopies = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];
        if (record.key === editing) {
            return (<InputNumber value={editedRecord._Copies} onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.id, { _Copies: e });
            }} />)
        } else {
            return text;
        }
    }

    renderEditCol = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];
        if (record.key === editing) {
            return (<TableLineIcons
                onCheck={() => {
                    if (!editedRecord._DocTypeId) {
                        message.error(this.getText(17416))
                    } else if (!editedRecord._PrintFormatId) {
                        message.error(this.getText(17417))
                    } else {
                        const { isCustSetting, visible } = this.props;
                        this.props.dataActions.genericDataSaveEditWithParams(
                            this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, {
                            _PosId: isCustSetting ? null : visible,
                            _CustId: isCustSetting ? visible : null,
                        });
                    }
                }}
                onClose={() => {
                    if (editing == '0') this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }} />)
        } else {
            return (<TableLineIcons
                onEdit={() => {
                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                }}
                deleteQuetion={{ title: this.getText(17398), okText: this.getText(17399), cancelText: this.getText(17400) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id,
                        record.key,
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.props.isCustSetting ? this.api.delete_cust : this.api.delete,
                        this.api.get);
                }}
                genericIcons={this.props.isCustSetting ? null : [
                    {
                        type: "setting",
                        tooltip: this.getText(19632),
                        onClick: () => {
                            this.setState({ modalMoreSettings: true }, () => {
                                this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                            })
                        }
                    }
                ]} />)
        }
    }

    formatView = (value) => {
        let boxViewStyle = {
            display: 'inline-block',
            verticalAlign: 'text-top',
            margin: 1,
            padding: 2,
            border: '1px solid black',
            background: 'white'
        }

        let frameStyle = {
            border: '2px solid blue',
            borderRadius: 5,
            background: 'lightblue',
            height: 65
        }

        const boxView = (parts, content) => {
            boxViewStyle = { ...boxViewStyle, width: Math.floor(90 / parts) + "%" }
            switch (content) {
                case '1': return (<div style={boxViewStyle}>{this.getText(17401)}</div>)
                case '2': return (<div style={boxViewStyle}>{this.getText(17402)}</div>)
                case '3': return (<div style={boxViewStyle}>{this.getText(17403)}</div>)
                case '4': return (<div style={boxViewStyle}>{this.getText(17404)}</div>)
                case '5': return (<div style={boxViewStyle}>{this.getText(17405)}</div>)
                case '6': return (<div style={boxViewStyle}>{this.getText(17406)}</div>)
                case '7': return (<div style={boxViewStyle}>{this.getText(17407)}</div>)
                case '8': return (<div style={boxViewStyle}>{this.getText(17408)}</div>)

                case 'a': return (<div style={boxViewStyle}>{this.getText(19205)}</div>)
                case 'b': return (<div style={boxViewStyle}>{this.getText(19206)}</div>)
                case 'c': return (<div style={boxViewStyle}>{this.getText(19207)}</div>)
                case 'd': return (<div style={boxViewStyle}>{this.getText(19552)}</div>)
                case 'e': return (<div style={boxViewStyle}>{this.getText(19647)}</div>)
                case 'f': return (<div style={boxViewStyle}>{this.getText(19703)}</div>)
                case 'g': return (<div style={boxViewStyle}>{this.getText(19704)}</div>)
            }
        }

        let rows = value ? value.split(",") : []
        return (<div style={frameStyle}>{rows.map(x => (<div>{x ? x.split("").map(y => boxView(x.length, y)) : ""}</div>))}</div>)
    }

    formatImage = (text) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        let baseUrl = isDev ? "http://147.235.163.248" : origin;
        return text ? (<img src={baseUrl + '/uploads/shared/' + text} height="65" />) : ""
    }

    formatCheckButton = (text, record) => {
        return (<Button
            type={record.code === this.props.data[this.id].editedRecord._PrintFormatId ? "primary" : undefined}
            onClick={() => {
                this.setState({ modalFormats: false }, () => { this.props.dataActions.genericDataSetEdit(this.id, { _PrintFormatId: record.code }) })
            }}>{this.getText(17414)}</Button>)
    }

    renderQtySummaryOption = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];
        return (<Select
            style={{ width: "100%" }}
            disabled={record.key !== editing}
            value={record.key === editing ? editedRecord._QtySummaryOption : text}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { _QtySummaryOption: e }) }} >
            <Option value={'0'}>{this.getText(19211)}</Option>
            <Option value={'1'}>{this.getText(19212)}</Option>
            <Option value={'2'}>{this.getText(19213)}</Option>
            <Option value={'3'}>{this.getText(19214)}</Option>
        </Select>)
    }

    renderAttachedPages = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];
        return (<Select
            style={{ width: "100%" }}
            disabled={record.key !== editing}
            value={record.key === editing ? editedRecord._AttachedPages : text}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { _AttachedPages: e }) }} >
            <Option value={'0'}>{this.getText(19215)}</Option>
            <Option value={'1'}>{this.getText(19216)}</Option>
            <Option value={'2'}>{this.getText(19217)}</Option>
        </Select>)
    }

    renderTotalSummaryOption = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];
        return (<Select
            style={{ width: "100%" }}
            disabled={record.key !== editing}
            value={record.key === editing ? editedRecord._TotalSummaryOption : text}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { _TotalSummaryOption: e }) }} >
            <Option value={'0'}>{this.getText(19218)}</Option>
            <Option value={'1'}>{this.getText(19219)}</Option>
        </Select>)
    }

    onCopy = () => {
        this.sendAPI(this.api.copy, "_pos\f_targetPosList\r" + this.props.visible + "\f" + this.state.modalCopyPosList, () => {
            message.success(this.getText(19409))
            this.setState({ modalCopy: false, modalCopyPosList: "" })
        })
    }

    render() {
        const gt = this.getText;
        const { isCustSetting } = this.props;
        const columns = [
            { title: gt(17391), key: "_DocTypeId", dataIndex: "_DocTypeId", width: "5%", render: this.renderDocTypeId },
            { title: gt(17392), key: "_DocName", dataIndex: "_DocName", width: "10%", render: this.renderDocName },
            { title: gt(17393), key: "_PrintFormatId", dataIndex: "_PrintFormatId", width: "34%", render: this.renderPrintFormatId },
            { title: gt(17394), key: "_PrintWithTax", dataIndex: "_PrintWithTax", width: "5%", render: this.renderPrintWithTax },
            { title: gt(17395), key: "_Copies", dataIndex: "_Copies", width: "5%", render: this.renderCopies },
            { title: gt(19208), key: "_QtySummaryOption", dataIndex: "_QtySummaryOption", width: "12%", render: this.renderQtySummaryOption },
            { title: gt(19209), key: "_AttachedPages", dataIndex: "_AttachedPages", width: "12%", render: this.renderAttachedPages },
            { title: gt(19210), key: "_TotalSummaryOption", dataIndex: "_TotalSummaryOption", width: "12%", render: this.renderTotalSummaryOption },
            { title: gt(17396), width: "5%", render: this.renderEditCol },
        ]

        const formatColumns = [
            { title: gt(17410), key: "code", dataIndex: "code", width: "7%", render: t => t },
            { title: gt(17411), key: "desc", dataIndex: "desc", width: "50%", render: this.formatView },
            { title: gt(17412), key: "image", dataIndex: "image", width: "35%", render: this.formatImage },
            { title: "", width: "8%", render: this.formatCheckButton },
        ]

        const formatsDataset = this.props.data[this.selectors[1].id].dataset.filter(f => f.isForBO == this.props.isBO)

        const pagination = { pageSize: Math.ceil((window.innerHeight - 300) / 70) }

        return (<GenericModal
            {...this.props}
            title={gt(isCustSetting ? 19503 : 17390)}
            actionButtons={this.actionButtons}
            genericActionButtons={isCustSetting ? null : [
                <Tooltip title={gt(19405)}>
                    <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => { this.setState({ modalCopy: true }) }}>
                        <Icon type={"copy"} style={{ fontSize: "16px" }} />
                    </Button>
                </Tooltip>
            ]}>
            {isCustSetting ?
                <Radio.Group
                    value={this.props.isBO}
                    onChange={e => { this.props.onChangeIsBo(e.target.value, this.refreshData) }}>
                    <Radio value={'0'}>{gt(19504)}</Radio>
                    <Radio value={'1'}>{gt(19505)}</Radio>
                </Radio.Group>
                : ""}
            <ResponsiveTable
                tableOnly
                idTable={this.props.data[this.id]}
                columns={columns}
                pagination={pagination}
            />
            <GenericModal
                title={gt(17409)}
                visible={this.state.modalFormats}
                onCancel={() => { this.setState({ modalFormats: false }) }}>
                <ResponsiveTable
                    tableOnly
                    dataSource={formatsDataset}
                    columns={formatColumns}
                    pagination={pagination}
                />
            </GenericModal>
            <GenericModal
                title={gt(19632)}
                visible={this.state.modalMoreSettings}
                onCancel={() => { this.setState({ modalMoreSettings: false }) }}
                width={500}
                footer={[
                    <Button onClick={() => {
                        this.setState({ modalMoreSettings: false }, () => {
                            this.props.dataActions.genericDataCancelEditing(this.id);
                        })
                    }}>{gt(19633)}</Button>,
                    <Button type={"primary"} onClick={() => {
                        this.setState({ modalMoreSettings: false }, () => {
                            this.props.dataActions.genericDataSaveEditWithParams(
                                this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, {
                                _PosId: this.props.visible,
                                _CustId: null
                            });
                        })
                    }}>{gt(19634)}</Button>
                ]}>
                <Form.Item>
                    <Checkbox
                        checked={this.props.data[this.id].editedRecord._SendToSign == '1'}
                        onChange={e => {
                            this.props.dataActions.genericDataSetEdit(this.id, { _SendToSign: e.target.checked ? '1' : '0' });
                        }}>{gt(19635)}</Checkbox>
                </Form.Item>
                <Form.Item label={gt(19636)}>
                    <GenericSelector {...this.props} {...this.selectors[2]}
                        value={this.props.data[this.id].editedRecord._QuestionnaireId}
                        onChange={e => {
                            this.props.dataActions.genericDataSetEdit(this.id, { _QuestionnaireId: e });
                        }} />
                </Form.Item>
                <Form.Item label={gt(19637)}>
                    <InputNumber 
                        value={this.props.data[this.id].editedRecord._QuestionSignId}
                        onChange={e => {
                            this.props.dataActions.genericDataSetEdit(this.id, { _QuestionSignId: e });
                        }} />
                </Form.Item>
                <Form.Item label={gt(19638)}>
                <GenericSelector {...this.props} {...this.selectors[3]}
                        value={this.props.data[this.id].editedRecord._TagTypeId}
                        onChange={e => {
                            this.props.dataActions.genericDataSetEdit(this.id, { _TagTypeId: e });
                        }} />
                </Form.Item>
            </GenericModal>
            <GenericModal
                title={gt(19405)}
                width={400}
                visible={this.state.modalCopy}
                onCancel={() => { this.setState({ modalCopy: false }) }}
                footer={[
                    <Button onClick={() => { this.setState({ modalCopy: false }) }}>{gt(19408)}</Button>,
                    <Button type={"primary"} onClick={this.onCopy}>{gt(19407)}</Button>
                ]}>
                <Form.Item label={gt(19406)}>
                    <GenericSelectorPosStorages {...this.props} multi
                        value={this.state.modalCopyPosList?.split(",") ?? []}
                        onChange={e => { this.setState({ modalCopyPosList: e?.join(",") ?? "" }) }}
                    />
                </Form.Item>
            </GenericModal>
        </GenericModal>)
    }
}

export default ModalDocumentPrintOptions;
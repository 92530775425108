/* @flow */
/*jshint esversion: 6 */
import { Form, Select } from "antd";
import { Row, Col, DatePicker } from "antd";
import React, { Component } from "react";
import "../../App.css";
import GenericSelector from "../../components/GenericSelector";
import ReportTypeButton from "../../components/ReportTypeButton";
import DateTimePicker from "../../components/DateTimePicker";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import moment from "moment";
import { disabledDatePicker } from "../../utils/utils";

const { MonthPicker } = DatePicker;

class MonthlyJoinRate extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _fromDate: null,
            _toDate: null,
            // _fromYear: null,
            // _toYear: null,
            // _fromMonth: null,
            // _toMonth: null,
            _branchList: '',
            _reportType: '2',
            _calcType: '1',
            reportName: "reports/MonthlyJoinRate.pdf"
        };

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(17962);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 700,
            margin: 'auto'
        };

        const { _fromDate, _toDate, _branchList, _reportType, _calcType } = this.state;
        // const { _fromYear, _toYear, _fromMonth, _toMonth, _branchList } = this.state;

        return (<div style={divStyle}>
            <PageToolbar title={gt(17963)} actionButtons={[]} />
            <Row>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(17964)}>
                        <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_fromDate}
                            onChange={e => { this.setState({ _fromDate: e }) }}
                            maxDate={_toDate}
                        />
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(17965)}>
                        <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_toDate}
                            onChange={e => { this.setState({ _toDate: e }) }}
                            minDate={_fromDate}
                        />
                    </Form.Item>
                </Col>
                {/* <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(17964)}>
                        <MonthPicker
                            format="MM/YYYY"
                            value={_fromYear && _fromMonth ? moment(_fromYear + _fromMonth, "YYYYMM") : null}
                            onChange={e => {
                                this.setState({
                                    _fromMonth: e ? moment(e).format("MM") : null,
                                    _fromYear: e ? moment(e).format("YYYY") : null
                                })
                            }}
                            disabledDate={(d) => disabledDatePicker(d, null, _toYear && _toMonth ? _toYear + "-" + _toMonth + "-01" : null)}
                        />
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(17965)}>
                        <MonthPicker
                            format="MM/YYYY"
                            value={_toYear && _toMonth ? moment(_toYear + _toMonth, "YYYYMM") : null}
                            onChange={e => {
                                this.setState({
                                    _toMonth: e ? moment(e).format("MM") : null,
                                    _toYear: e ? moment(e).format("YYYY") : null
                                })
                            }}
                            disabledDate={(d) => disabledDatePicker(d, _fromYear && _fromMonth ? _fromYear + "-" + _fromMonth + "-01" : null, null)}
                        />
                    </Form.Item>
                </Col> */}
            </Row>
            <Form.Item label={gt(17966)}>
                <GenericSelector
                    {...this.props}
                    id={TableIDs.genericSelectorBranches}
                    api={"get_branch_list"}
                    multi
                    value={_branchList ? _branchList.split(",") : []}
                    onChange={e => this.setState({ _branchList: e ? e.join(",") : "" })}
                />
            </Form.Item>
            <Form.Item label={gt(18272)}>
                <Select value={_reportType} onChange={e => this.setState({ _reportType: e })} style={{ width: 250 }}>
                    <Select.Option value={"2"}>{gt(18274)}</Select.Option>
                    <Select.Option value={"3"}>{gt(18487)}</Select.Option>
                    <Select.Option value={"1"}>{gt(18273)}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label={gt(18490)}>
                <Select value={_calcType} onChange={e => this.setState({ _calcType: e })} style={{ width: 250 }}>
                    <Select.Option value={"1"}>{gt(18488)}</Select.Option>
                    <Select.Option value={"2"}>{gt(18489)}</Select.Option>
                </Select>
            </Form.Item>
            <ReportTypeButton
                {...this.props}
                params={this.state}
                branchesField={"_branchList"}
                datesToFavorites={[
                    { field: '_fromDate', label: gt(17964) },
                    { field: '_toDate', label: gt(17965) },
                ]}
                //  datesToFavorites={[
                //     { fields: ["_fromMonth", "_fromYear"], label: gt(17964), format: 2 },
                //     { fields: ["_toMonth", "_toYear"], label: gt(17965), format: 2 },
                // ]}
                favoriteSave
            />
        </div>)
    }

}

export default MonthlyJoinRate;
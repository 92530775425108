/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import {
    Table, Icon, Select, Card, InputNumber, Checkbox, Popconfirm,
    Button, Input, message, Upload, List, notification
} from "antd";

import PageToolbar from "../../components/PageToolbar";
import TableIDs from "../../data/TableIDs";
import Jax from "../../Jax/jax.es6.module";
import { fixCurrentPage } from "../../utils/utils";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
// import reqwest from 'reqwest';
import LangContext from "../../contextProvider/LangContext";


const { Option, OptGroup } = Select;
const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class CustomerReadFile extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "CUSTOMER_READ_FILE";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state.fileList = [];
        this.state.uploading = false;

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        // this.api = {
        // get: "get_customers",
        // create: "",
        // edit: "",
        // delete: "delete_customer",
        // selector: "",
        // modalPriceListGet: "",
        // };

        this.actionButtons = [/* {
                buttonType: 'add',
                buttonFunction: () => {
                    this.props.uiActions.showModal(TableIDs.modalCreateEditCustomer, null);
                }
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        */];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - לקוחות";
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10156);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    getBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(file);
    }

    handleUpload = () => {
        const { fileList } = this.state;

        this.getBase64(fileList[0] /*info.file.originFileObj*/,
            fileUrl => {
                console.log("xxxx: ", fileUrl);
                //console.log("yyyy: ", fileUrl.split(',')[1]);
                this.setState({ fileUrl, loading: false, },
                    () => {
                        console.log("yyyy: ", fileUrl);
                        let user = new User(this.props.user.token, this.props.user.companyCode);
                        let job = new Job(user, /*"upload_image"*/"upload_customer_file",
                            //OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC);
                            OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                        //job.setSection("file", fileUrl);
                        job.setInput(fileUrl.split(',')[1]);
                        //job.setInput(fileUrl);
                        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
                    }
                )
            });
    }

    upload = (formData) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "upload_image"/*"upload_customer_file"*/,
            OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC);
        //OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);

        job.setSection("data", formData);
        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
    }

    successCallback = (ob: Object) => {
        const getHead = (ind) => {
            var i = ind.toString();
            switch (i) {
                case '0': return this.getText(10928);
                case '1': return this.getText(10929);
                case '2': return this.getText(10930);
                case '3': return this.getText(10931);
                default: return '';
            }
        }
        console.log("success: " + ob.data, 2);
        // blue
        const dataRow = (ob.data) ? ob.data.split('\r')[1] : [];
        const listData = (dataRow) ? dataRow.split('\f') : [];
        const listJson = listData.map((item, index) => { return { data: item, head: getHead(index) } })
        //message.success(" רשומות נקלטו בהצלחה " /*+ ob*/, 2);
        if (ob.data) {
            notification.config({ duration: 0 });
            notification.success({
                message: this.getText(10932),
                description: (<div style={{ direction: 'rtl' }}>
                    <List
                        header={<div>{this.getText(10933)}</div>}
                        //footer={<div>Footer</div>}
                        dataSource={listJson}
                        renderItem={item => (<List.Item>{item.head} : {item.data}</List.Item>)}
                    />
                </div>),
            });
        }

        this.setState({ uploading: false })
    }

    errorCallback = (error: any) => {
        //console.log(JSON.stringify(error));
        message.error(" " + this.getText(10934) + " " + JSON.stringify(error), 2);
        this.setState({ uploading: false })
    }

    handleDownload = () => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "download_failed_customer_file",
            //OutputType.OUTPUT_TYPE_REPORT_A4, ProcessType.PROCESS_TYPE_SYNC);
            //OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC);
            OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        //job.setSection("file", fileUrl);
        //job.setInput();
        //job.setInput(record["mId"]);
        job.send("/cgi-bin/CashOnTab", this.downloadSuccessCallback, this.downloadErrorCallback);
    }

    downloadSuccessCallback = (ob: Object) => {
        message.success(this.getText(10935));
        console.log("success:", ob.data);

        var text = ob.data,
            blob = new Blob([text.replace(/([^\r])\n/g, "$1\r\n")], { type: 'text/plain' }),
            anchor = document.createElement('a');
        anchor.download = 'customer_error_log' + '.txt';
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
        anchor.click();
    }

    downloadErrorCallback = (error: any) => {
        message.error(this.getText(10936) + " " + JSON.Stringify(error));
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        const { uploading, fileList } = this.state;
        const props = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file) => {
                console.log("file: ", file);
                this.setState(
                    state => ({ fileList: [...state.fileList, file], })
                );
                return false;
            },
            fileList,
        };

        return (
            <div style={divStyle}>
                <Card hoverable={true}
                    bordered={false}
                    style={{ width: 300 }}
                    cover={<PageToolbar
                        title={this.getText(10924)}
                        actionButtons={this.actionButtons}
                        //showsearch={(val) => {
                        //    this.props.dataActions.genericDataSetFilter(
                        //        this.id, this.props.user.companyCode, this.props.user.token,
                        //        { ...request, _words: val }, this.api.get)
                        //}}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions} />}

                    actions={false}

                >
                    <div>
                        <Upload {...props}>
                            <Button disabled={fileList.length !== 0}>
                                <Icon type="upload" />{this.getText(10925)}
                            </Button>
                        </Upload>
                        <Button
                            type="primary"
                            onClick={() => { this.setState({ uploading: true }, this.handleUpload) }}
                            disabled={fileList.length === 0}
                            loading={uploading}
                            style={{ marginTop: 16 }}
                        >
                            {uploading ? this.getText(10926) : this.getText(10927)}
                        </Button>
                        <Button
                            //type="primary"
                            onClick={this.handleDownload}
                            //disabled={fileList.length === 0}
                            //loading={uploading}
                            style={{ marginTop: 16, marginRight: 8 }}
                        >
                            <Icon type="download" />
                        </Button>
                    </div>
                </Card>
            </div>
        )
    }
}
export default CustomerReadFile;


import React from "react";
import { Input, InputNumber, DatePicker, TimePicker, Checkbox } from 'antd';
import moment from "moment";
import LockPicker from "./LockPicker";

class AnswerTypeInput extends React.Component {

    render() {
        switch (this.props.type) {
            case 'T': return (
                <Input
                    style={this.props.style}
                    value={this.props.value}
                    onChange={(value) => { this.props.onChange(value.target.value) }}
                />);
            case 'I': case 'M': return (
                <InputNumber
                    min={0}
                    step={1}
                    precision={this.props.type === 'M' ? 2 : 0}
                    style={this.props.style}
                    value={this.props.type === 'M' ? this.props.value ? parseFloat(this.props.value).toFixed(2) : '0.00' : this.props.value}
                    onChange={(value) => { this.props.type === 'M' ? this.props.onChange(parseFloat(value).toFixed(2)) : this.props.onChange(value) }}
                />);
            case 'H': return (
                <TimePicker
                    style={this.props.style}
                    value={(this.props.value) ? moment(this.props.value, "hh:mm:ss") : null}
                    format={"hh:mm:ss"}
                    onChange={(value) => { this.props.onChange(value.format("hh:mm:ss")) }}
                />);
            case 'D': return (
                <DatePicker
                    style={this.props.style}
                    value={(this.props.value) ? moment(this.props.value, "DD/MM/YYYY") : null}
                    format={"DD/MM/YYYY"}
                    onChange={(value) => { this.props.onChange(value.format("DD/MM/YYYY")) }}
                />);
            case 'B': return (
                <Checkbox
                    style={this.props.style}
                    checked={this.props.value === '1' ? true : false}
                    onChange={(value) => { this.props.onChange(value.target.checked ? '1' : '0') }}
                />);
            case 'L': return (
                <LockPicker
                    style={this.props.style}
                    value={this.props.value ? JSON.parse(this.props.value) : { size: 4, content: [] }}
                    onChange={(point) => {
                        if (point === false) {
                            this.props.onChange('');
                        } else {
                            let json = this.props.value ? JSON.parse(this.props.value) : { size: 4, content: [] };
                            json.content = point;
                            let value = JSON.stringify(json);
                            this.props.onChange(value);
                        }
                    }}
                    onSize={(size) => {
                        let json = this.props.value ? JSON.parse(this.props.value) : { size: 4, content: [] };
                        json.size = size;
                        let value = JSON.stringify(json);
                        this.props.onChange(value);
                    }}
                />);
            default: return (<div style={this.props.style} />)
        }
    }

}

export default AnswerTypeInput;

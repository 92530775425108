/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import { Input, InputNumber } from "antd";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";


class CitiesAreas extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalCities: null,
        };

        this.id = "AREAS_TABLE";
        this.cities_id = "CITIES_TABLE";

        this.api = {
            get: "get_areas",
            create_edit: "create_edit_areas",
            delete: "delete_areas",

            get_cities: "get_cities",
            create_edit_cities: "create_edit_cities",
            delete_cities: "delete_cities",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.cities_id] === undefined) props.data[this.cities_id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(19367);
    }

    render() {
        const gt = this.getText;
        const sorter = true;
        const { editedRecord, editing } = this.props.data[this.id];
        const cEditing = this.props.data[this.cities_id].editing;
        const cEditedRecord = this.props.data[this.cities_id].editedRecord;
        const { companyCode, token } = this.props.user;
        const renderID = (t, r) => r.key != 0 || editing != 0 ? t : (<InputNumber value={editedRecord.aID} onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { aID: e }) }} />)
        const renderName = (t, r) => r.key !== editing ? t : (<Input value={editedRecord.aName} onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { aName: e.target.value }) }} />)
        const renderPrice = (t, r) => r.key !== editing ? t : (<InputNumber value={editedRecord.aPrice} onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { aPrice: e }) }} />)
        const renderEdit = (t, r) => {
            if (r.key !== editing) {
                return (<TableLineIcons
                    onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, r.key, r.index) }}
                    onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, r.key, companyCode, token, this.api.delete, this.api.get) }}
                    deleteQuetion={{ title: this.getText(19373), okText: this.getText(19374), cancelText: this.getText(19375) }}
                    genericIcons={[{ type: "table", tooltip: this.getText(19376), onClick: () => { this.setState({ modalCities: r }) } }]}
                />)
            } else {
                return (<TableLineIcons
                    onCheck={() => { this.props.dataActions.genericDataSaveEdit(this.id, companyCode, token, this.api.create_edit, this.api.get) }}
                    onClose={() => { this.props.dataActions[editing == 0 ? "genericDataCancelInlineCreate" : "genericDataCancelEditing"](this.id) }}
                />)
            }
        }
        const renderCityName = (t, r) => r.key !== cEditing ? t : (<Input value={cEditedRecord.cName} onChange={e => { this.props.dataActions.genericDataSetEdit(this.cities_id, { cName: e.target.value }) }} />)

        const renderCityEdit = (t, r) => {
            if (r.key !== cEditing) {
                return (<TableLineIcons
                    onEdit={() => { this.props.dataActions.genericDataStartEditing(this.cities_id, r.key, r.index) }}
                    onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.cities_id, r.key, companyCode, token, this.api.delete_cities, this.api.get_cities) }}
                    deleteQuetion={{ title: this.getText(19379), okText: this.getText(19374), cancelText: this.getText(19375) }}
                />)
            } else {
                return (<TableLineIcons
                    onCheck={() => { this.props.dataActions.genericDataSaveEditWithParams(this.cities_id, companyCode, token, this.api.create_edit_cities, this.api.get_cities, { aID: this.state.modalCities?.aID }) }}
                    onClose={() => { this.props.dataActions[cEditing == 0 ? "genericDataCancelInlineCreate" : "genericDataCancelEditing"](this.cities_id) }}
                />)
            }
        }

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 800,
            margin: "auto",
        };

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(19368)}
                columns={[
                    { title: gt(19369), key: "aID", dataIndex: "aID", width: "15%", sorter, render: renderID },
                    { title: gt(19370), key: "aName", dataIndex: "aName", width: "50%", sorter, render: renderName },
                    { title: gt(19371), key: "aPrice", dataIndex: "aPrice", width: "20%", sorter, render: renderPrice },
                    { title: gt(19372), width: "15%", render: renderEdit },
                ]}
                searchBox
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
            />
            <GenericModal
                visible={this.state.modalCities != null}
                onCancel={() => { this.setState({ modalCities: null }) }}
                width={400}
                title={gt(19377) + " " + (this.state.modalCities?.aName ?? "")}
                actionButtons={[
                    { buttonType: 'add', buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.cities_id) } },
                    { buttonType: 'refresh', buttonFunction: () => { this.props.dataActions.genericDataGetRequested(this.cities_id, companyCode, token, this.api.get_cities) } },
                ]}
            >
                {this.state.modalCities ?
                    (<GenericTable
                        {...this.props}
                        tableOnly
                        id={this.cities_id}
                        getApi={this.api.get_cities}
                        filters={{ aID: this.state.modalCities.aID }}
                        columns={[
                            { title: gt(19378), key: "cName", dataIndex: "cName", width: "75%", render: renderCityName },
                            { title: gt(19372), width: "25%", render: renderCityEdit },
                        ]}
                        pagination={{ pageSize: 10 }}
                    />)
                    : ""}

            </GenericModal>
        </div>)
    }

}

export default CitiesAreas;
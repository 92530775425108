/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import PageToolber from "../../components/PageToolbar";
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import { Button, Checkbox } from "antd";


class OrderSendDeclaration extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "ORDER_SEND_DECLARATION";

        this.api = {
            get: "get_order_send_declaration",
            edit: "edit_order_send_declaration",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorOrderStatuses, api: 'external_orders_statuses_list' },
            { id: TableIDs.genericSelectorExternalOrderSuppliers, api: 'external_orders_supplier_logo_list' },
            { id: TableIDs.genericSelectorPreDefinedMsg, api: 'get_pre_defined_msg_list' },
            { id: TableIDs.genericSelectorGroupEmployee, api: 'get_employee_group_list' },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20145);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetEditWithParams(this.id, this.props.user.companyCode, this.props.user.token, {}, this.api.get);
    }

    render() {
        const gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 1000,
            margin: "auto"
        };

        const { editedRecord } = this.props.data[this.id]

        const render = t => t;
        const renderSelector = (t, r) => {
            if (t) {
                let multi = r.key <= 2
                let val = editedRecord[t]
                return (<GenericSelector {...this.props} {...this.selectors[r.sIndex]} multi={multi}
                    value={multi ? val ? val.split(',') : [] : val} onChange={e => {
                        this.props.dataActions.genericDataSetEdit(this.id, { [t]: multi ? e?.join(',') : e })
                    }} />)
            }
        }

        const renderCheckbox = t => {
            return (<Checkbox checked={editedRecord[t] == 1} onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.id, { [t]: e.target.checked ? '1' : '0' })
            }} />)
        }

        const dataSource1 = [
            { key: 1, sIndex: 0, name: gt(20146), managers: '_OSDManagerStatus', customers: '_OSDCustomerStatus' },
            { key: 2, sIndex: 1, name: gt(20147), managers: '_OSDManagerExternalOrderSupplliers', customers: '_OSDCustomerExternalOrderSupplliers' },
            { key: 3, sIndex: 2, name: gt(20148), managers: '_OSDManagersPredefinedMessage', customers: '_OSDCustomersPredefinedMessage' },
            { key: 4, sIndex: 3, name: gt(20149), managers: '_OSDManagerEmployeeGroup' },
        ]

        const dataSource2 = [
            { key: 5, name: gt(20150), pos: '_blEvent33', bo: '_blEvent35' },
            { key: 6, name: gt(20151), pos: '_blEvent34', bo: '_blEvent36' },
        ]

        const columns1 = [
            { title: gt(20152), key: "name", dataIndex: "name", width: "30%", render },
            { title: gt(20153), key: "managers", dataIndex: "managers", width: "35%", render: renderSelector },
            { title: gt(20154), key: "customers", dataIndex: "customers", width: "35%", render: renderSelector },
        ]

        const columns2 = [
            { title: gt(20152), key: "name", dataIndex: "name", width: "50%", render },
            { title: gt(20155), key: "pos", dataIndex: "pos", width: "25%", render: renderCheckbox },
            { title: gt(20156), key: "bo", dataIndex: "bo", width: "25%", render: renderCheckbox },
        ]


        return (<div style={divStyle}>
            <PageToolber title={gt(20145)} actionButtons={[{ buttonType: 'refresh', buttonFunction: this.refreshData }]} />
            <ResponsiveTable tableOnly dataSource={dataSource1} columns={columns1} pagination={false} />
            <ResponsiveTable tableOnly dataSource={dataSource2} columns={columns2} pagination={false} />
            <Button type="primary" onClick={()=>{
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.genericDataJustSaveEdit,
                    args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
                });
                this.refreshData()
                // this.props.dataActions.genericDataSaveEdit(
                //     this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
            }}>{gt(20157)}</Button>
        </div>)
    }

}

export default OrderSendDeclaration;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../App.css";


class GenericIcon extends Component {

    // https://htmlsymbols.cc/

    render() {
        return (<div
            {...this.props}
            style={{ display: "inline-block", ...this.props.style }}
            dangerouslySetInnerHTML={{ __html: '&#' + this.props.utf8Code + ";" }}
        />)
    }
}

export default GenericIcon;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import GenericSelector from "../../components/GenericSelector";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";


class MessageEvents extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "MESSAGE_EVENTS";

        this.api = {
            get: "get_message_events",
            edit: "edit_message_events",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorPreDefinedMsg, api: "get_pre_defined_msg_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16988);
    }

    renderPDMessage = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];

        return (<GenericSelector
            {...this.props}
            {...this.selectors[0]}
            disabled={editing != record.key}
            value={editing == record.key ? editedRecord.tPredefinedMessageId : text}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { tPredefinedMessageId: e }) }}
            addFirstRow={{ code: '0', name: '-' }}
        />)
    }

    renderEditCol = (text, record) => {
        if (this.props.data[this.id].editing == record.key) {
            return (<TableLineIcons
                onCheck={() => { this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get) }}
                onClose={() => { this.props.dataActions.genericDataCancelEditing(this.id) }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
            />)
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16991)}
                columns={[
                    { title: gt(16995), key: "tID", dataIndex: "tID", width: "20%", render: t => t },
                    { title: gt(16992), key: "tName", dataIndex: "tName", width: "35%", render: t => t },
                    { title: gt(16993), key: "tPredefinedMessageId", dataIndex: "tPredefinedMessageId", width: "35%", render: this.renderPDMessage },
                    { title: gt(16994), width: "10%", render: this.renderEditCol },
                ]}
            />
        </div>)
    }

}

export default MessageEvents;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Input, Form, Button, Radio, Checkbox, message, InputNumber, Popconfirm } from 'antd';
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";
import TableIDs from '../../data/TableIDs.js';
import { fixCurrentPage } from "../../utils/utils";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import RecyclingBin from "../../components/RecyclingBin"
import moment from "moment";

const topHeight = 340; // px
const rowHeight = 30;  // px

//const { Option, OptGroup } = Select;

class PriceListSettings extends Component<Props, State> {
    constructor(props/*: Props*/) {
        super(props);

        this.doOnResize = null;

        this.id = "CREATE_PRICE_LIST";
        this.pos_id = "POS_PRICE_LIST_ATTRIBUTION";
        this.recycling_id = "PRICE_LIST_DELETION_RECYCLING";
        this.re_prices_id = "PRICE_CHANGE_DATES";

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            modalPosAttr: false,
            modalCopyItems: false,
            modalChangePrices: false,
            modalRecyclingPrices: false,

            changePriceSuppList: '',
            changePriceTagList: '',
            changePriceType: 1,
            changePriceValue: 0,

            copyItemsFromPL: '1',
            copyItemsPriceList: null,
            copyItemsSupplierId: null,
            copyItemsTagList: '',
            copyItemsType: 1,

            loading: false,
        };

        this.selectors = [
            { id: TableIDs.genericSelectorTaxRegion, api: "get_tax_region_list" },
            { id: TableIDs.genericSelectorSuppliers, api: "get_suppliers", notApi: true },
            { id: TableIDs.genericMultiSelectorItemTags, api: "get_tags_with_types", notApi: true },
            { id: TableIDs.genericSelectorPriceList, api: "get_prices" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.pos_id] === undefined) props.data[this.pos_id] = { ...props.data.genericData };
        if (props.data[this.recycling_id] === undefined) props.data[this.recycling_id] = { ...props.data.genericData };
        if (props.data[this.re_prices_id] === undefined) props.data[this.re_prices_id] = { ...props.data.genericData };
        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.api = {
            get: "get_priceList_list_paging",
            create: "create_priceList",
            edit: "edit_priceList",
            delete: "delete_priceList",
            selector: "get_tax_region_list",

            get_pos: "get_pos_price_list_attribution",
            save_pos: "save_pos_price_list_attribution",

            change_prices: "change_prices_per_price_list",
            copy_items: "copy_items_per_price_list",

            get_recycling: "get_price_list_deletion_recycling",
            restoration: "restoration_price_list",

            get_re_prices: "get_price_changes_dates",
            edit_re_prices: "restoration_old_prices",

        }

        this.actionButtons = [{
            buttonType: 'add',
            buttonFunction: this.handleCreateNew
        }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }]
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();

        this.selectors.filter(f => !f.notApi).forEach(x => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.api);

        })

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10211);
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((window.innerHeight - topHeight > rowHeight)
            ? (window.innerHeight - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {

        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 1
            case "mId": col--;
            case "mName": col--;
            case "mRegionId": col--;
            case "mIsIncludeVat": break; // 5
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    handleOk = () => {
        let s = this.props.data[this.pos_id];
        let dataSend = [s.headers.join('\f'), ...s.data].join('\r');
        this.sendAPI(this.api.save_pos, dataSend, () => {
            this.successSave();
            this.handleCancel();
        })
    }

    successSave = () => {
        message.success(this.getText(14669));
    }

    handleCancel = () => {
        this.setState({ modalPosAttr: false });
    }

    checkAll = () => {
        let data = this.props.data[this.pos_id].data.map((x) => {
            return x.split('\f');
        });
        let isNotChecked = data.filter(f => f[2] !== '1');

        return (<Checkbox
            checked={!isNotChecked.length}
            onChange={(e) => {
                let c = e.target.checked;
                let newData = data.map(x => {
                    let y = [...x];
                    y[2] = c || y[3] == '1' ? '1' : '0';
                    return y.join('\f');
                });
                this.props.dataActions.setJson(this.pos_id, { data: newData })
            }}
        />)
    }

    attrButton = (text, record) => {
        return (<Checkbox
            checked={text == '1'}
            disabled={record.pIsDefault == '1'}
            onChange={(e) => {
                let newData = [...this.props.data[this.pos_id].data];
                let c = e.target.checked ? '1' : '0';
                newData[record.index] =
                    record.pID + '\f' +
                    record.pName + '\f' +
                    c + '\f' +
                    record.pIsDefault + '\f' +
                    record.plID;

                this.props.dataActions.setJson(this.pos_id, { data: newData })
            }}
        />)
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    apiPosPriceListAttr = (plID) => {
        this.props.dataActions.genericDataSetFilter(
            this.pos_id, this.props.user.companyCode, this.props.user.token, { plID }, this.api.get_pos);
    }

    changePricesOk = () => {
        let pl = this.state.modalChangePrices;

        if (pl) {
            let sendData =
                "plID\fsuppList\ftagList\fchangeType\fchangeValue\r" +
                pl + "\f" +
                this.state.changePriceSuppList + "\f" +
                this.state.changePriceTagList + "\f" +
                this.state.changePriceType + "\f" +
                this.state.changePriceValue;

            this.setState({ loading: true }, () => {
                this.sendAPI(this.api.change_prices, sendData, () => {
                    this.successSave();
                    this.setState({ modalChangePrices: false, loading: false });
                })
            })
        }
    }

    copyItemsOk = () => {
        let pl = this.state.modalCopyItems;
        let isSourcePl = this.state.copyItemsFromPL == '1'
        let sourcePl = this.state.copyItemsPriceList ? this.state.copyItemsPriceList : '';
        let sourceSupp = this.state.copyItemsSupplierId ? this.state.copyItemsSupplierId : '';

        if (pl && ((isSourcePl && sourcePl) || (!isSourcePl && sourceSupp))) {
            let sendData =
                "plID\fsourcePl\ftagList\fcopyType\fsourceSupp\r" +
                pl + "\f" +
                sourcePl + "\f" +
                this.state.copyItemsTagList + "\f" +
                this.state.copyItemsType + "\f" +
                sourceSupp;

            this.setState({ loading: true }, () => {
                this.sendAPI(this.api.copy_items, sendData, () => {
                    this.successSave();
                    this.setState({ modalCopyItems: false, loading: false });
                })
            })
        } else {
            message.error(this.getText(isSourcePl ? 14674 : 17163))
        }
    }

    createSelector = (index, props) => {
        let sendProps = {
            id: this.selectors[index].id,
            // api: this.selectors[index].api,
            user: this.props.user,
            dataActions: this.props.dataActions,
            data: this.props.data,
            ...props
        }

        return index === 1 ? (<GenericSelectorSuppliers {...sendProps} />) :
            index === 2 ? (<GenericSelectorItemTags {...sendProps} notReport />) :
                (<GenericSelector {...sendProps} />)
    }

    renderName = (text, record) => {
        return (this.props.data[this.id].editing === record.key) ?
            (<Input
                style={{ width: '98%', textAlign: 'center' }}
                defaultValue={text}
                onChange={(value) => {
                    this.props.dataActions.genericDataSetEdit(this.id, { mName: value.target.value });
                }} />)
            : text;
    }

    renderRegionId = (text, record) => {
        return this.createSelector(0, {
            value: (this.props.data[this.id].editing === record.key) ? this.props.data[this.id].editedRecord['mRegionId'] : text,
            onChange: (e) => { this.props.dataActions.genericDataSetEdit(this.id, { mRegionId: e }) },
            disabled: !(this.props.data[this.id].editing === record.key)
        });
    }

    renderIncludeVat = (text, record) => {
        return (<Checkbox
            onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsIncludeVat", record); }}
            checked={this.checkIfChecked('mIsIncludeVat', text, record)}
            defaultChecked={false}
            disabled={!(this.props.data[this.id].editing === record.key)} />
        )
    }

    apiRePricesDates = (plID) => {
        this.props.dataActions.genericDataSetFilter(
            this.re_prices_id, this.props.user.companyCode, this.props.user.token, { plID }, this.api.get_re_prices);
    }

    recyclingPricesBtn = (text, record) => {
        return (<Popconfirm
            title={this.getText(19584)}
            okText={this.getText(19585)}
            cancelText={this.getText(19586)}
            onCancel={() => { this.setState({ modalRecyclingPrices: false }) }}
            onConfirm={() => {
                this.sendAPI(this.api.edit_re_prices, "_priceListId\f_timestamp\r" + record._priceListId + "\f" + record._timestamp, () => {
                    this.setState({ modalRecyclingPrices: false }, () => { message.success(this.getText(19587)) })
                })
            }}
        >
            <Button size="small" type="primary">{this.getText(19583)}</Button>
        </Popconfirm>)
    }


    renderSettings = (text, record) => {
        if (this.props.data[this.id].editing !== record.key) {
            let genericActionButtons = [{
                type: "tag",
                tooltip: this.getText(14653),
                onClick: () => { this.setState({ modalPosAttr: true }, () => { this.apiPosPriceListAttr(record.key) }) }
            }, {
                type: "dollar",
                tooltip: this.getText(14659),
                onClick: () => { this.setState({ modalChangePrices: record.key }) }
            }, {
                type: "copy",
                tooltip: this.getText(14660),
                onClick: () => { this.setState({ modalCopyItems: record.key }) }
            }];

            if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '523')) {
                genericActionButtons.push({
                    type: "interaction",
                    tooltip: this.getText(19579),
                    onClick: () => { this.setState({ modalRecyclingPrices: true }, () => { this.apiRePricesDates(record.key) }) }
                })
            }


            return (<TableLineIcons
                onEdit={() => {
                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                }}
                onDelete={() => {
                    fixCurrentPage.bind(this)();
                    this.props.dataActions.genericDataDeleteRow(
                        this.id,
                        record['mId'],
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.api.delete,
                        this.api.get);
                }}
                deleteQuetion={{ title: this.getText(11821), okText: this.getText(11822), cancelText: this.getText(11823) }}
                genericIcons={genericActionButtons} />)
        } else {
            let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
            return (<TableLineIcons
                onCheck={() => {
                    const { mName, mRegionId } = this.props.data[this.id].editedRecord;
                    if (!mName || !mRegionId) {
                        message.error(this.getText(17941))
                    } else {
                        this.props.dataActions.genericDataSaveEdit(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            isCreate ? this.api.create : this.api.edit, this.api.get);
                    }
                }}
                onClose={() => {
                    if (isCreate) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }}
            />)
        }
    }

    render() {
        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            { title: this.getText(11816), dataIndex: "mId", key: "mId", sorter: true, width: '10%', render: (text) => text },
            { title: this.getText(11817), dataIndex: "mName", key: "mName", width: '20%', sorter: true, render: this.renderName },
            { title: this.getText(11818), dataIndex: "mRegionId", key: "mRegionId", width: '20%', sorter: true, render: this.renderRegionId },
            { title: this.getText(11819), dataIndex: "mIsIncludeVat", key: "mIsIncludeVat", width: '10%', sorter: true, render: this.renderIncludeVat },
            { title: this.getText(11820), key: "Settings", width: '13%', render: this.renderSettings }
        ];

        let posColumns = [
            { title: this.getText(14657), key: "pID", dataIndex: "pID", width: "20%", render: (t) => t },
            { title: this.getText(14658), key: "pName", dataIndex: "pName", width: "70%", render: (t) => t },
            { title: this.checkAll(), key: "pAttr", dataIndex: "pAttr", width: "10%", render: this.attrButton },
        ]

        return (

            <div style={{
                width: window.innerWidth > 600 ? '70%' : '100%',
                margin: 'auto',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[false, 40, 40, 20]}
                    title={this.getText(11815)}
                    onChange={this.handleTableChange}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    genericActionButtons={[
                        <RecyclingBin
                            id={this.recycling_id}
                            getApi={this.api.get_recycling}
                            restorationApi={this.api.restoration}
                            title={this.getText(18827)}
                            columnTitles={[this.getText(18828), this.getText(18829), this.getText(18830), this.getText(18832), this.getText(18831)]}
                            popconfirmText={{ title: this.getText(18833), okText: this.getText(18834), cancelText: this.getText(18835) }}
                            onClose={this.refreshData}
                            {...this.props}
                        />
                    ]}
                />
                <GenericModal
                    title={this.getText(14654)}
                    width={600}
                    visible={this.state.modalPosAttr}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{this.getText(14655)}</Button>,
                        <Button key={"create"} type="primary" onClick={this.handleOk} style={{ margin: 5 }}>{this.getText(14656)}</Button>
                    ]}>
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.pos_id]}
                        columns={posColumns}
                        scroll={{ y: window.innerHeight - topHeight }}
                        pagination={false}
                    />
                </GenericModal>
                <GenericModal
                    title={this.getText(14659)}
                    width={600}
                    visible={this.state.modalChangePrices}
                    onCancel={() => { this.setState({ modalChangePrices: false }) }}
                    footer={[
                        <Button key={"back"} onClick={() => { this.setState({ modalChangePrices: false }) }} style={{ margin: 5 }}>
                            {this.getText(14655)}
                        </Button>,
                        <Popconfirm title={this.getText(19576)} okText={this.getText(19577)} cancelText={this.getText(19578)} onConfirm={this.changePricesOk}>
                            <Button key={"create"} loading={this.state.loading} type="primary" style={{ margin: 5 }}>
                                {this.getText(14656)}
                            </Button>
                        </Popconfirm>
                    ]}>
                    <Form.Item label={this.getText(14661)}>
                        {this.createSelector(1, {
                            value: this.state.changePriceSuppList ? this.state.changePriceSuppList.split(',') : [],
                            onChange: (e) => { this.setState({ changePriceSuppList: e ? e.join(',') : "" }) },
                            multi: true,
                        })}
                    </Form.Item>
                    <Form.Item label={this.getText(14662)}>
                        {this.createSelector(2, {
                            value: this.state.changePriceTagList ? this.state.changePriceTagList.split(',') : [],
                            onChange: (e) => { this.setState({ changePriceTagList: e ? e.join(',') : "" }) },
                            multi: true,
                            optionsGroup: true,
                        })}
                    </Form.Item>
                    <Form.Item label={this.getText(14663)}>
                        <Radio.Group
                            value={this.state.changePriceType}
                            onChange={(e) => { this.setState({ changePriceType: e.target.value }) }}>
                            <Radio value={1}>{this.getText(14665)}</Radio>
                            <Radio value={2}>{this.getText(14666)}</Radio>
                            <Radio value={3}>{this.getText(14667)}</Radio>
                        </Radio.Group>
                        <br />
                        <InputNumber
                            precision={2}
                            value={this.state.changePriceValue}
                            onChange={(e) => { this.setState({ changePriceValue: e }) }}
                        />
                        <br />
                        {this.getText(14668)}
                    </Form.Item>
                </GenericModal>
                <GenericModal
                    title={this.getText(14660)}
                    width={600}
                    visible={this.state.modalCopyItems}
                    onCancel={() => { this.setState({ modalCopyItems: false }) }}
                    footer={[
                        <Button key={"back"} onClick={() => { this.setState({ modalCopyItems: false }) }} style={{ margin: 5 }}>{this.getText(14655)}</Button>,
                        <Button key={"create"} loading={this.state.loading} type="primary" onClick={this.copyItemsOk} style={{ margin: 5 }}>{this.getText(14656)}</Button>
                    ]}>
                    <Form.Item>
                        <Radio.Group
                            value={this.state.copyItemsFromPL}
                            onChange={(e) => { this.setState({ copyItemsFromPL: e.target.value }) }}>
                            <Radio value={'1'}>{this.getText(17160)}</Radio>
                            <Radio value={'0'}>{this.getText(17161)}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {this.state.copyItemsFromPL == '1' ? (
                        <Form.Item label={this.getText(14664)}>
                            {this.createSelector(3, {
                                value: this.state.copyItemsPriceList,
                                onChange: (e) => { this.setState({ copyItemsPriceList: e, copyItemsSupplierId: null }) }
                            })}
                        </Form.Item>
                    ) : (
                        <Form.Item label={this.getText(17162)}>
                            {this.createSelector(1, {
                                value: this.state.copyItemsSupplierId,
                                onChange: (e) => { this.setState({ copyItemsSupplierId: e, copyItemsPriceList: null }) }
                            })}
                        </Form.Item>
                    )}
                    <Form.Item label={this.getText(14662)}>
                        {this.createSelector(2, {
                            value: this.state.copyItemsTagList ? this.state.copyItemsTagList.split(',') : [],
                            onChange: (e) => { this.setState({ copyItemsTagList: e ? e.join(',') : "" }) },
                            multi: true,
                            optionsGroup: true,
                        })}
                    </Form.Item>
                    <Form.Item label={this.getText(14670)}>
                        <Radio.Group
                            value={this.state.copyItemsType}
                            onChange={(e) => { this.setState({ copyItemsType: e.target.value }) }}>
                            <Radio value={1}>{this.getText(14671)}</Radio>
                            <Radio value={2}>{this.getText(14672)}</Radio>
                            <Radio value={3}>{this.getText(14673)}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </GenericModal>
                <GenericModal
                    title={this.getText(19580)}
                    width={600}
                    visible={this.state.modalRecyclingPrices}
                    onCancel={() => { this.setState({ modalRecyclingPrices: false }) }}
                >
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.re_prices_id]}
                        columns={[
                            { title: this.getText(19581), key: "_timestamp", dataIndex: "_timestamp", width: "50%", render: t => moment(t).format("DD/MM/YYYY HH:mm") },
                            { title: this.getText(19582), key: "_count", dataIndex: "_count", width: "30%", render: t => t },
                            { width: "20%", render: this.recyclingPricesBtn },
                        ]}
                        scroll={{ y: window.innerHeight - topHeight }}
                        pagination={false}
                    />
                </GenericModal>
            </div >
        )
    }
}
export default PriceListSettings;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import LangContext from "../contextProvider/LangContext";
import KdsView from "../pages/Kds/KdsView";

class FormKds extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.id = "KDS_DATA";
        this.comp_id = "COMPANY_DETAILS_DATA";

        this.api = {
            get: "get_kds_data",
            get_details: "get_company_details_to_form",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = {};
        if (props.data[this.comp_id] === undefined) props.data[this.comp_id] = { name: "", term: "", logo: "" };
        if (props.data.formData === undefined) props.data.formData = { params: '' };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']';
    }

    componentDidMount() {
        this.getData();
          }

    componentDidUpdate(p) {
        if (p.data.formData.params !== this.props.data.formData.params) {
            this.getData();
        }
    }

    getData = () => {
        let fd = this.props.data.formData.params;

        let params = {}

        if (fd) {
            fd.split('&').forEach((x) => {
                let y = x.split('=');
                params = { ...params, [y[0]]: y[1] }
            })
        }
        console.log('FormKds', params)

        if (params.kdsID) {
            this.props.sendAPI('get_kds_data', params.kdsID, (ob) => {
                if (ob.data) {
                    let data = ob.data.split('\r').map(x => x.split('\f'));
                    let obj = {}
                    data[0].forEach((x, i) => { obj = { ...obj, [x]: data[1][i] } });
                    this.props.setJson(this.id, obj);
                } else {
                    this.props.setJson(this.id, {});
                }
            }, (e) => { console.error(e) })
        } else {
            this.props.setJson(this.id, {});
        }

        this.props.sendAPI(this.api.get_details, '', (ob) => {
            let d = ob.data.split("\f");
            this.props.setJson(this.comp_id, {
                name: d[0],
                desc: d[1],
                address1: d[2],
                address2: d[3],
                address3: d[4],
                telephone: d[5],
                term: d[6],
                logo: d[7],
                about: d[8],
            });
        }, (e) => { console.error(e) });
    }

    render() {

        return (<KdsView
            {...this.props}
            height={window.innerHeight * 0.8}
            kdsData={this.props.data[this.id]}
            getText={this.getText}
            sendAPI={(s, d, c) => { this.props.sendAPI(s, d, c, (e) => { console.error(e) }) }}
        />)
    }
}
export default FormKds;
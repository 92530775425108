/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Icon, Tooltip, Button } from "antd";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import 'react-google-places-autocomplete/dist/assets/index.css';
import "../../App.css";

type Props = {
    placeholder: String,
    inputStyle: Object,
    changeAddress: Function,
    getLocationAgain: Function,
    getLocationAgainText: String,
}

class GoogleAddress extends Component<Props, State> {

    // state = {
    //     valueSelect: null
    // }

    componentDidUpdate(p) {
        if (p.value !== this.props.value) this.setValue(this.props.value)
        else if (!p.latLng && this.props.latLng) this.geocodeLatLng(this.props.latLng)
    }

    componentDidMount() {
        if (this.props.value) this.setValue(this.props.value)
        else if (this.props.latLng) this.geocodeLatLng(this.props.latLng)

    }

    setValue = (value) => {
        geocodeByAddress(value)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                this.setValueAddress(value, lat, lng);
            });
    }

    geocodeLatLng = ({ lat, lng }) => {

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results) => {
            let first = results ? results[0] : {}
            this.setValueAddress(first.formatted_address, lat, lng);
        });
    }

    setValueAddress = (value, lat, lng) => {
        if (value) {
            let splitValue = value.split(',');
            let splitStreet = splitValue[0].split(' ');
            let city = splitValue[1] ? splitValue[1].trim() : "";
            let street = splitStreet.slice(0, -1).join(' ');
            let building = splitStreet.slice(-1)[0];
            let country = splitValue[3] ? splitValue[3] : "IL";
            this.props.changeAddress(city, street, building, lat, lng, country);
            // setTimeout(() => {
            //     console.log('-google-places-autocomplete-input update', lat, lng)
            //     document.getElementById('-google-places-autocomplete-input').value = value;
            // }, 1000);
        }
    }

    onSelect = (e) => {
        // `results` is an entire payload from Google API.
        geocodeByPlaceId(e.place_id)
            .then((results) => {
                let r = results[0];
                let ac = r.address_components;
                let city = ac ? ac.find(f => f.types[0] === "locality") : null
                let street = ac ? ac.find(f => f.types[0] === "route") : null
                let building = ac ? ac.find(f => f.types[0] === "street_number") : null
                let country = ac ? ac.find(f => f.types[0] === "country") : null
                let location = r.geometry ? r.geometry.location : null;
               
                this.props.changeAddress(
                    city ? city.long_name : '',
                    street ? street.long_name : '',
                    building ? building.long_name : '',
                    location ? location.lat() : '',
                    location ? location.lng() : '',
                    country ? country.short_name : ''
                )
            }).catch(() => {
                this.props.changeAddress('', '', '', '', '', '')
            });
    }

    render() {


        return (<span>
            {this.props.getLocationAgain ? (
                <Tooltip title={this.props.getLocationAgainText}>
                    <Icon
                        type="google"
                        style={{ height: 32, width: '5%' }}
                        onClick={() => {
                            if (this.props.latLng) {
                                this.geocodeLatLng(this.props.latLng)
                            } else {
                                this.props.getLocationAgain()
                            }
                        }} />
                </Tooltip>
            ) : (<Icon type="google" style={{ height: 32, width: '5%' }} />)}

            <GooglePlacesAutocomplete
                className={"text_item"}
                placeholder={this.props.placeholder}
                inputStyle={this.props.inputStyle}
                initialValue={this.props.value}

                // query={{language: 'he'}}
                onSelect={this.onSelect} 

                autocompletionRequest={{
                    componentRestrictions: {
                      country: ['il'],
                    }
                  }}
                />
        </span>)
    }
}

export default GoogleAddress;
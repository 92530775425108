/* @flow */
/*jshint esversion: 6 */
import { Select, Form, Card, Row, Col } from "antd";
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import GenericSelector from "../../components/GenericSelector";
import ReportTypeButton from "../../components/ReportTypeButton";
import PageToolbar from "../../components/PageToolbar";
//import CustomerPicker from "../../components/CustomerPicker";
import CustPicker from "../../components/CustPicker";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";

const FormItem = Form.Item;

class CustomerSalesMonthlyReport extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _dateFrom: null,
            _dateTo: null,
            _employeeList: '',
            _groupType: '0',
            _customerId: null,
            _custTagList: '',
            reportName: "reports/CustomerSalesMonthly.pdf",
        };

        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(17515);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    createDatePicker = (label, field) => {
        return (<FormItem label={this.getText(label)}>
            <DateTimePicker
                format="DD/MM/YYYY"
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                minDate={field == '_dateTo' ? this.state._dateFrom : null}
                maxDate={field == '_dateFrom' ? this.state._dateTo : null}
            />
        </FormItem>)
    }

    render() {
        let gt = this.getText;
        let mobile = window.innerWidth > 600 ? false : true;
        let params = this.state;

        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };



        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={gt(17516)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: window.innerWidth < 1200 ? '95%' : 1100 }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 18}>
                        <FormItem label={gt(17517)}>
                            <GenericSelector
                                multi
                                id={TableIDs.genericSelectorEmployees}
                                api={"get_employee_list"}
                                value={this.state._employeeList ? this.state._employeeList.split(',') : []}
                                onChange={(e) => { this.setState({ _employeeList: e ? e.join(',') : '' }) }}
                                data={this.props.data}
                                dataActions={this.props.dataActions}
                                user={this.props.user}
                            />
                        </FormItem>
                        <Row>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(17518, '_dateFrom')}</Col>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(17519, '_dateTo')}</Col>
                        </Row>
                        <Row>
                            <Col span={mobile ? 24 : 12}>
                                <FormItem label={gt(17520)}>
                                    <Select style={{ width: 250 }} value={this.state._groupType} onChange={(e) => { this.setState({ _groupType: e }) }}>
                                        <Select.Option value={"0"}> - </Select.Option>
                                        <Select.Option value={"1"}>{gt(17521)}</Select.Option>
                                        <Select.Option value={"2"}>{gt(19106)}</Select.Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={mobile ? 24 : 12}>
                                <FormItem label={gt(18258)}>
                                    <CustPicker
                                        {...this.props}
                                        value={this.state._customerId}
                                        onChange={(e) => { this.setState({ _customerId: e }) }}
                                        defaultView={this.getText(12121)}
                                    />
                                </FormItem>
                                <FormItem label={gt(18271)}>
                                    <GenericSelectorCustTags
                                        {...this.props} multi
                                        value={this.state._custTagList ? this.state._custTagList.split(',') : []}
                                        onChange={(e) => { this.setState({ _custTagList: e ? e.join(',') : '' }) }}
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={mobile ? 24 : 6}>
                        <ReportTypeButton
                            {...this.props}
                            params={params}
                            datesToFavorites={[
                                { field: '_dateFrom', label: gt(17518) },
                                { field: '_dateTo', label: gt(17519) },
                            ]}
                            favoriteSave
                        />
                    </Col>
                </Row>

            </Card>
        </div >
        );
    }

}

export default CustomerSalesMonthlyReport;
/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Button, Icon, Tooltip, message, Popover, Form, Select } from "antd";

import TableIDs from "../../data/TableIDs";
import ModalAccumulationOfSubscribers from "../../components/ModalAccumulationOfSubscribers";
import ModalCreateEditSubscribersCustomer from "../../components/ModalCreateEditSubscribersCustomer";
import ModalSearchSubscribers from "../../components/ModalSearchSubscribers";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import CustPicker from "../../components/CustPicker";
import ReportTypeButton from "../../components/ReportTypeButton";
import DateTimePicker from "../../components/DateTimePicker";
import moment from "moment";

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};


class CustomerSubscribers extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "CUSTOMER_SUBSCRIBERS_LIST";
        this.state = {
            modalCustReports: false,
            custID: '',
            custReport: 'CustomersSubscribers',

            filterActive: false,
            valueSearch: '',

            countFromDate: null,
            countToDate: null,

        };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.api = {
            get: "get_dealsActuals_subscribers_list",
            edit: "set_dealsActuals_subscribers_end_date"
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10158);
    }

    dataSetFilter = (request) => {
        let filters = this.props.data[this.id].filters;
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { ...filters, ...request, page: 1, _page_num: 1 }, this.api.get)
    }

    // generateReport = (params, reportName) => {
    //     let p = {
    //         ...params,
    //         _words: encodeURIComponent(params._words),
    //         _itemCode: encodeURIComponent(params._itemCode),
    //         _itemName: encodeURIComponent(params._itemName),
    //         reportName: "reports/" + reportName,
    //         REPORT_LOCALE: this.getText(101),
    //     }
    //     this.props.ActionQueue.addToQueue({
    //         action: this.props.dataActions.generateReport,
    //         args: [this.props.user.companyCode, this.props.user.token, p]
    //     });
    // }

    // reportButton = () => {
    //     const { _words, _creationDateStart, _creationDateEnd, _activityDateStart, _activityDateEnd, _validityDateStart, _validityDateEnd, _itemCode, _itemName, _customer, _posList, _remainZero } = this.props.data[this.id].filters;
    //     let params = { _words, _creationDateStart, _creationDateEnd, _activityDateStart, _activityDateEnd, _validityDateStart, _validityDateEnd, _itemCode, _itemName, _customer, _posList, _remainZero };

    //     let subButton = (text, type, reportName) => {
    //         return (<Tooltip title={this.getText(text)}>
    //             <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={() => { this.generateReport(params, reportName) }} />
    //         </Tooltip>)
    //     }

    //     return (<Tooltip title={this.getText(12720)}>
    //         <Popover content={[
    //             subButton(12721, "file-pdf", "CustomersSubscribers.pdf"),
    //             subButton(12722, "file-excel", "CustomersSubscribersExcel.xls")
    //         ]} title={this.getText(12723)} trigger="click">
    //             <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
    //                 <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
    //             </Button>
    //         </Popover>
    //     </Tooltip>)
    // }

    getEditButtons = (text, record) => {
        return (<span>
            <Tooltip title={this.getText(10981)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => {
                    this.setState({ customerId: record['dCustomerId'] },
                        this.props.uiActions.showModal(TableIDs.modalAccumulationOfSubscribers,
                            { customerId: record['dCustomerId'], customerIndex: record.index, dDocNum: record.dDocNum }))
                }} />
            </Tooltip>
            {this.props.data.PSEUDO_SELECTOR_UI_COMPONENTS_MODULES.dataset.find(f => f.code === '504') ?
                <Tooltip title={this.getText(10982)}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"plus"} onClick={() => {
                        // if (record['dRemain'] > 0) {
                        this.setState({ customerId: record['dCustomerId'] }, () => {
                            this.props.uiActions.showModal(TableIDs.modalCreateEditSubscribersCustomer,
                                { customerId: record['dCustomerId'], customerIndex: record.index })
                        });
                        // } else {
                        //     message.error(this.getText(10983));
                        // }
                    }} />
                </Tooltip>
                : ''}
        </span>);
    }

    getDateEndCol = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        if (editing === record.key) {
            return (<span>
                <Icon type={"check"} style={{ marginLeft: 5 }} onClick={() => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                }} />
                <Icon type={"close"} style={{ marginLeft: 5 }} onClick={() => { this.props.dataActions.genericDataCancelEditing(this.id) }} />
                <DateTimePicker
                    format="DD/MM/YYYY"
                    value={editedRecord.dDateEnd}
                    onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { dDateEnd: e }) }}
                />
            </span>)
        } else {
            return (<span>
                <Tooltip title={this.getText(17533)} style={{ marginLeft: 5 }}>
                    <Icon type="edit" onClick={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }} />
                </Tooltip>
                {text ? " " + moment(text).format("DD/MM/YYYY"): ""}
            </span>)
        }
    }

    clearFilters = () => {
        this.setState({ filterActive: false, valueSearch: "" })
    }

    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let initFilters = {
            _id: 0,
            _creationDateStart: null,
            _creationDateEnd: null,
            _activityDateStart: null,
            _activityDateEnd: null,
            _validityDateStart: null,
            _validityDateEnd: null,
            _itemCode: '',
            _customer: null,
            _posList: '',
            _remainZero: 0,
        }

        const getCol = (title, key, width, render) => {
             return {
                title: this.getText(title),
                dataIndex: key,
                key: key,
                width: width,
                render: render ? render : (text) => text
            }
        }

        const getDateFormat = t => t ?  moment(t).format("DD/MM/YYYY") : ""

        let columns = [
            getCol(10973, 'dCustomerId', '7%'),
            getCol(10974, 'dCustomerName', '14%'),
            getCol(12724, 'dDate', '7%', getDateFormat),
            getCol(10975, 'dName', '14%'),
            getCol(17494, 'dDateEnd', '7%', this.getDateEndCol),
            getCol(12725, 'dDocNum', '8%'),
            getCol(14246, 'dLastUse', '8%', getDateFormat),
            getCol(10976, 'dItemId', '7%'),
            getCol(10977, 'dItemName', '14%'),
            getCol(10978, 'dQuantity', '5%'),
            getCol(10979, 'dRemain', '5%'),
            getCol(10980, '', '5%', this.getEditButtons),
        ];

        let genericActionButtons = [
            // this.reportButton(),
            <Tooltip title={this.getText(15374)}>
                <Button key={"report-cust-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => { this.setState({ modalCustReports: true }) }}>
                    <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                </Button>
            </Tooltip>
        ];

        const { _words, _creationDateStart, _creationDateEnd, _activityDateStart, _activityDateEnd, _validityDateStart, _validityDateEnd, _itemCode, _itemName, _customer, _posList, _remainZero } = this.props.data[this.id].filters;
        let params = { _words, _creationDateStart, _creationDateEnd, _activityDateStart, _activityDateEnd, _validityDateStart, _validityDateEnd, _itemCode, _itemName, _customer, _posList, _remainZero };
        let isCustReport = this.state.custReport != "CustomersSubscribers" && this.state.custReport != "CustomersSubscribersCount";
        let reportParams = { reportName: "reports/" + this.state.custReport + ".pdf" }
        if (isCustReport) reportParams = { ...reportParams, custID: this.state.custID }
        else if(this.state.custReport == "CustomersSubscribersCount") reportParams = { ...reportParams, fromDate: this.state.countFromDate, toDate: this.state.countToDate}
        else reportParams = { ...reportParams, ...params }

        return (
            <div style={divStyle}>
                <GenericTable
                    id={this.id}
                    getApi={this.api.get}
                    filters={initFilters}
                    title={this.getText(10971)}
                    columns={columns}
                    actionButtons={[{
                        buttonType: 'filter',
                        buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchSubscribers, { ...this.props.ui.data, idSelector: "" }) }
                    }, {
                        buttonDisabled: !this.state.filterActive,
                        buttonType: 'clear',
                        buttonFunction: this.clearFilters,
                    }]}
                    genericActionButtons={genericActionButtons}
                    percentageToMobile={[false, 30, 35, 35]}
                    searchBox={true}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    setFilterActive={e => { this.setState({ filterActive: e }) }}
                    {...this.props}
                />

                <GenericModal
                    visible={this.state.modalCustReports}
                    onCancel={() => { this.setState({ modalCustReports: false }) }}
                    title={this.getText(15374)}
                    width={500}>
                    <Form.Item label={this.getText(15377)}>
                        <Select value={this.state.custReport} onChange={(v) => { this.setState({ custReport: v }) }} style={{ width: 250 }}>
                            <Select.Option value="CustomersSubscribers">{this.getText(18649)}</Select.Option>
                            <Select.Option value="CustomersSubscribersCount">{this.getText(18650)}</Select.Option>
                            <Select.Option value="SubscriptionsBalance">{this.getText(15375)}</Select.Option>
                            <Select.Option value="SubscriptionsHistory">{this.getText(15376)}</Select.Option>
                        </Select>
                    </Form.Item>
                    {isCustReport ?
                        <CustPicker
                            value={this.state.custID}
                            onChange={(code, name) => { this.setState({ custID: code }) }}
                            defaultView={this.getText(12121)}
                            {...this.props}
                        />
                        : ""}
                    {this.state.custReport == "CustomersSubscribersCount" ? [
                        <Form.Item label={this.getText(18651)}>
                            <DateTimePicker
                                format="DD/MM/YYYY"
                                value={this.state.countFromDate}
                                onChange={e => { this.setState({ countFromDate: e }) }}
                                maxDate={this.state.countToDate}
                            />
                        </Form.Item>,
                        <Form.Item label={this.getText(18652)}>
                            <DateTimePicker
                                format="DD/MM/YYYY"
                                value={this.state.countToDate}
                                onChange={e => { this.setState({ countToDate: e }) }}
                                minDate={this.state.countFromDate}
                            />
                        </Form.Item>
                    ] : ""}
                    <ReportTypeButton
                        {...this.props}
                        disabled={isCustReport && !this.state.custID}
                        params={reportParams}
                        xlsReportName={this.state.custReport == "CustomersSubscribers" ? "reports/CustomersSubscribersExcel.xls" : null}
                    />
                </GenericModal>

                <ModalAccumulationOfSubscribers
                    id={TableIDs.modalAccumulationOfSubscribers}
                    user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalAccumulationOfSubscribers)}
                />
                <ModalCreateEditSubscribersCustomer
                    id={TableIDs.modalCreateEditSubscribersCustomer}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditSubscribersCustomer)}
                />
                <ModalSearchSubscribers
                    id={TableIDs.modalSearchSubscribers}
                    dataSetFilter={this.dataSetFilter}
                    user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchSubscribers)}
                    filterActive={this.state.filterActive}
                    setFilterActive={e => {
                        if (e) this.setState({ filterActive: true })
                        else this.clearFilters()
                    }}
                />
            </div>
        );
    }
}

export default CustomerSubscribers;


/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Modal, Icon, DatePicker, Form, Row, Col, Popconfirm, Button, Input, Tooltip, Radio, Checkbox } from "antd";
import TableIDs from "../../data/TableIDs";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const FormItem = Form.Item;

class CustomerFilter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.id = this.props.mainId; // "CUSTOMER_TABLE";
        this.state = {
            filters: {
                _words: '',
                customerTags: '',
                birthDayLower: '',
                birthDayUpper: '',
                clubJoinLower: '',
                clubJoinUpper: '',
                lastClubJoinLower: '',
                lastClubJoinUpper: '',
                updateDateLower: '',
                updateDateUpper: '',
                creationPos: '',
                salesView: 0,
                custCredit: 0,
                custMailingIsActive: 0,
                notActiveView: 0,
                custEmployeeId: '',
            }
        };

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_customers",
        };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    setFilter = (filter) => {
        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',

            ...filter,
        };

        if (this.props.onFilterOk) {
            this.props.onFilterOk(request)
        } else {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        }
    }

    setStateFilter = (filter) => {
        this.setState({ filters: { ...this.state.filters, ...filter } })
    }

    resetFilter = () => {
        let clearFilters = {
            _words: '',
            customerTags: '',
            birthDayLower: '',
            birthDayUpper: '',
            clubJoinLower: '',
            clubJoinUpper: '',
            lastClubJoinLower: '',
            lastClubJoinUpper: '',
            updateDateLower: '',
            updateDateUpper: '',
            creationPos: '',
            salesView: 0,
            custCredit: 0,
            custMailingIsActive: 0,
            notActiveView: 0,
            custEmployeeId: '',
        }
        this.setStateFilter(clearFilters)
        this.setFilter(clearFilters)
    }

    componentDidUpdate(prev) {
        if (prev.filterActive && !this.props.filterActive) this.resetFilter()
        else if (prev.valueSearch !== this.props.valueSearch) this.setStateFilter({ _words: this.props.valueSearch })

        if (this.props.filterRequest && this.props.filterRequest !== prev.filterRequest) {
            setTimeout(() => {
                this.setStateFilter(this.props.filterRequest)
            }, 1000);
        }
    }

    render() {

        let filters = this.state.filters;

        let fItem = (label, content) => (<FormItem label={this.getText(label)}>{content}</FormItem>)

        let getDatePicker = (label, field, minDate, maxDate) => {
            return fItem(label, (<DatePicker
                onChange={(e) => { this.setStateFilter({ [field]: e ? e.format("YYYY-MM-DD") : null }) }}
                value={filters[field] ? moment(filters[field], "YYYY-MM-DD") : null}
                disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
                format={"DD/MM/YYYY"} />))
        }

        let datesInline = (label1, field1, label2, field2) => {
            let span = window.innerWidth > 600 ? 12 : 24
            return (<Row>
                <Col span={span}>{getDatePicker(label1, field1, null, filters[field2])}</Col>
                <Col span={span}>{getDatePicker(label2, field2, filters[field1], null)}</Col>
            </Row>)
        }

        let customerTags = fItem(14232, (<GenericSelectorCustTags
            {...this.props} multi
            // id={TableIDs.genericMultiSelectorCustomerTags}
            // api={"get_customer_tags_list"}
            // multi={true} optionsGroup={true}
            value={filters.customerTags ? filters.customerTags.split(',') : []}
            onChange={(e) => { this.setStateFilter({ customerTags: e.join(',') }) }}
        // user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
        />))

        let creationPos = fItem(14243, (<GenericSelectorPosStorages
            // id={TableIDs.genericSelectorPos}
            // api={"list_pos"}
            multi={true}
            value={filters.creationPos ? filters.creationPos.split(',') : []}
            onChange={(e) => { this.setStateFilter({ creationPos: e.join(',') }) }}
            user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
        />))

        let custCredit = (<FormItem>
            <Radio.Group value={filters.custCredit} onChange={(e) => { this.setStateFilter({ custCredit: e.target.value }) }}>
                <Radio value={0}>{this.getText(14268)}</Radio>
                <Radio value={1}>{this.getText(14269)}</Radio>
                <Radio value={2}>{this.getText(14270)}</Radio>
            </Radio.Group>
        </FormItem>)

        let custMailingIsActive = (<FormItem>
            <Radio.Group value={filters.custMailingIsActive} onChange={(e) => { this.setStateFilter({ custMailingIsActive: e.target.value }) }}>
                <Radio value={0}>{this.getText(14268)}</Radio>
                <Radio value={1}>{this.getText(17635)}</Radio>
                <Radio value={2}>{this.getText(17636)}</Radio>
            </Radio.Group>
        </FormItem>)

        let notActiveView = (<FormItem>
            <Checkbox
                checked={filters.notActiveView == '1'}
                onChange={(e) => { this.setStateFilter({ notActiveView: e.target.checked ? '1' : '0' }) }}
            >{this.getText(14590)}</Checkbox>
        </FormItem>)

        let custEmployeeId = fItem(17654, (<GenericSelector
            {...this.props}
            id={TableIDs.genericSelectorEmployees}
            api={"get_employee_list"}
            value={filters.custEmployeeId}
            onChange={(e) => { this.setStateFilter({ custEmployeeId: e }) }}
        />))


        let freeText = fItem(14239,
            (<Input value={filters._words} onChange={(e) => {
                this.setStateFilter({ _words: e.target.value })
                if (this.props.onChangeSearch) this.props.onChangeSearch(e)
            }} />))

        let titleBar = (<div style={{ padding: 4 }}>
            <PageToolbar
                title={this.getText(14231)}
                actionButtons={[]}
                isModal={true}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                data={this.props.data}
                dataActions={this.props.dataActions}
            />
        </div>)

        let close = this.props.onClose;

        let buttons = [
            <Button onClick={() => {
                if (this.props.setFilterActive) this.props.setFilterActive(false)
                else this.resetFilter()
            }}>{this.getText(14238)}</Button>,
            <Button type="primary" onClick={() => {
                this.setFilter(this.state.filters);
                if (this.props.setFilterActive) this.props.setFilterActive(true)
                close();
            }}>{this.getText(14237)}</Button>,
        ]

        return (<Modal
            destroyOnClose={true} width={window.innerWidth > 1000 ? 1000 : '95%'}
            visible={this.props.visible} onCancel={close} title={titleBar} footer={buttons}
        >
            <Row>
                <Col span={window.innerWidth > 1000 ? 12 : 24} style={{ paddingLeft: window.innerWidth > 1000 ? 10 : 0 }}>
                    {freeText}
                    {datesInline(14233, "birthDayLower", 14234, "birthDayUpper")}
                    {datesInline(14235, "clubJoinLower", 14236, "clubJoinUpper")}
                    {datesInline(17908, "lastClubJoinLower", 17909, "lastClubJoinUpper")}
                    {datesInline(14244, "updateDateLower", 14245, "updateDateUpper")}
                </Col>
                <Col span={window.innerWidth > 1000 ? 12 : 24} style={{ paddingRight: window.innerWidth > 1000 ? 10 : 0 }}>
                    {customerTags}
                    {creationPos}
                    {custCredit}
                    {custMailingIsActive}
                    {notActiveView}
                    {custEmployeeId}
                </Col>
            </Row>
        </Modal>)
    }
}
export default CustomerFilter;

/* @flow */
/*jshint esversion: 6 */
import React from "react";
import ReactDOM from "react-dom";
import { Menu, Icon, Badge } from "antd";
import { withRouter } from "react-router-dom";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext"

const SubMenu = Menu.SubMenu;

type History = {
  push: Function,
  location: {
    pathname: string
  }
};

type Props = {
  history: History,
  baseUrl: string
};

type State = {
  menuTag: any,
  menuItemsTop: Array<Object>,
  menuItemsDrop: Array<Object>
};

class CustomerMenu extends React.Component<Props, State> {
  myHistory: History;
  baseUrl: string;

  constructor(props: Props) {
    super(props);
    this.myHistory = this.props.history;
    this.baseUrl = this.props.baseUrl;

    if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
      props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
    }

    this.state = {
      menuTag: (<div></div>),
      menuItemsTop: [
        { KeyComp: "1", key: "customerList", text: "רשימת לקוחות", keyLang: 10152, icon: "table" },
        //{ key: "groups", text: "קבוצות", icon: "team" },
        //{ key: "settings", text: "הגדרות מערכת", icon: "table" },
        { KeyComp: "3", key: "customer-club", text: "מועדון לקוחות", keyLang: 10153, icon: "setting" },
        //{ key: "customer-fields", text: "שדות לקוחות", icon: "setting" },
        //{ key: "earning-points", text: "צבירת נקודות", icon: "setting" },
        //{ key: "using-points", text: "שימוש בנקודות", icon: "setting" },
        { KeyComp: "4", key: "direct-debit", text: "לקוחות להוראת קבע", keyLang: 10154, icon: "credit-card" },
        { KeyComp: "5", key: "direct-debit-charge", text: "חיוב הוראות קבע", keyLang: 10155, icon: "credit-card" },
        { KeyComp: "6", key: "read-file", text: "קליטת לקוחות", keyLang: 10156, icon: "cloud-upload" },
        { KeyComp: "8", key: "customer-points", text: "נקודות ללקוח", keyLang: 10157, icon: "wallet" },
        { KeyComp: "9", key: "subscribers", text: "מנויים", keyLang: 10158, icon: "idcard" },
        { KeyComp: "10", key: "coupons", text: "קופונים", keyLang: 10159, icon: "gift" },
        { KeyComp: "127", key: "coupons-v2", text: "קופונים 2", keyLang: 15614, icon: "gift" },
        { KeyComp: "121", key: "aggregate-card", text: "כרטיסיות צוברות", keyLang: 10160, icon: "cloud-upload" },
        { KeyComp: "123", key: "birthdays-report", text: "דוח ימי הולדת", keyLang: 10161, icon: "setting" },
        { KeyComp: "125", key: "app-registration", text: "אפליקציה", keyLang: 12935, icon: "setting" },
        { KeyComp: "126", key: "credit-card-charge", text: "חיוב באשראי", keyLang: 14034, icon: "setting" },
        { KeyComp: "128", key: "car-list", text: "שטיפת רכבים", keyLang: 16044, icon: "car" },
        { KeyComp: "129", key: "intercard", text: "אינטרקארד", keyLang: 16819, icon: "credit-card" },
        { KeyComp: "130", key: "telemarketing", text: "דו\"ח טלמרקטינג", keyLang: 17280, icon: "phone" },
        { KeyComp: "251", key: "aging-credit-cust", text: "דו\"ח גיול חובות", keyLang: 18714, icon: "sliders" },
        { KeyComp: "252", key: "coupon-check", text: "בדיקת קופון", keyLang: 18773, icon: "monitor" },
         // { KeyComp: "124", key: "self-create-customer", text: "", keyLang: 12593, icon: "setting" },

        { KeyComp: "-1", key: "mailing-list", text: "מערכת דיוור", icon: "mail" },

        { KeyComp: "0", key: "mut", text: "כרטיסיות", icon: "profile" },
        //  { KeyComp: "0", key: "coupons", text: "קופונים", icon: "gift" },
        { KeyComp: "0", key: "trash", text: "סל מחזור", icon: "delete" },
      ].filter((menuItem, index) => this.isActive(menuItem.KeyComp)),

      menuItemsDrop: []
    };

  }

  static contextType = LangContext;

  getText = (id) => {
    return this.context.get(id) || '[' + id + ']'
  }

  isActive = (key) => {
    return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
      .map(obj => obj.code).includes(key);
  };

  onMenuSelect(args) {
    this.myHistory.push(
      this.baseUrl + "/customers/" + (args.key !== "home" ? args.key : ""),
      {}
    );
  }

  updatePriorityMenu = () => {
    let maxHeight = 50; //px
    let _height = -1;
    let firstIter = true;

    let listTop = this.state.menuItemsTop;
    let listDrop = this.state.menuItemsDrop;
    // re-populate menu
    for (let i = 0; i < listDrop.length; i++) {
      listTop.push(listDrop.pop());
    }

    //ReactDOM.findDOMNode(this).clientHeight;
    while (_height > maxHeight || _height < 0) {
      //this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
      if (!firstIter) {
        this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
      }
      else {
        firstIter = false;
      }

      this.setState((prevState, props) => {
        return {
          menuTag: <Menu
            mode="horizontal"
            defaultSelectedKeys={[
              this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[2] || "home"
            ]}
            onSelect={this.onMenuSelect.bind(this)}
          >
            {this.renderMenuItemsTop()}
            <SubMenu title={<Badge count={listDrop.length}><span><Icon type="ellipsis" /></span></Badge>}>
              {this.renderMenuItemsDrop()}
            </SubMenu>
          </Menu>
        }
      });
      let e: Element | null | Text = ReactDOM.findDOMNode(this);
      _height = (e instanceof HTMLElement) ? e.clientHeight : 0;
      //alert(_height);
    }
  }

  renderMenuItemsTop() {
    let res = [];
    let list = this.state.menuItemsTop;
    if (list.length > 0) {
      for (let item of list) {
        res.push(
          <Menu.Item key={item.key}>
            <Icon type={item.icon} className="menu-icon" />
            <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
          </Menu.Item>);
      }
      return res;
    }
  }

  renderMenuItemsDrop() {
    let res = [];
    let list = this.state.menuItemsDrop;
    if (list.length > 0) {
      for (let item of list) {
        res.push(
          <Menu.Item key={item.key}>
            <Icon type={item.icon} className="menu-icon" />
            <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
          </Menu.Item>);
      }
    }
    return res;
  }

  doUpdateOfPriorityMenu = () => {
    setTimeout(this.updatePriorityMenu);
  };

  // Add event listener
  componentDidMount() {
    setTimeout(this.updatePriorityMenu);
    //window.addEventListener("resize", this.updatePriorityMenu);
    window.addEventListener("resize", this.doUpdateOfPriorityMenu);
  }

  // Remove event listener
  componentWillUnmount() {
    //window.removeEventListener("resize", this.updatePriorityMenu);
    window.removeEventListener("resize", this.doUpdateOfPriorityMenu);
  }

  render() {
    return this.state.menuTag;
  }
}

export default withRouter(CustomerMenu);

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Select, Switch, Checkbox, Popconfirm, Button, Form, Modal } from "antd";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import ModalCreateEditEmployee from "../../components/ModalCreateEditEmployee";
import ModalCreateEditEmployeeGroup from "../../components/ModalCreateEditEmployeeGroup";
import { fixCurrentPage, escapeRegExp } from "../../utils/utils.js"
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericSelector from "../../components/GenericSelector";
import LangContext from "../../contextProvider/LangContext";
import Jax from "../../Jax/jax.es6.module";
import RecyclingBin from "../../components/RecyclingBin";

const { Option, OptGroup } = Select;
const FormItem = Form.Item;

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class EmployeesTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "EMPLOYEES_MAIN";
        this.recycling_id = "EMPLOYEE_DELETION_RECYCLING";

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            height: window.innerHeight,
            modalFilter: false,
            modalFilterStoragesChecked: false,

            filterActive: false,
            valueSearch: '',

        };

        // calc page height
        this.doOnResize = null;

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.recycling_id] === undefined) props.data[this.recycling_id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericSelectorStorages] === undefined) props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorGroupEmployee] === undefined) props.data[TableIDs.genericSelectorGroupEmployee] = { ...props.data.genericSelector };



        this.api = {
            get: "get_employees_table_list",
            //create: "create_price",
            //edit: "edit_price",
            delete: "delete_employee",
            selector: "get_employee_group_list",
            selector_storages: "get_storage_list",

            get_recycling: "get_employee_deletion_recycling",
            restoration: "restoration_employee",

        }


        this.deletedText = (text, record) => (record['DeletionDate'] === null || record['DeletionDate'] === '')
            ? text : <div style={{ color: 'red', textDecoration: 'line-through' }}>{text}</div>

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        // this.initFilters();
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(prev) {
        document.title = "Cash On Tab - " + this.getText(10165);
        if (prev.data[TableIDs.genericSelectorStorages].dataset
            !== this.props.data[TableIDs.genericSelectorStorages].dataset) {
            this.setState(this.state, this.initFilters)
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        console.log("initfilter - num rows: ", numOfRowsByHeight, " h: ", this.state.height)

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
            _storageList: '',
            _group: '0',
            _isActive: 1,
        };
        this.apiTable(request);

    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
    }

    apiTable = (request) => {
        this.setState({
            filterActive: request._words || request._storageList || request._group > 0,
            valueSearch: request._words
        })
        let _storageList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((x) => x.code).join(',');
        if (!request._storageList) request = { ...request, _storageList: _storageList }

        // this.setState(this.state, () => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        // })
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        console.log("employee - handle table change: ",
            JSON.stringify(pagination), "\n",
            JSON.stringify(filters), "\n",
            JSON.stringify(sorter));

        let col = 8;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "ID": col--;
            case "Name": col--;
            case "Identification": col--;
            case "HomePhone": col--;
            case "MobilePhone": col--;
            case "Address": col--;
            case "IsActive": col--; // 7
            case "DeletionDate": break; // 8
            default:
                col = 0;
        }
        let request = {
            ...this.props.data[this.id].filters,
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //col
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
        };

        this.apiTable(request);
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    render() {
        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let request = {
            ...this.props.data[this.id].filters,
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            {
                title: this.getText(11055),
                dataIndex: "ID",
                key: "ID",
                sorter: true,
                width: '7%',
                render: this.deletedText,
            }, {
                title: this.getText(11056),
                dataIndex: "Name",
                key: "Name",
                width: '15%',
                sorter: true,
                render: this.deletedText,
            }, {
                title: this.getText(11057),
                dataIndex: "Identification",
                key: "Identification",
                width: '15%',
                sorter: true,
                render: this.deletedText,
            }, {
                title: this.getText(11058),
                dataIndex: "HomePhone",
                key: "HomePhone",
                width: '15%',
                sorter: true,
                render: this.deletedText,
            }, {
                title: this.getText(11059),
                dataIndex: "MobilePhone",
                key: "MobilePhone",
                width: '15%',
                sorter: true,
                render: this.deletedText,
            }, {
                title: this.getText(11060),
                dataIndex: "Address",
                key: "Address",
                width: '20%',
                sorter: true,
                render: this.deletedText,
            }, {
                title: this.getText(11061),
                dataIndex: "IsActive",
                key: "IsActive",
                width: '5%',
                sorter: true,
                render: (text, record) => {
                    return (<div><Checkbox
                        style={(record['DeletionDate'] === null || record['DeletionDate'] === '') ? { color: 'red' } : {}}
                        //onChange={(e) => { this.handleChangeCheckBox(e, text, "IsActive", record); }}
                        checked={(record['IsActive'] === 1 || record['IsActive'] === '1') ? true : false}
                        defaultChecked={false}
                        disabled={true}
                    /></div>)
                },
            }, {
                title: this.getText(11062),
                width: '8%',
                render: (text, record) => {
                    //if (this.props.data[this.id].editing !== record.key) {
                    return (record['DeletionDate'] === null || record['DeletionDate'] === '') ?
                        (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                this.props.uiActions.showModal(TableIDs.modalCreateEditEmployee, record['ID']);
                            }} />
                            <Popconfirm title={this.getText(11063)}
                                onConfirm={() => {
                                    console.log("record", record);
                                    fixCurrentPage.bind(this)();
                                    this.props.dataActions.genericDataDeleteRow(
                                        this.id,
                                        record['ID'],
                                        this.props.user.companyCode,
                                        this.props.user.token,
                                        this.api.delete,
                                        this.api.get);
                                }
                                }
                                okText={this.getText(11064)} cancelText={this.getText(11065)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                            </Popconfirm>
                        </span >) : '';
                }
            }
        ];

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(13969)}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <div/* style={divStyle}*/>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[30, 35, 35]}
                    title={this.getText(11054)}
                    actionButtons={[
                        {
                            buttonType: 'add',
                            buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalCreateEditEmployee,0) }
                        }, {
                            buttonType: 'refresh',
                            buttonFunction: this.refreshData
                        }, {
                            buttonType: 'filter',
                            buttonFunction: () => { this.setState({ modalFilter: true }) }
                        }, {
                            buttonDisabled: !this.state.filterActive,
                            buttonType: 'clear',
                            buttonFunction: this.initFilters,
                        },
                    ]}
                    genericActionButtons={[
                        <RecyclingBin
                            {...this.props}
                            id={this.recycling_id}
                            getApi={this.api.get_recycling}
                            restorationApi={this.api.restoration}
                            title={this.getText(16532)}
                            columnTitles={[this.getText(16533), this.getText(16534), this.getText(16535), this.getText(16540), this.getText(16536)]}
                            popconfirmText={{ title: this.getText(16537), okText: this.getText(16538), cancelText: this.getText(16539) }}
                            onClose={this.refreshData}
                        />
                    ]}
                    showsearch={(val) => {
                        if (val) { this.setState({ filterActive: true }) }
                        this.apiTable({
                            ...request, 
                            _words: escapeRegExp(val),
                            //_words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                            page: 1,
                            _page_num: 1,
                        })
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}

                />
                <Modal
                    destroyOnClose={true} width={600} visible={this.state.modalFilter}
                    title={titleBar}
                    onCancel={() => { this.setState({ modalFilter: false }) }}
                    footer={false}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataAction={this.props.dataAction}
                >
                    <Form id="filter_form" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: '1' }}>
                        <FormItem label={this.getText(12007)}>
                            <Checkbox onChange={(e) => {
                                this.setState({ modalFilterStoragesChecked: e.target.checked }, () => {
                                    let data = this.props.data[TableIDs.genericSelectorStorages].dataset.map(x => x.code);
                                    this.apiTable({
                                        ...request,
                                        _storageList: e.target.checked ? data.join(',') : ''
                                    })
                                })
                            }} />
                            <Select key={"mStorageList"} style={{ width: '95%' }} showsearch
                                filterOption={true} optionFilterProp="valuefilter" mode="multiple" maxTagCount={4}
                                placeholder={this.getText(13968)}
                                onChange={(value) => {
                                    this.setState({ modalFilterStoragesChecked: true }, () => {
                                        this.apiTable({ ...request, _storageList: value.join(',') })
                                    })
                                }}
                                value={!request._storageList || !this.state.modalFilterStoragesChecked ? [] : request._storageList.split(',')}
                            >{listStorage}</Select>
                        </FormItem>
                        <FormItem>
                            <FormItem label={this.getText(16068)}>
                                <GenericSelector
                                    {...this.props}
                                    id={TableIDs.genericSelectorGroupEmployee}
                                    api={this.api.selector}
                                    onChange={(value) => { this.apiTable({ ...request, _group: value }) }}
                                    value={request._group}
                                    addFirstRow={{ code: '0', name: this.getText(16067) }}
                                />
                            </FormItem>
                            <Switch
                                checkedChildren={this.getText(13970)}
                                unCheckedChildren={this.getText(13971)}
                                onChange={(value) => {
                                    this.apiTable({ ...request, _isActive: value ? 1 : 0 })
                                }}
                                checked={request._isActive == 1}
                            />
                        </FormItem>
                    </Form>
                </Modal>

                <ModalCreateEditEmployee
                    id={TableIDs.modalCreateEditEmployee}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditEmployee)}
                    title={this.getText(11066)}
                />

                <ModalCreateEditEmployeeGroup
                    id={TableIDs.modalCreateEditEmployeeGroup}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditEmployeeGroup)}
                    title={this.getText(11067)}
                />
            </div>
        );
    }
}

export default EmployeesTable;

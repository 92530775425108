/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Select, Tooltip, Popconfirm, Radio, Popover, Button, Form, Row, Col, Input } from "antd";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import { fixCurrentPage } from "../../utils/utils";
import ModalPurchaseAdvanceSearch from "../../components/ModalPurchaseAdvanceSearch";
import ModalEditPurchaseDocument from "../../components/ModalEditPurchaseDocument";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
import DateTimePicker from "../../components/DateTimePicker";
import GenericSelector from "../../components/GenericSelector";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class PurchaseDocumentsTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "PURCHES_DOCS_MAIN";

        this.reportRequestDefaults = {
            _words: '',
            _FromDate: undefined,
            _ToDate: undefined,
            _PosList: '',
            _SupplierList: '',
            _itemTagList: '',
            _typeID: '',
            reportName: "reports/PurchaseReport.pdf",
        }

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            modalReport: false,
            reportRequest: this.reportRequestDefaults,
        };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }


        this.api = {
            get: "get_document_purchase_list_table",
            delete: "delete_supplier",
        }

    }

    static contextType = LangContext;

    getText = (id) => this.context.get(id) || '[' + id + ']';

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        //this.refreshData();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, "list_pos");

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10183);
        if (
            this.props.data[TableIDs.genericSelectorPos].dataset !== prevProps.data[TableIDs.genericSelectorPos].dataset ||
            (this.props.data[TableIDs.genericSelectorPos].dataset[0] && !this.state.loading)
        ) this.setState({ loading: true }, this.initFilters())
    }

    initFilters = () => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            _FromDate: null,
            _ToDate: null,
            _PosList: fullPosList,
            _SupplierList: "",
            _typeID: "4",
            _isHandled: "0",
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 10;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mNumber": col--;
            case "mPOSID": col--;
            case "mTimestamp": col--;
            case "mDocumentRef2": col--;
            case "mRemarksDocDate": col--;
            case "mSupplierID": col--;
            case "mSupplierName": col--;
            case "mTotalWithTax": col--;
            case "mEmployeeID": col--;
            case "mTypeName": break; // 10
            default:
                col = 0;
        }
        console.log("Suppliers - handle table change: ", pagination, filters, sorter);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    generateReport = () => {
        const { _FromDate, _ToDate, _PosList, _SupplierList, _itemTagList, _isAsc, _sort_by, _words, _typeID, reportName } = this.state.reportRequest;

        let params = {
            _FromDate,
            _ToDate,
            _PosList,
            _SupplierList,
            _itemTagList,
            _isAsc,
            _sort_by,
            _words: encodeURIComponent(_words),
            _typeID,
            reportName,
            REPORT_LOCALE: this.getText(101),
        }
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [this.props.user.companyCode, this.props.user.token, params]
        });

    }

    getModalReportField = (field) => {
        const value = this.state.reportRequest[field]
        const onChange = (e) => { this.setState({ reportRequest: { ...this.state.reportRequest, [field]: e } }) }
        const minDate = field === '_ToDate' ? this.state.reportRequest._FromDate : null;
        const maxDate = field === '_FromDate' ? this.state.reportRequest._ToDate : null;
        switch (field) {
            case '_FromDate': case '_ToDate':
                return (<DateTimePicker format="DD/MM/YYYY" {...{ value, onChange, minDate, maxDate }} />)
            case '_PosList':
                return (<GenericSelectorPosStorages
                    {...this.props}
                    value={value ? value.split(",") : []}
                    onChange={e => { onChange(e ? e.join(",") : "") }}
                    // id={TableIDs.genericSelectorPos}
                    multi
                />)
            case '_SupplierList':
                return (<GenericSelectorSuppliers
                    {...this.props}
                    value={value ? value.split(",") : []}
                    onChange={e => { onChange(e ? e.join(",") : "") }}
                    multi
                />)
            case '_itemTagList':
                return (<GenericSelectorItemTags
                    {...this.props}
                    value={value ? value.split(",") : []}
                    onChange={e => { onChange(e ? e.join(",") : "") }}
                    multi
                />)
            case '_words':
                return (<Input value={value} onChange={e => { onChange(e.target.value) }} />)
            case '_typeID':
                return (<Radio.Group value={value} onChange={e => { onChange(e.target.value) }} >
                    <Radio value={''}>{this.getText(16233)}</Radio>
                    <Radio value={'4'}>{this.getText(16234)}</Radio>
                    <Radio value={'18'}>{this.getText(16235)}</Radio>
                </Radio.Group>);
            case 'reportName':
                return (<Select style={{ width: '100%' }} {...{ value, onChange }}>
                    <Select.Option value={"reports/PurchaseReport.pdf"}>{this.getText(16238)}</Select.Option>
                    <Select.Option value={"reports/PurchaseReportCentralSupplier.pdf"}>{this.getText(16239)}</Select.Option>
                    <Select.Option value={"reports/PurchaseReportCentralDoc.pdf"}>{this.getText(16240)}</Select.Option>
                    <Select.Option value={"reports/PurchaseReportCentralItems.pdf"}>{this.getText(17922)}</Select.Option>
                    <Select.Option value={"reports/PurchaseReportMatrixItemsByStorage.pdf"}>{this.getText(17923) + " " + this.getText(17924)}</Select.Option>
                    <Select.Option value={"reports/PurchaseReportMatrixItemsByColor.pdf"}>{this.getText(17923) + " " + this.getText(17925)}</Select.Option>
                </Select>)
        }
    }

    sendAPI = (script, data, sCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, (e) => { console.error(e) });
    };


    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mNumber: y[0],
                mPOSID: y[1],
                mTimestamp: y[2],
                mRemarksDocNum: y[3],
                mRemarksDocDate: y[4],
                mSupplierID: y[5],
                mSupplierName: y[6],
                mTotalWithTax: y[7],
                mEmployeeID: y[8],
                mTypeID: y[9],
                mTypeName: y[10],
                mDocumentRef2: y[11],
                mIsHandled: y[12],
                index: index
            })
        });


        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',

            _FromDate: null,
            _ToDate: null,
            _PosList: '',
            _SupplierList: '',
            _typeID: '4',
            _isHandled: '0',
        };

        let columns = [
            {
                title: this.getText(11825),
                dataIndex: "mNumber",
                key: "mNumber",
                sorter: true,
                width: '9%',
                render: (text, record) => text,
            }, {
                title: this.getText(16229),
                dataIndex: "mTypeName",
                key: "mTypeName",
                sorter: true,
                width: '10%',
                render: (text, record) => text
            }, {
                title: this.getText(11826),
                dataIndex: "mPOSID",
                key: "mPOSID",
                width: '7%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11827),
                dataIndex: "mTimestamp",
                key: "mTimestamp",
                width: '14%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11828),
                dataIndex: "mDocumentRef2",
                key: "mDocumentRef2",
                width: '8%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11829),
                dataIndex: "mRemarksDocDate",
                key: "mRemarksDocDate",
                width: '10%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11830),
                dataIndex: "mSupplierID",
                key: "mSupplierID",
                width: '8%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11831),
                dataIndex: "mSupplierName",
                key: "mSupplierName",
                width: '10%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11832),
                dataIndex: "mTotalWithTax",
                key: "mTotalWithTax",
                width: '10%',
                sorter: true,
                render: (text, record) => (record.mTypeID == '18' ? '-' : '') + text,
            }, {
                title: this.getText(11833),
                dataIndex: "mEmployeeID",
                key: "mEmployeeID",
                width: '8%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11834),
                width: '6%',
                render: (text, record) => {
                    return (
                        <span>
                            {(record.mTypeID == '4' || record.mTypeID == '16') ?
                                <Tooltip title={this.getText(11838)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                        //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                        this.props.uiActions.showModal(TableIDs.modalEditPurchaseDocument,
                                            { mNumber: record['mNumber'], mDocType: record["mTypeID"], mPOSID: record['mPOSID'] });
                                    }} />
                                </Tooltip>
                                : ""}
                            {/* <Popconfirm title={this.getText(11835)}
                                    onConfirm={() => {
                                        console.log("record", record);
                                        fixCurrentPage.bind(this)();
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.id,
                                            record['mName'],
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.get);
                                    }}
                                    okText={this.getText(11836)} cancelText={this.getText(11837)}>
                                    <Icon style={{ fontSize: 16, display: 'none' }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm> */}
                            <Tooltip title={this.getText(record.mIsHandled == '1' ? 19414 : 19415)}>
                                <Icon
                                    style={{ fontSize: 16, color: record.mIsHandled == '1' ? "red" : "green" }}
                                    className={"clickable margin-4"}
                                    type={record.mIsHandled == '1' ? "lock" : "unlock"}
                                    onClick={() => {
                                        let dataSend = "_num\f_type\f_pos\f_isHandled\r"
                                            + record.mNumber + "\f"
                                            + record.mTypeID + "\f"
                                            + record.mPOSID + "\f"
                                            + (record.mIsHandled == '1' ? '0' : '1')
                                        this.sendAPI("set_handled_doc", dataSend, this.refreshData)
                                    }} />
                            </Tooltip>
                        </span >);
                }

            }
        ];

        let actionButtons = [
            {
                buttonType: "filter",
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchPuchaseTable) }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

        // let radioReportType = (<Radio.Group style={{ direction: 'rtl' }} value={this.state.reportName} onChange={e => { this.setState({ reportName: e.target.value }) }}>
        //     <Radio value={"reports/PurchaseReport.pdf"}>{this.getText(16238)}</Radio>
        //     <Radio value={"reports/PurchaseReportCentralSupplier.pdf"}>{this.getText(16239)}</Radio>
        //     <Radio value={"reports/PurchaseReportCentralDoc.pdf"}>{this.getText(16240)}</Radio>
        //     <Button type={'primary'} onClick={this.generateReport}>{this.getText(16241)}</Button>
        // </Radio.Group>)

        let genericActionButtons = [
            <Tooltip title={this.getText(16236)}>
                {/* <Popover content={radioReportType} title={this.getText(16237)} trigger="click"> */}
                <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                    this.setState({ modalReport: true, reportRequest: { ...this.state.reportRequest, ...this.props.data[this.id].filters } })
                }}>
                    <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                </Button>
                {/* </Popover> */}
            </Tooltip >
        ]

        const { _FromDate, _ToDate, _PosList, _SupplierList, _itemTagList, _isAsc, _sort_by, _words, _typeID, reportName } = this.state.reportRequest;

        let params = {
            _FromDate,
            _ToDate,
            _PosList,
            _SupplierList,
            _itemTagList,
            _isAsc,
            _sort_by,
            _words: encodeURIComponent(_words),
            _typeID,
            reportName,
            REPORT_LOCALE: this.getText(101),
        }

        return (
            <div style={divStyle}>
                <PageToolbar
                    title={this.getText(11824)}
                    actionButtons={actionButtons}
                    genericActionButtons={genericActionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />
                <ResponsiveTable
                    tableOnly={true}
                    percentageToMobile={[false, 20, 40, false, false, false, false, 40]}
                    ui={this.props.ui}
                    idTable={this.props.data[this.id]}
                    dataSource={dataSource}
                    columns={columns}
                    onChange={this.handleTableChange}
                />

                <ModalPurchaseAdvanceSearch
                    id={TableIDs.modalSearchPuchaseTable}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchPuchaseTable)}
                />

                <ModalEditPurchaseDocument
                    id={TableIDs.modalEditPurchaseDocument}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalEditPurchaseDocument)}
                />

                <GenericModal
                    visible={this.state.modalReport}
                    onCancel={() => { this.setState({ modalReport: false }) }}
                    title={this.getText(17912)}
                    width={1200}>
                    <Row>
                        <Col span={window.innerWidth > 600 ? 12 : 24}>
                            <Form.Item label={this.getText(17913)}>{this.getModalReportField("_FromDate")}</Form.Item>
                            <Form.Item label={this.getText(17914)}>{this.getModalReportField("_ToDate")}</Form.Item>
                            <Form.Item label={this.getText(17915)}>{this.getModalReportField("_PosList")}</Form.Item>
                            <Form.Item label={this.getText(17916)}>{this.getModalReportField("_SupplierList")}</Form.Item>
                            <Form.Item label={this.getText(19026)}>{this.getModalReportField("_itemTagList")}</Form.Item>
                        </Col>
                        <Col span={window.innerWidth > 600 ? 12 : 24} style={{ paddingRight: 20 }}>
                            <Form.Item label={this.getText(17917)}>{this.getModalReportField("_words")}</Form.Item>
                            <Form.Item label={this.getText(17918)}>{this.getModalReportField("_typeID")}</Form.Item>
                            <Form.Item label={this.getText(17919)}>{this.getModalReportField("reportName")}</Form.Item>
                            <ReportTypeButton
                                {...this.props}
                                params={params}
                                posField={'_posList'}
                                datesToFavorites={[
                                    { field: '_FromDate', label: this.getText(17913) },
                                    { field: '_ToDate', label: this.getText(17914) },
                                ]}
                                favoriteSave
                            />
                        </Col>
                    </Row>

                </GenericModal>
            </div>
        );
    }
}

export default PurchaseDocumentsTable;

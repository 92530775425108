
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Input, Checkbox } from "antd";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericModal from "../../components/GenericModal";
import TableLineIcons from "../../components/TableLineIcons";

class VirtualShopNetworkLinks extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}

        this.id = "VIRTUAL_SHOP_NETWORK_LINKS_TABLE";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.api = {
            get: "get_virtual_shop_network_links_table",
            save: "save_virtual_shop_network_link",
            delete: "delete_virtual_shop_network_link",
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, {}, this.api.get)
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.refreshData();
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    saveEdit = () => {
        this.props.dataActions.genericDataSaveEdit(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.save, this.api.get);
    }

    cancelEdit = () => {
        if (this.props.data[this.id].editedRecord.key == 0)
            this.props.dataActions.genericDataCancelInlineCreate(this.id);
        else
            this.props.dataActions.genericDataCancelEditing(this.id);
    }

    getButtonsEdit = (text, record) => {
        let onEdit = () => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }
        let onDelete = () => {
            this.props.dataActions.genericDataDeleteRow(
                this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
        }
        let deleteQuetion = { title: this.getText(14370), okText: this.getText(14371), cancelText: this.getText(14372) }
        let onCheck = this.saveEdit;
        let onClose = this.cancelEdit;

        let props = (record.key !== this.props.data[this.id].editing) ? { onEdit, onDelete, deleteQuetion } : { onCheck, onClose }
        return (<TableLineIcons {...props} />)
    }

    render() {
        let gt = this.getText;
        let isEdit = (r) => r.key === this.props.data[this.id].editing;
        let editedRecord = this.props.data[this.id].editedRecord;
        let onChange = (f, v) => { this.props.dataActions.genericDataSetEdit(this.id, { [f]: v }) }

        let tInput = (t, r, f) => isEdit(r) ? (<Input value={editedRecord[f]} onChange={(v) => { onChange(f, v.target.value) }} />) : t

        let cb = (t, r, f) => {
            return (<Checkbox
                checked={(!isEdit(r) && t == '1') || (isEdit(r) && editedRecord[f] == '1')}
                disabled={!isEdit(r)}
                onChange={(e) => { onChange([f], e.target.checked ? '1' : '0') }}
            />);
        }

        let columns = [
            { title: gt(15805), key: 'kDesc', dataIndex: 'kDesc', width: '20%', render: (t, r) => tInput(t, r, 'kDesc') },
            { title: gt(15806), key: 'kURL', dataIndex: 'kURL', width: '40%', render: (t, r) => tInput(t, r, 'kURL') },
            { title: gt(15807), key: 'kNetwork', dataIndex: 'kNetwork', width: '20%', render: (t, r) => tInput(t, r, 'kNetwork') },
            { title: gt(15808), key: 'kIsActive', dataIndex: 'kIsActive', width: '10%', render: (t, r) => cb(t, r, 'kIsActive') },
            { title: gt(15809), width: '10%', render: this.getButtonsEdit },
        ];

        return (<GenericModal
            visible={this.props.visible}
            onCancel={this.props.onCancel}
            width={'95%'}
            title={gt(15804)}
            actionButtons={[{
                buttonType: 'add',
                buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) }
            },{
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }]}>
            <ResponsiveTable
                tableOnly={true}
                idTable={this.props.data[this.id]}
                columns={columns}
                percentageToMobile={[100]}
                ui={this.props.ui}
                pagination={{ pageSize: 10 }}
            />
        </GenericModal>)
    }
}
export default VirtualShopNetworkLinks;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import {
    Card, //Col, Row, Button, 
    Form, //Select, 
    Checkbox, InputNumber
} from "antd";
import Jax from "../../Jax/jax.es6.module";
//import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import { disabledDatePicker } from "../../utils/utils";

const FormItem = Form.Item;
//const Option = Select.Option;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class TelemarketingReport extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            TelemarketingReport: {
                mLowerSelectionDate: null,
                mUpperSelectionDate: null,
                mApplyRangeApply: 0,
                mApplyRangeLower: 0,
                mApplyRangeUpper: 0,
                reportName: 'reports/TelemarketingReport.pdf'
            }
        };
        this.state.type = "pdf";

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(17280);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("TelemarketingReport", this.props.ui.favoriteParams)
        }
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    disabledStartDate = (FromDate) => {
        const ToDate = this.state.TelemarketingReport.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.TelemarketingReport.mUpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("TelemarketingReport", { [field]: data });
                    else
                        this.setReportParameters("TelemarketingReport", { [field]: '' });
                }} />);
    }

    onChange = (field, value) => {
        this.setReportParameters("TelemarketingReport", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }


    render() {
        const { TelemarketingReport, endOpen } = this.state;
        const {
            mLowerSelectionDate,
            mUpperSelectionDate,
            mApplyRangeApply,
            mApplyRangeLower,
            mApplyRangeUpper,
            reportName
        } = TelemarketingReport;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };


        let resStyle = (first) => {
            let res = {
                display: 'inline-block',
                verticalAlign: 'text-top',
                width: '50%'
            }
            let mRes = (first) ? { marginTop: 80 } : {}
            return (window.innerWidth > 600) ? res : mRes
        }
        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(17280)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: (window.innerWidth > 600) ? '60%' : '96%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <div style={resStyle(true)}>
                        <FormItem label={this.getText(17281)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                            <DatePicker
                                disabledDate={d => disabledDatePicker(d, null, mUpperSelectionDate)}
                                // disabledDate={this.disabledStartDate}
                                //showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={mLowerSelectionDate ? moment(mLowerSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(17284)}
                                onChange={this.onStartChange}
                            //onOpenChange={this.handleStartOpenChange}
                            />
                        </FormItem>
                        <FormItem label={this.getText(17282)}>
                            <DatePicker
                                disabledDate={d => disabledDatePicker(d, mLowerSelectionDate, null)}
                                // disabledDate={this.disabledEndDate}
                                //showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={mUpperSelectionDate ? moment(mUpperSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(17285)}
                                onChange={this.onEndChange}
                            //open={endOpen}
                            //onOpenChange={this.handleEndOpenChange}
                            />
                        </FormItem>
                    </div>
                    <div style={resStyle(false)}>
                        <FormItem label={this.getText(17283)}>
                            <Checkbox
                                //onChange={this.handleCheckBoxRange}
                                //ApplyRange_Apply
                                checked={mApplyRangeApply == 1}
                                onChange={(value) => {
                                    let req = { mApplyRangeApply: value.target.checked ? 1 : 0 }
                                    if (!value.target.checked) req = { ...req, mApplyRangeLower: 0, mApplyRangeUpper: 0 }
                                    this.setReportParameters("TelemarketingReport", req)
                                }}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Lower
                                min={1}
                                value={this.state.TelemarketingReport.mApplyRangeApply &&
                                    this.state.TelemarketingReport.mApplyRangeLower ?
                                    this.state.TelemarketingReport.mApplyRangeLower : ''}
                                onChange={(value) => this.setReportParameters("TelemarketingReport", { mApplyRangeLower: value })}
                                disabled={this.state.TelemarketingReport.mApplyRangeApply != 1}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Upper
                                min={1}
                                value={this.state.TelemarketingReport.mApplyRangeApply &&
                                    this.state.TelemarketingReport.mApplyRangeUpper ?
                                    this.state.TelemarketingReport.mApplyRangeUpper : ''}
                                onChange={(value) => this.setReportParameters("TelemarketingReport", { mApplyRangeUpper: value })}
                                disabled={this.state.TelemarketingReport.mApplyRangeApply != 1}
                                style={{ marginLeft: '4px' }}

                            />
                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.TelemarketingReport}
                            datesToFavorites={[
                                { field: 'mLowerSelectionDate', label: this.getText(17281) },
                                { field: 'mUpperSelectionDate', label: this.getText(17282) },
                            ]}
                            favoriteSave
                        />

                    </div>
                    {/* </Form> */}
                </Card>
            </div>
        );
    }
}
export default TelemarketingReport;
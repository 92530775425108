/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Row, Col, Card } from "antd";
import moment from "moment";

type Props = {
    kdsData: Object,
    noDataText: String,
    sendAPI: Function,
    setJson: Function,
    data: Object,
    height: Number,
}

class KdsCarList extends Component {
    constructor(props: Props) {
        super(props);
        this.state = { };

        this.loop1 = null;
        this.loop2 = null;

        this.counterRefresh = 0;
        this.counterPage = 0;

        this.id = "KDS_CAR_LIST";

        this.api = {
            get: "get_car_list",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { groups: [], newEnd: [] }

    }

    componentDidMount() {
        this.getDataApi();

        this.loopRefresh();
        this.loopRows();
    }

    componentWillUnmount() {
        clearTimeout(this.loop1);
        clearTimeout(this.loop2);
    }

    loopRefresh = () => {
        let refreshTime = parseInt(this.props.kdsData.sRefreshTime);
        let pageTime = parseInt(this.props.kdsData.sScrollTime);
        let groups = this.props.data[this.id].groups;

        this.counterRefresh++;
        this.counterPage++;

        if (refreshTime <= this.counterRefresh) {
            this.getDataApi();
            this.counterRefresh = 0;
            this.counterPage = 0;

            groups.forEach(x => {
                let kdsContent = document.getElementById('kds_content_' + x.id);
                if (kdsContent) kdsContent.scrollTop = 0;
            })

        } else if (pageTime <= this.counterPage) {
            this.counterPage = 0;

            groups.forEach(x => {
                let kdsContent = document.getElementById('kds_content_' + x.id);
                if (kdsContent) {
                    if (kdsContent.scrollTop + this.props.height < kdsContent.scrollHeight) {
                        if (this.props.kdsData.sScrollType == '2') kdsContent.scrollTop += this.props.height * 0.75;
                    } else {
                        kdsContent.scrollTop = 0;
                    }
                }
            })
        }

        this.loop1 = setTimeout(this.loopRefresh, 1000);
    }


    loopRows = () => {

        if (this.props.kdsData.sScrollType == '1') {
            let pageTime = parseInt(this.props.kdsData.sScrollTime);
            let step = Math.round(this.props.height / 50 / pageTime);
            this.props.data[this.id].groups.forEach(x => {
                let kdsContent = document.getElementById('kds_content_' + x.id);
                if (kdsContent) kdsContent.scrollTop += step;
            });
        }

        let newEndContent = document.getElementById('new_end_order');
        if (newEndContent) {
            newEndContent.style.left = (parseInt(newEndContent.style.left) - 5) + 'px';
            if (parseInt(newEndContent.style.left) <= (this.props.leftView ? this.props.leftView : 0)) {
                this.props.setJson(this.id, { newEnd: [] })
            }
        }

        this.loop2 = setTimeout(this.loopRows, 20);
    }

    getDataApi = () => {
        let _branches = this.props.kdsData.sPosList;
        let _curdate = moment().format("YYYY-MM-DD");
        let dataSend = "_words\f_branches\f_date_from\f_date_to\f_employeeGroups\r\f" + _branches + "\f" + _curdate + " 00:00:00\f" + _curdate + " 23:59:59\f";

        this.props.sendAPI(this.api.get, dataSend, (ob) => {
            let notFinishList = [];
            let finishList = [];
            let newEnd = [];
            let oldEndData = this.props.data[this.id].groups[1];
            if (ob.data) {
                let data = ob.data.split("\r");
                let headers = data.splice(0, 1)[0].split("\f");

                data.forEach((x, index) => {
                    let y = x.split("\f");
                    let obj = { index, key: index }
                    headers.forEach((h, i) => { obj = { ...obj, [h]: y[i] } });

                    if (!obj.tExitDateTime && !obj.tDeletedMessage) {
                        if (obj.tFinishDateTime) {
                            let tData = { ...obj, tDateTime: moment(obj.tFinishDateTime).format("HH:mm") }
                            finishList.push(tData);
                            if (oldEndData && oldEndData.data && !oldEndData.data.find(f => f.tCarNumber == obj.tCarNumber)) {
                                newEnd.push(tData);
                            }
                        } else {
                            notFinishList.push({ ...obj, tDateTime: moment(obj.tEnterDateTime).format("HH:mm") });
                        }
                    }
                })
            }


            // let groups = [];
            // let oldEndData = this.props.data[this.id].groups[1];
            // let newEnd = [];
            // if (obj.data) {
            //     let gData = obj.data.split('\r');
            //     gData.forEach(x => {
            //         let y = x.split('\f');
            //         let gFind = groups.findIndex(f => f.id === y[4]);
            //         let oData = { tCarNumber: y[0], oDatetime: y[1] }
            //         if (gFind == -1) {
            //             groups.push({ id: y[4], data: [oData] })
            //         } else {
            //             groups[gFind] = { ...groups[gFind], data: [...groups[gFind].data, oData] }
            //         }

            //         if (oldEndData && oldEndData.data && !oldEndData.data.find(f => f.tCarNumber == y[0]) && y[4] == '2') {
            //             console.log('newEnd', oData, y[0], oldEndData.data)

            //             newEnd.push(oData);
            //         }
            //     });
            // }

             this.props.setJson(this.id, { groups: [{ id: '1', data: notFinishList }, { id: '2', data: finishList }], newEnd });
        });
    }

    cardStyle = (group) => {
        let rowHeight = this.props.height / 9.5;
        let viewTime = (group.id == '1' && this.props.kdsData.sViewTimeRight == '1') || (group.id == '2' && this.props.kdsData.sViewTimeLeft == '1');

        let background = this.props.kdsData.sBackgroundImage ? 'auto': '#FAEBD7';

        let rowStyle = {
            height: rowHeight,
            padding: rowHeight * 0.1
        }

        let contentStyle = {
            // color: '#ffffff',
            // background: '#000000',
            borderRadius: rowHeight * 0.2,
            // height: rowHeight * 0.8,
            padding: rowHeight * 0.1,
            fontSize: rowHeight * 0.6,
            textAlign: 'center',
            fontWeight: 'bold'
        }

        const divStyle = {
            // width: '100%',
            height: rowHeight * (viewTime ? 8 : 9),
            overflowY: 'auto',
            background,
            borderRadius: rowHeight * 0.2,

        }


        let titleDiv = (<h1 style={{ ...contentStyle, background: this.props.kdsData.sBackgroundImage ? 'auto': 'pink' }}>{this.props.getText(group.id == '1' ? 15881 : 15882)}</h1>)

        let secondTitles = viewTime ?
            (<Row style={{ ...contentStyle, background, marginBottom: 10 }}>
                <Col span={12}><h5>{this.props.getText(17157)}</h5></Col>
                <Col span={12}><h5>{this.props.getText(15884)}</h5></Col>
            </Row>)
            : "";

        let contentDiv = (<div id={'kds_content_' + group.id} style={divStyle}>
            {viewTime ?
                group.data.map(x => {
                    return (<div style={rowStyle}>
                        <Row style={contentStyle}>
                            <Col span={12}>{x.tCarNumber}</Col>
                            <Col span={12}>{x.tDateTime}</Col>
                        </Row>
                    </div>)
                })
                : (<Row>{group.data.map(x => (<Col span={8} style={{ ...rowStyle, ...contentStyle }}>{x.tCarNumber}</Col>))}</Row>)}
        </div>)

        return [titleDiv, secondTitles, contentDiv]
    }

    render() {
        let rowHeight = this.props.height / 9.5;

        let contentStyle = {
            borderRadius: rowHeight * 0.2,
            padding: rowHeight * 0.1,
            fontSize: rowHeight * 0.6,
            textAlign: 'center',
            background: this.props.kdsData.sBackgroundImage ? 'auto': '#FAEBD7',
            border: '2px solid pink'
        }

        let groupsCount = this.props.data[this.id].groups.length;
        return groupsCount ? (<Row>
            {this.props.data[this.id].groups.map(x => {
                return (<Col span={parseInt(24 / groupsCount)} style={{ paddingLeft: 20, paddingRight: 20 }}>
                    {this.cardStyle(x)}
                </Col>)
            })}
            {this.props.data[this.id].newEnd.length ?
                (<Col id={'new_end_order'} span={12} style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    position: 'fixed',
                    top: this.props.height / 2,
                    left: window.innerWidth / 2,
                }}>
                    {this.props.data[this.id].newEnd.map(x => {
                        return (<Row style={{ ...contentStyle, }}>
                            <Col span={12}><h5>{x.tCarNumber}</h5></Col>
                            <Col span={12}><h5>{x.tDatetime}</h5></Col>
                        </Row>)
                    })}
                </Col>)
                : ''}
        </Row>) :
            <div style={{ height: rowHeight }}>
                {this.props.getText(15878)}
            </div>
    }
}

export default KdsCarList;
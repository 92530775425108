
import React from "react";
import { Input, Form, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message, InputNumber } from 'antd';
import ResponsiveTable from "./ResponsiveTable";
//import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
//import moment from "moment";
import LangContext from "../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;


/*
type Props = {
    //title: string,
    apiCalls: any[],
    api_create: any,
    text:any,
};
*/
//type State = {}
const topHeight = 340; // px
const rowHeight = 30;  // px

class ModalCreateEditDirectDebit extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        //this.id = 'CUST_DIRECT_DEBIT_CREATE_EDIT';
        this.id_directdebit = 'DIRECT_DEBIT_CREATE_EDIT';

        //this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        //this.state.isValidItemCode = false;
        //this.itemCode = null;
        //this.IsDefault = null;

        //this.state.itemPick = null;
        //this.fieldsMeta = { unknown: { validateStatus: null, value: null } };

        //if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.id_directdebit] === undefined) {
            props.data[this.id_directdebit]
                = { ...props.data.genericData };
        }

        /*
        if (props.data[TableIDs.genericSelectorTaxType] === undefined) {
            props.data[TableIDs.genericSelectorTaxType] = { ...props.data.genericSelector };
        }*/

        this.api = {
            create_edit: "mock_create_edit_direct_debit",

            create: "cardcom_tokenize",
            edit: "deactivate_credit_card",

            get_direct_debit: "get_direct_debit_list_table",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        },/* {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/];

       
        this.picksPriceList = [];
        this.picksDirectDebit = [];
        // ddd

    }

	static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
      //  document.title = "Cash On Tab - עריכת/הוספת הוראת קבע";
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditDirectDebit);
    }
    handleOk = () => {
        //e.preventDefault();
        let isValid = this.checkMultiEntries();
        if (isValid == true) {
            this.props.dataActions.genericDataJustSaveEditMulti(
                this.id_directdebit,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.create_edit,
                [
                    //{ name: this.id },
                    { name: this.id_directdebit, sendDataTable: true },
                ],
                {
                    action: this.props.dataActions.genericDataGetRequested,
                    args: [this.id_directdebit /*this.props.context*/, this.props.user.companyCode,
                    this.props.user.token, this.api.get_direct_debit/*this.props.context_api_get*/]
                }
            )
            /*
                        this.props.ActionQueue.addToQueue({
                            action: this.props.dataActions.genericDataJustSaveEditMulti,
                            args: [this.id,
                            this.props.user.companyCode,
                            this.props.user.token,
                            this.api.create_edit,
                            [
                                { name: this.id },
                                { name: this.id_suppliers, sendDataTable: true },
                                { name: this.id_priceList, sendDataTable: true },
                                { name: this.id_tags, sendDataTable: true }]]
                        });
                        */
            /*
             this.props.ActionQueue.addToQueue({
                 action: this.props.dataActions.genericDataGetRequested,
                 args: ["ITEMS_TABLE", this.props.user.companyCode, this.props.user.token, "get_items_list"]
             });
             */
            //this.handleClear();
            this.props.uiActions.hideModal(TableIDs.modalCreateEditDirectDebit);
        }

    }

    errorSuppliers = () => {
        message.error(this.getText(10813));
    }

    errorSuppliersIsEdit = () => {
        message.error(this.getText(10814));
    }

    handleCreate = () => {
        let isValid = this.checkFields();
        if (isValid == true) {
            this.props.dataActions.genericDataJustSaveEditMulti(
                this.id_directdebit,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.create,
                [
                    //{ name: this.id },
                    { name: this.id_directdebit, sendDataTable: false },
                ],
                {
                    action: this.props.dataActions.genericDataGetRequested,
                    args: [this.id_directdebit /*this.props.context*/, this.props.user.companyCode,
                    this.props.user.token, this.api.get_direct_debit/*this.props.context_api_get*/]
                }
            )
            //this.props.uiActions.hideModal(TableIDs.modalCreateEditDirectDebit);
        }
        else {
            message.warning(this.getText(10815), 2);
        }
    }

    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditDirectDebit);
    }
    handleClear = () => {
        //this.clear();
        console.log('clear form');
        this.props.data[this.id].editedRecord = { mCode: this.props.data[this.id].editedRecord['mCode'] };
        this.props.data[this.id_directdebit].data = [];
        this.setState(this.state);
    }

    checkFields = () => {
        const { mCVV, mCustomerID, mHolderID, mIsActive, mLast4, mRemarks, mSource, mValid }
            = this.props.data[this.id_directdebit].editedRecord;

        if (mCVV == '' || mCVV == null) return false;
        //if (mCustomerID == '' || mCustomerID == null) return false;
        if (mHolderID == '' || mHolderID == null) return false;
        if (mIsActive == '' || mIsActive == null) return false;
        if (mLast4 == '' || mLast4 == null) return false;
        //if (mRemarks == '' || mRemarks == null) return false;
        if (mSource == '' || mSource == null) return false;
        if (mValid == '' || mValid == null) return false;

        return true;
    }

    checkMultiEntries = () => {
        let directdebit = this.props.data[this.id_directdebit]
            .data.map((x, index) => { let y = x.split("\f"); return y[1] });
        let unique_directdebit = directdebit.filter((elem, index, self) => {
            return index == self.indexOf(elem);
        });

        if (unique_directdebit.length != directdebit.length) {
            this.errorSuppliers()
            return false;
        }

        if (this.props.data[this.id_directdebit].editing > -1) {
            this.errorSuppliersIsEdit();
            return false;
        }

        return true;
    }

    handleSaveChanges = (e: Event) => {
        //e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
        this.props.dataActions.genericDataJustSaveEditMulti(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            this.api.create_edit,
            //sections: Array<Object>
        )
        this.props.uiActions.hideModal(TableIDs.modalCreateEditDirectDebit);
    }

    handleDirectDebitCreate = () => {
        //this.props.dataActions.genericDataStartInlineCreate(this.id_directdebit);
        this.props.uiActions.showModal(TableIDs.modalCreateCreditCard, this.props.ui.data);
    }

    generateInputField = (text, handle, val) => {
        return (
            <div style={{ padding: '4px' }}>
                <span style={{ display: 'inline-block', width: '15%', textAlign: 'left' }}>{text}</span>
                <Input
                    //disabled={!this.state.isValidItemCode}
                    type="text"
                    //placeholder="טקסט"
                    value={val}
                    style={{ width: '65%', marginRight: '3%' }}
                    onChange={handle} />
            </div>
        )
    }

    generateNumericInputField = (text, handle, val) => {
        return (
            <div style={{ padding: '4px' }}>
                <span style={{ display: 'inline-block', width: '15%', textAlign: 'left' }}>{text}</span>
                <InputNumeric
                    //disabled={!this.state.isValidItemCode}
                    type="text"
                    //placeholder="טקסט"
                    value={val}
                    style={{ width: '65%', marginRight: '3%' }}
                    onChange={handle} />
            </div>
        )
    }

    // TODO: remove me
    handleInputChangeItemShortName = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mShortName']: e.target.value });
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id_directdebit].editing === record.key)
            ? this.props.data[this.id_directdebit].editedRecord[col] : text;
        return (checkedVal === 1 || checkedVal === "1");
    }
    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id_directdebit, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    // TODO: remove me
    handleCheckBoxChangeIsActive = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsActive']: (e.target.checked) ? 1 : 0 });
    }

    render() {
        const { visible, loading } = this.state;
        //let request = { _id: this.props.ui.data, };
        let genericActionButtons = [
            (<Tooltip title={this.getText(10816)}>
                <Button key="create_direct_debit" className={"btn"} /*disabled={(this.props.data[this.id_directdebit].editing > -1)}*/
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={this.handleDirectDebitCreate}>
                    <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="plus" theme="outlined" />
                </Button>
            </Tooltip>),
            (<Tooltip title={this.getText(10817)}>
                <Button key="cancel_credit_card" className={"btn"}
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => {
                        this.props.dataActions.genericDataSaveEditWithParams(
                            this.id_directdebit, this.props.user.companyCode, this.props.user.token,
                            this.api.edit, this.api.get_direct_debit, { mCustomerID: this.props.ui.data });
                    }}>
                    <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="credit-card" theme="outlined" />
                </Button>
            </Tooltip>),
            //(<Button key="create_direct_debit" /*disabled={!this.state.isValidItemCode}*/onClick={this.handleDirectDebitCreate}>צור חדש</Button>),
        ]

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10804)}
                    actionButtons={this.actionButtons}
                    genericActionButtons={genericActionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        let styleRow = { direction: 'ltr' };
        let styleCol = { direction: 'rtl' };
        let stylePadding = { padding: '4px' };
        let modalStyle = { direction: 'rtl' };

        console.log("supp: ", this.props.data[this.id_directdebit]);
        console.log("supp data: ", this.props.data[this.id_directdebit].data);

        const dataSourceDirectDebit = this.props.data[this.id_directdebit].data.map((x, index) => {
            let y = x.split("\f");

            return ({
                key: y[0] + y[1] + index,
                mCustomerID: y[0],
                mLast4: y[1],
                mValid: y[2],
                mCVV: y[3],
                mHolderID: y[4],
                mIsActive: y[5],
                mSource: y[6],
                mRemarks: y[7],
                index: index
            })
        });

        let columnsDirectDebit = [
            {
                title:  this.getText(10805),
                dataIndex: "mCustomerID",
                key: "mCustomerID",
                sorter: false,
                width: '18%',
                render: (text, record) => text,
            }, {
                title:  this.getText(10806),
                dataIndex: "mLast4",
                key: "mLast4",
                sorter: true,
                width: '10%',
                render: (text, record) => {
                    if (this.props.data[this.id_directdebit].editing === record.key) {
                        if (this.props.data[this.id_directdebit].editing == 0) {
                            return (
                                <Input
                                    //pattern="[0-9]*"  
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id_directdebit, { mLast4: value.target.value });
                                    }} />);
                        }
                        else {
                            return text;
                        }
                    } else {
                        return text;
                    }
                },
            }, {
                title:  this.getText(10807),
                dataIndex: "mValid",
                key: "mValid",
                sorter: true,
                width: '10%',
                render: (text, record) => {
                    if (this.props.data[this.id_directdebit].editing === record.key) {
                        if (this.props.data[this.id_directdebit].editing == 0) {
                            return (
                                <Input
                                    //pattern="[0-9]*"  
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id_directdebit, { mValid: value.target.value });
                                    }} />);
                        }
                        else {
                            return text;
                        }
                    } else {
                        return text;
                    }
                },
            }, 
            // {
            //     title:  this.getText(10808),
            //     dataIndex: "mCVV",
            //     key: "mCVV",
            //     sorter: true,
            //     width: '10%',
            //     render: (text, record) => {
            //         if (this.props.data[this.id_directdebit].editing === record.key) {
            //             if (this.props.data[this.id_directdebit].editing == 0) {
            //                 return (
            //                     <Input
            //                         style={{ width: '98%', textAlign: 'center' }}
            //                         defaultValue={text}
            //                         onChange={(value) => {
            //                             this.props.dataActions.genericDataSetEdit(this.id_directdebit, { mCVV: value.target.value });
            //                         }} />);
            //             }
            //             else {
            //                 return text;
            //             }
            //         } else {
            //             return text;
            //         }
            //     },
            // }, 
            {
                title:  this.getText(10809),
                dataIndex: "mHolderID",
                key: "mHolderID",
                sorter: true,
                width: '13%',
                render: (text, record) => {
                    if (this.props.data[this.id_directdebit].editing === record.key) {
                        if (this.props.data[this.id_directdebit].editing == 0) {
                            return (
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id_directdebit, { mHolderID: value.target.value });
                                    }} />);
                        }
                        else {
                            return text;
                        }
                    } else {
                        return text;
                    }
                },
            }, {
                title:  this.getText(10810),
                dataIndex: "mIsActive",
                key: "mIsActive",
                sorter: true,
                width: '10%',
                render: (text, record) => {
                    return (<Checkbox
                        onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsActive", record); }}
                        checked={this.checkIfChecked("mIsActive", text, record)}
                        defaultChecked={(this.IsDefault == null) ? true : false}
                    //disabled={!(this.props.data[this.id_directdebit].editing > -1)}
                    />)
                },
            }, {
                title:  this.getText(10811),
                dataIndex: "mSource",
                key: "mSource",
                sorter: true,
                width: '15%',
                render: (text, record) => {
                    if (this.props.data[this.id_directdebit].editing === record.key) {
                        if (this.props.data[this.id_directdebit].editing == 0) {
                            return (
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id_directdebit, { mSource: value.target.value });
                                    }} />);
                        }
                        else {
                            return text;
                        }
                    } else {
                        return text;
                    }
                },
            }, {
                title:  this.getText(10812),
                dataIndex: "mRemarks",
                key: "mRemarks",
                sorter: true,
                width: '19%',
                render: (text, record) => {
                    if (this.props.data[this.id_directdebit].editing === record.key) {
                        if (this.props.data[this.id_directdebit].editing == 0) {
                            return (
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id_directdebit, { mRemarks: value.target.value });
                                    }} />);
                        }
                        else {
                            return text;
                        }
                    } else {
                        return text;
                    }
                },
            }, /*{
                title: "עריכה",
                width: '8%',
                render: (text, record) => {
                    if (this.props.data[this.id_directdebit].editing !== record.key) {
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.props.dataActions.genericDataStartEditing(this.id_directdebit, record.key, record.index);
                                    //this.props.uiActions.showModal(TableIDs.modalCreateEditDirectDebit, record['mId']);
                                }} />
                                {
                                //<Popconfirm title={"האם למחוק?"}
                                //    onConfirm={() => {
                                //        //console.log("record", record);
                                //        //this.props.dataActions.genericDataDeleteRow(
                                //        //    this.id_directdebit,
                                //        //    record['mId'],
                                //        //    this.props.user.companyCode,
                                //        //    this.props.user.token,
                                //        //    this.api.delete,
                                //        //    this.api.get);
                                //        this.props.dataActions.dataTableDeleteRow(
                                //            this.id_directdebit, record.index);
                                //    }
                                //    }
                                //    okText={"כן"} cancelText={"לא"}>
                                //    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                //</Popconfirm>
                                }
                            </span >);
                    } else {
                        let isCreate = (this.props.data[this.id_directdebit].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {

                                    if (isCreate) {
                                        this.handleCreate();
                                    }
                                    else {
                                        //this.props.dataActions.setActive(
                                        //    this.id_directdebit, record.index, "mIsActive", 5);
                                        //this.props.dataActions.genericDataSetEdit(
                                        //    this.id_directdebit, { mCustomerID: this.props.ui.data });
                                        //this.props.dataActions.dataTableSetRow(
                                        //    this.id_directdebit, record.index, this.props.data[this.id_directdebit].editedRecord, true);
                                        //this.props.dataActions.genericDataSaveEdit(
                                        //    this.id_directdebit, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get_direct_debit);
                                        this.props.dataActions.genericDataSaveEditWithParams(
                                            this.id_directdebit, this.props.user.companyCode, this.props.user.token,
                                            this.api.edit, this.api.get_direct_debit, {});
                                    }

                                    //this.props.uiActions.hideModal(this.id_directdebit);
                                    
                                    //this.props.dataActions.setActive(
                                    //    this.id_directdebit, record.index, "mIsActive", 5);
 
                                    //this.props.dataActions.genericDataSaveEdit(
                                    //    this.id_directdebit, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get_direct_debit);
                                    
                                }
                                } />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate) {
                                        // TO PREVENT MODAL FROM CLOSING 
                                        this.props.uiActions.showModal(TableIDs.modalCreateEditDirectDebit, this.props.ui.data);
                                        // ***************************** 
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id_directdebit);
                                    }
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id_directdebit);
                                }} />
                        </span>)
                    }
                    
                }
           }*/
        ];

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'98%'}
                visible={/*visible*/ false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={
                    //this.handleCancel
                    () => { this.props.uiActions.hideModal(TableIDs.modalCreateEditDirectDebit) }
                }
                footer={null/*[
                    <Button key="back" onClick={this.handleCancel}>ביטול</Button>,
                    //<Button key="clear" loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" //disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    //<Button key="createLeave" disabled={!this.state.isValidItemCode}
                    //    type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]*/}
                ui={this.props.ui}
                uiActions={this.props.uiActions} >
                {/*<span>הוראות קבע</span>
                <Button key="create_direct_debit" disabled={!this.state.isValidItemCode}
                onClick={this.handleDirectDebitCreate}>צור חדש</Button>*/}
                <ResponsiveTable dataSource={dataSourceDirectDebit}
                    columns={columnsDirectDebit}

                    // loading={this.props.ui.workInProgress}
                    //scroll={{ y: 280 }}
                    bordered
                    //pagination={this.props.data[this.id].pagination}
                    pagination={this.props.data[this.id_directdebit].pagination}
                    onChange={this.handleTableChange}
                    // locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                    tableOnly={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    percentageToMobile={[30, 35, 35]}

                />
                {/* <ResponsiveTable
                idTable={this.props.data[this.id]}
                columns={this.columns}
                percentageToMobile={[30, 35, 35]}
                title={'לקוחות להוראות קבע'}
                actionButtons={this.actionButtons}
                genericActionButtons={this.genericActionButtons}
                showsearch={(val) => {
                    this.props.dataActions.genericDataSetFilter(
                        this.id, this.props.user.companyCode, this.props.user.token,
                        {
                            ...request, _words: val
                                .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
                        }, this.api.get)
                }}
                onChange={this.handleTableChange}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            /> */}
            </Modal >
        )
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: this.props.ui.data,
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id_directdebit, this.props.user.companyCode, this.props.user.token, request, this.api.get_direct_debit);
        return request;
    }

    componentDidUpdate(prevProps) {
        console.log("xxx ", this.props.ui.data, ' xxx ', prevProps.ui.data,
            ' xxx ', prevProps.toggle, ' xxx ', this.props.toggle);

        if (this.props.ui.data !== prevProps.ui.data ||
            (prevProps.toggle === false && this.props.toggle === true)) {

            console.log("prev", prevProps.ui.data, "next", this.props.ui.data);

            let request = this.initFilters();
            request._id = this.props.ui.data;

            this.props.dataActions.genericDataGetEditWithParams(
                this.id_directdebit,
                this.props.user.companyCode,
                this.props.user.token,
                request
                /*{ _id: this.props.ui.data }*/,
                //this.api.get_suppliers
                this.api.get_direct_debit
            );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 8;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mCustomerID": col--;
            case "mLast4": col--;
            case "mValid": col--;
            case "mCVV": col--;
            case "mHolderID": col--;
            case "mIsActive": col--;
            case "mSource": col--;
            case "mRemarks": break; // 8
            default:
                col = 0;
        }

        console.log("pagination: ", pagination, "\nsorter: ", sorter, "\ncol: ", col);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //sortColumn
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id_directdebit, this.props.user.companyCode, this.props.user.token, request, this.api.get_direct_debit)
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id_directdebit, this.props.user.companyCode, this.props.user.token, this.api.get_direct_debit);
    }
}
export default ModalCreateEditDirectDebit;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Table, Icon, Select, InputNumber, Checkbox, Popconfirm, Button, Input, Tooltip, notification } from "antd";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import Jax from "../../Jax/jax.es6.module";
import { fixCurrentPage } from "../../utils/utils";
import ModalAddSizesToRuler from "../../components/ModalAddSizesToRuler";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 40;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class RulerTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "RULER_TABLE";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        //if (props.data[TableIDs.genericSelectorTagTypes] === undefined) {
        //    props.data[TableIDs.genericSelectorTagTypes] = { ...props.data.genericSelector };
        //}
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_rulers_table",
            create: "create_ruler",
            edit: "edit_ruler",
            delete: "delete_ruler",
            selector: "get_tag_types_with_relation_list",
        }

        this.actionButtons = [
            {
                buttonType: 'add',
                //buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalCreatePrice) }
                buttonFunction: this.handleCreateNew

            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];

        this.list = [];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - תגיות";
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector);
        //this.props.dataActions.genericMultiSelectorRefreshDataset(
        //    TableIDs.genericSelectorTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector);

        //this.props.ActionQueue.addToQueue({
        //    action: this.props.dataActions.genericMultiSelectorRefreshDataset,
        //    args: [TableIDs.genericSelectorTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector]
        //});

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selector]
        });

        //this.refreshData();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10181);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,//5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,//5,
            _isAsc: 1,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector); 

        //this.props.dataActions.genericMultiSelectorRefreshDataset(
        //    TableIDs.genericSelectorTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector);

        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selector);

    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 3;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            //fall through
            case "mID": col--;
            //fall through
            case "mCode": col--;
            //fall through
            case "mName": break; // 3
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal === 1 || checkedVal === "1");
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        //let list = this.props.data[TableIDs.genericSelectorTagTypes].dataset.map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        //});
        Jax.get(this.props.data, TableIDs.genericSelectorItemGroup + ".dataset", [])
        //let list = this.props.data[TableIDs.genericSelectorTagTypes].dataset.map(
        let rawList = Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", []);
        let list = rawList.map(
            (node: Object, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )
        this.list = list;

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mID: y[0],
                mCode: y[1],
                mName: y[2],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            {
                title: this.getText(10684),
                dataIndex: "mID",
                key: "mID",
                sorter: true,
                width: '20%',
                render: (text, record) => text,
            }, {
                title:  this.getText(10685),
                dataIndex: "mCode",
                key: "mCode",
                width: '35%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
                        return (
                            <InputNumber
                                min={0} step={1}
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mCode: value });
                                }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title:  this.getText(10686),
                dataIndex: "mName",
                key: "mName",
                width: '30%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
                        return (
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mName: value.target.value });
                                }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title:  this.getText(10687),
                width: '15%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                }} />
                                <Popconfirm title={this.getText(10688)}
                                    onConfirm={() => {
                                        console.log("record", record);
                                        this.tryToDeleteRuler(record['mID']);
                                        /*
                                        fixCurrentPage.bind(this)();
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.id,
                                            record['mID'],
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.get);
                                        */
                                    }
                                    }
                                    okText={this.getText(10689)} cancelText={this.getText(10690)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm>
                                <Tooltip title={this.getText(10691)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"appstore"} onClick={() => {
                                        this.props.uiActions.showModal(TableIDs.modalAddSizesToRuler, record['mID']);
                                    }} />
                                </Tooltip>
                            </span >);
                    } else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataSaveEdit(
                                            this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get);
                                    else
                                        this.props.dataActions.genericDataSaveEdit(
                                            this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)
                    }
                }
            }
        ];

        return (
            <div style={divStyle}>
                <PageToolbar
                    title={this.getText(10692)}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                    page: 1,
                                    _page_num: 1,
                            }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />
                {/*
                <Select
                    //disabled={!(this.props.data[this.id].editing === record.key)}
                    showsearch
                    filterOption={true}
                    optionFilterProp="valuefilter"
                    value={this.props.data[this.id].filters['_id']}
                    defaultValue='none'
                    style={{ width: '256px', padding: '4px' }}
                    onChange={(e) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            { _id: e }, this.api.get)
                    }}>
                    <Option key={0} value={0}>-כל הסוגי-תגיות-</Option>
                    {list}
                </Select>

                <Button key="showModal" type="primary"
                    onClick={() => { this.props.uiActions.showModal(TableIDs.modalAddSizesToRuler) }}>הוספת קבוצת תגיות</Button>
                */}
                <div style={{ width: window.innerWidth > 600 ? '70%' : '95%', margin: '0 auto' }}>
                    <ResponsiveTable
                        tableOnly={true}
                        percentageToMobile={[false, 30, 70]}
                        ui={this.props.ui}
                        idTable={this.props.data[this.id]}
                        dataSource={dataSource}
                        columns={columns}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalAddSizesToRuler
                    id={TableIDs.modalAddSizesToRuler}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalAddSizesToRuler)}
                    //title={"עדכון מידות לסרגל"}
                />
            </div>
        );
    }

    tryToDeleteRuler = (id) => {
        var dataSend = "mID\r" + id;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.api.delete,
            OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.SuccessCallback, this.ErrorCallback);
    }

    SuccessCallback = (ob: Object) => {
        console.log("success:", ob.data);

        if (ob.data == 0) {
            //this.props.uiActions.showModal(TableIDs.modalRunStandingOrders, { mId: this.state._id, count: ob.data });
            notification.success({
                message: this.getText(10693),
                description: '',
            });
            this.refreshData();
        }
        else {
            if (ob.data == 1) {
                notification.warning({
                    message: this.getText(10694),
                    description: this.getText(10695),
                });
            }
            else /*if (ob.data == 2)*/ {
                notification.warning({
                    message: this.getText(10696),
                    description: this.getText(10697),
                });
            }
        }
    }

    ErrorCallback = (error: any) => {
        //message.error('שגיאה לא ידועה', 2);
        notification.error({
            message: this.getText(10698),
            description: this.getText(10699),
        });
    }
}

export default RulerTable;


import React, { Component } from "react";


class GenericInputColor extends Component {
    render() {
        return (<input
            type="color"
            style={{ width: 250 }}
            value={this.props.value}
            onChange={e => { this.props.onChange(e.target.value) }}
        />)
    }
}

export default GenericInputColor;
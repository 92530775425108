import React from "react";
import { Upload, Icon, Modal } from 'antd';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableIDs from "../data/TableIDs";
import SelectorSalesAlerts from "./SelectorSuppliers";
import SelectorSuppliers from "./SelectorSuppliers";
import SelectorPrices from "./SelectorPrices";
import SelectorTaxType from "./SelectorTaxType";
import SelectorCustomerFields from "./SelectorCustomerFields";

class SelectorByID extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let styleSelector = { width: '100%' };

        let attr = {
            styleSelector: styleSelector,
            user: this.props.user,
            onSelect: this.props.onSelect,
            value: this.props.value,
            ui: this.props.ui,
            uiActions: this.props.uiActions,
            data: this.props.data,
            dataAction: this.props.dataAction,
            //disabled={!this.state.isValidItemCode}
            disabled: this.props.disabled,
            filter: this.props.filter,
        };

        switch (this.props.id) {
            case TableIDs.selectorSalesAlerts: {
                return (<SelectorSalesAlerts {...attr} />)
            }
            case TableIDs.selectorSuppliers: {
                return (<SelectorSuppliers {...attr} />)
            }
            case TableIDs.selectorPrices: {
                return (<SelectorPrices {...attr} />)
            }
            case TableIDs.selectorTaxType: {
                return (<SelectorTaxType {...attr} />)               
            }
            case TableIDs.selectorCustomerFields: {
                return (<SelectorCustomerFields {...attr} />)               
            }
        }
    }
}

export default SelectorByID;



import React from "react";
import {
    Input, InputNumber, Card, Button, Modal, Select, DatePicker,
    Checkbox, Collapse, message, Row, Col, Radio
} from 'antd';

import moment from "moment";
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import FormItem from "antd/lib/form/FormItem";
import Jax from "../Jax/jax.es6.module";
import LangContext from "../contextProvider/LangContext";
import GenericSelectorSuppliers from "./GenericSelectorSuppliers";
import GenericSelectorItemTags from "./GenericSelectorItemTags";
import GenericModal from "./GenericModal";
import ResponsiveTable from "./ResponsiveTable";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import DateTimePicker from "./DateTimePicker";

const { Option, OptGroup } = Select;

//type State = {}
class ModalCreateEditWinner extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            height: window.innerHeight,
            modalTargets: false,
        }

        this.doOnResize = null;
        this.doOnUpdateTargets = null;

        this.id = "MODAL_CREATE_EDIT_WINNER";
        this.targets_id = "WINNER_TARGETS_TABLE";


        // this.numeratorNewStore = -1;

        this.api = {
            get: "get_data_winner_declaration",
            create_edit: "create_edit_data_winner_declaration",

            get_targets: "get_winner_targets",
            create_edit_targets: "create_edit_winner_targets",
            delete_targets: "delete_winner_targets",

            selector_docTypes: "get_document_types_list",
            selector_tags: "get_tags_with_types",
            selector_suppliers: "get_suppliers",

            update_winner: "update_winner_table_on_declartion_change",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorDocumentTypes, script: this.api.selector_docTypes, action: 'genericSelectorRefreshDataset' },
            { id: TableIDs.genericMultiSelectorItemTags, script: this.api.selector_tags, action: 'genericMultiSelectorRefreshDataset' },
            { id: TableIDs.genericSelectorSuppliers, script: this.api.selector_suppliers, action: 'genericSelectorRefreshDataset' }
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.targets_id] === undefined) props.data[this.targets_id] = { ...props.data.genericData };


        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - מודול תחרויות";
        this.initFilters();
        this.selectors.forEach((x) => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions[x.action],
                args: [x.id, this.props.user.companyCode, this.props.user.token, x.script]
            });
        });
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight });
    };

    initFilters = () => {
        if (this.props.ui.data) {
            const { wId, wGroup, wSubject } = this.props.ui.data

            this.props.dataActions.genericDataGetEditWithParams(
                this.id, this.props.user.companyCode, this.props.user.token, { _id: wId }, this.api.get);

            if (wSubject == '7' || wSubject == '8') this.initFilterTargets(wId, wGroup, 1, 0, 0, 0);
        }
    }

    initFilterTargets = (wId, wGroup, wTargetPerEmployeeOrBranch, wTargetEnd, wMinLimit, wMaxLimit) => {
        let request = { wId, wGroup, wTargetPerEmployeeOrBranch, wTargetEnd, wMinLimit, wMaxLimit }
        this.props.dataActions.genericDataSetFilter(
            this.targets_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_targets)
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            this.initFilters();
        }

        const { wSubject, wTargetPerEmployeeOrBranch, wTargetEnd, wMinLimit, wMaxLimit } = this.props.data[this.id].editedRecord;
        const prevEditedRecord = prevProps.data[this.id].editedRecord;

        if ((
            wTargetEnd !== prevEditedRecord.wTargetEnd ||
            wMinLimit !== prevEditedRecord.wMinLimit ||
            wMaxLimit !== prevEditedRecord.wMaxLimit ||
            wTargetPerEmployeeOrBranch !== prevEditedRecord.wTargetPerEmployeeOrBranch
        ) && (wSubject == 7 || wSubject == 8)
            && wTargetPerEmployeeOrBranch != '1'
        ) {
            this.updateTargetFields()
        }

    }

    updateTargetFields = () => {

        clearTimeout(this.doOnUpdateTargets);
        this.doOnUpdateTargets = setTimeout(() => {

            const { wTargetEnd, wMinLimit, wMaxLimit } = this.props.data[this.id].editedRecord;

            this.props.dataActions.setJson(this.targets_id, {
                data: this.props.data[this.targets_id].data.map(x => {
                    return x.split("\f").map((y, i) => {
                        if (i <= 4) return y;
                        if (i === 5) return wTargetEnd;
                        if (i === 6) return wMinLimit;
                        if (i === 7) return wMaxLimit;
                    }).join("\f")
                })
            })
        }, 1000);
    }

    dataObject = (id) => {
        let headers = this.props.data[id].headers;
        return this.props.data[id].data.map((x, indexData) => {
            let y = x.split("\f");
            let ob = { key: y[0] }
            headers.map((z, index) => ob = { ...ob, [z]: y[index], index: indexData });
            return ob;
        });
    }

    handleOk = () => {
        let rData = this.props.data[this.id].editedRecord;
        let targetsData = this.dataObject(this.targets_id)
        let notValid = false;
        if (rData['wDescription'].length < 2 || rData['wDescription'].length > 50) notValid = this.getText(11527)
        if (new Date(rData['wDateTo']).getTime() <= new Date(rData['wDateFrom']).getTime()) notValid = this.getText(11528)
        if ((rData.wSubject == 7 || rData.wSubject == 8) && rData.wTargetPerEmployeeOrBranch == '1') {
            let isValidTarget = false;
            let isValidMaxLimit = false;
            targetsData.forEach(x => {
                if (parseInt(x._targetEnd) > 0) isValidTarget = true;
                if (parseInt(x._maxLimit) > parseInt(x._minLimit)) isValidMaxLimit = true;
            })
            if (!isValidTarget) notValid = this.getText(18341);
            if (!isValidMaxLimit) notValid = this.getText(11529);
        } else {
            if ((rData.wSubject == 7 || rData.wSubject == 8) && parseInt(rData['wTargetEnd']) <= 0) notValid = this.getText(18341);
            if (parseInt(rData['wMaxLimit']) <= parseInt(rData['wMinLimit'])) notValid = this.getText(11529);
        }

        if (notValid) {
            message.error(notValid);
        } else {
            // this.props.dataActions.genericDataJustSaveEdit(
            //     this.id, this.props.user.companyCode, this.props.user.token,
            //     this.api.create_edit);

            const { headers } = this.props.data[this.id];
            this.sendAPI(this.api.create_edit, headers.join("\f") + "\r" + headers.map(x => rData[x]).join("\f"), (ob) => {
                let onSuccess = () => {
                    message.success(this.getText(11530));
                    this.handleCancel();
                    this.initFilters();
                }
                if (rData.wSubject == 7 || rData.wSubject == 8) {
                    let dataSend = "wId\f_employeeId\f_branchId\f_targetEnd\f_minLimit\f_maxLimit\r" +
                        targetsData.map(x => {
                            return ob.data + "\f" +
                                x._employeeId + "\f" +
                                x._branchId + "\f" +
                                x._targetEnd + "\f" +
                                x._minLimit + "\f" +
                                x._maxLimit
                        }).join("\r");
                    //console.log("send_targets", ob.data, dataSend)
                    //this.sendAPI(this.api.create_edit_targets, dataSend, onSuccess)
                    this.sendAPI(this.api.create_edit_targets, dataSend, (ob2) => {
                        this.sendAPI(this.api.update_winner, "wId\r" + ob.data, onSuccess);
                    })
                } else {
                    //this.sendAPI(this.api.delete_targets, "wId\r" + rData.wId, onSuccess)
                    this.sendAPI(this.api.delete_targets, "wId\r" + rData.wId, (ob2) => {
                        this.sendAPI(this.api.update_winner, "wId\r" + rData.wId, onSuccess);
                    })

                }
            })
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalCreateEditWinner);
        this.props.dataActions.genericDataGetRequested(
            "WINNER_DECLARATION_DATA", this.props.user.companyCode, this.props.user.token, "get_table_winner_declaration");
    }

    getInputs = (field, label) => {

        let editedRecord = this.props.data[this.id].editedRecord;
        let notActive = editedRecord['wActive'] !== '1' ? true : false;
        let notTarget = (editedRecord['wSubject'] == '7' || editedRecord['wSubject'] == '8') ? false : true;
        let targetPerEorB = editedRecord['wTargetPerEmployeeOrBranch'] == '1' && !notTarget;
        let allItems = editedRecord['wAllItems'] === '1' ? true : false;
        // let nField = () => {
        //     switch (field) {
        //         // case 'wTagY': return 'wTagN';
        //         // case 'wTagN': return 'wTagY';
        //         case 'wSupplieresY': return 'wSupplieresN';
        //         case 'wSupplieresN': return 'wSupplieresY';
        //     }
        // }
        // let negative = (value) => {
        //     let n = nField();
        //     let nData = n && editedRecord[n] ? editedRecord[n].split(',') : [];
        //     return (nData.indexOf(value) > -1) ? false : true;
        // }
        // let cbNegative = () => {
        //     this.props.dataActions.genericDataSetEdit(this.id, { [nField()]: '' })
        // }
        let valueIn = editedRecord[field];
        let updateIn = (value) => {
            let obj = (field === 'wSubject' && [7, 8].indexOf(value) > -1) ?
                { wTargetEnd: 0, wMinLimit: 0, wMaxLimit: 0 }
                : (field === 'wAllItems' && value === '1') ?
                    { wTagY: '', wTagN: '', wSupplieresY: '', wSupplieresN: '' }
                    : {};
            this.props.dataActions.genericDataSetEdit(this.id, { ...obj, [field]: value })

            if (field === 'wSubject' && (value == 7 || value == 8)) {
                this.initFilterTargets(
                    editedRecord.wId,
                    editedRecord.wGroup,
                    editedRecord.wTargetPerEmployeeOrBranch,
                    editedRecord.wTargetEnd,
                    editedRecord.wMinLimit,
                    editedRecord.wMaxLimit)
            }

            if (field === 'wGroup' && (editedRecord.wSubject == 7 || editedRecord.wSubject == 8)) {
                this.initFilterTargets(
                    editedRecord.wId,
                    value,
                    editedRecord.wTargetPerEmployeeOrBranch,
                    editedRecord.wTargetEnd,
                    editedRecord.wMinLimit,
                    editedRecord.wMaxLimit)
            }
        }

        let optionList = () => {
            switch (field) {
                case 'wPeriod': return [
                    this.getText(11531),
                    this.getText(11532),
                    this.getText(11533)
                ];
                case 'wGroup': return [
                    this.getText(11534),
                    this.getText(11535)
                ];
                case 'wSubject': return [
                    this.getText(11536),
                    this.getText(11537),
                    this.getText(11538),
                    this.getText(11539),
                    this.getText(11540),
                    this.getText(11541),
                    this.getText(11542),
                    this.getText(11544),
                    this.getText(11543),
                ];
            }
        }

        // let selectorName = () => {
        //     switch (field) {
        //         case 'wDocTypes': return this.selectors[0].id;
        //         // case 'wTagY': case 'wTagN': return this.selectors[1].id;
        //         // case 'wSupplieresY': case 'wSupplieresN': return this.selectors[2].id;
        //     }
        // }

        let frameForm = (content) => {
            return (<FormItem label={label} style={{ margin: 5 }}> {content} </FormItem>)
        }

        let colStyle = (num) => { return { width: (100 / num) + '%', display: 'inline-block' } }

        switch (field) {

            case 'wDescription':
                return frameForm(<Input value={valueIn} onChange={(value) => { updateIn(value.target.value) }} />);

            case 'wDateFrom': case 'wDateTo':
                let minDate = field == 'wDateTo' && editedRecord.wDateFrom ? editedRecord.wDateFrom.split(" ")[0] : null
                let maxDate = field == 'wDateFrom' && editedRecord.wDateTo ? editedRecord.wDateTo.split(" ")[0] : null

                return (<div style={colStyle(2)}>
                    {frameForm(
                        // <DatePicker format="DD/MM/YYYY"
                        //     value={valueIn ? moment(valueIn, "YYYY-MM-DD") : null}
                        //     onChange={(value) => {
                        //         let endDateAdd = (field === 'wDateTo') ? " 23:59:59" : '';
                        //         updateIn(value ? value.format("YYYY-MM-DD") + endDateAdd : null)
                        //     }} />
                        <DateTimePicker format="DD/MM/YYYY"
                            value={valueIn}
                            onChange={(value) => {
                                let endDateAdd = (field === 'wDateTo') ? " 23:59:59" : ' 00:00:00';
                                updateIn(value ? value + endDateAdd : null)
                            }}
                            {...{ minDate, maxDate }}
                        />
                    )}
                </div>);

            case 'wPeriod': case 'wGroup': case 'wSubject':
                let list = optionList();
                return (<div style={colStyle((field === 'wSubject') ? 1 : 2)}>
                    {frameForm(<Select
                        key={field} showsearch filterOption={true} style={{ width: '100%' }}
                        disabled={notActive}
                        value={list[valueIn]} onChange={updateIn} >
                        {list.map((x, index) => { return (< Option key={index} value={index} valuefilter={x} > {x}  </Option >) })}
                    </Select>)}
                </div>);

            case 'wRenew': case 'wActive': case 'wAllItems': case 'wIsTargetFinal': case 'wIsPushPopup':
                if (field === 'wIsTargetFinal' && editedRecord.wSubject != 7 && editedRecord.wSubject != 8) return '';

                return (<div style={{ margin: 4 }}><Checkbox
                    disabled={(field !== 'wActive') ? notActive : false}
                    checked={valueIn === '1' ? true : false} style={{ margin: 5 }}
                    onChange={(value) => { updateIn(value.target.checked ? '1' : '0') }}
                />{label}</div>);

            case 'wTargetEnd': case 'wMinLimit': case 'wMaxLimit':
                return (<div style={colStyle(3)}>
                    {frameForm(<InputNumber min={field == 'wTargetEnd'? 0 : undefined} step={1} value={valueIn ? parseInt(valueIn) : 0}
                        disabled={notActive || targetPerEorB || (notTarget && field === 'wTargetEnd')}
                        onChange={(value) => updateIn(parseInt(value))} />)}
                </div>);

            case 'wDocTypes': // case 'wSupplieresY': case 'wSupplieresN': // case 'wTagY': case 'wTagN':
                let selectData = Jax.get(this.props.data, /*selectorName()*/ this.selectors[0].id + ".dataset", []);
                let cbSelect = (<Checkbox disabled={notActive || (allItems && field !== 'wDocTypes')}
                    style={{ display: 'inline' }} onChange={(e) => {
                        if (e.target.checked) {
                            // if (field !== 'wDocTypes') cbNegative();
                            // if (field === 'wDocTypes' || negative(e)) {
                            updateIn(
                                // (field === 'wTagY' || field === 'wTagN') ?
                                // selectData.reduce((acc, i) => { return acc.concat((i.tags.map(j => j.id))) }, []).join(',') :
                                selectData.map((obj) => obj['code']).join(','));
                            // }
                        } else {
                            updateIn('');
                        }
                    }}
                    checked={valueIn ? true : false}
                />);
                let aSelect =
                    // (field === 'wTagY' || field === 'wTagN') ?
                    //     selectData.map((node) => {
                    //         return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                    //             {node.tags.map((item) => {
                    //                 return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                    //             })}
                    //         </OptGroup>)
                    //     }) :
                    selectData.map((obj) => {
                        return (<Option key={obj['code']} value={obj['code']}
                            valuefilter={obj['code'] + ' ' + obj['name']}> {obj['name']}</Option>)
                    });

                let rs = (<Select disabled={notActive || (allItems && field !== 'wDocTypes')}
                    key={field} showSearch filterOption={true} style={{ width: '90%' }}
                    optionFilterProp="valuefilter" mode="multiple" maxTagCount={2}
                    onChange={(value) => { /*if (field === 'wDocTypes' || negative(value[value.length - 1]))*/ updateIn(value.join(',')) }}
                    value={(valueIn) ? valueIn.split(',') : []} >
                    {aSelect}
                </Select>);



                // switch (field) {
                // case 'wSupplieresY': case 'wSupplieresN':
                //     let getFilterList = (filterListField) => {
                //         return selectData.map(x => x.code)
                //             .filter(f => !editedRecord[filterListField] || editedRecord[filterListField].split(',').indexOf(f) === -1);
                //     }

                //     return (<Row style={{ marginTop: 10 }}>
                //         <Col span={6}>{label}</Col>
                //         <Col span={18}>
                //             <GenericSelectorSuppliers
                //                 {...this.props} notApi multi
                //                 disabled={notActive || (allItems)}
                //                 filterList={getFilterList(field === 'wSupplieresY' ? 'wSupplieresN' : 'wSupplieresY')}
                //                 onChange={(value) => { if (negative(value[value.length - 1])) updateIn(value.join(',')) }}
                //                 value={valueIn ? valueIn.split(',') : []} />
                //         </Col>
                //     </Row>)

                // case 'wTagY': case 'wTagN':
                //     let getFilterList2 = (filterListField) => {
                //         let res = [];
                //         selectData.forEach(x => {
                //             x.tags.forEach(y => {
                //                 if (!editedRecord[filterListField] || editedRecord[filterListField].split(',').indexOf(y.id) === -1)
                //                     res.push(y.id);
                //             })
                //         })
                //         return res
                //     }

                //     return (<Row style={{ marginTop: 10 }}>
                //         <Col span={6}>{label}</Col>
                //         <Col span={18}>
                //             <GenericSelectorItemTags
                //                 {...this.props} notApi multi
                //                 disabled={notActive || (allItems)}
                //                 filterList={getFilterList2(field === 'wTagY' ? 'wTagN' : 'wTagY',)}
                //                 // onChange={(value) => { if (negative(value[value.length - 1])) updateIn(value.join(',')) }}
                //                 onChange={(value) => { updateIn(value.join(',')) }}
                //                 value={valueIn ? valueIn.split(',') : []} />
                //         </Col>
                //     </Row>)

                // default:
                return (<div style={colStyle(/*(field === 'wDocTypes') ? 1 : 2*/1)}>
                    <FormItem label={label} style={{ margin: 5 }}>{cbSelect}{rs}</FormItem>
                </div>);
            // }

            case 'wTargetPerEmployeeOrBranch':
                const { wGroup, wSubject } = editedRecord;

                return wSubject == '7' || wSubject == '8' ?
                    frameForm(<Radio.Group value={valueIn} onChange={e => { updateIn(e.target.value) }}>
                        <Radio value={'0'}>{this.getText(wGroup != '1' ? 18343 : 17270)}</Radio>
                        <Radio value={'1'}>{this.getText(wGroup != '1' ? 17271 : 17272)}</Radio>
                        {valueIn == '1' ?
                            (<span>
                                <Button type="primary" icon="setting" onClick={() => { this.setState({ modalTargets: true }) }}>
                                    <span style={{ marginRight: 5 }}>{this.getText(17273)}</span>
                                </Button>
                                <span style={{ marginRight: 10 }}><b>{this.getText(18530)}: {this.getSumTargets()}</b></span>
                            </span>)
                            : ""}
                    </Radio.Group>)
                    : ""
        }
    }

    getSumTargets = () => {
        let res = 0;
        this.props.data[this.targets_id].data.forEach(x => {
            let y = x.split("\f");
            if (y[5]) res += parseInt(y[5])
        })
        return res;
    }

    getSelectorNegativ = (label, field, negativField) => {
        const isTags = field == "wTagY" || field == "wTagN";
        const { editedRecord } = this.props.data[this.id];
        const disabled = editedRecord['wActive'] != '1' || editedRecord['wAllItems'] === '1';
        const value = editedRecord[field] ? editedRecord[field].split(',') : []
        const onChange = (v) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: v ? v.join(",") : "", [negativField]: "" }) }

        return (<Row style={{ marginTop: 10 }}>
            <Col span={6}>{label}</Col>
            <Col span={18}>
                {isTags ?
                    <GenericSelectorItemTags {...this.props} notApi multi notReport {...{ disabled, value, onChange }} />
                    : <GenericSelectorSuppliers {...this.props} notApi multi {...{ disabled, value, onChange }} />
                }
            </Col>
        </Row>)
    }

    getSelectorSuppliers = (label, field, negativField) => {
        const { editedRecord } = this.props.data[this.id];
        const { dataset } = this.props.data[this.selectors[2].id];
        const selectorIdList = dataset.map(j => j.code);
        const disabled = editedRecord['wActive'] != '1' || editedRecord['wAllItems'] === '1';
        const value = editedRecord[field] ? editedRecord[field].split(',') : []
        const onChange = (v) => {
            this.props.dataActions.genericDataSetEdit(this.id, {
                [field]: v ? v.join(",") : "",
                [negativField]: selectorIdList.filter(f => !v || v.indexOf(f) === -1).join(","),
            })
        }

        return (<Row style={{ marginTop: 10 }}>
            <Col span={6}>{label}</Col>
            <Col span={18}>
                <GenericSelectorItemTags
                    {...this.props} notApi multi notReport
                    {...{ disabled, value, onChange }}
                />
            </Col>
        </Row>)
    }

    responsiveCard = (title, content) => {
        let responsiveStyle = (window.innerWidth > 600) ? {
            width: '32%',
            display: 'inline-block',
            marginRight: '1%',
            verticalAlign: 'text-top',
            backgroundColor: '#dddddd'
        } : {}

        return (<Card style={responsiveStyle} title={title}>{content}</Card>)
    };

    renderTargetsModal = (text, record, field) => {
        const { data, headers } = this.props.data[this.targets_id];
        return (<InputNumber
            value={text}
            onChange={v => {
                let newData = data.map((x, xIndex) => {
                    if (xIndex === record.index) {
                        let indexField = headers.findIndex(f => f === field);
                        return x.split("\f").map((y, yIndex) => yIndex === indexField ? v : y).join("\f");
                    } else {
                        return x
                    }
                })
                this.props.dataActions.setJson(this.targets_id, { data: newData })
            }} />)
    }


    render() {
        const { loading } = this.state;

        let docNum = this.props.ui.data ? this.props.ui.data.wId : 0;
        let docName = this.dataObject(this.id)[0] ? this.dataObject(this.id)[0]['wDescription'] : '';
        let titleName = ' ' + this.getText(11545) + ' ';
        titleName += (docNum > 0) ? this.getText(11546) + ' ' + docNum + ' - ' + docName : this.getText(11547);

        const { wGroup, wSubject, wTargetPerEmployeeOrBranch } = this.props.data[this.id].editedRecord;

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={titleName}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let basicSettings = (<div>
            {this.getInputs('wDescription', this.getText(11548))}
            {this.getInputs('wDateFrom', this.getText(11549))}
            {this.getInputs('wDateTo', this.getText(11550))}
            {this.getInputs('wPeriod', this.getText(11551))}
            {this.getInputs('wGroup', this.getText(11552))}
            {this.getInputs('wRenew', this.getText(11553))}
            {this.getInputs('wActive', this.getText(11554))}
            {this.getInputs('wIsTargetFinal', this.getText(17286))}
            {this.getInputs('wIsPushPopup', this.getText(18187))}
        </div>);

        let AtrebutesSettings = (<div>
            {this.getInputs('wSubject', this.getText(11555))}
            {this.getInputs('wTargetPerEmployeeOrBranch', this.getText(17269))}
            {this.getInputs('wTargetEnd', this.getText(11556))}
            {this.getInputs('wMinLimit', this.getText(11557))}
            {this.getInputs('wMaxLimit', this.getText(11558))}
        </div>);

        let includeSettings = (<div>
            {this.getInputs('wDocTypes', this.getText(11559))}
            {this.getInputs('wAllItems', this.getText(11560))}
            {this.getSelectorNegativ(this.getText(11561), 'wTagY', 'wTagN')}
            {this.getSelectorNegativ(this.getText(11562), 'wTagN', 'wTagY')}
            {this.getSelectorNegativ(this.getText(11563), 'wSupplieresY', 'wSupplieresN')}
            {this.getSelectorNegativ(this.getText(11564), 'wSupplieresN', 'wSupplieresY')}
        </div>);

        let wGroupText = this.getText(wGroup == '1' ? 11535 : 11534);
        let wTargetsGroupKey = wGroup == '1' ? '_branchName' : '_employeeName';
        let modalTargetsTitle = this.getText(17275) + " " + wGroupText;
        if (wTargetPerEmployeeOrBranch == '1' && (wSubject == '7' || wSubject == '8')) {
            modalTargetsTitle += " - " + this.getText(18530) + ": " + this.getSumTargets()
        }

        return (
            <Modal
                destroyOnClose={true} width={'95%'} visible={false || this.props.toggle}
                title={titleBar} onOk={this.handleOk} onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(11565)}</Button>,
                    <Button key="clear" loading={loading} onClick={this.initFilters}>{this.getText(11566)}</Button>,
                    <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(11567)}</Button>,
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction} >

                {this.responsiveCard(this.getText(11568), basicSettings)}
                {this.responsiveCard(this.getText(11569), AtrebutesSettings)}
                {this.responsiveCard(this.getText(11570), includeSettings)}

                <GenericModal
                    visible={this.state.modalTargets}
                    onCancel={() => { this.setState({ modalTargets: false }) }}
                    title={modalTargetsTitle}
                    width={1000}>
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.targets_id]}
                        columns={[
                            { title: wGroupText, key: '_id', dataIndex: wTargetsGroupKey, width: '40%', render: t => t },
                            { title: this.getText(11556), key: '_targetEnd', dataIndex: '_targetEnd', width: '20%', render: (t, r) => this.renderTargetsModal(t, r, '_targetEnd') },
                            { title: this.getText(11557), key: '_minLimit', dataIndex: '_minLimit', width: '20%', render: (t, r) => this.renderTargetsModal(t, r, '_minLimit') },
                            { title: this.getText(11558), key: '_maxLimit', dataIndex: '_maxLimit', width: '20%', render: (t, r) => this.renderTargetsModal(t, r, '_maxLimit') },
                        ]}
                        pagination={{ pageSize: parseInt(this.state.height / 40) }}
                    />
                </GenericModal>
            </Modal >)
    }
}

export default ModalCreateEditWinner;

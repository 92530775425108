import { Button, Icon, Modal } from "antd";
import React, { Component } from "react";
import GenericModal from "../../components/GenericModal";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class FavoriteReportsForAutoIphone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalItems: false,
            url: null,
            isReportModalShown: false
        }

        this.id = "REPORT_FAVORITES";
        this.api = "get_report_favorites_list";
        this.api_report = "get_report_favorite_data";

        if (props.data[this.id] === undefined) props.data[this.id] = { dataset: [] }

        this.refreshData();
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(this.id, this.props.user.companyCode, this.props.user.token, this.api);
    }

    
    showReportModal = (_id) => {
        this.setState({ isReportModalShown: true }, () => { this.showReport(_id); });
    }

    showReport = (_id) => {
        this.sendAPI(this.api_report, '_id\r' + _id, (ob) => {
            if (ob.data) {
                let params = {}
                ob.data.split('\r').forEach(x => {
                    let y = x.split('\f');
                    params = { ...params, [y[0]]: y[1] === 'null' || y[1] === 'undefined'? null : y[1] }
                })

                console.log('get_report_params', params);
                if (params && params.reportName) {
                    //this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
                    let user = new User(this.props.user.token, this.props.user.companyCode);
                    let request = new Job(user, "generate_report", OutputType.OUTPUT_TYPE_REPORT_A4, ProcessType.PROCESS_TYPE_SYNC);
                    request.setInput(params);
                    request.send("/cgi-bin/CashOnTab", (response: ReportBlob) => {
                        const data = response.blob;
                        const blob = data.slice(0, data.size, "application/pdf");
                        const url = (window.URL || window.webkitURL || window || {}).createObjectURL(blob);
                        /// const url = 'http://147.235.163.248/uploads/mishlocha.pdf'
                        console.log('generated report with url: ', url);

                        this.setState({ url, isReportModalShown: true })
                    }, (err) => {
                        console.log('failed to generate report: ', err);
                        const { url } = this.state;
                        if (url) { window.URL.revokeObjectURL(url); }
                        this.setState({ isReportModalShown: false, url: null })
                    });
                }
            }
        })
    }

    generateReport = (_id) => {
        this.sendAPI(this.api_report, '_id\r' + _id, (ob) => {
            if (ob.data) {
                let params = {}
                ob.data.split('\r').forEach(x => {
                    let y = x.split('\f');
                    params = { ...params, [y[0]]: y[1] === 'null'|| y[1] === 'undefined' ? null : y[1] }
                })

                console.log('get_report_params', params);
                if (params && params.reportName) {
                    this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
                }
            }
        })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


    render() {

        console.log("render this.state.url: ", this.state.url)
        console.log("render window.innerHeight: ", window.innerHeight)
        console.log("render window.innerWidth ", window.innerWidth)

        return (<div style={{ textAlign: "center", marginBottom: 5, display: 'inline-block', verticalAlign: 'text-top' }}>
            <Button onClick={() => { this.setState({ modalItems: true }, this.refreshData) }}>
                <Icon type={"printer"} style={{ marginLeft: 5 }} />
                {this.getText(16975)}
            </Button>
            <GenericModal
                visible={this.state.modalItems}
                onCancel={() => { this.setState({ modalItems: false }) }}
                title={this.getText(16975)}>
                {this.props.data[this.id].dataset.filter(f => f.code > 0).map(x => {
                    //return (<Button style={{ width: "98%", marginBottom: 5 }} onClick={() => { this.generateReport(x.code) }}>{x.name}</Button>)
                    return (<Button style={{ width: "98%", marginBottom: 5 }} onClick={() => { this.showReportModal(x.code) }}>{x.name}</Button>)
                })}
            </GenericModal>
            <Modal
                visible={this.state.isReportModalShown}
                onCancel={() => {
                    const { url } = this.state;
                    if (url) { window.URL.revokeObjectURL(url); }
                    this.setState({ isReportModalShown: false, url: null })
                }}
                style={{ position: "fixed", right: 0, top: 0 }}
                //width={window.innerWidth}
                width={"98vw"}
                title={false} footer={false}
            >
                {/* <div style={{ height: window.innerHeight }}> */}
                <div style={{ height: "98vh", width: "98vw" }}>
                    <iframe style={{ position: "relative", height: "100%", width: "100%" }}
                        src={(this.state.url) ? this.state.url : "about:blank"}></iframe>
                </div>
            </Modal>
        </div>)
    }
}

export default FavoriteReportsForAutoIphone;
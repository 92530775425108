/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { Input, Icon, Button, Tooltip, Col, Row, message, Popconfirm, Modal, Pagination } from "antd";
import Jax from "../Jax/jax.es6.module";
import TableIDs from '../data/TableIDs.js';
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import MatrixGridTile from "./MatrixGridTile";

const { confirm } = Modal;

type Props = {
  itemID: any,
  onClick?: (row, col) => void,
};

type State = {};

//const { Search } = Input;

class MatrixGrid extends React.Component<Props, State> {
  constructor(props/*: Props*/) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      sonItemFocus: {},
      gridPageY: 1,
      gridPageX: 1,
      xGridWithPaging: '1',
      gridScrollTop: 0,
      gridScrollLeft: 0,
    }
    this.state._id = null;
    this.state._colorFilter = null;

    this.id = "MATRIX_GRID";
    this.id_edit = "MATRIX_GRID_EDIT";
    this.id_size_bar = "SIZE_BAR";
    this.id_color_bar = "COLOR_BAR";
    //this.id_meta = "MODAL_MATRIX_META";

    this.api = {
      get: "get_matrix_grid",
      create: "add_to_matrix_grid",

      delete: "delete_matrix_item_grid",

      // get_color_bar: "get_color_bar",
      get_color_bar: "get_colors_table",
      get_size_bar: "get_size_bar",
    };
    // _rulerId
    if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
    if (props.data[this.id_edit] === undefined) { props.data[this.id_edit] = { ...props.data.genericData }; }
    //if (props.data[this.id_meta] === undefined) { props.data[this.id_meta] = { ...props.data.genericData }; }

    if (props.data[TableIDs.genericSelectorColors] === undefined) {
      props.data[TableIDs.genericSelectorColors] = { ...props.data.genericSelector };
    }

    if (props.data[TableIDs.genericSelectorSizes] === undefined) {
      props.data[TableIDs.genericSelectorSizes] = { ...props.data.genericSelector };
    }

    this.barcodeReaderBuffer = '';
    this.isBufferFull = false;
    this.ctrlBKeyFlag = false;

    this.testLoop = 0;
    this.datasetItemsGrid = [];

    this.firstRow = null;
    this.lastRow = null;
    this.firstCol = null;
    this.lastRow = null;


    this.scrollLocalUpdate = null;
    this.scrollLocalUpdate2 = null;
    this.scrollLocalFocus = null;

  }

  static contextType = LangContext;

  getText = (id) => {
    return this.context.get(id) || '[' + id + ']'
  }

  componentDidMount() {
    let _id = this.props.itemCode ? this.props.itemCode : '';
    this.setState({ _id }, () => {
      this.getAllData(_id)
    });

    this.sendAPI("get_GP_MatrixGridWithPaging", "", (ob) => {
      this.setState({ xGridWithPaging: ob.data })
    })
  }

  componentDidUpdate(prevProps) {
    let _id = this.props.itemCode;
    let lastSave = this.props.lastSave
    if (_id && (_id !== prevProps.itemCode || lastSave !== prevProps.lastSave)) {
      this.setState({ _id }, () => {
        this.getAllData(_id)
      });
    }
  }

  getAllData = (_id) => {

    this.sendAPI(this.api.get_color_bar, "_id\f_words\f_page_num\f_rows_num\f_sort_by\f_isAsc\r\f\f1\f999999\f\f1", (colors) => {
      this.sendAPI(this.api.get_size_bar, "_id\r" + _id, (sizes) => {
        this.sendAPI(this.api.get, "_id\r" + _id, (items) => {

          let colorsDataset = colors.data ? colors.data.split('\r').slice(1, -1).map((x) => {
            let y = x.split('\f');
            return { code: y[0], name: y[3], userCode: y[1] }
          }) : [];

          let sizesDataset = sizes.data ? sizes.data.split('\r').map((x) => {
            let y = x.split('\f');
            return { code: y[0], name: y[1] }
          }) : [];

          let itemsDataset = items.data ? items.data.split('\r') : [];
          let headers = itemsDataset[0] ? itemsDataset[0].split('\f') : [];
          let count = itemsDataset.length ? parseInt(itemsDataset[itemsDataset.length - 1]) : 0;
          let data = itemsDataset.length ? itemsDataset.slice(1, -1) : [];

          this.datasetItemsGrid = colorsDataset.map(row => {
            return {
              ...row,
              cols: sizesDataset.map(col => {
                this.testLoop++
                let itemData = data.map(i => i.split('\f')).filter(i => i[7] === row.code && i[5] === col.code);
                let isSet = itemData.length ? itemData[0] : null;
                let isActive = itemData && itemData[0] ? itemData[0][8] == '1' : null;
                let status = isSet ? isActive ? 2 : 3 : 0;
                return { ...col, isSet, isSelected: null, status }
              })
            }
          })

          this.props.dataActions.setJson('', {
            [TableIDs.genericSelectorColors]: { dataset: colorsDataset },
            [TableIDs.genericSelectorSizes]: { dataset: sizesDataset },
            [this.id]: { headers, data, count },
            [this.id_edit]: { headers, data: [], count: 0 },
          })
        })
      })
    })
  }

  sendAPI = (script, data, sCal) => {
    let user = new User(this.props.user.token, this.props.user.companyCode);
    let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
    job.setInput(data);
    job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
  }

  onFocus = (rowId, colId, isSet, isSelected, element) => {

    if (isSet) this.setState({ sonItemFocus: { rowId, colId, barcode2: isSet[2] } })
    else if (isSelected) this.setState({ sonItemFocus: { rowId, colId, barcode2: isSelected[2] } })
    else this.setState({ sonItemFocus: {} });

    if (this.state.xGridWithPaging != '1') this.onTabContentData(element)

  };

  tileColor = (status) => {
    switch (status) {
      case 1: return '#f44336'; // red
      case 2: return '#4CAF50'; // green
      case 3: return '#808080'; // gray
    }
  }

  updateDatasetItemsGrid = (rowId, colId, isSelected) => {
    this.datasetItemsGrid = this.datasetItemsGrid.map(row => {
      if (rowId === row.code) {
        return {
          ...row,
          cols: row.cols.map(col => {
            if (colId === col.code) {
              return { ...col, isSelected, status: isSelected ? 1 : 0 }
            } else {
              return col
            }
          })
        }
      } else {
        return row
      }
    })
  }

  addSonItem = (rowId, colId, status) => {
    if (status === 0) {
      let data = '\f\f' + this.props.itemCode + '\f';
      data += '\f' + this.props.data['ITEM_CREATE_EDIT'].editedRecord['mRuler1ID'];
      data += '\f' + this.props.data['ITEM_CREATE_EDIT'].editedRecord['mRuler2ID'];
      data += '\f' + colId + '\f\f' + rowId + '\f1';

      this.updateDatasetItemsGrid(rowId, colId, data);

      this.setState({ sonItemFocus: { rowId, colId, barcode2: '' } }, () => {
        this.props.dataActions.setJson(this.id_edit, { data: [...this.props.data[this.id_edit].data, data] })
      })
    }
  }

  removeSonItem = (rowId, colId, status) => {
    if (status === 2 || status === 3) {

      confirm({
        // title: this.getText(51823),
        content: this.getText(10659),
        iconType: 'exclamation-circle',
        okText: this.getText(10661),
        cancelText: this.getText(10662),
        onOk: () => {
          if (!this.isPickes()) {
            this.sendAPI(this.api.delete, "_ItemBarCode\f_colorId\f_sizeId\r" + this.props.itemCode + "\f" + rowId + "\f" + colId, ob => {
              setTimeout(
                () => {
                  this.props.dataActions.genericDataGetEditWithParams("MATRIX_GRID", this.props.user.companyCode,
                    this.props.user.token, { _id: this.props.itemCode }, "get_matrix_grid");

                }, 600)
              if (ob.data) {
                message.warning(ob.data.split("\r")[0].split("\f")[1])
              } else {
                message.success(this.getText(15327))
              }
              this.datasetItemsGrid = this.datasetItemsGrid.map(row => {
                if (rowId === row.code) {
                  return {
                    ...row,
                    cols: row.cols.map(col => {
                      if (colId === col.code) {
                        return { ...col, isSelected: ob.data ? true : false, status: ob.data ? 3 : 0 }
                      } else {
                        return col
                      }
                    })
                  }
                } else {
                  return row
                }
              })
            })
            // this.updateDatasetItemsGrid(rowId, colId, null);

            // this.props.dataActions.genericDataJustSaveEditWithParams(
            //   this.id,
            //   this.props.user.companyCode,
            //   this.props.user.token,
            //   this.api.delete,
            //   { _ItemBarCode: this.props.itemCode, _colorId: rowId, _sizeId: colId });

            // setTimeout(
            //   () => {
            //     this.props.dataActions.genericDataGetEditWithParams("MATRIX_GRID", this.props.user.companyCode,
            //       this.props.user.token, { _id: this.props.itemCode }, "get_matrix_grid");

            //   }, 600
            // )
          }
          else {
            message.info(this.getText(10660), 3, null);
          }
        },
        onCancel() { },
      });
    } else if (status === 1) {
      //'#f44336'; // red // do nothing\
      console.log("remove pick on click", this.props.data[this.id_edit].data);
      let filterdRecords = this.props.data[this.id_edit].data
        //.map((i, index) => i.split('\f').push(index))
        .map((i, index) => {
          let arr = i.split('\f');
          arr.push(index);
          return arr;
        })
        .filter(i => i[8] === rowId && i[6] === colId);

      if (filterdRecords.length === 1) {
        this.updateDatasetItemsGrid(rowId, colId, null);
        console.log("remove pick on click", filterdRecords);

        const _index = filterdRecords[0].pop();
        this.props.dataActions.dataTableDeleteRow(this.id_edit, _index);

      } else {
        console.log("no/multiple records with the same color and size");
      }

    }
    this.setState({ sonItemFocus: {} })
  }

  updateBarcode2 = (barcode2, rowId, colId, status) => {
    this.setState({ sonItemFocus: { ...this.state.sonItemFocus, barcode2 } }, () => {
      // const status = checkStatus(rowId, colId);
      let idStore = status == 2 || status == 3 ? this.id : status == 1 ? this.id_edit : null

      if (idStore) {
        let data = this.props.data[idStore].data.map(i => {
          let y = i.split('\f')
          if (y[7] === rowId && y[5] === colId) {
            y[2] = barcode2;
            return y.join('\f');
          } else {
            return i;
          }
        });
        this.props.dataActions.setJson(idStore, { data })
      }
    })
  }

  onTypeBarcode2 = (e, rowId, colId, status) => {

    switch (e.key) {
      case "b": case "B": case "נ": case "M": case "m": case "צ":
        if (e.ctrlKey) {
          this.updateBarcode2('', null, null, status);
          this.ctrlBKeyFlag = true;
          this.barcodeReaderBuffer = '';
        }
        break;

      case "Enter":
        if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
        if (this.barcodeReaderBuffer || this.isBufferFull === true) this.updateBarcode2(this.barcodeReaderBuffer, rowId, colId, status)
        break;

      case "Tab":
        if (colId === this.lastCol) {
          this.onTabMove("matrix-grid-tile-row-" + rowId + '-col-' + this.firstCol, false, true)
        }
        break;

      case "ArrowRight":
        e.preventDefault()
        if (colId === this.lastCol) {
          this.onTabMove("matrix-grid-tile-row-" + rowId + '-col-' + this.firstCol, false, true)
        } else {
          let curIndex = this.datasetItemsGrid[0].cols.findIndex(f => f.code == colId);
          let nextCol = this.datasetItemsGrid[0].cols[curIndex + 1].code;
          this.onTabMove("matrix-grid-tile-row-" + rowId + '-col-' + nextCol, false, false)
        }
        break;

      case "ArrowLeft":
        e.preventDefault()
        if (colId !== this.firstCol) {
          let curIndex = this.datasetItemsGrid[0].cols.findIndex(f => f.code == colId);
          let backCol = this.datasetItemsGrid[0].cols[curIndex - 1].code;
          this.onTabMove("matrix-grid-tile-row-" + rowId + '-col-' + backCol, false, false)
        }
        break;

      case "ArrowUp":
        e.preventDefault()
        if (rowId !== this.firstRow) {
          let curIndex = this.datasetItemsGrid.findIndex(f => f.code == rowId);
          let backRow = this.datasetItemsGrid[curIndex - 1].code;
          this.onTabMove("matrix-grid-tile-row-" + backRow + '-col-' + colId, false, false)
        }
        break;

      case "ArrowDown":
        e.preventDefault()
        if (rowId !== this.lastRow) {
          let curIndex = this.datasetItemsGrid.findIndex(f => f.code == rowId);
          let nextRow = this.datasetItemsGrid[curIndex + 1].code;
          this.onTabMove("matrix-grid-tile-row-" + nextRow + '-col-' + colId, false, false)
        }
        break;

      default:
        if (this.ctrlBKeyFlag == true) {
          this.barcodeReaderBuffer += e.key;
        } else if (e.key.length === 1) {
          this.updateBarcode2(this.state.sonItemFocus.barcode2 + e.key, rowId, colId, status)
        }
        break;
    }

  }

  onScrollContentData = (e) => {
    let top = e.target.scrollTop;
    let left = e.target.scrollLeft;
    let scrollSizesBar = document.getElementById('scroll_sizes_bar');
    let scrollColorsBar = document.getElementById('scroll_colors_bar');
    scrollSizesBar.scrollLeft = left;
    scrollColorsBar.scrollTop = top;

    clearTimeout(this.scrollLocalUpdate);
    this.scrollLocalUpdate = setTimeout(() => {
      console.log('onScrollContentData - positions', top, left)
      this.setState({
        gridScrollTop: top,
        gridScrollLeft: left < 0 ? left * -1 : 0,
      })
    }, 100);
  }

  onTabContentData = (e) => {

    let scroll = document.getElementById('scroll_all_content_data');
    let top = scroll.scrollTop;
    let left = scroll.scrollLeft;
    let value = e.value.split("#")
    const maxTileWidth = 48;
    const { x, y } = e.getBoundingClientRect();

    console.log("onTabContentData-7 ", y, window.innerHeight - y)
    let toRight = x < 200;
    let toLeft = x > (window.innerWidth - 400);
    let toDown = y > (window.innerHeight - 250);
    let toUp = y < 400


    if (toRight || toLeft || toDown || toUp) {
      if (toRight) left -= maxTileWidth;
      if (toLeft) left += maxTileWidth;
      if (toDown) top += maxTileWidth;
      if (toUp) top -= maxTileWidth;

      scroll.scrollTop = top;
      scroll.scrollLeft = left;
      this.focusElement(e.id)
    }
  }

  onTabMove = (id, toFirstRow, toFirstCol) => {
    if (toFirstRow || toFirstCol) {
      let scroll = document.getElementById('scroll_all_content_data');
      let top = scroll.scrollTop;
      let left = scroll.scrollLeft;

      clearTimeout(this.scrollLocalUpdate2);
      this.scrollLocalUpdate2 = setTimeout(() => {
        if (toFirstRow) scroll.scrollTop = 0;
        if (toFirstCol) scroll.scrollLeft = 0;
        console.log("ontabcontentdata-6", top, left, scroll.scrollTop, scroll.scrollLeft)

        this.focusElement(id)
      }, 500);
    } else {
      this.focusElement(id)
    }
  }


  focusElement = (id) => {
    console.log('onTabContentData-4 ', id);
    clearTimeout(this.scrollLocalFocus);
    this.scrollLocalFocus = setTimeout(() => {
      let e = document.getElementById(id)
      if (e) e.focus();
      // else this.focusElement(id)
      // setTimeout(() => {
      //   document.getElementById(e.id).focus();
      //   console.log('onTabContentData-5 ', e.value)
      // }, 3000);

    }, 300);
  }

  render() {
    let datasetItemsGrid = this.datasetItemsGrid.filter((i) => {
      const filter = (this.state._colorFilter) ? this.state._colorFilter : "";
      if (!i['userCode'] && !i['name'])
        return true;
      else
        return i['userCode'].includes(filter) || i['name'].includes(filter);
    });

    const { gridPageX, gridPageY, gridScrollTop, gridScrollLeft, xGridWithPaging } = this.state;
    const maxTileWidth = 48;
    const countTilesInRow = Math.floor((window.innerWidth - 200) / maxTileWidth);
    const countTilesInCol = Math.floor((window.innerHeight - 400) / maxTileWidth);

    let sumPagesX = 1;
    let sumPagesY = 1;

    let colorsDataset = datasetItemsGrid.map(x => { return { code: x.code, name: x.name, userCode: x.userCode } });
    let sizesDataset = datasetItemsGrid[0] ? datasetItemsGrid[0].cols : [];
    let colorLength = datasetItemsGrid.length;
    let sizesLength = sizesDataset.length;

    this.firstRow = colorLength ? datasetItemsGrid[0].code : null;
    this.lastRow = colorLength ? datasetItemsGrid.slice(-1)[0].code : null;
    this.firstCol = sizesLength ? sizesDataset[0].code : null;
    this.lastCol = sizesLength ? sizesDataset.slice(-1)[0].code : null;




    let isPaging = xGridWithPaging == '1';

    let noPagingRowPadStart = 0;
    let noPagingRowPadEnd = 0;
    let noPagingColPadStart = 0;
    let noPagingColPadEnd = 0;

    if (colorLength > countTilesInCol) {
      if (isPaging) {
        sumPagesY = Math.ceil(colorLength / countTilesInCol)
        datasetItemsGrid = datasetItemsGrid.slice((gridPageY - 1) * countTilesInCol, gridPageY * countTilesInCol);
        colorsDataset = datasetItemsGrid.map(x => { return { code: x.code, name: x.name, userCode: x.userCode } });
      } else {
        noPagingRowPadStart = Math.floor(gridScrollTop / maxTileWidth);
        if ((countTilesInCol + noPagingRowPadStart) > colorLength) noPagingRowPadStart = colorLength - countTilesInCol
        noPagingRowPadEnd = colorLength - noPagingRowPadStart - countTilesInCol;
        console.log('onScrollContentData skip row', colorLength, noPagingRowPadStart, countTilesInCol, noPagingRowPadEnd)
        datasetItemsGrid = datasetItemsGrid.slice(noPagingRowPadStart, noPagingRowPadStart + countTilesInCol);
      }
      colorLength = datasetItemsGrid.length;
    }

    if (sizesLength > countTilesInRow) {
      if (isPaging) {
        sumPagesX = Math.ceil(sizesLength / countTilesInRow);
        datasetItemsGrid = datasetItemsGrid.map(x => {
          return { ...x, cols: x.cols.slice((gridPageX - 1) * countTilesInRow, gridPageX * countTilesInRow) }
        })
        sizesDataset = datasetItemsGrid[0] ? datasetItemsGrid[0].cols : [];
      } else {
        noPagingColPadStart = Math.floor(gridScrollLeft / maxTileWidth);
        if ((countTilesInRow + noPagingColPadStart) > sizesLength) noPagingColPadStart = sizesLength - countTilesInRow
        noPagingColPadEnd = sizesLength - noPagingColPadStart - countTilesInRow;
        console.log('onScrollContentData skip col', sizesLength, noPagingColPadStart, countTilesInRow, noPagingColPadEnd)
        datasetItemsGrid = datasetItemsGrid.map(x => {
          return { ...x, cols: x.cols.slice(noPagingColPadStart, noPagingColPadStart + countTilesInRow) }
        })
      }
      sizesLength = sizesDataset.length;
    }



    const maxRowWidth = maxTileWidth * sizesLength;
    const colLength = 100 / sizesLength;
    const rowLength = 100 / colorLength;


    const sizes_bar = sizesDataset.map((obj, index) => {
      return (
        <div key={obj['code']} value={obj['code']}
          style={{
            transform: 'rotate(60deg)',
            //
            textAlign: 'center',
            padding: 4,
            marginBottom: 4,
            display: 'inline-block',
            minHeight: 24, minWidth: maxTileWidth,
            maxHeight: 96, maxWidth: maxTileWidth,
            height: (rowLength).toString() + '%', width: (colLength).toString() + '%'
          }}
        >{obj['name']}</div>
      )
    });

    const colors_bar = colorsDataset.map((obj, index) => {
      return (<div key={obj['code']} value={obj['code']}
        style={{
          textAlign: 'left',
          padding: 4,
          minHeight: maxTileWidth, minWidth: maxTileWidth,
          maxHeight: maxTileWidth, //maxWidth: 48,
          //height: (rowLength).toString() + '%', width: (colLength).toString() + '%'
          height: '100%', width: '100%'
        }}
      ><span style={{ verticalAlign: 'middle', }}>{obj['name'] + " - " + obj['userCode']}</span></div>)
    });


    const gridData = (<div>
      {datasetItemsGrid.map((row) => {
        return (<div key={row['code']} style={{ width: maxRowWidth }}>
          {row.cols.map((col) => {
            return (<MatrixGridTile
              {...{ col, row, maxTileWidth, rowLength, colLength }}
              onBlur={() => { this.setState({ sonItemFocus: {} }) }}
              onFocus={this.onFocus}
              addSonItem={this.addSonItem}
              removeSonItem={this.removeSonItem}
              onTypeBarcode2={this.onTypeBarcode2}

            />)
          })}
        </div>)
      })}
    </div>);

    const axisLabel = <div style={{ direction: 'rtl', textAlign: 'left' }}><sub>{this.getText(10665)}</sub>/<sup>{this.getText(10666)}</sup></div>;

    let colPadStyle = { height: maxTileWidth, display: 'inline-block', verticalAlign: 'text-top' }

    return (
      <div key="grid" style={{ marginTop: 16 }}>
        <label style={{ padding: 8 }}>{this.getText(10667)}</label>
        <Input style={{ width: 200, padding: 8 }} placeholder={this.getText(10668)} onChange={e => { this.setState({ _colorFilter: e.target.value }) }} allowClear />
        {this.state.sonItemFocus.rowId && this.state.sonItemFocus.colId ?
          <h2 style={{ display: 'inline-block' }}>{this.getText(16360)}: {this.state.sonItemFocus.barcode2}</h2>
          : ''}

        {isPaging ?
          (<div>
            {sumPagesX > 1 ? (<Pagination style={{ direction: 'ltr' }} onChange={x => { this.setState({ gridPageX: x }) }} total={sumPagesX * 10} />) : ""}
            <Row>
              <Col span={3}>{axisLabel}</Col>
              <Col span={21}><div style={{ width: maxRowWidth }}>{sizes_bar}</div></Col>
            </Row>
            <Row>
              <Col span={1}>{sumPagesY > 1 ? (<Pagination style={{ direction: 'ltr' }} onChange={x => { this.setState({ gridPageY: x }) }} total={sumPagesY * 10} />) : ""}</Col>
              <Col span={2}>{colors_bar}</Col>
              <Col span={21}>{gridData}</Col>
            </Row>
          </div>)
          : (<div>
            <Row>
              <Col span={2}>{axisLabel}</Col>
              <Col id={'scroll_sizes_bar'} span={22} style={{ overflowX: 'hidden' }}><div style={{ width: maxRowWidth }}>{sizes_bar}</div></Col>
            </Row>
            <Row>
              <Col id={'scroll_colors_bar'} span={2} style={{ overflowY: 'hidden', maxHeight: countTilesInCol * maxTileWidth }}>
                {/* <div style={{
                  height: (colorsDataset.length * maxTileWidth) + 4,
                  // width: 30,
                  paddingTop: (noPagingRowPadStart ? noPagingRowPadStart * maxTileWidth : 0) + 2,
                  paddingBottom: (noPagingRowPadEnd ? noPagingRowPadEnd * maxTileWidth : 0) + 2,
                  // paddingRight: (noPagingColPadStart ? noPagingColPadStart * maxTileWidth : 0) + 2,
                  // paddingLeft: (noPagingColPadEnd ? noPagingColPadEnd * maxTileWidth : 0) + 2,
                  overflow: 'hidden'
                }}> */}
                {colors_bar}
                {/* </div> */}
              </Col>
              <Col span={22}>
                <div
                  id="scroll_all_content_data"
                  onScroll={this.onScrollContentData}
                  style={{ overflow: 'scroll', maxHeight: countTilesInCol * maxTileWidth }}>
                  <div style={{
                    height: (colorsDataset.length * maxTileWidth) + 4,
                    width: (sizesDataset.length * maxTileWidth) + 4,
                    paddingTop: (noPagingRowPadStart ? noPagingRowPadStart * maxTileWidth : 0) + 2,
                    paddingBottom: (noPagingRowPadEnd ? noPagingRowPadEnd * maxTileWidth : 0) + 2,
                    paddingRight: (noPagingColPadStart ? noPagingColPadStart * maxTileWidth : 0) + 2,
                    paddingLeft: (noPagingColPadEnd ? noPagingColPadEnd * maxTileWidth : 0) + 2,
                    overflow: 'hidden'
                  }}>
                    {gridData}
                  </div>
                </div>
              </Col>
            </Row>
          </div>)}
      </div >
    );
  }

  isPickes = () => {
    let filterdRecords = this.props.data[this.id_edit].data;
    console.log("hhhh ", filterdRecords);
    if (filterdRecords) {
      console.log("hhhh ", filterdRecords.length);
      return (filterdRecords.length) ? true : false;
    } else {
      return false;
    }
  }
}

export default MatrixGrid;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import {
    Table, Icon, Select, DatePicker, TimePicker, Tooltip,
    InputNumber, Checkbox, Popconfirm, Button, Input
} from "antd";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import moment from "moment";
import { fixCurrentPage } from "../../utils/utils";
import ModalHoursReportAdvanceSearch from "../../components/ModalHoursReportAdvanceSearch";
import ModalMessageHistoryAdvanceSearch from "../../components/ModalMessageHistoryAdvanceSearch";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 50;  // px

const sqlFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";
const dateFormat = "DD/MM/YYYY";

const { TextArea } = Input;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};

type State = {};

class MessageHistory extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "MESSAGE_HISTORY_MAIN";

        // calc page height
        this.state = {

            filterActive: false,
            valueSearch: '',
          
        };
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        //if (props.data[TableIDs.genericSelectorPrices] === undefined) {
        //    props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        //}

        this.api = {
            //get: "get_working_hours_table_list",
            get: "get_message_queue_table_list",
            //create: "create_price",
            // edit: "edit_working_hour_by_id",
            // delete: "delete_working_hour_by_id",
            //selector: "get_prices",
            //modalPriceListGet: "Prices_List_v2",
        }


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10178);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: -1, // filter if not -1
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 0,
            _sort_by: 'col_1',
            //
            _toDate: null,
            _fromDate: null,
            _msgType: '',
            _preDefineType: '',
            _sentSuccessfully: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

            this.setState({ filterActive: false, valueSearch: "" })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 9;
        // fall through
        switch (sorter.field) {
            case "key": col--;  // falls through // 0
            case "mID": col--;  // falls through //1
            case "mCustomerCode": col--;  // falls through //2
            case "mFirstName": col--;  // falls through //3
            case "mLastName": col--;  // falls through //4
            case "mMessageText": col--;  // falls through //5
            case "mDateTimeSent": col--;  // falls through //6
            // mMessageType
            case "mTypeDescription": col--;  // falls through //7
            case "mDestination": col--;  // falls through //8
            case "mWasSentSuccessfully": break; // 9
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mID: y[0],
                mCustomerCode: y[1],
                mFirstName: y[2],
                mLastName: y[3],
                mMessageText: y[4],
                mDateTimeSent: y[5],
                //mMessageType: y[6], // not in table
                mTypeDescription: y[7],
                mDestination: y[8],
                mWasSentSuccessfully: y[9],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 0,
            _sort_by: 'col_1',
        };

        //window.console.log(TableIDs.genericSelectorPrices);
        //window.console.log(this.props.data);
        //let list = this.props.data[TableIDs.genericSelectorPrices].dataset.map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        //});
        //let list = (this.props.data[this.id])[].dataset.map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        //});

        let columns = [
            {
                title: this.getText(12359),
                dataIndex: "mID",
                key: "mID",
                sorter: true,
                width: '8%',
                render: (text, record) => text,
            }, {
                title: this.getText(12360),
                dataIndex: "mCustomerCode",
                key: "mCustomerCode",
                width: '8%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(12361),
                dataIndex: "mFirstName",
                key: "mFirstName",
                width: '10%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(12362),
                dataIndex: "mLastName",
                key: "mLastName",
                width: '10%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(12363),
                dataIndex: "mMessageText",
                key: "mMessageText",
                width: '20%',
                sorter: true,
                //render: (text, record) => text,
                render: (text, record) => (
                    <Tooltip title={text} placement="left">
                        <TextArea
                            style={{ resize: 'none' }}
                            readOnly={true}
                            value={text}
                            //autosize={{ minRows: 2, maxRows: 2 }}
                            //autosize={false}
                            rows={2}
                        />
                    </Tooltip>
                )
            }, {
                title: this.getText(12364),
                dataIndex: "mDateTimeSent",
                key: "mDateTimeSent",
                width: '10%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(14651),
                dataIndex: "mDestination",
                key: "mDestination",
                width: '9%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(12365),
                dataIndex: "mTypeDescription",
                key: "mTypeDescription",
                width: '15%',
                sorter: true,
                render: (text, record) => {
                    let mCount = "";
                    if (record.mMessageType == '2') mCount = ' - ' + record.mTextMessageCount;
                    return text + mCount;
                }
            }, {
                title: this.getText(12366),
                dataIndex: "mWasSentSuccessfully",
                key: "mWasSentSuccessfully",
                width: '10%',
                sorter: true,
                //render: (text, record) => text,
                render: (text, record) => {
                    var t = text + ''; // cast to string, just in case
                    switch (t) {
                        case '0': return this.getText(12367)
                        case '1': return this.getText(12368)
                        case '2': return this.getText(12369)
                        case '3': return this.getText(12370)
                        case '4': return this.getText(17202)
                        default: return ''
                    }
                }
            },
        ];

        let genericActionButtons = [
            <Tooltip title={this.getText(14652)}>
                <Button key={"report"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => {
                        const {
                            _id, _words, _sort_by, _isAsc, _fromDate, _toDate, _msgType, _preDefineType, _sentSuccessfully
                        } = this.props.data[this.id].filters;

                        let request = {
                            _id, _sort_by, _isAsc, _fromDate, _toDate, _msgType, _preDefineType, _sentSuccessfully,
                            _words: encodeURIComponent(_words),
                            _compCode: this.props.user.companyCode,
                            reportName: "reports/MessageHistoryExcel.xls",
                            REPORT_LOCALE: this.getText(101),
                        };

                        this.props.ActionQueue.addToQueue({
                            action: this.props.dataActions.generateReport,
                            args: [this.props.user.companyCode, this.props.user.token, request]
                        });
                    }}
                ><Icon style={{ fontSize: 16 }} type={"file-excel"} theme="outlined" /></Button>
            </Tooltip>
        ]

        return (
            <div style={divStyle}>
                <PageToolbar
                    title={this.getText(12358)}
                    actionButtons={ [{
                        buttonType: 'filter',
                        buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalMessageHistoryAdvanceSearch); },
                    }, {
                        buttonDisabled: !this.state.filterActive,
                        buttonType: 'clear',
                        buttonFunction: this.initFilters,
                    }, {
                        buttonType: 'refresh',
                        buttonFunction: this.refreshData
                    }]}
                    genericActionButtons={genericActionButtons}
                    showsearch={(val) => {
                        if (val) { this.setState({ filterActive: true }) }
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                    page: 1,
                                    _page_num: 1,
                            }, this.api.get)
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                   ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    tableOnly={true}
                    percentageToMobile={[20, false, false, false, false, 40, 40]}
                    //dataSource={dataSource}
                    columns={columns}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ModalMessageHistoryAdvanceSearch
                    id={TableIDs.modalMessageHistoryAdvanceSearch}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalMessageHistoryAdvanceSearch)}
                    setFilterActive={e => { this.setState({ filterActive: e }) }}
                    filterActive={this.state.filterActive}
                    setFilterActive={e => {
                        if (e) this.setState({ filterActive: true })
                        else this.initFilters()
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                 />

            </div>
        );
    }
}

export default MessageHistory;

import React, { Component } from 'react';
import { Route } from "react-router-dom";
import EmployeeMenu from "../menus/EmployeeMenu";
import EmployeeIndex from "./Employees/Index"
import HoursReport from "./Employees/HoursReport";
import HoursReportDoc from "./reports/HoursReport";
import AttendanceClock from "./Employees/AttendanceClock";
import ProductivityReport from "./Employees/ProductivityReport";
import TableIDs from '../data/TableIDs';
import TestingPage from './Employees/TestingPage';
import WorkingShifts from "./Employees/WorkingShifts";
import Bonuses from "./Employees/Bonuses";
import ReportEmployeesPerDates from "./Employees/ReportEmployeesPerDates";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: any,
};

type State = {};

class Employees extends Component<Props, State> {
    constructor(props/*: Props*/) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };


    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={'21'} path={this.props.baseUrl + "/employees/home"} exact render={() => <EmployeeIndex {...routeProps} />} />),
            (<Route key={'22'} path={this.props.baseUrl + "/employees/hours"} exact render={() => <HoursReport {...routeProps} />} />),
            (<Route key={'23'} path={this.props.baseUrl + "/employees/attendance-clock"} exact render={() => <AttendanceClock {...routeProps} />} />),
            (<Route key={'24'} path={this.props.baseUrl + "/employees/attendance-report"} exact render={() => <HoursReportDoc {...routeProps} />} />),
            (<Route key={'25'} path={this.props.baseUrl + "/employees/productivity-report"} exact render={() => <ProductivityReport {...routeProps} />} />),
            (<Route key={'26'} path={this.props.baseUrl + "/employees/working-shifts"} exact render={() => <WorkingShifts {...routeProps} />} />),
            (<Route key={'27'} path={this.props.baseUrl + "/employees/bonuses"} exact render={() => <Bonuses {...routeProps} />} />),
            (<Route key={'28'} path={this.props.baseUrl + "/employees/report-per-dates"} exact render={() => <ReportEmployeesPerDates {...routeProps} />} />),
            (<Route key={'9999'} path={this.props.baseUrl + "/employees/TESTING"} exact render={() => <TestingPage {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/employees"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <EmployeeMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>);
    }
}

export default Employees;

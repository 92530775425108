/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Popconfirm, Button, Form, DatePicker, Checkbox } from "antd";
import moment from "moment";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

const { MonthPicker } = DatePicker;

class Paradigma extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            dateTo: null,
            isCentral: false,
        };

        this.api = {
            get: "get_debt_paradigma_file_data",
            close_doc: "close_delivery_doc_customer_debt",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16598);
    }

    sendAPI = (script, data, sCallback) => {
        let outputType = script === "generate_report" ? "OUTPUT_TYPE_REPORT_A4" : "OUTPUT_TYPE_DATA";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType[outputType], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    printParadigma = () => {
        let rName = this.state.isCentral ? "ParadigmaCentral.xls" : "Paradigma.xls";
        let params = "_month\fREPORT_LOCALE\freportName\r" + this.state.dateTo + "\f" + this.getText(101) + "\freports/" + rName;

        this.sendAPI("generate_report", params, (ob) => {
            if (ob && ob.blob && ob.blob.size > 0) {
                this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReportSuccess, args: [ob.blob, rName] });
                this.sendAPI(this.api.close_doc, params, () => { });
            }
        });
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            maxWidth: 500,
        };

        return (<div style={divStyle}>
            <PageToolbar title={gt(16599)} actionButtons={[]} />
            <Form.Item label={gt(16600)}>
                <MonthPicker
                    value={this.state.dateTo ? moment(this.state.dateTo, "MMYY") : null}
                    format={"MM/YYYY"}
                    onChange={(date) => { this.setState({ dateTo: date ? date.format("MMYY") : null }) }}
                />
            </Form.Item>
            <Form.Item>
                <Checkbox checked={this.state.isCentral} onChange={e => { this.setState({ isCentral: e.target.checked }) }}>
                    {this.getText(17304)}
                </Checkbox>
            </Form.Item>
            <Popconfirm onConfirm={this.printParadigma} title={gt(16602)} okText={gt(16603)} cancelText={gt(16604)}>
                <Button type="primary">{gt(16601)}</Button>
            </Popconfirm>
        </div>)
    }

}

export default Paradigma;
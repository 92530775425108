/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import { Form } from "antd";
import TableIDs from "../../data/TableIDs";

class InventoryException extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            StorageList: "",
            SupplierList: "",
            reportName: "reports/InventoryException.pdf"
        };

        this.selectors = [
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
            { id: TableIDs.genericSelectorSuppliers, api: "get_suppliers" },
        ]

        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(16402);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 800,
            margin: 'auto'
        };

        return (<div style={divStyle}>
            <PageToolbar title={gt(16403)} actionButtons={[]} />
            <Form.Item label={gt(16404)}>
                <GenericSelector
                    {...this.props}
                    {...this.selectors[0]}
                    multi
                    value={this.state.StorageList ? this.state.StorageList.split(',') : []}
                    onChange={e => {
                        this.setState({ StorageList: e ? e.join(',') : '' })
                    }} />
            </Form.Item>
            <Form.Item label={gt(19471)}>
                <GenericSelector
                    {...this.props}
                    {...this.selectors[1]}
                    multi
                    value={this.state.SupplierList ? this.state.SupplierList.split(',') : []}
                    onChange={e => {
                        this.setState({ SupplierList: e ? e.join(',') : '' })
                    }} />
            </Form.Item>
            <ReportTypeButton
                {...this.props}
                params={this.state}
                storageField={'StorageList'}
                favoriteSave
            />
        </div>)
    }

}

export default InventoryException;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import MatrixItemMenu from "../menus/MatrixItemMenu";
//import TagIndex from "./Tags/Index";
//import ItemListIndex from "./ItemList/Index";
import Bargains from "./Bargains/Bargains";

import TableIDs from '../data/TableIDs';
import MatrixItemsTable from "./MatrixItemList/MatrixItemsTable";
import RulerTable from "./MatrixItemList/RulerTable";
import ColorTable from "./MatrixItemList/ColorTable";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: any,
    ActionQueue: any
};

type State = {};

class Items extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

     const routes = [
            (<Route key={'111'} path={this.props.baseUrl + "/matrixItems/matrixItemList"} exact render={() => <MatrixItemsTable {...routeProps} />} />),
            (<Route key={'112'} path={this.props.baseUrl + "/matrixItems/rulers"} exact render={() => <RulerTable {...routeProps} />} />),
            (<Route key={'113'} path={this.props.baseUrl + "/matrixItems/colors"} exact render={() => <ColorTable {...routeProps} />} />),
            (<Route key={'114'} path={this.props.baseUrl + "/matrixItems/sizes"} exact render={() => <Bargains {...routeProps} />} />)
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/matrixItems"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <MatrixItemMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default Items;


import React from "react";
import { Popover, Card, DatePicker, Form, InputNumber, Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message, Radio } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import moment from "moment";
import LangContext from "../contextProvider/LangContext";
import DateTimePicker from "./DateTimePicker";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

//type State = {}
class ModalCreateEditMessage extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            visible2: false,
            msgTextAreaStyle: { height: 200, width: '100%' },
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            GP_IsShomerShabbat: false,
        };
        this.id = 'CREATE_EDIT_MSG';
        this.params_id = 'PRE_DEFINED_MESSAGE_PARAMS';

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.params_id] === undefined) { props.data[this.params_id] = { ...props.data.genericSelector }; }


        this.api = {
            get: "get_message_by_id",
            edit: "edit_create_message",

            get_params: "get_pre_defined_message_params",

        }

        this.actionButtons = [];

        this.dataSource = [];
        //this.textFieldRef = React.createRef();
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI("get_GP_IsShomerShabbat", "", ob => { this.setState({ GP_IsShomerShabbat: ob.data == '1' }) })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditMessage);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditMessage);
    }
    handleClear = () => {
        //this.clear();
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
        if (this.isNameValid() === 'success') {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataJustSaveEdit,
                args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
            });

            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataGetRequested,
                args: ["MESSAGE_BANK_MAIN", this.props.user.companyCode, this.props.user.token, "get_messages_table_list"]
            });

            this.props.uiActions.hideModal(TableIDs.modalCreateEditMessage);
        }
        else {
            message.warning(this.getText(12348));
        }
    }

    isNameValid = () => {
        const name = this.props.data[this.id].editedRecord['mName'];
        return (name === '' || name === null) ? 'error' : 'success';
    }

    insertAtCursor = (text) => {
        //const field = (this.textFieldRef.current);//.textAreaRef;
        const ts = document.getElementById("msg_text_area");
        let _start = ts.selectionStart;
        let _end = ts.selectionEnd;
        let _value = ts.value;


        if (_start || _start == '0') {
            var startPos = _start;
            var endPos = _end;
            var val = _value.substring(0, startPos) + text + _value.substring(endPos, _value.length);
            this.props.dataActions.genericDataSetEdit(this.id, { ['mText']: val })
        } else {
            //field.value += text;\
            console.log("DEBOZ false");
            this.props.dataActions.genericDataSetEdit(this.id, { ['mText']: text })
        }
    }

    handleVisibleChange = visible => { this.setState({ visible }); };
    hide = () => { this.setState({ visible: false, }); };
    insertAndhide = () => {
        this.insertAtCursor(this.state.url);
        this.setState({ visible: false, })
    };

    getHourFields = (label, field) => {
        const getTimePicker = (type) => {
            const key = field + (type == 1 ? "_FromHour" : "_ToHour");
            const value = this.props.data[this.id].editedRecord[key]
            const onChange = (e) => {
                this.props.dataActions.genericDataSetEdit(this.id, { [key]: e });
            }
            return (<DateTimePicker format="HH:mm:ss" {...{ key, value, onChange }} />)
        }
        let textFromHour = this.getText(18957);
        let textToHour = this.getText(18958);
        if (this.state.GP_IsShomerShabbat) {
            if (field == "mFri") textToHour = this.getText(19052);
            if (field == "mSat") textFromHour = this.getText(19053);
        }
        return (<span>
            <h2>{this.getText(label)}</h2>
            <FormItem label={textFromHour}>{getTimePicker(1)}</FormItem>
            <FormItem label={textToHour}>{getTimePicker(2)}</FormItem>
        </span>)
    }

    render() {
        //const { loading } = this.state;
        //let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(12349)}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        const buttonBar = (() => {
            const btnStyle = { marginLeft: 8, border: 'groove', borderWidth: 1, padding: 4, borderRadius: 4 }

            return (
                <div>
                    <span style={btnStyle} className={"clickable"} onClick={() => { this.insertAtCursor('~CN~'); }}><Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"user-add"} />{this.getText(12350)}</span>
                    <span style={btnStyle} className={"clickable"} onClick={() => { this.insertAtCursor('~RC~'); }}><Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"lock"} />{this.getText(12351)}</span>
                    <span style={btnStyle} className={"clickable"} onClick={() => { this.insertAtCursor('~VD~'); }}><Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"clock-circle"} />{this.getText(12352)}</span>
                    <span style={btnStyle} className={"clickable"} onClick={() => { this.insertAtCursor('~LU~'); }}><Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"credit-card"} />{this.getText(12353)}</span>
                    <Popover
                        content={<div>
                            <Input placeholder={this.getText(18280)}
                                value={this.state.url}
                                onChange={(e) => this.setState({ url: e.target.value })} />
                            <a style={{ paddingRight: 8 }} onClick={this.hide}>{this.getText(18281)}</a>
                            <a style={{ paddingRight: 8 }} onClick={this.insertAndhide}>{this.getText(18282)}</a>
                        </div>}
                        //title="Title"
                        trigger="click"
                        visible={this.state.visible}
                        onVisibleChange={this.handleVisibleChange}
                    >
                        <span style={btnStyle} className={"clickable"}><Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"link"} />{this.getText(18283)}</span>
                    </Popover>
                    <Popover
                        title={this.getText(18285)}
                        content={<Radio.Group
                            value={this.state.msgTextAreaStyle.width}
                            onChange={e => {
                                this.setState({
                                    msgTextAreaStyle: { width: e.target.value, height: e.target.value != '100%' ? 640 : 200 },
                                    visible2: false,
                                })
                            }}>
                            <Radio value={'100%'}>{this.getText(18286)}</Radio><br />
                            {[240, 320, 360, 375, 390, 414, 480].map(x => [<Radio value={x}>{x}</Radio>, <br />])}
                        </Radio.Group>}
                        //title="Title"
                        trigger="click"
                        visible={this.state.visible2}
                        onVisibleChange={visible2 => { this.setState({ visible2 }) }}
                    >
                        <span style={btnStyle} className={"clickable"}><Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"eye"} />{this.getText(18284)}</span>
                    </Popover>
                </div>
            )
        })();

        const { mText } = this.props.data[this.id].editedRecord;
        let chartersCount = mText ? new Blob([mText]).size : 0;
        let smsPack = Math.ceil(chartersCount / 134);
        let smsPackView = smsPack > 4 ? 4 : smsPack;

        // let smsCountChar = [];

        // for (let x = 0; x < chartersCount; x += 134) {
        //     let p = chartersCount - (smsCountChar.length * 134)
        //     smsCountChar.push(<p>{p > 134 ? 134 : p}/134</p>)
        // }

        let textRemoveLink = "";
        if (this.props.data[this.id].editedRecord['mUnsubscribeLink'] == '1') {
            textRemoveLink = `
--------------------------
להסרה:
https://cashontab.co.il/?XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
`
        }

        let pDataset = this.props.data[this.params_id].dataset.filter(f => f.code)
        let textVal = this.props.data[this.id].editedRecord['mText']
        if (textVal && pDataset?.length) pDataset.forEach(x => { textVal = textVal.split("prm" + x.code).join('<' + x.name + '>') })
        if (textRemoveLink && textVal?.indexOf(textRemoveLink) == -1) textVal += textRemoveLink;

        const onChangeText = (txt) => {
            let mText = txt;
            if (mText && textRemoveLink) mText = mText.split(textRemoveLink).join("");
            [1, 2, 3, 4, 5].forEach(x => {
                if (mText && mText.indexOf('prm' + x) > -1) {
                    let findP = pDataset.find(f => f.code == x);
                    if (!findP) {
                        message.error(this.getText(19689));
                        mText = mText.split('prm' + x).join("")
                    }
                }
            })
            pDataset.forEach(x => { mText = mText.split('<' + x.name + '>').join("prm" + x.code) })
            this.props.dataActions.genericDataSetEdit(this.id, { mText });
        }


        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'80%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}

                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                data={this.props.data} dataAction={this.props.dataAction}>

                <Form id="create_edit_message_form" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: '1' }}
                    onSubmit={this.handleSaveChanges}>

                    <FormItem
                        key={'mName'} label={this.getText(12354)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }}

                        validateStatus={this.isNameValid()}
                        // hasFeedback={false}
                        extra={<label style={(this.isNameValid() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>{this.getText(12355)}</label>}
                        required={true}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mName']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mName']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mText'} label={[
                            <p>{this.getText(12356)}</p>,
                            <p><Icon type="mail" />&nbsp;{smsPackView}</p>,
                            <p dir="ltr">{chartersCount} / {smsPackView * 134}</p>,
                            smsPack > 4 ? (<p style={{ color: "red" }}>{this.getText(17718)}</p>) : "",
                            mText && chartersCount > mText.length ? (<p>{this.getText(17719)}</p>) : ""
                        ]}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }}
                    //validateStatus={}
                    >

                        <TextArea
                            //ref={this.textFieldRef}
                            id="msg_text_area"
                            value={textVal}
                            onChange={(value) => { onChangeText(value.target.value) }}
                            style={{ ...this.state.msgTextAreaStyle, direction: 'rtl' }}
                        />

                        {buttonBar}
                        {pDataset.map(x => {
                            if (textVal?.indexOf('<' + x.name + '>') > -1) {
                                return (<Button onClick={() => { onChangeText(textVal.split('<' + x.name + '>').join("")) }}> {'<' + x.name + '>'}</Button>)
                            } else {
                                return (<Button type={"primary"} onClick={() => { this.insertAtCursor('prm' + x.code) }}>{'<' + x.name + '>'}</Button>)
                            }
                        })}
                    </FormItem>
                    <FormItem
                        key={'mUnsubscribeLink'} label={' '}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }}
                    //validateStatus={}
                    >
                        <Checkbox
                            checked={this.props.data[this.id].editedRecord['mUnsubscribeLink'] == '1'}
                            onChange={(value) => {
                                const mUnsubscribeLink = value.target.checked ? '1' : '0';
                                this.props.dataActions.genericDataSetEdit(this.id, { mUnsubscribeLink });
                            }}
                        >{this.getText(16808)}</Checkbox>
                    </FormItem>
                    <div style={{ textAlign: "center" }}>
                        <h1>{this.getText(18953)}</h1>
                        <Row>
                            <Col span={window.innerWidth > 600 ? 8 : 24}>{this.getHourFields(18954, "mSun2Thr")}</Col>
                            <Col span={window.innerWidth > 600 ? 8 : 24}>{this.getHourFields(18955, "mFri")}</Col>
                            <Col span={window.innerWidth > 600 ? 8 : 24}>{this.getHourFields(18956, "mSat")}</Col>
                        </Row>
                    </div>
                </Form>
                <div className="action_footer">
                    <Button
                        type="primary" htmlType="submit" form="create_edit_message_form"
                        loading={this.props.ui.workInProgress}
                        className={"form-button-save"} icon={"check"}>{this.getText(12357)}</Button>
                </div>

            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                { _id: this.props.ui.data },
                this.api.get
            );

            this.props.dataActions.genericSelectorRefreshDataset(
                this.params_id,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.get_params,
                { _id: this.props.ui.data });
        }
    }
}

export default ModalCreateEditMessage;

/* @flow */
/*jshint esversion: 6 */
import { Col, Form, Row, Button, message, InputNumber } from "antd";
import React, { Component } from "react";
import "../../App.css";
import DateTimePicker from "../../components/DateTimePicker";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import TableIDs from "../../data/TableIDs";


class ExportSales extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _dateStart: "",
            _dateEnd: "",
            _docTypeList: "",
            _storageList: "",
            _staticCustH: "",
            _staticCustP: "",
        };

        this.api = {
            get: "get_export_sales_default_filters",
            set: "set_export_sales_default_filters",
        }

        this.selectors = [
            { id: "SALES_DOCUMENT_TYPES", api: "get_sales_document_types_list" },
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
        ];

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { dataset: [] }
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI(this.api.get, "", ob => {
            let y = ob.data ? ob.data.split("\f") : ["", "", "", ""];
            this.setState({
                _dateStart: y[0],
                _dateEnd: y[1],
                _docTypeList: y[2],
                _storageList: y[3],
                _staticCustH: y[4],
                _staticCustP: y[5],
            });
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(17011);
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    createFormItem = (label, content) => {
        return (<Form.Item label={this.getText(label)}>{content}</Form.Item>)
    }

    createDatePicker = (field, label) => {
        return this.createFormItem(label, <DateTimePicker
            format="DD/MM/YYYY"
            value={this.state[field] ? this.state[field] : null}
            onChange={e => { this.setState({ [field]: e ? e : "" }) }}
            minDate={field == '_dateEnd' ? this.state._dateStart : null}
            maxDate={field == '_dateStart' ? this.state._dateEnd : null}
        />)
    }

    createSelect = (index, field, label) => {
        return this.createFormItem(label, <GenericSelector
            {...this.props}
            {...this.selectors[index]}
            multi
            value={this.state[field] ? this.state[field].split(",") : []}
            onChange={e => { this.setState({ [field]: e ? e.join(",") : "" }) }}
        />)
    }

    createInputNumber = (field, label) => {
        return this.createFormItem(label, <InputNumber
            style={{ width: "100%" }}
            value={this.state[field] ? this.state[field] : null}
            onChange={e => { this.setState({ [field]: e ? e : "" }) }}
        />)
    }

    createButton = (label, onClick) => {
        return (<Button type="primary" style={{ margin: 10 }} onClick={onClick}>{this.getText(label)}</Button>)
    }

    generateReport = () => {
        const { _dateStart, _dateEnd, _docTypeList, _storageList, _staticCustH, _staticCustP } = this.state;
        let fullStoragesList = this.props.data[this.selectors[1].id].dataset.map(x => x.code).join(",")

        let params = {
            _dateStart, _dateEnd, _docTypeList, _staticCustH, _staticCustP,
            _storageList: _storageList ? _storageList : fullStoragesList,
            reportName: "reports/ExportSales.xls",
            REPORT_LOCALE: this.getText(101),
        }

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [this.props.user.companyCode, this.props.user.token, params]
        });
    }

    saveFilters = () => {
        const { _dateStart, _dateEnd, _docTypeList, _storageList, _staticCustH, _staticCustP } = this.state;

        let dataSend = "_dateStart\f_dateEnd\f_docTypeList\f_storageList\f_staticCustH\f_staticCustP\r"
            + _dateStart + "\f" + _dateEnd + "\f" + _docTypeList + "\f" + _storageList + "\f" + _staticCustH + "\f" + _staticCustP;

        this.sendAPI(this.api.set, dataSend, () => { message.success(this.getText(17019)) })
    }


    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 1000,
            margin: "auto"
        };

        return (<div style={divStyle}>
            <PageToolbar title={gt(17012)} actionButtons={[]} />
            <Row>
                <Col span={window.innerWidth > 600 ? 6 : 24}>
                    {this.createDatePicker('_dateStart', 17013)}
                    {this.createDatePicker('_dateEnd', 17014)}
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    {this.createSelect(0, '_docTypeList', 17015)}
                    {this.createSelect(1, '_storageList', 17016)}
                </Col>
                <Col span={window.innerWidth > 600 ? 6 : 24}>
                    {this.createInputNumber('_staticCustH', 17020)}
                    {this.createInputNumber('_staticCustP', 17021)}
                </Col>
            </Row>
            {this.createButton(17017, this.generateReport)}
            {this.createButton(17018, this.saveFilters)}
        </div>)
    }

}

export default ExportSales;
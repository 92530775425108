/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import TableIDs from '../data/TableIDs';
import MailingMenu from "../menus/MailingMenu";
import MailingList from "./mailing/MailingList";
import MessageBank from "./mailing/MessageBank";
import MessageHistory from "./mailing/MessageHistory";
import MessageUsing from "./mailing/MessageUsing";
import MailingLinkSettings from "./mailing/MailingLinkSettings";
import MessageListWebPushr from "./mailing/MessageListWebPushr";
import MessageEvents from "./mailing/MessageEvents";
import PosMessages from "./mailing/PosMessages";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object
};

type State = {};

class Mailing extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={101} path={this.props.baseUrl + "/mailing/mailing-list"} exact render={() => <MailingList {...routeProps} />} />),
            (<Route key={102} path={this.props.baseUrl + "/mailing/message-bank"} exact render={() => <MessageBank {...routeProps} />} />),
            (<Route key={103} path={this.props.baseUrl + "/mailing/message-history"} exact render={() => <MessageHistory {...routeProps} />} />),
            (<Route key={104} path={this.props.baseUrl + "/mailing/message-useing"} exact render={() => <MessageUsing {...routeProps} />} />),
            (<Route key={105} path={this.props.baseUrl + "/mailing/mailing-link-settings"} exact render={() => <MailingLinkSettings {...routeProps} />} />),
            (<Route key={106} path={this.props.baseUrl + "/mailing/mailing-list-webpushr"} exact render={() => <MessageListWebPushr {...routeProps} />} />),
            (<Route key={107} path={this.props.baseUrl + "/mailing/message-events"} exact render={() => <MessageEvents {...routeProps} />} />),
            (<Route key={108} path={this.props.baseUrl + "/mailing/pos-messages"} exact render={() => <PosMessages {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/mailing"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <MailingMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default Mailing;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
import { SocialIcon } from 'react-social-icons';

class SocialIconLinks extends Component {
    constructor(props: Props) {
        super(props);

        this.state = {}

        this.id = "VIRTUAL_SHOP_NETWORK_LINKS";

        if (props.data[this.id] === undefined) props.data[this.id] = { dataset: [] };

        this.api = {
            get: 'get_virtual_shop_network_links',
        }

    }

    componentDidMount() {
        this.props.sendAPI(this.api.get, '', (ob) => {
            let dataset = ob.data ?
                ob.data.split('\r').map(x => {
                    let y = x.split('\f');
                    return {
                        url: y[0],
                        network: y[1],
                        title: y[2],
                    }
                })
                : []
            this.props.setJson(this.id, { dataset })
            // setTimeout(() => {
            let targetBlank = [...document.getElementsByClassName('target_blank')];
            if (targetBlank) targetBlank.forEach(x => { x.target = '_blank' })
            // }, 1000);
        }, this.props.errorCallback)
    }

    render() {
        return (<div id="social-icon-links">
            {this.props.data[this.id].dataset.map(x => {
                return (<SocialIcon
                    {...x}
                    className={'target_blank'}
                    fgColor={'#ffffff'}
                    style={{ width: 40, height: 40 }}
                />)
            })}
        </div>)
    }
}
export default SocialIconLinks;


/* @flow */
/*jshint esversion: 6 */
/*jslint es6*/

import Dispatcher from "./Dispatcher";

export type intent = {action: Function, args: Array<any>};

const ActionQueue = {
    queue: [],
    isProcessing: false,

    addToQueue (intent: intent) {
        ActionQueue.queue.push(intent);
        if (!ActionQueue.isProcessing) {
            ActionQueue.startProcessing();
        }
    },

    startProcessing () {
        ActionQueue.isProcessing = true;
        while (ActionQueue.queue.length > 0) {
            if (Dispatcher.isDispatching()) {
                return setTimeout(ActionQueue.startProcessing, 100);
            }
            let intent: intent = ActionQueue.queue.shift();
            if (intent && intent.action) {
                intent.action.apply(null, intent.args);
            }
        }

        ActionQueue.isProcessing = false;
    }
};


export default ActionQueue;
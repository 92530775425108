/* @flow */
/*jshint esversion: 6 */
import { Checkbox, Icon, Select, Input } from "antd";
import React, { Component } from "react";
import "../../App.css";
import GenericModal from "../../components/GenericModal";
import GenericTable from "../../components/GenericTable";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class Benefits extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalCompanies: null,
        };

        this.id = "BENEFITS_DISTRIBUTORS";
        this.comp_id = "BENEFITS_COMPANIES";

        this.api = {
            get: "get_benefits_distributors",
            add: "add_benefits_distributors",
            delete: "delete_benefits_distributors",

            get_comp: "get_benefits_companies",
            add_comp: "add_benefits_companies",
            edit_comp: "edit_benefits_companies",
            delete_comp: "delete_benefits_companies",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.comp_id] === undefined) props.data[this.comp_id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(17462);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    refreshDataComp = () => {
        this.props.dataActions.genericDataGetRequested(
            this.comp_id, this.props.user.companyCode, this.props.user.token, this.api.get_comp);
    }

    editBenefits = (_id, _checked) => {
        if (_checked) this.sendAPI(this.api.add, "_id\r" + _id, this.refreshData)
        else this.sendAPI(this.api.delete, "_id\r" + _id, this.refreshData)
    }

    addOrDeleteComp = (_id, _checked) => {
        if (_checked) this.sendAPI(this.api.add_comp, "_id\r" + _id, this.refreshDataComp)
        else this.sendAPI(this.api.delete_comp, "_id\r" + _id, this.refreshDataComp)
    }

    editComp = () => {
        this.props.dataActions.genericDataSaveEdit(
            this.comp_id, this.props.user.companyCode, this.props.user.token, this.api.edit_comp, this.api.get_comp);
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        let gt = this.getText;

        const render = text => text;
        const renderCheckbox = (text, record) => (<Checkbox checked={text ? true : false} onChange={e => { this.editBenefits(record._id, e.target.checked) }} />);
        const renderOpenButton = (text, record) => {
            return record._checked ? (<Icon
                style={{ fontSize: 16 }}
                className={"clickable margin-4"}
                type={"table"}
                onClick={() => {
                    this.setState({ modalCompanies: record }, () => {
                        this.props.dataActions.genericDataSetFilter(this.comp_id, this.props.user.companyCode, this.props.user.token, { _id: record._id }, this.api.get_comp)
                    })
                }}
            />) : ""
        }

        const { editing, editedRecord } = this.props.data[this.comp_id];

        const renderCompCheckbox = (text, record) => (<Checkbox checked={text ? true : false} onChange={e => { this.addOrDeleteComp(record._id, e.target.checked) }} />);
        const renderCompEdit = (text, record) => {
            if (record._checked) {
                return (record.key === editing) ?
                    (<TableLineIcons onCheck={this.editComp} onClose={() => { this.props.dataActions.genericDataCancelEditing(this.comp_id) }} />)
                    : (<TableLineIcons onEdit={() => { this.props.dataActions.genericDataStartEditing(this.comp_id, record.key, record.index) }} />)
            }
        }

        const renderMinusItemCode = (text, record) => {
            if (record._checked) {
                return (record.key === editing) ?
                    (<SelectorItemClassic
                        {...this.props}
                        valueId={editedRecord._MinusItemCode}
                        valueName={editedRecord._MinusItemName}
                        onChange={(code, name) => { this.props.dataActions.genericDataSetEdit(this.comp_id, { _MinusItemCode: code, _MinusItemName: name }) }}
                        notViewName
                    />)
                    : text
            }
        }

        const renderMinusItemName = (text, record) => {
            if (record._checked) {
                return (record.key === editing) ?
                    (<Input
                        value={editedRecord._MinusItemName}
                        onChange={e => { this.props.dataActions.genericDataSetEdit(this.comp_id, { _MinusItemName: e.target.value }) }}
                    />)
                    : text
            }
        }

        const renderInvoiceOrReceipt = (text, record) => {
            if (record._checked) {
                return (record.key === editing) ?
                    (<Select
                        style={{ width: "100%" }}
                        value={editedRecord._InvoiceOrReceipt}
                        onChange={e => { this.props.dataActions.genericDataSetEdit(this.comp_id, { _InvoiceOrReceipt: e }) }}
                    >
                        <Select.Option value={'1'}>{gt(17476)}</Select.Option>
                        <Select.Option value={'2'}>{gt(17477)}</Select.Option>
                    </Select>)
                    : text == '1' ? gt(17476) : text == '2' ? gt(17477) : ""
            }
        }

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            width: 600,
            margin: "auto"
        };

        const mc = this.state.modalCompanies

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(17463)}
                columns={[
                    { title: gt(17464), key: "_id", dataIndex: "_id", width: "10%", render },
                    { title: gt(17465), key: "_text", dataIndex: "_text", width: "70%", render },
                    { title: gt(17466), key: "_checked", dataIndex: "_checked", width: "10%", render: renderCheckbox },
                    { title: gt(17467), width: "10%", render: renderOpenButton },
                ]}
            />
            <GenericModal
                visible={mc !== null}
                onCancel={() => { this.setState({ modalCompanies: null }) }}
                title={gt(17468) + " " + (mc ? mc._id + " - " + mc._text : "")}
                actionButtons={[{ buttonType: 'refresh', buttonFunction: this.refreshDataComp }]}>
                <GenericTable
                    {...this.props}
                    tableOnly
                    id={this.comp_id}
                    getApi={this.api.get_comp}
                    filters={{ _id: mc ? mc._id : 0 }}
                    columns={[
                        { title: gt(17469), key: "_id", dataIndex: "_id", width: "8%", render },
                        { title: gt(17470), key: "_text", dataIndex: "_text", width: "32%", render },
                        { title: gt(17471), key: "_InvoiceOrReceipt", dataIndex: "_InvoiceOrReceipt", width: "12%", render: renderInvoiceOrReceipt },
                        { title: gt(17472), key: "_MinusItemCode", dataIndex: "_MinusItemCode", width: "12%", render: renderMinusItemCode },
                        { title: gt(17473), key: "_MinusItemName", dataIndex: "_MinusItemName", width: "24%", render: renderMinusItemName },
                        { title: gt(17474), key: "_checked", dataIndex: "_checked", width: "6%", render: renderCompCheckbox },
                        { title: gt(17475), width: "6%", render: renderCompEdit },
                    ]}
                />
            </GenericModal>
        </div>)
    }
}

export default Benefits;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { InputNumber, Tooltip, Popover, Icon, Button, Row, Col, Select, Form } from "antd";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericModal from "../../components/GenericModal";
import TableLineIcons from "../../components/TableLineIcons";
import PageToolbar from "../../components/PageToolbar";
import GenericSelector from "../../components/GenericSelector";
import { colorPallete, colourHexToName, } from "../../utils/utils";
import { TwitterPicker, } from 'react-color';
import TableIDs from "../../data/TableIDs";

const { Option } = Select;

const topHeight = 340; // px
const rowHeight = 30;  // px

class VirtualShopStatuses extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            modalStateMachine: false,
            modalSmCreateEdit: null,
            statusName: '',
            statusesExsist: '',
        }

        this.doOnResize = null;

        this.id = "EXTERNAL_ORDER_DELIVERY_STATUSES";
        this.sm_id = "EXTERNAL_ORDERS_STATUS_CHANGE_LOGIC";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.sm_id] === undefined) props.data[this.sm_id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorExternalOrderSuppliers] === undefined) props.data[TableIDs.genericSelectorExternalOrderSuppliers] = { ...props.data.genericSelector };

        this.api = {
            get: "get_external_order_delivery_statuses",
            edit: "edit_external_order_delivery_statuses",

            get_sm: "get_external_orders_status_change_logic",
            create_edit_sm: "create_edit_external_orders_status_change_logic",
            delete_sm: "delete_external_orders_status_change_logic",
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.refreshData();
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    refreshDataSm = () => {
        this.props.dataActions.genericDataGetRequested(
            this.sm_id, this.props.user.companyCode, this.props.user.token, this.api.get_sm);
    }


    setFilterSM = (mStatusId) => {
        this.props.dataActions.genericDataSetFilter(
            this.sm_id, this.props.user.companyCode, this.props.user.token, { mStatusId }, this.api.get_sm);
    }

    getColorPicker = () => {
        const colorPickerContent = (
            <TwitterPicker
                colors={colorPallete}
                width={'100%'}
                triangle='hide'
                onChange={(color) => {
                    this.props.dataActions.genericDataSetEdit(this.id, { sColor: colourHexToName(color.hex) })
                }} />);

        return (
            <Tooltip title={this.getText(11506)}>
                <Popover
                    placement="top"
                    content={colorPickerContent}
                    title={
                        <PageToolbar
                            title={this.getText(11507)} actionButtons={[]} isModal={true}
                            ui={this.props.ui} uiActions={this.props.uiActions}
                            data={this.props.data} dataActions={this.props.dataActions}
                        />
                    }
                    trigger="click">
                    <Button className={"btn"} style={{
                        marginTop: "5px", marginLeft: "5px",
                        width: "100px", backgroundColor: this.props.data[this.id].editedRecord['sColor']
                    }}>
                        <Icon style={{ fontSize: 16 }} type="edit" theme="outlined" />
                    </Button>
                </Popover>
            </Tooltip>
        )
    }

    smCancel = () => {
        this.setState({ modalSmCreateEdit: null, statusesExsist: null })
    }

    smClear = (key) => {
        this.setState({
            modalSmCreateEdit: {
                key: key,
                cId: key,
                cSupplierId: '',
                cNextStatus: '',
                cLocalFunction: '',
                cFunction: '',
            }
        })
    }

    smOk = (request) => {
        this.props.dataActions.genericDataSaveEditWithParams(
            this.sm_id, this.props.user.companyCode, this.props.user.token, this.api.create_edit_sm, this.api.get_sm, request);
        // this.refreshDataSm();
        this.smCancel()
    }

    smEdit = (field, value) => {
        let modalSmCreateEdit = { ...this.state.modalSmCreateEdit, [field]: value }
        if (field === 'cSupplierId') {
            modalSmCreateEdit = { ...modalSmCreateEdit, cLocalFunction: '', cFunction: '', cNextStatus: '' }

            let statusesExsist = this.props.data[this.sm_id].data
                .map(x => {
                    let y = x.split('\f');
                    return { supp: y[1], status: y[3] }
                })
                .filter(f => f.supp === value)
                .map(x => x.status);

            this.setState({ modalSmCreateEdit, statusesExsist })
        } else {
            this.setState({ modalSmCreateEdit })
        }
    }

    colEdit = (text, record) => {
        let props = {
            onEdit: () => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) },
            genericIcons: [
                {
                    type: 'code-sandbox',
                    onClick: () => { this.setState({ modalStateMachine: record.key, statusName: record.sName }, () => { this.setFilterSM(record.key) }) },
                    tooltip: this.getText(14527),
                }
            ],
        }
        if (record.key === this.props.data[this.id].editing) {
            props = {
                onCheck: () => { this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get) },
                onClose: () => { this.props.dataActions.genericDataCancelEditing(this.id) }
            }
        }
        return (<TableLineIcons {...props} />)
    }

    colOrder = (text, record) => {
        if (record.key === this.props.data[this.id].editing) {
            return (<InputNumber
                value={this.props.data[this.id].editedRecord.sOrder}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { sOrder: e }) }}
            />)
        } else {
            return text;
        }
    };


    colColor = (text, record) => {
        if (record.key === this.props.data[this.id].editing) {
            return this.getColorPicker();
        } else {
            return (<div style={{ backgroundColor: text, width: '100%', height: '24px' }}></div>)
        }
    };

    supplierLogo = (t) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        let baseUrl = isDev ? "http://147.235.163.248" : origin;

        return (<img src={baseUrl + '/uploads/shared/' + t} height="40" />)
    }

    colFunName = (t) => {
        switch (t) {
            case '0': return this.getText(14528);
            case '1': return this.getText(14529);
            case '2': return this.getText(14530);
            case '3': return this.getText(14531);
            case '10': return this.getText(14532);
            case '11': return this.getText(14533);
            case '12': return this.getText(14534);
        }
    }

    colEditSm = (text, record) => {
        let props = {
            onEdit: () => { this.setState({ modalSmCreateEdit: record }) },
            onDelete: () => {
                this.props.dataActions.genericDataDeleteRow(
                    this.sm_id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete_sm, this.api.get_sm);
            },
            deleteQuetion: { title: this.getText(14539), okText: this.getText(14540), cancelText: this.getText(14541) },
        }

        return (<TableLineIcons {...props} />)
    }

    getSmField = (label, content) => {
        return (<Col span={window.innerWidth > 600 ? 12 : 24}>
            <Form.Item label={this.getText(label)}>{content}</Form.Item>
        </Col>)
    }

    getSuppSelector = () => {
        return (<GenericSelector
            value={this.state.modalSmCreateEdit ? this.state.modalSmCreateEdit.cSupplierId : null}
            onChange={(e) => { this.smEdit('cSupplierId', e) }}
            id={TableIDs.genericSelectorExternalOrderSuppliers}
            api={"external_orders_supplier_list"}
            data={this.props.data} dataActions={this.props.dataActions} user={this.props.user}
        />)
    }

    getDataSelector = (field, data) => {
        return (<Select
            value={this.state.modalSmCreateEdit ? this.state.modalSmCreateEdit[field] : null}
            onChange={(e) => { this.smEdit(field, e) }}
            style={{ width: '95%', paddingLeft: '4px', paddingRight: '4px' }}>
            {data.map((x) => (<Option key={x['code']} value={x['code']}>{x['code']} - {x['name']}</Option>))}
        </Select>)
    }

    getStatusSelector = () => {
        let data = this.props.data[this.id].data.map((x) => {
            let y = x.split('\f');
            return { code: y[0], name: y[1], order: parseInt(y[2]) }
        });
        let curStatus = data.find(f => f.code === this.state.modalStateMachine);
        if (curStatus) data = data.filter(f => f.order > curStatus.order);
        if (this.state.statusesExsist) data = data.filter(f => this.state.statusesExsist.indexOf(f.code) === -1);
        return this.getDataSelector('cNextStatus', data);
    }

    getLogicSelector = (field) => {
        let list = ['0', '10', '11', '12'];
        if (field === 'cFunction' && this.state.modalSmCreateEdit) {
            switch (this.state.modalSmCreateEdit.cSupplierId) {
                case '3': list = ['0', '1']; break;
                case '4': list = ['0', '2']; break;
                case '2': list = ['0', '3']; break;
                default: list = ['0'];
            }
        }
        return this.getDataSelector(field, list.map((x) => {
            return { code: x, name: this.colFunName(x) }
        }))
    }

    render() {
        let gt = this.getText;
        let colTxt = (text) => text

        let columns = [
            { title: gt(14473), dataIndex: "sID", key: "sID", width: '10%', render: colTxt },
            { title: gt(14474), dataIndex: "sName", key: "sName", width: '40%', render: colTxt },
            { title: gt(14475), dataIndex: "sOrder", key: "sOrder", width: '20%', render: this.colOrder },
            { title: gt(14476), dataIndex: "sColor", key: "sColor", width: '20%', render: this.colColor },
            { title: gt(14477), width: '10%', render: this.colEdit },
        ];

        let smColumns = [//'cId', 'cSupplierId', 'cNextStatus', 'cLocalFunction', 'cFunction'
            { title: gt(14535), dataIndex: "cSupplierLogo", key: "cSupplierLogo", width: '5%', render: this.supplierLogo },
            { title: gt(14536), dataIndex: "cNextStatusName", key: "cNextStatusName", width: '25%', render: colTxt },
            { title: gt(14538), dataIndex: "cLocalFunction", key: "cLocalFunction", width: '30%', render: this.colFunName },
            { title: gt(14537), dataIndex: "cFunction", key: "cFunction", width: '30%', render: this.colFunName },
            { title: gt(14477), width: '10%', render: this.colEditSm },
        ];

        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        return (<GenericModal
            visible={this.props.visible}
            onCancel={this.props.onCancel}
            title={gt(14472)}
            width={'60%'}
            actionButtons={[{
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }]}>
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                columns={columns}
                percentageToMobile={[20, 80]}
                tableOnly={true}
                pagination={{ pageSize: numOfRowsByHeight }}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
            <GenericModal
                visible={this.state.modalStateMachine}
                onCancel={() => { this.setState({ modalStateMachine: false, statusName: '' }) }}
                title={gt(14547) + " " + this.state.statusName}
                width={800}
                actionButtons={[{
                    buttonType: 'add',
                    buttonFunction: () => { this.smClear('0') }
                }, {
                    buttonType: 'refresh',
                    buttonFunction: this.refreshDataSm
                }]}>
                <ResponsiveTable
                    idTable={this.props.data[this.sm_id]}
                    columns={smColumns}
                    percentageToMobile={[20, 80]}
                    tableOnly={true}
                    pagination={{ pageSize: numOfRowsByHeight }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <GenericModal
                    visible={this.state.modalSmCreateEdit !== null}
                    onCancel={this.smCancel}
                    title={gt(14548) + " " + this.state.statusName}
                    width={700}
                    footer={[
                        <Button key='back' onClick={this.smCancel} style={{ margin: 5 }}>{gt(14542)}</Button>,
                        <Button key='clear' onClick={() => { this.smClear(this.state.modalSmCreateEdit.key) }} style={{ margin: 5 }}>{gt(14543)}</Button>,
                        <Button key='create' type='primary' onClick={() => {
                            this.smOk({
                                ...this.props.data[this.sm_id].filters,
                                ...this.state.modalSmCreateEdit,
                            })
                        }} style={{ margin: 5 }}>{gt(14544)}</Button>,
                    ]}>
                    <Row>
                        {this.getSmField(14545, this.getSuppSelector())}
                        {this.getSmField(14546, this.getStatusSelector())}
                        {this.getSmField(14538, this.getLogicSelector('cLocalFunction'))}
                        {this.getSmField(14537, this.getLogicSelector('cFunction'))}
                    </Row>
                </GenericModal>
            </GenericModal>
        </GenericModal>)
    }
}
export default VirtualShopStatuses;
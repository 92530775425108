import React from "react";
import {
    Form, Row, Col, Input, Icon, Button,
    Tooltip, Modal, Checkbox, Select, TimePicker, Popconfirm, message,
} from 'antd';
import moment from "moment";
import TableIDs from '../data/TableIDs.js';
import PageToolbar from "./PageToolbar";
import ResponsiveTable from "./ResponsiveTable";
import Jax from "../Jax/jax.es6.module";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import LangContext from "../contextProvider/LangContext";

const topHeight = 340; // px
const rowHeight = 30;  // px
const { Option } = Select;
const timeFormat = "HH:mm";
const sqlFormat = "YYYY-MM-DD";
const dateFormat = "DD/MM/YYYY";
const { confirm } = Modal;

class ModalWorkingHouersPerEmployee extends React.Component {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            HoursReport: {
                EmployeeList: undefined,
                BranchList: undefined,
                isTimeFormat: 0,
                Overtime: 0,
                SeparatePage: 0,
                NotIsActive: 0,
                FullDates: 0,
                inBranch: 1,
                FromDate: new moment().startOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
                ToDate: new moment().endOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
                reportName: 'reports/WorkingHours.pdf'

                // EmployeeList: undefined,
                // FromDate: new moment().startOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
                // ToDate: new moment().endOf('month').format("YYYY-MM-DD"), // HH:mm:ss"),
                // reportName: 'reports/HoursReportExtended.pdf'
            },
        }

        this.newLine = 0;

        this.id = "MODAL_WORKING_HOUERS_PER_EMPLOYEE";

        if (props.data[this.id] === undefined) props.data[this.id] = { dataset: [] }
        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };

        this.api = {
            get: "get_working_houers_per_employee",
            create_edit: "create_edit_working_houers",
            selector_branch: "get_branch_list",
        }

        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorBranches, this.props.user.companyCode, this.props.user.token, this.api.selector_branch);

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    initFilters = () => {
        let h = window.innerHeight;
        let numOfRowsByHeight = parseInt((h - topHeight > rowHeight) ? (h - topHeight) / rowHeight : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            //
            _FromDate: this.props.ui.data._FromDate ? this.props.ui.data._FromDate : null,
            _ToDate: this.props.ui.data._ToDate ? this.props.ui.data._ToDate : null,
            _Employee: this.props.ui.data._Employee ? this.props.ui.data._Employee : null,
            _inBranch: this.props.ui.data._inBranch ? this.props.ui.data._inBranch : 0,

        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);


        this.newLine = 0;

        // const { _FromDate, _ToDate, _Employee, _inBranch } = this.props.ui.data;
        // const dataSend = "_FromDate\f_ToDate\f_Employee\r" + (_FromDate ?? "") + "\f" + (_ToDate ?? "") + "\f" + (_Employee ?? "");

        // this.sendAPI("get_working_houers_per_employee_v2", dataSend, ob => {
        //     let whData = ob.data ? ob.data.split("\r").map(x => {
        //         let y = x.split("\f");
        //         return {
        //             id: y[0],
        //             pos: y[1],
        //             date: y[2],
        //             hour: y[3],
        //             inOrOut: y[4],
        //             reportType: y[5],
        //             remark: y[6],
        //             branch: y[7],
        //             posName: y[8]
        //         }
        //     }) : [];


        //     //mDate, mDay, mIsNew, mInId, mOutId, mTimeIn, mTimeOut, mReportType, mDelta, mRemarkUser, mInStatus, mOutStatus, mPos, mPosName, mBranch
        //     let dataset = [];
        //     // let datesList = [];
        //     let mDate = _FromDate;
        //     for (let x = 0; x <= 30; x++) {
        //         let tomorrow = moment(mDate, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD");
        //         if (mDate <= _ToDate) {
        //             let mDay = this.getText(11118 + moment(mDate, "YYYY-MM-DD").day());
        //             let rows = whData.filter(f => f.date == mDate)
        //             let rowsTomorrow = whData.filter(f => f.date == tomorrow);
        //             let templateRow = {
        //                 mDate, mDay,
        //                 mIsNew: null, mInId: null, mOutId: null,
        //                 mTimeIn: null, mTimeOut: null, mReportType: null, mDelta: null,
        //                 mRemarkUser: null, mInStatus: "unchanged", mOutStatus: "unchanged",
        //                 mPos: null, mPosName: null, mBranch: null, endShiftDate: null
        //             }

        //             let rowData = { ...templateRow };

        //             if (rows.length) {
        //                 rows.forEach(x => {

        //                     if (rowData.mTimeOut
        //                         || (rowData.mTimeIn && x.inOrOut == '1')
        //                         || (_inBranch == '1' && rowData.mBranch && rowData.mBranch != x.branch)
        //                     ) {
        //                         dataset.push(rowData);
        //                         rowData = { ...templateRow };
        //                     }



        //                     if (x.reportType == '6') {

        //                         if (x.inOrOut == '2') {
        //                             let endShift = rowsTomorrow[rowsTomorrow.findIndex(f => f.inOrOut == '1' && f.reportType == '6') + 1];
        //                             console.log('datesList-endShift', x, endShift, rowsTomorrow)
        //                             if (endShift) {
        //                                 rowData.mOutId = endShift.id;
        //                                 rowData.mTimeOut = endShift.hour;
        //                                 rowData.endShiftDate = endShift.date;
        //                                 rowData.mReportType = endShift.reportType;
        //                                 rowData.mDelta = this.calcDelta(rowData.mTimeIn, rowData.mTimeOut, true)
        //                                 rowData.mRemarkUser = endShift.remark;
        //                                 rowData.mPos = endShift.pos;
        //                                 rowData.mPosName = endShift.posName;
        //                                 rowData.mBranch = endShift.branch;
        //                             }
        //                         }

        //                     } else {

        //                         if (x.inOrOut == '1') {
        //                             rowData.mInId = x.id;
        //                             rowData.mTimeIn = x.hour;
        //                             rowData.mReportType = x.reportType;
        //                             rowData.mRemarkUser = x.remark;
        //                             rowData.mPos = x.pos;
        //                             rowData.mPosName = x.posName;
        //                             rowData.mBranch = x.branch;
        //                         } else {
        //                             rowData.mOutId = x.id;
        //                             rowData.mTimeOut = x.hour;
        //                             rowData.mReportType = x.reportType;
        //                             rowData.mDelta = this.calcDelta(rowData.mTimeIn, rowData.mTimeOut)
        //                             rowData.mRemarkUser = x.remark;
        //                             rowData.mPos = x.pos;
        //                             rowData.mPosName = x.posName;
        //                             rowData.mBranch = x.branch;
        //                         }

        //                     }

        //                 })
        //             }

        //             dataset.push(rowData);
        //             rowData = { ...templateRow };
        //         }

        //         mDate = tomorrow;
        //     }

        //     console.log('datesList-sum', dataset)



        //     this.props.dataActions.setJson(this.id, { dataset })

        // })
    }

    // calcDelta = (mTimeIn, mTimeOut, isEndShiftTomorrow) => {
    //     if (mTimeIn && mTimeOut) {
    //         let i = moment("1970-01-01 " + mTimeIn, "YYYY-MM-DD HH:mm:ss").unix();
    //         let o = moment("1970-01-01 " + mTimeOut, "YYYY-MM-DD HH:mm:ss").unix();
    //         let b = moment("1970-01-01 00:00:00", "YYYY-MM-DD HH:mm:ss").unix();
    //         return moment.unix(b + (o - i) + (isEndShiftTomorrow ? (24 * 60 * 60) : 0)).format("HH:mm:ss");
    //     }
    // }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }


    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            this.initFilters();
            this.setState({
                HoursReport: {
                    ...this.state.HoursReport,
                    EmployeeList: this.props.ui.data._Employee,
                    FromDate: this.props.ui.data._FromDate, // HH:mm:ss"),
                    ToDate: this.props.ui.data._ToDate, // HH:mm:ss"),
                    inBranch: this.props.ui.data._inBranch,
                }
            })
        }
        this.pinkNullBox();
    }

    pinkNullBox = () => {
        let inputTimes = [...document.getElementsByClassName("working-houer-time-box")];
        inputTimes.map((x) => {
            let y = [...x.getElementsByTagName("input")][0];
            if (!y.value) y.style.backgroundColor = 'pink';
            else y.style.backgroundColor = 'white';
        })
    }

    setRow = (record, newRecord) => {

        let newObj = {
            ...newRecord,
            mInStatus: this.getStatus(record, newRecord, "mTimeIn"),
            mOutStatus: this.getStatus(record, newRecord, "mTimeOut"),
        }

        if (record) {
            this.props.dataActions.dataTableSetRow(this.id, record.index, { ...record, ...newObj }, true);
        } else {
            this.props.dataActions.dataTableAddRow(this.id, newObj, false, true);
        }
    }

    getStatus = (record, newRecord, timeField) => {
        let nr = { ...record, ...newRecord }
        let isExist = record && record[timeField === "mTimeIn" ? "mInId" : "mOutId"];
        if (!isExist && nr[timeField]) {
            console.log("getStatus-edit-1", { esd: nr.mEndShiftDate, timeField }, nr.mEndShiftDate && timeField !== "mTimeIn" ? "insertlong" : "insert")
            return nr.mEndShiftDate && timeField !== "mTimeIn" ? "insertlong" : "insert";
            // return "insert";
        } else if (isExist && !nr[timeField]) {
            console.log("getStatus-edit-2", { record, newRecord, timeField }, "delete")
            return "delete";
        } else if (isExist && nr[timeField] && (
            record[timeField] !== nr[timeField] ||
            record['mRemarkUser'] !== nr['mRemarkUser'] ||
            record['mBranch'] !== nr['mBranch']
        )) {
            console.log("getStatus-edit-3", { record, newRecord, timeField }, "update")
            return "update";
        } else if (!isExist && !nr[timeField]) {
            console.log("getStatus-edit-4", { record, newRecord, timeField }, "unchanged")
            return "unchanged";
        } else {
            console.log("getStatus-edit-5", { record, newRecord, timeField }, record ? record[timeField === "mTimeIn" ? "mInStatus" : "mOutStatus"] : "unchanged")
            return record ? record[timeField === "mTimeIn" ? "mInStatus" : "mOutStatus"] : "unchanged";
        }
    }

    createPopConfirem = (q, on, con) => {
        return (<Popconfirm title={q} onConfirm={on} okText={this.getText(11129)} cancelText={this.getText(11130)}>{con}</Popconfirm>)
    }

    generateTimePicker = (record, field) => {
        let time = record[field];
        let calck = (time) => {
            let dt = time.split(':');
            return parseInt(dt[0] * 60 * 60) + parseInt(dt[1] * 60) // + parseInt(dt[2])
        }
        let unCalck = (num) => {
            let ss = num % 60;
            let mm = ((num % 3600) - ss) / 60;
            let hh = Math.floor(num / 3600);

            let resHH = hh ? String(hh).length === 1 ? "0" + hh : hh : "00"
            let resMM = mm ? String(mm).length === 1 ? "0" + mm : mm : "00"
            let resSS = ss ? String(ss).length === 1 ? "0" + ss : ss : "00"
            return resHH + ":" + resMM + ":" + resSS
        }
        let setRow = (rUpdate) => {
            this.setRow(record, {
                ...rUpdate,
                mReportType: 4,
                mPos: record.mPos ? record.mPos : 999,
                mPosName: record.mPosName ? record.mPosName : this.getText(14182)
            });

        }
        let updateIn = (e) => {
            let eFormat = e ? e.format(timeFormat) : "";
            let newDelta = (p24) => {
                let delta = e ? field === "mTimeIn" ?
                    calck(record.mTimeOut) - calck(eFormat) :
                    calck(eFormat) - calck(record.mTimeIn) : 0;
                if (delta && (p24 || record.mEndShiftDate)) delta += (24 * 60 * 60)
                if (delta > 0) return unCalck(delta)
            }

            if (field === "mTimeOut" && !record.mTimeIn) {
                message.error(this.getText(11133))
            } else if (field === "mTimeIn" && !e) {
                this.deleteRow(record)
            } else if (e && field === "mTimeOut" && (calck(eFormat) - calck(record.mTimeIn)) < 0 && !record.mEndShiftDate) {
                // const { data, headers } = this.props.data[this.id];
                // let nextIndex = record.index + 1;
                // if (data[nextIndex]) {
                confirm({
                    title: this.getText(17418),
                    content: this.getText(17419),
                    iconType: 'exclamation-circle',
                    okText: this.getText(17420),
                    cancelText: this.getText(17421),
                    onOk: () => {
                        setRow({
                            mTimeOut: e.format(timeFormat),
                            mDelta: newDelta(true),
                            mEndShiftDate: moment(record.mDate, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD")
                        });
                        // setRow({
                        //     mTimeOut: '23:59:59',// unCalck(calck(record.mTimeIn) + 60),
                        //     mDelta: unCalck(calck('23:59:59') - calck(record.mTimeIn)),
                        // });

                        // let nextData = data[nextIndex].split("\f");
                        // let nextObj = {}
                        // headers.forEach((x, i) => { nextObj = { ...nextObj, [x]: nextData[i] } });

                        // let newRecord = {
                        //     ...nextObj,
                        //     mTimeIn: '00:00:00',
                        //     mTimeOut: e.format(timeFormat),
                        //     mDelta: unCalck(calck(e.format(timeFormat)) - calck('00:00:00')),
                        //     mReportType: 6,
                        //     mPos: nextObj.mPos ? nextObj.mPos : 999,
                        //     mPosName: nextObj.mPosName ? nextObj.mPosName : this.getText(14182),
                        // }

                        // this.props.dataActions.dataTableSetRow(
                        //     this.id, nextIndex, {
                        //     ...newRecord,
                        //     mInStatus: this.getStatus(nextObj, newRecord, "mTimeIn"),
                        //     mOutStatus: this.getStatus(nextObj, newRecord, "mTimeOut"),
                        // }, true);
                    },
                    onCancel: () => { },
                });
                // } else {
                //     setRow({
                //         mTimeOut: unCalck(calck(record.mTimeIn) + 60),
                //         mDelta: unCalck(60),
                //     });
                //     message.error(this.getText(11134))
                // }
            } else if (e && field === "mTimeIn" && (calck(record.mTimeOut) - calck(eFormat)) < 0 && !record.mEndShiftDate) {
                setRow({
                    mTimeIn: unCalck(calck(record.mTimeOut) - 60),
                    mDelta: unCalck(60),
                });
                message.error(this.getText(11134))
            } else {
                setRow({
                    [field]: e ? e.format(timeFormat) : "",
                    mDelta: newDelta(),
                });
            }
            this.pinkNullBox();
        }

        let nowPicker = (<button style={{ width: '100%' }} type="primary" onClick={() => updateIn(moment(Date.now()), timeFormat)} >{this.getText(11135)}</button>)

        return (<TimePicker
            style={{ width: '100%' }}
            className={"working-houer-time-box"}
            value={!time ? null : moment(time, timeFormat)}
            defaultOpenValue={moment("00:00:00", timeFormat)}
            addon={() => nowPicker}
            format={timeFormat} onChange={updateIn} />)
    }

    handleOk = () => {
        let data = this.dataObject();
        let mEmployee = this.props.ui.data._Employee;
        let errorBranch = false;
        // let mFromDate = this.props.ui.data._FromDate;
        // let mToDate = this.props.ui.data._ToDate;

        // let deleteSend = 'mEmployee\fmFromDate\fmToDate\r' + mEmployee + '\f' + mFromDate + '\f' + mToDate
        let dataSend = 'mEmployee\fmDate\fmTime\fmInOut\fmType\fmRemarkUser\fmStatus\fmID\fmBranch\fmPos\r';
        let timeFormat = (t) => t ? t + (t.length < 8 ? ':00' : '') : ''
        data.forEach((x) => {
            if ((x.mInStatus !== "unchanged" || x.mOutStatus !== "unchanged") && !x.mBranch) {
                errorBranch = true;
            }
            if (x.mInStatus !== "unchanged") {
                dataSend += mEmployee + '\f' + x.mDate + '\f' + timeFormat(x.mTimeIn) + '\f1\f' +
                    x.mReportType + '\f' + x.mRemarkUser + '\f' + x.mInStatus + '\f' + x.mInId + '\f' +
                    x.mBranch + '\f' + x.mPos + '\r';
            }
            if (x.mOutStatus == "insertlong") {
                dataSend += mEmployee + '\f' + x.mDate + '\f23:59:59\f2\f6\f\finsert\f\f' + x.mBranch + '\f' + x.mPos + '\r';
                dataSend += mEmployee + '\f' + x.mEndShiftDate + '\f00:00:00\f1\f6\f\finsert\f\f' + x.mBranch + '\f' + x.mPos + '\r';
                dataSend += mEmployee + '\f' + x.mEndShiftDate + '\f' + timeFormat(x.mTimeOut) + '\f2\f' + x.mReportType + '\f' + x.mRemarkUser + '\finsert\f\f' + x.mBranch + '\f' + x.mPos + '\r';
            } else if (x.mOutStatus !== "unchanged") {
                dataSend += mEmployee + '\f' + x.mDate + '\f' + timeFormat(x.mTimeOut) + '\f2\f' +
                    x.mReportType + '\f' + x.mRemarkUser + '\f' + x.mOutStatus + '\f' + x.mOutId + '\f' +
                    x.mBranch + '\f' + x.mPos + '\r';
            }
            if ((x.mInStatus == "delete" || x.mOutStatus == "delete") && x.mEndShiftDate) {
                console.log("delete run...", x)
                if (x.mEndShiftId1) dataSend += mEmployee + '\f' + x.mDate + '\f23:59:59\f2\f6\f\fdelete\f' + x.mEndShiftId1 + '\f' + x.mBranch + '\f' + x.mPos + '\r';
                if (x.mEndShiftId2) dataSend += mEmployee + '\f' + x.mEndShiftDate + '\f00:00:00\f1\f6\f\fdelete\f' + x.mEndShiftId2 + '\f' + x.mBranch + '\f' + x.mPos + '\r';
            }
        });
        console.log("getStatus", data, dataSend.split("\r").map(x => x.split("\f")))
        if (!errorBranch) {
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, this.api.create_edit, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            // job.setSection("D_DELETE", deleteSend);
            // job.setSection("_DATA_EDIT", dataSend);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab", () => {
                this.initFilters();
                message.success(this.getText(15215))
            }, (e) => console.error(e));
        } else {
            message.error(this.getText(15214))
        }
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalCreateBOHoursReport);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    printReport = () => {
        this.handleOk();
        this.generateReport.apply(this, ["HoursReport"]);
    }

    dataObject = () => {
        let d = this.props.data[this.id];
        d = d && d.data ? d.data.sort().map((x, index) => {
            let y = x.split('\f');
            // if (y[0].slice(-1) === "n") y[0] = y[0].slice(0, -1)
            return ({
                key: y[0] + "#" + index,
                mDate: y[0],
                mDay: y[1],
                mIsNew: y[2],
                mInId: y[3],
                mOutId: y[4],
                mTimeIn: y[5],
                mTimeOut: y[6],
                mReportType: y[7],
                mDelta: y[8],
                mRemarkUser: y[9],
                mInStatus: y[10],
                mOutStatus: y[11],
                mPos: y[12],
                mPosName: y[13],
                mBranch: y[14],
                mEndShiftDate: y[15],
                mEndShiftId1: y[16],
                mEndShiftId2: y[17],
                index: index
            })
        }) : null;

        return d
    }

    deleteRow = (record) => {
        this.setRow(record, {
            mTimeIn: '',
            mTimeOut: '',
            mReportType: '0',
            mDelta: '',
            mRemarkUser: '',
            mPos: '0',
            mPosName: '',
            mBranch: '0'
        })
    }

    addRow = (record) => {
        this.newLine++;

        this.setRow(null, {
            mDate: record.mDate,
            mDay: record.mDay,
            mIsNew: this.newLine,
            mInId: 0,
            mOutId: 0,
            mTimeIn: '',
            mTimeOut: '',
            mReportType: '',
            mDelta: '',
            mInStatus: 'unchanged',
            mOutStatus: 'unchanged',
            mRemarkUser: '',
            mPos: '999',
            mPosName: '',
            mBranch: record.mBranch
        });
    }

    render() {
        const { loading } = this.state;
        let lastDate = null;
        let dataSource = this.dataObject()
        dataSource = dataSource && dataSource.length ? dataSource.filter(row => {
            let isNewDay = lastDate != row.mDate;
            lastDate = row.mDate;
            return isNewDay || row.mInStatus != 'delete' || row.mOutStatus != 'delete'
        }) : []

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        let modalTitle = this.getText(11107) + ": " + (this.props.ui.data ?
            this.props.ui.data._Employee + " - " + this.props.ui.data._EmployeeName :
            "");

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={modalTitle}
                    actionButtons={[{
                        buttonType: 'print',
                        buttonFunction: this.printReport
                    }, {
                        buttonType: 'refresh',
                        buttonFunction: this.initFilters
                    }]}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        let columns = [
            {
                title: this.getText(11111),
                dataIndex: "mDate",
                key: "mDate",
                width: '9%',
                render: (text, record) => text
            }, {
                title: this.getText(11112),
                dataIndex: "mDay",
                key: "mDay",
                width: '6%',
                render: (text, record) => {
                    switch (text) {
                        case '1': return this.getText(11118);
                        case '2': return this.getText(11119);
                        case '3': return this.getText(11120);
                        case '4': return this.getText(11121);
                        case '5': return this.getText(11122);
                        case '6': return this.getText(11123);
                        case '7': return this.getText(11124);
                    }
                }
            }, {
                title: this.getText(11113),
                dataIndex: "mTimeIn",
                key: "mTimeIn",
                width: '8%',
                render: (text, record) => this.generateTimePicker(record, "mTimeIn")
            }, {
                title: this.getText(11114),
                dataIndex: "mTimeOut",
                key: "mTimeOut",
                width: '8%',
                render: (text, record) => {
                    return (<span>
                        {this.generateTimePicker(record, "mTimeOut")}
                        {record.mEndShiftDate ?? ""}
                    </span>)
                }
            }, {
                title: this.getText(11116),
                dataIndex: "mDelta",
                key: "mDelta",
                width: '8%',
                render: (text, record) => text.split(":").slice(0, -1).join(":")
            }, {
                title: this.getText(11115),
                dataIndex: "mReportType",
                key: "mReportType",
                width: '12%',
                render: (text, record) => {
                    switch (text) {
                        case '1': return this.getText(12698);
                        case '2': return this.getText(12699);
                        case '3': return this.getText(12702);
                        case '4': return this.getText(12701);
                        case '5': return this.getText(12700);
                        case '6': return this.getText(12703);
                    }
                }
            }, {
                title: this.getText(14045),
                dataIndex: "mRemarkUser",
                key: "mRemarkUser",
                width: '25%',
                render: (text, record) => {
                    if (record.mTimeIn || record.mTimeOut) {
                        return (<Input value={text} onChange={(e) => {
                            this.setRow(record, { mRemarkUser: e.target.value });
                        }} />)
                    } else {
                        return '';
                    }
                }
            }, {
                title: this.getText(14180),
                dataIndex: "mPos",
                key: "mPos",
                width: '6%',
                render: (text, record) => text && text !== '0' ? (<Tooltip title={record.mPosName}>{text}</Tooltip>) : ""
            }, {
                title: this.getText(14181),
                dataIndex: "mBranch",
                key: "mBranch",
                width: '12%',
                render: (text, record) => {
                    if (record.mTimeIn || record.mTimeOut) {
                        return (< Select key={"mBranch"} style={{ width: "100%" }} showSearch filterOption={true} optionFilterProp="valuefilter"
                            onChange={(value) => this.setRow(record, { mBranch: value })} value={text}>
                            {this.props.data[TableIDs.genericSelectorBranches] ? this.props.data[TableIDs.genericSelectorBranches].dataset.map((obj) => {
                                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
                            }) : []}
                        </Select>)
                    } else {
                        return "";
                    }
                }
            }, {
                title: this.getText(11117),
                width: '6%',
                render: (text, record) => {
                    return (<span>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"plus"} onClick={() => {
                            if (record.mTimeIn !== '' && record.mTimeOut !== '') { this.addRow(record) }
                        }} />
                        {this.createPopConfirem(this.getText(11128), () => {
                            this.deleteRow(record)
                        }, (
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />))}
                    </span >);
                }
            }
        ];

        // let colStyle = window.innerWidth > 600 ? 3 : 24;
        return (<Modal
            style={modalStyle}
            destroyOnClose={true}
            width={'95%'}
            visible={false || this.props.toggle}
            title={titleBar}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
                <span style={{ float: 'right' }}>
                    {this.props.data[this.id] && this.props.data[this.id].count ? this.props.data[this.id].count : 0}
                    {" " + this.getText(11131)}
                </span>,
                <Button key="back" onClick={this.handleCancel}>{this.getText(11108)}</Button>,
                this.createPopConfirem(this.getText(11132), this.initFilters, (<Button key="clear">{this.getText(11109)}</Button>)),
                <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(11110)}</Button>,
            ]}
        >
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                tableOnly={true}
                dataSource={dataSource}
                columns={columns}
                percentageToMobile={[40, 30, false, false, false, 30]}
                pagination={{ pageSize: 40 }}
                scroll={{ y: window.innerHeight / 2 }}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
        </Modal>)
    }

}
export default ModalWorkingHouersPerEmployee;
/* @flow */
/*jshint esversion: 6 */
import React, {Component} from "react";

import "../../App.css";
import {Form, Button, Input, InputNumber, Switch, Select} from "antd";
import PageToolbar from "../../components/PageToolbar";

const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {};

class UsingPoints extends Component<Props, State> {

    refreshData () {
        this.props.dataActions.getUsingPoints(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getItemTags(this.props.user.companyCode, this.props.user.token);
    }

    componentDidMount() {
        document.title = "Cash On Tab - שימוש בנקודות";
        this.refreshData();
    }

    handleSaveChanges(e: Event) {
        e.preventDefault();
        this.props.dataActions.saveUsingPoints(this.props.user.companyCode, this.props.user.token, this.props.data.usingPoints);
    }

    handleValidDaysChanged(value: number) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {validDays: value}));
    }

    handleDaysNotToUseChanged(value: number) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {daysNotToUse : value}));
    }

    handleMaxDiscountChanged(value: number) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {maxDiscount : value}));
    }

    handleMinPointsForUseChanged(value: number) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {minPointsForUse : value}));
    }

    handleCanUseDecimalPointsChanged(value: boolean) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {canUseDecimalPoints : value ? 1 : 0}));
    }

    handleUsageItemCodeChanged(e: any) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {usageItemCode : e.target.value}));
    }

    handleUsageItemNameChanged(e: any) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {usageItemName : e.target.value}));
    }

    handleMaxUsageChanged(value: number) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {maxUsage : value}));
    }

    handlePointValueChanged(value: number) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {pointValue : value}));
    }


    handleNotAllowedTagsChanged(value: Array<string>) {
        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, {notAllowedTags : value.join(",")}));
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

        };

        const toolbarActions: Array<Object> = [

            {
                buttonType: "refresh",
                buttonFunction: this.refreshData.bind(this)
            }
        ];

        let tagList = this.props.data.itemTags.map(
            (node: Object) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object) => {
                            return (<Option key={item.id}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        );

        /*
        <h1>שימוש בנקודות</h1>
         */

        return (
            <div style={divStyle}>
                <PageToolbar title={"שימוש בנקודות"} actionButtons={toolbarActions} isOfModal={false}/>

                <Form className="data-entry-form" layout="vertical" onSubmit={this.handleSaveChanges.bind(this)}>
                    <FormItem
                        label="תוקף נקודות"
                        help="תוקף הנקודות בימים."
                        labelCol={{span: 7}}
                        wrapperCol={{span: 12}}
                    >
                        <InputNumber
                            min={0}
                            onChange={this.handleValidDaysChanged.bind(this)}
                            value={this.props.data.usingPoints.validDays ? this.props.data.usingPoints.validDays : ""}
                        />
                    </FormItem>

                    <FormItem
                        label="ימים לפני שימוש"
                        help="מספר ימים לפני שיהיה ניתן להשתמש בנקודות שזה עתה נצברו. רשום אפס עבור שימוש מיידי."
                        labelCol={{span: 7}}
                        wrapperCol={{span: 12}}
                    >
                        <InputNumber
                            min={0}
                            onChange={this.handleDaysNotToUseChanged.bind(this)}
                            value={this.props.data.usingPoints.daysNotToUse ? this.props.data.usingPoints.daysNotToUse : ""}
                        />
                    </FormItem>

                    <FormItem
                        label="הנחה מרבית לשימוש"
                        help="ההנחה המרבית שעדיין ניתן להשתמש בנקודות כאמצעי תשלום"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 12}}
                    >
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={this.handleMaxDiscountChanged.bind(this)}
                            value={this.props.data.usingPoints.maxDiscount ? this.props.data.usingPoints.maxDiscount : ""}
                        />
                    </FormItem>

                    <FormItem
                        label="כמות מינימלית לשימוש"
                        help="הכמות המינימלית של נקודות לשימוש ."
                        labelCol={{span: 7}}
                        wrapperCol={{span: 12}}
                    >
                        <InputNumber
                            min={0}
                            onChange={this.handleMinPointsForUseChanged.bind(this)}
                            value={this.props.data.usingPoints.minPointsForUse ? this.props.data.usingPoints.minPointsForUse : ""}
                        />
                    </FormItem>

                    <FormItem
                        label="אפשר שימוש בחלקי נקודות"
                        help="אפשר שימוש בחלקי נקודות או בנקודות שלנות בלבד."
                        labelCol={{span: 7}}
                        wrapperCol={{span: 12}}
                    >
                        <Switch
                            onChange={this.handleCanUseDecimalPointsChanged.bind(this)}
                            checked={parseInt(this.props.data.usingPoints.canUseDecimalPoints, 10) === 1}
                        />
                    </FormItem>

                    <FormItem
                        label="קוד פריט שימוש"
                        help="קוד הפריט המסמן שימוש בנקודות"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 12}}
                    >
                        <Input
                            onChange={this.handleUsageItemCodeChanged.bind(this)}
                            value={this.props.data.usingPoints.usageItemCode ? this.props.data.usingPoints.usageItemCode : ""}
                        />
                    </FormItem>

                    <FormItem
                    label="שם פריט שימוש"
                    help="שם פריט המסמן שימוש בנקודות כפי שיופיע במסמך המכירה"
                    labelCol={{span: 7}}
                    wrapperCol={{span: 12}}
                >
                    <Input
                        min={0}
                        onChange={this.handleUsageItemNameChanged.bind(this)}
                        value={this.props.data.usingPoints.usageItemName ? this.props.data.usingPoints.usageItemName : ""}
                    />
                </FormItem>

                    <FormItem
                        label="אחוז מרבי מסך המכירה"
                        help="אחוז שימוש המרבי בנקודות מסך כל המכירה הנוכחית"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 12}}
                    >
                        <InputNumber
                            min={0}
                            onChange={this.handleMaxUsageChanged.bind(this)}
                            value={this.props.data.usingPoints.maxUsage ? this.props.data.usingPoints.maxUsage : ""}
                        />
                    </FormItem>

                    <FormItem
                        label="ערך נקודות"
                        help="ערך נקודה בשקלים חדשים"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 12}}
                    >
                        <InputNumber
                            min={0}
                            onChange={this.handlePointValueChanged.bind(this)}
                            value={this.props.data.usingPoints.pointValue ? this.props.data.usingPoints.pointValue : ""}
                        />
                    </FormItem>

                    <FormItem
                        label="תגיות לאי שימוש"
                        help="תגיות פריטים שעליהם לא ניתן לשלם בנקודות"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 12}}
                    >
                        <Select
                            mode="multiple"
                            onChange={this.handleNotAllowedTagsChanged.bind(this)}
                            value={this.props.data.usingPoints.notAllowedTags ? this.props.data.usingPoints.notAllowedTags.split(",") : []}
                        >
                            {tagList}
                        </Select>
                    </FormItem>

                    <Button type="primary" htmlType="submit" loading={this.props.ui.workInProgress} className={"form-button-save"} icon={"check"}>
                        שמור שינויים
                    </Button>
                </Form>
            </div>
        );
    }
}

export default UsingPoints;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import { Checkbox, Form, Row, Col, DatePicker, Input, Button, message, Tooltip } from "antd";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import { object } from "prop-types";
import TableLineIcons from "../../components/TableLineIcons";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import ResponsiveTable from "../../components/ResponsiveTable";
import moment from "moment";


class PosMessages extends Component {
    constructor(props: Props) {
        super(props);

        this.newMessage = {
            mOriginPosId: null,
            mTargetPosId: null,
            mDisplayStartTime: null,
            mDisplayEndTime: null,
            mText: '',
            mIsSticky: '0',
            mIsNotPush: '0',
            mIsReadingRecord: '0',
        }

        this.state = {
            modalNewMessage: false,
            request: this.newMessage,
            loading: false,
            modalReadsView: null,
        };

        this.id = "POS_MESSAGES_TABLE";

        this.api = {
            get: "get_pos_messages_table",
        }

        // this.selectors = [
        //     { id: TableIDs.genericSelectorPos, api: "list_pos" },
        // ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        if (props.data.POS_STORAGE_LIST === undefined) props.data.POS_STORAGE_LIST = { ...props.data.genericSelector };


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps, prevState) {
        document.title = "Cash On Tab - " + this.getText(17435);
        if ((!prevProps.visibleFromCoto && this.props.visibleFromCoto) || (!prevState.modalNewMessage && this.state.modalNewMessage)) {
            this.sendAPI("get_default_user_pos", "", (ob) => { this.setState({ request: { ...this.state.request, mOriginPosId: ob.data } }) })
        }
    }

    handleSend = () => {
        const {
            mOriginPosId,
            mTargetPosId,
            mDisplayStartTime,
            mDisplayEndTime,
            mText
        } = this.state.request;

        if (!mOriginPosId || !mTargetPosId || !mDisplayStartTime || !mDisplayEndTime || !mText) {
            message.error(this.getText(17505))
        } else {
            this.setState({ loading: true }, () => {
                this.addTalkBackTask(0)
            })
        }
    }

    addTalkBackTask_v0 = (index) => {
        let req = { ...this.state.request };
        req = { ...req, mText: req.mText.split("\n").join(" ") };
        let mTargetPosId = req.mTargetPosId.split(",")[index];

        if (mTargetPosId) {

            let payload = {
                workStatus: "0",
                documentNumber: "",
                startDate: req.mDisplayStartTime,
                endDate: req.mDisplayEndTime,
                sendLogs: 0,
                interval: "",
                directMessage: req.mText,
                //generalMessage: req.mText,
            }

            let payloadKeys = Object.keys(payload);

            let dataSend = payloadKeys.join("\f") +
                "\fcompany\ftaskAction\fposId\foriginPosId\fisSticky\fisNotPush\fisReadingRecord\fpayload\r" +
                payloadKeys.map(x => payload[x]).join("\f") +
                "\f" + this.props.user.companyCode +
                "\f" + "direct_message" +
                //"\f" + "general_message" +  
                "\f" + mTargetPosId +
                "\f" + req.mOriginPosId +
                "\f" + req.mIsSticky +
                "\f" + req.mIsNotPush +
                "\f" + req.mIsReadingRecord +
                "\f" + JSON.stringify(payload);

            this.sendAPI("add_talk_back_task", dataSend, () => { this.addTalkBackTask(index + 1) })

        } else {
            this.setState({ modalNewMessage: false }, () => {
                message.success(this.getText(17506))
                if (this.props.onCancelFromCoto) {
                    this.props.onCancelFromCoto()
                } else {
                    this.props.dataActions.genericDataGetRequested(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
                }
            })
        }
    }

    addTalkBackTask = (index) => {
        let req = { ...this.state.request };
        req = { ...req, mText: req.mText.split("\n").join(" ") };
        let mTargetPosId = req.mTargetPosId.split(",")[index];

        if (mTargetPosId) {

            let payload = {
                workStatus: "0",
                //documentNumber: "",
                startDate: req.mDisplayStartTime,
                endDate: req.mDisplayEndTime,
                //sendLogs: 0,
                //interval: "",
                directMessage: req.mText,
                //generalMessage: req.mText,
                IsSticky: req.mIsSticky == '1' ? 1 : 0,
                IsNotPush: req.mIsNotPush,
                IsReadingRecord: req.mIsReadingRecord
            }

            let payloadKeys = Object.keys(payload);

            let dataSend = payloadKeys.join("\f") +
                "\fcompany\ftaskAction\fposId\foriginPosId\fisSticky\fisNotPush\fisReadingRecord\fpayload\r" +
                payloadKeys.map(x => payload[x]).join("\f") +
                "\f" + this.props.user.companyCode +
                //"\f" + "direct_message" + 
                "\f" + "general_message" +
                "\f" + mTargetPosId +
                "\f" + req.mOriginPosId +
                "\f" + req.mIsSticky +
                "\f" + req.mIsNotPush +
                "\f" + req.mIsReadingRecord +
                "\f" + JSON.stringify(payload);

            this.sendAPI("add_talk_back_task", dataSend, () => { this.addTalkBackTask(index + 1) })

        } else {
            this.setState({ modalNewMessage: false, loading: false }, () => {
                message.success(this.getText(17506))
                if (this.props.onCancelFromCoto) {
                    this.props.onCancelFromCoto()
                } else {
                    this.props.dataActions.genericDataGetRequested(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
                }
            })
        }
    }


    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    editButtons = (t, record) => {
        const displayEndTime = new Date(record.mDisplayEndTime).getTime()
        const nowTime = new Date().getTime()

        let genericIcons = [
            {
                type: "copy",
                onClick: () => {
                    const { mOriginPosId, mTargetPosId, mText, mDisplayStartTime, mIsSticky, mIsNotPush, mIsReadingRecord } = record;
                    const request = { mOriginPosId, mTargetPosId, mDisplayStartTime, mDisplayEndTime: null, mText, mIsSticky, mIsNotPush, mIsReadingRecord };
                    this.setState({ modalNewMessage: true, request })
                },
                tooltip: this.getText(19118)
            }
        ];

        if (displayEndTime > nowTime) {
            genericIcons.push({
                type: "close-circle",
                onClick: () => {
                    this.sendAPI("cancel_message", "mId\r" + record.key, () => {
                        this.props.dataActions.genericDataGetRequested(
                            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
                    })
                },
                tooltip: this.getText(19126)
            })
        }

        if (record.mReads && record.mReads.indexOf("{") > -1) {
            const modalReadsView = JSON.parse('[' + record.mReads + ']');
            console.log("getIcon", modalReadsView)

            genericIcons.push({
                type: "eye",
                onClick: () => { this.setState({ modalReadsView }) },
                tooltip: this.getText(19506),
                color: modalReadsView.find(f => f.isAdmin == 1) ? "green" : null
            })

            genericIcons.push(modalReadsView.length)
        }

        return (<TableLineIcons {...{ genericIcons }} />)
    }

    render() {
        let gt = this.getText;
        const sorter = true;
        const render = text => text;
        const renderCB = text => (<Checkbox disabled checked={text == '1'} />);

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const columns = [
            { title: gt(17437), key: "mId", dataIndex: "mId", width: "6%", sorter, render },
            { title: gt(17438), key: "mOriginPosId", dataIndex: "mOriginPosId", width: "6%", sorter, render },
            { title: gt(17439), key: "mCreationTime", dataIndex: "mCreationTime", width: "10%", sorter, render },
            { title: gt(17440), key: "mTargetPosId", dataIndex: "mTargetPosId", width: "10%", sorter, render },
            { title: gt(17441), key: "mDisplayStartTime", dataIndex: "mDisplayStartTime", width: "10%", sorter, render },
            { title: gt(17442), key: "mDisplayEndTime", dataIndex: "mDisplayEndTime", width: "10%", sorter, render },
            { title: gt(17443), key: "mIsSticky", dataIndex: "mIsSticky", width: "6%", sorter, render: renderCB },
            { title: gt(17444), key: "mText", dataIndex: "mText", width: "35%", sorter, render },
            { title: "", width: "7%", render: this.editButtons },
        ];

        const rValue = (field) => this.state.request[field];
        const rOnChange = (value) => { this.setState({ request: { ...this.state.request, ...value } }) }

        let targetFilterList = this.props.data.POS_STORAGE_LIST?.dataset.map(x => x.code).filter(f => f != this.state.request.mOriginPosId) ?? []
        let colProps = { span: 24 }
        if (window.innerWidth > 600) colProps = { span: 8, style: { paddingLeft: 20 } }

        const modalSendPosMessage = (visible, onCancel) => {
            return (<GenericModal
                {...{ visible, onCancel }} title={gt(17500)}
                footer={[<Button type="primary" size="large" onClick={this.handleSend} loading={this.state.loading}>{gt(17504)}</Button>]}>
                <Row>
                    <Col {...colProps}>
                        <Form.Item label={gt(17438)} required validateStatus={rValue('mOriginPosId') ? 'success' : 'error'}>
                            <GenericSelectorPosStorages {...this.props} //{...this.selectors[0]}
                                value={rValue('mOriginPosId')}
                                onChange={e => { rOnChange({ mOriginPosId: e }) }} />
                        </Form.Item>
                        <Form.Item label={gt(17440)} required validateStatus={rValue('mTargetPosId') ? 'success' : 'error'}>
                            <GenericSelectorPosStorages {...this.props} //{...this.selectors[0]}
                                multi
                                filterList={targetFilterList}
                                value={rValue('mTargetPosId') ? rValue('mTargetPosId').split(',') : []}
                                onChange={e => { rOnChange({ mTargetPosId: e ? e.join(",") : '' }) }} />
                        </Form.Item>

                        <Checkbox checked={rValue('mIsSticky') == '1'} onChange={e => { rOnChange({ mIsSticky: e.target.checked ? '1' : '0' }) }}>{gt(17501)}</Checkbox>
                        <br />
                        <Checkbox checked={rValue('mIsNotPush') == '1'} onChange={e => { rOnChange({ mIsNotPush: e.target.checked ? '1' : '0' }) }}>{gt(17502)}</Checkbox>
                        <br />
                        <Checkbox checked={rValue('mIsReadingRecord') == '1'} onChange={e => { rOnChange({ mIsReadingRecord: e.target.checked ? '1' : '0' }) }}>{gt(17503)}</Checkbox>
                    </Col>
                    <Col {...colProps}>
                        <Form.Item label={gt(17441)} required validateStatus={rValue('mDisplayStartTime') ? 'success' : 'error'}>
                            <DateTimePicker
                                format="DD/MM/YYYY HH:mm:ss" showTime
                                maxDate={rValue('mDisplayEndTime')}
                                value={rValue('mDisplayStartTime')}
                                onChange={e => { rOnChange({ mDisplayStartTime: e }) }} />
                        </Form.Item>
                        <Form.Item label={gt(17442)} required validateStatus={rValue('mDisplayEndTime') ? 'success' : 'error'}>
                            <DateTimePicker
                                format="DD/MM/YYYY HH:mm:ss" showTime
                                minDate={rValue('mDisplayStartTime')}
                                value={rValue('mDisplayEndTime')}
                                onChange={e => { rOnChange({ mDisplayEndTime: e }) }} />
                        </Form.Item>
                    </Col>
                    <Col {...colProps}>
                        <Form.Item label={gt(17444)} required validateStatus={rValue('mText') ? 'success' : 'error'}>
                            <Input.TextArea rows={10} value={rValue('mText')} onChange={e => { rOnChange({ mText: e.target.value }) }} />
                        </Form.Item>
                    </Col>
                </Row>
            </GenericModal>)
        }

        if (this.props.isFromCoto) {
            return modalSendPosMessage(this.props.visibleFromCoto, this.props.onCancelFromCoto)
        } else {
            return (<div style={divStyle}>
                <GenericTable
                    {...this.props}
                    id={this.id}
                    getApi={this.api.get}
                    filters={{ _isAsc: 0 }}
                    rowHeight={45}
                    columns={columns}
                    title={gt(17436)}
                    searchBox
                    addButton={() => { this.setState({ modalNewMessage: true, request: this.newMessage }) }}
                />
                {modalSendPosMessage(this.state.modalNewMessage, () => { this.setState({ modalNewMessage: false }) })}
                <GenericModal
                    visible={this.state.modalReadsView}
                    onCancel={() => { this.setState({ modalReadsView: null }) }}
                    title={gt(19506)}
                    width={700}
                >
                    <ResponsiveTable
                        tableOnly
                        dataSource={this.state.modalReadsView ?? []}
                        columns={[
                            { title: gt(19507), width: '40%', key: "employee", dataIndex: "employee" },
                            { title: gt(19631), width: '30%', key: "groupName", dataIndex: "groupName" },
                            { title: gt(19508), width: '30%', key: "time", dataIndex: "time" },
                        ]}
                        pagination={{ pageSize: 10 }}
                    />
                </GenericModal>
            </div>)
        }
    }
}

export default PosMessages;
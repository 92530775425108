/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { Pagination } from 'antd';

// shoot me

type Props = {
  title: string
};
type State = {};

class Paging extends React.Component<Props, State> {
  defaultPageSize: number;
  total: number;

  /*constructor(props) {
    super(props);
  }*/
  // ReactDOM.render(<Paginiation defaultCurrent={6} total={500} />, mountNode);
  render() {
    return (
      <div>
        <h2>{this.props.title}</h2>
        <div dir="ltr" align="center">
          <Pagination showQuickJumper showSizeChanger defaultCurrent={1} total={200} onChange={this.onChangePage}/>
        </div>
      </div>
    );
  }

  onChangePage = () => {
    this.setRowsPerPage(5);
  }

  setRowsPerPage = (rows_per_page: number) => {
    this.defaultPageSize = rows_per_page;
  }

  setRowsTotal = (rows_total: number) => {
    this.total = rows_total;
  }
}

export default Paging;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
//import { Table } from "antd";

import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";

const topHeight = 340; // px
const rowHeight = 50;  // px


type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};

type State = {};

class MessageUsing extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.mainId = "MESSAGE_USING_MAIN";
        this.historyId = "MESSAGE_PURCHASE_HISTORY";
        // calc page height
        this.state = {};
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.mainId] === undefined) {
            props.data[this.mainId] = { ...props.data.genericData };
        }

        if (props.data[this.historyId] === undefined) {
            props.data[this.historyId] = { ...props.data.genericData };
        }

        this.api = {
            get: "get_message_summeraized",
            getHistory: "get_BillingLog_history",

        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        },
        ];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10179);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: -1, // filter if not -1
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 0,
            _sort_by: 'col_1',
            //
            _toDate: null,
            _fromDate: null,
            _msgType: '',
            _preDefineType: '',
        };
        //this.props.dataActions.genericDataSetFilter(
        //    this.mainId, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        this.props.dataActions.genericDataSetFilter(
            this.historyId, this.props.user.companyCode, this.props.user.token, request, this.api.getHistory);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.mainId, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericDataGetRequested(
            this.historyId, this.props.user.companyCode, this.props.user.token, this.api.getHistory);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 8;
        // fall through
        switch (sorter.field) {
            case "key": col--; // fallsthrough  // 0
            case "mID": col--; // fallsthrough 
            case "mCustomerCode": col--; // fallsthrough 
            case "mFirstName": col--; // fallsthrough 
            case "mLastName": col--; // fallsthrough 
            case "mMessageText": col--; // fallsthrough 
            case "mDateTimeSent": col--; // fallsthrough 
            // mMessageType
            case "mTypeDescription": col--; // fallsthrough 
            case "mDestination": col--; // fallsthrough 
            case "mWasSentSuccessfully": break; // 8
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.historyId, this.props.user.companyCode, this.props.user.token, request, this.api.getHistory)
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.mainId].editing === record.key)
            ? this.props.data[this.mainId].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    render() {
        const gt = this.getText
        const render = t => t
        const renderType = t => {
            switch (t) {
                case '1': return this.getText(12390);
                case '2': return this.getText(12391);
                case '3': return this.getText(17638);
                case '4': return this.getText(13991);
                case '5': return this.getText(18109);
                default: return '';
            }
        }

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const historyTableStyle = {
            margin: "auto",
            marginTop: "20px",
            align: "center",
            width: window.innerWidth > 600 ? "60%" : "100%"
        }


        const dataSource2 = this.props.data[this.historyId].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: index,
                mPurchaseText: y[0],
                mPurchaseDate: y[1],
            })
        });

        let columnsSum = [
            { title: gt(12384), dataIndex: "mType", key: "mType", width: '25%', render: renderType },
            { title: gt(12385), dataIndex: "mPurchased", key: "mPurchased", width: '25%', render },
            { title: gt(12386), dataIndex: "mUsed", key: "mUsed", width: '25%', render },
            { title: gt(12387), dataIndex: "mBalance", key: "mBalance", width: '25%', render },
        ];

        let columns = [
            { title: gt(12388), dataIndex: "mPurchaseDate", key: "mPurchaseDate", width: '20%', sorter: true, render },
            { title: gt(12389), dataIndex: "mPurchaseText", key: "mPurchaseText", width: '80%', render },
        ];

        let firstRow = this.props.data[this.mainId].data[0];
        let lastDateUpdate = firstRow ? firstRow.split("\f")[4] : null


        return (
            <div style={divStyle}>
                <PageToolbar
                    title={this.getText(12383)}
                    actionButtons={this.actionButtons}
                    showsearch={false}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />
                <h3 style={{ float: 'left' }}>{lastDateUpdate ? this.getText(18135) + " " + lastDateUpdate : ""}</h3>
                <ResponsiveTable
                    tableOnly={true}
                    idTable={this.props.data[this.mainId]}
                    columns={columnsSum}
                    pagination={false}
                />

                <div style={historyTableStyle}>
                    <ResponsiveTable ui={this.props.ui} tableOnly={true} dataSource={dataSource2}
                        columns={columns}
                        loading={this.props.ui.workInProgress}
                        //scroll={{ x: 1650 }}
                        bordered
                        pagination={this.props.data[this.historyId].pagination}
                        onChange={this.handleTableChange}
                        locale={{ emptyText: this.getText(12392) }}
                    />
                </div>


            </div>
        );
    }
}

export default MessageUsing;

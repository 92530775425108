/* @flow */
/*jshint esversion: 6 */
import React, { Component } from 'react';
import LangContext from "../contextProvider/LangContext";
import SelectorItems from "./SelectorItems";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableIDs from '../data/TableIDs.js';
import { message } from "antd";

class SelectorItemClassic extends Component<Props, State> {
  constructor(props/*: Props*/) {
    super(props);

    this.barcodeReaderBuffer = '';
    this.isBufferFull = false;
    this.ctrlBKeyFlag = false;
  }

  static contextType = LangContext;

  getText = (id) => {
    return this.context.get(id) || '[' + id + ']'
  }

  checkIfItemExist = (s) => {
    if (s) {
      let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
      let user = new User(this.props.user.token, this.props.user.companyCode);
      let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
      job.setInput(dataSend);
      job.send("/cgi-bin/CashOnTab",
        (ob) => {
          let itemsRows = (ob.data).split("\r");
          let _ItemData = itemsRows[0].split('\f');
          let _ItemCode = _ItemData[1];
          let _ItemName = _ItemData[2];

          if (!_ItemCode || itemsRows.length > 1) {
            this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data, idSelector: this.props.idSelector });
          } else {
            this.onChangeItemCode(_ItemCode, _ItemName)
          }
        },
        (e) => { message.error(this.getText(10494)); }
      );
    } else {
      this.onChangeItemCode("", "")
    }
  }

  keyboardListener = (e) => {
    let bufferUpdate = (u) => { if (u.length === 1) this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
    switch (e.key) {
      case "b": case "B": case "נ": case "M": case "m": case "צ":
        if (e.ctrlKey) {
          this.ctrlBKeyFlag = true;
          this.barcodeReaderBuffer = '';
        } else {
          bufferUpdate(e.key)
        }
        break;

      case "Enter":
        if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
        this.checkIfItemExist(this.barcodeReaderBuffer)
        if (this.props.onEnter) this.props.onEnter();
        break;

      case "Backspace":
        this.barcodeReaderBuffer = this.barcodeReaderBuffer.slice(0, -1);
        break;

      default:
        bufferUpdate(e.key)
        break;
    }

  }

  onChangeItemCode = (code, name) => {
    if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
      this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
    } else {
      this.props.onChange(code, name);
    }
  }

  render() {
    let isPerSupplier = this.props.isPerSupplier ?? {}
    console.log("isPerSupplier", isPerSupplier)
    return (<span>
      <SelectorItems
        idSelector={this.props.idSelector}
        user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
        dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
        ctrlBKeyFlag={this.props.ctrlBKeyFlag ? this.props.ctrlBKeyFlag : null}
        isExpandMatrixFatherItem={this.props.isExpandMatrixFatherItem ? this.props.isExpandMatrixFatherItem : false}
        itemType={this.props.itemType ? this.props.itemType : this.props.itemTypeAll ? 0 : 1}
        value={this.props.valueId}
        onChangeSearch={(s) => {
          this.onChangeItemCode(s.mCode, s.mName)
          if (this.props.onEnter) setTimeout(this.props.onEnter, 1000);
        }}
        onChange={(s) => {
           this.props.onChange(s.target.value)
        }}
        onBlur={(s) => {
          this.checkIfItemExist(this.props.valueId)
          if (this.props.onBlur) this.props.onBlur(s)
        }}
        onKeyDown={this.keyboardListener}
        onSaveNewItem={this.props.onSaveNewItem}
        disabled={this.props.disabled}
        supplierId={isPerSupplier.code}
        supplierName={isPerSupplier.name}
        filterPerSupplier={this.props.isPerSupplier ? true : false}
        onMultiChange={this.props.onMultiChange}
      />
      {this.props.notViewName ? "" : (<span><h4>{this.props.valueId ? this.props.valueName : this.getText(10476)}</h4></span>)}
    </span>)

  }
}

export default SelectorItemClassic;

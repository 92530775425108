
import React from "react";
import {
    Form, Select, InputNumber, Input, DatePicker, Button, Icon, Popconfirm, Checkbox,
    Modal, Col, Row, message, Radio
} from 'antd';
import TableIDs from '../../data/TableIDs.js';
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import ResponsiveTable from "../../components/ResponsiveTable";
import PictureView from "../../components/PictureView";
import GenericSelector from "../../components/GenericSelector";
import moment from "moment";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags.js";
import { escapeRegExp,disabledDatePicker } from "../../utils/utils";

const { Option } = Select;
const topHeight = 340; // px
const rowHeight = 30;  // px

class KuponDeclarations extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            height: window.innerHeight,
            modalCreateEdit: null,
            kuponType: 1,
            inMonth: 0,
        };

        this.doOnResize = null;

        this.id = "KUPON_DECLARATIONS";
        this.items_id = "KUPON_DECLARATION_ITEMS";

        this.fields_selector = "CUSTOMER_DATE_FIELDS";
        this.alt_contact_fields_selector = "CUSTOMER_ALT_CONTACT_FIELDS";

        this.api = {
            get: "get_kupon_declarations",
            create_edit: "create_edit_kupon_declarations",
            get_items: "get_kupon_declaration_items",
        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
        if (props.data[this.items_id] === undefined) { props.data[this.items_id] = { ...props.data.genericData } }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.handleClear('0') }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

        this.selectors = [
            { id: TableIDs.genericMultiSelectorItemTags, api: "get_tags_with_types" },
            { id: this.fields_selector, api: "get_customer_date_fields" },
            { id: TableIDs.genericSelectorPreDefinedMsg, api: "get_pre_defined_msg_list" },
            { id: this.alt_contact_fields_selector, api: "get_alt_contact_customer_date_fields" },
        ];

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilter();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilter);
    };

    initFilter = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_0',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 7;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "kdID": col--;
            case "kdLogic": col--;
            case "KdActive": col--;
            case "kdDateTo": col--;
            case "kdDateFrom": col--;
            case "kdDayBefore": col--;
            case "kdDaysAfter": break; // 7
            default:
                col = 0;
        }

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(14386);
    }

    onChange = (field, value) => {
        this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, [field]: value } })
    }

    onChangeMivzaType = (value) => {
        this.setState({
            modalCreateEdit: {
                ...this.state.modalCreateEdit,
                kdMivzaType: value,
                kdMivzaTnaiType: value === '9' ? '2' : '1',
                kdMivzaTnaiAmountFrom: value === '9' ? '1' : '',
                kdMivzaTnaiAmountTo: '',
                kdMivzaTnaiKofel: value === '9' ? '0' : '',
            }
        })
    }

    apiItems = (mId) => {
        this.props.dataActions.genericDataSetFilter(
            this.items_id, this.props.user.companyCode, this.props.user.token, { mId: mId }, this.api.get_items);
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: null, kuponType: 1, inMonth: 0 }, this.refreshData)
    }

    handleClear = (key) => {
        let obj = {}
        this.props.data[this.id].headers.forEach((x) => { obj = { ...obj, [x]: '' } });
        obj = { ...obj, key: key, kdID: key }
        this.setState({ modalCreateEdit: obj }, this.apiItems)
    }

    handleOk = (data) => {
        const { kuponType, inMonth } = this.state;
        let valid = true;
        if (!data.kdLogic || !data.kdMivzaType) valid = false;
        if (kuponType == 1 && (!data.kdDateFrom || !data.kdDateTo)) valid = false;
        if (kuponType == 2 && !data.kdValidFrom) valid = false;
        if (kuponType == 2 && !inMonth && (!data.kdDayBefore || !data.kdDaysAfter)) valid = false;

        if (!valid) {
            message.error(this.getText(14412))
        } else if (!this.props.data[this.items_id].data.length) {
            message.error(this.getText(15835))
        } else {
            let kuponHeaders = this.props.data[this.id].headers;
            let itemsHeaders = this.props.data[this.items_id].headers;

            let kuponData = kuponHeaders.join('\f');
            kuponHeaders.forEach((x, i) => {
                kuponData += i === 0 ? "\r" : "\f";
                kuponData += data[x] || data[x] === 0 ? data[x] : "";
            });

            let itemsData = itemsHeaders.join('\f');
            itemsData += "\r";
            itemsData += this.props.data[this.items_id].data.join('\r');

            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, this.api.create_edit, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
            job.setSection('KUPON', kuponData);
            job.setSection('ITEMS', itemsData);
            job.send("/cgi-bin/CashOnTab", (ob) => {
                this.handleCancel();
                message.success(this.getText(14449))
            }, (e) => {
                this.handleCancel();
                message.error(this.getText(14450))
            });
        }
    }

    getItemField = (t, r, f) => {
        if (r.key !== this.props.data[this.items_id].editing) {
            switch (f) {
                case 'iCode': return t + " - " + r.iName;
                case 'iTagId': return t + " - " + r.iTagName;
                default: return t;
            }
        } else {
            let editedRecord = this.props.data[this.items_id].editedRecord;
            let changeItem = (obj) => { this.props.dataActions.genericDataSetEdit(this.items_id, obj) }
            switch (f) {
                case 'iDscnt': case 'iPrice': case 'iBonusQuantity':
                    let onChange = (v) => {
                        changeItem(f === 'iDscnt' ? { iDscnt: v, iPrice: '' } : f === 'iPrice' ? { iPrice: v, iDscnt: '' } : { [f]: v });
                    }
                    return (<InputNumber min={0} precision={2} step={1} value={editedRecord[f]} onChange={onChange} />)

                case 'iCode':
                    return (<SelectorItemClassic
                        user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
                        dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                        ctrlBKeyFlag={null} isExpandMatrixFatherItem={false} itemType={1}
                        valueId={editedRecord[f]} valueName={editedRecord.iName}
                        onChange={this.onChangeItemCode} />)

                case 'iTagId':
                    let findTagName = (id) => {
                        let res = "";
                        this.props.data[this.selectors[0].id].dataset.forEach((x) => {
                            let y = x.tags.find(f => f.id === id);
                            if (y) res = y.text
                        });
                        return res
                    }
                    return (<GenericSelectorItemTags {...this.props} notReport 
                        // id={this.selectors[0].id}
                        // api={this.selectors[0].api}
                        // optionsGroup
                        value={editedRecord[f]}
                        onChange={(v) => {
                            changeItem({ iTagId: v, iTagName: findTagName(v), iCode: '', iName: '' });
                            this.cleareItemsState()
                        }}
                    // user={this.props.user}
                    // dataActions={this.props.dataActions}
                    // data={this.props.data} 
                    />);

                default: return t;
            }
        }
    }

    onChangeItemCode = (code, name) => {
        this.props.dataActions.genericDataSetEdit(this.items_id, { iCode: code, iName: name, iTagId: '', iTagName: '' });
    }

    cleareItemsState = () => {
        this.setState({ newItemCode: null, newItemName: null })
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let idTable = this.props.data[this.id];

        let gt = this.getText;
        let colTxt = (text) => text
        let colCb = (text) => (<Checkbox checked={text == '1'} />)
        let colEdit = (text, record) => {
            return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={'edit'} onClick={() => {
                let kuponType = !record.kdDateFrom && !record.kdDateTo ? 2 : 1;
                let inMonth = kuponType == 2 && !parseInt(record.kdDayBefore) && !parseInt(record.kdDaysAfter) ? 1 : 0
                this.setState({ modalCreateEdit: record, kuponType, inMonth }, () => { this.apiItems(record.kdMivzaId) })
            }} />)
        }

        let colEditModal = (text, record) => {
            let mData = this.props.data[this.items_id];

            if (mData.editing === record.key) {
                let cancelEditing = () => {
                    if (mData.editing == 0) {
                        this.props.dataActions.genericDataCancelInlineCreate(this.items_id, true);
                    } else {
                        this.props.dataActions.genericDataCancelEditing(this.items_id);
                    }
                    this.cleareItemsState()
                }
                let setRow = () => {
                    let data = [...mData.data];
                    let editedRecord = { ...mData.editedRecord };

                    if (mData.editing == 0) {
                        editedRecord = { ...editedRecord, iId: -1000 + mData.data.length, key: -1000 + mData.data.length }

                        let parsingData = mData.headers.map(x => editedRecord[x]).join("\f");
                        data = [...data.slice(1), parsingData];
                    } else {
                        data[record.index] = mData.headers.map(x => editedRecord[x]).join("\f");
                    }
                    this.props.dataActions.setJson(this.items_id, { data, editedRecord: {}, editing: -1 });
                    this.cleareItemsState()
                    // cancelEditing();
                }
                return (<span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"} onClick={setRow} />
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"} onClick={cancelEditing} />
                </span>);

            } else {
                return (<span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                        this.props.dataActions.genericDataStartEditing(this.items_id, record.key, record.index, false)
                    }} />
                    <Popconfirm title={gt(14394)} okText={gt(14395)} cancelText={gt(14396)} onConfirm={() => {
                        this.props.dataActions.dataTableDeleteRow(this.items_id, record.index)
                    }}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                    </Popconfirm>
                </span>);
            }
        }

        let showsearch = (val) => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token,
                {
                    ...idTable.filters,
                    _words: escapeRegExp(val),
                    // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                    page: 1,
                    _page_num: 1,
                },
                this.api.get)
        }

        let ceModal = this.state.modalCreateEdit ? this.state.modalCreateEdit : {};
        let isNew = ceModal.key == '0';
        let modalTitle = (<PageToolbar isModal={true} title={gt(isNew ? 14398 : 14397)} actionButtons={[]} />)
        let modalButtons = [
            <Button key="back" onClick={this.handleCancel}>{gt(14399)}</Button>,
            <Button key="clear" onClick={() => { this.handleClear(ceModal.key) }}>{gt(14400)}</Button>,
            <Button key="create" type="primary" onClick={() => { this.handleOk(ceModal) }}>{gt(14401)}</Button>
        ];

        let kuponType = this.state.kuponType;
        let getInput = (f) => (<Input value={ceModal[f]} onChange={(v) => { this.onChange(f, v.target.value) }} />)
        let getCheckbox = (f, t) => {
            return (<Form.Item>
                <Checkbox checked={ceModal[f] == '1'} onChange={(e) => {
                    this.onChange(f, e.target.checked ? '1' : '0')
                }}>{gt(t)}</Checkbox>
            </Form.Item>)
        }
        let getDatePicker = (f, t) => {
            let minDate = f == 'kdDateTo' ? ceModal.kdDateFrom : null
            let maxDate = f == 'kdDateFrom' ? ceModal.kdDateTo : null
             return (<Form.Item label={gt(t)} validateStatus={ceModal[f] ? 'success' : 'error'} required={true}>
                <DatePicker
                    format={"DD/MM/YYYY"}
                    disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
                    value={ceModal[f] ? moment(ceModal[f], "YYYY-MM-DD") : null}
                    onChange={(e) => { this.onChange(f, e ? e.format("YYYY-MM-DD") : "") }} />
            </Form.Item>)
        }
        let getSelector = (f, t, i, valid) => {
            return (<Form.Item label={gt(t)} validateStatus={!valid || ceModal[f] ? 'success' : 'error'} required={valid}>
                <GenericSelector
                    id={this.selectors[i].id} api={this.selectors[i].api}
                    value={ceModal[f]} onChange={(v) => { this.onChange(f, v) }}
                    user={this.props.user} dataActions={this.props.dataActions} data={this.props.data} />
            </Form.Item>)
        }
        let getInputNumber = (f, t, valid) => {
            return (<Form.Item label={gt(t)} validateStatus={!valid || ceModal[f] ? 'success' : 'error'} required={valid}>
                <InputNumber min={0} value={ceModal[f]} onChange={(v) => { this.onChange(f, v) }} />
            </Form.Item>)
        }

        let columns = [
            { title: gt(14388), dataIndex: "kdID", key: "kdID", width: '10%', sorter: true, render: colTxt },
            { title: gt(14389), dataIndex: "kdLogic", key: "kdLogic", width: '30%', sorter: true, render: colTxt },
            { title: gt(14390), dataIndex: "KdActive", key: "KdActive", width: '5%', sorter: true, render: colCb },
            { title: gt(14391), dataIndex: "kdDateFrom", key: "kdDateFrom", width: '15%', sorter: true, render: colTxt },
            { title: gt(14392), dataIndex: "kdDateTo", key: "kdDateTo", width: '15%', sorter: true, render: colTxt },
            { title: gt(14446), dataIndex: "kdDayBefore", key: "kdDayBefore", width: '10%', sorter: true, render: colTxt },
            { title: gt(14447), dataIndex: "kdDaysAfter", key: "kdDaysAfter", width: '10%', sorter: true, render: colTxt },
            { title: gt(14393), width: '5%', render: colEdit },
        ];

        let modalColumns = [
            { title: "#", width: "5%", render: (t, r) => parseInt(r.index) + 1 },
            { title: gt(14439), width: "10%", key: "iDscnt", dataIndex: "iDscnt", render: (t, r) => this.getItemField(t, r, 'iDscnt') },
            { title: gt(14440), width: "10%", key: "iPrice", dataIndex: "iPrice", render: (t, r) => this.getItemField(t, r, 'iPrice') },
            { title: gt(14437), width: "30%", key: "iCode", dataIndex: "iCode", render: (t, r) => this.getItemField(t, r, 'iCode') },
            { title: gt(14438), width: "30%", key: "iTagId", dataIndex: "iTagId", render: (t, r) => this.getItemField(t, r, 'iTagId') },
            ceModal.kdMivzaType != '2' ?
                { title: gt(14441), width: "10%", key: "iBonusQuantity", dataIndex: "iBonusQuantity", render: (t, r) => this.getItemField(t, r, 'iBonusQuantity') }
                : null,
            { title: "", width: ceModal.kdMivzaType == '2' ? "15%" : "5%", render: colEditModal },
        ].filter(f => f);

        let addItemsActive = (this.props.data[this.items_id].editing === -1 && (!this.props.data[this.items_id].data.length || ceModal.kdMivzaType == '9'));

        let getRadioInMonth = [
            <Radio.Group value={this.state.inMonth} onChange={e => {
                this.setState({
                    inMonth: e.target.value,
                    modalCreateEdit: {
                        ...this.state.modalCreateEdit,
                        kdDayBefore: '',
                        kdDaysAfter: '',
                    }
                })
            }}>
                <Radio value={0}>{gt(16292)}</Radio><br />
                <Radio value={1}>{gt(16293)}</Radio><br />
            </Radio.Group>
        ];

        if (!this.state.inMonth) {
            getRadioInMonth.push([
                getInputNumber('kdDayBefore', 14446, true),
                getInputNumber('kdDaysAfter', 14447, true)
            ])
        }

        return (<div style={divStyle}>
            <ResponsiveTable
                idTable={idTable}
                columns={columns}
                percentageToMobile={[20, 60, 20]}
                title={gt(14387)}
                actionButtons={this.actionButtons}
                showsearch={showsearch}
                onChange={this.handleTableChange}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />

            <Modal visible={this.state.modalCreateEdit!==null} onCancel={this.handleCancel} width={'95%'} title={modalTitle} footer={modalButtons}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ padding: '2%' }}>
                        <Form.Item label={gt(14402)}>
                            <Radio.Group
                                value={kuponType}
                                onChange={(e) => {
                                    let updateKeys = {}
                                    if (e.target.value == 1) updateKeys = { kdDateFrom: '', kdDateTo: '' }
                                    if (e.target.value == 2) updateKeys = { kdDayBefore: '', kdDaysAfter: '', kdValidFrom: '' }
                                    this.setState({ kuponType: e.target.value, modalCreateEdit: { ...this.state.modalCreateEdit, ...updateKeys } })
                                }}>
                                <Radio value={1}>{gt(14432)}</Radio>
                                <Radio value={2}>{gt(14433)}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ padding: '2%' }}>
                        <Form.Item label={gt(15823)} validateStatus={ceModal.kdMivzaType ? 'success' : 'error'} required={true}>
                            <Select value={ceModal.kdMivzaType} onChange={this.onChangeMivzaType} style={{ width: 300 }}>
                                <Option value={'2'}>{gt(15824)}</Option>
                                <Option value={'3'}>{gt(15825)}</Option>
                                {/* <Option value={'9'}>{gt(15826)}</Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ padding: '2%' }}>
                        {ceModal.kdMivzaType == '2' ?
                            <Form.Item label={gt(15827)}>
                                <Radio.Group value={ceModal.kdMivzaTnaiType} onChange={(v) => { this.onChange('kdMivzaTnaiType', v.target.value) }}>
                                    <Radio value={'1'}>{gt(15828)}</Radio>
                                    <Radio value={'2'}>{gt(15829)}</Radio>
                                </Radio.Group>
                                <Row>
                                    <Col span={8}>{getInputNumber('kdMivzaTnaiAmountFrom', ceModal.kdMivzaTnaiType == '2' ? 15832 : 15830)}</Col>
                                    <Col span={8}>{getInputNumber('kdMivzaTnaiAmountTo', ceModal.kdMivzaTnaiType == '2' ? 15833 : 15831)}</Col>
                                    <Col span={8}>{getInputNumber('kdMivzaTnaiKofel', 15834)}</Col>
                                </Row>
                            </Form.Item>
                            : ceModal.kdMivzaType == '3' ?
                                getInputNumber('kdMivzaTnaiKofel', 15834)
                                : ""}
                    </Col>
                </Row>
                <Row>
                    <Col span={window.innerWidth > 600 ? 8 : 24} style={{ padding: '2%' }}>
                        <Form.Item label={gt(14389)} validateStatus={ceModal['kdLogic'] ? 'success' : 'error'} required={true}>
                            {getInput('kdLogic')}
                        </Form.Item>
                        <Form.Item label={gt(14434)}>
                            {getInput('kdMivzaDesc')}
                        </Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 5 : 24} style={{ padding: '2%' }}>
                        {getCheckbox('KdActive', 14390)}
                        {getCheckbox('KdIsMultiAllowed', 15946)}
                        {kuponType == 1 ?
                            getCheckbox('kdOnce', 14407)
                            : [
                                getSelector('kdValidFrom', 14445, 1, true),
                                getCheckbox('kdValidTo', 14448)
                            ]}
                    </Col>
                    <Col span={window.innerWidth > 600 ? 6 : 24} style={{ padding: '2%' }}>
                        {kuponType == 1 ? [
                            getDatePicker('kdDateFrom', 14391),
                            getDatePicker('kdDateTo', 14392)
                        ] : getRadioInMonth}

                        {getSelector('kdCustFieldAltContact', 16688, 3)}
                    </Col>
                    <Col span={window.innerWidth > 600 ? 5 : 24} style={{ padding: '2%' }}>
                        <Form.Item label={gt(14435)}>
                            <PictureView
                                user={this.props.user} data={this.props.data} dataActions={this.props.dataActions}
                                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                                url={ceModal['kdLogoUrl']}
                                onChange={(url) => { this.onChange('kdLogoUrl', url) }}
                                onRemove={() => { this.onChange('kdLogoUrl', '') }}
                                onUploadedSuccess={(response) => { }}
                                onUploadedFailed={(errorMessage, errorCode) => { }}
                                imageType={'I'}
                                aspect={4 / 3}
                            />
                        </Form.Item>
                        {kuponType == 2 ? getSelector('kdPredefinedMessageId', 14408, 2) : ""}
                    </Col>
                </Row>
                <ResponsiveTable
                    idTable={this.props.data[this.items_id]}
                    title={gt(14436)}
                    columns={modalColumns}
                    actionButtons={addItemsActive ? [{
                        buttonType: 'add',
                        buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.items_id) }
                    }] : []}
                />
            </Modal>
        </div >)

    }

}

export default KuponDeclarations;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";

import "../../App.css";
import { Icon, Input, Checkbox, Button, message } from "antd";
import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import ModalCreateEditMultilingual from "../../components/ModalCreateEditMultilingual";
import ModalSearchMultilingual from "../../components/ModalSearchMultilingual";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import Jax from "../../Jax/jax.es6.module";

const topHeight = 340; // px
const rowHeight = 30;  // px

class Multilingual extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            height: window.innerHeight,
            langName: "",
            editMulti: false,
            editMultiList: [],
            editMultiValue: "",

            filterActive: false,
            valueSearch: '',
        };

        this.api = {
            get: "get_multilingual_table",
            get_multi: "get_multilingual_filter_list",
            edit_multi: "edit_multilingual_multi",
        }

        this.id = 'MULTILINGUAL_TABLE';
        this.multi_id = 'MULTILINGUAL_FILTER_LIST';

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.multi_id] === undefined) props.data[this.multi_id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorLanguages] === undefined) props.data[TableIDs.genericSelectorLanguages] = { ...props.data.genericSelector }

        this.doOnResize = null;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    createButton = (type, onclick) => {
        return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={type} onClick={onclick} />)
    }

    createCheckBox = (id) => {
        if (id === "multi") {
            return (<Checkbox checked={this.state.editMulti} onChange={(e) => {
                if (e.target.checked) {
                    let f = this.props.data[this.id].filters;
                    let params = "_words\f_language\f_document_id\f_element\r" +
                        f._words + "\f" + f._language + "\f" + f._document_id + "\f" + f._element;
                    this.sendAPI(this.api.get_multi, params, (ob) => {
                        this.setState({ editMulti: true, editMultiList: ob.data.split("\r") })
                    })
                } else {
                    this.setState({ editMulti: false, editMultiList: [] })
                }
            }}
            />)
        } else {
            if (this.state.editMulti) {
                let list = this.state.editMultiList;
                let local = list ? list.indexOf(id) : -1;
                return (<Checkbox checked={local > -1} onChange={(e) => {
                    if (e.target.checked) list.push(id)
                    else list.splice(local, 1)
                    this.setState({ editMultiList: list })
                }} />)
            }
        }
    }

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        this.refreshSelectors();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10294);
        let sl = this.props.data[TableIDs.genericSelectorLanguages];
        let psl = prevProps.data[TableIDs.genericSelectorLanguages];
        let f = this.props.data[this.id].filters;
        let pf = prevProps.data[this.id].filters;
        let lName = "";
        // if (!sl || !sl.dataset || !sl.dataset[0]) this.refreshSelectors();
        // if (pf && (pf._language !== f._language)) {
        if ((sl && sl.dataset && sl.dataset[0] !== psl.dataset[0]) ||
            (pf._language !== f._language)
        ) {
            sl.dataset.map((x) => {
                if (x.code === f._language) lName = x.name
            })
            this.setState({ langName: lName })
            console.log("yuda componentDidUpdate ", lName, f._language, sl.dataset)
        }

    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_0',
            _language: "2",
            _document_id: null,
            _element: "",
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

        this.setState({ filterActive: false, valueSearch: "" })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.refreshSelectors();
    }

    refreshSelectors = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorLanguages, this.props.user.companyCode, this.props.user.token, "get_languages_list"]
        });
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => e);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 5;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "eID": col--;
            case "eDesc": col--;
            case "eScreen": col--;
            case "eDocument": col--;
            case "eValue": break; // 5
            default:
                col = 0;
        }

        //console.log("yuda filters ", filters)

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    render() {
        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };

        let columns = [
            {
                title: this.createCheckBox("multi"),
                dataIndex: "eID",
                key: "eID",
                width: '5%',
                render: (text, record) => this.createCheckBox(text),
            }, {
                title: this.getText(10295),
                dataIndex: "eID",
                key: "eID",
                width: '10%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(10296),
                dataIndex: "eDesc",
                key: "eDesc",
                width: '20%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(10297),
                dataIndex: "eScreen",
                key: "eScreen",
                width: '20%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(10298),
                dataIndex: "eDocument",
                key: "eDocument",
                width: '20%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(10299) + " - " + this.state.langName,
                dataIndex: "eValue",
                key: "eValue",
                width: '20%',
                sorter: true,
                render: (text, record) => text
            }, {
                title: this.getText(10300),
                width: '5%',
                render: (text, record) => this.createButton("edit", () => {
                    this.props.uiActions.showModal(TableIDs.modalCreateEditMultilingual, { eID: record.key, eDesc: record.eDesc })
                })
            },
        ];

        let filters = this.props.data[this.id].filters;

        let request = {
            // ui-pagination 
            _id: (this.props.ui.data) ? this.props.ui.data : -1,
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
            _language: filters._language,
            _document_id: filters._document_id,
            _element: filters._element,
        };

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10302) + " - " + this.state.langName}
                    actionButtons={[
                        {
                            buttonType: 'filter',
                            buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchMultilingual) },
                        }, {
                            buttonDisabled: !this.state.filterActive,
                            buttonType: 'clear',
                            buttonFunction: this.initFilters,
                        }, {
                            buttonType: 'refresh',
                            buttonFunction: this.refreshData
                        }
                    ]}
                    showsearch={(val) => {
                        if (val) { this.setState({ filterActive: true }) }

                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}

                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>);

        console.log("request changed", this.state.filterActive)

        return (
            <div style={divStyle}>
                {titleBar}
                {this.state.editMulti ? (<span>
                    <Input
                        placeholder={this.getText(10310)}
                        style={{ width: window.innerWidth > 600 ? "50%" : "100%" }}
                        value={this.state.editMultiValue}
                        onChange={(v) => { this.setState({ editMultiValue: v.target.value }) }}
                    />
                    <Button type={"primary"} onClick={() => {
                        let lang = this.props.data[this.id].filters._language;
                        let list = this.state.editMultiList.toString();
                        let value = this.state.editMultiValue;
                        console.log("yuda ", list, lang, value)
                        let dataSend = "_language\feditMultiList\feditMultiValue\r" + lang + "\f" + list + "\f" + value;
                        this.sendAPI(this.api.edit_multi, dataSend, () => {
                            this.setState({
                                editMulti: false,
                                editMultiList: [],
                                editMultiValue: "",
                            });
                            this.refreshData();
                            message.success(this.getText(10311));
                        });
                    }}>
                        {this.createButton("save", () => { })}
                    </Button>
                </span>) : ""}
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[20, 40, false, false, 40]}
                    onChange={this.handleTableChange}
                    tableOnly={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <ModalCreateEditMultilingual
                    id={TableIDs.modalCreateEditMultilingual}
                    onClose={this.refreshData}
                    user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditMultilingual)}
                />
                <ModalSearchMultilingual
                    id={TableIDs.modalSearchMultilingual}
                    title={this.getText(10303)}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchMultilingual)}
                    setFilterActive={e => { this.setState({ filterActive: e }) }}
                />
            </div>
        )

    }
}
export default Multilingual;
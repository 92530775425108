
import React from "react";
import { Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Popover, Row, Col, Checkbox, message, InputNumber } from 'antd';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import ModalCreateEditItem from "../components/ModalCreateEditItem";
import { fixCurrentPage, escapeRegExp } from "../utils/utils";
import Jax from "../Jax/jax.es6.module";
import ResponsiveTable from "../components/ResponsiveTable";
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext";
import TableLineIcons from "./TableLineIcons";
import GenericModal from "./GenericModal";

const topHeight = 300; // px
const rowHeight = 40;  // px

//type State = {}
class ModalEditPurchaseDocument extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            type: null,

            modalBonusCalcData: null,
            modalBonusCalcDataIndex: null,
            modalBonusCalcDataUpdated: false,

            ReturnPurchaseReport: {
                mDocumentNumber: null,
                mDocumentTypeID: null,
                mPOSID: null,
                reportName: "reports/ReturnPurchase.pdf",
            }
        }

        this.id = "EDIT_PURCHASE_DOC";

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.api = {
            get: "get_documentLines_purchase_list_table",
            createRetrunPurchaseDoc: "create_document_return_purchase",
            edit: "edit_documentLines_purchase_by_id",
        }


        this.actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }]


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };
        if (this.props.ui.data !== prevProps.ui.data ||
            ((prevProps.ui.data) ? this.props.ui.data.mNumber !== prevProps.ui.data.mNumber : false) ||
            ((prevProps.ui.data) ? this.props.ui.data.mPOSID !== prevProps.ui.data.mPOSID : false) ||
            (prevProps.toggle === false && this.props.toggle === true)) {

            let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
                ? (this.state.height - topHeight) / rowHeight
                : 1);

            let request = {
                _id: 0, // for testing. TODO: "read" from selector
                // ui
                page: 1,
                recordsPerPage: numOfRowsByHeight, //5,
                sortType: 1,
                sortColumn: 1,
                // api
                _page_num: 1,
                _rows_num: numOfRowsByHeight, //5,
                _isAsc: 1,
                _sort_by: 'col_1',
                _words: '',

                ...this.props.ui.data
            };
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
        }
    }

    setReportParametersAndDo(report: string, params: Object, func) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState, func);
    }

    componentDidMount() {
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',

            mNumber: -1,
            mDocType: -1,
            mPOSID: -1,
            //...this.props.ui.data
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);

    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 9;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mNumber": col--;
            case "mItemCode": col--;
            case "mItemBarcode": col--;
            case "mDescription": col--;
            case "mUnitPriceNoTax": col--;
            case "mQuantity": col--;
            case "mTotalSumNoTax": col--;
            case "mCost": col--;
            case "mActualQuantity": break; // 9
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalEditPurchaseDocument);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        this.setState({ visible: false });
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalEditPurchaseDocument);
    }
    handleClear = () => {
        //this.clear();
    }


    sendAPI = (script, data, sCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, (e) => { console.error(e) });
    };

    render() {
        const handleClick = (type: String) => {
            // create return purchase document 
            this.setState({ type: type }, () => { this.create_retrun_purchase_doc(); });
        }

        const content = (<div>
            <Tooltip title={this.getText(11853)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                    type={"file-pdf"} onClick={() => { handleClick("pdf") }} />
            </Tooltip>
            <Tooltip title={this.getText(11854)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                    type={"file-excel"} onClick={() => { handleClick("xls") }} />
            </Tooltip>
            <Tooltip title={this.getText(11855)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                    type={"file-word"} onClick={() => { handleClick("docx") }} />
            </Tooltip>
        </div>);

        let genericActionButtons = [
            <Tooltip title={this.getText(11856)}>
                <Popover placement="right" content={content} title={this.getText(11857)} trigger="click">
                    <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                        <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="export" theme="outlined" />
                    </Button>
                </Popover>
            </Tooltip>,
            <Tooltip title={this.getText(19422)}>
                <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                    let dataSend = "itemCode\fsupplier\fcost\fisDefault\fquantity\f_differenceBonusData\f_baseCost\f_dscnt1\f_dscnt2\f_dscnt3\f_bonusQty\f_qtyForBonusQty\f_afterBonuses";
                    let calcHeaders = ["mBaseCost", "mSupDscnt1", "mSupDscnt2", "mSupDscnt3", "mCostIncludeDscnt", "mBonusQty", "mQtyForBonusQty", "mCostIncludeBonus"];
                    this.props.data[this.id].data.forEach(x => {
                        let y = x.split("\f");
                        if (y[19] == 1) {
                            let dataToUpdate = y[17]?.indexOf('{') > -1 ? JSON.parse(y[17]) : {}
                            let oldData = y[18]?.indexOf('{') > -1 ? JSON.parse(y[18]) : {}
                            let differenceBonusData = '0';
                            calcHeaders.forEach(x => {
                                if (!dataToUpdate[x]) dataToUpdate[x] = '';
                                if (dataToUpdate[x] != oldData[x]) differenceBonusData = '1';
                            })
                            const { mBaseCost, mSupDscnt1, mSupDscnt2, mSupDscnt3, mCostIncludeDscnt, mBonusQty, mQtyForBonusQty, mCostIncludeBonus } = dataToUpdate;

                            dataSend += "\r" + y[4] + "\f" + y[20] + "\f"
                                + (differenceBonusData == '1' ? mCostIncludeDscnt : y[10]) + "\f\f\f" + differenceBonusData + "\f"
                                + mBaseCost + "\f" + mSupDscnt1 + "\f" + mSupDscnt2 + "\f" + mSupDscnt3 + "\f" + mBonusQty + "\f" + mQtyForBonusQty + "\f" + mCostIncludeBonus
                        }
                    })
                    this.sendAPI("update_cost_price_from_doc", dataSend, this.refreshData)
                }}>
                    <Icon style={{ fontSize: 16 }} type="save" theme="outlined" />
                </Button>
            </Tooltip>,
            <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} >
                <Checkbox
                    onChange={e => {
                        let allData = this.props.data[this.id].data.map(x => {
                            let y = x.split("\f");
                            y[19] = e.target.checked ? "1" : "0";
                            return y.join("\f")
                        })
                        this.props.dataActions.setJson(this.id, { data: allData })
                    }} />
            </Button>
        ]

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(11839)}
                    actionButtons={this.actionButtons}
                    genericActionButtons={genericActionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request,
                                _words: escapeRegExp(val),
                                // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[3],
                mDocumentNumber: y[0],
                mDocumentTypeID: y[1],
                mPOSID: y[2],
                mNumber: y[3],
                mItemCode: y[4],
                mItemBarcode: y[5],
                mDescription: y[6],
                mUnitPriceNoTax: y[7],
                mQuantity: y[8],
                mTotalSumNoTax: y[9],
                mCost: y[10],
                mActualQuantity: y[11],
                mItemIsMatrix: y[12],
                mColorUserCode: y[13],
                mColorDesc: y[14],
                mSizeCode: y[15],
                mSizeDesc: y[16],
                mDocBonusCalcData: y[17],
                mSuppBonusCalcData: y[18],
                mUpdateLine: y[19],
                mSupplier: y[20],
                index: index,

            })
        });

        let columns =
            [
                {
                    title: this.getText(11840),
                    dataIndex: "mNumber",
                    key: "mNumber",
                    sorter: true,
                    width: '6%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(11841),
                    dataIndex: "mItemCode",
                    key: "mItemCode",
                    sorter: true,
                    width: '14%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(11842),
                    dataIndex: "mItemBarcode",
                    key: "mItemBarcode",
                    sorter: true,
                    width: '14%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(11843),
                    dataIndex: "mDescription",
                    key: "mDescription",
                    sorter: true,
                    width: '14%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(11844),
                    dataIndex: "mQuantity",
                    key: "mQuantity",
                    sorter: true,
                    width: '8%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(11845),
                    dataIndex: "mActualQuantity",
                    key: "mActualQuantity",
                    sorter: true,
                    width: '8%',
                    render: (text, record) => {
                        const _color = (record['mActualQuantity'] - record['mQuantity'] != 0) ? 'red' : 'unset';

                        if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
                            return (
                                <Input
                                    style={{ width: '98%', textAlign: 'center' }}
                                    defaultValue={text}
                                    onChange={(value) => {
                                        console.log("input number: ", text, record);
                                        this.props.dataActions.genericDataSetEdit(this.id, { mActualQuantity: value.target.value });
                                    }} />);
                        } else {
                            return <div style={{ color: _color }}>
                                {text}</div>;
                        }
                    }
                }, {
                    title: this.getText(11846),
                    dataIndex: "mUnitPriceNoTax",
                    key: "mUnitPriceNoTax",
                    sorter: true,
                    width: '8%',
                    render: (text, record) => text,
                }, {
                    title: this.getText(11847),
                    dataIndex: "mCost",
                    key: "mCost",
                    sorter: true,
                    width: '10%',
                    render: (text, record) => (<div style={{ color: (record['mCost'] != record['mUnitPriceNoTax']) ? 'red' : 'auto' }}>{text}</div>)

                    // }, {
                    //     title: this.getText(11848),
                    //     dataIndex: "mTotalSumNoTax",
                    //     key: "mTotalSumNoTax",
                    //     sorter: true,
                    //     width: '10%',
                    //     render: (text, record) => {
                    //         const _color = (record['mCost'] - record['mTotalSumNoTax'] != 0) ? 'red' : 'unset';
                    //         return (<div style={{ color: _color }}>{text}</div>);
                    //     }
                }, {
                    title: this.getText(11849),
                    key: "Settings",
                    width: '8%',
                    render: (text, record) => {
                        if (this.props.data[this.id].editing !== record.key) {
                            let genericIcons = [
                                {
                                    type: "hdd",
                                    tooltip: this.getText(11859),
                                    onClick: () => {
                                        this.props.uiActions.showModal(TableIDs.modalCreateEditItem, { ...this.props.ui.data, itemId: record['mItemCode'], fromMatrix: true })
                                    }
                                }
                            ];

                            if (record.mSuppBonusCalcData) {
                                genericIcons.push({
                                    type: "setting",
                                    tooltip: this.getText(19418),
                                    onClick: () => {
                                        let oldData = record.mSuppBonusCalcData?.indexOf('{') > -1 ? JSON.parse(record.mSuppBonusCalcData) : {}
                                        let newData = record.mDocBonusCalcData?.indexOf('{') > -1 ? JSON.parse(record.mDocBonusCalcData) : {}
                                        this.setState({
                                            modalBonusCalcDataIndex: record.index,
                                            modalBonusCalcData: [
                                                { key: "mBaseCost", desc: this.getText(16434), oData: oldData.mBaseCost, nData: newData.mBaseCost },
                                                { key: "mSupDscnt1", desc: this.getText(16435), oData: oldData.mSupDscnt1, nData: newData.mSupDscnt1 },
                                                { key: "mSupDscnt2", desc: this.getText(16436), oData: oldData.mSupDscnt2, nData: newData.mSupDscnt2 },
                                                { key: "mSupDscnt3", desc: this.getText(16437), oData: oldData.mSupDscnt3, nData: newData.mSupDscnt3 },
                                                { key: "mCostIncludeDscnt", desc: this.getText(16438), oData: oldData.mCostIncludeDscnt, nData: newData.mCostIncludeDscnt },
                                                { key: "mBonusQty", desc: this.getText(16439), oData: oldData.mBonusQty, nData: newData.mBonusQty },
                                                { key: "mQtyForBonusQty", desc: this.getText(16440), oData: oldData.mQtyForBonusQty, nData: newData.mQtyForBonusQty },
                                                { key: "mCostIncludeBonus", desc: this.getText(16441), oData: oldData.mCostIncludeBonus, nData: newData.mCostIncludeBonus },
                                            ]
                                        })
                                    }
                                })
                            }
                            return (<TableLineIcons
                                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                                genericIcons={genericIcons}
                            />)
                            // <span>
                            //     <Tooltip title={this.getText(11858)}>
                            //         <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                            //             this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                            //         }} />
                            //     </Tooltip>
                            //     <Tooltip title={this.getText(11859)}>
                            //         <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"hdd"}
                            //             onClick={() => { this.props.uiActions.showModal(TableIDs.modalCreateEditItem, { ...this.props.ui.data, itemId: record['mItemCode'], fromMatrix: true }); }} />
                            //     </Tooltip>

                            // </span >);
                        } else {
                            let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                            return (<TableLineIcons
                                onCheck={() => {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token,
                                        isCreate ? this.api.create : this.api.edit, this.api.get);
                                }}
                                onClose={() => {
                                    if (isCreate) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else this.props.dataActions.genericDataCancelEditing(this.id);
                                }}
                            />)
                            // return (<span>
                            //     <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                            //         onClick={() => {
                            //             if (isCreate)
                            //                 this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get);
                            //             else
                            //                 this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                            //         }} />
                            //     <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                            //         onClick={() => {
                            //             if (isCreate)
                            //                 this.props.dataActions.genericDataCancelInlineCreate(this.id);
                            //             else
                            //                 this.props.dataActions.genericDataCancelEditing(this.id);
                            //         }} />
                            // </span>);
                        }
                    },
                }, {
                    title: "",
                    dataIndex: "mUpdateLine",
                    key: "mUpdateLine",
                    width: '5%',
                    render: (text, record) => {
                        return (<Checkbox checked={text == '1'} onChange={e => {
                            let allData = this.props.data[this.id].data.map((x, i) => {
                                if (i != record.index) return x;
                                let y = x.split("\f");
                                y[19] = e.target.checked ? "1" : "0";
                                return y.join("\f")
                            })
                            this.props.dataActions.setJson(this.id, { data: allData })
                        }} />)
                    },
                },
            ];

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}

                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <div style={{ fontSize: 18, textWeight: 'bold', color: 'rgba(0, 0, 0, 0.9)', paddingBottom: 4, paddingLeft: 8, paddingRight: 8 }}>
                    {(this.props.ui.data) ? this.getText(11860) + " " + this.props.ui.data.mNumber : null}
                </div>


                <ResponsiveTable
                    tableOnly={true}
                    percentageToMobile={[false, 30, 30, 40]}
                    ui={this.props.ui}
                    idTable={this.props.data[this.id]}
                    dataSource={dataSource}
                    columns={columns}
                    onChange={this.handleTableChange}
                    pagination={{ pageSize: 10 }}
                />

                <ModalCreateEditItem
                    id={TableIDs.modalCreateEditItem}
                    context={this.id} context_api_get={this.api.get}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditItem)}
                />

                <GenericModal
                    visible={this.state.modalBonusCalcData != null}
                    onCancel={this.onCloseModalBonusCalcdata}
                    title={this.getText(19418)}
                    width={600}
                >
                    {this.state.modalBonusCalcData ?
                        <ResponsiveTable
                            tableOnly
                            dataSource={this.state.modalBonusCalcData}
                            columns={[
                                { title: this.getText(19419), key: "desc", dataIndex: "desc", render: t => t },
                                { title: this.getText(19420), key: "oData", dataIndex: "oData", render: (t, r) => t != r.nData ? (<span style={{ color: 'red' }}>{t}</span>) : t },
                                { title: this.getText(19421), key: "nData", dataIndex: "nData", render: this.getInputBonusCalcData },
                            ]}
                            pagination={false}
                        />
                        : ""}
                </GenericModal>

            </Modal>
        )
    }

    onCloseModalBonusCalcdata = () => {
        let newData = {}
        if (this.state.modalBonusCalcData) this.state.modalBonusCalcData.forEach(x => { newData = { ...newData, [x.key]: x.nData } });
        let allData = this.props.data[this.id].data.map((x, i) => {
            if (i != this.state.modalBonusCalcDataIndex) return x;
            let y = x.split("\f");
            y[17] = JSON.stringify(newData);
            if (this.state.modalBonusCalcDataUpdated) y[19] = "1";
            return y.join("\f")
        })
        this.props.dataActions.setJson(this.id, { data: allData })
        this.setState({ modalBonusCalcData: null, modalBonusCalcDataIndex: null, modalBonusCalcDataUpdated: false })
    }

    getInputBonusCalcData = (text, record) => {
        let newData = {}
        if (this.state.modalBonusCalcData) this.state.modalBonusCalcData.forEach(x => { newData = { ...newData, [x.key]: x.nData } })

        return (<InputNumber value={text} onChange={e => {
            newData[record.key] = e;
            switch (record.key) {
                case 'mBaseCost': case 'mSupDscnt1': case 'mSupDscnt2': case 'mSupDscnt3':
                    newData.mCostIncludeDscnt = this.calcIncludeDscnt(newData.mBaseCost, newData.mSupDscnt1, newData.mSupDscnt2, newData.mSupDscnt3);
                    newData.mCostIncludeBonus = this.calcIncludeBonus(newData.mCostIncludeDscnt, newData.mBonusQty, newData.mQtyForBonusQty);
                    break;
                case 'mCostIncludeDscnt':
                    newData.mBaseCost = this.calcBaseCost(e, newData.mSupDscnt1, newData.mSupDscnt2, newData.mSupDscnt3);
                    newData.mCostIncludeBonus = this.calcIncludeBonus(e, newData.mBonusQty, newData.mQtyForBonusQty);
                    break;
                case 'mBonusQty': case 'mQtyForBonusQty':
                    newData.mCostIncludeBonus = this.calcIncludeBonus(newData.mCostIncludeDscnt, newData.mBonusQty, newData.mQtyForBonusQty);
                    break;
                case 'mCostIncludeBonus':
                    newData.mBaseCost = this.calcBaseCost(e, newData.mSupDscnt1, newData.mSupDscnt2, newData.mSupDscnt3);
                    newData.mCostIncludeDscnt = e;
                    newData.mBonusQty = 0;
                    newData.mQtyForBonusQty = 0;
                    break;
            }

            this.setState({
                modalBonusCalcData: this.state.modalBonusCalcData.map(x => { return { ...x, nData: newData[x.key] } }),
                modalBonusCalcDataUpdated: true,
            })
        }} />)
    }

    calcIncludeDscnt = (baseCost, dscnt1, dscnt2, dscnt3) => {
        let b = parseFloat(baseCost ?? 0) ?? 0;
        let d1 = (100 - (parseFloat(dscnt1 ?? 0) ?? 0)) / 100;
        let d2 = (100 - (parseFloat(dscnt2 ?? 0) ?? 0)) / 100;
        let d3 = (100 - (parseFloat(dscnt3 ?? 0) ?? 0)) / 100;
        return ((b * d1 * d2 * d3) ?? 0).toFixed(2)
    }

    calcBaseCost = (includeDscnt, dscnt1, dscnt2, dscnt3) => {
        let b = parseFloat(includeDscnt ?? 0) ?? 0;
        let d3 = (100 + (parseFloat(dscnt3 ?? 0) ?? 0)) / 100;
        let d2 = (100 + (parseFloat(dscnt2 ?? 0) ?? 0)) / 100;
        let d1 = (100 + (parseFloat(dscnt1 ?? 0) ?? 0)) / 100;
        return ((b * d3 * d2 * d1) ?? 0).toFixed(2)
    }

    calcIncludeBonus = (includeDscnt, bQty, qtyForB) => {
        let b = parseFloat(includeDscnt ?? 0) ?? 0;
        let q = parseFloat(bQty) > 0 && parseFloat(qtyForB) > 0 ? parseFloat(qtyForB) / (parseFloat(qtyForB) + parseFloat(bQty)) : 1
        return ((b * q) ?? 0).toFixed(2)
    }

    create_retrun_purchase_doc = () => {
        const dataID = this.props.ui.data;
        console.log("create_retrun_purchase_doc", dataID);

        let dataSend = Object.keys(dataID).join('\f') + '\r' + Object.values(dataID).join('\f');
        //let dataSend = "mWorkerId\fmIsPunchIn\r" + this.state.employeeID + '\f' + ;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.api.createRetrunPurchaseDoc, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
    };
    successCallback = (ob: Object) => {
        console.log("success:", ob.data);
        this.props.uiActions.hideModal(TableIDs.modalEditPurchaseDocument);

        if (ob.data > 0) {
            const str = this.getText(11861) + ' ' + ob.data;
            message.success(str);

            /* generate report */
            this.setReportParametersAndDo("ReturnPurchaseReport", {
                DocumentNumber: ob.data,/*2400050,*//*this.props.ui.data.mNumber,*/
                DocumentTypeID: 18,        /*this.props.ui.data.mDocType*/
                DocumentPOSID: 999,/*2, //this.props.ui.data.mPOSID,*/
                CompanyCode: this.props.user.companyCode,
                userID: this.props.user.userId,
                userName: this.props.user.displayName,
                isOriginal: 1,
                reportName: "reports/DocumentCopy." + this.state.type
            }, () => { this.generateReport.apply(this, ["ReturnPurchaseReport"]) })
            //this.setReportParameters("PaymentsSearchReport", { reportName: "reports/paymentsSearch." + this.state.type, type: value });         
        } else {
            message.error(this.getText(17303));
        }
    }
    errorCallback = (error: any) => {
        message.error(this.getText(11862));
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

}

export default ModalEditPurchaseDocument;

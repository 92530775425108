import React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Select, Button } from 'antd';
import ExtendedSelector from "./ExtendedSelector";


const { Option, OptGroup } = Select;

class SelectorTaxType extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
    }

    render() {
        let attr = {
            styleSelector: this.props.styleSelector,
            user: this.props.user,
            disabled: this.props.disabled,
            onSelect: this.props.onSelect,
            value: this.props.value,
            filter: this.props.filter,

            ui: this.props.ui,
            uiActions: this.props.uiActions,

            showTitle: true,
            title: "סוג מס",
            placeholder: "בחר סוג מס",
            api: "get_tax_types",
            onButtonClick: () => {},
        }

        return (
            <ExtendedSelector {...attr} />
        )
    }
};


export default SelectorTaxType;

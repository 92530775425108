import React from "react";
import { Icon, Modal } from 'antd';
import PageToolbar from "./PageToolbar";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";



class ModalPricesHistory extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = { visible: false, modalBonusCalcData: null }
        this.id = "PRICES_LOG";
        this.api = "get_prices_log";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prev) {
        if (!prev.visible && this.props.visible) {
            this.props.dataActions.genericDataSetFilter(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                {
                    itemId: this.props.ui.data.itemId,
                    forPrices: this.props.forPrices ? '1' : '0'
                },
                this.api);
            this.setState({ visible: true })
        }
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api);
    }

    render() {
        const { visible, close } = this.props;

        const status = (t, r) => {
            let calc = parseFloat(r.gPriceAfterChange) - parseFloat(r.gPriceBeforeChange);
            if (calc > 0) {
                return (<Icon style={{ color: 'green', fontSize: 20 }} type={"arrow-up"} />)
            } else if (calc < 0) {
                return (<Icon style={{ color: 'red', fontSize: 20 }} type={"arrow-down"} />)
            } else {
                return ""
            }
        }

        const actionType = (t) => {
            switch (t) {
                case '1': return this.getText(17377);
                case '2': return this.getText(17378);
            }
        }

        const bonusCalcData = (t, r) => {
            if (r.gAfterBaseCost) {
                return (<Icon type="eye" onClick={() => {
                    const {
                        gBeforeBaseCost,
                        gBeforeSupDscnt1,
                        gBeforeSupDscnt2,
                        gBeforeSupDscnt3,
                        gBeforeCostIncludeDscnt,
                        gBeforeBonusQty,
                        gBeforeQtyForBonusQty,
                        gBeforeCostIncludeBonus,
                        gAfterBaseCost,
                        gAfterSupDscnt1,
                        gAfterSupDscnt2,
                        gAfterSupDscnt3,
                        gAfterCostIncludeDscnt,
                        gAfterBonusQty,
                        gAfterQtyForBonusQty,
                        gAfterCostIncludeBonus
                    } = r;

                    let modalBonusCalcData = [
                        { desc: this.getText(19305), before: gBeforeBaseCost, after: gAfterBaseCost },
                        { desc: this.getText(19306), before: gBeforeSupDscnt1, after: gAfterSupDscnt1 },
                        { desc: this.getText(19307), before: gBeforeSupDscnt2, after: gAfterSupDscnt2 },
                        { desc: this.getText(19308), before: gBeforeSupDscnt3, after: gAfterSupDscnt3 },
                        { desc: this.getText(19309), before: gBeforeCostIncludeDscnt, after: gAfterCostIncludeDscnt },
                        { desc: this.getText(19310), before: gBeforeBonusQty, after: gAfterBonusQty },
                        { desc: this.getText(19311), before: gBeforeQtyForBonusQty, after: gAfterQtyForBonusQty },
                        { desc: this.getText(19312), before: gBeforeCostIncludeBonus, after: gAfterCostIncludeBonus },
                    ];
                    this.setState({ modalBonusCalcData })
                }} />)
            }
        }

        const bonusDataRender = (t, r) => r.before != r.after ? (<span style={{ color: "red" }}>{t}</span>) : t

        let firstCol = this.props.forPrices ? 'gPriceListName' : 'gSuppName';
        let firstTitle = this.props.forPrices ? 14292 : 16510;

        let columns = [
            { title: this.getText(firstTitle), key: firstCol, dataIndex: firstCol, width: '20%', render: (t) => t },
            { title: this.getText(14288), key: 'gPriceBeforeChange', dataIndex: 'gPriceBeforeChange', width: '10%', render: (t) => t },
            { title: this.getText(14289), key: 'gPriceAfterChange', dataIndex: 'gPriceAfterChange', width: '10%', render: (t) => t },
            { title: this.getText(14290), key: 'gTimestamp', dataIndex: 'gTimestamp', width: '15%', render: (t) => t },
            { title: this.getText(14291), key: 'gUserName', dataIndex: 'gUserName', width: '15%', render: (t) => t },
            { title: this.getText(17376), key: 'gAction', dataIndex: 'gAction', width: '10%', render: actionType },
            { title: this.getText(14293), width: '10%', render: status },
            { title: this.getText(19323), width: '10%', render: bonusCalcData },
        ];

        let title = (<PageToolbar
            title={this.getText(14294)} isModal={true}
            actionButtons={[{ buttonType: 'refresh', buttonFunction: this.refreshData }]}
            ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataActions={this.props.dataActions}
        />);


        return (<Modal destroyOnClose={true} onCancel={close} visible={visible} title={title} footer={false} width={'90%'}>
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                tableOnly={true}
                columns={columns}
                percentageToMobile={[40, 30, 30]}
                scroll={{ y: window.innerHeight - 300 }}
                pagination={false}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
            <GenericModal
                visible={this.state.modalBonusCalcData != null}
                onCancel={() => { this.setState({ modalBonusCalcData: null }) }}
                width={600}
                title={this.getText(19323)}>
                <ResponsiveTable
                    tableOnly
                    dataSource={this.state.modalBonusCalcData}
                    columns={[
                        { title: "", key: "desc", dataIndex: "desc", width: "50%", render: bonusDataRender },
                        { title: this.getText(19324), key: "before", dataIndex: "before", width: "25%", render: bonusDataRender },
                        { title: this.getText(19325), key: "after", dataIndex: "after", width: "25%", render: bonusDataRender },
                    ]}
                    pagination={false}
                />
            </GenericModal>
        </Modal>)
    }
}
export default ModalPricesHistory;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import { Button, Form, Select } from "antd";
import DateTimePicker from "../../components/DateTimePicker";


class InsertUsersLog extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalFilter: false,
            modalFilterUser: 0,
            modalFilterFromDate: null,
            modalFilterToDate: null,
            modalFilterUserIP: null,

            userList: [],
            userIPList: [],
        };

        this.id = "INSERT_USERS_LOG";

        this.api = {
            get: "get_insert_users_log",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(18856);
    }

    updateUserList = () => {
        let userList = new Set();
        let userIPList = new Set();

        const { modalFilterUser, modalFilterUserIP } = this.state;
        if (!modalFilterUser && !modalFilterUserIP) {
            this.props.data[this.id].data.forEach(x => {
                let y = x.split("\f");
                userList.add(y[1] + "#" + y[2])
                userIPList.add(y[6])
            })

            this.setState({ userList, userIPList })
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                title={gt(18857)}
                actionButtons={[{ buttonType: 'filter', buttonFunction: () => { this.setState({ modalFilter: true }, this.updateUserList) } }]}
                id={this.id}
                getApi={this.api.get}
                filters={{
                    _userId: null,
                    _fromDate: null,
                    _toDate: null,
                    _userIP: null,
                }}
                columns={[
                    { title: gt(17587), key: 'nUserID', dataIndex: 'nUserID', width: '10%', render: t => t },
                    { title: gt(17588), key: 'nUserName', dataIndex: 'nUserName', width: '40%', render: t => t },
                    { title: gt(17589), key: 'nDateTime', dataIndex: 'nDateTime', width: '20%', render: t => t },
                    { title: gt(17590), key: 'nIP', dataIndex: 'nIP', width: '30%', render: t => t },
                ]} />
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                width={600}
                title={this.getText(18859)}
                footer={[
                    <Button onClick={() => {
                        this.setState({ modalFilter: false })
                    }}>{this.getText(18864)}</Button>,
                    <Button onClick={() => {
                        this.setState({
                            modalFilterUser: null,
                            modalFilterFromDate: null,
                            modalFilterToDate: null,
                            modalFilterUserIP: null,
                        });
                    }}>{this.getText(18865)}</Button>,
                    <Button type="primary" onClick={() => {
                        this.setState({ modalFilter: false }, () => {
                            this.props.dataActions.genericDataSetFilter(
                                this.id,
                                this.props.user.companyCode,
                                this.props.user.token,
                                {
                                    _userId: this.state.modalFilterUser,
                                    _fromDate: this.state.modalFilterFromDate,
                                    _toDate: this.state.modalFilterToDate,
                                    _userIP: this.state.modalFilterUserIP,
                                },
                                this.api.get)
                        })
                    }}>{this.getText(18866)}</Button>,
                ]}>
                <Form.Item label={this.getText(18860)}>
                    <Select
                        key={"USER_SELECTOR"}
                        showSearch
                        filterOption={true}
                        optionFilterProp="valuefilter"
                        style={{ width: 300 }}
                        value={this.state.modalFilterUser}
                        onChange={e => { this.setState({ modalFilterUser: e }) }}>
                        {[...this.state.userList].map(x => {
                            let y = x.split("#");
                            return (<Select.Option dir={"rtl"} key={x} value={y[0]} valuefilter={y[0] + " " + y[1]}>{y[0] + " - " + y[1]}</Select.Option>)
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label={this.getText(18861)}>
                    <DateTimePicker
                        format="DD/MM/YYYY"
                        value={this.state.modalFilterFromDate}
                        onChange={e => { this.setState({ modalFilterFromDate: e }) }}
                        maxDate={this.state.modalFilterToDate}
                    />
                </Form.Item>
                <Form.Item label={this.getText(18862)}>
                    <DateTimePicker
                        format="DD/MM/YYYY"
                        value={this.state.modalFilterToDate}
                        onChange={e => { this.setState({ modalFilterToDate: e }) }}
                        minDate={this.state.modalFilterFromDate}
                    />
                </Form.Item>
                <Form.Item label={this.getText(18863)}>
                    <Select
                        key={"USER_IP_SELECTOR"}
                        showSearch
                        filterOption={true}
                        optionFilterProp="valuefilter"
                        style={{ width: 300 }}
                        value={this.state.modalFilterUserIP}
                        onChange={e => { this.setState({ modalFilterUserIP: e }) }}>
                        {[...this.state.userIPList].map(x => (<Select.Option dir={"rtl"} key={x} value={x} valuefilter={x}>{x}</Select.Option>))}
                    </Select>
                </Form.Item>
            </GenericModal>
        </div>)
    }

}

export default InsertUsersLog;
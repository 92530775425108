
import React from "react";
import {
    Form, Row, Col, Input, Icon, Button,
    Tooltip, Modal, Checkbox, Select
} from 'antd';

//import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableIDs from '../data/TableIDs.js';
import PageToolbar from "./PageToolbar";
import GenericSelector from "./GenericSelector";
import Jax from "../Jax/jax.es6.module";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
import GenericSelectorSuppliers from "./GenericSelectorSuppliers.js";
import GenericSelectorItemTags from "./GenericSelectorItemTags";
import TagRelationRadio from "./TagRelationRadio";
import { escapeRegExp } from "../utils/utils";


const FormItem = Form.Item;
const { Option, OptGroup } = Select;
/*
type Props = {
    //title: string,
    apiCalls: any[],
    api_create: any,
    text:any,
};
*/
//type State = {}

type TagObj = {
    type: {
        id: string,
        text: string,
    },
    tags: {
        id: string,
        text: string,
    }[],
}

class ModalAdvanceSearch extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.id = "ADVANCE_ITEM_SEARCH";
        this.fi_id = "FATHER_ITEMS_LIST";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.fi_id] === undefined) { props.data[this.fi_id] = { ...props.data.genericSelector }; }

        if (props.data[TableIDs.genericSelectorSalesAlerts] === undefined) {
            props.data[TableIDs.genericSelectorSalesAlerts] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorTaxType] === undefined) {
            props.data[TableIDs.genericSelectorTaxType] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorRuler] === undefined) {
            props.data[TableIDs.genericSelectorRuler] = { ...props.data.genericSelector };
        }

        if (props.data["MIVZA_LIST"] === undefined) props.data["MIVZA_LIST"] = { ...props.data.genericSelector };


        this.api = {
            selectorTags: "get_tags_with_types",
            selectorAlerts: "get_sale_alert_list",
            selector_suppliers: "get_suppliers",
            selector_tax_type: "get_tax_types",
            get: "get_items_list",
            get_fi: "get_father_items_list",
            // get: "get_matrix_items_table"
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.state = {
            loading: false,
            visible: false,
            modalSelectorFatherItems: false,
            request: {
                _filter: '', //this.props.searchInput,
                _tagList: '',
                _isAll: '',
                _isActive: '',
                _isWeightable: '',
                _isFixedPrice: '',
                _isForSale: '',
                _isDiscount: '',
                _saleAlert: '',
                _supplierList: '',
                _isForInventory: '',
                _isSerialize: '',
                _isMatrixReverse: '',
                _connectedItems: '',
                _taxType: '',
                _fatherItemsList: '',
                _searchColFilter: '1',
                _notAssociatedTag: '0',
                _notAssociatedSupp: '0',
                _rulerList: '',
                _notAssociatedRuler: '0',
                _itemSerialNumber: '',
                _supplierItemCode: '',
                _tagRelation: '1',
                _mivzaimList: '',
            }
        }
        //this.api_create = this.props.apiCalls.find(item => item.apiType === 'create').apiCall;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prev) {
        let id = this.props.context;
        let curData = id ? this.props.data[id] : null;
        let prevData = id ? prev.data[id] : null;

        if (curData && prevData && prevData.filters !== curData.filters) {
            let request = curData.filters;
            if (this.props.valueSearch) request._filter = this.props.valueSearch;
            if (request) {
                setTimeout(() => {
                    console.log("request changed", request)
                    this.setState({ request })
                }, 1000);
            }
        }

        if (this.props.filterRequest && this.props.filterRequest !== prev.filterRequest) {
            setTimeout(() => {
                this.setState({ request: this.props.filterRequest })
            }, 1000);
        }
    }

    // send data to parent
    // componentWillReceiveProps(newProps) {
    //     //alert("on props: " + JSON.stringify(newProps));        
    //     let _state = this.state;
    //     _state.visible = newProps.toggle;
    //     this.setState(_state);
    // }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSalesAlerts, this.props.user.companyCode, this.props.user.token, this.api.selectorAlerts);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorTags);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_suppliers);
    }

    showModal = () => {
        this.setState({ visible: true, });
    }

    setFilter = (request) => {
        if (this.props.onFilterOk) {
            this.props.onFilterOk(request)
        } else {
            this.props.dataActions.genericDataSetFilter(
                this.props.context, this.props.user.companyCode, this.props.user.token, request, this.props.getApi);
        }
    }

    handleOk = () => {
        console.log("search...", this.props.getApi);

        this.setFilter({
            ...this.state.request,
            page: 1,
            _page_num: 1,
            _filter: escapeRegExp(this.state.request._filter),
            //  _filter: this.state.request._filter
            // .trim()
            // .replace(/\s\s+/g, ' ')
            // .replace(/\'/g, "\\\'")
        });

        if (this.props.onChangeSearch) this.props.onChangeSearch(this.state.request._filter)

        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
        if (this.props.setFilterActive) this.props.setFilterActive(true)
    }

    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }

    booleanDropMenu = (label_name, field) => {
        return (
            <FormItem label={label_name} /*{...formItemLayout}*/>
                <Select
                    value={(this.state.request[field] === '') ? "none" : this.state.request[field]}
                    defaultValue="none"
                    style={{ width: 100 }}
                    onChange={(e) => {
                        let r = { ...this.state.request };
                        r[field] = e;
                        this.setState({ ...this.state, request: r });
                    }}
                >
                    <Option value="yes">{this.getText(10320)}</Option>
                    <Option value="no">{this.getText(10321)}</Option>
                    <Option value="none">-</Option>
                </Select>
            </FormItem>
        )
    }

    modalSelectorFatherItems = () => {
        let close = () => { this.setState({ modalSelectorFatherItems: false }) }
        return (<Modal
            visible={this.state.modalSelectorFatherItems} onCancel={close}
            footer={(<Button onClick={close}>{this.getText(14298)}</Button>)}
            title={(<PageToolbar
                title={this.getText(14295)} actionButtons={[]}
                isModal={true}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataActions={this.props.dataActions}
            />)}
        >
            <ResponsiveTable
                // idTable={this.props.data[this.fi_id]}
                dataSource={this.props.data[this.fi_id].dataset.map((x, i) => { return { ...x, key: x.code, index: i } })}
                tableOnly={true}
                columns={[
                    { title: this.getText(14296), key: "code", dataIndex: "code", render: t => t },
                    { title: this.getText(14297), key: "name", dataIndex: "name", render: t => t },
                ]}
                pagination={{ pageSize: 10 }}
                rowSelection={{
                    onChange: (e) => {
                        this.setState({ request: { ...this.state.request, _fatherItemsList: e.join(',') } })
                    }
                }}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
        </Modal>)
    }

    apiFatherItems = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.fi_id, this.props.user.companyCode, this.props.user.token, this.api.get_fi);
    }

    render() {

        const { visible, loading, re } = this.state;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
        }

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        const alertList = this.props.data[TableIDs.genericSelectorSalesAlerts].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        });

        //let tagList = this.props.data[TableIDs.genericSelectorItemTagTypes].dataset.map(
        // let tagList = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map(
        //     (node: TagObj, index) => {
        //         return (
        //             <OptGroup label={node.type.text} key={"type_" + node.type.id}>
        //                 {node.tags.map((item: string, index) => {
        //                     return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
        //                 })}
        //             </OptGroup>
        //         )
        //     }
        // )

        // let supplierList = Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => {
        //     return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        // });

        const isMatrix = this.props.getApi === 'get_matrix_items_table'

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10322)}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)


        let colStyle = window.innerWidth > 600 ? 6 : 24;

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'70%'}
                visible={this.props.toggle}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={/**/null}
            // <Button key="back" onClick={this.handleCancel}>ביטול</Button>,
            // <Button key="clear" onClick={this.handleReset}>ניקוי</Button>,
            // <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>חיפוש</Button>,
            //]} 
            >
                <Form layout="horizontal" className="ant-advanced-search-form" onSubmit={this.handleSearch}>

                    <FormItem label={this.getText(10323)} {...formItemLayout}>
                        <Input placeholder={this.getText(10334)}
                            value={
                                //this.props.searchInput
                                (this.state.request['_filter']) ? this.state.request['_filter']
                                /*.trim().replace(/\s\s+/g, ' ').replace(/\\\'/g, "'")*/ : null
                            }
                            onChange={this.onChangeInput}
                            onBlur={this.handleFilterBlur}
                        />
                    </FormItem>
                    <FormItem label={this.getText(18950)} {...formItemLayout}>
                        <Input
                            value={this.state.request._itemSerialNumber}
                            onChange={e => { this.setState({ request: { ...this.state.request, _itemSerialNumber: e.target.value } }) }}
                        />
                    </FormItem>
                    <FormItem label={this.getText(18952)} {...formItemLayout}>
                        <Input
                            value={this.state.request._supplierItemCode}
                            onChange={e => { this.setState({ request: { ...this.state.request, _supplierItemCode: e.target.value } }) }}
                        />
                    </FormItem>
                    <FormItem>
                        <Row>
                            <Col span={window.innerWidth > 600 ? 16 : 24}>
                                <Row>
                                    <Col span={10}>{this.getText(10324)} :</Col>
                                    <Col span={14}>
                                        <GenericSelectorItemTags {...this.props} multi
                                            value={this.state.request['_tagList'] ? this.state.request['_tagList'].split(',') : []}
                                            onChange={this.handleTagSelectChange}
                                            disabled={this.state.request['_notAssociatedTag'] == '1'}
                                            notReport
                                        />
                                        <TagRelationRadio showed={this.state.request._tagList}
                                            checked={this.state.request._tagRelation == '1' ? true : false}
                                            onChange={(value) => {
                                                let r = { ...this.state.request };
                                                r._tagRelation = (value === true) ? 1 : 0
                                                this.setState({ ...this.state, request: r });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={window.innerWidth > 600 ? 8 : 24}>
                                <Checkbox
                                    checked={this.state.request['_notAssociatedTag'] == '1'}
                                    onChange={e => { this.setState({ request: { ...this.state.request, _notAssociatedTag: e.target.checked ? '1' : '0' } }) }}
                                >{this.getText(17312)}</Checkbox>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem>
                        <Row>
                            <Col span={window.innerWidth > 600 ? 16 : 24}>
                                <Row>
                                    <Col span={10}>{this.getText(10333)} :</Col>
                                    <Col span={14}>
                                        <GenericSelectorSuppliers
                                            {...this.props} multi
                                            value={this.state.request['_supplierList'] ? this.state.request['_supplierList'].split(',') : []}
                                            onChange={(e) => {
                                                let r = { ...this.state.request };
                                                r._supplierList = e.join(',');
                                                this.setState({ ...this.state, request: r });
                                            }}
                                            disabled={this.state.request['_notAssociatedSupp'] == '1'}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={window.innerWidth > 600 ? 8 : 24}>
                                <Checkbox
                                    checked={this.state.request['_notAssociatedSupp'] == '1'}
                                    onChange={e => { this.setState({ request: { ...this.state.request, _notAssociatedSupp: e.target.checked ? '1' : '0' } }) }}
                                >{this.getText(17313)}</Checkbox>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem>
                        <Row>
                            <Col span={window.innerWidth > 600 ? 16 : 24}>
                                <Row>
                                    <Col span={10}>{this.getText(17374)} :</Col>
                                    <Col span={14}>
                                        <GenericSelector
                                            {...this.props} multi
                                            id={TableIDs.genericSelectorRuler}
                                            api={"get_rulers_list"}
                                            value={this.state.request['_rulerList'] ? this.state.request['_rulerList'].split(',') : []}
                                            onChange={(e) => {
                                                let r = { ...this.state.request };
                                                r._rulerList = e.join(',');
                                                this.setState({ ...this.state, request: r });
                                            }}
                                            disabled={this.state.request['_notAssociatedRuler'] == '1'}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={window.innerWidth > 600 ? 8 : 24}>
                                <Checkbox
                                    checked={this.state.request['_notAssociatedRuler'] == '1'}
                                    onChange={e => { this.setState({ request: { ...this.state.request, _notAssociatedRuler: e.target.checked ? '1' : '0' } }) }}
                                >{this.getText(17375)}</Checkbox>
                            </Col>
                        </Row>
                    </FormItem>
                    {/* <GenericSelector
                            id={TableIDs.genericMultiSelectorItemTags} api={this.api.selectorTags}
                            multi={true} optionsGroup={true}
                            value={this.state.request['_tagList'] ? this.state.request['_tagList'].split(',') : []}
                            onChange={this.handleTagSelectChange}
                            user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
                        /> */}
                    <FormItem label={this.getText(16222)} {...formItemLayout}>
                        <GenericSelector
                            id={TableIDs.genericSelectorTaxType} api={this.api.selector_tax_type}
                            value={this.state.request['_taxType'] ? this.state.request['_taxType'].split(',') : []}
                            onChange={this.handleTaxTypeSelectChange} multi
                            user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
                            addFirstRow={{ code: "", name: "-" }}
                        />
                    </FormItem>
                    <Row>
                        <Col span={colStyle}>{this.booleanDropMenu(this.getText(10325), '_isActive')}</Col>
                        <Col span={colStyle}>{this.booleanDropMenu(this.getText(10326), '_isWeightable')}</Col>
                        <Col span={colStyle}>{this.booleanDropMenu(this.getText(10327), '_isForSale')}</Col>
                        <Col span={colStyle}>{this.booleanDropMenu(this.getText(10328), '_isFixedPrice')}</Col>
                    </Row>
                    <Row>
                        <Col span={colStyle}>{this.booleanDropMenu(this.getText(10329), '_isDiscount')}</Col>
                        <Col span={colStyle}>{this.booleanDropMenu(this.getText(10330), '_isForInventory')}</Col>
                        <Col span={colStyle}>{this.booleanDropMenu(this.getText(10331), '_isSerialize')}</Col>
                        <Col span={colStyle}>{!isMatrix ? this.booleanDropMenu(this.getText(12695), '_isMatrixReverse') : ""}</Col>
                    </Row>
                    <div
                    /*this.props.data['ITEMS_TABLE'].filters['_isActive']*/
                    /*this.props.data['ITEMS_TABLE'].filters['_isWeightable']*/
                    /*this.props.data['ITEMS_TABLE'].filters['_isForSale']*/
                    /*this.props.data['ITEMS_TABLE'].filters['_isFixedPrice'])}*/
                    /*this.props.data['ITEMS_TABLE'].filters['_isDiscount'])}*/
                    />
                    {!isMatrix ?
                        (<FormItem label={this.getText(16126)} {...formItemLayout}>
                            <Select
                                value={this.state.request._connectedItems}
                                style={{ width: '90%' }}
                                onChange={(e) => {
                                    this.setState({ ...this.state, request: { ...this.state.request, _connectedItems: e } });
                                }}>
                                <Option value="-1">{this.getText(16150)}</Option>
                                <Option value="0">{this.getText(16151)}</Option>
                                <Option value="1">{this.getText(16153)}</Option>
                                <Option value="2">{this.getText(16152)}</Option>
                                <Option value="3">{this.getText(16154)}</Option>
                            </Select>
                        </FormItem>)
                        : ""}
                    <FormItem label={this.getText(10332)} {...formItemLayout}>
                        <Select
                            showsearch
                            value={//this.props.data['ITEMS_TABLE'].filters['_saleAlert']
                                this.state.request['_saleAlert']
                            }
                            defaultValue=""
                            style={{ width: '90%' }}
                            placeholder={this.getText(10336)}
                            optionFilterProp="children"
                            onChange={this.handleSelectChange}
                            onFocus={this.handleSelectFocus}
                            onBlur={this.handleSelectBlur}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            <Option value="">{this.getText(10338)}</Option>
                            {alertList}
                        </Select>

                    </FormItem>

                    <FormItem label={this.getText(19765)} {...formItemLayout}>
                        <GenericSelector
                            {...this.props}
                            id={"MIVZA_LIST"}
                            api={"mivza_list"}
                            multi
                            value={this.state.request._mivzaimList ? this.state.request._mivzaimList.split(',') : []}
                            onChange={e => { this.setState({ ...this.state, request: { ...this.state.request, _mivzaimList: e ? e.join(',') : "" } }) }}
                        />

                    </FormItem>

                    {/* {this.createCheckBox('_supplierList', Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", [])
                            .map((obj, index) => obj['code']).join(','))}
                        <Select
                            key={"_supplierList"}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={10}
                            placeholder={this.getText(10337)}
                            style={{ width: '90%', paddingLeft: '4px', paddingRight: '4px' }}
                            value={(this.state.request._supplierList === '') ? []
                                : this.state.request._supplierList.split(',')}
                            onChange={(e) => {
                                let r = { ...this.state.request };
                                r._supplierList = e.join(',');
                                this.setState({ ...this.state, request: r });
                            }}
                        >
                            {supplierList}
                        </Select> 
                         <GenericSelector
                            id={TableIDs.genericSelectorSuppliers} api={this.api.selector_suppliers}
                            multi={true}
                            value={this.state.request['_supplierList'] ? this.state.request['_supplierList'].split(',') : []}
                            onChange={(e) => {
                                let r = { ...this.state.request };
                                r._supplierList = e.join(',');
                                this.setState({ ...this.state, request: r });
                            }}
                            user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
                        /> */}

                    {this.props.filterFatherItems ?
                        (<FormItem label={this.getText(14295)} {...formItemLayout}>
                            <div onClick={() => { this.setState({ modalSelectorFatherItems: true }, this.apiFatherItems) }}>
                                <Select
                                    mode="multiple" maxTagCount={4} style={{ width: '100%' }}
                                    value={this.props.data[this.fi_id].dataset.filter((f) => {
                                        return this.state.request._fatherItemsList.split(',').indexOf(f.code) > -1
                                    }).map(x => x.name)}
                                ><Option value={""}> </Option></Select>
                            </div>
                        </FormItem>) : ""}

                    <FormItem>
                        <Button key="back" onClick={this.handleCancel}>{this.getText(10339)}</Button>
                        <Button key="clear" onClick={this.handleReset}>{this.getText(10340)}</Button>
                        <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(10341)}</Button>
                    </FormItem>

                </Form >
                {this.props.filterFatherItems ? this.modalSelectorFatherItems() : ""}
            </Modal >
        )
    }

    createCheckBox = (field, data) => {
        let r = this.state.request;
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setState({ request: { ...r, [field]: data } });
                //this.setReportParameters("SalesItems", { [field]: data });
                else
                    this.setState({ request: { ...r, [field]: '' } });
                //this.setReportParameters("SalesItems", { [field]: '' });
            }} />);
    }

    // bind input field
    onChangeInput = (e) => {
        let r = { ...this.state.request };
        r._filter = e.target.value; //.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'");
        //r._filter = e.target.value.replace(/\'/g, "\\\'");
        this.setState({ ...this.state, request: r });
        //this.props.searchInputCallback(e);
    }

    handleFilterBlur = (e) => {
        let r = { ...this.state.request };
        r._filter = e.target.value; //.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'");
        //r._filter = e.target.value.replace(/\'/g, "\\\'");
        this.setState({ ...this.state, request: r });
        //this.props.searchInputCallback(e);
    }

    // tag select
    handleTagSelectChange = (e) => {
        let r = { ...this.state.request };
        r._tagList = e.join(',');
        this.setState({ ...this.state, request: r });

    }

    // tax type
    handleTaxTypeSelectChange = (e) => {
        let r = { ...this.state.request };
        r._taxType = e.join(',');
        this.setState({ ...this.state, request: r });

    }

    // filtering
    onChangeActive = (e) => {
        let r = { ...this.state.request };
        r._isActive = e;
        this.setState({ ...this.state, request: r });
    }
    onChangeDiscount = (e) => {
        let r = { ...this.state.request };
        r._isDiscount = e;
        this.setState({ ...this.state, request: r });
    }
    onChangeForSale = (e) => {
        let r = { ...this.state.request };
        r._isForSale = e;
        this.setState({ ...this.state, request: r });
    }
    onChangeWeightable = (e) => {
        let r = { ...this.state.request };
        r._isWeightable = e;
        this.setState({ ...this.state, request: r });
    }
    onChangeFixedPrice = (e) => {
        let r = { ...this.state.request };
        r._isFixedPrice = e;
        this.setState({ ...this.state, request: r });
    }


    // select sale alerts
    handleSelectChange = (e) => {
        let r = { ...this.state.request };
        r._saleAlert = e;
        this.setState({ ...this.state, request: r });
    }
    handleSelectBlur = () => { console.log('blur'); }
    handleSelectFocus = () => { console.log('focus'); }

    /// search
    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log('Received values of form: ', values);
        });
    }

    handleReset = () => {
        //this.props.searchInputCallback('');
        let _request = {
            _filter: '', //this.props.searchInput,
            _tagList: '',
            _isAll: '',
            _isActive: '',
            _isWeightable: '',
            _isFixedPrice: '',
            _isForSale: '',
            _isDiscount: '',
            _saleAlert: '',
            _supplierList: '',
            _isForInventory: '',
            _isSerialize: '',
            _isMatrixReverse: '',
            _connectedItems: '',
            _taxType: '',
            _fatherItemsList: '',
            _searchColFilter: '1',
            _notAssociatedTag: '0',
            _notAssociatedSupp: '0',
            _rulerList: '',
            _notAssociatedRuler: '0',
            _itemSerialNumber: '',
            _supplierItemCode: '',
            _tagRelation: '1',
            _mivzaimList: '',
        };
        this.setState({ request: _request },
            () => {
                this.setFilter(_request);
                if (this.props.setFilterActive) this.props.setFilterActive(false)
            }
        );

    }
}

export default ModalAdvanceSearch;

/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import {
    notification, Table, Icon, Select, Tooltip, message, Spin,
    Checkbox, Popconfirm, Button, Input, Modal, DatePicker, Form
} from "antd";
import moment from "moment";
import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ModalCreateStandingOrders from "../../components/ModalCreateStandingOrders";
import ModalUploadDebitCardChargesFile from "../../components/ModalUploadDebitCardChargesFile";
import ResponsiveTable from "../../components/ResponsiveTable";
//import Jax from "../../Jax/jax.es6.module";
//import { fixCurrentPage } from "../../utils/utils";
import ModalRunStandingOrders from "../../components/ModalRunStandingOrders";
import ModalStandingOrdersChargesReport from "../../components/ModalStandingOrdersChargesReport";
import ModalStandingOrdersChargesSummaryReport from "../../components/ModalStandingOrdersChargesSummaryReport";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
import GenericTable from "../../components/GenericTable";

const { Option, OptGroup } = Select;
const { MonthPicker } = DatePicker;
const FormItem = Form.Item;
const { TextArea } = Input;

const topHeight = 340; // px
const rowHeight = 30;  // px


type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class DirectDebitCharge extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "CHARGE_DIRECT_DEBIT_TABLE";

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            addButton: false,
            addValue: '',
            messageModal: false,
            message: '',
            messageID: null,
            docGenerate: false,

           };
        this.state.check_count = 0;
        this.state._id = 0;
        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }
        if (props.data[TableIDs.genericSelectorPreDefinedMsg] === undefined) {
            props.data[TableIDs.genericSelectorPreDefinedMsg] = { ...props.data.genericSelector };
        }

        //if (props.data[TableIDs.genericSelectorPrices] === undefined) {
        //    props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        //}

        this.fileName = '';

        this.api = {
            //get: "get_customers",
            get: "mock_standing_order_total",
            create: "create_standing_order_total",
            edit: "",
            delete: "delete_customer",
            selector: "",
            modalPriceListGet: "",
            get_count: "get_idle_standing_orders_count",

         };

        this.actionButtons = [
            /*{
                buttonType: 'add',
                buttonFunction: () => {
                    //this.props.uiActions.showModal(TableIDs.modalCreateEditStandingOrders, null);
                    this.props.dataActions.genericDataStartInlineCreate(this.id);
                }
            },*/ {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }

    sendAPIResAsBlob = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        //let job = new Job(user, script, OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_REPORT_A4, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }
    // downloadFailedChargesFile = (record) => {
    //     let user = new User(this.props.user.token, this.props.user.companyCode);
    //     let job = new Job(user, "download_failed_charges_file",
    //         //OutputType.OUTPUT_TYPE_REPORT_A4, ProcessType.PROCESS_TYPE_SYNC);
    //         //OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC);
    //         OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
    //     //job.setSection("file", fileUrl);
    //     //job.setInput();
    //     job.setInput(record["mId"]);
    //     job.send("/cgi-bin/CashOnTab", this.downloadSuccessCallback, this.downloadErrorCallback);
    // }

    downloadSuccessCallback = (ob: Object) => {
        message.success(this.getText(10844));
        console.log("success:", ob.data);

        var text = ob.data,
            blob = new Blob([text.replace(/([^\r])\n/g, "$1\r\n")], { type: 'text/plain' }),
            anchor = document.createElement('a');

        if (blob.size > 1 /*true*/) {
            let nameArr = this.fileName.split('.');
            nameArr.pop();
            anchor.download = nameArr.join('.') + ".arr";
            anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
            anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
            anchor.click();
        }
        else {
            //notification.warning({
            //    message: this.getText(10845),
            //    description: this.getText(10846),
            //});
            let nameArr = this.fileName.split('.');
            nameArr.pop();
            anchor.download = nameArr.join('.') + ".arr";
            anchor.href = (window.webkitURL || window.URL).createObjectURL(new Blob(["\x04"]));
            anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
            anchor.click();
        }
    }

    downloadErrorCallback = (error: any) => {
        message.error(this.getText(10847) + " " + JSON.stringify(error));
        //this.openNotificationWithIcon('error', 'שגיאה לא ידועה', 'descr');
    }

    downloadLogCommandFile = (record) => {
        //this.sendAPI(
        this.sendAPIResAsBlob(
            "download_log_command_file",
            record["mId"],
            this.downloadSuccessLogCommand,
            this.downloadErrorLogCommand);
    }

    downloadSuccessLogCommand = (ob: Object) => {
        message.success(this.getText(10844));
        /*
        const saveData = (function () {
            const a = document.createElement("a");
            if (document.body) {
                document.body.appendChild(a);
            }
            a.style.display = "none";
            return function (data, fileName) {
                let url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            };
        }());
        */

        var text = ob.rawData,
            //var text = "hello\nworld\n1111",
            //blob = new Blob([text.replace(/([^\r])\n/g, "$1\r\n")], { type: 'text/plain' }),
            //blob = new Blob([text.replace(/([^\r])\n/g, "$1\r\n")], { type: 'text/plain;charset=ISO-8859-1' }),
            //blob = new Blob([text], { type: 'text/plain;charset=ISO-8859-1' }),
            blob = new Blob([new Uint8Array(ob.rawData)], { type: 'text/plain;charset=ISO-8859-1' }),
            anchor = document.createElement('a');

        // console.log("DEBOZ xxxxx: ", ob.rawData);
        //console.log("DEBOZ xxxxx: ", [new Uint8Array(ob.rawData)]);
        //console.log("DEBOZ xxxx: ", blob);


        // let nameArr = this.fileName.split('.');
        // nameArr.pop();
        // anchor.download = nameArr.join('.') + ".arr";
        //this.fileName.split('.')[0] + ".err"; //"Workers_pay_with_icard.txt";
        anchor.download = 'Loadfnc.txt';
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
        //anchor.dataset.downloadurl = ['text/plain;charset=ISO-8859-1', anchor.download, anchor.href].join(':');
        anchor.click();

        //this.openNotificationWithIcon('success', 'שאילתא עברה', 'descr');
    }

    downloadErrorLogCommand = (error: any) => {
        message.error(this.getText(10847) + " " + JSON.stringify(error));
        //this.openNotificationWithIcon('error', 'שגיאה לא ידועה', 'descr');
    }


    componentDidMount() {

        this.initFilters();

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPreDefinedMsg, this.props.user.companyCode, this.props.user.token, "get_pre_defined_msg_list");

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10155);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            let p = this.props.ui.favoriteParams;
            if (p && p.reportName && p.reportName.indexOf("standingOrdersChargesSummary") > -1) {
                setTimeout(() => {
                    this.props.uiActions.showModal(TableIDs.modalStandingOrdershChargesSummaryReport)
                }, 1000);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 11;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mId": col--;
            case "mFileName": col--;
            case "mDate": col--;
            case "mRecordCount": col--;
            case "mSum": col--;
            case "mChargedSum": col--;
            case "mPeriod": col--;
            case "mBillable": col--;
            case "mBilled": col--;
            case "mNotBilled": col--;
            case "mStatus": break; // 10
            default:
                col = 0;
        }

        console.log("pagination: ", pagination,
            "\nsorter: ", sorter,
            "\ncol: ", col);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //sortColumn
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0,
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 0,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    generateDocuments = (key) => {
        let uiSelector = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset;
        if (uiSelector && uiSelector.find(f => f.code === '501')) {
            this.setState({ docGenerate: true }, () => {
                //this.sendAPI("create_doc_standing_orders", key, (ob) => {
                this.sendAPI("create_doc_standing_orders_with_sms", key, (ob) => {
                    let mText = this.getText(14068);
                    if (ob.data) {
                        let y = ob.data.split("\r");
                        if (y.length > 0) {
                            let fDoc = parseInt(y[0].split('\f')[1]);
                            let lDoc = parseInt(y.slice(-1)[0].split('\f')[1]);
                            let sumDocs = (lDoc - fDoc) + 1;

                            if (sumDocs > 1) {
                                mText = sumDocs + " " +
                                    this.getText(14066) + " " +
                                    fDoc + " - " + lDoc;
                            } else {
                                mText = this.getText(14067) + " " + fDoc;
                            }
                        }
                    }

                    this.refreshData();
                    this.setState({ docGenerate: false }, () => { message.success(mText) })
                }, (e) => {
                    console.errer(e);
                    this.refreshData();
                    this.setState({ docGenerate: false }, () => { message.error(this.getText(14272)) })
                })
            })
        }
    }

    generateReportLink = (fDoc, lDoc) => {
        let promises = [];
        for (let x = fDoc; x <= lDoc; x++) {
            let p = new Promise((resolve, reject) => {
                this.sendAPI("get_document_report_link",
                    "docNum\fdocType\fdocPos\fisOriginal\r" + x + "\f3\f999\f1",
                    (ob) => { resolve(this.sendReportLink(x, ob.data)) },
                    (e) => { reject(e) })
            });
            promises.push(p)
        }

        Promise.allSettled(promises).then((results) => {
            this.setState({ messageModal: false }, () => {
                console.log("yuda promises ", results);
            });
        })
    }

    sendReportLink = (docNum, link) => {
        return new Promise((resolve, reject) => {
            this.sendAPI("send_receipt_to_customers",
                "docNum\fdocLink\fmessageID\r" + docNum + "\f" + link + "\f" + this.state.messageID,
                (ob) => { resolve({ link: link, docNum: docNum, data: ob.data }) },
                (e) => { reject(e) })
        });
    }

   
    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mFileName: y[1],
                mDate: y[2],
                mRecordCount: y[3],
                mSum: y[4],
                mChargedSum: [10],
                mPeriod: y[5],
                mBillable: y[6],
                mBilled: y[7],
                mNotBilled: y[8],
                mStatus: y[9],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        //let list = this.props.data[TableIDs.genericSelectorPrices].dataset.map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        //});

        let genericActionButtons = [
            (<Tooltip title={this.getText(10848)}>
                <Button key="upload_file" className={"btn"} /*disabled={(this.props.data[this.id_directdebit].editing > -1)}*/
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => this.props.uiActions.showModal(TableIDs.modalUploadDebitCardChargesFile)}
                >
                    <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="cloud-upload" theme="outlined" />
                </Button>
            </Tooltip>),
            (<Tooltip title={this.getText(10849)}>
                <Button key="report_summary" className={"btn"} /*disabled={(this.props.data[this.id_directdebit].editing > -1)}*/
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => this.props.uiActions.showModal(TableIDs.modalStandingOrdershChargesSummaryReport)}
                >
                    <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="file" theme="outlined" />
                </Button>
            </Tooltip>)
        ];

        let addModule = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset ?
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '501')
            : false;

        let actionButtons = addModule ? [
            {
                buttonType: 'add',
                buttonFunction: () => { this.setState({ addButton: true, addValue: '' }) }
            },
            ...this.actionButtons
        ] : this.actionButtons

        let columns = [
            {
                title: this.getText(10832),
                dataIndex: "mId",
                key: "mId",
                sorter: false,
                width: '5%',
                render: (text, record) => text,
            }, {
                title: this.getText(10833),
                dataIndex: "mFileName",
                key: "mFileName",
                sorter: false,
                width: '10%',
                render: (text, record) => text,
            }, {
                title: this.getText(10834),
                dataIndex: "mDate",
                key: "mDate",
                sorter: false,
                width: '12%',
                render: (text, record) => text,
            }, {
                title: this.getText(10835),
                dataIndex: "mRecordCount",
                key: "mRecordCount",
                sorter: false,
                width: '5%',
                render: (text, record) => text,
            }, {
                title: this.getText(10836),
                dataIndex: "mSum",
                key: "mSum",
                sorter: false,
                width: '8%',
                render: (text, record) => text,
            }, {
                title: this.getText(10837),
                dataIndex: "mChargedSum",
                key: "mChargedSum",
                sorter: false,
                width: '8%',
                render: (text, record) => text,
            }, {
                title: this.getText(10838),
                dataIndex: "mPeriod",
                key: "mPeriod",
                sorter: false,
                width: '8%',
                render: (text, record) => (text.length === 4) ? text.slice(0, 2) + '/' + text.slice(-2) : text,
            }, {
                title: this.getText(10839),
                dataIndex: "mBillable",
                key: "mBillable",
                sorter: false,
                width: '8%',
                render: (text, record) => text,
            }, {
                title: this.getText(10840),
                dataIndex: "mBilled",
                key: "mBilled",
                sorter: false,
                width: '8%',
                render: (text, record) => text,
            }, {
                title: this.getText(10841),
                dataIndex: "mNotBilled",
                key: "mNotBilled",
                sorter: false,
                width: '8%',
                render: (text, record) => text,
            }, {
                title: this.getText(10842),
                dataIndex: "mStatus",
                key: "mStatus",
                sorter: false,
                width: '8%',
                render: (text, record) => {
                    if (text === '0') {
                        return this.getText(10850);
                    } else if (text === '1') {
                        return this.getText(10851);
                    } else if (text === '2') {
                        return this.getText(10852);
                    } else if (text === '3') {
                        return this.getText(10853);
                    } else if (text === '4') {
                        return this.getText(10854);
                    } else {
                        return this.getText(10855);
                    }

                }/*text*/,
            }, {
                title: this.getText(10843),
                width: '12%',
                render: (text, record) => {
                    /*
                    return (
                        <Tooltip title="ניהול הוראות קבע">
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"plus"} onClick={() => {
                                //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                this.props.uiActions.showModal(TableIDs.modalCreateEditStandingOrders, record['mId']);
                            }} />
                        </Tooltip>
                    )
                    */
                    if (this.props.data[this.id].editing !== record.key) {
                        return (
                            <span>
                                <Tooltip title={this.getText(10856)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"caret-right"}
                                        onClick={() => {
                                            const isValid: boolean = record['mStatus'] === '0' || record['mStatus'] === '3';
                                            if (!isValid) {
                                                // סיים קליטה קובץ ERR....
                                                notification.error({
                                                    message: this.getText(10861),
                                                    description: this.getText(10862),
                                                });
                                            } else {
                                                this.setState({ _id: record['mId'] }, () => this.getCount(record['mId']));
                                                //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                            }
                                        }}
                                    ></Icon>
                                </Tooltip>
                                <Tooltip title={this.getText(10857)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"file"}
                                        onClick={() => {
                                            //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                            this.props.uiActions.showModal(TableIDs.modalStandingOrdersChargesReport, { mId: record['mId'], mPeriod: record['mPeriod'] });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title={this.getText(10858)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"search"} onClick={() => {
                                        //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                        this.props.uiActions.showModal(TableIDs.modalCreateEditStandingOrders, record['mId']);
                                      }}
                                    />
                                </Tooltip>
                                <Tooltip title={this.getText(10859)}>
                                    <Popconfirm title={this.getText(10863)}
                                        onConfirm={(e) => {
                                            //e.stopPropagation();
                                            //if (record["mStatus"] === '4') {
                                            // נעול
                                            //    message.error('');
                                            //} else {
                                            this.fileName = record["mFileName"];
                                            // this.downloadFailedChargesFile(record);
                                            //}
                                            this.sendAPI(
                                                "download_failed_charges_file",
                                                record["mId"],
                                                this.downloadSuccessCallback,
                                                this.downloadErrorCallback);
                                        }
                                        }
                                        okText={this.getText(10864)} cancelText={this.getText(10865)}>
                                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"download"}
                                        //onClick={() => {
                                        //    this.fileName = record["mFileName"];
                                        //    this.downloadFailedChargesFile(record);
                                        //}}
                                        />
                                    </Popconfirm>
                                </Tooltip>
                                <Tooltip title={this.getText(10860)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"file-text"}
                                        onClick={() => {
                                            if (record["mStatus"] !== '4') {
                                                // נעול
                                                message.error(this.getText(10866));
                                            } else {
                                                // this.fileName = record["mFileName"];
                                                this.downloadLogCommandFile(record);
                                                //console.log('xxx-yuda work');
                                            }
                                        }}
                                    />
                                </Tooltip>
                                {addModule && record['mStatus'] === '3' ? (<Tooltip title={this.getText(14065)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"file-pdf"}
                                        onClick={() => { this.generateDocuments(record.key) }} />
                                </Tooltip>) : ""}
                                        </span >);
                    } else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)
                    }
                }
            }
        ];

        return (
            <div style={divStyle} >
                {/* <PageToolbar
                    title={'מנות חיוב להוראת קבע'}
                    actionButtons={this.actionButtons}
                    genericActionButtons={this.genericActionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
                            }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <Table dataSource={dataSource}
                    columns={this.columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                /> */}
                < ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    //dataSource={dataSource}
                    percentageToMobile={[30, 35, 35]}
                    title={this.getText(10831)}
                    actionButtons={actionButtons}
                    genericActionButtons={genericActionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <ModalCreateStandingOrders
                    id={TableIDs.modalCreateEditStandingOrders}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditStandingOrders)}
                    // title={"ניהול הוראות קבע"}
                    ActionQueue={this.props.ActionQueue}
                   
                />

                <ModalUploadDebitCardChargesFile
                    id={TableIDs.modalUploadDebitCardChargesFile}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalUploadDebitCardChargesFile)}
                    // title={"קליטת קובץ חיובים"}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalRunStandingOrders
                    id={TableIDs.modalRunStandingOrders}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalRunStandingOrders)}
                    // title={"הרצת הוראות קבע"}
                    ActionQueue={this.props.ActionQueue}
                    generateDocuments={this.generateDocuments}
                />

                <ModalStandingOrdersChargesReport
                    id={TableIDs.modalStandingOrdersChargesReport}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalStandingOrdersChargesReport)}
                    //title={"הפקת דוח חיובים למנה"}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalStandingOrdersChargesSummaryReport
                    id={TableIDs.modalStandingOrdershChargesSummaryReport}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalStandingOrdershChargesSummaryReport)}
                    // title={"הפקת דוח חיובים מסכם"}
                    ActionQueue={this.props.ActionQueue}
                />
                <Modal
                    destroyOnClose={true}
                    width={300}
                    visible={this.state.addButton}
                    title={this.getText(14021)}
                    onCancel={() => { this.setState({ addButton: false }) }}
                    footer={[<Button type="primary" onClick={() => {
                        this.sendAPI(this.api.create, this.state.addValue, () => {
                            this.setState({ addButton: false }, () => {
                                message.success(this.getText(14023))
                                this.refreshData();
                            })
                        }, (e) => { console.error(e) })
                    }}>{this.getText(14022)}</Button>]}
                >
                    <MonthPicker
                        value={this.state.addValue ? moment(this.state.addValue, "MMYY") : null}
                        format={"MM/YYYY"}
                        onChange={(date) => { this.setState({ addValue: date ? date.format("MMYY") : "" }) }}
                    />
                </Modal>
                <Modal
                    destroyOnClose={true} width={500} title={this.getText(14069)}
                    visible={this.state.messageModal}
                    onCancel={() => { this.setState({ messageModal: false }) }}
                    footer={[<Button key="sendLinks" type="primary" onClick={() => {
                        let dn = this.state.messageModal;
                        this.generateReportLink(dn.fDoc, dn.lDoc);
                    }}>{this.getText(14070)}</Button>]}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataAction={this.props.dataAction}
                >
                    <FormItem label={this.getText(12928)}>
                        <Select
                            style={{ width: '100%' }} showSearch filterOption={true} optionFilterProp="valuefilter"
                            value={this.state.messageID} onChange={(value) => {
                                this.sendAPI("get_message_def_value", value, (ob) => {
                                    this.setState({ message: ob.data, messageID: value })
                                }, (e) => { console.error(e) });
                            }}>
                            {this.props.data[TableIDs.genericSelectorPreDefinedMsg].dataset.map((obj) => {
                                return (<Option
                                    key={obj['code']} value={obj['code']} valuefilter={obj['name']}
                                >{obj['code'] + '-' + obj['name']}</Option>)
                            })}
                        </Select>
                    </FormItem>
                    <FormItem label={this.getText(12927)}>
                        <TextArea rows={5} value={this.state.message} />
                    </FormItem>
                </Modal >
              
                <Modal visible={this.state.docGenerate} footer={false}>
                    <div style={{ textAlign: 'center' }}>
                        <h1>{this.getText(14271)}</h1>
                        <br />
                        <br />
                        <Spin />
                    </div>
                </Modal>
            </div >
        )
    }

    getCount = (id) => {
        this.sendAPI(this.api.get_count, "mID\r" + id, this.SuccessCallback, this.ErrorCallback);
    }

    SuccessCallback = (ob: Object) => {
        console.log("success:", ob.data);

        if (ob.data > 0) {
            this.props.uiActions.showModal(TableIDs.modalRunStandingOrders, { mId: this.state._id, count: ob.data });
        } else {
            notification.error({
                message: this.getText(10867),
                description: this.getText(10868),
            });
        }
    }

    ErrorCallback = (error: any) => {
        //message.error('שגיאה לא ידועה', 2);
        notification.error({
            message: this.getText(10869),
            description: '',
        });
    }
}
export default DirectDebitCharge;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Form, Button, InputNumber, Select } from "antd";
import PageToolbar from "../../components/PageToolbar";
import type { Field } from "../../components/ModalForm";
import ItemPicker from "../../components/ItemPicker";



const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {};

class CustomerClub extends Component<Props, State> {
    DATA_SET_NAME: string = "CustomerClub";

    constructor(props: Props) {
        super(props);
        if (props.data[this.DATA_SET_NAME] === undefined) {
            props.data[this.DATA_SET_NAME] = Object.assign({}, props.data.genericData);
        }
        window.console.log(this.DATA_SET_NAME, props.data[this.DATA_SET_NAME]);
    }

    refreshData() {
        this.props.dataActions.genericDataGetEditRequested(this.DATA_SET_NAME, this.props.user.companyCode, this.props.user.token, "get_customer_club_settings");
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getCustomerTags(this.props.user.companyCode, this.props.user.token);
    }

    componentDidMount() {
        document.title = "Cash On Tab - ניהול מועדון לקוחות";
        this.refreshData();
    }

    createFormItem(field: Field) {
        return (<FormItem key={field.key} label={field.label} help={field.help} labelCol={{ span: 7 }} wrapperCol={{ span: 12 }}>
            {field.node}
        </FormItem>);
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

        };

        const toolbarActions: Array<Object> = [

            {
                buttonType: "refresh",
                buttonFunction: this.refreshData.bind(this)
            }
        ];

        const customerTags = this.props.data.customerTags.map(
            (node: Object) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: Object) => {
                            return (<Option key={item.id} valuefilter={item.text}>{item.text}</Option>);
                        })}
                    </OptGroup>
                )
            }
        );

        /*
        <h1>שימוש בנקודות</h1>
         */

        const formFields: Array<Field> = [
            {
                key: "clubNewItem",
                label: "פריט הקמת לקוח",
                help: "קוד פריט של הקמת לקוח חדש במועדון",
                node: (<ItemPicker defaultItemCode={this.props.data[this.DATA_SET_NAME].editedRecord.clubNewItem}
                    onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubNewItem: value.itemCode, clubNewItemName: value.itemName }) }}
                    label={this.props.data[this.DATA_SET_NAME].editedRecord.clubNewItemName}
                    data={this.props.data.itemCodes}
                    showDialog={this.props.uiActions.uiShowItemPickerDialog}
                    hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                />),
            },
            {
                key: "clubRenewItem",
                label: "פריט חידוש לקוח",
                help: "קוד פריט של חידוש חברות במועדון",
                node: (<ItemPicker defaultItemCode={this.props.data[this.DATA_SET_NAME].editedRecord.clubRenewItem}
                    onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubRenewItem: value.itemCode, clubRenewItemName: value.itemName }) }}
                    label={this.props.data[this.DATA_SET_NAME].editedRecord.clubRenewItemName}
                    data={this.props.data.itemCodes}
                    showDialog={this.props.uiActions.uiShowItemPickerDialog}
                    hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                />),
            },
            {
                key: "clubValidDays",
                label: "תוקף חברות",
                help: "תוקף חברות במועדון הלקוחות בימים",
                node: (<InputNumber min={0} value={this.props.data[this.DATA_SET_NAME].editedRecord.clubValidDays} onChange={(value: number) => {
                    this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubValidDays: value })
                }} />),
            },
            {
                key: "clubMinPointsAutoRenewal",
                label: "מינימום נקודות לחידוש אוטומטי",
                help: "סכום נקודות מצטבר מינימלי לחידוש אוטומטי",
                node: (<InputNumber min={0} value={this.props.data[this.DATA_SET_NAME].editedRecord.clubMinPointsAutoRenewal} onChange={(value: number) => {
                    this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubMinPointsAutoRenewal: value })
                }} />),
            },
            {
                key: "clubFixedDiscount",
                label: "הנחה קבועה",
                help: "אחוז הנחה קבוע ללקוחות מועדון",
                node: (<InputNumber min={0} max={100} value={this.props.data[this.DATA_SET_NAME].editedRecord.clubFixedDiscount} onChange={(value: number) => {
                    this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubFixedDiscount: value })
                }} />),
            },
            {
                key: "clubDiscountItem",
                label: "פריט הנחת מועדון",
                help: "קוד פריט של הנחת מועדון קבועה",
                node: (<ItemPicker defaultItemCode={this.props.data[this.DATA_SET_NAME].editedRecord.clubDiscountItem}
                    onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubDiscountItem: value.itemCode, clubDiscountItemName: value.itemName }) }}
                    label={this.props.data[this.DATA_SET_NAME].editedRecord.clubDiscountItemName}
                    data={this.props.data.itemCodes}
                    showDialog={this.props.uiActions.uiShowItemPickerDialog}
                    hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                />),
            },
            {
                key: "clubTagId",
                label: "תגית לקוח מועדון",
                help: "תגית מזהה ללקוח מועדון לקוחות",
                node: (<Select size={"large"} value={this.props.data[this.DATA_SET_NAME].editedRecord.clubTagId} notFoundContent={"לא נמצאו תגיות"}
                    showsearch={true}
                    filterOption={true}
                    optionFilterProp="valuefilter"
                    allowClear={true}
                    onChange={(value, option) => {
                        this.props.dataActions.genericDataSetEdit(this.DATA_SET_NAME, { clubTagId: value })
                    }} placeholder={"בחר תגית לקוחות"}>{customerTags}</Select>),
            },

        ];

        return (
            <div style={divStyle}>
                <PageToolbar title={"ניהול מועדון לקוחות"} actionButtons={toolbarActions} isModal={false} />

                <Form className="data-entry-form" layout="vertical" onSubmit={this.handleSaveChanges.bind(this)}>
                    {formFields.map((value) => this.createFormItem(value))}

                    <Button type="primary" htmlType="submit" loading={this.props.ui.workInProgress} className={"form-button-save"} icon={"check"}>
                        שמור שינויים
                    </Button>
                </Form>
            </div>
        );
    }

    handleSaveChanges(e: Event) {
        e.preventDefault();
        this.props.dataActions.genericDataJustSaveEdit("CustomerClub", this.props.user.companyCode, this.props.user.token, "set_customer_club_settings");
    }

}

export default CustomerClub;
/* @flow */
/*jshint esversion: 6 */

import React from "react";
import { Input, Icon, Modal } from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";

class ModalCreateEditMultilingual extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            visible: false,
            values: [],
        }

        this.id = this.props.id;

        this.api = {
            get: "get_multilingual_table_per_element",
            edit: "edit_multilingual_table",
        }

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;
    getText = (id) => this.context.get(id) || '[' + id + ']';

    componentDidMount() {
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    initFilters = () => {
        let request = { eID: this.props.ui.data ? this.props.ui.data['eID'] : 0 }
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    componentDidUpdate(prevProps) {
        let d = this.props.ui.data;
        let pd = prevProps.ui.data;
        if ((d && pd && d.eID !== pd.eID) || (prevProps.toggle === false && this.props.toggle === true)) {
            this.setState({ visible: true }, this.initFilters);
        }

    }

    handleCancel = () => {
        this.setState({ visible: false }, () => {
            this.props.uiActions.hideModal(TableIDs.modalCreateEditMultilingual);
            if (this.props.onClose) this.props.onClose();
        })
    }

    createButton = (type, onclick) => {
        return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={type} onClick={onclick} />)
    }

    render() {

        let dIU = this.props.ui.data;
        let eID = dIU && dIU.eID ? " - " + dIU.eID : "";
        let eDesc = dIU && dIU.eDesc ? " - " + dIU.eDesc : "";
        let mTitle = this.getText(12605) + eID + eDesc;

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={mTitle}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let mobile = window.innerWidth <= 600 ? true : false;
        let columns = [
            {
                title: this.getText(12609),
                dataIndex: "lName",
                key: "lName",
                width: '40%',
                render: (text, record) => text,
            }, {
                title: this.getText(12610),
                dataIndex: "lValue",
                key: "lValue",
                width: '40%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return (<Input
                            value={this.props.data[this.id].editedRecord.lValue}
                            onChange={(v) => { this.props.dataActions.genericDataSetEdit(this.id, { lValue: v.target.value }) }}
                        />)
                    }
                },
            }, {
                title: this.getText(12611),
                width: '20%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return this.createButton("edit", () => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) })
                    } else {
                        return (<span>
                            {this.createButton("check", () => {
                                let eRecord = this.props.data[this.id].editedRecord;
                                this.props.dataActions.genericDataSaveEditWithParams(
                                    this.id,
                                    this.props.user.companyCode,
                                    this.props.user.token,
                                    this.api.edit,
                                    this.api.get,
                                    {
                                        eID: this.props.ui.data.eID,
                                        eLang: eRecord.lID,
                                        eValue: eRecord.lValue
                                    })
                            })}
                            {this.createButton("close", () => { this.props.dataActions.genericDataCancelEditing(this.id) })}
                        </span>)
                    }
                },
            },
        ];

        return (
            <Modal
                destroyOnClose={true} width={mobile ? '95%' : "50%"} visible={false || this.props.toggle}
                title={titleBar} onCancel={this.handleCancel} footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataAction={this.props.dataAction} >
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    tableOnly={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
            </Modal >)
    }
}

export default ModalCreateEditMultilingual;

/* @flow */
/*jshint esversion: 6 */
import * as React from "react";

type Props = {
  title: string
};

type State = {};

class PageTitle extends React.Component<Props, State> {
  /*constructor(props) {
    super(props);
  }*/

  render() {
    return (
      <div>
        <h2 className="page-title">{this.props.title}</h2>
      </div>
    );
  }
}

export default PageTitle;

import React from "react";
import firebase from 'firebase';
import { message } from "antd";

class InitFirebase extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
    }


    componentDidMount() {
        this.initializeFirebase();
    }

    componentDidUpdate(p) {
        if (!p.getLocationAgain && this.props.getLocationAgain) this.getLocation()
    }

    initializeFirebase = () => {
        try {
            firebase.initializeApp(
                {
                    apiKey: "AIzaSyCIu7i9ko7aa_oPOOGm4wrzSYNHFmd4-O0",
                    authDomain: "prime-radio-176013.firebaseapp.com",
                    databaseURL: "https://prime-radio-176013.firebaseio.com",
                    projectId: "prime-radio-176013",
                    storageBucket: "prime-radio-176013.appspot.com",
                    messagingSenderId: "1034334295542",
                    appId: "1:1034334295542:web:769c596abe7e5b9c1563d7"
                }

            );
        }
        catch (err) {
            console.error('Firebase initialization error (already exists)', err.stack);
        }

        let serviceWorkerPath = () => {
            console.log("firebase: url:location ", window.location.hostname)
            switch (window.location.hostname) {
                case "localhost": return '../public/firebase-messaging-sw';
                // case "auto-club.co.il": return './firebase-messaging-sw.js';
                // //case "auto-club.co.il": return './cash-on-tab/public/firebase-messaging-sw';
                // case "cashontab.co.il": return './firebase-messaging-sw.js';

                // case "cashontab.net": return './firebase-messaging-sw.js';
                default: return './firebase-messaging-sw.js';

            }
        }

        console.log("firebase register push");
        console.log("serviceWorkerPath() = ", serviceWorkerPath());

        navigator.serviceWorker
            //.register('./firebase-messaging-sw') // prod
            // .register('../public/firebase-messaging-sw') // local
            //.register('../../firebase-messaging-sw') // dev
            .register(serviceWorkerPath(), { scope: window.location.hostname })
            .then((registration) => {
                console.log("firebase then register msg ", registration);
                firebase.messaging().useServiceWorker(registration);
                console.log("firebase useServiceWorker", registration);
            })
            .catch((err) => {
                console.log("firebase registeration error: ", err);
            });

        console.log("after firebase register push");
        setTimeout(() => {
            console.log("call firebase after timeout");
            this.getASK();
            if (this.props.getCustLocation) {
                this.getLocation();
            }
        }, 3000);
    }

    getASK = async () => {
        try {
            const messaging = firebase.messaging();
            await messaging.requestPermission();

            ////////////
            console.log("firebase getASK", messaging);

            messaging.onMessage(payload => {
                console.log("firebase foreground msg");
                const { title, body, action, } = payload.data;
                // const title = payload.notification.body;
                // const options = {
                //     action: [{ action: payload.fcmOptions.link, title: 'testTitle' }]
                // };
                // messaging.registration.showNotification(title, options);
            })
            //////////////////

            const token = await messaging.getToken();

            console.log("firebase token:", token);

            this.props.getToken(token);

            let self = navigator.serviceWorker;

            //////////////
            messaging.setBackgroundMessageHandler(function (payload) {
                console.log('Handling background message ', payload);
                console.log('dddddd wwwwwww', payload);

                return self.registration.showNotification(payload.data.title, {
                    body: payload.data.body,
                    icon: payload.data.icon,
                    tag: payload.data.tag,
                    data: payload.data.link
                });
            });
            self.addEventListener('notificationclick', function (event) {
                event.notification.close();
                event.waitUntil(self.clients.openWindow(event.notification.data));
            });
            //////////////

            return token;
        } catch (error) {
            console.error('firebase onMsg:', error);
        }
    }

    getLocation = async () => {
        try {
            if (navigator.geolocation && this.props.getCustLocation) {
                let latitude = '';
                let longitude = '';
                await navigator.geolocation.getCurrentPosition((position) => {
                    latitude = position.coords.latitude;
                    longitude = position.coords.longitude;
                    this.props.getCustLocation(latitude, longitude);
                    console.log('getLocationAgain run', latitude, longitude)
     
                });
                setTimeout(() => {
                    if ((!latitude || !longitude) && this.props.getLocationAgain) {
                        message.info('יש להסיר את החסימה להרשאת מיקום');
                    }
                }, 1000);
            }
        } catch (error) {
            console.error('getLocation error:', error);
        }
    }

    render() {
        console.log('InitFirebase')
        return (<span></span>)
    }
}

export default InitFirebase;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
import { Button, Checkbox, Form, Icon, Input, InputNumber, Radio, Select, Row, Col } from "antd";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ResponsiveTable from "../../components/ResponsiveTable";
import PictureView from "../../components/PictureView";

class VirtualShopComponents extends Component {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalCreateEdit: null,
            viewType: '0',
            titleList: []
        }

        this.id = "VIRTUAL_SHOP_COMPONENTS";

        if (props.data[this.id] === undefined) props.data[this.id] = { dataset: [] };

        this.api = {
            get: "get_virtual_shop_components",
            set: "set_virtual_shop_components",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.apiComponents()
    }

    apiComponents = () => {
        this.sendAPI(this.api.get, "", ob => {
            const dataset = ob.data ?
                ob.data.split("\r").map(x => {
                    const y = x.split("\f");
                    return {
                        id: y[0],
                        name: y[1],
                        description: y[2],
                        value: y[3],
                        valueLang: y[4],
                        valueType: y[5],
                        fatherDiv: y[6],
                        mobileFatherDiv: y[7],
                        active: y[8],
                        mobileActive: y[9],
                        order: y[10],
                        mobileOrder: y[11],
                        style: y[12],
                        mobileStyle: y[13]
                    }
                })
                : []
            this.props.dataActions.setJson(this.id, { dataset })
        })
    }

    sendAPI = (script, data, sCal, eCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, eCal);
    }

    getJsonInput = (value, onChange) => {
        // const obJson = value ? JSON.parse(value) : {}
        // const keysList = Object.keys(obJson);
        // return [
        //     ...keysList.map(x => {
        //         const v = typeof obJson[x] == "object" ? JSON.stringify(obJson[x]) : obJson[x]
        //         return (<Row dir="ltr">
        //             <Col span={12}>
        //                 <Input value={v} onChange={e => {
        //                     onChange(JSON.stringify({ ...obJson, [x]: e.target.value }))
        //                 }} />
        //             </Col>
        //             <Col span={1} style={{ textAlign: "center" }}>
        //                 <Icon type="double-right" />
        //             </Col>
        //             <Col span={10}>
        //                 <Input value={x} onChange={e => {
        //                     let newObj = {}
        //                     keysList.forEach(y => {
        //                         if (y == x) { newObj = { ...newObj, [e.target.value]: v } }
        //                         else { newObj = { ...newObj, [y]: obJson[y] } }
        //                     })
        //                     onChange(JSON.stringify(newObj))
        //                 }} />
        //             </Col>
        //             <Col span={1} style={{ textAlign: "center" }}>
        //                 <Icon type="minus-circle" onClick={() => {
        //                     let newObj = {}
        //                     keysList.filter(f => f != x).forEach(y => {
        //                         newObj = { ...newObj, [y]: obJson[y] }
        //                     })
        //                     onChange(JSON.stringify(newObj))
        //                 }} />
        //             </Col>
        //         </Row>)
        //     }),
        //     <Icon type="plus-circle" onClick={() => {
        //         onChange(JSON.stringify({ ...obJson, newKey: "" }))
        //     }} />
        // ]

        return (<Input.TextArea dir="ltr" value={value} onChange={e => { onChange(e.target.value) }} />)

    }

    getEditField = (label, field) => {
        const { modalCreateEdit } = this.state;
        if (modalCreateEdit) {
            const value = modalCreateEdit[field];
            const onChange = e => { this.setState({ modalCreateEdit: { ...modalCreateEdit, [field]: e } }) }
            const formItem = c => (<Form.Item label={this.getText(label)}>{c}</Form.Item>)

            switch (field) {
                case "name": case "description": case "valueType":
                    return formItem(<Input value={value} onChange={e => { onChange(e.target.value) }} />)

                case "valueLang":
                    return (modalCreateEdit.valueType == 'text') ? formItem(this.getJsonInput(value, onChange)) : ""

                case "style":
                    return formItem(this.getJsonInput(value, onChange))

                case "fatherDiv":
                    return formItem(<Select {...{ value, onChange }} style={{ width: "100%" }}>
                        {this.props.data[this.id].dataset.map(x => (<Select.Option value={x.id} valuefilter={x.id + x.description}>{x.id} - {x.description}</Select.Option>))}
                    </Select>)

                case "order":
                    return formItem(<InputNumber {...{ value, onChange }} />)

                case "active":
                    return (<Form.Item>
                        <Checkbox checked={value == 1} onChange={e => { onChange(e.target.checked) }} >{this.getText(label)}</Checkbox>
                    </Form.Item>)

                case "value":
                    switch (modalCreateEdit.valueType) {
                        case "text": case "button": case "div_link": case "navbar_btn":
                            return formItem(<Input.TextArea value={value} onChange={e => { onChange(e.target.value) }} />)
                        case "img": case "img_shared":
                            return formItem(<PictureView
                                user={this.props.user}
                                url={value} onChange={onChange} onRemove={() => onChange("")} onUploadedSuccess={() => { }} onUploadedFailed={() => { }}
                                getText={this.getText} imageType={'V'} isShared={modalCreateEdit.valueType == "img_shared"}
                            />);
                    }
            }
        }
    }

    render() {
        const { titleList, viewType, modalCreateEdit } = this.state;
        const { dataset } = this.props.data[this.id];
        const lastFatherDiv = titleList.length ? titleList[titleList.length - 1].id : '0'
        const dataSource = dataset
            .map(x => {
                return (viewType == 1) ? {
                    ...x,
                    fatherDiv: x.mobileFatherDiv,
                    active: x.mobileActive,
                    order: x.mobileOrder,
                    style: x.mobileStyle,
                } : x;
            })
            .filter(f => f.fatherDiv == lastFatherDiv)
            .sort((a, b) => a.order - b.order);

        const typeContent = (t) => {
            return ["text", "value", "img", "img_shared", "input", "button", "social_icon_links", "lang_selector"].indexOf(t) == -1
        }


        const gt = this.getText;
        const render = t => t
        const renderCB = t => (<Checkbox checked={t == 1} />)
        const renderEdit = (t, r) => {
            return [
                <Icon type="edit" style={{ padding: 5 }} onClick={() => { this.setState({ modalCreateEdit: r }) }} />,
                <Icon type="table" style={{ padding: 5, display: typeContent(r.valueType) ? "inline-block" : "none" }} onClick={() => { this.setState({ titleList: [...titleList, r] }) }} />,
            ]
        }

        const actionBtn = [{
            buttonType: 'add',
            buttonFunction: () => {
                this.setState({
                    modalCreateEdit: {
                        id: "0",
                        name: "",
                        description: "",
                        value: "",
                        valueLang: "",
                        valueType: "",
                        fatherDiv: "",
                        active: "",
                        order: "",
                        style: "",
                    }
                })
            }
        }, {
            buttonType: 'refresh',
            buttonFunction: this.apiComponents
        }]


        return (<GenericModal
            visible={this.props.visible}
            onCancel={this.props.onCancel}
            width={'95%'}
            title={gt(18446)}
            actionButtons={actionBtn}>

            <Radio.Group value={viewType} onChange={e => { this.setState({ viewType: e.target.value }) }}>
                <Radio value={'0'}>{gt(18464)}</Radio>
                <Radio value={'1'}>{gt(18465)}</Radio>
            </Radio.Group>
            <br />
            <br />

            <Icon type="arrow-right" onClick={() => { this.setState({ titleList: [] }) }} />

            {titleList.map((x, i) => {
                return (<span>
                    {i ? <Icon type="double-left" /> : ""}
                    <Button onClick={() => { this.setState({ titleList: titleList.slice(0, i + 1) }) }}>{x.description}</Button>
                </span>)
            })}

            <br />

            <ResponsiveTable
                tableOnly
                columns={[
                    { title: gt(18456), key: "id", dataIndex: "id", width: "10%", render },
                    { title: gt(18457), key: "name", dataIndex: "name", width: "25%", render },
                    { title: gt(18458), key: "description", dataIndex: "description", width: "25%", render },
                    { title: gt(18459), key: "valueType", dataIndex: "valueType", width: "15%", render },
                    { title: gt(18460), key: "active", dataIndex: "active", width: "8%", render: renderCB },
                    { title: gt(18461), key: "order", dataIndex: "order", width: "8%", render },
                    { title: gt(18462), width: "9%", render: renderEdit },
                ]}
                dataSource={dataSource}
                pagination={{ pageSize: 10 }}
            />
            <GenericModal
                visible={this.state.modalCreateEdit != null}
                onCancel={() => { this.setState({ modalCreateEdit: null }) }}
                width={'90%'}
                title={gt(18463) + " - " + gt(viewType == 1 ? 18465 : 18464)}>

                <Row>
                    <Col span={10} style={{ paddingLeft: 20 }}>
                        {this.getEditField(18457, "name")}
                        {this.getEditField(18458, "description")}
                        {this.getEditField(18468, "fatherDiv")}
                        {this.getEditField(18460, "active")}
                        {this.getEditField(18461, "order")}
                    </Col>
                    <Col span={14} >
                        {this.getEditField(18459, "valueType")}
                        {this.getEditField(18466, "value")}
                        {this.getEditField(18467, "valueLang")}
                        {this.getEditField(18469, "style")}
                    </Col>
                </Row>
            </GenericModal>

        </GenericModal>)
    }
}

export default VirtualShopComponents;

import React from "react";
import {
    Card, DatePicker, Form, InputNumber, Input, Icon, Button, Popconfirm,
    Tooltip, Modal, Table, Select, Row, Col, Checkbox, message
} from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import Jax from "../Jax/jax.es6.module";

import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import moment from "moment";
//import MatrixGrid from "./MatrixGrid";
import MatrixGridQuantities from "./MatrixGridQuantities";
import MatrixBarcodeTable from "./MatrixBarcodeTable";
import LangContext from "../contextProvider/LangContext";


const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;


//type State = {}
class   ModalMatrixQuantities extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state.matrixGridMode = true;
        this.state.id = null;
        this.state._PriceList = undefined;

        this.id = 'MODAL_MATRIX';
        this.id_meta = 'META_MATRIX';
        this.id_edit = 'MATRIX_GRID_EDIT';

        this.id_item = 'ITEM_CREATE_EDIT';
        this.id_priceList = 'ITEM_PRICE_LIST_CREATE_EDIT';
        this.id_suppliers = 'ITEM_SUPPLIERS_CREATE_EDIT';
        this.id_tags = "ITEM_TAGS_CREATE_EDIT";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.id_item] === undefined) { props.data[this.id_item] = { ...props.data.genericData }; }
        if (props.data[this.id_meta] === undefined) { props.data[this.id_meta] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorPrices] === undefined) {
            props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_father_item",
            //create_edit: "create_edit_item",
            //create: "create_item",
            edit: "edit_item",

            //get_priceList: "get_prices_by_item",
            //get_suppliers: "get_suppliers_by_item",
            //get_tags: "get_tags_by_item_with_headers",

            selector_price_list: "get_prices",
            create: "add_to_matrix_grid",
            get_meta: "get_matrix_meta",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.genericActionButtons = []
        this.dataSource = [];
    }

	static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetEditWithParams("MATRIX_GRID", this.props.user.companyCode,
            this.props.user.token, { _id: this.state.id }, "get_matrix_grid");

        this.props.dataActions.genericDataGetEditWithParams("MATRIX_GRID_EDIT", this.props.user.companyCode,
            this.props.user.token, { _id: -1 }, "get_matrix_grid");
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector_price_list);

        /*
        this.props.dataActions.genericDataGetEditWithParams(
            this.id_meta,
            this.props.user.companyCode,
            this.props.user.token,
            { _id: this.props.ui.data, mItemCode: this.props.ui.data },
            this.api.get_meta
        );
        */
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.ModalMatrixQuantities);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.ModalMatrixQuantities);
    }
    handleClear = () => {
        //this.clear();
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        if (this.isNameValid() === 'success') {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataJustSaveEdit,
                args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
            });
            //this.props.ActionQueue.addToQueue({
            //    action: this.props.dataActions.genericDataGetRequested,
            //    args: ["SUPPLIERS_MAIN", this.props.user.companyCode, this.props.user.token, "get_suppliers_table_list"]
            ///});
            this.props.uiActions.hideModal(TableIDs.ModalMatrixQuantities);
        }
        else {
            message.warning(this.getText(12453));
        }
    }

    isNameValid = () => {
        const name = this.props.data[this.id].editedRecord['mName'];
        return (name === '' || name === null) ? 'error' : 'success';
    }

    render() {
        const { loading } = this.state;
        //let request = { _id: 0, };
        const genericActionButtons = [].concat(this.genericActionButtons).concat(
            [(<Tooltip title={(this.state.matrixGridMode == true) ? this.getText(12454) : this.getText(12455)}>
                <Button key="toggle_grid" className={"btn"} // disabled={(this.props.data[this.id_directdebit].editing > -1)} 
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    //onClick={this.getFile}
                    onClick={() => this.setState({ matrixGridMode: !this.state.matrixGridMode })}
                >
                    {(this.state.matrixGridMode == true)
                        ? <Icon style={{ fontSize: 16 }} type="table" theme="outlined" />
                        : <Icon style={{ fontSize: 16 }} type="barcode" theme="outlined" />
                    }
                </Button>
            </Tooltip>),]
        );

        let fluxProps = { ui: this.props.ui, uiActions: this.props.uiActions, data: this.props.data, dataActions: this.props.dataActions, user: this.props.user }

        let list = Jax.get(this.props.data, TableIDs.genericSelectorPrices + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' - ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={this.actionButtons}
                    genericActionButtons={[]/*genericActionButtons*/}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'90%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                zIndex={this.props.zIndex}

                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                //bodyStyle={{ height: parseInt(window.innerHeight * 0.9, 10) }}
                //style={{ height: parseInt(window.innerHeight * 0.9, 10) }}
                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue} data={this.props.data} dataAction={this.props.dataAction}
            >
                <div id={'headers'} style={{ padding: 8 }}>
                    <span>{this.getText(12456)}
                        <Input
                            disabled={true}
                            style={{ width: 150, marginLeft: 8, marginRight: 8 }}
                            value={this.props.data['ITEM_CREATE_EDIT'].editedRecord['mCode']}
                        />
                    </span>
                    <span>{this.getText(12457)}
                        <Input
                            disabled={true}
                            style={{ width: 400, marginLeft: 8, marginRight: 8 }}
                            value={this.props.data['ITEM_CREATE_EDIT'].editedRecord['mName']}
                        />
                    </span>
                    <span style={(this.state.matrixGridMode)
                        ? { display: 'none' } : {}} >{this.getText(12458)}
                        <Select key={"PriceListList"} style={{ width: 250, marginRight: 8 }}
                            placeholder={this.getText(12459)}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            onChange={(e) => {
                                this.props.dataActions.genericDataSetEdit(this.id_meta, { ['mDefaultPriceListId']: e });
                            }}
                            value={this.props.data[this.id_meta].editedRecord['mDefaultPriceListId']}
                            notFoundContent={this.getText(12460)}
                        >
                            {list}
                        </Select>
                    </span>
                </div>
                {/*
                <div style={{ padding: 8, textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>
                {(this.state.matrixGridMode == true) ? "תצוגת מטריצה" : "תצוגת פריט ברקוד"}</div>
                */}
                <div key="grid_container" style={{ padding: 8 }} >
                    {(true /*this.state.matrixGridMode*/)
                        ? <MatrixGridQuantities {...fluxProps} itemCode={this.props.mParentItemCode/*ui.data*/} />
                        //<MatrixGrid {...fluxProps} itemCode={this.props.mParentItemCode/*ui.data*/} />
                        : <MatrixBarcodeTable {...fluxProps}
                            itemCode={this.props.mParentItemCode/*ui.data*/}
                            priceListId={this.props.data[this.id_meta].editedRecord['mDefaultPriceListId']} />}
                </div>
                <div className="action_footer" style={(this.state.matrixGridMode) ? {} : { display: 'none' }}>
                    <Button
                        onClick={this.handleCancel}
                        type="primary" //htmlType="submit" form="create_edit_supplier_form"
                        loading={this.props.ui.workInProgress}
                    >{this.getText(12461)}</Button>
                    <Button
                        onClick={this.addGridData}
                        type="primary" //htmlType="submit" form="create_edit_supplier_form"
                        loading={this.props.ui.workInProgress}
                        //className={"form-button-save"} 
                        icon={"check"}>{this.getText(12462)}</Button>
                </div>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };
        //this.props.mParentItemCode
        if (/*this.props.ui.data !== prevProps.ui.data*/
            this.props.mParentItemCode !== prevProps.mParentItemCode
            || (prevProps.toggle === false && this.props.toggle === true)) {
            
            // clear selection
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.overrideJson,
                args: [TableIDs.matrixQuantities, {}]
            });
            //this.props.dataActions.overrideJson(TableIDs.matrixQuantities, {});


            this.setState({ id: this.props.mParentItemCode/*ui.data*/, matrixGridMode: true }, () => {
                this.props.dataActions.genericDataGetEditWithParams(
                    this.id,
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id: this.props.mParentItemCode/*ui.data*/, mItemCode: this.props.mParentItemCode/*ui.data*/ },
                    this.api.get
                );

                this.props.dataActions.genericDataGetEditWithParams(
                    this.id_meta,
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id: this.props.mParentItemCode/*ui.data*/, mItemCode: this.props.mParentItemCode/*ui.data*/ },
                    this.api.get_meta
                );

                this.props.dataActions.genericDataGetEditWithParams(
                    'ITEM_CREATE_EDIT',
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id: this.props.mParentItemCode/*ui.data*/, mItemCode: this.props.mParentItemCode/*ui.data*/ },
                    "get_father_item"
                );

                let request = {
                    _id: this.props.mParentItemCode,
                    // ui
                    page: 1,
                    recordsPerPage: 1,
                    sortType: 1,
                    sortColumn: 1,
                    // api
                    _words: '',
                    _page_num: 1,
                    _rows_num: 999999,
                    _isAsc: 1,
                    _sort_by: 'col_1',

                    _priceList_id: -1,
                };

                this.props.dataActions.genericDataGetEditWithParams(
                    "MATRIX_BARCODE",
                    this.props.user.companyCode,
                    this.props.user.token,
                    request,
                    "get_barcode_table"
                );

            })
        }
    }

    addGridData = () => {
        const itemCode = this.props.data['ITEM_CREATE_EDIT'].editedRecord['mCode'];
        this.props.onAddMatrixItemsQuantities(itemCode);
        this.props.uiActions.hideModal(TableIDs.ModalMatrixQuantities);
        this.props.uiActions.hideModal(TableIDs.SelectorItemsModal);
    }
}

export default ModalMatrixQuantities;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Checkbox, Form, Icon, Input, InputNumber, Radio, Button } from "antd";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericModal from "../../components/GenericModal";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class VirtualShopBranchShippingAreas extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalAreas: null,
            modalFilter: false,
            filterType: '-1',
        }

        this.id = "BRANCHES_MAIN";
        this.areas_id = "BRANCH_SHIPPING_AREAS";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.areas_id] === undefined) props.data[this.areas_id] = { ...props.data.genericData };

        this.api = {
            get: "get_branches_list_table",
            get_areas: "get_branch_shipping_areas",
            set_areas: "set_branch_shipping_areas",
            delete_areas: "delete_branch_shipping_areas",
            order_areas: "order_branch_shipping_areas",

        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getTableIcon = (t, r) => {
        return (<Icon type="table" onClick={() => {
            this.setState({ modalAreas: r }, () => {
                this.props.dataActions.genericDataSetFilter(
                    this.areas_id, this.props.user.companyCode, this.props.user.token, {
                    _branch: r.key,
                    _filterType: this.state.filterType
                }, this.api.get_areas);
            })
        }} />)
    }

    getEditBSA = (t, r) => {
        const { editing } = this.props.data[this.areas_id];
        if (r.key == editing) {
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.areas_id, this.props.user.companyCode, this.props.user.token, this.api.set_areas, this.api.get_areas, { _branch: this.state.modalAreas?.mID });
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.areas_id);
                    else this.props.dataActions.genericDataCancelEditing(this.areas_id);
                }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.areas_id, r.key, r.index) }}
                onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.areas_id, r.key, this.props.user.companyCode, this.props.user.token, this.api.delete_areas, this.api.get_areas) }}
                deleteQuetion={{ title: this.getText(19744), okText: this.getText(19745), cancelText: this.getText(19746) }}
            />)
        }
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    refreshAreas = () => {
        this.props.dataActions.genericDataGetRequested(
            this.areas_id, this.props.user.companyCode, this.props.user.token, this.api.get_areas);
    }


    render() {
        const gt = this.getText;
        const render = t => t;
        const sorter = true;
        const { editing, editedRecord } = this.props.data[this.areas_id];
        const onChangeBSA = (obj) => { this.props.dataActions.genericDataSetEdit(this.areas_id, obj) }
        const renderBSAName = (t, r) => r.key != editing ? t : (<Input value={editedRecord._Name} onChange={e => { onChangeBSA({ _Name: e.target.value }) }} />)
        const renderBSAPrice = (t, r) => r.key != editing ? t : (<InputNumber value={editedRecord._Price} onChange={e => { onChangeBSA({ _Price: e }) }} />)
        const renderBSAMinimum = (t, r) => r.key != editing ? t : (<InputNumber value={editedRecord._Minimum} onChange={e => { onChangeBSA({ _Minimum: e }) }} />)
        const renderBSAMinutes = (t, r) => r.key != editing ? t : (<InputNumber value={editedRecord._Minutes} onChange={e => { onChangeBSA({ _Minutes: e }) }} />)
        const renderBSAIsActive = (t, r) => (<Checkbox disabled={r.key != editing} checked={r.key != editing ? t == 1 : editedRecord._IsActive == 1} onChange={e => { onChangeBSA({ _IsActive: e.target.checked ? "1" : "0" }) }} />)

        return (<GenericModal visible={this.props.visible} onCancel={this.props.onCancel} width={1200} title={gt(19732)}>
            <GenericTable
                {...this.props}
                tableOnly
                id={this.id}
                getApi={this.api.get}
                filters={{ _id: 0, _isActive: "0", _franchiseList: "" }}
                columns={[
                    { title: gt(19733), dataIndex: "mID", key: "mID", width: '15%', sorter, render },
                    { title: gt(19734), dataIndex: "mName", key: "mName", width: '27%', sorter, render },
                    { title: gt(19735), dataIndex: "mAddress", key: "mAddress", width: '30%', sorter, render },
                    { title: gt(19736), dataIndex: "mFranchise", key: "mFranchise", width: '20%', sorter, render },
                    { title: gt(19737), width: '8%', render: this.getTableIcon },
                ]}
            />
            <GenericModal
                visible={this.state.modalAreas != null}
                onCancel={() => { this.setState({ modalAreas: null }) }}
                width={800}
                title={gt(19732) + " " + this.state.modalAreas?.mID + " - " + this.state.modalAreas?.mName}
                actionButtons={[
                    { buttonType: 'add', buttonFunction: () => { if (editing === -1) this.props.dataActions.genericDataStartInlineCreate(this.areas_id) } },
                    { buttonType: 'refresh', buttonFunction: this.refreshAreas },
                    { buttonType: 'filter', buttonFunction: () => { this.setState({ modalFilter: true }) } },
                ]}>
                <ResponsiveTable
                    tableOnly
                    id={this.areas_id}
                    idTable={this.props.data[this.areas_id]}
                    columns={[
                        { title: gt(19738), dataIndex: "_Name", key: "_Name", width: '30%', render: renderBSAName },
                        { title: gt(19739), dataIndex: "_Price", key: "_Price", width: '17%', render: renderBSAPrice },
                        { title: gt(19740), dataIndex: "_Minimum", key: "_Minimum", width: '17%', render: renderBSAMinimum },
                        { title: gt(19741), dataIndex: "_Minutes", key: "_Minutes", width: '17%', render: renderBSAMinutes },
                        { title: gt(19742), dataIndex: "_IsActive", key: "_IsActive", width: '10%', render: renderBSAIsActive },
                        { title: gt(19743), width: '9%', render: this.getEditBSA },
                    ]}
                    onDragAndDrop={(keyFrom, keyTo) => {
                        this.sendAPI(this.api.order_areas, "_branch\f_idFrom\f_idTo\r" + this.state.modalAreas?.mID + "\f" + keyFrom + "\f" + keyTo, this.refreshAreas)
                    }}
                    pagination={false}
                    scroll={{ y: window.innerHeight - 200 }}
                />
            </GenericModal>
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                width={600}
                title={gt(19747)}
                footer={[
                    <Button key="back" onClick={() => { this.setState({ modalFilter: false }) }}>{this.getText(19751)}</Button>,
                    <Button key="clear" onClick={() => { this.setState({ filterType: '-1' }) }}>{this.getText(19752)}</Button>,
                    <Button key="create" type="primary" onClick={() => {
                        this.setState({ modalFilter: false }, () => {
                            this.props.dataActions.genericDataSetFilter(
                                this.areas_id, this.props.user.companyCode, this.props.user.token, {
                                _branch: this.state.modalAreas?.mID,
                                _filterType: this.state.filterType
                            }, this.api.get_areas);
                        })
                    }}>{this.getText(19753)}</Button>,
                ]}>
                <Form.Item>
                    <Radio.Group value={this.state.filterType} onChange={e => { this.setState({ filterType: e.target.value }) }}>
                        <Radio value="-1">{gt(19748)}</Radio><br/>
                        <Radio value="1">{gt(19749)}</Radio><br/>
                        <Radio value="0">{gt(19750)}</Radio>
                    </Radio.Group>
                </Form.Item>
            </GenericModal>
        </GenericModal>)
    }
}
export default VirtualShopBranchShippingAreas;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import StockItemBalanceReport from "./reports/StockItemBalanceReport";
import StockItemBalanceValueReport from "./reports/StockItemBalanceValueReport";
import DeadStockReport from "./reports/DeadStockReport";
import StockItemCardReport from "./reports/StockItemCardReport";
import PaymentsSearch from "./reports/PaymentsSearchReport";
import ReportMenuInventory from "../menus/ReportMenuInventory";
import PurchaseDocumentsTable from "./Inventory/PurchaseDocumentsTable";
import InventoryException from "./reports/InventoryException";
import TableIDs from '../data/TableIDs';
import PurchaseItems from "./reports/PurchaseItemsReport";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object
};

type State = {};

// SalesItemsReport // SalesReport
class ReportsInventory extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

     const routes = [
            (<Route key={'45'} path={this.props.baseUrl + "/reports_inventory/purchase-docs-table"} exact render={() => <PurchaseDocumentsTable {...routeProps} />} />),
            (<Route key={'41'} path={this.props.baseUrl + "/reports_inventory/stock-items-balance"} exact render={() => <StockItemBalanceReport {...routeProps} />} />),
            (<Route key={'42'} path={this.props.baseUrl + "/reports_inventory/stock-items-balance-value"} exact render={() => <StockItemBalanceValueReport {...routeProps} />} />),
            (<Route key={'43'} path={this.props.baseUrl + "/reports_inventory/dead-stock"} exact render={() => <DeadStockReport {...routeProps} />} />),
            (<Route key={'44'} path={this.props.baseUrl + "/reports_inventory/stock-Item-Card"} exact render={() => <StockItemCardReport {...routeProps} />} />),
            (<Route key={'46'} path={this.props.baseUrl + "/reports_inventory/inventory-exception"} exact render={() => <InventoryException {...routeProps} />} />),
            (<Route key={'47'} path={this.props.baseUrl + "/reports_inventory/purchase-items-report"} exact render={() => <PurchaseItems {...routeProps} />} />),
            (<Route key={'-1'} path={this.props.baseUrl + "/reports_inventory/payments-search"} exact render={() => <PaymentsSearch {...routeProps} />} />)

        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/reports_inventory"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <ReportMenuInventory baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default ReportsInventory;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import {
    Modal, Tooltip, Icon, Button, message, Row, Col,
} from "antd";
import ResponsiveTable from "../../components/ResponsiveTable";
import FoodsFormatCart from "./FoodsFormatCart";

class PrevOrders extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalLines: false,
            orderStatus: null,
            modalQuestion: false,
        }

        this.id = "PREV_ORDERS_LIST";
        this.lines_id = "PREV_ORDER_LINES";
        this.cart_id = "FOOD_FORMAT_CART";

        this.api = {
            get_lines: "get_prev_order_lines",
            delete: "delete_external_orders",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { data: [], headers: [] }
        if (props.data[this.lines_id] === undefined) props.data[this.lines_id] = { data: [], headers: [] }
        if (props.data[this.cart_id] === undefined) props.data[this.cart_id] = { data: [] }

    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.props.refrashData(1)
    }

    orderLinesApiWithOpen = (record) => {
        this.setState({ modalLines: record.oNum, orderStatus: record.oFinal }, () => { this.orderLinesApi(record) })
    }

    orderLinesApi = (record, next) => {
        this.props.sendAPI(this.api.get_lines, "oID\r" + record.oID, (ob) => {
            let res = ob.data.split("\r");
            let headers = res[0].split("\f");
            let fData = res.slice(1);
            let nData = [];
            fData.map((x) => {
                let z = x.split("\f");
                let obj = {}
                let levels = null;
                headers.map((y, i) => {
                    if (y === "iLevelJson" && z[i]) {
                        levels = JSON.parse(z[i]);
                    } else {
                        obj = { ...obj, [y]: z[i] }
                    }
                });
                let itemData = { item: obj }
                if (levels) itemData = { ...itemData, ...levels }
                nData.push(itemData);
            });

            this.props.setJson(this.lines_id, { data: nData });
            this.mivzaimLogic(this.lines_id);
            if (next) next();

        }, (e) => { console.error(e) })
    }

    mivzaimLogic = (idStore) => {
        setTimeout(() => {
            this.props.mivzaimLogic(idStore);
        }, 1000);
    }

    addLines = (copy, dDelete) => {
        let linesData = this.props.data[this.lines_id].data;
        let cartData = this.props.data[this.cart_id].data;
        let newData = dDelete ? linesData : [...cartData, ...linesData];

        this.props.setJson(this.cart_id, { data: newData });

        if (!copy) this.props.ownerUpdate(this.state.modalLines);

        this.setState({ modalLines: false, modalQuestion: false }, () => {
            this.props.close();
            this.mivzaimLogic(this.cart_id);
            this.props.changeScreen(1);
        });

    }

    modalQuestion = () => {
        let qu = "";
        let buttons = false;
        let gt = (t) => this.props.getText(t)

        let quStyle = {
            fontSize: 50,
            textAlign: 'center',
        }

        let cartData = this.props.data[this.cart_id].data;

        let closeModal = (a) => { this.setState({ modalQuestion: false }, a ? a : () => { }) }

        let deleteLines = () => {
            this.props.sendAPI(this.api.delete, this.state.modalLines, () => {
                this.setState({ modalLines: false }, () => {
                    message.success(gt(13447));
                    this.props.refrashData(1);
                    this.mivzaimLogic(this.cart_id);
                    this.props.changeScreen(1);
                });
            }, (e) => { console.error(e) })
        }

        let s = this.state.modalQuestion;

        switch (s) {
            case 'delete':
                qu = gt(13443) + " " + this.state.modalLines + "?";
                buttons = [
                    this.buttonStyle("deleteOrder", 13359, () => { closeModal(deleteLines) }),
                    this.buttonStyle("cancelDelete", 13360, () => { closeModal(false) }),
                ];
                break;
            case 'copy': case 'edit':
                qu = gt(13444);
                buttons = [
                    this.buttonStyle("deleteCopy", 13445, () => { this.addLines(s === 'copy' ? true : false, true) }),
                    this.buttonStyle("addCopy", 13446, () => { this.addLines(s === 'copy' ? true : false, false) }),
                ];
                break;
        }

        return (
            <Modal
                zIndex={20000}
                visible={this.state.modalQuestion ? true : false}
                width={window.innerWidth <= 600 ? "80%" : "40%"}
                onCancel={() => { closeModal(false) }}
                title={this.props.getClassNameStyle("title_block")}
                footer={buttons}
                data={this.props.data}
                dataAction={this.props.dataAction}
            >
                <div style={quStyle}>{qu}</div>
            </Modal>)
    }

    buttonStyle = (key, text, onClick, color) => {
        let bStyle = {
            margin: 10,
            width: 140,
            height: 50,
        }

        if (color) {
            bStyle = { ...bStyle, background: color }
        } else {
            bStyle = { ...bStyle, ...this.props.buttonStyle }
        }

        return (<Button key={key} style={bStyle} onClick={onClick}>
            <span className={"text_item"} style={this.props.buttonStyle}>{this.props.getText(text)}</span>
        </Button>);
    }

    render() {
        let cartData = this.props.data[this.cart_id].data;
        let gt = (t) => this.props.getText(t)

        let actions = (record) => {
            return (<span>
                <Tooltip title={gt(13409)}>
                    <Icon type={'eye'} style={{ fontSize: 16 }} onClick={() => { this.orderLinesApiWithOpen(record) }} />
                </Tooltip>
            </span>)
        }

        let closeLines = () => {
            this.setState({ modalLines: false }, () => {
                this.mivzaimLogic(this.cart_id);
                this.props.changeScreen(1);
            })
        }

        let addLines = (copy) => {
            if (cartData[0]) {
                this.setState({ modalQuestion: copy ? 'copy' : 'edit' })
            } else {
                this.addLines(copy, true)
            }
        }

        let openStatusButtons = [
            this.buttonStyle("delete", 13420, () => { this.setState({ modalQuestion: 'delete' }) }),
            this.buttonStyle("edit", 13421, () => { addLines(false) }),
            this.buttonStyle("closeLines", 13419, closeLines),
        ]

        let button = [
            this.buttonStyle("copy", 13421, () => { addLines(true) }),
            this.buttonStyle("closeLines", 13419, closeLines),
        ]

        let statusText = (s) => {
            switch (s) {
                case '0': return gt(13412);
                case '1': return gt(13413);
                case '2': return gt(13414);
            }
        }

        let columns = [
            { title: gt(13404), key: 'oNum', dataIndex: 'oNum', width: '20%', render: (text, record) => text },
            { title: gt(13405), key: 'oDete', dataIndex: 'oDete', width: '18%', render: (text, record) => text },
            { title: gt(13406), key: 'oTime', dataIndex: 'oTime', width: '18%', render: (text, record) => text },
            { title: gt(13407), key: 'oTotal', dataIndex: 'oTotal', width: '20%', render: (text, record) => text },
            { title: gt(13408), key: 'oFinal', dataIndex: 'oFinal', width: '15%', render: (text, record) => statusText(text) },
            { title: '', render: (text, record) => actions(record) }
        ]


        let resModals = [
            <Modal
                visible={this.state.modalLines ? true : false}
                width={window.innerWidth <= 600 ? "95%" : "60%"}
                title={this.props.getClassNameStyle("title_block", {}, this.state.modalLines ? gt(13415) + " " + this.state.modalLines : '')}
                onCancel={closeLines}
                footer={this.state.orderStatus === "0" ? openStatusButtons : button}
                data={this.props.data}
                dataAction={this.props.dataAction}
            >
                <FoodsFormatCart
                    dataId={this.lines_id}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                    isBackOffice={this.props.isBackOffice}
                    getText={this.props.getText}
                    sendAPI={this.props.sendAPI}
                    changeScreen={false}
                    companyCode={this.props.companyCode}
                    isShop={false}
                    getPiecesText={this.props.getPiecesText}
                    cartFullScreen
                />
            </Modal>,
            this.modalQuestion()
        ]

        let ordersData = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            let obj = { index, key: y[0] }
            this.props.data[this.id].headers.forEach((z, i) => {
                obj = { ...obj, [z]: y[i] }
            })
            return obj
        })

        return this.props.isStyle3 ?
            <div>
                <Row
                    style={{
                        fontFamily: "FbTipograf",
                        fontWeight: "bold",
                        lineHeight: 0.5,
                        fontSize: 18,
                    }}>
                    {ordersData.map(x => {
                        const { oNum, oDete, oTime, oTotal, oFinal } = x;

                        return (<Col span={12} style={{ padding: 10 }}>
                            <div style={{ padding: 10, border: "1px solid #888888", background: "white" }}>
                                <p>{gt(18495)} {oNum}</p>
                                <p>{gt(18496)}: {statusText(oFinal)}</p>
                                <p dir="ltr">{oDete ? oDete.split("-").reverse().join("/") : ""} {oTime ? oTime.split(":").slice(0, 2).join(":") : ""}</p>
                                <p>₪{oTotal}</p>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}
                                        style={{ color: "gold", cursor: "pointer", textAlign: "right" }}
                                        onClick={() => { this.orderLinesApi(x, () => { addLines(true) }) }}>
                                        {gt(18497)}
                                    </Col>
                                    <Col span={12}
                                        style={{ color: "black", cursor: "pointer", textAlign: "left" }}
                                        onClick={() => { this.orderLinesApiWithOpen(x) }}>
                                        {gt(18498)}
                                    </Col>
                                </Row>
                            </div>
                        </Col>)
                    })}
                </Row>
                {resModals}
            </div>
            : (
                <Modal
                    visible={this.props.visible}
                    width={window.innerWidth > 800 ? 800 : "95%"}
                    title={this.props.getClassNameStyle("title_block", {}, gt(13403))}
                    onCancel={this.props.close}
                    footer={false}
                    data={this.props.data}
                    dataAction={this.props.dataAction}
                >
                    <ResponsiveTable
                        getText={this.props.getText}
                        idTable={this.props.data[this.id]}
                        tableOnly={true}
                        columns={columns}
                        percentageToMobile={[100]}
                        onChange={p => { this.props.refrashData(p.current) }}
                    />
                    {resModals}
                </Modal>)
    }
}

export default PrevOrders;
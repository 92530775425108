/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { InputNumber, Checkbox, Button, Form, Row, Col } from "antd";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericModal from "../../components/GenericModal";
import TableLineIcons from "../../components/TableLineIcons";
import SelectorItemClassic from "../../components/SelectorItemClassic";

class VirtualShopRadius extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            height: window.innerHeight,

            modalCopyRow: null,
            searchValue: "",
        }

        this.doOnResize = null;

        this.id = "DELIVERY_RADIUS";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.api = {
            get: "get_delivery_radius",
            save: "save_delivery_radius",
            copy: "copy_delivery_radius",
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, { _lat: "", _long: "", _method: "" }, this.api.get)
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.refreshData();
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    saveEdit = () => {
        this.props.dataActions.genericDataSaveEdit(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.save, this.api.get);
    }

    cancelEdit = () => {
        if (this.props.data[this.id].editedRecord.key == 0)
            this.props.dataActions.genericDataCancelInlineCreate(this.id);
        else
            this.props.dataActions.genericDataCancelEditing(this.id);
    }

    getButtonsEdit = (text, record) => {
        const onEdit = () => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }
        const onCheck = this.saveEdit;
        const onClose = this.cancelEdit;
        const genericIcons = [{ type: "copy", onClick: () => { this.setState({ modalCopyRow: record }) }, tooltip: this.getText(18791) }]

        let props = (record.key !== this.props.data[this.id].editing) ? { onEdit, genericIcons } : { onCheck, onClose }
        return (<TableLineIcons {...props} />)
    }

    getItemPicker = (text, record) => {
        let data = this.props.data[this.id];
        return record.key === data.editing ? (<SelectorItemClassic
            user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
            ctrlBKeyFlag={null} isExpandMatrixFatherItem={false} itemType={1}
            valueId={data.editedRecord['bDeliveryItem']}
            valueName={data.editedRecord['bDeliveryItemName']}
            onChange={(code, name) => { this.props.dataActions.genericDataSetEdit(this.id, { bDeliveryItem: code, bDeliveryItemName: name }) }}
        />) : text
    }

    onCopyRow = () => {
        const { bID, bRadiusCopy, bPalestineCopy, bDeliveryItemNameCopy, bPricePerKmCopy, bPriceFirstKmCopy, bMinPriceCopy, bMaxPriceCopy, bIsRoundCopy } = this.state.modalCopyRow
        const params = { bID, bRadiusCopy, bPalestineCopy, bDeliveryItemNameCopy, bPricePerKmCopy, bPriceFirstKmCopy, bMinPriceCopy, bMaxPriceCopy, bIsRoundCopy }
        this.setState({ modalCopyRow: null }, () => {
            this.props.dataActions.genericDataSaveEditWithParams(
                this.id, this.props.user.companyCode, this.props.user.token, this.api.copy, this.api.get, params);
        })
    }

    render() {
        console.log("test data",this.props.data[this.id])
        let gt = this.getText;
        let isEdit = (r) => r.key === this.props.data[this.id].editing;
        let editedRecord = this.props.data[this.id].editedRecord;
        let onChange = (f, v) => { this.props.dataActions.genericDataSetEdit(this.id, { [f]: v }) }

        let rInput = (t, r, p, f) => {
            if (isEdit(r)) {
                return (<InputNumber min={0} precision={p} value={editedRecord[f]} onChange={(v) => { onChange(f, v) }} />)
            } else {
                return t ? t : gt(13494);
            }
        }

        let cb = (t, r, f) => {
            return (<Checkbox
                checked={(!isEdit(r) && t == '1') || (isEdit(r) && editedRecord[f] == '1')}
                disabled={!isEdit(r)}
                onChange={(e) => { onChange([f], e.target.checked ? '1' : '0') }}
            />);
        }

        let columns = [
            { title: gt(13490), key: 'bName', dataIndex: 'bName', width: '12%', render: (t, r) => t },
            { title: gt(13491), key: 'bAddress', dataIndex: 'bAddress', width: '25%', render: (t, r) => t },
            { title: gt(13492), key: 'bRadius', dataIndex: 'bRadius', width: '8%', render: (t, r) => rInput(t, r, 3, 'bRadius') },
            { title: gt(13495), key: 'bPalestine', dataIndex: 'bPalestine', width: '5%', render: (t, r) => cb(t, r, 'bPalestine') },
            { title: gt(14150), key: 'bDeliveryItemName', dataIndex: 'bDeliveryItemName', width: '10%', render: this.getItemPicker },
            { title: gt(14151), key: 'bPricePerKm', dataIndex: 'bPricePerKm', width: '7%', render: (t, r) => rInput(t, r, 2, 'bPricePerKm') },
            { title: gt(15335), key: 'bPriceFirstKm', dataIndex: 'bPriceFirstKm', width: '7%', render: (t, r) => rInput(t, r, 2, 'bPriceFirstKm') },
            { title: gt(14352), key: 'bMinPrice', dataIndex: 'bMinPrice', width: '7%', render: (t, r) => rInput(t, r, 2, 'bMinPrice') },
            { title: gt(14152), key: 'bMaxPrice', dataIndex: 'bMaxPrice', width: '7%', render: (t, r) => rInput(t, r, 2, 'bMaxPrice') },
            { title: gt(14153), key: 'bIsRound', dataIndex: 'bIsRound', width: '5%', render: (t, r) => cb(t, r, 'bIsRound') },
            { title: gt(13493), width: '7%', render: this.getButtonsEdit },
        ];

        const mcr = this.state.modalCopyRow

        return (<GenericModal
            visible={this.props.visible} onCancel={this.props.onCancel} width={'95%'} title={gt(13489)}
            showsearch={val => { this.setState({ searchValue: val }) }}>
            <ResponsiveTable
                tableOnly={true}
                idTable={this.props.data[this.id]}
                columns={columns}
                percentageToMobile={[100]}
                ui={this.props.ui}
                pagination={{ pageSize: 10 }} 
                filterDatasetText={this.state.searchValue}
            />
            <GenericModal
                visible={mcr != null}
                onCancel={() => { this.setState({ modalCopyRow: null }) }}
                width={600}
                title={gt(18794)}
                footer={[
                    <Button type="primary" onClick={this.onCopyRow}>{gt(18792)}</Button>,
                    <Button onClick={() => { this.setState({ modalCopyRow: null }) }}>{gt(18793)}</Button>,
                ]}>
                <h2>{gt(18795)}</h2>
                <h3>{gt(18796)}</h3>
                {mcr ? columns.map((x, i) => {
                    if (i > 1 && i < 10) {
                        return (<div>
                            <Checkbox
                                checked={mcr[x.dataIndex + "Copy"] == '1'}
                                onChange={e => { this.setState({ modalCopyRow: { ...mcr, [x.dataIndex + "Copy"]: e.target.checked ? "1" : "0" } }) }}>
                                <b>{x.title}:</b>  {x.render(mcr[x.dataIndex], mcr)}
                            </Checkbox>
                        </div>)
                    } else {
                        return ""
                    }
                }) : ""}
            </GenericModal>
        </GenericModal>)
    }
}
export default VirtualShopRadius;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
//import "../../App.css";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

import { Card, notification } from "antd";

import PageToolbar from '../../components/PageToolbar'
import LangContext from "../../contextProvider/LangContext";
import MultiUpload from "../../components/MultiUpload";

class MultiUploadImagesForFiles extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            imageType: "I",
            fileList: [],
            succeededfileList: [],
            failedfileList: [],
        };

        this.id = "MULTI_UPLOAD_IMAGES_FOR_FILES";
        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        // this.api = {
        //     get: "newPage",
        // }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(100);
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        const { fileList, isDirectory, FileType, failedfileList, succeededfileList, imageType } = this.state;

        const titles = {
            main: this.getText(16652), // "העלאת מרובה תמונות פריטים",
            add: "",
            edit: "",
            search: ""
        };

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        return (<div style={divStyle}>
            <Card
                //hoverable={true}
                //title="שעון נוכחות"
                bordered={false}
                style={{ width: "100%" }}
                cover={<PageToolbar
                    title={this.getText(16653)} // title="העלאת תמונות לפריטים"
                    actionButtons={[]}
                    genericActionButtons={[]}
                    showsearch={false}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />}
            >
                <MultiUpload {...this.props}
                    fileList={fileList}
                    succeededfileList={[...succeededfileList]}
                    failedfileList={[...failedfileList]}

                    beforeUpload={(file, fileList, failedfileList, succeededfileList) => {
                        //console.log("beforeUpload(main file):", file);
                        //console.log("beforeUpload(main fileList):", fileList, failedfileList, succeededfileList);
                        this.setState((s, p) => {
                            return {
                                ...s,
                                fileList: [...fileList],
                                failedfileList: [...failedfileList],
                                succeededfileList: [...succeededfileList]
                            }
                        })
                    }}
                    onChange={(file, fileList, failedfileList, succeededfileList) => {
                        //console.log("onChange(main file):", file);
                        //console.log("onChange(main fileList):", fileList, failedfileList, succeededfileList);
                        this.setState((s, p) => {
                            return {
                                ...s,
                                fileList: [...fileList],
                                failedfileList: [...failedfileList],
                                succeededfileList: [...succeededfileList],
                            }
                        })
                    }}
                    onRemove={(file, fileList, failedfileList, succeededfileList) => {
                        //console.log("onRemove(main file):", file);
                        //console.log("onRemove(main file):", fileList, failedfileList, fileList);
                        this.setState((s, p) => {
                            return {
                                ...s,
                                fileList: [...fileList],
                                failedfileList: [...failedfileList],
                                succeededfileList: [...succeededfileList]
                            }
                        })
                    }}
                    onComplete={(file, fileList, failedfileList, succeededfileList) => {
                        //console.log("onComplete(main file):", file);
                        //console.log("onComplete(main file):", fileList, failedfileList, succeededfileList);
                        this.setState({
                            fileList: [...fileList],
                            failedfileList: [...failedfileList],
                            succeededfileList: [...succeededfileList]
                        }, () => {
                            if (succeededfileList.length > 0) {
                                //fileList, failedfileList, succeededfileList
                                var text = [
                                    "### " + this.getText(16654) + " ###",  // "### רשימת קבצים שנכשלו בהעלאה ###",
                                    ...failedfileList.map(i => i.name/*.toLocaleString('he-IL')*/),
                                    "\n",
                                    "### " + this.getText(16655) + " ###",  // "### רשימת קבצים שהועלו בהצלחה ###",
                                    ...succeededfileList.map(i => i.name/*.toLocaleString('he-IL')*/)
                                ].join('\n'),
                                    blob = new Blob([text.replace(/([^\r])\n/g, "$1\r\n")], { type: 'text/plain' }),
                                    anchor = document.createElement('a');
                                anchor.download = 'upload_report.txt';
                                anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
                                anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
                                anchor.click();
                            } else {
                                notification.warning({
                                    message: this.getText(16656), // 'דוח ריצה',
                                    description: this.getText(16657), // 'לא הועלו קבצים בהצלחה. ולכן לא נוצר דוח ריצה',
                                });
                            }
                        })
                    }}
                    //onUploadedSuccess={(response) => { }}
                    //onUploadedFailed={(errorMessage, errorCode) => { }}
                    imageType={imageType}
                    //aspect={4 / 3}
                />



            </Card>


        </div>)
    }

}

export default MultiUploadImagesForFiles;
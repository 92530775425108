
import React from "react";
import {
    InputNumber, Icon, Button, Modal,
} from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";

//type State = {}
class ModalNumeratorsPointsOfSale extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {}

        this.id = "MODAL_NUMERATORS_POINTS_OF_SALE";

        this.api = {
            get: "get_numerators_per_pos",
            edit: "edit_numerators_per_pos",

            get_admin: "get_numerators_per_pos_for_admin",
            edit_admin: "edit_numerators_per_pos_for_admin",
        }

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    initFilters = () => {
        if (this.props.ui.data && this.props.ui.data.compIdFromAdmin) {
            let request = { compId: this.props.ui.data.compIdFromAdmin, posId: this.props.ui.data.sID }
            this.props.dataActions.genericDataGetEditWithParams(
                this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get_admin);
        } else {
            let request = { rID: this.props.ui.data ? this.props.ui.data['sID'] : 0 }
            this.props.dataActions.genericDataGetEditWithParams(
                this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        }
    }

    refreshData = () => {
        let api = this.props.ui.data && this.props.ui.data.compIdFromAdmin ? this.api.get_admin : this.api.get
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, api);
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true))
            this.setState({}, this.initFilters(), {});
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalNumeratorsPointsOfSale);
    }

    render() {
        const { loading } = this.state;

        let posNum = this.props.ui.data ? this.props.ui.data['sID'] : 0;
        let posName = this.props.ui.data ? this.props.ui.data['sName'] : '';

        let titleName = posNum && posName ? this.getText(11703) + ' ' + posNum + ' - ' + posName : '';

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={titleName}
                    actionButtons={[{
                        buttonType: 'refresh',
                        buttonFunction: this.initFilters
                    }]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let columns = [
            {
                title: this.getText(11704),
                dataIndex: "rID",
                key: "rID",
                width: '8%',
                render: (text, record) => text
            }, {
                title: this.getText(11705),
                dataIndex: "rName",
                key: "rName",
                width: '28%',
                render: (text, record) => text
            }, {
                title: this.getText(11706),
                dataIndex: "rCurrent",
                key: "rCurrent",
                width: '28%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        let v = this.props.data[this.id].editedRecord['rCurrent'];
                        let o = (value) => this.props.dataActions.genericDataSetEdit(this.id, { rCurrent: value });
                        return (<InputNumber style={{ width: "90%" }} value={v} onChange={o} />);
                    }
                }
            }, {
                title: this.getText(11707),
                dataIndex: "rLast",
                key: "rLast",
                width: '28%',
                render: (text, record) => text
            }, {
                title: this.getText(11708),
                width: '8%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                            this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                        }} />);
                    } else {
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"} onClick={() => {
                                if (this.props.ui.data && this.props.ui.data.compIdFromAdmin) {
                                    let request = { compId: this.props.ui.data.compIdFromAdmin, posId: this.props.ui.data.sID }
                                    this.props.dataActions.genericDataJustSaveEditWithParams(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit_admin, request);
                                    setTimeout(this.initFilters, 1000);
                                } else {
                                    this.props.dataActions.genericDataJustSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit);
                                    setTimeout(this.initFilters, 1000);
                                }
                            }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"} onClick={() => {
                                this.props.dataActions.genericDataCancelEditing(this.id)
                            }} />
                        </span>)
                    }
                }
            },
        ]

        let widthStyle = window.innerWidth > 600 ? '70%' : '95%'

        return (
            <Modal
                destroyOnClose={true} width={widthStyle} visible={false || this.props.toggle}
                title={titleBar} onOk={this.handleOk} onCancel={this.handleCancel}
                footer={[<Button key="back" type="primary" onClick={this.handleCancel}>{this.getText(11709)}</Button>]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction} >
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    tableOnly={true}
                    columns={columns}
                    percentageToMobile={[false, 40, 30, 30]}
                    pagination={false}
                    scroll={{ y: window.innerHeight / 2 }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
            </Modal >)
    }
}

export default ModalNumeratorsPointsOfSale;

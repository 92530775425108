/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import { Select, Form, Checkbox, Row, Col, Button } from "antd";
import PageToolbar from "../../components/PageToolbar";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelector from "../../components/GenericSelector"


class HeshinFile extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            setId: null,
            colActive: '',
        };

        this.id = "HESHIN_SETS";
        this.prm_id = "HESHIN_SETS_PRM";

        this.api = {
            get: "get_heshin_sets",
            get_prm: "get_heshin_sets_prm",
            get_file: "get_heshin_file",
            get_prm_file: "get_heshin_prm_file",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.prm_id] === undefined) props.data[this.prm_id] = { ...props.data.genericData };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, {}, this.api.get);
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(15936);
    }

    getDataSource = () => {
        return this.props.data[this.prm_id].data.map((x, index) => {
            let y = x.split('\f');
            return {
                index,
                key: y[0],
                pID: y[0],
                pPrmId: y[1],
                pOrder: parseInt(y[2]),
                pDescription: y[3],
                pLength: parseInt(y[4]),
            }
        }).filter(f => f.pLength)
    }

    activeCheckbox = (text, record) => {
        let colActive = this.state.colActive ? this.state.colActive.split(',') : []
        return (<Checkbox
            checked={colActive.indexOf(record.pID) > -1}
            onChange={(e) => {
                let c = e.target.checked;

                if (c) colActive.push(record.pID);
                else colActive = colActive.filter(f => f !== record.pID);

                this.setState({ colActive: colActive.join(',') })
            }}
        />)
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    reverseHe = (str) => {
        let res = '';
        let notHeChars = '';
        let notHeRegexp = /[0-9a-zA-Z]/;
        let heCharsAdd = () => {
            if (notHeChars) {
                res += notHeChars.split('').reverse().join('');
                notHeChars = '';
            }
        }

        str.split('').forEach((x) => {
            if (x.search(notHeRegexp) > -1) {
                notHeChars += x;
            } else {
                heCharsAdd();
                res += x;
            }
        });
        heCharsAdd();

        return res.split('').reverse().join('');
    }

    printHeshin = () => {
        const { setId, colActive } = this.state;
        let dataSend = "hID\fcolActive\r" + (setId ? setId : '') + '\f' + (colActive ? colActive : '');
        this.sendAPI(this.api.get_file, dataSend, (ob) => {
            if (ob.data) {
                let heRegexp = /[א-ת]/;

                let text = ob.data.split("\r").map((x) => {
                    return x.split("\f").map(y => y && y.search(heRegexp) > -1 ? this.reverseHe(y) : y).join("")
                }).join("\r\n");

                let uint8 = new Uint8Array(text.length);
                for (let i = 0; i < uint8.length; i++) {
                    let b = text.charCodeAt(i);
                    uint8[i] = (b >= 1488 && b <= 1514) ? b - 1360 : b;
                }

                this.printFile([uint8], 'text/plain;charset=cp862', 'heshin.dat');
            }
        });

        this.sendAPI(this.api.get_prm_file, dataSend, (ob) => {
            if (ob.data) {
                let prmData = ob.data.split('\r').map(x => x.split('\f').join(' ')).join(";\r\n") + ';';
                this.printFile([prmData], 'text/plain', 'heshin.prm');
            }
        });

    }

    printFile = (data, type, fileName) => {
        let blob = new Blob(data, { type });
        let anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = [type, anchor.download, anchor.href].join(':');
        anchor.click();
    }

    printExcel = () => {
        const { setId, colActive } = this.state;
        let dataSend = "hID\fcolActive\r" + (setId ? setId : '') + '\f' + (colActive ? colActive : '');
        this.sendAPI(this.api.get_file, dataSend, (ob) => {
            if (ob.data) {
                let data = [
                    "\ufeff",
                    ob.data.split("\r").map((x) => {
                        return x.split("\f").map(y => '"' + y.split('"').join('""') + '"').join(",")
                    }).join("\r\n")
                ];

                this.printFile(data, "text/csv;charset=utf8", 'heshin.csv');
            }
        });
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            maxWidth: 800,
        };

        let numOfRowsByHeight = parseInt((window.innerHeight - 340 > 30) ? (window.innerHeight - 340) / 30 : 1);

        return (<div style={divStyle}>
            <PageToolbar title={gt(15937)} actionButtons={[]} />
            <Form.Item label={gt(15938)}>
                <Select
                    showSearch
                    filterOption={true}
                    optionFilterProp="valuefilter"
                    style={{ width: 300 }}
                    value={this.state.setId}
                    onChange={(e) => {
                        this.setState({ setId: e }, () => {
                            this.props.dataActions.genericDataSetFilter(
                                this.prm_id, this.props.user.companyCode, this.props.user.token, { hID: e }, this.api.get_prm);
                            setTimeout(() => {
                                this.setState({ colActive: this.getDataSource().map(x => x.pID).join(',') })
                            }, 1000)
                        })
                    }}>
                    {this.props.data[this.id].data.map(x => {
                        let y = x.split('\f');
                        return (<Select.Option value={y[0]} valuefilter={y[1]}>{y[1]}</Select.Option>)
                    })}
                </Select>
            </Form.Item>

            <ResponsiveTable
                dataSource={this.getDataSource()}
                tableOnly
                columns={[
                    { title: gt(15939), key: 'pOrder', dataIndex: 'pOrder', width: '10%', render: t => t },
                    { title: gt(15940), key: 'pDescription', dataIndex: 'pDescription', width: '80%', render: t => t },
                    { title: gt(15941), width: '10%', render: this.activeCheckbox },
                ]}
                pagination={{ pageSize: numOfRowsByHeight > 4 ? numOfRowsByHeight - 4 : numOfRowsByHeight }}
            />

            <Row>
                <Col span={12} style={{ paddingTop: 15, textAlign: 'center' }}>
                    <Button type="primary" onClick={this.printHeshin} disabled={!this.state.setId}>{gt(15942)}</Button>
                </Col>
                <Col span={12} style={{ paddingTop: 15, textAlign: 'center' }}>
                    <Button type="primary" onClick={this.printExcel} disabled={!this.state.setId}>{gt(15943)}</Button>
                </Col>
            </Row>
        </div>)
    }

}

export default HeshinFile;
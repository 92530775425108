/* @flow */
/*jshint esversion: 6 */
import { Checkbox } from "antd";
import React, { Component } from "react";
import "../../App.css";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";
import { Input } from "antd"


class Currencies extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "CURRENCIES_ADMIN";

        this.api = {
            get: "get_currencies_admin",
            checked: "set_currencies",
            edit: "edit_currencies_admin",
            create: "create_currencies_admin",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, {}, this.api.get);
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(17894);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    getCheckbox = (text, record) => {
        const { data } = this.props.data[this.id]
        let checked = text ? true : false;
        let indeterminate = false;

        if (text === 'all') {
            data.forEach(x => {
                let y = x.split("\f");
                if (y[4]) indeterminate = true;
                else checked = false;
            });
            if (checked) indeterminate = false;
        }

        const onChange = (e) => {
            this.props.dataActions.genericDataSaveEditWithParams(
                this.id, this.props.user.companyCode, this.props.user.token, this.api.checked, this.api.get, {
                _id: text === 'all' ? '0' : record.key,
                _checked: e.target.checked ? '1' : '0'
            });
        }

        return (<Checkbox {...{ checked, indeterminate, onChange }} />)
    }

    getInputField = (field) => {
        const { editedRecord } = this.props.data[this.id];
        return (<Input
            value={editedRecord[field]}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.target.value }) }}
        />)
    }

    renderEditCol = (text, record) => {
        const { editing } = this.props.data[this.id];

        return (editing != record.key) ?
            (<TableLineIcons onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }} />)
            : (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.id, this.props.user.companyCode, this.props.user.token,
                        editing == 0 ? this.api.create : this.api.edit,
                        this.api.get);
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }}
            />)
    }

    createNew = () => {
        if (this.props.data[this.id].editing == -1)
            this.props.dataActions.genericDataStartInlineCreate(this.id)
    }

    render() {
        const gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const { editing } = this.props.data[this.id]

        let columns = [///CurrencyID, CurrencyName, CurrencyCode, CurrencySymbol
            { title: this.getCheckbox("all"), key: "cIsChecked", dataIndex: "cIsChecked", width: "10%", render: this.getCheckbox },
            { title: gt(17896), width: "10%", key: "cId", dataIndex: "cId", render: t => t },
            { title: gt(17897), width: "40%", key: "cName", dataIndex: "cName", render: (t, r) => editing == r.key ? this.getInputField("cName") : t },
            { title: gt(17898), width: "20%", key: "cCode", dataIndex: "cCode", render: (t, r) => editing == r.key ? this.getInputField("cCode") : t },
            { title: gt(17899), width: "10%", key: "cSymbol", dataIndex: "cSymbol", render: (t, r) => editing == r.key ? this.getInputField("cSymbol") : t },
        ]

        let actionButtons = [
            { buttonType: 'refresh', buttonFunction: this.refreshData },
        ]

        if (this.props.user.companyCode === '2f5a') {
            columns.push({ title: gt(17900), width: "10%", render: this.renderEditCol })
            actionButtons.push({ buttonType: 'add', buttonFunction: this.createNew })
        }

        return (<div style={divStyle}>
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                title={gt(17895)}
                columns={columns}
                actionButtons={actionButtons}
            />
        </div>)
    }

}

export default Currencies;
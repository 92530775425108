/* eslint-env es6 */
/**
 * Jax - a simple Json Accessor library
 * Usage: Jax.get() and Jax.set()
 *
 * Copyright (C) 2018  Itay Avtalyon <itay@imoogi.com>

 This program is free software: you can redistribute it and/or modify
 it under the terms of the GNU General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 This program is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU General Public License for more details.

 You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

function Jax(object) {
    function _Jax (object) {
        this.object = object;
        this.results = [];
        this.virtual = [];
        this.sources = {};
        this.isVirtual = false;
        let self = this;

        this.path = function (path) {
            const pathArr = path.split(".");
            let obj = self.object;
            for (let i = 0, j = pathArr.length - 1; i < j; i++) {
                if (pathArr[i].indexOf("[") > -1) {
                    let link = pathArr[i].split("[");
                    let index = parseInt(link[1].split("]")[0], 10);
                    if (obj[link[0]] === undefined) {
                        obj[link[0]] = [];
                        obj[link[0]][index] = {};
                    }
                    obj = obj[link[0]][index];
                } else {
                    if (obj[pathArr[i]] === undefined) {
                        obj[pathArr[i]] = {};
                    }
                    obj = obj[pathArr[i]];
                }
            }

            if (obj[pathArr[pathArr.length - 1]]) {
                self.object = obj[pathArr[pathArr.length -1]];
            } else {
                self.object = null;
            }

            return self;
        };

        this.virtual = function () {
            self.object = null;
            self.isVirtual = true;
            return self;
        };

        this.convert = function (conv) {
            if (Array.isArray(self.object)) {
                for (let i = 0, j = self.object.length; i < j; i++) {
                    if (typeof conv === "function") {
                        self.object[i] = conv(self.object[i]);
                    } else {
                        self.object[i] = JSON.parse(self.object[i]);
                    }
                }
            } else {
                if (typeof conv === "function") {
                    self.object = conv(self.object);
                } else {
                    self.object = JSON.parse(self.object);
                }
            }

            return self;
        };

        this.list = function () {
            self.function = "list";
            if (self.isVirtual) {
                self.results = Array.from(self.virtual);
            } else {
                if (self.object === undefined || self.object == null) {
                    self.results = [];
                } else if (Array.isArray(self.object)) {
                    self.results = Array.from(self.object);
                } else {
                    self.results = [self.object];
                }
            }
            return self;
        };

        this.values = function (field) {
            self.function = "values";
            self.findField = field;
            if (self.isVirtual) {
                self.results = self.virtual.map((el) => el[self.findField]);
            } else {
                if (self.object === undefined || self.object == null) {
                    self.results = [];
                } else if (Array.isArray(self.object)) {
                    self.results = self.object.map((el) => el[self.findField]);
                } else {
                    self.results = [self.object[self.findField]];
                }
            }
            return self;
        };

        this.insert = function (record) {
            if (self.isVirtual) {
                self.virtual.push(record);
            } else {
                // Immutability is broken here!
                self.object.push(record);
            }

            return self;
        };

        this.insertAt = function (index, record) {
            if (self.isVirtual) {
                self.virtual.splice(index, 0, record);
            } else {
                // Immutability is broken here!
                self.object.splice(index, 0, record);
            }

            return self;
        };

        this.replace = function (index, record) {
            if (self.isVirtual) {
                self.virtual.splice(index, 1, record);
            } else {
                // Immutability is broken here!
                self.object.splice(index, 1, record);
            }

            return self;
        };

        this.remove = function (index) {
            if (self.isVirtual) {
                self.virtual.splice(index, 1);
            } else {
                // Immutability is broken here!
                self.object.splice(index, 1);
            }

            return self;
        };

        this.map  = function (mapFunction) {
            return self.results.map(mapFunction);
        };

        this.reduce = function (reduceFunction, initialValue) {
            return self.results.reduce(reduceFunction, initialValue);
        };

        this.where = function (field) {
            self.whereField = field;
            return self;
        };

        this.equals = function (value) {
            let results = [];
            switch (self.function) {
                case "list":
                    return self.results.filter(function (el) {
                        return el[self.whereField] === value;
                    });

                case "values":
                    if (Array.isArray(self.object)) {
                        for (let i = 0, j = self.object.length; i < j; i++) {
                            if (self.object[i][self.whereField] === value) {
                                results.push(self.results[i]);
                            }
                        }
                    } else {
                        if (self.object !== undefined && self.object !== null && self.object[self.whereField] === value) {
                            results.push(self.results[0]);
                        }
                    }
                    return results;

                default:
                    return [];
            }
        };

        this.setSource = function (data) {
            console.log("setSource start");
            for (let key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    self.sources[key] = data[key];
                }
            }
            console.log("setSource end");
            return self;
        };

        this.produce = function (producer) {
            console.log("produce start");
            if (typeof producer === "function") {
                self.virtual = producer(self.sources, self.virtual);
            }
            console.log("produce end");
            return self;
        };

        this.count = function () {
            if (self.virtual) {
                return self.virtual.length
            } else {
                return self.object.length;
            }
        }
    }



    return new _Jax(object);
}



/**
 * Returns the value specified by the path or the default value if path resolves to undefined
 * @param json the object containing the data
 * @param path the path of the value we are interested in
 * @param defaultValue the default value to return if not found
 * @returns {*} the value defined by the path or the default value
 */
Jax.get = function (json, path, defaultValue) {
    const pathArr = path.split(".");
    let obj = json;
    let dontStop = 1;
    let i, j;

    if (json === undefined || json === null) {
        return defaultValue;
    }

    for (i = 0, j = pathArr.length; i < j && dontStop === 1; i++) {
        if (pathArr[i].indexOf("[") > -1) {
            let link = pathArr[i].split("[");
            let index = parseInt(link[1].split("]")[0], 10);
            obj = obj[link[0]];
            if (obj !== undefined) {
                obj = obj[index];
            }
        } else {
            obj = obj[pathArr[i]];
        }
        if (obj === undefined) {
            dontStop = 0;
        }
    }

    return obj !== undefined ? obj : defaultValue;
};

/**
 * Creates a clone of the object, sets a value according to the path and returns the clone
 * @param json The original to be cloned and modified (the original remains untouched)
 * @param path The JSON path where to save the value. It will be created if it doesn't exist
 * @param value The value to be set in the cloned object
 * @returns {*} The cloned object with the modifications
 */
Jax.set = function (json, path, value) {
    const pathArr = path.split(".");
    let obj;
    let i, j;
    let newObj;
    let key;


    if (typeof Object.assign === "function") {
        newObj = Object.assign({}, json);
    } else {
        newObj = {};
        for (key in json) {
            // Calling hasOwnProperty ensures we are calling the original function we want
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                console.log("key: ", key, " json: ", json);
                newObj[key] = json[key];
            }
        }
    }



    if (newObj) {
        obj = newObj;
        for (i = 0, j = pathArr.length - 1; i < j; i++) {
            if (pathArr[i].indexOf("[") > -1) {
                let link = pathArr[i].split("[");
                let index = parseInt(link[1].split("]")[0], 10);
                if (obj[link[0]] === undefined) {
                    obj[link[0]] = [];
                    obj[link[0]][index] = {};
                }
                obj = obj[link[0]][index];
            } else {
                if (obj[pathArr[i]] === undefined) {
                    obj[pathArr[i]] = {};
                }
                obj = obj[pathArr[i]];
            }
        }

        if (pathArr[pathArr.length - 1].indexOf("[") > -1) {
            let link = pathArr[pathArr.length - 1].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            obj[link[0]][index] = value;
        } else {
            obj[pathArr[pathArr.length - 1]] = value;
        }
    }

    return newObj;
};

Jax.inc = function (json, path) {
    const pathArr = path.split(".");
    let obj;
    let i, j;
    let newObj;
    let key;

    if (typeof Object.assign === "function") {
        newObj = Object.assign({}, json);
    } else {
        newObj = {};
        for (key in json) {
            // Calling hasOwnProperty ensures we are calling the original function we want
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                console.log("key: ", key, " json: ", json);
                newObj[key] = json[key];
            }
        }
    }

    if (newObj) {
        obj = newObj;
        for (i = 0, j = pathArr.length - 1; i < j; i++) {
            if (pathArr[i].indexOf("[") > -1) {
                let link = pathArr[i].split("[");
                let index = parseInt(link[1].split("]")[0], 10);
                if (obj[link[0]] === undefined) {
                    obj[link[0]] = [];
                    obj[link[0]][index] = {};
                }
                obj = obj[link[0]][index];
            } else {
                if (obj[pathArr[i]] === undefined) {
                    obj[pathArr[i]] = {};
                }
                obj = obj[pathArr[i]];
            }
        }

        if (pathArr[pathArr.length - 1].indexOf("[") > -1) {
            let link = pathArr[pathArr.length - 1].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            if (typeof obj[link[0]][index] === "number") {
                obj[link[0]][index]++;
            }
        } else {
            if (typeof obj[pathArr[pathArr.length - 1]] === "number") {
                obj[pathArr[pathArr.length - 1]]++;
            }
        }
    }

    return newObj;
};

Jax.dec = function (json, path) {
    const pathArr = path.split(".");
    let obj;
    let i, j;
    let newObj;
    let key;


    if (typeof Object.assign === "function") {
        newObj = Object.assign({}, json);
    } else {
        newObj = {};
        for (key in json) {
            // Calling hasOwnProperty ensures we are calling the original function we want
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                console.log("key: ", key, " json: ", json);
                newObj[key] = json[key];
            }
        }
    }

    if (newObj) {
        obj = newObj;
        for (i = 0, j = pathArr.length - 1; i < j; i++) {
            if (pathArr[i].indexOf("[") > -1) {
                let link = pathArr[i].split("[");
                let index = parseInt(link[1].split("]")[0], 10);
                if (obj[link[0]] === undefined) {
                    obj[link[0]] = [];
                    obj[link[0]][index] = {};
                }
                obj = obj[link[0]][index];
            } else {
                if (obj[pathArr[i]] === undefined) {
                    obj[pathArr[i]] = {};
                }
                obj = obj[pathArr[i]];
            }
        }

        if (pathArr[pathArr.length - 1].indexOf("[") > -1) {
            let link = pathArr[pathArr.length - 1].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            if (typeof obj[link[0]][index] === "number") {
                obj[link[0]][index]--;
            }
        } else {
            if (typeof obj[pathArr[pathArr.length - 1]] === "number") {
                obj[pathArr[pathArr.length - 1]]--;
            }
        }
    }

    return newObj;
};

Jax.mutate = function (json, path, value) {
    const pathArr = path.length > 0 ? path.split(".") : [];
    let obj;
    let i, j;
    let newObj;
    let key;

    if (typeof Object.assign === "function") {
        newObj = Object.assign({}, json);
    } else {
        newObj = {};
        for (key in json) {
            // Calling hasOwnProperty ensures we are calling the original function we want
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                newObj[key] = json[key];
            }
        }
    }

    if (newObj) {
        obj = newObj;
        if (pathArr.length > 0) {
            for (i = 0, j = pathArr.length - 1; i < j; i++) {
                if (pathArr[i].indexOf("[") > -1) {
                    let link = pathArr[i].split("[");
                    let index = parseInt(link[1].split("]")[0], 10);
                    if (obj[link[0]] === undefined) {
                        obj[link[0]] = [];
                        obj[link[0]][index] = {};
                    }
                    obj = obj[link[0]][index];
                } else {
                    if (obj[pathArr[i]] === undefined) {
                        obj[pathArr[i]] = {};
                    }
                    obj = obj[pathArr[i]];
                }
            }

            if (pathArr[pathArr.length - 1].indexOf("[") > -1) {
                let link = pathArr[pathArr.length - 1].split("[");
                let index = parseInt(link[1].split("]")[0], 10);

                if (obj[link[0]] === undefined) {
                    obj[link[0]] = [];
                    obj[link[0]][index] = {};
                } else if (obj[link[0]][index] === undefined) {
                    obj[link[0]][index] = {};
                } else {
                    let clone = {};
                    for (let key in obj[link[0]][index]) {
                        if (Object.prototype.hasOwnProperty.call(obj[link[0]][index], key)) {
                            clone[key] = obj[link[0]][index][key];
                        }
                    }
                    obj[link[0]][index] = clone;
                }
                obj = obj[link[0]][index];
            } else {
                if (obj[pathArr[pathArr.length - 1]] === undefined) {
                    obj[pathArr[pathArr.length - 1]] = {};
                } else {
                    let clone = {};
                    for (let key in obj[pathArr[pathArr.length - 1]]) {
                        if (Object.prototype.hasOwnProperty.call(obj[pathArr[pathArr.length - 1]], key)) {
                            clone[key] = obj[pathArr[pathArr.length - 1]][key];
                        }
                    }
                    obj[pathArr[pathArr.length - 1]] = clone;
                }
                obj = obj[pathArr[pathArr.length - 1]];
            }
        }

        for (key in value) {
            if (Object.prototype.hasOwnProperty.call(value, key)) {
                obj[key] = value[key];
            }
        }
    }

    return newObj;
};

Jax.push = function (json, path, value) {
    const pathArr = path.split(".");
    let obj;
    let i, j;
    let newObj;
    let key;

    if (typeof Object.assign === "function") {
        newObj = Object.assign({}, json);
    } else {
        newObj = {};
        for (key in json) {
            // Calling hasOwnProperty ensures we are calling the original function we want
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                newObj[key] = json[key];
            }
        }
    }

    if (newObj) {
        obj = newObj;
        for (i = 0, j = pathArr.length - 1; i < j; i++) {
            if (pathArr[i].indexOf("[") > -1) {
                let link = pathArr[i].split("[");
                let index = parseInt(link[1].split("]")[0], 10);
                if (obj[link[0]] === undefined) {
                    obj[link[0]] = [];
                    obj[link[0]][index] = {};
                }
                obj = obj[link[0]][index];
            } else {
                if (obj[pathArr[i]] === undefined) {
                    obj[pathArr[i]] = {};
                }
                obj = obj[pathArr[i]];
            }
        }

        if (pathArr[pathArr.length - 1].indexOf("[") > -1) {
            let link = pathArr[pathArr.length - 1].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            if (Array.isArray(obj[link[0]][index])) {
                obj[link[0]][index].push(value);
            } else if (obj[link[0]] === undefined) {
                obj[link[0]] = [];
                obj[link[0]][index] = [value];

            } else if (obj[link[0]][index] === undefined) {
                obj[link[0]][index] = [value];
            }
        } else {
            if (Array.isArray(obj[pathArr[pathArr.length - 1]])) {
                obj[pathArr[pathArr.length - 1]].push(value);
            } else if (obj[pathArr[pathArr.length - 1]] === undefined) {
                obj[pathArr[pathArr.length - 1]] = [value];
            }
        }
    }

    return newObj;
};

Jax.pop = function (json, path) {
    const pathArr = path.split(".");
    let obj;
    let i, j;
    let newObj;
    let key;

    if (typeof Object.assign === "function") {
        newObj = Object.assign({}, json);
    } else {
        newObj = {};
        for (key in json) {
            // Calling hasOwnProperty ensures we are calling the original function we want
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                newObj[key] = json[key];
            }
        }
    }

    if (newObj) {
        obj = newObj;
        for (i = 0, j = pathArr.length - 1; i < j; i++) {
            if (pathArr[i].indexOf("[") > -1) {
                let link = pathArr[i].split("[");
                let index = parseInt(link[1].split("]")[0], 10);
                if (obj[link[0]] === undefined) {
                    obj[link[0]] = [];
                    obj[link[0]][index] = {};
                }
                obj = obj[link[0]][index];
            } else {
                if (obj[pathArr[i]] === undefined) {
                    obj[pathArr[i]] = {};
                }
                obj = obj[pathArr[i]];
            }
        }

        if (pathArr[pathArr.length - 1].indexOf("[") > -1) {
            let link = pathArr[pathArr.length - 1].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            if (Array.isArray(obj[link[0]][index])) {
                obj[link[0]][index].pop();
            } else if (obj[link[0]] === undefined) {
                obj[link[0]] = [];
                obj[link[0]][index] = [];

            } else if (obj[link[0]][index] === undefined) {
                obj[link[0]][index] = [];
            }
        } else {
            if (Array.isArray(obj[pathArr[pathArr.length - 1]])) {
                obj[pathArr[pathArr.length - 1]].pop();
            } else if (obj[pathArr[pathArr.length - 1]] === undefined) {
                obj[pathArr[pathArr.length - 1]] = [];
            }
        }
    }

    return newObj;
};

Jax.shift = function (json, path) {
    const pathArr = path.split(".");
    let obj;
    let i, j;
    let newObj;
    let key;

    if (typeof Object.assign === "function") {
        newObj = Object.assign({}, json);
    } else {
        newObj = {};
        for (key in json) {
            // Calling hasOwnProperty ensures we are calling the original function we want
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                newObj[key] = json[key];
            }
        }
    }

    if (newObj) {
        obj = newObj;
        for (i = 0, j = pathArr.length - 1; i < j; i++) {
            if (pathArr[i].indexOf("[") > -1) {
                let link = pathArr[i].split("[");
                let index = parseInt(link[1].split("]")[0], 10);
                if (obj[link[0]] === undefined) {
                    obj[link[0]] = [];
                    obj[link[0]][index] = {};
                }
                obj = obj[link[0]][index];
            } else {
                if (obj[pathArr[i]] === undefined) {
                    obj[pathArr[i]] = {};
                }
                obj = obj[pathArr[i]];
            }
        }

        if (pathArr[pathArr.length - 1].indexOf("[") > -1) {
            let link = pathArr[pathArr.length - 1].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            if (Array.isArray(obj[link[0]][index])) {
                obj[link[0]][index].shift();
            } else if (obj[link[0]] === undefined) {
                obj[link[0]] = [];
                obj[link[0]][index] = [];

            } else if (obj[link[0]][index] === undefined) {
                obj[link[0]][index] = [];
            }
        } else {
            if (Array.isArray(obj[pathArr[pathArr.length - 1]])) {
                obj[pathArr[pathArr.length - 1]].shift();
            } else if (obj[pathArr[pathArr.length - 1]] === undefined) {
                obj[pathArr[pathArr.length - 1]] = [];
            }
        }
    }

    return newObj;
};

Jax.unshift = function (json, path, value) {
    const pathArr = path.split(".");
    let obj;
    let i, j;
    let newObj;
    let key;

    if (typeof Object.assign === "function") {
        newObj = Object.assign({}, json);
    } else {
        newObj = {};
        for (key in json) {
            // Calling hasOwnProperty ensures we are calling the original function we want
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                newObj[key] = json[key];
            }
        }
    }

    if (newObj) {
        obj = newObj;
        for (i = 0, j = pathArr.length - 1; i < j; i++) {
            if (pathArr[i].indexOf("[") > -1) {
                let link = pathArr[i].split("[");
                let index = parseInt(link[1].split("]")[0], 10);
                if (obj[link[0]] === undefined) {
                    obj[link[0]] = [];
                    obj[link[0]][index] = {};
                }
                obj = obj[link[0]][index];
            } else {
                if (obj[pathArr[i]] === undefined) {
                    obj[pathArr[i]] = {};
                }
                obj = obj[pathArr[i]];
            }
        }

        if (pathArr[pathArr.length - 1].indexOf("[") > -1) {
            let link = pathArr[pathArr.length - 1].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            if (Array.isArray(obj[link[0]][index])) {
                obj[link[0]][index].unshift(value);
            } else if (obj[link[0]] === undefined) {
                obj[link[0]] = [];
                obj[link[0]][index] = [value];

            } else if (obj[link[0]][index] === undefined) {
                obj[link[0]][index] = [value];
            }
        } else {
            if (Array.isArray(obj[pathArr[pathArr.length - 1]])) {
                obj[pathArr[pathArr.length - 1]].unshift(value);
            } else if (obj[pathArr[pathArr.length - 1]] === undefined) {
                obj[pathArr[pathArr.length - 1]] = [value];
            }
        }

    }

    return newObj;
};

Jax.splice = function (json, path, index, count, value) {
    const pathArr = path.split(".");
    let obj;
    let i, j;
    let newObj;
    let key;

    if (typeof Object.assign === "function") {
        newObj = Object.assign({}, json);
    } else {
        newObj = {};
        for (key in json) {
            // Calling hasOwnProperty ensures we are calling the original function we want
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                newObj[key] = json[key];
            }
        }
    }

    if (newObj && index > -1) {
        obj = newObj;
        for (i = 0, j = pathArr.length - 1; i < j; i++) {
            if (pathArr[i].indexOf("[") > -1) {
                let link = pathArr[i].split("[");
                let index = parseInt(link[1].split("]")[0], 10);
                if (obj[link[0]] === undefined) {
                    obj[link[0]] = [];
                    obj[link[0]][index] = {};
                }
                obj = obj[link[0]][index];
            } else {
                if (obj[pathArr[i]] === undefined) {
                    obj[pathArr[i]] = {};
                }
                obj = obj[pathArr[i]];
            }
        }

        if (pathArr[pathArr.length - 1].indexOf("[") > -1) {
            let link = pathArr[pathArr.length - 1].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            if (Array.isArray(obj[link[0]][index])) {
                if (value !== undefined) {
                    obj[link[0]][index].splice(index, count, value);
                } else {
                    obj[link[0]][index].splice(index, count);
                }
            } else if (obj[link[0]] === undefined) {
                obj[link[0]] = [];
                obj[link[0]][index] = [];
                if (value !== undefined) {
                    obj[link[0]][index].splice(index, count, value);
                } else {
                    obj[link[0]][index].splice(index, count);
                }
            } else if (obj[link[0]][index] === undefined) {
                obj[link[0]][index] = [];
                if (value !== undefined) {
                    obj[link[0]][index].splice(index, count, value);
                } else {
                    obj[link[0]][index].splice(index, count);
                }
            }
        } else {
            if (Array.isArray(obj[pathArr[pathArr.length - 1]])) {
                if (value !== undefined) {
                    obj[pathArr[pathArr.length - 1]].splice(index, count, value);
                } else {
                    obj[pathArr[pathArr.length - 1]].splice(index, count);
                }
            } else if (obj[pathArr[pathArr.length - 1]] === undefined) {
                obj[pathArr[pathArr.length - 1]] = [];
                if (value !== undefined) {
                    obj[pathArr[pathArr.length - 1]].splice(index, count, value);
                } else {
                    obj[pathArr[pathArr.length - 1]].splice(index, count);
                }
            }
        }
    }

    window.console.log(newObj);

    return newObj;
};

Jax.filter = function (json, path, filterFunction) {
    const pathArr = path.split(".");
    let obj = json;
    let dontStop = 1;
    let i, j;

    if (json === undefined || json === null) {
        return [];
    }

    for (i = 0, j = pathArr.length; i < j && dontStop === 1; i++) {
        if (pathArr[i].indexOf("[") > -1) {
            let link = pathArr[i].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            obj = obj[link[0]][index];
        } else {
            obj = obj[pathArr[i]];
        }
        if (obj === undefined) {
            dontStop = 0;
        }
    }

    return obj !== undefined && Array.isArray(obj) ? obj.filter(filterFunction) : [];
};

Jax.map = function (json, path, mappingFunction) {
    const pathArr = path.split(".");
    let obj = json;
    let dontStop = 1;
    let i, j;

    if (json === undefined || json === null) {
        return [];
    }

    for (i = 0, j = pathArr.length; i < j && dontStop === 1; i++) {
        if (pathArr[i].indexOf("[") > -1) {
            let link = pathArr[i].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            obj = obj[link[0]][index];
        } else {
            obj = obj[pathArr[i]];
        }
        if (obj === undefined) {
            dontStop = 0;
        }
    }

    return obj !== undefined && Array.isArray(obj) ? obj.map(mappingFunction) : [];
};

Jax.reduce = function (json, path, reduceFunction, initialValue) {
    const pathArr = path.split(".");
    let obj = json;
    let dontStop = 1;
    let i, j;

    if (json === undefined || json === null) {
        return [];
    }

    for (i = 0, j = pathArr.length; i < j && dontStop === 1; i++) {
        if (pathArr[i].indexOf("[") > -1) {
            let link = pathArr[i].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            obj = obj[link[0]][index];
        } else {
            obj = obj[pathArr[i]];
        }
        if (obj === undefined) {
            dontStop = 0;
        }
    }

    return obj !== undefined && Array.isArray(obj) ? obj.reduce(reduceFunction, initialValue) : undefined;
};

Jax.findFirst = function (json, path, queryFunction) {
    const pathArr = path.split(".");
    let obj = json;
    let dontStop = 1;
    let i, j;

    if (json === undefined || json === null) {
        return [];
    }

    for (i = 0, j = pathArr.length; i < j && dontStop === 1; i++) {
        if (pathArr[i].indexOf("[") > -1) {
            let link = pathArr[i].split("[");
            let index = parseInt(link[1].split("]")[0], 10);

            obj = obj[link[0]][index];
        } else {
            obj = obj[pathArr[i]];
        }
        if (obj === undefined) {
            dontStop = 0;
        }
    }

    return obj !== undefined && Array.isArray(obj) ? obj.find(queryFunction) : undefined;
};

Jax.clone = function (json, path) {
    const pathArr = path.split(".");
    let obj = json;
    let dontStop = 1;
    let i, j;
    let key;
    let clone = {};

    if (json === undefined || json === null) {
        return null;
    }

    for (i = 0, j = pathArr.length; i < j && dontStop === 1; i++) {
        if (pathArr[i].indexOf("[") > -1) {
            let link = pathArr[i].split("[");
            let index = parseInt(link[1].split("]")[0], 10);
            obj = obj[link[0]];
            if (obj !== undefined) {
                obj = obj[index];
            }
        } else {
            obj = obj[pathArr[i]];
        }
        if (obj === undefined) {
            dontStop = 0;
        }
    }

    for (key in obj) {
        // Calling hasOwnProperty ensures we are calling the original function we want
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            clone[key] = obj[key];
        }
    }

    return clone;
};

export default Jax;

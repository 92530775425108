import React, { Component } from "react";
import TableWithPaging from '../../components/TableWithPaging';
import PageToolbar from '../../components/PageToolbar'
import ExtendedTable from '../../components/ExtendedTable';
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ModalCreate from '../../components/ModalCreate';
import ModalEditItem from '../../components/ModalEditItem';
import ModalAdvanceSearch from "../../components/ModalAdvanceSearch";

class ItemListTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: {
                pagination: {
                    current: 1,
                    defaultPageSize: 5,
                },
                sorting: {
                    col: 'col_1',
                    order: 0, // 0 - desend // 1 - asend
                },
                searchTerm: "",
                // tag filter
                tagList: [],
                isAll: false,
                // chk filter
                isActive: "",
                isWeightable: "",
                isFixedPrice: "",
                isForSale: "",
                isDiscount: "",
                // alert filter
                saleAlert: "",
            }
        }

        // table
        this.format = [{
            title: 'מזהה פריט',
            width: '0%',
            sorter: false,
            editable: false,
            isCheckBox: false,
        }, {
            title: 'קוד פריט',
            width: '15%',
            sorter: true,
            editable: true,
            isCheckBox: false,
        }, {
            title: 'ברקוד פריט',
            width: '15%',
            sorter: true,
            editable: true,
            isCheckBox: false,
        }, {
            title: 'שם מקוצר',
            width: '15%',
            sorter: true,
            editable: true,
            isCheckBox: false,
        }, {
            title: 'פעיל?',
            width: '8%',
            sorter: false,
            editable: false,
            isCheckBox: true,
        }, {
            title: 'שקיל?',
            width: '8%',
            sorter: false,
            editable: false,
            isCheckBox: true,
        }, {
            title: 'למכירה',
            width: '8%',
            sorter: false,
            editable: false,
            isCheckBox: true,
        }, {
            title: 'מחיר קבוע',
            width: '8%',
            sorter: false,
            editable: false,
            isCheckBox: true,
        }, {
            title: 'חסום להנחה',
            width: '8%',
            sorter: false,
            editable: false,
            isCheckBox: true,
        }, {
            title: 'פעולות',
            width: '9%',
            sorter: false,
            editable: false,
            isCheckBox: false,
            actions: [{
                actionType: 'delete',
                actionFunction: () => { alert('deleting'); },
            }, {
                actionType: 'edit_pop',
                actionFunction: (data) => { this.props.uiActions.showModal(this.modalID.edit,data); },
            }],
        }];

        this.modalID = {
            create: "ITEMS_CREATE_MODAL",
            search: "ITEMS_SEARCH_MODAL",
            edit: "ITEMS_EDIT_MODAL",
        }

        // toolbar params
        this.actionButtons = [{
            buttonType: 'add',
            buttonFunction: () => { this.props.uiActions.showModal(this.modalID.create); }
        }, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        }, {
            buttonType: 'search',
            buttonFunction: () => { this.props.uiActions.showModal(this.modalID.search); },
        },
        ];

        this.api = [{
            apiType: 'get',
            apiCall: this.api_getItems,
        }, {
            apiType: 'create',
            apiCall: this.api_createItem,
        }, {
            apiType: 'delete',
            apiCall: this.api_deleteItem,
        }, {
            apiType: 'edit',
            apiCall: this.api_editItem,
        }];

        // create modal params
        this.createFormat = [{
            title: 'מזהה',
            width: '8%',
            sorter: false,
            editable: false,
        }, {
            title: 'קוד פריט',
            width: '8%',
            sorter: false,
            editable: true,
        }, {
            title: 'ברקוד פריט',
            width: '10%',
            sorter: false,
            editable: true,
        }, {
            title: 'שם מקוצר',
            width: '8%',
            sorter: false,
            editable: true,
        }, {
            title: 'שם מלא',
            width: '10%',
            sorter: false,
            editable: true,
        }, {
            title: 'פעיל?',
            width: '8%',
            sorter: false,
            editable: true,
            isCheckBox: true,
        }, {
            title: 'שקיל?',
            width: '8%',
            sorter: false,
            editable: true,
            isCheckBox: true,
        }, {
            title: 'למכירה',
            width: '8%',
            sorter: false,
            editable: true,
            isCheckBox: true,
        }, {
            title: 'מחיר קבוע',
            width: '8%',
            sorter: false,
            editable: true,
            isCheckBox: true,
        }, {
            title: 'חסום להנחה',
            width: '8%',
            sorter: false,
            editable: true,
            isCheckBox: true,
        }, {
            title: 'התראת מכירה',
            width: '8%',
            sorter: false,
            editable: false,
        }, {
            title: 'עריכה',
            width: '8%',
            sorter: false,
            editable: false,
            actions: [{
                actionType: 'edit',
                actionFunction: () => { alert("editing"); },
            }],
        }];

        // search modal params
        this.searchFormat = [{
            type: 'regex', // regex, range, bool, drop
            description: 'שם פריט',
            api: () => { },
        }, {
            type: 'drop',
            description: 'תגיות',
            api: () => { }, // get option from DB
        }, {
            type: 'drop',
            description: 'פעיל',
            api: () => { },
        }, {
            type: 'drop',
            description: 'שקיל',
            api: () => { },
        }, {
            type: 'drop',
            description: 'למכירה',
            api: () => { },
        }, {
            type: 'drop',
            description: 'מחיר קבוע',
            api: () => { },
        }, {
            type: 'drop',
            description: 'חסום להנחה',
            api: () => { },
        }, {
            type: 'bool',
            description: 'התראת מכירה',
            api: () => { },
        }, {
            type: 'bool',
            description: 'כל התגיות',
            api: () => { },
        },]

        // TODO - set format sent to props od modal search
    }
/*
    dataCallback = (val: any) => {
        let data = {};
        data.searchTerm = val;
        data.pagination = {
          current: 1,
          defaultPageSize: 5,
        };
        data.sorting = {
          col: 'col_1',
          order: 0, // 0 - desend // 1 - asend
        };
        // tag filter
        data.tagList = null;
        data.isAll = null;
        // chk filter
        data.isActive = 'none';
        data.isWeightable = 'none';
        data.isFixedPrice = 'none';
        data.isForSale = 'none';
        data.isDiscount = 'none';
        // alert filter
        data.saleAlert = '';
    
        //alert("MODAL_DATA: " + JSON.stringify(data));
    
        this.props.searchCallback(data);
      }
*/

    render() {
        let titles = {
            main: "רשימת פריטים",
            add: "הוספת פריט",
            edit: "עריכת פריט",
            search: "רשימת פריטים - סינון"
        };

        let modalStack = this.props.ui.modalStack;

        return (
            <div>
                <PageToolbar
                    actionButtons={this.actionButtons}
                    title={titles.main}
                    //for search
                    searchCallback={this.advanceSearchCallback} // pull search field c->p
                    apiCallData={this.state.status} // push data p->c
                    showsearch={this.advanceSearchCallback}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ExtendedTable
                    user={this.props.user}
                    tableFormat={this.format}
                    apiCalls={this.api}
                    apiCallData={this.state.status} // push data p->c
                    showsearch={false}
                    showCreate={false}
                    isStaticData={false}

                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <ModalCreate
                    id={this.modalID.create}
                    user={this.props.user}
                    tableFormat={this.createFormat}
                    apiCalls={this.api.filter((item) => item.apiType === 'create')}
                    //toggle={this.props.ui.toggleDialog}
                    uiActions={this.props.uiActions}
                    ui={this.props.ui}
                    toggle={modalStack.includes(this.modalID.create)}
                    title={titles.add}
                //isStaticData={true}
                />
                <ModalAdvanceSearch
                    id={this.modalID.search}
                    user={this.props.user}
                    tableFormat={this.searchFormat}
                    apiCalls={this.api}
                    //apiCallData= {this.state.status}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    //toggle={this.props.ui.toggleDialog}
                    toggle={modalStack.includes(this.modalID.search)}
                    title={titles.search}
                    dataCallback={this.advanceSearchCallback} // pull data c->p
                />

                <ModalEditItem
                    id={this.modalID.edit}
                    user={this.props.user}
                    tableFormat={this.createFormat}
                    apiCalls={this.api.filter((item) => item.apiType === 'create')}
                    //toggle={this.props.ui.toggleDialog}
                    uiActions={this.props.uiActions}
                    ui={this.props.ui}
                    data={this.props.data}
                    dataAction={this.props.dataAction} 
                    toggle={modalStack.includes(this.modalID.edit)}
                    title={titles.edit}
                //isStaticData={true}
                />
            </div>
        );
    }

    advanceSearchCallback = (dataFromChild) => {
        let data = {};
        data.searchTerm = dataFromChild;
        data.pagination = {
          current: 1,
          defaultPageSize: 5,
        };
        data.sorting = {
          col: 'col_1',
          order: 0, // 0 - desend // 1 - asend
        };
        // tag filter
        data.tagList = null;
        data.isAll = null;
        // chk filter
        data.isActive = 'none';
        data.isWeightable = 'none';
        data.isFixedPrice = 'none';
        data.isForSale = 'none';
        data.isDiscount = 'none';
        // alert filter
        data.saleAlert = '';
        console.log("advance search callback");
        let s = this.state;
        s.status = data;
        this.setState(s);
    }

    //// create ////
    api_createItem = (data: Array<string>, successCallback, errorCallback) => {
        //alert(JSON.stringify(data));
        let fields = ['ItemId', 'ItemCode', 'ItemBarCode', 'ItemShortName',
            'ItemIsActive', 'ItemIsByWeight', 'ItemIsSold', 'ItemIsPriceFixed', 'ItemIsDiscountProhibited'];

        if (data.length != fields.length) {
            alert('data sent is missing values requered by api');
            return;
        }
        var dataSend = fields.join('\f') + '\r' + data.join('\f');
        console.log("dataSend");
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "create_new_tag", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", successCallback, errorCallback);
    }
    createItemSuccessCallback = (ob) => {
        alert("creates success:" + ob.data)
        //this.api_getTags({},(a)=>{return a;}, (a)=>{return a;});
    }
    createItemErrorCallback = (error) => {
        alert(error ? error : "Unspecified error create");
    }

    //// edit ////
    api_editItem = (_newTagName, _TagID, successCallback, errorCallback) => {
        var dataSend = "mNewTagName\fmTagID\r" + _newTagName + "\f" + _TagID;
        console.log("dataSend");
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "edit_tag", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", successCallback, errorCallback);
    }
    editItemSuccessCallback = (ob) => {
        console.log("edit success:" + ob.data);
        this.api_getTags({
            filter: this.state.searchTerm,
            current: this.state.pagination.current,
            pageSize: this.state.pagination.defaultPageSize,
            sortField: this.state.sorting.col, //sortField,
            //sortOrder: (changes.sortOrder === 'descend' ? 0 : 1),
            sortOrder: (this.state.sorting.order === 'descend') ? 0 : 1,
        },
            (a) => { return a; },
            (a) => { return a; });
    }
    editItemErrorCallback = (error) => {
        console.log(error ? error : "Unspecified error edit");
    }

    //// get ////
    api_getItems = (input, successCallback, errorCallback) => {
        //const dataSource = [...this.state.dataSource];
        //this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
        console.log("API_GET_ITEMS" + JSON.stringify(input));
        var dataSend = "_filter\f_page_num\f_rows_num\f_sort_by\f_isAsc\f_tagList\f_isAll\f_isActive\f_isWeightable\f_isFixedPrice\f_isForSale\f_isDiscount\f_saleAlert\r"
            + [
                // paging
                input.filters.searchTerm,
                input.current,
                //input.filters.current,
                input.pageSize,
                //input.filters.pageSize,
                input.sortField,
                //input.filters.sortField,
                input.sortOrder,
                //input.filters.sortOrder,

                // advance search
                input.filters.tagList,
                input.filters.isAll,
                //
                input.filters.isActive,
                input.filters.isWeightable,
                input.filters.isFixedPrice,
                input.filters.isForSale,
                input.filters.isDiscount,
                //
                input.filters.saleAlert,
            ].join("\f");

        console.log('datasend: ' + dataSend);
        //console.log(this.props.user.token + "  "  + this.props.user.companyCode);

        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_items_list", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", successCallback, errorCallback); // callback problem here
    }
    getItemsSuccessCallback = (ob) => {
        console.log("get success:" + ob.data);
        let data = ob.data;
        let rowsData = data.split("\r");
        let pageing = { ...this.state.pagination };
        //ageing.current = this._page_num;
        if (rowsData.length < 1) {
            pageing.total = 1;
        } else {
            pageing.total = rowsData[rowsData.length - 1];
        }
        let dataSource = [];
        for (var i = 0; i < rowsData.length - 1; i++) {
            let cols = rowsData[i].split("\f");
            let node = { key: i, };
            for (var j = 0; j < cols.length; j++) {
                node['col_' + (j + 1)] = cols[j];
            }
            dataSource.push(node);

            dataSource.push({
                key: i,
                col_1: cols[0],
                col_2: cols[1],
            })
        }


        this.setState({
            pagination: pageing,
            // current
            // pagesize
            // showQuickJumper
            // showTotal
            //showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} pages`,
            //current: _page_num,
            //defaultCurrent: 1,
            //defaultPageSize: 5,
            //total: 0,
            dataSource: dataSource,
            count: dataSource.length,
        });
    }
    getItemsErrorCallback = (error) => {
        console.log(error ? error : "Unspecified error get");
    }

    //// delete ////
    api_deleteItem = (id, successCallback, errorCallback) => {
        //const dataSource = [...this.state.dataSource];
        //var dataSend = "mTagID\r" + dataSource.find(item => item.key === key).col_1;
        var dataSend = "ItemId\r" + id;  // ItemCode
        var user = new User(this.props.user.token, this.props.user.comapnyCode);
        var job = new Job(user, "delete_tag", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", successCallback, errorCallback);
    }
    deleteSuccessCallback = (ob) => {
        console.log("delete success:" + ob.data);
        //this.handleTableChange(this.state.pagination, {}, this.state.sorter);
        //this.api_getTags({});
        // TODO. call api to get page
    }
    deleteErrorCallback = (error) => {
        console.log(error ? error : "Unspecified error delete");
    }
}

export default ItemListTable;



import React from "react";
import {
    Card, DatePicker, TimePicker, Form, Switch, InputNumber,
    Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message
} from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import moment from "moment";
import Jax from "../Jax/jax.es6.module";
import LangContext from "../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

// const codeInvalid = () => {
//     message.error('קוד הפריט כבר קיים במסד הנתונים');
// };
// const codeValid = () => {
//     message.success('קוד פריט פנוי');
// };
// const codeExists = () => {
//     message.warning('קוד פריט כבר קיים במסד הנתונים אך בסטטוס לא פעיל');
// };

//type State = {}
class ModalCreateCreditCard extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = 'CREATE_CREDIT_CARD';

        this.state.request = {
            //mCustomerID: this.props.ui.data, // for clear active card JM action

            customerId: this.props.ui.data,
            creditCard: '',
            expiry: '',
            cvv: '',
            nationalId: '',
            isActive: 1,
            remarks: '',
            source: 3,
            posId: 999
        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

        this.api = {
            create: "cardcom_tokenize",
            //create: "cardcom_tokenize_deactivate_first",

        }

        this.actionButtons = [/*{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/];

        this.dataSource = [];

        /*
        this.props.dataActions.genericDataGetEditWithParams(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            { _id: 0 },
            this.api.get
        );*/
    }

    static contextType = LangContext;
    getText = (id) => this.context.get(id) || '[' + id + ']';

    // componentDidMount() {
    //     document.title = "Cash On Tab - כרטיס אשראי";
    // }

    refreshData = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
    }

    clearAndClose = () => {
        this.props.uiActions.hideModal(TableIDs.modalCreateCreditCard);
        this.setState({
            ...this.state,
            request: {
                //mCustomerID: this.props.ui.data, // for clear active card JM action

                customerId: this.props.ui.data,
                creditCard: '',
                expiry: '',
                cvv: '',
                nationalId: '',
                isActive: 1,
                remarks: '',
                source: 3,
                posId: 999
            }
        },
            () => {
                this.props.dataActions.genericDataGetRequested('DIRECT_DEBIT_CREATE_EDIT', this.props.user.companyCode, this.props.user.token, 'get_direct_debit_list_table') 
            }
        )
        /*
        this.setState({
            request: {
                customerId: this.props.ui.data,
                creditCard: '',
                expiry: '',
                cvv: '',
                nationalId: '',
                isActive: 1,
                source: null,
                remarks: ''
            }
        },
            () => this.props.uiActions.hideModal(TableIDs.modalCreateCreditCard)
        )
        */

    };

    handleOkLeave = () => {
        this.clearAndClose();

    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.clearAndClose();
        //this.props.uiActions.hideModal(TableIDs.modalCreateCreditCard);
    }
    handleClear = () => {
        //this.clear();
    }


    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
        /*
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.networkPostJson,
                    args: [this.props.user.companyCode, this.props.user.token,
                    { script: this.api.create, params: this.state.request },
                        this.id, this.id,
                    { loading: 'loading', failed: 'failed', success: 'success' }, () => { }, null]
                    //args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
                });
                */
        if (this.isValidcreditCard() === 'success'
            && this.isValidexpiry() === 'success'
            && this.isValidnationalId() === 'success'
            && this.isValidcvv() === 'success') {
            //console.log("isValid - working hours create");
            this.create_card();
        }
        else {
            message.warning(this.getText(13011));
        }
    }

    isValidcreditCard = () => {
        const creditCard = this.state.request.creditCard;
        return (creditCard === ''
            || creditCard === null
            //|| creditCard.length !== 4
        )
            ? 'error' : 'success';
    }

    isValidexpiry = () => {
        const expiry = this.state.request['expiry'];
        return (this.state.request['expiry'] === ''
            || this.state.request['expiry'] === null
            || expiry.length !== 4)
            ? 'error' : 'success';
    }

    isValidcvv = () => {
        const cvv = this.state.request['cvv'];
        return (cvv === ''
            || cvv === null
            || cvv.length !== 3)
            ? 'error' : 'success';
    }

    isValidnationalId = () => {
        return (this.state.request['nationalId'] === '' || this.state.request['nationalId'] === null)
            ? 'error' : 'success';
    }

    render() {
        const sqlFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm:ss";
        const dateFormat = "DD/MM/YYYY";
        const { loading } = this.state;

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(13010)}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'60%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                data={this.props.data} dataAction={this.props.dataAction}>

                <Form id="create_credit_card" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: '1' }}
                    onSubmit={this.handleSaveChanges}>

                    <FormItem
                        key={'creditCard'} label={this.getText(13012)}
                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}
                        validateStatus={this.isValidcreditCard()}
                        // hasFeedback={false}
                        //help={"בלה בלה"}
                        extra={<label style={(this.isValidcreditCard() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>{this.getText(13013)}</label>}
                        required={true}
                    >
                        <Input
                            style={{ width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }}
                            onChange={(e) => this.setState({ request: { ...this.state.request, creditCard: e.target.value.replace(/\D/g, '') } })}
                            value={this.state.request.creditCard}
                        />
                    </FormItem>
                    <FormItem
                        key={'expiry'} label={this.getText(13014)}
                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}
                        validateStatus={this.isValidexpiry()}
                        // hasFeedback={false}
                        //help={"בלה בלה"}
                        extra={<label style={(this.isValidexpiry() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>{this.getText(13015)}</label>}
                        required={true}
                    >
                        <Input
                            style={{ width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }}
                            onChange={(e) => this.setState({ request: { ...this.state.request, expiry: e.target.value.replace(/\D/g, '') } })}
                            value={this.state.request.expiry}
                        />
                    </FormItem>
                    <FormItem
                        key={'cvv'} label={'cvv'}
                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}
                        validateStatus={this.isValidcvv()}
                        // hasFeedback={false}
                        //help={"בלה בלה"}
                        extra={<label style={(this.isValidcvv() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>{this.getText(13016)}</label>}
                        required={true}
                    >
                        <Input
                            style={{ width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }}
                            onChange={(e) => this.setState({ request: { ...this.state.request, cvv: e.target.value.replace(/\D/g, '') } })}
                            value={this.state.request.cvv}
                        />
                    </FormItem>
                    <FormItem
                        key={'nationalId'} label={this.getText(13017)}
                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}
                        validateStatus={this.isValidnationalId()}
                        // hasFeedback={false}
                        //help={"בלה בלה"}
                        extra={<label style={(this.isValidnationalId() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>{this.getText(13018)}</label>}
                        required={true}
                    >
                        <Input
                            style={{ width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }}
                            onChange={(e) => this.setState({ request: { ...this.state.request, nationalId: e.target.value.replace(/\D/g, '') } })}
                            value={this.state.request.nationalId}
                        />
                    </FormItem>
                    <FormItem
                        key={'isActive'} label={this.getText(13019)}
                        style={{ marginBottom: 12, display: 'none' }} help={''} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}
                    //validateStatus={}
                    >
                        <Checkbox
                            onChange={(e) =>
                                this.setState({
                                    request: {
                                        ...this.state.request,
                                        isActive: (e.target.value) ? 1 : 0
                                    }
                                })
                            }
                            checked={(this.state.request.isActive == 1) ? true : false}
                        //defaultChecked={(this.IsDefault == null) ? true : false}
                        //disabled={!(this.props.data[this.id_directdebit].editing > -1)}
                        />
                    </FormItem>
                    <FormItem
                        key={'source'} label={this.getText(13020)}
                        style={{ marginBottom: 12, display: 'none' }} help={''} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}
                    //validateStatus={}
                    >
                        <Input
                            style={{ width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }}
                            onChange={(e) => this.setState({ request: { ...this.state.request, source: e.target.value } })}
                            value={this.state.request.source}
                        />
                    </FormItem>

                    <FormItem
                        key={'remarks'} label={this.getText(13021)}
                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}
                    //validateStatus={}
                    >
                        <TextArea
                            //value={this.props.data[this.id].editedRecord['mremarks']}
                            onChange={(value) => {
                                //this.props.dataActions.genericDataSetEdit(this.id, { ['mremarks']: value.target.value }); 
                                this.setState({ request: { ...this.state.request, remarks: value.target.value.replace(/\'/g, "\\\'") } })
                            }}
                            autosize={{ minRows: 2, maxRows: 5 }}
                        />
                    </FormItem>
                </Form>
                <div className="action_footer">
                    <Button
                        type="primary" htmlType="submit" form="create_credit_card"
                        loading={this.props.ui.workInProgress}
                        className={"form-button-save"} icon={"check"}>{this.getText(13022)}</Button>

                </div>
            </Modal >
        )
    }

    create_card = () => {
        let dataSend = Object.keys(this.state.request).join('\f') + '\r'
            + Object.values({ ...this.state.request, customerId: this.props.ui.data }).join('\f')

        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.api.create, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
    };

    successCallback = (ob: Object) => {
        console.log("success:", ob.data);
        let response = (ob.data) ? ob.data.split('\f') : [2, "error - no response"];
        if (parseInt(response[0]) === 0) {
            message.success(this.getText(13023), 2);
            setTimeout(() => {
                this.clearAndClose();
            }, 1400);
        } else {
            message.error("כרטיס נמצא במערכת עבור משתמש אחר", 2);
        }
    }
    errorCallback = (error: any) => {
        console.log("error: ", error);
        message.error(this.getText(13024), 2);
    }
    /*
                componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                { _id: this.props.ui.data },
                this.api.get
            );
        }
    }*/
}

export default ModalCreateCreditCard;


/* @flow */
/*jshint esversion: 6 */
import { Button, Card, Col, Form, Icon, InputNumber, message, Popconfirm, Radio, Row } from "antd";
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import { takeWhile } from "lodash";
import TableLineIcons from "../../components/TableLineIcons";
import ResponsiveTable from "../../components/ResponsiveTable";
import SelectorItemClassic from "../../components/SelectorItemClassic";


class CreateDoc15ForAutoIphone extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            modalOpenEditDoc: false,

            _number: null,
            _pos: null,
            _supplier: null,
            _storage: null,

            _addItemCode: null,
            _addItemName: null,
            _addItemQty: null,
            _addItemQtyInMonth: null,

            _itemsList: [],

            _suppOnly: "1",
        }

        this.id = "temporaryDocuments";

        this.selectors = [
            { id: TableIDs.genericSelectorSuppliers, api: "get_suppliers" },
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
        ];

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }
        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.api = {
            get: "get_documents_for_user",
            get_draft: "create_temp_doc_type",
            save_draft: "save_temp_doc_short",
            save_draft_lines: "save_temp_doc_lines_short",
            save_temp_doc: "save_temp_document",
            delete_draft: "delete_temp_document",
            get_qty_month: "get_item_qty_in_month_order_supp",
        }

        this.itemCodeChange = null;

        this.isCreateNewDraft = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    componentDidMount() {
        this.apiTempList()
    }

    apiTempList = () => {
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, { docTypeId: 15 }, this.api.get)
    }

    refreshData = (docNum) => {
        if (!this.isCreateNewDraft) {
            this.isCreateNewDraft = true;
            this.sendAPI(this.api.get_draft, "_docNum\f_docType\r" + (docNum ? docNum : "") + "\f15", (ob) => {
                let _number = null;
                let _pos = null;
                let _supplier = null;
                let _storage = null;

                let _itemsList = ob.data ?
                    ob.data.split("\r").map((x, i) => {
                        let y = x.split("\f");
                        if (!i) {
                            _number = y[0];
                            _pos = y[1];
                            _storage = y[2];
                            _supplier = y[3];
                        }
                        return {
                            key: i + 1,
                            index: i,
                            lineNumber: i + 1,
                            itemCode: y[4],
                            itemDesc: y[5],
                            itemQty: y[6],
                            itemQtyInMonth: y[7],
                        }
                    }).filter(f => f.itemCode)
                    : []
                this.setState({
                    _number, _pos, _supplier, _storage, _itemsList,
                    modalOpenEditDoc: true,
                    _addItemCode: null,
                    _addItemName: null,
                    _addItemQty: null,
                    _addItemQtyInMonth: null,
                }, () => {
                    this.isCreateNewDraft = false;
                })
            })
        }
    }

    addItem = () => {
        const { _itemsList, _addItemCode, _addItemName, _addItemQty, _addItemQtyInMonth } = this.state;
        this.setState({
            _itemsList: [
                ..._itemsList,
                {
                    key: _itemsList.length + 1,
                    index: _itemsList.length,
                    lineNumber: _itemsList.length + 1,
                    itemCode: _addItemCode,
                    itemDesc: _addItemName,
                    itemQty: _addItemQty,
                    itemQtyInMonth: _addItemQtyInMonth,
                }
            ],
            _addItemCode: null,
            _addItemName: null,
            _addItemQty: null,
            _addItemQtyInMonth: null,
        }, () => {
            this.saveDraft(this.state)
            document.getElementById("search_item_input_id").focus()
        })
    }

    getSelector = (feild, index) => {
        return (<GenericSelector
            {...this.props}
            {...this.selectors[index]}
            value={this.state[feild]}
            onChange={e => {
                this.setState({ [feild]: e }, () => {
                    this.saveDraft(this.state)
                    setTimeout(() => {
                        this.refreshData(this.state._number)
                        document.getElementById("search_item_input_id").focus()
                    }, 1000);
                })
            }}
        />)
    }

    getModalContent = () => {
        const mobile = window.innerWidth <= 600;
        const suppDataset = this.props.data[TableIDs.genericSelectorSuppliers] ? this.props.data[TableIDs.genericSelectorSuppliers].dataset : []
        const draftList = this.props.data[this.id].data;
        const render = t => t
        const renderNum = t => t ? Math.round(t * 100) / 100 : ""
        if (this.state.modalOpenEditDoc) {
            const onChangeItem = () => {
                this.sendAPI(this.api.get_qty_month, "_item\r" + this.state._addItemCode, (ob) => {
                    this.setState({ _addItemQty: 1, _addItemQtyInMonth: renderNum(ob.data) }, () => {
                        let item_qty_input = document.getElementById("item_qty_input");
                        if (item_qty_input) {
                            item_qty_input.focus();
                            setTimeout(() => { item_qty_input.select() }, 1000)
                        }
                    })
                })
            }
            return (<div>
                <Row>
                    <Col span={6}>{this.getText(18747)}</Col><Col span={18}>{this.getSelector("_supplier", 0)}</Col>
                    <Col span={6}>{this.getText(18748)}</Col><Col span={18}>{this.getSelector("_storage", 1)}</Col>

                    <Col span={6} style={{ marginTop: 15 }}>{this.getText(18762)}</Col>
                    <Col span={18} style={{ marginTop: 15 }}>
                        <SelectorItemClassic
                            {...this.props}
                            id={"item_code_input"}
                            valueId={this.state._addItemCode} valueName={this.state._addItemName}
                            onChange={(code, name) => {
                                this.setState({ _addItemCode: code, _addItemName: name }, () => {
                                    clearTimeout(this.itemCodeChange);
                                    this.itemCodeChange = setTimeout(onChangeItem, 1000);
                                })
                            }}
                            onBlur={onChangeItem}
                            disabled={!this.state._supplier || !this.state._storage}
                            isPerSupplier={this.state._suppOnly == "1" ? suppDataset.find(f => f.code == this.state._supplier) : null}
                        />
                    </Col>
                </Row>
                <Radio.Group value={this.state._suppOnly} onChange={e => { this.setState({ _suppOnly: e.target.value }) }}>
                    <Radio value="0">{this.getText(18787)}</Radio>
                    <Radio value="1">{this.getText(18788)}</Radio>
                </Radio.Group>

                {/* <h4>
                    {this.getText(18760)}: {this.state._addItemQtyInMonth}
                </h4> */}
                {/* <Form.Item label={this.getText(18763)}> */}
                {this.getText(18763)}
                <InputNumber id="item_qty_input" style={{ margin: 15 }} value={this.state._addItemQty} onChange={e => { this.setState({ _addItemQty: e }) }} />
                {/* </Form.Item> */}
                <Button
                    style={{ margin: 15 }}
                    onClick={this.addItem}
                    disabled={!this.state._addItemCode || !this.state._addItemQty || !this.state._supplier || !this.state._storage}
                >{this.getText(18764)}</Button>
                <ResponsiveTable
                    tableOnly
                    dataSource={this.state._itemsList}
                    columns={[
                        { title: this.getText(18757), key: "itemCode", dataIndex: "itemCode", width: "20%", render },
                        { title: this.getText(18758), key: "itemDesc", dataIndex: "itemDesc", width: "30%", render },
                        { title: this.getText(18759), key: "itemQty", dataIndex: "itemQty", width: "15%", render: renderNum },
                        { title: this.getText(18760), key: "itemQtyInMonth", dataIndex: "itemQtyInMonth", width: "25%", render: renderNum },
                        {
                            title: "", key: "lineNumber", dataIndex: "lineNumber", width: "10%", render: t => {
                                return (<TableLineIcons
                                    onDelete={() => { this.setState({ _itemsList: this.state._itemsList.filter(f => f.lineNumber != t) }) }}
                                    deleteQuetion={{ title: this.getText(18761), okText: this.getText(18753), cancelText: this.getText(18754) }}
                                />)
                            }
                        },
                    ]}
                />
            </div>)
        } else {
            return (<div>
                {draftList.map(x => {
                    let y = x.split("\f");
                    return (<Card title={"הזמנה מספק " + y[0]} style={{ margin: 10 }}>
                        <p>{y[4]} - {y[5]}</p>
                        <p>{y[3]}</p>
                        <TableLineIcons
                            onEdit={() => { this.refreshData(y[0]) }}
                            onDelete={() => {
                                this.sendAPI(this.api.delete_draft, "number\r" + y[0], () => {
                                    message.success(this.getText(18755))
                                    this.apiTempList()
                                })
                            }}
                            deleteQuetion={{ title: this.getText(18752), okText: this.getText(18753), cancelText: this.getText(18754) }}
                        />
                    </Card>)
                })}
            </div>)
        }
    }

    getModalFooter = () => {
        if (this.state.modalOpenEditDoc) {
            return [
                <Button type="primary" onClick={this.saveTempDoc} disabled={!this.state._itemsList.length}>{this.getText(18749)}</Button>,
                <Popconfirm
                    onConfirm={() => { this.setState({ _itemsList: [] }, () => { this.saveDraft(this.state) }) }}
                    title={this.getText(18765)} okText={this.getText(18753)} cancelText={this.getText(18754)} >
                    <Button>{this.getText(18750)}</Button>
                </Popconfirm>,
                <Button onClick={this.onCancel}>{this.getText(18751)}</Button>,
            ]
        } else {
            return [
                <Button type="primary" onClick={() => { this.refreshData() }}>{this.getText(18756)}</Button>
            ]
        }
    }

    onCancel = () => {
        const draft = { ...this.state }
        this.setState({
            modalOpen: false,
            modalOpenEditDoc: false,
            _number: null,
            _pos: null,
            _supplier: null,
            _storage: null,
            _itemsList: [],
            _addItemCode: null,
            _addItemName: null,
            _addItemQty: null,
            _addItemQtyInMonth: null,
        }, () => {
            this.saveDraft(draft)
        })
    }

    saveDraft = (draft) => {
        const { _number, _pos, _storage, _supplier, _itemsList } = draft;
        let dataSend1 = "_supplier\f_storage\f_number\f_type\f_pos\r"
            + _supplier + "\f" + _storage + "\f" + _number + "\f15\f" + _pos;
        let dataSend2 = "lineNumber\fitemCode\fitemQty\f_number\f_type\f_pos\r"
            + _itemsList.map(x => x.lineNumber + "\f" + x.itemCode + "\f" + x.itemQty + "\f" + _number + "\f15\f" + _pos).join("\r")

        this.sendAPI(this.api.save_draft, dataSend1, () => {
            this.sendAPI(this.api.save_draft_lines, dataSend2, this.apiTempList)
        })
    }

    saveTempDoc = () => {
        const { _number, _pos } = this.state;
        this.sendAPI(this.api.save_temp_doc, "DocNum\fDocTypeID\fDocPosID\fisDeleteDraft\r" + _number + "\f15\f" + _pos + "\f1", () => {
            message.success(this.getText(18768));
            this.onCancel()
            this.apiTempList()
        })
    }


    render() {
        const draftList = this.props.data[this.id].data;
        return (<div style={{ textAlign: "center", marginBottom: 5, display: 'inline-block', verticalAlign: 'text-top' }}>
            <Button onClick={() => { this.setState({ modalOpen: true }, () => { if (!draftList.length) this.refreshData() }) }}>
                <Icon type={"project"} style={{ marginLeft: 5 }} />
                {this.getText(18746)}
            </Button>
            <GenericModal
                visible={this.state.modalOpen}
                onCancel={this.onCancel}
                title={this.getText(18746)}
                footer={this.getModalFooter()}>
                {this.getModalContent()}
            </GenericModal>
        </div>)
    }
}

export default CreateDoc15ForAutoIphone;
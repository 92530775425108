/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import GenericSelector from "../../components/GenericSelector";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import TableLineIcons from "../../components/TableLineIcons";
import { Popconfirm, Button, Row, Col } from "antd";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class TransfersPending extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalItems: null,
        };

        this.id = "TRANSFERS_PENDING";
        this.items_id = "TRANSFERS_PENDING_LINES";

        this.api = {
            get: "transfers_pending_list",
            // get_def: "get_default_storage_from_user",
            get_items: "get_documentLines_purchase_list_table",
            update: "update_doc_transfer_storage",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    setFilter = (request) => {
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    // componentDidMount() {
    // this.sendAPI(this.api.get_def, '', (ob) => {
    //     if (ob.data) this.setFilter({ _storage: ob.data })
    // })

    // }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(16079);
        let storageData = this.props.data[this.selectors[0].id];
        if (storageData !== prevProps.data[this.selectors[0].id]) {
            let storageList = storageData.dataset.map(x => x.code);
            this.setFilter({ _storageList: storageList.length ? storageList.join(',') : '' })
        }
    }

    getEditIcons = (text, record) => {
        let gt = this.getText;

        let genericIcons = [
            {
                type: 'eye',
                tooltip: gt(16088),
                onClick: () => { this.setState({ modalItems: record }, () => { this.getDocItems(record) }) },
            }, {
                type: 'check-circle',
                tooltip: gt(16105),
                onClick: () => { this.transferChange(record, 1) },
            },
        ];

        if (record.dStatus == '1') {
            genericIcons[1].tooltip = gt(16089);
            genericIcons[2] = {
                type: 'close-circle',
                tooltip: gt(16090),
                onClick: () => { this.transferChange(record, 0) },
                popconfirm: { title: gt(16091), okText: gt(16092), cancelText: gt(16093) },
            }
        }

        return <TableLineIcons genericIcons={genericIcons} />
    }

    getDocItems = (record) => {
        let numOfRowsByHeight = parseInt((window.innerHeight - 340 > 30) ? (window.innerHeight - 340) / 30 : 1);

        let request = {
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _id: 0,
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            //filters   
            mNumber: record.dNumber,
            mDocType: 51,
            mPOSID: record.dPOSID,
        }

        this.props.dataActions.genericDataSetFilter(this.items_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_items)

    }

    getStatusText = (status) => {
        switch (status) {
            case '1': return this.getText(16103);
            case '2': return this.getText(16104);
        }
    }

    transferChange = (record, _isOk) => {
        let _docNum = record.dNumber;
        let _docPos = record.dPOSID;
        let _employee = this.props.user.displayName;

        let dataSend = '_docNum\f_docPos\f_isOk\f_employee\r' + _docNum + '\f' + _docPos + '\f' + _isOk + '\f' + _employee;

        this.sendAPI(this.api.update, [{ name: 'DOC_PARAMS', data: dataSend }, { name: 'DOC_BLOB', data: '' }], () => {
            this.setState({ modalItems: null }, () => { this.setFilter({}) })
        })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType['OUTPUT_TYPE_DATA'], ProcessType.PROCESS_TYPE_SYNC);
        if (typeof data === 'object') {
            data.map(x => { job.setSection(x.name, x.data) })
        } else {
            job.setInput(data);
        }
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    getItemDesc = (text, record) => {
        if (record.mItemIsMatrix == 1) {
            return (<Row>
                <Col span={12}>{text}</Col>
                <Col span={2}>{record.mSizeCode}</Col>
                <Col span={4}>{record.mSizeDesc}</Col>
                <Col span={2}>{record.mColorUserCode}</Col>
                <Col span={4}>{record.mColorDesc}</Col>
            </Row>)
        }
        return text;
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        let _storageList = this.props.data[this.id].filters._storageList ? this.props.data[this.id].filters._storageList : '';
        let modalItems = this.state.modalItems ? this.state.modalItems : {}

        return (<div style={divStyle}>
            <div style={{ maxWidth: 500, paddingBottom: 20 }}>
                <GenericSelector
                    multi
                    {...this.props}
                    {...this.selectors[0]}
                    value={_storageList ? _storageList.split(',') : []}
                    onChange={(e) => { this.setFilter({ _storageList: e ? e.join(',') : '' }) }}
                    placeholder={gt(16081)}
                // addFirstRow={{ code: '-', name: gt(16081) }}
                />
            </div>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16080)}
                columns={[
                    { title: gt(16082), key: 'dNumber', dataIndex: 'dNumber', width: '10%', sorter: true, render: t => t },
                    { title: gt(16084), key: 'dPos', dataIndex: 'dPos', width: '15%', sorter: true, render: t => t },
                    { title: gt(16085), key: 'dStorageFrom', dataIndex: 'dStorageFrom', width: '15%', sorter: true, render: t => t },
                    { title: gt(16518), key: 'dStorageTo', dataIndex: 'dStorageTo', width: '15%', sorter: true, render: t => t },
                    { title: gt(16086), key: 'dDatetime', dataIndex: 'dDatetime', width: '13%', sorter: true, render: t => t },
                    { title: gt(16102), key: 'dStatus', dataIndex: 'dStatus', width: '12%', sorter: true, render: this.getStatusText },
                    { title: gt(16197), key: 'dLines', dataIndex: 'dLines', width: '6%', sorter: true, render: t => t },
                    { title: gt(16198), key: 'dQty', dataIndex: 'dQty', width: '7%', sorter: true, render: t => t },
                    { title: gt(16087), width: '7%', render: this.getEditIcons },
                ]}
                percentageToMobile={[40, 0, 0, 60]}
                filters={{ _storageList }}
            />
            <GenericModal
                visible={this.state.modalItems !== null}
                onCancel={() => { this.setState({ modalItems: null }) }}
                title={gt(16096) + ' ' + (modalItems.dNumber ? modalItems.dNumber : "")}
                footer={[
                    <Button type='primary' style={{ marginLeft: 5 }} onClick={() => { this.transferChange(modalItems, 1) }}>{gt(16094)}</Button>,
                    <Popconfirm title={gt(16091)} okText={gt(16092)} cancelText={gt(16093)} onConfirm={() => { this.transferChange(modalItems, 0) }}>
                        <Button>{gt(16095)}</Button>
                    </Popconfirm>
                ]}>
                <GenericTable
                    {...this.props}
                    id={this.items_id}
                    getApi={this.api.get_items}
                    tableOnly
                    keyIndex={3}
                    columns={[
                        { title: gt(16097), key: 'mNumber', dataIndex: 'mNumber', width: '10%', render: t => t },
                        { title: gt(16098), key: 'mItemCode', dataIndex: 'mItemCode', width: '20%', render: t => t },
                        { title: gt(16099), key: 'mItemBarcode', dataIndex: 'mItemBarcode', width: '20%', render: t => t },
                        { title: gt(16100), key: 'mDescription', dataIndex: 'mDescription', width: '35%', render: this.getItemDesc },
                        { title: gt(16101), key: 'mQuantity', dataIndex: 'mQuantity', width: '15%', render: t => t },
                    ]}
                    percentageToMobile={[40, 0, 0, 60]}
                    filters={{
                        _id: 0,
                        mNumber: modalItems.dNumber ? modalItems.dNumber : 0,
                        mDocType: 51,
                        mPOSID: modalItems.dPOSID ? modalItems.dPOSID : 0,
                    }}
                />
            </GenericModal>
        </div>)
    }

}

export default TransfersPending;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Checkbox, Button, Form, Select, DatePicker, Row, Col, Radio } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import GenericSelector from "../../components/GenericSelector";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import { disabledDatePicker } from "../../utils/utils";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class ReportingIndex extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.selectors = [
            { id: TableIDs.genericSelectorPrices, api: "get_prices" },
            { id: TableIDs.genericMultiSelectorItemTags, api: "get_tags_with_types" },
            { id: TableIDs.genericSelectorSuppliers, api: "get_suppliers" },
        ];

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

        this.state = {

            PriceList: {
                isByFatherItem: '0',
                PriceListId: '',
                viewCost: '1',
                tagList: '',
                supplierList: '',
                isChanged: '0',
                onlyActive: '0',
                extendedExcel: '0',
                dateStart: null,
                dateEnd: null,
                reportName: "reports/PriceList.pdf"
            }
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10188);


        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("PriceList", this.props.ui.favoriteParams)
        }

        let plSelector = this.props.data[TableIDs.genericSelectorPrices].dataset;

        if (prevProps.data[TableIDs.genericSelectorPrices].dataset != plSelector && plSelector[0]) {
            this.setReportParameters("PriceList", { PriceListId: plSelector[0].code })
        }
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, "get_prices"]
        });
    }

    createSelector = (label, field, sIndex, multi, optionsGroup) => {
        let value = multi ?
            this.state.PriceList[field] ? this.state.PriceList[field].split(',') : []
            : this.state.PriceList[field];

        let props = {
            ...this.props,
            ...this.selectors[sIndex],
            multi,
            optionsGroup,
            value,
            onChange: (e) => { this.setReportParameters("PriceList", { [field]: multi ? e.join(',') : e }) }
        }

        return (<FormItem label={this.getText(label)}>
            {field === 'supplierList' ? (<GenericSelectorSuppliers {...props} />) :
                field === 'tagList' ? (<GenericSelectorItemTags {...props} />) :
                    (<GenericSelector {...props} />)}
            {/* <GenericSelector
                id={this.selectors[sIndex].id} api={this.selectors[sIndex].api}
                multi={multi} optionsGroup={optionsGroup} value={value}
                onChange={(e) => { this.setReportParameters("PriceList", { [field]: multi ? e.join(',') : e }) }}
                user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
            /> */}
        </FormItem>)
    }

    createCheckbox = (label, field) => {
        return (<FormItem>
            <Checkbox
                onChange={(e) => { this.setReportParameters("PriceList", { [field]: (e.target.checked) ? 1 : 0 }) }}
                checked={(this.state.PriceList[field] == 1) ? true : false} >
                {this.getText(label)}
            </Checkbox>
        </FormItem>)
    }

    createDatePicker = (label, field) => {
        let minDate = field == 'dateEnd' ? this.state.PriceList.dateStart : null
        let maxDate = field == 'dateStart' ? this.state.PriceList.dateEnd : null
        return (<FormItem label={this.getText(label)}>
            <DatePicker
                format="DD-MM-YYYY" //"YYYY-MM-DD"
                value={this.state.PriceList[field] ? moment(this.state.PriceList[field], "YYYY-MM-DD") : null}
                onChange={(e) => { this.setReportParameters("PriceList", { [field]: e ? e.format("YYYY-MM-DD") : null }) }}
                disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
            />
        </FormItem>)
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        // this.props.data[TableIDs.genericSelectorPrices].dataset
        // let priceLists = Jax.get(this.props.data, TableIDs.genericSelectorPrices + ".dataset", [])
        //     .map((obj, index) => {
        //         return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        //     });

        let mobile = window.innerWidth > 600 ? false : true;

        let params = this.state.PriceList
        let xlsReportName = "reports/PriceListExcel.xls"
        let xlsOnly = this.state.PriceList.extendedExcel == 1
        if (xlsOnly) {
            params = { ...params, reportName: "reports/PriceListExtendedExcel.xls" }
            xlsReportName = "reports/PriceListExtendedExcel.xls";
        }


        return (
            <div style={divStyle}>
                <Card
                    title={(<PageToolbar title={this.getText(12265)} showsearch={false} actionButtons={[]} />)}
                    bordered={true}
                    style={{ width: window.innerWidth < 1000 ? '100%' : 1000, padding: 20 }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}
                // actions={[<Button type={"primary"} onClick={() => { this.generateReport.apply(this, ["PriceList"]) }}>{this.getText(12272)}</Button>]}
                >
                    <div style={{ paddingTop: mobile ? 50 : 0, marginBottom: 50 }}> {this.getText(12273)} </div>
                    <Row>
                        <Col span={mobile ? 24 : 12}>
                            {this.createSelector(12267, 'PriceListId', 0)}
                            {this.createSelector(14199, 'tagList', 1, true, true)}
                            {this.createSelector(14200, 'supplierList', 2, true)}
                        </Col>
                        <Col span={mobile ? 24 : 12} style={{ paddingRight: 20 }}>
                            <FormItem label={this.getText(12266)}>
                                <RadioGroup
                                    value={this.state.PriceList.isByFatherItem}
                                    onChange={(e) => { this.setReportParameters("PriceList", { isByFatherItem: e.target.value }) }}
                                >
                                    <Radio value={'0'}>{this.getText(12275)}</Radio>
                                    <Radio value={'1'}>{this.getText(12274)}</Radio>
                                </RadioGroup>
                            </FormItem>
                            {this.createCheckbox(14140, "viewCost")}
                            {this.createCheckbox(14201, "isChanged")}

                            {this.state.PriceList.isChanged == 1 ?
                                (<Row>
                                    <Col span={12}>{this.createDatePicker(14202, "dateStart")}</Col>
                                    <Col span={12}>{this.createDatePicker(14203, "dateEnd")}</Col>
                                </Row>)
                                : ''}

                            {this.createCheckbox(18302, "onlyActive")}
                            {this.createCheckbox(19130, "extendedExcel")}

                            <ReportTypeButton
                                {...this.props}
                                {...{ params, xlsReportName, xlsOnly }}
                                tagsField={'tagList'}
                                datesToFavorites={this.state.PriceList.isChanged == 1 ? [
                                    { field: 'dateStart', label: this.getText(14202) },
                                    { field: 'dateEnd', label: this.getText(14203) },
                                ] : null}
                                favoriteSave
                            />
                        </Col>
                    </Row>
                </Card>

            </div >
        );
    }
}
export default ReportingIndex;
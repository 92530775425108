/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Modal, Progress, Alert, Button } from "antd";
import "../App.css";
import LangContext from "../contextProvider/LangContext";
import PageToolbar from "./PageToolbar";

type Props = {
    title: String,
    toggle: Boolean,
    ui: Object,
    uiActions: any,
    dataActions: any,
    data: Object,

    ////
    handleCancel: Function,
    handleRefrash: Function,
    handleIgnore: Function,
    percent: Number,
    status: String,
    message: String,
    type: String,
}

class ModalProgress extends Component<Props, State> {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    footerButtons = () => {
        let res = [<Button key="back" onClick={this.props.handleCancel}>{this.getText(12917)}</Button>];

        if (this.props.type === "error" && this.props.handleRefrash) res.push(<Button key="refrash" onClick={this.props.handleRefrash}>{this.getText(12921)}</Button>);
        if (this.props.type === "warning" && this.props.handleIgnore) res.push(<Button key="warning" onClick={this.props.handleIgnore}>{this.getText(12978)}</Button>);
       
        return res;
    }

    render() {
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        return (<Modal
            destroyOnClose={true}
            width={500}
            title={titleBar}
            visible={false || this.props.toggle}
            onCancel={this.props.handleCancel}
            footer={this.footerButtons()}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <Progress percent={this.props.percent} status={this.props.status} />
            <Alert style={{ marginTop: 20 }} message={this.props.message} type={this.props.type} />
            {this.props.moreErrors ? this.props.moreErrors.map(x=> (<Alert style={{ marginTop: 20 }} message={x} type={"error"} />)):""}
        </Modal >)
    }
}

export default ModalProgress;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from 'react';
import Jax from "../Jax/jax.es6.module";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Form, Input, Icon, Button, Select, message, InputNumber } from 'antd';
import LangContext from "../contextProvider/LangContext";

type ButtonConfig = {
    ElementID: String,
    ButtonType: Number,
    SwiichToken: String,
    SwiichVendorToken: String,
};
type EventsConfig = {
    onButtonClick: Function,
    onCancel: Function,
    onPaymentApproved: Function,
    onPaymentError: Function,
};

type Props = {
    phoneNumber: String,
    elementId: String,
    width: Number,
    height: Number,
    eventsConfig: Object,
    customerName: String,
    amount: Number,
    orderId: Number,
    walletList: Object,
    value: Number,
    onChange: Function
}

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

class SwiichButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.api = {
            create_payment: "swiich_create_payment_bo",
            charge_offline: "swiich_charge_pos_j4",
            wallets: "swiich_get_wallet_list_bo"
        }
        this.state = {
            walletList: [],
            loading: false
        }

        this.isBySdkList = [1]; /* bit */
    }

    static contextType = LangContext;
    getText = (id) => { return this.context.get(id) || '[' + id + ']' }


    /* not used - sendApi pass by props */
    sendAPI = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }

    ButtonClickDefault = (e) => { console.log("BUTTON CLICKED ") }
    PaymentApprovedDefault = (e) => { console.log("APPROVED: " + e.SwiichEvntIsSuccessful + " " + e.SwiichEvntInfo); }
    PaymentCancelDefault = (e) => { console.log("CANCELED: " + e.SwiichEvntIsSuccessful + " " + e.SwiichEvntInfo); }
    PaymentErrorDefault = (e) => { console.log("ERROR: " + e.SwiichEvntIsSuccessful + " " + e.SwiichEvntInfo); }

    refreshList = () => {
        //this.sendAPI(
        let sendAPI = (this.props.sendAPI) ? this.props.sendAPI : this.sendAPI;
        sendAPI(
            this.api.wallets,
        //"\f\r\f",
        /*"amount\fcustomerName\forderId\r" + 
        this.props.amount + '\f' + 
        this.props.customerName + '\f' + 
        this.props.orderId*/"",
            (ob) => {
                let str = ob.data;
                let filterList = this.props.walletList;
                let list = JSON.parse(str);
                let walletList = list
                    .filter((obj) => { return filterList.includes(obj.walletID) })
                    .map((obj) => {
                        return {
                            //obj.vendorID,
                            //obj.vendorName,
                            code /*walletID*/: obj.walletID,
                            name /*walletName*/: obj.walletName,
                            //walletIcon: obj.walletIcon
                        }
                    })

                this.setState({ walletList: walletList })
                console.log(list);
            },
            (err) => { console.error(err) }
        );
    }

    refreshButton = () => {
        //this.sendAPI(
        let sendAPI = (this.props.sendAPI) ? this.props.sendAPI : this.sendAPI;
        sendAPI(
            this.api.create_payment,
            //"\f\r\f",
            "amount\fcustomerName\forderId\r" +
            this.props.amount + '\f' +
            this.props.customerName + '\f' +
            this.props.orderId,
            (ob) => {
                let responseStr = ob.data;
                //console.log("DEBOZ1: ", responseStr);
                let response = JSON.parse(responseStr);
                //console.log("DEBOZ2: ", response)

                let buttonConfig = {
                    ElementID: this.props.elementId, //'swiich-payment-button',
                    //ButtonType: 1,
                    ButtonType: this.props.value,
                    SwiichToken: response.swiichPaymentToken, //'2020080625823936',
                    SwiichVendorToken: response.vendorPaymentToken //'38fcdc0f-1801-4e4e-a92a-2ae26aa1c298'
                }
                let eventsConfig = {
                    onButtonClick: this.ButtonClickDefault,
                    onCancel: this.PaymentCancelDefault,
                    onPaymentApproved: this.PaymentApprovedDefault,
                    onPaymentError: this.PaymentErrorDefault
                    , ...this.props.eventsConfig
                }

                this.initSwiichButton(buttonConfig, eventsConfig)
            },
            (err) => { console.error(err) }
        );

    }

    charge_offline = () => {
        //this.sendAPI(
        let sendAPI = (this.props.sendAPI) ? this.props.sendAPI : this.sendAPI;
        sendAPI(
            this.api.charge_offline,
            //"\f\r\f",
            "amount\fcustomerName\forderId\ftotalAmount\fwalletId\fphoneNumber\r" +
            this.props.amount + '\f' +
            this.props.customerName + '\f' +
            this.props.orderId + '\f' +
            this.props.amount + '\f' +
            this.props.value + '\f' + // walletid
            this.state.phoneNumber + '\f',
            (ob) => { console.log(ob) },
            (err) => { console.error(err) }
        );
    }

    componentWillMount(prevProps, prevState) {
        this.refreshList();
    }

    initSwiichButton = (buttonConfig: ButtonConfig, eventsConfig: EventsConfig) => {
        /* 
        {
            ElementID: 'swiich-payment-button',
            ButtonType: 1,
            SwiichToken: '202901102620042',
            SwiichVendorToken: '873692b5-7619-4c51-93ed-765ee15b2548'
         }
         */
        // console.log("DEBOZ: ", buttonConfig);
        window.SwiichSDKobj.SetPaymentButton(buttonConfig);
        window.SwiichSDKobj.SetButtonEvents(eventsConfig);
        window.SwiichSDKobj.CreatePaymentButton();
        //window.SwiichSDKobj.
        // console.log("SDK: ", window.SwiichSDKobj)
    }

    clearButton = () => {
        document.getElementById(this.props.elementId).innerHTML = '';
    }

    isBySDK = (walletId) => {
        if (walletId) {
            return !!this.isBySdkList.find(i => i == walletId)
        } else {
            return false
        }
    }

    generateForm = () => {
        if (this.isBySDK(this.props.value)) { return (<div></div>); }

        return (
            <div id="swiichFormContainer">
                <Form id="swiichForm" className="data-entry-form" layout='horizontal'
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '2' : '1' }}
                    //onSubmit={this.charge_offline}
                >
                    <FormItem label={"שם לקוח"}/*{this.getText(11184)}*/>
                        <Input
                            value={this.props.customerName}
                            style={{ marginLeft: '4px' }}
                            disabled={true}
                        />                </FormItem>
                    <FormItem label={"מספר הזמנה"}/*{this.getText(11184)}*/>
                        <Input
                            value={this.props.orderId}
                            style={{ marginLeft: '4px' }}
                            disabled={true}
                        />                </FormItem>
                    <FormItem label={"סכום"}/*{this.getText(11184)}*/>
                        <Input
                            value={this.props.amount}
                            style={{ marginLeft: '4px' }}
                            disabled={true}
                        />                </FormItem>
                    <FormItem label={"מספר טלפון"}/*{this.getText(11184)}*/>
                        <Input
                            value={this.state.phoneNumber}
                            onChange={(value) => this.setState({ phoneNumber: value.target.value })}
                            style={{ marginLeft: '4px' }}
                        />
                    </FormItem>
                </Form>
                <div className="action_footer">
                    <Button form="swiichForm" key="swiichFormButton" type="primary" loading={this.state.loading} onClick={this.charge_offline}>{"שלם בארנק דיגיטלי"/*this.getText(11199)*/}</Button>
                </div>
            </div>
        )
    }

    render() {
        const { walletList } = this.state;
        let list = walletList.filter(() => {
            return true
        })

        let listOptions = this.state.walletList.map((obj) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        })

        return (
            <div>
                <Select key={"walletList"} style={{ width: '90%' }}
                    showsearch
                    filterOption={true}
                    optionFilterProp="valuefilter"
                    placeholder="בחר ארנק דיגיטלי"
                    onChange={(e) => {
                        this.props.onChange(e);
                        this.clearButton();

                        console.log("deboz7: ", e, this.isBySDK(e))

                        if (this.isBySDK(e)) {
                            // generate bit button
                            this.refreshButton();
                        }
                    }}
                    value={this.props.value}
                >
                    {listOptions}
                </Select>
                <div width={20} id={this.props.elementId}></div>
                <div>{this.generateForm()}</div>

            </div>
        )
    }

}


export default SwiichButton;


import React from "react";
import GenericSelector from "./GenericSelector";
import LangContext from "../contextProvider/LangContext";

class GenericSelectorPosStorages extends React.Component/*<Props, State>*/ {
    constructor(props) {
        super(props);
        this.id = 'POS_STORAGE_LIST';
        this.api = 'pos_storage_list'
        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericSelector }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    onChange = (list) => {
        const { dataset } = this.props.data[this.id];
        let typeList = [];
        dataset.forEach(x => {
            if (x.tags && x.tags.find(f => list.indexOf(f.id) > -1)) typeList.push(x.type.id)
        });
        this.props.onChange(list, typeList)
    }

    render() {
        let gt = this.getText;

        return (<GenericSelector
            {...this.props}
            optionsGroup
            id={this.id}
            api={this.props.notApi ? undefined : this.api}
            onChange={this.onChange}
            modalStyle={{
                title: gt(this.props.multi ? 18151 : 18150),
                col1Title: gt(18152),
                col2Title: gt(18153),
                cancelText: gt(18154),
                clearText: gt(18155),
            }}
        />)
    }
}

export default GenericSelectorPosStorages;

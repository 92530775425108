import React from "react";
import { Icon, Form, Input, Button, Select, /*Modal, InputNumber,*/ message, Tooltip, notification, Col, Row } from "antd";
import GenericModal from "./GenericModal";
//import { RichUtils } from "draft-js";
//import TableIDs from "../data/TableIDs";
import { /*Login, Job, User, ProcessType, OutputType,*/ JobResponse, StringView, Get_User_For_Renew, Renew, Resend, RequestForget, ValidateForget } from "../JmReact";
//import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
//const { getFieldDecorator } = Form;
const { Option } = Select;

type Props = {
    setToken: Function,
    login: Function,
    error: string,
    working: boolean,
    previousValues: Object,
    saveValues: Function
};

type State = {};

class LoginFormV2 extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            // from
            companyCode: "", password: "", userName: "",
            language: this.props.language ? this.props.language : 2,
            confirmationNumber: "",
            isRenewFormShown: false,
            isRenewConfDiagShown: false,
            isForgetDiagShown: false,

            // curr user
            currName: "", currDisplayName: "", currphone: "", currmail: "", 
            currpsw1: "", currpsw2: "", currDayLeft: "",
            count: 0,

            visibilityToggle: false
        }
    }

    MAX_COUNT = 60;
    //userName: string = "";
    //companyCode: string = "";

    componentDidMount() { this.indexedDbCheck(); }

    componentDidUpdate(prevProps) {
        if (this.props.isRenewModalShown && !prevProps.isRenewModalShown) {
            this.api_renew_get_user();
        }
    }

    // DB STUFF
    indexedDbConnect = (onsuccess) => {
        let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
        let open = indexedDB.open("user_login_cot", 1);
        open.onupgradeneeded = () => {
            let db = open.result;
            let store = db.createObjectStore("user_data", { keyPath: "field_name" });
            store.createIndex("field_name", "field_name");
            store.createIndex("field_value", "field_value");
        };
        open.onsuccess = () => { onsuccess(open) }
    }

    indexedDbCheck = () => {
        this.indexedDbConnect((open) => {
            let db = open.result;
            let tx = db.transaction("user_data", "readwrite");
            let store = tx.objectStore("user_data");
            store.index("field_name");
            let getComp = store.get("companyCode");
            getComp.onsuccess = () => {
                if (getComp.result && getComp.result.field_value) {
                    console.log('test db - companyCode: ', getComp.result.field_value);
                    // let cci = document.getElementById("companyCode");
                    // if (cci) cci.value = getComp.result.field_value;
                    this.props.saveValues({ companyCode: getComp.result.field_value });
                    this.setState({ companyCode: getComp.result.field_value })
                }
            };
        })
    }

    indexedDbCreate = (companyCode) => {
        this.indexedDbConnect((open) => {
            console.log("test db - create");

            let db = open.result;
            let tx = db.transaction("user_data", "readwrite");
            let store = tx.objectStore("user_data");
            store.index("field_name");
            store.put({ field_name: "companyCode", field_value: companyCode });
            tx.oncomplete = () => { db.close() };
        })
    }

    // FORM STUFF
    getText = (id) => {
        let data = [
            {},
            {
                t1: "Connecting to the system",
                t2: "You are trying to view a secure page.",
                t3: "To continue, please connect to the system:",
                t4: "Company code is mandatory",
                t5: "Company Code",
                t6: "Username is required",
                t7: "username",
                t8: "Password is required",
                t9: "Password",
                t10: "Log in",
                t11: "SMS confirmation code",
                t12: "Cancel",
                t13: "Log in",
                t14: "Hello <P1>, your password will expire in <P2> days.",
                t15: "Hello <P1>, your password expired.",
                t16: "Continue without changing the password",
                t17: "Change password",
                t18: "Cancel",
                t19: "Change password",
                t20: "User name",
                t21: "User display name",
                t22: "Mobile phone number",
                t23: "Email",
                t24: "New Password",
                t25: "Retype new password",
                t26: "Between 6 to 30 characters, at least 1 digit, at least 1 alphabetic char (en/heb). <br/>Different than user name and old password.",
                t27: "Cancel",
                t28: "Save",
                t29: "Update User",
                t30: "resend <P1>",

                t31: "new password is not valid",
                t32: "second password doesnt match first password",
                t33: "invalid phone number",
                t34: "invalid email",

                t35: "update user details succeeded",
                t36: "update user details failed",
                t37: "Save",

                t38: "Renew Failed",
                t39: "Make sure, the password meets the security requirements:",
                t40: "Confirm",
                t41: "1. Between 6 to 30 characters",
                t42: "2. at least 1 digit",
                t43: "3. at least 1 digit alphabetic char",
                t44: "4. Different than user name",
                t45: "5. Different than old password",

                t46: "Forgot your password?",

                t47: "Failed to send confirmation code to your device",
                t48: "Confirmation code sent to your device",
                t49: "Failed to validate confirmation code",
                t50: "Password sent to your device. The password will be valid for an hour",
            }, {
                t1: "התחברות למערכת",
                t2: "אתה מנסה לצפות בעמוד מאובטח. ",
                t3: "על מנת להמשיך, נא להתחבר למערכת:",
                t4: "קוד חברה הוא שדה חובה",
                t5: "קוד חברה",
                t6: "שם משתמש הוא שדה חובה",
                t7: "שם משתמש",
                t8: "סיסמה היא שדה חובה",
                t9: "סיסמה",
                t10: "התחבר",
                t11: "SMS קוד אישור",
                t12: "ביטול",
                t13: "התחבר",
                t14: "שלום <P1>, תוקף הסיסמא עומד לפוג בתוך <P2> ימים",
                t15: "שלום <P1>, תוקף סיסמתך פג",
                t16: "המשך ללא שינוי סיסמא",
                t17: "שינוי סיסמא",
                t18: "יציאה",
                t19: "שינוי סיסמא",
                t20: "שם משתמש",
                t21: "שם תצוגה",
                t22: "מספר טלפון נייד",
                t23: "כתובת מייל",
                t24: "סיסמא חדשה",
                t25: "הקש שנית סיסמא",
                t26: "בין 6 ל- 30 תוים, לפחות ספרה אחת ואות אחת. שונה משם המשתמש והסיסמא הישנה",
                t27: "ביטול",
                t28: "שמור",
                t29: "עדכון פרטי משתמש",
                t30: "שלח שנית <P1>",

                t31: "הסיסמא החדשה לא חוקית",
                t32: "הסיסמאות אינן זהות",
                t33: "מספר טלפון לא חוקי",
                t34: "כתובת אימייל לא חוקית",

                t35: "נתוני משתמש עודכנו בהצלחה",
                t36: "עדכון נתוני משתמש נכשל",
                t37: "שמור",

                t38: "חידוש סיסמא נכשל",
                t39: "נא וודא, שהסיסמא עומדת בדרישות האבטחה:",
                t40: "אישור",
                t41: "1. בעלת אורך של 6 עד 30 תווים",
                t42: "2. בעלת לפחות ספרה אחת",
                t43: "3. בעלת לפחות אות אחת",
                t44: "4. שונה משם המשתמש",
                t45: "5. שונה מהסיסמא הישנה",

                t46: "שכחת את הסיסמא?",

                t47: "שליחת קוד הזדהות נכשלה",
                t48: "נשלח קוד הזדהות למכשירך",
                t49: "קוד הזדהות שגוי",
                t50: "נשלחה סיסמא זמנית למכשירך. תוקף הסיסמא יפוג בתום שעה",
            }, {
                t1: "الاتصال بالنظام",
                t2: "أنت تحاول عرض صفحة آمنة.",
                t3: "للمتابعة ، يرجى الاتصال بالنظام:",
                t4: "رمز الشركة إلزامي",
                t5: "رمز الشركة",
                t6: "اسم المستخدم مطلوب",
                t7: "اسم المستخدم",
                t8: "كلمة المرور مطلوبة",
                t9: "كلمة المرور",
                t10: "تسجيل الدخول",
                t11: "رمز تأكيد الرسائل القصيرة",
                t12: "إلغاء",
                t13: "تسجيل الدخول",
                t14: "שלום <P1>, תוקף הסיסמא עומד לפוג בתוך <P2> ימים",
                t15: "שלום <P1>, תוקף הסיסמתך פג",
                t16: "המשך ללא שינוי סיסמא",
                t17: "שינוי סיסמא",
                t18: "יציאה",
                t19: "שינוי סיסמא",
                t20: "שם משתמש",
                t21: "שם תצוגה",
                t22: "מספר טלפון נייד",
                t23: "כתובת מייל",
                t24: "סיסמא חדשה",
                t25: "הקש שנית סיסמא",
                t26: "בין 6 ל- 30 תוים, לפחות ספרה אחת ואות אחת. שונה משם המשתמש והסיסמא הישנה",
                t27: "ביטול",
                t28: "שמור",
                t29: "עדכון פרטי משתמש",
                t30: "שלח שנית <P1>",

                t31: "הסיסמא החדשה לא חוקית",
                t32: "הסיסמאות אינן זהות",
                t33: "מספר טלפון לא חוקי",
                t34: "כתובת אימייל לא חוקית",

                t35: "נתוני משתמש עודכנו בהצלחה",
                t36: "עדכון נתוני משתמש נכשל",
                t37: "שמור",

                t38: "חידוש סיסמא נכשל",
                t39: "נא וודא, שהסיסמא עומדת בדרישות האבטחה.</b>1. בעלת אורךשל 6 עד 30 תווים</b>2. בעלת לפחות ספרה אחת</b>3. בעלת לפחות אות אחת</b>4. שונה משם המשתמש</b>5. שונה מהסיסמא הישנה",
                t40: "אישור",
                t41: "1. בעלת אורך של 6 עד 30 תווים",
                t42: "2. בעלת לפחות ספרה אחת",
                t43: "3. בעלת לפחות אות אחת",
                t44: "4. שונה משם המשתמש",
                t45: "5. שונה מהסיסמא הישנה",

                t46: "שכחת את הסיסמא?",

                t47: "שליחת קוד הזדהות נכשלה",
                t48: "נשלח קוד הזדהות למכשירך",
                t49: "קוד הזדהות שגוי",
                t50: "נשלחה סיסמא זמנית למכשירך. תוקף הסיסמא יפוג בתום שעה",

            }
        ];
        let lang = this.state.language;
        return data[lang]["t" + id]
    }

    api_renew_get_user = () => {
        const { companyCode, password, userName } = this.props.previousValues;
        //const { companyCode, password, userName } = this.state;
        Get_User_For_Renew(userName, password, companyCode,
            "/cgi-bin/CotRenew",
            "", /* confimation */
            (o: JobResponse) => {
                let oToken = new StringView(o.data.buffer, "UTF-8", 4);
                let res = oToken.toString();
                const [_name, _display, _phone, _email, _days] = res.split('\f');
                //console.log("DEBUG: ", _name, _display, _phone, _email, _days)
                this.setState({ currName: _name, currDisplayName: _display, currphone: _phone, currmail: _email, currDayLeft: _days })
            },
            (err: string) => { console.log("STEP FAILED"); });
    }

    api_renew_user = () => {
        const { companyCode, password, userName, confirmationNumber } = this.state;
        const { currName, currDisplayName, currphone, currmail, currpsw1, currpsw2 } = this.state;

        Renew(userName, password, companyCode,
            "/cgi-bin/CotRenew",
            confirmationNumber || "",
            currName, currDisplayName, currphone, currmail, currpsw1, currpsw2,
            (o: JobResponse) => {
                console.log("CALL OK renew")
                let oToken = new StringView(o.data.buffer, "UTF-8", 4);
                let res = oToken.toString();
                //const [token, isTwoWayStr, isExpired] = res.split('\f');
                const [isTwoWayStr, isOK] = res.split('\f');
                const isTwoWay = parseInt(isTwoWayStr);
                if (isTwoWay > 0) {   // two way auth
                    console.log("CALL two way auth")
                    this.setState({ confirmationNumber: "", isRenewConfDiagShown: true }, this.initCounter)
                } else {
                    console.log("CALL one way auth")
                    this.setState({ confirmationNumber: "", isRenewConfDiagShown: false, isRenewFormShown: false })
                    this.props.toggleRenewModal(0);
                    message.success(this.getText(35))
                }
            },
            (err: string) => {
                console.log("CALL FAILED")
                this.setState({ confirmationNumber: "", isRenewConfDiagShown: false }, () => {
                    //message.error(this.getText(36)) 
                    let lang = this.state.language;
                    let dir = (lang > 1) ? "rtl" : "ltr";
                    notification.error({
                        placement: "topRight", rtl: true, maxCount: 1, duration: 60,
                        message: (<p style={{ fontSize: 16, display: "block" }} dir={dir}>{this.getText(38)}</p>),
                        description: (<div>
                            <p dir={dir}>{this.getText(39)}</p>
                            <p dir={dir}>{this.getText(41)}</p>
                            <p dir={dir}>{this.getText(42)}</p>
                            <p dir={dir}>{this.getText(43)}</p>
                            <p dir={dir}>{this.getText(44)}</p>
                            <p dir={dir}>{this.getText(45)}</p>
                        </div>)
                    })
                })
            });
    }

    api_renew_resend = () => {
        const { companyCode, password, userName, confirmationNumber } = this.state;
        const { currName, currDisplayName, currphone, currmail, currpsw1, currpsw2 } = this.state;
        this.setState({ confirmationNumber: "", count: this.MAX_COUNT, isRenewConfDiagShown: true })

        Resend(userName, password, companyCode,
            "/cgi-bin/CotRenew",
            // confirmationNumber || "", currName, currDisplayName, currphone, currmail, currpsw1, currpsw2,
            (o: JobResponse) => {
                console.log("resend OK")
                this.initCounter()
            },
            (err: string) => {
                console.log("resend FAILED")
            });
    }

    handleSubmit = (e) => {
        const oThat = this;
        e.preventDefault();
        this.props.setLang(this.state.language)

        /*
        this.props.form.validateFields((err, values) => {
            if (!err) {
                oThat.props.saveValues(values);
                oThat.props.login((values.companyCode).toLowerCase(), values.userName, values.password, this.state.confirmationNumber);
                this.props.indexedDbCreate(values.companyCode);
            }
        });
        */
        const { companyCode, password, userName, confirmationNumber } = this.state;
        //console.log("LOG: ", companyCode, password, userName, confirmationNumber)
        const values = { companyCode, password, userName, confirmationNumber }
        oThat.props.saveValues(values);
        oThat.props.login((values.companyCode)?.toLowerCase(), values.userName, values.password, values.confirmationNumber);
        this.indexedDbCreate(values.companyCode);
    }

    handleRenewPrev = (e) => {
        const oThat = this;
        //const { companyCode, password, userName } = this.props.previousValues;
        const { companyCode, password, userName, confirmationNumber } = this.state;
        oThat.props.renew((companyCode).toLowerCase(), userName, password, confirmationNumber,
            this.state.currName, this.state.currDisplayName, this.state.currphone, this.state.currmail,
            this.state.currpsw1, this.state.currpsw2);
    }

    /* renew in 7 msg */
    handleSkipRenewAndLogin = (e) => {
        const oThat = this;
        //const { companyCode, password, userName } = this.props.previousValues;
        const { companyCode, password, userName, confirmationNumber } = this.state;
        console.log("handleSkipRenewAndLogin", companyCode, password, userName)
        oThat.props.justLogin((companyCode).toLowerCase(), userName, password, confirmationNumber);
        setTimeout(() => { this.setState({ confirmationNumber: "" }) }, 2000);
    }

    handleCancelRenew = () => {
        this.props.toggleRenewModal(0);
    }

    handleShowRenewForm = () => {
        this.api_renew_get_user();
        this.setState({ isRenewFormShown: true })
    }

    /* renew form */
    handleCancelRenewForm = () => {
        this.setState({ isRenewFormShown: false })
    }

    validateForm = () => {
        const { currName, currDisplayName, currphone, currmail, currpsw1, currpsw2 } = this.state;
        let isOk = true;
        const validation = {
            isEmailAddress: (str) => { return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(str); },
            isNotEmpty: (str) => { return (/\S+/).test(str); },
            isNumber: (str) => { return (/^\d+\.?\d*$/).test(str); },
            isSame: (str1, str2) => { return str1 === str2; },
            isAlphanumeric: (str) => { return (/^[0-9a-zA-Z]+$/).test(str); },
            isPhoneNumber: (str) => { return (/^[0-9]{10}$/).test(str); },
            isPassword: (str) => { return (/^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z\d]{6,30}$/).test(str); },
            isPasswordHeb: (str) => { return (/^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z\u0590-\u05FF\d]{6,30}$/).test(str); }
        }

        if (!validation.isPasswordHeb(currpsw1)) { message.error(this.getText(31)); }
        else if (!validation.isSame(currpsw1, currpsw2)) { message.error(this.getText(32)); }
        else if (!validation.isPhoneNumber(currphone)) { message.error(this.getText(33)); }
        else if (!validation.isEmailAddress(currmail)) { message.error(this.getText(34)); }
        else { return true; }
        return false;
    }

    handleSubmitRenewForm = () => {
        console.log('debug: handleSubmitRenewForm')
        if (this.validateForm()) {
            this.setState({ isRenewFormShown: false })
            //this.handleRenewPrev();
            this.api_renew_user();
        }
    }

    /* login confirmation  */
    handleSubmitPrev = (e) => {
        const oThat = this;
        //const { companyCode, password, userName } = this.props.previousValues;
        const { companyCode, password, userName, confirmationNumber } = this.state;
        //console.log("handleSubmitPrev", companyCode, password, userName)
        //oThat.props.login((values.companyCode).toLowerCase(), values.userName, values.password, this.state.confirmationNumber );
        oThat.props.login((companyCode).toLowerCase(), userName, password, confirmationNumber);
        setTimeout(() => { this.setState({ confirmationNumber: "" }) }, 2000);
    }

    handleCancel = () => {
        this.props.toggleConfirmationModal(false);
        this.setState({ confirmationNumber: "" })
    }

    populate_msg(text, prm1, prm2) {
        return text.replace('<P1>', prm1 || 'משתמש').replace('<P2>', prm2 || '0')
    }

    button_description() {
        let id = 30;
        let text = this.getText(id)
        if (this.state.count > 0) {
            return text.replace('<P1>', '(<P1>)').replace('<P1>', this.state.count)
        } else {
            return text.replace('<P1>', '')
        }
    }

    initCounter = () => {
        this.setState({ count: this.MAX_COUNT },
            () => {
                let x = setInterval(() => {
                    //console.log("interval count: ", count)
                    const { count } = this.state;
                    this.setState({ count: count - 1 })
                    if (count <= 0) { clearInterval(x); }
                }, 1000);
            }
        );
    }

    tooltip = () => {
        return (this.getText(26))
    }

    api_request_forget = () => {
        const { companyCode, userName } = this.state;
        //this.setState({ confirmationNumber: "", count: this.MAX_COUNT, isForgetDiagShown: true }, this.initCounter) // debug
        RequestForget(userName, companyCode,
            "/cgi-bin/CotForget",
            (o: JobResponse) => {
                let raw = new StringView(o.data.buffer, "UTF-8", 4);
                let res = raw.toString();
                if (res === "1") {
                    this.setState({ confirmationNumber: "", count: this.MAX_COUNT, isForgetDiagShown: true }, this.initCounter)
                    message.success(this.getText(48))
                } else { message.error(this.getText(47)) }
            },
            (err: string) => { console.log(err); message.error(this.getText(47)) });
    }

    api_validate_forget = () => {
        const { companyCode, userName, confirmationNumber } = this.state;
        ValidateForget(userName, confirmationNumber, companyCode,
            "/cgi-bin/CotForget",
            (o: JobResponse) => {
                let raw = new StringView(o.data.buffer, "UTF-8", 4);
                let res = raw.toString();
                if (res === "1") {
                    this.setState({ confirmationNumber: "", count: 0, isForgetDiagShown: false })
                    message.success(this.getText(50))
                } else { message.error(this.getText(49)) }
            },
            (err: string) => { console.log(err); message.error(this.getText(49)) });
    }


    /* RENDER */
    render() {
        //const { getFieldDecorator } = this.props.form;
        const { companyCode, password, userName } = this.props.previousValues;
        //console.log("login previousValues ", companyCode, password, userName)
        //console.log("log state ", this.state.companyCode, this.state.password, this.state.userName)
        //console.log("log ", this.props)
        //console.log("log ", this.props.ui)
        let form = (
            <React.Fragment>
                <Form onSubmit={this.handleSubmit} className="login-form" layout="vertical" id="form-login-bo">
                    <h2>{this.getText(1)}</h2>
                    <div className="form-instruction">
                        <strong>{this.getText(2)}</strong><br />
                        {this.getText(3)}
                    </div>

                    <FormItem>
                        <Input prefix={<Icon type="shop" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.getText(5)} autoComplete="organization"
                            required={true} message={this.getText(4)}
                            defaultValue={this.props.previousValues && this.props.previousValues.companyCode ? this.props.previousValues.companyCode : ""}
                            value={this.state.companyCode}
                            onChange={e => this.setState({ companyCode: e.target.value })}
                        />
                    </FormItem>

                    <FormItem>
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.getText(7)} autoComplete="username"
                            required={true} message={this.getText(6)}
                            value={this.state.userName}
                            onChange={e => this.setState({ userName: e.target.value })}
                            onFocus={e => this.setState({visibilityToggle: false})}
                        />
                    </FormItem>

                    <FormItem>
                        <Input.Password
                            placeholder={this.getText(9)}
                            //iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            visibilityToggle={this.state.visibilityToggle}
                            required={true} message={this.getText(8)}
                            value={this.state.password}
                            onChange={e => {
                                if (!e.target.value) {
                                    this.setState({ password: e.target.value, visibilityToggle: true });
                                } else {
                                    this.setState({ password: e.target.value });
                                }
                            }}
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        />
                        {/* <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.getText(9)} type="password"
                            autoComplete="current-password"
                            required={true} message={this.getText(8)}
                            value={this.state.password}
                            onChange={e => this.setState({ password: e.target.value })}
                        />
                        */}
                    </FormItem>

                    <FormItem>
                        <Select
                            key={"lang"}
                            showSearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            onChange={(v) => {
                                //this.props.uiActions.setJsonUI("",{ language: v})
                                this.setState({ language: v })
                            }}
                            value={this.state.language}>
                            <Option value={1}>English</Option>
                            <Option value={2}>עברית</Option>
                            <Option value={3}>عربيه</Option>
                        </Select>
                    </FormItem>

                    <Row>
                        <Col span={12} style={{ display: "flex", justifyContent: "right", paddingTop: 2, paddingRight: 8, paddingLeft: 8 }}>
                            <a onClick={(e) => { this.api_request_forget(); return false; }}>{this.getText(46)}</a>
                        </Col>
                        <Col span={12} style={{ display: "flex", justifyContent: "left", paddingRight: 8, paddingLeft: 8 }}>
                            <Button type="primary" htmlType="submit" className="login-form-submit" loading={this.props.working} >
                                {this.getText(10)}
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <GenericModal
                    width={300}
                    visible={this.props.isConfDiagShown}
                    onCancel={this.handleCancel}
                    title={this.getText(11)}
                    footer={[
                        <Button key="Back_button" onClick={this.handleCancel}>{this.getText(12)}</Button>,
                        <Button key="login_button" type="primary" onClick={this.handleSubmitPrev}>{this.getText(13)}</Button>,
                    ]}>
                    <Input prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        //placeholder={''}
                        //type="password" 
                        autoFocus
                        value={this.state.confirmationNumber}
                        onChange={(e) => { this.setState({ confirmationNumber: e.target.value }) }}
                        onPressEnter={this.handleSubmitPrev}
                    />
                </GenericModal>

                <GenericModal
                    /* renew in 7 msg */
                    width={500}
                    visible={this.props.isRenewModalShown && this.props.isRenewModalShown === 1}
                    onCancel={this.handleCancelRenew}
                    //title={this.getText(10000)}
                    footer={[
                        <Button key="skip_button" onClick={this.handleSkipRenewAndLogin}>{this.getText(16)}</Button>,
                        <Button key="renew_form_button" type="primary" onClick={this.handleShowRenewForm}>{this.getText(17)}</Button>,
                    ]}>
                    <div><strong style={{ fontSize: 18 }}>{this.populate_msg(this.getText(14), this.state.currDisplayName, this.state.currDayLeft)}</strong></div>
                </GenericModal>

                <GenericModal
                    /* renew expired msg */
                    width={500}
                    visible={this.props.isRenewModalShown && this.props.isRenewModalShown === 2}
                    onCancel={this.handleCancelRenew}
                    //title={this.getText(10000)}
                    footer={[
                        <Button key="Back_button" onClick={this.handleCancelRenew}>{this.getText(18)}</Button>,
                        <Button key="renew_form_button" type="primary" onClick={this.handleShowRenewForm}>{this.getText(19)}</Button>,
                    ]}>
                    <div><strong style={{ fontSize: 18 }}>{this.populate_msg(this.getText(15), this.state.currDisplayName, this.state.currDayLeft)}</strong></div>
                </GenericModal>

                <GenericModal
                    /* renew form */
                    width={700}
                    visible={this.state.isRenewFormShown}
                    //visible={this.props.ui.modalStack.includes(TableIDs.modalRenewForm)}
                    onCancel={this.handleCancelRenewForm}
                    title={this.getText(29)}
                    footer={[
                        <Button key="Back_button" onClick={this.handleCancelRenewForm}>{this.getText(27)}</Button>,
                        <Button key="renew_button" type="primary" onClick={this.handleSubmitRenewForm}>{this.getText(28)}</Button>,
                    ]}>
                    <Form id="renew_form" layout='horizontal' style={{ columnWidth: 'auto', columnCount: '2' }}>
                        <FormItem required={true} label={this.getText(20)}>
                            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.getText(20)}
                                //value={this.props.user.currName} onChange={(e) => { this.setUserData('currName',e) }} />
                                value={this.state.currName} onChange={(e) => { this.setState({ currName: e.target.value }) }} disabled />
                        </FormItem>
                        <FormItem required={true} label={this.getText(21)}>
                            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.getText(21)}
                                //value={this.props.user.currDisplayName} onChange={(e) => { this.setUserData('currDisplayName',e) }} />
                                value={this.state.currDisplayName} onChange={(e) => { this.setState({ currDisplayName: e.target.value }) }} />
                        </FormItem>
                        <FormItem required={true} label={this.getText(22)}>
                            <Input prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.getText(22)}
                                //value={this.props.user.currphone} onChange={(e) => { this.setUserData('currphone',e) }} />
                                value={this.state.currphone} onChange={(e) => { this.setState({ currphone: e.target.value }) }} />
                        </FormItem>
                        <FormItem required={true} label={this.getText(23)}>
                            <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.getText(23)}
                                //value={this.props.user.currmail} onChange={(e) => { this.setUserData('currmail',e) }} />
                                value={this.state.currmail} onChange={(e) => { this.setState({ currmail: e.target.value }) }} />
                        </FormItem>
                        <FormItem required={true}  /*label={this.getText(24)}*/ label={<Tooltip title={this.tooltip()}>{this.getText(24)}{' '}<Icon type="info-circle-o" /></Tooltip>}>
                            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.getText(24)} type="password"
                                //value={this.props.user.currpsw1} onChange={(e) => { this.setUserData('currpsw1',e) }} />
                                value={this.state.currpsw1} onChange={(e) => { this.setState({ currpsw1: e.target.value }) }} />
                        </FormItem>
                        <FormItem required={true} label={this.getText(25)}>
                            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.getText(25)} type="password"
                                //value={this.props.user.currpsw2} onChange={(e) => { this.setUserData('currpsw2',e) }} />
                                value={this.state.currpsw2} onChange={(e) => { this.setState({ currpsw2: e.target.value }) }} />
                        </FormItem>
                    </Form>
                </GenericModal>

                <GenericModal
                    /* renew confirmation */
                    width={400}
                    visible={this.state.isRenewConfDiagShown}
                    onCancel={() => { this.setState({ confirmationNumber: "", isRenewConfDiagShown: false }) }}
                    title={this.getText(11)}
                    footer={[
                        <Button key="Back_button" onClick={() => { this.setState({ confirmationNumber: "", isRenewConfDiagShown: false }) }}>{this.getText(12)}</Button>,
                        <Button key="resend" disabled={this.state.count > 0} onClick={this.api_renew_resend}>{this.button_description()}</Button>,
                        <Button key="confirm_renew_button" type="primary" onClick={this.api_renew_user}>{this.getText(40)}</Button>,
                    ]}>
                    <Input prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        //placeholder={''}
                        //type="password" 
                        autoFocus
                        value={this.state.confirmationNumber}
                        onChange={(e) => { this.setState({ confirmationNumber: e.target.value }) }}
                        onPressEnter={this.api_renew_user}
                    />
                </GenericModal>

                <GenericModal
                    /* forget confirmation */
                    width={400}
                    visible={this.state.isForgetDiagShown}
                    onCancel={() => { this.setState({ confirmationNumber: "", isForgetDiagShown: false, count: 0 }) }}
                    title={this.getText(11)}
                    footer={[
                        <Button key="Back_button" onClick={() => { this.setState({ confirmationNumber: "", isForgetDiagShown: false, count: 0 }) }}>{this.getText(12)}</Button>,
                        <Button key="request_forget_button" disabled={this.state.count > 0} onClick={this.api_request_forget}>{this.button_description()}</Button>,
                        <Button key="confirm_forget_button" type="primary" onClick={this.api_validate_forget}>{this.getText(40)}</Button>,
                    ]}>
                    <Input prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        //placeholder={''}
                        //type="password" 
                        autoFocus
                        value={this.state.confirmationNumber}
                        onChange={(e) => { this.setState({ confirmationNumber: e.target.value }) }}
                        onPressEnter={this.api_request_forget}
                    />
                </GenericModal>

            </React.Fragment>
        );

        return form;
    }
}


export default LoginFormV2;

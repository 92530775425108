
import React from "react";
import {
    Form, Button, DatePicker, Modal, Checkbox, Select
} from 'antd';
import moment from "moment";
import PageToolbar from "./PageToolbar";
import LangContext from "../contextProvider/LangContext";
import TableIDs from "../data/TableIDs";
import GenericSelector from "./GenericSelector";
import { disabledDatePicker } from "../utils/utils";

const FormItem = Form.Item;
const { Option } = Select;

type TagObj = {
    type: {
        id: string,
        text: string,
    },
    tags: {
        id: string,
        text: string,
    }[],
}

class ModalSearchFixLabDocuments extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.id = "FIX_LAB_DOCUMENTS_SEARCH";
        this.statuses_id = "FIX_LAB_STATUSES_LIST"
        this.list_id = "FIX_LAB_DOCUMENTS";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.list_id] === undefined) { props.data[this.list_id] = { ...props.data.genericData }; }

        this.api = {
            get_statuses: "get_fix_lab_statuses",
            get: "get_fixLabDocuments_table_bo",
        }

        this.selectors = [
            { id: this.statuses_id, api: this.api.get_statuses },
            { id: TableIDs.genericSelectorStorages, api: 'get_storage_list' },
        ];

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector }; }
        })

        this.actionButtons = []
        //  [{
        //     buttonType: 'refresh',
        //     buttonFunction: this.refreshData
        // }];

        this.state = {
            loading: false,
            visible: false,

            request: {
                _statusFilter: '',
                _statusDateStart: '',
                _statusDateEnd: '',
                _storageList: '',

            }
        }

        this.refreshData();
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    // send data to parent
    UNSAFE_componentWillReceiveProps(newProps) {
        let _state = { ...this.state };
        _state.visible = newProps.toggle;
        this.setState(_state);
    }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.statuses_id, this.props.user.companyCode, this.props.user.token, this.api.get_statuses);
    }

    showModal = () => { this.setState({ visible: true, }); }
    handleOk = () => {
        console.log("search...");
        let request = {
            ...this.state.request,
            _page_num: 1,
            _statusFilter: this.state.request._statusFilter,
            _statusDateStart: this.state.request._statusDateStart,
            _statusDateEnd: this.state.request._statusDateEnd,
            _storageList: this.state.request._storageList,
        }

        this.props.dataActions.genericDataSetFilter(
            this.list_id, this.props.user.companyCode, this.props.user.token,
            request, this.api.get);

        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
        if (this.props.setFilterActive) this.props.setFilterActive(true)
    }
    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }

    onStartChange = (value) => {
        this.onChange('_statusDateStart', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('_statusDateEnd', value ? value.format("YYYY-MM-DD") : null);
    }

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }

    getSelector = (index, field) => {
        let r = { ...this.state.request };
        return (<GenericSelector
            {...this.props}
            {...this.selectors[index]}
            multi
            value={r[field] ? r[field].split(',') : []}
            onChange={(e) => {
                this.setState({ request: { ...r, [field]: e ? e.join(',') : '' } });
            }}
        />)
    }

    render() {
        const { visible, loading, endOpen } = this.state;
        const { _statusFilter, _statusDateStart, _statusDateEnd, _storageList } = this.state.request;

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        let statusesData = () => {
            let data = this.props.data[this.statuses_id];
            return (data && data.data && data.data[0]) ? data.data : false;
        }

        let statusesList = statusesData() ?
            statusesData().map((x) => {
                let y = x.split("\f");
                let styleStatus = { backgroundColor: y[2] }
                return (
                    <Option
                        style={styleStatus}
                        key={y[0]}
                        value={y[0]}
                        valuefilter={y[1]}>
                        <span style={styleStatus}> {y[0] + '-' + y[1]} </span>
                    </Option>
                )
            }) : '';

        let statusesListJoin = statusesData() ?
            statusesData().map((x) => {
                let y = x.split("\f");
                return y[0];
            }).join(',')
            : [];

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(11287)}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>);

        let formItemStyle = {
            width: (window.innerWidth > 600) ? '48%' : '96%',
            display: 'inline-block',
            verticalAlign: 'text-top',
        }

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'70%'}
                visible={visible}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(11288)}</Button>,
                    <Button key="clear" onClick={this.handleReset}>{this.getText(11289)}</Button>,
                    <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(11290)}</Button>
                ]}
            >
                <Form
                    layout="horizontal"
                    className="ant-advanced-search-form"
                    onSubmit={this.handleSearch}
                >
                    <div style={formItemStyle}>
                        <FormItem label={this.getText(11291)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                            <DatePicker
                                // disabledDate={this.disabledStartDate}
                                // showTime
                                disabledDate={d => disabledDatePicker(d, null, _statusDateEnd)}
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                value={_statusDateStart ? moment(_statusDateStart, "YYYY-MM-DD") : null}
                                placeholder="Start"
                                onChange={this.onStartChange}
                                onOpenChange={this.handleStartOpenChange}
                            />
                        </FormItem>
                        <FormItem label={this.getText(11292)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
                            <DatePicker
                                // disabledDate={this.disabledEndDate}
                                // showTime
                                disabledDate={d => disabledDatePicker(d, _statusDateStart, null)}
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                value={_statusDateEnd ? moment(_statusDateEnd, "YYYY-MM-DD") : null}
                                placeholder="End"
                                onChange={this.onEndChange}
                                open={endOpen}
                                onOpenChange={this.handleEndOpenChange}
                            />
                        </FormItem>
                    </div>
                    <div style={formItemStyle}>
                        <FormItem label={this.getText(11293)}>
                            {this.createCheckBox('_statusFilter', statusesListJoin)}
                            <Select
                                key={"_statusFilter"}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={10}
                                placeholder={this.getText(11294)}
                                style={{ width: '90%', paddingLeft: '4px', paddingRight: '4px' }}
                                value={!_statusFilter ? [] : _statusFilter.split(',')}
                                onChange={(e) => {
                                    let r = { ...this.state.request };
                                    r._statusFilter = e.join(',');
                                    this.setState({ ...this.state, request: r });
                                }}
                            >
                                {statusesList}
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(16692)}>
                            {this.getSelector(1, '_storageList')}
                        </FormItem>
                    </div>
                </Form >
            </Modal >
        )
    }

    createCheckBox = (field, data) => {
        let r = this.state.request;
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setState({ request: { ...r, [field]: data } });
                else
                    this.setState({ request: { ...r, [field]: '' } });
            }} />);
    }

    // bind input field
    onChange = (field, value) => {
        let r = this.state.request;
        this.setState({
            request: {
                ...r, [field]: value,
            }
        });
    }

    /// search
    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log('Received values of form: ', values);
        });
    }

    handleReset = () => {
        if (this.props.setFilterActive) this.props.setFilterActive(false)
        else this.resetFilter();
    }

    resetFilter = () => {
        let _request = {
            _statusFilter: '',
            _statusDateStart: '',
            _statusDateEnd: '',
            _storageList: '',
        };
        this.setState({ request: _request },
            () => {
                this.props.dataActions.genericDataSetFilter(
                    this.list_id, this.props.user.companyCode, this.props.user.token, _request, this.api.get);

            }
        );
    }

    componentDidUpdate(prev) {
        if (prev.filterActive && !this.props.filterActive) this.resetFilter()
    }
}

export default ModalSearchFixLabDocuments;


import React from "react";
import { DatePicker, notification, Form, Input, Button, Modal, Select, message, Upload, Checkbox, InputNumber } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import Jax from "../Jax/jax.es6.module";
import moment from "moment";
import CustomerPicker from "./CustomerPicker";
import LangContext from "../contextProvider/LangContext";
import ReportTypeButton from "./ReportTypeButton"
import { disabledDatePicker } from "../utils/utils";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

//type State = {}
class ModalStandingOrdersChargesSummaryReport extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            loadingImg: false,
        }

        this.id = 'MODAL_STANDING_ORDERS_CHARGES_REPORT';

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        //this.state._count = 0;
        //this.state._id = 0;

        this.state.StandingOrdersChargesSummary = {
            ApplyRangeApplyCharge: 0,
            ApplyRangeLowerCharge: 0,
            ApplyRangeUpperCharge: 0,
            ApplyRangeApplyCustomerCode: 0,
            ApplyRangeLowerCustomerCode: 0,
            ApplyRangeUpperCustomerCode: 0,
            mLowerActionDate: null,
            mUpperActionDate: null,
            StatusList: '',
            StatusTextList: '',
            reportName: "reports/standingOrdersChargesSummary.pdf",
        };

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorStandingOrderStatus] === undefined) {
            props.data[TableIDs.genericSelectorStandingOrderStatus] = { ...props.data.genericSelector };
        }

        this.api = {
            //get: "get_branch_by_id",
            //edit: "edit_create_branch",
            //selector: "list_pos",
            //delete: "delete_branch",
            //runCharges: "run_standing_order_charges",
            selector: "get_standing_order_status_list",
            //getCount: "get_customer_with_creditcards_count",
        }

        this.actionButtons = [
            /*{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/];

        this.dataSource = [];

        /*
        this.openNotificationWithIcon = (type, msg, descr) => {
            notification[type]({
                message: msg,
                description: descr,
            });
        };
        */
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //document.title = "Cash On Tab - לקוחות להוראת קבע";
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStandingOrderStatus, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("StandingOrdersChargesSummary", {
                            [field]: data,
                            StatusTextList: encodeURIComponent(Jax.get(this.props.data, TableIDs.genericSelectorStandingOrderStatus + ".dataset", [])
                                .filter((obj, index) => data.split(',').includes(obj['code']))
                                .map((obj, index) => obj['name'])
                                .join(','))
                        });
                    else
                        this.setReportParameters("StandingOrdersChargesSummary", { [field]: '', StatusTextList: '' });
                }} />);
    }

    handleOkLeave = () => { this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch); }
    handleOk = () => { }
    handleCancel = () => { this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch); }
    handleClear = () => { }

    refreshData = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStandingOrderStatus, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    disabledStartDate = (FromDate) => {
        const ToDate = this.state.StandingOrdersChargesSummary.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.StandingOrdersChargesSummary.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("StandingOrdersChargesSummary", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    // onStartChange = (value) => { this.onChange('mLowerDate', value ? value.format("YYYY-MM-DD") : null); }
    // onEndChange = (value) => { this.onChange('mUpperDate', value ? value.format("YYYY-MM-DD") : null); }

    onChangeAction = (field, value) => {
        this.setReportParameters("StandingOrdersChargesSummary", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChangeAction = (value) => { this.onChangeAction('mLowerActionDate', value ? value.format("YYYY-MM-DD") : null); }
    onEndChangeAction = (value) => { this.onChangeAction('mUpperActionDate', value ? value.format("YYYY-MM-DD") : null); }

    render() {
        const { loading, loadingImg } = this.state;
        const {
            ApplyRangeApplyCharge,
            ApplyRangeLowerCharge,
            ApplyRangeUpperCharge,
            ApplyRangeApplyCustomerCode,
            ApplyRangeLowerCustomerCode,
            ApplyRangeUpperCustomerCode,
            mLowerActionDate,
            mUpperActionDate
        } = this.state.StandingOrdersChargesSummary;
        //const sqlFormat = "YYYY-MM-DD";
        //const timeFormat = "HH:mm:ss";
        //const dateFormat = "DD/MM/YYYY";

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10895)}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        const list = this.props.data[TableIDs.genericSelectorStandingOrderStatus].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        });

        const inputStyle = { marginLeft: '4px', width: '40%' };

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'70%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                <Form id="report_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '2' : '1' }}
                    onSubmit={this.handleSaveChanges}
                >
                    <FormItem label={this.getText(10896)}>
                        {this.createCheckBox('StatusList', Jax.get(this.props.data, TableIDs.genericSelectorStandingOrderStatus + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                        <Select key={"StatusList"} style={{ width: '90%' }}
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            mode="multiple"
                            maxTagCount={4}
                            //placeholder="Please select"
                            //className={""}
                            //defaultValue={1}
                            onChange={(value) => this.setReportParameters("StandingOrdersChargesSummary", {
                                StatusList: value.join(','),
                                StatusTextList: encodeURIComponent(Jax.get(this.props.data, TableIDs.genericSelectorStandingOrderStatus + ".dataset", [])
                                    .filter((obj, index) => value.includes(obj['code']))
                                    .map((obj, index) => obj['name'])
                                    .join(',')),
                            })}
                            value={(this.state.StandingOrdersChargesSummary.StatusList === '') ? [] : this.state.StandingOrdersChargesSummary.StatusList.split(',')}
                        //notFoundContent={"לא נמצאו עובדים"}       
                        >
                            {list}
                        </Select>
                    </FormItem>

                    <FormItem label={this.getText(10897)}>
                        <Checkbox onChange={this.handleCheckBoxRange}
                            //ApplyRange_Apply
                            checked={ApplyRangeApplyCustomerCode == 1}
                            onChange={(value) => this.setReportParameters("StandingOrdersChargesSummary", { ApplyRangeApplyCustomerCode: (value.target.checked) ? 1 : 0 })}
                            style={{ marginLeft: '4px' }}

                        />

                        <InputNumber
                            //ApplyRange_Lower
                            value={ApplyRangeApplyCustomerCode == 1 ? ApplyRangeLowerCustomerCode : ""}
                            min={0} precision={0}
                            onChange={(value) => this.setReportParameters("StandingOrdersChargesSummary", { ApplyRangeLowerCustomerCode: value })}
                            disabled={ApplyRangeApplyCustomerCode != 1}
                            style={inputStyle}

                        />
                        <InputNumber
                            //ApplyRange_Upper
                            value={ApplyRangeApplyCustomerCode == 1 ? ApplyRangeUpperCustomerCode : ""}
                            min={0} precision={0}
                            onChange={(value) => this.setReportParameters("StandingOrdersChargesSummary", { ApplyRangeUpperCustomerCode: value })}
                            disabled={ApplyRangeApplyCustomerCode != 1}
                            style={inputStyle}
                        />

                    </FormItem>
                    <FormItem label={this.getText(10898)}>
                        <Checkbox onChange={this.handleCheckBoxRange}
                            //ApplyRange_Apply
                            checked={ApplyRangeApplyCharge == 1}
                            onChange={(value) => this.setReportParameters("StandingOrdersChargesSummary", { ApplyRangeApplyCharge: (value.target.checked) ? 1 : 0 })}
                            style={{ marginLeft: '4px' }}

                        />
                        <InputNumber
                            //ApplyRange_Lower
                            value={ApplyRangeApplyCharge == 1 ? ApplyRangeLowerCharge : ""}
                            precision={2}
                            onChange={(value) => this.setReportParameters("StandingOrdersChargesSummary", { ApplyRangeLowerCharge: value })}
                            disabled={ApplyRangeApplyCharge != 1}
                            style={inputStyle}

                        />
                        <InputNumber
                            //ApplyRange_Upper
                            value={ApplyRangeApplyCharge == 1 ? ApplyRangeUpperCharge : ""}
                            precision={2}
                            onChange={(value) => this.setReportParameters("StandingOrdersChargesSummary", { ApplyRangeUpperCharge: value })}
                            disabled={ApplyRangeApplyCharge != 1}
                            style={inputStyle}

                        />
                    </FormItem>

                    <FormItem label={this.getText(10899)} /*label={"מתאריך חיוב:"}*/>
                        <DatePicker
                            disabledDate={d => disabledDatePicker(d, null, mUpperActionDate)}
                            // disabledDate={this.disabledStartDate}
                            // showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            //format="D/M/YY"
                            value={mLowerActionDate ? moment(mLowerActionDate, "YYYY-MM-DD") : null}
                            placeholder={this.getText(10902)}
                            onChange={this.onStartChangeAction}
                        //onOpenChange={this.handleStartOpenChange}
                        />

                    </FormItem>
                    <FormItem label={this.getText(10900)} /*label={"עד תאריך חיוב:"} style={isEndDateShownStyle}*/>
                        <DatePicker
                            disabledDate={d => disabledDatePicker(d, mLowerActionDate, null)}
                            // disabledDate={this.disabledEndDate}
                            // showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            //format="D/M/YY"
                            value={mUpperActionDate ? moment(mUpperActionDate, "YYYY-MM-DD") : null}
                            placeholder={this.getText(10903)}
                            onChange={this.onEndChangeAction}
                        //open={endOpen}
                        //onOpenChange={this.handleEndOpenChange}
                        />
                    </FormItem>
                    <ReportTypeButton
                        {...this.props}
                        params={this.state.StandingOrdersChargesSummary}
                        datesToFavorites={[
                            { field: 'mLowerActionDate', label: this.getText(10899) },
                            { field: 'mUpperActionDate', label: this.getText(10900) },
                        ]}
                        favoriteSave
                        favoriteDesc={this.getText(10895)}
                    />


                    {/* <FormItem key={"reportName"} label={this.getText(10901)}>
                        <Select style={{ width: 250 }}
                            //size={"large"}
                            className={"reports/standingOrdersChargesSummary.pdf"}
                            //combobox={null}
                            defaultValue={"pdf"}
                            onChange={(value) => {
                                this.setReportParameters("StandingOrdersChargesSummary", {
                                    reportName: "reports/standingOrdersChargesSummary." + value,
                                    type: value
                                });
                            }}
                        >
                            <Option key={"pdf"}>{this.getText(10904)}</Option>
                            <Option key={"xls"}>{this.getText(10905)}</Option>
                            <Option key={"docx"}>{this.getText(10906)}</Option>
                        </Select>
                    </FormItem> */}
                    {/*
                    <FormItem key={"submit"}>
                        <Button type={"primary"} onClick={() => {
                            //const type = this.state.RequestedReport;
                            this.generateReport.apply(this, ["StandingOrdersChargesSummary"])
                        }}>הפק דו"ח</Button>
                    </FormItem>
                    */}
                </Form>
                {/* <div className="action_footer">
                    <Button type="primary" htmlType="submit" form="report_form" onClick={() => {
                        //const type = this.state.RequestedReport;
                        this.generateReport.apply(this, ["StandingOrdersChargesSummary"])
                    }}>{this.getText(10907)}</Button>
                </div> */}
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toggle === false && this.props.toggle === true) {
            /*
            const period: String = this.props.ui.data.mPeriod;
            this.setReportParameters("StandingOrdersChargesSummary", {
                StandingOrderTotalID: this.props.ui.data.mId,
                StandingOrderTotalPeriod: (period.length === 0) ? '' : period.slice(0, 2) + '/' + period.slice(-2)
            })
            */
        }


        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("StandingOrdersChargesSummary", this.props.ui.favoriteParams)
        }
    }
}

export default ModalStandingOrdersChargesSummaryReport;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import GenericSelector from "../../components/GenericSelector";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import PageToolbar from "../../components/PageToolbar";
import ReportTypeButton from "../../components/ReportTypeButton";
import { Form, Card, Row, Col, Checkbox, InputNumber, Select, Radio, } from "antd";
import GenericSelectorCustomers from "../../components/GenericSelectorCustomers";


class AgingCreditCust extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _branchList: '',
            _tagList: '',
            _custList: '',
            _employeeList: '',
            _isActive: "1",
            _isWithPurchase: "0",
            _fromBalance: -9999999,
            _toBalance: -1,
            _orderType: "0",
            _employeePerPage: "0",
            _showPartialSum: "0",
            _sortPerAgeMonth: "0",
            reportName: "reports/AgingCreditCust.pdf"
        };

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" },
            { id: TableIDs.genericSelectorEmployees, api: "get_employee_list" },
        ];

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(18714);
        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }



    render() {
        const gt = this.getText;
        const mobile = window.innerWidth <= 600;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        return (<div style={divStyle}>
            <Card title={(<PageToolbar title={this.getText(18715)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: (window.innerWidth > 600) ? 1000 : '96%' }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 12} style={{ padding: 10 }}>
                        <Form.Item label={gt(18716)}>
                            <GenericSelector
                                {...this.props} multi {...this.selectors[0]}
                                value={this.state._branchList ? this.state._branchList.split(",") : []}
                                onChange={e => { this.setState({ _branchList: e ? e.join(",") : "" }) }}
                            />
                        </Form.Item>
                        <Form.Item label={gt(18717)}>
                            <GenericSelectorCustTags
                                {...this.props} multi
                                value={this.state._tagList ? this.state._tagList.split(",") : []}
                                onChange={e => { this.setState({ _tagList: e ? e.join(",") : "" }) }}
                            />
                        </Form.Item>
                        <Form.Item label={this.getText(19097)}>
                            <GenericSelectorCustomers
                                {...this.props} multi
                                value={this.state._custList ? this.state._custList.split(",") : []}
                                onChange={e => { this.setState({ _custList: e ? e.join(",") : "" }) }}
                            />
                        </Form.Item>
                        <Form.Item label={gt(18718)}>
                            <GenericSelector
                                {...this.props} multi {...this.selectors[1]}
                                value={this.state._employeeList ? this.state._employeeList.split(",") : []}
                                onChange={e => { this.setState({ _employeeList: e ? e.join(",") : "" }) }}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Checkbox
                                checked={this.state._isActive == '1'}
                                onChange={e => { this.setState({ _isActive: e.target.checked ? "1" : "0" }) }}
                            >{gt(18719)}</Checkbox>
                            <br />
                            <Checkbox
                                checked={this.state._isWithPurchase == '1'}
                                onChange={e => { this.setState({ _isWithPurchase: e.target.checked ? "1" : "0" }) }}
                            >{gt(18720)}</Checkbox>
                            <br />
                            <Checkbox
                                checked={this.state._employeePerPage == '1'}
                                onChange={e => { this.setState({ _employeePerPage: e.target.checked ? "1" : "0" }) }}
                            >{gt(18727)}</Checkbox>
                            <br />
                            <Checkbox
                                checked={this.state._showPartialSum == '1'}
                                onChange={e => { this.setState({ _showPartialSum: e.target.checked ? "1" : "0" }) }}
                            >{gt(18798)}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={mobile ? 24 : 12} style={{ padding: 10 }}>

                        <Form.Item label={gt(18721)}>
                            <Row>
                                <Col span={2}>{gt(18722)}</Col>
                                <Col span={10}><InputNumber value={this.state._fromBalance} onChange={e => { this.setState({ _fromBalance: e }) }} /></Col>
                                <Col span={2}>{gt(18723)}</Col>
                                <Col span={10}><InputNumber value={this.state._toBalance} onChange={e => { this.setState({ _toBalance: e }) }} /></Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label={gt(18724)}>
                            <Select style={{ width: 250 }} value={this.state._orderType} onChange={e => { this.setState({ _orderType: e }) }}>
                                <Select.Option value={"0"}>{gt(18725)}</Select.Option>
                                <Select.Option value={"1"}>{gt(18726)}</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Radio.Group
                                value={this.state._sortPerAgeMonth}
                                onChange={e => { this.setState({ _sortPerAgeMonth: e.target.value }) }}>
                                <Radio value={"0"}>{this.getText(18799)}</Radio><br />
                                <Radio value={"1"}>{this.getText(18800)}</Radio><br />
                                <Radio value={"2"}>{this.getText(18801)}</Radio><br />
                                <Radio value={"3"}>{this.getText(18802)}</Radio><br />
                                <Radio value={"4"}>{this.getText(18803)}</Radio><br />
                            </Radio.Group>
                        </Form.Item>
                        <ReportTypeButton
                            {...this.props}
                            params={this.state}
                            branchesField={'_branchList'}
                            favoriteSave
                        />
                    </Col>
                </Row>

            </Card>
        </div>)
    }

}

export default AgingCreditCust;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import PricesTable from "./PricesTable";

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    data: any,
    dataActions: any,
};
type State = {};

class PricesTableIndex extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        var divStyle = {
            align: 'center',
            dir: 'rtl', // dir: 'ltr'
            padding: '2% 2% 2% 2%',
            //whiteSpace: 'unset',
        }

        return (
            <div style={divStyle}>
                <PricesTable
                    user={this.props.user}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>
        );
    }
}
export default PricesTableIndex;

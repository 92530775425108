/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Tooltip, Icon, Row, Col, Collapse } from "antd";
import { ChartCard } from "ant-design-pro/lib/Charts";
import Trend from 'ant-design-pro/lib/Trend';
import numeral from "numeral";
import Nis from "../../components/Nis";
import LangContext from "../../contextProvider/LangContext";
import "../Dashboard.css";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import moment from "moment";

class ShortSummarySection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isProductivityExtended: false,
            isAverageLinesExtended: false,
            isAverageSaleExtended: false,
            isSalesExtended: false,

            costValue: null,
            noTaxValue: null,
        }

        this.profits_id = "PROFITS_DATA";
        this.catering_id = "CATERING_TICKETS_DATA";

        if (props.data[this.profits_id] === undefined) props.data[this.profits_id] = { ...props.data.genericData }
        if (props.data[this.catering_id] === undefined) props.data[this.catering_id] = { ...props.data.genericData }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    componentDidUpdate(p) {
        if (
            p.dashboardDate !== this.props.dashboardDate ||
            p.dashboardPos !== this.props.dashboardPos
        ) {
            this.sendAPI("get_dashboard_profits", "_date\f_pos_list\r" + moment(this.props.dashboardDate).format("YYYY-MM-DD") + "\f" + this.props.dashboardPos, ob => {
                let d = ob.data ? ob.data.split("\f") : ['0', '0']
                this.setState({ costValue: parseFloat(d[0]), noTaxValue: parseFloat(d[1]) })
            });
        }
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }


    createTrend = (percent) => {
        return (<Trend flag={percent > 0 ? "up" : "down"} reverseColor={true} style={{ marginLeft: "4px", fontWeight: "bold" }}>
            {numeral(percent * 100).format('0,0')}%
        </Trend>)
    }

    getDetails = (obj, avg, isProfitContent) => {
        if (isProfitContent) {
            const {noTaxValue, costValue} = this.state
            const profitValue = noTaxValue - costValue;

            return (<div className={"chart-card-details"}>
                <div>{this.createTrend(profitValue / noTaxValue )}{this.getText(19847)}</div>
                <div>{this.createTrend(profitValue / costValue )}{this.getText(19848)}</div>
            </div>)
        } else {
            return (<div className={"chart-card-details"}>
                <div>{this.createTrend(avg ? obj.weekAgo.diffAvg : obj.weekAgo.diff)}{this.getText(10123)}</div>
                <div>{this.createTrend(avg ? obj.monthAgo.diffAvg : obj.monthAgo.diff)}{this.getText(10124)}</div>
                <div>{this.createTrend(avg ? obj.sixMonthsAgo.diffAvg : obj.sixMonthsAgo.diff)}{this.getText(10125)}</div>
                <div>{this.createTrend(avg ? obj.twelveMonthsAgo.diffAvg : obj.twelveMonthsAgo.diff)}{this.getText(10126)}</div>
            </div>)
        }
    }

    getChartCard = (title, tooltip, total, data, stateName, avg) => {
        let isExtended = this.state[stateName];
        let isCountContent = stateName === 'isAverageLinesExtended'
        let isProfitContent = stateName === 'isProfitExtended'
        let footer = (<Button htmlType={"button"} onClick={() => { this.setState({ [stateName]: !isExtended }) }}>{this.getText(isExtended ? 10121 : 10122)}</Button>);

        return (<div style={{ marginBottom: "24px", display: "inline-block", width: "20%", padding: 10 }}>
            <ChartCard
                bordered={false} title={title} action={(<Tooltip title={tooltip}><Icon type="info-circle-o" /></Tooltip>)}
                loading={this.props.isLoading} total={() => isCountContent && total ? total.toFixed(1) : (<Nis>{total}</Nis>)} footer={footer} contentHeight={46}
            >{isExtended ? this.getDetails(data, avg, isProfitContent) : null}</ChartCard>
        </div>)
    }

    getMobilePanel = (title, tooltip, total, data, stateName, avg) => {
        let isCountContent = stateName === 'isAverageLinesExtended'
        let isProfitContent = stateName === 'isProfitExtended'

        return (<Collapse.Panel
            header={(<span>
                <h3>{title}</h3>
                <h3>{isCountContent && total ? total.toFixed(1) : (<Nis>{total}</Nis>)}</h3>
            </span>)}
        >
            <div style={{ marginBottom: 5 }}>{tooltip}</div>
            <div>{this.getDetails(data, avg, isProfitContent)}</div>
        </Collapse.Panel>)
    }

    render() {
        let gt = this.getText;
        const { productivity, lines, documents } = this.props.apiData;

        let productivityTooltip = (<div>{gt(10118)}. {gt(10119)} <b>{productivity.today.totalWorkingHours}</b> {gt(10120)}</div>);
        let linesTooltip = (<div><b>{lines.today.lineCount}</b> {gt(10128)}</div>);
        let documentsAvgTooltip = (<div>{gt(10130)}: <b>{documents.today.max}</b></div>);
        let documentsTooltip = (<div>{gt(10119)}: <b>{documents.today.count}</b> {gt(10132)}</div>);
        let profitTooltip = (<div>{gt(19845)}: <b>{this.state.costValue?.toFixed(2)}</b><br/> {gt(19846)}: <b>{this.state.noTaxValue?.toFixed(2)}</b></div>);
        let profitValue = (this.state.noTaxValue - this.state.costValue)?.toFixed(0);

        let cateringSum = 0;
        if (this.props.data[this.catering_id] && this.props.viewPaysType != '1') {
            this.props.data[this.catering_id].data.forEach(((x) => {
                let y = x.split('\f');
                cateringSum += parseFloat(y[1]);
            }))
        }

        let dataRes = [
            [gt(10131), documentsTooltip, documents.today.sum + parseInt(cateringSum), documents, 'isSalesExtended'],
            [gt(19844), profitTooltip, profitValue, documents, 'isProfitExtended', true],
            [gt(10129), documentsAvgTooltip, documents.today.avg, documents, 'isAverageSaleExtended', true],
            [gt(10127), linesTooltip, lines.today.avg, lines, 'isAverageLinesExtended'],
            [gt(10117), productivityTooltip, productivity.today.productivity, productivity, 'isProductivityExtended'],
        ];

        let mobile = window.innerWidth <= 600;


        return mobile ?
            (<Collapse style={{ marginBottom: 20 }}>
                {dataRes.map(x => this.getMobilePanel(x[0], x[1], x[2], x[3], x[4], x[5]))}
            </Collapse>)
            :
            (<div style={{ marginBottom: "0" }}>
                {dataRes.map(x => this.getChartCard(x[0], x[1], x[2], x[3], x[4], x[5]))}
            </div>);

    }
}
export default ShortSummarySection;

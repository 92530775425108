/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import { Record } from "immutable";
import TableLineIcons from "../../components/TableLineIcons";
import { Input, InputNumber, DatePicker, Form, Button, Icon, Tooltip } from "antd";
import DateTimePicker from "../../components/DateTimePicker";
import moment, { months } from "moment";
import PageToolbar from "../../components/PageToolbar";
import { array } from "prop-types";
const { MonthPicker } = DatePicker

class AdvancesSetting extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            viewCopybtn: null 

        };

        this.id = "MIKDAMOT_TABLE";

        this.api = {
            get: "get_mikdamot_table",
            create_edit: "create_edit_mikdamot_table"
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }
    componentDidMount(){
        this.props.dataActions.genericDataGetEditWithParams(this.id,
            this.props.user.companyCode,
            this.props.user.token,
            {_year:moment().format("YYYY")},
            this.api.get);
    }
    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(19260);
    }

    onSave = (editedRecord) => {


        this.props.dataActions.genericDataJustSaveEdit(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit)   

    };

    onCopy =()=>{

            const {viewCopybtn} =this.state
            const {editedRecord} =this.props.data[this.id]

            let val = editedRecord["month"+viewCopybtn]

            this.props.dataActions.genericDataSetEdit(this.id, { 
                month1 : val, 
                month2 : val, 
                month3 : val, 
                month4 : val, 
                month5 : val, 
                month6 : val, 
                month7 : val, 
                month8 : val, 
                month9 : val, 
                month10 : val, 
                month11 : val, 
                month12 : val, 

            
            })








    };


    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 1000,
            margin: "auto"
        };
        let gt = this.getText;
        const {editedRecord,filters } = this.props.data[this.id]
        const mobile =window.innerWidth <=600
        return (

            <div style={divStyle}>
                
                <PageToolbar title={gt(19260)} actionButtons={[]} />
                <Form.Item label={gt(19263)}>
                            
                            <InputNumber
                                value={editedRecord._year}
                                max={2099}
                                min={1970}
                                precision={0}
                                onChange={(e) => {


                                    this.props.dataActions.genericDataGetEditWithParams(this.id,
                                        this.props.user.companyCode,
                                        this.props.user.token,
                                        {_year:e},
                                        this.api.get);

                                }}



                            />

                        </Form.Item>
                <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        
                    >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(x => {
                    return (
                        <Form.Item label={gt(10040 + x) } >
                            
                            <InputNumber
                                value={editedRecord["month" + x]}
                                max={99.99}
                                min={0}
                                precision={2}
                                onFocus={()=>{
                                    this.setState({viewCopybtn:x})

                                }}
                                onChange={(e) => {


                                    this.props.dataActions.genericDataSetEdit(this.id, { ["month" + x]: e })
                                    
                                }}



                            />
                            {
                                this.state.viewCopybtn == x ? <Tooltip title={gt(19268)}><Button style={{marginRight: 5}} onClick={this.onCopy}><Icon type={"copy"}/></Button></Tooltip> : ""
                            }
                            

                        </Form.Item>

                    )
                })}
                <Button type="primary" onClick={this.onSave} style={{ margin: 5 }}>{gt(15230)}</Button>
                </Form>
                


            </div>


        )

        
    }


}

export default AdvancesSetting;

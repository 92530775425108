/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Form, Button, Input, InputNumber, Switch, Select, Row, Col, message } from "antd";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import ModalCreateEditItem from "../../components/ModalCreateEditItem";
import TableIDs from "../../data/TableIDs";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";

const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {};

class UsingPoints extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.mobile = window.innerWidth <= 600;
        if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        }
    }

    refreshData() {
        this.props.dataActions.getUsingPoints(this.props.user.companyCode, this.props.user.token);
        this.props.dataActions.getItemTags(this.props.user.companyCode, this.props.user.token);

        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorCustomerTags,
            this.props.user.companyCode,
            this.props.user.token,
            'get_customer_tags_list');
    }

    componentDidMount() {
        // document.title = "Cash On Tab - שימוש בנקודות";
        this.refreshData();
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10217);
    }

    handleSaveChanges(e: Event) {
        e.preventDefault();
        if (!(this.props.data.usingPoints.maxDiscount > 0)) {
            message.error(this.getText(19648));
            return;
        }
        let usingPoints = this.props.data.usingPoints;
        if (!usingPoints.canUseDecimalPoints) this.props.dataActions.setJson('usingPoints', { canUseDecimalPoints: '0' })
        this.props.dataActions.saveUsingPoints(this.props.user.companyCode, this.props.user.token, this.props.data.usingPoints);
    }

    getFieldContent = (field, max) => {
        let value = this.props.data.usingPoints[field] ? this.props.data.usingPoints[field] : ""
        let onChange = (value) => {
            this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, { [field]: value }))
        }

        switch (field) {
            case 'validDays': case 'daysNotToUse': case 'maxDiscount': case 'minPointsForUse': case 'maxUsage': case 'pointValue':
                return (<InputNumber min={0} max={max} onChange={onChange} value={value} />);

            case 'canUseDecimalPoints':
                return (<Switch onChange={e => { onChange(e ? 1 : 0) }} checked={value == 1} />);

            case 'usageItemCode':
                //case 'usageItemName':
                // return (<Input onChange={e => { onChange(e.target.value) }} value={value} />);
                return (<SelectorItemClassic
                    {...this.props}
                    valueId={this.props.data.usingPoints['usageItemCode']}
                    valueName={this.props.data.usingPoints['usageItemName']}
                    onChange={(code, name) => {
                        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, { usageItemCode: code, usageItemName: name }))
                    }}
                    onSaveNewItem={(s) => {
                        console.log('usingPoints', s)
                        this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, { usageItemCode: s.mCode, usageItemName: s.mName }))
                    }}
                />)

            case 'notAllowedTags': case 'allowedTags':
                return (<GenericSelectorItemTags {...this.props} multi notReport value={value ? value.split(",") : []} onChange={e => {
                    const { notAllowedTags, allowedTags } = this.props.data.usingPoints;
                    let n = field === 'notAllowedTags' ? e : (notAllowedTags?.split(",") ?? []).filter(f => !e?.find(ff => ff == f));
                    let y = field === 'allowedTags' ? e : (allowedTags?.split(",") ?? []).filter(f => !e?.find(ff => ff == f));
                    this.props.dataActions.setData("usingPoints", Object.assign({}, this.props.data.usingPoints, { notAllowedTags: n?.join(",") ?? "", allowedTags: y?.join(",") ?? "" }))
                }} />)

            case 'requiredCustTag':
                return (<GenericSelectorCustTags {...this.props} {...{ value, onChange }} />)

        }
    }



    getFormItem = (label, help, field, max) => {
        return (<FormItem style={{ marginTop: 25 }} label={this.getText(label)} help={this.getText(help)} labelCol={{ span: this.mobile ? 24 : 8 }} wrapperCol={{ span: this.mobile ? 24 : 12 }} >
            {this.getFieldContent(field, max)}
        </FormItem>
        )
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

        };

        const toolbarActions: Array<Object> = [

            {
                buttonType: "refresh",
                buttonFunction: this.refreshData.bind(this)
            }
        ];


        return (
            <div style={divStyle}>
                <PageToolbar title={this.getText(11425)} actionButtons={toolbarActions} isOfModal={false} />

                <Row>
                    <Col span={this.mobile ? 24 : 12}>
                        {this.getFormItem(11426, 11436, 'validDays', 9999)}
                        {this.getFormItem(11427, 11437, 'daysNotToUse', 9999)}
                        {this.getFormItem(11428, 11438, 'maxDiscount', 100)}
                        {this.getFormItem(11429, 11439, 'minPointsForUse')}
                        {this.getFormItem(11430, 11440, 'canUseDecimalPoints')}
                    </Col>
                    <Col span={this.mobile ? 24 : 12}>
                        {this.getFormItem(11431, 11441, 'usageItemCode')}
                        {/* {this.getFormItem(11432, 11442, 'usageItemName')} */}
                        {this.getFormItem(11433, 11443, 'maxUsage', 100)}
                        {this.getFormItem(11434, 11444, 'pointValue')}
                        {this.getFormItem(11435, 11445, 'notAllowedTags')}
                        {this.getFormItem(16163, 16164, 'requiredCustTag')}
                        {this.getFormItem(19509, 19510, 'allowedTags')}
                    </Col>
                </Row>

                <div style={{ textAlign: 'center', marginTop: 25 }}>
                    <Button type="primary" onClick={this.handleSaveChanges.bind(this)} loading={this.props.ui.workInProgress} className={"form-button-save"} icon={"check"}>
                        {this.getText(11446)}
                    </Button>
                </div>
                <ModalCreateEditItem
                    {...this.props}
                    id={TableIDs.modalCreateEditItem}
                    // context={"ITEMS_TABLE"} context_api_get={"get_items_list"}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditItem)}
                    title={this.getText(10241)}
                />
            </div >
        );
    }
}

export default UsingPoints;
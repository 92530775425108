/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Tooltip, Icon, Card, Table, message, Row, Col } from "antd";
import moment from "moment";
import LangContext from "../../contextProvider/LangContext";
import "../Dashboard.css";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

class BranchPaysTableSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            lastRefreshTime: null,
            posToHour: '',
            mobileKeySum: '_sumDay'
        }

        this.id = "DASHBOARD_PAYMENTS_TABLE";
        if (props.data[this.id] === undefined) { props.data[this.id] = { dataset: [] } }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getParseInt = (str) => {
        return str ? parseInt(str) : 0
    }

    componentDidUpdate(p) {
        if (
            p.dashboardDate !== this.props.dashboardDate ||
            p.dashboardPos !== this.props.dashboardPos ||
            p.branchTablePerPos !== this.props.branchTablePerPos //||
            // p.dDateCheck !== this.props.dDateCheck
        ) {
            this.getDataPaysTable()
        }
    }

    getDataPaysTable = () => {

        const _date = this.props.dashboardDate ? moment(this.props.dashboardDate).format("YYYY-MM-DD") : null;
        const _posList = this.props.dashboardPos;
        const _tablePerPos = this.props.branchTablePerPos;

        let sumRowData = {
            key: "Sum",
            _name: this.getText(_tablePerPos ? 16356 : 17260),
            _sumDay: 0,
            _sumWeek: 0,
            _sumMonth: 0,
            _payTypes: []
        }

        let tableData = []

        const addSumCulc = (_oldSum, _newSum) => {
            let res = 0;
            if (parseFloat(_oldSum)) res += Math.round(_oldSum * 100) / 100;
            if (parseFloat(_newSum)) res += Math.round(_newSum * 100) / 100;
            return res;
        }

        const addDataRow = (_oldRow, _newData) => {
            let res = { ..._oldRow }

            let findPayType = res._payTypes.find(f => f.id === _newData._payType);

            let payTypeRow = findPayType ? findPayType : {
                id: _newData._payType,
                name: _newData._payDesc,
                day: 0,
                week: 0,
                month: 0
            }

            if (_newData._inDay == '1') {
                res._sumDay = addSumCulc(res._sumDay, _newData._sum)
                payTypeRow.day = addSumCulc(payTypeRow.day, _newData._sum)
            }
            if (_newData._inWeek == '1') {
                res._sumWeek = addSumCulc(res._sumWeek, _newData._sum)
                payTypeRow.week = addSumCulc(payTypeRow.week, _newData._sum)
            }
            if (_newData._inMonth == '1') {
                res._sumMonth = addSumCulc(res._sumMonth, _newData._sum)
                payTypeRow.month = addSumCulc(payTypeRow.month, _newData._sum)
            }

            if (findPayType) res._payTypes = res._payTypes.map(x => x.id === _newData._payType ? payTypeRow : x)
            else res._payTypes = [...res._payTypes, payTypeRow]

            return res;
        }


        this.sendAPI("get_dashboard_payments_table", "_date\f_posList\r" + _date + "\f" + _posList, (ob) => {
            let resData = ob.data ? ob.data.split("\r") : [];

            resData.forEach(x => {
                let y = x.split("\f");
                let r = {
                    _pos: y[0],
                    _posName: y[1],
                    _branch: y[2],
                    _branchName: y[3],
                    _payType: y[4],
                    _payDesc: y[5],
                    _sum: y[6],
                    _inDay: y[7],
                    _inWeek: y[8],
                    _inMonth: y[9]
                }

                sumRowData = addDataRow(sumRowData, r);

                let kyeToFind = _tablePerPos ? r._pos : r._branch;
                let tableFindRow = tableData.find(f => f.key === kyeToFind);

                let tableRowData = tableFindRow ? tableFindRow : {
                    key: kyeToFind,
                    _name: kyeToFind + " - " + (_tablePerPos ? r._posName : r._branchName),
                    _sumDay: 0,
                    _sumWeek: 0,
                    _sumMonth: 0,
                    _payTypes: []
                }

                tableRowData = addDataRow(tableRowData, r);

                if (tableFindRow) tableData = tableData.map(x => x.key === kyeToFind ? tableRowData : x)
                else tableData = [...tableData, tableRowData]
            })

            this.setState({ lastRefreshTime: moment().format('HH:mm:ss'/*'LTS'*/) }, () => {
                this.props.dataActions.setJson(this.id, { dataset: [sumRowData, ...tableData] })
            })
        })
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { message.error(e) });
    }

    createButton = (title, icon, onClick) => {
        return (<Tooltip title={this.getText(title)}>
            <Button className={"btn"} disabled={this.props.ui.workInProgress} onClick={onClick}>
                <Icon type={icon} style={{ fontSize: 16, padding: 4 }} />
            </Button>
        </Tooltip>)
    }

    renderCol = (text, record) => {
        return (<div style={{ textAlign: "right" }}>{(record.key == 'Sum') ? (<b>{text}</b>) : text}</div>)
    }



    render() {
        let mobile = window.innerWidth <= 600;

        let tPerPos = this.props.branchTablePerPos;

        const gt = this.getText;
        const render = this.renderCol;
        // const renderSum = (t, r) => this.renderCol(parseFloat(t) ? Math.round(parseFloat(t) * 100) / 100 : 0, r)
        // ביקשו לשנות 13/11/2023
        const renderSum = (t, r) => this.renderCol(Math.round(t) ?? 0, r)


        const nameTitle = gt(tPerPos ? 10081 : 17258);
        const { mobileKeySum } = this.state;
        const mobileKeySumDetail = mobileKeySum === "_sumDay" ? "day" : mobileKeySum === "_sumWeek" ? "week" : mobileKeySum === "_sumMonth" ? "month" : ""

        const columns = mobile ? [
            { title: nameTitle, dataIndex: '_name', key: '_name', width: '60%', render },
            { title: gt(17548), dataIndex: mobileKeySum, key: mobileKeySum, width: '40%', render: renderSum },
        ] : [
            { title: nameTitle, dataIndex: '_name', key: '_name', width: '37%', render },
            { title: gt(17549), dataIndex: '_sumDay', key: '_sumDay', width: '21%', render: renderSum },
            { title: gt(17550), dataIndex: '_sumWeek', key: '_sumWeek', width: '21%', render: renderSum },
            { title: gt(17551), dataIndex: '_sumMonth', key: '_sumMonth', width: '21%', render: renderSum },
        ];

        // const columnDetail = mobile ? [
        //     { dataIndex: 'name', key: 'name', width: '60%', render },
        //     { dataIndex: mobileKeySumDetail, key: mobileKeySumDetail, width: '40%', render },
        // ] : [
        //     { dataIndex: 'name', key: 'name', width: '40%', render },
        //     { dataIndex: 'day', key: 'day', width: '20%', render },
        //     { dataIndex: 'week', key: 'week', width: '20%', render },
        //     { dataIndex: 'month', key: 'month', width: '20%', render },
        // ];

        const getDetail = (record) => {
            return record._payTypes.map(x => {
                return (<Row>
                    <Col span={mobile ? 15 : 9}>{render(x.name, {})}</Col>
                    <Col span={mobile ? 9 : 5}>{renderSum(x[mobile ? mobileKeySumDetail : "day"], {})}</Col>
                    <Col span={mobile ? 0 : 5}>{renderSum(x.week, {})}</Col>
                    <Col span={mobile ? 0 : 5}>{renderSum(x.month, {})}</Col>
                </Row>)
            })
        }

        // (<Table columns={columnDetail} dataSource={record._payTypes} />)

        let mobileProps = {}

        if (mobile) {
            mobileProps = {
                activeTabKey: this.state.mobileKeySum,
                onTabChange: key => { this.setState({ mobileKeySum: key }) },
                tabList: [
                    { key: '_sumDay', tab: gt(16962) },
                    { key: '_sumWeek', tab: gt(16964) },
                    { key: '_sumMonth', tab: gt(16965) },
                ]
            }
        }



        return (<div style={{ marginBottom: "24px" }}>
            <Card
                bordered={false}
                title={(<div>
                    <div>
                        <span>{this.getText(tPerPos ? 17546 : 17547)}</span>
                        <span style={{ float: "left" }}>
                            {this.createButton(14057, "reload", this.getDataPaysTable)}
                            <span style={{ marginLeft: 4 }}>{this.state.lastRefreshTime}</span>
                        </span>
                    </div>
                </div>)}
                bodyStyle={{ padding: (window.innerWidth > 600) ? 24 : 5 }}
                headStyle={{ direction: "ltr" }}
                {...mobileProps}>
                <Table
                    columns={columns}
                    dataSource={this.props.data[this.id] ? this.props.data[this.id].dataset : []}
                    pagination={{ pageSize: 10 }}
                    expandedRowRender={getDetail}
                    bordered
                />
            </Card>
        </div>);
    }
}

export default BranchPaysTableSection;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import GenericModal from "../../components/GenericModal";
import GenericTable from "../../components/GenericTable";
import LangContext from "../../contextProvider/LangContext";
import { Button, Form, Input, InputNumber, Select, Row, Col, Checkbox } from "antd";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import TableLineIcons from "../../components/TableLineIcons";


class WorkingShifts extends Component {
    constructor(props: Props) {
        super(props);

        this.clearFilters = {
            _branchList: "",
            _employeeGroupList: "",
            _daysList: "",
            _hourActive: null,
            _words: "",
        }

        this.state = {
            modalFilter: false,
            filters: this.clearFilters,
        };

        this.id = "WORKING_SHIFTS";

        this.api = {
            get: "get_working_shifts",
            set: "set_working_shifts",
            delete: "delete_working_shifts",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list", zeroText: 17799 },
            { id: TableIDs.genericSelectorGroupEmployee, api: "get_employee_group_list", zeroText: 17800 },
        ];

        this.dayOfWeekList = [
            { code: '0', text: 17801 },
            { code: '1', text: 17802 },
            { code: '2', text: 17803 },
            { code: '3', text: 17804 },
            { code: '4', text: 17805 },
            { code: '5', text: 17806 },
            { code: '6', text: 17807 },
            { code: '7', text: 17808 },
        ]

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.forEach(x => {
            this.props.dataActions.genericSelectorRefreshDataset(
                x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(17784);
    }

    handleCancelEdit = () => {
        const { editing } = this.props.data[this.id];
        if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id)
        else this.props.dataActions.genericDataCancelEditing(this.id)
    }

    handleSaveEdit = () => {
        this.props.dataActions.genericDataSaveEdit(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.set, this.api.get);
    }

    editColRender = (text, record) => {
        return (<TableLineIcons
            onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
            onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get) }}
            deleteQuetion={{ title: this.getText(17809), okText: this.getText(17810), cancelText: this.getText(17811) }}
        />)
    }

    getGenericSelector = (value, onChange, index, multi) => {
        const { id, zeroText } = this.selectors[index]
        return multi ? (<GenericSelector {...this.props} {...{ value, onChange, id }} multi />)
            : (<GenericSelector {...this.props} {...{ value, onChange, id }} addFirstRow={{ code: '0', name: this.getText(zeroText) }} />)
    }

    getDayOfWeekSelector = (value, onChange, multi) => {
        return (<span>
            {multi ?
                (<Checkbox
                    checked={value.length === 7}
                    onChange={e => onChange(e.target.checked ? ['1', '2', '3', '4', '5', '6', '7'] : [])} style={{ marginLeft: 5 }}
                    indeterminate={value.length && value.length < 7}
                />)
                : ""}
            <Select {...{ value, onChange }} style={{ width: multi ? '90%' : '100%' }} mode={multi ? "multiple" : null} maxTagCount={7}>
                {this.dayOfWeekList.filter(f => !multi || f.code != '0').map(x => (<Select.Option value={x.code}>{this.getText(x.text)}</Select.Option>))}
            </Select>
        </span>)
    }

    getInputField = (field) => {
        const { editedRecord } = this.props.data[this.id];
        const value = editedRecord[field];
        const onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }

        switch (field) {
            case 'tName': return (<Input value={value} onChange={e => { onChange(e.target.value) }} />)
            case 'tBranchId': case 'tGroupId': return this.getGenericSelector(value, onChange, field === 'tBranchId' ? 0 : 1)
            case 'tDayOfWeek': return this.getDayOfWeekSelector(value, onChange)
            case 'tTimeStart': case 'tTimeEnd': return <DateTimePicker format="HH:mm:ss" {...{ value, onChange }} />
            case 'tExitGrace': case 'tLimitWorkers': return <InputNumber min={0} {...{ value, onChange }} />
        }
    }

    getFilterField = (field) => {
        const value = this.state.filters[field];
        const valueMulti = value ? value.split(",") : []
        const onChange = (e) => { this.setState({ filters: { ...this.state.filters, [field]: field === '_hourActive' ? e : e ? e.join(",") : "" } }) }

        switch (field) {
            case '_branchList': case '_employeeGroupList': return this.getGenericSelector(valueMulti, onChange, field === '_branchList' ? 0 : 1, true)
            case '_daysList': return this.getDayOfWeekSelector(valueMulti, onChange, true)
            case '_hourActive': return <DateTimePicker format="HH:mm:ss" {...{ value, onChange }} />
        }
    }

    handleFilter = () => {
        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, this.state.filters, this.api.get)
        })
    }

    handleClear = () => {
        this.setState({ filters: this.clearFilters }, this.handleFilter)
    }

    render() {
        const gt = this.getText;
        const sorter = true;
        const render = t => t;
        const renderBranch = (t, r) => r.tBranchId == '0' ? this.getText(this.selectors[0].zeroText) : t;
        const renderGroup = (t, r) => r.tGroupId == '0' ? this.getText(this.selectors[1].zeroText) : t;
        const renderDayOfWeek = t => this.getText(this.dayOfWeekList.find(f => f.code == (t ? t : '0')).text);
        const { editing } = this.props.data[this.id];

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const { _branchList, _employeeGroupList, _daysList, _hourActive, _words } = this.state.filters
        const clearDisabled = !_branchList && !_employeeGroupList && !_daysList && !_hourActive && !_words


        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                filters={this.state.filters}
                actionButtons={[
                    { buttonType: 'add', buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) } },
                    { buttonType: 'filter', buttonFunction: () => { this.setState({ modalFilter: true }) } },
                    { buttonType: 'clear', buttonFunction: this.handleClear, buttonDisabled: clearDisabled },
                ]}
                title={gt(17785)}
                searchBox
                valueSearch={this.state.filters._words}
                onChangeSearch={e => { this.setState({ filters: { ...this.state.filters, _words: e.target.value } }) }}
                columns={[
                    //'tID', 'tName', 'tBranchId', 'tBranchName', 'tGroupId', 'tGroupName', 'tDayOfWeek', 'tTimeStart', 'tTimeEnd', 'tExitGrace', 'tLimitWorkers'
                    { title: gt(17786), key: "tID", dataIndex: "tID", width: "6%", sorter, render },
                    { title: gt(17787), key: "tName", dataIndex: "tName", width: "20%", sorter, render },
                    { title: gt(17788), key: "tBranchName", dataIndex: "tBranchName", width: "13%", sorter, render: renderBranch },
                    { title: gt(17789), key: "tGroupName", dataIndex: "tGroupName", width: "13%", sorter, render: renderGroup },
                    { title: gt(17790), key: "tDayOfWeek", dataIndex: "tDayOfWeek", width: "10%", sorter, render: renderDayOfWeek },
                    { title: gt(17791), key: "tTimeStart", dataIndex: "tTimeStart", width: "8%", sorter, render },
                    { title: gt(17792), key: "tTimeEnd", dataIndex: "tTimeEnd", width: "8%", sorter, render },
                    { title: gt(17793), key: "tExitGrace", dataIndex: "tExitGrace", width: "8%", sorter, render },
                    { title: gt(17794), key: "tLimitWorkers", dataIndex: "tLimitWorkers", width: "8%", sorter, render },
                    { title: gt(17795), width: "6%", render: this.editColRender },
                ]}
                percentageToMobile={[30, 70]}
            />
            <GenericModal
                visible={editing > -1}
                onCancel={this.handleCancelEdit}
                title={gt(17796)}
                width={1000}
                footer={[
                    <Button type='primary' onClick={this.handleSaveEdit}>{gt(17797)}</Button>,
                    <Button onClick={this.handleCancelEdit}>{gt(17798)}</Button>,
                ]}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 16 : 24} style={{ paddingLeft: 30 }}>
                        <Form.Item label={gt(17787)}>{this.getInputField('tName')}</Form.Item>
                        <Form.Item label={gt(17788)}>{this.getInputField('tBranchId')}</Form.Item>
                        <Form.Item label={gt(17789)}>{this.getInputField('tGroupId')}</Form.Item>
                        <Form.Item label={gt(17790)}>{this.getInputField('tDayOfWeek')}</Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 8 : 24}>
                        <Form.Item label={gt(17791)}>{this.getInputField('tTimeStart')}</Form.Item>
                        <Form.Item label={gt(17792)}>{this.getInputField('tTimeEnd')}</Form.Item>
                        <Form.Item label={gt(17793)}>{this.getInputField('tExitGrace')}</Form.Item>
                        <Form.Item label={gt(17794)}>{this.getInputField('tLimitWorkers')}</Form.Item>
                    </Col>
                </Row>
            </GenericModal>
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                title={gt(17812)}
                width={600}
                footer={[
                    <Button type='primary' onClick={this.handleFilter}>{gt(17813)}</Button>,
                    <Button onClick={() => { this.setState({ filters: this.clearFilters }) }}>{gt(17819)}</Button>,
                    <Button onClick={() => { this.setState({ modalFilter: false }) }}>{gt(17814)}</Button>,
                ]}>
                <Form.Item label={gt(17815)}>{this.getFilterField('_branchList')}</Form.Item>
                <Form.Item label={gt(17816)}>{this.getFilterField('_employeeGroupList')}</Form.Item>
                <Form.Item label={gt(17817)}>{this.getFilterField('_daysList')}</Form.Item>
                <Form.Item label={gt(17818)}>{this.getFilterField('_hourActive')}</Form.Item>
            </GenericModal>
        </div >)
    }

}

export default WorkingShifts;
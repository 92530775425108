/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Popconfirm, Button, Form, DatePicker, Checkbox } from "antd";
import moment from "moment";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import { Job, User, OutputType, ProcessType } from '../../JmReact';

const { MonthPicker } = DatePicker;

class DebtEshcol extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            dateTo: null,
            includeOpenDoc: 1,
            includeCloseDoc: 0,
        };

        this.api = {
            get: "get_debt_eshcol_file_data",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16282);
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    printEshcol = () => {
        const {dateTo, includeOpenDoc, includeCloseDoc} = this.state;
        this.sendAPI(this.api.get, "_month\f_includeOpenDoc\f_includeCloseDoc\r" + dateTo + "\f" + includeOpenDoc+ "\f" + includeCloseDoc, (ob) => {
            let data = ob.data ? ob.data.split('\r').join('\r\n') : ''
            this.printFile([data], 'text/plain', 'kolbo.txt');
        })
    }

    printFile = (data, type, fileName) => {
        let blob = new Blob(data, { type });
        let anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = [type, anchor.download, anchor.href].join(':');
        anchor.click();
    }

    render() {
        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            maxWidth: 500,
        };

        let gt = this.getText;

        return (<div style={divStyle}>
            <PageToolbar title={gt(16283)} actionButtons={[]} />
            <Form.Item label={gt(16289)}>
                <MonthPicker
                    value={this.state.dateTo ? moment(this.state.dateTo, "MMYY") : null}
                    format={"MM/YYYY"}
                    onChange={(date) => { this.setState({ dateTo: date ? date.format("MMYY") : null }) }}
                />
            </Form.Item>
            <Form.Item>
                <Checkbox
                    checked={this.state.includeOpenDoc == 1}
                    onChange={e => { this.setState({ includeOpenDoc: e.target.checked ? 1 : 0 }) }}
                >{this.getText(19087)}</Checkbox>
            </Form.Item>
            <Form.Item>
                <Checkbox
                    checked={this.state.includeCloseDoc == 1}
                    onChange={e => { this.setState({ includeCloseDoc: e.target.checked ? 1 : 0 }) }}
                >{this.getText(19088)}</Checkbox>
            </Form.Item>
            <Popconfirm onConfirm={this.printEshcol} title={gt(16285)} okText={gt(16286)} cancelText={gt(16287)}>
                <Button type="primary">{gt(16284)}</Button>
            </Popconfirm>
        </div>)
    }

}

export default DebtEshcol;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Icon, Popconfirm, Input, InputNumber, Checkbox, message } from "antd";
import ResponsiveTable from "../../components/ResponsiveTable";
import GenericSelector from "../../components/GenericSelector";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import TableIDs from "../../data/TableIDs";

const topHeight = 340; // px
const rowHeight = 30;  // px

class PaymentTypes extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { height: window.innerHeight };

        this.api = {
            get: "get_payment_types_table",
            create_edit: "create_edit_payment_types_table",
            delete: "delete_payment_types_table",
        }

        this.id = 'PAYMENT_TYPES_TABLE';

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data['CURRENCY_SELECTOR'] === undefined) props.data['CURRENCY_SELECTOR'] = { ...props.data.GenericSelector };

        this.doOnResize = null;

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: this.addRow
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(14211);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericSelectorRefreshDataset(
            'CURRENCY_SELECTOR', this.props.user.companyCode, this.props.user.token, 'get_currency_list');
    }

    cancelEditing = () => {
        let editing = this.props.data[this.id].editing;
        if (editing == 0)
            this.props.dataActions.genericDataCancelInlineCreate(this.id);
        else
            this.props.dataActions.genericDataCancelEditing(this.id);
    }

    validPayId = (id) => {
        if (id < 11) return false;
        if (id > 98 && id < 200) return false;
        if (id > 220) return false;
        return true
    }

    editButtons = (t, r) => {
        let sData = this.props.data[this.id];
        if (sData.editing === r.key) {
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                    onClick={() => {
                        console.log("sData.editedRecord.eID: ", sData)
                        if (sData.editing == 0 && !this.validPayId(sData.editedRecord.eID)) {
                            message.error(this.getText(14229))
                        } else if (sData.editing == 0 && sData.editedRecord.eID >= 200 && sData.editedRecord.eID <= 220 && !sData.editedRecord.eForeignCurrencyID) {
                            message.error(this.getText(18575))
                        } else {
                            let sendData = '';
                            let params = '';
                            sData.headers.forEach((x) => {
                                sendData += x + "\f";
                                params += sData.editedRecord[x] + "\f";
                            })
                            sendData += "isNew\r" + params + (sData.editing == 0 ? '1' : '0');
                            this.sendAPI(this.api.create_edit, sendData, (ob) => {
                                if (ob.data === '0') {
                                    message.error(this.getText(14228))
                                } else {
                                    this.refreshData();
                                }
                            })
                        }

                    }} />
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"} onClick={this.cancelEditing} />
            </span>)
        } else {
            return (<span>
                <Icon
                    style={{ fontSize: 16 }}
                    className={"clickable margin-4"}
                    type={"edit"}
                    onClick={() => {
                        this.props.dataActions.genericDataStartEditing(this.id, r.key, r.index);
                    }} />
                {r.key > 10 && r.key < 99 ? (
                    <Popconfirm title={this.getText(11835)}
                        onConfirm={() => {
                            this.props.dataActions.genericDataDeleteRow(
                                this.id,
                                r.key,
                                this.props.user.companyCode,
                                this.props.user.token,
                                this.api.delete,
                                this.api.get);
                        }}
                        okText={this.getText(11836)} cancelText={this.getText(11837)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                    </Popconfirm>
                ) : ""}
            </span>)
        }
    }

    inputEdit = (record, field, number) => {
        let d = this.props.data[this.id];
        let isEdit = d.editing === record.key;
        if (
            field === 'eSwiichWalletId' &&
            ((!isEdit && record.eIsForSwiich != '1') || (isEdit && d.editedRecord.eIsForSwiich != '1'))
        ) {
            return '';
        }

        if (d.editing != 0 && field == 'eID') return record[field];

        let value = d.editedRecord[field];
        let onChange = (e) => {
            this.props.dataActions.genericDataSetEdit(this.id, { [field]: number ? e : e.target.value })
        }
        if (isEdit) {
            return number ?
                (<InputNumber value={value} onChange={onChange} style={{ width: '100%' }} />) :
                (<Input value={value} onChange={onChange} />)
        } else {
            return record[field];
        }
    }

    checkboxEdit = (record, field) => {
        let d = this.props.data[this.id];
        let isEdit = d.editing === record.key;
        return (<Checkbox
            checked={isEdit ? d.editedRecord[field] == '1' : record[field] == '1'}
            disabled={!isEdit}
            onChange={(e) => {
                let toUpdate = { [field]: e.target.checked ? '1' : '0' }
                if (field === 'eIsForSwiich') toUpdate = { ...toUpdate, eSwiichWalletId: 0 }
                if (isEdit) this.props.dataActions.genericDataSetEdit(this.id, toUpdate)
            }} />)
    }

    currencySelector = (record, field) => {
        let d = this.props.data[this.id];
        let isEdit = d.editing === record.key;
        return (<GenericSelector
            id={'CURRENCY_SELECTOR'}
            value={isEdit ? d.editedRecord[field] : record[field]}
            onChange={(data) => {
                if (isEdit) this.props.dataActions.genericDataSetEdit(this.id, { [field]: data })
            }}
            disabled={!isEdit}
            user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
        />)

    }

    colObj = (title, key, width, type) => {
        let render = (t, r) => t;

        switch (type) {
            case 'input': render = (t, r) => this.inputEdit(r, key); break;
            case 'inputNumber': render = (t, r) => this.inputEdit(r, key, true); break;
            case 'checkbox': render = (t, r) => this.checkboxEdit(r, key); break;
            case 'currency': render = (t, r) => this.currencySelector(r, key); break;
        }

        return {
            title: this.getText(title),
            dataIndex: key,
            key: key,
            width: width,
            render: render
        }
    }

    addRow = () => {
        if (this.props.data[this.id].editing === -1) {
            // this.refreshData();
            this.props.dataActions.genericDataStartInlineCreate(this.id);
            this.props.dataActions.genericDataSetEdit(this.id, { eSwiichWalletId: 0 })
        }
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    render() {
        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };

        let numOfRowsByHeight = parseInt((window.innerHeight - topHeight > rowHeight)
            ? (window.innerHeight - topHeight) / rowHeight
            : 1);

        let swiitchModule = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset ?
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '502')
            : false;

        let columns = [
            this.colObj(14212, "eID", '6%', 'inputNumber'),
            this.colObj(14213, "eName", '14%', 'input'),
            this.colObj(14214, "eAccount", '10%', 'input'),
            this.colObj(14215, "eSaveReference", '5%', 'checkbox'),
            this.colObj(14216, "eIsReferenceRequired", '5%', 'checkbox'),
            this.colObj(14217, "eSaveDate", '5%', 'checkbox'),
            this.colObj(14218, "eIsDateRequired", '4%', 'checkbox'),
            this.colObj(14219, "eForeignCurrencyID", '16%', 'currency'),
            this.colObj(14220, "eIsGroupable", '4%', 'checkbox'),
            this.colObj(14221, "eTaxCode", '7%', 'inputNumber'),
            this.colObj(14222, "eIsForVirtualShop", '4%', 'checkbox'),
            this.colObj(18270, "eIsForOpenDrawer", '4%', 'checkbox'),
        ]

        if (swiitchModule) {
            columns = [
                ...columns,
                this.colObj(14224, "eIsForSwiich", '4%', 'checkbox'),
                this.colObj(14223, "eSwiichWalletId", '7%', 'inputNumber'),
            ]
        }

        columns = [
            ...columns,
            { title: this.getText(14225), width: '5%', render: this.editButtons },
        ]

        return (
            <div style={divStyle}>
                <ResponsiveTable
                    id={'ptTable'}
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[20, 40, 40]}
                    title={this.getText(14226)}
                    actionButtons={this.actionButtons}
                    scroll={{ y: (window.innerHeight - topHeight) }}
                    pagination={false}
                    // pagination={{ pageSize: numOfRowsByHeight }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
            </div>
        )

    }
}
export default PaymentTypes;
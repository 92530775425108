/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Input, Icon } from "antd";
import "../App.css";
import Jax from "../Jax/jax.es6.module";

const Search = Input.Search;

type DataTypeOrPickProps = {
    defaultDataId: string,
    onChange: Function,
    data: Array<string>,
    showDialog: Function,
    hideDialog: Function,
    disabled?: boolean,
    placeholder: string,
    value: { data: string, dataName: string },
    showDescription?: boolean,
    doFocus: boolean,
    onValidated?: Function
};

type State = {
    icon: string,
};

class DataTypeOrPick extends Component<DataTypeOrPickProps, State> {
    ref: any;
    keyboardTimer: any;

    // constructor (props: DataTypeOrPickProps) {
    //         super(props);
    //         // this.ref = React.createRef();
    //         // this.keyboardTimer = null;
    // }
    //
    // componentDidUpdate (prev: DataTypeOrPickProps) {
    //     // if (this.props.value.data && this.props.value.data !== prev.value.data) {
    //     //         //     this.validateInput(this.props.value.data);
    //     //         // }
    //     // if (this.props.doFocus && this.props.doFocus !== prev.doFocus) {
    //     //     if (this.ref) {
    //     //         this.ref.current.focus();
    //     //     }
    //     // }
    // }

    handleClick(value: string, e) {
        console.log("xxxx enter press: ", this.props.disableEnterPress);
        if (!this.props.disableEnterPress) {
            console.log("xxxx enter NOT pressed");
            this.props.showDialog(value, this.props.data, this.handleChange.bind(this));
        }
    }

    handleChange(state: { data: string, dataName: string }) {
        if (state.data !== undefined) {
            let data = {
                data: state.data ? state.data : "",
                dataName: state.dataName ? state.dataName : ""
            };
            this.props.onChange(data);

        }
        // this.props.hideDialog();
    }

    validateInput(value: string) {
        if (value.length > 0) {
            let found = false;
            let index = 0;
            let row = [value, ""];
            let icon;

            this.setState(Jax.set(this.state, "icon", "loading"));

            window.console.log("About to search...");
            while (!found && index < this.props.data.length) {
              
                if (("\f" + this.props.data[index] + "\f").indexOf("\f" + value + "\f") > -1 ) {
                    found = true;
                    row = this.props.data[index].split("\f");
                }
                index++;
            }

            if (found) {
                icon = "check";
            } else {
                icon = "close";
            }

            this.setState(Jax.set(this.state, "icon", icon));

            return ({
                data: row[0],
                dataName: row[1],
            });
        } else {
            this.setState(Jax.set(this.state, "icon", "form"));

            return ({
                data: "",
                dataName: "",
            });
        }
    }

    handleOnBlur(val: any) {
        let value = val.target.value || "";
        let data = this.validateInput(value);
        if (this.props.onValidated) {
            this.props.onValidated(data);
        } else {
            this.handleChange(data);
        }
    }

    onChange(val: any) {
        let value = val.target.value || "";
        let data = /*this.validateInput(value)*/{ data: value, dataName: "" };
        this.handleChange(data);
        // let that = this;
        // function handleChangeAfterTime () {
        //     let data = that.validateInput(value);
        //     that.handleChange(data);
        //     if (that.keyboardTimer) {
        //         clearTimeout(that.keyboardTimer);
        //     }
        //     window.console.log("Time!");
        // }
        // if (this.keyboardTimer) {
        //     clearTimeout(this.keyboardTimer);
        // }
        // this.keyboardTimer = setTimeout(handleChangeAfterTime, 500);
    }

    render() {
        return (<div>
            <Search
                id={this.props.searchId}
                autoFocus={this.props.doFocus}
                tabIndex={this.props.tabIndex}
                ref={this.ref}
                placeholder={this.props.placeholder}
                enterButton={<Icon type={"ellipsis"} />}
                size="large"
                onSearch={(value, e) => this.handleClick(value, e)}
                style={{ textAlign: 'end' }}
                //onSearch={value => value}
                /*
                value={(Jax.get(this.props.value, "data", ""))
                    ? Jax.get(this.props.value, "data", "") + " - " + Jax.get(this.props.value, "dataName", "")
                    : null}
                */
                value={Jax.get(this.props.value, "data", "")}
                defaultValue={this.props.defaultDataId}
                onChange={this.onChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
                prefix={<Icon type={Jax.get(this.state || {}, "icon", "form")} style={{ color: 'rgba(0,0,0,1)' }} />}
                onKeyDown={(this.props.onKeyDown) ? this.props.onKeyDown : () => { }}
            //suffix={<Icon type={Jax.get(this.state || {}, "icon", "form")} style={{ color: 'rgba(0,0,0,1)' }} />}
            />
            <div style={{ fontWeight: "bold", fontSize: "0.9em" }}>
                {this.props.showDescription ? Jax.get(this.props.value, "dataName", "") : ""}</div>

        </div>);
    }
}

export default DataTypeOrPick;
/* @flow */
/*jshint esversion: 6 */
import { Checkbox, Form, Row, Col, Button, Radio } from "antd";
import React, { Component } from "react";
import "../../App.css";
import GenericSelector from "../../components/GenericSelector";
//import ReportTypeButton from "../../components/ReportTypeButton";
import DateTimePicker from "../../components/DateTimePicker";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import * as XLSX from 'xlsx/xlsx.mjs';
import ReportFavoriteSave from "../../components/ReportFavoriteSave";
import moment from "moment";


class ReportEmployeesPerDates extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _fromDate: null,
            _toDate: null,
            reportName: "ReportEmployeesPerDates.xlsx"
        };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(19286);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    generateReport = () => {
        const { _fromDate, _toDate, reportName } = this.getReportParams();

        let dataSend = "_fromDate\f_toDate\r" + _fromDate + "\f" + _toDate;

        this.sendAPI("get_sum_employees_per_dates", dataSend, (ob) => {
            let fullData = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return {
                    _date: y[0],
                    _count: y[1],
                    _names: y[2],
                }
            }) : [];

            let excelData = {
                A1: { t: "s", v: "תאריך" },
                B1: { t: "s", v: "כמות" },
                C1: { t: "s", v: "שמות" },
            }

            fullData.forEach((x, i) => {
                excelData = {
                    ...excelData,
                    ["A" + (i + 2)]: { t: "s", v: moment(x._date).format("DD/MM/YYYY") },
                    ["B" + (i + 2)]: { t: "n", v: x._count, z: "#,##0" },
                    ["C" + (i + 2)]: { t: "s", v: x._names },
                }
            });

            excelData = {
                ...excelData,
                ['!cols']: [{ width: 11 },{ width: 7 },{ width: 60 }],
                ["!ref"]: "A1:C" + (fullData.length + 1),
            }

            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            
            const wb = { Sheets: { data: excelData }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const blob = new Blob([excelBuffer], { type: fileType });
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = reportName;
            a.click();
        })

    }


    sendAPI = (script, data, sCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, (e) => { console.error(e) });
    };

    getReportParams = () => {
        const { _fromDate, _toDate, reportName } = this.state;

        return {
            _fromDate: (_fromDate ?? ""),
            _toDate: (_toDate ?? ""),
            reportName
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 700,
            margin: 'auto'
        };

        const { _fromDate, _toDate } = this.state;

        return (<div style={divStyle}>
            <PageToolbar title={gt(18366)} actionButtons={[]} />

            <Row>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18367)}>
                        <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_fromDate}
                            onChange={e => { this.setState({ _fromDate: e }) }}
                            maxDate={_toDate}
                        />
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(18368)}>
                        <DateTimePicker
                            format="DD/MM/YYYY"
                            value={_toDate}
                            onChange={e => { this.setState({ _toDate: e }) }}
                            minDate={_fromDate}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item key={"submit"}>
                <Button type={"primary"} loading={this.props.ui.workInProgress} onClick={this.generateReport}>{gt(18372)}</Button>
                <ReportFavoriteSave
                    {...this.props}
                    params={this.getReportParams()}
                    datesToFavorites={[
                        { field: '_fromDate', label: gt(18367) },
                        { field: '_toDate', label: gt(18368) },
                    ]}
                />
            </Form.Item>
        </div>)
    }

}

export default ReportEmployeesPerDates;
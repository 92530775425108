/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { Upload, Icon, Modal, message } from "antd";
import { Job, User, OutputType, ProcessType, JobResponse } from '../JmReact';
import LangContext from "../contextProvider/LangContext";
import ImageSlicer from "./ImageSlicer";

type formEntPoint = {
  token: String,
  companyCode: String,
  entpoint: String,
  success: String,
  error: String,
}

type Props = {
  url: string,
  imageType: string,
  boxSize: number,
  token: string,
  companyCode: string,
  onUploadComplete: Function,
  formEntPoint: formEntPoint,

  uploadScriptName: string,
  isShared: Boolean,
};

type FileItem = { uid: number, name: string, status: string, url: string };

type State = {
  previewVisible: boolean,
  previewImage: string,
  fileList: Array<FileItem>
};

export class PictureView extends React.Component<Props, State> {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [/*{
        uid: -1,
        name: 'xxx.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    }*/],
    oldSrc: null,
    fileBlob: {},
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file: Object) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleChange = (e) => {
    this.setState({ fileList: e.fileList }, () => {
      if (e.file.status !== "removed") {
        this.handleUpload(e.file)
      }
    });
  };

  static contextType = LangContext;

  getText = (id) => {
    if (this.props.getText) return this.props.getText(id)
    else return this.context && this.context.get(id) ? this.context.get(id) : '[' + id + ']'
  }

  /*
  getBase64(file: File, callback: Function) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(file);
  }
  
  onUpload(uploadedData: Object) {
      window.console.log(uploadedData);
      this.getBase64(uploadedData.file, this.handleUpload.bind(this));
  }
  */

  getBase64 = (file, callback) => {
    console.log('yuda1', file)
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(file);
  }

  handleUpload = (fileBlob) => {
    const { fileList } = this.state;
    this.getBase64(fileBlob/*fileList[0]*/,  // info.file.originFileObj 
      fileUrl => {
        this.setState({ fileUrl, loading: false, fileBlob: fileBlob },
          () => {
            if (this.props.aspect) {
              this.setState({ oldSrc: fileUrl });
            } else {
              this.savePicture(fileUrl);
            }
            // let formEntPoint = this.props.formEntPoint;
            // let token = formEntPoint ? formEntPoint.token : this.props.user.token;
            // let companyCode = formEntPoint ? formEntPoint.companyCode : this.props.user.companyCode;
            // let entpoint = formEntPoint ? formEntPoint.entpoint : "CashOnTab";

            // let user = new User(token, companyCode);
            // let job = new Job(user, this.props.uploadScriptName || "upload_image",
            //   OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC, (entpoint === "CashOnTab") ? false : true);

            // //job.setInput(fileUrl.split(',')[1]);
            // //job.setSection("imageName", fileList[0].name.split('.')[0]);
            // job.setSection("imageName", fileBlob.name.split('.')[0]);
            // //Job.setSection("imageExtension", fileList[0].name.split('.')[1]);
            // job.setSection("imageExt", fileBlob.name.split('.')[1]);
            // job.setSection("imageType", this.props.imageType);
            // //job.setSection("fileData", fileUrl.split(',')[1]);
            // job.setSection("imageMeta", fileUrl.split(',')[0]);
            // job.setSection("imageData", fileUrl.split(',')[1]);
            // //job.setSection("imageURL", "");

            // console.log("imageMeta", fileUrl.split(',')[0]);
            // console.log("imageData", fileUrl.split(',')[1]);

            // job.send("/cgi-bin/" + entpoint, this.successCallback, this.errorCallback);
          }
        )
      });
  }

  savePicture = (fileUrl) => {
    let fileBlob = this.state.fileBlob;

    let formEntPoint = this.props.formEntPoint;
    let token = formEntPoint ? formEntPoint.token : this.props.user.token;
    let companyCode = formEntPoint ? formEntPoint.companyCode : this.props.user.companyCode;
    let entpoint = formEntPoint ? formEntPoint.entpoint : "CashOnTab";

    let user = new User(token, companyCode);
    let job = new Job(user, this.props.uploadScriptName || "upload_image",
      OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC, (entpoint === "CashOnTab") ? false : true);

    //job.setInput(fileUrl.split(',')[1]);
    //job.setSection("imageName", fileList[0].name.split('.')[0]);
    job.setSection("imageName", fileBlob.name.split('.')[0]);
    //Job.setSection("imageExtension", fileList[0].name.split('.')[1]);
    job.setSection("imageExt", fileBlob.name.split('.').slice(-1)[0]);
    job.setSection("imageType", this.props.imageType);
    //job.setSection("fileData", fileUrl.split(',')[1]);
    job.setSection("imageMeta", fileUrl.split(',')[0]);
    job.setSection("imageData", fileUrl.split(',')[1]);
    //job.setSection("imageURL", "");

    console.log("imageMeta", fileUrl.split(',')[0]);
    console.log("imageData", fileUrl.split(',')[1]);

    job.send("/cgi-bin/" + entpoint, this.successCallback, this.errorCallback);
  }

  getOldUrl = () => {
    let companyCode = (this.props.isShared && this.props.isShared === true) ? 'shared'
      : this.props.formEntPoint ? this.props.formEntPoint.companyCode : this.props.user.companyCode;

    let origin = window.location.origin;
    const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
    const baseUrl = !isDev ? origin : "http://147.235.163.248";

    let url = this.props.url && this.props.url !== '' ?
      baseUrl + "/uploads/" + companyCode + "/" + this.props.url
      : null;

    this.setState({ fileList: url ? [{ uid: -1, name: "image.png", status: "done", url: url }] : [] });
  }

  componentDidMount() {
    this.getOldUrl();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.url !== this.props.url) {
      this.getOldUrl();
    }
    if (!prevProps.googleImage && this.props.googleImage) {
      this.setState({
        oldSrc: this.props.googleImage.base64,
        fileBlob: this.props.googleImage.fileBlob,

      })
    }
  }

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (<div><Icon type="plus" /><div className="ant-upload-text">Upload</div></div>);

    const onRemove = (file) => {
      this.setState((state) => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        this.props.onRemove();

        return { fileList: newFileList, };
      });
    }

    const props = {
      onRemove: onRemove,
      beforeUpload: (file) => {
        console.log("file: ", file);
        this.setState(
          state => ({ fileList: [...state.fileList, file], })
        );
        return false;
      },
      fileList,
    };

    let imgURL = this.state.fileList[0] ? this.state.fileList[0].url : "";

    return (this.props.readOnly) ?
      (<img style={{ width: '50%' }} src={imgURL} />) :
      (<div className="clearfix">
        <Upload
          {...props}
          action=""
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        //customRequest={this.onUpload.bind(this)}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
          {/* {this.props.aspect} */}
        </Modal>
        <ImageSlicer
          aspect={this.props.aspect}
          src={this.state.oldSrc}
          close={() => { this.setState({ oldSrc: null }, () => { onRemove(this.state.fileBlob) }) }}
          title={this.props.slicerTitle ? this.props.slicerTitle : this.getText(13594)}
          textButton={this.props.slicerButton ? this.props.slicerButton : this.getText(13595)}
          newImg={(e) => { this.setState({ oldSrc: null }, () => { this.savePicture(e) }) }}
          getText={this.props.getText}
        />
      </div>
      );
  }

  // TODO: imp more informative error response
  successCallback = (ob: Object) => {
    console.log("success: ", ob.data);
    let companyCode = this.props.formEntPoint ? this.props.formEntPoint.companyCode : this.props.user.companyCode;

    // NEW
    companyCode = (this.props.isShared && this.props.isShared === true) ? 'shared' : companyCode;

    let success = this.props.formEntPoint ? this.props.formEntPoint.success : this.getText(11336);
    const baseUrl = (window.location.hostname == "localhost") ? "http://147.235.163.248" : window.location.hostname;
    this.props.onChange(ob.data);
    this.props.onUploadedSuccess(ob.data, "https://" + baseUrl + "/uploads/" + companyCode + "/" + ob.data);
    message.success(success);
  }
  errorCallback = (error: any) => {
    let mError = this.props.formEntPoint ? this.props.formEntPoint.error : this.getText(11337);
    console.log("error: ", error);
    this.props.onUploadedFailed(error);
    message.error(mError);
  }
}

export default PictureView;

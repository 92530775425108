/* @flow */
/*jshint esversion: 6 */
/*jslint es6*/
import App from "./App";
import { Container } from "flux/utils";
import UserStore from "./data/UserStore";
import UiStore from "./data/UiStore";
import DataStore from "./data/DataStore";
import FormsStore from "./data/FormsStore";
import Actions from "./data/Actions";
import ActionQueue from "./data/ActionQueue";
//import { formatDiagnosticsWithColorAndContext } from "typescript";
//import ActionTypes from "./data/ActionTypes";

function getStores() {
    return [
        UserStore,
        UiStore,
        DataStore,
        FormsStore
    ];
}

function getState() {
    //console.log("\nUI-STORE: " + JSON.stringify(UiStore.getState()));
    //console.log("\nUSER-STORE" + JSON.stringify(UserStore.getState()));
    return {
        User: UserStore.getState(),
        justLoginUser: Actions.userJustLogin,
        loginUser: Actions.userLogin,
        logoutUser: Actions.userLogout,
        loginRefresh: Actions.userRefresh,
        renewUser: Actions.userRenew,
        getUserForRenew: Actions.userGetUserForRenew,
        setUserForRenew: Actions.userSetUserForRenew,
        UserActions: {
            companyNameLoaded: Actions.companyNameLoaded,
        },

        Ui: UiStore.getState(),
        toggleMainMenu: Actions.toggleMainMenu,
        savePreviousFormValues: Actions.saveFormValues,

        UiActions: {
            toggleRenewModal: Actions.toggleRenewModal,
            toggleConfirmationModal: Actions.toggleConfirmationModal,
            showModal: Actions.showModal,
            hideModal: Actions.hideModal,
            hideModalByName: Actions.hideModalByName,
            setModalUIData: Actions.setModalUIData,
            onClickCreateBtn: Actions.onClickCreateBtn,
            onPressEnter: Actions.onPressEnter,
            getCompsData: Actions.getCompsData,
            getCompData: Actions.getCompData,
            sendCompData: Actions.sendCompData,
            uiShowItemPickerDialog: Actions.uiShowItemPickerDialog,
            uiHideItemPickerDialog: Actions.uiHideItemPickerDialog,
            uiShowCustomerPickerDialog: Actions.uiShowCustomerPickerDialog,
            uiHideCustomerPickerDialog: Actions.uiHideCustomerPickerDialog,
            setIsEditFatherItem: Actions.setIsEditFatherItem,
            raiseWorkInProgressFlag: Actions.raiseWorkInProgressFlag,
            lowerWorkInProgressFlag: Actions.lowerWorkInProgressFlag,
            setJsonUI: Actions.setJsonUI,
            overrideJsonUI: Actions.overrideJsonUI,
        },

        dataActions: {
            setData: Actions.setData,
            getUsingPoints: Actions.getUsingPoints,
            saveUsingPoints: Actions.saveUsingPoints,
            getItemTags: Actions.getItemTags,
            getItemCodes: Actions.getItemCodes,
            getCustomerTags: Actions.getCustomerTags,
            getEarningPoints: Actions.getEarningPoints,
            earningPointsCancelEditing: Actions.earningPointsCancelEditing,
            earningPointsDeleteRow: Actions.earningPointsDeleteRow,
            earningPointsSaveEdit: Actions.earningPointsSaveEdit,
            earningPointsSetEdit: Actions.earningPointsSetEdit,
            earningPointsStartEditing: Actions.earningPointsStartEditing,

            // selectorUpdateSalesalerts: Actions.selectorUpdateSalesAlerts,
            selectorUpdateSuppliers: Actions.selectorUpdateSuppliers,
            selectorUpdatePrices: Actions.selectorUpdatePrices,
            selectorUpdateTaxType: Actions.selectorUpdateTaxType,
            selectorPickCustomerFields: Actions.selectorPickCustomerFields,

            // selectorPickSalesalerts: Actions.selectorPickSalesalerts,
            selectorPickSuppliers: Actions.selectorPickSuppliers,
            selectorPickPrices: Actions.selectorPickPrices,
            selectorPickTaxType: Actions.selectorPickTaxType,
            earningPointsStartNew: Actions.earningPointsStartNew,
            earningPointsCancelNew: Actions.earningPointsCancelNew,
            earningPointsSetFilter: Actions.earningPointsSetFilter,
            earningPointsShowFilters: Actions.earningPointsShowFilters,
            earningPointsHideFilters: Actions.earningPointsHideFilters,
            earningPointsClearFilters: Actions.earningPointsClearFilters,

            bargainTypesGetRequested: Actions.bargainTypesGetRequested,

            // GENERIC ACTIONS
            genericDataGetRequested: Actions.genericDataGetRequested,
            genericDataGetEditRequested: Actions.genericDataGetEditRequested,
            genericDataGetSucceeded: Actions.genericDataGetSucceeded,
            genericDataGetFailed: Actions.genericDataGetFailed,
            genericDataStartEditing: Actions.genericDataStartEditing,
            genericDataStartEditingPath: Actions.genericDataStartEditingPath,
            genericDataStartNew: Actions.genericDataStartNew,
            genericDataCancelNew: Actions.genericDataCancelNew,
            genericDataCancelEditing: Actions.genericDataCancelEditing,
            genericDataSaveEdit: Actions.genericDataSaveEdit,
            genericDataJustSaveEdit: Actions.genericDataJustSaveEdit,
            genericDataJustSaveEditNext: Actions.genericDataJustSaveEditNext,
            genericDataSetEdit: Actions.genericDataSetEdit,
            genericDataDeleteRow: Actions.genericDataDeleteRow,
            genericDataDeleteRowWithParams: Actions.genericDataDeleteRowWithParams,
            genericDataDeleteRowSucceeded: Actions.genericDataDeleteRowSucceeded,
            genericDataDeleteRowFailed: Actions.genericDataDeleteRowFailed,
            genericDataSaveEditSucceeded: Actions.genericDataSaveEditSucceeded,
            genericDataSaveEditFailed: Actions.genericDataSaveEditFailed,
            genericDataSetFilter: Actions.genericDataSetFilter,
            genericDataClearFilters: Actions.genericDataClearFilters,
            genericDataShowFilters: Actions.genericDataShowFilters,
            genericDataHideFilters: Actions.genericDataHideFilters,
            genericDataStartInlineCreate: Actions.genericDataStartInlineCreate,
            genericDataCancelInlineCreate: Actions.genericDataCancelInlineCreate,
            genericDataGetWithParams: Actions.genericDataGetWithParams,
            genericDataGetEditWithParams: Actions.genericDataGetEditWithParams,
            genericDataSaveEditMulti: Actions.genericDataSaveEditMulti,
            genericDataJustSaveEditMulti: Actions.genericDataJustSaveEditMulti,
            genericDataSaveEditWithParams: Actions.genericDataSaveEditWithParams,
            genericDataSaveEditWithParamsNext: Actions.genericDataSaveEditWithParamsNext,
            genericDataJustSaveEditWithParams: Actions.genericDataJustSaveEditWithParams,

            genericSelectorPick: Actions.genericSelectorPick,
            genericSelectorPickUpdate: Actions.genericSelectorPickUpdate,
            genericSelectorRefreshDataset: Actions.genericSelectorRefreshDataset,
            genericMultiSelectorRefreshDataset: Actions.genericMultiSelectorRefreshDataset,

            dataTableAddRow: Actions.dataTableAddRow,
            dataTableSetRow: Actions.dataTableSetRow,
            dataTableDeleteRow: Actions.dataTableDeleteRow,

            genericJsonGet: Actions.genericJsonGet,
            genericJsonGetAndEdit: Actions.genericJsonGetAndEdit,
            genericJsonSet: Actions.genericJsonSet,
            genericJsonSetAndReload: Actions.genericJsonSetAndReload,

            setNewDataState: Actions.setNewDataState,
            genericNetworkRequest: Actions.genericNetworkRequest,

            networkFetchData: Actions.networkFetchData,
            networkFetchJson: Actions.networkFetchJson,
            networkPostData: Actions.networkPostData,
            networkPostJson: Actions.networkPostJson,
            networkPostJsonAsData: Actions.networkPostJsonAsData,
            networkFetch: Actions.networkFetch,
            networkPost: Actions.networkPost,
            networkPostAndThenFetchData: Actions.networkPostAndThenFetchData,
            networkPostAndThenFetchJson: Actions.networkPostAndThenFetchJson,
            networkPostDataAndThenFetchJson: Actions.networkPostDataAndThenFetchJson,
            networkPostJsonAndThenFetchData: Actions.networkPostJsonAndThenFetchData,
            generateReport: Actions.generateReport,
            generateReportSuccess: Actions.generateReportSuccess,
            imageUploadRequested: Actions.imageUploadRequested,

            setDefault: Actions.setDefault,
            setActive: Actions.setActive,
            setJson: Actions.setJson,
            overrideJson: Actions.overrideJson,

            clearDataStore: Actions.clearDataStore,
        },

        formsActions: {
            setJson: Actions.setJson,
            overrideJson: Actions.overrideJson,
        },

        data: DataStore.getState(),
        forms: FormsStore.getState(),

        ActionQueue: ActionQueue
    }
}

export default Container.createFunctional(App, getStores, getState);

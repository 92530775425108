import * as React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Input } from 'antd';

class InputNumeric extends React.Component {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        //console.log("sss: click: ", e.target.value);
        let { value } = e.target;

        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            this.props.onChange(e);
        }
    }

    render() {
        const { value } = this.props;

        return (
            <Input
                {...this.props}
                onChange={this.onChange}
                onBlur={this.onBlur}
                placeholder=""
                maxLength="25"
            />
        );
    }
}

export default InputNumeric;


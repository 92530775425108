/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Select, Input, Icon, Popconfirm } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class ExternalOrders extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {};

        this.api = {
            get: "get_company_order_supplier",
            create_edit: "create_edit_company_order_supplier",
            delete: "delete_company_order_supplier",
            selector_suppliers: "external_orders_supplier_list",
            selector_pos: "list_pos",
        }

        this.id = 'COMPANY_ORDER_SUPPLIERS';

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorPos] === undefined) props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorExternalOrderSuppliers] === undefined) props.data[TableIDs.genericSelectorExternalOrderSuppliers] = { ...props.data.genericSelector };

        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorExternalOrderSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_suppliers);

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.loadData
            }
        ]
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10207);
    }

    setStateVal(root: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, root, params);
        this.setState(newState);
    }

    loadData = () => {
        // console.log(this.props);

        this.props.dataActions.genericDataGetEditWithParams(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            { _id: 0 },
            this.api.get
        );
    }

    generateSelector = (selector, field) => {
        if (selector == TableIDs.genericSelectorPos) {
            return (<GenericSelectorPosStorages {...this.props}
                onChange={(e) => {
                    this.props.dataActions.genericDataSetEdit(this.id, { [field]: e })
                }}
                value={this.props.data[this.id].editedRecord[field]}
            />)
        }
        let list = this.props.data[selector].dataset.map((obj) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });
        return (<Select style={{ width: '100%' }}
            onChange={(e) => {
                this.props.dataActions.genericDataSetEdit(this.id, { [field]: e })
            }}
            value={this.props.data[this.id].editedRecord[field]}
        >{list}</Select>)
    }

    generateInput = (field) => {
        return (<Input style={{ width: '100%' }}
            onChange={(e) => {
                this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.target.value })
            }}
            value={this.props.data[this.id].editedRecord[field]} />)
    }

    render() {
        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };

        let columns = [
            {
                title: this.getText(11669),
                dataIndex: "cId",
                key: "cId",
                width: '5%',
                render: (text, record) => parseInt(text) > 0 ? text : '',
            }, {
                title: this.getText(11670),
                dataIndex: "cPosName",
                key: "cPosName",
                width: '10%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateSelector(TableIDs.genericSelectorPos, 'cPosId')
                    }
                }
            }, {
                title: this.getText(11671),
                dataIndex: "cSupName",
                key: "cSupName",
                width: '10%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateSelector(TableIDs.genericSelectorExternalOrderSuppliers, 'cSupId')
                    }
                }
            }, {
                title: this.getText(11672),
                dataIndex: "cPrm1",
                key: "cPrm1",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm1')
                    }
                }
            }, {
                title: this.getText(11673),
                dataIndex: "cPrm2",
                key: "cPrm2",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm2')
                    }
                }
            }, {
                title: this.getText(11674),
                dataIndex: "cPrm3",
                key: "cPrm3",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm3')
                    }
                }
            }, {
                title: this.getText(11675),
                dataIndex: "cPrm4",
                key: "cPrm4",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm4')
                    }
                }
            }, {
                title: this.getText(11676),
                dataIndex: "cPrm5",
                key: "cPrm5",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm5')
                    }
                }
            }, {
                title: this.getText(11677),
                dataIndex: "cPrm6",
                key: "cPrm6",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm6')
                    }
                }
            }, {
                title: this.getText(11678),
                dataIndex: "cPrm7",
                key: "cPrm7",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm7')
                    }
                }
            }, {
                title: this.getText(11679),
                dataIndex: "cPrm8",
                key: "cPrm8",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm8')
                    }
                }
            }, {
                title: this.getText(11680),
                dataIndex: "cPrm9",
                key: "cPrm9",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm9')
                    }
                }
            }, {
                title: this.getText(11681),
                dataIndex: "cPrm10",
                key: "cPrm10",
                width: '7%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return text
                    } else {
                        return this.generateInput('cPrm10')
                    }
                }
            }, {
                title: this.getText(11682),
                width: '5%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing != record.key) {
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    //console.log('yuda edit record ',record)

                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                }} />
                                <Popconfirm title={this.getText(11683)}
                                    onConfirm={() => {
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.id,
                                            record['cId'],
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.get);
                                    }
                                    }
                                    okText={this.getText(11684)} cancelText={this.getText(11685)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm>
                            </span >);
                    } else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)

                    }

                }
            }
        ]

        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[20, 40, 40]}
                    title={this.getText(11668)}
                    actionButtons={this.actionButtons}
                    pagination={false}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
            </div>
        )

    }
}
export default ExternalOrders;
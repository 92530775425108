/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
import VirtualShopHeader from "./VirtualShopHeader";
import VirtualShopMain from "./VirtualShopMain";
import VirtualShopFooter from "./VirtualShopFooter";

class VirtualShopIndex extends Component {
    constructor(props) {
        super(props);

        this.style_id = "VIRTUAL_SHOP_STYLES";

        if (props.data[this.style_id] === undefined) props.data[this.style_id] = { dataset: [] }

        this.api = {
            get_style: "get_style_virtual_shop",
        }

    }

    componentDidMount() {
        this.apiStyle();
    }

    componentDidUpdate(prev) {
        this.setStyle()
    }

    apiStyle = () => {
        let isMobile = window.innerWidth <= 600 ? "1" : "0"
        this.props.sendAPI(this.api.get_style, "isMobile\r" + isMobile, ob => {
            let dataset = ob.data?.split("\r").map(x => {
                let y = x.split("\f");
                return {
                    className: y[1],
                    key: y[2],
                    value: this.getStyleValue(y[3], y[5])
                }
            }) ?? [];
            this.props.setJson(this.style_id, { dataset })
        }, e => {
            console.error(e)
        })
    }

    getStyleValue = (value, calcType) => {
        switch (calcType) {
            case '6': return (window.innerHeight - parseInt(value)) + "px";
            default: return value;
        }
    }

    setStyle = () => {
        this.props.data[this.style_id].dataset.forEach(x => {
            Array.from(document.getElementsByClassName(x.className)).forEach(y => { y.style[x.key] = x.value });
        })
    }

    render() {
        return (<div className="cotvs_root">
            <VirtualShopHeader {...this.props} />
            <VirtualShopMain {...this.props} />
            <VirtualShopFooter {...this.props} />
        </div>)
    }
}
export default VirtualShopIndex;
/* eslint-disable */
import React from "react";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Select, Button } from 'antd';


const { Option, OptGroup } = Select;

class ExtendedSelector2 extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            pick: this.props.pick,
            optionList: this.props.optionList,  // {code, name}
            chosenList: this.props.chosenList,  // {code}
            //disabled: (this.props.disabled === undefined) ? false : this.props.disabled,
        }

        //this.api_get();
    }

    render() {
        //let filterList = optionList.filter(item => item)
        //let inverse = (item) => !this.state.optionList.map(item => item[col.dataIndex]).includes(item.code)
        
        let inverse = (item) => true;
        //let inverse = (item) => !this.state.chosenList.includes(item.code);
        let list = this.state.optionList.filter(inverse).map((obj, index) => {
            var text = obj['name']; 
            var code = obj['code'];
            return (<Option key={code} value={code}>{text}</Option>)
        });

        let styleShowHideButton = (this.props.showButton) ? { display: 'inline' } : { display: 'none' };
        let styleShowHideTitle = (this.props.showTitle) ? { display: 'inline' } : { display: 'none' };

       // מחירונים
        return (
            <div>
                <label style={{ paddingLeft: '4px', ...styleShowHideTitle }}>
                    {this.props.title}           
                </label>
                <Select
                    //disabled={this.state.disabled}
                    disabled={(this.props.disabled === undefined) ? false : this.props.disabled}
                    showsearch
                    value={this.props.value}
                    defaultValue=""
                    //style={{ width: 200, paddingLeft: '4px' }}
                    style={this.props.styleSelector}
                    placeholder={this.props.placeholder} /*"בחר מחירון"*/
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    filterOption={(input, option) => {
                        return option.props.children ? 
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    }>
                    {list}
                    <Option key="key_" value="">-</Option>
                </Select>
                <Button key="showModal" type="primary"
                    //disabled={this.state.disabled}
                    style={styleShowHideButton}
                    disabled={(this.props.disabled === undefined) ? false : this.props.disabled}
                    onClick={this.props.onButtonClick /*this.showSalesAlertModal*/}>הרחב</Button>
            </div>
        )

    }

    //showSalesAlertModal = () => {
     //   this.props.uiActions.showModal(this.modalID.edit);
    //}
/*
    handleSelectChange = (value) => {
        this.setState({ pick: value });
        this.props.onSelect(value); //callback
        //this.saleAlert = value;
        console.log(`selected ${value}`);
    }*/

    api_get = () => {
        console.log("API_GET\n");
        var dataSend = "";
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, this.props.api /*"get_prices"*/, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallback, (e) => { (e) => (e) }); // callback problem here  
    }

    successCallback = (ob) => {
        console.log("get success selector" + ob.data);
        let s = this.state;
        let rowsRaw = ob.data;
        let rows = rowsRaw.split("\r");

        for (var rowRaw of rows) {
            let row = rowRaw.split("\f");
            s.optionList.push({
                code: row[0],
                name: row[1],
            });
        }
        this.setState(s);
    }
}
export default ExtendedSelector2;

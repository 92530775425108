/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Card, Button, Icon } from "antd";
import moment from "moment";
import soundFile from "../../sound/cashSound.wav";
import Nis from "../../components/Nis";
import LangContext from "../../contextProvider/LangContext";
import "../Dashboard.css";

class FlashTitle extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newFlash: false,
            LastTimeRefreshe: null,
        }

        this.id = "LAST_SALES_FROM_FLASH";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {

        if (
            // p.dashboardDate !== this.props.dashboardDate ||
            // p.dashboardPos !== this.props.dashboardPos ||
            p.lastLoad !== this.props.lastLoad
        ) {
            this.getDataFlashTitle()
        }
    }

    getDataFlashTitle = () => {
        let request = {
            dashboardDate: this.props.dashboardDate ? moment(this.props.dashboardDate).format("YYYY-MM-DD") : null,
            dashboardPos: this.props.dashboardPos,
        };

        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, "get_dashboard_last_sales");
        this.setState({ LastTimeRefreshe: moment().format('HH:mm:ss'/*'LTS'*/), newFlash: true }, () => {
            if (this.props.soundActive) {
                let audio = new Audio(soundFile);
                audio.play();
            }
            setTimeout(() => { this.setState({ newFlash: false }) }, 3000);
        });

    }

    createFlashTitleMobile = (data) => {
        let resStr = data.map((x, i) => {
            return (<span>
                {(i > 0) ? (<span> &nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;&nbsp; </span>) : ''}
                {' #' + x.sId + ' ' + this.getText(10061) + ': ' + x.sPos + ' ' + this.getText(10062) + ': '}
                <Nis>{x.sSum}</Nis>
                {' ' + this.getText(10063) + ': ' + x.sTime + ' ' + this.getText(10064) + ': ' + x.sEmploeey}
            </span>);
        });
        return (<div id="frame-title-mobile"><h3>{resStr}</h3></div>)
    }

    render() {
        let dataFT = this.props.data[this.id];

        let dataFTOb = dataFT.data[0] ? dataFT.data.map((x) => {
            let resOb = {}
            x.split("\f").forEach((y, i) => resOb = { ...resOb, [dataFT.headers[i]]: y });
            return resOb;
        }) : [];

        let lastDil = (index) => {
            let x = dataFTOb[index];
            let sStyle = {
                color: '#40a9ff',
                border: "2px solid #40a9ff",
                borderRadius: '50%',
                width: 25,
                height: 25,
                padding: 0,
                display: 'inline-block',
                float: 'left',
                textAlign: 'center',
                // verticalAlign: 'sub'
            }
            let nisSymble = (<h3 style={sStyle}><i>&#8362;</i></h3>)
            let dStyle = {
                color: index === 0 || (index === 1 && this.state.newFlash) ? '#40a9ff' : '#000000',
                border: '3px solid #40a9ff',
                borderRadius: 5,
                width: this.state.newFlash ? '99%' : '18%',
                marginRight: '1%',
                display: 'inline-block',
                padding: 2,
            }
            let lStyle = {
                width: this.state.newFlash ? '99%' : '18%',
                marginRight: '1%',
                display: 'inline-block',
                padding: 2,
            }
            return x ? (<div style={dStyle}>
                {/* <div style={{ fontSize: 20, color: '#40a9ff' }}> עסקה אחרונה </div> */}
                <b> {this.getText(10061)}: </b>{x.sPos}<br />
                <b> {this.getText(10062)}: </b>{parseFloat(x.sSum).toFixed(2)}<br />
                <b> {this.getText(10063)}: </b>{x.sTime}<br />
                <b> {this.getText(10064)}: </b>{x.sEmploeey}
                {nisSymble}
            </div>) : (<div style={lStyle}></div>)
        }

        let mobile = window.innerWidth > 600 ? false : true;

        return (<Card title={<div>
            <span>{this.getText(10142)}</span>
            <span style={{ float: "left" }}>
                <span style={{ marginLeft: 4 }}>{this.state.LastTimeRefreshe}</span>
                <Button className={"btn"}
                    disabled={this.props.ui.workInProgress}
                    onClick={this.getDataFlashTitle}>
                    <Icon type={"reload"} style={{ fontSize: 16, padding: 4 }} />
                </Button>
            </span>
        </div>} >
            {mobile && (dataFTOb[0]) ?
                this.createFlashTitleMobile(dataFTOb)
                : this.state.newFlash ? (
                    <div id={"frame-title"}>

                        <div style={{ width: '125%' }}>
                            <div>{lastDil(0)}</div>
                            <div>{lastDil(1)}</div>
                            <div>{lastDil(2)}</div>
                            <div>{lastDil(3)}</div>
                            <div>{lastDil(4)}</div>
                            <div>{lastDil(5)}</div>
                        </div>
                    </div>
                ) : (
                    <div id="frame-title-normal">
                        {lastDil(0)}
                        {lastDil(1)}
                        {lastDil(2)}
                        {lastDil(3)}
                        {lastDil(4)}
                    </div>
                )}
        </Card>)
    }
}
export default FlashTitle;

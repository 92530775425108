
import React from "react";
import { Input, InputNumber, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
import { fixCurrentPage, escapeRegExp } from "../utils/utils";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const topHeight = 340; // px
const rowHeight = 30;  // px

//type State = {}
class ModalAddSizesToRuler extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = "SIZES_TO_RULER";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[TableIDs.genericSelectorRuler] === undefined) {
            props.data[TableIDs.genericSelectorRuler] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_sizes_table",
            create: "create_size",
            edit: "edit_size",
            delete: "delete_size",
            selector: "get_rulers_list",
        }

        this.actionButtons = [{
            buttonType: 'add',
            buttonFunction: this.handleCreateNew
        }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        },/* {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //document.title = "Cash On Tab - מידות";
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorRuler, this.props.user.companyCode,
            this.props.user.token, this.api.selector);
        //this.refreshData();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: (this.props.ui.data) ? this.props.ui.data : 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        return request;
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mID": col--;
            //fall through
            //case "mRulerID": col--;
            //fall through
            case "mNumerator": col--;
            //fall through
            case "mCode": col--;
            case "mDesc": break; // 4
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalAddSizesToRuler);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        this.setState({ visible: false });
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalAddSizesToRuler);
    }
    handleClear = () => {
        //this.clear();
    }

    render() {
        const { loading } = this.state;
        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10708)}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request,
                                _words: escapeRegExp(val),
                                //  _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mID: y[0],
                //mRulerID: y[1],
                mNumerator: y[2],
                mCode: y[3],
                mDesc: y[4],
                mUOMQty: y[5],
                index: index
            })
        });

        let columns = [
            // {
            //     title: "", //"מזהה",
            //     dataIndex: "mID",
            //     key: "mID",
            //     sorter: false,
            //     width: '0px',//'15%',
            //     render: (text, record) => <div />, //text,
            // },
            //{
            //    title: 'סרגל',
            //    dataIndex: "mRulerID",
            //    key: "mRulerID",
            //    width: '10%',
            //    sorter: true,
            //    render: (text, record) => {
            //        if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
            //            return (
            //                <Input
            //                    style={{ width: '98%', textAlign: 'center' }}
            //                    defaultValue={text}
            //                    onChange={(value) => {
            //                        console.log("input number: ", text, record);
            //                        this.props.dataActions.genericDataSetEdit(this.id, { mRulerID: value.target.value });
            //                    }} />);
            //        } else {
            //            return text;
            //        }
            //     }
            //}, 
            {
                title: this.getText(10700),
                dataIndex: "mNumerator",
                key: "mNumerator",
                width: '20%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
                        return (
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mNumerator: value.target.value });
                                }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title: this.getText(10701),
                dataIndex: "mCode",
                key: "mCode",
                width: '25%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
                        return (
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mCode: value.target.value });
                                }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title: this.getText(10702),
                dataIndex: "mDesc",
                key: "mDesc",
                width: '25%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
                        return (
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mDesc: value.target.value });
                                }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title: this.getText(10703),
                dataIndex: "mUOMQty",
                key: "mUOMQty",
                width: '20%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
                        return (
                            <InputNumber
                                style={{ width: '98%', textAlign: 'center' }}
                                min={1}
                                defaultValue={text}
                                readOnly={this.props.data[this.id].filters._page_num == 1 && record.index == 0 && text == 1}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { mUOMQty: value ? value : null });
                                }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title: this.getText(10704),
                width: '10%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                }} />
                                <Popconfirm title={this.getText(10705)}
                                    onConfirm={() => {
                                        console.log("record", record);
                                        fixCurrentPage.bind(this)();
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.id,
                                            record['mID'],
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.get);
                                    }
                                    }
                                    okText={this.getText(10706)} cancelText={this.getText(10707)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm>
                            </span >);
                    } else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataSaveEditWithParams(
                                            this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get, {
                                            ...this.props.data[this.id].editedRecord,
                                            _id: this.props.ui.data
                                        });
                                    //this.props.dataActions.genericDataSaveEdit(
                                    //    this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get);
                                    else
                                        //this.props.dataActions.genericDataSaveEditWithParams(
                                        //    this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get, { _id: this.props.ui.data });
                                        this.props.dataActions.genericDataSaveEdit(
                                            this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)
                    }
                }
            }
        ];

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={window.innerWidth > 600 ? '70%' : '90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={false}
                /*footer={
                    [
                        <Button key="back"
                            onClick={this.handleCancel}>ביטול</Button>,
                        <Button key="clear"
                            loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                        <Button key="create" disabled={!this.state.isValidItemCode}
                            type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                        <Button key="createLeave" disabled={!this.state.isValidItemCode}
                            type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                    ]}*/
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >

                <ResponsiveTable
                    tableOnly={true}
                    percentageToMobile={[false, 30, 70]}
                    ui={this.props.ui}
                    idTable={this.props.data[this.id]}
                    dataSource={dataSource}
                    columns={columns}
                    // loading={this.props.ui.workInProgress}
                    // //scroll={{ x: 1650 }}
                    // bordered
                    // pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                //locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                />


            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data ||
            (prevProps.toggle === false && this.props.toggle === true)) {
            console.log("prev", prevProps.ui.data, "next", this.props.ui.data);

            let request = this.initFilters();
            request._id = (this.props.ui.data) ? this.props.ui.data : 0;

            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                request
                /*{ _id: this.props.ui.data }*/,
                //this.api.get_suppliers
                this.api.get
            );
        }
    }
}

export default ModalAddSizesToRuler;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Modal, Row, Col, DatePicker, Checkbox, Select, Form } from "antd";
import "../App.css";
import PageToolbar from "./PageToolbar";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
import moment from "moment";

const topHeight = 340; // px
const rowHeight = 30;  // px
const { Option } = Select;

class ModalEmployeeHours extends Component<Props, State> {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            height: window.innerHeight,
            blankPosList: false,
            blankStorageList: false,
        }

        this.doOnResize = null;

        this.api = {
            get: "get_working_hours_per_date",
        }

        this.id = 'EMPLOYEE_HOURS';

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages,
            this.props.user.companyCode,
            this.props.user.token,
            "get_storage_list");

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos,
            this.props.user.companyCode,
            this.props.user.token,
            "list_pos");

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) {
            this.initFilters({
                _date: this.props.date ? this.props.date : this.getCurdate(),
                _storageList: this.props.data[TableIDs.genericSelectorStorages]?this.props.data[TableIDs.genericSelectorStorages].dataset.map(x => x.code).join(','):"",
                _posList: this.props.pos ? this.props.pos : this.props.data[TableIDs.genericSelectorPos] ? this.props.data[TableIDs.genericSelectorPos].dataset.map(x => x.code).join(','):"",
            });
        }
    }

    getCurdate = () => {
        let d = new Date();
        let yy = d.getFullYear();
        let mm = d.getMonth();
        let dd = d.getDate();
        let zeros = (n) => n < 10 ? "0" + n : n;
        return (yy + "-" + zeros(mm + 1) + "-" + zeros(dd))
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        if (this.props.visible) this.setState({ height: window.innerHeight }, this.refreshData);
    };

    initFilters = (request) => {
        let filters = this.props.data[this.id] ? this.props.data[this.id].filters : {}
        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            { ...filters, ...request },
            this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    timeToSecounds = (time) => {
        let tSplit = time.split(':').map(x => parseInt(x));
        return (tSplit[0] * 3600) + (tSplit[1] * 60) + tSplit[2];
    }

    secoundsToTime = (sec) => {
        let resSec = sec % 60;
        let resMin = ((sec % 3600) - resSec) / 60;
        let resHour = Math.floor(sec / 3600);
        return (resHour < 10 ? '0' : '') + resHour + ':' +
            (resMin < 10 ? '0' : '') + resMin + ':' +
            (resSec < 10 ? '0' : '') + resSec;
    }

    calculateHour = (tIn, tOut) => {
        let btwn = this.timeToSecounds(tOut) - this.timeToSecounds(tIn);
        return this.secoundsToTime(btwn);
    }

    sumHours = (dataSource) => {
        let secSum = 0;
        dataSource.forEach((x) => {
            if (x._sum) secSum += this.timeToSecounds(x._sum);
        });
        return this.secoundsToTime(secSum);
    }

    render() {

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.getText(14046)}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let columns = [
            { title: this.getText(14047), dataIndex: "_employee_id", key: "_employee_id", width: '15%', render: (text) => text },
            { title: this.getText(14048), dataIndex: "_employee_name", key: "_employee_name", width: '35%', render: (text) => text },
            { title: this.getText(14049), dataIndex: "_timeIn", key: "WorkingHourTimeIn", width: '18%', render: (text) => text },
            { title: this.getText(14050), dataIndex: "_timeOut", key: "WorkingHourTimeOut", width: '18%', render: (text) => text },
            { title: this.getText(14051), dataIndex: "_sum", key: "_sum", width: '14%', render: (text) => text },
        ];

        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let dataSource = [];
        let dataEmployees = [];

        if (this.props.data[this.id]) {

            this.props.data[this.id].data.forEach((x, i) => {
                let y = x.split('\f');
                let pIndex = dataSource.find(f => f.index === (i - 1));
                let pIndexPos = dataSource.findIndex(f => f.index === (i - 1));
                let ei = dataEmployees.findIndex(f => f.id === y[1]);
                let newEmployee = ei >= 0 ? false : true

                if (newEmployee) {
                    dataEmployees.push({ id: y[1], finish: y[4] ? true : false })
                } else {
                    dataEmployees[ei] = { ...dataEmployees[ei], finish: y[4] ? true : false }
                }

                if (y[3] || !pIndex || pIndex['_timeOut'] || newEmployee) {
                    dataSource.push({
                        key: y[0],
                        index: i,
                        _employee_id: y[1],
                        _employee_name: y[2],
                        _timeIn: y[3],
                        _timeOut: y[4],
                        _sum: '',
                    })
                } else {
                    dataSource[pIndexPos] = {
                        ...pIndex,
                        _timeOut: y[4],
                        _sum: this.calculateHour(pIndex['_timeIn'], y[4])
                    }
                }
            });
        }

        let finishEmployees = dataEmployees.filter(f => f.finish).length;
        let activeEmployees = dataEmployees.filter(f => !f.finish).length;


        const { _date, _storageList, _posList } = this.props.data[this.id] ? this.props.data[this.id].filters : {};
        const { blankPosList, blankStorageList } = this.state;
        const storagesSelect = this.props.data[TableIDs.genericSelectorStorages] ? this.props.data[TableIDs.genericSelectorStorages].dataset : [];
        const posSelect = this.props.data[TableIDs.genericSelectorPos] ? this.props.data[TableIDs.genericSelectorPos].dataset: [];
        const mobile = window.innerWidth <= 600 ? true : false;

        let selector = (text, key) => {
            let fullList = key === "mPosList" ? posSelect : storagesSelect;
            let keyFilter = key === "mPosList" ? "_posList" : "_storageList";
            let valueFilter = key === "mPosList" ? _posList : _storageList;
            let isBlank = key === "mPosList" ? blankPosList : blankStorageList;
            let keyBlank = key === "mPosList" ? "blankPosList" : "blankStorageList";
            const value = !isBlank && valueFilter ? valueFilter.split(',') : [];
            const onChange = (_val) => {
                this.setState({ [keyBlank]: !_val.length }, () => {
                    this.initFilters({ [keyFilter]: _val.length > 0 ? _val.join(',') : fullList.map(x => x.code).join(',') })
                })
            }

            return (<Col span={mobile ? 24 : 9}>
                <Form.Item label={this.getText(text)}>
                    <Checkbox
                        style={{ marginRight: 10 }}
                        checked={value.length === fullList.length}
                        indeterminate={value.length && value.length < fullList.length}
                        onChange={(e) => { onChange(e.target.checked ? fullList.map(x => x.code) : []) }} >
                        <Select key={key} showSearch filterOption={true} optionFilterProp="valuefilter"
                            mode="multiple" maxTagCount={3} style={{ width: 300 }} value={value} onChange={onChange} >
                            {fullList.map((x) => (<Option key={x.code} value={x.code} valuefilter={x.name}>{x.code + " - " + x.name}</Option>))}
                        </Select>
                    </Checkbox>
                </Form.Item>
            </Col>)
        }

        return (<Modal
            destroyOnClose={true}
            width={'95%'}
            visible={this.props.visible}
            title={titleBar}
            onCancel={this.props.onCancel}
            footer={false}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <Row style={{ marginTop: mobile ? 50 : 0, marginBottom: 20 }}>
                <Col span={mobile ? 24 : 6}>
                    <Form.Item label={this.getText(14052)}>
                        <DatePicker
                            style={{ marginRight: 10 }}
                            format="DD/MM/YYYY"//"YYYY-MM-DD"
                            value={moment(_date ? _date : this.getCurdate(), "YYYY-MM-DD")}
                            onChange={(value) => {
                                this.initFilters({ _date: value ? value.format("YYYY-MM-DD") : this.getCurdate() })
                            }} />
                    </Form.Item>
                </Col>
                {selector(14053, "mStorageList")}
                {selector(14059, "mPosList")}
            </Row>

            <ResponsiveTable
                dataSource={dataSource}
                tableOnly={true}
                columns={columns}
                percentageToMobile={[30, 70]}
                pagination={{ pageSize: numOfRowsByHeight }}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />

            <Row>
                <Col span={mobile ? 24 : 8}><b>{this.getText(14054) + ": "}</b>{activeEmployees}</Col>
                <Col span={mobile ? 24 : 8}><b>{this.getText(14055) + ": "}</b>{finishEmployees}</Col>
                <Col span={mobile ? 24 : 8}><b>{this.getText(14056) + ": "}</b>{this.sumHours(dataSource)}</Col>
            </Row>
        </Modal >)
    }
}

export default ModalEmployeeHours;
/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import { Card, InputNumber, Input, Checkbox, Form, DatePicker, Select, Button, message, Modal, Row, Col } from "antd";
import "../../App.css";
import moment from "moment";
import TableIDs from '../../data/TableIDs.js';
import PictureView from "../../components/PictureView";
import RegiCode from "./RegiCode";
import GoogleAddress from "./GoogleAddress";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags.js";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    data: Object,
    dataActions: Object,
    isBackOffice: Boolean,
    getText: Function,
    sendAPI: Function,
    companyCode: String,
    visible: Boolean,
    onCancel: Function,
    rgiForm: Boolean,
}

class CustomerRegistration extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            termToggle: false,
            isTermOk: false,
            rgiForm: false,
            loginPhone: '',
            loginPassword: '',
            loginError: false,
            modalRegiCode: false,
            mobileToRegiCode: false,
            custData: false,
            modalResetPassword: false,
            modalNotReg: false,
            valueResetPassword: '',
            addressError: false,

        }

        this.getText = this.props.getText;

        // this.id = "SELF_CREATE_CUSTOMER_FULL";
        this.edit_id = "REGISTRATION_CUSTOMER_EDIT";
        this.view_id = "REGISTRATION_CUSTOMER_VIEW";
        this.comp_id = "COMPANY_DETAILS_DATA";
        this.order_fields_id = "FOOD_FORMAT_ORDER_FIELDS";
        this.setting_id = "FORMAT_SITE_SETTINGS";

        this.api = {
            get_format: "get_customer_fields_full",
            get_data: "get_customer_data",
            edit: "v2/set_customer",
            selector: "get_customer_tags_list",
            selector_price_list: "get_prices",
            selector_titles: "get_customer_titles",
            delete: "delete_form_token",
            delete_a: "delete_form_alternate_token",
            firebase_token: "save_firebase_from_customer",
            login_site: "customer_login_to_site",
            check_mobile: "check_if_exists_cust_mobile",
            regi_code: "send_registration_code_to_site",
            check_regi_code: "check_registration_code_to_site",
            update_regi: "update_registration_customer",
            create_tag_reg: "create_tag_reg_from_customer",
            edit_tag_reg: "edit_tag_reg_from_customer",
            reset_password: "reset_password_from_site",
        }

    }

    timeoutForNewCodeOn = () => {
        function to(a, b) {
            if (a()) {
                b();
                setTimeout(() => { to(a, b) }, 1000);
            }
        }
        let a = () => this.state.timeoutForNewCode > 0;
        let b = () => { this.setState({ timeoutForNewCode: this.state.timeoutForNewCode - 1 }) }
        if (this.state.timeoutForNewCode > -1) to(a, b);
    }

    componentDidMount() {
        this.getFormData(this.api.get_format, { rID: 4 }, this.filterFields);
        this.getFormData(this.api.selector, {}, this.filterSelectorCustomerTags);
        this.getFormData(this.api.selector_price_list, {}, this.filterSelectorPriceList);
        this.getFormData(this.api.selector_titles, {}, this.filterSelectorTitles);
    }

    componentDidUpdate(p, s) {
        let ps = this.props.rgiForm;
        let ss = this.state.rgiForm;
        let v = this.props.visible;
        if (!p.visible && v) this.setState({ rgiForm: ps }, () => { this.clearFields(ps) })
        if (p.visible && !v) this.clearFields(ss)
        if (s.rgiForm !== ss) this.props.regiModal(ss);
    }

    filterFields = (fullData) => {
        let headers = fullData[0].split("\f");
        let perentData = [];
        // let count = parseInt(fullData[fullData.length - 1]);
        fullData.forEach((x, i) => {
            if (i > 0 && i < (fullData.length - 1)) {
                let z = x.split("\f");
                let perentObj = {}
                headers.map((y, ii) => { perentObj = { ...perentObj, [y]: z[ii] } })
                perentData.push(perentObj)
            }
        });

        // let d = {
        //     headers: headers,
        //     data: perentData,
        //     count: count
        // }

        let editedRecord = { key: 0 }

        perentData.forEach((x) => {
            let keyName = x.CustomerFieldName;
            let defValue = x.CustomerFieldRoleListDefaultValue;
            if (x.CustomerFieldControlType === "5" && defValue === 'NOW') {
                defValue = moment().format("YYYY-MM-DD");
            }
            if (keyName) {
                editedRecord = { ...editedRecord, [keyName]: defValue }
            }
        })
        let containers = [];
        let formFields = [];
        perentData.forEach((x) => {
            let num = parseInt(x.CustomerFieldContainer);
            let isTitle = x.CustomerFieldContainerOrder === "0" ? true : false;
            let isActive = x.CustomerFieldIsActive !== "0" ? true : false;
            let indexContainer = containers.indexOf(num);
            if (isActive || isTitle) {
                if (indexContainer === -1) {
                    let toPush = isTitle ? { title: x, fields: [] } : { title: {}, fields: [x] }
                    containers.push(num);
                    formFields.push(toPush);
                } else {
                    let toPush = isTitle ? { title: x } : { fields: [...formFields[indexContainer].fields, x] }
                    formFields[indexContainer] = { ...formFields[indexContainer], ...toPush }
                }
            }
        });

        let orderFields = this.props.data[this.order_fields_id].orderFields;

        let mobile = orderFields && orderFields.dPhone ? orderFields.dPhone : "";

        if (mobile) {
            this.getFormData(this.api.get_data, { _mobile: mobile }, (fullData) => {

                if (mobile && fullData[1]) {
                    editedRecord = {}
                    let headers = fullData[0].split("\f");
                    let data = fullData[1].split("\f");
                    headers.map((x, i) => {
                        editedRecord = { ...editedRecord, [x]: data[i] }
                    })
                    editedRecord = { ...editedRecord, CustomerContactPersonMobile: mobile }
                }


                // this.props.setJson(this.id, d);
                this.props.setJson(this.edit_id, editedRecord);
                this.props.setJson(this.view_id, { data: formFields });
            });
        } else {
            this.props.setJson(this.edit_id, editedRecord);
            this.props.setJson(this.view_id, { data: formFields });
        }
    }

    // filterEditFields = (fullData) => {

    //     let obj = {}
    //     let headers = fullData[0].split("\f");
    //     let data = fullData[1] ? fullData[1].split("\f") : [];
    //     headers.map((x, i) => {
    //         obj = { ...obj, [x]: data[i] ? data[i] : "" }
    //     })

    //     this.props.setJson(this.edit_id, obj);
    // }

    filterSelectorCustomerTags = (data) => {
        let types = [];
        let dataset = [];
        data.map((x) => {
            let y = x.split("\f");
            let indexType = types.indexOf(y[0]);
            if (indexType === -1) {
                types.push(y[0])
                dataset.push({
                    tags: [{ id: y[1], text: y[2] }],
                    type: { id: y[0], text: y[3] }
                });
            } else {
                dataset[indexType] = {
                    ...dataset[indexType],
                    tags: [...dataset[indexType].tags, { id: y[1], text: y[2] }]
                }
            }
        })

        this.props.setJson(TableIDs.genericMultiSelectorCustomerTags, { dataset: dataset });
    }

    filterSelectorPriceList = (data) => {
        let dataset = [];
        data.map((x) => {
            let y = x.split("\f");
            dataset.push({ code: y[0], name: y[1] });
        })
        this.props.setJson(TableIDs.genericSelectorPriceList, { dataset: dataset });
    }

    filterSelectorTitles = (data) => {
        let dataset = [];
        data.map((x) => {
            let y = x.split("\f");
            dataset.push({ code: y[0], name: y[1] });
        })
        this.props.setJson(TableIDs.genericSelectorCustomerTitles, { dataset });
    }


    getFormData = (script, params, next) => {
        let sCallback = (ob) => {
            let fullData = ob.data.split("\r");
            next(fullData);
        }
        let data = this.parseParamsToApi(params);
        this.props.sendAPI(script, data, sCallback, this.errorMessage)
    }

    setFormData = (script, params, next) => {
        let data = this.parseParamsToApi(params);
        this.props.sendAPI(script, data, next, this.errorMessage)
    }

    setFormDataMultiSections = (script, params, next) => {
        let data = params.map((x) => { return { ...x, data: this.parseParamsToApi(x.data) } })
        this.props.sendAPI(script, data, next, this.errorMessage)
    }

    errorMessage = (e) => {
        message.error(this.getText(13039));
    }

    parseParamsToApi = (params) => {
        let keys = Object.keys(params)
        let data1 = "";
        let data2 = "";
        keys.map((x, i) => {
            if (i > 0) {
                data1 += "\f";
                data2 += "\f";
            }
            data1 += x;
            data2 += (x === "CustomerDefaultPriceList" && !params[x] ? "1" : params[x]);
        })
        return data1 + "\r" + data2;
    }

    getField = (field) => {
        const sqlFormat = "YYYY-MM-DD";
        const dateFormat = "DD/MM/YYYY";

        let label = field.CustomerFieldDescription;
        let key = field.CustomerFieldName;
        let readOnly = field.CustomerFieldRoleListIsReadOnly == '1';
        let isMandatory = field.CustomerFieldIsMandatory == '1';

        let siteSettings = this.props.data["FORMAT_SITE_SETTINGS"];
        let isStyle3 = siteSettings.pDesktopCategoriesView == '3';
        let isStyle4 = siteSettings.pDesktopCategoriesView == '4';
        let isStyle5 = siteSettings.pDesktopCategoriesView == '5';


        /////
        if (key === 'CustomerContactPersonMobile') {
            readOnly = this.isCustEdit();
            isMandatory = !this.isCustEdit();
        }
        /////

        let editedRecord = this.props.data[this.edit_id];
        let value = editedRecord[key];
        let onChange = (v) => { this.props.setJson(this.edit_id, { [key]: v }) }
        let isError = isMandatory && !value;
        let mobile = window.innerWidth <= 700 ? true : false;
        let formItemStyle = { textAlign: mobile ? 'right' : 'left', marginBottom: 10 }
        let iStyle = isStyle3 ? { width: "100%" } : { width: mobile ? '95%' : '75%', marginRight: 20 }
        let custLocation = this.props.data.formData ? this.props.data.formData.custLocation : null;

        let input = () => {
            if (key === 'CustomerContactPersonAddress') {
                return (<div style={{ ...iStyle, display: 'inline-block' }}>
                    <GoogleAddress
                        placeholder={''}
                        inputStyle={{ paddingTop: 0, paddingBottom: 0, height: 32, borderRadius: 5, width: '100%' }}
                        changeAddress={(dCity, dStreet, dBuilding, dLat, dLng, dCountry) => {
                            let valid = this.props.notValidAddress || (dCity && dStreet && dBuilding && dLat && dLng);
                            this.setState({ addressError: !valid ? true : false }, () => {
                                onChange(valid ? dStreet + ' ' + dBuilding + ', ' + dCity : '');
                            });
                        }}
                        value={value}
                        latLng={custLocation ? { lat: custLocation.latitude, lng: custLocation.longitude } : null}
                        getLocationAgain={this.props.getLocationAgain}
                        getLocationAgainText={this.props.getLocationAgainText}
                    />
                    {this.state.addressError ? (<div style={{ color: 'red' }}>{this.getText(13372)}</div>) : ''}
                    <Checkbox
                        checked={this.props.notValidAddress}
                        onChange={e => { this.props.onChangeValidAddress(e.target.checked) }}
                        className={"text_item"}>
                        {this.getText(19754)}
                    </Checkbox>
                </div>)
            }

            switch (field.CustomerFieldControlType) {
                case '1':   // text
                    return (<Input
                        className={"text_item"}
                        type={key === 'CustomerContactPersonMobile' || key === 'CustomerContactPersonPhone' ? 'tel' : undefined}
                        style={iStyle}
                        disabled={readOnly}
                        // autoComplete="off" // לא עובד
                        readOnly={true}
                        onFocus={readOnly ? () => { } : e => {
                            e.target.select()
                            e.target.removeAttribute('readonly')
                        }} // על מנת למנוע השלמה אוטומטית של סיסמאות
                        value={value}
                        onChange={(v) => { onChange(v.target.value) }}
                    />);
                case '2':   // price
                    return (<InputNumber
                        className={"text_item"}
                        style={iStyle}
                        min={0}
                        precision={2}
                        disabled={readOnly}
                        value={value}
                        onChange={onChange}
                        onFocus={(e) => { e.target.select() }}
                    />);
                case '3':   // float
                    return (<InputNumber
                        className={"text_item"}
                        style={iStyle}
                        precision={2}
                        disabled={readOnly}
                        value={value}
                        onChange={onChange}
                        onFocus={(e) => { e.target.select() }}
                    />);
                case '4':   // int
                    return (<InputNumber
                        className={"text_item"}
                        style={iStyle}
                        min={0}
                        disabled={readOnly}
                        value={value}
                        onChange={onChange}
                        onFocus={(e) => { e.target.select() }}
                    />);
                case '5':   // date
                    return (<DatePicker
                        disabled={readOnly}
                        style={iStyle}
                        value={value === '' ? null : moment(value, sqlFormat)}
                        format={dateFormat}
                        onChange={(date) => { onChange(date != null ? date.format(sqlFormat) : "") }}
                    />);
                case '6':   // bool
                    return (<Checkbox
                        onChange={(e) => { onChange((e.target.checked) ? 1 : 0) }}
                        checked={(value == 1) ? true : false}
                        defaultChecked={false}
                        disabled={readOnly} />)

                case '7': case '8':  // select


                    // if (key === "CustomerGroupID") {
                     
                    //     return (
                    //         <GenericSelectorCustTags
                    //             {...this.props}
                    //             multi
                    //             value={value ? value.split(",") : []}
                    //             onChange={(e) => { onChange(e ? e.join(",") : "") }}
                    //             disabled={readOnly}
                    //         />
                    //     );

                    // } else {
                        let list = [];
                        if (field.CustomerFieldSelectorData?.indexOf('[') > -1) list = JSON.parse(field.CustomerFieldSelectorData);

                        if (field.CustomerFieldControlType == '7') {

                            return (<Select {...{ value, onChange, disabled: readOnly, style: iStyle }}>
                                <Option key={"0"} value={""} >-</Option>
                                {/* {list.map(obj => (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>))} */}
                                {list.map(obj => (<Option key={obj['code']} value={obj['code']?.toString()} valuefilter={obj['code'] + obj['name']}>{obj['code'] + " - " + obj['name']}</Option>))}
                            </Select>)

                        } else {

                            return (<Checkbox
                                disabled={readOnly}
                                checked={value && list.length === value.split(",").length}
                                indeterminate={value?.split(",").length && value?.split(",").length < list.length}
                                onChange={(e) => { onChange(e.target.checked ? list.map(x => x.code).join(",") : "") }}
                            >
                                <Select
                                    mode={"multiple"} maxTagCount={4} disabled={readOnly} style={iStyle}
                                    value={value ? value.split(",") : []}
                                    onChange={(e) => { onChange(e ? e.join(",") : "") }}>
                                    {list.map(obj => (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>))}
                                </Select>
                            </Checkbox>)
                        }
                    // }

                // case '7':   // select
                //     let selectorName = key === 'CustomerTitleID' ? TableIDs.genericSelectorCustomerTitles : TableIDs.genericSelectorPriceList

                //     let list = this.props.data[selectorName] ?
                //         this.props.data[selectorName].dataset.map((obj, index) => {
                //             return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
                //         }) : [];
                //     return (<Select disabled={readOnly} value={value} style={iStyle} onChange={onChange}>{list}</Select>)

                // case '8':   // multi select
                //     let tagList = this.props.data[TableIDs.genericMultiSelectorCustomerTags] ?
                //         this.props.data[TableIDs.genericMultiSelectorCustomerTags].dataset.map((node) => {
                //             return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                //                 {node.tags.map((item) => (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>))}
                //             </OptGroup>)
                //         }) : []
                //     let fullSelect = value !== "" ? value.split(",") : [];
                //     return (<Select
                //         showSearch
                //         filterOption={true}
                //         optionFilterProp="valuefilter"
                //         mode="multiple"
                //         onChange={(e) => { onChange(fullSelect[0] ? e.join(",") : e[0]) }}
                //         value={fullSelect}
                //         disabled={readOnly}>
                //         {tagList}
                //     </Select>);

                case '9':   // image
                    let formEntPoint = {
                        token: this.props.token,
                        companyCode: this.props.companyCode,
                        entpoint: this.props.isBackOffice ? "CashOnTab" : "JMForms",
                        success: this.getText(13031),
                        error: this.getText(13032),
                    }
                    return (<PictureView
                        formEntPoint={formEntPoint}
                        url={value}
                        onChange={onChange}
                        onRemove={() => onChange("")}
                        onUploadedSuccess={() => { }}
                        onUploadedFailed={() => { }}
                        aspect={9 / 10}
                        getText={this.getText}
                        imageType={'C'}
                        readOnly={readOnly} />);

                case '10':   // text
                    return (<Input.Password
                        className={"text_item_input_password"}
                        style={{ ...iStyle, fontSize: 26 }}
                        disabled={readOnly}
                        value={value}
                        onChange={(v) => { onChange(v.target.value) }}
                        onFocus={(e) => { e.target.select() }}
                    />);

                case '11':
                    return (<Select
                        value={value}
                        defaultValue=""
                        style={iStyle}
                        onChange={onChange}
                        disabled={readOnly}>
                        <Option value={'1'}>{this.getText(16788)}</Option>
                        <Option value={'2'}>{this.getText(16790)}</Option>
                        <Option value={'3'}>{this.getText(16789)}</Option>
                        <Option value={'4'}>{this.getText(16791)}</Option>
                        <Option value={'5'}>{this.getText(18136)}</Option>
                    </Select>)



                default:
                    return (<div>undefined controller</div>);
            }
        }
        let sRed = { color: '#f5222d' }

        if (isStyle3 || isStyle4 || isStyle5) {
            return (<Row>
                <Col span={6}>
                    <span className={"text_item"}>{label}</span>
                </Col>
                <Col span={18}>
                    <FormItem
                        key={key}
                        validateStatus={(isError) ? "error" : "success"}
                        required={isMandatory}
                        extra={<label style={isError ? sRed : { display: 'none' }}>{this.getText(13033)}</label>}
                    >
                        {input()}
                    </FormItem>
                </Col>
            </Row>)
        } else {
            return (<FormItem
                key={key}
                // label={label}
                style={formItemStyle}
                validateStatus={(isError) ? "error" : "success"}
                required={isMandatory}
                extra={<label style={isError ? sRed : { display: 'none' }}>{this.getText(13033)}</label>}
            // labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
            >
                <span className={"text_item"}>{label}</span>
                {input()}
            </FormItem>)
        }
    }

    getContainer = (container) => {
        let fields = container.fields[0] ? container.fields.map((x) => this.getField(x)) : [];
        let titleStyle = { paddingRight: 12, height: 42, backgroundColor: '#d0d2d3', marginBottom: 12 }
        return fields[0] ? (<div>
            <div className={"text_item"} style={titleStyle}>
                {container.title ? container.title.CustomerFieldDescription : ""}
            </div>
            {fields}
        </div>) : ""
    }

    handleOk = () => {
        let editedRecord = this.props.data[this.edit_id];
        let _mobile = editedRecord.CustomerContactPersonMobile;

        let mandatories = [];
        this.props.data[this.view_id].data.map((x) => {
            x.fields
                .filter(f => f.CustomerFieldIsMandatory == '1' && f.CustomerFieldRoleListIsReadOnly !== "1")
                .map((y) => { mandatories.push(y.CustomerFieldName) })
        });
        let valid = true;
        mandatories.map((x) => { if (!editedRecord[x]) valid = false })
        if (!valid) {
            message.error(this.getText(13035));
        } else if (!this.props.mobileValidate(_mobile)) {
            message.error(this.getText(13528));
        } else if (!this.state.isTermOk) {
            message.error(this.getText(13515));
            this.setState({ termToggle: true });
        } else {
            this.props.sendAPI(this.api.check_mobile, '_mobile\r' + _mobile, (ob) => {
                if (!ob.data) {
                    this.errorMessage();
                } else if (ob.data === '-1') {
                    message.error(this.getText(13517));
                    this.clearFields(false);
                } else {
                    let lName = editedRecord.CustomerFirstName;
                    let lLastName = editedRecord.CustomerLastName;
                    let lAddress = editedRecord.CustomerContactPersonAddress;
                    let lImage = editedRecord.CustomerPicUrl;

                    let lParams = ob.data === '0' ? editedRecord : { ...editedRecord, key: ob.data, CustomerID: ob.data }

                    let customer = this.parseParamsToApi(lParams);
                    let CustomerTa = "CustTagTagId\r" + this.props.data[this.setting_id].pRegTag // editedRecord.CustomerGroupID.split(",").join("\r") ;

                    let params = [
                        { section: "customer", data: customer },
                        { section: "CustomerTa", data: CustomerTa }
                    ];

                    let custData = {
                        cID: ob.data,
                        lMobile: _mobile,
                        lName: lName,
                        lLastName: lLastName,
                        lAddress: lAddress,
                        lImage: lImage,
                        params: params,
                        isExistGuest: ob.data !== '0' ? true : false
                    }

                    this.props.sendAPI(this.api.regi_code, "_mobile\f_is_reset\r" + _mobile + "\f0", (ob2) => {
                        switch (ob2.data) {
                            case '1': this.setState({ modalRegiCode: true, mobileToRegiCode: _mobile, custData: custData }); break;
                            case '2': this.setState({ custData: custData }, () => { this.regiCodeOk(_mobile) }); break;
                        }
                    }, (e) => { console.error(e) })
                    // });
                }
            }, this.errorMessage)
        }
    }

    handleEdit = () => {
        let editedRecord = this.props.data[this.edit_id];
        let _mobile = editedRecord.CustomerContactPersonMobile;

        let mandatories = [];
        this.props.data[this.view_id].data.map((x) => {
            x.fields
                .filter(f => f.CustomerFieldIsMandatory == '1' && f.CustomerFieldRoleListIsReadOnly !== "1" && f.CustomerFieldName !== 'CustomerContactPersonMobile')
                .forEach((y) => { mandatories.push(y.CustomerFieldName) })
        });
        let valid = true;
        mandatories.map((x) => { if (!editedRecord[x]) valid = false })
        if (!valid) {
            message.error(this.getText(13035));
        } else {
            let lName = editedRecord.CustomerFirstName;
            let lLastName = editedRecord.CustomerLastName;
            let lAddress = editedRecord.CustomerContactPersonAddress;
            let lImage = editedRecord.CustomerPicUrl;

            let params = [
                { section: "customer", data: this.parseParamsToApi(editedRecord) },
                { section: "CustomerTa", data: "CustTagTagId\r" + this.props.data[this.setting_id].pRegTag }
            ];

            this.props.sendAPI(this.api.edit, params, (ob) => {
                console.log(ob.data, "edit cust res:", ob.data.split("\r")[1], 'key:', editedRecord.CustomerID)
                // this.props.sendAPI(this.api.edit_tag_reg, ob.data, () => {
                let cID = ob && ob.data ? ob.data.split("\r")[1] : false;
                if (cID) {
                    this.props.setJson(this.order_fields_id + '.orderFields', {
                        dName: lName + " " + lLastName,
                        dFullAddress: lAddress
                    });
                    this.props.login(lName, lImage, _mobile, ob.data);
                    message.success("הפרטים נשמרו בהצלחה")
                } else {
                    this.errorMessage();
                }
                this.props.onCancel();
                // }, this.errorMessage)
            }, this.errorMessage);
        }
    }

    handleLogin = () => {
        let _mobile = this.state.loginPhone;

        if (!this.props.mobileValidate(_mobile)) {
            message.error(this.getText(13528));
        } else {
            this.props.sendAPI(this.api.regi_code, "_mobile\f_is_reset\r" + _mobile + "\f0", (ob2) => {
                switch (ob2.data) {
                    case '1': this.setState({ modalRegiCode: true, mobileToRegiCode: _mobile, regiCodeToLogin: true }); break;
                    case '2': this.handleLogin2(_mobile) ; break;
                }
            }, (e) => { console.error(e) })
        }
    }

    handleLogin2 = (_phone) => {
        // let _phone = this.state.loginPhone;
        // let _password = this.state.loginPassword;
        let posNumber = this.props.data[this.order_fields_id].dPosNumber;
        // if (!_phone || !_password) {
        //     message.error(this.getText(13035));
        // } else
        // if (!this.props.mobileValidate(_phone)) {
        //     message.error(this.getText(13528));
        // } else {

            let data = "_phone\f_password\r" + _phone + "\f"// + _password
            this.props.sendAPI(this.api.login_site, data, (ob) => {
                let pData = ob.data ? ob.data.split("\f") : [];
                if (pData[0]) {
                    this.props.login(pData[0], pData[1], _phone, pData[2]);
                    // if (!this.props.data[this.order_fields_id].orderFields.dName)
                    this.props.setJson(this.order_fields_id + '.orderFields', {
                        dName: pData[0] + " " + pData[3],
                        dPhone: _phone,
                        cID: pData[2],
                        dFullAddress: pData[4]
                    });
                    this.props.apiMivzaim(pData[2], posNumber);
                } else {
                    // this.setState({ loginError: true })
                    this.clearFields(true)
                    setTimeout(() => {
                        
                        this.props.setJson(this.edit_id, { CustomerContactPersonMobile: _phone })
                    }, 1000);
                };
            }, this.errorMessage)
        // }

    }

    clearFields = (rgiForm) => {
        this.setState({
            termToggle: false,
            isTermOk: false,
            rgiForm: rgiForm,
            loginPhone: '',
            loginPassword: '',
            loginError: false,
        }, () => {
            // if (this.isCustEdit()) {
            this.getFormData(this.api.get_format, { rID: 4 }, this.filterFields);
            // } else {
            //     let fields = this.props.data[this.edit_id];
            //     let fKeys = Object.keys(fields);
            //     let newOb = {}
            //     fKeys.map((x) => {
            //         newOb = { ...newOb, [x]: '' }
            //     })
            //     this.props.setJson(this.edit_id, newOb)
            // }
            setTimeout(() => {
                let loginPhone = document.getElementById("loginPhone")
                if (loginPhone) loginPhone.focus()
            }, 1000);
        })
    }

    isCustEdit = () => {
        if (this.props.isCustEdit) return true;
        let orderFields = this.props.data[this.order_fields_id].orderFields;
        return orderFields && orderFields.cID ? true : false
    }

    regiCodeOk = (mobile) => {
        if (this.state.regiCodeToLogin) {
            this.setState({ modalRegiCode: false, regiCodeToLogin: false }, () => {
                this.handleLogin2(mobile);
            })
            return;
        }
        let s = this.state.custData;
        let posNumber = this.props.data[this.order_fields_id].dPosNumber;

        this.props.setJson(this.order_fields_id + '.orderFields', {
            dName: s.lName + " " + s.lLastName,
            dPhone: mobile,
            cID: s.cID,
        });
        this.props.apiMivzaim(s.cID, posNumber);
        this.props.sendAPI(this.api.edit, s.params, (ob) => {
            this.props.sendAPI(this.api.update_regi, ob.data, () => {
                // this.props.sendAPI(this.api.edit_tag_reg, ob.data, () => {
                this.props.onCancel();
                this.props.login(s.lName, s.lImage, mobile, s.cID);
                // }, this.errorMessage);
            }, this.errorMessage);
        }, this.errorMessage);
    }

    resetPassword = (mobile) => {
        let p = this.state.valueResetPassword;
        this.props.sendAPI(this.api.reset_password, "_mobile\f_password\r" + mobile + "\f" + p, () => {
            this.setState({ loginPhone: '', loginPassword: '', loginError: false }, () => {
                message.success(this.props.getText(13541) + ": " + p);
            })
        }, this.errorMessage)
    }

    render() {
        let isCustEdit = this.isCustEdit();

        let mobile = window.innerWidth <= 700 ? true : false;
        let siteSettings = this.props.data["FORMAT_SITE_SETTINGS"];
        let isStyle3 = siteSettings.pDesktopCategoriesView == '3';
        let isStyle4 = siteSettings.pDesktopCategoriesView == '4';
        let isStyle5 = siteSettings.pDesktopCategoriesView == '5';
        let isStyle6 = siteSettings.pDesktopCategoriesView == '6';

        let formItemStyle = { textAlign: mobile || isStyle4 || isStyle5 ? 'right' : 'left', marginBottom: 30 }
        let inputStyle = { width: mobile ? '95%' : '75%', height: 50, marginRight: 20 }
        let buttonStyle = { margin: 10, width: 140, height: 50, ...this.props.buttonStyle }

        // if(isStyle4) buttonStyle = {...buttonStyle, background: "#BE1E2D"}

        let formFields = this.props.data[this.view_id];
        let resForm = formFields && formFields.data && formFields.data[0] ? formFields.data.map((x) => x ? this.getContainer(x) : "") : "";

        let loginFormFields = (
            <div>
                <FormItem key={"loginPhone"} style={formItemStyle} validateStatus={(!this.state.loginPhone) ? "error" : "success"} required={true} >
                    <span style={(isStyle4 || isStyle5) && !mobile ? { display: "inline-block", width: "20%" } : {}} className={"text_item"}>{this.getText(13048)}</span>
                    <Input
                        className={"text_item"} id={"loginPhone"}
                        style={{ ...inputStyle, textAlign: 'left' }}
                        type={'tel'}
                        value={this.state.loginPhone}
                        onChange={(v) => { this.setState({ loginPhone: v.target.value }) }}
                        onBlur={() => { if (this.state.loginError) this.setState({ loginError: false }) }}
                        // autoComplete="off" // לא עובד
                        readOnly={true}
                        onFocus={e => {
                            e.target.removeAttribute('readonly') // על מנת למנוע השלמה אוטומטית של סיסמאות
                            e.target.select()
                        }}
                    // autoFocus={true}
                    />
                </FormItem>
                {/* <FormItem key={"loginPassword"} style={formItemStyle} validateStatus={(!this.state.loginPassword) ? "error" : "success"} required={true} >
                    <span style={(isStyle4 || isStyle5) && !mobile ? { display: "inline-block", width: "20%" } : {}} className={"text_item"}>{this.getText(13046)}</span>
                    <Input.Password
                        className={"text_item_input_password"}
                        style={{ ...inputStyle, fontSize: 26 }}
                        value={this.state.loginPassword}
                        onChange={(v) => { this.setState({ loginPassword: v.target.value }) }}
                        onBlur={() => { if (this.state.loginError) this.setState({ loginError: false }) }}
                        onFocus={(e) => { e.target.select() }}
                    />
                </FormItem> */}
                {this.state.loginError ?
                    // (<div>
                    <div style={{ color: 'red' }}>{this.getText(13051)}</div>
                    //     <Button style={buttonStyle} onClick={() => {
                    //         this.props.sendAPI(this.api.regi_code, "_mobile\f_is_reset\r" + this.state.loginPhone + "\f1", (ob) => {
                    //             let newState = { valueResetPassword: '' }
                    //             if (ob.data == '1') {
                    //                 newState = { ...newState, modalResetPassword: true }
                    //             } else if (ob.data == '3') {
                    //                 newState = { ...newState, modalNotReg: true }
                    //             }
                    //             this.setState(newState);
                    //         }, (e) => { console.error(e) })
                    //     }}>
                    //         <span className={"text_item"} style={this.props.buttonStyle} >{this.getText(13538)}</span>
                    //     </Button>
                    //     <Button style={buttonStyle} onClick={() => {
                    //         this.setState({ rgiForm: true, loginError: false }, () => {
                    //             this.props.setJson(this.edit_id, { CustomerContactPersonMobile: this.state.loginPhone })
                    //         })
                    //     }}>
                    //         <span className={"text_item"} style={this.props.buttonStyle} >{this.getText(13370)}</span>
                    //     </Button>
                    // </div>)
                    : ''}
            </div>)

        let companyTerm = this.props.data[this.comp_id] ? this.props.data[this.comp_id].term : ""

        if (companyTerm) companyTerm = companyTerm.split("\n").join("<br/>");

        let formStyle = {
            columnWidth: 'auto',
            columnCount: '1',
            padding: 5,
            margin: 5,
        }

        let termToggle = this.state.termToggle;

        let isTermOk = this.state.isTermOk;

        let termHtml = (<div style={{ ...formStyle, fontSize: 10 }} id={"termHtml"}></div>);

        let termElement = document.getElementById("termHtml");

        if (termElement) {
            termElement.innerHTML = companyTerm;
        }
        let sbStyle1 = (isStyle4 || isStyle5) ? { ...buttonStyle, background: "#BE1E2D", color: "white" }
            : isStyle6 ? { ...buttonStyle, background: "#ddc268", color: "black" }
                : buttonStyle;

        let sbStyle2 = (isStyle4 || isStyle5) ? { ...this.props.buttonStyle, background: "#BE1E2D", color: "white" }
            : isStyle6 ? { ...this.props.buttonStyle, background: "#ddc268", color: "black" }
                : this.props.buttonStyle;

        let rgf = this.state.rgiForm;

        let submitButton = (
            <Button style={sbStyle1} onClick={isCustEdit ? this.handleEdit : this.state.rgiForm ? this.handleOk : this.handleLogin}>
                <span className={"text_item"} style={sbStyle2}>{this.getText(isCustEdit ? 14468 : this.state.rgiForm ? 13036 : 13047)}</span>
            </Button>)

        // let forgetButton = rgf ? "" : (
        //     <Button style={buttonStyle} onClick={() => {
        //         this.props.sendAPI(this.api.regi_code, "_mobile\f_is_reset\r" + this.state.loginPhone + "\f1", (ob) => {
        //             let newState = { valueResetPassword: '' }
        //             if (ob.data == '1') {
        //                 newState = { ...newState, modalResetPassword: true }
        //             } else if (ob.data == '3') {
        //                 newState = { ...newState, modalNotReg: true }
        //             }
        //             this.setState(newState);
        //         }, (e) => { console.error(e) })
        //     }}>
        //         <span className={"text_item"} style={this.props.buttonStyle} >{this.getText(13538)}</span>
        //     </Button>)



        let bgTitleStyle = (active) => {
            return active ? {
                color: isStyle3 || isStyle4 || isStyle5 ? "white" : siteSettings.rFonts,
                background: isStyle4 || isStyle5 ? "#BE1E2D" : isStyle6 ? "#ddc268" : "auto",
                cursor: 'pointer'
            } : {
                background: isStyle3 ? "black" : siteSettings.rTitle,
                color: isStyle3 ? "white" : isStyle4 || isStyle5 ? "#BE1E2D" : isStyle6 ? "#ddc268" : siteSettings.rFonts,
                cursor: 'pointer'
            }
        }

        let titleBar = isCustEdit ? this.props.getClassNameStyle("title_block", bgTitleStyle(true), this.getText(14467))
            : this.props.isLoginRequired ? this.props.getClassNameStyle("title_block", bgTitleStyle(true), this.getText(13044))
                : (<Row>
                    <Col span={12}>
                        {this.props.getClassNameStyle("title_block", bgTitleStyle(!rgf), this.getText(13044), () => { if (rgf) this.clearFields(false) })}
                    </Col>
                    <Col span={12}>
                        {this.props.getClassNameStyle("title_block", bgTitleStyle(rgf), this.getText(13030), () => { if (!rgf) this.clearFields(true) })}
                    </Col>
                </Row>);

        let buttonColors = this.props.buttonStyle;

        let buttonsStyle = {
            margin: 10,
            width: 140,
            height: 50,
            ...buttonColors,
        }

        let resTermDiv = isCustEdit ? "" : (<Card
            title={(<div onClick={() => { this.setState({ termToggle: termToggle ? false : true }) }}>
                <div className={"text_item"}>{this.getText(13037)}</div>
                <label style={!isTermOk && !termToggle ? { color: '#f5222d', fontSize: 12 } : { display: 'none' }}>
                    {' * ' + this.getText(13034)}
                </label>
            </div>)}
            hoverable={true}>
            <span style={{ display: termToggle ? "block" : "none" }}>
                {termHtml}
                <Checkbox
                    style={{ padding: 10, backgroundColor: 'red', color: 'white' }}
                    checked={isTermOk}
                    onChange={(e) => { this.setState({ isTermOk: e.target.checked }) }}>
                    {this.getText(13038)}
                </Checkbox>
            </span>
        </Card>)

        let viewForm = (<Form className="data-entry-form" layout='vertical' style={formStyle}>
            {this.state.rgiForm ? [resForm, resTermDiv] : loginFormFields}
        </Form>)

        let viewModals = [
            <RegiCode
                visible={this.state.modalRegiCode}
                onCancel={() => { this.setState({ modalRegiCode: false }) }}
                getText={this.props.getText}
                getClassNameStyle={this.props.getClassNameStyle}
                sendAPI={this.props.sendAPI}
                mobileInput={this.state.mobileToRegiCode}
                mobileOnChange={(e) => { this.setState({ mobileToRegiCode: e.target.value }) }}
                regiCodeOk={this.regiCodeOk}
                dataActions={this.props.dataActions}
                data={this.props.data}
            />,
            <RegiCode
                visible={this.state.modalResetPassword}
                onCancel={() => { this.setState({ modalResetPassword: false }) }}
                getText={this.props.getText}
                getClassNameStyle={this.props.getClassNameStyle}
                sendAPI={this.props.sendAPI}
                mobileInput={this.state.loginPhone}
                mobileOnChange={(e) => { this.setState({ loginPhone: e.target.value }) }}
                passwordInput={this.state.valueResetPassword}
                passwordOnChange={(e) => { this.setState({ valueResetPassword: e.target.value }) }}
                regiCodeOk={this.resetPassword}
                dataActions={this.props.dataActions}
                data={this.props.data}
            />,
            <Modal
                title={this.props.getClassNameStyle("title_block")}
                visible={this.state.modalNotReg} destroyOnClose={true}
                width={this.mobile ? '100%' : 300}
                onCancel={() => { this.setState({ modalNotReg: false }) }}
                footer={<Button style={buttonsStyle} onClick={() => { this.setState({ modalNotReg: false }) }}>
                    <span className={"text_item"} style={buttonColors}>{this.getText(14301)}</span>
                </Button>}>
                <div className={"text_item"}>
                    <h2 style={{ textAlign: 'center' }}>{this.getText(14302)}</h2>
                </div>
            </Modal >
        ]

        if (this.props.isCustEdit) {
            return (<div>
                <Form className="data-entry-form" layout='vertical' style={formStyle}>
                    {resForm}
                </Form>
                {viewModals}
                <div style={{ float: "left" }}>{submitButton}</div>
            </div>)
        } else {
            return (<Modal
                visible={this.props.visible}
                onCancel={() => {
                    if (!this.props.isLoginRequired) {
                        this.props.onCancel()
                        this.clearFields(this.state.rgiForm)
                    }
                }}
                destroyOnClose={true}
                width={window.innerWidth < 1000 ? "95%" : 800}
                title={titleBar}
                className={"cust_registration"}
                // footer={[submitButton, forgetButton]}
                footer={[submitButton]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>
                {viewForm}
                {viewModals}
            </Modal>)
        }

    }
}
export default CustomerRegistration;

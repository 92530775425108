
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Input, Form, Radio } from "antd";
//import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";

const FormItem = Form.Item;
//const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class CateringTickets extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            itemList: '',
            branchList: '',
            dateFrom: null,
            dateTo: null,
            isDetailed: '1',
            isPerBranches: '1',
        };

        this.selectors = [
            { id: "DINING_CARDS_ITEMS", api: "get_dining_cards_items" },
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" },
        ];

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(14629);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    createDatePicker = (label, field) => {
        return (<FormItem label={this.getText(label)}>
            <DateTimePicker
                format="DD/MM/YYYY"
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                minDate={field == 'dateTo' ? this.state.dateFrom : this.state.dateTo ? moment(this.state.dateTo, "YYYY-MM-DD").add(-1, 'y') : null}
                maxDate={field == 'dateFrom' ? this.state.dateTo : this.state.dateFrom ? moment(this.state.dateFrom, "YYYY-MM-DD").add(1, 'y') : null}
            />
        </FormItem>)
    }

    createGenericSelector = (label, field, index) => {
        return (<FormItem label={this.getText(label)}>
            <GenericSelector {...this.props} {...this.selectors[index]} multi
                value={this.state[field] ? this.state[field].split(',') : []}
                onChange={(e) => { this.setState({ [field]: e ? e.join(',') : '' }) }}
            />
        </FormItem>)
    }

    createRadioGroup = (label, field, text1, text0) => {
        return (<FormItem label={this.getText(label)}>
            <Radio.Group value={this.state[field]} onChange={(e) => { this.setState({ [field]: e.target.value }) }}>
                <Radio value={'1'}>{this.getText(text1)}</Radio>
                <Radio value={'0'}>{this.getText(text0)}</Radio>
            </Radio.Group>
        </FormItem>)
    }


    render() {

        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;

        let params = {
            ...this.state,
            itemList: encodeURIComponent(this.state.itemList),
            reportName: this.state.isDetailed == 1 ? "reports/DiningCardsDetailed.pdf" : "reports/DiningCards.pdf",
        };


        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={this.getText(14630)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: window.innerWidth < 1200 ? '95%' : 1100 }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 18}>
                        {this.createGenericSelector(19184, "itemList", 0)}
                        {this.createGenericSelector(19185, "branchList", 1)}
                        <Row>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14632, 'dateFrom')}</Col>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14633, 'dateTo')}</Col>
                        </Row>
                    </Col>
                    <Col span={mobile ? 24 : 6}>
                        {this.createRadioGroup(19186, "isDetailed", 19188, 19189)}
                        {this.createRadioGroup(19187, "isPerBranches", 19190, 19191)}
                        <ReportTypeButton
                            {...this.props}
                            params={params}
                            branchesField={'branchList'}
                            datesToFavorites={[
                                { field: 'dateFrom', label: this.getText(14632) },
                                { field: 'dateTo', label: this.getText(14633) },
                            ]}
                            favoriteSave
                        />
                    </Col>
                </Row>

            </Card>
        </div >
        );
    }
}
export default CateringTickets;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
import { Button, Select, } from "antd";
import TableIDs from '../../data/TableIDs.js';

const { Option } = Select;
// const recaptchaRef = React.createRef();

type Props = {
    isFromPos: Boolean,
    isBackOffice: Boolean,
    loginPhone: String,
    loginName: String,
    loginImage: String,
    companyCode: String,
    companyName: String,
    telephone: String,
    menuId: Number,
    siteSettings: Object,
    orderData: Object,
    logout: Function,
    regiModal: Function,
    getClassNameStyle: Function,
    getText: Function,
}

class FoodOrdersHeader extends Component<Props, State> {

    mobile = window.innerWidth <= 700 ? true : false;
    getText = this.props.getText;

    imgUrl = (x) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const baseUrl = isDev ? "http://147.235.163.248" : origin
        return  baseUrl + "/uploads/" + this.props.companyCode + "/" + x;
    }

    getFontStyle2 = (className) => {
        return className + (this.props.siteSettings.pDesktopCategoriesView == '2' ? " font_style_2" : "");
    }

    changeLang = () => {
        if (window.location.search) {
            let fullParams = window.location.search.split('&');
            let langIndex = fullParams.findIndex(f => f.split("=")[0] === 'lang');
            let newParams = [...fullParams];

            let value = fullParams[langIndex].split("=")[1];
            let onChange = (e) => {
                newParams[langIndex] = 'lang=' + e;
                window.location.search = newParams.join('&');
            }

            return (<div className={"item_header"} style={{ paddingTop: 15 }}>
                <div id="language_icon"></div>
                <Select
                    suffixIcon={(<span><div id="slide_button" /></span>)}
                    id="language_field"
                    key={"language_field"}
                    value={value}
                    onChange={onChange}>
                    {this.props.data[TableIDs.genericSelectorLanguages].dataset.map((x) => {
                        return (<Option value={x.code}>{x.name}</Option>)
                    })}
                </Select>
            </div>)
        } else {
            return (<span />)
        }
    }

    render() {
        const {
            isFromPos, isBackOffice,
            companyName, siteSettings, orderData, telephone, loginPhone, loginName, loginImage, menuId,
            logout, regiModal, getClassNameStyle
        } = this.props

        const { orderType, orderFields } = orderData;

        let pLogoHeader = siteSettings.pLogoHeader;

        let telStyle = (id) => {
            return (<a href={'tel:' + telephone}>
                <div id={id} style={{ color: siteSettings.rFonts }}>
                    <b>{telephone}</b>
                </div>
            </a>)
        }

        let logoutButton = loginPhone ?
            (<div className={"item_header"} style={{ textAlign: 'left', marginTop: 10 }}>
                <Button size="small" style={{ color: siteSettings.rFonts }} ghost onClick={logout}
                >{this.getText(13049)}</Button>
            </div>)
            : '';

        let userImageOrIcon = loginImage ?
            (<img alt="userImg" src={this.imgUrl(loginImage)} style={{ borderRadius: '50%' }} id="user_image" />)
            : (<div id="knisa_icon" />);

        let userProfileDiv = (<div className={"item_header"}>

            <span onClick={() => { regiModal(loginPhone ? true : false) }}>
                {userImageOrIcon}
            </span>

            <div id="guest" className={this.getFontStyle2('')} style={{ color: siteSettings.rFonts }}>
                {this.getText(13561)}
                <br />
                <span style={{ fontSize: 25, fontWeight: 'bold' }}>
                    {loginName ? loginName : this.getText(13050)}
                </span>
                <span style={{ fontSize: 15, display: !orderType || loginName ? 'none' : 'block' }}>
                    {orderFields && orderFields.dName ? orderFields.dName : ""}
                </span>
            </div>
        </div>)

        let compNameDiv = getClassNameStyle("logo_text", {}, companyName);
        let compLogoDiv = (<span style={{cursor:"pointer"}} onClick={this.props.onClickLogo}>
            {pLogoHeader ?
                (<img src={this.imgUrl(pLogoHeader)} alt={'Logo'} title={companyName} className={"logo_agala_2"} />)
                : (<div className={"logo_agala"}></div>)}
        </span>)

        let telHeaderDiv = (<div className={"item_header"}>
            <div id={"tel_icon"}></div>
            {telStyle("telephone")}
        </div>)

        let verticalTopStyle = {}
        if (siteSettings.pDesktopCategoriesView == '2' && !this.mobile && menuId == 1 && !isBackOffice) {
            verticalTopStyle = { position: 'fixed', top: 0, width: '100%', zIndex: 1000 }
        }

        if (isFromPos) {
            return (<div style={{ background: '#e6e7e8', height: siteSettings.pDesktopCategoriesView == '2' ? 125 : 65, ...verticalTopStyle }}>
                <div style={{ padding: 20, float: 'left' }}>{process.env.REACT_APP_VERSION}</div>
                <span style={{ fontSize: 40, fontWeight: 'bold' }}>
                    {userImageOrIcon}
                    {loginName}
                </span>
            </div>)
        } else if (siteSettings.pDesktopCategoriesView == '3') {
            let hStyle = { display: "inline-block", verticalAlign: "text-top" }
            return (<div style={{ background: siteSettings.rTitle ? siteSettings.rTitle : 'lightgrey', fontFamily: "FbTipograf" }}>
                <div style={{ ...hStyle, width: "50%", textAlign: 'right' }}>
                    {compLogoDiv}
                    <div style={{ ...hStyle, width: 28, height: 28, margin: 20, marginRight: 40, marginTop: 32 }} id={"tel_icon_2"}></div>
                    <a href={'tel:' + telephone}>
                        <div style={{ ...hStyle, width: 300, color: siteSettings.rFonts, marginTop: 25, fontSize: 25 }}>
                            <b>{telephone}</b>
                        </div>
                    </a>
                </div>
                <div style={{ ...hStyle, width: "50%", textAlign: 'left' }}>
                    {logoutButton}



                    {!loginName && orderType && orderFields && orderFields.dName ?
                        (<div style={{ ...hStyle, width: 300, fontSize: 15, marginTop: 30 }}>{orderFields.dName}</div>)
                        : (<div style={{ ...hStyle, width: 300, fontSize: 25, fontWeight: 'bold', marginTop: 25 }}>
                            {loginName ? loginName : this.getText(13050)}
                        </div>)}

                    <div style={{ ...hStyle, width: 60, margin: 10, marginLeft: 30 }} onClick={() => { regiModal(loginPhone ? true : false) }}>
                        {loginImage ?
                            (<img alt="userImg" src={this.imgUrl(loginImage)} style={{ borderRadius: '50%' }} />)
                            : (<div id={"knisa_icon_2"} />)}
                    </div>
                </div>
            </div>)
        } else {
            return (<div id="header" style={{ background: siteSettings.rTitle ? siteSettings.rTitle : '#404041', ...verticalTopStyle }}>
                <div id="logo_header">
                    {compNameDiv}
                    {this.mobile ? "" : compLogoDiv}
                </div>

                {this.mobile ?
                    (<div id="item_headers">
                        <div className={"item_header2"} style={{ width: window.innerWidth * 0.55, textAlign: 'right' }}>
                            {compLogoDiv}
                        </div>
                        <div className={"item_header2"} style={{ width: window.innerWidth * 0.4, textAlign: 'left' }}>
                            {telHeaderDiv}
                        </div>
                        <div className={"item_header2"} style={{ width: window.innerWidth * 0.55, textAlign: 'right' }}>
                            {logoutButton}
                            {userProfileDiv}
                        </div>

                        <div className={"item_header2"} style={{ width: window.innerWidth * 0.4, textAlign: 'left' }}>
                            {this.changeLang()}
                        </div>
                    </div>)
                    : (<div id="item_headers">
                        <div className={"item_header2"}>
                            {logoutButton}
                            {userProfileDiv}
                        </div>
                        <div className={"item_header2"}>
                            {telHeaderDiv}
                            {this.changeLang()}
                        </div>
                    </div>)}

            </div >)
        }
    }
}

export default FoodOrdersHeader;
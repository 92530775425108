/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../App.css";
import "../virtualShop.css";
import "../custOrder.css";
import { Collapse } from "antd";

type Props = {
    defaultSection: String | Number,
    compData: Object,
    formTitle: String,
    sections: [
        { title: String, key: String | Number, content: Object }
    ]
}

class FormLinkStyle extends Component<Props, State> {

    state = {
        sectionView: null,
    }

    componentDidMount() {
        if (this.props.defaultSection)
            this.setState({ sectionView: this.props.defaultSection })
    }

    getTitle = (title, key, content, link) => {
        let isActive = this.state.sectionView === key;

        let arrowIconFrameStyle = {
            background: this.props.colorForm ? this.props.colorForm : '#ff4d4f',
            width: 35,
            height: 35,
            marginTop: -35,
            marginLeft: -35,
            float: 'left',
            borderRadius: '50%',
        }
        let arrowIconStyle = {
            fontSize: 35,
            color: 'white',
            paddingTop: 5,
            paddingRight: 11,
        }

        return (<Collapse.Panel key={key} header={(
            <div className={"text_item"} onClick={() => {
                if (link) {
                    window.open(link, '_blank');
                } else {
                    this.setState({ sectionView: isActive ? null : key });
                }
            }}>
                <h2>{title}</h2>
                {/* <div className={isActive ? 'arrowdown_icon' : 'arrow_icon'} /> */}
                <div style={arrowIconFrameStyle}>
                    {isActive ?
                        (<div style={arrowIconStyle}>-</div>)
                        : (<div style={arrowIconStyle}>+</div>)}
                    {/* (<div style={{ ...arrowIconStyle, transform: 'scale(1, 0.6)', paddingRight: 8 }}>&darr;</div>)
                        : (<div style={{ ...arrowIconStyle, transform: 'scale(0.6, 1)' }}>&larr;</div>)} */}
                </div>
            </div>)}>{content}</Collapse.Panel>)
    }

    render() {
        let cData = this.props.compData;

        return (<div>
            {/* <div className={"logo_frame"}> */}
            <div style={{ background: this.props.colorForm ? this.props.colorForm : '#ff4d4f' }}>
                <h1 style={{ textAlign: 'center', padding: 10 }}>
                    {cData.logo ? (<img src={cData.logo} alt={'Logo'} title={cData.name} />) : ""}
                    <div style={{ color: 'white' }}>{cData.name + (cData.desc && cData.desc !== cData.name ? " - " + cData.desc : "")}</div>
                </h1>
            </div>
            <h2 style={{ padding: 10, textAlign: 'center' }}>
                {this.props.formTitle}
            </h2>
            <div style={{ textAlign: 'center' }}>
                {this.props.topBanner ? (<img src={this.props.topBanner} width={window.innerWidth > 600 ? 600 : '100%'} />) : ""}
            </div>
            <Collapse expandIcon={() => (<div />)} activeKey={this.state.sectionView}>
                {this.props.sections ? this.props.sections.map(x => this.getTitle(x.title, x.key, x.content)) : ""}
                {this.props.linkToDoc ? this.getTitle(this.props.linkToDoc.title, this.props.linkToDoc.key, '', this.props.linkToDoc.link) : ""}
            </Collapse>
            <div style={{ textAlign: 'center' }}>
                {this.props.bootomBanner ? (<img src={this.props.bootomBanner} width={window.innerWidth > 600 ? 600 : '100%'} />) : ""}
            </div>
        </div>)
    }
}

export default FormLinkStyle;

import React from "react";
import { Card, DatePicker, Form, InputNumber, Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import SelectorSalesAlerts from "./SelectorSalesAlerts";
import SelectorTaxType from "./SelectorTaxType";
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import moment from "moment";
import LangContext from "../contextProvider/LangContext";
import GenericSelector from "./GenericSelector";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

// const codeInvalid = () => {
//     message.error('קוד הפריט כבר קיים במסד הנתונים');
// };
// const codeValid = () => {
//     message.success('קוד פריט פנוי');
// };
// const codeExists = () => {
//     message.warning('קוד פריט כבר קיים במסד הנתונים אך בסטטוס לא פעיל');
// };

//type State = {}
class ModalCreateEditSuppliers extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.id = 'CREATE_SUPPLIER';

        this.selectors = [
            { id: "EDI_SUPP_LIST", api: "get_edi_supp_list" },
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" }
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })


        //if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
        //    props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        //}

        this.api = {
            get: "get_supplier_by_id",
            edit: "edit_create_supplier",
            //selector: "get_customer_tags_list",
            //get_tags: "get_customer_tags_by_id",
            //delete: "delete_customer", //"delete_priceList",
        }

        this.actionButtons = [/*{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }*/];

        this.dataSource = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    // componentDidMount() {
    //     document.title = "Cash On Tab - ספקים";
    // }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditSupplier);
    }

    handleOk = () => {
        //this.clear();
    }

    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditSupplier);
    }

    handleClear = () => {
        //this.clear();
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
        if (this.isNameValid() === 'success') {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataJustSaveEdit,
                args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
            });
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataGetRequested,
                args: ["SUPPLIERS_MAIN", this.props.user.companyCode, this.props.user.token, "get_suppliers_table_list"]
            });
            this.props.uiActions.hideModal(TableIDs.modalCreateEditSupplier);
        }
        else {
            message.warning(this.getText(10550));
        }
    }

    isNameValid = () => {
        const name = this.props.data[this.id].editedRecord['mName'];
        return (name === '' || name === null) ? 'error' : 'success';
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


    render() {
        const { loading } = this.state;
        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10551)}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'90%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                data={this.props.data} dataAction={this.props.dataAction}>

                <Form id="create_edit_supplier_form" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '3' : '1' }}
                    onSubmit={this.handleSaveChanges}>

                    {!parseInt(this.props.data[this.id].editedRecord['mID']) ?
                        <FormItem
                            key={'mIdNew'} label={this.getText(18963)}
                            style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                            <InputNumber
                                value={this.props.data[this.id].editedRecord['mIdNew']}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { ['mIdNew']: value });
                                }}
                                onBlur={() => {
                                    const { mIdNew } = this.props.data[this.id].editedRecord;
                                    this.sendAPI("check_supp_new_id", mIdNew, ob => {
                                        if (ob.data !== '0') {
                                            message.error(this.getText(18964))
                                            this.props.dataActions.genericDataSetEdit(this.id, { ['mIdNew']: "" });
                                        }
                                    })
                                }} />
                        </FormItem>
                        : ""}
                    <FormItem
                        key={'mName'} label={this.getText(10552)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}

                        validateStatus={this.isNameValid()}
                        // hasFeedback={false}
                        //help={"בלה בלה"}
                        extra={<label style={(this.isNameValid() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>{this.getText(10553)}</label>}
                        required={true}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mName']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mName']: value.target.value });
                            }}
                            onBlur={() => {
                                const { mID, mName } = this.props.data[this.id].editedRecord;
                                this.sendAPI("check_supp_name", "_id\f_name\r" + mID + "\f" + mName, ob => {
                                    if (parseInt(ob.data)) {
                                        message.error(this.getText(18713))
                                        this.props.dataActions.genericDataSetEdit(this.id, { ['mName']: "" });
                                    }
                                })
                            }} />
                    </FormItem>
                    <FormItem
                        key={'mRemarks'} label={this.getText(10554)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <TextArea
                            value={this.props.data[this.id].editedRecord['mRemarks']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mRemarks']: value.target.value });
                            }}
                            autosize={{ minRows: 2, maxRows: 5 }}
                        />
                    </FormItem>
                    <FormItem
                        key={'mContactPersonName'} label={this.getText(10555)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mContactPersonName']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mContactPersonName']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mContactPersonPhone'} label={this.getText(10556)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mContactPersonPhone']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mContactPersonPhone']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mContactPersonMobile'} label={this.getText(10557)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mContactPersonMobile']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mContactPersonMobile']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mContactPersonFax'} label={this.getText(10558)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mContactPersonFax']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mContactPersonFax']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mContactPersonEmail'} label={this.getText(10559)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mContactPersonEmail']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mContactPersonEmail']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mContactPersonAddress'} label={this.getText(10560)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mContactPersonAddress']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mContactPersonAddress']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mAccountingCode'} label={this.getText(10561)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            //disabled={true}
                            value={this.props.data[this.id].editedRecord['mAccountingCode']}
                            //value={'0'}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mAccountingCode']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mVATID'} label={this.getText(10562)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mVATID']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mVATID']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mIsActive'} label={this.getText(10563)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Checkbox
                            //onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsIncludeVat", record); }}
                            onChange={(e) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mIsActive']: (e.target.checked) ? 1 : 0 });
                            }}
                            checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                            defaultChecked={false}
                            //disabled={!(this.props.data[this.id].editing === record.key)} />
                            disabled={false} />
                    </FormItem>
                    <FormItem
                        key={'mEDI'} label={this.getText(17253)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <GenericSelector
                            {...this.props}
                            {...this.selectors[0]}
                            modalStyle={{
                                title: this.getText(17306),
                                col1Title: this.getText(17307),
                                col2Title: this.getText(17308),
                                cancelText: this.getText(17309),
                                clearText: this.getText(17310),
                            }}
                            value={this.props.data[this.id].editedRecord['mEDI']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mEDI']: value });
                            }} />
                    </FormItem>
                    <FormItem
                        key={'mIsInternal'} label={this.getText(19148)}
                        style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Checkbox
                            //onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsIncludeVat", record); }}
                            onChange={(e) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mIsInternal']: (e.target.checked) ? 1 : 0 });
                            }}
                            checked={(this.props.data[this.id].editedRecord['mIsInternal'] == 1) ? true : false}
                            defaultChecked={false}
                            //disabled={!(this.props.data[this.id].editing === record.key)} />
                            disabled={false} />
                    </FormItem>
                    {this.props.data[this.id].editedRecord['mIsInternal'] == 1 ?
                        <FormItem
                            key={'mOrdersBranch'} label={this.getText(19149)}
                            style={{ marginBottom: 8 }} help={''} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                        //validateStatus={}
                        >
                            <GenericSelector
                                {...this.props}
                                {...this.selectors[1]}
                                value={this.props.data[this.id].editedRecord['mOrdersBranch']}
                                onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { ['mOrdersBranch']: value });
                                }} />
                        </FormItem>
                        : ""}
                </Form>
                <div className="action_footer">
                    <Button
                        type="primary" htmlType="submit" form="create_edit_supplier_form"
                        loading={this.props.ui.workInProgress}
                        className={"form-button-save"} icon={"check"}>{this.getText(10564)}</Button>
                </div>

            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                { _id: this.props.ui.data },
                this.api.get
            );
            // set defaults on CREATE
            if (this.props.ui.data == 0 || this.props.ui.data === undefined) {
                setTimeout(
                    () => this.props.dataActions.genericDataSetEdit(this.id, { ['mIsActive']: 1 }),
                    500);
            }

        }
    }
}

export default ModalCreateEditSuppliers;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
// import { Row, Col, Tooltip } from "antd";
import DividedImage from "../../components/DividedImage";
import PizzaButtons from "./PizzaButtons";
import { Row, Col } from "antd";
// import baseImg from "../../skins/pizza/base.png";

// type Props {
//     extraList: [{ eID, eItemCode, eItemName, ePrice, eType, eIsChecked, eLocals, eImage }],
//     baseImg: String,
//     areasQty: Number,
//     companyCode: String,
// }

class PizzaView extends Component {
    sizePerHeight = window.innerHeight / 1100;

    mobile = window.innerWidth <= 700;

    state = {
        localsToCheck: { r1: true, r2: true, r3: true, r4: true },
    }

    getFrameImg = (width, height, rotate, image, top, left, fullWidth) => {
        let props = { width, height, rotate, image, top, left, fullWidth }
        // if(!props.width) props.image = "";
        console.log("test DividedImage", props)
        return (<DividedImage {...props} key={image} />)
    }

    imgUrl = (x) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const baseUrl = isDev ? "http://147.235.163.248" : origin
        return baseUrl + "/uploads/" + this.props.companyCode + "/" + x;
    }

    addContent = () => {
        // let divContent = document.getElementById('add-content');
        let pizzaView = document.getElementById('pizza-view');
        let positions = pizzaView ? pizzaView.getBoundingClientRect() : {};

        let res = [];

        let fullData = this.props.extraList ? [...this.props.extraList.data2, ...this.props.extraList.data3, ...this.props.extraList.data4] : []

        fullData.forEach((x) => {
            let localMap = this.getPizzaMap(x.eLocals);

            let width = positions.width;
            let height = positions.height;
            let image = this.imgUrl(x.ePizzaImg);
            let top = this.props.visible ? positions.top : 0;
            let left = this.props.visible ? positions.left : 0;

            if (localMap.r1 && localMap.r2 && localMap.r3 && localMap.r4) {
                res.push(this.getFrameImg(width, height, 0, image, top, left, width))
            } else if (localMap.r1 && localMap.r2 && !localMap.r3 && !localMap.r4) {
                res.push(this.getFrameImg(width / 2, height, 0, image, top, left + (width / 2), width))
            } else if (!localMap.r1 && !localMap.r2 && localMap.r3 && localMap.r4) {
                res.push(this.getFrameImg(width / 2, height, 180, image, top, left, width))
            } else {
                if (localMap.r1) res.push(this.getFrameImg(width / 2, height / 2, 0, image, top, left + (width / 2), width))
                if (localMap.r2) res.push(this.getFrameImg(width / 2, height / 2, 90, image, top + (height / 2), left + (width / 2), width))
                if (localMap.r3) res.push(this.getFrameImg(width / 2, height / 2, 180, image, top + (height / 2), left, width))
                if (localMap.r4) res.push(this.getFrameImg(width / 2, height / 2, 270, image, top, left, width))
            }
        });

        return res
    }

    getPizzaMap = (localList) => {
        let res = { r1: false, r2: false, r3: false, r4: false }

        if (localList) {
            switch (this.props.areasQty) {
                case 1:
                    if (localList.indexOf(1) > -1) res = { r1: true, r2: true, r3: true, r4: true }
                    break;

                case 2:
                    if (localList.indexOf(1) > -1) res = { ...res, r1: true, r2: true }
                    if (localList.indexOf(2) > -1) res = { ...res, r3: true, r4: true }
                    break;

                case 4:
                    if (localList.indexOf(1) > -1) res = { ...res, r1: true }
                    if (localList.indexOf(2) > -1) res = { ...res, r2: true }
                    if (localList.indexOf(3) > -1) res = { ...res, r3: true }
                    if (localList.indexOf(4) > -1) res = { ...res, r4: true }
                    break;
            }
        }

        return res;
    }

    replaceMap = (prevLocals) => {
        let prevMap = this.getPizzaMap(prevLocals);
        let localMap = this.state.localsToCheck;

        if (localMap.r1 && localMap.r2 && localMap.r3 && localMap.r4) {
            let isChecked = prevMap.r1 && prevMap.r2 && prevMap.r3 && prevMap.r4 ? true : false;
            prevMap = { r1: !isChecked, r2: !isChecked, r3: !isChecked, r4: !isChecked }
        } else if (localMap.r1 && localMap.r2 && !localMap.r3 && !localMap.r4) {
            let isChecked = prevMap.r1 && prevMap.r2 ? true : false;
            prevMap = { ...prevMap, r1: !isChecked, r2: !isChecked }
        } else if (!localMap.r1 && !localMap.r2 && localMap.r3 && localMap.r4) {
            let isChecked = prevMap.r3 && prevMap.r4 ? true : false;
            prevMap = { ...prevMap, r3: !isChecked, r4: !isChecked }
        } else {
            if (localMap.r1) prevMap = { ...prevMap, r1: !prevMap.r1 }
            if (localMap.r2) prevMap = { ...prevMap, r2: !prevMap.r2 }
            if (localMap.r3) prevMap = { ...prevMap, r3: !prevMap.r3 }
            if (localMap.r4) prevMap = { ...prevMap, r4: !prevMap.r4 }
        }

        return prevMap;
    }

    setNewData = (extraData, typeIndex, typeNum, localMap) => {
        let res = [];

        switch (this.props.areasQty) {
            case 1:
                if (localMap.r1 && localMap.r2 && localMap.r3 && localMap.r4) res.push(1);
                break;

            case 2:
                if (localMap.r1 && localMap.r2) res.push(1);
                if (localMap.r3 && localMap.r4) res.push(2)
                break;

            case 4:
                if (localMap.r1) res.push(1);
                if (localMap.r2) res.push(2);
                if (localMap.r3) res.push(3);
                if (localMap.r4) res.push(4);
                break;
        }

        this.props.onChange({ ...extraData, typeIndex, typeNum, eIsChecked: res.length > 0 ? 1 : 0, eLocals: res });
    }

    getPizzaButton = (localsToCheck, text) => {
        let bStyle = {
            width: this.mobile ? 60 : 100 * this.sizePerHeight,
            height: this.mobile ? 60 : 100 * this.sizePerHeight,
            border: "5px solid " + this.props.btnColor,
            borderRadius: '50%',
            cursor: 'pointer',
            background: this.props.btnColor,
            textAlign: 'center'
        }

        let background = 'white';
        let ls = this.state.localsToCheck;
        let deg = '';

        if (ls.r1 === localsToCheck.r1 && ls.r2 === localsToCheck.r2 && ls.r3 === localsToCheck.r3 && ls.r4 === localsToCheck.r4) background = '#f2f2f2';
        if (!localsToCheck.r1 && !localsToCheck.r2) deg = '-90deg';
        if (!localsToCheck.r3 && !localsToCheck.r4) deg = '90deg';

        if (deg) bStyle = { ...bStyle, background: 'linear-gradient( ' + deg + ', white, white 50%, ' + this.props.btnColor + ' 50%)' }

        return (<Col span={8} style={{ padding: '5%', background }}>
            <div style={bStyle} onClick={() => { this.setState({ localsToCheck }) }} />
            <div className={this.props.getFontStyle2('text_item')} style={{ textAlign: 'center', fontSize: this.mobile ? 18 : 30 * this.sizePerHeight }}>{this.props.getText(text)}</div>
        </Col >)
    }

    getExtrasView = (data, title, num) => {
        let pizzaExtrasStyle = {
            display: 'inline-block',
            verticalAlign: 'text-top',
            width: this.mobile ? '48%' : 150 * this.sizePerHeight,
            padding: 10 * this.sizePerHeight,
            textAlign: 'center',
            cursor: 'pointer'
        }

        let pizzaExtrasImgStyle = {
            width: 83 * this.sizePerHeight,
            height: 83 * this.sizePerHeight,
            borderRadius: '50%',
        }

        return (<div>
            {data.length ? (<div className={this.props.getFontStyle2("text_item")} style={{
                textAlign: 'center',
                color: this.props.isStyle6 ? '#ddc268' : '#be1e2d',
                fontSize: 34 * this.sizePerHeight
            }}>{title}</div>) : ""}
            {data.map((x, i) => {
                if (this.props.isStyle4 || this.props.isStyle5) {
                    let checkedColor = "#BE1E2D";
                    let uncheckedColor = "#CCCCCC";
                    let lMap = this.getPizzaMap(x.eLocals);
                    let rChecked = lMap.r1 && lMap.r2;
                    let lChecked = lMap.r3 && lMap.r4;

                    let bStyle = {
                        width: this.mobile ? 20 : 30,
                        height: this.mobile ? 20 : 30,
                        border: "2px solid " + uncheckedColor,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        background: uncheckedColor,
                        textAlign: 'center',
                        cursor: "pointer",
                        display: "inline-block",
                        margin: this.mobile ? 2 : 5,
                    }

                    let bStyle1 = { ...bStyle }
                    let bStyle2 = { ...bStyle, background: 'linear-gradient(90deg, white, white 50%, ' + uncheckedColor + ' 50%)' }
                    let bStyle3 = { ...bStyle, background: 'linear-gradient(-90deg, white, white 50%, ' + uncheckedColor + ' 50%)' }

                    if (rChecked && lChecked) bStyle1 = { ...bStyle, background: checkedColor, border: "2px solid " + checkedColor }
                    if (rChecked && !lChecked) bStyle2 = { ...bStyle, background: 'linear-gradient(90deg, white, white 50%, ' + checkedColor + ' 50%)', border: "2px solid " + checkedColor }
                    if (!rChecked && lChecked) bStyle3 = { ...bStyle, background: 'linear-gradient(-90deg, white, white 50%, ' + checkedColor + ' 50%)', border: "2px solid " + checkedColor }

                    let btn1Check = !(rChecked && lChecked)
                    let btn2Check = !rChecked || lChecked
                    let btn3Check = !lChecked || rChecked

                    return (<Row>
                        <Col span={3}><img style={{ borderRadius: '50%', width: "50%" }} src={this.imgUrl(x.eImg)} /></Col>
                        <Col span={12} style={{ fontSize: this.mobile ? 16 : 22, paddingRight: 10, fontWeight: "bold", textAlign: "right" }}>{x.eItemName}</Col>
                        <Col span={3}>{this.props.nisFormat(x.ePrice, 0, true)}</Col>
                        <Col span={6}>
                            <div style={bStyle1} onClick={() => { this.setNewData(x, i, num, { r1: btn1Check, r2: btn1Check, r3: btn1Check, r4: btn1Check }) }} />
                            <div style={bStyle2} onClick={() => { this.setNewData(x, i, num, { r1: btn2Check, r2: btn2Check, r3: false, r4: false }) }} />
                            <div style={bStyle3} onClick={() => { this.setNewData(x, i, num, { r1: false, r2: false, r3: btn3Check, r4: btn3Check }) }} />
                        </Col>
                    </Row>)
                } else {
                    return (<div style={pizzaExtrasStyle} onClick={() => { this.setNewData(x, i, num, this.replaceMap(x.eLocals)) }}>
                        <img style={pizzaExtrasImgStyle} src={this.imgUrl(x.eImg)} />
                        <div className={this.props.getFontStyle2("text_item")} style={{ textAlign: 'center', fontSize: this.mobile ? 18 : 26 * this.sizePerHeight }}>{x.eItemName}</div>
                        {this.props.nisFormat(x.ePrice, 0, true)}
                    </div>)
                }
            })}
        </div>)
    }

    render() {
        let data3Title = this.props.freeExtras < 2 ?
            this.props.getText(16029) + ' ' + this.props.freeExtras + ' ' + this.props.getText(16031) :
            this.props.freeExtras + ' ' + this.props.getText(16030) + ' ' + this.props.getText(16031);

        let data2 = this.props.extraList && this.props.extraList.data2 ? this.props.extraList.data2 : [];
        let data3 = this.props.extraList && this.props.extraList.data3 ? this.props.extraList.data3 : [];
        let data4 = this.props.extraList && this.props.extraList.data4 ? this.props.extraList.data4 : [];

        let extrasSection = (<div>
            {this.getExtrasView(data2, this.props.getText(16028), 2)}
            {this.getExtrasView(data3, data3Title, 3)}
            {this.getExtrasView(data4, this.props.getText(16032), 4)}
        </div>);

        let pizzaSection = (<div>
            <div className={this.props.getFontStyle2("text_item")} style={{ textAlign: 'center', color: this.props.isStyle6 == '6' ? "#ddc268" : '#be1e2d', fontSize: 41 * this.sizePerHeight }}>{this.props.itemName}</div>
            {this.props.isStyle4 || this.props.isStyle5 ? "" :
                this.props.areasQty == 2 ? (
                    <Row style={{ height: this.mobile ? 150 : 200 * this.sizePerHeight, textAlign: 'center' }}>
                        {this.getPizzaButton({ r1: true, r2: true, r3: true, r4: true }, 16025)}
                        {this.getPizzaButton({ r1: true, r2: true, r3: false, r4: false }, 16026)}
                        {this.getPizzaButton({ r1: false, r2: false, r3: true, r4: true }, 16027)}
                    </Row>
                ) : this.props.areasQty == 4 ? (
                    <PizzaButtons
                        {...this.props}
                        value={this.state.localsToCheck}
                        onChange={(localsToCheck) => { this.setState({ localsToCheck }) }}
                    />
                ) : ""}
            {/* , background: this.props.btnColor ,   background: 'linear-gradient( -90deg, blue, blue 49%, white 49%, white 51%, red 51% )', */}
            <img id="pizza-view" src={this.imgUrl(this.props.baseImg)} alt="pizza" width={400 * this.sizePerHeight} />
            <div>{this.addContent()}</div>
        </div>);

        return this.mobile ?
            (<span>{pizzaSection}{extrasSection}</span>)
            : (<Row style={{ textAlign: 'center' }}>
                <Col span={14}>{extrasSection}</Col>
                <Col span={10}>{pizzaSection}</Col>
            </Row>)
    }
}

export default PizzaView;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import PageToolbar from "../../components/PageToolbar";
import ReportTypeButton from "../../components/ReportTypeButton";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import { Row, Col, Form } from "antd";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";


class DocumentsCancelled extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _dateFrom: null,
            _dateTo: null,
            _reasonList: '',
            _posList: '',
            _employeeList: '',
            reportName: "reports/DocumentsCancelled.pdf"
        };

        this.selectors = [
            { id: "CANCEL_REASONS_LIST", api: "cancel_reasons_list" },
            { id: TableIDs.genericSelectorPos, api: "list_pos" },
            { id: TableIDs.genericSelectorEmployees, api: "get_employee_list" },
        ];

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { dataset: [] } })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(19119);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    getSelector = (field, index) => {
        const props = {
            ...this.props,
            multi: true,
            value: this.state[field] ? this.state[field].split(",") : [],
            onChange: e => { this.setState({ [field]: e ? e.join(",") : "" }) }
        }

        return index == 1 ? (<GenericSelectorPosStorages {...props} />) : (<GenericSelector {...props} {...this.selectors[index]} />)
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 1000,
            margin: 'auto'
        };

        const { _dateFrom, _dateTo } = this.state;

        return (<div style={divStyle}>
            <PageToolbar title={gt(19120)} actionButtons={[]} />
            <Row>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(19123)}>{this.getSelector("_reasonList", 0)}</Form.Item>
                    <Form.Item label={gt(19124)}>{this.getSelector("_posList", 1)}</Form.Item>
                    <Form.Item label={gt(19125)}>{this.getSelector("_employeeList", 2)}</Form.Item>
                </Col>
                <Col span={window.innerWidth > 600 ? 12 : 24}>
                    <Form.Item label={gt(19121)}>
                        <DateTimePicker format="DD/MM/YYYY" maxDate={_dateTo} value={_dateFrom} onChange={d => { this.setState({ _dateFrom: d }) }} />
                    </Form.Item>
                    <Form.Item label={gt(19122)}>
                        <DateTimePicker format="DD/MM/YYYY" minDate={_dateFrom} value={_dateTo} onChange={d => { this.setState({ _dateTo: d }) }} />
                    </Form.Item>

                    <ReportTypeButton
                        {...this.props}
                        params={this.state}
                        posField={"_posList"}
                        datesToFavorites={[
                            { field: '_dateFrom', label: gt(19121) },
                            { field: '_dateTo', label: gt(19122) },
                        ]}
                        favoriteSave
                    />
                </Col>
            </Row>

        </div>)
    }

}

export default DocumentsCancelled;
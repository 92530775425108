/* @flow */
/*jshint esversion: 6 */
import { Bar } from "ant-design-pro/lib/Charts";
import { Input, message } from "antd";
import moment from "moment";
import React, { Component } from "react";
import "../../App.css";
import DateTimePicker from "../../components/DateTimePicker";
import GenericModal from "../../components/GenericModal";
import GenericTable from "../../components/GenericTable";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";


class Events extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalDates: null,
            modalGraph: null,
        };

        this.id = "EVENTS_TABLE";
        this.dates_id = "EVENT_DATES_TABLE";
        this.graph_id = "EVENT_GRAPH_TABLE";

        this.api = {
            get: "get_events_table",
            create: "create_events_table",
            edit: "edit_events_table",
            delete: "delete_events_table",

            get_dates: "get_event_dates",
            create_dates: "create_event_dates",
            edit_dates: "edit_event_dates",
            delete_dates: "delete_event_dates",

            get_graph: "get_event_graph",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.dates_id] === undefined) props.data[this.dates_id] = { ...props.data.genericData };
        if (props.data[this.graph_id] === undefined) props.data[this.graph_id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(18580);
    }

    renderName = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        if (editing == record.key) {
            return (<Input
                value={editedRecord.eName}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { eName: e.target.value }) }}
            />)
        } else {
            return text;
        }
    }

    renderDateDesc = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.dates_id];

        if (editing == record.key) {
            return (<Input
                value={editedRecord.eDateDesc}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.dates_id, { eDateDesc: e.target.value }) }}
            />)
        } else {
            return text;
        }
    }

    renderDateFrom = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.dates_id];

        if (editing == record.key) {
            return (<DateTimePicker
                format="DD/MM/YYYY"
                value={editedRecord.eDateFrom}
                maxDate={editedRecord.eDateTo}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.dates_id, { eDateFrom: e }) }}
            />)
        } else {
            return text ? moment(text).format("DD/MM/YYYY") : "";
        }
    }

    renderDateTo = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.dates_id];

        if (editing == record.key) {
            return (<DateTimePicker
                format="DD/MM/YYYY"
                value={editedRecord.eDateTo}
                minDate={editedRecord.eDateFrom}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.dates_id, { eDateTo: e }) }}
            />)
        } else {
            return text ? moment(text).format("DD/MM/YYYY") : "";
        }
    }

    renderEdit = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];

        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={() => {
                    if (!editedRecord.eName) {
                        message.error(this.getText(18852));
                        return;
                    }
                    this.props.dataActions.genericDataSaveEdit(
                        this.id, this.props.user.companyCode, this.props.user.token,
                        editing == 0 ? this.api.create : this.api.edit,
                        this.api.get);
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }} />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                deleteQuetion={{ title: this.getText(18585), okText: this.getText(18586), cancelText: this.getText(18587) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}
                genericIcons={[
                    { type: "table", onClick: () => { this.openModalDates(record) }, tooltip: this.getText(18588) },
                    { type: "project", onClick: () => { this.openModalGraph(record) }, tooltip: this.getText(18847) },
                ]}
            />)
        }
    }

    renderEditDates = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.dates_id];

        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={() => {
                    const { eID } = this.state.modalDates;
                    if (!editedRecord.eDateDesc || !editedRecord.eDateFrom || !editedRecord.eDateTo) {
                        message.error(this.getText(18852));
                        return;
                    }
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.dates_id, this.props.user.companyCode, this.props.user.token,
                        editing == 0 ? this.api.create_dates : this.api.edit_dates,
                        this.api.get_dates, { eID });
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.dates_id);
                    else this.props.dataActions.genericDataCancelEditing(this.dates_id);
                }} />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.dates_id, record.key, record.index) }}
                deleteQuetion={{ title: this.getText(18594), okText: this.getText(18586), cancelText: this.getText(18587) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.dates_id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete_dates, this.api.get_dates);
                }}
            />)
        }
    }

    openModalDates = (record) => {
        this.setState({ modalDates: record }, () => {
            const { eID } = record
            this.props.dataActions.genericDataSetFilter(this.dates_id, this.props.user.companyCode, this.props.user.token, { eID }, this.api.get_dates)
        })
    }

    openModalGraph = (record) => {
        this.setState({ modalGraph: record }, () => {
            const { eID } = record
            this.props.dataActions.genericDataSetFilter(this.graph_id, this.props.user.companyCode, this.props.user.token, { eID }, this.api.get_graph)
        })
    }


    render() {
        const gt = this.getText;
        const sorter = true;

        const { modalDates, modalGraph } = this.state;

        const mobile = window.innerWidth <= 600;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            width: mobile ? "100%" : 600,
            margin: "auto",
        };


        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                columns={[
                    { title: gt(18582), key: "eID", dataIndex: "eID", width: "15%", sorter, render: t => t },
                    { title: gt(18583), key: "eName", dataIndex: "eName", width: "50%", sorter, render: this.renderName },
                    { title: gt(18584), width: "25%", render: this.renderEdit },
                ]}
                title={gt(18581)}
                searchBox
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
            />
            <GenericModal
                visible={modalDates !== null}
                onCancel={() => { this.setState({ modalDates: null }) }}
                title={gt(18589) + (modalDates ? " - " + modalDates.eName : "")}
                actionButtons={[
                    { buttonType: 'add', buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.dates_id) } },
                    { buttonType: 'refresh', buttonFunction: () => { this.openModalDates(modalDates) } },
                ]}
                width={1000}>
                <ResponsiveTable
                    idTable={this.props.data[this.dates_id]}
                    columns={[//elect 'eDateID','eId','eDateDesc','eDateFrom','eDateTo'
                        { title: gt(18590), key: "eDateDesc", dataIndex: "eDateDesc", width: "50%", sorter, render: this.renderDateDesc },
                        { title: gt(18591), key: "eDateFrom", dataIndex: "eDateFrom", width: "20%", sorter, render: this.renderDateFrom },
                        { title: gt(18592), key: "eDateTo", dataIndex: "eDateTo", width: "20%", sorter, render: this.renderDateTo },
                        { title: gt(18593), width: "10%", render: this.renderEditDates },
                    ]}
                    tableOnly
                    pagination={{ pageSize: parseInt((window.innerHeight - 300) / 40) }}
                    percentageToMobile={[100]}
                />
            </GenericModal>
            <GenericModal
                visible={modalGraph !== null}
                onCancel={() => { this.setState({ modalGraph: null }) }}
                title={gt(18848) + (modalGraph ? " - " + modalGraph.eName : "")}
                actionButtons={[
                    { buttonType: 'refresh', buttonFunction: () => { this.openModalGraph(modalGraph) } },
                ]}
                width={1000}>
                <Bar height={300} data={[
                    { x: "", y: 0 },
                    ...this.props.data[this.graph_id].data.map(a => {
                        let b = a.split("\f");
                        return { x: b[1], y: parseInt(b[2]) > 0 ? parseInt(b[2]) : 0 }
                    })]} />
            </GenericModal>
        </div>)
    }

}

export default Events;
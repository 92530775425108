
import React from "react";
import { Input, InputNumber, Checkbox, Form } from 'antd';
import DateTimePicker from "./DateTimePicker";
import GenericSelector from "./GenericSelector";
import SelectorItemClassic from "./SelectorItemClassic";
import PictureView from "./PictureView";
import ImageSearch from "./ImageSearch";

class FormItemInput extends React.Component {

    getInput = () => {
        switch (this.props.inputType) {
            case 'text': return (<Input {...this.props} onChange={e => { this.props.onChange(e.target.value) }} />);
            case 'textarea': return (<Input.TextArea {...this.props} onChange={e => { this.props.onChange(e.target.value) }} />);
            case 'number': return (<InputNumber {...this.props} />);
            case 'datetime': return (<DateTimePicker format={'DD/MM/YYYY HH:mm'} {...this.props} />);
            case 'date': return (<DateTimePicker format={'DD/MM/YYYY'} {...this.props} />);
            case 'time': return (<DateTimePicker format={'HH:mm:ss'} {...this.props} />);
            case 'select': return (<GenericSelector {...this.props} />);
            case 'item': return (<SelectorItemClassic {...this.props} />);
            case 'image': return (<PictureView {...this.props} />);
            case 'checkbox':
                return (<Checkbox
                    {...this.props}
                    checked={this.props.value == '1'}
                    onChange={e => { this.props.onChange(e.target.checked ? '1' : '0') }}>
                    {this.props.label}
                </Checkbox>);

            default: return (<div {...this.props} />)
        }
    }

    render() {
        let formItemProps = this.props.formItemProps ? this.props.formItemProps : {}
        let label = this.props.inputType === 'checkbox' ? undefined : this.props.label;
        let content = this.getInput();
        return (<Form.Item label={label} {...formItemProps}>
            {content}
            {this.props.imageSearchProps ? (<ImageSearch {...this.props.imageSearchProps} />) : ""}
        </Form.Item>)
    }

}

export default FormItemInput;


import React from "react";
import GenericModal from "./GenericModal";
import LangContext from "../contextProvider/LangContext";
import GenericTable from "./GenericTable";
import SelectorItemClassic from "./SelectorItemClassic";
import { InputNumber } from "antd";
import TableLineIcons from "./TableLineIcons";


class ModalItemPackageDeduct extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {

        }

        this.id = "MODAL_ITEM_PACKAGE_DEDUCT";


        this.api = {

            get: "get_item_package_deduct",
            create: "create_item_package_deduct",
            edit: "edit_item_package_deduct",
            delete: "delete_item_package_deduct",

        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.refreshData()
    }


    refreshData = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token,
            { _itemCode: this.props.itemCode }, this.api.get);
    }

    renderItem = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        if (editing == record.key) {
            return (<SelectorItemClassic {...this.props}
                valueId={editedRecord.pCode} valueName={editedRecord.pName}
                onChange={(pCode, pName) => { this.props.dataActions.genericDataSetEdit(this.id, { pCode, pName }) }}
            />)
        } else {
            return text + " - " + record.pName
        }
    }

    renderValue = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        const field = this.props.isByWeight ? "pWeight" : "pPrice"
        const nField = !this.props.isByWeight ? "pWeight" : "pPrice"
        if (editing == record.key) {
            return (<InputNumber
                value={editedRecord[field]}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e, [nField]: null }) }}
            />)
        } else {
            return text
        }
    }

    renderEdit = (text, record) => {
        const { editing } = this.props.data[this.id];
        if (editing == record.key) {
            if (editing == 0) {
                return (<TableLineIcons
                    onCheck={() => { this.props.dataActions.genericDataSaveEditWithParams(this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get, { _itemCode: this.props.itemCode }) }}
                    onClose={() => { this.props.dataActions.genericDataCancelInlineCreate(this.id) }}
                />)
            } else {
                return (<TableLineIcons
                    onCheck={() => { this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get) }}
                    onClose={() => { this.props.dataActions.genericDataCancelEditing(this.id) }}
                />)
            }
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get) }}
                deleteQuetion={{ title: this.getText(19861), okText: this.getText(19862), cancelText: this.getText(19863) }}
            />)
        }
    }

    render() {

        const columns = [
            { title: this.getText(19855), width: "40%", key: "pCode", dataIndex: "pCode", render: this.renderItem },
            this.props.isByWeight ? { title: this.getText(19856), width: "40%", key: "pWeight", dataIndex: "pWeight", render: this.renderValue } : null,
            this.props.isByWeight ? null : { title: this.getText(19857), width: "40%", key: "pPrice", dataIndex: "pPrice", render: this.renderValue },
            { title: this.getText(19858), width: "20%", render: this.renderEdit },
        ].filter(f => f)


        return (<span>
            <GenericModal
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                title={this.getText(19854) + " - " + this.getText(19354) + ": " + this.props.itemCode + " - " + this.getText(19355) + ": " + this.props.itemName}
                width={600}
                actionButtons={[
                    { buttonType: 'add', buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) } },
                    { buttonType: 'refresh', buttonFunction: this.refreshData },
                ]}>
                <GenericTable
                    {...this.props}
                    tableOnly
                    id={this.id}
                    getApi={this.api.get}
                    filters={{ _itemCode: this.props.itemCode }}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                />
            </GenericModal>
        </span>)
    }


}
export default ModalItemPackageDeduct;

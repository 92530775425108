
import React from "react";
import { Button, Checkbox, Input, InputNumber, message } from 'antd';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import GenericModal from "./GenericModal";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";
import GenericTable from "./GenericTable";
import TableLineIcons from "./TableLineIcons";
import TableIDs from "../data/TableIDs";


class ModalItemMivzaim extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            activeOnly: true,
        }

        this.id = "ITEM_MIVZAIM";


        this.api = {

            get: "get_bargains_Table",

        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }


        this.filters = {
            _words: "",
            _page_num: 1,
            _rows_num: 10,
            _sort_by: "col_1",
            _isAsc: 1,
            itemCode: "",
            isActive: '1',
            dateFilter1: 0,
            dateFilter2: 1,
            dateFilter3: 1,
            onSun: -1,
            onMon: -1,
            onTue: -1,
            onWed: -1,
            onThu: -1,
            onFri: -1,
            onSat: -1,
            bargainType: -1,
            allowMulti: -1,
            itemTag: null,
            custId: null,
            custTag: null,
            posList: "",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.refreshData()
    }


    refreshData = () => {
        let filters = {...this.filters, itemCode: this.props.itemCode, isActive: this.state.activeOnly ? '1' : '-1', dateFilter1: this.state.activeOnly ? 0 : 1 }
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, filters, this.api.get);
    }

    render() {

        const gt = this.getText;
        const render = t => t;
        const sorter = true;

        const filters = {
            ...this.filters,
            itemCode: this.props.itemCode,
        }

        const columns = [
            { title: gt(19762), key: 'bargainId', dataIndex: 'bargainId', width: '20%', sorter, render },
            { title: gt(19763), key: 'bargainDescription', dataIndex: 'bargainDescription', width: '80%', sorter, render },
        ];

        return (<span>
            <GenericModal
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                title={this.getText(19730) + " - " + this.getText(19354) + ": " + this.props.itemCode + " - " + this.getText(19355) + ": " + this.props.itemName}
                width={400}
                actionButtons={[{
                    buttonType: 'refresh',
                    buttonFunction: this.refreshData
                }]}>
                <Checkbox
                    checked={this.state.activeOnly}
                    onChange={e => { this.setState({ activeOnly: e.target.checked }, this.refreshData) }}
                >{gt(19764)}</Checkbox>
                <GenericTable
                    {...this.props}
                    tableOnly
                    searchBox
                    id={this.id}
                    getApi={this.api.get}
                    filters={filters}
                    columns={columns}
                />
            </GenericModal>
        </span>)
    }


}
export default ModalItemMivzaim;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from 'react';
import { Job, User, OutputType, ProcessType } from '../JmReact';

class TextValuesData extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.sendAPI();
    }

    sendAPI = () => {
        let endpoint = this.props.endpoint ? this.props.endpoint : "CashOnTab";
        let isForms = this.props.endpoint == 'JMForms';
        let data = "pLanguageId\fpDocId\r" + (this.props.language ? this.props.language : 2) + "\f1";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "get_interface_language_values", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"], isForms);
        job.setInput(data);
        job.send("/cgi-bin/" + endpoint, this.sCallback, (e) => { console.error(e) });
    }

    // sendAPINoToken = (script, data, sCallback) => {
    //     let user = new User("", this.params.code);
    //     let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"], true);
    //     job.setInput(data);
    //     job.send("/cgi-bin/JMForms", sCallback, this.errorMessage);
    // }

    sCallback = (ob) => {
        let res = ob.data.split("\r");
        res.map((y) => {
            let x = y.split("\f");
            this.props.dataTextValues.set(parseInt(x[0]), x[1])
        })

        if (this.props.onLoadText) this.props.onLoadText()

    }

    render() {
        return (<div></div>);
    }
}

export default TextValuesData;

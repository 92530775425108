/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import { Job, OutputType, ProcessType, User } from "../../JmReact";
import { Checkbox, Row, Col, Input, Button } from "antd";
import TableLineIcons from "../../components/TableLineIcons";
import ModalContacts from "../../components/ModalContacts";


class ExternalServiceProviders extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalContacts: null,
        };

        this.id = "EXTERNAL_SERVICE_PROVIDERS";

        this.api = {
            get: "get_external_service_providers",
            create: "create_external_service_providers",
            edit: "edit_external_service_providers",
            edit_active: "edit_active_external_service_providers",
            delete: "delete_external_service_providers",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20249);
    }

    refreshData = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { custID: this.props.visible?.mId }, this.api.get);
    }

    renderIsActive = (text, record) => {
        return (<Checkbox
            checked={text == 1}
            onChange={e => { this.sendAPI(this.api.edit_active, "eIsActive\feID\r" + (e.target.checked ? "1" : "0") + "\f" + record.key, this.refreshData) }}
        />)
    }

    renderEditCol = (text, record) => {
        return (<TableLineIcons
            onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
            deleteQuetion={{ title: this.getText(20258), okText: this.getText(20259), cancelText: this.getText(20260) }}
            onDelete={() => {
                this.props.dataActions.genericDataDeleteRow(
                    this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
            }}
            genericIcons={[
                {
                    type: "table",
                    onClick: () => { this.setState({ modalContacts: { code: record.eID, name: record.eName } }) },
                    tooltip: this.getText(20264)
                }
            ]}
        />)
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    handleCancel = () => {
        if (this.props.data[this.id].editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
        else this.props.dataActions.genericDataCancelEditing(this.id);
    }

    handleOk = () => {
        const { editing } = this.props.data[this.id]
        this.props.dataActions.genericDataSaveEdit(
            this.id, this.props.user.companyCode, this.props.user.token,
            editing == 0 ? this.api.create : this.api.edit,
            this.api.get);
    }

    getField = (label, field) => {
        const { editedRecord } = this.props.data[this.id]
        const value = editedRecord[field]
        const onChange = e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }

        return (<Row style={{ padding: 5 }}>
            <Col span={9}>{label}</Col>
            <Col span={15}>
                {field == "eIsActive" ?
                    (<Checkbox checked={value == 1} onChange={e => { onChange(e.target.checked ? '1' : '0') }} />)
                    : field == "eDesc" || field == "eEmailText" ? (<Input.TextArea value={value} onChange={e => { onChange(e.target.value) }} />)
                        : (<Input value={value} onChange={e => { onChange(e.target.value) }} />)}
            </Col>
        </Row>)
    }

    render() {
        const gt = this.getText;
        const render = t => t;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",

            maxWidth: 1000,
            margin: "auto",
        };

        const { editing } = this.props.data[this.id] ?? {}


        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                rowsFilter={r => r.key > 0}
                id={this.id}
                getApi={this.api.get}
                title={gt(20250)}
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
                columns={[//"eID", "eName", "eDesc", "eEmailHeader", "eEmailText", "eIsActive"
                    { title: gt(20251), key: "eID", dataIndex: "eID", width: "10%", render },
                    { title: gt(20252), key: "eName", dataIndex: "eName", width: "25%", render },
                    { title: gt(20253), key: "eDesc", dataIndex: "eDesc", width: "45%", render },
                    { title: gt(20256), key: "eIsActive", dataIndex: "eIsActive", width: "10%", render: this.renderIsActive },
                    { title: gt(20257), width: "10%", render: this.renderEditCol },
                ]}
            />
            <GenericModal
                visible={editing > -1}
                onCancel={this.handleCancel}
                title={gt(20261)}
                width={600}
                footer={[
                    <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{gt(20262)}</Button>,
                    <Button key={"create"} type={"primary"} onClick={this.handleOk} style={{ margin: 5 }}>{gt(20263)}</Button>,
                ]}>
                {this.getField(gt(20252), "eName")}
                {this.getField(gt(20253), "eDesc")}
                {this.getField(gt(20254), "eEmailHeader")}
                {this.getField(gt(20255), "eEmailText")}
                {this.getField(gt(20256), "eIsActive")}

            </GenericModal>
            <ModalContacts
                {...this.props}
                visible={this.state.modalContacts?.code ?? null}
                onCancel={() => { this.setState({ modalContacts: null }) }}
                title={gt(20265) + " " + this.state.modalContacts?.code + " - " + this.state.modalContacts?.name}
                ownerType={"2"}
            />
        </div>)
    }

}

export default ExternalServiceProviders;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Input, message, Form, Select, Modal, Button } from "antd";
import PageToolbar from "./PageToolbar";
import TableIDs from "../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import LangContext from "../contextProvider/LangContext";

import "../App.css";

const FormItem = Form.Item;
const { Option } = Select;

type Props = {
    type: Number,
    params: String,
}

class ModalSendLinkSelfCustomer extends Component<Props, State> {

    constructor(props/*: Props*/) {
        super(props);
        this.state = { _mobile: "" }
        this.api = "create_random_connection_token";
    }

    static contextType = LangContext;
    getText = (id) => this.context.get(id) || '[' + id + ']';

    handleOk = () => {
        let value = this.state._mobile;
        if (!this.validMobile(value, true)) {
            message.error(this.getText(12619));
        } else {
            let type = this.props.type;
            let params = (this.props.params !== "" ? this.props.params + "&" : "") + "mobile=" + value;
            let dataSend = "_type\f_params\f_mobile\f_lang\r" + type + "\f" + params + "\f" + value + "\f" + this.getText(100);
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, this.api, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab", (ob) => {
                message.success(this.getText(12620));
                console.log("yuda ob.data ", ob.data)
                this.handleCancel();
            }, (e) => {
                message.error(e);
            });
        }
    }

    handleCancel = () => {
        this.setState({ _mobile: "" })
        this.props.uiActions.hideModal(TableIDs.modalSendLinkSelfCustomer);
    }

    handleClear = () => {
        this.setState({ _mobile: "" })
    }

    validMobile = (v, finish) => {
        let long = (!finish && v.length <= 10) || v.length === 10 ? true : false;
        let reg = RegExp(/[0][5]\d{1,8}/);
        let base = reg.test(v) && long ? true : false;
        let inEdit = (v === "" || v === "0" || v === "05") ? true : false;

        if ((inEdit && !finish) || base) return true;
    }

    render() {

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);


        return (<Modal
            destroyOnClose={true}
            width={300}
            visible={false || this.props.toggle}
            title={titleBar}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
                <Button key="back" onClick={this.handleCancel}>{this.getText(12616)}</Button>,
                <Button key="clear" onClick={this.handleClear}>{this.getText(12617)}</Button>,
                <Button key="create" type="primary" onClick={this.handleOk}>{this.getText(12618)}</Button>,
            ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <FormItem label={this.getText(12615)}>
                <Input value={this.state._mobile} onChange={(value) => {
                    let v = value.target.value;
                    if (this.validMobile(v, 0)) this.setState({ _mobile: v })
                }} />
            </FormItem>
        </Modal >)
    }
}

export default ModalSendLinkSelfCustomer;
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import CustomerMenu from '../menus/CustomerMenu';
import CustomerIndex from './Customers/Index';
import Index2 from "./Customers/Index2";
//import MailingList from "./mailing/MailingList"
//import EarningPoints from "./Customers/EarningPoints";
//import UsingPoints from "./Customers/UsingPoints";
//import CustomerField from "./Customers/CustomerField";
import CustomerClub from "./Customers/CustomerClub";
import DirectDebit from "./Customers/DirectDebit";
import DirectDebitCharge from "./Customers/DirectDebitCharge";
import TableIDs from '../data/TableIDs';
//import Jax from '../Jax/jax.es6.module';
import CustomerReadFile from './Customers/CustomerReadFile';
import CustomerPoints from './Customers/CustomerPoints';
import CustomerSubscribers from './Customers/CustomerSubscribers';
import CustomerCoupons from './Customers/CustomerCoupons';
import CustomerAggregateCard from './Customers/CustomerAggregateCard';
import BirthdaysReport from './Customers/BirthdaysReport';
import AppRegistration from './Customers/AppRegistration';
import CreditCardCharge from './Customers/CreditCardCharge';
import Coupons from "./Customers/Coupons";
import CarList from "./Customers/CarList";
import InterCard from "./Customers/InterCard";
import TelemarketingReport from "./Customers/TelemarketingReport";
import AgingCreditCust from "./Customers/AgingCreditCust";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};

type State = {};

class Customers extends Component<Props, State> {
    constructor(props/*: Props*/) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={'1'} path={this.props.baseUrl + "/customers/customerList"} exact render={() => <CustomerIndex {...routeProps} />} />),
            (<Route key={'2'} path={this.props.baseUrl + "/customers/settings"} exact render={() => <Index2 {...routeProps} />} />),
            (<Route key={'3'} path={this.props.baseUrl + "/customers/customer-club"} exact render={() => <CustomerClub {...routeProps} />} />),
            (<Route key={'4'} path={this.props.baseUrl + "/customers/direct-debit"} exact render={() => <DirectDebit {...routeProps} />} />),
            (<Route key={'5'} path={this.props.baseUrl + "/customers/direct-debit-charge"} exact render={() => <DirectDebitCharge {...routeProps} />} />),
            (<Route key={'6'} path={this.props.baseUrl + "/customers/read-file"} exact render={() => <CustomerReadFile {...routeProps} />} />),
            (<Route key={'8'} path={this.props.baseUrl + "/customers/customer-points"} exact render={() => <CustomerPoints {...routeProps} />} />),
            (<Route key={'9'} path={this.props.baseUrl + "/customers/subscribers"} exact render={() => <CustomerSubscribers {...routeProps} />} />),
            (<Route key={'10'} path={this.props.baseUrl + "/customers/coupons"} exact render={() => <CustomerCoupons {...routeProps} />} />),
            (<Route key={'127'} path={this.props.baseUrl + "/customers/coupons-v2"} exact render={() => <Coupons {...routeProps} />} />),
            (<Route key={'121'} path={this.props.baseUrl + "/customers/aggregate-card"} exact render={() => <CustomerAggregateCard {...routeProps} />} />),
            (<Route key={'123'} path={this.props.baseUrl + "/customers/birthdays-report"} exact render={() => <BirthdaysReport {...routeProps} />} />),
            (<Route key={'125'} path={this.props.baseUrl + "/customers/app-registration"} exact render={() => <AppRegistration {...routeProps} />} />),
            (<Route key={'126'} path={this.props.baseUrl + "/customers/credit-card-charge"} exact render={() => <CreditCardCharge {...routeProps} />} />),
            (<Route key={'128'} path={this.props.baseUrl + "/customers/car-list"} exact render={() => <CarList {...routeProps} />} />),
            (<Route key={'129'} path={this.props.baseUrl + "/customers/intercard"} exact render={() => <InterCard {...routeProps} />} />),
            (<Route key={'130'} path={this.props.baseUrl + "/customers/telemarketing"} exact render={() => <TelemarketingReport {...routeProps} />} />),
            (<Route key={'251'} path={this.props.baseUrl + "/customers/aging-credit-cust"} exact render={() => <AgingCreditCust {...routeProps} />} />),
            (<Route key={'252'} path={this.props.baseUrl + "/customers/coupon-check"} exact render={() => <Coupons isCouponCheckComponent {...routeProps} />} />),
                     // (<Route key={'124'} path={this.props.baseUrl + "/customers/self-create-customer"} exact render={() => <SelfCreateCustomer {...routeProps} />} />),
            //(<Route key={'7'} path={this.props.baseUrl + "/customers/mailing-list"} exact render={() => <MailingList {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/customers"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <CustomerMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}
export default Customers;

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import PageTitle from "../../components/PageTitle";
import { Table } from "antd";
import EmployeesTable from "./EmployeesTable";
import PageToolbar from "../../components/PageToolbar"

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    data: any,
    dataActions: any,
};
type State = {};

class EmployeeIndex extends Component<Props, State> {

    // componentDidMount() {
    //     document.title = "Cash On Tab - רשימת פריטים";
    // }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            //whiteSpace: "unset",
        };

        return (
            <div style={divStyle}>
                <EmployeesTable
                    user={this.props.user}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                    ActionQueue={this.props.ActionQueue}
                />
            </div>
        );
    }
}
export default EmployeeIndex;

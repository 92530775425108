
import React from "react";
import {
    Input, Form, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select,
    Checkbox, message, InputNumber, Radio,
    Row,
    Col
} from 'antd';
import moment from "moment";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import GenericModal from "./GenericModal";
import TableIDs from '../data/TableIDs.js';
import ResponsiveTable from "./ResponsiveTable";
import ImageSearch from "./ImageSearch";
// import ModalPricesHistory from "./ModalPricesHistory";
import ModalCreateEditItemPrices from "./ModalCreateEditItemPrices";
import ModalCreateEditItemSuppliers from "./ModalCreateEditItemSuppliers";
import LangContext from "../contextProvider/LangContext";
import GenericTable from "./GenericTable";
import TableLineIcons from "./TableLineIcons";
import GenericSelectorItemTags from "./GenericSelectorItemTags";
import ModalCreateEditItemStock from "./ModalCreateEditItemStock";
import ModalItemPackageDeduct from "./ModalItemPackageDeduct.js";
import ModalMatrixReverse from "./ModalMatrixReverse.js";


const { Option, OptGroup } = Select;
const { TextArea } = Input;


class ModalCreateEditItem extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            isValidItemCode: false,
            isNewItem: false,
            googleImage: null,
            // modalPricesHistory: false,
            modalComparativeLabel: false,
            modalVirtualShopSettings: false,
            modalItemStoragesInventoryLimits: false,
            modalItemPackageDeduct: false,
            modalWooConvItem: false,
            useItemCodeNumerator: false,
            displayOnlyDefaultPriceList: null,


            // modalLocations: null,
            // locationsLoading: false,
        }

        this.id = 'ITEM_CREATE_EDIT';
        this.id_priceList = 'ITEM_PRICE_LIST_CREATE_EDIT';
        this.id_suppliers = 'ITEM_SUPPLIERS_CREATE_EDIT';
        this.id_tags = "ITEM_TAGS_CREATE_EDIT";
        this.id_defaults = "ITEM_DEFULTS_SETTINGS";
        // this.inv_limits_id = "ITEM_STORAGES_INVENTORY_LIMITS";

        this.modifier1_id = "SELECTOR_TAG_TYPE_5";

        this.id_rates = "LAST_CURRENCY_RATES";
        // this.id_locations = "ITEM_STORAGE_LOCATIONS";

        this.woo_id = "WOO_CONV_ITEM";


        this.itemCode = null;

        this.api = {
            get: "get_item",
            create_edit: "create_edit_item",
            create: "create_item",
            edit: "edit_item",

            get_priceList: "get_prices_by_item",
            get_suppliers: "get_suppliers_by_item",
            get_tags: "get_tags_by_item_with_headers",
            get_defaults: "get_item_defaults",

            // get_inv_limits: "get_item_storages_inventory_limits",
            save_inv_limits: "save_item_storages_inventory_limits",

            get_locations: "get_item_storage_locations",
            create_edit_locations: "create_edit_item_storage_locations",

            get_woo: "get_woo_conv_item",
            save_woo: "save_woo_conv_item",
            copy_woo: "copy_woo_conv_item",

            selector_tax_type: "get_tax_types",
            selector_sale_alert: "get_sale_alert_list",
            selector_tags: "get_tags_with_types",
            selector_price_list: "get_prices",
            selector_suppliers: "get_suppliers",
            selector_rulers: "get_rulers_list",
            selector_modifier1: "get_tags_from_type_5",
        }

        this.IDs = [
            { id: this.id, api: this.api.get },
            { id: this.id_priceList, api: this.api.get_priceList },
            { id: this.id_suppliers, api: this.api.get_suppliers },
            { id: this.id_tags, api: this.api.get_tags },
            { id: this.id_defaults, api: this.api.get_defaults },
            { id: this.woo_id, api: this.api.get_woo }
        ];

        this.selectors = [
            { id: TableIDs.genericSelectorPriceList, api: this.api.selector_price_list },
            { id: TableIDs.genericSelectorTaxType, api: this.api.selector_tax_type },
            { id: TableIDs.genericSelectorSalesAlerts, api: this.api.selector_sale_alert },
            { id: TableIDs.genericMultiSelectorItemTags, api: this.api.selector_tags },
            { id: TableIDs.genericSelectorSuppliers, api: this.api.selector_suppliers },
            { id: TableIDs.genericSelectorRuler, api: this.api.selector_rulers },
            { id: this.modifier1_id, api: this.api.selector_modifier1 },
            { id: "CURRENCY_SELECTOR", api: "get_currency_list" },
        ];

        this.IDs.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericData } })
        // if (props.data[this.inv_limits_id] === undefined) props.data[this.inv_limits_id] = { ...props.data.genericData }
        if (props.data[this.id_rates] === undefined) props.data[this.id_rates] = { ...props.data.genericData }
        // if (props.data[this.id_locations] === undefined) props.data[this.id_locations] = { ...props.data.genericData }

        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.picksPriceList = [];
        this.picksSuppliers = [];

        this.blockRemoveCodeWhenFromCreateDoc = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.forEach((x) => {
            let action = (x.api === this.api.selector_tags) ? 'genericMultiSelectorRefreshDataset' : 'genericSelectorRefreshDataset';
            this.props.dataActions[action](x.id, this.props.user.companyCode, this.props.user.token, x.api);
        });

        this.sendAPI('get_GP_UseItemCodeNumerator', '', (ob) => {
            this.sendAPI('get_Gp_DisplayAllPosPriceList', '', (ob1) => {
                this.setState({
                    useItemCodeNumerator: ob.data === '1',
                    displayOnlyDefaultPriceList: ob1.data,
                })
            })
        })

        this.apiLastRates()

    }

    codeInvalid = () => {
        message.warning(this.getText(10248));
    };
    codeWithSpaces = () => {
        message.warning(this.getText(10249));
    };
    codeValid = () => {
        message.success(this.getText(10250));
    };

    codeError = () => {
        message.error(this.getText(10251));
    };

    errorSuppliers = () => {
        message.error(this.getText(10252));
    };

    errorPriceList = () => {
        message.error(this.getText(10253));
    };

    errorSuppliersIsEdit = () => {
        message.error(this.getText(10254));
    };

    errorPriceListIsEdit = () => {
        message.error(this.getText(10255));
    };

    virtualShopValid = () => {
        let issetVirtualShop = this.props.data.PSEUDO_SELECTOR_UI_COMPONENTS_MODULES.dataset.find(f => f.name === '3' || f.name === '485') ? true : false;
        let virtualShopTagsChecked = [];
        let valueTagList = this.props.data[this.id_tags] ? Object.values(this.props.data[this.id_tags].data) : [];
        let virtualShopTags = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.find(f => f.type.id == '4');
        if (virtualShopTags && virtualShopTags.tags) {
            virtualShopTagsChecked = valueTagList.filter(f => {
                let y = f.split('\f');
                return virtualShopTags.tags.find(ff => ff.id == y[0])
            });
        }
        let er = this.props.data[this.id].editedRecord;
        let vssView = issetVirtualShop && !er['mFoodDishID'] && er['mIsSerialize'] != '1' ? true : false;
        let vsFields = er['mVirtualShopMax'] && er['mVirtualShopMin'] && er['mVirtualShopStep'] ? true : false;
        if (vssView && er['mIsByWeight'] == '1' && !vsFields && virtualShopTagsChecked.length > 0) {
            message.error(this.getText(13560));
            return false;
        } else {
            return true;
        }
    }

    checkValidLength = () => {
        const { mBarCode, mBarCode2, mName, mShortName } = this.props.data[this.id].editedRecord;
        const gt = this.getText;
        if (mBarCode && mBarCode.length > 15) { message.error(gt(18576) + " " + gt(10259) + " " + gt(18577) + " 15 " + gt(18578)); return false; }
        if (mBarCode2 && mBarCode2.length > 15) { message.error(gt(18576) + " " + gt(17311) + " " + gt(18577) + " 15 " + gt(18578)); return false; }
        if (mName && mName.length > 50) { message.error(gt(18576) + " " + gt(10260) + " " + gt(18577) + " 50 " + gt(18578)); return false; }
        if (mShortName && mShortName.length > 20) { message.error(gt(18576) + " " + gt(10261) + " " + gt(18577) + " 20 " + gt(18578)); return false; }

        return true;

    }

    handleOk = (hide, next) => {
        const { mCode, mName, mShortName, mPictureFile, mIsReverse } = this.props.data[this.id].editedRecord;
        const { itemId, isMatrixReverseFather } = this.props.ui.data ?? {};

        let isValid = this.checkMultiEntries() && this.isMainRulerSet() && this.virtualShopValid() && this.checkValidLength();

        if (isValid == true && mCode) {

            let getScriptObj = null;

            if (this.props.context && this.props.context_api_get) {
                getScriptObj = {
                    action: this.props.dataActions.genericDataGetRequested,
                    args: [this.props.context, this.props.user.companyCode,
                    this.props.user.token, this.props.context_api_get/*"get_items_list"*/]
                }
            }

            console.log("edit_item 111", this.props.data[this.id].editedRecord)

            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataJustSaveEditMulti,
                args: [
                    this.id,
                    this.props.user.companyCode,
                    this.props.user.token,
                    this.api.create_edit,
                    [
                        { name: this.id },
                        { name: this.id_suppliers, sendDataTable: true },
                        { name: this.id_priceList, sendDataTable: true },
                        { name: this.id_tags, sendDataTable: true }
                    ],
                    getScriptObj
                ]
            });


            if (this.props.ui.data?.onSave && hide) {
                this.props.ui.data.onSave({ mCode, mName });
            }

            if (this.props.onSaveFromCreateDoc && hide) {
                this.props.onSaveFromCreateDoc(mCode, mName)
            }


            if (mIsReverse != '1' && isMatrixReverseFather) {
                Modal.confirm({
                    title: "",
                    content: this.getText(19519),
                    iconType: 'exclamation-circle',
                    okText: this.getText(19520),
                    cancelText: this.getText(19521),
                    onOk: () => { this.sendAPI("set_matrix_reverse_to_normal", itemId, () => { }) },
                    onCancel: () => { this.sendAPI("delete_matrix_reverse", itemId, () => { }) },

                })
            }

            if (hide) {
                this.handleCancel();
            } else if (next) {
                // setTimeout(()=>{
                this.props.ActionQueue.addToQueue({
                    action: this.props.uiActions.setModalUIData,
                    args: [{ ...this.props.ui.data, isNew: false, itemId: mCode, saveAndNext: next }]
                });
                // next()
                // }, 1000);
            } else {
                this.handleClear();

                this.props.ActionQueue.addToQueue({
                    action: this.props.uiActions.setModalUIData,
                    args: [{ isNew: true }]
                });
            }

            if (this.props.checkButtonsOnSave && hide) {
                this.props.checkButtonsOnSave(mCode, mShortName, mPictureFile)
            }


        }
    }


    handleCancel = () => {

        this.setState({ googleImage: null }, () => {
            this.props.uiActions.hideModal(TableIDs.modalCreateEditItem);
            this.clearIsFather();
            this.props.dataActions.setJson(this.id, { createNewFromDoc: null, editedRecord: {} })

        })
    }
    handleClear = () => {

        this.setState({ googleImage: null }, () => {
            this.props.data[this.id].editedRecord = { mCode: this.props.data[this.id].editedRecord['mCode'] };
            this.props.data[this.id_priceList].data = [];
            this.props.data[this.id_suppliers].data = [];
            this.props.data[this.id_tags].data = [];

            setTimeout(() => {
                let fInput = document.getElementById("focus-input");
                fInput.autofocus = false;
                setTimeout(() => {
                    fInput.autofocus = true
                }, 3000);
            }, 1000);
        });
    }

    isMainRulerSet = () => {

        const mainRuler = this.props.data[this.id].editedRecord['mRuler1ID'];
        if (mainRuler && mainRuler !== '') {
            return true;
        }
        else {
            message.error(this.getText(10621));
            return false
        }
    }

    checkMultiEntries = () => {

        let suppliers = this.props.data[this.id_suppliers]
            .data.map((x, index) => { let y = x.split("\f"); return y[1] });
        let unique_suppliers = suppliers.filter((elem, index, self) => {
            return index == self.indexOf(elem);
        });
        let priceList = this.props.data[this.id_priceList]
            .data.map((x, index) => { let y = x.split("\f"); return y[1] });
        let unique_priceList = priceList.filter((elem, index, self) => {
            return index == self.indexOf(elem);
        });

        if (unique_suppliers.length != suppliers.length) {
            this.errorSuppliers()
            return false;
        }
        if (unique_priceList.length != priceList.length) {
            this.errorPriceList()
            return false;
        }

        if (parseInt(this.props.data[this.id_suppliers].editing) > -1) {
            this.errorSuppliersIsEdit();
            return false;
        }

        if (parseInt(this.props.data[this.id_priceList].editing) > -1) {
            this.errorPriceListIsEdit();
            return false;
        }

        if (parseInt(this.props.data[this.id_suppliers].data.length) <= 0) {
            message.error(this.getText(10256));
            return false;
        }

        if (parseInt(this.props.data[this.id_priceList].data.length) <= 0) {
            message.error(this.getText(10257));
            return false;
        }

        return true;
    }

    clearIsFather = () => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mIsMatrixFather']: null });
    }

    handlePriceListCreate = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id_priceList);
    }

    handleSuppliersCreate = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id_suppliers, { mIsDefault: "0" });
    }

    frameStyle = (text, con) => {
        let labelStyle = { display: 'inline-block', width: '20%', textAlign: 'right', verticalAlign: 'top' }
        return (<div style={{ padding: '4px' }}><div style={labelStyle}><div>{text}</div></div>{con}</div>)
    }

    generateSelectOptions = (text, ph, field, TableID) => {
        const selectorList = (this.props.data[TableID]) ? this.props.data[TableID].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + obj['name']}>
                {field !== 'mModifierGroup1' ? obj['code'] + ' - ' : ''}{obj['name']}
            </Option>)
        }) : [];
        let mSaleAlertID = field === "mSaleAlertID" ? true : false;
        let gSelector = (<Select placeholder={ph} disabled={!this.state.isValidItemCode || (this.props.isMatrix && field == "mRuler1ID")}
            value={this.props.data[this.id].editedRecord[field]} defaultValue={''}
            style={{ width: mSaleAlertID ? '60%' : '65%', marginRight: '3%' }}
            showSearch filterOption={true} optionFilterProp="valuefilter"
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }}
        >
            {field !== 'mTaxTypeId' ? <Option key={0} value={''}>-</Option> : ""}
            {selectorList}
        </Select>);
        let mSaleAlert = (<span>
            {gSelector}
            <Button type={"primary"} icon={"plus"} disabled={!this.state.isValidItemCode}
                onClick={() => { this.props.uiActions.showModal(TableIDs.modalSalesAlert, { ...this.props.ui.data }) }}
            />
        </span>)
        return this.frameStyle(text, mSaleAlertID ? mSaleAlert : gSelector)
    }

    generateInputField = (text, handle, field, len, disb) => {
        let val = this.props.data[this.id] ? this.props.data[this.id].editedRecord[field] : null;
        let isItem = this.state.isValidItemCode;
        let isBarcode = this.props.barCodeDisabled;
        let mCodeField = field === 'mCode';
        let mBarCodeField = field === 'mBarCode';
        let disbld = (disb === 1 && !isItem) || (disb === 2 && isItem) || (disb === 3 && (!isItem || isBarcode))
        // () => {
        //     switch (disb) {
        //         case 1: return !isItem;
        //         case 2: return isItem;
        //         case 3: return !isItem || isBarcode;
        //     }
        // }
        let getByCode = (e) => {
            if (len && e.target.value && e.target.value.length > len) {
                message.error(this.getText(18576) + " " + text + " " + this.getText(18577) + " " + len + " " + this.getText(18578));
                // handle({ target: { value: e.target.value.slice(0, len) } })
                return;
            }
            if (mCodeField && val && val.length > 0) this.get_item_by_code()
        }



        let content = (<Input disabled={disbld} type="text" value={val} autoComplete={"off"}
            //  maxLength={len} 
            style={{ width: '65%', marginRight: '3%' }} onChange={handle} onBlur={getByCode} onPressEnter={getByCode}
            autoFocus={(mCodeField && !isItem) || (mBarCodeField && isItem) || this.state.isNewItem ? true : false}
            id={(mCodeField && !isItem) ? "focus-input" : field + "-input"} />)

        let btnGenerateCode = mCodeField && !isItem && this.state.useItemCodeNumerator ? (
            <Tooltip title={this.getText(16251)}>
                <Button type={"primary"} icon={"setting"} onClick={() => {
                    this.sendAPI("get_item_code_numerator", '', (ob) => {
                        handle({ target: { value: ob.data } });
                        setTimeout(this.get_item_by_code, 500);
                    })
                }} />
            </Tooltip>
        ) : ''

        return this.frameStyle(text, [content, btnGenerateCode])
    }

    generateCheckboxField = (text, field) => {
        return (<label style={{ display: 'block' }}>
            <Checkbox
                style={{ padding: '4px' }}
                disabled={!this.state.isValidItemCode}
                onChange={(e) => {
                    if (field === 'mIsByWeight') {
                        let defs = this.props.data[this.id_defaults].editedRecord;
                        this.props.dataActions.genericDataSetEdit(this.id, {
                            mIsByWeight: (e.target.checked) ? 1 : 0,
                            mVirtualShopStep: (e.target.checked) ? defs['tVirtualShopStep'] : '',
                            mVirtualShopMin: (e.target.checked) ? defs['tVirtualShopMin'] : '',
                            mVirtualShopMax: (e.target.checked) ? defs['tVirtualShopMax'] : '',
                        })
                    } else if (field === 'mIsActive' && !e.target.checked) {
                        Modal.confirm({
                            title: "",
                            content: this.getText(18559),
                            iconType: 'exclamation-circle',
                            okText: this.getText(18560),
                            cancelText: this.getText(18561),
                            onOk: () => {
                                this.props.dataActions.genericDataSetEdit(this.id, { [field]: (e.target.checked) ? 1 : 0 })
                            },
                            onCancel() { },
                        })
                    } else {
                        this.props.dataActions.genericDataSetEdit(this.id, { [field]: (e.target.checked) ? 1 : 0 })
                    }


                }}
                checked={(this.props.data[this.id].editedRecord[field] == 1) ? true : false}
            />
            {text}
        </label>)
    }

    generateTextareaField = (text, field) => {
        return this.frameStyle(text, (<TextArea style={{ display: 'inline-block', width: '65%', marginRight: '3%' }}
            value={this.props.data[this.id].editedRecord[field]}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.target.value }) }}
            disabled={!this.state.isValidItemCode} autoSize={{ minRows: 2, maxRows: 5 }}
        />))
    }

    handleInputChangeItemShortName = (e) => {

        this.props.dataActions.genericDataSetEdit(this.id, { ['mShortName']: e.target.value });
    }
    handleInputChangeItemLongName = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mName']: e.target.value, ['mShortName']: e.target.value.substring(0, 20) });
    }
    handleInputChangeItemProfit = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mProfitMargin']: e.target.value });
    }
    handleInputChangeItemBarcode = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mBarCode']: e.target.value });
    }
    handleInputChangeItemBarcode2 = (e) => {
        this.props.dataActions.genericDataSetEdit(this.id, { ['mBarCode2']: e.target.value });
    }
    handleInputChangeItemCode = (e) => {
        this.itemCode = e.target.value;
        if (this.itemCode.indexOf("\t") > -1) this.itemCode = this.itemCode.split("\t").join("")
        this.props.dataActions.genericDataSetEdit(this.id, { ['mCode']: this.itemCode });
    }

    titleBar = (title, buttons) => {
        let primary = title === this.getText(10241) ? true : false;
        return (<div style={{ padding: '4px' }}><PageToolbar title={title}
            actionButtons={primary ? buttons : []} genericActionButtons={!primary ? buttons : false} isModal={primary}
            ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataActions={this.props.dataActions} />
        </div>)
    }

    getCol = (style, span, con) => {
        let mSpan = (window.innerWidth > 600) ? (span * 4) + '%' : '96%';
        let mStyle = {
            display: 'inline-block',
            width: mSpan,
            marginLeft: 1,
            marginBottom: 10,
            verticalAlign: 'text-top',
            ...style
        };
        return (<div style={mStyle}>{con}</div>);
    }

    generateInputNumberField = (text, field) => {
        let er = this.props.data[this.id].editedRecord;
        let isDec = er['mIsByWeight'] == '1' && field !== 'mModifierMinimum1' && field !== 'mModifierMaximum1' && field !== 'mModifierMaximum2' ? true : false;

        return (<div style={{ padding: 4, paddingRight: 10 }}>
            <div style={{ display: 'inline-block', width: '50%', textAlign: 'right' }}>{this.getText(text)}</div>
            <InputNumber
                style={{ backgroundColor: er['mIsByWeight'] == '1' && !er[field] ? 'pink' : 'white' }}
                step={isDec ? 0.001 : 1}
                min={field == 'mModifierMaximum2' ? undefined :
                    (field == 'mModifierMinimum1' || field == 'mModifierMaximum1') && !er.mModifierGroup1 ? undefined :
                        field === 'mVirtualShopMax' ? er['mVirtualShopMin'] :
                            isDec ? 0.001 :
                                1}
                precision={isDec ? 3 : 0}
                value={er[field]}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e ? e : "" }) }}
            />
        </div>)
    }

    modalButton = (state, text) => {
        return (<Button
            type="primary"
            disabled={!this.state.isValidItemCode}
            style={{ marginTop: 10, width: '100%' }}
            onClick={() => { this.saveNewBeforeOpen(() => { this.setState({ [state]: true }) }) }}>
            {this.getText(text)}
        </Button>)
    }

    getPictureView = (field, aspect, googleImage) => {
        return (<span>
            <PictureView
                {...this.props}
                url={this.props.data[this.id].editedRecord[field]}
                onChange={(url) => this.props.dataActions.genericDataSetEdit(this.id, { [field]: url })}
                onRemove={() => this.props.dataActions.genericDataSetEdit(this.id, { [field]: '' })}
                onUploadedSuccess={(response) => { }}
                onUploadedFailed={(errorMessage, errorCode) => { }}
                imageType={'I'}
                aspect={aspect}
                googleImage={googleImage}
            />
        </span>)
    }

    getPiecesSelect = () => {
        return (<Select
            style={{ width: 150 }}
            value={this.props.data[this.id].editedRecord['mPiecesNum']}
            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mPiecesNum']: e }) }}>
            <Select.Option value={'1'}>1</Select.Option>
            <Select.Option value={'2'}>2</Select.Option>
            <Select.Option value={'4'}>4</Select.Option>
        </Select>)
    }

    onChangeTagList = (e) => {
        let findDefault = this.props.data[this.id_tags].data.find(f => f.split("\f")[1] == '1')
        let valueTagDefault = findDefault ? findDefault.split("\f")[0] : null;
        let allTags = [];
        this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.forEach(node => {
            node.tags.forEach(item => { allTags.push(item) })
        })

        let newData = e.map(id => {
            let findText = allTags.find(f => f.id === id);
            let text = findText ? findText.text : "";
            let isDef = valueTagDefault === id || e.length === 1 ? '1' : '0';
            return id + "\f" + isDef + "\f" + text
        })
        this.props.dataActions.setJson(this.id_tags, { data: newData })
    }

    apiLastRates = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id_rates, this.props.user.companyCode, this.props.user.token, { dDate: moment().format("YYYY-MM-DD") }, "get_last_rates")
    }

    wooConvItemEdit = (text, record) => {
        const { editing } = this.props.data[this.woo_id];
        let isEdit = record.key === editing;
        let onEdit = null;
        let onCheck = null;
        let onClose = null;
        let genericIcons = []

        if (isEdit) {
            onClose = () => { this.props.dataActions.genericDataCancelEditing(this.woo_id) }
            onCheck = () => {
                this.props.dataActions.genericDataSaveEdit(
                    this.woo_id, this.props.user.companyCode, this.props.user.token, this.api.save_woo, this.api.get_woo);
            }
        } else {
            onEdit = () => { this.props.dataActions.genericDataStartEditing(this.woo_id, record.key, record.index) }
            genericIcons.push({
                type: "copy",
                onClick: () => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.woo_id, this.props.user.companyCode, this.props.user.token, this.api.copy_woo, this.api.get_woo, {
                        ...record, _all: 1
                    });
                },
                onCancel: () => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.woo_id, this.props.user.companyCode, this.props.user.token, this.api.copy_woo, this.api.get_woo, {
                        ...record, _all: 0
                    });
                },
                tooltip: this.getText(18913),
                popconfirm: { title: this.getText(18913), okText: this.getText(18914), cancelText: this.getText(18915) },
            })
        }

        return (<TableLineIcons {...{ onEdit, onClose, onCheck, genericIcons }} />)
    }

    wooConvItemInput = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.woo_id];
        let isEdit = record.key === editing;
        return isEdit ? (<InputNumber
            value={editedRecord.mWooId}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.woo_id, { mWooId: e }) }}
        />) : text
    }

    saveNewBeforeOpen = (next) => {
        if (!this.props.ui.data || !this.props.ui.data.itemId) {
            Modal.confirm({
                title: "",
                content: <div>
                    <p>{this.getText(19921)}</p>
                    <p>{this.getText(19922)}</p>
                </div>,
                iconType: 'exclamation-circle',
                okText: this.getText(19923),
                cancelText: this.getText(19924),
                onOk: () => { this.handleOk(false, next) },
                onCancel: () => { },

            })
        } else {
            next()
        }
    }

    render() {
        const { loading } = this.state;
        let styleCol = { direction: 'rtl' };
        let modalStyle = { direction: 'rtl' };

        // let issetVirtualShop = this.props.data.PSEUDO_SELECTOR_UI_COMPONENTS_MODULES.dataset.find(f => f.name === '3' || f.name === '485') ? true : false;

        let er = this.props.data[this.id] ? this.props.data[this.id].editedRecord : {};
        // console.log(er, 'editedrecord create item')

        let isUserWooSettings = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '518')
        let isUserWarrantyMonthsView = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '527')


        let valueTagList = this.props.data[this.id_tags] ? Object.values(this.props.data[this.id_tags].data) : [];
        let valueTagDefault = null;
        let valueTagObjList = valueTagList.map(x => {
            let y = x.split("\f");
            if (y[1] == '1') valueTagDefault = y[0];
            return { id: y[0], isDefault: y[1], text: y[2] }
        })
        let tagDefaultList = valueTagObjList.map(x => (<Option value={x.id}>{x.text}</Option>));

        // let tooltipTagList = this.getText(10282) + ": ";
        // let tagDefaultList = [];
        // let valueTagDefault = '';



        let virtualShopTags = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.find(f => f.type.id == '4');
        virtualShopTags = virtualShopTags && virtualShopTags.tags ? virtualShopTags.tags : [];

        // this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.forEach((x) => {
        //     x.tags.map((y) => {
        //         let tagNormal = (valueTagList.indexOf(y.id + '\f0') >= 0);
        //         let tagDef = (valueTagList.indexOf(y.id + '\f1') >= 0);
        //         if (tagNormal || tagDef) {
        //             // tooltipTagList += y.text + ', ';
        //             tagDefaultList.push(<Option value={y.id}>{y.text}</Option>)
        //             if (tagDef) valueTagDefault = y.id;
        //         }
        //     })
        // });

        let footerButtons = [
            <Button key="back" onClick={this.handleCancel}>{this.getText(10312)}</Button>,
            <Button key="clear" loading={loading} onClick={this.handleClear}>{this.getText(10313)}</Button>,
            <Button key="create" disabled={!this.state.isValidItemCode}
                type="primary" loading={loading} onClick={() => { this.handleOk(true) }}>{this.getText(10292)}</Button>
        ];

        if (this.props.ui.data && !this.props.ui.data.fromMatrix && !this.props.ui.data.onSave && !this.props.onSaveFromCreateDoc) {
            footerButtons.push(<Button key="saveAndCreate" disabled={!this.state.isValidItemCode}
                type="primary" loading={loading} onClick={() => { this.handleOk(false) }}>{this.getText(10293)}</Button>)
        }

        // let isNew = !this.props.ui.data || !this.props.ui.data.itemId || this.props.ui.data.copyItemId ? true : false;


        let baseData = (<span>
            {this.generateInputField(this.getText(10258), this.handleInputChangeItemCode, 'mCode', 15, 2)}
            {this.generateInputField(this.getText(10259), this.handleInputChangeItemBarcode, 'mBarCode', 15, 3)}
            {this.generateInputField(this.getText(17311), this.handleInputChangeItemBarcode2, 'mBarCode2', 15, 3)}
            {this.generateInputField(this.getText(10260), this.handleInputChangeItemLongName, 'mName', 50, 1)}
            {this.generateInputField(this.getText(10261), this.handleInputChangeItemShortName, 'mShortName', 20, 1)}
            {this.generateSelectOptions(this.getText(10262), this.getText(10263), 'mTaxTypeId', TableIDs.genericSelectorTaxType)}
        </span>)

        let settingsData = (<span>

            {this.generateSelectOptions(this.getText(10264), this.getText(10265), 'mSaleAlertID', TableIDs.genericSelectorSalesAlerts)}

            {this.frameStyle(this.getText(10266), (
                // <Tooltip title={tooltipTagList}>
                <div style={{ width: '65%', marginRight: '3%', display: 'inline-block' }}>
                    <GenericSelectorItemTags {...this.props} multi notReport
                        //  <Select
                        //                 showSearch
                        //                 filterOption={true}
                        //                 optionFilterProp="valuefilter"
                        //                 mode="multiple"
                        //                 maxTagCount={4}
                        // onChange={(e) => { this.props.dataActions.setData('tags', e.map(x => x === valueTagDefault || e.length === 1 ? x + '\f1' : x + '\f0')) }}
                        placeholder={this.getText(10267)}
                        onChange={this.onChangeTagList}
                        value={valueTagObjList.map((x) => x.id) /*.map(obj => obj.split('\f')[1])*/}
                        disabled={!this.state.isValidItemCode}
                    // >
                    //     {tagList}
                    // </Select>
                    />
                </div>
                // </Tooltip>
            ))}

            {this.frameStyle(this.getText(15363), (<Select
                showSearch
                filterOption={true}
                optionFilterProp="valuefilter"
                onChange={(e) => {
                    let newData = valueTagObjList.map((x) => {
                        let isDef = x.id === e ? '1' : '0';
                        return x.id + "\f" + isDef + "\f" + x.text
                    })
                    this.props.dataActions.setJson(this.id_tags, { data: newData })
                }}
                style={{ width: '65%', marginRight: '3%' }}
                value={valueTagDefault}
                disabled={!this.state.isValidItemCode}
            >
                {tagDefaultList}
            </Select>))}

            {this.generateSelectOptions(this.getText(10268), '', 'mRuler1ID', TableIDs.genericSelectorRuler)}

            {/* {this.frameStyle(this.getText(10269), (<TextArea style={{ display: 'inline-block', width: '65%', marginRight: '3%' }}
                value={this.props.data[this.id].editedRecord['mRemarks']}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mRemarks']: e.target.value }) }}
                disabled={!this.state.isValidItemCode} autoSize={{ minRows: 2, maxRows: 5 }}
            />))} */}

            {this.generateTextareaField(this.getText(10269), 'mRemarks')}
            {this.generateTextareaField(this.getText(18850), 'mInternalRemarks')}

            <Row>

                <Col span={window.innerWidth > 1000 && isUserWarrantyMonthsView ? 15 : 24}>
                    {this.frameStyle(this.getText(10276),
                        (<Radio.Group
                            value={this.props.data[this.id].editedRecord['mIsSerialize']}
                            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mIsSerialize']: e.target.value }) }}
                            disabled={!this.state.isValidItemCode} >
                            <Radio value="0">{this.getText(18499)}</Radio><br />
                            <Radio value="1">{this.getText(18500)}</Radio><br />
                            <Radio value="2">{this.getText(18501)}</Radio><br />
                            <Radio value="3">{this.getText(18502)}</Radio>
                        </Radio.Group>))}
                </Col>
                <Col span={window.innerWidth > 1000 && isUserWarrantyMonthsView ? 9 : 24}>
                    {/* {isUserWarrantyMonthsView ? */}
                    {/* דני ביקש להוריד 2024-10-28 */}
                        <span>
                            {this.getText(19919)}
                            <InputNumber style={{ margin: 5 }} precision={0} min={0} max={999}
                                value={this.props.data[this.id].editedRecord['mWarrantyMonths']}
                                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mWarrantyMonths']: e }) }}
                                disabled={!this.state.isValidItemCode}
                            />
                            {this.getText(19920)}
                        </span>
                        {/* : ""} */}
                </Col>
            </Row>

        </span>)

        let checkboxData = (<span>
            {this.generateCheckboxField(this.getText(10270), 'mIsActive')}
            {this.generateCheckboxField(this.getText(10271), 'mIsByWeight')}
            {this.generateCheckboxField(this.getText(10272), 'mIsSold')}
            {this.generateCheckboxField(this.getText(10273), 'mIsPriceFixed')}
            {this.generateCheckboxField(this.getText(10274), 'mIsDiscountProhibited')}
            {this.generateCheckboxField(this.getText(10275), 'mIsForInventory')}
            {/* {this.generateCheckboxField(this.getText(10276), 'mIsSerialize')} */}
            {this.generateCheckboxField(this.getText(10277), 'mIsReverse')}




        </span>)

        let imageBox = (<span>
            {this.getPictureView('mPictureFile', 4 / 3, this.state.googleImage)}
            <ImageSearch
                {...this.props}
                disabled={!this.state.isValidItemCode}
                onChange={(base64) => this.setState({ googleImage: base64 })}
                defaultValue={this.props.data[this.id].editedRecord['mName']}
            />
            {this.modalButton('modalComparativeLabel', 14526)}
            {this.modalButton('modalVirtualShopSettings', 13556)}
            {this.props.onOpenModalConnectedItems ? (
                <Button
                    type="primary"
                    disabled={!this.props.ui.data || !this.props.ui.data.itemId}
                    style={{ marginTop: 10, width: '100%' }}
                    onClick={() => { this.saveNewBeforeOpen(() => { this.props.onOpenModalConnectedItems(this.props.ui.data ? this.props.ui.data.itemId : '') }) }}>
                    {this.getText(16167)}
                </Button>)
                : ""}
            {this.modalButton('modalItemStoragesInventoryLimits', 16394)}
            {this.modalButton('modalItemPackageDeduct', 19853)}
            {
                // this.props.ui.data?.isMatrixReverseFather 
                // ||
                this.props.data[this.id].editedRecord.mIsReverse == '1'
                    ?
                    (<Button
                        type="primary"
                        disabled={!this.state.isValidItemCode}
                        style={{ marginTop: 10, width: '100%' }}
                        onClick={() => {
                            this.saveNewBeforeOpen(() => { this.setState({ mParentItemCode: this.props.ui.data?.itemId, modalMatrixReverse: true }) })
                        }}>
                        {this.getText(19864)}
                    </Button>)
                    : ""}

        </span>)

        let virtualShopCategories = virtualShopTags.map(x => {
            let checked = valueTagList.find(f => f.split('\f')[0] == x.id);
            let onChange = (e) => {
                let newData = valueTagList.map(x => x.split("\f")[0])
                if (e.target.checked) {
                    newData = [...newData, x.id]
                } else {
                    newData = newData.filter(f => f != x.id)
                }
                this.onChangeTagList(newData);
                // this.props.dataActions.setData('tags', newData);
            }
            return [<Checkbox checked={checked ? true : false} onChange={onChange}>{x.text}</Checkbox>, <br />]
        })

        let mTaxTypeId = this.props.data[this.id].editedRecord.mTaxTypeId;
        const { isValidItemCode } = this.state;
        const findDefaultSupplier = this.props.data[this.id_suppliers].data.map(x => {
            let y = x.split("\f");
            return { _cost: y[2], _isDefault: y[4], _currency: y[13] }
        }).find(f => f._isDefault == '1')

        let costPrice = 0;
        if (findDefaultSupplier) {
            costPrice = findDefaultSupplier._cost;
            if (findDefaultSupplier._currency > 1) {
                const findLastRate = this.props.data[this.id_rates].data.map(x => {
                    let y = x.split("\f");
                    return { _id: y[0], _val: y[2] }
                }).find(f => f._id == findDefaultSupplier._currency)
                if (findLastRate && findLastRate._val) {
                    costPrice = costPrice * findLastRate._val;
                }
            }
        }

        let defaultCurrencyCode = this.props.data[this.id_defaults].editedRecord.tCostCurrency

        let modalCreateEditItemProps = { ...this.props, costPrice, mTaxTypeId, isValidItemCode, defaultCurrencyCode }

        if (parseInt(this.props.data[this.id_suppliers].editing) > -1) console.log("parseInt(this.props.data[this.id_suppliers].editing) > -1", this.props.data[this.id_suppliers].editing, parseInt(this.props.data[this.id_suppliers].editing))




        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'98%'}
                visible={/*visible*/ false || this.props.toggle}
                title={this.titleBar(this.getText(10241), this.actionButtons)}
                onOk={() => { this.handleOk(true) }}
                onCancel={this.handleCancel}
                footer={footerButtons}
                ui={this.props.ui}
                uiActions={this.props.uiActions} >


                {this.getCol(styleCol, 6, baseData)}
                {this.getCol({ paddingRight: '1%' }, 12, settingsData)}
                {this.getCol({}, 3.5, checkboxData)}
                {this.getCol({ paddingRight: '1%' }, 3, imageBox)}


                {this.getCol({ marginLeft: 12 }, 12, (<ModalCreateEditItemPrices {...modalCreateEditItemProps} />))}
                {this.getCol({ marginLeft: 12 }, 12, (<ModalCreateEditItemSuppliers {...modalCreateEditItemProps} />))}
                {/* {this.getTable(this.getText(10278), dataSourcePriceList, columnsPriceList, 'PriceList', this.id_priceList, 10)} */}
                {/* {this.getTable(this.getText(10279), dataSourceSuppliers, columnsSuppliers, 'Suppliers', this.id_suppliers, 12)} */}
                {/* {vssView ?
                    this.getCol({}, 4, (
                        <div>
                            {this.titleBar(this.getText(13556), [])}
                            {this.generateInputNumberField(13557, 'mVirtualShopMin')}
                            {this.generateInputNumberField(13558, 'mVirtualShopMax')}
                            {this.generateInputNumberField(13559, 'mVirtualShopStep')}
                        </div>
                    ))
                    : ''} */}
                {/* <ModalPricesHistory
                    visible={this.state.modalPricesHistory}
                    close={() => { this.setState({ modalPricesHistory: false }) }}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                    user={this.props.user}
                /> */}
                <GenericModal
                    visible={this.state.modalComparativeLabel}
                    onCancel={() => { this.setState({ modalComparativeLabel: false }) }}
                    width={600}
                    title={this.getText(14526)}>
                    {this.generateSelectOptions(this.getText(14377), this.getText(14378), 'mModifierGroup1', this.modifier1_id)}
                    {this.generateInputNumberField(14379, 'mModifierMinimum1')}
                    {this.generateInputNumberField(14380, 'mModifierMaximum1')}
                    {this.generateInputNumberField(18573, 'mModifierMaximum2')}
                </GenericModal>
                <GenericModal
                    visible={this.state.modalVirtualShopSettings}
                    onCancel={() => { this.setState({ modalVirtualShopSettings: false }) }}
                    width={800}
                    title={this.getText(13556)}>
                    {this.getCol({}, 8, virtualShopCategories)}
                    {this.getCol({}, 8, [
                        this.generateInputNumberField(13557, 'mVirtualShopMin'),
                        this.generateInputNumberField(13558, 'mVirtualShopMax'),
                        this.generateInputNumberField(13559, 'mVirtualShopStep'),
                    ])}
                    {this.getCol({ marginRight: 20 }, 6, [
                        <Form.Item label={this.getText(15535)}>{this.getPiecesSelect()}</Form.Item>,
                        <Form.Item label={this.getText(15540)}>{this.getPictureView('mPizzaImage', 1)}</Form.Item>,
                        isUserWooSettings ?
                            <Button type="primary" onClick={() => { this.setState({ modalWooConvItem: true }) }}>{this.getText(18909)}</Button>
                            : ""
                    ])}
                </GenericModal>
                <GenericModal
                    visible={this.state.modalWooConvItem}
                    onCancel={() => { this.setState({ modalWooConvItem: false }) }}
                    width={600}
                    title={this.getText(18909)}>
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.woo_id]}
                        columns={[
                            { title: this.getText(18910), width: "50%", key: "mWooSiteName", dataIndex: "mWooSiteName", render: t => t },
                            { title: this.getText(18911), width: "35%", key: "mWooId", dataIndex: "mWooId", render: this.wooConvItemInput },
                            { title: this.getText(18912), width: "15%", render: this.wooConvItemEdit },
                        ]}
                        pagination={{ pageSize: 10 }}
                    />
                </GenericModal>
                <ModalCreateEditItemStock
                    {...this.props}
                    visible={this.state.modalItemStoragesInventoryLimits}
                    onCancel={() => { this.setState({ modalItemStoragesInventoryLimits: false }) }}
                    itemCode={this.props.data[this.id].editedRecord.mCode}
                    itemName={this.props.data[this.id].editedRecord.mName}
                />

                <ModalItemPackageDeduct
                    {...this.props}
                    visible={this.state.modalItemPackageDeduct}
                    onCancel={() => { this.setState({ modalItemPackageDeduct: false }) }}
                    itemCode={this.props.data[this.id].editedRecord.mCode}
                    itemName={this.props.data[this.id].editedRecord.mName}
                    isByWeight={this.props.data[this.id].editedRecord.mIsByWeight == '1'}
                />

                <ModalMatrixReverse
                    id={TableIDs.modalMatrixReverse + "InModal"}
                    // context={this.id} context_api_get={this.api.get}
                    mParentItemCode={this.state.mParentItemCode}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.state.modalMatrixReverse}
                    onClose={() => { this.setState({ modalMatrixReverse: false }) }}
                    title={this.getText(10243)}
                />

            </Modal >
        )
    }


    refreshData = () => {
        let mItemCode = 0;
        if (this.props.ui.data) {
            mItemCode = this.props.ui.data.copyItemId ? this.props.ui.data.copyItemId : this.props.ui.data.itemId
        }
        this.IDs.map((x) => {
            if (x.id === this.id_priceList || x.id === this.id_suppliers || x.id === this.woo_id) {
                this.props.dataActions.genericDataSetFilter(x.id, this.props.user.companyCode, this.props.user.token, { mItemCode }, x.api);
            } else {
                this.props.dataActions.genericDataGetEditWithParams(x.id, this.props.user.companyCode, this.props.user.token, { mItemCode }, x.api);
            }
        })

        // setTimeout(() => {
        //     this.props.dataActions.genericDataCancelEditing(this.id_priceList, true);
        //     this.props.dataActions.genericDataCancelEditing(this.id_suppliers, true);
        // }, 1500);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const isFather = nextProps.ui.isEditFatherItem;
        return (isFather === true) ? false : true;
    }

    componentDidUpdate(prevProps) {


        console.log("change props edit item", this.props.ui, this.props.data[this.id].editedRecord)

        let pui = this.props.ui.data;
        let ppui = prevProps.ui.data;
        if ((pui && ppui && pui.itemId !== ppui.itemId) ||
            (prevProps.toggle === false && this.props.toggle === true)
        ) {

            this.state.isValidItemCode = (this.props.ui.data && !this.props.ui.data.itemId) ? false : true;
            if (this.props.ui.data && this.props.ui.data.isNew) {
                this.props.ActionQueue.addToQueue({
                    action: this.props.uiActions.setModalUIData,
                    args: [{ itemId: null }]
                });

            }

            this.refreshData();
        }

        if ((pui && ppui && pui.copyItemId && !ppui.copyItemId)) {

            this.state.isValidItemCode = false;

            this.refreshData();
        }

        let pCode = this.props.data[this.id].editedRecord ? this.props.data[this.id].editedRecord.mCode : null;
        let ppCode = prevProps.data[this.id].editedRecord ? prevProps.data[this.id].editedRecord.mCode : null;
        if (!pCode && ppCode && (this.props.onSaveFromCreateDoc || this.props.ui.data?.saveAndNext) && !this.blockRemoveCodeWhenFromCreateDoc) {
            this.blockRemoveCodeWhenFromCreateDoc = true;
            setTimeout(() => {
                this.props.dataActions.genericDataSetEdit(this.id, prevProps.data[this.id].editedRecord);
                if (this.props.ui.data?.saveAndNext) {
                    this.saveAndNextToRun = true;
                    console.log("trb run", Date.now())
                }
            }, 50);
        }

        if(prevProps.ui.workInProgress && !this.props.ui.workInProgress && this.saveAndNextToRun){
            // setTimeout(() => {
                this.props.ui.data.saveAndNext()
                this.refreshData()
                this.props.ActionQueue.addToQueue({
                    action: this.props.uiActions.setModalUIData,
                    args: [{ ...this.props.ui.data, saveAndNext: null }]
                });
                this.blockRemoveCodeWhenFromCreateDoc = false
                this.saveAndNextToRun = false
                console.log("trb run", Date.now())
            // }, 5000);
       
        }
    }

    get_item_by_code = () => {
        if (this.itemCode && this.itemCode.search(" ") > -1) {
            this.codeWithSpaces();
        } else {
            this.api_get_item_by_code();
        }
    }

    api_get_item_by_code = () => {
        var dataSend = "mItemCode\r" + this.itemCode;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        // var job = new Job(user, "get_item", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        var job = new Job(user, "check_if_exsist_item", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.successCallbackGetItemByCode, (e) => { this.codeInvalid(e); }); // callback problem here
    }

    successCallbackGetItemByCode = (ob) => {
        if (ob.data.length == 0) {
            this.codeError();
        } else {
            let defOb = {}
            let defaults = this.props.data[this.id_defaults];
            let y = defaults.data[0] ? defaults.data[0].split("\f") : [];
            defaults.headers.map((x, i) => { defOb = { ...defOb, [x]: y[i] } });

            // let arr = ob.data.split("\r");
            // if (arr[arr.length - 1] == 0) {
            let arr = ob.data.split("\f");
            if (arr[0] == 0) {
                this.codeValid();
                this.setState({
                    isValidItemCode: true,
                    isNewItem: false,
                }, /*tmp defaults TODO: remove this callback*/() => {
                    const { createNewFromDoc } = this.props.data[this.id];

                    /// item
                    let newRecord = {
                        mBarCode: this.itemCode,
                        mBarCode2: this.itemCode,
                    }

                    if (!this.props.ui.data.copyItemId) {
                        newRecord = {
                            ...newRecord,
                            // mShortName: this.getText(10280) + " " + this.itemCode,
                            // mName: this.getText(10280) + " " + this.itemCode,
                            mIsActive: defOb.tIsActive,
                            mIsByWeight: defOb.tIsByWeight,
                            mIsSold: defOb.tIsSold,
                            mIsPriceFixed: defOb.tIsPriceFixed,
                            mIsDiscountProhibited: defOb.tIsDiscountProhibited,
                            mIsForInventory: defOb.tIsForInventory,
                            mIsSerialize: defOb.tIsSerialize,
                            mTaxTypeId: defOb.tTaxTypeId,
                            mSaleAlertID: defOb.tSaleAlertId,
                            mIsReverse: defOb.tIsMatrixReverse,
                            mRuler1ID: defOb.tRulerId,
                            mVirtualShopStep: defOb.tIsByWeight == '1' ? defOb.tVirtualShopStep : '',
                            mVirtualShopMin: defOb.tIsByWeight == '1' ? defOb.tVirtualShopMin : '',
                            mVirtualShopMax: defOb.tIsByWeight == '1' ? defOb.tVirtualShopMax : '',
                            mFoodDishID: '',
                            mIsMatrix: '0',
                            mIsMatrixFather: '0',
                            mModifierGroup1: "",
                            mModifierMaximum1: "",
                            mModifierMinimum1: "",
                            mModifierMaximum2: "",
                            mPictureFile: "",
                            mPiecesNum: "",
                            mPizzaImage: "",
                            mProfitMargin: "",
                            mRemarks: "",
                        }

                        if (createNewFromDoc && createNewFromDoc.description) {
                            newRecord = {
                                ...newRecord,
                                mBarCode: createNewFromDoc.itemCode,
                                mBarCode2: createNewFromDoc.itemCode,
                                mName: createNewFromDoc.description,
                                mShortName: createNewFromDoc.description,
                            }
                        }
                    }

                    this.props.dataActions.genericDataSetEdit(this.id, newRecord);

                    if (!this.props.ui.data.copyItemId) {

                        /// suppliers

                        let newRecordSuppliers = {
                            key: 0,
                            mItemCode: '',
                            mSupplierId: this.props.defaultSupplier ? this.props.defaultSupplier : defOb.tSupplierID,
                            mItemCostNoVat: '0.00',
                            mCurrencyCode: defOb.tCostCurrency ? defOb.tCostCurrency : '1',
                            mSupplierItemCode: '',
                            mIsDefault: '1',
                            number: 1
                        }

                        if (createNewFromDoc && (createNewFromDoc.documentType == 4 || createNewFromDoc.documentType == 18)) {
                            let mItemCostNoVat = createNewFromDoc.unitPriceNoTax;
                            if (!parseFloat(mItemCostNoVat)) {
                                if (createNewFromDoc.unitPriceWithTax) {
                                    mItemCostNoVat = (parseFloat(createNewFromDoc.unitPriceWithTax) / 1.17).toFixed(2);
                                } else {
                                    mItemCostNoVat = '0.00'
                                }
                            }

                            newRecordSuppliers = { ...newRecordSuppliers, mSupplierId: createNewFromDoc.supplierCode, mItemCostNoVat }
                        }

                        if (newRecordSuppliers.mSupplierId) {
                            this.props.dataActions.dataTableSetRow(this.id_suppliers, 0, newRecordSuppliers, true);
                        }

                        /// tags

                        let tTagList = defOb.tTagList;
                        if (tTagList) this.onChangeTagList(tTagList.split(','));
                        // this.props.dataActions.setData('tags', tTagList.split(',').map((x, i) => i === 0 ? x + '\f1' : x + '\f0'));

                        /// prices

                        let priceList = this.props.data[TableIDs.genericSelectorPriceList].dataset;
                        if (this.state.displayOnlyDefaultPriceList) {
                            priceList = priceList.filter(f => f.code == this.state.displayOnlyDefaultPriceList)
                        }

                        for (let x = priceList.length; x > 0; x--) {
                            this.handlePriceListCreate();
                            let newRecordPrices = {
                                key: 0,
                                mId: "",
                                mItemCode: "",
                                mPriceListId: priceList[x - 1].code,
                                mPriceVal: '0.00',
                                mPriceNoVat: '0.00',
                                number: this.props.data[this.id_priceList].data.length + 1
                            }
                            if (createNewFromDoc
                                && createNewFromDoc.priceListId === priceList[x - 1].code
                                && createNewFromDoc.documentType != 4
                                && createNewFromDoc.documentType != 18
                                && parseFloat(createNewFromDoc.unitPriceWithTax)
                            ) {
                                let value = createNewFromDoc.unitPriceWithTax;
                                let noTaxText = '(לא כולל מע"מ)';
                                let plIsNoTax = priceList[x - 1].name.indexOf(noTaxText) > -1;
                                let mPriceNoVat = plIsNoTax ? value : (parseFloat(value) / 1.17).toFixed(2)

                                newRecordPrices = { ...newRecordPrices, mPriceVal: value, mPriceNoVat }
                            }
                            this.props.dataActions.dataTableSetRow(this.id_priceList, 0, newRecordPrices, true);
                        }
                    }
                });

            } else {
                // this.codeInvalid();
                message.warning(arr[1]);
            }
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }



}
export default ModalCreateEditItem;
